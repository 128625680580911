import clsx from 'clsx';
import React from 'react';
import { Icon } from '../../Icon/Icon';
import styles from './Step.module.scss';

export interface Step {
	title: string;
	subTitle: string;
	subTitleClassName?: string;
	state: 'DONE' | 'IN_PROGRESS' | 'UNSET';
	component: (prop?: any) => JSX.Element;
}

interface StepProps extends Step {
	isActive: boolean;
	neutral?: boolean;
	onPress(): void;
}

export const Step = ({ title, subTitle, neutral, subTitleClassName, onPress, state, isActive }: StepProps) => {
	const showInnerCircle = state === 'IN_PROGRESS';
	return (
		<div onClick={onPress} className={styles.stepContainer}>
			<div
				className={clsx(
					styles.mainCircle,
					styles[`${state}circle`],
					neutral && styles[`${state}circleNeutral`],
					isActive && (neutral ? styles.activeNeutralButton : styles.activeButton),
				)}
				data-testid='step-circle'
			>
				{state === 'DONE' ? (
					<Icon imgType='v_icon' className={styles.doneImg} color='white' />
				) : showInnerCircle ? (
					<div className={styles.innerCircle}></div>
				) : null}
			</div>
			<div className={styles.textContainer}>
				<span className={clsx(styles.stepText, neutral && styles.neutralTitle)}>{title}</span>
				<span className={clsx(styles.stepText, styles.stepSubtitle, neutral && styles.neutralSubTitle, subTitleClassName)}>{subTitle}</span>
			</div>
		</div>
	);
};
