import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SettingsCategory, SettingsName } from '@received/pricing-model';
import { setAppCurrency } from '../../storeSlices/generalSlice';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setIsAuthenticated, setUser } from '../../storeSlices/userSlice';
import { Avatars, Icon } from '../_uiComponents';
import { Store } from '../../types/storeTypes';
import { UserDto } from '../../types/userTypes';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { formatUserName } from '../../utils/UserUtils';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { NavigationLink } from '../NavigationSideBar/NavigationLink/NavigationLink';
import styles from './CurrentUser.module.scss';
// Need NavigationSideBar css to handle media queries
import navigationStyles from './../NavigationSideBar/NavigationSideBar.module.scss';

export const CurrentUser = () => {
	const { t } = useTranslation('translation');
	const { isAuthenticated, ...userData } = useSelector((store: Store) => store.user);

	const location = useLocation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (isAuthenticated) {
			currentUser();
			getCurrency();
		}
	}, [isAuthenticated]);

	const logout = async () => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.authLogout,
			});
			dispatch(setIsAuthenticated(false));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CURRENT_USER }));
		}
	};

	const currentUser = async () => {
		try {
			const userDto: UserDto = (
				await httpService({
					dispatch,
					path: PathsConfig.authCurrentUser,
				})
			).data;
			dispatch(setUser(userDto));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CURRENT_USER }));
		}
	};

	const getCurrency = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getSettingsDataByAttributeName,
				urlParam: { settingsSection: SettingsCategory.COMMON, settingName: SettingsName.DEFAULT_CURRENCY },
			});

			dispatch(setAppCurrency(res.data.value));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_UPDATE_CURRENCY }));
		}
	};

	return (
		<div className={styles.footer}>
			<div className={styles.userContainer}>
				<Avatars imgArray={[{ name: userData.name }]} className={styles.avatar} />
				<div className={navigationStyles.userDetails}>
					<span className={styles.userName}>{formatUserName(userData)}</span>
				</div>
			</div>

			<NavigationLink
				item={{
					iconType: 'settings',
					name: t('auth.settings'),
					path: 'settings',
					showOnSidebar: true,
				}}
				customLink={
					<>
						<Icon imgType='settings' color='neutral' className={navigationStyles.linkIcon} />
						<span className={navigationStyles.linkText}>{t('auth.settings')}</span>
					</>
				}
			/>

			<div className={clsx(navigationStyles.sidebarLinksContainer)}>
				<div data-testid={`side-bar-link-${t('auth.logout')}`} onClick={logout} className={navigationStyles.link}>
					<Icon imgType='logout' color='neutral600' className={navigationStyles.linkIcon} />
					<span className={navigationStyles.linkText}>{t('auth.logout')}</span>
				</div>
			</div>
		</div>
	);
};
