import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContractState, ContractType } from '@received/pricing-model';
import { Table } from '../../../components';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { subscriptionsMenuOptions, subscriptionsTabTableHeader } from './SubscriptionsTab.utils';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Store } from '../../../types/storeTypes';
import { SubscribersView } from '../../../types/subscriptionTypes';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { SubscribersResponse } from '../../../pages/_ContractsViews/Subscriptions/Subscriptions';
import styles from './SubscriptionsTab.module.scss';

const defaultNumberOfItems = '20';

export const SubscriptionsTab = ({ isActive, customerData, sideBarFilters, refreshTabs, setOpenSideBarById }: CustomerTabProps) => {
	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);

	const [subscribersList, setSubscribersList] = useState<SubscribersView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [states, setStates] = useState<ContractState[]>([]);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.DESC });

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		isActive && getData();
	}, [
		customerData,
		customerData?.id,
		numberOfItems,
		pageNumber,
		states,
		sort.orderBy,
		sort.order,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
		isActive,
		refreshTabs,
	]);

	const getData = async () => {
		if (customerData?.id) {
			await getContractTabData();
		}
	};

	const getContractTabData = async (page?: number, items?: string) => {
		try {
			const res: SubscribersResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getSubscriptionsViewData,
					params: {
						customerId: customerData?.id,
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						order: sort.orderBy ? sort.order : undefined,
						states: states.length ? states : undefined,
						contractType: ContractType.SUBSCRIPTION,
						...sideBarFilters?.filterData,
					},
				})
			).data;

			setTotalCount(res?.totalCount);
			setSubscribersList(res?.viewList);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_TAB }));
		}
	};

	return (
		<div className={styles.container}>
			<Table
				sort={sort}
				header={subscriptionsTabTableHeader(t)}
				rows={subscribersList}
				className={styles.tableContainer}
				numberOfPages={Math.ceil(totalCount / +numberOfItems)}
				optionsList={(row) => subscriptionsMenuOptions(row, t, dispatch, getData)}
				totalNumberOfItems={totalCount}
				onUpdateNumberOfItems={setNumberOfItems}
				numberOfItems={numberOfItems}
				onUpdatePageNumber={setPageNumber}
				onRowPress={(data: SubscribersView) => data.contractId && setOpenSideBarById?.({ sideBarType: SidebarType.CONTRACT, id: data.contractId })}
				onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				emptyStateProps={{
					title: t('noSubscriptions'),
					description: t('noSubscriptionsAssigned'),
					buttonText: `${t('assignSubscription')} +`,
					onClick: () => setOpenSideBarById?.({ sideBarType: SidebarType.ASSIGN_SUBSCRIPTION, id: '' }),
				}}
			/>
		</div>
	);
};
