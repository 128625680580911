import { Dispatch } from '@reduxjs/toolkit';
import { MenuOption } from '../../../components';
import { onDeletePricingModel, onDuplicatePricingModel, onUpdateFavorite } from '../../../components/_uiComponents/CatalogCard/CatalogCard.utils';
import { PricingModel } from '../../../types/contractTypes';

export const resetItemPriceInPricingModel = (pricingModel: PricingModel) => {
	return {
		...pricingModel,
		tabs: pricingModel.tabs.map((tab) => ({
			...tab,
			pricingModelTable: {
				...tab.pricingModelTable,
				rows: tab.pricingModelTable.rows.map((row) => {
					let newRow = { ...row, itemPriceId: undefined };
					if (row?.itemPrice) {
						newRow = { ...newRow, itemPrice: { ...row?.itemPrice, id: undefined } };
					}
					return newRow;
				}),
			},
		})),
	};
};

export const menuOptions = (
	t: any,
	dispatch: Dispatch,
	pricingModel?: PricingModel,
	onRefresh?: (pricingModelId?: string) => void,
	onRefreshAndGoBack?: () => void,
): MenuOption[] => {
	let options: MenuOption[] = [
		{
			title: pricingModel?.isFavorite ? t('unfavoriteTemplate') : t('favoriteTemplate'),
			onPress: () => onUpdateFavorite(dispatch, pricingModel, onRefresh),
			iconType: 'emptyStar',
		},
	];
	if (pricingModel?.product) {
		options = [
			...options,

			{
				title: t('duplicate'),
				onPress: () => onDuplicatePricingModel(dispatch, pricingModel, onRefreshAndGoBack),
				iconType: 'duplicate',
				hasBottomBorder: true,
			},
			{
				title: t('removeTemplate'),
				onPress: () => onDeletePricingModel(dispatch, pricingModel, onRefreshAndGoBack),
				iconType: 'delete',
				color: 'destructive',
			},
		];
	}
	return options;
};
