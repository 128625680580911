import dayjs from 'dayjs';
import { Currency, convertCurrencyToSign } from '@received/pricing-model';
import { Cell, ContractCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT, SHORT_YEAR_FORMAT } from '../../../constants/templateConstants';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { recognitionMethodLabels } from '../../../constants/generalConstants';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import { RevRecView } from '../../../types/revRecTypes';
import { revRecStatusColor } from '../../../utils/revRecUtils';

export const revRecTabTableHeader = (appCurrency: Currency) => [
	{
		headerTitle: 'contractNo',
		accessor: 'contractNumber',
		isSortable: true,
		CustomComponent: (item: RevRecView) => (
			<ContractCell contractNumber={contractIdFormat(item?.poNumber, item?.contractNumber)} subAccessor={item?.contractName} />
		),
	},
	{
		headerTitle: 'recognitionMethod',
		accessor: 'recognitionMethod',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <Cell accessor={recognitionMethodLabels[item?.recognitionMethod]} />,
	},

	{
		headerTitle: 'period',
		accessor: 'period',
		CustomComponent: (item: RevRecView) => (
			<Cell accessor={`${dayjs(item.periodStartDate).format(SHORT_YEAR_FORMAT)} - ${dayjs(item.periodEndDate).format(SHORT_YEAR_FORMAT)}`} />
		),
	},
	{
		headerTitle: 'postingDate',
		accessor: 'postingDate',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <Cell accessor={dayjs(item.postingDate).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'product',
		accessor: 'productName',
		isSortable: true,
	},
	{
		headerTitle: 'revenueAmount',
		accessor: 'revenueAmount',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <Cell accessor={`${getValueWithCurrencySign(item.revenueAmount, convertCurrencyToSign(appCurrency))}`} />,
	},
	{
		headerTitle: 'status',
		accessor: 'status',
		isSortable: true,
		disableRowClick: true,
		CustomComponent: (item: RevRecView) => {
			const badgeColor = revRecStatusColor(item);
			return <StatusCol color={badgeColor.color}>{badgeColor.text}</StatusCol>;
		},
	},
];
