import {
	PricingModelTable,
	SyncStatusDto,
	ContractEditBlocker,
	BillingCycleStruct,
	PricingType,
	CyclicDate,
	ContractSyncStatusDto,
	Currency,
	AccountingEventType,
	CreditNoteReasonCode,
	ContractState,
	EventsGenerationMethod,
	RevenueRecognitionPeriod,
	CryptoTokens,
	IntegrationType,
	PaymentOptions,
	NetTerms,
	PricingCycleStruct,
	PricingCycleActivation,
	BillingCycleUnit,
	ContractType,
} from '@received/pricing-model';
import { Transform, Type } from 'class-transformer';
import { ArrayNotEmpty, IsNotEmpty, ValidateIf, ValidateNested, ValidationArguments } from 'class-validator';
import dayjs from 'dayjs';
import { CatalogCardType } from '../components/_uiComponents/CatalogCard/CatalogCard.utils';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { PaymentTimeOptions } from './generalTypes';
import { Invoice } from './invoiceTypes';
import { Customer } from './customerTypes';
import { IconColor } from '../components';
import { StripeCard } from './integrationTypes';

let tabName = '';

export class Contract {
	id?: string;
	name?: string;
	contractNumber?: number;
	customer?: Customer;
	customerId?: string | null;
	@ValidateNested()
	@Type(() => PricingModel)
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `Missing Products`;
		},
	})
	pricingModel: PricingModel;
	contractType?: ContractType;
	createdAt?: Date;
	poNumber?: string;
	attachment?: string[];
	isDraft?: boolean;
	invoices?: Invoice[];
	updatedAt?: Date;
	state?: ContractState;
	linkToContract?: string;
	syncStatus?: ContractSyncStatusDto[];
	editBlockers?: ContractEditBlocker[];
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `${(args.object as PricingModelTab).name}:__ Missing Activation Start Date`;
		},
	})
	activationStartDate?: Date | null;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	@ValidateIf((o) => o.billingCycle?.unit !== BillingCycleUnit.ONE_TIME)
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `${(args.object as PricingModelTab).name}:__ Missing Activation End Date`;
		},
	})
	activationEndDate?: Date | null;
	includeCurrentInvoice?: boolean;
}

export class ContractValidationData {
	@ValidateNested()
	@Type(() => PricingModel)
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `Missing Products`;
		},
	})
	pricingModel: PricingModel;
}

export class ContractValidation {
	@IsNotEmpty({
		message: 'Missing Customer',
	})
	customer: Customer;

	@ValidateNested()
	@Type(() => ContractValidationData)
	contract: ContractValidationData;
}

export interface ContractView {
	id: string; //contractId
	name?: string;
	customerName: string;
	iconData?: string;
	totalPayments: number;
	lastEdit: Date;
	isDraft: boolean;
	state: ContractState;
	currency: Currency;
	contractNumber: number;
	productCount: number;
	productName: string;
	nextInvoiceAmount?: number;
	nextInvoiceDate?: Date;
	nextInvoiceLastUpdate?: Date;
	pricingModelName: string;
	lastPaidAmount?: number;
	poNumber?: string;
	syncStatus: SyncStatusDto[];
	contractType?: ContractType;
}

export class PricingModel {
	id?: string;
	name?: string; //modelName
	@ValidateNested({ each: true })
	@Type(() => PricingModelTab)
	@ArrayNotEmpty({
		message: (args: ValidationArguments) => {
			return `Missing Products`;
		},
	})
	tabs: PricingModelTab[];
	isFavorite?: boolean;
	catalogIds?: string[];
	isTemplate?: boolean;
	createdAt?: string | Date;
	updatedAt?: string;
	isArchived?: boolean;
	product?: { id: string; name: string };
	categoryName?: string;
	sourcePricingModelIds?: string[];
	categories?: Catalog[];
	iconType?: any;
	color?: IconColor;
	comment?: string;
	dunningSequenceId?: string | null;
	isDunningActive?: boolean;
}

export class PricingModelTab {
	id?: string;
	index?: number;
	name: string;
	productName?: string;
	pricingModelTable: PricingModelTable;
	total?: number;
	billingCycle: BillingCycleStruct;
	pricingCycle: PricingCycleStruct | null;
	pricingCycleActivation: PricingCycleActivation;
	paymentTime?: PaymentTimeOptions;
	autoRenewal?: boolean;
	currency: Currency;
	netTerms: NetTerms;
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `${(args.object as PricingModelTab).name}:__ Missing Entity`;
		},
	})
	supplierId?: string;
	supplier?: Customer;
	@ValidateNested()
	@Type(() => BillingDetails)
	billingDetails?: BillingDetails;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `${(args.object as PricingModelTab).name}:__ Missing Activation Start Date`;
		},
	})
	billingStartDate?: Date | null;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	@ValidateIf((o) => o.billingCycle?.unit !== BillingCycleUnit.ONE_TIME)
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			tabName = (args.object as PricingModelTab).name;
			return `${(args.object as PricingModelTab).name}:__ Missing Activation End Date`;
		},
	})
	billingEndDate?: Date | null;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	issueDate?: Date | null;
	issueDay?: CyclicDate;
	billingDay?: CyclicDate;
	tax: number;
	discount: number;
	totalTax?: number;
	totalDiscount?: number;
	subTotal: number;
	pricingModelTableTotal: number;
	isEstimatedTotal: boolean;
	contractState?: ContractState;
	type?: CatalogCardType;
	pricingType?: PricingType;
	isPartialPeriod?: boolean;
	invoiceMonthInQuarter?: number;
	isCollective: boolean;
	note?: string;
	creditUsed?: number;
	accountingEventType?: AccountingEventType;
	reasonCode?: CreditNoteReasonCode;
	hasUsageReports?: boolean;
	revenueSettings?: RevenueSettings;
	@ValidateNested()
	@Type(() => CryptoSettings)
	cryptoSettings?: CryptoSettings;
	originPricingModelTabId?: string;
	templatePricingModelTabId?: string;
	minimumFee?: number;
	// lastActiveDate used to update billing period in contract with editBlockers
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	lastActiveDate?: Date | null;
}

export class CryptoSettings {
	id?: string;
	isEnabled?: boolean;
	@ValidateIf((o) => o.isEnabled)
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `${tabName}:__ Missing Crypto Wallet Address`;
		},
	})
	address?: string;
	@ValidateIf((o) => o.isEnabled)
	@ArrayNotEmpty({
		message: (args: ValidationArguments) => {
			return `${tabName}:__ Missing Crypto Token`;
		},
	})
	tokens?: CryptoTokens[];
}

export class RevenueSettings {
	revenueCycle?: BillingCycleStruct;
	recognitionTime?: PaymentTimeOptions;
	postingDay?: CyclicDate;
	revenueRecognitionPeriod?: RevenueRecognitionPeriod;
	currency?: Currency;
	revenueRecognitionMethod?: EventsGenerationMethod;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	recognitionPeriodStart?: Date | null;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	recognitionPeriodEnd?: Date | null;
	recognitionAmount?: number;
}

export interface Catalog {
	id: string;
	name: string;
}

export interface ContractSummary {
	activeContractCount: number;
	activeContractAmount: number;
	endContractBillingCount: number;
	endContractBillingAmount: number;
}

export class BillingDetails {
	billingAccountId?: string | null;
	@ValidateIf((o) => o.paymentOptions.includes(PaymentOptions.PAYMENT_LINK) || o.paymentOptions.includes(PaymentOptions.AUTO_CHARGE))
	@IsNotEmpty({
		message: (args: ValidationArguments) => {
			return `${tabName}:__ Missing Stripe Account`;
		},
	})
	paymentGatewayId?: string | null;
	paymentGateway?: IntegrationType;
	@ArrayNotEmpty({
		message: (args: ValidationArguments) => {
			return `${tabName}:__ Missing Payment Method`;
		},
	})
	paymentOptions?: PaymentOptions[];
	paymentGatewayCustomerId?: string | null;
	paymentGatewayCustomer?: StripeCard | null;
}

export interface ContractTabDataRes {
	count: number;
	entries: ContractView[];
}
