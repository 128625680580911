import clsx from 'clsx';
import { Button, Icon } from '../../_uiComponents';
import { HeaderCell } from './HeaderCell/HeaderCell';
import { PricingModelTableClassNames } from '../PricingModelTable';
import { PricingModelTableViewTypeOptions } from '../PricingModelTable.utils';
import { PricingModelTableColumns } from '../../../types/pricingModelTypes';
import { PricingModelTab } from '../../../types/contractTypes';
import styles from './TableHeader.module.scss';

export interface TableHeaderProps {
	columns: PricingModelTableColumns[];
	className?: string;
	classNames?: PricingModelTableClassNames;
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	hoverOnFormula: boolean;
	columnsToColor: { [id: string]: string };
	isEditable?: boolean;
	pricingModelTab?: PricingModelTab;
	headerChildren?: React.ReactNode;
	addColum: () => void;
	updateHeaderName(name: string, id: string, index: number): void;
	onInsertCol(side: 'RIGHT' | 'LEFT', index: number): void;
	onDelete(index: number): void;
	openUsageSideBar(column: PricingModelTableColumns, index: number): void;
	onHideShowColumn(column: PricingModelTableColumns, index: number): void;
	onHideShowColumnInInvoice(column: PricingModelTableColumns, index: number): void;
	addManualUsageReport(): void;
	onMoveCol(side: 'RIGHT' | 'LEFT', index: number): void;
}

export const TableHeader = ({
	columns,
	classNames,
	pricingModelTableViewType,
	pricingModelTab,
	hoverOnFormula,
	columnsToColor,
	isEditable,
	addColum,
	updateHeaderName,
	onInsertCol,
	onDelete,
	openUsageSideBar,
	onHideShowColumn,
	onHideShowColumnInInvoice,
	addManualUsageReport,
	onMoveCol,
}: TableHeaderProps) => {
	return (
		<div className={clsx(styles.headerContainer, classNames?.headerContainer)}>
			{pricingModelTableViewType === PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE && <div className={styles.switchSpace}></div>}
			{columns.map((column, index) => (
				<HeaderCell
					columns={columns}
					colIndex={index}
					hoverOnFormula={hoverOnFormula}
					onInsertCol={(side) => onInsertCol(side, index)}
					onDelete={() => onDelete(index)}
					updateName={(name) => updateHeaderName(name, column.id, index)}
					key={column.id}
					isEditable={isEditable}
					column={column}
					classNames={classNames}
					columnsToColor={columnsToColor}
					isHiddenInInvoice={column.isHiddenInInvoice}
					pricingModelTableViewType={pricingModelTableViewType}
					openUsageSideBar={() => openUsageSideBar(column, index)}
					onHideShowColumn={() => onHideShowColumn(column, index)}
					onHideShowColumnInInvoice={() => onHideShowColumnInInvoice(column, index)}
					addManualUsageReport={addManualUsageReport}
					onMoveCol={(side) => onMoveCol(side, index)}
				/>
			))}
			{pricingModelTableViewType === PricingModelTableViewTypeOptions.PRICING_MODEL ? (
				<Button type='link' color='neutral' className={styles.addColum} onClick={addColum} dataTestId='pricing-table-add-column'>
					<Icon imgType='add' color='neutral700' className={styles.addIcon}></Icon>
				</Button>
			) : (
				<div className={styles.addColum}></div>
			)}
		</div>
	);
};
