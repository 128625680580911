/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { BillingCycleLabel, CellProps, PricingCycleLabel, calculateValueByCycle, handleCellKeyDown } from '../../TableRow.utils';
import { requiredColumnsEnum } from '../../../PricingModelTable.utils';
import styles from './NumberCell.module.scss';

interface NumberCell extends CellProps {
	prefixSign?: string;
	positiveNumbers?: boolean;
}

export const NumberCell = ({
	rowData,
	column,
	prefixSign,
	selectedCell,
	positiveNumbers,
	classNames,
	rowIndex,
	columnIndex,
	pricingModelTab,
	onArrowNavigation,
	onCellSelected,
	updateTableData,
}: NumberCell) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [value, setValue] = useState<string | number>('');
	const [calculatedValue, setCalculatedValue] = useState<string | number>('');

	const isEditByPricingCycle =
		column.id === pricingModelTab?.pricingCycleActivation?.toLocaleLowerCase() &&
		pricingModelTab?.pricingCycle != null &&
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		pricingModelTab?.pricingCycle?.unit != pricingModelTab?.billingCycle?.unit
			? true
			: pricingModelTab?.pricingCycle?.cycle !== pricingModelTab?.billingCycle?.cycle;

	useEffect(() => {
		setIsEditMode(selectedCell?.cell.cellId === rowData.cells[column.id].cellId);
	}, [selectedCell, rowData]);

	useEffect(() => {
		const val = rowData.cells[column.id].cellValue;
		setValue(rowData.cells[column.id].cellValue);

		if (!isEditMode) {
			const calculatedPricing = calculateValueByCycle(val, pricingModelTab, true);
			setCalculatedValue(calculatedPricing || val);
		}
	}, [rowData, isEditMode, pricingModelTab?.pricingCycle, pricingModelTab?.billingCycle]);

	const updateValue = (val: string | number) => {
		const newValue = positiveNumbers && +val < 0 ? 0 : val;
		setValue(newValue);
		const newRowData = { ...rowData };
		newRowData.cells[column.id].cellValue = newValue;

		if (column.id === requiredColumnsEnum.price && newRowData.itemPrice) {
			newRowData.itemPrice = { ...newRowData.itemPrice, price: +newValue };
		}
		updateTableData(newRowData);
	};

	const updateValueByPricingCycle = (val: string | number) => {
		setCalculatedValue(val);
		const calculated = calculateValueByCycle(val, pricingModelTab);
		updateValue(calculated || val);
	};

	return (
		<div className={clsx(styles.cell, classNames?.cell, isEditMode && styles.editMode)}>
			{!isEditMode ? (
				<>
					<BillingCycleLabel column={column} pricingModelTab={pricingModelTab} value={value} prefixSign={prefixSign} />
					<PricingCycleLabel column={column} pricingModelTab={pricingModelTab} value={calculatedValue} prefixSign={prefixSign} />
				</>
			) : (
				<div className={styles.inputContainer}>
					{prefixSign}
					<input
						data-testid={`${rowIndex}-row-${column.title}-column-cell-input`}
						autoFocus
						value={isEditByPricingCycle ? calculatedValue : value}
						type='number'
						onChange={(event) => (isEditByPricingCycle ? updateValueByPricingCycle(event.target.value) : updateValue(event.target.value))}
						className={clsx(styles.input, isEditMode && styles.isEditable, isEditMode && classNames?.isEditable)}
						onKeyDown={(event) => isEditMode && handleCellKeyDown(event, rowIndex, columnIndex, onArrowNavigation, onCellSelected)}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						onWheel={(e) => e.target?.blur()}
					></input>
				</div>
			)}
		</div>
	);
};
