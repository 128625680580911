import React, { useEffect, useState } from 'react';
import { UsageAggregator } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { UpdateUsageProduct } from '../PricingModelTable.utils';
import { UsageConnectionStatusTypes, UsageProduct } from '../../../types/usageTypes';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { Button, Icon, Modal, Select, Tooltip } from '../../_uiComponents';
import { UsageProductModal } from '../../UsageProductModal/UsageProductModal';
import { UsageCellPreview } from './UsageCellPreview/UsageCellPreview';
import { UsageConnectionStatus } from './UsageConnectionStatus/UsageConnectionStatus';
import { UsageProductItem } from './UsageProductItem/UsageProductItem';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { initialAggregations, UsageProductAggregation } from './UsageProductItem/UsageProductItem.utils';
import { PricingModelTableColumns } from '../../../types/pricingModelTypes';
import styles from './ConnectUsageProductModal.module.scss';

export interface UsageEditorModalProps {
	isOpen: boolean;
	usageEditorData: { column: PricingModelTableColumns; columnIndex: number; rowIndex?: number };
	closeModal(): void;
	updateCellWithUsage(updateUsageProduct: UpdateUsageProduct): void;
}

export interface UsageProductInitialization {
	usageProductId: string;
	usageName: string;
}

const defaultState = { usageProductId: '', usageName: '' };

export const ConnectUsageProductModal = ({ isOpen, usageEditorData, updateCellWithUsage, closeModal }: UsageEditorModalProps) => {
	const { t } = useTranslation('translation');
	const [usageProducts, setUsageProducts] = useState<UsageProduct[]>([]);
	const [selectedUsageProduct, setSelectedUsageProduct] = useState<UsageProductInitialization>(defaultState);
	const [usageProductConnectionStatus, setUsageProductConnectionStatus] = useState<UsageConnectionStatusTypes>();
	const [{ method, scope }, setAggregation] = useState<UsageProductAggregation>(initialAggregations);
	const [openAddUsage, setOpenAddUsage] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		isOpen && getUsageProductList();
	}, [isOpen]);

	const getUsageProductList = async (id?: string) => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getUsageProductList,
				params: { isDraft: false },
			});

			setUsageProducts(res.data);
			if (id) {
				const selected = res.data.find((usageProduct: UsageProduct) => usageProduct.id === id);
				selected && setSelectedUsageProduct({ usageProductId: selected.id, usageName: selected.name });
			} else {
				const selectedUsage = usageEditorData?.column?.refObject?.usageProductId;
				selectedUsage && onUsageProductSelected(selectedUsage, res.data);
				//This is aggregator id
				if (usageEditorData?.column?.refObject?.id) {
					setAggregation({
						method: usageEditorData?.column?.refObject?.aggregationFunction,
						scope: usageEditorData?.column?.refObject?.aggregationScope,
					});
				}
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_USAGE_LIST }));
		}
	};

	const createUsageAggregator = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.createUsageAggregator,
				data: {
					usageProductId: selectedUsageProduct.usageProductId,
					name: selectedUsageProduct.usageName,
					usageProduct: selectedUsageProduct,
					aggregationFunction: method,
					aggregationScope: scope,
				},
			});

			return res.data;
		} catch (error) {
			dispatch(
				setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CONNECT_USAGE_PRODUCT }),
			);
		}
	};

	const onSave = async () => {
		const usageAggregator: UsageAggregator = await createUsageAggregator();
		updateCellWithUsage({
			usageAggregator,
			column: usageEditorData?.column,
			columnIndex: usageEditorData?.columnIndex,
			rowIndex: usageEditorData.rowIndex,
		});
		onClose();
	};

	const onUsageProductSelected = (id: string, usageProductList?: UsageProduct[]) => {
		const products = usageProductList || usageProducts;
		const findSelected = products.find((product) => product.id === id);
		findSelected && setSelectedUsageProduct({ usageProductId: findSelected?.id, usageName: findSelected?.name });
		setUsageProductConnectionStatus(setUsageStatus(id));
	};

	const setUsageStatus = (usageProductId: string) => {
		const selected = usageProducts?.find((item) => item.id === usageProductId);
		if (selected?.isManual) return UsageConnectionStatusTypes.MANUAL;
		else if (!selected?.isManual && selected?.isConnected) return UsageConnectionStatusTypes.API;
		else if (!selected?.isManual && !selected?.isConnected) return UsageConnectionStatusTypes.NOT_CONNECTED;
		return;
	};

	const onCreateUsageProduct = () => {
		reset();
		setOpenAddUsage(true);
	};

	const reset = () => {
		setSelectedUsageProduct(defaultState);
		setUsageProductConnectionStatus(undefined);
	};

	const onClose = () => {
		reset();
		closeModal();
	};

	return (
		<Modal isOpen={isOpen} closeModal={onClose} className={styles.modalContainer}>
			<div className={styles.header}>
				<div>
					<div className={styles.title}>{t('connectUsageProduct')}</div>
					<div className={styles.subTitle}>{t('addUsageProductDescription')}</div>
				</div>
				<Button type='link' color='neutral' onClick={onClose}>
					<Icon imgType='x_icon' color='neutral700' className={styles.xIcon} />
				</Button>
			</div>

			<div className={styles.contentContainer}>
				<div className={styles.contentTitle}>{t('Define input usage role')}</div>
				<UsageCellPreview
					columnName={usageEditorData?.column.title}
					selectedUsageProduct={selectedUsageProduct}
					aggregationData={selectedUsageProduct.usageProductId ? { method, scope } : undefined}
				/>
				<UsageConnectionStatus status={usageProductConnectionStatus} />

				<Select
					title={t('chooseUsageProduct')}
					placeholder={t('Choose usage products')}
					defaultValue={selectedUsageProduct.usageProductId}
					data={usageProducts?.map((item) => ({ label: item.name, value: item.id })) || []}
					onChange={(id: string) => onUsageProductSelected(id)}
					width={47.2}
					addButton={{ title: t('createUsageProduct'), onPress: onCreateUsageProduct }}
				/>
				<div className={styles.usageProductsItems}>
					{usageProducts?.map((item) => (
						<UsageProductItem
							key={item.id}
							item={item}
							aggregationData={{ method, scope }}
							isSelected={item.id === selectedUsageProduct.usageProductId}
							onPress={() => onUsageProductSelected(item.id)}
							status={setUsageStatus(item.id)}
							setAggregation={setAggregation}
							onEdit={() => setOpenAddUsage(true)}
						/>
					))}
				</div>
			</div>

			<div className={styles.footer}>
				<Tooltip
					tooltipComponent={<div>{t('chooseUsageProduct')}</div>}
					disabled={!!selectedUsageProduct.usageProductId}
					containerClassName={styles.footerButtons}
				>
					<Button onClick={onSave} className={styles.footerButtons} disabled={!selectedUsageProduct.usageProductId} color='success'>
						{t('connect')}
					</Button>
				</Tooltip>
			</div>

			<UsageProductModal
				isOpen={openAddUsage}
				selectedUsageProductId={selectedUsageProduct.usageProductId}
				closeModal={(usageProductId) => {
					setOpenAddUsage(false);
					onUsageProductSelected(usageProductId || selectedUsageProduct.usageProductId);
					getUsageProductList(usageProductId || selectedUsageProduct.usageProductId);
				}}
			/>
		</Modal>
	);
};
