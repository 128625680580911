import { convertCurrencyToSign, Currency, getValueWithCurrencySign } from '@received/pricing-model';
import { ScheduledRevenueAmountItem } from '../../../../types/generalTypes';
import { Cell } from '../../../_uiComponents/Table/Table.utils';

export const tableHeaders = (currency: Currency) => {
	return {
		title: 'Date',
		data: [
			{
				headerTitle: 'booking',
				accessor: 'bookingAmount',
				CustomComponent: (item: ScheduledRevenueAmountItem) => (
					<Cell accessor={item.bookingAmount ? getValueWithCurrencySign(item.bookingAmount, convertCurrencyToSign(currency)) : '-'} />
				),
			},
			{
				headerTitle: 'recognizedRevenue',
				accessor: 'recognizedRevenue',
				CustomComponent: (item: ScheduledRevenueAmountItem) => (
					<Cell accessor={item.recognizedRevenue ? getValueWithCurrencySign(item.recognizedRevenue, convertCurrencyToSign(currency)) : '-'} />
				),
			},
			{
				headerTitle: 'deferredRevenue',
				accessor: 'deferredRevenue',
				CustomComponent: (item: ScheduledRevenueAmountItem) => (
					<Cell accessor={item.deferredRevenue ? getValueWithCurrencySign(item.deferredRevenue, convertCurrencyToSign(currency)) : '-'} />
				),
			},
			{
				headerTitle: 'billed',
				accessor: 'billedAmount',
				CustomComponent: (item: ScheduledRevenueAmountItem) => (
					<Cell accessor={item.billedAmount ? getValueWithCurrencySign(item.billedAmount, convertCurrencyToSign(currency)) : '-'} />
				),
			},
			{
				headerTitle: 'unbilled',
				accessor: 'unbilledAmount',
				CustomComponent: (item: ScheduledRevenueAmountItem) => (
					<Cell accessor={item.unbilledAmount ? getValueWithCurrencySign(item.unbilledAmount, convertCurrencyToSign(currency)) : '-'} />
				),
			},
		],
	};
};
