import { convertCurrencyToSign, DATE_FORMAT, getValueWithCurrencySign } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { getDocumentIdNameByType } from '../../../../../../utils/GeneralUtils';
import { Button, Icon } from '../../../../../_uiComponents';
import { TimelineDocument } from '../../ContractTimeline.utils';
import styles from './DocumentItem.module.scss';

interface DocumentItemProps {
	timeLineItem: TimelineDocument;
	moreDocsCount: number;
	isActive?: boolean;
	documentIsOnTheSameDate: boolean;
	onClick: () => void;
	onClickMoreDocs: (displayMoreDocs: boolean) => void;
}

export const DocumentItem = ({ timeLineItem, isActive, moreDocsCount, documentIsOnTheSameDate, onClickMoreDocs, onClick }: DocumentItemProps) => {
	const [displayMoreDocs, setDisplayMoreDocs] = useState(true);

	const document = timeLineItem.document;

	const getTimelinePoint = () => {
		if (isActive) {
			return (
				<div className={styles.activeCircle}>
					<div className={styles.innerActiveCircle} />
				</div>
			);
		} else {
			if (!documentIsOnTheSameDate) {
				return (
					<div className={styles.circle}>
						<div className={styles.innerCircle} />
					</div>
				);
			}
			return <div className={styles.point} />;
		}
	};
	return (
		<div className={clsx(styles.content, documentIsOnTheSameDate && timeLineItem?.hidden ? styles.hidden : styles.appear)}>
			<div className={styles.pointWrapper}>{getTimelinePoint()}</div>

			<Button
				className={clsx(styles.container, isActive && styles.selected)}
				type='outlined'
				color='neutral'
				onClick={(e) => {
					e.stopPropagation();
					onClick();
				}}
			>
				<div className={styles.documentContent}>
					<span className={clsx(styles.docNumber, styles.neutralText)}>{getDocumentIdNameByType(document)}</span>
					<span className={styles.darkText}>{dayjs(document.issueDate).format(DATE_FORMAT)}</span>
				</div>
				<div className={styles.documentContent}>
					<span className={clsx(styles.darkText, styles.badge)}>
						{getValueWithCurrencySign(document.total, convertCurrencyToSign(document.currency))}
					</span>
					<div className={styles.flex}>
						<span className={clsx(styles.neutralText, styles.centered, styles.ellipsis)}>{document?.pricingModel?.tabs[0].productName}</span>
						<span className={clsx(styles.neutralText, styles.centered)}>
							{document?.pricingModel?.tabs?.length > 1 ? ` +${document?.pricingModel?.tabs?.length - 1}` : ''}
						</span>
					</div>
				</div>

				{moreDocsCount != 0 && (
					<Button
						type='secondary'
						color={isActive ? 'primary' : 'neutral'}
						className={styles.moreDocsBadge}
						onClick={(e) => {
							e.stopPropagation();
							onClickMoreDocs(!displayMoreDocs);
							setDisplayMoreDocs(!displayMoreDocs);
						}}
					>
						{!displayMoreDocs ? `+${moreDocsCount}` : <Icon imgType='arrow_down' color={isActive ? 'primary' : 'neutral'} width={1} />}
					</Button>
				)}
			</Button>
		</div>
	);
};
