import React, { InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './SearchBar.module.scss';

export interface SearchBarProps<T> extends Omit<InputHTMLAttributes<T>, 'onChange'> {
	className?: string;
	mediumInput?: boolean;
	onChange(val: string): void;
}

export const SearchBar = <T extends HTMLInputElement>({ className, mediumInput, onChange, ...props }: SearchBarProps<T>) => {
	return (
		<input
			{...props}
			className={clsx(styles.inputContainer, mediumInput && styles.inputMediumInputContainer, className)}
			onChange={(event) => onChange(event.target.value)}
			data-testid='search-bar'
		/>
	);
};
