import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, IconProps, MenuList, MenuOption } from '..';
import styles from './CategoriesTabs.module.scss';
import { colors } from '../../../constants/colors';

export interface CategoriesTabsItem {
	id: string;
	name: string;
	count?: number;
	iconType?: IconProps['imgType'];
	[key: string]: any;
}

export interface CategoriesTabsProps {
	title: string;
	allTitle: string;
	tabsList: CategoriesTabsItem[];
	defaultSelectedTab?: CategoriesTabsItem;
	allCount?: number;
	menuOptions?(item: CategoriesTabsItem): MenuOption[];
	onSelectTab: (selected: any) => void;
	onClickAdd?(): void;
}

export const CategoriesTabs = ({
	title,
	allTitle,
	tabsList,
	defaultSelectedTab,
	allCount,
	menuOptions,
	onClickAdd,
	onSelectTab,
}: CategoriesTabsProps) => {
	const [selectedTab, setSelectedTab] = useState<CategoriesTabsItem>();
	const [{ openOptionsMenu, listId }, setOpenOptionsMenu] = useState({ openOptionsMenu: false, listId: '' });

	useEffect(() => {
		setSelectedTab(defaultSelectedTab);
	}, [defaultSelectedTab]);

	const onSelectItem = (item: CategoriesTabsItem | undefined) => {
		setSelectedTab(item);
		onSelectTab(item);
	};

	return (
		<div className={styles.container}>
			<div className={styles.categoriesHeader}>
				<div>{title}</div>
				<div>
					{onClickAdd && (
						<Button type='link' dataTestId='add_category' color='neutral' className={styles.headerButtons} onClick={onClickAdd}>
							<Icon imgType='add' color='neutral700' height={1.3} />
						</Button>
					)}
				</div>
			</div>

			<div className={styles.categoriesListContainer}>
				<div className={clsx(styles.allProductsListItem, !selectedTab && styles.productListItemSelected)} onClick={() => onSelectItem(undefined)}>
					<div>{allTitle}</div>

					{allCount && <div className={styles.productListItemCount}>{allCount}</div>}
				</div>
				{tabsList.map((item) => {
					return (
						<div
							data-testid={`list-item-${item.name}`}
							key={item.id}
							onClick={() => onSelectItem(item)}
							className={clsx(styles.productListItem, selectedTab?.id === item.id && styles.productListItemSelected)}
						>
							<div className={styles.productListItemTitle}>
								{item?.iconType && <Icon imgType={item?.iconType} color='neutral' />}
								<div className={styles.name}>{item.name}</div>
							</div>

							<div className={styles.rightSection}>
								{item?.count?.toString() && <div className={styles.productListItemCount}>{item?.count}</div>}

								{menuOptions && (
									<MenuList
										optionsList={menuOptions(item)}
										isMenuOpen={openOptionsMenu && item.id === listId}
										openMenu={() => setOpenOptionsMenu({ listId: '', openOptionsMenu: false })}
									>
										<Button
											onClick={() => setOpenOptionsMenu({ listId: item.id, openOptionsMenu: true })}
											type='link'
											color='neutral'
											className={styles.hamburger}
											dataTestId={`hamburger-${item.name}`}
										>
											{<Icon imgType='hamburger' color='neutral' />}
										</Button>
									</MenuList>
								)}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
