import { DocumentSettings } from '../../../Contracts/ContractComponent/DocumentSettings/DocumentSettings';
import { PayoutProducts } from './PayoutProducts/PayoutProducts';

export enum SinglePayoutTabsName {
	PRODUCTS = 'Products',
	SETTINGS = 'Settings',
}

export const payoutTabs = () => {
	return [
		{
			title: SinglePayoutTabsName.PRODUCTS,
			component: (prop?: any) => <PayoutProducts {...prop} />,
		},
		{
			title: SinglePayoutTabsName.SETTINGS,
			component: (prop?: any) => <DocumentSettings {...prop} />,
		},
	];
};
