import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Step } from './Step/Step';
import styles from './VerticalStepper.module.scss';

export interface VerticalStepperProps {
	stepsList: Step[];
	className?: string;
	neutral?: boolean;
	updateSteps(newList: Step[]): void;
	updateActiveIndex(activeIndex: number): void;
}

export const VerticalStepper = ({ stepsList, neutral, className, updateSteps, updateActiveIndex }: VerticalStepperProps) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const isActiveLinking = (state: string) => state === 'DONE' || state === 'IN_PROGRESS';

	useEffect(() => {
		if (stepsList.length && stepsList[0].state === 'UNSET') {
			onChangeStep(0);
		}
	}, []);

	const onChangeStep = (index: number) => {
		setActiveIndex(index);
		const tempList = stepsList.map((item, itemIndex) => {
			let newState = item.state;
			if (index === itemIndex) {
				newState = item.state === 'DONE' ? 'DONE' : 'IN_PROGRESS';
			}
			return { ...item, state: newState };
		});
		updateSteps(tempList);
		updateActiveIndex(index);
	};

	return (
		<div className={className}>
			{stepsList.map((item, index) => (
				<div key={index}>
					{index != 0 && (
						<div
							data-testid='stepper-link'
							className={clsx(
								styles.stepLinking,
								isActiveLinking(item.state) ? styles.activeLinking : neutral ? styles.inactiveLinkingNeutral : styles.inactiveLinking,
							)}
						></div>
					)}
					<Step {...item} neutral={neutral} isActive={activeIndex === index} onPress={() => onChangeStep(index)} />
				</div>
			))}
		</div>
	);
};
