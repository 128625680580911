import { PricingTermsSettings } from './settingsTypes';

export const stripeSettings: PricingTermsSettings = {
	isStripeSelectDisplayed: true,
	isStripeCardsSelectDisplayed: true,
	isStripeSelectDisabled: false,
	isStripeMethodSelected: true,
	isStripeMethodDisabled: false,

	isBanksSelectDisplayed: false,
	isBankMethodSelected: false,
	isBanksSelectDisabled: false,
	isBankMethodDisabled: false,

	isAutoChargeToggleOn: false,
	isAutoChargeToggleDisabled: false,

	isCryptoSettingsDisabled: false,

	showAutoChargeOnTooltip: false,
	showDueUponUsageTooltip: false,
};

export const bankTransferSettings: PricingTermsSettings = {
	isStripeSelectDisplayed: false,
	isStripeCardsSelectDisplayed: false,
	isStripeSelectDisabled: false,
	isStripeMethodSelected: false,
	isStripeMethodDisabled: false,

	isBanksSelectDisplayed: true,
	isBankMethodDisabled: false,
	isBanksSelectDisabled: false,
	isBankMethodSelected: true,

	isAutoChargeToggleOn: false,
	isAutoChargeToggleDisabled: false,

	isCryptoSettingsDisabled: false,

	showAutoChargeOnTooltip: false,
	showDueUponUsageTooltip: false,
};

export const autoChargeSettings: PricingTermsSettings = {
	isStripeSelectDisplayed: true,
	isStripeSelectDisabled: false,
	isStripeCardsSelectDisplayed: true,
	isStripeMethodSelected: true,
	isStripeMethodDisabled: false,

	isBanksSelectDisplayed: false,
	isBanksSelectDisabled: false,
	isBankMethodSelected: false,
	isBankMethodDisabled: true,

	isAutoChargeToggleOn: true,
	isAutoChargeToggleDisabled: false,

	isCryptoSettingsDisabled: true,

	showAutoChargeOnTooltip: true,
	showDueUponUsageTooltip: false,
};

export const dueUponUsageSettings: PricingTermsSettings = {
	isStripeSelectDisplayed: true,
	isStripeCardsSelectDisplayed: true,
	isStripeSelectDisabled: true,
	isStripeMethodSelected: true,
	isStripeMethodDisabled: true,

	isBanksSelectDisabled: true,
	isBankMethodDisabled: true,
	isBanksSelectDisplayed: false,
	isBankMethodSelected: false,

	isAutoChargeToggleOn: true,
	isAutoChargeToggleDisabled: true,

	isCryptoSettingsDisabled: true,

	showAutoChargeOnTooltip: false,
	showDueUponUsageTooltip: true,
};

export const bankTransferWithStripeSettings: PricingTermsSettings = {
	isStripeSelectDisplayed: true,
	isStripeCardsSelectDisplayed: true,
	isStripeSelectDisabled: false,
	isStripeMethodSelected: true,
	isStripeMethodDisabled: false,

	isBanksSelectDisplayed: true,
	isBankMethodDisabled: false,
	isBanksSelectDisabled: false,
	isBankMethodSelected: true,

	isAutoChargeToggleOn: false,
	isAutoChargeToggleDisabled: false,

	isCryptoSettingsDisabled: false,

	showAutoChargeOnTooltip: false,
	showDueUponUsageTooltip: false,
};
