import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '@received/pricing-model';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Contract } from '../../../../../types/contractTypes';
import { getEmailsLabel } from '../../../../../utils/GeneralUtils';
import { arrowRight } from '../../../../../constants/unicodes';
import { getUniqueBanksCount, getUniqueSuppliersCount, isBankExist, isSupplierExist } from '../../ContractSideBar.utils';
import { BillingAccountDetails, Customer } from '../../../../../types/customerTypes';
import { statusByContractType } from '../../../../../utils/ContractUtils';
import { StatusCol } from '../../../../_uiComponents/Table/Table.utils';
import styles from './AgreementData.module.scss';

interface AgreementDataProps {
	agreementData?: Contract;
	suppliers?: Customer[];
	banks?: BillingAccountDetails[];
}

export const AgreementData = ({ agreementData, suppliers, banks }: AgreementDataProps) => {
	const { t } = useTranslation('translation');

	const contractSupplier = isSupplierExist(agreementData);
	const navigate = useNavigate();

	return (
		<div className={styles.container}>
			<div className={styles.flex}>
				<span className={styles.detailsText}>{t('partner')}</span>
				<span
					data-testid='agreement-side-bar-partner-name'
					className={clsx(styles.blackText, styles.ellipsis, styles.customerLink)}
					style={{ marginRight: '0.4rem' }}
					onClick={() => agreementData?.customer && navigate(`/partners/single-${agreementData?.contractType}/${agreementData?.customer.id}`)}
				>
					{agreementData?.customer?.name}
				</span>
				<span className={clsx(styles.greyText, styles.ellipsis)}>{` | ${getEmailsLabel(agreementData?.customer?.email)}`}</span>
			</div>

			<div>
				<span className={styles.detailsText}>{t('activation')}</span>
				<span data-testid='agreement-side-bar-activation-date' className={styles.blackText}>
					{`${dayjs(agreementData?.activationStartDate).format(DATE_FORMAT)} ${
						agreementData?.activationEndDate ? `${arrowRight} ` + dayjs(agreementData?.activationEndDate).format(DATE_FORMAT) : ''
					}`}
				</span>
			</div>

			{contractSupplier && (
				<div className={clsx(styles.detailsRow, styles.flex)}>
					<span className={styles.detailsText}>{t('entity')}</span>
					<span data-testid='agreement-side-bar-supplier' className={clsx(styles.blackText, styles.ellipsis)} style={{ marginRight: '0.4rem' }}>
						{`${suppliers?.find((supplier: Customer) => contractSupplier == supplier.id)?.name} ${
							getUniqueSuppliersCount(agreementData) - 1 > 0 ? `+${getUniqueSuppliersCount(agreementData) - 1}` : ''
						}`}
					</span>
					{isBankExist(agreementData) && (
						<span data-testid='agreement-side-bar-bank' className={clsx(styles.blackText, styles.ellipsis)}>{` | ${
							banks?.find((bank) => bank.id == isBankExist(agreementData))?.bank
						} ${getUniqueBanksCount(agreementData) - 1 > 0 ? `+${getUniqueBanksCount(agreementData) - 1}` : ''}`}</span>
					)}
				</div>
			)}

			<div>
				<span className={styles.detailsText}>{t('createAt')}</span>
				<span data-testid='agreement-side-bar-create-at' className={styles.blackText}>
					{agreementData?.updatedAt && dayjs(agreementData?.createdAt).format(DATE_FORMAT)}
				</span>
			</div>

			<div className={styles.detailsRowStatus}>
				<span className={styles.detailsText}>{t('status')}</span>
				{agreementData && <StatusCol color={statusByContractType(agreementData).color}>{statusByContractType(agreementData).text}</StatusCol>}
			</div>
		</div>
	);
};
