import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, EmptyStateCard, Table } from '../../../components';
import { sequenceRowOptions, tableHeader } from './Dunning.utils';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Sequence } from '../../../types/dunningTypes';
import { SequenceSideBar } from './SequenceSideBar/SequenceSideBar';
import styles from './Dunning.module.scss';

export const Dunning = () => {
	const [sequencesList, setSequencesList] = useState<Sequence[]>([]);
	const [isSequenceModelOpen, setIsSequenceModelOpen] = useState(false);
	const [selectedSequenceId, setSelectedSequenceId] = useState();
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		getSequences();
	}, []);

	const getSequences = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getSequences,
				})
			).data;
			setSequencesList(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<h2>{t('dunning')}</h2>
				<Button className={styles.addButton} onClick={() => setIsSequenceModelOpen(true)}>{`${t('newSequence')} +`}</Button>
			</div>

			<Table
				header={tableHeader}
				rows={sequencesList}
				className={styles.tableContainer}
				hideTableFooter
				emptyState={<EmptyStateCard showIcon containerStyle={styles.emptyState} title={t('noDataSource')}></EmptyStateCard>}
				onRowPress={(sequence) => {
					setSelectedSequenceId(sequence?.id);
					setIsSequenceModelOpen(true);
				}}
				optionsList={(sequence) => sequenceRowOptions(dispatch, t, sequence, getSequences)}
			/>

			<SequenceSideBar
				id={selectedSequenceId}
				isOpen={isSequenceModelOpen}
				isFirstSequence={sequencesList.length == 0}
				onClose={() => {
					setIsSequenceModelOpen(false);
					setSelectedSequenceId(undefined);
					getSequences();
				}}
			/>
		</div>
	);
};
