import React, { useEffect, useState } from 'react';
import { Button, Icon } from '../..';
import styles from './FlippingAddButton.module.scss';

export interface FlippingAddButtonProps {
	isPressed?: boolean;
	onClick(value?: any): void;
}

export const FlippingAddButton = ({ isPressed, onClick }: FlippingAddButtonProps) => {
	const [isClicked, setIsClicked] = useState(!!isPressed);

	useEffect(() => {
		setIsClicked(!!isPressed);
	}, [isPressed]);

	return (
		<Button
			className={styles.plusButton}
			onClick={() => {
				onClick();
				setIsClicked(!isClicked);
			}}
		>
			<Icon imgType='x_icon' color='white' height={1.2} className={isClicked ? styles.xIconClicked : styles.xIcon} />
		</Button>
	);
};
