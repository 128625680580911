import clsx from 'clsx';
import { Dispatch, SetStateAction } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Collapsible, CountriesSelect, FileUploader, Icon, Input, MultipleTagsInput, Switch, TextArea } from '../..';
import { DEFAULT_COUNTRY, DEFAULT_STATE, EMAIL_REGEX } from '../../../constants/generalConstants';
import { CustomerForm, tabIndexes } from '../CreateCustomer.utils';
import { isValidEmail } from '../../../utils/validationUtils';
import { Store } from '../../../types/storeTypes';
import { countryIsUSA } from '../../../utils/GeneralUtils';
import styles from '../CreateCustomer.module.scss';

export interface CustomerFormDetailsProps {
	customerDetails: CustomerForm;
	isPartner?: boolean;
	isShippingAsBillingAddress: boolean;
	updateForm(field: string, data?: string): void;
	setCustomerDetails: Dispatch<SetStateAction<CustomerForm>>;
	setIsShippingAsBillingAddress: Dispatch<SetStateAction<boolean>>;
}

export const CustomerFormDetails = ({
	customerDetails,
	isShippingAsBillingAddress,
	isPartner,
	updateForm,
	setCustomerDetails,
	setIsShippingAsBillingAddress,
}: CustomerFormDetailsProps) => {
	const { t } = useTranslation('translation');
	const { countries, usaStates } = useSelector((store: Store) => store.general.geoLocationData);

	const updatePhone = (field: string, data?: string) => {
		const phone = { ...customerDetails.phone, [field]: data ?? '', [`${field}Error`]: false };
		setCustomerDetails((prev) => ({ ...prev, phone }));
	};

	return (
		<Collapsible
			headerClassName={styles.collapsibleHeader}
			className={styles.collapsible}
			heightWhenOpen={customerDetails.email ? '90rem' : '87rem'}
			header={
				<div className={styles.headerContainer}>
					<div className={styles.iconWrapper}>
						<Icon imgType='office_building' className={styles.headerIcon} />
					</div>
					<span className={styles.title}>{t(isPartner ? 'partnerDetails' : 'customerDetails')}</span>
				</div>
			}
		>
			<div className={styles.wrapper} style={{ height: customerDetails.email ? '90rem' : '87rem' }}>
				<div className={styles.content}>
					<div className={styles.logoNameContainer}>
						<FileUploader
							tabIndex={tabIndexes.FILE}
							value={customerDetails.iconData}
							className={styles.logoWrapper}
							fileTypes={['PNG', 'JPG', 'JPEG']}
							uploadBase64File={(file) => updateForm('iconData', file)}
						/>

						<Input
							autoFocus
							tabIndex={tabIndexes.CUSTOMER_NAME}
							value={customerDetails.name}
							showError={customerDetails.nameError}
							title={t(isPartner ? 'partnerName' : 'customerName')}
							placeholder={t('companyName')}
							required
							titleStyle={styles.titleStyle}
							containerStyle={{ width: '100%' }}
							inputClassName={styles.lightGreyText}
							onChange={(value) => updateForm('name', value)}
						/>
					</div>

					<Input
						tabIndex={tabIndexes.LEGAL_NAME}
						value={customerDetails.legalName}
						showError={customerDetails.legalNameError}
						title={t('legalName')}
						placeholder={t('officialNameOnDoc')}
						required
						titleStyle={styles.titleStyle}
						inputClassName={styles.lightGreyText}
						onChange={(value) => updateForm('legalName', value)}
					/>
					<Input
						tabIndex={tabIndexes.EIN}
						value={customerDetails.ein}
						showError={customerDetails.einError}
						title={t('EINumber')}
						placeholder={`${t('eg')} 0000000000000`}
						titleStyle={styles.titleStyle}
						inputClassName={styles.lightGreyText}
						onChange={(value) => updateForm('ein', value)}
					/>
				</div>

				<MultipleTagsInput
					tabIndex={tabIndexes.EMAIL}
					value={customerDetails.email ?? ''}
					title={t('emailForSendingDocs')}
					placeholder={t('addAnEmail')}
					showError={customerDetails.emailError}
					type='email'
					required
					regex={EMAIL_REGEX}
					validateInput={isValidEmail}
					width={67}
					maxEmailCount={5}
					titleStyle={styles.titleStyle}
					inputClassName={styles.lightGreyText}
					tagsContainerClassName={styles.tagsContainerClassName}
					rightChildren={<Icon imgType='email' color='neutral300' width={1.5} className={styles.rightInputIcon} />}
					onChange={(value) => updateForm('email', value)}
				/>
				<CountriesSelect
					tabIndex={tabIndexes.COUNTRY}
					title={t('country')}
					width={67}
					defaultValue={customerDetails.country || DEFAULT_COUNTRY}
					data={countries}
					showError={customerDetails.countryError}
					headerLabelStyle={styles.titleStyle}
					required
					onChange={(value) => updateForm('country', value)}
				/>
				<div className={styles.inputsInRow}>
					<CountriesSelect
						tabIndex={tabIndexes.STATE}
						title={t('state')}
						placeholder={t('selectState')}
						containerStyle={!countryIsUSA(customerDetails.country) ? styles.hide : ''}
						width={11.4}
						data={usaStates}
						defaultValue={customerDetails.countryState || DEFAULT_STATE}
						showOnlyPrefix
						headerLabelStyle={styles.titleStyle}
						onChange={(value) => updateForm('countryState', value)}
					/>
					<Input
						tabIndex={tabIndexes.CITY}
						value={customerDetails.city}
						containerClass={styles.fullWidth}
						title={t('city')}
						placeholder={t('cityPlaceHolder')}
						titleStyle={styles.titleStyle}
						inputClassName={styles.lightGreyTeunxt}
						onChange={(value) => updateForm('city', value)}
					/>
				</div>
				<div className={styles.phoneContainer}>
					<span className={styles.titleStyle}>{t('phoneNumber')}</span>
					<div className={styles.phoneInputs}>
						<Input
							tabIndex={tabIndexes.PHONE_PREFIX}
							maxLength={4}
							value={`+${customerDetails?.phone?.countryCode ?? ''}`}
							showError={customerDetails.countryCodeError}
							onChange={(value) => updatePhone('countryCode', value.replace(/\D+/g, ''))}
							className={styles.phonePrefix}
							inputClassName={clsx(styles.textCenter, styles.lightGreyText)}
						/>
						<Input
							tabIndex={tabIndexes.PHONE_NUMBER}
							maxLength={20}
							className={styles.phone}
							value={customerDetails?.phone?.phone}
							showError={customerDetails.phoneError}
							placeholder='(234) 567 8910'
							inputClassName={styles.lightGreyText}
							containerClass={styles.fullWidth}
							onChange={(value) => updatePhone('phone', value.replace(/\D+/g, ''))}
						/>
					</div>
				</div>
				<TextArea
					tabIndex={tabIndexes.BILLING_ADDRESS}
					type='textarea'
					value={customerDetails.billingAddress}
					showError={customerDetails.billingAddressError}
					title={t('billingAddress')}
					className={styles.multiline}
					titleStyle={styles.titleStyle}
					placeholder={t('billingAddressPlaceholder')}
					onChange={(value) => updateForm('billingAddress', value)}
				/>

				<TextArea
					tabIndex={tabIndexes.SHIPPING_ADDRESS}
					value={isShippingAsBillingAddress ? customerDetails.billingAddress : customerDetails.shippingAddress}
					showError={customerDetails.shippingAddressError}
					title={t('shippingAddress')}
					placeholder={t('shippingAddressPlaceholder')}
					disabled={isShippingAsBillingAddress}
					className={styles.multiline}
					titleStyle={styles.titleStyle}
					onChange={(value) => updateForm('shippingAddress', value)}
					customTitle={
						<div className={styles.addressCheckBoxContainer}>
							<span className={styles.addressCheckboxText}>{t('sameAsBillingAddress')}</span>
							<Switch className={styles.addressToggle} defaultChecked={isShippingAsBillingAddress} onChange={setIsShippingAsBillingAddress} />
						</div>
					}
				/>
			</div>
		</Collapsible>
	);
};
