/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch } from 'redux';
import dayjs from 'dayjs';
import { AccountingEventType } from '@received/pricing-model';
import { NavigateFunction } from 'react-router-dom';
import { PathsConfig, httpService } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { downloadFile } from './GeneralUtils';
import { setShowHideToastNotification, setShowImportedDataIndicator } from '../storeSlices/generalSlice';
import { ToastNotificationComponentType } from '../components/NotificationCard/NotificationCard.utils';
import { Invoice, InvoiceView } from '../types/invoiceTypes';
import { CreditNoteStatusTag, InvoiceStatusTag, MenuOption, PayoutStatusTag } from '../components';
import { CreditNote, CreditNoteView } from '../types/creditNoteTypes';
import { IntegrationType } from '../types/integrationTypes';
import { colors } from '../constants/colors';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { StatusTagProps } from '../components/StatusTags/StatusTags.utils';

export const downloadDocumentPDFById = async (dispatch: Dispatch, id?: string, count?: number) => {
	if (!id) return;

	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.downloadDocumentById,
			urlParam: { generationId: id },
			responseType: 'blob',
		});
		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
				title: count && count > 1 ? 'filesBeingDownloaded' : 'fileBeingDownloaded',
				description: count && count > 1 ? 'filesBeingDownloadedDescription' : 'fileBeingDownloadedDescription',
				icon: 'downloadArrow',
				sideColor: colors.success,
			}),
		);

		downloadFile(res);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export interface DocumentMenuListProps {
	item: InvoiceView | Invoice | CreditNote | CreditNoteView;
	dispatch: Dispatch;
	navigate: NavigateFunction;
	t: any;
	availableIntegration?: IntegrationType[];
	editDocumentNumber?: MenuOption;
	onRefresh?: () => void;
	onPreview?: () => void;
}

export const sendMultipleDocuments = async (t: any, ids: string[], dispatch: Dispatch) => {
	try {
		httpService({
			dispatch,
			showLoader: false,
			path: PathsConfig.sendMultipleDocuments,
			data: { ids },
		});

		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				title: 'sendingDocuments',
				description: 'theActionTakeAWhile',
			}),
		);

		return dispatch(
			setShowImportedDataIndicator({
				totalItems: ids.length,
				importedItems: 0,
				barTitle: t('sent'),
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.SEND_DOCUMENT }));
	}
};

export const submitDocuments = async (date: Date, documents: { id: string; type: AccountingEventType }[], dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.submitDocument,
			data: { documents, submissionDate: dayjs(date).format(DATE_FORMAT_FOR_SERVER) },
		});
		dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.DOCUMENT_UPDATED }));
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_UPDATE_DOCUMENT }));
	}
};

export const reconcileDocuments = async (date: Date, documents: { id: string; type: AccountingEventType }[], dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.reconcileDocuments,
			data: { documents, reconciliationDate: dayjs(date).format(DATE_FORMAT_FOR_SERVER) },
		});
		dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.INVOICE_STATUS_UPDATED }));
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

interface GenericStatusTagProps extends StatusTagProps {
	document: any;
}

export const getStatusTagByDocumentType = (props: GenericStatusTagProps) => {
	switch (props?.document?.accountingEventType) {
		case AccountingEventType.DEBIT:
		case AccountingEventType.INVOICE:
			return <InvoiceStatusTag {...props} />;
		case AccountingEventType.CREDIT:
			return <CreditNoteStatusTag {...props} />;
		case AccountingEventType.PAYOUT:
			return <PayoutStatusTag {...props} />;

		default:
			return <InvoiceStatusTag {...props} />;
	}
};

export interface DocumentViewMenuListProps {
	t: any;
	onDownloadCSV: (props: any) => void; // each 'onDownloadCSV' function will have different props for different pages
}

export const documentsViewOptionsList = ({ t, onDownloadCSV }: DocumentViewMenuListProps): MenuOption[] => {
	return [
		{
			title: t('downloadCSV'),
			onPress: onDownloadCSV,
			iconType: 'download',
		},
	];
};
