import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Icon, IconImgType, IconProps } from '../_uiComponents';
import { priceNumberWithCurrencySignDisplay } from '../../utils/NumberUtils';
import { summaryType } from '../../types/invoiceTypes';
import { Store } from '../../types/storeTypes';
import styles from './SummaryBlock.module.scss';

export interface SummaryBlockStyles {
	className?: string;
	summaryIconClassName?: string;
}

export interface SummaryBlockProps {
	small?: boolean;
	type: summaryType;
	title: string;
	total?: number;
	subTitle?: string;
	iconColorCode?: string;
	icon?: IconImgType;
	style?: any;
	summaryBlockStyles?: SummaryBlockStyles;
	iconColor?: IconProps['color'];
	isSelected?: boolean;
	isPressable?: boolean;
	iconHeight?: number;
	onClick?(): void;
}
export const SummaryBlock = ({
	title,
	total,
	iconColor,
	small,
	subTitle,
	type,
	icon,
	style,
	summaryBlockStyles,
	isSelected,
	isPressable,
	iconHeight = 1.8,
	onClick,
}: SummaryBlockProps) => {
	const { appCurrency } = useSelector((store: Store) => store.general);

	return (
		<div
			className={clsx(
				styles.summaryBlockContainer,
				small && styles.smallSummaryBlockContainer,
				isPressable && styles.isPressable,
				isSelected && styles.isSelected,
				summaryBlockStyles?.className,
			)}
			style={style}
			onClick={onClick}
		>
			<div className={clsx(styles.summaryIconContainer, styles[type], summaryBlockStyles?.summaryIconClassName)} data-testid='summary-block'>
				<Icon imgType={(icon || type.toLowerCase()) as IconProps['imgType']} color={iconColor || 'neutral700'} height={iconHeight} />
			</div>

			<div>
				<span className={styles.title}>{title}</span>
				<span className={clsx(styles.total, small && styles.smallTotal)}>
					{priceNumberWithCurrencySignDisplay(total || 0, convertCurrencyToSign(appCurrency))}
				</span>
				<span className={styles.subTitle}>{subTitle}</span>
			</div>
		</div>
	);
};
