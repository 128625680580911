import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { DATE_FORMAT, DATE_FORMAT_FOR_SERVER } from '../../constants/templateConstants';
import { DatesData } from '../../types/generalTypes';

dayjs.extend(quarterOfYear);

export enum FilterTimeOptions {
	LAST_7_DAYS = 'LAST_7_DAYS',
	LAST_30_DAYS = 'LAST_30_DAYS',
	LAST_12_MONTHS = 'LAST_12_MONTHS',
	UPCOMING_12_MONTHS = 'UPCOMING_12_MONTHS',
	CURRENT_YEAR = 'CURRENT_YEAR',
	YEAR_TO_DATE = 'YEAR_TO_DATE',
	LAST_YEAR = 'LAST_YEAR',
	QUARTER_4 = 'QUARTER_4',
	QUARTER_3 = 'QUARTER_3',
	QUARTER_2 = 'QUARTER_2',
	QUARTER_1 = 'QUARTER_1',
	CUSTOM_DATES = 'CUSTOM_DATES',
}

export const filtersSelectLabels = {
	LAST_7_DAYS: 'Last 7 days',
	LAST_30_DAYS: 'Last 30 days',
	LAST_12_MONTHS: 'Last 12 months',
	UPCOMING_12_MONTHS: 'Next 12 months',
	CURRENT_YEAR: 'Current year',
	YEAR_TO_DATE: 'Year to date',
	LAST_YEAR: 'Last year',
	QUARTER_4: 'Quarter 4/',
	QUARTER_3: 'Quarter 3/',
	QUARTER_2: 'Quarter 2/',
	QUARTER_1: 'Quarter 1/',
	CUSTOM_DATES: 'Custom Dates',
};

export const fullFiltersSelectOptions = [
	{
		label: filtersSelectLabels.LAST_7_DAYS,
		value: FilterTimeOptions.LAST_7_DAYS,
	},
	{
		label: filtersSelectLabels.LAST_30_DAYS,
		value: FilterTimeOptions.LAST_30_DAYS,
	},
	{
		label: filtersSelectLabels.LAST_12_MONTHS,
		value: FilterTimeOptions.LAST_12_MONTHS,
	},
	{
		label: filtersSelectLabels.UPCOMING_12_MONTHS,
		value: FilterTimeOptions.UPCOMING_12_MONTHS,
	},
	{
		label: `${filtersSelectLabels.CURRENT_YEAR} (${dayjs().year()})`,
		value: FilterTimeOptions.CURRENT_YEAR,
	},
	{
		label: filtersSelectLabels.YEAR_TO_DATE,
		value: FilterTimeOptions.YEAR_TO_DATE,
	},
	{
		label: `${filtersSelectLabels.LAST_YEAR} (${dayjs().year() - 1})`,
		value: FilterTimeOptions.LAST_YEAR,
	},
	{
		label: `${filtersSelectLabels.QUARTER_4}${dayjs().year() - 1}`,
		value: FilterTimeOptions.QUARTER_4,
	},
	{
		label: `${filtersSelectLabels.QUARTER_3}${dayjs().year() - 1}`,
		value: FilterTimeOptions.QUARTER_3,
	},
	{
		label: `${filtersSelectLabels.QUARTER_2}${dayjs().year() - 1}`,
		value: FilterTimeOptions.QUARTER_2,
	},
	{
		label: `${filtersSelectLabels.QUARTER_1}${dayjs().year() - 1}`,
		value: FilterTimeOptions.QUARTER_1,
	},
	{
		label: filtersSelectLabels.CUSTOM_DATES,
		value: FilterTimeOptions.CUSTOM_DATES,
	},
];

export const handleChangeSelectTimePeriod = (value: string, callback: ({ from, to, title }: DatesData) => void, from?: Date, to?: Date) => {
	const datesData = getTimePeriodByFilterTimeOptions(value, from, to);
	return datesData && callback(datesData);
};

export const getTimePeriodByFilterTimeOptions = (value: string, from?: Date, to?: Date) => {
	switch (value) {
		case FilterTimeOptions.CUSTOM_DATES:
			return {
				from: dayjs(from).format(DATE_FORMAT_FOR_SERVER) ?? dayjs().format(DATE_FORMAT_FOR_SERVER),
				to: dayjs(to).format(DATE_FORMAT_FOR_SERVER) ?? dayjs().format(DATE_FORMAT_FOR_SERVER),
				title: `${dayjs(from).format(DATE_FORMAT)} - ${dayjs(to).format(DATE_FORMAT)}`,
			};
		case FilterTimeOptions.LAST_7_DAYS:
			return {
				from: dayjs().subtract(7, 'day').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().format(DATE_FORMAT_FOR_SERVER),
				title: filtersSelectLabels.LAST_7_DAYS,
				value: FilterTimeOptions.LAST_7_DAYS,
			};
		case FilterTimeOptions.YEAR_TO_DATE:
			return {
				from: dayjs().startOf('year').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().format(DATE_FORMAT_FOR_SERVER),
				title: filtersSelectLabels.YEAR_TO_DATE,
				value: FilterTimeOptions.YEAR_TO_DATE,
			};

		case FilterTimeOptions.LAST_30_DAYS:
			return {
				from: dayjs().subtract(30, 'day').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().format(DATE_FORMAT_FOR_SERVER),
				title: filtersSelectLabels.LAST_30_DAYS,
				value: FilterTimeOptions.LAST_30_DAYS,
			};
		case FilterTimeOptions.LAST_12_MONTHS:
			return {
				from: dayjs().startOf('month').subtract(11, 'month').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().endOf('month').format(DATE_FORMAT_FOR_SERVER),
				title: filtersSelectLabels.LAST_12_MONTHS,
				value: FilterTimeOptions.LAST_12_MONTHS,
			};
		case FilterTimeOptions.CURRENT_YEAR:
			return {
				from: dayjs().startOf('year').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().endOf('year').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.CURRENT_YEAR)?.label ?? '',
				value: FilterTimeOptions.CURRENT_YEAR,
			};
		case FilterTimeOptions.LAST_YEAR:
			return {
				from: dayjs().subtract(1, 'year').startOf('year').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().subtract(1, 'year').endOf('year').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.LAST_YEAR)?.label ?? '',
				value: FilterTimeOptions.LAST_YEAR,
			};
		case FilterTimeOptions.QUARTER_4:
			return {
				from: dayjs().subtract(1, 'year').quarter(4).startOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().subtract(1, 'year').quarter(4).endOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.QUARTER_4)?.label ?? '',
				value: FilterTimeOptions.QUARTER_4,
			};
		case FilterTimeOptions.QUARTER_3:
			return {
				from: dayjs().subtract(1, 'year').quarter(3).startOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().subtract(1, 'year').quarter(3).endOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.QUARTER_3)?.label ?? '',
				value: FilterTimeOptions.QUARTER_3,
			};
		case FilterTimeOptions.QUARTER_2:
			return {
				from: dayjs().subtract(1, 'year').quarter(2).startOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().subtract(1, 'year').quarter(2).endOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.QUARTER_2)?.label ?? '',
				value: FilterTimeOptions.QUARTER_2,
			};
		case FilterTimeOptions.QUARTER_1:
			return {
				from: dayjs().subtract(1, 'year').quarter(1).startOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().subtract(1, 'year').quarter(1).endOf('quarter').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.QUARTER_1)?.label ?? '',
				value: FilterTimeOptions.QUARTER_1,
			};
		case FilterTimeOptions.UPCOMING_12_MONTHS:
			return {
				from: dayjs().startOf('month').format(DATE_FORMAT_FOR_SERVER),
				to: dayjs().add(11, 'month').endOf('month').format(DATE_FORMAT_FOR_SERVER),
				title: fullFiltersSelectOptions.find((i) => i.value == FilterTimeOptions.UPCOMING_12_MONTHS)?.label ?? '',
				value: FilterTimeOptions.UPCOMING_12_MONTHS,
			};
	}
};

export const generalTimePeriodInitialState = {
	from: dayjs().startOf('month').subtract(11, 'month').format(DATE_FORMAT_FOR_SERVER),
	to: dayjs().endOf('month').format(DATE_FORMAT_FOR_SERVER),
	title: filtersSelectLabels.LAST_12_MONTHS,
	value: FilterTimeOptions.LAST_12_MONTHS,
};
