import clsx from 'clsx';
import { CSSProperties } from 'react';
import styles from './LoadingLine.module.scss';

interface ImportItemsProgressProps {
	totalNumber: number;
	currentNumber: number;
	style?: CSSProperties;
	className?: string;
}

export const LoadingLine = ({ totalNumber, currentNumber, className, style }: ImportItemsProgressProps) => {
	return (
		<div className={clsx(styles.indicatorContainer, className)} style={style}>
			<div
				className={styles.indicator}
				style={{
					width: `${(currentNumber * 100) / totalNumber}%`,
				}}
			></div>
		</div>
	);
};
