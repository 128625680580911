import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { IntegrationPlatform } from '@received/pricing-model';
import { Button, Icon, Select } from '../../../../../_uiComponents';
import { SelectItem } from '../../../../../../types/generalTypes';
import { IntegrationType } from '../../../../../../types/integrationTypes';
import styles from './NetSuiteListItem.module.scss';

interface NetSuiteListItemProps {
	item: IntegrationType;
	disabled?: boolean;
	subsidiaries: SelectItem[];
	onClick(): void;
	onDeleteItem(): void;
	onChangeSubsidiary(subsidiaryId: string): void;
}

export const NetSuiteListItem = ({ disabled, item, subsidiaries, onClick, onDeleteItem, onChangeSubsidiary }: NetSuiteListItemProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.itemContainer, !disabled && styles.clickableItemContainer)} onClick={() => !disabled && onClick()}>
			<div className={styles.flexCenter}>
				<div className={styles.logoContainer}>
					{item?.supplier?.iconData ? (
						<img alt='logo' src={item?.supplier?.iconData} className={styles.logo} />
					) : (
						<Icon imgType='companyFlag' color='neutral300' width={2.8} />
					)}
				</div>
				<div className={styles.description}>
					<h2 className={styles.name}>{item?.supplier?.name}</h2>
					{item?.supplier?.ein && (
						<div className={clsx(styles.neutralFontSize12, styles.fontWeight650)}>
							{t('EIN')} {item?.supplier?.ein}
						</div>
					)}
					<Select
						data={subsidiaries}
						width={''}
						defaultValue={
							item?.supplier?.syncStatus?.find(
								(platform) => platform?.platformName === IntegrationPlatform.NET_SUITE && platform?.direction == 'EXPORT',
							)?.metadata?.remoteObject?.id || ''
						}
						className={styles.select}
						placeholder={t('chooseSubsidiary')}
						inputClassName={styles.inputSelect}
						arrowIcon={{ width: 1 }}
						isSearchable
						disabled={item?.metadata?.isItemsMatched}
						onChange={(subsidiaryId) => onChangeSubsidiary(subsidiaryId)}
					/>
				</div>
			</div>

			<div className={styles.actions}>
				<Button
					onClick={(e) => {
						e.stopPropagation();
						onDeleteItem();
					}}
					type='outlined'
					color='neutral'
					className={clsx(styles.actionButton, styles.editable)}
				>
					<Icon imgType='delete' width={1.2} color='neutral700' />
				</Button>
				<Button
					onClick={(e) => {
						e.stopPropagation();
						onClick();
					}}
					type='outlined'
					color='neutral'
					className={styles.actionButton}
					disabled={disabled}
				>
					<Icon imgType='settings' width={1.2} color='neutral700' />
				</Button>
			</div>
		</div>
	);
};
