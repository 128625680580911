export type UpdatedDataRows = {
	id: string;
	rowIndex: number;
	value: string;
};

export enum PricingModelTabContentOptions {
	PRICING_MODEL = 'PRICING_MODEL',
	CONTRACT = 'CONTRACT',
}
