import { UsageAggregationFunction, UsageAggregationScope } from '@received/pricing-model';

export const aggregationByScope = [
	{
		label: 'Per Document',
		value: UsageAggregationScope.DOCUMENT,
	},
	{
		label: 'Per Contract',
		value: UsageAggregationScope.CONTRACT,
	},
	{
		label: 'Per Usage Report',
		value: UsageAggregationScope.USAGE_REPORT,
	},
];

export const aggregationMethods = [
	{
		label: 'SUM',
		value: UsageAggregationFunction.SUM,
	},
	// TODO return when fixed
	// {
	// 	label: 'AVG',
	// 	value: UsageAggregationFunction.AVG,
	// },
	// {
	// 	label: 'COUNT',
	// 	value: UsageAggregationFunction.COUNT,
	// },
	// {
	// 	label: 'MAX',
	// 	value: UsageAggregationFunction.MAX,
	// },
	// {
	// 	label: 'MIN',
	// 	value: UsageAggregationFunction.MIN,
	// },
];

export interface UsageProductAggregation {
	method: UsageAggregationFunction;
	scope: UsageAggregationScope;
}

export const initialAggregations: UsageProductAggregation = {
	method: aggregationMethods[0].value,
	scope: aggregationByScope[2].value,
};
