import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../constants/colors';
import { Button, CheckBox, DatePicker, DatePickerFormatType, Icon, Input } from '../_uiComponents';
import styles from './FilterSideBar.module.scss';

export enum FilterSideBarOptions {
	CUSTOMERS_PAGE = 'CUSTOMERS_PAGE',
	SINGLE_CUSTOMER_PAGE = 'SINGLE_CUSTOMER_PAGE',
	ALL_INVOICES_PAGE = 'ALL_INVOICES_PAGE',
}

interface PriceRangeProps {
	minPrice: string;
	maxPrice: string;
	updateMinPrice(val: string): void;
	updateMaxPrice(val: string): void;
}

interface DateRangeProps {
	fromDate: Date | null;
	toDate: Date | null;
	fromDateTitle: string;
	toDateTitle: string;
	generalTitle: string;
	updateFromDate(val: Date | null): void;
	updateToDate(val: Date | null): void;
}

interface CheckBoxListProps {
	optionsList: { title: any; key: any }[];
	selectedList: { title: any; key: any }[];
	updateSelected(list: { title: any; key: any }[]): void;
}

export const PriceRange = ({ minPrice, maxPrice, updateMinPrice, updateMaxPrice }: PriceRangeProps) => {
	const { t } = useTranslation('translation');
	const [openPicker, setOpenPicker] = useState(true);

	return (
		<div className={clsx(styles.section, openPicker ? styles.sectionOpen : styles.sectionClose)}>
			<Button type='link' color='neutral' onClick={() => setOpenPicker(!openPicker)} className={styles.sectionHeader}>
				<div className={styles.sectionHeaderTitle}>
					<Icon imgType='graph' color='neutral700' className={styles.sectionIcon} />
					{t('priceRange')}
				</div>
				<Icon imgType='arrow_up' color='neutral700' className={openPicker ? styles.arrow : styles.arrowClosed} />
			</Button>

			<div className={styles.inputContainer} style={{ opacity: openPicker ? 1 : 0 }}>
				<Input
					title={t('minPrice')}
					value={minPrice}
					onChange={updateMinPrice}
					containerStyle={{ width: '16.4rem', color: colors.neutral900 }}
					type='number'
				/>
				<span style={{ marginTop: '1.4rem' }}>-</span>
				<Input
					title={t('maxPrice')}
					value={maxPrice}
					onChange={updateMaxPrice}
					containerStyle={{ width: '16.4rem', color: colors.neutral900 }}
					type='number'
				/>
			</div>
		</div>
	);
};

export const DateRange = ({ generalTitle, fromDateTitle, toDateTitle, fromDate, toDate, updateFromDate, updateToDate }: DateRangeProps) => {
	const [openPicker, setOpenPicker] = useState(true);

	return (
		<div className={clsx(styles.section, openPicker ? styles.sectionOpen : styles.sectionClose)}>
			<Button type='link' color='neutral' onClick={() => setOpenPicker(!openPicker)} className={styles.sectionHeader}>
				<div className={styles.sectionHeaderTitle}>
					<Icon imgType='date' color='neutral700' className={styles.sectionIcon} />
					{generalTitle}
				</div>
				<Icon imgType='arrow_up' color='neutral700' className={openPicker ? styles.arrow : styles.arrowClosed} />
			</Button>

			<div className={styles.inputContainer} style={{ opacity: openPicker ? 1 : 0 }}>
				<div>
					<span className={styles.formTitle}>{fromDateTitle}</span>
					<DatePicker
						defaultFromDate={fromDate}
						updateDate={(date: DatePickerFormatType) => updateFromDate(date.fromDate)}
						className={styles.dateContainer}
						showError={toDate && !fromDate ? true : false}
						datePickerLabel={`${fromDateTitle} ${generalTitle}`}
					/>
				</div>
				-
				<div>
					<span className={styles.formTitle}>{toDateTitle}</span>
					<DatePicker
						defaultFromDate={toDate}
						updateDate={(date: DatePickerFormatType) => updateToDate(date.fromDate)}
						className={styles.dateContainer}
						showError={fromDate && !toDate ? true : false}
						datePickerLabel={`${toDateTitle} ${generalTitle}`}
					/>
				</div>
			</div>
		</div>
	);
};

export const CheckBoxList = ({ optionsList, selectedList, updateSelected }: CheckBoxListProps) => {
	const { t } = useTranslation('translation');
	const [openPicker, setOpenPicker] = useState(true);

	const onUpdateList = (isChecked: boolean, currentOption: { title: any; key: any }) => {
		const newList = isChecked ? [...selectedList, currentOption] : selectedList.filter((options) => options.key !== currentOption.key);

		updateSelected(newList);
	};

	return (
		<div
			className={clsx(styles.section, openPicker ? styles.sectionOpen : styles.sectionClose)}
			style={{ height: openPicker ? `${6 + optionsList.length * 4}rem` : '5.5rem ' }}
		>
			<Button type='link' color='neutral' onClick={() => setOpenPicker(!openPicker)} className={styles.sectionHeader}>
				<div className={styles.sectionHeaderTitle}>
					<Icon imgType='graph' color='neutral700' className={styles.sectionIcon} />
					{t('integrations')}
				</div>
				<Icon imgType='arrow_up' color='neutral700' className={openPicker ? styles.arrow : styles.arrowClosed} />
			</Button>

			<div className={styles.checkboxContainer} style={{ opacity: openPicker ? 1 : 0 }}>
				{optionsList.map((option, index) => (
					<div key={index} className={styles.checkboxItem}>
						<CheckBox isChecked={selectedList.includes(option)} setIsChecked={(val) => onUpdateList(val, option)} />
						<div>{option.title}</div>
					</div>
				))}
			</div>
		</div>
	);
};
