import clsx from 'clsx';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Icon } from '../Icon/Icon';
import styles from './CheckBox.module.scss';

export interface CheckBoxProps {
	className?: string;
	style?: CSSProperties;
	isChecked?: boolean;
	tabIndex?: number;
	setIsChecked(val: boolean): void;
}

export const CheckBox = ({ isChecked, tabIndex, style, className, setIsChecked }: CheckBoxProps) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setChecked(!!isChecked);
	}, [isChecked]);

	const onCheck = () => {
		setIsChecked(!checked);
		setChecked(!checked);
	};

	return (
		<div
			tabIndex={tabIndex}
			className={clsx(styles.checkBox, checked && styles.checkBoxActive, className)}
			onClick={onCheck}
			data-testid='checkBox'
			style={style}
		>
			{checked && <Icon imgType='v_icon' color='white' width={1.4} className={styles.checkBoxIcon} />}
		</div>
	);
};
