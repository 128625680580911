import { IntegrationPlatform, UserPermissions } from '@received/pricing-model';
import { AccountPermissions, AccountPermissionsActionsList, NodeEnvTypes } from '../../types/generalTypes';

export const permissionCheck = (actionName?: AccountPermissions, userPermissions?: UserPermissions[]) => {
	const environment = window._env_.REACT_APP_NODE_ENV;

	switch (actionName) {
		case AccountPermissionsActionsList.AUTO_CHARGE:
		case IntegrationPlatform.SALESFORCE:
		case IntegrationPlatform.XERO:
		case IntegrationPlatform.HUBSPOT:
			return environment !== NodeEnvTypes.PRODUCTION;
		case AccountPermissionsActionsList.VIEW_REV_REC:
			return userPermissions?.includes(UserPermissions.REV_REC);
		case IntegrationPlatform.QUICK_BOOKS_ONLINE_SANDBOX:
		case IntegrationPlatform.QUICK_BOOKS_ONLINE:
		case IntegrationPlatform.STRIPE:
		case IntegrationPlatform.NET_SUITE:
		case AccountPermissionsActionsList.DUNNING:
		case AccountPermissionsActionsList.DOCUMENT_SETTINGS:
			return true;
		default:
			return false;
	}
};
