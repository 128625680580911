import dayjs from 'dayjs';
import { SHORT_YEAR_FORMAT } from '@received/pricing-model';
import { Cell, LogoCell } from '../../../../components/_uiComponents/Table/Table.utils';
import { UsageReportView } from '../../../../types/usageTypes';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { numberWithCommas } from '../../../../utils/NumberUtils';
import { formatEmailList } from '../../../../utils/UserUtils';
import { getStatusTagByDocumentType } from '../../../../utils/DocumentsUtils';

export const usageReportsTableHeader = [
	{
		headerTitle: 'customer',
		accessor: 'customerName',
		CustomComponent: (item: UsageReportView) => (
			<LogoCell title={item.customerName} subTitle={formatEmailList(item.customerEmail)} logoData={item.customerIconData} />
		),
	},
	{
		headerTitle: 'documentID',
		accessor: 'documentNumber',
		CustomComponent: (item: UsageReportView) => <Cell accessor={getDocumentIdNameByType(item)} />,
	},
	{
		headerTitle: 'usageProduct',
		accessor: 'usageProductName',
	},
	{
		headerTitle: 'periodStartEnd',
		accessor: 'startDate',
		CustomComponent: (item: UsageReportView) => (
			<Cell
				accessor={`${dayjs(item.startDate).format(SHORT_YEAR_FORMAT)} - ${dayjs(item.endDate).format(SHORT_YEAR_FORMAT)}`}
				subAccessor={item.subActivity}
			/>
		),
	},
	{
		headerTitle: 'importedUsageQuantity',
		accessor: 'quantity',
		CustomComponent: (item: UsageReportView) => <Cell accessor={`${numberWithCommas(item.quantity)} ${item.countedUnit}`} />,
	},
	{
		headerTitle: 'currentStatus',
		accessor: 'invoiceState',
		CustomComponent: (document: UsageReportView) => getStatusTagByDocumentType({ document, disabled: true }),
	},
];
