import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../_uiComponents';
import { RoutesType } from '../../../types/generalTypes';
import styles from '../NavigationSideBar.module.scss';

interface NavigationSideBarProps {
	item: RoutesType;
	customLink?: React.ReactNode;
}

export const NavigationLink = ({ item, customLink }: NavigationSideBarProps) => {
	const { t } = useTranslation('translation');
	const [openSubLinks, setOpenSubLinks] = useState(true);

	const activeSubList = item?.subElements?.find((item) => location.pathname.includes(item.path));

	return (
		<div>
			{item?.subElements ? (
				<div
					data-testid={`side-bar-dropDown-${item.name}`}
					onClick={(e) => setOpenSubLinks(!openSubLinks)}
					className={clsx(styles.sidebarLinksContainer, activeSubList && styles.sidebarActiveLinks)}
				>
					<div className={clsx(styles.link, { [styles.linkActive]: activeSubList })}>
						{customLink ? (
							customLink
						) : (
							<div className={styles.flex}>
								{item.iconType && <Icon imgType={item.iconType} color='neutral600' className={styles.linkIcon} />}
								<span className={styles.linkText}>{t(item.name)}</span>
							</div>
						)}
					</div>
					<Icon imgType='arrow_down' color='neutral900' width={1} className={openSubLinks ? styles.arrowUp : styles.arrowDown} />
				</div>
			) : (
				<NavLink data-testid={`side-bar-link-${item.name}`} key={item.name} to={item.path} className={({ isActive }) => styles.navLink}>
					{({ isActive }) => (
						<div className={clsx(styles.sidebarLinksContainer, (isActive || activeSubList) && styles.sidebarActiveLinks)}>
							<div className={clsx(styles.link, { [styles.linkActive]: isActive })}>
								{customLink ? (
									customLink
								) : (
									<div className={styles.flex}>
										{item.iconType && <Icon imgType={item.iconType} color='neutral600' className={styles.linkIcon} />}
										<span className={styles.linkText}>{t(item.name)}</span>
									</div>
								)}
							</div>
						</div>
					)}
				</NavLink>
			)}

			{item?.subElements && (
				<div
					className={openSubLinks ? styles.openList : styles.closeList}
					style={{ height: openSubLinks ? `${item?.subElements.filter((item) => item.showOnSidebar).length * 4}rem` : 0 }}
				>
					{item?.subElements
						.filter((item) => item.showOnSidebar)
						.map((item) => (
							<NavLink
								data-testid={`side-bar-link-${item.name}${item?.dataTestId ? `-${item?.dataTestId}` : ''}`}
								key={item.name}
								to={item?.path}
								className={({ isActive }) => styles.navLink}
							>
								{({ isActive }) => (
									<div className={clsx(styles.subLink, { [styles.subLinkActive]: isActive })}>
										<span className={styles.linkText}>{t(item.name)}</span>
									</div>
								)}
							</NavLink>
						))}
				</div>
			)}
		</div>
	);
};
