import { IntegrationPlatform, IntegrationProvider } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { httpService, PathsConfig } from '..';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { setAvailableIntegration } from '../../storeSlices/IntegrationsSlice';

export const getAvailableIntegrations = async (dispatch: Dispatch) => {
	try {
		const availableIntegrations = await httpService({
			dispatch,
			path: PathsConfig.getIntegrationsCards,
		});

		dispatch(setAvailableIntegration(availableIntegrations.data));
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CONNECTIONS }));
	}
};

export const onConnectEntity = async (
	dispatch: Dispatch,
	integrationProvider: IntegrationProvider,
	supplierId: string,
	platform: IntegrationPlatform,
	integrationAccountId?: string,
	existing?: boolean,
) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.connectIntegrationWithEntity,
			urlParam: { integrationProvider },
			data: {
				supplierId,
				platform,
				integrationAccountId,
				existing,
			},
		});
		window.open(res.data.redirectUrl);
	} catch (error: any) {
		if (error.code === 'ERR_BAD_REQUEST' && error?.response?.data?.errorCode == 5005) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.PLATFORM_ALREADY_CONNECTED }));
		} else {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CONNECT_INTEGRATION }));
		}
	}
};

export const onDisconnectEntity = async (
	dispatch: Dispatch,
	integrationProvider: IntegrationProvider,
	supplierId: string,
	platform: IntegrationPlatform,
) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.disconnectIntegrationFromEntity,
			urlParam: { integrationProvider },
			data: {
				supplierId,
				platform,
			},
		});
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const onConnectStripe = async (
	dispatch: Dispatch,
	integrationProvider: IntegrationProvider,
	integrationAccountId?: string,
	existing?: boolean,
) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.connectIntegrationWithEntity,
			urlParam: { integrationProvider },
			data: {
				integrationAccountId,
				existing,
			},
		});
		window.open(res.data.redirectUrl);
	} catch (error: any) {
		if (error.code === 'ERR_BAD_REQUEST' && error?.response?.data?.errorCode == 5005) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.PLATFORM_ALREADY_CONNECTED }));
		} else {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CONNECT_INTEGRATION }));
		}
	}
};

export const onMissingDetailsEntity = async (dispatch: Dispatch, integrationProvider: IntegrationProvider, integrationAccountId: string) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.continueIntegrationWithEntity,
			urlParam: { integrationProvider },
			data: {
				integrationAccountId,
			},
		});
		window.open(res.data.redirectUrl);
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const onCancelIntegration = async (
	dispatch: Dispatch,
	integrationProvider: IntegrationProvider,
	supplierId: string,
	platform: IntegrationPlatform,
	integrationAccountId?: string,
) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.cancelIntegration,
			urlParam: { integrationProvider },
			data: {
				supplierId,
				platform,
				integrationAccountId,
			},
		});
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DISCONNECT_INTEGRATION }));
	}
};

export const onRemoveIntegration = async (dispatch: Dispatch, integrationProvider: IntegrationProvider, integrationAccountId?: string) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.removeIntegration,
			urlParam: { integrationProvider },
			data: {
				integrationAccountId,
			},
		});
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DISCONNECT_INTEGRATION }));
	}
};

export const getStripeConnectedCards = async (
	dispatch: Dispatch,
	integrationProvider: IntegrationProvider,
	integrationAccountId: string,
	filter?: string,
	startAfter?: string | null,
) => {
	try {
		return (
			await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getCustomersByIntegration,
				urlParam: { integrationProvider },
				params: { integrationAccountId, filter, startAfter },
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_STRIPE_CARDS_DATA }));
	}
};
