import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmButtonsFooter, MonthsFooter, YearsFooter } from './Footer.utils';
import { Button } from '../..';
import styles from './Footer.module.scss';

export enum FooterTypeOptions {
	ADD_YEARS = 'ADD_YEARS',
	ADD_MONTHS = 'ADD_MONTHS',
	SHOW_CONFIRM_BUTTONS = 'SHOW_CONFIRM_BUTTONS',
}
interface FooterProps {
	footerType?: FooterTypeOptions;
	fromDate: Date | null;
	onAdd(numberToAdd: number): void;
	onConfirm(): void;
	confirmFromFooter(): void;
	onCancel(): void;
}

export const Footer = ({ footerType, fromDate, onAdd, onConfirm, confirmFromFooter, onCancel }: FooterProps) => {
	const { t } = useTranslation('translation');

	const renderFooter = () => {
		switch (footerType) {
			case FooterTypeOptions.ADD_YEARS:
				return <YearsFooter onAddYear={onAdd} onConfirm={confirmFromFooter} />;
			case FooterTypeOptions.ADD_MONTHS:
				return <MonthsFooter onAddMonth={onAdd} onConfirm={confirmFromFooter} />;
			case FooterTypeOptions.SHOW_CONFIRM_BUTTONS:
				return <ConfirmButtonsFooter fromDate={fromDate} onConfirm={confirmFromFooter} onCancel={onCancel} />;
			default:
				return (
					<div className={styles.doneButtonContainer}>
						<Button className={styles.doneButton} onClick={onConfirm}>
							{t('done')}
						</Button>
					</div>
				);
		}
	};
	return <div>{renderFooter()}</div>;
};
