import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon';
import { Input } from '../../Input/Input';
import { MenuList, MenuOption } from '../../MenuList/MenuList';
import { NestedArrayItem } from '../NestedItem/NestedItem';
import styles from './NestedListItem.module.scss';

export interface NestedListItemProps {
	item: NestedArrayItem;
	selectedItem: boolean;
	count?: number;
	dataTestId?: string;
	onSelect(selectedItem: string): void;
}

export const NestedListItem = ({ item, selectedItem, count, dataTestId, onSelect }: NestedListItemProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [name, setName] = useState(item.name);
	const [editMode, setEditMode] = useState(false);

	const { t } = useTranslation('translation');

	const optionList: MenuOption[] = [
		{ title: t('rename'), onPress: () => onClickRename(), iconType: 'rename' },
		{ title: t('Duplicate'), onPress: () => item.onDuplicateProduct && item.onDuplicateProduct(item.id), iconType: 'duplicate' },
		{ title: t('delete'), onPress: () => item.onDeleteProduct && item.onDeleteProduct(item.id), iconType: 'delete', color: 'destructive' },
	];

	useEffect(() => {
		setName(item.name);
	}, [item.name]);

	const onClickRename = () => {
		onSelect(item.id);
		setEditMode(true);
	};

	const updateName = () => {
		setEditMode(false);
		name != item.name && item.onRenameProduct && item.onRenameProduct(name);
	};

	return (
		<div
			className={clsx(styles.listItem, selectedItem && styles.selectedItem)}
			onClick={() => onSelect(item.id)}
			onDoubleClick={() => {
				setEditMode(true);
				onSelect(item.id);
			}}
			data-testid={dataTestId || `nested-list-item-${item.name}`}
		>
			{editMode ? (
				<Input
					autoFocus
					value={name}
					onChange={setName}
					className={styles.nameInput}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							event.currentTarget.blur();
							updateName();
						}
					}}
					onBlur={updateName}
					onFocus={(e) => e.target.select()}
					data-testid={`nested-list-item-input-${item.name}`}
				/>
			) : (
				<span>{item.name}</span>
			)}

			{item.displayOptionMenu && (
				<MenuList optionsList={optionList} isMenuOpen={isOpen} openMenu={() => setIsOpen(false)} placement='bottom' className={styles.menuContainer}>
					<Button type='link' color='neutral' onClick={() => setIsOpen(true)}>
						<Icon imgType='hamburger' color='neutral700' className={clsx(styles.kebab, isOpen ? styles.isOpen : styles.isClosed)} />
					</Button>
				</MenuList>
			)}
			<div className={clsx(styles.count, !item.displayOptionMenu && styles.alwaysVisible)}>{count}</div>
		</div>
	);
};
