import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractState, ContractType } from '@received/pricing-model';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Icon, ImportItemsProgress, SearchBar, Sidebars } from '../../../components';
import { PathsConfig, httpService } from '../../../services';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { Store } from '../../../types/storeTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { useDebounce } from '../../../hooks';
import { enterprisesContractsHeader } from './EnterprisesContracts.utils';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { contractMenuOptions } from '../../../utils/ContractUtils';
import { ContractTabDataRes, ContractView } from '../../../types/contractTypes';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import { defaultNumberOfItems } from '../../../constants/generalConstants';
import styles from './EnterprisesContracts.module.scss';

export const EnterprisesContracts = () => {
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);

	const [contractList, setContractList] = useState<ContractView[]>([]);
	const [sort, setSort] = useState<Sort>({ orderBy: 'customerName', order: SortOrder.ASC });
	const [searchText, setSearchText] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [tableSmallRowsView, setTableSmallRowsView] = useState(false);
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [states, setStates] = useState<ContractState[]>([ContractState.ACTIVE]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		setTableSmallRowsView(+numberOfItems >= 50);
	}, [numberOfItems]);

	useEffect(() => {
		getContractsData();
	}, [numberOfItems, pageNumber, toastNotification.showToastNotification, sort, states]);

	useEffect(() => {
		onSearchContracts();
	}, [searchText]);

	const onSearchContracts = () => {
		getContractsData();
		setPageNumber(0);
	};

	const getContractsData = async (page?: number, items?: string) => {
		try {
			const res: ContractTabDataRes = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractsView,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						order: sort.orderBy ? sort.order : undefined,
						contractType: ContractType.ENTERPRISE,
						states: states.length ? states : undefined,
						textFilter: searchText || undefined,
					},
				})
			).data;

			setTotalCount(res?.count);
			setContractList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onRefreshData = () => {
		getContractsData();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
	};

	const onSearch = useDebounce(setSearchText, 650);

	const onPressNewContract = async () => {
		const contractId = await createContract(dispatch, ContractType.ENTERPRISE);
		contractId && navigate('../contract', { state: { contractId } });
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container}>
				<ImportItemsProgress onRefresh={() => onRefreshData()} />
				<Sidebars openSideBarById={openSideBarById} onCloseSideBars={() => setOpenSideBarById(undefined)} onDataChange={onRefreshData} />

				<div className={styles.header}>
					<header className={styles.customHeader}>
						<h1 data-testid='contracts-title'>{t('enterprisesContracts')}</h1>

						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.search} />
							<Button onClick={onPressNewContract} className={styles.assignButton}>
								{t('createContract')}
								<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
							</Button>
						</div>
					</header>
				</div>

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={enterprisesContractsHeader(tableSmallRowsView, t)}
					rows={contractList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					optionsList={(row) =>
						contractMenuOptions(row, t, dispatch, () => navigate('../contract', { state: { contractId: row?.id } }), onRefreshData)
					}
					tableRowClassName={tableSmallRowsView ? styles.extendedTableRowClassName : styles.tableRowClassName}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onRowPress={(data) =>
						setOpenSideBarById({
							sideBarType: SidebarType.CONTRACT,
							id: data.id,
						})
					}
					showSwitch
					defaultSwitchChecked={false}
					onToggleSwitch={(showDraft: boolean) => setStates(showDraft ? [] : [ContractState.ACTIVE])}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyStateProps={
						!searchText
							? {
									title: t('noContracts'),
									description: t('noContractsDescription'),
									buttonText: `${t('createContract')} +`,
									onClick: () => onPressNewContract(),
							  }
							: undefined
					}
				/>
			</div>
		</div>
	);
};
