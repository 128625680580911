import { CrmImportState, IntegrationCategory, IntegrationPlatform, IntegrationProvider, SyncObjectType } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { images } from '../../constants/images';
import { CompanyTabs, Sections } from '../../pages/Settings/Settings.utils';

export enum AccountingItemsExport {
	// TODO enable when page is ready
	Accounts,
	Items,
	Customers,
	// Invoices,
}

enum SalesforceImport {
	// TODO enable when page is ready
	// Accounts,
	PriceBooks,
	// Opportunities,
}

export const convertTypeToIcon = (integrationType: IntegrationPlatform) => {
	switch (integrationType) {
		case IntegrationPlatform.SALESFORCE:
			return images.salesforce;
		case IntegrationPlatform.NET_SUITE:
			return images.netsuite;
		case IntegrationPlatform.STRIPE:
			return images.stripePayment;
		case IntegrationPlatform.XERO:
			return images.xero;
		case IntegrationPlatform.QUICK_BOOKS_ONLINE:
		case IntegrationPlatform.QUICK_BOOKS_ONLINE_SANDBOX:
			return images.quickBooks;
		default:
			return images.wip;
	}
};

export const onSaveSalesforceImport = async (supplierId: string, data: { [index: number]: any }, dispatch: Dispatch, navigate: NavigateFunction) => {
	try {
		onDoneImportConfiguration(dispatch);
		return navigate('/settings', { state: { activeSection: Sections.COMPANY, activeButton: CompanyTabs.INTEGRATIONS } });
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const onAccountingExport = async (
	supplierId: string,
	data: { [index: number]: any },
	platform: IntegrationPlatform,
	dispatch: Dispatch,
	navigate: NavigateFunction,
	provider: IntegrationProvider,
	accountingDeletedItems: string[],
) => {
	accountingDeletedItems.length && deleteItems(accountingDeletedItems, dispatch);
	data?.[AccountingItemsExport.Customers] &&
		(await exportItems(platform, supplierId, data[AccountingItemsExport.Customers], dispatch, SyncObjectType.CUSTOMER, provider));
	data?.[AccountingItemsExport.Items] &&
		(await exportItems(platform, supplierId, data[AccountingItemsExport.Items], dispatch, SyncObjectType.ITEM, provider));
	data?.[AccountingItemsExport.Accounts] &&
		(await exportItems(platform, supplierId, data[AccountingItemsExport.Accounts], dispatch, SyncObjectType.BILLING_ACCOUNT, provider));
	return navigate('/settings', { state: { activeSection: Sections.COMPANY, activeButton: CompanyTabs.INTEGRATIONS } });
};

export const deleteItems = async (ids: string[], dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteAccountingIntegrationItems,
			data: { ids },
		});
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const exportItems = async (
	platform: IntegrationPlatform,
	supplierId: string,
	data: any,
	dispatch: Dispatch,
	syncObjectType: SyncObjectType,
	provider: IntegrationProvider,
) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.updateExportIntegrationItems,
			urlParam: { provider, category: IntegrationCategory.ACCOUNTING },
			data: { supplierId, platform, matchingData: data, dataType: syncObjectType },
		});
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const onDoneImportConfiguration = async (dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.doneImportConfiguration,
			urlParam: { state: CrmImportState.FINISHED_CONFIGURATION },
		});
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
