/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch } from '@reduxjs/toolkit';
import {
	AccountingEventType,
	convertCurrencyToSign,
	DEFAULT_CURRENCY,
	DocumentState,
	IntegrationCategory,
	IntegrationStatus,
	PricingCycleUnit,
} from '@received/pricing-model';
import { IconImgType, MenuOption, SummaryBlockProps } from '../components';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { InvoiceView, Invoice, summaryType, InvoiceSummary } from '../types/invoiceTypes';
import { UsageReportView } from '../types/usageTypes';
import { getValueWithCurrencySign, numberWithCommas, sumNumbersArray } from './NumberUtils';
import { setShowHideToastNotification } from '../storeSlices/generalSlice';
import { ToastNotificationComponentType } from '../components/NotificationCard/NotificationCard.utils';
import { colors } from '../constants/colors';
import { downloadDocumentUsageLogs } from '../pages/Usage/Usage.utils';
import { onDuplicateInvoice } from '../components/Sidebars/InvoicesSideBar/InvoicesSideBar.utils';
import { convertIntegrationTypeToStringService } from './IntegrationUtils';
import { DocumentMenuListProps } from './DocumentsUtils';

export const getSummaryBlocksData = (t: any, invoiceSummary?: InvoiceSummary): SummaryBlockProps[] => {
	return [
		{
			type: summaryType.WARNING,
			title: 'Review',
			total: (invoiceSummary?.missingDataSum || 0) + (invoiceSummary?.readyForSendingSum || 0),
			subTitle: `${numberWithCommas((invoiceSummary?.readyForSendingCount || 0) + (invoiceSummary?.readyForSendingCreditsCount || 0))} ${t(
				'ready',
			)} • ${(invoiceSummary?.missingDataCount || 0) + (invoiceSummary?.missingDataCreditsCount || 0) || '0'} ${t(
				'missingInfo',
			)}`.toLocaleLowerCase(),
			iconColor: 'warning',
			iconColorCode: colors.warning,
		},
		{
			type: summaryType.PRIMARY,
			title: 'pendingPayments',
			total: invoiceSummary?.pendingPaymentsSum,
			subTitle: `${numberWithCommas(invoiceSummary?.pendingPaymentsCount) || '0'} ${t('invoices')}`
				.concat(
					invoiceSummary?.pendingPaymentsFromLastWeekCount
						? ` • ${numberWithCommas(invoiceSummary?.pendingPaymentsFromLastWeekCount)} ${t('sentLastWeek')}`
						: '',
				)
				.toLocaleLowerCase(),
			iconColor: 'primary',
			iconColorCode: colors.primary,
		},
		{
			type: summaryType.DESTRUCT,
			title: 'latePayments',
			total: invoiceSummary?.latePaymentsSum,
			subTitle: `${numberWithCommas(invoiceSummary?.latePaymentsCount) || '0'} ${t('invoices')}`
				.concat(
					invoiceSummary?.latePaymentsLongerThan90DaysCount
						? ` • ${numberWithCommas(invoiceSummary?.latePaymentsLongerThan90DaysCount)} ${t('moreThan90DaysLate')}`
						: '',
				)
				.toLocaleLowerCase(),
			iconColor: 'destructive',
			iconColorCode: colors.destructive,
		},
		{
			type: summaryType.SUCCESS,
			title: 'paid',
			total: invoiceSummary?.paidPaymentsSum,
			subTitle: `${numberWithCommas(invoiceSummary?.paidPaymentsCount) || '0'} ${t('invoices')}`.concat(
				invoiceSummary?.paidCreditsCount ? ` • ${numberWithCommas(invoiceSummary?.paidCreditsCount)} ${t('creditnotes')}` : '',
			),
			iconColor: 'success',
			iconColorCode: colors.success,
		},
	];
};

export const startGenerateInvoicePDFById = async (dispatch: Dispatch, id?: string) => {
	if (!id) return;

	try {
		await httpService({
			dispatch,
			path: PathsConfig.generateInvoiceIdForDownload,
			urlParam: { id },
		});

		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
				title: 'PreparingFileForDownload',
				description: 'PreparingFileForDownloadDescription',
				icon: 'clock',
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const getSummaryData = async (
	dispatch: Dispatch,
	fromDate: Date | string,
	toDate: Date | string,
	customerIds?: string[],
	accountingEventTypes?: AccountingEventType[],
	searchText?: string,
) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getPaymentsSummary,
			params: { fromDate, toDate, customerIds: customerIds || undefined, accountingEventTypes: accountingEventTypes, searchText },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};

export const getInvoiceIdName = (invoice: Invoice | InvoiceView | UsageReportView) => {
	if ((invoice as Invoice | InvoiceView)?.documentNumber) {
		return `${(invoice as Invoice | InvoiceView)?.documentNumber}`;
	} else if ((invoice as UsageReportView)?.documentNumber) {
		return `${(invoice as UsageReportView)?.documentNumber}`;
		// @ts-ignore
	} else if (invoice?.customNumber) {
		// @ts-ignore
		return invoice?.customNumber;
	} else if (invoice?.draftNumber) {
		return `DRAFT-${invoice?.draftNumber}`;
	} else {
		return '';
	}
};

export const getTotalDue = (invoice?: InvoiceView) => {
	const creditUsed = sumNumbersArray(invoice?.invoiceCredits?.map((credit) => credit?.usedCredit) || []);
	return creditUsed && invoice?.total ? invoice?.total - creditUsed : invoice?.total || 0;
};

export const getCreditUsed = (invoice?: InvoiceView) => {
	const creditUsed = sumNumbersArray(invoice?.invoiceCredits?.map((credit) => credit?.usedCredit) || []);
	return creditUsed ? `${getValueWithCurrencySign(creditUsed, convertCurrencyToSign(invoice?.currency || DEFAULT_CURRENCY))} credits applied` : '';
};

export const isInvoiceEditable = (invoiceState?: DocumentState) =>
	invoiceState == DocumentState.DRAFT || invoiceState == DocumentState.REVIEW_MISSING_DATA || invoiceState == DocumentState.REVIEW_READY_TO_SEND;

export const canAddMoreUsageStates = (invoiceState?: DocumentState) =>
	invoiceState == DocumentState.DRAFT ||
	invoiceState == DocumentState.REVIEW_MISSING_DATA ||
	invoiceState == DocumentState.REVIEW_READY_TO_SEND ||
	invoiceState == DocumentState.PAYMENT_FAILED ||
	invoiceState == DocumentState.PAYMENT_FAILED_COLLECTING_INFO ||
	invoiceState == DocumentState.COLLECTING_INFO;

export const getSortingOrderList = (accessor: string) => {
	switch (accessor) {
		case 'issueDate':
			return ['issueDate', 'draftNumber'];

		default:
			return [accessor, 'issueDate'];
	}
};

export const syncInvoiceWithAccountingIntegration = async (dispatch: Dispatch, ids: string[], t: any, refresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.syncInvoiceWithAccountingIntegration,
			data: { ids },
		});
		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
				title: 'resyncStarted',
				description: 'theSyncMayTakeSomeTime',
			}),
		);

		refresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export enum PricingCycleSubset {
	CUSTOM = 'custom',
}

export type PricingCycleUnitsEnum = PricingCycleSubset | PricingCycleUnit;

export const invoiceMenuList = ({
	item,
	dispatch,
	navigate,
	t,
	availableIntegration,
	editDocumentNumber,
	onRefresh,
	onPreview,
}: DocumentMenuListProps) => {
	let listOptions: MenuOption[] = [];

	const linkedAccountingIntegrations = availableIntegration?.filter((item) => {
		const connectedCount = item?.connectedCount ? +item?.connectedCount : 0;
		return (item.connectionStatus === IntegrationStatus.LINKED || !!connectedCount) && item.category === IntegrationCategory.ACCOUNTING;
	});

	if (isInvoiceEditable(item?.state)) {
		listOptions = [
			...listOptions,
			{
				title: t('edit'),
				onPress: () => navigate('../invoices/single-invoice', { state: { InvoiceId: item?.id } }),
				iconType: 'edit',
			},
		];
	}

	if (onPreview && item.state !== DocumentState.CANCELED) {
		listOptions = [
			...listOptions,
			{
				title: t('preview'),
				onPress: () => onPreview(),
				iconType: 'eye',
			},
		];
	}

	listOptions = [
		...listOptions,
		{
			title: t('Duplicate'),
			onPress: () => onDuplicateInvoice(dispatch, item?.id, onRefresh),
			iconType: 'duplicate',
		},
		{
			title: t('downloadDocument'),
			onPress: () => startGenerateInvoicePDFById(dispatch, item?.id),
			iconType: 'download',
		},
	];

	if (editDocumentNumber) {
		listOptions = [...listOptions, editDocumentNumber];
	}

	if (item?.hasUsageReports) {
		listOptions = [
			...listOptions,
			{
				title: t('downloadUsageReports'),
				onPress: () => item?.hasUsageReports && downloadDocumentUsageLogs(item.id ? [item.id] : [], dispatch),
				iconType: 'download',
			},
		];
	}

	if (item.state !== DocumentState.CANCELED) {
		if (
			// @ts-ignore
			!item?.syncBlockers?.length.length
		) {
			listOptions = [
				...listOptions,
				{
					title: t('syncWith'),
					iconType: 'refresh',
					color: 'primary',
					subList: linkedAccountingIntegrations?.map((integration) => ({
						title: t(convertIntegrationTypeToStringService(integration.platform)),
						onPress: () => item.id && syncInvoiceWithAccountingIntegration(dispatch, [item.id], t, onRefresh),
						iconType: integration?.iconSrc as IconImgType,
					})),
				},
			];
		}
	}
	return listOptions;
};
