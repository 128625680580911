import { DateUtils, Currency, DATE_FORMAT, AccountingEventType, PricingType } from '@received/pricing-model';
import { plainToInstance } from 'class-transformer';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebounce } from '../../../../hooks';
import {
	Button,
	CustomerDetails,
	DatePicker,
	DatePickerFormatType,
	Icon,
	DocumentDetail,
	InvoiceStatusTag,
	MenuList,
	MenuOption,
	Tooltip,
	PDFPreviewModal,
	NetTermsSelect,
	Sidebars,
	ActionModal,
	GroupButton,
} from '../../../../components';
import { sendInvoice } from '../../../../components/InvoiceTable/InvoiceTable.utils';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { httpService } from '../../../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { setSidebarIsOpen } from '../../../../storeSlices/generalSlice';
import { PricingModel, PricingModelTab } from '../../../../types/contractTypes';
import { isInvoiceEditable, startGenerateInvoicePDFById } from '../../../../utils/InvoiceUtils';
import {
	DisabledActions,
	SideBarData,
	UnSavedChanges,
	UnSavedModalActions,
	initialUnSavedChangesState,
	invoiceTabs,
	isDueDateEditable,
	isTabEditable,
	setCollectiveTabsData,
} from './SingleInvoicePage.utils';
import { Invoice } from '../../../../types/invoiceTypes';
import { onUpdateUsageManualReportFromTable } from '../../../../utils/pricingModelUtils';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { getSupplierById, getSuppliers } from '../../../../utils/CustomerUtils';
import { Customer } from '../../../../types/customerTypes';
import { contractIdFormat } from '../../../../utils/ContractUtils';
import { images } from '../../../../constants/images';
import styles from './SingleInvoicePage.module.scss';

export const SingleInvoicePage = () => {
	const [invoice, setInvoice] = useState<Invoice>({ currency: Currency.USD, pricingModel: { tabs: [] } });
	const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [selectedProductTab, setSelectedProductTab] = useState(0);
	const [activeTab, setActiveTab] = useState(0);

	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [disableActions, setDisabledActions] = useState<DisabledActions>({
		disabledSend: false,
		disabledSaveAndClose: false,
		tooltipLabel: '',
	});
	const [viewOnlyMode, setViewOnlyMode] = useState(false);
	const [supplierEntities, setSupplierEntities] = useState<Customer[]>([]);
	const [openSideBarById, setOpenSideBarById] = useState<SideBarData>();
	const [newInvoiceToNavigate, setNewInvoiceToNavigate] = useState<string>();
	const [closeAllSideBars, setCloseAllSideBars] = useState(false);
	const [unSavedChanges, setUnSavedChanges] = useState<UnSavedChanges>(initialUnSavedChangesState);

	const { t } = useTranslation('translation');

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location: any = useLocation();

	const menuOptions: MenuOption[] = [
		{
			title: t('saveAndDownload'),
			onPress: () => saveAndDownload(),
			disabled: { isDisabled: disableActions.disabledSaveAndClose },
			iconType: 'download',
		},
	];

	const selectedUsagePeriod = {
		fromDate: invoice?.pricingModel?.tabs[selectedProductTab]?.billingEndDate || null,
		toDate: invoice?.pricingModel?.tabs[selectedProductTab]?.billingEndDate || null,
	};

	useEffect(() => {
		setAllSuppliers();
	}, []);

	useEffect(() => {
		location.state?.InvoiceId ? getInvoiceData(location.state.InvoiceId) : navigate(-1);
		dispatch(setSidebarIsOpen(false));
	}, [location.state?.InvoiceId]);

	useEffect(() => {
		let isMultipleCurrency = false;
		if (invoice?.pricingModel?.tabs.length) {
			const currency = invoice?.pricingModel?.tabs[0]?.currency;

			invoice?.pricingModel?.tabs.forEach((tab) => {
				if (tab.currency != currency) {
					isMultipleCurrency = true;
				}
			});
		}
		setDisabledActions({ disabledSend: isMultipleCurrency, disabledSaveAndClose: isMultipleCurrency, tooltipLabel: 'multipleCurrencyDocument' });
	}, [invoice?.pricingModel?.tabs[selectedProductTab]?.currency]);

	const getInvoiceData = async (id: string) => {
		try {
			const invoiceData: Invoice = (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoiceData,
					urlParam: { id },
				})
			).data;
			setInvoice({
				...invoiceData,
				pricingModel: {
					...invoiceData.pricingModel,
					tabs: invoiceData?.cryptoSettings
						? invoiceData.pricingModel.tabs.map((tab) => ({ ...tab, cryptoSettings: invoiceData?.cryptoSettings }))
						: invoiceData.pricingModel.tabs,
				},
			});
			setViewOnlyMode(!isInvoiceEditable(invoiceData?.state));
			setUnSavedChanges({ ...unSavedChanges, documentChanged: false, isSaveChangesModalOpen: false });
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.INVOICE_DETAILS }));
		}
	};

	const saveInvoice = async () => {
		try {
			await httpService({
				dispatch,
				path: invoice?.editBlockers?.length ? PathsConfig.updateLockedInvoice : PathsConfig.updateInvoice,
				data: plainToInstance(Invoice, invoice),
			});
			setUnSavedChanges({ ...unSavedChanges, documentChanged: false });
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.PAYOUT_UPDATE }));
		}
	};

	const setAllSuppliers = async () => {
		const suppliers = await getSuppliers(dispatch);
		setSupplierEntities(suppliers);
	};

	const setSupplier = async (newPricingModelTab: PricingModelTab, suppliers: Customer[]) => {
		let chosenSupplier;
		chosenSupplier = suppliers.find((supplier) => supplier.id === newPricingModelTab.supplierId);
		if (!chosenSupplier) {
			chosenSupplier = await getSupplierById(dispatch, newPricingModelTab?.supplierId);
		}

		return chosenSupplier;
	};

	const saveClose = async () => {
		if (unSavedChanges.documentChanged) {
			await saveInvoice();
		}
		navigate(-1);
	};

	const saveAndSend = async () => {
		await saveInvoice();
		if (invoice.id) {
			await sendInvoice(t, invoice.id, dispatch);
		}
		navigate(-1);
	};

	const onSend = async () => {
		if (unSavedChanges.documentChanged) {
			setUnSavedChanges({
				...unSavedChanges,
				isSaveChangesModalOpen: true,
				action: UnSavedModalActions.SAVE_AND_SEND,
				mainButtonAction: saveAndSend,
				secondaryButtonAction: () => setUnSavedChanges({ ...unSavedChanges, isSaveChangesModalOpen: false }),
			});
		} else {
			await saveAndSend();
		}
	};

	const saveAndDownload = async () => {
		if (isInvoiceEditable(invoice.state)) {
			await saveInvoice();
		}
		if (invoice.id) {
			await startGenerateInvoicePDFById(dispatch, invoice.id);
		}
	};

	const setInvoiceProductByIndex = async (newPricingModelTab: PricingModelTab, tabIndex: number, suppliers: Customer[]) => {
		if (!viewOnlyMode) {
			setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
			let chosenSupplier: Customer | undefined = undefined;
			if (invoice?.supplierId != newPricingModelTab?.supplierId) {
				chosenSupplier = await setSupplier(newPricingModelTab, suppliers);
			}
			// pricing model id should be reused from prev and not created in container
			setInvoice((prev) => {
				let newInvoice = { ...prev };
				newInvoice.pricingModel.tabs[tabIndex] = newPricingModelTab;
				newPricingModelTab.subTotal = Math.max(newPricingModelTab.pricingModelTableTotal, newPricingModelTab.minimumFee || 0);
				newPricingModelTab.total = Math.max(newPricingModelTab.subTotal, newPricingModelTab.minimumFee || 0);
				const collectiveTabsData = setCollectiveTabsData(newInvoice, newPricingModelTab) as Invoice;

				const dueDate =
					collectiveTabsData?.customer?.country && prev.issueDate
						? DateUtils.getDueDate(
								prev.issueDate,
								isNaN(+newPricingModelTab.netTerms) ? newPricingModelTab.netTerms : +newPricingModelTab.netTerms,
								collectiveTabsData?.customer?.country,
						  )
						: invoice.dueDate;

				newInvoice = { ...collectiveTabsData, issueDate: prev.issueDate, dueDate, supplier: chosenSupplier };

				return newInvoice;
			});
		}
	};

	const onUpdateManualReportFromTable = async () => {
		setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
		if (invoice?.id) {
			const resInvoiceData: Invoice = (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoiceData,
					urlParam: { id: invoice?.id },
				})
			).data;

			const newInvoice = onUpdateUsageManualReportFromTable(resInvoiceData, invoice) as Invoice;
			setInvoice((prev) => ({ ...prev, ...newInvoice }));
		}
	};

	const onUpdateIssueDate = async (date: DatePickerFormatType) => {
		setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
		const netTerms = invoice.pricingModel.tabs[selectedProductTab].netTerms;

		const dueDate =
			invoice?.customer?.country && date.fromDate
				? DateUtils.getDueDate(date.fromDate, isNaN(+netTerms) ? netTerms : +netTerms, invoice?.customer?.country)
				: invoice.dueDate;
		setInvoice((prev) => ({ ...prev, issueDate: date.fromDate || undefined, dueDate }));
	};

	const handleNavigationToDifferentInvoiceFromSideBar = (newInvoiceId?: string) => {
		setCloseAllSideBars(!closeAllSideBars);
		setUnSavedChanges({ documentChanged: false, isSaveChangesModalOpen: false });
		navigate('../invoices/single-invoice', {
			state: {
				InvoiceId: newInvoiceId || newInvoiceToNavigate,
			},
		});
	};

	const onBackArrowClick = () => {
		if (unSavedChanges.documentChanged) {
			setUnSavedChanges({
				...unSavedChanges,
				isSaveChangesModalOpen: true,
				action: UnSavedModalActions.GO_BACK,
				mainButtonAction: saveClose,
				secondaryButtonAction: () => navigate(-1),
			});
		} else {
			setUnSavedChanges(initialUnSavedChangesState);
			navigate(-1);
		}
	};

	const setInvoicePricingModel = (newPricingModel: PricingModel) => {
		if (isTabEditable(activeTab, viewOnlyMode)) {
			setInvoice((prev) => ({ ...prev, pricingModel: { ...prev.pricingModel, ...newPricingModel } }));
			return setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
		}
		setUnSavedChanges({ ...unSavedChanges, documentChanged: false });
	};

	const onUpdateInvoicePricingModel = useDebounce(setInvoiceProductByIndex, 350);

	return (
		<div className={styles.globalPageContainer}>
			<div className={clsx(styles.content, viewOnlyMode && styles.notAllowedCursor)}>
				<div className={clsx(styles.invoiceContainer, viewOnlyMode && styles.locked)}>
					<div className={styles.headerContainer}>
						<div className={styles.header}>
							<div className={styles.flex}>
								<Button
									type='outlined'
									color='neutral'
									onClick={() => onBackArrowClick()}
									className={clsx(styles.goBack, viewOnlyMode && styles.editable)}
								>
									<Icon imgType='arrow_back' color='neutral700' width={1.8} />
								</Button>

								<div className={styles.topContainer}>
									<CustomerDetails
										name={invoice.customer?.name}
										country={invoice.customer?.country}
										currency={invoice.customer?.currency}
										ein={invoice.customer?.ein}
										email={invoice.customer?.email}
										hideLogo
									/>
								</div>
							</div>

							<div className={clsx(styles.actions, styles.editable)}>
								{invoice?.pricingModel && (
									<Button type='outlined' color='neutral' onClick={() => setOpenInvoicePreview(!openInvoicePreview)} className={styles.eyeButton}>
										<Icon imgType={openInvoicePreview ? 'closed_eye' : 'eye'} color='neutral' height={2} />
									</Button>
								)}

								<div className={styles.actions}>
									<Tooltip tooltipComponent={disableActions.disabledSaveAndClose && t(disableActions.tooltipLabel)} placement='left' blackTooltip>
										<Button
											onClick={saveClose}
											type='outlined'
											color='neutral'
											className={styles.actionButton}
											disabled={disableActions.disabledSaveAndClose}
										>
											<span className={styles.buttonText}>{t(unSavedChanges.documentChanged ? 'saveClose' : 'close')}</span>
										</Button>
									</Tooltip>

									<Button onClick={onSend} color='success' className={styles.publishButton} disabled={disableActions.disabledSend}>
										{t('send')}
									</Button>

									<MenuList optionsList={menuOptions} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
										<Button type='outlined' color='neutral' onClick={() => setOpenMenuOptions(true)} className={styles.hamburgerButton}>
											<Icon imgType='hamburger' height={2} color='neutral700' />
										</Button>
									</MenuList>
								</div>
							</div>
						</div>

						<div className={styles.metadata}>
							<DocumentDetail
								title={t(invoice?.accountingEventType == AccountingEventType.INVOICE ? 'invoiceNo' : 'debitNumber')}
								body={getDocumentIdNameByType(invoice)}
								footer={<InvoiceStatusTag document={invoice} />}
							/>

							<DocumentDetail
								title={t('issueDate')}
								body={`${dayjs(invoice.issueDate).format(DATE_FORMAT)}`}
								footer={!!invoice?.isCollective && t('collective')}
								displayArrowIcon
								onClick={() => setOpenDatePicker(true)}
								flipArrow={openDatePicker}
							/>

							<DatePicker
								hideInput
								openDatePicker={openDatePicker}
								defaultFromDate={invoice.issueDate}
								updateDate={onUpdateIssueDate}
								updateIsOpen={() => setOpenDatePicker(false)}
							/>

							<DocumentDetail
								title={t('dueDate')}
								body={
									<NetTermsSelect
										isUsageProduct={invoice?.pricingModel?.tabs[selectedProductTab]?.pricingType == PricingType.USAGE}
										width={''}
										disabled={!isDueDateEditable(invoice)}
										defaultValue={invoice?.netTerms}
										onChange={(value) => {
											setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
											setInvoiceProductByIndex(
												{
													...invoice?.pricingModel?.tabs[selectedProductTab],
													netTerms: value,
												},
												selectedProductTab,
												supplierEntities,
											);
										}}
										className={styles.select}
									/>
								}
								footer={dayjs(invoice?.dueDate).format(DATE_FORMAT)}
							/>

							<DocumentDetail
								title={t('billingPeriod')}
								body={`${dayjs(invoice?.pricingModel?.tabs[selectedProductTab]?.billingStartDate).format(DATE_FORMAT)} - ${dayjs(
									invoice?.pricingModel?.tabs[selectedProductTab]?.billingEndDate,
								).format(DATE_FORMAT)}`}
								footer={contractIdFormat(invoice?.poNumber, invoice?.contractNumber)}
							/>
						</div>
					</div>

					<GroupButton
						buttonArray={invoiceTabs().map((tab) => ({ children: tab.title }))}
						buttonClassName={styles.tabComponent}
						className={styles.mainTabsContainer}
						selectedIndex={activeTab}
						setSelectedIndex={setActiveTab}
					/>

					<div className={styles.bottomContainer}>
						{invoiceTabs()[activeTab].component({
							invoice,
							isEditMode: isTabEditable(activeTab, viewOnlyMode),
							selectedCustomer: invoice.customer,
							selectedProductTab,
							disableActions,
							supplierEntities,
							pricingModel: invoice?.pricingModel,
							className: styles.settingClassName,
							setSelectedProductTab,
							onUpdateInvoicePricingModel: (data: PricingModelTab) => onUpdateInvoicePricingModel(data, selectedProductTab, supplierEntities),
							setOpenSideBarById,
							updateSettings: setInvoicePricingModel,
						})}
					</div>
					{invoice?.pricingModel.tabs && (
						<PDFPreviewModal
							isOpen={openInvoicePreview}
							documentsList={[invoice]}
							onClose={() => setOpenInvoicePreview(false)}
							onDownload={() => saveAndDownload()}
						/>
					)}
				</div>
			</div>

			<Sidebars
				selectedCustomer={invoice?.customer}
				selectedUsagePeriod={selectedUsagePeriod}
				disableDocumentSideBar
				openSideBarById={openSideBarById}
				onCloseSideBars={() => setOpenSideBarById(undefined)}
				closeAllSideBars={closeAllSideBars}
				navigateToDifferentSingleDocumentPage={{
					onClick: (id) => {
						setNewInvoiceToNavigate(id);
						if (unSavedChanges.documentChanged) {
							setUnSavedChanges({
								...unSavedChanges,
								isSaveChangesModalOpen: true,
								action: UnSavedModalActions.NAVIGATE_TO_DIFFERENT_DOCUMENT,
								mainButtonAction: async () => {
									await saveInvoice();
									handleNavigationToDifferentInvoiceFromSideBar(id);
								},
								secondaryButtonAction: () => handleNavigationToDifferentInvoiceFromSideBar(id),
							});
						} else {
							handleNavigationToDifferentInvoiceFromSideBar(id);
						}
					},
					originalDocumentId: invoice.id,
				}}
				onUpdateManualReport={onUpdateManualReportFromTable}
			/>

			<ActionModal
				isOpen={unSavedChanges.isSaveChangesModalOpen}
				title={t('unsavedChanges')}
				description={
					<>
						<div>{t('youMadeSomeChanges')}</div>
						<div>{t(unSavedChanges.action == UnSavedModalActions.SAVE_AND_SEND ? 'wouldYouLikeToSaveAndSend' : 'wouldYouLikeToSaveOrDiscard')}</div>
					</>
				}
				mainButton={{
					title: t('save'),
					onClick: async () => {
						unSavedChanges?.mainButtonAction?.();
					},
				}}
				secondaryButton={{
					title: t(unSavedChanges.action == UnSavedModalActions.SAVE_AND_SEND ? 'cancel' : 'discardChanges'),
					type: 'outlined',
					color: 'destructive',
					className: styles.actionModalSecondaryButton,
					onClick: () => {
						unSavedChanges?.secondaryButtonAction?.();
					},
				}}
				imgSrc={images.saveChanges}
				onClose={() => {
					setNewInvoiceToNavigate(undefined);
					setUnSavedChanges({ ...unSavedChanges, isSaveChangesModalOpen: false });
				}}
			/>
		</div>
	);
};
