import dayjs from 'dayjs';
import { AssignedSubscription } from '../../types/subscriptionTypes';

export const defaultSubscription = {
	customerId: '',
	billingStartDate: dayjs().toDate(),
	billingEndDate: null,
};

export const subscriptionValidation = (subscription: AssignedSubscription) => {
	const errors: string[] = [];
	if (!subscription.customerId) {
		errors.push('missingCustomer');
	}
	if (!subscription.subscriptionId) {
		errors.push('missingSubscription');
	}
	if (!subscription.billingStartDate) {
		errors.push('missingBillingStartDate');
	}
	if (!subscription.billingEndDate) {
		errors.push('missingBillingEndDate');
	}
	return errors;
};
