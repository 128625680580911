import React, { useEffect } from 'react';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { hotjar } from 'react-hotjar';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from './store';
import { AppRoutes } from './routes';
import strings from './defaultStrings.json';
import { NodeEnvTypes } from './types/generalTypes';
import styles from './App.module.scss';

i18next.use(initReactI18next).init({
	lng: 'en',
	fallbackLng: 'en',
	debug: false,
	resources: {
		en: { translation: strings },
	},
});

const App = () => {
	useEffect(() => {
		if (window._env_.REACT_APP_NODE_ENV === NodeEnvTypes.PRODUCTION) {
			hotjar.initialize(Number(window._env_.REACT_APP_HOTJAR_HJID), Number(window._env_.REACT_APP_HOTJAR_HJSV));
		}
	}, []);

	return (
		<div className={styles.app}>
			<Provider store={store}>
				<Router>
					<AppRoutes />
				</Router>
			</Provider>
		</div>
	);
};

export default App;
