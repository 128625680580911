import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PricingList, CategoriesTabs, Button, PricingTableCardView } from '../../../../components';
import { ProductAndCategoriesItem } from '../../../../types/ProductTypes';
import { httpService } from '../../../../services/httpService/httpService';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PricingModel } from '../../../../types/contractTypes';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { getTemplateColorAndIconById } from './AllTemplatesTab.utils';
import { filterCatalogPricingModels } from '../../PricingModels.utils';
import styles from './AllTemplatesTab.module.scss';

interface AllTemplatesTabProps {
	searchText: string;
	navigateToNewModel?: (data: any) => void;
}

export const AllTemplatesTab = ({ searchText, navigateToNewModel }: AllTemplatesTabProps) => {
	const { t } = useTranslation('translation');

	const [selectedPricingModel, setSelectedPricingModel] = useState<PricingModel>();
	const [selectedList, setSelectedList] = useState<PricingModel[]>([]);
	const [templateList, setTemplateList] = useState<ProductAndCategoriesItem[]>([]);
	const [allTemplateList, setAllTemplateList] = useState<PricingModel[]>([]);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		getTemplates();
	}, []);

	const getTemplates = async () => {
		try {
			const res: ProductAndCategoriesItem[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getCategoriesList,
					params: { includePricingModels: 'true' },
				})
			).data;

			let allModels: PricingModel[] = [];
			const templates = res.map((product) => {
				const models = product.pricingModels.map((model) => ({
					...model,
					iconType: getTemplateColorAndIconById(product.id).iconType,
					color: getTemplateColorAndIconById(product.id).color,
					categoryName: product.name,
				}));

				allModels = [...allModels, ...models];
				return {
					...product,
					iconType: getTemplateColorAndIconById(product.id).iconType,
					color: getTemplateColorAndIconById(product.id).color,
					count: product.pricingModels.length,
					pricingModels: [...models],
				};
			});
			setTemplateList(templates);
			setAllTemplateList(allModels);
			setSelectedList(allModels);
			if (allModels.length) {
				setSelectedPricingModel(allModels[0]);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CATEGORIES }));
		}
	};

	const onSelectTemplate = (selected: ProductAndCategoriesItem) => {
		const selectedList = selected ? selected.pricingModels : allTemplateList;
		setSelectedList(selectedList);
		setSelectedPricingModel(selectedList[0]);
	};

	const onUseThisPricing = () => {
		const modelData = { selectedPricingModel };
		navigateToNewModel
			? navigateToNewModel(modelData)
			: navigate('/pricing-models/new-pricing-model', {
					state: modelData,
			  });
	};

	return (
		<div className={styles.container}>
			<div className={styles.categoriesContainer}>
				<CategoriesTabs
					title={t('categories')}
					allTitle={t('allTemplates')}
					tabsList={templateList}
					onSelectTab={onSelectTemplate}
					allCount={allTemplateList.length}
				/>
			</div>

			<PricingList
				list={filterCatalogPricingModels('', selectedList, searchText)}
				selectedModel={selectedPricingModel}
				selectPricing={setSelectedPricingModel}
				className={styles.pricingListContainer}
			/>

			<div className={styles.tableContainer}>
				<PricingTableCardView
					pricingModel={selectedPricingModel}
					imgType={selectedPricingModel?.iconType || 'subscription_template'}
					tagText={selectedPricingModel?.categoryName}
				/>

				<div className={styles.buttonsContainer}>
					<Button onClick={onUseThisPricing}>{t('useThisPricing')}</Button>
				</div>
			</div>
		</div>
	);
};
