import { ErrorSuccessModalTypes } from '../../types/generalTypes';

export const modalTypeByStatusCodeHandler = (error: any) => {
	if (error) {
		if (error?.request?.status === 401 || error?.request?.status === 403) return ErrorSuccessModalTypes.AUTHORIZATION;
		if (error.massage === 'Network Error') return ErrorSuccessModalTypes.NO_INTERNET;

		return ErrorSuccessModalTypes.ERROR;
	}
	return ErrorSuccessModalTypes.SUCCESS;
};
