import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IntegrationCategory, IntegrationPlatform, IntegrationProvider, IntegrationStatus, SyncObjectType } from '@received/pricing-model';
import { Button, CloseButton, CreateSupplier, EmptyStateCard, Icon, MenuList, MenuOption, Modal } from '../../../..';
import { images } from '../../../../../constants/images';
import { PathsConfig, httpService } from '../../../../../services';
import { setOpenSuccessErrorModal } from '../../../../../storeSlices/errorSuccessSlice';
import { getSuppliers } from '../../../../../utils/CustomerUtils';
import { convertIntegrationTypeToDescription, convertIntegrationTypeToStringService } from '../../../../../utils/IntegrationUtils';
import { IntegrationType, Subsidiary } from '../../../../../types/integrationTypes';
import { Customer } from '../../../../../types/customerTypes';
import { NetSuiteListItem } from './NetSuiteListItem/NetSuiteListItem';
import styles from './NetSuiteConnectModal.module.scss';

export interface NetSuiteConnectModalProps {
	isOpen: boolean;
	platform: IntegrationPlatform;
	integrationProvider: IntegrationProvider;
	category: IntegrationCategory;
	image: string;
	closeModal(): void;
}

export const NetSuiteConnectModal = ({ isOpen, platform, image, category, integrationProvider, closeModal }: NetSuiteConnectModalProps) => {
	const [connections, setConnections] = useState<IntegrationType[]>([]);
	const [subsidiaries, setSubsidiaries] = useState<Subsidiary[]>();
	const [companies, setCompanies] = useState<MenuOption[]>([]);
	const [openEntityMenu, setOpenEntityMenu] = useState(false);
	const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			getSupplierCompanies();
			getSubsidiaries();
		}
	}, [isOpen]);

	useEffect(() => {
		subsidiaries && getConnectionsList();
	}, [subsidiaries]);

	const getSubsidiaries = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getIntegrationMatchingItems,
				urlParam: { provider: integrationProvider, category },
				params: {
					platform,
					dataType: SyncObjectType.ENTITY,
				},
			});

			setSubsidiaries(res.data?.remoteObjects);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const matchSubsidiaryToEntity = async (supplier: Customer, subsidiaryId: string) => {
		try {
			const matchingData = [
				{
					domainObject: {
						name: supplier?.name,
						originalObject: supplier,
					},
					remoteObject: subsidiaries?.find((subsidiary) => subsidiary?.originalObject?.id === subsidiaryId),
				},
			];

			await httpService({
				dispatch,
				path: PathsConfig.updateExportIntegrationItems,
				urlParam: { provider: integrationProvider, category },
				data: {
					platform,
					dataType: SyncObjectType.ENTITY,
					matchingData,
				},
			});

			getConnectionsList();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getConnectionsList = async () => {
		try {
			const res: IntegrationType[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getIntegrations,
					params: { platform },
				})
			).data;

			setConnections(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const addConnection = async (supplierId?: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.createEntityConnection,
				urlParam: { integrationProvider },
				data: { platform, category, supplierId },
			});

			getConnectionsList();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSupplierCompanies = async () => {
		const suppliers: Customer[] = await getSuppliers(dispatch);
		const supplierMenu = suppliers?.map((supplier, index) => ({
			title: supplier.name,
			onPress: () => supplier && addConnection(supplier?.id),
			hasBottomBorder: suppliers.length - 1 === index,
		}));

		setCompanies([...supplierMenu, { title: t('newEntity'), onPress: () => setShowCreateEntityModal(true), iconType: 'add', color: 'primary' }]);
	};

	const createSupplier = async (entityData: Customer) => {
		entityData && addConnection(entityData?.id);
		await getSupplierCompanies();
	};

	const deleteConnection = async (connectionId?: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.deleteIntegrationConnection,
				urlParam: { id: connectionId || '' },
			});

			getConnectionsList();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const updateMatchingIndication = async (connection: IntegrationType) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.updateIntegrationConnection,
				data: {
					...connection,
					metadata: {
						...connection.metadata,
						isItemsMatched: true,
					},
				},
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} closeModal={closeModal} className={styles.modalContainer}>
				<div className={styles.header}>
					<div className={styles.flexCenter}>
						<div className={styles.iconContainer}>
							<img alt='cardIcon' src={image} className={styles.cardIcon} />
						</div>
						<div>
							<h2 className={styles.fontWeight650}>{`${t(convertIntegrationTypeToStringService(platform))} ${t('configuration')}`}</h2>
							<div className={styles.cardDescription}>{t(convertIntegrationTypeToDescription(platform))}</div>
						</div>
					</div>

					<CloseButton onClick={closeModal} />
				</div>

				<div className={styles.content}>
					{connections.length ? (
						<>
							<div className={styles.contentHeader}>
								<div className={styles.fontWeight650}>{t('Entities')}</div>
								<MenuList optionsList={companies} placement='right' isMenuOpen={openEntityMenu} openMenu={() => setOpenEntityMenu(false)}>
									<Button type='link' onClick={() => setOpenEntityMenu(true)}>
										{t('addEntity')} <Icon imgType='add' color='primary' />
									</Button>
								</MenuList>
							</div>

							{connections.map((connection) => (
								<NetSuiteListItem
									key={connection.id}
									item={connection}
									subsidiaries={subsidiaries?.map((subsidiary) => ({ label: subsidiary.name, value: subsidiary?.originalObject?.id })) || []}
									onClick={() => {
										updateMatchingIndication(connection);
										navigate('../integrations', {
											state: {
												integrationType: platform,
												supplierId: connection?.supplier?.id,
												IntegrationCategory,
												integrationProvider,
												connectionStatus: connection?.connectionStatus,
												category,
												connection,
											},
										});
									}}
									disabled={
										!connection?.supplier?.syncStatus?.find(
											(platform) => platform?.platformName === IntegrationPlatform.NET_SUITE && platform?.direction == 'EXPORT',
										)?.metadata?.remoteObject?.id
									}
									onDeleteItem={() => deleteConnection(connection?.id)}
									onChangeSubsidiary={(subsidiaryId) => matchSubsidiaryToEntity(connection?.supplier, subsidiaryId)}
								/>
							))}
						</>
					) : (
						<div className={styles.emptyContainer}>
							<EmptyStateCard
								src={images.emptyIntegration}
								emptyIconClassName={styles.imgContainerStyle}
								title={t('connectEntitiesAccount').replace('__', platform?.replaceAll('_', ' ').toLowerCase())}
								subTitle={t('connectSubtitle')}
							>
								<MenuList optionsList={companies} isMenuOpen={openEntityMenu} openMenu={() => setOpenEntityMenu(false)}>
									<Button onClick={() => setOpenEntityMenu(true)} className={styles.emptyIntegrationButton}>
										{t('addEntity')} <Icon imgType='add' color='white' />
									</Button>
								</MenuList>
							</EmptyStateCard>
						</div>
					)}
				</div>

				{!!connections.length && (
					<div className={styles.footer}>
						<Button onClick={closeModal} className={styles.footerButton}>
							{t('done')}
						</Button>
					</div>
				)}
			</Modal>
			<CreateSupplier
				companiesListLength={companies?.length}
				isOpen={showCreateEntityModal}
				closeModal={() => setShowCreateEntityModal(false)}
				onCreateUpdateSupplier={createSupplier}
				overlayClassName={styles.newEntityModel}
			/>
		</>
	);
};
