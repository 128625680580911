import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { validate } from 'class-validator';
import { ContractState, ContractType } from '@received/pricing-model';
import { plainToInstance } from 'class-transformer';
import creatingContract from '../../../assets/lottie/creatingContract.json';
import { Button, Icon, Tooltip, ActionModal, PDFPreviewModal, CreateIndicator, GroupButton, CloseButton } from '../../../components';
import { GreatJobModal } from './GreatJobModal/GreatJobModal';
import { ReviewModal } from './ReviewModal/ReviewModal';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { Contract as ContractData, ContractValidation, PricingModel, PricingModelTab } from '../../../types/contractTypes';
import {
	ContractTabsName,
	contractTabs,
	createContractDocuments,
	deleteContract,
	formatValidationError,
	getContractError,
	getContractsDocuments,
	getCustomerSelectedById,
} from './Contract.utils';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { useDebounce } from '../../../hooks/generalHooks';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { images } from '../../../constants/images';
import { Customer } from '../../../types/customerTypes';
import { Sequence } from '../../../types/dunningTypes';
import { ContractLeftSection } from './ContractLeftSection/ContractLeftSection';
import { BillingDocumentType } from '../../../types/generalTypes';
import styles from './Contract.module.scss';

interface ContractProps {
	contractType: ContractType;
	mainUrlToReturn: string;
}

export const Contract = ({ contractType, mainUrlToReturn }: ContractProps) => {
	const [showGreatJobModal, setShowGreatJobModal] = useState(false);
	const [customerSelected, setCustomerSelected] = useState<Customer>();
	const [contractData, setContractData] = useState<ContractData>({
		pricingModel: { tabs: [], sourcePricingModelIds: [] },
	});
	const [contractDataFromServer, setContractDataFromServer] = useState<ContractData>({
		pricingModel: { tabs: [] },
	});

	const [showReview, setShowReview] = useState(false);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [isEditMode, setIsEditMode] = useState(false);
	const [showExitViewModeModal, setShowExitViewModeModal] = useState(false);
	const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);
	const [documentsList, setDocumentsList] = useState<Array<BillingDocumentType>>([]);
	const [activeTab, setActiveTab] = useState(0);
	const [showCreatingLoader, setShowCreatingLoader] = useState(false);
	const [productsSelectedTab, setProductsSelectedTab] = useState(0);

	const { t } = useTranslation('translation');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location: any = useLocation();
	const updateContractTimer = useRef<NodeJS.Timeout>(null);

	const isEditActiveContract = !!contractData?.editBlockers?.length && isEditMode;
	const isPartner = [ContractType.REFERRAL, ContractType.RESELLER].includes(contractType);
	const singleCustomerUrl = isPartner
		? `../partners/${contractType === ContractType.RESELLER ? 'single-reseller' : 'single-referral'}/`
		: '../customers/single-customer/';

	useEffect(() => {
		dispatch(setSidebarIsOpen(false));
		location?.state?.customer && setCustomerSelected(location.state.customer);
		location?.state?.contractId && getContractData(location?.state?.contractId);
	}, []);

	useEffect(() => {
		validateContract();
	}, [customerSelected, contractData.pricingModel]);

	useEffect(() => {
		!!contractData?.pricingModel?.tabs?.length && setInitialSequenceId();
	}, [contractData?.pricingModel?.tabs?.length]);

	let isMultipleCurrency = false;
	if (contractData?.pricingModel?.tabs?.length) {
		const currency = contractData.pricingModel?.tabs[0]?.currency;

		contractData?.pricingModel?.tabs.forEach((tab) => {
			if (tab.currency != currency) {
				isMultipleCurrency = true;
			}
		});
	}

	const setInitialSequenceId = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getSequences,
				})
			).data;
			setContractData((prev) => ({
				...prev,
				pricingModel: {
					...prev.pricingModel,
					dunningSequenceId: prev.pricingModel?.dunningSequenceId ?? res.find((seq: Sequence) => seq.isDefault)?.id,
					isDunningActive: prev.pricingModel?.isDunningActive ?? !!res.find((seq: Sequence) => seq.isDefault),
				},
			}));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getContractData = async (contractId: string) => {
		try {
			const newContract = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractData,
					urlParam: { id: contractId },
				})
			).data;
			let customer: Customer;
			if (!customerSelected) {
				customer = await getCustomerSelectedById(newContract.customerId, dispatch);
				setCustomerSelected(customer);
			}

			// copy contract state to tab so it can be checked down the component tree
			newContract.pricingModel?.tabs?.forEach((tab: PricingModelTab) => {
				tab.contractState = contractData.state;
			});
			setIsEditMode(newContract.state === ContractState.DRAFT);
			setContractData(newContract);
			setContractDataFromServer(newContract);
			setProductsSelectedTab(0);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const updateContract = async (newContractData: ContractData) => {
		try {
			if (!contractData?.editBlockers?.length) {
				const transformedContractData = plainToInstance(ContractData, newContractData);
				const updatedContract: ContractData = (
					await httpService({
						dispatch,
						showLoader: false,
						path: PathsConfig.updateContract,
						data: transformedContractData,
					})
				).data;
				setContractDataFromServer(updatedContract);

				setContractData((prev) => {
					// need to get id's from server because the server generate new id's for each pricingModel
					prev.activationEndDate = updatedContract?.activationEndDate;
					prev.activationStartDate = updatedContract?.activationStartDate;

					// need to check for   * prev.pricingModel *   here because it crashes on empty state
					if (updatedContract?.pricingModel && prev.pricingModel) {
						prev.pricingModel.id = updatedContract?.pricingModel?.id;
						updatedContract?.pricingModel?.tabs.forEach((item, index) => {
							if (index < prev.pricingModel.tabs.length) {
								// Don't destruct here the item or the tab it cause loop reaction and the page refreshes
								prev.pricingModel.tabs[index].id = item.id;
								prev.pricingModel.tabs[index].index = item.index;
								prev.pricingModel.tabs[index].pricingModelTable = item.pricingModelTable;
							}
						});
					}
					return { ...prev };
				});
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const validateContract = async () => {
		const instance = plainToInstance(ContractValidation, { customer: customerSelected, contract: contractData });
		const errors = await validate(instance);
		setValidationErrors(formatValidationError(errors, t, isMultipleCurrency));
	};

	const setContractPricingModel = (newPricingModel: PricingModel, instantUpdate?: boolean) => {
		// pricing model id should be reused from prev and not created in container
		setContractData((prev) => {
			const newContract = { ...prev, ...{ pricingModel: { ...prev.pricingModel, ...newPricingModel } } };
			if (instantUpdate) {
				updateContractTimer.current && clearInterval(updateContractTimer.current);
				updateContract(newContract);
			} else {
				onUpdateServer(newContract);
			}
			return newContract;
		});
	};

	const setContractMetadata = (field: string, value?: any) => {
		setContractData((prev) => {
			const newContract = { ...prev, [field]: value };
			onUpdateServer(newContract);
			return newContract;
		});
	};

	const onDoneEditingContract = async (contractData: ContractData) => {
		try {
			setShowCreatingLoader(true);
			updateContractTimer.current && clearInterval(updateContractTimer.current);
			const transformedContractData = plainToInstance(ContractData, contractData);
			await httpService({
				dispatch,
				showLoader: false,
				path: PathsConfig.publishContract,
				data: transformedContractData,
			});

			setShowCreatingLoader(false);
			setShowGreatJobModal(true);
		} catch (error) {
			setShowCreatingLoader(false);
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onOpenPDFPreview = async () => {
		try {
			if (contractData?.id) {
				updateContractTimer.current && clearInterval(updateContractTimer.current);
				let documentData;
				if (!isEditMode) {
					documentData = await getContractsDocuments(dispatch, contractData);
				} else {
					await updateContract(contractData);
					documentData = await createContractDocuments(dispatch, contractData.id);
				}
				documentData && setDocumentsList(documentData);
			}
			setIsPDFViewerOpen(true);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const errorList =
		validationErrors.length > 1
			? validationErrors.map((error, index) => <li key={index}>{getContractError(error)}</li>)
			: getContractError(validationErrors[0]);

	const onSaveAndClose = async () => {
		try {
			updateContractTimer.current && clearInterval(updateContractTimer.current);
			await updateContract(contractData);
			navigate(`${singleCustomerUrl}${customerSelected?.id}`, { state: { contractId: contractData.id } });
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_DATA }));
		}
	};

	const onClose = async () => {
		if (!isEditMode) {
			navigate(`${singleCustomerUrl}${customerSelected?.id}`, { state: { contractId: contractData.id } });
		} else if (customerSelected && contractData.pricingModel?.tabs?.length > 0) {
			onSaveAndClose();
		} else {
			onDelete();
		}
	};

	const onDelete = async () => {
		updateContractTimer.current && clearInterval(updateContractTimer.current);
		contractData.id
			? await deleteContract(contractData.id, dispatch, handleDeleteContract)
			: navigate(customerSelected?.id ? `${singleCustomerUrl}${customerSelected?.id}` : mainUrlToReturn);
	};

	const onPublish = async () => {
		// todo - use API to get Contract Summary instead of calling this 2 API
		updateContractTimer.current && clearInterval(updateContractTimer.current);
		await updateContract(contractData);
		await createContractDocuments(dispatch, contractData.id);
		contractData.id && (await getContractData(contractData.id));
		setShowReview(true);
	};

	const onPublishActiveContact = async () => {
		try {
			setShowCreatingLoader(true);
			updateContractTimer.current && clearInterval(updateContractTimer.current);
			const transformedContractData = plainToInstance(ContractData, contractData);
			transformedContractData.includeCurrentInvoice = true;
			await httpService({
				dispatch,
				showLoader: false,
				path: PathsConfig.editActiveContract,
				data: transformedContractData,
			});

			setShowCreatingLoader(false);
			setShowGreatJobModal(true);
		} catch (error) {
			setShowCreatingLoader(false);
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const handleDeleteContract = async () => {
		setContractData((prev) => ({ ...prev, id: undefined }));
		customerSelected?.id ? navigate(`${singleCustomerUrl}${customerSelected?.id}`) : navigate(mainUrlToReturn);
	};

	const onUpdateCustomer = async (customer: Customer) => {
		setCustomerSelected(customer);
		const newContract = { ...contractData, customerId: customer ? customer.id : null, customer: customer || null };
		onUpdateServer(newContract);
		setContractData(newContract);
	};

	const onChangeTab = async (newTab: number) => {
		// All this actions relevant only to regular create/edit and not for contract with blockers
		if (newTab == Object.values(ContractTabsName).indexOf(ContractTabsName.OVERVIEW) && isEditMode && !contractData?.editBlockers?.length) {
			updateContractTimer.current && clearInterval(updateContractTimer.current);
			await updateContract(contractData);
			!validationErrors.length && (await createContractDocuments(dispatch, contractData.id));
			!validationErrors.length && contractData.id && (await getContractData(contractData.id));
		}
		setActiveTab(newTab);
	};

	const onExitViewMode = async () => {
		setShowExitViewModeModal(false);
		setIsEditMode(true);

		if (!contractData?.editBlockers?.length) {
			updateContractTimer.current && clearInterval(updateContractTimer.current);
			await updateContract(contractData);
			contractData.id && (await getContractData(contractData.id));
		}
	};

	const isPreviewEnabled = () => {
		if (!isEditMode) return true;
		return !validationErrors.length;
	};

	const onUpdateServer = useDebounce(isEditMode ? updateContract : () => {}, 5000, updateContractTimer);

	return (
		<div className={styles.globalPageContainer}>
			<CreateIndicator isShown={showCreatingLoader} lottie={creatingContract} />

			<div className={styles.container}>
				<ContractLeftSection
					contractData={contractData}
					isPartner={isPartner}
					isEditMode={isEditMode}
					customerSelected={customerSelected}
					setContractMetadata={setContractMetadata}
					onUpdateCustomer={onUpdateCustomer}
				/>

				<div className={styles.rightSection}>
					<div className={styles.headerButtons}>
						<GroupButton
							buttonArray={contractTabs(contractType).map((tab) => ({
								children: tab.title,
								disabled: {
									tooltipComponent:
										(tab.title == ContractTabsName.OVERVIEW && t('cantWatchOverview')) ||
										(tab.title == ContractTabsName.SETTINGS && t('missingProducts')),
									buttonDisabled:
										(tab.title == ContractTabsName.OVERVIEW && isEditActiveContract) ||
										(tab.title == ContractTabsName.SETTINGS && !contractData?.pricingModel),
									tooltipPlacement: 'bottom',
									tooltipClassName: styles.tooltipClassName,
								},
							}))}
							buttonClassName={styles.tabComponent}
							className={contractTabs(contractType).length === 1 ? styles.disabled : undefined}
							selectedIndex={activeTab}
							setSelectedIndex={onChangeTab}
						/>

						<div className={styles.actionButtons}>
							{isEditActiveContract ? (
								<Button
									type='outlined'
									color='neutral'
									onClick={async () => {
										contractData?.id && (await getContractData(contractData?.id));
										setIsEditMode(true);
									}}
								>
									{t('discardChanges')}
								</Button>
							) : (
								<Tooltip tooltipComponent={<ul>{errorList}</ul>} disabled={!validationErrors.length} placement='left'>
									<Button
										type='outlined'
										color='neutral'
										onClick={onOpenPDFPreview}
										className={clsx(isPreviewEnabled() && styles.editable)}
										disabled={!isPreviewEnabled()}
									>
										{t('timeline')}
									</Button>
								</Tooltip>
							)}

							{contractData?.state == ContractState.DRAFT ? (
								<Tooltip tooltipComponent={<ul>{errorList}</ul>} disabled={!validationErrors.length} placement='left'>
									<Button color='success' disabled={!!validationErrors.length} onClick={onPublish}>
										{t('publish')}
									</Button>
								</Tooltip>
							) : isEditActiveContract ? (
								<Button color='success' disabled={!!validationErrors.length} onClick={() => onPublishActiveContact()}>
									{t('update')}
								</Button>
							) : (
								<Button color='success' type='primary' onClick={() => setShowExitViewModeModal(true)}>
									{t('edit')}
									<Icon imgType='edit' color='white' />
								</Button>
							)}

							<CloseButton onClick={onClose} />
						</div>
					</div>

					<div className={styles.bottomContainer}>
						{contractTabs(contractType)[activeTab].component({
							selectedCustomer: customerSelected,
							pricingModel: contractData?.pricingModel,
							setPricingModel: setContractPricingModel,
							isEditMode,
							contractState: contractData?.state,
							contract: contractDataFromServer,
							isValid: validationErrors.length === 0,
							defaultSelectedTab: productsSelectedTab,
							contractType,
							isPartner,
							setProductsSelectedTab,
							updateSettings: setContractPricingModel,
						})}
					</div>
				</div>
			</div>

			{/* Modals */}
			{documentsList && (
				<PDFPreviewModal
					isOpen={isPDFViewerOpen}
					isContractTimelineOpen
					documentsList={documentsList}
					activationStartDate={documentsList[0]?.issueDate}
					activationEndDate={documentsList[documentsList.length - 1]?.issueDate}
					onClose={() => setIsPDFViewerOpen(false)}
				/>
			)}
			<GreatJobModal
				contractType={contractType}
				showModal={showGreatJobModal}
				customer={customerSelected}
				contractId={contractData?.id}
				closeModal={() => setShowGreatJobModal(false)}
			/>
			<ReviewModal
				showModal={showReview}
				closeModal={() => setShowReview(false)}
				onPublish={() => onDoneEditingContract(contractData)}
				contractData={contractDataFromServer}
				customer={customerSelected}
			/>

			<ActionModal
				isOpen={showExitViewModeModal}
				title={t('openingViewMode')}
				description={t('youAreExitingViewMode')}
				mainButton={{ title: t('yes'), onClick: onExitViewMode }}
				secondaryButton={{ title: t('cancel'), type: 'outlined', color: 'neutral', onClick: () => setShowExitViewModeModal(false) }}
				imgSrc={images.exitViewMode}
				titleClassName={styles.viewModeModalTitle}
				onClose={() => setShowExitViewModeModal(false)}
			/>
		</div>
	);
};
