import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContractType, convertCurrencyToSign } from '@received/pricing-model';
import {
	StackBarGraph,
	StackBarDataset,
	SearchBar,
	DateRangeFilters,
	CallToActionMenu,
	ImportItemsProgress,
	AnalyticsList,
	forecastDataHandler,
	AnalyticsListData,
	ScrollingTable,
	Button,
	Icon,
	MenuList,
} from '../../components';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig, httpService } from '../../services';
import { setSidebarIsOpen } from '../../storeSlices/generalSlice';
import { Store } from '../../types/storeTypes';
import {
	accountsReceivableAgingHeader,
	forecastFiltersSelectOptions,
	getCashflowData,
	getForecastData,
	getTopLateCustomers,
	initialGraphState,
	getCustomerLocation,
	defaultUpForRenewalPeriod,
	upForRenewalPeriodItems,
	forecastTimePeriodInitialState,
	collectionStatusTimePeriodInitialState,
} from './Dashboard.utils';
import { formatUserName } from '../../utils/UserUtils';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { DatesData, GraphState, SelectItem, SortOrder, SyncedContractsData, UpForRenewalData } from '../../types/generalTypes';
import { cashTimeUnitHandler } from '../../utils/DateUtils';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../components/Filters/DateRangeFilters.utils';
import { collectionStatusDataHandler } from '../../utils/GraphsUtils';
import { Cell } from '../../components/_uiComponents/Table/Table.utils';
import { priceNumberWithCurrencySignDisplay } from '../../utils/NumberUtils';
import { AnalyticsSummary } from './AnalyticsSummary/AnalyticsSummary';
import { EmptyIntegrations } from './EmptyIntegrations/EmptyIntegrations';
import { summaryType } from '../../types/invoiceTypes';
import { contractIdFormat } from '../../utils/ContractUtils';
import { createContract } from '../Contracts/ContractComponent/Contract.utils';
import { ARAgingResponse, onDownloadARAgingCSV } from './ArAgingPage/ArAging.utils';
import { documentsViewOptionsList } from '../../utils/DocumentsUtils';
import styles from './Dashboard.module.scss';

export const Dashboard = () => {
	const { t } = useTranslation('translation');
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [topLatePeriod, setTopLatePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [upForRenewalPeriod, setUpForRenewalPeriod] = useState<SelectItem>(defaultUpForRenewalPeriod);
	const [forecastPeriod, setForecastPeriod] = useState<DatesData>(forecastTimePeriodInitialState);
	const [collectionStatusPeriod, setCollectionStatusPeriod] = useState<DatesData>(collectionStatusTimePeriodInitialState);
	const [collectionStatus, setCollectionStatus] = useState<GraphState>(initialGraphState);
	const [invoiceForecast, setInvoiceForecast] = useState<GraphState>(initialGraphState);
	const [arTableData, setArTableData] = useState<ARAgingResponse>();
	const [syncedContracts, setSyncedContracts] = useState<{ data: AnalyticsListData[]; totalContractCount: number }>();
	const [topLateCustomers, setTopLateCustomers] = useState<{ data: AnalyticsListData[]; total: number }>();
	const [upForRenewal, setUpForRenewal] = useState<{ data: AnalyticsListData[]; total: number }>();
	const [customerLocationList, setCustomerLocationList] = useState<{ data: AnalyticsListData[]; total: number }>();
	const [isARAgingOptionsMenuOpen, setIsARAgingOptionsMenuOpen] = useState(false);

	const userData = useSelector((store: Store) => store.user);
	const { isSidebarOpen, appCurrency } = useSelector((store: Store) => store.general);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
		getSyncedContracts();
		getCustomerLocationList();
	}, []);

	useEffect(() => {
		getReceivableAging();
	}, [timePeriod]);

	useEffect(() => {
		getTopLateCustomersList();
	}, [topLatePeriod]);

	useEffect(() => {
		handleForecastData();
	}, [forecastPeriod]);

	useEffect(() => {
		getUpForRenewal();
	}, [upForRenewalPeriod]);

	useEffect(() => {
		handleCollectionStatusData();
	}, [collectionStatusPeriod]);

	const handleCollectionStatusData = async () => {
		const cashflow = await getCashflowData(collectionStatusPeriod, dispatch);
		const { dataForGraph, isEmpty, dataFormat } = collectionStatusDataHandler(
			cashflow,
			t,
			cashTimeUnitHandler(collectionStatusPeriod.from, collectionStatusPeriod.to),
			collectionStatusPeriod.value,
		);
		setCollectionStatus({ data: dataForGraph, isEmpty, dataFormat });
	};

	const handleForecastData = async () => {
		const futureData = await getForecastData(forecastPeriod, dispatch);
		const { dataForGraphForecast, isForecastEmpty, dataFormat } = forecastDataHandler(
			futureData,
			cashTimeUnitHandler(forecastPeriod.from, forecastPeriod.to),
			forecastPeriod.value,
		);
		setInvoiceForecast({ data: dataForGraphForecast, isEmpty: isForecastEmpty, dataFormat });
	};

	const getTopLateCustomersList = async () => {
		const customers = await getTopLateCustomers(dispatch, topLatePeriod, t, appCurrency);
		customers && setTopLateCustomers({ total: customers?.total, data: customers?.data });
	};

	const getSyncedContracts = async () => {
		try {
			const res: SyncedContractsData = (
				await httpService({
					dispatch,
					path: PathsConfig.getSyncedContracts,
				})
			).data;
			setSyncedContracts({
				...res,
				data: res.data.map((item) => ({
					count: item.contractCount,
					name: item.name,
					id: item.id,
					iconData: item.iconData,
					subText: `${item.contractCount} ${t(item.contractCount === 1 ? 'contractWaitingReview' : 'contractsWaitingReview')}`,
				})),
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_SYNCED_CONTRACTS }));
		}
	};

	const getCustomerLocationList = async () => {
		const locations = await getCustomerLocation(dispatch);
		locations && setCustomerLocationList(locations);
	};

	const getReceivableAging = async (customerName?: string) => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getReceivableAging,
				params: {
					pageSize: 50,
					pageNumber: 0,
					orderBy: 'startDate',
					order: 'ASC',
					textFilter: customerName,
				},
			});

			setArTableData(res?.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_AR_AGING_DATA }));
		}
	};

	const getUpForRenewal = async () => {
		try {
			const res: UpForRenewalData = (
				await httpService({
					dispatch,
					path: PathsConfig.getUpForRenewal,
					params: {
						dateUntilRenewal: upForRenewalPeriod.value,
					},
				})
			).data;

			setUpForRenewal({
				total: res.count,
				data: res.contracts.map((item) => ({
					count: item.contractTotal,
					name: item.customerName,
					id: item.contractId,
					iconData: item.icon,
					subText: `${t('contract')} ${contractIdFormat(item?.contractPoNumber, item.contractNumber)}`,
					amount: priceNumberWithCurrencySignDisplay(+item.contractTotal || 0, convertCurrencyToSign(appCurrency)),
					amountSubTitle: `${item.daysLeft} ${t(item.daysLeft === 1 ? 'dayLeft' : 'daysLeft')}`,
					customerId: item.customerId,
				})),
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_UP_FOR_RENEWAL }));
		}
	};

	const onPressNewContract = async () => {
		const contractId = await createContract(dispatch, ContractType.ENTERPRISE);
		contractId && navigate('../contract', { state: { contractId } });
	};

	const refreshData = () => {
		getSyncedContracts();
		handleCollectionStatusData();
		getReceivableAging();
		handleForecastData();
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={refreshData} />
				<header className={styles.pageHeader}>
					<h1>
						<span className={styles.fontWeight250}>
							{t('dashboardWelcome')}
							{userData.name && ', '}
						</span>
						{formatUserName(userData)}
					</h1>
					<div className={styles.headerButtons}>
						<CallToActionMenu />
					</div>
				</header>

				<AnalyticsSummary />

				<div className={styles.graphsSection}>
					<div className={styles.collectionStatusContainer}>
						<div className={styles.graphsCardHeader}>
							<div className={styles.fontSize20}>{t('collectionStatus')}</div>
							<DateRangeFilters
								mediumInput
								data={fullFiltersSelectOptions}
								defaultSelectValue={collectionStatusPeriod.title}
								callback={setCollectionStatusPeriod}
							/>
						</div>
						<div className={styles.graphContainer}>
							<StackBarGraph
								isCashflow
								isEmpty={collectionStatus.isEmpty}
								emptyTitle={t('noDataSource')}
								chartData={{ labels: collectionStatus.data.labels, datasets: collectionStatus.data.datasets as StackBarDataset[] }}
								dataFormat={collectionStatus.dataFormat}
							/>
						</div>
					</div>

					<div className={styles.cashflowContainer}>
						<div className={styles.graphsCardHeader}>
							<div className={styles.fontSize20}>{t('cashInflowsForecast')}</div>
							<DateRangeFilters
								mediumInput
								data={forecastFiltersSelectOptions}
								defaultSelectValue={forecastPeriod.title}
								callback={setForecastPeriod}
							/>
						</div>
						<div className={styles.graphContainer}>
							<StackBarGraph
								isCashflow
								isEmpty={invoiceForecast.isEmpty}
								dataFormat={invoiceForecast.dataFormat}
								chartData={{
									labels: invoiceForecast.data.labels,
									datasets: invoiceForecast.data.datasets as StackBarDataset[],
								}}
							/>
						</div>
					</div>
				</div>

				<div className={styles.analyticsListsSection}>
					<div className={styles.analyticsLeftSection}>
						<AnalyticsList
							title={t('upForRenewal')}
							total={upForRenewal?.total}
							subTitle={`${t(upForRenewal?.total === 1 ? 'contract' : 'contracts')} ${t('upForRenewal').toLowerCase()}`}
							data={upForRenewal?.data}
							className={styles.analyticsList}
							emptyStateTitle={t('noDataSource')}
							filterData={{
								defaultValue: upForRenewalPeriod,
								data: upForRenewalPeriodItems,
							}}
							// TODO return when apiReady
							// onNavigate={() => navigate(`../customers/single-customer'`)}
							onSelectFilterItem={(_, item) => setUpForRenewalPeriod(item as SelectItem)}
							onRowClick={(row) => navigate(`../customers/single-customer/${row?.customerId}`, { state: { contractId: row.id } })}
						/>

						<AnalyticsList
							title={t('topLate')}
							total={topLateCustomers?.total}
							subTitle={`${t(topLateCustomers?.total === 1 ? 'customer' : 'customers')}`}
							data={topLateCustomers?.data}
							className={styles.analyticsList}
							emptyStateTitle={t('nobodyLate')}
							emptyStateIcon='v_circle'
							onRowClick={(row) => navigate('./all-invoices', { state: { summaryType: summaryType.DESTRUCT } })}
							onNavigate={() => navigate('./customers', { state: { orderBy: 'delayedPaymentsAmount', order: SortOrder.DESC } })}
							customFilters={
								<DateRangeFilters
									width={15}
									mediumInput
									data={fullFiltersSelectOptions}
									defaultSelectValue={topLatePeriod.title}
									callback={setTopLatePeriod}
								/>
							}
						/>
					</div>

					{syncedContracts?.data.length ? (
						<AnalyticsList
							title={t('syncedContracts')}
							total={syncedContracts?.totalContractCount}
							subTitle={`${t('importedContractLeftReview').replace('__', t(syncedContracts?.totalContractCount === 1 ? 'contract' : 'contracts'))}`}
							data={syncedContracts?.data}
							emptyStateTitle={t('noDataSource')}
							className={styles.rightListContainer}
							// TODO return when apiReady
							// onNavigate={() => navigate('/dashboard/synced-status')}
							// TODO return when have customerId
							// onRowClick={(row) => navigate(`../customers/single-customer/${row?.customerId}`, { state: { contractId: row.id } })}
						/>
					) : (
						<EmptyIntegrations className={styles.rightListContainer} />
					)}
				</div>

				<div className={styles.tableSection}>
					<ScrollingTable
						title={t('accountsReceivableAging')}
						headers={accountsReceivableAgingHeader(convertCurrencyToSign(appCurrency))}
						rows={arTableData?.data}
						totalRow={arTableData?.total}
						totalComponent={(item) => <Cell accessor={priceNumberWithCurrencySignDisplay(item, convertCurrencyToSign(appCurrency))} />}
						filters={
							<>
								<SearchBar mediumInput placeholder={t('searchCustomer')} onChange={getReceivableAging} />
								<Button onClick={() => navigate(`/dashboard/ar-aging`)} type='outlined' color='neutral' className={styles.navigateButton}>
									<Icon imgType='navigate' color='neutral700' height={1.8} />
								</Button>
								<MenuList
									isMenuOpen={isARAgingOptionsMenuOpen}
									openMenu={() => setIsARAgingOptionsMenuOpen(false)}
									optionsList={documentsViewOptionsList({ t, onDownloadCSV: () => onDownloadARAgingCSV(t, arTableData?.data || [], appCurrency) })}
								>
									<Button type='outlined' color='neutral' onClick={() => setIsARAgingOptionsMenuOpen(!isARAgingOptionsMenuOpen)}>
										<Icon imgType='hamburger' color='neutral700' />
									</Button>
								</MenuList>
							</>
						}
						containerClassName={styles.arAging}
						emptyStateChildren={
							<Button type='link' onClick={onPressNewContract}>
								{t('createContract')}
							</Button>
						}
					/>

					<AnalyticsList
						title={t('customerLocation')}
						titles={[t('country'), t('customers')]}
						total={customerLocationList?.total}
						subTitle={`${t(customerLocationList?.total === 1 ? 'customer' : 'customers')}`}
						data={customerLocationList?.data}
						className={styles.rightListContainer}
						emptyStateTitle={t('nobodyLate')}
					/>
				</div>
			</div>
		</div>
	);
};
