import { useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AccountingEventType, DocumentState } from '@received/pricing-model';
import dayjs from 'dayjs';
import { Button, DatePicker, Icon, MenuList, Tooltip } from '../..';
import { changeInvoiceStatus, getSyncStatusError, invoiceStatus } from './InvoiceStatusTag.utils';
import { ChangeStatusOption, PaidPeriod } from '../../InvoiceTable/InvoiceTable.utils';
import { FooterTypeOptions } from '../../_uiComponents/DatePicker/Footer/Footer';
import { Invoice, InvoiceView } from '../../../types/invoiceTypes';
import { reconcileDocuments, submitDocuments } from '../../../utils/DocumentsUtils';
import { StatusTagProps } from '../StatusTags.utils';
import styles from './InvoiceStatusTag.module.scss';

export interface InvoiceStatusTagProps extends StatusTagProps {
	document: InvoiceView | Invoice;
}

export const InvoiceStatusTag = ({ document, isShort, disabled, tooltipPlacement = 'left', refreshData }: InvoiceStatusTagProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [currentAction, setCurrentAction] = useState<ChangeStatusOption | ''>('');

	const dispatch = useDispatch();
	const { t } = useTranslation('translation');
	const invoiceStatusData = invoiceStatus(document?.state, isShort);

	const onMarkAsPaidFromTable = (period: PaidPeriod) => {
		setCurrentAction(ChangeStatusOption.MARK_AS_PAID_FROM_ROW);
		switch (period) {
			case PaidPeriod.TODAY:
				return onUpdateInvoiceStatus(new Date());
			case PaidPeriod.CUSTOM:
				return setOpenDatePicker(true);
		}
	};

	const onUpdateInvoiceStatus = async (date: Date) => {
		if (document?.id) {
			await reconcileDocuments(date, [{ id: document.id, type: document?.accountingEventType || AccountingEventType.INVOICE }], dispatch);
			refreshData?.();
		}
	};

	const onPublish = (currentAction: ChangeStatusOption) => {
		setOpenDatePicker(true);
		setCurrentAction(currentAction);
	};

	const onUpdateStatusFromDatePicker = async (date: Date) => {
		if (document) {
			setOpenDatePicker(false);
			if (currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW && document?.id) {
				await reconcileDocuments(date, [{ id: document.id, type: document?.accountingEventType || AccountingEventType.INVOICE }], dispatch);
			} else {
				document.id &&
					(await submitDocuments(date, [{ id: document.id, type: document?.accountingEventType || AccountingEventType.INVOICE }], dispatch));
			}
			setCurrentAction('');
			refreshData?.();
		}
	};

	const onCloseDatePicker = (isOpen: boolean) => {
		setOpenDatePicker(isOpen);
		setCurrentAction('');
	};

	const invoiceList = document ? changeInvoiceStatus(t, document, dispatch, onMarkAsPaidFromTable, onPublish, refreshData) : [];

	return (
		<>
			{status !== DocumentState.CONTRACT_DRAFT && (
				<Tooltip
					tooltipComponent={<div className={styles.tooltip}>{getSyncStatusError(document)}</div>}
					disabled={!getSyncStatusError(document)}
					blackTooltip
					placement={tooltipPlacement}
				>
					<MenuList optionsList={invoiceList} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
						<Button
							dataTestId='invoice-status-tag-button'
							onClick={(e) => {
								setOpenMenuOptions(true);
								e.stopPropagation();
							}}
							type={invoiceStatusData.type}
							color={invoiceStatusData.color}
							className={clsx(styles.button, isShort && styles.isShortContainer)}
							style={{ pointerEvents: disabled ? 'none' : document && invoiceList.length ? 'auto' : 'none' }}
						>
							{t(invoiceStatus(document?.state, isShort).text)}
							<div className={styles.iconContainer}>
								<Icon imgType='arrow_down' color={invoiceStatusData?.iconColor || invoiceStatusData.color} width={1} />
							</div>
						</Button>
					</MenuList>
				</Tooltip>
			)}

			<DatePicker
				hideInput
				openDatePicker={openDatePicker}
				datePickerLabel={
					currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW || currentAction === ChangeStatusOption.MARK_AS_PAID
						? t('choosePaymentDate')
						: t('choosePublishDate')
				}
				headerLabelStyle={styles.datePickerHeaderLabelStyle}
				footerType={FooterTypeOptions.SHOW_CONFIRM_BUTTONS}
				onConfirm={onUpdateStatusFromDatePicker}
				updateIsOpen={onCloseDatePicker}
				defaultFromDate={
					document
						? currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW || currentAction === ChangeStatusOption.MARK_AS_PAID
							? dayjs(document.dueDate).toDate()
							: dayjs(document.issueDate).toDate()
						: undefined
				}
			/>
		</>
	);
};
