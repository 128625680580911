import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DragAndDropSelectItem, ContractAndPricingModelContainer } from '../../../components';
import { PricingModel } from '../../../types/contractTypes';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { PricingModelTabContentOptions } from '../../../components/PricingModelTabContent/PricingModelTabContentType';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { resetItemPriceInPricingModel } from './NewPricingModel.utils';
import { AddProductModal } from '../AddProductModal/AddProductModal';
import { ProductAndCategoriesItem } from '../../../types/ProductTypes';
import { PricingModelHeader } from './PricingModelHeader/PricingModelHeader';
import styles from './NewPricingModel.module.scss';

interface PricingModelsProps {
	isUsedAsComponent?: boolean;
	localLocation?: any;
	selectedRowItem?: DragAndDropSelectItem;
	navigateToProducts?: (data: any) => void;
}

export const NewPricingModel = ({ isUsedAsComponent, localLocation, selectedRowItem, navigateToProducts }: PricingModelsProps) => {
	const { t } = useTranslation('translation');

	const initialModel = {
		tabs: [],
		isFavorite: true,
		isTemplate: true,
		name: t('newPricingModelTitle'),
	};
	const [pricingModel, setPricingModel] = useState<PricingModel>(initialModel);

	const [product, setProduct] = useState<{ id: string; name: string }>({ id: '', name: '' });
	const [openProductModal, setOpenProductModal] = useState(false);
	const [productsList, setProductsList] = useState<ProductAndCategoriesItem[]>([]);

	const location: any = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const currentLocation = localLocation || location.state;

	useEffect(() => {
		!isUsedAsComponent && dispatch(setSidebarIsOpen(true));
		if (currentLocation?.pricingModelId) {
			getPricingModel(currentLocation?.pricingModelId);
		} else {
			setPricingModel(currentLocation?.selectedPricingModel || pricingModel);
			setProduct({ id: currentLocation?.productId || '', name: currentLocation?.productName || '' });
			getProductsList();
		}
	}, []);

	const getProductsList = async () => {
		try {
			const res: ProductAndCategoriesItem[] = (
				await httpService({
					dispatch,
					path: PathsConfig.myProductsList,
				})
			).data;
			setProductsList(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_PRODUCTS }));
		}
	};

	const getPricingModel = async (id: string) => {
		try {
			const res: PricingModel = (
				await httpService({
					dispatch,
					path: PathsConfig.getPricingModelData,
					urlParam: { id },
				})
			).data;
			setPricingModel(res);
			res?.product && setProduct(res?.product);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_PRICING_MODEL }));
		}
	};

	const createPricingModel = async () => {
		try {
			let modelFromServer: PricingModel = (await httpService({ dispatch, path: PathsConfig.createPricingModel, data: { ...pricingModel, product } }))
				.data;

			// reset itemPrice Ids so the server will create new
			modelFromServer = resetItemPriceInPricingModel(modelFromServer);
			return modelFromServer;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onDoneEditingPricingModel = async () => {
		let model = pricingModel;
		if (!currentLocation?.pricingModelId) {
			model = (await createPricingModel()) || pricingModel;
		}
		try {
			await httpService({ dispatch, path: PathsConfig.publishPricingModel, data: model });
			{
				product?.id &&
					!currentLocation?.pricingModelId &&
					(await httpService({
						dispatch,
						path: PathsConfig.connectModelToProduct,
						data: { pricingModelId: model.id, productId: product.id },
					}));
			}

			const locationProduct = { product: { id: product.id, name: product.id } };
			navigateToProducts ? navigateToProducts(product) : navigate('../pricing-models', { replace: true, state: locationProduct });
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onGoBack = () => {
		const locationProduct = { product: { id: currentLocation?.productId, name: currentLocation?.productName } };
		navigateToProducts ? navigateToProducts(product) : navigate('../pricing-models', { replace: true, state: locationProduct });
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.mainContainer}>
				<PricingModelHeader
					pricingModel={pricingModel}
					product={product}
					productsList={productsList}
					currentLocation={currentLocation}
					isUsedAsComponent={isUsedAsComponent}
					setProduct={setProduct}
					setOpenProductModal={setOpenProductModal}
					getPricingModel={getPricingModel}
					onGoBack={onGoBack}
					onDoneEditingPricingModel={onDoneEditingPricingModel}
					setPricingModel={setPricingModel}
				/>
				<ContractAndPricingModelContainer
					pricingModel={pricingModel}
					setPricingModel={(newPricingModel: PricingModel) => setPricingModel((prev) => ({ ...prev, ...newPricingModel }))}
					selectedRowItem={selectedRowItem}
					tabContentType={PricingModelTabContentOptions.PRICING_MODEL}
					className={styles.contentContainer}
				/>
			</div>

			<AddProductModal
				isOpen={openProductModal}
				onProductAdded={(id, name) => {
					getProductsList();
					setProduct({ name, id });
				}}
				closeModal={() => setOpenProductModal(false)}
			/>
		</div>
	);
};
