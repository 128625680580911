import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContractType } from '@received/pricing-model';
import {
	Button,
	CustomerDetails,
	Tabs,
	Sidebars,
	SideBarFilters,
	FilterSideBar,
	Icon,
	DateRangeFilters,
	FilterTags,
	ImportItemsProgress,
	CreateCustomer,
	MenuList,
	ActionModalModal,
} from '../../../components';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { customerOptionList, isTabHasTimeFilters, SingleCustomerPageTabIndex, singleCustomerPageTabs } from './SingleCustomerPage.utils';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../components/Filters/DateRangeFilters.utils';
import { DatesData } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { FilterSideBarOptions } from '../../../components/FilterSideBar/FilterSideBar.utils';
import { Customer } from '../../../types/customerTypes';
import { deleteCustomer } from '..';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { images } from '../../../constants/images';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import { ContractEmptyState } from '../../Contracts/ContractComponent/ContractEmptyState/ContractEmptyState';
import styles from './SingleCustomerPage.module.scss';

export const SingleCustomerPage = () => {
	const { t } = useTranslation('translation');
	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);

	const [customerData, setCustomerData] = useState<Customer>();
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [refreshTabs, setRefreshTabs] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [openFilter, setOpenFilter] = useState(false);
	const [sideBarFilters, setSideBarFilters] = useState<SideBarFilters>({ filterData: {}, filterTags: [] });
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [isCreateCustomerOpen, setIsCreateCustomerOpen] = useState(false);

	const customerTabs = singleCustomerPageTabs().map((tab) => t(tab.title));

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location: any = useLocation();
	const { id } = useParams();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
		id ? getCustomer(id) : navigate('../customers');
	}, []);

	const getCustomer = async (id: string) => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getCustomerData,
				urlParam: { id },
			});
			setCustomerData(res.data);

			setIsEmpty(res.data.contractsCount <= 0);
			location.state?.contractId && handleShowContract();
			if (location.state?.activeTab) {
				const tabIndex = singleCustomerPageTabs().findIndex((tab) => tab.tabIndex === location.state?.activeTab);
				setActiveTab(tabIndex >= 0 ? tabIndex : 0);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.FAILED_LOAD_CUSTOMERS }));
			!customerData && navigate('../customers');
		}
	};

	const handleShowContract = () => {
		setActiveTab(singleCustomerPageTabs().findIndex((tab) => tab.tabIndex === SingleCustomerPageTabIndex.contracts));
		setOpenSideBarById({ sideBarType: SidebarType.CONTRACT, id: location.state?.contractId });
		//reset location state
		navigate('.', { replace: true });
	};

	const onPressNewContract = async () => {
		const contractId = await createContract(dispatch, ContractType.ENTERPRISE, customerData?.id);
		contractId && navigate('../contract', { state: { contractId, customer: customerData } });
	};

	const onDeleteCustomer = async (id: string) => {
		await deleteCustomer(id, dispatch, () => navigate('../customers'));
		setIsDeleteModalOpen(false);
	};

	const getAddButtonByTabType = () => {
		switch (singleCustomerPageTabs()[activeTab].tabIndex) {
			case SingleCustomerPageTabIndex.credits:
				return { title: `${t('newCredit')} +`, onClick: () => navigate('../single-credit-note', { state: { customer: customerData } }) };

			case SingleCustomerPageTabIndex.subscriptions:
				return {
					title: `${t('assignSubscription')} +`,
					onClick: () => setOpenSideBarById?.({ sideBarType: SidebarType.ASSIGN_SUBSCRIPTION, id: '' }),
				};

			default:
				return { title: t('createContract'), onClick: () => onPressNewContract() };
		}
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.customersContainer}>
				<ImportItemsProgress
					onRefresh={() => {
						setRefreshTabs(true);
						setTimeout(() => setRefreshTabs(false));
					}}
				/>
				<Sidebars
					openSideBarById={openSideBarById}
					onCloseSideBars={() => setOpenSideBarById(undefined)}
					onDataChange={() => {
						customerData?.id && getCustomer(customerData?.id);
					}}
					selectedCustomer={customerData}
				/>

				<FilterSideBar
					filterData={sideBarFilters}
					isOpen={openFilter}
					filterOption={FilterSideBarOptions.SINGLE_CUSTOMER_PAGE}
					closeSideBar={() => setOpenFilter(false)}
					updateFilters={setSideBarFilters}
				/>

				<CreateCustomer
					isOpen={isCreateCustomerOpen}
					closeModal={() => setIsCreateCustomerOpen(false)}
					onCustomerAdded={async () => {
						setIsCreateCustomerOpen(false);
						customerData?.id && (await getCustomer(customerData?.id));
					}}
					customer={customerData}
				/>

				<ActionModalModal
					rightButton={{ title: 'delete', color: 'destructive' }}
					isOpen={isDeleteModalOpen}
					imagesOptions={images.deleteItem}
					deleteMessage={
						<>
							{t('deleteMessagePrefix').replace('__', 'customer')}
							<span className={styles.name}> {customerData?.name}</span> {t('deleteMessagePostfix').replace('__', 'customer')}
						</>
					}
					onAccept={() => onDeleteCustomer(customerData?.id || '')}
					onClose={() => setIsDeleteModalOpen(false)}
				/>

				<div className={styles.customersHeaderContainer} style={{ height: sideBarFilters?.filterTags.length ? '18.5rem' : '14rem' }}>
					<div className={styles.goBackContainer}>
						<Button type='link' color='neutral' onClick={() => navigate('../customers')} className={styles.goBack}>
							{t('Customers')}
						</Button>

						<div className={styles.ellipsis}>{`/ ${customerData?.name}`} </div>
					</div>

					<header className={styles.customersHeader}>
						<CustomerDetails {...customerData} nameStyle={styles.ellipsis} />

						<div className={styles.buttonsContainer}>
							{isTabHasTimeFilters(singleCustomerPageTabs()[activeTab].tabIndex) && (
								<>
									<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />
									<Button onClick={() => setOpenFilter(true)} type='outlined' color='neutral' className={styles.filterButton}>
										{t('filters')}
										<Icon imgType='filters' color='neutral' height={2} />
									</Button>
								</>
							)}

							<Button onClick={getAddButtonByTabType().onClick} className={styles.customerButtons}>
								{getAddButtonByTabType().title}
							</Button>

							<MenuList
								isMenuOpen={isOptionsMenuOpen}
								openMenu={() => setIsOptionsMenuOpen(false)}
								optionsList={customerOptionList(
									dispatch,
									() => setIsCreateCustomerOpen(true),
									() => setIsDeleteModalOpen(true),
									customerData,
								)}
							>
								<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)} className={styles.hamburgerButton}>
									<Icon imgType='hamburger' color='neutral700' />
								</Button>
							</MenuList>
						</div>
					</header>

					<FilterTags
						filterData={sideBarFilters}
						onRemoveTag={(newTags) => sideBarFilters && setSideBarFilters((prev) => ({ ...prev, ...newTags }))}
					/>
				</div>

				{!isEmpty ? (
					<>
						<Tabs
							chosenTab={activeTab}
							tabsArray={customerTabs}
							updateChosenTab={(val: number) => setActiveTab(val)}
							style={{ padding: '0 2.4rem' }}
							classNames={styles}
						/>
						<div
							className={styles.tabsContent}
							style={{
								height: [SingleCustomerPageTabIndex.analytics].includes(singleCustomerPageTabs()[activeTab].tabIndex)
									? 'auto'
									: sideBarFilters?.filterTags.length
									? 'calc(100% - 23.5rem)'
									: 'calc(100% - 19.5rem)',
							}}
						>
							{singleCustomerPageTabs()[activeTab].component({
								customerData,
								timePeriod,
								sideBarFilters,
								refreshTabs,
								setOpenSideBarById,
								isActive: activeTab == singleCustomerPageTabs().findIndex((tab, index: number) => index == activeTab),
							})}
						</div>
					</>
				) : (
					<ContractEmptyState onClick={onPressNewContract} className={styles.emptyStateContainer} />
				)}
			</div>
		</div>
	);
};
