import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContractType, DEFAULT_CURRENCY, DocumentState, convertCurrencyToSign } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PayoutView, PayoutsResponse, PayoutsSummary } from '../../../types/PayoutsTypes';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { defaultNumberOfItems } from '../../../constants/generalConstants';
import { Store } from '../../../types/storeTypes';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { getPayoutSummaryData, onDownloadPayoutsCSV, payoutsMenuOptions, payoutsTableHeader } from './Payouts.utils';
import { useDebounce } from '../../../hooks';
import { Button, CreateCustomer, EmptyStateCard, Icon, ImportItemsProgress, MenuList, SearchBar, Sidebars, Table } from '../../../components';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { documentsViewOptionsList } from '../../../utils/DocumentsUtils';
import styles from './Payouts.module.scss';

export const Payouts = () => {
	const [showCreateCustomer, setShowCreateCustomer] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [payoutsList, setPayoutsList] = useState<PayoutView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [searchText, setSearchText] = useState('');
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [sort, setSort] = useState<Sort>({ orderBy: 'issueDate', order: SortOrder.ASC });
	const [statusBarData, setStatusBarData] = useState<PayoutsSummary>();
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);

	const currency = convertCurrencyToSign(payoutsList?.[0]?.currency || DEFAULT_CURRENCY);
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getPayoutsData();
		//TODO - uncomment when server ready
		// setSummaryBar();
	}, [numberOfItems, pageNumber, toastNotification.showToastNotification, sort, searchText]);

	const getPayoutsData = async (page?: number, items?: string) => {
		try {
			const res: PayoutsResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getPayoutsViewData,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						textFilter: searchText || undefined,
						order: sort.order,
						excludedStates: [DocumentState.CONTRACT_DRAFT],
					},
				})
			).data;

			setTotalCount(res?.count);
			setPayoutsList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_PAYOUTS_LIST }));
		}
	};

	const setSummaryBar = async () => {
		const summaryData = await getPayoutSummaryData(dispatch, searchText);
		setStatusBarData(summaryData);
	};

	const onRefreshData = () => {
		getPayoutsData();
		//TODO - uncomment when server ready
		//setSummaryBar();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
	};

	const newReferralAgreement = async () => {
		const agreementId = await createContract(dispatch, ContractType.REFERRAL);
		agreementId && navigate('../referral-agreement', { state: { contractId: agreementId } });
	};

	const onSearch = useDebounce(setSearchText, 650);

	return (
		<div className={styles.globalPageContainer}>
			<Sidebars openSideBarById={openSideBarById} onCloseSideBars={() => setOpenSideBarById(undefined)} onDataChange={onRefreshData} />

			<div className={styles.container}>
				<ImportItemsProgress onRefresh={onRefreshData} />

				<div className={styles.header}>
					<header className={styles.customHeader}>
						<h1 data-testid='payouts-title'>{t('payouts')}</h1>

						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.search} />

							<Button color='neutral' type='outlined' onClick={() => setShowCreateCustomer(true)} className={styles.customerButtons}>
								{t('newPartner')}
								<Icon imgType='add' width={1} />
							</Button>

							<Button color='success' onClick={newReferralAgreement} className={styles.newAgreement}>
								{t('newReferralAgreement')}
								<Icon imgType='add' color='white' width={1} />
							</Button>

							<MenuList
								isMenuOpen={isOptionsMenuOpen}
								openMenu={() => setIsOptionsMenuOpen(false)}
								optionsList={documentsViewOptionsList({ t, onDownloadCSV: () => onDownloadPayoutsCSV(t, payoutsList) })}
							>
								<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}>
									<Icon imgType='hamburger' color='neutral700' />
								</Button>
							</MenuList>
						</div>
					</header>
				</div>

				{/* TODO - uncomment when specify 
				<div className={styles.cardsContainer}>
					{quantityBarData(t, statusBarData).map((card, index: number) => (
						<div key={index} className={styles.cardWrapper} data-testid={`payouts-summary-card=${index}`}>
							<div className={styles.card}>
								<span className={styles.cardTitle}>{card.title}</span>
								<span className={styles.cardQuantity}>{getValueWithCurrencySign(card.quantity, currency)}</span>
								<span className={styles.cardDescription}>{card.description}</span>
							</div>
							<div className={styles.divider} />
						</div>
					))}
				</div> */}

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={payoutsTableHeader(onRefreshData)}
					rows={payoutsList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					onRowPress={(data) => setOpenSideBarById?.({ sideBarType: SidebarType.PAYOUT, id: data.id })}
					optionsList={(row) =>
						payoutsMenuOptions({
							t,
							payout: row,
							dispatch,
							navigate,
							onRefresh: onRefreshData,
						})
					}
					emptyState={
						!searchText && (
							<EmptyStateCard
								showIcon
								imgType='users'
								title={t('noPayoutsFound')}
								subTitle={t('noAgreementsFoundDescription')}
								bodyContainerClassName={styles.emptyStateWrapper}
							/>
						)
					}
				/>
			</div>

			<CreateCustomer
				isOpen={showCreateCustomer}
				closeModal={() => setShowCreateCustomer(false)}
				onCustomerAdded={() => setShowCreateCustomer(false)}
				refreshData={onRefreshData}
				isPartner
			/>
		</div>
	);
};
