import { UserPermissions } from '@received/pricing-model';
import { Referrals, Resellers, SingleReferralPage, SingleResellerPage } from '../../pages/_Partners';
import { RoutesType } from '../../types/generalTypes';

export const partnersRoutes: RoutesType[] = [
	{
		name: 'partners',
		path: '',
		showOnSidebar: true,
		iconType: 'users',
		licenses: [UserPermissions.PARTNERS],
		subElements: [
			{
				name: 'referrals',
				path: '/referrals',
				showOnSidebar: true,
				element: <Referrals />,
				licenses: [UserPermissions.PARTNERS],
			},
			{
				name: 'resellers',
				path: '/resellers',
				showOnSidebar: true,
				iconType: 'users',
				element: <Resellers />,
				licenses: [UserPermissions.PARTNERS],
			},
		],
	},
	{
		name: 'singlePartners',
		path: '/partners/single-referral/:id',
		showOnSidebar: false,
		element: <SingleReferralPage />,
	},
	{
		name: 'singlePartners',
		path: '/partners/single-reseller/:id',
		showOnSidebar: false,
		element: <SingleResellerPage />,
	},
];
