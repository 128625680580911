import { importCustomerColumns } from '../DataHandlers';

const nameExample = 'Acme';
const legalNameExample = 'Acme Inc';
const EntityExample = 'Apex Inc USA';
const einExample = '123456789';
const emailExample = '"info1@acme.com, info2@acme.com"';
const countryExample = 'United States';
const stateExample = 'California';
const cityExample = 'Los Angeles';
const billingAddressExample = '"456 Main St, Suite 200, CA, 90013"';
const taxExample = '8.5';
const idExample = 'sub_1ORXiubkk1LFZtA';

export const CUSTOMER_CSV_TEMPLATE = `data:text/csv;charset=utf-8,${importCustomerColumns
	.slice(2)
	?.join(
		',',
	)}\n${nameExample},${legalNameExample},${EntityExample},${einExample},${emailExample},${countryExample},${stateExample},${cityExample},${billingAddressExample},${taxExample},${idExample},,`;

export const CUSTOMER_CSV_TEMPLATE_NAME = 'template';
