import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { EmptyStateCard } from '../EmptyStateCard/EmptyStateCard';
import styles from './ScrollingTable.module.scss';

interface ScrollingTableHeader {
	headerTitle: string;
	accessor: string;
	totalTitle?: string;
	CustomComponent?: (item: any, index: number) => React.ReactNode;
}

interface ScrollingTableProps {
	title?: string;
	headers?: ScrollingTableHeader[];
	rows?: any[];
	totalRow?: any;
	emptyTitle?: string;
	emptySubTitle?: string;
	filters?: React.ReactNode;
	emptyStateChildren?: React.ReactNode;
	containerClassName?: string;
	headerCellClassName?: string;
	cellClassName?: string;
	totalComponent?: (item: any) => React.ReactNode;
}

export const ScrollingTable = ({
	title,
	headers,
	rows,
	totalRow,
	emptyTitle,
	emptySubTitle,
	filters,
	emptyStateChildren,
	containerClassName,
	headerCellClassName,
	cellClassName,
	totalComponent,
}: ScrollingTableProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.container, containerClassName)}>
			{title && (
				<div className={styles.titleContainer}>
					<div className={styles.fontSize20}>{title}</div>
					<div className={styles.filters}>{filters}</div>
				</div>
			)}

			<div className={styles.tableContainer} style={{ paddingBottom: title ? '8.3rem' : '0rem' }}>
				{!rows?.length ? (
					<EmptyStateCard
						showIcon
						imgAlt={'empty-graph'}
						title={emptyTitle || t('noDataSource')}
						subTitle={emptySubTitle || t('emptyStateSubtitle')}
						emptyIconContainer={styles.emptyStateCard}
					>
						{emptyStateChildren}
					</EmptyStateCard>
				) : (
					<>
						<div className={styles.header}>
							{headers?.map((header) => (
								<div key={header.accessor} className={clsx(styles.headerCell, headerCellClassName)}>
									{t(header.headerTitle)}
								</div>
							))}
						</div>

						<div className={styles.rowsContainer}>
							{rows?.map((row, index) => (
								<div className={styles.row} key={index}>
									{headers?.map((header) => (
										<div key={header.accessor} className={clsx(styles.cell, cellClassName)}>
											<div className={styles.wrap}>{!header?.CustomComponent ? row[header.accessor] : header?.CustomComponent(row, index)}</div>
										</div>
									))}
								</div>
							))}
						</div>

						{totalRow && (
							<div className={styles.footer}>
								{headers?.map((header) => (
									<div
										key={header.accessor}
										className={clsx(styles.cell, styles.footerCell, header.totalTitle && styles.fontWeight450, cellClassName)}
									>
										{header.totalTitle
											? t(header.totalTitle)
											: totalComponent
											? totalComponent?.(totalRow[header.accessor])
											: t(totalRow[header.accessor])}
									</div>
								))}
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};
