import { useEffect, useState } from 'react';
import { ContractType } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Icon, ImportItemsProgress, SearchBar, AssignSubscription, EmptyStateCard } from '../../../components';
import { PathsConfig, httpService } from '../../../services';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { Store } from '../../../types/storeTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { useDebounce } from '../../../hooks';
import { pageOptions, subscribersTableHeader } from './Subscribers.utils';
import { SubscribersView } from '../../../types/subscriptionTypes';
import { SingleCustomerPageTabIndex } from '../SingleCustomerPage/SingleCustomerPage.utils';
import { CustomerView } from '../../../types/customerTypes';
import styles from './Subscribers.module.scss';
import { SubscribersResponse } from '../../_ContractsViews/Subscriptions/Subscriptions';

export interface CustomerResponse {
	customerViewList: CustomerView[];
	totalCount: number;
}

const defaultNumberOfItems = '20';

export const Subscribers = () => {
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);

	const [subscribersList, setSubscribersList] = useState<SubscribersView[]>([]);
	const [sort, setSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.ASC });
	const [searchText, setSearchText] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState('100');
	const [showAssignSubscription, setShowAssignSubscription] = useState(false);
	const [tableSmallRowsView, setTableSmallRowsView] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		setTableSmallRowsView(+numberOfItems >= 50);
	}, [numberOfItems]);

	useEffect(() => {
		getSubscribersData();
	}, [numberOfItems, pageNumber, toastNotification.showToastNotification, sort]);

	useEffect(() => {
		searchSubscribers();
	}, [searchText]);

	const searchSubscribers = () => {
		getSubscribersData();
		setPageNumber(0);
	};

	const getSubscribersData = async (page?: number, items?: string) => {
		try {
			const res: SubscribersResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getSubscribersViewData,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						textFilter: searchText || undefined,
						order: sort.order,
						contractTypes: [ContractType.SUBSCRIPTION],
					},
				})
			).data;

			setTotalCount(res?.totalCount);
			setSubscribersList(res.viewList);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onRefreshData = () => {
		getSubscribersData();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
	};

	const onSearch = useDebounce(setSearchText, 650);

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container}>
				<ImportItemsProgress onRefresh={() => onRefreshData()} />

				<div className={styles.header}>
					<header className={styles.customHeader}>
						<h1 data-testid='subscribers-title'>{t('subscribers')}</h1>

						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.search} />
							<Button onClick={() => setShowAssignSubscription(true)} className={styles.assignSubscription}>
								{t('assignSubscription')}
								<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
							</Button>
						</div>
					</header>
				</div>

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={subscribersTableHeader(tableSmallRowsView, t)}
					rows={subscribersList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					pageItemsOptions={pageOptions(t)}
					totalNumberOfItems={totalCount}
					tableRowClassName={tableSmallRowsView ? styles.extendedTableRowClassName : styles.tableRowClassName}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onRowPress={(data: SubscribersView) =>
						navigate(`../customers/single-customer/${data.customerId}`, {
							state: { activeTab: SingleCustomerPageTabIndex.subscriptions },
						})
					}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyState={
						!searchText ? (
							<EmptyStateCard showIcon imgType='refresh' title={t('noAssignedSubscribers')} subTitle={t('noSubscribersAssigned')}>
								<Button className={styles.createButton} onClick={() => setShowAssignSubscription(true)}>
									<span>{t('assignSubscription')}</span>
									<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
								</Button>
							</EmptyStateCard>
						) : undefined
					}
				/>

				<AssignSubscription
					isOpen={showAssignSubscription}
					onAssignSubscription={() => getSubscribersData()}
					closeSideBar={() => setShowAssignSubscription(false)}
				/>
			</div>
		</div>
	);
};
