import { Dispatch } from '@reduxjs/toolkit';
import { plainToInstance } from 'class-transformer';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { PricingModelTab } from '../../../../types/contractTypes';
import { CreditNote } from '../../../../types/creditNoteTypes';
import { CreditNoteProducts } from './CreditNoteProducts/CreditNoteProducts';
import { DocumentSettings } from '../../../Contracts/ContractComponent/DocumentSettings/DocumentSettings';

export enum SingleCreditNoteTabsName {
	PRODUCTS = 'Products',
	SETTINGS = 'Settings',
}

export const creditNoteTabs = () => {
	return [
		{
			title: SingleCreditNoteTabsName.PRODUCTS,
			component: (prop?: any) => <CreditNoteProducts {...prop} />,
		},
		{
			title: SingleCreditNoteTabsName.SETTINGS,
			component: (prop?: any) => <DocumentSettings {...prop} />,
		},
	];
};

export const getCreditNoteCurrentNumber = async (dispatch: Dispatch) => {
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getSettingsStringByAttributeName,
				urlParam: { settingsSection: 'credit-notes', settingName: 'creditNoteCurrentNumber' },
			})
		).data;
	} catch (error) {
		dispatch(
			setOpenSuccessErrorModal({
				responseError: error,
				successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTE_STARTING_NUMBER,
			}),
		);
	}
};

export const createCreditNote = async (dispatch: Dispatch, creditNote: CreditNote) => {
	try {
		const transformedCreditNoteData = plainToInstance(CreditNote, creditNote);
		const data: CreditNote = (
			await httpService({
				dispatch,
				path: PathsConfig.createCreditNote,
				data: transformedCreditNoteData,
			})
		).data;
		return data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTE }));
	}
};

export const updateCreditNote = async (dispatch: Dispatch, creditNote: CreditNote) => {
	try {
		const transformedCreditNoteData = plainToInstance(CreditNote, creditNote);
		const data: CreditNote = (
			await httpService({
				dispatch,
				path: creditNote?.editBlockers?.length ? PathsConfig.updateLockedCreditNote : PathsConfig.updateCreditNote,
				urlParam: { id: creditNote.id || '' },
				data: transformedCreditNoteData,
			})
		).data;
		return data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const setCollectiveDataInTabs = (newCreditNote: CreditNote, newPricingModelTab: PricingModelTab) => {
	if (newCreditNote?.supplierId != newPricingModelTab?.supplierId) {
		newCreditNote.supplierId = newPricingModelTab?.supplierId;
		newCreditNote.pricingModel.tabs.forEach((tab) => {
			tab.supplierId = newPricingModelTab?.supplierId;
		});
	}
	if (newCreditNote?.currency != newPricingModelTab?.currency) {
		newCreditNote.currency = newPricingModelTab?.currency;
		newCreditNote.pricingModel.tabs.forEach((tab) => {
			tab.currency = newPricingModelTab?.currency;
		});
	}
	return newCreditNote;
};

export const isTabEditable = (activeTab: number, viewOnlyMode: boolean) =>
	activeTab == Object.values(SingleCreditNoteTabsName).indexOf(SingleCreditNoteTabsName.PRODUCTS) ? !viewOnlyMode : true;
