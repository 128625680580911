import { useTranslation } from 'react-i18next';
import { Button } from '../_uiComponents';
import styles from './NewEntity.module.scss';

interface NewEntityProps {
	onClick?: () => void;
}
export const NewEntity = ({ onClick }: NewEntityProps) => {
	const { t } = useTranslation('translation');

	return (
		<Button className={styles.container} type='link' onClick={onClick}>
			<div className={styles.circle} />
			<span className={styles.text}>{t('newEntity')}</span>
		</Button>
	);
};
