import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { convertCurrencyToSign, DATE_FORMAT, getNetTermLabel, PricingType } from '@received/pricing-model';
import { Button, DatePickerFormatType, Icon } from '../../../_uiComponents';
import { SideBarProductDetails, getLabelForQuantity, getLabelForPrice } from '../../../PricingModelDetails/PricingModelDetails.utils';
import { PricingModelTab } from '../../../../types/contractTypes';
import { getValueWithCurrencySign } from '../../../../utils/NumberUtils';
import styles from './CreditNotePricingModelDetails.module.scss';

export interface CreditNotePricingModelDetailsProps {
	tab: PricingModelTab;
	balance?: number;
	contractId?: string;
	hasUsageReport?: boolean;
	fromDate: Date | null | undefined;
	toDate: Date | null | undefined;
	onPressUpdateUsage?: (timePeriod?: DatePickerFormatType) => void;
}

export const CreditNotePricingModelDetails = ({
	tab,
	balance,
	contractId,
	fromDate,
	toDate,
	hasUsageReport,
	onPressUpdateUsage,
}: CreditNotePricingModelDetailsProps) => {
	const { t } = useTranslation('translation');
	const [isOpen, setIsOpen] = useState(false);
	const isStripeConnected = !!tab?.billingDetails?.paymentGatewayId;

	return (
		<div className={clsx(styles.mainContainer, isOpen && styles.mainContainerOpen)}>
			<div data-testid='side-bar-product-details' className={styles.mainSection} onClick={() => setIsOpen(!isOpen)}>
				<div className={styles.imgSection}>
					<Icon imgType='box' color='neutral700' width={1.8} />
				</div>

				<div className={styles.dataRowContainer}>
					<div className={styles.dataRowLabel}>
						<span data-testid='side-bar-product-product-name' className={styles.productName}>
							{tab.productName}
						</span>
					</div>
					<div className={styles.dataRowLabel}>
						<div className={styles.iconWrapper}>
							<span data-testid='side-bar-product-name' className={styles.mainData}>
								{tab.name}
							</span>
							<Icon imgType='arrow_down' color='neutral400' width={1} className={isOpen ? styles.arrowUp : styles.arrowDown} />
						</div>

						<span className={styles.mainData} data-testid='side-bar-product-total'>
							{getValueWithCurrencySign(tab.total, convertCurrencyToSign(tab.currency))}
						</span>
					</div>
					<div className={styles.dataRowLabel}>
						<span className={styles.text}>
							{`${dayjs(fromDate).format(DATE_FORMAT)}`}
							<span>
								{toDate &&
									` - ${dayjs(toDate).format(DATE_FORMAT)} • ${getNetTermLabel(tab.netTerms)} ${isStripeConnected ? `• ${t('stripe')}` : ''}`}
							</span>
						</span>
					</div>
				</div>
			</div>

			<div className={clsx(isOpen ? [styles.isOpen, styles.cards] : styles.isClosed)}>
				<SideBarProductDetails
					header={{ key: t('Items') }}
					metaData={tab?.pricingModelTable?.rows.map((row) => ({
						key: row.cells.item.cellValue,
						value: getLabelForPrice(row.cells.price.cellValue, row.cells.price.cellType, tab.currency),
						style: styles.bold,
						subLine: { key: '', value: getLabelForQuantity(row.cells.quantity.cellValue, row.cells.quantity.cellType, tab.currency) },
					}))}
				/>
				{tab?.pricingType == PricingType.USAGE && !hasUsageReport ? (
					<div className={styles.usageBox}>
						<div className={styles.usageCircle}>
							<Icon imgType='usageIcon' color='calming' className={styles.usageIcon} />
						</div>
						<span className={styles.usageDescription}>{t('usageMustBeUpdated')}</span>

						{onPressUpdateUsage && (
							<Button type='link' color='primary' onClick={onPressUpdateUsage}>
								<span className={styles.usageUpdate}>{t('updateUsage')}</span>
							</Button>
						)}
					</div>
				) : (
					<SideBarProductDetails
						header={{ key: t('summary') }}
						additionalData={[
							{
								key: t('subtotal'),
								value: getValueWithCurrencySign(tab?.subTotal, convertCurrencyToSign(tab?.currency)),
							},
							{
								key: t('tax'),
								value: `${getValueWithCurrencySign(tab?.totalTax || 0, convertCurrencyToSign(tab?.currency))}`,
							},
							{
								key: t('totalCredits'),
								value: getValueWithCurrencySign(tab?.total, convertCurrencyToSign(tab?.currency)),
								style: styles.bold,
							},
							{
								key: t('previousCreditsPaid'),
								value: `(${getValueWithCurrencySign(tab?.total ? tab?.total - (balance || 0) : 0, convertCurrencyToSign(tab?.currency))})`,
								style: styles.bold,
								isHidden: !!contractId,
							},
							{
								key: t('creditBalance'),
								value: getValueWithCurrencySign(balance, convertCurrencyToSign(tab?.currency)),
								style: styles.bold,
								isHidden: !!contractId,
							},
						]}
					/>
				)}
			</div>
		</div>
	);
};
