import { AccountingEventType, convertCurrencyToSign, getValueWithCurrencySign, SHORT_YEAR_FORMAT } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CreditNoteView } from '../../../../../types/creditNoteTypes';
import { creditNoteMenuList, getCreditNoteIdName } from '../../../../../utils/CreditNoteUtils';
import { Button, CreditNoteStatusTag, Icon, MenuList } from '../../../..';
import styles from './CreditNoteListItem.module.scss';

interface CreditNoteListItemProps {
	creditNoteView: CreditNoteView;
	isActive: boolean;
	onRowPress: (id: string) => void;
	onDataChange: () => void;
}

export const CreditNoteListItem = ({ creditNoteView, isActive, onRowPress, onDataChange }: CreditNoteListItemProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const { t } = useTranslation('translation');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div className={clsx(styles.item, isActive && styles.isActive)} onClick={() => onRowPress(creditNoteView.id)}>
			<span className={clsx(styles.cell, styles.defaultCell)}>
				{getCreditNoteIdName(creditNoteView)}
				<span className={styles.itemCellSubValue}>
					{creditNoteView?.contractCreditNoteNumber ? `${creditNoteView.contractCreditNoteNumber}/${creditNoteView.contractCreditNoteCount}` : ''}
				</span>
			</span>

			<span className={clsx(styles.cell, styles.defaultCell)}>{dayjs(creditNoteView?.issueDate).format(SHORT_YEAR_FORMAT)}</span>

			<div className={clsx(styles.cell, styles.defaultCell)}>
				{getValueWithCurrencySign(+creditNoteView?.creditsAmount, convertCurrencyToSign(creditNoteView.currency))}
			</div>

			<div className={styles.statusCell}>
				<CreditNoteStatusTag className={styles.badge} document={creditNoteView} refreshData={onDataChange} />
			</div>

			<div className={styles.hamburgerContainer}>
				<MenuList
					optionsList={creditNoteMenuList({
						item: { ...creditNoteView, accountingEventType: AccountingEventType.CREDIT },
						dispatch,
						navigate,
						t,
						onRefresh: () => {
							onRowPress?.(creditNoteView.id);
							onDataChange?.();
						},
					})}
					isMenuOpen={openMenuOptions}
					openMenu={() => setOpenMenuOptions(false)}
				>
					<Button type='link' color='neutral' onClick={() => setOpenMenuOptions(!openMenuOptions)}>
						<Icon imgType='hamburger' className={styles.hamburgerButton} color='neutral700' />
					</Button>
				</MenuList>
			</div>
		</div>
	);
};
