import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../../types/storeTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Button, Icon, ImportItemsProgress, MenuList, ScrollingTable, SearchBar } from '../../../components';
import { accountsReceivableAgingHeader } from '../Dashboard.utils';
import { Cell } from '../../../components/_uiComponents/Table/Table.utils';
import { priceNumberWithCurrencySignDisplay } from '../../../utils/NumberUtils';
import { PathsConfig, httpService } from '../../../services';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { useDebounce } from '../../../hooks';
import { documentsViewOptionsList } from '../../../utils/DocumentsUtils';
import { ARAgingResponse, onDownloadARAgingCSV } from './ArAging.utils';
import styles from './ArAgingPage.module.scss';

export const ArAgingPage = () => {
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [arTableData, setArTableData] = useState<ARAgingResponse>();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	useEffect(() => {
		getReceivableAging();
	}, []);

	const getReceivableAging = async (textFilter?: string) => {
		try {
			const res: ARAgingResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getReceivableAging,
					params: {
						pageSize: 200,
						pageNumber: 0,
						orderBy: 'startDate',
						order: 'ASC',
						textFilter,
					},
				})
			).data;

			setArTableData(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_AR_AGING_DATA }));
		}
	};

	const onSearch = useDebounce(getReceivableAging, 650);

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container}>
				<ImportItemsProgress onRefresh={() => getReceivableAging()} />
				<div className={styles.goBackContainer}>
					<Button type='link' color='neutral' onClick={() => navigate('../')} className={styles.goBack}>
						{t('dashboard')}
					</Button>

					<div className={styles.ellipsis}> / {t('accountsReceivableAging')}</div>
				</div>
				<header className={styles.header}>
					<h1 data-testid='upForRenewal-title'>{t('accountsReceivableAging')}</h1>
					<div className={styles.buttonsContainer}>
						<SearchBar placeholder={t('search')} onChange={onSearch} />

						<MenuList
							isMenuOpen={isOptionsMenuOpen}
							openMenu={() => setIsOptionsMenuOpen(false)}
							optionsList={documentsViewOptionsList({
								t,
								onDownloadCSV: () => onDownloadARAgingCSV(t, arTableData?.data || [], appCurrency),
							})}
						>
							<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}>
								<Icon imgType='hamburger' color='neutral700' />
							</Button>
						</MenuList>
					</div>
				</header>

				<ScrollingTable
					headers={accountsReceivableAgingHeader(convertCurrencyToSign(appCurrency))}
					rows={arTableData?.data}
					totalRow={arTableData?.total}
					headerCellClassName={styles.headerCellClassName}
					cellClassName={styles.headerCellClassName}
					totalComponent={(item) => <Cell accessor={priceNumberWithCurrencySignDisplay(item, convertCurrencyToSign(appCurrency))} />}
					containerClassName={styles.arAging}
				/>
			</div>
		</div>
	);
};
