import { PricingModelTableRow, BillingCycleStruct, CyclicDate, PricingType, Currency, BillingCycleUnit, ContractType } from '@received/pricing-model';
import dayjs from 'dayjs';
import { Contract, PricingModelTab } from '../../types/contractTypes';
import { getDatePostfix } from '../../utils/DateUtils';
import { DateType } from '../_uiComponents';
import { ContractContentProps } from '.';

export const getDateLabelByBillingCycle = (date?: Date, cycle?: BillingCycleStruct) => {
	const postfix = getDatePostfix(dayjs(date).date());
	if (cycle?.unit === BillingCycleUnit.ONE_TIME) {
		return `${dayjs(date, 'MMMM').format('MMM')} ${dayjs(date).date()}${postfix}, ${dayjs(date).year()}`;
	}
	return `${dayjs(date, 'MMMM').format('MMM')} ${dayjs(date).date()}${postfix}, ${dayjs(date).year()}`;
};

export const calculateSubTotal = (newRows: PricingModelTableRow[]) => {
	return newRows.reduce((previousValue, currentValue) => {
		return isNaN(Number(currentValue.cells['amount'].cellValue)) ? previousValue : previousValue + Number(currentValue.cells['amount'].cellValue);
	}, 0);
};

export const isPaymentPeriodDisabled = (pricingModelTab: PricingModelTab) => {
	return pricingModelTab?.billingCycle?.unit === BillingCycleUnit.ONE_TIME || pricingModelTab?.pricingType == PricingType.USAGE;
};

export const isRevRecPaymentPeriodDisabled = (pricingModelTab: PricingModelTab) => {
	return pricingModelTab?.revenueSettings?.revenueCycle?.unit === BillingCycleUnit.ONE_TIME || pricingModelTab?.pricingType == PricingType.USAGE;
};

export const mapCyclicDateToDate = (cyclicDate?: CyclicDate, cycle?: BillingCycleStruct) => {
	if (cycle?.unit === BillingCycleUnit.QUARTERLY) {
		return new Date(dayjs().year(), 0, cyclicDate?.day || 1);
	}
	return new Date(cyclicDate?.year || dayjs().year(), cyclicDate?.month ? cyclicDate?.month - 1 : 1, cyclicDate?.day || 1);
};

export const mapDateToCyclicDate = (date: Date | DateType, cycle?: BillingCycleStruct, monthInQuarter?: number): CyclicDate => {
	return {
		day: date?.getDate() || 1,
		month: cycle?.unit === BillingCycleUnit.QUARTERLY ? 1 : date ? date?.getMonth() + 1 : 1,
		year: date?.getFullYear() || dayjs().year(),
		monthInQuarter: monthInQuarter || 1,
	};
};

export const initialCyclicDay = { day: 1, month: 1, monthInQuarter: 1, year: dayjs().year() };

export interface TabSettingsProps {
	pricingModelTab: PricingModelTab;
	contract?: Contract;
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void;
	updateActivationDates: (startDate: Date | null, endDate: Date | null) => void;
	updateCurrency: (currency: Currency) => void;
}
