import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { Button, Icon, MenuList, MenuOption, SearchBar } from '../_uiComponents';
import { Subscription } from '../../types/subscriptionTypes';
import { SubscriptionDetails } from '..';
import styles from './SubscriptionSelect.module.scss';

export interface SubscriptionSelectProps {
	defaultSubscription?: Subscription;
	moveHumbuggerButton?: boolean;
	hideOptionButton?: boolean;
	isOptionsOpen?: boolean;
	selectedSubscriptionStyle?: string;
	showArrow?: boolean;
	selectContainer?: string;
	detailsClassName?: string;
	onSubscriptionSelected?(data?: Subscription): void;
	onClickNewSubscription?(): void;
}

export const SubscriptionSelect = ({
	defaultSubscription,
	hideOptionButton,
	moveHumbuggerButton,
	selectedSubscriptionStyle,
	showArrow,
	selectContainer,
	detailsClassName,
	onClickNewSubscription,
	onSubscriptionSelected,
}: SubscriptionSelectProps) => {
	const { t } = useTranslation('translation');

	const [subscriptionList, setSubscriptionList] = useState<Subscription[]>([]);
	const [isSubscriptionSelectOpen, setIsSubscriptionSelectOpen] = useState(false);
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [selectedSubscription, setSelectedSubscription] = useState<Subscription | undefined>(defaultSubscription);

	const dispatch = useDispatch();

	useEffect(() => {
		setSelectedSubscription(defaultSubscription);
	}, [defaultSubscription]);

	const getSubscriptionsList = async () => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getSubscriptionList,
			});
			setSubscriptionList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_LIST }));
		}
	};

	const onSelectSubscription = (customer?: Subscription) => {
		setSelectedSubscription(customer);
		onSubscriptionSelected && onSubscriptionSelected(customer);
		setIsSubscriptionSelectOpen(false);
	};

	const openSelect = () => {
		getSubscriptionsList();
		setIsSubscriptionSelectOpen(true);
	};

	const onOpenList = () => {
		setOpenMenuOptions(!openMenuOptions);
		if (!openMenuOptions) {
			getSubscriptionsList();
		}
	};

	const menuOptions: MenuOption[] = [
		{
			title: t('removeSubscription'),
			onPress: () => {
				setSelectedSubscription(undefined);
				onSubscriptionSelected?.(undefined);
			},
			iconType: 'delete',
			color: 'destructive',
		},
	];

	return (
		<div className={clsx(styles.dataSelectContainer, selectContainer)}>
			<div
				onClick={(e) => {
					e.preventDefault();
					selectedSubscription && openSelect();
				}}
				className={clsx(styles.customerDetails, detailsClassName)}
			>
				{selectedSubscription ? (
					<>
						<SubscriptionDetails subscription={selectedSubscription} small showArrow={showArrow} arrowUp={isSubscriptionSelectOpen} />
						{!hideOptionButton && (
							<MenuList optionsList={menuOptions} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
								<Button
									type='link'
									color='neutral'
									style={{ marginRight: moveHumbuggerButton ? '6rem' : 0 }}
									onClick={(e) => {
										e.preventDefault();
										onOpenList();
									}}
									className={styles.hamburgerButton}
								>
									<Icon imgType='hamburger' className={styles.hamburger} />
								</Button>
							</MenuList>
						)}
					</>
				) : (
					<SearchBar placeholder={t('searchAvailableSubscription')} onChange={setSearchText} onFocus={openSelect} className={styles.inputContainer} />
				)}
			</div>

			<div className={isSubscriptionSelectOpen ? styles.selectListContainer : styles.selectListContainerHidden}>
				<div className={styles.selectList}>
					{onClickNewSubscription && (
						<Button
							type='outlined'
							color='neutral'
							onClick={() => {
								onClickNewSubscription();
								setIsSubscriptionSelectOpen(false);
							}}
							className={styles.newSubscriptionButton}
						>
							{t('newSubscription')} +
						</Button>
					)}

					{subscriptionList
						.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()))
						.map((item, index) => (
							<div data-testid='customer-details-item' key={index} className={styles.selectItem} onClick={() => onSelectSubscription(item)}>
								<SubscriptionDetails subscription={item} small nameStyle={selectedSubscriptionStyle ?? styles.ellipsis} />
							</div>
						))}
				</div>
			</div>
			{isSubscriptionSelectOpen && <div className={styles.selectOverlay} onClick={() => setIsSubscriptionSelectOpen(false)}></div>}
		</div>
	);
};
