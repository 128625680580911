import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DocumentState } from '@received/pricing-model';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { httpService } from '../../../../services/httpService/httpService';
import { Contract } from '../../../../types/contractTypes';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { EmptyState } from '..';
import { PayoutListItem } from './PayoutListItem/PayoutListItem';
import { PayoutView, PayoutsResponse } from '../../../../types/PayoutsTypes';
import styles from './PayoutsTab.module.scss';

interface PayoutsTabProps {
	contractData?: Contract;
	payoutId?: string;
	selectedRowId?: string;
	className?: string;
	onRowPress?: (invoiceId?: string) => void;
	onSideBarRefresh?: () => void;
}

export const PayoutsTab = ({ contractData, payoutId, selectedRowId, className, onRowPress, onSideBarRefresh }: PayoutsTabProps) => {
	const { t } = useTranslation('translation');
	const [payoutsList, setPayoutsList] = useState<PayoutView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [listCount, setListCount] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setPayoutData();
	}, [contractData, payoutId]);

	const setPayoutData = async (page?: number) => {
		try {
			if (contractData || payoutId) {
				const res: PayoutsResponse = await getPayouts(page);
				setPayoutsList(res.entries || []);
				setIsEmpty(res.entries.length === 0);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_PAYOUTS_LIST }));
		}
	};

	const getPayouts = async (page?: number) => {
		const res: PayoutsResponse = (
			await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getPayoutsViewData,
				params: {
					contractId: contractData?.id,
					payoutId,
					pageSize: 20,
					pageNumber: page || pageNumber,
					orderBy: 'payoutDate',
					order: 'ASC',
					excludedStates: [DocumentState.AGREEMENT_DRAFT],
				},
			})
		).data;

		setListCount(res.count);
		return res;
	};

	const onScrolledToBottom = async (event: any) => {
		const bottom = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight + 15;

		if (bottom) {
			if ((contractData || payoutId) && payoutsList.length < listCount) {
				try {
					setPageNumber((prev) => prev + 1);
					const res: PayoutsResponse = await getPayouts(pageNumber + 1);
					setPayoutsList((prev) => [...prev, ...res.entries]);
				} catch (error) {
					dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_PAYOUTS_LIST }));
				}
			}
		}
	};

	return (
		<div className={clsx(styles.container, className)}>
			{!isEmpty ? (
				<>
					<div className={styles.header}>
						<div className={styles.headerItem}>{t('documentNo')}</div>
						<div className={styles.headerItem}>{t('payoutDate')}</div>
						<div className={styles.headerItem}>{t('Total')}</div>
						<div className={styles.headerItem}>{t('status')}</div>
						<div style={{ width: '2rem' }}></div>
					</div>
					<div className={styles.listContainer} onScroll={onScrolledToBottom}>
						{payoutsList.map((payout, index) => (
							<PayoutListItem
								key={index}
								index={index}
								payout={payout}
								isPayoutActive={selectedRowId === payout.id}
								onRowPress={onRowPress}
								onRefreshData={async () => {
									onSideBarRefresh ? await onSideBarRefresh() : await setPayoutData();
								}}
							/>
						))}
					</div>
				</>
			) : (
				<EmptyState
					title={t('noExistingPayouts')}
					description={t('noExistingPayoutsDescription')}
					buttonText={t('editAgreement')}
					onEdit={() => contractData?.id && navigate('/contract', { state: { contractId: contractData?.id } })}
				/>
			)}
		</div>
	);
};
