import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY } from '@received/pricing-model';
import { Store } from '../../types/storeTypes';
import {
	Button,
	DateRangeFilters,
	Icon,
	ImportItemsProgress,
	RevenueFeatureDisabled,
	RevenueRecognitionComponent,
	Schedule,
	Table,
} from '../../components';
import { fullFiltersSelectOptions } from '../../components/Filters/DateRangeFilters.utils';
import { AccountPermissionsActionsList, DatesData, Sort, SortOrder } from '../../types/generalTypes';
import { setSidebarIsOpen } from '../../storeSlices/generalSlice';
import { PermissionsMapping } from '../../services/PermissionsMapping/PermissionsMapping';
import { PathsConfig, httpService } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { RevRecView } from '../../types/revRecTypes';
import { filterYearsList } from '../../constants/generalConstants';
import { initialMainTimePeriod, revRecTabTableHeader } from './RevenueRecognition.utils';
import styles from './RevenueRecognition.module.scss';

const numberOfItems = 20;

export const RevenueRecognition = () => {
	const { t } = useTranslation('translation');

	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const { appCurrency } = useSelector((store: Store) => store.general);

	const [revRecList, setRevRecList] = useState<RevRecView[]>([]);
	const [sort, setSort] = useState<Sort>({ orderBy: 'postingDate', order: SortOrder.ASC });
	const [timePeriod, setTimePeriod] = useState<DatesData>(initialMainTimePeriod);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getRevRecData();
	}, [sort, timePeriod]);

	const getRevRecData = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getRevenueTableView,
					params: {
						pageSize: numberOfItems,
						pageNumber: 0,
						orderBy: sort.orderBy,
						order: sort.order,
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
					},
				})
			).data;

			setRevRecList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={getRevRecData} />
				<header className={styles.pageHeader}>
					<h1>{t('revenueRecognition')}</h1>

					<Button type='outlined' color='neutral' onClick={() => navigate('./revenue-recognition-records')}>
						{t('allActivities')}
						<Icon imgType='navigate' color='neutral700' />
					</Button>
				</header>
				<PermissionsMapping actionType={AccountPermissionsActionsList.VIEW_REV_REC} disabledFeatureChildren={<RevenueFeatureDisabled />}>
					<DateRangeFilters
						selectClassName={styles.reportSelectClassName}
						inputClassName={styles.reportSelectInputClassName}
						mediumInput
						data={fullFiltersSelectOptions}
						defaultSelectValue={timePeriod.title}
						callback={setTimePeriod}
						width={17}
					/>

					<RevenueRecognitionComponent
						hideHeader
						timeFilter={timePeriod}
						classNames={{ revRecContainer: styles.rowContainer, stackBarGraph: styles.stackBarGraph }}
					/>

					<Schedule title='schedule' currency={DEFAULT_CURRENCY} yearsList={filterYearsList} />

					<Table
						filters={
							<Button type='outlined' color='neutral' onClick={() => navigate('./revenue-recognition-records')}>
								{t('allActivities')}
								<Icon imgType='navigate' color='neutral700' />
							</Button>
						}
						sort={sort}
						title={t('records')}
						header={revRecTabTableHeader(appCurrency)}
						rows={revRecList}
						className={styles.tableContainer}
						hideTableFooter
						onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					/>
				</PermissionsMapping>
			</div>
		</div>
	);
};
