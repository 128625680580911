import { useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';
import { IntegrationProvider } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { SelectItem } from '../../../../types/generalTypes';
import { Customer } from '../../../../types/customerTypes';
import { Button } from '../../Button/Button';
import { Header } from '../../Header/Header';
import { Icon, IconImgType } from '../../Icon';
import { images } from '../../../../constants/images';
import { EmptyStateCard, InputIconType } from '../../..';
import { StripeCard, StripeCardResponse } from '../../../../types/integrationTypes';
import { getStripeConnectedCards } from '../../../../services/integrationService/integrationService';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { CreditCardSelectItem, Errors, getInputLogo, getStripeCreditCardErrors, INITIAL_INPUT_ICON } from './StripeCardSelect.utils';
import { useDebounce } from '../../../../hooks';
import { handleKeyDown } from '../Select/Select.utils';
import { creditCardLabel } from '../../../../utils/GeneralUtils';
import styles from './StripeCardSelect.module.scss';

export interface StripeCardSelectProps {
	containerStyle?: string;
	className?: string;
	tabIndex?: number;
	paymentGatewayId?: string;
	supplierId?: string;
	suppliers?: Customer[];
	displayCustomerNameOnly?: boolean;
	defaultCustomer?: StripeCard;
	tooltipComponent?: React.ReactNode;
	title?: string;
	iconType?: IconImgType | null;
	showCheckoutComment?: boolean;
	headerLabelStyle?: string;
	showError?: boolean;
	onChange(val: string, selected: StripeCard | undefined): void;
}

export const StripeCardSelect = ({
	containerStyle,
	supplierId,
	className,
	defaultCustomer,
	suppliers,
	tabIndex,
	tooltipComponent,
	displayCustomerNameOnly,
	paymentGatewayId,
	showCheckoutComment,
	title,
	iconType,
	headerLabelStyle,
	showError,
	onChange,
}: StripeCardSelectProps) => {
	const searchRef = useRef<HTMLInputElement>(null);
	const containerRef = useRef<HTMLInputElement>(null);

	const [referenceElement, setReferenceElement] = useState<any>(null);
	const [popperElement, setPopperElement] = useState<any>(null);
	const [selectedValue, setSelectedValue] = useState<SelectItem | string>();
	const [selectLabel, setSelectLabel] = useState<string | number>('');
	const [isOpen, setIsOpen] = useState(false);
	const [focusedItem, setFocusedItem] = useState<number>();

	const [options, setOptions] = useState<SelectItem[]>([]); // shown select options
	const [customers, setCustomers] = useState<StripeCard[]>([]); //all fetched customers
	const [inputLogo, setInputLogo] = useState<InputIconType>(INITIAL_INPUT_ICON);
	const [searchText, setSearchText] = useState<{ text?: string; isValid: boolean }>({ isValid: true });
	const [fetchMoreData, setFetchMoreData] = useState<string | null>();
	const [selectedItem, setSelectedItem] = useState<StripeCard | string>();

	const { t } = useTranslation('translation');
	const searchDebounce = useRef<NodeJS.Timeout>(null);
	const dispatch = useDispatch();
	const {
		styles: popperStyle,
		attributes,
		update,
	} = usePopper(referenceElement, popperElement, {
		placement: 'auto',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'flip',
				options: {
					allowedAutoPlacements: ['top', 'bottom'],
					flipVariations: true,
					fallbackPlacements: ['top'],
				},
			},
		],
	});

	useEffect(() => {
		const defaultVal = getDefaultValue();
		if (defaultVal) {
			setSelectLabel(typeof defaultVal === 'string' ? defaultVal : defaultVal?.label);
			setSelectedValue(defaultVal);
			setSelectedItem(defaultCustomer);
		} else {
			setSelectLabel('');
			setSelectedValue(undefined);
			setSelectedItem(undefined);
		}
	}, [defaultCustomer, searchText?.text, displayCustomerNameOnly]);

	useEffect(() => {
		setData(true);
	}, [paymentGatewayId, suppliers, supplierId]);

	useEffect(() => {
		setCustomersOptions();
		setInputLogo(getInputLogo(displayCustomerNameOnly, defaultCustomer?.cardBrand));
	}, [customers, displayCustomerNameOnly]);

	const getDefaultValue = () => {
		const res = searchText?.text
			? searchText?.text
			: defaultCustomer
			? {
					label: creditCardLabel(defaultCustomer, displayCustomerNameOnly) || '',
					value: defaultCustomer?.id || '',
					customComponent: () => <CreditCardSelectItem item={defaultCustomer} displayCustomerNameOnly={displayCustomerNameOnly} />,
			  }
			: undefined;
		return res;
	};

	const setData = async (setDefault?: boolean, filter?: string, paymentGatewayIdVal?: string) => {
		try {
			const stripeAccountID = paymentGatewayId || paymentGatewayIdVal;
			let customers: StripeCard[] = [];
			if (stripeAccountID) {
				const res: StripeCardResponse = await getStripeConnectedCards(dispatch, IntegrationProvider.STRIPE, stripeAccountID, filter, fetchMoreData);
				customers = res.customers;
				setDefault && setSelectedItem(defaultCustomer);
			}
			setCustomers(customers);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_STRIPE_CARDS_DATA }));
		}
	};

	const setCustomersOptions = async () => {
		const options =
			customers.map((item: StripeCard) => ({
				label: creditCardLabel(item, displayCustomerNameOnly) || '',
				value: item.id,
				customComponent: () => <CreditCardSelectItem item={item} displayCustomerNameOnly={displayCustomerNameOnly} />,
			})) || [];
		setOptions(options);
	};

	const onChangeInput = async (value: string) => {
		if (!value) {
			onValueChange(value);
		} else {
			setSelectLabel(value);
			setIsOpen(true);
			await filterOptions(value);
		}
	};

	const filterOptions = (text: string) => {
		searchDebounce.current && clearInterval(searchDebounce.current);
		setSearchText({ text: text.length > 0 ? text : undefined, isValid: text.length >= 3 || text.length == 0 });
		setInputLogo(getInputLogo(displayCustomerNameOnly));

		setSelectedItem(text);
		setFetchMoreData(undefined);
		if (text.length >= 3) {
			onSearch(false, text, paymentGatewayId);
		} else if (text.length == 0) {
			onSearch();
		}
	};

	const onValueChange = (item: SelectItem | string) => {
		setSelectedValue(item);
		setSelectLabel(typeof item === 'string' ? item : item?.label);
		const value = typeof item === 'string' ? item : item?.value;
		setSearchText({ text: undefined, isValid: true });
		const selected = customers?.find((customer: StripeCard) => customer.id === value);
		setInputLogo(getInputLogo(displayCustomerNameOnly, selected?.cardBrand));
		setSelectedItem(selected);
		onChange(value, selected);
		setIsOpen(false);
		setFocusedItem(undefined);
	};

	const onRemoveSelectedItem = () => {
		onChange('', undefined);
		setSelectedValue(undefined);
		setSelectLabel('');
		setInputLogo(INITIAL_INPUT_ICON);
		setSearchText({ text: undefined, isValid: true });
		setIsOpen(false);
		setFocusedItem(undefined);
		setSelectedItem(undefined);
	};

	const onOpenFromArrow = () => {
		setIsOpen(true);
		searchRef.current && searchRef?.current.focus();
	};

	const onClose = () => {
		setIsOpen(false);
		setFocusedItem(undefined);
		setData();
	};

	const onScroll = async () => {
		try {
			if (fetchMoreData && paymentGatewayId) {
				const res: StripeCardResponse = await getStripeConnectedCards(
					dispatch,
					IntegrationProvider.STRIPE,
					paymentGatewayId,
					searchText?.text,
					fetchMoreData,
				);
				setFetchMoreData(res.startAfter);
				setCustomers((prev) => [...prev, ...res.customers]);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_STRIPE_CARDS_DATA }));
		}
	};

	const isErrorShown = () => {
		// the error shown incase:
		// 1. displayCustomerNameOnly -> depend on is auto charge
		// 2. for cards with errors
		if (displayCustomerNameOnly) return false;
		return showCheckoutComment && getStripeCreditCardErrors(customers.find((card) => card.id == defaultCustomer?.id)).length > 0;
	};

	const onSearch = useDebounce(setData, 650);

	return (
		<div ref={containerRef} data-testid='stripe-select-component' id='stripe-select' className={containerStyle}>
			<Header
				title={t(title || 'relatedCustomerOptional')}
				id='stripe-header-select'
				tooltipComponent={tooltipComponent}
				iconType={iconType}
				labelStyle={headerLabelStyle}
			/>

			<div
				style={popperStyle.offset}
				ref={(ref) => setReferenceElement(ref)}
				className={clsx(styles.input, (!searchText.isValid || showError) && styles.errorContainer, className)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						onOpenFromArrow();
					}
				}}
				onClick={() => {
					update?.();
					onOpenFromArrow();
				}}
				id='stripe-select-component-input'
				data-testid='stripe-select-input-button-related-customer'
			>
				<Icon imgType={inputLogo.imgType} width={inputLogo?.width} color={inputLogo?.color} className={styles.inputIcon} />
				<input
					id='stripe-select-input'
					data-testid='stripe-select-input-related-customer'
					tabIndex={tabIndex}
					placeholder={t('searchARelated')}
					className={styles.inputText}
					onChange={(event) => onChangeInput(event.target.value)}
					value={selectLabel}
					onFocus={(event) => event.target.select()}
					onKeyDown={(e) => isOpen && handleKeyDown(e, focusedItem, options, setFocusedItem, selectedValue)}
					autoComplete='off'
				/>

				{typeof selectedItem == 'object' && (
					<Errors className={styles.errors} item={selectedItem} displayCustomerNameOnly={displayCustomerNameOnly} />
				)}

				{selectLabel && (
					<Button color='neutral' type='outlined' className={styles.removeSelectedOption} onClick={onRemoveSelectedItem}>
						<Icon id='remove-selected-icon' imgType='x_icon' color='neutral700' width={0.8} />
					</Button>
				)}

				<Icon id='stripe-select-icon' imgType='arrow_down' color='neutral700' width={1.5} className={isOpen ? styles.arrowUp : styles.arrowDown} />
			</div>

			<span className={clsx(isErrorShown() ? styles.error : styles.hidden)}>*{t('checkoutLinkWillBeSent')}.</span>

			{ReactDOM.createPortal(
				<>
					<div
						ref={(ref) => setPopperElement(ref)}
						{...attributes.popper}
						className={clsx(styles.selectDropdownContainer, isOpen ? styles.selectDropdownContainerOpen : styles.selectDropdownClose)}
						style={{
							minWidth: containerRef?.current?.offsetWidth,
							...popperStyle.popper,
						}}
						onScroll={onScroll}
					>
						{options?.length ? (
							options?.map((item, index) => {
								const selected = typeof selectedValue === 'string' ? selectedValue : selectedValue?.label;
								return (
									<div
										className={clsx(styles.selectItem, item?.label === selected && styles.selected, index === focusedItem && styles.focusedItem)}
										id={`stripe-searchable-select-list-item-${index}`}
										key={index}
										onClick={() => onValueChange(item)}
									>
										{item.customComponent ? item.customComponent(item) : item.label}
									</div>
								);
							})
						) : (
							<EmptyStateCard
								containerStyle={styles.empty}
								showIcon
								src={images.emptyGraph}
								imgAlt='empty-graph'
								title={t('noDataSource')}
								subTitle={t('emptyStateSubtitle')}
							/>
						)}
					</div>
					{isOpen && <div className={styles.selectOverlay} onClick={onClose}></div>}
				</>,
				document.body,
			)}
		</div>
	);
};
