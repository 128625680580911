import { configureStore } from '@reduxjs/toolkit';
import errorSuccessReducer from './storeSlices/errorSuccessSlice';
import generalReducer from './storeSlices/generalSlice';
import integrationsReducer from './storeSlices/IntegrationsSlice';
import userReducer from './storeSlices/userSlice';

const store = configureStore({
	reducer: {
		general: generalReducer,
		user: userReducer,
		errorSuccess: errorSuccessReducer,
		integrations: integrationsReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: ['errorSuccess/setOpenSuccessErrorModal', 'general/setShowLoader', 'general/setShowHideToastNotification'],
				ignoredPaths: ['general.toastNotification.additionalData.onRefresh', 'payload.additionalData.onRefresh'],
			},
		}),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
