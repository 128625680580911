import React from 'react';
import clsx from 'clsx';
import { Icon } from '../Icon/Icon';
import { IconImgType } from '../Icon/IconOptions';
import { Tooltip } from '../Tooltip/Tooltip';
import styles from './Header.module.scss';

export interface HeaderProps {
	title?: string;
	iconType?: IconImgType | null;
	containerStyle?: string;
	labelStyle?: string;
	tooltipComponent?: React.ReactNode;
	id?: string;
	headerLeftChildren?: React.ReactNode;
}

export const Header = ({ title, iconType, containerStyle, id, tooltipComponent, labelStyle, headerLeftChildren }: HeaderProps) => {
	return (
		<div className={clsx(styles.container, containerStyle)}>
			<div className={styles.titleIconContainer}>
				<span id={id} className={clsx(styles.formTitle, labelStyle)}>
					{title}
				</span>
				<Tooltip blackTooltip disabled={!tooltipComponent} tooltipComponent={tooltipComponent}>
					{iconType && <Icon imgType={iconType} className={styles.icon} />}
				</Tooltip>
			</div>
			<div className={styles.headerLeftChildrenContainer}>{headerLeftChildren}</div>
		</div>
	);
};
