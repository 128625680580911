import { isValidEmail, isValidPassword } from '../../utils/validationUtils';

export const loginValidation = (data: any) => {
	const validationData = { ...data };
	let isValid = true;

	if (!isValidEmail(validationData.email)) {
		validationData.emailError = true;
		isValid = false;
	}

	if (!isValidPassword(validationData.password)) {
		validationData.passwordError = true;
		isValid = false;
	}

	return { isValid, data: validationData };
};
