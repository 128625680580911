import { useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { BillingCycleUnit, EventsGenerationMethod, RevenueRecognitionPeriod } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import {
	DEFAULT_BILLING_CYCLE_VALUE,
	billingCycleSelect,
	recognitionMethodSelect,
	recognitionPeriodSelect,
} from '../../../../constants/generalConstants';
import { Button, DatePicker, Icon, Select } from '../../../../components/_uiComponents';
import { onClickUpgradeRevRecFeature } from '../../../../utils/GeneralUtils';
import { PricingModelTab } from '../../../../types/contractTypes';
import { DATE_FORMAT, DATE_FORMAT_FOR_SERVER } from '../../../../constants/templateConstants';
import { FooterTypeOptions } from '../../../../components/_uiComponents/DatePicker/Footer/Footer';
import styles from './PricingModelRevRecSettings.module.scss';

interface PricingModelRevRecSettingsProps {
	pricingModelTab?: PricingModelTab;
	disabledFeature?: boolean;
	updatePricingModelData?: (data: PricingModelTab, instantUpdate?: boolean) => void;
}

export const PricingModelRevRecSettings = ({ pricingModelTab, disabledFeature, updatePricingModelData }: PricingModelRevRecSettingsProps) => {
	const { t } = useTranslation('translation');
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [customSelectedValue, setCustomSelectedValue] = useState<string>();

	useEffect(() => {
		// customSelectedValue shown in period select only for RevenueRecognitionPeriod.CUSTOM
		if (!isDatePickerOpen && pricingModelTab?.revenueSettings?.recognitionPeriodStart && pricingModelTab?.revenueSettings?.recognitionPeriodEnd) {
			setCustomSelectedValue(
				`${dayjs(pricingModelTab?.revenueSettings?.recognitionPeriodStart).format(DATE_FORMAT)} - ${dayjs(
					pricingModelTab?.revenueSettings?.recognitionPeriodEnd,
				).format(DATE_FORMAT)}`,
			);
		}
	}, [
		isDatePickerOpen,
		pricingModelTab?.revenueSettings?.revenueRecognitionPeriod,
		pricingModelTab?.revenueSettings?.recognitionPeriodStart,
		pricingModelTab?.revenueSettings?.recognitionPeriodEnd,
	]);

	const onSelectPeriod = (value: RevenueRecognitionPeriod) => {
		if (value == RevenueRecognitionPeriod.CUSTOM) {
			setCustomSelectedValue(t('customDates'));
			return setIsDatePickerOpen(true);
		}

		setCustomSelectedValue(undefined);
		pricingModelTab &&
			updatePricingModelData?.(
				{
					...pricingModelTab,
					revenueSettings: {
						...pricingModelTab?.revenueSettings,
						revenueRecognitionPeriod: value,
						recognitionPeriodStart: null,
						recognitionPeriodEnd: null,
					},
				},
				true,
			);
	};

	const onCloseCustomWithoutDates = () => {
		if (!pricingModelTab?.revenueSettings?.recognitionPeriodStart || !pricingModelTab?.revenueSettings.recognitionPeriodEnd) {
			setCustomSelectedValue(undefined);
			pricingModelTab &&
				updatePricingModelData?.(
					{
						...pricingModelTab,
						revenueSettings: {
							...pricingModelTab?.revenueSettings,
							recognitionPeriodStart: null,
							recognitionPeriodEnd: null,
						},
					},
					true,
				);
		}
	};

	return (
		<div className={styles.settingsBarContainer}>
			<div className={styles.header}>
				<span className={styles.settingsBarTitle}>{t('revenueRecognition')}</span>
				{disabledFeature && (
					<Button className={styles.needLicenseButton} color='calming' type='secondary' onClick={onClickUpgradeRevRecFeature}>
						<Icon imgType='spark' color='calming' width={2} />
						{t('upgrade')}
					</Button>
				)}
			</div>

			<div className={clsx(styles.settingsContent, disabledFeature && styles.disabledFeature)}>
				<Select
					title={t('revenueMethod')}
					data={recognitionMethodSelect}
					iconType={'information'}
					onChange={(revenueRecognitionMethod: EventsGenerationMethod) =>
						pricingModelTab &&
						updatePricingModelData?.({
							...pricingModelTab,
							revenueSettings: { ...pricingModelTab.revenueSettings, revenueRecognitionMethod },
						})
					}
					defaultValue={pricingModelTab?.revenueSettings?.revenueRecognitionMethod}
					className={styles.select}
					style={{ width: '100%' }}
					disabled={disabledFeature}
					placeholder={t('selectMethod')}
				/>

				{pricingModelTab?.revenueSettings?.revenueRecognitionMethod !== EventsGenerationMethod.IMMEDIATE && (
					<>
						<Select
							title={t('cycle')}
							data={billingCycleSelect(false, true)}
							onChange={(revenueCycleUnit: BillingCycleUnit) =>
								pricingModelTab &&
								updatePricingModelData?.({
									...pricingModelTab,
									revenueSettings: {
										...pricingModelTab.revenueSettings,
										revenueCycle: { unit: revenueCycleUnit, cycle: DEFAULT_BILLING_CYCLE_VALUE },
									},
								})
							}
							defaultValue={pricingModelTab?.revenueSettings?.revenueCycle?.unit}
							className={styles.select}
							style={{ width: '100%' }}
							disabled={disabledFeature}
							placeholder={t('selectCycle')}
						/>

						<Select
							title={t('period')}
							onChange={onSelectPeriod}
							defaultValue={customSelectedValue || pricingModelTab?.revenueSettings?.revenueRecognitionPeriod}
							data={recognitionPeriodSelect}
							className={styles.select}
							style={{ width: '100%' }}
							width={18}
							disabled={disabledFeature}
							placeholder={t('selectPeriod')}
						/>
					</>
				)}
			</div>

			<DatePicker
				hideInput
				rangePicker
				defaultFromDate={pricingModelTab?.revenueSettings?.recognitionPeriodStart}
				defaultToDate={pricingModelTab?.revenueSettings?.recognitionPeriodEnd}
				openDatePicker={isDatePickerOpen}
				updateIsOpen={(isOpen) => {
					setIsDatePickerOpen(isOpen);
					!isOpen && onCloseCustomWithoutDates();
				}}
				updateDate={(date) => {
					date.fromDate &&
						date.toDate &&
						pricingModelTab &&
						updatePricingModelData?.(
							{
								...pricingModelTab,
								revenueSettings: {
									...pricingModelTab?.revenueSettings,
									revenueRecognitionPeriod: RevenueRecognitionPeriod.CUSTOM,
									// unknown as Date used here to handle type problem compared to the contact
									recognitionPeriodStart: dayjs(date.fromDate).format(DATE_FORMAT_FOR_SERVER) as unknown as Date,
									recognitionPeriodEnd: dayjs(date.toDate).format(DATE_FORMAT_FOR_SERVER) as unknown as Date,
								},
							},
							true,
						);
				}}
				footerType={FooterTypeOptions.ADD_YEARS}
			/>
		</div>
	);
};
