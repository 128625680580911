import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentState } from '@received/pricing-model';
import { ProductUsage, ProductUseProps, SummaryBar, InvoiceTable, StackBarGraph, StackBarDataset, StackBarGraphData } from '../..';
import { NumberFormat, Sort, SortOrder } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { UsageProductAnalyticsList } from '../../../types/usageTypes';
import { InvoiceView, InvoiceSummary, InvoiceCashflow } from '../../../types/invoiceTypes';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { sumNumbersArray } from '../../../utils/NumberUtils';
import { initialStackBarGraphNumberFormat, initialStackBarGraphState } from '../../_uiComponents/Graphs/StackBarGraph/StackBarGraph.utils';
import { collectionStatusDataHandler } from '../../../utils/GraphsUtils';
import { colors } from '../../../constants/colors';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { cashTimeUnitHandler } from '../../../utils/DateUtils';
import { customerLastActivityTableHeader, getSidebarTypeByDocumentType } from './EnterpriseOverviewTab.utils';
import { getSummaryData, getSummaryBlocksData } from '../../../utils/InvoiceUtils';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { InvoiceResponse } from '../../../pages/_Billing/Invoices/AllInvoices/AllInvoices.utils';
import { stackBarGraphUsageDataHandler } from '../../../pages/Usage/Usage.utils';
import styles from './EnterpriseOverviewTab.module.scss';

export const OverviewTab = ({ isActive, customerData, timePeriod, sideBarFilters, refreshTabs, setOpenSideBarById }: CustomerTabProps) => {
	const { t } = useTranslation('translation');

	const { toastNotification } = useSelector((store: Store) => store.general);

	const [isCashflowEmpty, setIsCashflowEmpty] = useState(false);
	const [invoiceActivitiesList, setInvoiceActivitiesList] = useState<InvoiceView[]>([]);
	const [cashflow, setCashflow] = useState<StackBarGraphData>(initialStackBarGraphState);
	const [isUsageDataEmpty, setIsUsageDataEmpty] = useState(false);
	const [totalSummary, setTotalSummary] = useState<InvoiceSummary>();
	const [stackBarDataFormat, setStackBarDataFormat] = useState<NumberFormat>(initialStackBarGraphNumberFormat);
	const [usageSummaryData, setUsageSummaryData] = useState<ProductUseProps>({
		datasets: [],
		labels: [],
	});
	const [sortLate, setSortLate] = useState<Sort>({ orderBy: 'updatedAt', order: SortOrder.DESC });

	const numberOfItems = '20';
	const dispatch = useDispatch();

	useEffect(() => {
		isActive && customerData && refreshData(customerData.id);
	}, [
		customerData,
		refreshTabs,
		customerData?.id,
		timePeriod,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
	]);

	useEffect(() => {
		customerData && getInvoicesActivitiesData();
	}, [sortLate, customerData]);

	const refreshData = async (id?: string) => {
		await Promise.all([getInvoicesActivitiesData(), getCashflowData(), getUsageData(id)]);

		const summary = await getSummaryData(dispatch, timePeriod.from, timePeriod.to, customerData?.id ? [customerData?.id] : undefined);
		setTotalSummary(summary);
	};

	const getCashflowData = async () => {
		try {
			const res: InvoiceCashflow[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getCollectionStatus,
					params: {
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to),
						customerId: customerData?.id,
					},
				})
			).data;

			const { dataForGraph, isEmpty, dataFormat } = collectionStatusDataHandler(
				res,
				t,
				cashTimeUnitHandler(timePeriod.from, timePeriod.to),
				timePeriod.value,
			);
			setCashflow(dataForGraph);
			setIsCashflowEmpty(isEmpty);
			setStackBarDataFormat(dataFormat);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	const getInvoicesActivitiesData = async () => {
		try {
			const invoicesActivitiesRes: InvoiceResponse = (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.getDocumentsView,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: 0,
						orderBy: sortLate.orderBy || 'updatedAt',
						order: sortLate.order,
						customerIds: [customerData?.id],
						excludedStates: [DocumentState.CONTRACT_DRAFT, DocumentState.DRAFT],
						fromIssueDate: timePeriod.from,
						toIssueDate: timePeriod.to,
						...sideBarFilters?.filterData,
					},
				})
			).data;

			setInvoiceActivitiesList(invoicesActivitiesRes.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_INVOICE_VIEW }));
		}
	};

	const getUsageData = async (customerId?: string) => {
		try {
			const res: UsageProductAnalyticsList[] = (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.getUsageProductAnalyticsList,
					params: {
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						customerIds: [customerId],
					},
				})
			).data;

			const { convertedData, isEmptyData } = stackBarGraphUsageDataHandler(
				res,
				colors.primary,
				cashTimeUnitHandler(timePeriod.from, timePeriod.to),
				false,
			);
			setIsUsageDataEmpty(isEmptyData);

			setUsageSummaryData({
				labels: convertedData.datasets.map((item) => item.label || ''),
				datasets: convertedData.datasets.map((item) => sumNumbersArray(item.data.map((item) => item || 0))),
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	return (
		<div className={styles.overviewContainer}>
			<div className={styles.statistic}>
				<SummaryBar hideDiviner small summary={getSummaryBlocksData(t, totalSummary)} className={styles.summarySection} />

				<div className={styles.cashflow}>
					<span className={styles.cardTitle}>{t('collectionStatus')}</span>
					<span className={styles.cardDescription}>{timePeriod.title}</span>
					<div className={styles.overflowGraph}>
						<StackBarGraph
							isCashflow
							isEmpty={isCashflowEmpty}
							dataFormat={stackBarDataFormat}
							emptyTitle={t('noDataSource')}
							chartData={{ labels: cashflow.labels, datasets: cashflow.datasets as StackBarDataset[] }}
						/>
					</div>
				</div>
			</div>

			<div className={styles.bottomContainer}>
				<InvoiceTable
					refreshData={() => customerData && refreshData(customerData?.id)}
					tableProps={{
						sort: sortLate,
						hideTableFooter: true,
						title: t('latestActivities'),
						header: customerLastActivityTableHeader(() => refreshData(customerData?.id)),
						rows: invoiceActivitiesList,
						className: styles.tableContainer,
						onRowPress: (data) => setOpenSideBarById?.({ sideBarType: getSidebarTypeByDocumentType(data), id: data.id }),
						onFlipOrder: (accessor) => setSortLate({ orderBy: accessor, order: sortLate.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }),
					}}
				/>

				<div className={styles.usageContainer}>
					<span className={styles.cardTitle}>{t('usage')}</span>
					<span className={styles.cardDescription}>{timePeriod.title}</span>
					<div className={styles.productUseContainer}>
						<ProductUsage
							isEmpty={isUsageDataEmpty}
							emptyTitle={t('noDataSource')}
							labels={usageSummaryData.labels}
							datasets={usageSummaryData.datasets}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
