import React from 'react';
import clsx from 'clsx';
import {
	BillingCycleStruct,
	CellTypes,
	PricingType,
	convertCurrencyToSign,
	Currency,
	EventsGenerationMethod,
	BillingCycleUnit,
} from '@received/pricing-model';
import { colors } from '../../constants/colors';
import { billingCycleLabels, recognitionMethodLabels } from '../../constants/generalConstants';
import { getValueWithCurrencySign } from '../../utils/NumberUtils';
import { images } from '../../constants/images';
import styles from './PricingModelDetails.module.scss';

interface SideBarProductDetailsData {
	key: string | number;
	value: string | number;
	style?: string;
	subLine?: SideBarProductDetailsData;
	isHidden?: boolean;
}

export interface SideBarProductDetailsProps {
	header: { key: string; value?: React.ReactNode };
	metaData?: SideBarProductDetailsData[];
	additionalData?: SideBarProductDetailsData[];
}

export const SideBarProductDetails = ({ header, metaData, additionalData }: SideBarProductDetailsProps) => {
	return (
		<div>
			<div className={styles.dataRow}>
				<span className={styles.title} style={{ marginBottom: '1.4rem' }}>
					{header.key}
				</span>
				<span className={styles.description}>{header?.value}</span>
			</div>

			<div>
				{metaData?.map(({ key, value, style, subLine }, index) => (
					<div className={clsx(styles.flexColumn, styles.dataRowBorder)} key={index}>
						<div className={styles.dataRow}>
							<span className={clsx(styles.subText, style)}>{key}</span>
							<span className={clsx(styles.subText, style)}>{value}</span>
						</div>
						{subLine && (
							<div className={styles.dataRow}>
								<span className={clsx(styles.subText)}>{subLine?.key}</span>
								<span className={clsx(styles.subText)}>{subLine?.value}</span>
							</div>
						)}
					</div>
				))}

				{additionalData?.map(({ key, value, style, isHidden }, index) => {
					return (
						!isHidden && (
							<div key={index} className={styles.contractDetails}>
								<span className={clsx(style, styles.text)}>{key}</span>
								<span className={clsx(style, styles.text)}>{value}</span>
							</div>
						)
					);
				})}
			</div>
		</div>
	);
};

export const getBillingCycleLabel = (cycle?: BillingCycleStruct) => {
	switch (cycle?.unit) {
		case BillingCycleUnit.ONE_TIME:
			return billingCycleLabels.ONE_TIME;
		case BillingCycleUnit.MONTHLY:
			return billingCycleLabels.MONTHLY;
		case BillingCycleUnit.QUARTERLY:
			return billingCycleLabels.QUARTERLY;
		case BillingCycleUnit.YEARLY:
			return billingCycleLabels.YEARLY;
	}
};

export const getLabelForQuantity = (value: string | number, type: CellTypes, currency: Currency) => {
	switch (type) {
		case CellTypes.FORMULA:
		case CellTypes.COUNT:
			return `${+value} Qty.`;
		default:
			return getValueWithCurrencySign(+value, convertCurrencyToSign(currency));
	}
};
export const getLabelForPrice = (value: string | number, type: CellTypes, currency: Currency) => {
	switch (type) {
		case CellTypes.PERCENTAGE:
			return `${value}%`;
		default:
			return getValueWithCurrencySign(+value, convertCurrencyToSign(currency));
	}
};

export const getBankAccountLabel = (account: string) => {
	const accountNumber = account.slice(-4);
	const label = `••••${accountNumber}`;
	return label;
};

export const getPaymentLabel = (t: any, isStripeConnected: boolean, bankDataExist?: boolean) => {
	if (isStripeConnected && bankDataExist) {
		return [t(isStripeConnected ? 'stripe' : ''), t(bankDataExist ? 'bankTransfer' : '')].join(' • ');
	} else if (isStripeConnected) {
		return t('stripe');
	} else if (bankDataExist) {
		return t('bankTransfer');
	}
};
