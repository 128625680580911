import dayjs from 'dayjs';
import { Currency, ProductPerformanceType, convertCurrencyToSign } from '@received/pricing-model';
import { DATE_FORMAT_FOR_SERVER } from '../../constants/templateConstants';
import { FilterTimeOptions, filtersSelectLabels } from '../../components/Filters/DateRangeFilters.utils';
import { absoluteValueArray, getNumberingFormat, numberWithCommas, priceNumberWithCurrencySignDisplay } from '../../utils/NumberUtils';
import { ProductAnalyticsList, TimeUnit } from '../../types/generalTypes';
import { monthLabel } from '../../utils/GraphsUtils';
import { colors } from '../../constants/colors';
import styles from './Reports.module.scss';

export const initialMainTimePeriod = {
	from: dayjs().startOf('year').format(DATE_FORMAT_FOR_SERVER),
	to: dayjs().endOf('year').format(DATE_FORMAT_FOR_SERVER),
	title: `${filtersSelectLabels.CURRENT_YEAR} (${dayjs().year()})`,
	value: FilterTimeOptions.CURRENT_YEAR,
};

export interface ReportsMetrics {
	todayArr: number;
	todayArrYoy: number;
	quarterlyArrGrowth: number;
	quarterlyArrGrowthQoq: number;
	totalCustomers: number;
	totalCustomersYoy: number;
	totalContracts: number;
	totalContractsYoy: number;
	avgArrPerCustomer: number;
	avgArrPerCustomerMom: number;
	avgRevenuePerCustomer: number;
	avgRevenuePerCustomerMom: number;
}

export interface CustomersMetrics {
	totalCount: number;
	newCount: number;
	data: GraphData[];
}

export interface GraphData {
	date: Date;
	amount: number;
}

export interface ProductPerformanceMetrics {
	date: Date;
	amountsByProduct: ProductAnalyticsList[];
}

export const initialSummaryData = {
	todayArr: 0,
	todayArrYoy: 0,
	quarterlyArrGrowth: 0,
	quarterlyArrGrowthQoq: 0,
	totalCustomers: 0,
	totalCustomersYoy: 0,
	totalContracts: 0,
	totalContractsYoy: 0,
	avgArrPerCustomer: 0,
	avgArrPerCustomerMom: 0,
	avgRevenuePerCustomer: 0,
	avgRevenuePerCustomerMom: 0,
};

export const leftMetrics = (t: any, data: ReportsMetrics, currency: Currency) => {
	return [
		{
			title: t('todaysArr'),
			quantity: priceNumberWithCurrencySignDisplay(data.todayArr, convertCurrencyToSign(currency)),
			subQuantity: data.todayArrYoy,
			description: `${Math.abs(data.todayArrYoy)}% YoY`,
		},
		{
			title: t('quarterlyArrGrowth'),
			quantity: priceNumberWithCurrencySignDisplay(data.quarterlyArrGrowth, convertCurrencyToSign(currency)),
			subQuantity: data.quarterlyArrGrowthQoq,
			description: `${Math.abs(data.quarterlyArrGrowthQoq)}% QoQ`,
		},
	];
};

export const rightMetrics = (t: any, data: any, currency: Currency) => {
	return [
		{
			title: t('activeCustomers'),
			quantity: numberWithCommas(data.totalCustomers),
			subQuantity: data.totalCustomersYoy,
			description: `${Math.abs(data.totalCustomersYoy.toFixed())}% YoY`,
			styles: styles.analyticsCard,
		},
		{
			title: t('activeContracts'),
			quantity: numberWithCommas(data.totalContracts),
			subQuantity: data.totalContractsYoy,
			description: `${Math.abs(data.totalContractsYoy.toFixed())}% YoY`,
			styles: styles.analyticsCard,
		},
		{
			title: t('avgArrPerCustomers'),
			quantity: priceNumberWithCurrencySignDisplay(data.avgArrPerCustomer, convertCurrencyToSign(currency)),
			subQuantity: data.avgArrPerCustomerMom,
			description: `${Math.abs(data.avgArrPerCustomerMom.toFixed())}% MoM`,
			styles: styles.avgPerCustomer,
		},
		{
			title: t('avgRevenuePerCustomer'),
			quantity: priceNumberWithCurrencySignDisplay(data.avgRevenuePerCustomer, convertCurrencyToSign(currency)),
			subQuantity: data.avgRevenuePerCustomerMom,
			description: `${Math.abs(data.avgRevenuePerCustomerMom.toFixed())}% MoM`,
			styles: styles.avgPerCustomer,
		},
	];
};

export const arrDataMomentumDataHandler = (arrMomentumData: GraphData[], timeUnit: TimeUnit, filter?: FilterTimeOptions) => {
	let isEmpty = true;

	const calmingLine: Array<number> = [];
	const allSums: number[] = [];

	const labels = arrMomentumData?.map(({ date, amount }, index) => {
		if (Math.abs(amount) > 0) {
			isEmpty = false;
		}
		const reviewSum = calmingLine[index] || 0;
		allSums.push(amount);
		calmingLine[index] = reviewSum + amount;
		return monthLabel(date, timeUnit, filter);
	});

	const dataForGraph = {
		labels,
		datasets: [{ data: calmingLine, backgroundColor: colors.calming, borderColor: colors.calming, label: '' }],
	};

	return { dataForGraph, isEmpty, dataFormat: getNumberingFormat(Math.max(...absoluteValueArray(allSums))) };
};

export const productPerformancesTypeLabels = {
	CASH: 'Cash',
	REV: 'Rev',
	ARR: 'ARR',
};

export const productPerformancesTypeSelect = [
	{
		label: productPerformancesTypeLabels.ARR,
		value: ProductPerformanceType.ARR,
	},
	{
		label: productPerformancesTypeLabels.REV,
		value: ProductPerformanceType.REV,
	},
	{
		label: productPerformancesTypeLabels.CASH,
		value: ProductPerformanceType.CASH,
	},
];
