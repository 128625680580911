import dayjs from 'dayjs';
import clsx from 'clsx';
import { ContractState, Currency, convertCurrencyToSign } from '@received/pricing-model';
import { Cell, LogoCell, StatusCol } from '../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT, DATE_FORMAT_FOR_SERVER, SHORT_YEAR_FORMAT } from '../../constants/templateConstants';
import { contractIdFormat } from '../../utils/ContractUtils';
import { recognitionMethodShortLabels } from '../../constants/generalConstants';
import { getValueWithCurrencySign } from '../../utils/NumberUtils';
import { RevRecView } from '../../types/revRecTypes';
import { revRecStatusColor } from '../../utils/revRecUtils';
import { FilterTimeOptions, filtersSelectLabels } from '../../components/Filters/DateRangeFilters.utils';
import styles from './RevenueRecognition.module.scss';

export const initialMainTimePeriod = {
	from: dayjs().startOf('year').format(DATE_FORMAT_FOR_SERVER),
	to: dayjs().endOf('year').format(DATE_FORMAT_FOR_SERVER),
	title: `${filtersSelectLabels.CURRENT_YEAR} (${dayjs().year()})`,
	value: FilterTimeOptions.CURRENT_YEAR,
};

export const revRecTabTableHeader = (appCurrency: Currency) => [
	{
		headerTitle: 'customerName',
		accessor: 'name',
		subAccessor: 'email',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <LogoCell title={item?.customerName} logoData={item?.iconData} />,
	},
	{
		headerTitle: 'contract',
		accessor: 'contractNumber',
		isSortable: true,
		CustomComponent: (item: RevRecView) => (
			<Cell accessor={contractIdFormat(item?.poNumber, item?.contractNumber)} subAccessor={item?.contractName} />
		),
	},
	{
		headerTitle: 'recognitionMethod',
		accessor: 'recognitionMethod',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <Cell accessor={recognitionMethodShortLabels[item?.recognitionMethod]} />,
	},

	{
		headerTitle: 'period',
		accessor: 'period',
		CustomComponent: (item: RevRecView) => (
			<Cell accessor={`${dayjs(item.periodStartDate).format(SHORT_YEAR_FORMAT)} - ${dayjs(item.periodEndDate).format(SHORT_YEAR_FORMAT)}`} />
		),
	},
	{
		headerTitle: 'postingDate',
		accessor: 'postingDate',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <Cell accessor={dayjs(item.postingDate).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'product',
		accessor: 'productName',
		isSortable: true,
	},
	{
		headerTitle: 'revenueAmount',
		accessor: 'revenueAmount',
		isSortable: true,
		CustomComponent: (item: RevRecView) => <Cell accessor={`${getValueWithCurrencySign(item.revenueAmount, convertCurrencyToSign(appCurrency))}`} />,
	},
	{
		headerTitle: 'status',
		accessor: 'status',
		isSortable: true,
		disableRowClick: true,
		CustomComponent: (item: RevRecView) => {
			const badgeColor = revRecStatusColor(item);
			return <StatusCol color={badgeColor.color}>{badgeColor.text}</StatusCol>;
		},
	},
];
