import { BillingCycleUnit } from '@received/pricing-model';

export const enum CustomCycleType {
	BILLING = 'BILLING',
	PRICING = 'PRICING',
}

export const BillingCycleIntervalLabels = {
	// [BillingCycleUnit.WEEKLY]: 'Weekly',
	[BillingCycleUnit.MONTHLY]: 'Monthly',
	[BillingCycleUnit.QUARTERLY]: 'Quarterly',
	[BillingCycleUnit.YEARLY]: 'Yearly',
};

export const billingCycleIntervals = [
	// {
	// 	label: BillingCycleIntervalLabels[BillingCycleUnit.WEEKLY],
	// 	value: BillingCycleUnit.WEEKLY,
	// },
	{
		label: BillingCycleIntervalLabels[BillingCycleUnit.MONTHLY],
		value: BillingCycleUnit.MONTHLY,
	},
	{
		label: BillingCycleIntervalLabels[BillingCycleUnit.QUARTERLY],
		value: BillingCycleUnit.QUARTERLY,
	},
	{
		label: BillingCycleIntervalLabels[BillingCycleUnit.YEARLY],
		value: BillingCycleUnit.YEARLY,
	},
];
