import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { plainToInstance } from 'class-transformer';
import clsx from 'clsx';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { PathsConfig, httpService } from '../../services';
import {
	Button,
	CreateSubscription,
	CustomerSelect,
	DatePicker,
	DatePickerFormatType,
	Icon,
	StripeCardSelect,
	SubscriptionDetailsCard,
	SubscriptionSelect,
	Tooltip,
} from '..';
import { AssignedSubscription, Subscription } from '../../types/subscriptionTypes';
import { defaultSubscription, subscriptionValidation } from './AssignSubscription.utils';
import { Customer } from '../../types/customerTypes';
import { FooterTypeOptions } from '../_uiComponents/DatePicker/Footer/Footer';
import styles from './AssignSubscription.module.scss';

export interface AssignSubscriptionProps {
	isOpen: boolean;
	selectedSubscriptionId?: string;
	selectedCustomer?: Customer;
	onAssignSubscription?(): void;
	closeSideBar(): void;
}

export const AssignSubscription = ({
	isOpen,
	selectedSubscriptionId,
	selectedCustomer,
	onAssignSubscription,
	closeSideBar,
}: AssignSubscriptionProps) => {
	const { t } = useTranslation('translation');

	const [customer, setCustomer] = useState<Customer>();
	const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
	const [subscription, setSubscription] = useState<AssignedSubscription>(defaultSubscription);
	const [openCreateSubscription, setOpenCreateSubscription] = useState(false);

	const dispatch = useDispatch();

	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		selectedSubscriptionId ? getSelectedSubscription() : selectedCustomer && onSelectCustomer(selectedCustomer);
	}, [isOpen, selectedSubscriptionId, selectedCustomer]);

	useEffect(() => {
		if (isOpen) {
			containerRef?.current?.scroll({
				top: 0,
				behavior: 'smooth',
			});
		}
	}, [isOpen]);

	const assignSubscription = async () => {
		try {
			const transformedContractData = plainToInstance(AssignedSubscription, subscription);

			await httpService({
				dispatch,
				path: PathsConfig.assignSubscription,
				data: transformedContractData,
			});
			onAssignSubscription?.();

			onClose();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSelectedSubscription = async () => {
		try {
			if (selectedSubscriptionId) {
				const res = await httpService({
					dispatch,
					path: PathsConfig.getSelectedSubscription,
					urlParam: { id: selectedSubscriptionId },
				});

				setSubscription({
					id: selectedSubscriptionId,
					...res.data,
					customerId: res.data.customer.id,
					subscriptionId: res.data.template?.id,
					paymentGatewayCustomerId: res.data.paymentGatewayCustomer.id,
				});

				setSelectedSubscription(res.data.template);
				setCustomer(res.data.customer);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const updateAssignedSubscription = async () => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.updateAssignedSubscription,
				data: subscription,
			});
			onAssignSubscription?.();
			onClose();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onSelectCustomer = (customer: Customer) => {
		setCustomer(customer);
		setSubscription((prev) => ({ ...prev, customerId: customer?.id || '' }));
	};

	const onClose = () => {
		setSubscription(defaultSubscription);
		setCustomer(undefined);
		setSelectedSubscription(undefined);
		closeSideBar();
	};

	const onResetAll = () => {
		setSubscription({ ...defaultSubscription });
		setSelectedSubscription(undefined);
		setCustomer(undefined);
	};

	return (
		<>
			{isOpen && <div onClick={onClose} className={styles.modalOverlay} />}

			<div className={clsx(!isOpen ? styles.sidebarHidden : styles.sidebar)}>
				<div className={styles.header}>
					<div>
						<div className={styles.title}>{t('assignSubscription')}</div>
					</div>
					<Button type='link' color='neutral' onClick={closeSideBar}>
						<Icon imgType='x_icon' color='neutral700' height={1} />
					</Button>
				</div>

				<div className={styles.dataContainer} style={{ cursor: subscription?.editBlockers?.length ? 'not-allowed' : 'auto' }}>
					<div
						ref={containerRef}
						style={{
							height: subscription?.editBlockers?.length ? '100%' : 'height: calc(100% - 16rem)',
							pointerEvents: subscription?.editBlockers?.length ? 'none' : 'auto',
						}}
					>
						<div className={styles.subTitle}>{t('subscriber')}</div>

						<CustomerSelect
							hideOptionButton={!!selectedCustomer}
							defaultCustomer={customer}
							small
							hideEmailTooltip
							onCustomerSelected={onSelectCustomer}
						/>

						<div className={styles.subTitle}>{t('subscriptionDetails')}</div>
						<div className={styles.subscriptionDetails}>
							<DatePicker
								fullBorder
								rangePicker
								footerType={FooterTypeOptions.ADD_YEARS}
								icon={'information'}
								title={t('BillingPeriod')}
								headerContainerStyle={styles.activationDatePickerTitle}
								defaultFromDate={subscription?.billingStartDate}
								defaultToDate={subscription?.billingEndDate}
								updateOnClose={(date: DatePickerFormatType) =>
									setSubscription((prev) => ({ ...prev, billingStartDate: date.fromDate, billingEndDate: date.toDate }))
								}
							/>

							<div className={selectedSubscription && styles.selectedSubscriptionContainer}>
								<SubscriptionSelect
									onClickNewSubscription={() => setOpenCreateSubscription(true)}
									defaultSubscription={selectedSubscription}
									onSubscriptionSelected={(data) => {
										setSubscription((prev) => ({ ...prev, subscriptionId: data?.id || '' }));
										setSelectedSubscription(data);
									}}
								/>

								{selectedSubscription ? (
									<SubscriptionDetailsCard subscription={selectedSubscription} />
								) : (
									<div className={styles.emptyContainer}>
										<div className={styles.emptyIconContainer}>
											<Icon imgType='subscription' color='neutral400' height={3} />
										</div>
										{t('addSubscription')}
										<div className={styles.fontSize14neutral}>{t('subscriptionEmptySubTitle')}</div>
									</div>
								)}
							</div>
						</div>

						<div className={styles.subTitle}>{t('subscriptionSettings')}</div>
						<StripeCardSelect
							onChange={(selectedId, selectedItem) =>
								setSubscription((prev) => ({ ...prev, paymentGatewayCustomerId: selectedId, paymentGatewayCustomer: selectedItem }))
							}
							title={'stripeRelatedCustomerOptional'}
							paymentGatewayId={selectedSubscription?.billingDetails?.paymentGatewayId || undefined}
							defaultCustomer={subscription?.paymentGatewayCustomer}
							tooltipComponent={
								<div className={styles.tooltipLabel}>
									<div>{t('ifNoCardIsSelected')}</div>
									<div>{t('automaticPaymentLinkWillBe')}</div>
									<div>{t('sentToTheCustomer')}</div>
								</div>
							}
							displayCustomerNameOnly={!selectedSubscription?.autoCharge}
						/>
					</div>
				</div>

				<div className={styles.footer} style={{ cursor: subscription?.editBlockers?.length ? 'not-allowed' : 'auto' }}>
					<Button onClick={onResetAll} className={styles.footerButtons} type='link' color='neutral' disabled={!!subscription?.editBlockers?.length}>
						{t('resetAll')}
					</Button>
					<Tooltip
						disabled={!subscriptionValidation(subscription).length}
						tooltipComponent={
							<>
								{subscriptionValidation(subscription).map((err) => (
									<div key={err}>{t(err)}</div>
								))}
							</>
						}
					>
						<Button
							onClick={selectedSubscriptionId ? updateAssignedSubscription : assignSubscription}
							className={styles.footerButtons}
							disabled={!!subscriptionValidation(subscription).length || !!subscription?.editBlockers?.length}
						>
							{t(selectedSubscriptionId ? 'update' : 'assignSubscription')}
						</Button>
					</Tooltip>
				</div>

				<CreateSubscription
					selectedSubscriptionId={selectedSubscriptionId}
					isOpen={openCreateSubscription}
					onCreateSubscription={(sub) => {
						setSelectedSubscription(sub);
						setSubscription((prev) => ({ ...prev, subscriptionId: sub?.id }));
					}}
					closeSideBar={() => setOpenCreateSubscription(false)}
				/>
			</div>
		</>
	);
};
