import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { convertCurrencyToSign } from '@received/pricing-model';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { numberWithCommas, priceNumberWithCurrencySignDisplay } from '../../../utils/NumberUtils';
import { Store } from '../../../types/storeTypes';
import { Icon, LoadingLine, SummaryOverviewGraph } from '../../../components';
import { colors } from '../../../constants/colors';
import { initialState } from './AnalyticsSummary.utils';
import { productsColorsList } from '../../../utils/GeneralUtils';
import styles from './AnalyticsSummary.module.scss';

interface AnalyticsSummary {
	arr: number;
	billedCurrentMonth: { sentTotalAmount: number; readyToSendTotalAmount: number };
	billedLastMonth: { sentTotalAmount: number; readyToSendTotalAmount: number };
	mrr: number;
	readyToSend7Days: string;
	sentLastMonth: string;
	readyToSendCredit7Days: string;
	sentCreditLastMonth: string;
	top3Products: { name: string; amount: number; percentage: number }[];
	upForRenewal: { total: string; contracts: string; customers: string };
}

export const AnalyticsSummary = () => {
	const { t } = useTranslation('translation');
	const [analyticsSummary, setAnalyticsSummary] = useState<AnalyticsSummary>(initialState);

	const { appCurrency } = useSelector((store: Store) => store.general);
	const dispatch = useDispatch();

	useEffect(() => {
		getAnalyticsSummary();
	}, []);

	const getAnalyticsSummary = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getAnalyticsSummary,
				})
			).data;
			setAnalyticsSummary(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.leftSection}>
				<div className={styles.arrMrr}>
					<div className={styles.card}>
						<div className={styles.fontSize14}>{t('arr')}</div>
						<div className={clsx(styles.fontSize20, styles.fontWeight250, !analyticsSummary?.mrr ? styles.zero : '')}>
							{priceNumberWithCurrencySignDisplay(analyticsSummary?.arr || 0, convertCurrencyToSign(appCurrency))}
						</div>
					</div>

					<div className={styles.card}>
						<div className={styles.fontSize14}>{t('mrr')}</div>
						<div className={clsx(styles.fontSize20, styles.fontWeight250, !analyticsSummary?.mrr ? styles.zero : '')}>
							{priceNumberWithCurrencySignDisplay(analyticsSummary?.mrr || 0, convertCurrencyToSign(appCurrency))}
						</div>
					</div>
				</div>

				<div className={styles.billedCurrentMonth}>
					<div className={styles.billedCurds}>
						<div className={styles.fontSize14}>{t('billedCurrentMonth')}</div>
						<div className={clsx(styles.fontSize20, styles.fontWeight250)}>
							<span
								className={
									!analyticsSummary?.billedCurrentMonth?.sentTotalAmount && !analyticsSummary?.billedCurrentMonth?.readyToSendTotalAmount
										? styles.zero
										: ''
								}
							>
								{priceNumberWithCurrencySignDisplay(+analyticsSummary?.billedCurrentMonth.sentTotalAmount || 0, convertCurrencyToSign(appCurrency))}
								{' / '}
								{priceNumberWithCurrencySignDisplay(
									+analyticsSummary?.billedCurrentMonth.readyToSendTotalAmount || 0,
									convertCurrencyToSign(appCurrency),
								)}
							</span>
						</div>
						<LoadingLine
							totalNumber={analyticsSummary?.billedCurrentMonth.readyToSendTotalAmount}
							currentNumber={analyticsSummary?.billedCurrentMonth.sentTotalAmount}
							className={styles.loadingLine}
						/>
					</div>
					<div className={styles.billedCurds}>
						<div className={styles.fontSize14}>{t('billedLastMonth')}</div>
						<div className={clsx(styles.fontSize20, styles.fontWeight250)}>
							<span
								className={
									!analyticsSummary?.billedLastMonth?.sentTotalAmount && !analyticsSummary?.billedLastMonth.readyToSendTotalAmount ? styles.zero : ''
								}
							>
								{priceNumberWithCurrencySignDisplay(+analyticsSummary?.billedLastMonth.sentTotalAmount || 0, convertCurrencyToSign(appCurrency))}
								{' / '}
								{priceNumberWithCurrencySignDisplay(
									+analyticsSummary?.billedLastMonth.readyToSendTotalAmount || 0,
									convertCurrencyToSign(appCurrency),
								)}
							</span>
						</div>
						<LoadingLine
							totalNumber={analyticsSummary?.billedLastMonth.readyToSendTotalAmount}
							currentNumber={analyticsSummary?.billedLastMonth.sentTotalAmount}
							className={styles.loadingLine}
						/>
					</div>
				</div>

				<div className={styles.readyToSend}>
					<div className={styles.cardWithIcon}>
						<div className={styles.iconContainer}>
							<Icon imgType='clock' color='neutral700' height={2.4} />
						</div>
						<div>
							<div className={styles.fontSize14}>{t('readySendNext7Days')}</div>
							<span className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.readyToSend7Days ? styles.zero : '')}>
								{analyticsSummary?.readyToSend7Days}{' '}
								<span className={clsx(styles.fontSize14neutral, styles.fontWeight450, !+analyticsSummary?.readyToSend7Days ? styles.zero : '')}>
									{t(+analyticsSummary?.readyToSend7Days === 1 ? 'invoice' : 'invoices')}
									{' • '}
								</span>
							</span>
							<span className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.readyToSendCredit7Days ? styles.zero : '')}>
								{analyticsSummary?.readyToSendCredit7Days}{' '}
								<span className={clsx(styles.fontSize14neutral, styles.fontWeight450, !+analyticsSummary?.readyToSendCredit7Days ? styles.zero : '')}>
									{t(+analyticsSummary?.readyToSendCredit7Days === 1 ? 'credit' : 'credits')}
								</span>
							</span>
						</div>
					</div>

					<div className={styles.cardWithIcon}>
						<div className={styles.iconContainer}>
							<Icon imgType='clock' color='neutral700' height={2.4} />
						</div>
						<div>
							<div className={styles.fontSize14}>{t('sentLastMonth')}</div>
							<span className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.sentLastMonth ? styles.zero : '')}>
								{analyticsSummary?.sentLastMonth}{' '}
								<span className={clsx(styles.fontSize14neutral, styles.fontWeight450, !+analyticsSummary?.sentLastMonth ? styles.zero : '')}>
									{t(+analyticsSummary?.readyToSend7Days === 1 ? 'invoice' : 'invoices')}
									{' • '}
								</span>
							</span>
							<span className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.sentCreditLastMonth ? styles.zero : '')}>
								{analyticsSummary?.sentCreditLastMonth}{' '}
								<span className={clsx(styles.fontSize14neutral, styles.fontWeight450, !+analyticsSummary?.sentCreditLastMonth ? styles.zero : '')}>
									{t(+analyticsSummary?.sentCreditLastMonth === 1 ? 'credit' : 'credits')}
								</span>
							</span>
						</div>
					</div>
				</div>
			</div>

			<div className={styles.rightSection}>
				<div className={styles.graphContainer}>
					<div className={styles.fontSize14}>
						{t('top3ProductsIn')} {dayjs().year()}
						<SummaryOverviewGraph
							showOnlyPercentage
							containerClassName={styles.topProductGraphContainer}
							graphContainerClassName={styles.graphContainerClassName}
							itemsContainerClassName={styles.itemsContainerClassName}
							dataList={[
								...analyticsSummary.top3Products.map((item, index) => ({
									label: item.name,
									total: item.amount,
									count: item.percentage ? item.percentage?.toFixed() : '0',
									color: item.name === 'Others' ? colors.warning : colors[productsColorsList[index].colorIcon],
								})),
							]}
						/>
					</div>
				</div>

				<div className={styles.upForRenewal}>
					<div className={styles.fontSize14} style={{ height: '4rem' }}>
						{t('upForRenewalNext90days')}
					</div>
					<div
						className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.upForRenewal.total ? styles.zero : '')}
						style={{ marginBottom: '0.6rem' }}
					>
						{priceNumberWithCurrencySignDisplay(+analyticsSummary?.upForRenewal.total || 0, convertCurrencyToSign(appCurrency))}
					</div>

					<div className={styles.fontSize14}>{t('contracts')}</div>
					<div
						className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.upForRenewal.contracts ? styles.zero : '')}
						style={{ marginBottom: '0.6rem' }}
					>
						{numberWithCommas(+analyticsSummary?.upForRenewal.contracts)}
					</div>

					<div className={styles.fontSize14}>{t('customers')}</div>
					<div className={clsx(styles.fontSize20, styles.fontWeight250, !+analyticsSummary?.upForRenewal.customers ? styles.zero : '')}>
						{numberWithCommas(+analyticsSummary?.upForRenewal.customers)}
					</div>
				</div>
			</div>
		</div>
	);
};
