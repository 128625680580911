import react from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { UsageReportView } from '../../../../types/usageTypes';
import { CloseButton, Table } from '../../../../components';
import { usageReportsTableHeader } from './LogsTableModal.utils';
import styles from './LogsTableModal.module.scss';

export interface LogsTableModalProps {
	isOpen: boolean;
	usageReportView?: UsageReportView[];
	onClose(): void;
}

export const LogsTableModal = ({ isOpen, usageReportView = [], onClose }: LogsTableModalProps) => {
	const { t } = useTranslation('translation');

	return ReactDOM.createPortal(
		<>
			{isOpen && <div onClick={onClose} className={styles.modalOverlay} />}
			<div className={clsx(styles.container, isOpen && styles.containerOpen)}>
				<Table
					title={`${usageReportView.length} ${t(usageReportView.length === 1 ? 'usageReport' : 'usageReports')} • ${
						usageReportView[0]?.usageProductName || ''
					}`}
					header={usageReportsTableHeader}
					filters={
						<>
							<CloseButton onClick={onClose} />
						</>
					}
					rows={usageReportView}
					hideTableFooter
					tableRowClassName={styles.tableRowClassName}
					className={styles.tableContainer}
				/>
			</div>
		</>,
		document.body,
	);
};
