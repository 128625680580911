import { useTranslation } from 'react-i18next';
import { images } from '../../../../constants/images';
import { Button } from '../../../_uiComponents';
import styles from './EmptyState.module.scss';

interface EmptyStateProps {
	title: string;
	description: string;
	buttonText: string;
	onEdit?: () => void;
}

export const EmptyState = ({ title, description, buttonText, onEdit }: EmptyStateProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<img src={images.emptyBox} className={styles.img} />
			<span className={styles.title}>{title}</span>
			{onEdit && (
				<>
					<span className={styles.description}>{description}</span>
					<Button type='primary' className={styles.button} onClick={onEdit}>
						<span className={styles.fontSize16}>{buttonText}</span>
					</Button>
				</>
			)}
		</div>
	);
};
