import { useMemo } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ContractType, DEFAULT_CURRENCY, convertCurrencyToSign } from '@received/pricing-model';
import { Button, CustomerDetails, Icon, Modal } from '../../../../components';
import { DATE_FORMAT } from '../../../../constants/templateConstants';
import { Contract } from '../../../../types/contractTypes';
import { getValueWithCurrencySign } from '../../../../utils/NumberUtils';
import { getBillingCycleLabel } from '../../../../components/PricingModelDetails/PricingModelDetails.utils';
import { Customer } from '../../../../types/customerTypes';
import styles from './ReviewModal.module.scss';

interface ReviewModalProps {
	showModal: boolean;
	customer?: Customer;
	contractData?: Contract;
	closeModal(): void;
	onPublish(): void;
}

export const ReviewModal = ({ showModal, customer, contractData, onPublish, closeModal }: ReviewModalProps) => {
	const { t } = useTranslation('translation');

	const currency = convertCurrencyToSign(contractData?.pricingModel?.tabs[0]?.currency || DEFAULT_CURRENCY);
	const isPartner = contractData?.contractType && [ContractType.REFERRAL, ContractType.RESELLER].includes(contractData?.contractType);

	const calculateTotals = useMemo(() => {
		let totalDiscount = 0;
		let totalTAX = 0;
		let totalModels = 0;
		let subTotalAmount = 0;
		let isEstimatedTotal = false;

		contractData?.pricingModel?.tabs.forEach((item) => {
			if (item.isEstimatedTotal) isEstimatedTotal = true;

			const subTotal = item?.subTotal || 0;
			const discount = item.discount || 0;
			const tax = item?.tax || 0;

			const discountValue = (subTotal * discount) / 100;
			const taxValue = ((subTotal - discountValue) * tax) / 100;
			const totalValue = subTotal - discountValue + taxValue;

			totalDiscount = totalDiscount + discountValue;
			totalTAX = totalTAX + taxValue;
			totalModels = totalModels + totalValue;

			subTotalAmount = subTotalAmount + subTotal;
		});

		return { totalDiscount, totalTAX, totalModels, isEstimatedTotal, subTotalAmount };
	}, [contractData]);

	return (
		<Modal isOpen={showModal} closeModal={closeModal}>
			<div className={styles.mainContainer}>
				<div className={styles.header}>
					<div>
						<div className={styles.title}>{t(isPartner ? 'publishAgreement' : 'publishContract')}</div>
						<div className={styles.description}>{t('reviewBeforePublish')}</div>
					</div>

					<Button type='link' color='neutral' onClick={closeModal}>
						<Icon imgType='x_icon' color='neutral700' className={styles.xIcon} />
					</Button>
				</div>

				<div className={styles.dataContainer}>
					<div className={styles.dataSection}>
						<div style={{ paddingBottom: '1.2rem' }}>{t(isPartner ? 'partner' : 'customer')}</div>
						<CustomerDetails
							name={customer?.name || ''}
							country={customer?.country || ''}
							currency={customer?.currency || ''}
							ein={customer?.ein || ''}
							email={customer?.email || ''}
							logo={customer?.iconData || ''}
							small
							nameStyle={styles.ellipsis}
							hideTooltip
						/>
					</div>

					<div className={clsx(styles.dataSection, styles.listSection)}>
						<div className={styles.structureTitle}>
							<span>{t('products')}</span>
							{contractData?.pricingModel?.tabs && (
								<div className={styles.grayText}>{`${contractData?.pricingModel?.tabs.length} ${t(
									contractData?.pricingModel && contractData?.pricingModel?.tabs?.length > 1 ? 'products' : 'product',
								)}`}</div>
							)}
						</div>

						<div className={styles.structureList}>
							{contractData?.pricingModel?.tabs.map((tab) => {
								const subTotal = tab?.subTotal || 0;
								const tax = tab?.totalTax || 0;
								const discount = tab?.totalDiscount || 0;
								const totalValue = subTotal + tax - discount;

								return (
									<div key={tab.id} className={styles.structureItem}>
										<div>
											<div className={styles.structureTopText}>{tab.productName}</div>
											<div className={styles.structureBottomText}>
												{`${getBillingCycleLabel(tab.billingCycle)} • ${dayjs(tab.billingStartDate).format(DATE_FORMAT)} ${
													tab.billingEndDate ? ` - ${dayjs(tab.billingEndDate).format(DATE_FORMAT)}` : ''
												}`}
											</div>
										</div>
										<div>
											<div className={styles.structureTopText}>{getValueWithCurrencySign(totalValue, currency)}</div>
											<div className={styles.structureBottomText}>{getValueWithCurrencySign(subTotal, currency)}</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>

					<div>
						<div style={{ paddingTop: '1.2rem' }}>{t('summary')}</div>
						<div className={styles.summary}>
							<div className={styles.summaryItem}>
								<div className={styles.grayTextValue}>{getValueWithCurrencySign(calculateTotals.subTotalAmount, currency)}</div>
								<span className={styles.grayText}>{t('subtotal')}</span>
							</div>

							<div className={styles.summaryItem}>
								<div className={styles.grayTextValue}>({getValueWithCurrencySign(calculateTotals.totalDiscount, currency)})</div>
								<span className={styles.grayText}>{t('totalDiscount')}</span>
							</div>

							<div className={styles.summaryItem}>
								<div className={styles.grayTextValue}>{getValueWithCurrencySign(calculateTotals.totalTAX, currency)}</div>
								<span className={styles.grayText}>{t('totalTAX')}</span>
							</div>

							<div className={styles.summaryItem}>
								<div>{getValueWithCurrencySign(calculateTotals.totalModels, currency)}</div>
								<span className={styles.grayText}>{t('totalOfAllProducts')}</span>
							</div>
						</div>
					</div>
				</div>

				<div className={styles.footer}>
					<div></div>
					<Button
						color='success'
						className={styles.publishButton}
						onClick={() => {
							closeModal();
							onPublish();
						}}
					>
						{t(isPartner ? 'publish' : 'publishContract')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
