import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Input } from '../../components';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { images } from '../../constants/images';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import styles from './CreateProduct.module.scss';

export interface CreateProduct {
	editProductData?: { name: string; id: string };
	onProductAdded(productId: string, productName: string): void;
}

export const CreateProduct = ({ editProductData, onProductAdded }: CreateProduct) => {
	const { t } = useTranslation('translation');
	const [productName, setProductName] = useState<string>(editProductData?.name || '');

	const dispatch = useDispatch();

	const onAddProduct = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.createNewProduct,
				data: { name: productName },
			});

			onProductAdded(res.data.id, productName);
			setProductName('');
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onUpdateProduct = async (id: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.editProduct,
				data: { name: productName, id },
			});

			dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.PRODUCT_UPDATED }));
			onProductAdded(id, productName);
			setProductName('');
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.container}>
			<img alt='add-model-icon' src={images.addProduct} className={styles.icon}></img>
			<div className={styles.title}>{editProductData?.id ? t('editProduct') : t('createProduct')}</div>

			<Input
				autoFocus
				placeholder={t('Product Name')}
				value={productName}
				defaultValue={editProductData?.name}
				onChange={(value) => setProductName(value)}
				inputClassName={styles.input}
				className={clsx(styles.inputContainerClassName, styles.input)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						editProductData?.id ? onUpdateProduct(editProductData?.id) : onAddProduct();
					}
				}}
			/>

			<Button
				onClick={() => (editProductData?.id ? onUpdateProduct(editProductData?.id) : onAddProduct())}
				className={styles.footerButtons}
				disabled={!productName}
			>
				{editProductData?.id ? t('update') : t('Add Product')}
			</Button>
		</div>
	);
};
