import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventType, IntegrationPlatform, IntegrationProvider, IntegrationStatus } from '@received/pricing-model';
import { Button, CloseButton, CreateSupplier, EmptyStateCard, Icon, MenuList, MenuOption, Modal } from '../../../..';
import { images } from '../../../../../constants/images';
import { PathsConfig, httpService } from '../../../../../services';
import { setOpenSuccessErrorModal } from '../../../../../storeSlices/errorSuccessSlice';
import { getSuppliers } from '../../../../../utils/CustomerUtils';
import { convertIntegrationTypeToDescription, convertIntegrationTypeToStringService } from '../../../../../utils/IntegrationUtils';
import { IntegrationType } from '../../../../../types/integrationTypes';
import { Store } from '../../../../../types/storeTypes';
import { setListenerResponseType } from '../../../../../storeSlices/generalSlice';
import { Customer } from '../../../../../types/customerTypes';
import { ListItem } from '../ConnectModal/ListItem/ListItem';
import { onRemoveIntegration } from '../../../../../services/integrationService/integrationService';
import styles from './StripeConnectModal.module.scss';

export interface StripeConnectModalProps {
	isOpen: boolean;
	platform: IntegrationPlatform;
	image: string;
	closeModal(): void;
	onConnect(supplierId: string, integrationAccountId?: string, existing?: boolean): void;
	onCancel?(supplierId: string, integrationAccountId: string): void;
	onMissingDetails?(integrationAccountId: string): void;
	onRefresh?(): void;
}

export const StripeConnectModal = ({
	isOpen,
	platform,
	image,
	closeModal,
	onConnect,
	onCancel,
	onMissingDetails,
	onRefresh,
}: StripeConnectModalProps) => {
	const [platformList, setPlatformList] = useState<IntegrationType[]>([]);
	const [companies, setCompanies] = useState<MenuOption[]>([]);
	const [openEntityMenu, setOpenEntityMenu] = useState(false);
	const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const listenerResponseType = useSelector((store: Store) => store.general.listenerResponseType);

	useEffect(() => {
		if (isOpen || listenerResponseType == EventType.CHANGED_INTEGRATION_ACCOUNT) {
			getConnectionsList();
			if (listenerResponseType == EventType.CHANGED_INTEGRATION_ACCOUNT) {
				dispatch(setListenerResponseType(undefined));
				onRefresh?.();
			}
		}
	}, [isOpen, listenerResponseType]);

	useEffect(() => {
		isOpen && getSupplierCompanies();
	}, [platformList]);

	const menuOptions = (item: IntegrationType): MenuOption[] | undefined => {
		let options: MenuOption[] | undefined = undefined;
		if (item.connectionStatus == IntegrationStatus.UNLINKED) {
			options = [
				{
					title: t('existingAccount'),
					onPress: () => item?.supplier?.id && onConnect(item?.supplier?.id, item?.id, true),
					hasBottomBorder: true,
				},
				{
					title: t('newStripeAccount'),
					onPress: () => item?.supplier?.id && onConnect(item?.supplier?.id, item?.id, false),
					color: 'success',
				},
			];
		}
		return options;
	};

	const getConnectionsList = async () => {
		try {
			const res: IntegrationType[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getIntegrations,
					params: { platform },
				})
			).data;
			setPlatformList(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const addNewConnection = async (supplier: Customer) => {
		supplier?.id && (await createConnection(supplier.id));
		getConnectionsList();
	};

	const createConnection = async (supplierId: string) => {
		try {
			const res: IntegrationType[] = (
				await httpService({
					dispatch,
					path: PathsConfig.createIntegrationWithEntity,
					urlParam: { integrationProvider: IntegrationProvider.STRIPE.toLocaleLowerCase() },
					data: { supplierId },
				})
			).data;
			setPlatformList(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSupplierCompanies = async () => {
		const suppliers: Customer[] = await getSuppliers(dispatch);
		const supplierMenu = suppliers?.map((supplier, index) => ({
			title: supplier.name,
			onPress: () => supplier && addNewConnection(supplier),
			hasBottomBorder: suppliers.length - 1 === index,
		}));

		setCompanies([...supplierMenu, { title: t('newEntity'), onPress: () => setShowCreateEntityModal(true), iconType: 'add', color: 'primary' }]);
	};

	const createSupplier = async (entityData: Customer) => {
		entityData && addNewConnection(entityData);
		await getSupplierCompanies();
	};

	return (
		<>
			<Modal isOpen={isOpen} closeModal={closeModal} className={styles.modalContainer}>
				<div className={styles.header}>
					<div className={styles.flexCenter}>
						<div className={styles.iconContainer}>
							<img alt='cardIcon' src={image} className={styles.cardIcon}></img>
						</div>
						<div>
							<h2 className={styles.fontWeight650}>{`${t(convertIntegrationTypeToStringService(platform))} ${t('configuration')}`}</h2>
							<div className={styles.cardDescription}>{t(convertIntegrationTypeToDescription(platform))}</div>
						</div>
					</div>

					<CloseButton onClick={closeModal} />
				</div>

				<div className={styles.content}>
					{platformList.length ? (
						<>
							<div className={styles.contentHeader}>
								<div className={styles.fontWeight650}>{t('Entities')}</div>
								<MenuList optionsList={companies} placement='right' isMenuOpen={openEntityMenu} openMenu={() => setOpenEntityMenu(false)}>
									<Button type='link' onClick={() => setOpenEntityMenu(true)}>
										{t('addEntity')} <Icon imgType='add' color='success' width={1.2} className={styles.addIcon} />
									</Button>
								</MenuList>
							</div>

							{platformList.map((item) => (
								<ListItem
									key={item.id}
									item={item}
									connectLabel={t('connectStripeAccount')}
									menuOptions={menuOptions(item)}
									onDisconnect={() => item?.supplier?.id && item?.id && onCancel?.(item?.supplier?.id, item?.id)}
									onCancel={() => {
										item?.supplier?.id && item?.id && onCancel?.(item?.supplier?.id, item?.id);
									}}
									onMissingDetails={() => {
										item?.supplier?.id && item?.id && onMissingDetails?.(item?.id);
									}}
									onRemove={() => item?.id && onRemoveIntegration(dispatch, IntegrationProvider.STRIPE, item?.id)}
									onReconnect={() => item?.supplier?.id && onConnect(item?.supplier?.id, item?.id, true)}
								/>
							))}
						</>
					) : (
						<div className={styles.emptyContainer}>
							<EmptyStateCard
								src={images.emptyIntegration}
								emptyIconClassName={styles.imgContainerStyle}
								title={t('connectEntitiesAccount').replace('__', platform?.replaceAll('_', ' ').toLowerCase())}
								subTitle={t('connectSubtitle')}
							>
								<MenuList optionsList={companies} isMenuOpen={openEntityMenu} openMenu={() => setOpenEntityMenu(false)}>
									<Button onClick={() => setOpenEntityMenu(true)} className={styles.emptyIntegrationButton}>
										{t('addEntity')} <Icon imgType='add' color='white' />
									</Button>
								</MenuList>
							</EmptyStateCard>
						</div>
					)}
				</div>

				{!!platformList.length && (
					<div className={styles.footer}>
						<Button onClick={closeModal} className={styles.footerButton}>
							{t('done')}
						</Button>
					</div>
				)}
			</Modal>
			<CreateSupplier
				companiesListLength={companies?.length}
				isOpen={showCreateEntityModal}
				closeModal={() => setShowCreateEntityModal(false)}
				onCreateUpdateSupplier={createSupplier}
				overlayClassName={styles.newEntityModel}
			/>
		</>
	);
};
