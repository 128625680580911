import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ContractType } from '@received/pricing-model';
import { useNavigate } from 'react-router-dom';
import { Button, Table, Icon, ImportItemsProgress, SearchBar, EmptyStateCard, CreateCustomer, ActionModalModal } from '../../../components';
import { PathsConfig, httpService } from '../../../services';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { Store } from '../../../types/storeTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { useDebounce } from '../../../hooks';
import { partnerOptionList, referralsTableHeader } from './Referrals.utils';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import { Customer, CustomerView } from '../../../types/customerTypes';
import { images } from '../../../constants/images';
import { deleteCustomer } from '../../_Customers';
import styles from './Referrals.module.scss';

const defaultNumberOfItems = '20';

export const Referrals = () => {
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);

	const [referralsDataList, setReferralsDataList] = useState<[]>([]);
	const [sort, setSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.ASC });
	const [searchText, setSearchText] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [currentPartner, setCurrentPartner] = useState<Customer>();
	const [tableSmallRowsView, setTableSmallRowsView] = useState(false);
	const [showCreateCustomer, setShowCreateCustomer] = useState(false);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedPartner, setSelectedPartner] = useState({ name: '', id: '' });

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		setTableSmallRowsView(+numberOfItems >= 50);
	}, [numberOfItems]);

	useEffect(() => {
		getReferralsData();
	}, [numberOfItems, pageNumber, toastNotification.showToastNotification, sort]);

	useEffect(() => {
		searchSubscribers();
	}, [searchText]);

	const searchSubscribers = () => {
		getReferralsData();
		setPageNumber(0);
	};

	const getReferralsData = async (page?: number, items?: string) => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getPartnerReferralView,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						isPartner: true,
						orderBy: sort.orderBy,
						textFilter: searchText || undefined,
						order: sort.order,
						contractTypes: [ContractType.REFERRAL],
					},
				})
			).data;

			setTotalCount(res.count);
			setReferralsDataList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onRefreshData = () => {
		getReferralsData();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
		setCurrentPartner(undefined);
	};

	const newReferralAgreement = async () => {
		const contractId = await createContract(dispatch, ContractType.REFERRAL);
		contractId && navigate('../referral-agreement', { state: { contractId } });
	};

	const onSearch = useDebounce(setSearchText, 650);

	const onEditPartner = (partner: Customer) => {
		setCurrentPartner(partner);
		setShowCreateCustomer(true);
	};

	const onPressDelete = (id: string, name: string) => {
		setIsDeleteModalOpen(true);
		setSelectedPartner({ id, name });
	};

	const onDeleteCustomer = (id: string) => {
		deleteCustomer(id, dispatch, onRefreshData);
		setSelectedPartner({ name: '', id: '' });
		setIsDeleteModalOpen(false);
	};

	const onCloseDeleteModal = () => {
		setSelectedPartner({ name: '', id: '' });
		setIsDeleteModalOpen(false);
	};

	const onCloseCreateModal = () => {
		setShowCreateCustomer(false);
		setSelectedPartner({ name: '', id: '' });
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container}>
				<ImportItemsProgress onRefresh={() => onRefreshData()} />

				<div className={styles.header}>
					<header className={styles.customHeader}>
						<h1 data-testid='referral-partners-title'>{t('referralPartners')}</h1>

						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.search} />

							<Button color='neutral' type='outlined' onClick={() => setShowCreateCustomer(true)} className={styles.referralAgreement}>
								{t('newPartner')}
								<Icon imgType='add' width={1.2} className={styles.addIcon} />
							</Button>

							<Button color='success' onClick={newReferralAgreement} className={styles.referralAgreement}>
								{t('referralAgreement')}
								<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
							</Button>
						</div>
					</header>
				</div>

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={referralsTableHeader(tableSmallRowsView, () => newReferralAgreement())}
					rows={referralsDataList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					tableRowClassName={tableSmallRowsView ? styles.extendedTableRowClassName : styles.tableRowClassName}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					optionsList={(row) => partnerOptionList(row, dispatch, onEditPartner, onPressDelete)}
					onRowPress={(data: CustomerView) => navigate(`../partners/single-referral/${data.id}`)}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyState={
						!searchText ? (
							<EmptyStateCard showIcon imgType='users' title={t('noPartnersFound')}>
								<Button color='success' className={styles.emptyButton} onClick={() => setShowCreateCustomer(true)}>
									{t('newPartner')}
									<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
								</Button>
							</EmptyStateCard>
						) : undefined
					}
				/>
			</div>
			<CreateCustomer
				isPartner
				isOpen={showCreateCustomer}
				customer={currentPartner}
				closeModal={onCloseCreateModal}
				onCustomerAdded={() => {
					onCloseCreateModal();
					onRefreshData();
				}}
				refreshData={() => onRefreshData()}
			/>

			<ActionModalModal
				rightButton={{ title: 'delete', color: 'destructive' }}
				imagesOptions={images.deleteItem}
				isOpen={isDeleteModalOpen}
				deleteMessage={
					<>
						{t('deleteMessagePrefix').replace('__', 'partner')}
						<span className={styles.name}> {selectedPartner.name}</span> {t('deleteMessagePostfix').replace('__', 'partner')}
					</>
				}
				onAccept={() => onDeleteCustomer(selectedPartner.id)}
				onClose={onCloseDeleteModal}
			/>
		</div>
	);
};
