import clsx from 'clsx';
import { convertCurrencyToSign } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Store } from '../../../../../types/storeTypes';
import { AnalyticCard } from '../../../../../pages/Reports/AnalyticCard/AnalyticCard';
import { PartnerOverviewSummary } from '../../PartnerOverviewTab.utils';
import { getValueWithCurrencySign, numberWithCommas } from '../../../../../utils/NumberUtils';
import styles from './PartnerOverviewTabSummary.module.scss';

interface PartnerOverviewTabSummaryProps {
	summary: PartnerOverviewSummary;
}

export const PartnerOverviewTabSummary = ({ summary }: PartnerOverviewTabSummaryProps) => {
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	return (
		<>
			<div className={styles.topSection}>
				{/* TODO return when booking implemented 
				<AnalyticCard
					title={t('bookings')}
					quantity={getValueWithCurrencySign(summary.bookingsSum, convertCurrencyToSign(appCurrency))}
					subQuantity={summary.bookingYoy}
					description={summary.bookingYoy ? `${Math.abs(summary.bookingYoy).toFixed()}% YoY` : ''}
					iconProps={{ imgType: 'add', width: 0.8 }}
					classNames={{
						title: styles.cardTitles,
						quantity: styles.topSectionCardQuantity,
					}}
				/> */}
				{/* <div className={styles.divider} /> */}
				<AnalyticCard
					title={t('partnerFees')}
					quantity={getValueWithCurrencySign(summary.partnerFees, convertCurrencyToSign(appCurrency))}
					subQuantity={summary.partnerFeesYoy}
					description={summary.partnerFeesYoy ? `${Math.abs(summary.partnerFeesYoy).toFixed()}% YoY` : ''}
					iconProps={{ imgType: 'add', width: 0.8 }}
					classNames={{ title: styles.cardTitles, quantity: styles.topSectionCardQuantity }}
				/>
			</div>
			<div className={styles.bottomSection}>
				{/* TODO return when booking implemented 
				<AnalyticCard
					title={t('grossIncome')}
					quantity={getValueWithCurrencySign(summary.grossIncome, convertCurrencyToSign(appCurrency))}
					classNames={{
						title: styles.cardTitles,
						quantity: styles.bottomSectionCardQuantity,
						container: styles.cardContainer,
					}}
				/>
				<div className={styles.divider} /> */}

				<div className={styles.bottomRightSection}>
					{/* TODO return when booking implemented 
					 <AnalyticCard
						title={t('customers')}
						quantity={numberWithCommas(summary.customers)}
						classNames={{
							title: styles.cardTitles,
							quantity: styles.bottomSectionCardQuantity,
						}}
					/>
					<AnalyticCard
						title={t('activeContracts')}
						quantity={numberWithCommas(summary.activeContracts)}
						classNames={{
							title: styles.cardTitles,
							quantity: styles.bottomSectionCardQuantity,
						}}
					/>
					<AnalyticCard
						title={t('churnRate')}
						quantity={`${numberWithCommas(summary.churnRate, 1)}%`}
						classNames={{
							title: styles.cardTitles,
							quantity: clsx(styles.bottomSectionCardQuantity, summary.churnRate > 0 && styles.destructiveLabel),
						}}
					/> */}

					<AnalyticCard
						title={t('unpaidFee')}
						quantity={getValueWithCurrencySign(summary.unpaidFees, convertCurrencyToSign(appCurrency))}
						classNames={{
							title: styles.cardTitles,
							quantity: styles.bottomSectionCardQuantity,
						}}
					/>
				</div>
			</div>
		</>
	);
};
