import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { SelectItem } from '../../../../types/generalTypes';
import { Button } from '../../Button/Button';
import { Header } from '../../Header/Header';
import { IconImgType, Icon, IconColor } from '../../Icon';
import { images } from '../../../../constants/images';
import { EmptyStateCard } from '../../..';
import { handleKeyDown } from './Select.utils';
import styles from './Select.module.scss';

export interface InputIconType {
	imgType: IconImgType;
	color?: IconColor;
	width?: number;
}

export interface SelectProps {
	title?: string;
	tabIndex?: number;
	className?: string;
	width?: number | string;
	placeholder?: string;
	containerStyle?: string;
	defaultValue?: SelectItem | string;
	required?: boolean;
	data: Array<SelectItem | string>;
	style?: CSSProperties;
	selectItemClassName?: string;
	optionsStyle?: CSSProperties;
	optionStyle?: CSSProperties;
	iconType?: IconImgType | null;
	disabled?: boolean;
	addButton?: { title: string; onPress(): void; isDisabled?: boolean };
	headerStyle?: string;
	headerLabelStyle?: string;
	inputClassName?: string;
	hideArrowIcon?: boolean;
	arrowIcon?: {
		color?: IconColor;
		width?: number;
	};
	showError?: boolean;
	isSearchable?: boolean;
	inputRef?: RefObject<HTMLInputElement>;
	resetSelectOnClose?: boolean;
	mediumInput?: boolean;
	inputIcon?: InputIconType;
	autoFocus?: boolean;
	headerLeftChildren?: React.ReactNode;
	emptyState?: {
		title?: string;
		subTitle?: string;
	};
	canRemoveSelectedValue?: boolean;
	fullBorder?: boolean;
	onChange(val: string, selected?: SelectItem | string): void;
	onCustomSearch?: (value: string) => any;
}

export const Select = ({
	data,
	title,
	iconType,
	placeholder,
	defaultValue,
	width = 15,
	addButton,
	style,
	disabled,
	className,
	headerStyle,
	headerLabelStyle,
	required,
	containerStyle,
	tabIndex,
	inputClassName,
	hideArrowIcon,
	showError,
	isSearchable,
	inputRef,
	arrowIcon,
	mediumInput,
	resetSelectOnClose = true,
	inputIcon,
	optionStyle,
	autoFocus,
	headerLeftChildren,
	optionsStyle,
	selectItemClassName,
	emptyState,
	canRemoveSelectedValue,
	fullBorder,
	onChange,
	onCustomSearch,
}: SelectProps) => {
	const searchRef = useRef<HTMLInputElement>(null);
	const containerRef = useRef<HTMLInputElement>(null);

	const [referenceElement, setReferenceElement] = useState<any>(null);
	const [popperElement, setPopperElement] = useState<any>(null);
	const [selectedValue, setSelectedValue] = useState<SelectItem | string>();
	const [selectLabel, setSelectLabel] = useState<string | number>('');
	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState<Array<SelectItem | string>>(data);
	const [focusedItem, setFocusedItem] = useState<number>();
	const { t } = useTranslation('translation');

	const {
		styles: popperStyle,
		attributes,
		update,
	} = usePopper(referenceElement, popperElement, {
		placement: 'auto',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'flip',
				options: {
					allowedAutoPlacements: ['top', 'bottom'],
					flipVariations: true,
					fallbackPlacements: ['top'],
				},
			},
		],
	});

	useEffect(() => {
		setIsOpen(!!autoFocus);
	}, [autoFocus]);

	useEffect(() => {
		if (isOpen) {
			const selectedValueIndex = data?.findIndex((item) => {
				const val = typeof item === 'string' ? item : item?.value;
				const selected = typeof selectedValue === 'string' ? selectedValue : selectedValue?.value;

				return val === selected;
			});
			const focusedElement = popperElement?.children?.[focusedItem || (selectedValueIndex >= 0 ? selectedValueIndex : 0)];
			focusedElement && focusedElement.scrollIntoView({ behavior: 'smooth' });
		}
	}, [focusedItem, isOpen]);

	useEffect(() => {
		setOptions(data);

		const defaultValIndex = data?.findIndex((item) => {
			const val = typeof item === 'string' ? item : item?.value;
			const selected = typeof defaultValue === 'string' ? defaultValue : defaultValue?.value;

			return val === selected;
		});
		setFocusedItem(defaultValIndex);
		setSelectedValue(defaultValue);

		const selected = defaultValIndex >= 0 ? data[defaultValIndex] : defaultValue || '';
		setSelectLabel(typeof selected === 'string' ? selected : selected?.label);
	}, [defaultValue, data]);

	const onSearch = async (value: string) => {
		setSelectLabel(value);
		setIsOpen(true);
		const options = await filterOptions(value);
		setOptions(options);
		if (!value) onValueChange(value);
	};

	const filterOptions = async (value: string) => {
		return onCustomSearch
			? await onCustomSearch(value)
			: data.filter((item) => {
					const val = typeof item === 'string' ? item : item?.label;
					return val.toString().toLowerCase().includes(value.toLowerCase());
			  });
	};

	const onValueChange = (item: SelectItem | string) => {
		setSelectedValue(item);
		setSelectLabel(typeof item === 'string' ? item : item?.label);
		onChange(typeof item === 'string' ? item : item?.value, item);
		setIsOpen(false);
		setFocusedItem(undefined);
	};

	const onRemoveSelectedItem = () => {
		setSelectedValue(undefined);
		setSelectLabel('');
		onChange('', undefined);
		setIsOpen(false);
		setFocusedItem(undefined);
	};

	const onOpenFromArrow = () => {
		setIsOpen(true);
		searchRef.current && searchRef?.current.focus();
	};

	const onClose = () => {
		setIsOpen(false);
		setFocusedItem(undefined);

		// reset select input after search and not select
		if (isSearchable && resetSelectOnClose) {
			if (selectedValue && selectLabel) {
				const currentSelect = typeof selectedValue === 'string' ? selectedValue : selectedValue?.label;
				setSelectLabel(currentSelect);
			} else {
				onRemoveSelectedItem();
				onChange('', undefined);
			}
			setOptions(data);
		}
	};

	return (
		<div
			ref={containerRef}
			data-testid='select-component'
			style={{ width: `${width}rem`, ...style }}
			className={clsx(containerStyle, disabled && styles.disabled)}
			id='select'
		>
			{title && (
				<Header
					title={required ? `${title}*` : title}
					containerStyle={headerStyle}
					labelStyle={headerLabelStyle}
					id='select'
					iconType={iconType}
					headerLeftChildren={headerLeftChildren}
				/>
			)}

			<div
				style={popperStyle.offset}
				ref={(ref) => setReferenceElement(ref)}
				className={clsx(
					styles.input,
					fullBorder && styles.fullBorder,
					mediumInput && styles.inputMediumInputContainer,
					showError && styles.errorContainer,
					className,
				)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						onOpenFromArrow();
						typeof focusedItem === 'number' && onValueChange(options[focusedItem]);
					}
				}}
				onClick={(e) => {
					e.stopPropagation();
					update && update();
					onOpenFromArrow();
				}}
				id='select-component-input'
				data-testid={`select-input-button${title ? `-${title}` : ''}`}
			>
				{inputIcon?.imgType && (
					<Icon imgType={inputIcon.imgType} width={inputIcon?.width} color={inputIcon?.color || 'neutral900'} className={styles.inputIcon} />
				)}

				<input
					id='select-input'
					data-testid={`select-input${title ? `-${title}` : ''}`}
					tabIndex={tabIndex}
					placeholder={placeholder}
					className={clsx(styles.inputText, !isSearchable && styles.disabledInput, inputClassName)}
					onChange={(event) => isSearchable && onSearch(event.target.value)}
					value={selectLabel}
					ref={inputRef || searchRef}
					onFocus={(event) => isSearchable && event.target.select()}
					autoFocus={autoFocus}
					onKeyDown={(e) => isOpen && handleKeyDown(e, focusedItem, options, setFocusedItem, selectedValue)}
					autoComplete='off'
				/>

				{canRemoveSelectedValue && selectLabel && (
					<Button color='neutral' type='outlined' className={styles.removeSelectedOption} onClick={onRemoveSelectedItem}>
						<Icon id='remove-selected-icon' imgType='x_icon' color='neutral700' width={0.8} />
					</Button>
				)}

				{!hideArrowIcon && (
					<Icon
						id='select-icon'
						imgType='arrow_down'
						color={arrowIcon?.color || 'neutral700'}
						width={arrowIcon?.width || 1.5}
						className={isOpen ? styles.arrowUp : styles.arrowDown}
					/>
				)}
			</div>

			{ReactDOM.createPortal(
				<>
					<div
						ref={(ref) => setPopperElement(ref)}
						{...attributes.popper}
						className={clsx(styles.selectDropdownContainer, isOpen ? styles.selectDropdownContainerOpen : styles.selectDropdownClose)}
						style={{
							minWidth: containerRef?.current?.offsetWidth,
							...optionsStyle,
							...popperStyle.popper,
						}}
						id='select-list'
					>
						{options?.length ? (
							options?.map((item, index) => {
								const val = typeof item === 'string' ? item : item?.label;
								const selected = typeof selectedValue === 'string' ? selectedValue : selectedValue?.label;
								const isDisabled = typeof item === 'string' ? false : item?.isDisabled;
								return (
									<div
										key={index}
										className={clsx(
											styles.selectItem,
											val === selected && styles.selected,
											index === focusedItem && styles.focusedItem,
											isDisabled && styles.disabled,
											selectItemClassName,
										)}
										data-testid={`searchable-select-list-item-${index}`}
										id={`searchable-select-list-item-${index}`}
										style={optionStyle}
										onClick={(e) => {
											e.stopPropagation();
											onValueChange(item);
										}}
									>
										{typeof item === 'string' ? (
											item
										) : item.customComponent ? (
											item.customComponent(item)
										) : (
											<span id='select-ellipsis-label' className={styles.ellipsis}>
												{item.label}
											</span>
										)}
									</div>
								);
							})
						) : !addButton ? (
							<EmptyStateCard
								containerStyle={styles.empty}
								showIcon
								src={images.emptyGraph}
								imgAlt={'empty-graph'}
								title={emptyState?.title ?? t('noDataSource')}
								subTitle={emptyState?.subTitle ?? t('emptyStateSubtitle')}
							/>
						) : null}

						{addButton && (
							<Button
								type='link'
								className={styles.addButton}
								disabled={addButton.isDisabled}
								onClick={() => {
									addButton.onPress();
									setIsOpen(false);
								}}
							>
								<Icon imgType='add' width={1} color='success' />
								{addButton.title}
							</Button>
						)}
					</div>
					{isOpen && (
						<div
							className={styles.selectOverlay}
							onClick={(e) => {
								e.stopPropagation();
								onClose();
							}}
						></div>
					)}
				</>,
				document.body,
			)}
		</div>
	);
};
