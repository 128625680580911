import { DocumentState } from '@received/pricing-model';
import { TimeUnit } from '../../../../types/generalTypes';
import { InvoiceCashflow } from '../../../../types/invoiceTypes';
import { colors } from '../../../../constants/colors';
import { LineGraphDataset } from './LineGraph';
import { absoluteValueArray, getNumberingFormat } from '../../../../utils/NumberUtils';
import { monthLabel } from '../../../../utils/GraphsUtils';
import { FilterTimeOptions } from '../../../Filters/DateRangeFilters.utils';

export const initialCashflowState: LineGraphDataset = {
	labels: [],
	data: [],
};

export const forecastDataHandler = (forecastData: InvoiceCashflow[], timeUnit: TimeUnit, filter?: FilterTimeOptions) => {
	let isForecastEmpty = true;

	const neutralLine: Array<number> = [];
	const allSums: number[] = [];

	const labels =
		forecastData?.map(({ date, statesCashflow }, index) => {
			statesCashflow.forEach((item) => {
				switch (item.state) {
					case DocumentState.DRAFT:
					case DocumentState.REVIEW_READY_TO_SEND:
					case DocumentState.REVIEW_MISSING_DATA:
					case DocumentState.COLLECTING_INFO: {
						if (Math.abs(item.totalSum) > 0) {
							isForecastEmpty = false;
						}
						const reviewSum = neutralLine[index] || 0;
						allSums.push(reviewSum + item.totalSum);
						return (neutralLine[index] = reviewSum + item.totalSum);
					}
				}
			});

			if (neutralLine.length != index + 1) {
				neutralLine.push(0);
			}
			return monthLabel(date, timeUnit, filter);
		}) || [];

	const dataForGraphForecast = {
		labels,
		datasets: [{ data: neutralLine, backgroundColor: colors.primary700, borderColor: colors.primary700, label: '' }],
	};

	return { dataForGraphForecast, isForecastEmpty, dataFormat: getNumberingFormat(Math.max(...absoluteValueArray(allSums))) };
};
