import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PricingType, convertCurrencyToSign } from '@received/pricing-model';
import { useSelector } from 'react-redux';
import { Subscription } from '../../../types/subscriptionTypes';
import { Icon } from '..';
import { billingCycleShortLabelsByEnum } from '../../PricingModelTable/TableRow/TableRow.utils';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import { Store } from '../../../types/storeTypes';
import { colors } from '../../../constants/colors';
import { images } from '../../../constants/images';
import styles from './SubscriptionDetailsCard.module.scss';

export interface SubscriptionDetailsCardProps {
	subscription: Subscription;
}

export const SubscriptionDetailsCard = ({ subscription }: SubscriptionDetailsCardProps) => {
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	const isStripeConnected = !!subscription?.billingDetails?.paymentGatewayId;

	return (
		<div className={styles.container}>
			<div className={styles.productsSubTitle}>{t('products')}</div>

			{subscription?.pricingModel.tabs.map((item) => {
				const isUsage = item?.pricingType === PricingType.USAGE;
				return (
					<div key={item?.id} className={styles.tabContainer}>
						<div className={styles.product}>
							<div className={styles.cube} style={{ backgroundColor: isUsage ? colors.calming100 : colors.primary100 }}>
								<Icon imgType={isUsage ? 'usage_template' : 'cube'} color={isUsage ? 'calming' : 'primary'} height={1.8} />
							</div>
							<div>
								<div>{item.productName}</div>
								<div className={styles.fontSize14neutral}>{item.name}</div>
							</div>
						</div>

						<div className={styles.rightColl}>
							<div className={styles.fontWeight450}>
								{getValueWithCurrencySign(item.total, convertCurrencyToSign(subscription.currency || appCurrency))}
								<span className={styles.billingCycle}> / {billingCycleShortLabelsByEnum[item?.billingCycle?.unit]}</span>
							</div>
							<div className={styles.fontSize14neutral}>
								{t('discount')} {getValueWithCurrencySign(item.totalDiscount, convertCurrencyToSign(subscription.currency || appCurrency))} |{' '}
								{t('tax')} {getValueWithCurrencySign(item.totalTax, convertCurrencyToSign(subscription.currency || appCurrency))}
							</div>
						</div>
					</div>
				);
			})}

			<div className={styles.productsSubTitle}>
				{t('settings')}

				{isStripeConnected && (
					<div className={styles.stripeTag}>
						<img src={images.stripePayment} className={styles.stripeIcon} />
						<span className={styles.stripeText}>{t('stripe')}</span>
					</div>
				)}
			</div>

			<div className={styles.tabContainer}>
				<div>
					<div>{t('debitEntity')}</div>
					{/* <div className={styles.fontSize14neutral}>{t('stripeAccountNumber')}</div> */}
				</div>
				<div className={styles.rightColl}>
					<div className={styles.fontWeight450}>{subscription?.supplier?.name}</div>
					{/* <div className={styles.fontSize14neutral}>{card}</div> */}
				</div>
			</div>

			<div className={styles.productsSubTitle}>{t('additional')}</div>
			<div className={clsx(styles.tabContainer, styles.fontSize14neutral)}>
				<div>
					<div>{t('discount')}</div>
					<div>{t('tax')}</div>
				</div>
				<div className={styles.rightColl}>
					<div>{subscription.discount}%</div>
					<div>{subscription.tax}%</div>
				</div>
			</div>
		</div>
	);
};
