import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import {
	Chart as ChartJS,
	ChartData,
	LineController,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	CategoryScale,
	ArcElement,
	DoughnutController,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { Currency, convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../../../types/storeTypes';
import { colors } from '../../../../constants/colors';
import { numberWithCommas } from '../../../../utils/NumberUtils';
import { EmptyStateCard } from '../..';
import { images } from '../../../../constants/images';
import { NumberFormat } from '../../../../types/generalTypes';
import { textCenter } from '../SummeryOverviewGraph/SummaryOverviewGraph.utils';
import styles from './DoughnutGraph.module.scss';

ChartJS.register(LineController, ArcElement, DoughnutController, LineElement, PointElement, CategoryScale, Title, Tooltip, Filler, Legend);

export interface DoughnutGraphData {
	label: string;
	value: number;
	backgroundColor: string;
	hoverBorderColor: string;
}

interface DoughnutGraphDataProps {
	data: DoughnutGraphData[];
	title?: string;
	selectedYear?: number;
	showTooltip?: boolean;
	isEmpty?: boolean;
	dataFormat?: NumberFormat;
	customStyle?: CSSProperties;
	className?: string;
	emptyTitle?: string;
	emptySubTitle?: string;
	currency?: Currency;
}

export const DoughnutGraph = ({ data, showTooltip, customStyle, className, emptyTitle, currency, emptySubTitle }: DoughnutGraphDataProps) => {
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	const isEmpty = data.map((item) => item.value).reduce((a, b) => a + b, 0) === 0;

	const chartRef = useRef<ChartJS>(null);
	const [chartData, setChartData] = useState<ChartData<any>>({
		datasets: [],
	});

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}

		const chartData = {
			labels: data.map((item) => item.label),
			datasets: [
				{
					data: data.map((item) => item.value),
					backgroundColor: data.map((item) => item.backgroundColor),
					hoverBorderColor: data.map((item) => item.hoverBorderColor),
				},
			],
		};

		setChartData(chartData);
	}, [data]);

	const options = {
		responsive: true,
		cutout: '89%',
		borderRadius: 10,
		maintainAspectRatio: false,
		animation: {
			duration: 2000,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				titleFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				bodyFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				enabled: !!showTooltip,
				displayColors: false,
				backgroundColor: colors.neutral900,
				intersect: false,
				width: 30,
				padding: 10,
				callbacks: {
					label: (context: any) => {
						let label = '';
						if (context.parsed) {
							let numericLabel = `${convertCurrencyToSign(currency || appCurrency)}${numberWithCommas(Math.abs(context.parsed))}`;
							numericLabel = `${context.parsed < 0 ? `(${numericLabel})` : numericLabel}`;
							label = ` ${context.label ? `${context.label} • ` : ''} ${numericLabel}`;
						}
						return label;
					},
				},
			},
		},
	};

	return (
		<>
			{isEmpty ? (
				<div className={styles.emptyContainer}>
					<EmptyStateCard
						src={images.emptyGraphs}
						imgAlt={'empty-graph'}
						title={emptyTitle || t('noDataSource')}
						subTitle={emptySubTitle || t('emptyStateSubtitle')}
					/>
				</div>
			) : (
				<Chart
					plugins={textCenter(convertCurrencyToSign(currency || appCurrency))}
					type='doughnut'
					options={options}
					ref={chartRef}
					data={chartData}
					style={{ height: '100%', ...customStyle }}
					className={className}
				/>
			)}
		</>
	);
};
