import { CreditNotePDF, AccountingEventType, InvoicePDF, DebitPDF, PayoutPDF } from '@received/pricing-model';
import { Invoice } from '../../../../types/invoiceTypes';
import { BillingAccountDetails, Customer } from '../../../../types/customerTypes';
import { CreditNote } from '../../../../types/creditNoteTypes';
import { BillingDocumentType } from '../../../../types/generalTypes';
import { Payout } from '../../../../types/PayoutsTypes';

export const getDocumentByType = (document: BillingDocumentType, supplier?: Customer, bankDetails?: BillingAccountDetails) => {
	switch (document?.accountingEventType) {
		case AccountingEventType.INVOICE:
			return <InvoicePDF invoiceData={document as Invoice} supplier={supplier} bankDetails={bankDetails} isPreview />;
		case AccountingEventType.DEBIT:
			return <DebitPDF debitData={document as Invoice} supplier={supplier} bankDetails={bankDetails} isPreview />;
		case AccountingEventType.CREDIT:
			return <CreditNotePDF creditNoteData={document as CreditNote} supplier={supplier} isPreview />;
		case AccountingEventType.PAYOUT:
			return <PayoutPDF payoutData={document as Payout} supplier={supplier} bankDetails={bankDetails} isPreview />;
		default:
			return <div></div>;
	}
};
