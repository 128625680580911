import clsx from 'clsx';
import { useState } from 'react';
import { PDFPreview } from '../Sidebars/PDFPreviewSideBar/PDFPreview/PDFPreview';
import { Modal } from '../_uiComponents';
import { ContractTimeline, TopBar } from './components';
import styles from './PDFPreviewModal.module.scss';
import { BillingDocumentType } from '../../types/generalTypes';

interface PDFPreviewModalProps {
	isOpen: boolean;
	documentsList: Array<BillingDocumentType>;
	isContractTimelineOpen?: boolean;
	activationStartDate?: Date | null;
	activationEndDate?: Date | null;
	onClose: () => void;
	onDownload?: () => void;
}

export const PDFPreviewModal = ({
	isOpen,
	documentsList,
	activationStartDate,
	activationEndDate,
	isContractTimelineOpen,
	onClose,
	onDownload,
}: PDFPreviewModalProps) => {
	const [activeIndex, setActiveIndex] = useState(0);

	const closeModal = () => {
		setActiveIndex(0);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} closeModal={closeModal} className={styles.container}>
			<TopBar document={documentsList[activeIndex]} onClose={closeModal} onDownload={onDownload} />
			<div className={clsx(styles.content, isContractTimelineOpen && styles.wideContent)}>
				<div className={clsx(styles.timelineContainer, isContractTimelineOpen && styles.visible)}>
					<ContractTimeline
						documentsList={documentsList}
						activationStartDate={activationStartDate}
						activationEndDate={activationEndDate}
						activeDocumentIndex={activeIndex}
						setActiveDocumentIndex={setActiveIndex}
					/>
				</div>

				<div className={styles.pdfContainer}>
					<PDFPreview document={documentsList[activeIndex]} isOpen={isOpen} />
				</div>
			</div>

			{isOpen && <div className={styles.selectOverlay} onClick={closeModal}></div>}
		</Modal>
	);
};
