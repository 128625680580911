import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SummaryBlock, SummaryBlockProps, SummaryBlockStyles } from '../../../../../components';
import { summaryType } from '../../../../../types/invoiceTypes';
import styles from './SummaryColorCards.module.scss';

interface SummaryColorCardsProps {
	summaryItems: SummaryBlockProps[];
	className?: string;
	summaryBlockStyles?: SummaryBlockStyles;
	onClick: (type: summaryType) => void;
}

export const SummaryColorCards = ({ summaryItems, className, summaryBlockStyles, onClick }: SummaryColorCardsProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.container, className)}>
			<div className={clsx(styles.column, styles.linearGradientLine)}>
				{summaryItems.slice(0, 2).map((itemSummary, index) => (
					<SummaryBlock
						key={index}
						{...itemSummary}
						title={t(itemSummary.title)}
						summaryBlockStyles={{
							...summaryBlockStyles,
							className: clsx(styles.leftLinearGradientBorder, summaryBlockStyles?.className),
						}}
						style={{ borderColor: itemSummary.iconColorCode }}
						onClick={() => onClick(itemSummary.type)}
					/>
				))}
			</div>
			<div className={clsx(styles.column, styles.linearGradientLine)}>
				{summaryItems.slice(2, 4).map((itemSummary, index) => (
					<SummaryBlock
						key={index}
						{...itemSummary}
						title={t(itemSummary.title)}
						summaryBlockStyles={{
							...summaryBlockStyles,
							className: clsx(styles.rightLinearGradientBorder, summaryBlockStyles?.className),
						}}
						style={{ borderColor: itemSummary.iconColorCode }}
						onClick={() => onClick(itemSummary.type)}
					/>
				))}
			</div>
		</div>
	);
};
