import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ChartData,
	LineController,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	CategoryScale,
	ArcElement,
	DoughnutController,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../../../types/storeTypes';
import { colors } from '../../../../constants/colors';
import { numberWithCommas, priceNumberWithCurrencySignDisplay, sumNumbersArray } from '../../../../utils/NumberUtils';
import { EmptyStateCard } from '../..';
import { images } from '../../../../constants/images';
import styles from './ProductUsage.module.scss';

ChartJS.register(LineController, ArcElement, DoughnutController, LineElement, PointElement, CategoryScale, Title, Tooltip, Filler, Legend);

export interface ProductUseProps {
	labels: string[];
	datasets: number[];
	isEmpty?: boolean;
	emptyTitle?: string;
	emptySubTitle?: string;
}

export const ProductUsage = ({ labels, datasets, isEmpty, emptyTitle, emptySubTitle }: ProductUseProps) => {
	const { appCurrency } = useSelector((store: Store) => store.general);

	const chartRef = useRef<ChartJS>(null);
	const [chartData, setChartData] = useState<ChartData<any>>({
		datasets: [],
	});

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}

		const chartData = {
			labels: labels,
			datasets: [
				{
					data: datasets,
					backgroundColor: [colors.primary900, colors.primary, colors.primary300, colors.primary700, colors.primary400, colors.primary800],
					hoverBackgroundColor: [colors.primary],
				},
			],
		};

		setChartData(chartData);
	}, [labels, datasets]);

	const options = {
		responsive: true,
		cutout: '90%',
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				titleFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				bodyFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				displayColors: false,
				backgroundColor: colors.neutral900,
				padding: 10,
				callbacks: {
					label: (context: any) => {
						let label = '';
						if (context.parsed) {
							let numericLabel = `${convertCurrencyToSign(appCurrency)}${numberWithCommas(Math.abs(context.parsed))}`;
							numericLabel = `${context.parsed < 0 ? `(${numericLabel})` : numericLabel}`;
							const checkForLongLabel = context.label.length > 10 ? `${context.label.substring(0, 10)}...` : context.dataset.label;

							label = ` ${checkForLongLabel ? `${checkForLongLabel} • ` : ''} ${numericLabel}`;
						}
						return label;
					},
				},
			},
		},
	};

	return isEmpty ? (
		<div className={styles.emptyContainer}>
			<EmptyStateCard
				src={images.emptyGraph}
				imgAlt={'empty-graph'}
				title={emptyTitle}
				subTitle={emptySubTitle}
				containerStyle={styles.textContainer}
			/>
		</div>
	) : (
		<div className={styles.container}>
			<div className={styles.totalText}>
				<span>{priceNumberWithCurrencySignDisplay(sumNumbersArray(datasets), convertCurrencyToSign(appCurrency))}</span>
			</div>

			<Chart type='doughnut' options={options} ref={chartRef} data={chartData} />
		</div>
	);
};
