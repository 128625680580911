import { PricingType, AccountingEventType } from '@received/pricing-model';
import { Contract } from '../../../types/contractTypes';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { SideBarOverview } from './SideBarOverview/SideBarOverview';
import { isContractHasProductsByDocumentType, isContractHasProductsByPricingType } from '../../../utils/ContractUtils';
import { CreditNotesSidebarTab } from '../components/CreditNotesSidebarTab/CreditNotesSidebarTab';
import { InvoicesSideBarTab, UsageTab, UsageTabProps } from '../components';
import { Customer } from '../../../types/customerTypes';
import { PayoutsTab } from '../components/PayoutsTab/PayoutsTab';
import styles from './ContractSideBar.module.scss';

export interface tabItem {
	title: string;
	component: (prop?: any) => JSX.Element;
}

export interface contractsTabsProps {
	contractData?: Contract;
	suppliers?: Customer[];
	selectedRowId?: string;
	title: string;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	onDelete: () => void;
	getSplittedInvoices: (search?: string, page?: number, splittedOnly?: boolean) => any;
	onEditContract: () => void;
	onDataChange: () => void;
	onSideBarRefresh: () => void;
}

export const contractsTabs = (contract?: Contract) => {
	let tabs = [{ title: 'Overview', component: (prop?: any) => <SideBarOverview {...prop} /> }];

	if (
		isContractHasProductsByDocumentType(AccountingEventType.INVOICE, contract) ||
		isContractHasProductsByDocumentType(AccountingEventType.DEBIT, contract)
	) {
		tabs = [...tabs, { title: 'Invoices', component: (prop?: any) => <SideBarInvoices {...prop} /> }];
	}

	if (isContractHasProductsByDocumentType(AccountingEventType.PAYOUT, contract)) {
		tabs = [
			...tabs,
			{
				title: 'Payouts',
				component: (props: contractsTabsProps) => {
					const { openOtherSidebar } = props;
					return (
						<PayoutsTab
							onRowPress={(id?: string) => openOtherSidebar({ sidebarType: SidebarType.PAYOUT, triggeredBy: SidebarType.CONTRACT, id })}
							{...props}
						/>
					);
				},
			},
		];
	}
	if (isContractHasProductsByDocumentType(AccountingEventType.CREDIT, contract)) {
		tabs = [...tabs, { title: 'Credits', component: (prop?: any) => <CreditNotesSidebarTab {...prop} /> }];
	}
	if (isContractHasProductsByPricingType(PricingType.USAGE, contract)) {
		tabs = [...tabs, { title: 'Usage', component: (prop: UsageTabProps) => <UsageTab {...prop} /> }];
	}
	return tabs;
};

export const SideBarInvoices = ({ contractData, selectedRowId, openOtherSidebar, getSplittedInvoices, onSideBarRefresh }: contractsTabsProps) => {
	return (
		<InvoicesSideBarTab
			contractData={contractData}
			selectedRowId={selectedRowId}
			onRowPress={(id?: string) => openOtherSidebar({ sidebarType: SidebarType.INVOICE, triggeredBy: SidebarType.CONTRACT, id })}
			getSplittedInvoices={getSplittedInvoices}
			onSideBarRefresh={onSideBarRefresh}
			className={styles.invoiceTabContainer}
		/>
	);
};

export const isSupplierExist = (contract?: Contract) => {
	return contract?.pricingModel?.tabs?.find((tab) => tab.supplierId)?.supplierId;
};

export const getUniqueSuppliersCount = (contract?: Contract) => {
	return new Set(contract?.pricingModel?.tabs?.filter((tab) => tab?.supplierId).map((tab) => tab?.supplierId)).size;
};

export const getUniqueBanksCount = (contract?: Contract) => {
	return new Set(
		contract?.pricingModel?.tabs?.filter((tab) => tab.billingDetails?.billingAccountId).map((tab) => tab.billingDetails?.billingAccountId),
	).size;
};

export const isBankExist = (contract?: Contract) => {
	return contract?.pricingModel?.tabs?.find((tab) => tab.billingDetails?.billingAccountId)?.billingDetails?.billingAccountId;
};
