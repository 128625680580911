import React, { useEffect, useState } from 'react';
import { PaymentTerms, PricingType } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { PricingModel, PricingModelTab } from '../../../types/contractTypes';
import { Button, CatalogModal, Icon, NetTermsSelect } from '../..';
import { Subscription } from '../../../types/subscriptionTypes';
import { calculateSubTotal, initialCyclicDay } from '../../PricingModelTabContent';
import { PricingModelTableCard } from './PricingModelTableCard/PricingModelTableCard';
import styles from './ProductDetails.module.scss';

export interface CreateSubscriptionProps {
	subscription: Subscription;
	setSubscription: React.Dispatch<React.SetStateAction<Subscription>>;
}

export const ProductDetails = ({ subscription, setSubscription }: CreateSubscriptionProps) => {
	const { t } = useTranslation('translation');
	const [openCatalogModal, setOpenCatalogModal] = useState(false);
	const [showCantAddPricing, setShowCantAddPricing] = useState(false);

	useEffect(() => {
		if (!isAllProductsWithUsage() && subscription.netTerms === PaymentTerms.DUE_UPON_USAGE) {
			setSubscription((prev) => ({ ...prev, netTerms: PaymentTerms.DUE_UPON_RECEIPT }));
		}
	}, [subscription.pricingModel.tabs]);

	const onAddProduct = (model: PricingModel) => {
		let currency = subscription.currency;

		if (subscription.pricingModel.tabs.length) {
			if (model.tabs[0].currency != currency) {
				setShowCantAddPricing(true);
				return setTimeout(() => setShowCantAddPricing(false), 1500);
			}
		} else {
			currency = model.tabs[0].currency;
		}
		const newModel = { ...subscription.pricingModel };
		model.id && newModel.sourcePricingModelIds?.push(model.id);
		newModel.tabs = [...newModel.tabs, ...addTabs(model.tabs)];
		newModel.tabs.forEach((tab, index) => {
			tab.index = index;
		});

		setSubscription((prev) => ({ ...prev, pricingModel: newModel, currency }));
	};

	const addTabs = (tabs: PricingModelTab[]): PricingModelTab[] => {
		const newTabs = [...tabs];
		newTabs.forEach((tab) => {
			tab.pricingModelTableTotal = calculateSubTotal(tab.pricingModelTable.rows);
			tab.templatePricingModelTabId = tab.id;
			tab.issueDay = initialCyclicDay;
			delete tab?.cryptoSettings?.id;
			// delete id so api will generate a new object instead of updating an existing one
			delete tab.id;
		});

		return newTabs;
	};

	const updateTab = (tab: PricingModelTab, index: number) => {
		const newModel = { ...subscription.pricingModel };
		newModel.tabs = [...newModel.tabs];
		newModel.tabs[index] = { ...tab };

		setSubscription((prev) => ({ ...prev, pricingModel: newModel }));
	};

	const isAllProductsWithUsage = () => {
		return !subscription.pricingModel.tabs.find((tab) => tab.pricingType != PricingType.USAGE);
	};

	const onRemoveProduct = (index: number) => {
		const newModel = { ...subscription.pricingModel, tabs: [...subscription.pricingModel.tabs] };
		newModel.tabs.splice(index, 1);
		setSubscription((prev) => ({ ...prev, pricingModel: newModel }));
	};

	return (
		<div className={styles.container}>
			{showCantAddPricing && <div className={styles.errorAlert}>{t('cantAddPricingWithDifferentCurrency')}</div>}
			<NetTermsSelect
				title={t('paymentTerms')}
				onChange={(netTerms: any) => setSubscription((prev) => ({ ...prev, netTerms }))}
				defaultValue={subscription?.netTerms}
				isUsageProduct={isAllProductsWithUsage()}
				width={17}
			/>

			<div className={styles.productDetailsContainerCubTitle}>
				<div className={styles.fontSize14neutral}> {t('products')}</div>

				<Button onClick={() => setOpenCatalogModal(true)} type='secondary' className={styles.addProduct}>
					{t('addProduct')}
					<Icon imgType='round_add' color='primary' />
				</Button>
			</div>

			{subscription.pricingModel.tabs.length ? (
				<div className={styles.pricingModelContainer}>
					{subscription.pricingModel.tabs.map((tab, index) => (
						<PricingModelTableCard
							key={tab?.id}
							tab={tab}
							onRemoveProduct={() => onRemoveProduct(index)}
							updateTab={(newTab) => updateTab(newTab, index)}
						/>
					))}
				</div>
			) : (
				<div className={styles.emptyContainer}>
					<div className={styles.emptyIconContainer}>
						<Icon imgType='cube' color='neutral' height={3} />
					</div>
					{t('subscriptionMustContainProduct')}
				</div>
			)}

			<CatalogModal
				editMode
				startFromProducts
				openModal={openCatalogModal}
				setOpenModal={(isOpen) => setOpenCatalogModal(isOpen)}
				onUseThisPricing={onAddProduct}
			/>
		</div>
	);
};
