import { convertCurrencyToSign, Currency } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { getValueWithCurrencySign } from '../../../../../../utils/NumberUtils';
import styles from './CollapsedDocumentsGroup.module.scss';

interface CollapsedDocumentsGroupProps {
	docsCount: number;
	productsCount: number;
	total: number;
	title: string;
	currency: Currency;
}

export const CollapsedDocumentsGroup = ({ docsCount, productsCount, total, title, currency }: CollapsedDocumentsGroupProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.data}>
			<div className={styles.docsCount}>{docsCount}</div>
			<div className={styles.flexColumn}>
				<span className={styles.mainTitle}>{title}</span>
				<span className={styles.subTitle}>
					{productsCount ? `${productsCount} ${t(productsCount > 1 ? 'Products' : 'product')}` : ''}
					<span className={styles.dot}> • </span>
					{getValueWithCurrencySign(total, convertCurrencyToSign(currency))}
				</span>
			</div>
		</div>
	);
};
