import { useEffect, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DatesData, SelectItem } from '../../types/generalTypes';
import { DatePicker, DatePickerFormatType, Select } from '../_uiComponents';
import { FilterTimeOptions, handleChangeSelectTimePeriod } from './DateRangeFilters.utils';
import { DATE_FORMAT, DATE_FORMAT_FOR_SERVER } from '../../constants/templateConstants';
import styles from './DateRangeFilters.module.scss';

interface DateRangeFiltersProps {
	data: SelectItem[];
	className?: string;
	selectClassName?: string;
	inputClassName?: string;
	width?: number;
	defaultSelectValue?: FilterTimeOptions | string;
	disabled?: boolean;
	mediumInput?: boolean;
	callback(datesData: DatesData): void;
}

export const DateRangeFilters = ({
	data,
	className,
	width = 20,
	defaultSelectValue,
	disabled,
	inputClassName,
	selectClassName,
	mediumInput,
	callback,
}: DateRangeFiltersProps) => {
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [timePeriod, setTimePeriod] = useState<DatePickerFormatType>();
	const [selectedValue, setSelectedValue] = useState<FilterTimeOptions | string>();

	useEffect(() => {
		setSelectedValue(defaultSelectValue);
	}, [defaultSelectValue]);

	const onHandleSelectChange = (value: string) => {
		setSelectedValue(value);
		if (value == FilterTimeOptions.CUSTOM_DATES) return setIsDatePickerOpen(true);

		setTimePeriod(undefined);
		handleChangeSelectTimePeriod(value, callback);
	};

	const onSelectCustom = (date: DatePickerFormatType) => {
		if (date?.fromDate && date?.toDate) {
			setTimePeriod(date);
			setSelectedValue(`${dayjs(date?.fromDate).format(DATE_FORMAT)} - ${dayjs(date?.toDate).format(DATE_FORMAT)}`);
			handleChangeSelectTimePeriod(
				FilterTimeOptions.CUSTOM_DATES,
				callback,
				dayjs(date?.fromDate, DATE_FORMAT_FOR_SERVER).toDate(),
				dayjs(date?.toDate, DATE_FORMAT_FOR_SERVER).toDate(),
			);
		}
	};

	const onCloseCustomWithoutDates = () => {
		if (!timePeriod?.fromDate || !timePeriod?.toDate) {
			setSelectedValue(defaultSelectValue);
		}
	};

	return (
		<div className={className}>
			<Select
				fullBorder
				mediumInput={mediumInput}
				disabled={disabled}
				isSearchable
				className={clsx(styles.select, selectClassName)}
				inputClassName={inputClassName}
				onChange={onHandleSelectChange}
				width={timePeriod?.fromDate && timePeriod?.toDate ? 25 : width}
				data={data}
				defaultValue={selectedValue}
			/>

			<DatePicker
				hideInput
				rangePicker
				defaultFromDate={timePeriod?.fromDate}
				defaultToDate={timePeriod?.toDate}
				openDatePicker={isDatePickerOpen}
				updateIsOpen={(isOpen) => {
					setIsDatePickerOpen(isOpen);
					!isOpen && onCloseCustomWithoutDates();
				}}
				updateDate={onSelectCustom}
			/>
		</div>
	);
};
