import { DateUtils, DATE_FORMAT, BillingCycleUnit, ContractType } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { ValidationError } from 'class-validator';
import { ContractAndPricingModelContainer } from '../../../components';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { DATE_FORMAT_FOR_SERVER } from '../../../constants/templateConstants';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Contract } from '../../../types/contractTypes';
import { ContractOverview } from './ContractOverview/ContractOverview';
import { PricingModelTabContentOptions } from '../../../components/PricingModelTabContent';
import { DocumentSettings } from './DocumentSettings/DocumentSettings';
import styles from './Contract.module.scss';

export enum ContractTabsName {
	PRODUCTS = 'Products',
	SETTINGS = 'Settings',
	OVERVIEW = 'Overview',
}

export const contractTabs = (contractType: ContractType) => {
	if (contractType === ContractType.REFERRAL) {
		return [
			{
				title: ContractTabsName.PRODUCTS,
				component: (prop?: any) => (
					<ContractAndPricingModelContainer
						pricingModelClassName={styles.pricingModelClassName}
						tabContentType={PricingModelTabContentOptions.CONTRACT}
						{...prop}
					/>
				),
			},
		];
	}
	return [
		{
			title: ContractTabsName.PRODUCTS,
			component: (prop?: any) => (
				<ContractAndPricingModelContainer
					pricingModelClassName={styles.pricingModelClassName}
					tabContentType={PricingModelTabContentOptions.CONTRACT}
					{...prop}
				/>
			),
		},
		{
			title: ContractTabsName.SETTINGS,
			component: (prop?: any) => <DocumentSettings {...prop} />,
		},
		{
			title: ContractTabsName.OVERVIEW,
			component: (prop?: any) => <ContractOverview {...prop} />,
		},
	];
};

export const createContract = async (dispatch: Dispatch, contractType: ContractType, customerId?: string) => {
	try {
		const newContract: Contract = (
			await httpService({
				dispatch,
				path: PathsConfig.createContract,
				data: { customerId: customerId || undefined, contractType },
			})
		).data;
		return newContract.id;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const deleteContract = async (contractId: string, dispatch: Dispatch, callback?: () => void, onDelete?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteContract,
			urlParam: { id: contractId },
		});
		onDelete?.();
		callback?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DELETE_CONTRACT }));
	}
};

export const cancelContract = async (contractId: string, dispatch: Dispatch, callback?: () => void) => {
	try {
		const res = (
			await httpService({
				dispatch,
				path: PathsConfig.cancelContract,
				urlParam: { id: contractId },
			})
		).data;
		callback?.();
		return res;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CANCEL_CONTRACT }));
	}
};

export const cloneContract = async (contractId: string, dispatch: Dispatch, callback?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.cloneContract,
			urlParam: { id: contractId },
		});
		callback?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CLONE_CONTRACT }));
	}
};

export const getContractError = (error: string) => {
	try {
		const split = error.split('__');
		return (
			<span className={styles.bold}>
				{split[0]}
				<span className={styles.error}>{split[1]}</span>
			</span>
		);
	} catch (e) {
		return <span className={styles.error}>{error}</span>;
	}
};

export const getCustomerSelectedById = async (id: string, dispatch: Dispatch) => {
	try {
		if (id) {
			const customer = (
				await httpService({
					dispatch,
					path: PathsConfig.getCustomerData,
					urlParam: { id },
				})
			).data;

			return customer;
		}
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const createContractDocuments = async (dispatch: Dispatch, contractId?: string) => {
	if (!contractId) return;
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.createContractsDocuments,
				urlParam: { id: contractId },
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const getContractsDocuments = async (dispatch: Dispatch, contractData: Contract, numberOfInvoices?: number, splittedOnly?: boolean) => {
	const defaultNumberOfInvoices = window._env_?.INVOICES_YEARS_PERIOD ? parseInt(window._env_?.INVOICES_YEARS_PERIOD) : 3;

	const contractId = contractData.id;
	let billingStartDate: Date | undefined;
	let billingEndDate: Date | undefined | null;
	contractData?.pricingModel?.tabs?.forEach((tab) => {
		if (!tab.billingStartDate) {
			return;
		}
		const tabBillingStartDate = dayjs(tab.billingStartDate, DATE_FORMAT);
		if (!billingStartDate || tabBillingStartDate.isBefore(billingStartDate)) billingStartDate = tabBillingStartDate.toDate();

		const tabBillingEndDate = tab.billingEndDate
			? dayjs(tab.billingEndDate, DATE_FORMAT)
			: tab.billingCycle?.unit === BillingCycleUnit.ONE_TIME
			? tabBillingStartDate
			: undefined;

		if (billingEndDate === null) return;

		if (tab.autoRenewal) billingEndDate = null;
		else if (tabBillingEndDate && billingEndDate === undefined) {
			billingEndDate = tabBillingEndDate.toDate();
		} else if (tabBillingEndDate && billingEndDate !== undefined && tabBillingEndDate.isAfter(billingEndDate)) {
			billingEndDate = tabBillingEndDate.toDate();
		}
	});

	const params = billingEndDate
		? {
				contractId,
				startDate: dayjs(billingStartDate).format(DATE_FORMAT_FOR_SERVER),
				endDate: dayjs(billingEndDate).format(DATE_FORMAT_FOR_SERVER),
				splittedOnly,
		  }
		: numberOfInvoices
		? { contractId, startDate: dayjs(billingStartDate).format(DATE_FORMAT_FOR_SERVER), count: numberOfInvoices, splittedOnly }
		: {
				contractId,
				startDate: dayjs(billingStartDate).format(DATE_FORMAT_FOR_SERVER),
				endDate: dayjs(DateUtils.calculateBillingEndDate(billingStartDate, defaultNumberOfInvoices)).format(DATE_FORMAT_FOR_SERVER),
				splittedOnly,
		  };

	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getContractDocument,
				params,
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const formatValidationError = (errors: ValidationError[], t: any, isMultipleCurrency: boolean): string[] => {
	let res: string[] = [];
	errors.forEach((error) => {
		if (error.constraints) {
			for (const [, value] of Object.entries(error.constraints)) {
				res.push(value);
			}
		}

		if (error.children) res = res.concat(formatValidationError(error.children, t, isMultipleCurrency));
	});

	if (isMultipleCurrency && !res.includes(t('multipleCurrencyError'))) {
		res.push(t('multipleCurrencyError'));
	}
	return res;
};
