import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, EmptyStateCard, FileUploader } from '../../../components';
import { images } from '../../../constants/images';
import { CUSTOMER_CSV_TEMPLATE_NAME, CUSTOMER_CSV_TEMPLATE } from '../../../Integrations';
import { createCSVFile } from '../../../utils/GeneralUtils';
import styles from './CustomerEmptyState.module.scss';

interface CustomerEmptyStateProps {
	className?: string;
	onAddManual(): void;
	onExportFromCSV(file: File): void;
}
export const CustomerEmptyState = ({ className, onAddManual, onExportFromCSV }: CustomerEmptyStateProps) => {
	const { t } = useTranslation('translation');

	return (
		<FileUploader fileTypes={['CSV']} uploadFile={onExportFromCSV} className={clsx(styles.flexCenter, styles.container, className)}>
			<span className={styles.header}>{t('newCustomer')}</span>
			<span className={styles.headerSubTitle}>{t('chooseHowToAddCustomers')}</span>

			<div className={styles.buttonsContainer}>
				<EmptyStateCard
					src={images.exportCSV}
					imgAlt='add-customer-icon'
					title={t('import')}
					containerStyle={clsx(styles.emptyStateCard, styles.button)}
					imgContainerStyle={styles.imgStyle}
				>
					<span>
						<span className={styles.subTitle}>{t('importCSVhere')} </span>
						<span
							className={clsx(styles.download, styles.subTitle)}
							onClick={(e) => {
								e.stopPropagation();
								createCSVFile(CUSTOMER_CSV_TEMPLATE, CUSTOMER_CSV_TEMPLATE_NAME);
							}}
						>
							{t('templateHere')}
						</span>
					</span>
				</EmptyStateCard>

				<Button
					className={styles.button}
					type='link'
					onClick={(e) => {
						e.stopPropagation();
						onAddManual();
					}}
				>
					<EmptyStateCard
						src={images.addCustomer}
						imgAlt='add-customer-icon'
						title={t('manual')}
						titleStyle={styles.title}
						subTitle={t('addCustomerManually')}
						subTitleStyle={styles.subTitle}
						containerStyle={styles.emptyStateCard}
						imgContainerStyle={styles.imgStyle}
					/>
				</Button>
			</div>
		</FileUploader>
	);
};
