import react from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';
import { Button, Icon } from '../../../../../components';
import { ValidationWarnings } from '../../../../../types/usageTypes';
import { PathsConfig, httpService } from '../../../../../services';
import { setOpenSuccessErrorModal } from '../../../../../storeSlices/errorSuccessSlice';
import { convertWarningKeyToString } from './WarningModal.utils';
import { downloadFile } from '../../../../../utils/GeneralUtils';
import styles from './WarningModal.module.scss';

export interface WarningModalProps {
	isOpen: boolean;
	warnings?: ValidationWarnings;
	csvWithErrorsUid?: string;
	fileName?: string;
	totalWarningCount?: number;
	onClose(): void;
}

export const WarningModal = ({ isOpen, warnings, fileName = '', csvWithErrorsUid = '', totalWarningCount = 0, onClose }: WarningModalProps) => {
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	const getWarningsFile = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getCsvWithErrors,
				urlParam: { id: csvWithErrorsUid },
				responseType: 'blob',
			});

			downloadFile(res, `${fileName.replace('.csv', '')}_${t('warnings')}.csv`);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return ReactDOM.createPortal(
		isOpen && (
			<>
				<div onClick={onClose} className={styles.warningModalOverlay} />
				<div className={styles.container}>
					<div className={styles.header}>
						<div>
							{totalWarningCount > 0 ? `${totalWarningCount} ${t(totalWarningCount === 1 ? 'row' : 'rows')} ${t('withWarningsIn')} ` : ''}
							{fileName}
						</div>
						{csvWithErrorsUid && (
							<Button type='outlined' color='neutral' onClick={getWarningsFile} className={styles.downloadWarningsButton}>
								{t('downloadWarnings')}
							</Button>
						)}
					</div>

					{warnings &&
						Object.keys(warnings).map((warningKey, index) =>
							warnings[warningKey].count > 0 ? (
								<div key={index} className={styles.errorMassageContainer}>
									<Icon imgType='warning_sign' color='warning700' height={2} />
									<div className={styles.warningNumbers}>
										{warnings[warningKey].count} {t(warnings[warningKey].count === 1 ? 'warning' : 'warnings')}
										{' - '}
										{t(convertWarningKeyToString(warningKey))} {t(warnings[warningKey].count === 1 ? 'onRow' : 'onRows')}:{' '}
										{warnings[warningKey].groupedRows.map((wor) => wor).join(', ')}
									</div>
								</div>
							) : null,
						)}
				</div>
			</>
		),
		document.body,
	);
};
