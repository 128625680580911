import { DEFAULT_CURRENCY, PaymentTerms } from '@received/pricing-model';
import { DEFAULT_COUNTRY } from '../../constants/generalConstants';
import { Customer, CustomersDetails } from '../../types/customerTypes';

export const handelCustomerForServer = (rows: any[], existedCustomers?: CustomersDetails) => {
	return rows.map((row): Customer => {
		return {
			name: row[IndexInCustomerCSVFile.NAME],
			legalName: row[IndexInCustomerCSVFile.LEGAL_NAME],
			email: row[IndexInCustomerCSVFile.EMAIL] || null,
			supplierId: row[IndexInCustomerCSVFile.ENTITY]?.id ?? undefined,
			ein: row[IndexInCustomerCSVFile.EIN],
			city: row[IndexInCustomerCSVFile.CITY],
			phone: { phone: undefined, countryCode: undefined },
			country: row[IndexInCustomerCSVFile.COUNTRY],
			currency: DEFAULT_CURRENCY,
			iconData: row[IndexInCustomerCSVFile.ICON_DATA],
			tax: row[IndexInCustomerCSVFile.TAX] ? row[IndexInCustomerCSVFile.TAX].replace('%', '') : undefined,
			countryState: row[IndexInCustomerCSVFile.COUNTRY] !== DEFAULT_COUNTRY ? null : row[IndexInCustomerCSVFile.COUNTRY_STATE],
			netTerms: PaymentTerms.DUE_UPON_RECEIPT,
			billingAddress: row[IndexInCustomerCSVFile.BILLING_ADDRESS],
			shippingAddress: '',
			remoteId: row[IndexInCustomerCSVFile.REMOTE_ID]?.length ? row[IndexInCustomerCSVFile.REMOTE_ID] : null,
			id: existedCustomers?.customers?.find(
				(customer) => customer.name?.trim()?.toLocaleLowerCase() === row[IndexInCustomerCSVFile.NAME]?.trim()?.toLocaleLowerCase(),
			)?.id,
			settings: {
				attachUsageLogsCsv: false,
				attachUsageLogsPdf: true,
			},
		};
	});
};

export const IndexInCustomerCSVFile = {
	ROW_STATUS: 0,
	ICON_DATA: 1,
	NAME: 2,
	LEGAL_NAME: 3,
	ENTITY: 4,
	EIN: 5,
	EMAIL: 6,
	COUNTRY: 7,
	COUNTRY_STATE: 8,
	CITY: 9,
	BILLING_ADDRESS: 10,
	TAX: 11,
	REMOTE_ID: 12,
};
