import { convertCurrencyToSign, ContractState } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Cell, ContractCell, PriceCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { numberWithCommas } from '../../../utils/NumberUtils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { AgreementView } from '../../../types/AgreementTypes';
import { contractStateColor } from '../ContractsTab/ContractsTab.utils';
import styles from './AgreementsTab.module.scss';

export const agreementsTableHeader = [
	{
		headerTitle: 'agreementNo',
		accessor: 'agreementNumber',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <ContractCell contractNumber={contractIdFormat(item?.poNumber, item?.agreementNumber)} />,
	},
	{
		headerTitle: 'createAt',
		accessor: 'createAt',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <Cell accessor={dayjs(item.createdAt).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'programs',
		accessor: 'programsCount',
		isSortable: true,
		CustomComponent: (item: AgreementView) => (
			<div className={styles.programContainer}>
				<span className={styles.accessorRow}>{item?.programName}</span>
				{item.programsCount > 1 && <span className={styles.programsCount}>{`+${item.programsCount}`}</span>}
			</div>
		),
	},
	{
		headerTitle: 'refCustomers',
		accessor: 'refCustomersCount',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <Cell accessor={numberWithCommas(item.refCustomersCount)} />,
	},
	{
		headerTitle: 'totalFee',
		accessor: 'totalFees',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <PriceCell mainNumber={item?.totalFees} currency={convertCurrencyToSign(item?.currency)} />,
	},
	{
		headerTitle: 'nextFee',
		accessor: 'nextFee',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <PriceCell mainNumber={item?.nextFee} currency={convertCurrencyToSign(item?.currency)} />,
	},

	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		CustomComponent: (item: AgreementView) => {
			const badgeColor = contractStateColor(item);
			return (
				<StatusCol color={badgeColor.color} className={clsx(item?.state == ContractState.END_BILLING && styles.contractEndBillingBadge)}>
					{badgeColor.text}
				</StatusCol>
			);
		},
	},
];
