import clsx from 'clsx';
import { UsageSummaryBlock } from '../../../components';
import { UsageSummaryItem } from '../../../types/usageTypes';
import { numberWithCommas } from '../../../utils/NumberUtils';
import styles from './UsageProductsRow.module.scss';

export interface UsageProductsRowProps {
	productList: UsageSummaryItem[];
	className?: string;
	selectedUsageProductIds?: string[];
	onDeleteUsageProduct(usageId: string): void;
	onEditOnUsage(usageId: string): void;
	onClickOnUsage(usageId: string): void;
}

export const UsageProductsRow = ({
	productList,
	className,
	selectedUsageProductIds,
	onDeleteUsageProduct,
	onClickOnUsage,
	onEditOnUsage,
}: UsageProductsRowProps) => {
	return (
		<div className={clsx(styles.summarySection, className)} id='summarySectionDropTarget'>
			{productList
				?.filter((item) => !!item.usageProductName)
				.map((item, index) => (
					<UsageSummaryBlock
						key={index}
						colors={item.colors}
						isSelected={selectedUsageProductIds?.includes(item.usageProductId)}
						title={item.usageProductName}
						total={item.totalUsageAmount}
						subTitle={`${numberWithCommas(item.totalUsageQuantity)} ${item.usageGroupName}`}
						connectedProducts={item.connectedProducts}
						onEdit={() => onEditOnUsage(item?.usageProductId)}
						onClick={() => onClickOnUsage(item?.usageProductId)}
						onDeleteUsageProduct={() => onDeleteUsageProduct(item.usageProductId)}
					/>
				))}
		</div>
	);
};
