import { Dispatch } from 'redux';
import { BaseErrorCode } from '@received/pricing-model';
import { ButtonStatusColor, ButtonTypes } from '..';
import { images, ImagesOptions, ImagesOptionsType } from '../../constants/images';
import { ErrorSuccessModalTypes } from '../../types/generalTypes';
import { PathsConfig, httpService } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { colors } from '../../constants/colors';

export const successErrorMassageOptions: any = {
	ERROR: {
		ALL_STRIPE_CARDS_DATA: 'failedLoadStripeCards',
		FAILED_LOAD_CUSTOMERS: 'failedLoadCustomers',
		FAILED_LOAD_PRICING_TEMPLATES: 'failedLoadPricingTemplates',
		FAILED_LOAD_CATEGORIES: 'failedLoadCategories',
		CREATE_CUSTOMER: 'createCustomerFail',
		GET_CUSTOMER_LIST: 'getCustomerList',
		GET_PRICING_MODEL: 'getPricingModel',
		FAILED_LOAD_SUMMARY: 'failLoadSummary',
		FAILED_LOAD_CASH_INFLOWS: 'failLoadCashInflows',
		FAILED_LOAD_REVENUES_RECOGNITIONS: 'failLoadRevenuesRecognitions',
		FAILED_LOAD_DEFERRED: 'failLoadDeferred',
		FAILED_LOAD_CONTRACT_DATA: 'failLoadContract',
		FAILED_LOAD_CONTRACT_TAB: 'failLoadContractTab',
		FAILED_DELETE_CONTRACT: 'failedDeleteContract',
		FAILED_CANCEL_CONTRACT: 'failedCancelContract',
		FAILED_CLONE_CONTRACT: 'failedCloneContract',
		FAILED_LOAD_INVOICE_VIEW: 'failedLoadInvoices',
		ALL_INVOICE_DATA: 'failedLoadAllInvoices',
		SEND_INVOICE: 'sendInvoiceFail',
		SEND_PAYOUT: 'sendPayoutFail',
		CHARGE_INVOICE: 'chargeInvoiceFail',
		INVOICE_DETAILS: 'invoiceDetailsFail',
		INVOICE_UPDATE: 'invoiceUpdate',
		FAILED_INVOICE_NUMBER: 'failedInvoiceNumber',
		FAILED_LOAD_PRICING_MODEL_TEMPLATES: 'failedLoadTemplates',
		CREATE_INVOICE_FAIL: 'getContractsInvoiceListFail',
		FAILED_LOAD_USAGE: 'failedLoadUsage',
		FAILED_LOAD_USAGE_LIST: 'failedLoadUsageList',
		FAILED_USAGE_REPORTS_DATA: 'failedLoadUsageReports',
		FAILED_LOAD_PRODUCTS: 'failedLoadProducts',
		FAILED_LOAD_CURRENT_USER: 'failedLoadCurrentUser',
		FAILED_LOGOUT: 'failedLogout',
		GET_COMPANIES: 'getCompanies',
		GET_COMPANY_BY_ID: 'getCompanyById',
		GET_CUSTOMER_BY_ID: 'getCustomerById',
		CREATE_COMPANY: 'createCompany',
		GET_COUNTRIES: 'getCountries',
		UPDATE_COMPANY: 'updateCompany',
		DELETE_COMPANY: 'deleteCompany',
		DELETE_PRICING_MODEL: 'deletePricingModel',
		DELETE_PRODUCT: 'failedDeleteProduct',
		INVOICE_CANCELED: 'cancelInvoiceFailed',
		INVOICE_DOWNLOAD: 'DownloadInvoiceFailed',
		CREDIT_NOTE_DOWNLOAD: 'DownloadCreditNoteFailed',
		FAILED_DUPLICATE_PRODUCT: 'failedToDuplicateProduct',
		FAILED_LOAD_FILE: 'filedLoadFile',
		FAILED_GET_LINK_TOKEN: 'failedGetLinkToken',
		FAILED_SWAP_PUBLIC_TOKEN: 'failedSwapPublicToken',
		FAILED_DISCONNECT_SALESFORCE: 'failedDisconnectSalesforce',
		FAILED_CONNECT_INTEGRATION: 'failedToConnectIntegration',
		FAILED_DISCONNECT_INTEGRATION: 'failedToDisconnectIntegration',
		FAILED_GET_CONNECTIONS: 'failedGetConnections',
		PLATFORM_ALREADY_CONNECTED: 'platformAlreadyConnected',
		FAILED_GET_SYNCED_CONTRACTS: 'failedGetSyncedContracts',
		FAILED_GET_INVOICE_STARTING_NUMBER: 'failedGetInvoiceStartingNumber',
		FAILED_GET_STARTING_NUMBER: 'failedGetStartingNumber',
		FAILED_UPDATE_INVOICE_STARTING_NUMBER: 'failedUpdateInvoiceStartingNumber',
		FAILED_SPLIT_INVOICE: 'failedSplitInvoice',
		FAILED_GET_SPLIT_INVOICES: 'failedGetSplitInvoices',
		FAILED_LOAD_INTEGRATION_LIST: 'failedToLoadIntegrations',
		CUSTOMER_COUNTRY_NOT_DEFINED: 'addCountryToCustomer',
		COUNTRY_NAME_NOT_MAPPED: 'countryNotMapped',
		FAILED_CONNECT_USAGE_PRODUCT: 'connectUsageProductFailed',
		FAILED_GET_CREDIT_NOTE: 'getCreditNoteFailed',
		FAILED_POST_CREDIT_NOTE: 'saveCreditNoteFailed',
		FAILED_UPDATE_CREDIT_NOTE: 'updateCreditNoteFailed',
		FAILED_UPDATE_PAYOUT: 'updatePayoutFailed',
		FAILED_UPDATE_CREDIT_NOTE_STARTING_NUMBER: 'updateCreditNotesStartingNumberFailed',
		FAILED_GET_CREDIT_NOTE_STARTING_NUMBER: 'failedGetCreditNoteStartingNumber',
		FAILED_GET_CREDIT_NOTES: 'failedGetCreditNotes',
		FAILED_DELETE_CREDIT_NOTE: 'failedDeleteCreditNote',
		FAILED_UPDATE_CURRENCY: 'failedUpdateCurrency',
		SEND_CREDIT: 'failedToSendCredit',
		SEND_DOCUMENT: 'failedToSendDocument',
		USAGE_LOG_FORMULA_HEADER_NOT_FOUND: 'usageLogFormulaNotFound',
		FAILED_LOAD_SCHEDULE_DATA: 'failedLoadScheduleData',
		FAILED_LOAD_CUSTOMERS_BY_INTEGRATION: 'failedLoadCustomersByIntegration',
		FAILED_AR_AGING_DATA: 'failedLoadAr',
		FAILED_UP_FOR_RENEWAL: 'failedUpForRenewal',
		FAILED_DOWNLOAD: 'failedDownload',
		FAILED_DELETE_AGREEMENT: 'failedDeleteAgreement',
		FAILED_CLONE_AGREEMENT: 'failedCloneAgreement',
		FAILED_CANCEL_AGREEMENT: 'failedCancelAgreement',
		FAILED_GET_PAYOUTS_LIST: 'failedGetPayoutsList',
		FAILED_DUPLICATE_PAYOUT: 'failedDuplicatePayout',
		FAILED_PAYOUT_DETAILS: 'payoutDetailsFail',
		PAYOUT_UPDATE: 'payoutUpdate',
	},
	SUCCESS: {
		USAGE_CREATED: 'usageCreated',
		INVOICE_STATUS_CANCELED: 'invoiceCanceled',
		CREDIT_STATUS_CANCELED: 'creditCanceled',
		USAGE_REPORTED: 'usageReported',
		PRODUCT_CREATED: 'productCreated',
		INVOICE_STATUS_UPDATED: 'invoicePaid',
		CREDIT_NOTE_STATUS_UPDATED: 'creditNotePaid',
		PAYOUT_UPDATED: 'payoutUpdated',
		PRODUCT_UPDATED: 'productUpdated',
		USAGE_UPDATED: 'usageUpdated',
		PRODUCT_DUPLICATED: 'productDuplicated',
		INVOICE_SENT: 'invoiceSentSuccessfully',
		DOCUMENT_SENT: 'documentSentSuccessfully',
		DOCUMENT_CHARGED: 'documentChargedSuccessfully',
		DOCUMENTS_CHARGE_REQUEST_SENT: 'documentsChargeRequestedSuccessfully',
		DOCUMENT_CHARGE_REQUEST_SENT: 'documentChargeRequestedSuccessfully',
		SETTING_UPDATED_SUCCESSFULLY: 'settingUpdatedSuccessfully',
		DOCUMENT_UPDATED: 'documentUpdatedSuccessfully',
	},
};

interface ModalDataByTypeReturnType {
	title: string;
	iconType: ImagesOptions[ImagesOptionsType];
	description: string;
	leftButtonTitle: string;
	leftButtonColor?: ButtonStatusColor;
	leftButtonType?: ButtonTypes;
	onLeftButtonPress: () => void;
	rightButtonTitle: string;
	rightButtonColor?: ButtonStatusColor;
	onRightButtonPress: () => void;
	responseId?: string;
	dispatch?: Dispatch;
}

export const modalDataByType = ({
	modalType,
	successErrorMassage,
	responseErrorEnum,
	title,
	responseId,
	dispatch,
}: {
	modalType: ErrorSuccessModalTypes;
	successErrorMassage: string;
	responseErrorEnum: any;
	title?: string;
	responseId?: string;
	dispatch?: Dispatch;
}): ModalDataByTypeReturnType => {
	const isMappedError = responseErrorEnum && Object.keys(ErrorSuccessModalDescriptionOptions).includes(responseErrorEnum.toString());

	switch (modalType) {
		case ErrorSuccessModalTypes.AUTHORIZATION:
			return {
				title: 'sessionTimeout',
				iconType: images.authorization,
				description: 'sessionTimeoutDescription',
				leftButtonTitle: 'close',
				rightButtonTitle: '',
				onLeftButtonPress: () => {},
				onRightButtonPress: () => {},
			};
		case ErrorSuccessModalTypes.ERROR:
			return isMappedError
				? {
						title: 'actionCantCompleted',
						iconType: images.somethingWentWrong,
						description: responseErrorEnum,
						leftButtonTitle: 'close',
						rightButtonTitle: '',
						onLeftButtonPress: () => {},
						onRightButtonPress: () => {},
				  }
				: {
						title: 'somethingWentWrong',
						iconType: images.somethingWentWrong,
						description: successErrorMassage || '',
						leftButtonTitle: responseId ? '' : 'close',
						rightButtonTitle: responseId ? 'sendReport' : '',
						onLeftButtonPress: () => {},
						onRightButtonPress: () => responseId && dispatch && sendReport(responseId, dispatch),
				  };
		case ErrorSuccessModalTypes.SUCCESS:
			return {
				title: title || 'great',
				iconType: images.success,
				description: successErrorMassage || '',
				leftButtonTitle: 'close',
				rightButtonTitle: '',
				onLeftButtonPress: () => {},
				onRightButtonPress: () => {},
			};
		case ErrorSuccessModalTypes.NO_INTERNET:
			return {
				title: 'noInternet',
				iconType: images.noInternet,
				description: 'noInternetDescription',
				leftButtonTitle: 'tryAgain',
				leftButtonColor: 'success',
				leftButtonType: 'primary',
				rightButtonTitle: '',
				onLeftButtonPress: () => window.location.reload(),
				onRightButtonPress: () => {},
			};
		case ErrorSuccessModalTypes.CANT_DELETE:
			return {
				title: title || 'somethingWentWrong',
				iconType: images.cantDelete,
				description: successErrorMassage || '',
				leftButtonTitle: 'okNeverMind',
				rightButtonTitle: '',
				onLeftButtonPress: () => {},
				onRightButtonPress: () => {},
			};
	}
};

// For each error you add, need to add it also to defaultStrings.json
export enum ErrorSuccessModalDescriptionOptions {
	REPORT_PRODUCT_NOT_FOUND = BaseErrorCode.REPORT_PRODUCT_NOT_FOUND,
	CUSTOMER_ACTIVE_INVOICE_RESTRICTION = BaseErrorCode.CUSTOMER_ACTIVE_INVOICE_RESTRICTION,
	CONTRACT_ACTIVE_INVOICE_RESTRICTION = BaseErrorCode.CONTRACT_ACTIVE_INVOICE_RESTRICTION,
	INVALID_MANUAL_INVOICE_NUMBER = BaseErrorCode.INVALID_MANUAL_INVOICE_NUMBER,
	INVOICE_NUMBER_TENANT_UQ = BaseErrorCode.INVOICE_NUMBER_TENANT_UQ,
	USAGE_LOG_FORMULA_HEADER_NOT_FOUND = BaseErrorCode.USAGE_LOG_FORMULA_HEADER_NOT_FOUND,
	CONTRACT_CUSTOMER_USAGE_DATES_OVERLAP = BaseErrorCode.CONTRACT_CUSTOMER_USAGE_DATES_OVERLAP,
	REPORT_INVOICE_RESTRICTION = BaseErrorCode.REPORT_INVOICE_RESTRICTION,
	PRICING_MODEL_USAGE_PRODUCT_RESTRICTION = BaseErrorCode.PRICING_MODEL_USAGE_PRODUCT_RESTRICTION,
	USAGE_REPORT_INVOICE_RESTRICTION = BaseErrorCode.USAGE_REPORT_INVOICE_RESTRICTION,
	USAGE_REPORT_CREDIT_NOTE_RESTRICTION = BaseErrorCode.USAGE_REPORT_CREDIT_NOTE_RESTRICTION,
	UQ_USAGE_PRODUCT_NAME = BaseErrorCode.UQ_USAGE_PRODUCT_NAME,
	UQ_COMPANY_NAME = BaseErrorCode.UQ_COMPANY_NAME,
	PRICING_TABLE_MISSING_DATA = BaseErrorCode.PRICING_TABLE_MISSING_DATA,
	PRICING_TABLE_CALCULATION_ERROR = BaseErrorCode.PRICING_TABLE_CALCULATION_ERROR,
	INVALID_USAGE_REPORT_PERIOD = BaseErrorCode.INVALID_USAGE_REPORT_PERIOD,
	IDX_CUSTOMER_REMOTE_ID = BaseErrorCode.IDX_CUSTOMER_REMOTE_ID,
	INVOICE_NUMBER_ALREADY_IN_USE = BaseErrorCode.INVOICE_NUMBER_ALREADY_IN_USE,
	CONTRACT_UPDATE_NOT_ACTIVE_NOT_ALLOWED = BaseErrorCode.CONTRACT_UPDATE_NOT_ACTIVE_NOT_ALLOWED,
}

const sendReport = async (responseId: string, dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.sendErrorReportId,
			data: {
				requestId: responseId,
				data: {},
			},
		});
		dispatch(setOpenSuccessErrorModal({ customTitle: 'thankYou', successErrorMassage: 'submissionBeenReceived' }));
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
