import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../constants/images';
import { Button, EmptyStateCard, FileUploader, Icon, Modal } from '../_uiComponents';
import { FileItem } from '../_uiComponents/FileUploader';
import { createCSVFile } from '../../utils/GeneralUtils';
import styles from './UploadCSVModal.module.scss';

export interface UploadCSVModalProps {
	isOpen: boolean;
	texts: { title: string; subTitle: string; template: string; templateName: string };
	closeModal(): void;
	onExportFromCSV(file: File): void;
}

export const UploadCSVModal = ({ isOpen, texts, closeModal, onExportFromCSV }: UploadCSVModalProps) => {
	const { t } = useTranslation('translation');
	const [file, setFile] = useState<File>();

	const onExport = (file: File) => {
		onExportFromCSV(file);
		onClose();
	};

	const onClose = () => {
		closeModal();
		setFile(undefined);
	};

	return (
		<Modal isOpen={isOpen} closeModal={onClose} className={styles.modalContainer}>
			<div className={styles.header}>
				<div>
					<h2>{t(texts.title)}</h2>
					<span className={styles.text}>{t(texts.subTitle)}</span>
				</div>

				<Button tabIndex={16} type='link' color='neutral' onClick={onClose}>
					<Icon imgType='x_icon' color='neutral700' className={styles.x_icon} height={1} />
				</Button>
			</div>

			<div className={styles.fileContainer}>
				<FileUploader className={styles.fileUploader} fileTypes={['CSV']} uploadFile={(file) => setFile(file)}>
					<EmptyStateCard src={images.exportCSV} imgAlt='add-customer-icon'>
						<span className={styles.title}>
							<span>{t('download')} </span>
							<span
								className={styles.download}
								onClick={(e) => {
									e.stopPropagation();
									createCSVFile(texts.template, texts.templateName);
								}}
							>
								{t('template')}
							</span>
							<span> {t('dragCSVHere')}.</span>
						</span>
						<span className={styles.fontSize14neutral}>{t('orClickToBrowse')}</span>
					</EmptyStateCard>
				</FileUploader>

				{file && <FileItem file={file} onDelete={() => setFile(undefined)} />}
			</div>

			<div className={styles.footer}>
				<Button onClick={onClose} type='outlined' color='neutral' className={styles.cancelButton}>
					{t('cancel')}
				</Button>
				<Button onClick={() => file && onExport(file)} disabled={!file} className={styles.importButton}>
					{t('import')}
				</Button>
			</div>
		</Modal>
	);
};
