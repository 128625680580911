import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '@received/pricing-model';
import dayjs from 'dayjs';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, StackBarGraph, Table, ActionModalModal } from '../../../../components';
import { SidebarType } from '../../../../components/Sidebars/Sidebars.utils';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { onDeleteUsageProduct } from '../../../../components/UsageProductModal/UsageProductModal.utils';
import { colors } from '../../../../constants/colors';
import { images } from '../../../../constants/images';
import { DATE_FORMAT_FOR_SERVER } from '../../../../constants/templateConstants';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { DatesData, Sort, SortOrder } from '../../../../types/generalTypes';
import { UsageReportView, UsageSummaryView, UsageSummaryItem, UsageProductAnalyticsList, UsageProductsSummary } from '../../../../types/usageTypes';
import { cashTimeUnitHandler } from '../../../../utils/DateUtils';
import { productsColorsList } from '../../../../utils/GeneralUtils';
import {
	GraphState,
	initialGraphState,
	stackBarGraphUsageDataHandler,
	usageOptionList,
	onPressDeleteUsageReport,
	UsagePageTabIndex,
} from '../../Usage.utils';
import { Store } from '../../../../types/storeTypes';
import { AnalyticCard } from '../../../Reports/AnalyticCard/AnalyticCard';
import { summaryDataBar } from './UsageProductsTab.utils';
import { UsageProductCard } from './components';
import { UsageReportResponse, usageReportsTableHeader } from '../UsageReportsTab/UsageReportsTab.utils';
import styles from './UsageProductsTab.module.scss';

export interface UsageProductsTabProps {
	isActive: boolean;
	isAddUsageProductOpen: boolean;
	openSideBarById?: string;
	timePeriod: DatesData;
	selectedUsageProductsIds: string[];
	selectedUsageProduct: string;
	selectedUsageReport: UsageReportView;
	refreshTab: boolean;
	setIsAddUsageProductOpen: Dispatch<SetStateAction<boolean>>;
	setActiveTab: Dispatch<SetStateAction<number>>;
	setSelectedUsageProductsIds: Dispatch<SetStateAction<string[]>>;
	setSelectedUsageProduct: Dispatch<SetStateAction<string>>;
	setSelectedUsageReport: Dispatch<SetStateAction<UsageReportView | undefined>>;
	setOpenSideBarById: Dispatch<SetStateAction<{ sideBarType: SidebarType; id: string }>>;
}

export const UsageProductsTab = ({
	isActive,
	timePeriod,
	selectedUsageReport,
	selectedUsageProductsIds,
	isAddUsageProductOpen,
	refreshTab,
	openSideBarById,
	setIsAddUsageProductOpen,
	setSelectedUsageProductsIds,
	setSelectedUsageProduct,
	setSelectedUsageReport,
	setOpenSideBarById,
	setActiveTab,
}: UsageProductsTabProps) => {
	const { t } = useTranslation('translation');

	//ACTIONS
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

	//GRAPHS
	const [usageBreakdown, setUsageBreakdown] = useState<GraphState>(initialGraphState);
	const [cashBreakdown, setCashBreakdown] = useState<GraphState>(initialGraphState);

	//PRODUCTS
	const [usageProducts, setUsageProducts] = useState<UsageSummaryItem[]>([]);
	const usageDataDate = {
		fromDate: dayjs().subtract(11, 'months'),
		toDate: dayjs(),
	};

	//SUMMARY
	const [summary, setSummary] = useState<UsageProductsSummary>();

	//TABLE
	const [sort, setSort] = useState<Sort>({ orderBy: 'updatedAt', order: SortOrder.DESC });
	const [usageReportsList, setUsageReportsList] = useState<UsageReportView[]>([]);

	const numberOfItems = 10;
	const dispatch = useDispatch();
	const { appCurrency } = useSelector((store: Store) => store.general);

	useEffect(() => {
		isActive && getUsageProducts();
	}, [timePeriod, isActive, isAddUsageProductOpen, refreshTab]);

	useEffect(() => {
		isActive && getUsageGraphsData();
	}, [usageProducts, refreshTab, isActive]);

	useEffect(() => {
		isActive && getUsageReports();
	}, [sort, refreshTab, isActive]);

	const onRefresh = async () => {
		await Promise.all([getUsageProducts(), getUsageProductsSummary(), getUsageGraphsData(), getUsageReports()]);
	};

	const getUsageProducts = async () => {
		try {
			const res: UsageSummaryView[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getUsageProductSummaryList,
					params: {
						fromDate: dayjs(timePeriod.from).format(DATE_FORMAT_FOR_SERVER),
						toDate: dayjs(timePeriod.to).format(DATE_FORMAT_FOR_SERVER),
					},
				})
			).data;
			const usageProducts: UsageSummaryItem[] = res
				?.filter((item) => !!item.usageProductName)
				.map((usageSummary, index) => {
					return {
						...usageSummary,
						colors: productsColorsList[index % productsColorsList.length],
					};
				});

			setUsageProducts(usageProducts);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	const getUsageProductsSummary = async () => {
		// TODO -  waiting for server implementation
		// try {
		// 	const res: UsageProductsSummary = (
		// 		await httpService({
		// 			dispatch,
		// 			path: PathsConfig.getUsageSummaryData,
		// 			params: {
		// 				pageSize: numberOfItems,
		// 				pageNumber: 0,
		// 				orderBy: sort.orderBy,
		// 				order: sort.order,
		// 				usageProductIds: selectedUsageProductsIds || undefined,
		// 			},
		// 		})
		// 	).data;
		// 	setSummary(res);
		// } catch (error) {
		// 	dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		// }
	};

	const getUsageGraphsData = async () => {
		try {
			const res: UsageProductAnalyticsList[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getUsageProductAnalyticsList,
					params: {
						fromDate: dayjs(timePeriod.from).format(DATE_FORMAT_FOR_SERVER),
						toDate: dayjs(timePeriod.to).format(DATE_FORMAT_FOR_SERVER),
						usageProductIds: selectedUsageProductsIds || undefined,
					},
				})
			).data;

			const usages = res?.map((usageSummary) => {
				return {
					...usageSummary,
					products: usageSummary?.products?.map((product) => {
						return {
							...product,
							colors: usageProducts?.find((usageProduct) => usageProduct.usageProductId == product.usageProductId)?.colors,
						};
					}),
				};
			});

			const cashBreakdownData = stackBarGraphUsageDataHandler(
				usages,
				colors.primary800,
				cashTimeUnitHandler(dayjs(timePeriod.from).toDate(), dayjs(timePeriod.to).toDate()),
				false,
				timePeriod.value,
			);
			setCashBreakdown({ data: cashBreakdownData.convertedData, isEmpty: cashBreakdownData.isEmptyData, dataFormat: cashBreakdownData.dataFormat });

			const usageBreakdownData = stackBarGraphUsageDataHandler(
				usages,
				colors.primary,
				cashTimeUnitHandler(dayjs(timePeriod.from).toDate(), dayjs(timePeriod.to).toDate()),
				true,
				timePeriod.value,
			);
			setUsageBreakdown({
				data: usageBreakdownData.convertedData,
				isEmpty: usageBreakdownData.isEmptyData,
				dataFormat: usageBreakdownData.dataFormat,
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	const getUsageReports = async () => {
		try {
			const res: UsageReportResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getUsageReportsViewData,
					params: {
						pageSize: numberOfItems,
						pageNumber: 0,
						orderBy: sort.orderBy,
						order: sort.order,
						usageProductIds: selectedUsageProductsIds || undefined,
					},
				})
			).data;

			setUsageReportsList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_USAGE_REPORTS_DATA }));
		}
	};

	const onOpenReport = (rowData: UsageReportView) => {
		setSelectedUsageReport(rowData);
		setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: rowData?.usageReportId });
	};

	const onDeleteReport = (rowData: UsageReportView) => {
		setIsDeleteModalOpen(true);
		setSelectedUsageReport(rowData);
	};

	const onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedUsageReport(undefined);
		getUsageProducts();
	};

	const onSelectUsageProduct = async (id: string) => {
		if (selectedUsageProductsIds.includes(id)) {
			setSelectedUsageProductsIds(selectedUsageProductsIds.filter((usageProductId) => usageProductId !== id));
		} else {
			setSelectedUsageProductsIds([...selectedUsageProductsIds, id]);
		}
		await Promise.all([getUsageProducts(), getUsageProductsSummary(), getUsageGraphsData()]);
	};

	const onEditUsageProductById = (id: string) => {
		setSelectedUsageProduct(id);
		setIsAddUsageProductOpen(true);
	};

	const onDeleteUsageProductById = async (id: string) => {
		await onDeleteUsageProduct(id, dispatch);
		await onRefresh();
	};

	return (
		<div className={styles.pageContainer}>
			<div className={styles.leftContainer}>
				<div className={styles.leftContainerHeader}>
					<div className={styles.leftContainerLabels}>
						<span className={styles.leftContainerTitle}>{t('usageProducts')}</span>
						<span className={styles.leftContainerSubtitle}>{`${usageProducts.length} ${t('products')}`}</span>
					</div>
					<Button type='outlined' color='neutral' onClick={() => setIsAddUsageProductOpen(true)}>
						<Icon imgType='add' color='neutral700' width={1.5} />
					</Button>
				</div>
				<div className={styles.scrollable} data-testid='scrollable-container'>
					{usageProducts.map((usageProduct) => (
						<UsageProductCard
							key={usageProduct.usageProductId}
							usageProduct={usageProduct}
							isSelected={selectedUsageProductsIds?.includes(usageProduct.usageProductId)}
							onClick={() => onSelectUsageProduct(usageProduct.usageProductId)}
							onEdit={() => onEditUsageProductById(usageProduct.usageProductId)}
							onDelete={() => onDeleteUsageProductById(usageProduct.usageProductId)}
						/>
					))}
				</div>
			</div>
			<div className={styles.rightContainer}>
				{/* TODO -  waiting for server implementation 
				<div className={styles.summaryBar}>
					{summaryDataBar(t, appCurrency, summary).map((card, index) => (
						<AnalyticCard key={index} {...card} classNames={{ container: styles.summaryCard }} />
					))}
				</div> */}
				<div className={styles.graphs}>
					<div className={styles.analyticsContainer}>
						<span className={styles.cardTitle}>{t('usageBreakdown')}</span>

						<div className={styles.analyticsGraphContainer}>
							<StackBarGraph
								isEmpty={usageBreakdown.isEmpty}
								chartData={usageBreakdown.data}
								isStacked
								dataFormat={usageBreakdown.dataFormat}
								emptyTitle={t('noDataSource')}
							/>
						</div>
					</div>

					<div className={styles.analyticsContainer}>
						<span className={styles.cardTitle}>{`${t('cashBreakdown')}`}</span>

						<div className={styles.analyticsGraphContainer}>
							<StackBarGraph
								isCashflow
								isEmpty={cashBreakdown.isEmpty}
								chartData={cashBreakdown.data}
								isStacked
								dataFormat={cashBreakdown.dataFormat}
								emptyTitle={t('noDataSource')}
							/>
						</div>
					</div>
				</div>
				<div className={styles.table}>
					<Table
						sort={sort}
						title={t('latestUsageActivities')}
						subTitle={`${usageDataDate.fromDate.format(DATE_FORMAT)} - ${usageDataDate.toDate.format(DATE_FORMAT)}`}
						header={usageReportsTableHeader}
						filters={
							<Button type='outlined' color='neutral' onClick={() => setActiveTab(UsagePageTabIndex.reports)}>
								{t('allActivities')}
								<Icon imgType='navigate' color='neutral700' />
							</Button>
						}
						rows={usageReportsList}
						hideTableFooter
						optionsList={(row) => usageOptionList(row, onDeleteReport, dispatch)}
						onRowPress={onOpenReport}
						tableRowClassName={styles.tableRowClassName}
						onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					/>
				</div>
			</div>

			<ActionModalModal
				rightButton={{ title: 'delete', color: 'destructive' }}
				imagesOptions={images.deleteItem}
				isOpen={isDeleteModalOpen}
				deleteMessage={`${t('deleteMessagePrefix').replace('__', 'usage report')}${t('deleteMessagePostfix').replace('__', 'report')}`}
				onAccept={() => {
					selectedUsageReport && onPressDeleteUsageReport(dispatch, getUsageReports, selectedUsageReport);
					onCloseDeleteModal();
				}}
				onClose={onCloseDeleteModal}
			/>
		</div>
	);
};
