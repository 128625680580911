import clsx from 'clsx';
import { Icon } from '../_uiComponents';
import styles from './DocumentDetail.module.scss';

interface DocumentDetailProps {
	title: string;
	body: string | React.ReactNode;
	footer?: string | React.ReactNode;
	displayArrowIcon?: boolean;
	flipArrow?: boolean;
	className?: string;
	onClick?: () => void;
}

export const DocumentDetail = ({ title, body, displayArrowIcon, flipArrow, footer, className, onClick }: DocumentDetailProps) => {
	return (
		<div className={clsx(styles.detailsContainer, className)} onClick={onClick}>
			<span className={clsx(styles.title, styles.text)}>{title}</span>

			<div className={styles.bodyContainer}>
				<span className={styles.body}>{body}</span>
				{displayArrowIcon && (
					<Icon imgType='arrow_down' width={1} color='neutral700' className={clsx(styles.arrow, flipArrow ? styles.arrowDown : styles.arrowUp)} />
				)}
			</div>

			<span className={clsx(styles.text, styles.footer)}>{footer}</span>
		</div>
	);
};
