import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Input } from '../../_uiComponents';
import { BillingDetailsFormType } from '../BillingDetails.utils';
import { tabIndexes } from './BillingDetailsForm.utils';
import styles from './BillingDetailsForm.module.scss';

interface BillingDetailsFormProps {
	isOpen: boolean;
	data?: BillingDetailsFormType;
	setCurrentBankData: (data: BillingDetailsFormType) => void;
}

export const BillingDetailsForm = ({ isOpen, data, setCurrentBankData }: BillingDetailsFormProps) => {
	const { t } = useTranslation('translation');

	const updateForm = (field: string, value: string) => {
		if (data) {
			setCurrentBankData({ ...data, [field]: value, [`${field}Error`]: false });
		}
	};

	return (
		<div className={clsx(styles.formContent, isOpen ? styles.isOpen : styles.isClosed)}>
			<div className={styles.accountContainer}>
				<Input
					tabIndex={tabIndexes.BANK_NAME}
					autoFocus
					value={data?.bank}
					title={t('bankNameNumber')}
					placeholder={t('bankNamePlaceholder')}
					showError={data?.bankError}
					onChange={(value) => updateForm('bank', value)}
					titleStyle={styles.fontSize14}
					containerClass={clsx(styles.accountInput, styles.input)}
					required
					onBlur={(e) => {
						updateForm('bank', e.target.value.trim());
					}}
				/>
				<Input
					tabIndex={tabIndexes.BANK_CODE}
					value={data?.bankCode}
					title={t('bankCode')}
					placeholder={t('0000')}
					onChange={(value) => updateForm('bankCode', value)}
					titleStyle={styles.fontSize14}
					containerClass={clsx(styles.branchInput, styles.input)}
					maxLength={80}
				/>
			</div>

			<div className={styles.accountContainer}>
				<Input
					tabIndex={tabIndexes.ACCOUNT_NUMBER}
					value={data?.account}
					title={t('accountNumber')}
					placeholder={'0000000'}
					showError={data?.accountError}
					onChange={(value) => updateForm('account', value)}
					titleStyle={styles.fontSize14}
					containerClass={clsx(styles.accountInput, styles.input)}
					required
				/>

				<Input
					tabIndex={tabIndexes.BRANCH}
					value={data?.branch}
					title={t('branchNumber')}
					placeholder={t('0000')}
					onChange={(value) => updateForm('branch', value)}
					titleStyle={styles.fontSize14}
					containerClass={clsx(styles.branchInput, styles.input)}
					maxLength={80}
				/>
			</div>

			<Input
				tabIndex={tabIndexes.ACCOUNT_NAME}
				value={data?.accountName}
				title={t('accountName')}
				placeholder={t('giveYourAccountName')}
				onChange={(value) => updateForm('accountName', value)}
				titleStyle={styles.fontSize14}
				containerClass={styles.input}
				maxLength={80}
			/>

			<Input
				tabIndex={tabIndexes.ADDRESS}
				value={data?.address}
				title={t('bankAddress')}
				placeholder={t('bankAddressPlaceholder')}
				showError={data?.swiftError}
				onChange={(value) => updateForm('address', value)}
				titleStyle={styles.fontSize14}
				containerClass={styles.input}
			/>

			<Input
				tabIndex={tabIndexes.ROUTING}
				value={data?.routing}
				title={t('routing')}
				placeholder={'1234 5670 89'}
				showError={data?.routingError}
				onChange={(value) => updateForm('routing', value)}
				titleStyle={styles.fontSize14}
				containerClass={styles.input}
				maxLength={80}
			/>
			<Input
				tabIndex={tabIndexes.SWIFT}
				value={data?.swift}
				title={t('swift')}
				placeholder={t('swiftPlaceholder')}
				showError={data?.swiftError}
				onChange={(value) => updateForm('swift', value)}
				titleStyle={styles.fontSize14}
				containerClass={styles.input}
				maxLength={80}
			/>

			<Input
				tabIndex={tabIndexes.IBAN}
				value={data?.iban}
				title={t('iban')}
				placeholder={t('ibanPlaceholder')}
				showError={data?.ibanError}
				onChange={(value) => updateForm('iban', value)}
				titleStyle={styles.fontSize14}
				containerClass={styles.input}
				maxLength={80}
			/>
		</div>
	);
};
