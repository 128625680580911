import { DEFAULT_PHONE_PREFIX } from '../../../constants/generalConstants';
import { Customer } from '../../../types/customerTypes';
import { isValidEmail } from '../../../utils/validationUtils';
export interface EntityForm extends Customer {
	nameError: boolean;
	einError: boolean;
	legalNameError: boolean;
	countryError: boolean;
	phoneError: boolean;
	countryStateError: boolean;
	cityError?: boolean;
	countryCodeError: boolean;
	shippingAddressError?: boolean;
	emailError: boolean;
	postalCodeError?: boolean;
}

export const initialState: EntityForm = {
	name: '',
	legalName: '',
	city: '',
	billingAddress: '',
	nameError: false,
	legalNameError: false,
	einError: false,
	country: '',
	countryError: false,
	countryState: '',
	countryStateError: false,
	cityError: false,
	shippingAddressError: false,
	postalCodeError: false,
	emailError: false,
	phone: {
		countryCode: DEFAULT_PHONE_PREFIX,
	},
	countryCodeError: false,
	phoneError: false,
	billingDetails: { billingAccounts: [] },
	integrationsRemoteIds: [],
};

export const entityValidation = (data: EntityForm) => {
	const entityData = { ...data };
	let isValid = true;

	if (!entityData.legalName) {
		entityData.legalNameError = true;
		isValid = false;
	}

	if (!entityData.name) {
		entityData.nameError = true;
		isValid = false;
	}

	if (entityData?.email && !isValidEmail(entityData?.email)) {
		entityData.emailError = true;
		isValid = false;
	}

	if (entityData.phone?.phone && !isNaN(+entityData.phone?.phone) && entityData.phone?.phone.length < 7) {
		entityData.phoneError = true;
		isValid = false;
	}

	if (entityData.phone?.countryCode && isNaN(+entityData.phone.countryCode)) {
		entityData.countryCodeError = true;
		isValid = false;
	}

	return { isValid, data: entityData };
};

export const tabIndexes = {
	LEGAL_NAME: 0,
	NAME: 1,
	EIN: 2,
	COUNTRY: 3,
	ZIP_CODE: 4,
	STATE: 5,
	CITY: 6,
	FULL_ADDRESS: 7,
	EMAIL: 8,
	PHONE_PREFIX: 9,
	PHONE_NUMBER: 10,
	NEXT_BUTTON: 11,
};
