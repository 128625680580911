import { StepType } from '../../../../../../types/dunningTypes';

export const timeIndicatorLabels = {
	BEFORE: 'Before',
	AFTER: 'After',
};

export const timeIndicatorSelect = [
	{
		label: timeIndicatorLabels.BEFORE,
		value: StepType.BEFORE,
	},
	{
		label: timeIndicatorLabels.AFTER,
		value: StepType.AFTER,
	},
];
