import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '../_uiComponents';
import styles from './PaginationBar.module.scss';

interface PaginationBarProps {
	pageNumber: number;
	numPages: number;
	pageScale: number;
	goToPreviousPage: () => void;
	goToNextPage: () => void;
	zoomIn: () => void;
	zoomOut: () => void;
}

export const PaginationBar = ({ pageNumber, numPages, pageScale, goToPreviousPage, goToNextPage, zoomIn, zoomOut }: PaginationBarProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.paginationContainer}>
			<div className={styles.actionsContainer}>
				<Button type='link' color='neutral' className={styles.actionButton} onClick={goToPreviousPage} disabled={pageNumber == 1}>
					<Icon imgType='arrow_back' color='white' width={1.8} className={styles.left} />
				</Button>

				<span className={styles.pageNumber}>{`${t('page')} ${pageNumber}/${numPages}`}</span>

				<Button type='link' color='neutral' className={styles.actionButton} onClick={goToNextPage} disabled={pageNumber == numPages}>
					<Icon imgType='arrow_back' color='white' width={1.8} className={styles.right} />
				</Button>
			</div>

			<div className={styles.actionsContainer}>
				<Button type='link' color='neutral' className={styles.actionButton} onClick={zoomIn} disabled={pageScale >= 3}>
					<Icon imgType='add' color='white' width={1.8} />
				</Button>

				<Icon imgType='zoom' width={2} color='white' className={styles.zoom} />

				<Button type='link' color='neutral' className={styles.actionButton} onClick={zoomOut} disabled={pageScale <= 0.3}>
					<Icon imgType='minus' color='white' width={1.8} />
				</Button>
			</div>
		</div>
	);
};
