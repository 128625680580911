import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '../../..';
import { images } from '../../../../constants/images';
import { onClickUpgradeRevRecFeature } from '../../../../utils/GeneralUtils';
import styles from './RevenueFeatureDisabled.module.scss';

interface RevenueFeatureDisabledProps {
	className?: string;
}

export const RevenueFeatureDisabled = ({ className }: RevenueFeatureDisabledProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.needLicense, className)}>
			<img alt='need-license-for-rev-rec' src={images.analytics} className={styles.noDataImage} />

			<div className={styles.flexColumnCenter}>
				<span className={styles.noDataTitle}>{t('revenueRecognition')}</span>
				<span className={styles.noDataDesc}>{t('revenueRecognitionNeedLicense')}</span>

				<Button className={styles.needLicenseButton} color='calming' type='secondary' onClick={onClickUpgradeRevRecFeature}>
					<Icon imgType='spark' color='calming' width={2} />
					{t('upgrade')}
				</Button>
			</div>
		</div>
	);
};
