import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Icon, MenuList, MenuOption, Select } from '../../../_uiComponents';
import { UsageConnectionStatusTypes, UsageProduct } from '../../../../types/usageTypes';
import { aggregationByScope, aggregationMethods, initialAggregations, UsageProductAggregation } from './UsageProductItem.utils';
import styles from './UsageProductItem.module.scss';

interface UsageProductItemProps {
	status?: UsageConnectionStatusTypes;
	item: UsageProduct;
	isSelected?: boolean;
	aggregationData?: UsageProductAggregation;
	onPress?: () => void;
	onEdit: () => void;
	setAggregation: ({ method, scope }: UsageProductAggregation) => void;
}

export const UsageProductItem = ({ status, item, isSelected, aggregationData, onPress, setAggregation, onEdit }: UsageProductItemProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);

	const { t } = useTranslation('translation');

	useEffect(() => {
		setIsOpen(!!isSelected);
	}, [isSelected]);

	const menuOptions: MenuOption[] = [{ title: t('editProduct'), onPress: () => onEdit(), iconType: 'edit' }];

	const onClick = () => {
		setIsOpen(!isOpen);
		onPress && onPress();
	};

	return (
		<div className={clsx(styles.container, isSelected && styles.selectedContainer, isOpen && styles.isOpen)}>
			<div onClick={onClick} className={styles.topContainer}>
				<div className={styles.flex}>
					<div className={styles.iconContainer}>
						<Icon imgType='graph' color='primary' className={styles.icon} />
						{status && <div className={clsx(styles.statusDot, status && styles[status])}></div>}
					</div>
					<div className={styles.flexColumn}>
						<div className={styles.text}>{item.name}</div>
						<div className={styles.description}>{item.usageGroup?.description}</div>
					</div>
				</div>

				<MenuList optionsList={menuOptions} isMenuOpen={isOptionsMenuOpen} openMenu={() => setIsOptionsMenuOpen(false)}>
					<Button type='link' color='neutral' className={styles.menuButton} onClick={setIsOptionsMenuOpen}>
						<Icon imgType='hamburger' className={clsx(styles.hamburger, isOptionsMenuOpen ? styles.isMenuOpen : styles.isMenuClosed)} />
					</Button>
				</MenuList>
			</div>

			<div className={styles.topContainer}>
				<Select
					data={aggregationMethods}
					headerStyle={styles.selectTitle}
					title={t('aggregateFunction')}
					containerStyle={styles.select}
					defaultValue={aggregationData?.method}
					className={styles.inputClassName}
					onChange={(value: any) => setAggregation({ scope: aggregationData?.scope || initialAggregations.scope, method: value })}
				/>

				<Select
					data={aggregationByScope}
					headerStyle={styles.selectTitle}
					containerStyle={styles.select}
					defaultValue={aggregationData?.scope}
					className={styles.inputClassName}
					title={t('aggregateReports')}
					onChange={(value: any) => setAggregation({ method: aggregationData?.method || initialAggregations.method, scope: value })}
				/>
			</div>
		</div>
	);
};
