import React, { CSSProperties, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tooltip, TooltipPlacement } from '../Tooltip/Tooltip';
import styles from './GroupButton.module.scss';

export interface GroupButtonProps {
	buttonArray: {
		children: React.ReactNode | string;
		disabled?: { buttonDisabled?: boolean; tooltipComponent: React.ReactNode; tooltipPlacement?: TooltipPlacement; tooltipClassName?: string };
		onClick?: () => void;
	}[];
	disabled?: boolean;
	style?: CSSProperties;
	selectedIndex?: number;
	className?: string;
	buttonClassName?: string;
	setSelectedIndex?: (index: number) => void;
}

export const GroupButton = ({ buttonArray, disabled, style, selectedIndex, setSelectedIndex, className, buttonClassName }: GroupButtonProps) => {
	const [activeIndex, setActiveIndex] = useState<number>();

	useEffect(() => {
		setActiveIndex(selectedIndex);
	}, [selectedIndex]);

	return (
		<div className={clsx(styles.buttonsContainer, disabled && styles.disabled, className)} data-testid='group-button' style={style}>
			{buttonArray.map((item, index) => {
				return (
					<Tooltip
						key={index}
						tooltipComponent={item?.disabled?.tooltipComponent}
						disabled={!item?.disabled?.buttonDisabled}
						dataTestId={`group-button-inner-${index}`}
						onClick={() => {
							if (!item?.disabled?.buttonDisabled) {
								item.onClick?.();
								setActiveIndex(index);
								setSelectedIndex?.(index);
							}
						}}
						className={item?.disabled?.tooltipClassName}
						placement={item?.disabled?.tooltipPlacement}
						containerClassName={clsx(
							styles.generalGroupButton,
							buttonClassName,
							activeIndex === index && styles.activeButtonStyle,
							item?.disabled?.buttonDisabled && styles.disabledButton,
						)}
					>
						{item.children}
					</Tooltip>
				);
			})}
		</div>
	);
};
