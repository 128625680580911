export interface Sequence {
	id?: string;
	name: string;
	isDefault: boolean;
	customers: number;
	steps: Step[];
}

export interface Step {
	id?: string;
	name?: string;
	days: number | undefined;
	timeIndicator: StepType;
}

export enum StepType {
	BEFORE = 'BEFORE',
	AFTER = 'AFTER',
}
