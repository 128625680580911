import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination, Select, Switch } from '../..';
import { SelectItem } from '../../../../types/generalTypes';
import styles from './TableFooter.module.scss';
interface TableFooterProps {
	numberOfPages?: number;
	totalNumberOfItems?: number;
	currentPageNumber?: number;
	rowsNumber: number;
	numberOfItems?: string;
	showSwitch?: boolean;
	defaultSwitchChecked?: boolean;
	disableSwitch?: boolean;
	pageItemsOptions?: SelectItem[];
	onUpdateNumberOfItems?: (num: string) => void;
	onUpdatePageNumber?: (num: number) => void;
	onToggleSwitch?: (isChecked: boolean) => void;
}

export const TableFooter = ({
	numberOfPages,
	totalNumberOfItems,
	rowsNumber,
	currentPageNumber,
	numberOfItems,
	showSwitch,
	defaultSwitchChecked,
	disableSwitch,
	pageItemsOptions,
	onUpdateNumberOfItems,
	onUpdatePageNumber,
	onToggleSwitch,
}: TableFooterProps) => {
	const { t } = useTranslation('translation');

	const pageOptions = pageItemsOptions || [
		{ label: `20 ${t('items')}`, value: '20' },
		{ label: `50 ${t('items')}`, value: '50' },
		{ label: `100 ${t('items')}`, value: '100' },
	];

	const onUpdateNumberItems = (value: string) => {
		onUpdatePageNumber && onUpdatePageNumber(0);
		onUpdateNumberOfItems && onUpdateNumberOfItems(value);
	};

	return (
		<div className={styles.footerContainer}>
			<Select
				defaultValue={numberOfItems ? pageOptions.find((item) => item.value === numberOfItems) : pageOptions[0]}
				data={pageOptions}
				onChange={onUpdateNumberItems}
				disabled={totalNumberOfItems === 0}
			/>

			<Pagination numberOfPages={numberOfPages} onUpdatePageNumber={onUpdatePageNumber} currentPageNumber={currentPageNumber} />

			<div className={clsx(styles.flexCenter, disableSwitch && styles.disabled)}>
				{showSwitch && (
					<div className={styles.flexCenter}>
						<div>{t('showDrafts')}</div>
						<Switch onChange={onToggleSwitch} style={{ margin: '0 1rem' }} defaultDisable={disableSwitch} defaultChecked={defaultSwitchChecked} />
					</div>
				)}

				<div>{`${rowsNumber}/${totalNumberOfItems} ${t('items')}`}</div>
			</div>
		</div>
	);
};
