import clsx from 'clsx';
import { ClickableCard } from '../ClickableCard/ClickableCard';
import styles from './ClickableCardsList.module.scss';

export interface ClickableCardsItem {
	isSelected?: boolean;
	color?: string;
	description?: string;
	id?: string;
	productName?: string;
}

interface ClickableCardsListProps {
	cards: Array<ClickableCardsItem>;
	className?: string;
	setCards: (cards: Array<ClickableCardsItem>) => void;
}

export const ClickableCardsList = ({ cards, className, setCards }: ClickableCardsListProps) => {
	const onClickProduct = (product: ClickableCardsItem) => {
		if (cards.length === 1) return;
		const tempProducts = [...cards];
		product = { ...product, isSelected: !product.isSelected };
		tempProducts.splice(
			tempProducts.findIndex((selectedProduct) =>
				selectedProduct.id ? selectedProduct.id === product.id : selectedProduct.productName === product.productName,
			),
			1,
			product,
		);
		setCards(tempProducts);
	};

	return (
		<div className={clsx(styles.container, className)}>
			{cards?.map((card, index) => (
				<ClickableCard
					key={index}
					{...card}
					title={card.productName}
					description={card?.description}
					isSelected={card.isSelected}
					color={card?.color}
					onClick={() => onClickProduct(card)}
				/>
			))}
		</div>
	);
};
