import { AccountingEventType, DocumentState } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { MenuOption } from '../../components';
import { StackBarDataset, StackBarGraphData } from '../../components/_uiComponents/Graphs/StackBarGraph/StackBarGraph';
import { initialStackBarGraphNumberFormat } from '../../components/_uiComponents/Graphs/StackBarGraph/StackBarGraph.utils';
import { colors } from '../../constants/colors';
import { UsageProductsTab, UsageReportsTab } from './UsagePageTabs';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { NumberFormat, TimeUnit } from '../../types/generalTypes';
import { UsageProductAnalyticsList, UsageProductSummary, UsageReportView } from '../../types/usageTypes';
import { downloadFile } from '../../utils/GeneralUtils';
import { absoluteValueArray, getNumberingFormat } from '../../utils/NumberUtils';
import { FilterTimeOptions } from '../../components/Filters/DateRangeFilters.utils';
import { monthLabel } from '../../utils/GraphsUtils';
import { DataStreamTab } from './UsagePageTabs/DataStreamTab/DataStreamTab';

export enum UsagePageTabIndex {
	products,
	reports,
	// logs,
	dataStream,
}

export const usagePageTabs = [
	{ title: 'products', component: (prop?: any) => <UsageProductsTab {...prop} />, tabIndex: UsagePageTabIndex.products },
	{
		title: 'reports',
		component: (prop?: any) => <UsageReportsTab {...prop} />,
		tabIndex: UsagePageTabIndex.reports,
	},
	// WIP
	// {
	// 	title: 'dataStream',
	// 	component: (prop?: any) => <DataStreamTab {...prop} />,
	// 	tabIndex: UsagePageTabIndex.dataStream,
	// },
];

export interface GraphState {
	isEmpty: boolean;
	dataFormat: NumberFormat;
	data: StackBarGraphData;
}

export const initialGraphState: GraphState = {
	isEmpty: false,
	data: { labels: [], datasets: [] },
	dataFormat: initialStackBarGraphNumberFormat,
};

export const stackBarGraphUsageDataHandler = (
	data: UsageProductAnalyticsList[],
	backgroundColor: string | string[],
	timeUnit: TimeUnit,
	isQuantity: boolean,
	filter?: FilterTimeOptions,
) => {
	let isEmptyData = true;
	const convertedData = data.reduce(
		(
			acc: {
				labels: string[];
				datasets: StackBarDataset[];
				isEmpty: boolean;
			},
			{ date, products },
		) => {
			const labels = acc.labels.concat(monthLabel(date, timeUnit, filter));
			const { monthlyDataSet, isEmpty } = getDataSet(products, isQuantity);
			if (!isEmpty) {
				isEmptyData = isEmpty;
			}
			const updatedDataSets = appendMonthlyDataSet(acc.datasets, monthlyDataSet, backgroundColor, products);
			return { labels, datasets: updatedDataSets, isEmpty: false };
		},
		{
			labels: [],
			datasets: [],
			isEmpty: false,
		},
	);
	const allValues = convertedData.datasets.map((item) => (!item.data ? item.data : 0)).flat();

	return { convertedData, isEmptyData, dataFormat: getNumberingFormat(Math.max(...absoluteValueArray(allValues))) };
};

export const usageCashflowDataHandler = (data: StackBarGraphData, resData: UsageProductAnalyticsList[]) => {
	const randomColors = [colors.neutral, colors.neutral300, colors.neutral400, colors.primary300, colors.neutral600];

	const sumLine = resData.map((item) => {
		let sum = 0;
		item.products.forEach((item) => (sum = sum + item.totalUsageAmount));
		return sum;
	});
	const newData = data.datasets.map((item) => {
		return { data: item.data, label: item.label, borderColor: randomColors[Math.floor(Math.random() * randomColors.length)] };
	});

	const dataForGraph = {
		labels: data.labels,
		data: [...newData, { data: sumLine, backgroundColor: colors.primary400, borderColor: colors.primary }],
	};
	return dataForGraph;
};

const getDataSet = (products: UsageProductSummary[], isQuantity?: boolean) => {
	let isEmpty = true;

	const monthlyDataSet = products.reduce((acc: Record<string, number>, { usageProductName, totalUsageAmount, totalUsageQuantity }) => {
		usageProductName = usageProductName || '';
		if (isQuantity ? Math.abs(totalUsageQuantity) > 0 : Math.abs(totalUsageAmount) > 0) {
			isEmpty = false;
		}
		return { ...acc, [usageProductName]: isQuantity ? totalUsageQuantity : totalUsageAmount };
	}, {});
	return { monthlyDataSet, isEmpty };
};

const appendMonthlyDataSet = (
	datasets: StackBarDataset[],
	newDataSet: Record<string, number>,
	backgroundColor: string | string[],
	products: UsageProductSummary[],
) => {
	return Object.entries(newDataSet).reduce((acc, [label, data]) => {
		const productDataSetIndex = acc.findIndex((dataset) => dataset.label === label);
		if (productDataSetIndex >= 0) {
			const updatedDataSets = [...acc];
			updatedDataSets[productDataSetIndex].data = updatedDataSets[productDataSetIndex].data.concat(data);
			return updatedDataSets;
		}
		return acc.concat({
			label,
			data: [data],
			backgroundColor: products?.find((product) => product?.usageProductName == label)?.colors?.textColor || backgroundColor,
			borderColor: products?.find((product) => product?.usageProductName == label)?.colors?.textColor || backgroundColor,
		});
	}, datasets);
};

export const usageOptionList = (row: UsageReportView, onDelete: (row: UsageReportView) => void, dispatch: Dispatch) => {
	let list: MenuOption[] = [
		{
			title: 'Download Usage Report',
			onPress: () => downloadUsageLogs([row.usageReportId], dispatch),
			iconType: 'download',
		},
	];

	if (isUsageReportEditable(row)) {
		list = [...list, { title: 'Delete Usage Report', onPress: () => onDelete(row), iconType: 'delete', color: 'destructive' }];
	}

	return list;
};

export const isUsageReportEditable = (usageReportView: UsageReportView) => {
	switch (usageReportView.accountingEventType) {
		case AccountingEventType.INVOICE:
		case AccountingEventType.DEBIT:
			return [DocumentState.DRAFT, DocumentState.REVIEW_MISSING_DATA, DocumentState.COLLECTING_INFO, DocumentState.REVIEW_READY_TO_SEND].includes(
				usageReportView.state,
			);
		case AccountingEventType.CREDIT:
			return Object.values(DocumentState).indexOf(usageReportView.state) < Object.values(DocumentState).indexOf(DocumentState.PAID);
	}
};

export const onPressDeleteUsageReport = async (dispatch: Dispatch, onRefresh: () => void, selectedUsageReport: UsageReportView) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.onDeleteUsageReport,
			urlParam: { id: selectedUsageReport?.usageReportId },
		});

		onRefresh();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const downloadUsageLogs = async (ids: string[], dispatch: Dispatch) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.downloadUsageLogs,
			params: { usageReportIds: ids },
			responseType: 'blob',
		});

		downloadFile(res);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const downloadDocumentUsageLogs = async (documentIds: string[], dispatch: Dispatch) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.downloadDocumentUsageLogs,
			params: { documentIds: documentIds },
			responseType: 'blob',
		});

		downloadFile(res);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
