import { Currency, EventType, AccountingEventType, IntegrationPlatform, IntegrationStatus, UserPermissions } from '@received/pricing-model';
import { RouteObject } from 'react-router-dom';
import { IconImgType, DoughnutGraphData, StackBarGraphData } from '../components';
import { FilterTimeOptions } from '../components/Filters/DateRangeFilters.utils';
import { BillingDetails } from './contractTypes';
import { Invoice, InvoiceView } from './invoiceTypes';
import { CreditNote, CreditNoteView } from './creditNoteTypes';
import { UsageReportView } from './usageTypes';
import { Payout, PayoutView } from './PayoutsTypes';

export interface RoutesType extends RouteObject {
	name: string;
	path: string;
	dataTestId?: string;
	showOnSidebar: boolean;
	iconType?: IconImgType;
	element?: React.ReactNode;
	subElements?: RoutesType[];
	licenses?: UserPermissions[];
}

export enum AsyncMessageStatus {
	SUCCESS = 'SUCCESS',
	FAILURE = 'FAILURE',
}

export interface AsyncMessageData {
	msg: string;
	data: any;
	status: AsyncMessageStatus;
	type: EventType;
}

export interface connectionStatusData {
	dataConnectionId: string;
	companyId: string;
	newStatus: IntegrationStatus;
}

export enum PaymentTimeOptions {
	BEFORE_USE = 'BEFORE_USE',
	DURING_USE = 'DURING_USE',
	AFTER_USE = 'AFTER_USE',
}

export enum PaymentMethodOptions {
	BANK_TRANSFER = 'bank_transfer',
	PAYMENT_LINK = 'payment_link',
	AUTO_CHARGE = 'auto_charge',
}

export interface SelectItem {
	label: string | number;
	value: any;
	isDisabled?: boolean;
	customComponent?: (item: SelectItem) => JSX.Element;
}

export interface CountrySelectItem extends SelectItem {
	prefix: string;
	phonePrefix?: string;
}

export interface HeaderTableItem {
	name: string;
	type: string;
}

export interface GeoLocationOptionsData {
	countries: CountrySelectItem[];
	usaStates: CountrySelectItem[];
}

export enum ErrorSuccessModalTypes {
	ERROR = 'ERROR',
	SUCCESS = 'SUCCESS',
	AUTHORIZATION = 'AUTHORIZATION',
	NO_INTERNET = 'NO_INTERNET',
	CANT_DELETE = 'CANT_DELETE',
}

export interface DatesRange {
	from: Date | string;
	to: Date | string;
}

export interface DatesData extends DatesRange {
	title: string;
	value?: FilterTimeOptions;
}

export enum TimeUnit {
	DAY = 'day',
	MONTH = 'month',
	YEAR = 'year',
}

export enum NodeEnvTypes {
	PRODUCTION = 'production',
	STAGING = 'staging',
	QA = 'qa',
	DEV = 'dev',
}

export interface Sort {
	orderBy: string | string[] | undefined;
	order: SortOrder;
}

export enum SortOrder {
	DESC = 'DESC',
	ASC = 'ASC',
}

export enum AccountPermissionsActionsList {
	DOCUMENT_SETTINGS = 'DOCUMENT_SETTINGS',
	VIEW_REV_REC = 'VIEW_REV_REC',
	AUTO_CHARGE = 'AUTO_CHARGE',
	DUNNING = 'DUNNING',
}

export type AccountPermissions = AccountPermissionsActionsList | IntegrationPlatform;

export interface NumberFormat {
	postfix: string;
	divider: number;
}

export interface SettingStartingNumberResponse {
	category: string;
	editable: boolean;
	settingName: string;
	number: string;
}

export interface GraphState {
	isEmpty: boolean;
	dataFormat: NumberFormat;
	data: StackBarGraphData;
}

export interface DoughnutGraphState {
	isEmpty: boolean;
	dataFormat: NumberFormat;
	data: DoughnutGraphData[];
}

export interface DocumentView {
	id: string;
	accountingEventType?: AccountingEventType;
	contractDocumentNumber: number;
	contractDocumentsCount: number;
	creditsAmount: number;
	invoiceCredits?: any;
	customerEmail: string;
	customerIconData: string;
	customerName: string;
	currency: Currency;
	issueDate: string;
	state: string;
	documentNumber: string;
	draftNumber: string;
	contractName?: string;
	totalInSplitInvoice: number;
	numberInSplitInvoice: number;
	billingDetails?: BillingDetails;
}

export interface ProductAnalyticsList {
	date: Date;
	amountsPerProduct: DocumentProductSummary[];
}

export interface DocumentProductSummary {
	productName: string;
	productId: string;
	totalProductAmount: number;
	pricingModelTabName?: string;
	color?: string;
}

export interface ScheduledRevenueItem {
	date: string;
	amounts: ScheduledRevenueAmountItem;
}

export interface ScheduledRevenueAmountItem {
	bookingAmount: number;
	recognizedRevenue: number;
	deferredRevenue: number;
	billedAmount: number;
	unbilledAmount: number;
}

export interface SyncedContractsData {
	totalContractCount: number;
	data: { contractCount: number; name: string; id: string; iconData: string }[];
}

export interface UpForRenewalData {
	count: number;
	contracts: {
		contractId: string;
		customerName: string;
		contractTotal: string;
		daysLeft: number;
		icon: string;
		contractNumber: number;
		contractPoNumber?: number;
		customerId: string;
	}[];
}

export interface CustomerLocation {
	country: string;
	count: string;
	percentage: string;
}

export type BillingDocumentType = Invoice | CreditNote | Payout;
export type BillingDocumentViewType = InvoiceView | UsageReportView | CreditNoteView | DocumentView | PayoutView;

export interface NavigateToDifferentSingleDocumentPage {
	originalDocumentId?: string;
	onClick?: (documentId: string) => void;
}

export interface ARAgingRecord {
	'1-30 days': number;
	'31-60 days': number;
	'61-90 days': number;
	current: number;
	customerIcon?: string;
	customerName?: string;
	'over 90 days': number;
	total: number;
}
