import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Input, Select } from '../../../../../../components';
import { SelectItem } from '../../../../../../types/generalTypes';
import { timeIndicatorSelect } from './SequenceItem.utils';
import { Step, StepType } from '../../../../../../types/dunningTypes';
import styles from './SequenceItem.module.scss';

interface SequenceItemProps {
	index: number;
	data: Step;
	showError: boolean;
	onDelete: () => void;
	onEdit: (step: Step) => void;
}

const emptySequenceItem = { days: undefined, timeIndicator: StepType.AFTER };

export const SequenceItem = ({ index, data, showError, onDelete, onEdit }: SequenceItemProps) => {
	const [sequenceItem, setSequenceItem] = useState<Step>(emptySequenceItem);
	const { t } = useTranslation('translation');

	useEffect(() => {
		data && setSequenceItem(data);
	}, [data, index]);

	return (
		<div className={styles.container}>
			<div className={styles.leftSide}>
				<div className={styles.iconContainer}>
					<Icon imgType='email' color='primary' width={2} />
				</div>
				<span className={styles.text}>{t('emailSend')}</span>

				<Input
					value={sequenceItem.days}
					className={styles.daysInput}
					inputClassName={styles.daysInput}
					type='number'
					showError={showError}
					onFocus={(event) => event.target.select()}
					onChange={(days) => setSequenceItem({ ...sequenceItem, days: typeof days == 'string' ? +days.replace(/[^0-9]/g, '') : undefined })}
					onBlur={(e) => onEdit({ ...sequenceItem, days: sequenceItem.days })}
					onKeyDown={(e) => e.key === 'Enter' && onEdit({ ...sequenceItem, days: sequenceItem.days })}
				/>
				<span className={styles.text}>{t('days')}</span>

				<Select
					data={timeIndicatorSelect}
					defaultValue={
						timeIndicatorSelect.find((item) => item.value === sequenceItem.timeIndicator)?.label || '' || timeIndicatorSelect[1].value.toString()
					}
					className={styles.selectContainer}
					width={''}
					inputClassName={styles.selectInput}
					selectItemClassName={styles.selectInput}
					onChange={(value, selected: SelectItem) => onEdit({ ...sequenceItem, timeIndicator: selected.value as StepType })}
				/>
				<span className={styles.text}>{t('duedate')}</span>
			</div>
			<Button type='outlined' color='neutral' data-testid='sequence-item-delete-button' onClick={() => onDelete()}>
				<Icon imgType='delete' color='neutral700' width={1.6} />
			</Button>
		</div>
	);
};
