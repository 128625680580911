import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { CustomerSelect, CreateCustomer, Icon, Input, Badge } from '../../../../components';
import { Contract as ContractData } from '../../../../types/contractTypes';
import { getCustomerSelectedById } from './../Contract.utils';
import { Customer } from '../../../../types/customerTypes';
import { contractStateColor } from '../../../../components/_customerTabs/ContractsTab/ContractsTab.utils';
import { contractTitleByType } from '../../../../utils/ContractUtils';
import { DATE_FORMAT } from '../../../../constants/templateConstants';
import styles from './ContractLeftSection.module.scss';

interface ContractLeftSectionProps {
	contractData: ContractData;
	isPartner: boolean;
	isEditMode: boolean;
	customerSelected?: Customer;
	setContractMetadata(field: string, value?: any): void;
	onUpdateCustomer(customer: Customer): void;
}

export const ContractLeftSection = ({
	contractData,
	isPartner,
	isEditMode,
	customerSelected,
	onUpdateCustomer,
	setContractMetadata,
}: ContractLeftSectionProps) => {
	const [showCreateCustomer, setShowCreateCustomer] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	const onCreateNewCustomer = async (id: string) => {
		const customer = await getCustomerSelectedById(id, dispatch);
		onUpdateCustomer(customer);
	};

	return (
		<div className={styles.container}>
			<Badge className={styles.statusTag} color={contractStateColor(contractData).color} type='secondary' dataTestId='contract-badge'>
				{contractStateColor(contractData).text}
			</Badge>

			<div className={styles.fontSize24}>{t(contractTitleByType(contractData.contractType))}</div>
			<div className={clsx(styles.subTitle, styles.fontSize14)}>
				{dayjs(contractData?.activationStartDate).format(DATE_FORMAT)}
				{contractData?.activationEndDate ? ` - ${dayjs(contractData?.activationEndDate).format(DATE_FORMAT)}` : ''}
			</div>

			<div className={styles.subTitle}>{t(isPartner ? 'partner' : 'customer')}</div>
			<CustomerSelect
				small
				hideDetails
				isPartner={isPartner}
				defaultCustomer={customerSelected}
				onAddCustomer={() => setShowCreateCustomer(true)}
				onCustomerSelected={onUpdateCustomer}
				hideOptionButton
				selectedCustomerStyle={styles.selectedCustomerStyle}
			/>

			<div className={styles.subTitle}>{t('PurchaseOrder')}</div>
			<Input
				disabled={!isEditMode}
				onFocus={(e) => e.target.select()}
				placeholder='PO-0000000'
				value={contractData?.poNumber || ''}
				onChange={(val, e) => setContractMetadata('poNumber', e.target.value)}
				onKeyDown={(event: any) => {
					if (event.key === 'Enter') {
						event.currentTarget.blur();
						setContractMetadata('poNumber', event?.target?.value);
					}
				}}
				onBlur={(event: any) => {
					setContractMetadata('poNumber', event?.target?.value);
				}}
				className={clsx(styles.inputs, !isEditMode && styles.notAllowedCursor)}
				type='text'
				rightChildren={<Icon className={styles.poIcon} imgType='edit' width={1.2} color='neutral400' />}
			/>

			<div className={styles.subTitle}>{t('customName')}</div>
			<Input
				disabled={!isEditMode}
				onFocus={(e) => e.target.select()}
				placeholder={t(isPartner ? 'agreementName' : 'contractName')}
				value={contractData?.name || ''}
				onChange={(val, e) => setContractMetadata('name', e.target.value)}
				onKeyDown={(event: any) => {
					if (event.key === 'Enter') {
						event.currentTarget.blur();
						setContractMetadata('name', event?.target?.value);
					}
				}}
				onBlur={(event: any) => {
					setContractMetadata('name', event?.target?.value);
				}}
				className={clsx(styles.inputs, !isEditMode && styles.notAllowedCursor)}
				type='text'
				rightChildren={<Icon className={styles.contractNameIcon} imgType='edit' width={1.2} color='neutral400' />}
			/>

			<CreateCustomer
				isPartner={isPartner}
				isOpen={showCreateCustomer}
				closeModal={() => setShowCreateCustomer(false)}
				onCustomerAdded={(customerName, id) => onCreateNewCustomer(id)}
			/>
		</div>
	);
};
