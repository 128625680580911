import { useSelector } from 'react-redux';
import { UserPermissions } from '@received/pricing-model';
import { protectedRoutes } from '../../routes/protected';
import { Icon } from '../_uiComponents';
import { CurrentUser } from '../CurrentUser/CurrentUser';
import { NavigationLink } from './NavigationLink/NavigationLink';
import { Store } from '../../types/storeTypes';
import styles from './NavigationSideBar.module.scss';

interface NavigationSideBarProps {
	isOpen: boolean;
}

export const NavigationSideBar = ({ isOpen = true }: NavigationSideBarProps) => {
	const userPermissions = useSelector((store: Store) => store.user.permissions);

	return (
		<div className={isOpen ? styles.sidebar : styles.sidebarHidden} data-testid='navigation-side-bar'>
			<div className={styles.header}>
				<Icon className={styles.logo} imgType='logo' height={2.5} width={12} color='black' />
				<Icon className={styles.logo} imgType='letter_logo' height={2.5} color='black' />
			</div>

			<nav className={styles.sidebarNav}>
				{protectedRoutes(userPermissions)
					.filter((item) => item.showOnSidebar)
					.map((item, index) => (
						<NavigationLink key={index} item={item} />
					))}
			</nav>

			<CurrentUser />
		</div>
	);
};
