import { IntegrationDataSyncStatus, IntegrationPlatform, SyncObjectType } from '@received/pricing-model';
import { Step } from '../../../components/_uiComponents/VerticalStepper/Step/Step';
import { images } from '../../../constants/images';
import { ExportItems } from '../IntegrationComponents';
import { convertIntegrationTypeToStringService } from '../../../utils/IntegrationUtils';
import { AccountingItemsExport, convertTypeToIcon } from '../IntegrationLayout.utils';
import { IntegrationExportData, IntegrationMatchedObjects } from '../../../types/integrationTypes';
import styles from '../IntegrationSideBar/IntegrationSideBar.module.scss';

export interface exportItemsStepperProps {
	integrationType: IntegrationPlatform;
	t: any;
	syncingSteps: { [key: string | number]: IntegrationDataSyncStatus };
	exportItems?: IntegrationExportData;
	matchingAccounts?: IntegrationExportData;
	matchingCustomers?: IntegrationExportData;
	onUpdateExportItems?(matchedObjects: IntegrationMatchedObjects[], syncStatusId: string): void;
	onUpdateExportCustomers?(matchedObjects: IntegrationMatchedObjects[], syncStatusId: string): void;
	onUpdateMatchingAccounts?(matchedObjects: IntegrationMatchedObjects[], syncStatusId: string): void;
	onResync?(accountingItemsExportStep: AccountingItemsExport, syncObjectType: SyncObjectType): void;
	onDeleteRow?(index: number, step: AccountingItemsExport): void;
}

export const exportItemsStepper = ({
	integrationType,
	t,
	syncingSteps,
	exportItems,
	matchingAccounts,
	matchingCustomers,
	onUpdateExportItems,
	onUpdateMatchingAccounts,
	onResync,
	onDeleteRow,
	onUpdateExportCustomers,
}: exportItemsStepperProps): Step[] => [
	{
		title: t('accounts'),
		subTitle: syncingSteps?.[AccountingItemsExport.Accounts] == IntegrationDataSyncStatus.SYNCING ? ` ${t('syncing')}...` : '',
		subTitleClassName: styles.success,
		state: 'IN_PROGRESS',
		component: () => (
			<ExportItems
				isSyncing={syncingSteps?.[AccountingItemsExport.Accounts] == IntegrationDataSyncStatus.SYNCING}
				exportItems={matchingAccounts}
				onUpdateExportItems={onUpdateMatchingAccounts}
				onRefresh={() => onResync?.(AccountingItemsExport.Accounts, SyncObjectType.BILLING_ACCOUNT)}
				title={`${t('matchingAccountsWith')} ${convertIntegrationTypeToStringService(integrationType)}`}
				subTitle={`${t('matchAndSyncFieldsFromReceived')} ${convertIntegrationTypeToStringService(integrationType)}`}
				itemMatcherHeader={{
					rightLisHeader: { title: t('fromReceived'), subTitle: t('receivedAccount'), icon: images.received },
					leftListHeader: {
						title: `${t('to')} ${convertIntegrationTypeToStringService(integrationType)}`,
						subTitle: `${convertIntegrationTypeToStringService(integrationType)} ${t('settingsAccount')}`,
						icon: convertTypeToIcon(integrationType),
					},
				}}
				onDeleteRow={(index) => onDeleteRow?.(index, AccountingItemsExport.Accounts)}
			/>
		),
	},
	{
		title: t('Items'),
		subTitle: syncingSteps?.[AccountingItemsExport.Items] == IntegrationDataSyncStatus.SYNCING ? ` ${t('syncing')}...` : '',
		subTitleClassName: styles.success,
		state: 'UNSET',
		component: () => (
			<ExportItems
				exportItems={exportItems}
				isSyncing={syncingSteps?.[AccountingItemsExport.Items] == IntegrationDataSyncStatus.SYNCING}
				onUpdateExportItems={onUpdateExportItems}
				onRefresh={() => onResync?.(AccountingItemsExport.Items, SyncObjectType.ITEM)}
				title={t('exportItems')}
				subTitle={`${t('MatchFieldsTo')} ${convertIntegrationTypeToStringService(integrationType)}`}
				itemMatcherHeader={{
					rightLisHeader: { title: t('fromReceived'), subTitle: t('receivedItems'), icon: images.received },
					leftListHeader: {
						title: `${t('to')} ${convertIntegrationTypeToStringService(integrationType)}`,
						subTitle: `${convertIntegrationTypeToStringService(integrationType)} ${t('Items')}`,
						icon: convertTypeToIcon(integrationType),
					},
				}}
				onDeleteRow={(index) => onDeleteRow?.(index, AccountingItemsExport.Items)}
			/>
		),
	},
	{
		title: t('customers'),
		subTitle: syncingSteps?.[AccountingItemsExport.Customers] == IntegrationDataSyncStatus.SYNCING ? ` ${t('syncing')}...` : '',
		state: 'UNSET',
		subTitleClassName: styles.success,
		component: () => (
			<ExportItems
				isSyncing={syncingSteps?.[AccountingItemsExport.Customers] == IntegrationDataSyncStatus.SYNCING}
				exportItems={matchingCustomers}
				onUpdateExportItems={onUpdateExportCustomers}
				onRefresh={() => onResync?.(AccountingItemsExport.Customers, SyncObjectType.CUSTOMER)}
				title={t('exportCustomers')}
				subTitle={`${t('MatchFieldsTo')} ${convertIntegrationTypeToStringService(integrationType)}`}
				itemMatcherHeader={{
					rightLisHeader: { title: t('fromReceived'), subTitle: t('receivedCustomers'), icon: images.received },
					leftListHeader: {
						title: `${t('to')} ${convertIntegrationTypeToStringService(integrationType)}`,
						subTitle: `${convertIntegrationTypeToStringService(integrationType)} ${t('customers')}`,
						icon: convertTypeToIcon(integrationType),
					},
				}}
				onDeleteRow={(index) => onDeleteRow?.(index, AccountingItemsExport.Customers)}
			/>
		),
	},

	// TODO enable when page is ready
	// {
	// 	title: 'Invoices',
	// 	subTitle: '',
	// 	state: 'UNSET',
	// 	component: (prop?: any) => <div>Invoices</div>,
	// },
];
