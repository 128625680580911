import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { PricingModel } from '../../../../../../types/contractTypes';
import { Button, Icon, MenuList } from '../../../../../../components';
import { pricingTableHeader } from './../PricingTable.utils';
import { pricingModelMenuOptions } from '../../../../../../utils/pricingModelUtils';
import styles from './../PricingTable.module.scss';

interface PricingTableRowProps {
	pricingModel: PricingModel;
	selectedModel?: PricingModel;
	onRowClick(): void;
	onRefreshTable?(): void;
}

export const PricingTableRow = ({ pricingModel, selectedModel, onRowClick, onRefreshTable }: PricingTableRowProps) => {
	const { t } = useTranslation('translation');
	const [openMenu, setOpenMenu] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	return (
		<div
			data-testid={`table-item-${pricingModel.name}`}
			className={clsx(styles.row, selectedModel?.id === pricingModel.id && styles.selectedRow, selectedModel && styles.itemsSizesClose)}
			onClick={(e) => {
				e.stopPropagation();
				onRowClick();
			}}
		>
			{pricingTableHeader.map((header) => (
				<div
					key={header.headerTitle}
					className={clsx(styles.rowItems, selectedModel && styles.rowItemsSmallView)}
					style={{ opacity: pricingModel.isArchived ? 0.6 : 1 }}
				>
					{header?.CustomComponent(pricingModel)}
				</div>
			))}

			<MenuList
				optionsList={pricingModelMenuOptions(t, dispatch, navigate, pricingModel, onRefreshTable)}
				isMenuOpen={openMenu}
				openMenu={() => setOpenMenu(false)}
			>
				<Button dataTestId={`table-hamburger-${pricingModel.name}`} type='link' color='neutral' onClick={() => setOpenMenu(true)}>
					<Icon imgType='hamburger' color='neutral' />
				</Button>
			</MenuList>
		</div>
	);
};
