import { Dispatch } from '@reduxjs/toolkit';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';

export const cloneAgreement = async (agreementId: string, dispatch: Dispatch, onRefresh: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.cloneAgreement,
			urlParam: { id: agreementId },
		});
		onRefresh();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CLONE_AGREEMENT }));
	}
};

export const deleteAgreement = async (agreementId: string, dispatch: Dispatch, onRefresh: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteAgreement,
			urlParam: { id: agreementId },
		});
		onRefresh();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DELETE_AGREEMENT }));
	}
};

export const cancelAgreement = async (agreementId: string, dispatch: Dispatch, onRefresh: () => void) => {
	try {
		const res = (
			await httpService({
				dispatch,
				path: PathsConfig.cancelAgreement,
				urlParam: { id: agreementId },
			})
		).data;
		onRefresh();
		return res;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_CANCEL_AGREEMENT }));
	}
};
