import { convertCurrencyToSign, ContractState } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Cell, ContractCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { ContractView } from '../../../types/contractTypes';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { partnersAgreementColor } from '../../../utils/PartnersUtils';
import styles from './ResellerAgreementsTab.module.scss';

export const resellerAgreementsTabTableHeader = [
	{
		headerTitle: 'agreementNo',
		accessor: 'contract',
		CustomComponent: (item: ContractView) => (
			<ContractCell contractNumber={contractIdFormat(item?.poNumber, item?.contractNumber)} subAccessor={item?.name} />
		),
	},
	{
		headerTitle: 'products',
		accessor: 'productName',
		CustomComponent: (item: ContractView) => {
			const { t } = useTranslation('translation');
			const accessor = item?.productName || '';
			const subAccessor = +item?.productCount > 1 ? `+${item?.productCount - 1} ${t(item?.productCount > 2 ? 'Products' : 'product')}` : '';
			return <Cell accessor={accessor} subAccessor={subAccessor} />;
		},
	},
	{
		headerTitle: 'paid',
		accessor: 'totalPayments',
		CustomComponent: (item: ContractView) => {
			return <Cell accessor={item.totalPayments ? `${getValueWithCurrencySign(item.totalPayments, convertCurrencyToSign(item.currency))}` : '-'} />;
		},
	},
	{
		headerTitle: 'nextInvoice',
		accessor: 'nextInvoiceAmount',
		CustomComponent: (item: ContractView) => (
			<Cell
				accessor={item.nextInvoiceAmount ? `${getValueWithCurrencySign(item.nextInvoiceAmount, convertCurrencyToSign(item.currency))}` : '-'}
				subAccessor={item.nextInvoiceDate ? dayjs(item.nextInvoiceDate).format(DATE_FORMAT) : ''}
			/>
		),
	},
	{
		headerTitle: 'lastEdit',
		accessor: 'lastEdit',
		CustomComponent: (item: ContractView) => <Cell accessor={dayjs(item?.lastEdit).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		CustomComponent: (item: ContractView) => {
			const badgeColor = partnersAgreementColor(item);
			return (
				<StatusCol color={badgeColor.color} className={clsx(item?.state == ContractState.END_BILLING && styles.contractEndBillingBadge)}>
					{badgeColor.text}
				</StatusCol>
			);
		},
	},
];
