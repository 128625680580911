import dayjs from 'dayjs';
import { convertCurrencyToSign, DocumentState } from '@received/pricing-model';
import { Cell, LogoCell, PriceCell } from '../../../components/_uiComponents/Table/Table.utils';
import { colors } from '../../../constants/colors';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { DocumentView, GraphState, TimeUnit } from '../../../types/generalTypes';
import { InvoiceCashflow, InvoiceView } from '../../../types/invoiceTypes';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { getCreditUsed, getTotalDue } from '../../../utils/InvoiceUtils';
import { absoluteValueArray, getNumberingFormat } from '../../../utils/NumberUtils';
import { formatDraftIssueDays, formatSentIssueDays } from './AllInvoices/AllInvoices.utils';
import { getDocumentIdNameByType } from '../../../utils/GeneralUtils';
import { CreditNoteView } from '../../../types/creditNoteTypes';
import { monthLabel } from '../../../utils/GraphsUtils';
import { FilterTimeOptions } from '../../../components/Filters/DateRangeFilters.utils';
import { formatEmailList } from '../../../utils/UserUtils';
import { getStatusTagByDocumentType } from '../../../utils/DocumentsUtils';
import styles from './Invoices.module.scss';

export const lateInvoiceTableHeader = [
	{
		headerTitle: 'relatedCustomer',
		accessor: 'customerName',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<LogoCell
				title={item.customerName}
				cellSyncStatus={getSyncStatus(item)}
				subTitle={formatEmailList(item.customerEmail)}
				logoData={item.customerIconData}
				overrideStyles={{ subAccessorRow: styles.logoCellSubAccessor, accessorRow: styles.logoCellAccessor }}
			/>
		),
	},
	{
		headerTitle: 'totalDue',
		accessor: 'total',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<PriceCell
				currency={convertCurrencyToSign(item.currency)}
				mainNumber={getTotalDue(item)}
				subNumber={getCreditUsed(item)}
				subAccessor={formatSentIssueDays(item)}
			/>
		),
	},
];

export const invoiceUpcomingTableHeader = (refreshData: () => void) => [
	{
		headerTitle: 'relatedCustomer',
		accessor: 'customerName',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<LogoCell
				cellSyncStatus={getSyncStatus(item)}
				title={item.customerName}
				subTitle={formatEmailList(item.customerEmail)}
				logoData={item.customerIconData}
				overrideStyles={{ subAccessorRow: styles.logoCellSubAccessor, accessorRow: styles.logoCellAccessor }}
			/>
		),
	},
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.issueDate).format(DATE_FORMAT)} subAccessor={formatDraftIssueDays(item)} />,
	},
	{
		headerTitle: 'documentNo',
		accessor: 'documentNumber',
		isSortable: true,
		CustomComponent: (item: DocumentView) => {
			return <Cell accessor={getDocumentIdNameByType(item)} subAccessor={getDocumentNumberInSeries(item as DocumentView)} />;
		},
	},
	{
		headerTitle: 'totalDue',
		accessor: 'total',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => {
			return <PriceCell currency={convertCurrencyToSign(item.currency)} mainNumber={getTotalDue(item)} subNumber={getCreditUsed(item)} />;
		},
	},

	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		disableRowClick: true,
		CustomComponent: (document: InvoiceView | CreditNoteView) => getStatusTagByDocumentType({ document, refreshData }),
	},
];

export const statusOverTimeDataHandler = (invoiceData: InvoiceCashflow[], t: any, timeUnit: TimeUnit, filter?: FilterTimeOptions) => {
	let isEmpty = true;

	const destructiveLine: Array<number | null> = [];
	const successLine: Array<number | null> = [];
	const warningLine: Array<number | null> = [];
	const primaryLine: Array<number | null> = [];
	const allSums: number[] = [];

	const labels = invoiceData.map(({ date, statesCashflow }, index) => {
		statesCashflow.forEach((item) => {
			if (item.totalSum > 0 && item.state != DocumentState.DRAFT) {
				isEmpty = false;
				allSums.push(item.totalSum);
			}

			switch (item.state) {
				case DocumentState.SENT:
					return primaryLine.push(item.totalSum);
				case DocumentState.LATE:
					return destructiveLine.push(item.totalSum);
				case DocumentState.PAID:
					return successLine.push(item.totalSum);
				case DocumentState.COLLECTING_INFO:
				case DocumentState.REVIEW_MISSING_DATA:
				case DocumentState.REVIEW_READY_TO_SEND: {
					const reviewSum = warningLine[index] || 0;
					return (warningLine[index] = reviewSum + item.totalSum);
				}
			}
		});

		if (primaryLine.length != index + 1) {
			primaryLine.push(null);
		}
		if (destructiveLine.length != index + 1) {
			destructiveLine.push(null);
		}
		if (successLine.length != index + 1) {
			successLine.push(null);
		}
		if (warningLine.length != index + 1) {
			warningLine.push(null);
		}

		return monthLabel(date, timeUnit, filter);
	});

	const dataForGraph = {
		labels,
		datasets: [
			{ data: destructiveLine, backgroundColor: colors.destructive, borderColor: colors.destructive, label: t('late') },
			{ data: successLine, backgroundColor: colors.success, borderColor: colors.success, label: t('paid') },
			{ data: warningLine, backgroundColor: colors.warning, borderColor: colors.warning, label: t('review') },
			{ data: primaryLine, backgroundColor: colors.primary, borderColor: colors.primary, label: t('sent') },
		],
	};

	return { dataForGraph, isEmpty, dataFormat: getNumberingFormat(Math.max(...absoluteValueArray(allSums))) };
};

export const getInvoiceNumberInSeries = (item: InvoiceView) => {
	const documentNumber = item?.contractInvoiceNumber ? `${item.contractInvoiceNumber}/${item.contractInvoicesCount}` : '';
	const splittedNumber = item?.totalInSplitInvoice ? ` | split ${item.numberInSplitInvoice}/${item.totalInSplitInvoice}` : '';
	return `${documentNumber}${splittedNumber}`;
};

export const getDocumentNumberInSeries = (item: DocumentView) => {
	const documentNumber = item?.contractDocumentNumber ? `${item.contractDocumentNumber}/${item.contractDocumentsCount}` : '';
	const splittedNumber = item?.totalInSplitInvoice ? ` | split ${item.numberInSplitInvoice}/${item.totalInSplitInvoice}` : '';
	return `${documentNumber}${splittedNumber}`;
};

export const initialGraphState: GraphState = {
	isEmpty: false,
	data: {
		labels: [],
		datasets: [],
	},
	dataFormat: {
		postfix: '',
		divider: 1,
	},
};
