import dayjs from 'dayjs';
import {
	CreditNoteReasonCode,
	creditNoteReasonCodesLabels,
	AccountingEventType,
	EventsGenerationMethod,
	RevenueRecognitionPeriod,
	PricingCycleUnit,
	BillingCycleUnit,
} from '@received/pricing-model';
import { invoicesFilterOptions } from '../types/invoiceTypes';
import { NodeEnvTypes, PaymentTimeOptions } from '../types/generalTypes';

export const billingCycleLabels = {
	ONE_TIME: 'One Time',
	WEEKLY: 'Weekly',
	MONTHLY: 'Monthly',
	QUARTERLY: 'Quarterly',
	YEARLY: 'Yearly',
};

export const billingCycleLabelsByEnum = {
	[BillingCycleUnit.ONE_TIME]: 'One Time',
	[BillingCycleUnit.WEEKLY]: 'Weekly',
	[BillingCycleUnit.MONTHLY]: 'Monthly',
	[BillingCycleUnit.QUARTERLY]: 'Quarterly',
	[BillingCycleUnit.YEARLY]: 'Yearly',
	[BillingCycleUnit.CUSTOM]: '',
};

export const PricingCycleLabels = {
	PER_UNIT: 'Per Unit',
	ONE_TIME: 'One Time',
	WEEKLY: 'Weekly',
	MONTHLY: 'Monthly',
	QUARTERLY: 'Quarterly',
	YEARLY: 'Yearly',
	CUSTOM: 'Custom',
};

export const billingCycleSelect = (disableOneTime?: boolean, isRevRec?: boolean) => {
	let list = disableOneTime
		? []
		: [
				{
					label: billingCycleLabels.ONE_TIME,
					value: BillingCycleUnit.ONE_TIME,
				},
		  ];
	list = [
		...list,
		// TODO Return when API is ready
		// {
		// 	label: billingCycleLabels.WEEKLY,
		// 	value: BillingCycleUnit.WEEKLY,
		// },
		{
			label: billingCycleLabels.MONTHLY,
			value: BillingCycleUnit.MONTHLY,
		},
		{
			label: billingCycleLabels.QUARTERLY,
			value: BillingCycleUnit.QUARTERLY,
		},
		{
			label: billingCycleLabels.YEARLY,
			value: BillingCycleUnit.YEARLY,
		},
	];

	if (!isRevRec) {
		list = [
			...list,
			{
				label: PricingCycleLabels.CUSTOM,
				value: BillingCycleUnit.CUSTOM,
			},
		];
	}
	return list;
};

export const pricingCycleSelect = () => {
	const list = [
		{
			label: PricingCycleLabels.PER_UNIT,
			value: PricingCycleUnit.PER_UNIT,
		},
		{
			label: PricingCycleLabels.ONE_TIME,
			value: PricingCycleUnit.ONE_TIME,
		},
		// TODO Return when API is ready
		// {
		// 	label: PricingCycleLabels.WEEKLY,
		// 	value: PricingCycleUnit.WEEKLY,
		// },
		{
			label: PricingCycleLabels.MONTHLY,
			value: PricingCycleUnit.MONTHLY,
		},
		{
			label: PricingCycleLabels.QUARTERLY,
			value: PricingCycleUnit.QUARTERLY,
		},
		{
			label: PricingCycleLabels.YEARLY,
			value: PricingCycleUnit.YEARLY,
		},
		{
			label: PricingCycleLabels.CUSTOM,
			value: PricingCycleUnit.CUSTOM,
		},
	];
	return list;
};

export const recognitionMethodLabels = {
	[EventsGenerationMethod.STRAIGHT_LINE]: 'Straight-Line (Over-Time)',
	[EventsGenerationMethod.FIXED_STRAIGHT_LINE]: 'Fixed Straight-Line',
	[EventsGenerationMethod.IMMEDIATE]: 'Invoice-Based',
};

export const recognitionMethodShortLabels = {
	[EventsGenerationMethod.STRAIGHT_LINE]: 'Straight-Line',
	[EventsGenerationMethod.FIXED_STRAIGHT_LINE]: 'Fixed Straight-Line',
	[EventsGenerationMethod.IMMEDIATE]: 'Invoice-Based',
};

export const recognitionMethodSelect = [
	{
		label: recognitionMethodLabels[EventsGenerationMethod.STRAIGHT_LINE],
		value: EventsGenerationMethod.STRAIGHT_LINE,
	},
	{
		label: recognitionMethodLabels[EventsGenerationMethod.FIXED_STRAIGHT_LINE],
		value: EventsGenerationMethod.FIXED_STRAIGHT_LINE,
	},
	{
		label: recognitionMethodLabels[EventsGenerationMethod.IMMEDIATE],
		value: EventsGenerationMethod.IMMEDIATE,
	},
];

export const recognitionPeriodLabels = {
	CONTRACT_ACTIVATION: 'Contract Activation',
	CUSTOM: 'Custom Dates',
};

export const recognitionPeriodSelect = [
	{
		label: recognitionPeriodLabels.CONTRACT_ACTIVATION,
		value: RevenueRecognitionPeriod.CONTRACT_ACTIVATION,
	},
	{
		label: recognitionPeriodLabels.CUSTOM,
		value: RevenueRecognitionPeriod.CUSTOM,
	},
];

export const AccountingEventTypeTypeLabels = {
	INVOICE: 'Invoice',
	CREDIT: 'Credit Note',
	DEBIT: 'Debit Note',
	PAYOUT: 'Payout',
};

export const accountingEventTypeSelect = () => {
	const list = [
		{
			label: AccountingEventTypeTypeLabels.INVOICE,
			value: AccountingEventType.INVOICE,
		},
		{
			label: AccountingEventTypeTypeLabels.CREDIT,
			value: AccountingEventType.CREDIT,
		},
		{
			label: AccountingEventTypeTypeLabels.DEBIT,
			value: AccountingEventType.DEBIT,
		},
	];
	if (window._env_.REACT_APP_NODE_ENV !== NodeEnvTypes.PRODUCTION) {
		list.push({
			label: AccountingEventTypeTypeLabels.PAYOUT,
			value: AccountingEventType.PAYOUT,
		});
	}
	return list;
};

export const paymentPeriodLabels = {
	BEFORE_USE: 'Before Period',
	DURING_USE: 'During Period',
	AFTER_USE: 'After Period',
};

export const paymentTimeSelect = [
	{
		label: paymentPeriodLabels.BEFORE_USE,
		value: PaymentTimeOptions.BEFORE_USE,
	},
	{
		label: paymentPeriodLabels.DURING_USE,
		value: PaymentTimeOptions.DURING_USE,
	},
	{
		label: paymentPeriodLabels.AFTER_USE,
		value: PaymentTimeOptions.AFTER_USE,
	},
];

export const invoicesFilterLabels = {
	CONTRACT: 'Contract',
	SPLITTED_INVOICES: 'Duplicated invoices',
};

export const invoiceFilterSelect = [
	{
		label: invoicesFilterLabels.CONTRACT,
		value: invoicesFilterOptions.CONTRACT,
	},
	{
		label: invoicesFilterLabels.SPLITTED_INVOICES,
		value: invoicesFilterOptions.SPLITTED_INVOICES,
	},
];

export const creditNoteReasonCodesLabelsSelect = [
	{
		label: creditNoteReasonCodesLabels.CREDIT_PURCHASE,
		value: CreditNoteReasonCode.CREDIT_PURCHASE,
	},
	{
		label: creditNoteReasonCodesLabels.ORDER_CANCELLATION,
		value: CreditNoteReasonCode.ORDER_CANCELLATION,
	},
	{
		label: creditNoteReasonCodesLabels.ORDER_CHANGE,
		value: CreditNoteReasonCode.ORDER_CHANGE,
	},
	{
		label: creditNoteReasonCodesLabels.OTHER,
		value: CreditNoteReasonCode.OTHER,
	},
	{
		label: creditNoteReasonCodesLabels.PRODUCT_UNSATISFACTORY,
		value: CreditNoteReasonCode.PRODUCT_UNSATISFACTORY,
	},
	{
		label: creditNoteReasonCodesLabels.SERVICE_UNSATISFACTORY,
		value: CreditNoteReasonCode.SERVICE_UNSATISFACTORY,
	},
	{
		label: creditNoteReasonCodesLabels.USAGE_CREDIT,
		value: CreditNoteReasonCode.USAGE_CREDIT,
	},
	{
		label: creditNoteReasonCodesLabels.WAIVER,
		value: CreditNoteReasonCode.WAIVER,
	},
];

export const DEFAULT_BILLING_CYCLE_VALUE = 1;
export const DEFAULT_COUNTRY = 'United States';
export const DEFAULT_COUNTRY_PREFIX = 'US';
export const DEFAULT_COUNTRY_FULL_NAME = `${DEFAULT_COUNTRY_PREFIX} - ${DEFAULT_COUNTRY}`;
export const DEFAULT_STATE = 'New York';
export const DEFAULT_STATE_PREFIX = 'NY';
export const DEFAULT_PHONE_PREFIX = '1';
export const DEFAULT_COUNTRIES_LIST = [
	{ value: DEFAULT_COUNTRY, label: DEFAULT_COUNTRY, prefix: DEFAULT_COUNTRY_PREFIX, phonePrefix: DEFAULT_PHONE_PREFIX },
];
export const DEFAULT_STATES_LIST = [
	{ label: 'Alabama', value: 'Alabama', prefix: 'AL' },
	{ label: 'Alaska', value: 'Alaska', prefix: 'AK' },
	{ label: 'American Samoa', value: 'American Samoa', prefix: 'AS' },
	{ label: 'Arizona', value: 'Arizona', prefix: 'AZ' },
	{ label: 'Arkansas', value: 'Arkansas', prefix: 'AR' },
	{ label: 'California', value: 'California', prefix: 'CA' },
	{ label: 'Colorado', value: 'Colorado', prefix: 'CO' },
	{ label: 'Connecticut', value: 'Connecticut', prefix: 'CT' },
	{ label: 'Delaware', value: 'Delaware', prefix: 'DE' },
	{ label: 'District Of Columbia', value: 'District Of Columbia', prefix: 'DC' },
	{ label: 'Federated States Of Micronesia', value: 'Federated States Of Micronesia', prefix: 'FM' },
	{ label: 'Florida', value: 'Florida', prefix: 'FL' },
	{ label: 'Georgia', value: 'Georgia', prefix: 'GA' },
	{ label: 'Guam', value: 'Guam', prefix: 'GU' },
	{ label: 'Hawaii', value: 'Hawaii', prefix: 'HI' },
	{ label: 'Idaho', value: 'Idaho', prefix: 'ID' },
	{ label: 'Illinois', value: 'Illinois', prefix: 'IL' },
	{ label: 'Indiana', value: 'Indiana', prefix: 'IN' },
	{ label: 'Iowa', value: 'Iowa', prefix: 'IA' },
	{ label: 'Kansas', value: 'Kansas', prefix: 'KS' },
	{ label: 'Kentucky', value: 'Kentucky', prefix: 'KY' },
	{ label: 'Louisiana', value: 'Louisiana', prefix: 'LA' },
	{ label: 'Maine', value: 'Maine', prefix: 'ME' },
	{ label: 'Marshall Islands', value: 'Marshall Islands', prefix: 'MH' },
	{ label: 'Maryland', value: 'Maryland', prefix: 'MD' },
	{ label: 'Massachusetts', value: 'Massachusetts', prefix: 'MA' },
	{ label: 'Michigan', value: 'Michigan', prefix: 'MI' },
	{ label: 'Minnesota', value: 'Minnesota', prefix: 'MN' },
	{ label: 'Mississippi', value: 'Mississippi', prefix: 'MS' },
	{ label: 'Missouri', value: 'Missouri', prefix: 'MO' },
	{ label: 'Montana', value: 'Montana', prefix: 'MT' },
	{ label: 'Nebraska', value: 'Nebraska', prefix: 'NE' },
	{ label: 'Nevada', value: 'Nevada', prefix: 'NV' },
	{ label: 'New Hampshire', value: 'New Hampshire', prefix: 'NH' },
	{ label: 'New Jersey', value: 'New Jersey', prefix: 'NJ' },
	{ label: 'New Mexico', value: 'New Mexico', prefix: 'NM' },
	{ label: DEFAULT_STATE, value: DEFAULT_STATE, prefix: DEFAULT_STATE_PREFIX },
	{ label: 'North Carolina', value: 'North Carolina', prefix: 'NC' },
	{ label: 'North Dakota', value: 'North Dakota', prefix: 'ND' },
	{ label: 'Northern Mariana Islands', value: 'Northern Mariana Islands', prefix: 'MP' },
	{ label: 'Ohio', value: 'Ohio', prefix: 'OH' },
	{ label: 'Oklahoma', value: 'Oklahoma', prefix: 'OK' },
	{ label: 'Oregon', value: 'Oregon', prefix: 'OR' },
	{ label: 'Palau', value: 'Palau', prefix: 'PW' },
	{ label: 'Pennsylvania', value: 'Pennsylvania', prefix: 'PA' },
	{ label: 'Puerto Rico', value: 'Puerto Rico', prefix: 'PR' },
	{ label: 'Rhode Island', value: 'Rhode Island', prefix: 'RI' },
	{ label: 'South Carolina', value: 'South Carolina', prefix: 'SC' },
	{ label: 'South Dakota', value: 'South Dakota', prefix: 'SD' },
	{ label: 'Tennessee', value: 'Tennessee', prefix: 'TN' },
	{ label: 'Texas', value: 'Texas', prefix: 'TX' },
	{ label: 'Utah', value: 'Utah', prefix: 'UT' },
	{ label: 'Vermont', value: 'Vermont', prefix: 'VT' },
	{ label: 'Virgin Islands', value: 'Virgin Islands', prefix: 'VI' },
	{ label: 'Virginia', value: 'Virginia', prefix: 'VA' },
	{ label: 'Washington', value: 'Washington', prefix: 'WA' },
	{ label: 'West Virginia', value: 'West Virginia', prefix: 'WV' },
	{ label: 'Wisconsin', value: 'Wisconsin', prefix: 'WI' },
	{ label: 'Wyoming', value: 'Wyoming', prefix: 'WY' },
];

export const FORMULA = 'Σ';

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const LISTENER_ADDRESS = `${window._env_?.REACT_APP_API_ENDPOINT}${'/sse/**'}`;
export const REVENUE_RECOGNITION_LINK = 'https://www.received.ai/solutions/revenue-recognition';

export const filterYearsList = [
	{
		label: dayjs().subtract(3, 'year').format('YYYY').toString(),
		value: dayjs().subtract(3, 'year').format('YYYY').toString(),
	},
	{
		label: dayjs().subtract(2, 'year').format('YYYY').toString(),
		value: dayjs().subtract(2, 'year').format('YYYY').toString(),
	},
	{
		label: dayjs().subtract(1, 'year').format('YYYY').toString(),
		value: dayjs().subtract(1, 'year').format('YYYY').toString(),
	},
	{
		label: dayjs().year().toString(),
		value: dayjs().year().toString(),
	},
	{
		label: dayjs().add(1, 'year').format('YYYY').toString(),
		value: dayjs().add(1, 'year').format('YYYY').toString(),
	},
	{
		label: dayjs().add(2, 'year').format('YYYY').toString(),
		value: dayjs().add(2, 'year').format('YYYY').toString(),
	},
	{
		label: dayjs().add(3, 'year').format('YYYY').toString(),
		value: dayjs().add(3, 'year').format('YYYY').toString(),
	},
];

export const defaultNumberOfItems = '20';
