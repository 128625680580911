import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ContractType } from '@received/pricing-model';
import { Button, Icon, Modal } from '../../../../components';
import { images } from '../../../../constants/images';
import { Customer } from '../../../../types/customerTypes';
import styles from './GreatJobModal.module.scss';

interface GreatJobModalProps {
	showModal: boolean;
	customer?: Customer;
	contractId?: string;
	contractType: ContractType;
	closeModal(): void;
}

export const GreatJobModal = ({ showModal, contractType, closeModal, customer, contractId }: GreatJobModalProps) => {
	const { t } = useTranslation('translation');

	const isPartner = [ContractType.REFERRAL, ContractType.RESELLER].includes(contractType);
	const singleCustomerUrl = isPartner
		? `../partners/${contractType === ContractType.RESELLER ? 'single-reseller' : 'single-referral'}/`
		: '../customers/single-customer/';

	const navigate = useNavigate();
	return (
		<Modal isOpen={showModal} closeModal={closeModal}>
			<div className={styles.mainContainer}>
				<div className={styles.header}>
					<Icon imgType='logo' className={styles.logoImg} height={2.5} width={12.3} color='black' />
				</div>
				<div className={styles.container}>
					<img alt='customer-success' src={images.greenPlanes} className={styles.icon}></img>

					<div className={styles.title}>{t('greatJob')}!</div>
					<div className={styles.description}>
						{t(isPartner ? 'youPublishAnewAgreement' : 'youPublishAnewContract')}
						<span className={styles.customer}>{customer?.name}. </span> {t('youCanCheckTheStatus')}
						<span
							className={styles.link}
							onClick={() => {
								navigate(`${singleCustomerUrl}${customer?.id}`, { state: { contractType } });
								closeModal();
							}}
						>
							{t(isPartner ? 'partnerProfile' : 'customerProfile')}
						</span>
						.
					</div>

					<div className={styles.buttonsContainer}>
						<Button
							className={styles.button}
							type='secondary'
							onClick={() => {
								navigate(isPartner ? (contractType === ContractType.RESELLER ? '../resellers' : '../referrals') : '../customers');
								closeModal();
							}}
						>
							{t(isPartner ? 'goToPartners' : 'goToCustomers')}
						</Button>
						<Button
							className={styles.button}
							onClick={() => {
								navigate(`${singleCustomerUrl}${customer?.id}`, { state: { contractId, contractType } });
								closeModal();
							}}
						>
							{t(isPartner ? 'goToAgreement' : 'goToContract')}
						</Button>
					</div>
				</div>
			</div>
		</Modal>
	);
};
