import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CreateIndicator, Layout, Loader, Main, NotificationCard, NavigationSideBar, SuccessErrorModal } from '../components';
import { LISTENER_ADDRESS } from '../constants/generalConstants';
import { ErrorBoundary } from '../services/ErrorBoundary/ErrorBoundary';
import { PathsConfig } from '../services/httpService/configPaths';
import { httpService, setupInterceptor } from '../services/httpService/httpService';
import { getAvailableIntegrations } from '../services/integrationService/integrationService';
import { handleListenerChannelMessages } from '../services/listenerHandler/listenerHandler';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { setHideLoader } from '../storeSlices/generalSlice';
import { setIsAuthenticated } from '../storeSlices/userSlice';
import { ErrorSuccessModalTypes } from '../types/generalTypes';
import { Store } from '../types/storeTypes';
import { getGeoLocationOptionsData } from '../utils/GeneralUtils';
import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { keepAliveIds, startKeepAlive, stopKeepAliveTime } from '../services/keepAliveService/keepAliveService';
import { loggerService } from '../services/loggerService/loggerService';

export const AppRoutes = () => {
	const {
		isSidebarOpen,
		toastNotification: { showToastNotification },
		showCreateIndicator,
	} = useSelector((store: Store) => store.general);
	const isAuthenticated = useSelector((store: Store) => store.user.isAuthenticated);
	const userPermissions = useSelector((store: Store) => store.user.permissions);

	const dispatch = useDispatch();
	const { t } = useTranslation('translation');

	useEffect(() => {
		authStatus();
		setupInterceptor(dispatch);
		getGeoLocationOptionsData(dispatch);
	}, []);

	useEffect(() => {
		loggerService(`useEffect before sse, isAuthenticated: ${isAuthenticated}`);

		const sse = new EventSource(LISTENER_ADDRESS, { withCredentials: true });
		loggerService(`open sse: ${JSON.stringify(sse)}, isAuthenticated: ${isAuthenticated}`);

		let mouseTimeout: NodeJS.Timeout;

		if (isAuthenticated) {
			sse.onmessage = (event) => {
				handleListenerChannelMessages(t, event, dispatch);
				loggerService(`on message sse: ${JSON.stringify(sse)}, event:${JSON.stringify(event)}, isAuthenticated: ${isAuthenticated}`);
			};
			sse.onerror = (e) => {
				loggerService(`on error sse: ${JSON.stringify(sse)}, error: ${JSON.stringify(e)}, isAuthenticated: ${isAuthenticated}`);
			};

			getAvailableIntegrations(dispatch);

			window.addEventListener('mousemove', () => {
				if (keepAliveIds.length === 0) {
					startKeepAlive(dispatch);
				}

				if (mouseTimeout) clearTimeout(mouseTimeout);

				mouseTimeout = setTimeout(() => {
					stopKeepAliveTime();
				}, 15 * 60 * 1_000);
			});
		} else {
			loggerService(`before on close sse: ${JSON.stringify(sse)}, isAuthenticated: ${isAuthenticated}`);
			sse.close();
			loggerService(`after on close sse: ${JSON.stringify(sse)}, isAuthenticated: ${isAuthenticated}`);
			stopKeepAliveTime();
		}

		return () => {
			window.removeEventListener('mousemove', () => {});
		};
	}, [isAuthenticated]);

	useEffect(() => {
		window.addEventListener('resize', () => {});
		window.addEventListener('offline', () => dispatch(setOpenSuccessErrorModal({ modalType: ErrorSuccessModalTypes.NO_INTERNET })));
	});

	const authStatus = async () => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.getAuthStatus,
				unResetKeepAlive: true,
			});
			dispatch(setIsAuthenticated(true));
		} catch (e) {
			dispatch(setHideLoader(null));
		}
	};

	const routes = isAuthenticated
		? protectedRoutes(userPermissions).flatMap((item) => (item?.subElements ? [item, ...item.subElements] : item))
		: publicRoutes;

	return (
		<ErrorBoundary>
			<Layout>
				<CreateIndicator isShown={showCreateIndicator} />
				<SuccessErrorModal />
				{showToastNotification && <NotificationCard />}
				<NavigationSideBar isOpen={isAuthenticated && isSidebarOpen} />

				<Main>
					<Loader />
					{useRoutes(routes)}
				</Main>
			</Layout>
		</ErrorBoundary>
	);
};
