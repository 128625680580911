import { CellTypes, tableCalculation } from '@received/pricing-model';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from '@reduxjs/toolkit';
import { MenuOption } from '../components';
import { PricingModel } from '../types/contractTypes';
import { onDeletePricingModel, onDuplicatePricingModel, onUpdateFavorite } from '../components/_uiComponents/CatalogCard/CatalogCard.utils';
import { PathsConfig, httpService } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { BillingDocumentType } from '../types/generalTypes';

export const onUpdateUsageManualReportFromTable = (document: BillingDocumentType, prevDocument: BillingDocumentType) => {
	const newDocument = { ...prevDocument, state: document?.state, subTotal: document?.subTotal, total: document?.total };
	newDocument.pricingModel = { ...newDocument.pricingModel };
	newDocument.pricingModel.tabs = [...newDocument.pricingModel.tabs];
	newDocument.pricingModel.tabs.forEach((tab, index) => {
		newDocument.pricingModel.tabs[index] = {
			...newDocument.pricingModel.tabs[index],
			pricingModelTableTotal: document.pricingModel.tabs[index]?.pricingModelTableTotal,
			total: document.pricingModel.tabs[index]?.total,
			subTotal: document.pricingModel.tabs[index]?.subTotal,
		};
		const savedRowsCount = document.pricingModel.tabs[index].pricingModelTable.rows.length;
		newDocument.pricingModel.tabs[index].pricingModelTable.rows = newDocument.pricingModel.tabs[index].pricingModelTable.rows.map((row, rowIndex) => {
			if (rowIndex < savedRowsCount) {
				// update saved rows
				const item = document.pricingModel.tabs[index].pricingModelTable.rows[rowIndex];
				const cells = { ...row.cells };
				Object.keys(cells).forEach((key) => {
					if (cells[key]?.cellType) {
						cells[key].cellValue =
							cells[key].cellType === CellTypes.USAGE_AGGREGATOR_REFERENCE || cells[key].cellType === CellTypes.USAGE_QTY_AGGREGATOR
								? item.cells[key].cellValue
								: cells[key].cellValue;
					}
				});
				return {
					...row,
					cells: { ...cells },
				};
			} else {
				// update unsaved rows
				const item = document.pricingModel.tabs[index].pricingModelTable.rows[savedRowsCount - 1];

				const cells = { ...row.cells };
				Object.keys(cells).forEach((key) => {
					cells[key] = { ...cells[key] };
					if (cells[key]?.cellType) {
						cells[key].cellValue =
							cells[key].cellType === CellTypes.USAGE_AGGREGATOR_REFERENCE || cells[key].cellType === CellTypes.USAGE_QTY_AGGREGATOR
								? item.cells[key].cellValue
								: cells[key].cellValue;
					}
				});

				return { ...row, cells: { ...cells } };
			}
		});

		const newTableCalculation = tableCalculation(newDocument.pricingModel.tabs[index].pricingModelTable);
		newDocument.pricingModel.tabs[index].pricingModelTable = newTableCalculation.table;
	});
	newDocument.usageReportsByProduct = document?.usageReportsByProduct;
	return newDocument;
};

export const pricingModelMenuOptions = (
	t: any,
	dispatch: Dispatch,
	navigate: NavigateFunction,
	pricingModel?: PricingModel,
	onRefresh?: () => void,
	onEditPricingModel?: (state: any) => void,
): MenuOption[] => {
	let options: MenuOption[] = [
		{
			title: pricingModel?.isFavorite ? t('unfavoriteTemplate') : t('favoriteTemplate'),
			onPress: () => onUpdateFavorite(dispatch, pricingModel, onRefresh),
			iconType: 'emptyStar',
		},
		{
			title: t('Duplicate'),
			onPress: () => onDuplicatePricingModel(dispatch, pricingModel, onRefresh),
			iconType: 'duplicate',
			hasBottomBorder: true,
		},
	];
	if (pricingModel?.product) {
		options = [
			...options,
			{
				title: t('editPricingModel'),
				onPress: () => {
					const state = { pricingModelId: pricingModel?.id, productId: pricingModel?.product?.id, productName: pricingModel?.product?.name };
					onEditPricingModel
						? onEditPricingModel(state)
						: navigate('/pricing-models/new-pricing-model', {
								state,
						  });
				},
				iconType: 'edit',
			},
			{
				title: t('removeTemplate'),
				onPress: () => onDeletePricingModel(dispatch, pricingModel, onRefresh),
				iconType: 'delete',
				color: 'destructive',
			},
		];
	}
	return options;
};

export const getPricingModel = async (id: string, dispatch: Dispatch) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getPricingModelData,
			urlParam: { id },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_PRICING_MODEL }));
	}
};
