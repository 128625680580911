import { IntegrationCategory, IntegrationPlatform, IntegrationProvider, IntegrationStatus } from '@received/pricing-model';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NetSuiteConnectModal, getButtonByStatus, getTagByStatus } from '../../..';
import styles from './NetsuiteCard.module.scss';

export interface NetsuiteCardProps {
	title: string;
	disabled?: boolean;
	description?: string;
	imgType: string;
	platform: IntegrationPlatform;
	connectionStatus: IntegrationStatus;
	haveConfigurationOptions: boolean;
	category: IntegrationCategory;
	provider: IntegrationProvider;
	navigateToMatchingPage?: boolean;
	onConnect: () => void;
	onDisconnect?: () => void;
}

export const NetsuiteCard = ({
	title,
	connectionStatus,
	platform,
	haveConfigurationOptions,
	description,
	disabled,
	imgType,
	category,
	provider,
	navigateToMatchingPage,
	onConnect,
	onDisconnect,
}: NetsuiteCardProps) => {
	const { t } = useTranslation('translation');
	const [buttonStatus, setButtonStatus] = useState<IntegrationStatus>(IntegrationStatus.UNLINKED);
	const [openConnectModal, setOpenConnectModal] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		setButtonStatus(connectionStatus);
	}, [connectionStatus]);

	useEffect(() => {
		setOpenConnectModal(!!navigateToMatchingPage);
	}, [navigateToMatchingPage]);

	return (
		<div className={clsx(styles.card, disabled && styles.cardDisabled)}>
			<div className={styles.topContainer}>
				<div className={styles.iconContainer}>
					<img alt='cardIcon' src={imgType} className={styles.cardIcon}></img>
				</div>
				{getTagByStatus(t, buttonStatus)}
			</div>

			<h2>{t(title)}</h2>

			<span className={styles.cardDescription}>{description}</span>
			{getButtonByStatus(t, buttonStatus, haveConfigurationOptions, onConnect, () => setOpenConnectModal(true), onDisconnect, disabled)}

			<NetSuiteConnectModal
				isOpen={openConnectModal}
				platform={platform}
				category={category}
				integrationProvider={provider}
				image={imgType}
				closeModal={() => {
					navigate('.', { replace: true });
					setOpenConnectModal(false);
				}}
			/>
		</div>
	);
};
