import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { SearchBar, Select, Button, Icon, Table } from '../../../components';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { UserRole, UserDto } from '../../../types/userTypes';
import { tableHeader, userPermissionTypes, userRowOptions } from './UsersPermissions.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import styles from './UsersPermissions.module.scss';

export const UsersPermissions = () => {
	const { t } = useTranslation('translation');

	const [inviteEmail, setInviteEmail] = useState<string>();
	const [inviteRole, setInviteRole] = useState<string>();
	const [userList, setUserList] = useState<UserDto[]>([]);

	const dispatch = useDispatch();

	useEffect(() => {
		getUsers();
	}, []);

	const getUsers = async () => {
		try {
			const res: UserDto[] = (
				await httpService({
					dispatch,
					path: PathsConfig.authUserList,
				})
			).data;

			setUserList(res || []);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const inviteUser = async () => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.authInviteUser,
				data: {
					email: inviteEmail,
					role: inviteRole,
				},
			});
			getUsers();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.appearAnimation}>
			<div className={styles.pageHeader}>
				<h2>{t('usersPermissions')}</h2>
				<span className={styles.description}>{t('usersPermissionsDescription')}</span>
			</div>
			<div className={styles.actionBar}>
				<SearchBar
					placeholder={t('inviteByEmail')}
					onChange={(value) => {
						setInviteEmail(value);
					}}
				/>
				<div className={styles.actionBarButtons}>
					<Select
						fullBorder
						defaultValue={UserRole.Member}
						data={userPermissionTypes}
						onChange={(value) => {
							setInviteRole(value);
						}}
						width={13}
					/>
					<Button onClick={inviteUser} className={styles.sendInvitationButton}>
						{t('sendInvitation')}
					</Button>
				</div>
			</div>

			<Table
				header={tableHeader(dispatch)}
				rows={userList}
				className={styles.tableContainer}
				tableRowClassName={styles.tableRowContainer}
				hideTableFooter
				optionsList={(data) => userRowOptions(data, getUsers, dispatch)}
			/>
		</div>
	);
};
