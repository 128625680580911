import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import {
	CryptoTokens,
	IntegrationPlatform,
	IntegrationProvider,
	IntegrationStatus,
	PaymentOptions,
	PaymentPlatform,
	cryptoTokens,
	getBankAccountString,
} from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import {
	Button,
	CheckBox,
	CheckedTag,
	CheckedTagsList,
	Collapsible,
	CreateSupplier,
	CryptoWallet,
	Icon,
	IconImgType,
	Input,
	NetTermsSelect,
	Select,
	StripeCardSelect,
	StripeConnectModal,
	Switch,
	Tooltip,
} from '../..';
import { CustomerForm, tabIndexes } from '../CreateCustomer.utils';
import {
	PricingTermsSettings,
	autoChargeSettings,
	bankTransferSettings,
	bankTransferWithStripeSettings,
	stripeSettings,
} from '../../PricingTerms/settings';
import {
	getImageSrcByName,
	getTooltipBySettings,
	isPaymentMethodDisabled,
	isPaymentMethodSelected,
	paymentOptions,
} from '../../PricingTerms/PricingTerms.utils';
import { BillingAccountDetails, Customer } from '../../../types/customerTypes';
import { IntegrationType, StripeCard } from '../../../types/integrationTypes';
import { PaymentMethodOptions } from '../../../types/generalTypes';
import { getSuppliers } from '../../../utils/CustomerUtils';
import { images } from '../../../constants/images';
import { onCancelIntegration, onConnectEntity, onMissingDetailsEntity } from '../../../services/integrationService/integrationService';
import { Store } from '../../../types/storeTypes';
import { UserRole } from '../../../types/userTypes';
import { numberInRange } from '../../../utils/NumberUtils';
import styles from '../CreateCustomer.module.scss';

export interface ConfigurationsProps {
	customerDetails: CustomerForm;
	isOpen?: boolean;
	setCustomerDetails: Dispatch<SetStateAction<CustomerForm>>;
	updateForm(field: string, data?: string | number): void;
}

export const Configurations = ({ customerDetails, isOpen, updateForm, setCustomerDetails }: ConfigurationsProps) => {
	const { t } = useTranslation('translation');
	const [cryptoWallet, setCryptoWallet] = useState<CryptoWallet>({ isCryptoWalletEnabled: false, cryptoWalletAddress: '', tokens: [] });
	const [banks, setBanks] = useState<BillingAccountDetails[]>([]);
	const [isNewPaymentAccountOpened, setIsNewPaymentAccountOpened] = useState(false);
	const [onlinePaymentsGateways, setOnlinePaymentsGateways] = useState<IntegrationType[]>([]);
	const [supplierEntities, setSupplierEntities] = useState<Customer[]>([]);
	const [settings, setSettings] = useState<PricingTermsSettings>();
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodOptions[]>([]);
	const [isCreateEntityOpened, setIsCreateEntityOpened] = useState(false);

	const userRole = useSelector((store: Store) => store.user.role);
	const dispatch = useDispatch();

	useEffect(() => {
		isOpen && initSettings();
	}, [isOpen, customerDetails?.supplierId, customerDetails?.billingIntegration?.paymentOptions]);

	// IS SUPPLIER DO NOT ASSIGNED, SO DEFAULT SUPPLIER WILL BE ASSIGN IF EXIST
	useEffect(() => {
		if (!customerDetails?.supplierId) {
			const defaultEntity = supplierEntities.find((entity) => entity.isDefault);
			defaultEntity?.id && onChangeEntity(defaultEntity?.id);
		}
	}, [supplierEntities]);

	// SUPPLIERS SETTINGS
	useEffect(() => {
		isOpen && setSuppliers();
	}, [isOpen]);

	// CRYPTO SETTINGS
	useEffect(() => {
		setCryptoWallet({
			isCryptoWalletEnabled: customerDetails?.cryptoSettings?.isEnabled || false,
			cryptoWalletAddress: customerDetails?.cryptoSettings?.address,
			tokens: cryptoTokens.map((token) => ({
				id: token.id,
				isChecked: customerDetails?.cryptoSettings?.tokens?.includes(token.id) || false,
				label: token.title,
				iconSrc: getImageSrcByName(token.id),
			})),
		});
	}, [customerDetails?.cryptoSettings?.isEnabled]);

	// STRIPE ACCOUNT SETTINGS
	useEffect(() => {
		setPaymentGateways();
	}, [supplierEntities, customerDetails?.supplierId, customerDetails?.billingIntegration?.paymentOptions]);

	// AUTO CHARGE SETTINGS
	useEffect(() => {
		settings?.isAutoChargeToggleOn && onUpdateAutoCharge();
	}, [settings?.isAutoChargeToggleOn]);

	const initSettings = () => {
		let methods: PaymentMethodOptions[] = [];
		let settings: PricingTermsSettings | undefined;

		if (customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.BANK_TRANSFER)) {
			settings = bankTransferSettings;
			methods = [...methods, PaymentMethodOptions.BANK_TRANSFER];
			const bank = updateBank();
			customerDetails?.supplierId && !customerDetails?.billingIntegration?.billingAccountId && onChangeBank(bank?.id);
		}
		if (customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.PAYMENT_LINK)) {
			settings = stripeSettings;
			if (customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.BANK_TRANSFER)) {
				settings = bankTransferWithStripeSettings;
			}
			methods = [...methods, PaymentMethodOptions.PAYMENT_LINK];
		}
		if (customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE)) {
			settings = autoChargeSettings;
			methods = [PaymentMethodOptions.AUTO_CHARGE, PaymentMethodOptions.PAYMENT_LINK];
		}

		setSettings(settings);
		setPaymentMethods(methods);
	};

	const setPaymentGateways = () => {
		if (
			customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.PAYMENT_LINK) ||
			customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE)
		) {
			// get the linked stripe accounts from the chosen supplier
			const linkedPaymentGateways = supplierEntities
				.find((company) => company.id === customerDetails?.supplierId)
				?.billingDetails?.paymentGateways?.filter((paymentGateway) => paymentGateway.connectionStatus == IntegrationStatus.LINKED);
			setOnlinePaymentsGateways(linkedPaymentGateways || []);
		}
	};

	const setSuppliers = async () => {
		const companies: Customer[] = await getSuppliers(dispatch);
		setSupplierEntities(companies);
	};

	const onPaste = async (event: any) => {
		event.preventDefault();
		const paste = await navigator.clipboard.readText();
		setCryptoWallet({ ...cryptoWallet, cryptoWalletAddress: paste });
		updateCryptoWalletAddress(paste);
	};

	const updateCryptoWalletEnabled = (isCryptoWalletEnabled: boolean) => {
		setCryptoWallet({ ...cryptoWallet, isCryptoWalletEnabled });
		setCustomerDetails((prev) => ({
			...prev,
			cryptoSettings: {
				...prev?.cryptoSettings,
				isEnabled: isCryptoWalletEnabled,
			},
			paymentMethodError: false,
		}));
	};

	const updateCryptoWalletAddress = (address: string) => {
		setCryptoWallet({ ...cryptoWallet, cryptoWalletAddress: address });

		setCustomerDetails((prev) => ({
			...prev,
			cryptoSettings: {
				...prev?.cryptoSettings,
				address,
			},
			cryptoSettingAddressError: false,
		}));
	};

	const onUpdateCryptoTokens = (tags: CheckedTag[]) => {
		setCryptoWallet({ ...cryptoWallet, tokens: tags });

		setCustomerDetails((prev) => ({
			...prev,
			cryptoSettings: {
				...prev?.cryptoSettings,
				tokens: tags.filter((token) => token.isChecked).map((token) => token.id || '') as CryptoTokens[],
			},
			cryptoSettingTokensError: false,
		}));
	};

	const onChangeStripeAccount = (paymentGatewayId: string) => {
		setCustomerDetails((prev) => ({
			...prev,
			billingIntegration: {
				...prev?.billingIntegration,
				paymentGatewayId: paymentGatewayId,
				paymentGatewayCustomerId: null,
				paymentGatewayCustomer: null,
			},
			paymentGatewayIdError: false,
		}));
	};

	const onSwitchAutoCharge = () => {
		setCustomerDetails((prev) => ({
			...prev,
			billingIntegration: {
				...prev?.billingIntegration,
				paymentOptions: settings?.isAutoChargeToggleOn ? [PaymentOptions.PAYMENT_LINK] : [PaymentOptions.AUTO_CHARGE],
				billingAccountId: null,
			},
			cryptoSettings: {
				...prev?.cryptoSettings,
				isEnabled: settings?.isAutoChargeToggleOn ? prev?.cryptoSettings?.isEnabled || false : false,
			},
		}));
	};

	const onChangeStripeCard = (paymentGatewayCustomerId: string, paymentGatewayCustomer: StripeCard | undefined) => {
		setCustomerDetails((prev) => ({
			...prev,
			billingIntegration: {
				...prev?.billingIntegration,
				paymentGatewayCustomer: paymentGatewayCustomer ? paymentGatewayCustomer : null,
				paymentGatewayCustomerId: paymentGatewayCustomerId?.length ? paymentGatewayCustomerId : null,
			},
		}));
	};

	const onUpdateAutoCharge = () => {
		setCustomerDetails((prev) => ({
			...prev,
			billingIntegration: { ...prev?.billingIntegration, paymentOptions: [PaymentOptions.AUTO_CHARGE], billingAccountId: null },
			cryptoSettings: {
				...prev?.cryptoSettings,
				isEnabled: false,
			},
		}));
	};

	const onChangeBank = (billingAccountId?: string) => {
		setCustomerDetails((prev) => ({
			...prev,
			billingIntegration: {
				...prev?.billingIntegration,
				billingAccountId,
			},
		}));
	};

	const updateBank = () => {
		const banks = supplierEntities.find((company) => company.id === customerDetails?.supplierId)?.billingDetails?.billingAccounts || [];
		setBanks(banks);
		return banks[0];
	};

	const onChangePaymentMethod = (paymentMethod: PaymentMethodOptions) => {
		let data: PaymentMethodOptions[];
		let paymentOptions: PaymentOptions[] = [];
		if (paymentMethods.includes(paymentMethod)) {
			data = paymentMethods.filter((item) => item != paymentMethod);
		} else {
			data = [...paymentMethods, paymentMethod];
		}

		if (data.includes(PaymentMethodOptions.BANK_TRANSFER)) {
			paymentOptions = [PaymentOptions.BANK_TRANSFER];
		}

		if (data.includes(PaymentMethodOptions.PAYMENT_LINK)) {
			paymentOptions.push(PaymentOptions.PAYMENT_LINK);
		}

		setPaymentMethods(data);
		setCustomerDetails((prev) => ({
			...prev,
			billingIntegration: {
				...prev?.billingIntegration,
				paymentGatewayId: data.includes(PaymentMethodOptions.PAYMENT_LINK) ? prev?.billingIntegration?.paymentGatewayId : null,
				paymentGatewayCustomerId: settings?.isAutoChargeToggleOn ? prev?.billingIntegration?.paymentGatewayCustomerId : null,
				paymentGatewayCustomer: settings?.isAutoChargeToggleOn ? prev?.billingIntegration?.paymentGatewayCustomer : null,
				paymentOptions,
			},
			paymentGatewayIdError: false,
			cryptoSettingTokensError: false,
			cryptoSettingAddressError: false,
		}));
	};

	const onChangeEntity = (supplierId: string) => {
		const billingAccountId = supplierEntities
			.find((company) => company?.id === supplierId)
			?.billingDetails?.billingAccounts?.find((bank) => bank.isDefault)?.id;
		setCustomerDetails((prev) => ({
			...prev,
			supplierId,
			billingIntegration: {
				...prev?.billingIntegration,
				billingAccountId: settings?.isBankMethodSelected ? billingAccountId : null,
				paymentGatewayId: null,
				paymentGatewayCustomerId: null,
				paymentGatewayCustomer: null,
			},
		}));
	};

	const addEntity = async (supplier: Customer) => {
		setBanks(supplier?.billingDetails?.billingAccounts || []);
		const billingAccountId = supplier?.billingDetails?.billingAccounts?.find((bank: BillingAccountDetails) => bank.isDefault)?.id;
		setCustomerDetails((prev) => ({
			...prev,
			supplierId: supplier.id,
			billingIntegration: {
				...customerDetails?.billingIntegration,
				billingAccountId,
			},
		}));

		await setSuppliers();
	};

	return (
		<Collapsible
			className={styles.collapsible}
			headerClassName={styles.collapsibleHeader}
			heightWhenOpen={'6rem'}
			header={
				<div className={styles.headerContainer}>
					<div className={styles.iconWrapper}>
						<Icon imgType='settings' className={styles.headerIcon} />
					</div>
					<span className={styles.title}>{t('configuration')}</span>
				</div>
			}
		>
			<div className={styles.section}>
				<div className={styles.wrapper}>
					<Select
						tabIndex={tabIndexes.DEBIT_ENTITY}
						placeholder={t('receivingEntityPlaceholder')}
						title={t('debitEntity')}
						onChange={(value) => onChangeEntity(value)}
						defaultValue={customerDetails?.supplierId}
						showError={customerDetails?.supplierIdError}
						width={''}
						data={
							supplierEntities?.map((item: Customer) => ({
								label: `${item?.name} ${item?.ein ? `(EIN ${item?.ein})` : ''}`,
								value: item.id,
							})) || []
						}
						headerLabelStyle={styles.titleStyle}
						addButton={{ title: t('addNewEntity'), onPress: () => setIsCreateEntityOpened(true), isDisabled: userRole !== UserRole.Admin }}
					/>

					{customerDetails?.supplierId && (
						<>
							<div className={styles.rowItem}>
								<div className={styles.titleStyle}>{t('paymentMethod')}</div>
								<div className={clsx(styles.flex, customerDetails?.paymentMethodError && styles.error)}>
									{paymentOptions.map((item: { id: PaymentMethodOptions; title: string }) => {
										return (
											<Tooltip
												key={item.id}
												tooltipComponent={getTooltipBySettings(t, item.id, settings)}
												blackTooltip
												disabled={!isPaymentMethodDisabled(item.id, settings)}
												containerClassName={styles.tooltipContainer}
											>
												<Button
													type='outlined'
													color='neutral'
													disabled={isPaymentMethodDisabled(item.id, settings)}
													className={clsx(styles.iconContainer, isPaymentMethodSelected(item.id, settings) && styles.selected)}
													onClick={() => onChangePaymentMethod(item.id)}
												>
													<Icon
														imgType={item.id as IconImgType}
														width={2.9}
														color={isPaymentMethodSelected(item.id, settings) ? 'success900' : 'neutral700'}
													/>
													{item.title}
												</Button>
											</Tooltip>
										);
									})}
									<Tooltip disabled={!settings?.isCryptoSettingsDisabled} tooltipComponent={getTooltipBySettings(t, '', settings)} blackTooltip>
										<Button
											type='outlined'
											color='neutral'
											className={clsx(styles.iconContainer, cryptoWallet.isCryptoWalletEnabled && styles.selected)}
											onClick={() => updateCryptoWalletEnabled(!cryptoWallet.isCryptoWalletEnabled)}
											disabled={settings?.isCryptoSettingsDisabled}
										>
											<Icon imgType='crypto_wallet' width={2.9} color={cryptoWallet.isCryptoWalletEnabled ? 'success900' : 'neutral700'} />
											{t('crypto')}
										</Button>
									</Tooltip>
								</div>
							</div>

							<Select
								tabIndex={tabIndexes.BANK}
								data={
									banks?.map((item: BillingAccountDetails) => ({
										label: getBankAccountString(item),
										value: item.id,
									})) || []
								}
								onChange={onChangeBank}
								defaultValue={customerDetails?.billingIntegration?.billingAccountId || ''}
								width={''}
								headerLabelStyle={styles.titleStyle}
								containerStyle={clsx(!settings?.isBanksSelectDisplayed && styles.hidden)}
								title={t('bankTransfer')}
							/>

							{settings?.isStripeSelectDisplayed && (
								<div className={styles.selectsContainer}>
									<Select
										tabIndex={tabIndexes.STRIPE_ACCOUNT}
										data={
											onlinePaymentsGateways?.map((item) => ({
												label: item?.metadata?.accountName || item?.metadata?.accountId || '',
												value: item?.id,
												customComponent: () => (
													<div className={styles.stripeAccountItem}>
														<Icon imgType='stripe' color='calming' width={2} />
														{item?.metadata?.accountName || item?.metadata?.accountId || ''}
													</div>
												),
											})) || []
										}
										onChange={onChangeStripeAccount}
										defaultValue={customerDetails?.billingIntegration?.paymentGatewayId || undefined}
										width={''}
										headerLabelStyle={styles.titleStyle}
										title={t('stripeIntegration')}
										placeholder={t('addOnlinePaymentAccount')}
										addButton={{
											title: t('connectOnlinePaymentAccount'),
											onPress: () => setIsNewPaymentAccountOpened(true),
										}}
										inputIcon={{ imgType: 'stripe', color: 'calming' }}
										showError={customerDetails?.paymentGatewayIdError}
									/>

									<div className={styles.autoCharge}>
										<span className={styles.autoChargeLabel}>{t('autoCharge')}</span>
										<Switch
											onChange={() => onSwitchAutoCharge()}
											defaultChecked={settings.isAutoChargeToggleOn}
											defaultDisable={settings.isAutoChargeToggleDisabled}
											className={clsx(styles.autoChargeToggle, settings.isAutoChargeToggleDisabled && styles.autoChargeToggleDisabled)}
										/>
									</div>
								</div>
							)}

							<StripeCardSelect
								tabIndex={tabIndexes.STRIPE_CUSTOMER}
								onChange={(selectedId, selectedItem) => onChangeStripeCard(selectedId, selectedItem)}
								// PaymentGatewayCustomer and paymentGatewayId are needed to show the select which stripe account selected
								defaultCustomer={customerDetails?.billingIntegration?.paymentGatewayCustomer || undefined}
								paymentGatewayId={customerDetails?.billingIntegration?.paymentGatewayId || undefined}
								supplierId={customerDetails?.supplierId}
								// suppliers needed in case supplier was changed
								suppliers={supplierEntities}
								containerStyle={clsx(settings?.isStripeCardsSelectDisplayed ? styles.appearAnimation : styles.hidden)}
								headerLabelStyle={styles.titleStyle}
								displayCustomerNameOnly={!customerDetails?.billingIntegration?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE)}
								showError={customerDetails?.paymentCustomerGatewayIdError}
							/>

							<div className={clsx(styles.cryptoWalletInputContainer, cryptoWallet.isCryptoWalletEnabled ? styles.appearAnimation : styles.hidden)}>
								<Input
									tabIndex={tabIndexes.CRYPTO_ADDRESS}
									value={cryptoWallet?.cryptoWalletAddress || ''}
									onChange={(val: string, event: ChangeEvent<HTMLInputElement>) => {
										setCryptoWallet({ ...cryptoWallet, cryptoWalletAddress: event.target.value });
										updateCryptoWalletAddress(event.target.value);
									}}
									title={t('receiptCryptoWallet')}
									titleStyle={styles.titleStyle}
									placeholder={t('pasteHereYourWalletAddress')}
									showError={customerDetails?.cryptoSettingAddressError}
									rightChildren={
										<Tooltip tooltipComponent={t('paste')}>
											<Button color='neutral' type='link' className={styles.pasteButton} onClick={onPaste}>
												<Icon imgType='paste' width={2} color='neutral700'></Icon>
											</Button>
										</Tooltip>
									}
								/>
								<div className={styles.flex}>
									<CheckedTagsList
										data={cryptoWallet?.tokens}
										showError={customerDetails?.cryptoSettingTokensError}
										onUpdateTags={onUpdateCryptoTokens}
									/>
								</div>
							</div>
						</>
					)}
				</div>

				<div className={styles.wrapper}>
					<div className={styles.inputsInRow}>
						<NetTermsSelect
							title={t('paymentTerms')}
							tabIndex={tabIndexes.NET_TERMS}
							headerLabelStyle={styles.titleStyle}
							onChange={(netTerms: any) => updateForm('netTerms', netTerms)}
							defaultValue={customerDetails?.netTerms}
							width={''}
							containerStyle={styles.flexInRow}
						/>
						<Input
							title={t('Tax')}
							tabIndex={tabIndexes.TAX}
							type='number'
							value={customerDetails.tax}
							onChange={(value, event) => updateForm('tax', event.target.valueAsNumber)}
							onBlur={() => updateForm('tax', numberInRange(customerDetails?.tax, 0, 100))}
							onKeyDown={(event) => event.key === 'Enter' && updateForm('tax', numberInRange(customerDetails?.tax, 0, 100))}
							onFocus={(e) => e.target.select()}
							titleStyle={styles.titleStyle}
							containerClass={styles.flexInRow}
						/>
					</div>

					<span className={styles.greyTitle}>{t('attachUsageReports')}</span>
					<div className={styles.checkboxContainer}>
						<div className={styles.checkboxItem}>
							<CheckBox
								tabIndex={tabIndexes.PDF_REPORT}
								isChecked={customerDetails.settings?.attachUsageLogsPdf}
								setIsChecked={(val) =>
									setCustomerDetails((prev) => ({
										...prev,
										settings: { attachUsageLogsCsv: !!prev?.settings?.attachUsageLogsCsv, attachUsageLogsPdf: val },
									}))
								}
							/>
							<div>{t('pdfReport')}</div>
						</div>
						<div className={styles.checkboxItem}>
							<CheckBox
								tabIndex={tabIndexes.CSV_REPORT}
								isChecked={customerDetails.settings?.attachUsageLogsCsv}
								setIsChecked={(val) =>
									setCustomerDetails((prev) => ({
										...prev,
										settings: { attachUsageLogsPdf: !!prev?.settings?.attachUsageLogsPdf, attachUsageLogsCsv: val },
									}))
								}
							/>
							<div>{t('csvReport')}</div>
						</div>
					</div>
				</div>
			</div>
			<StripeConnectModal
				isOpen={isNewPaymentAccountOpened}
				platform={PaymentPlatform.STRIPE}
				image={images.stripePayment}
				onConnect={(id, integrationAccountId, existing) =>
					onConnectEntity(dispatch, IntegrationProvider.STRIPE, id, IntegrationPlatform.STRIPE, integrationAccountId, existing)
				}
				onCancel={(supplierId: string, integrationAccountId?: string) =>
					onCancelIntegration(dispatch, IntegrationProvider.STRIPE, supplierId, IntegrationPlatform.STRIPE, integrationAccountId)
				}
				onMissingDetails={(integrationAccountId: string) => onMissingDetailsEntity(dispatch, IntegrationProvider.STRIPE, integrationAccountId)}
				closeModal={() => setIsNewPaymentAccountOpened(false)}
				onRefresh={setSuppliers}
			/>
			<CreateSupplier
				companiesListLength={supplierEntities?.length || 0}
				isOpen={isCreateEntityOpened}
				closeModal={() => setIsCreateEntityOpened(false)}
				onCreateUpdateSupplier={addEntity}
				selectedBankId={customerDetails?.billingIntegration?.billingAccountId || null}
				overlayClassName={styles.overlayModal}
			/>
		</Collapsible>
	);
};
