export const numberWithCommas = (num?: number, toFixed = 2) =>
	!num || isNaN(num)
		? 0
		: num
				?.toFixed(toFixed)
				.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
				.replace(/[.,]00$/, '');

export const priceNumberDisplay = (num: number, toFixed?: number, currency?: string) => {
	if (Math.abs(num) >= 1_000_000_000_000) {
		return `${numberWithCommas(num / 1_000_000_000)}B`;
	}
	if (Math.abs(num) >= 1_000_000_000) {
		return `${numberWithCommas(num / 1_000_000)}M`;
	}
	if (Math.abs(num) >= 1_000_000) {
		return `${numberWithCommas(num / 1_000)}K`;
	}
	return `${currency}${numberWithCommas(num, toFixed)}`;
};

export const priceNumberGraphsDisplay = (num: number) => {
	if (Math.abs(num) >= 10_000_000_000) {
		return `${numberWithCommas(num / 10_000_000_000)}B`;
	}
	if (Math.abs(num) >= 10_000_000) {
		return `${numberWithCommas(num / 10_000_000)}M`;
	}
	if (Math.abs(num) >= 1_000) {
		return `${numberWithCommas(num / 1_000)}K`;
	}
	return numberWithCommas(num);
};

export const sumNumbersArray = (arr: number[]) => {
	return arr.reduce((partialSum, num) => partialSum + num, 0);
};

export const numberInRange = (val: number, minVal: number, maxVal: number) => {
	if (isNaN(val)) return minVal;

	if (val < minVal) return minVal;

	if (val > maxVal) return maxVal;
	// round number to 2 decimal places
	return Math.round((val + Number.EPSILON) * 100) / 100;
};

export const getValueWithCurrencySign = (num?: number, currency?: string, toFixed?: number) => {
	const result = num ? numberWithCommas(num, toFixed) : 0;
	return result != 0 && result.includes('-') ? `(${currency}${result})`.replace('-', '') : `${currency}${result}`;
};

export const priceNumberWithCurrencySignDisplay = (num: number, currency?: string) => {
	return getValueWithCurrencySign(+num?.toFixed(), currency);
};

export const tableInputNumberWithCommas = (num?: number) => {
	if (!num || isNaN(num)) {
		return 0;
	}

	if (num < 0) {
		return num;
	}

	const numberParts = num.toString().split('.');
	const newNum = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	return numberParts[1] ? `${newNum}.${numberParts[1].slice(0, 5)}` : newNum;
};

export const getNumberingFormat = (num: number) => {
	if (Math.abs(num) >= 1_000_000_000) {
		return {
			postfix: 'B',
			divider: 1_000_000_000,
		};
	}
	if (Math.abs(num) >= 1_000_000) {
		return {
			postfix: 'M',
			divider: 1_000_000,
		};
	}
	if (Math.abs(num) >= 1_000) {
		return {
			postfix: 'K',
			divider: 1_000,
		};
	}
	return {
		postfix: '',
		divider: 1,
	};
};

export const absoluteValueArray = (array: number[]) => {
	return array.map(Math.abs);
};
