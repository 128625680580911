import { CryptoTokens, DEFAULT_CURRENCY, PaymentOptions, PaymentTerms } from '@received/pricing-model';
import { DEFAULT_COUNTRY, DEFAULT_PHONE_PREFIX } from '../../constants/generalConstants';
import { EntityForm } from '../CreateSupplier/EntityInfoTab/EntityInfoTab.utils';

export interface CustomerForm extends EntityForm {
	tax: number;
	taxError: boolean;
	supplierIdError: boolean;
	currencyError: boolean;
	shippingAddressError: boolean;
	billingAddressError: boolean;
	paymentMethodError: boolean;
	paymentGatewayIdError: boolean;
	paymentCustomerGatewayIdError: boolean;
	cryptoSettingAddressError: boolean;
	cryptoSettingTokensError: boolean;
	errorSection?: FormSections;
}

const enum FormSections {
	CUSTOMER_DETAILS,
	INTEGRATION_IDS,
	CONFIGURATIONS,
}

export const initialState: CustomerForm = {
	name: '',
	nameError: false,
	legalName: '',
	legalNameError: false,
	email: '',
	emailError: false,
	city: '',
	einError: false,
	ein: '',
	countryCodeError: false,
	country: DEFAULT_COUNTRY,
	countryError: false,
	phone: {
		countryCode: DEFAULT_PHONE_PREFIX,
		phone: undefined,
	},
	countryState: '',
	countryStateError: false,
	currency: DEFAULT_CURRENCY,
	currencyError: false,
	iconData: '',
	taxError: false,
	supplierId: undefined,
	supplierIdError: false,
	shippingAddressError: false,
	billingAddressError: false,
	phoneError: false,
	tax: 0,
	billingAddress: '',
	shippingAddress: '',
	netTerms: PaymentTerms.DUE_UPON_RECEIPT,
	settings: { attachUsageLogsCsv: false, attachUsageLogsPdf: true },
	integrationsRemoteIds: [],
	remoteId: undefined,
	paymentMethodError: false,
	paymentGatewayIdError: false,
	cryptoSettingAddressError: false,
	cryptoSettingTokensError: false,
	paymentCustomerGatewayIdError: false,
	billingIntegration: {
		paymentOptions: [PaymentOptions.BANK_TRANSFER],
	},
	cryptoSettings: {
		isEnabled: false,
		tokens: [CryptoTokens.USDC, CryptoTokens.USDT],
	},
	errorSection: undefined,
	isPartner: false,
};

export const customerValidation = (data: CustomerForm) => {
	const customerData = { ...data };
	let isValid = true;

	if (!customerData.name) {
		customerData.nameError = true;
		isValid = false;
		customerData.errorSection = FormSections.CUSTOMER_DETAILS;
	}
	if (!customerData.legalName) {
		customerData.legalNameError = true;
		isValid = false;
		customerData.errorSection = FormSections.CUSTOMER_DETAILS;
	}

	if (!customerData?.email) {
		customerData.emailError = true;
		isValid = false;
		customerData.errorSection = FormSections.CUSTOMER_DETAILS;
	}

	if (!customerData?.phone?.countryCode && isNaN(Number(customerData?.phone?.countryCode))) {
		customerData.countryCodeError = true;
		isValid = false;
		customerData.errorSection = FormSections.CUSTOMER_DETAILS;
	}

	if (customerData?.phone?.phone && !isNaN(Number(customerData?.phone?.phone)) && customerData?.phone?.phone?.length < 7) {
		customerData.phoneError = true;
		isValid = false;
		customerData.errorSection = FormSections.CUSTOMER_DETAILS;
	}

	if (!customerData.country) {
		customerData.countryError = true;
		isValid = false;
		customerData.errorSection = FormSections.CUSTOMER_DETAILS;
	}

	if (customerData.supplierId && customerData?.billingIntegration?.paymentOptions?.length === 0 && !customerData?.cryptoSettings?.isEnabled) {
		customerData.paymentMethodError = true;
		isValid = false;
		customerData.errorSection = FormSections.CONFIGURATIONS;
	}
	if (
		customerData.supplierId &&
		customerData?.billingIntegration?.paymentOptions?.includes(PaymentOptions.PAYMENT_LINK) &&
		!customerData?.billingIntegration?.paymentGatewayId
	) {
		customerData.paymentGatewayIdError = true;
		isValid = false;
		customerData.errorSection = FormSections.CONFIGURATIONS;
	}
	if (
		customerData.supplierId &&
		customerData?.billingIntegration?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE) &&
		!customerData?.billingIntegration?.paymentGatewayId
	) {
		customerData.paymentGatewayIdError = true;
		isValid = false;
		customerData.errorSection = FormSections.CONFIGURATIONS;
	}

	if (customerData.supplierId && customerData?.cryptoSettings?.isEnabled && !customerData?.cryptoSettings?.address) {
		customerData.cryptoSettingAddressError = true;
		isValid = false;
		customerData.errorSection = FormSections.CONFIGURATIONS;
	}
	if (customerData.supplierId && customerData?.cryptoSettings?.isEnabled && customerData?.cryptoSettings?.tokens?.length === 0) {
		customerData.cryptoSettingTokensError = true;
		isValid = false;
		customerData.errorSection = FormSections.CONFIGURATIONS;
	}
	if (customerData.supplierId && customerData?.cryptoSettings?.isEnabled && customerData?.cryptoSettings?.tokens?.length === 0) {
		customerData.cryptoSettingTokensError = true;
		isValid = false;
		customerData.errorSection = FormSections.CONFIGURATIONS;
	}
	return { isValid, data: customerData };
};

export const tabIndexes = {
	FILE: 0,
	CUSTOMER_NAME: 1,
	IS_PARTNER: 2,
	LEGAL_NAME: 3,
	EIN: 4,
	EMAIL: 5,
	COUNTRY: 6,
	STATE: 7,
	CITY: 8,
	PHONE_PREFIX: 9,
	PHONE_NUMBER: 10,
	BILLING_ADDRESS: 11,
	SHIPPING_ADDRESS: 12,
	REMOTE_ID: 13,
	DEBIT_ENTITY: 14,
	BANK: 15,
	STRIPE_ACCOUNT: 16,
	STRIPE_CUSTOMER: 17,
	CRYPTO_ADDRESS: 18,
	CRYPTO_EDIT_TOKENS: 19,
	NET_TERMS: 20,
	TAX: 21,
	PDF_REPORT: 22,
	CSV_REPORT: 23,
	RESET_BUTTON: 24,
	CONFIRM_BUTTON: 25,
	CLOSE_BUTTON: 26,
};
