import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { UsageProductAggregation } from '../UsageProductItem/UsageProductItem.utils';
import { getUsageCellAggregationLabel } from '../../PricingModelTable.utils';
import { UsageProductInitialization } from '../ConnectUsageProductModal';
import { diamond } from '../../../../constants/unicodes';
import styles from './UsageCellPreview.module.scss';

interface UsageCellPreviewProps {
	columnName?: string;
	selectedUsageProduct?: UsageProductInitialization;
	aggregationData?: UsageProductAggregation;
}

export const UsageCellPreview = ({ columnName, aggregationData, selectedUsageProduct }: UsageCellPreviewProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<div className={styles.productColl}>
				<div className={clsx(styles.dataRow, styles.itemDescription)}>{columnName}</div>
			</div>

			<div className={styles.usageCall}>
				<div className={clsx(styles.dataRow, styles.usageCell)}>
					<div className={styles.flexCenter}>
						<span className={styles.purpleDiamond}>{diamond} </span>
						<div className={styles.usageText}>{selectedUsageProduct?.usageName || `${t('usage')}`}</div>
					</div>

					<div className={styles.usageText}>{getUsageCellAggregationLabel(aggregationData?.method, aggregationData?.scope)}</div>
				</div>
			</div>
		</div>
	);
};
