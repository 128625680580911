import React from 'react';
import { Button, ButtonTypes, Icon } from '../..';
import styles from './CloseButton.module.scss';

export interface CloseButtonProps {
	type?: ButtonTypes;
	onClick?: (value?: any) => void;
}

export const CloseButton = ({ type = 'outlined', onClick }: CloseButtonProps) => {
	return (
		<Button type={type} color='neutral' className={styles.xButton} onClick={onClick}>
			<Icon imgType='x_icon' color='neutral700' height={1.2} className={styles.xButtonIcon} />
		</Button>
	);
};
