import { AccountingEventType } from '@received/pricing-model';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TruncatedList } from '../../..';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { BillingDocumentType } from '../../../../types/generalTypes';
import styles from './DocumentList.module.scss';

interface DocumentListProps {
	documentList: Array<BillingDocumentType>;
	isSideBarOpen: boolean;
	onClickDocument(selectedDocument: BillingDocumentType): void;
}

export const DocumentList = ({ documentList, isSideBarOpen, onClickDocument }: DocumentListProps) => {
	const { t } = useTranslation('translation');
	const [isListOpen, setIsListOpen] = useState(false);

	useEffect(() => {
		setIsListOpen(false);
	}, [isSideBarOpen]);

	return (
		<TruncatedList
			alwaysShowTruncator
			renderTruncator={({ hiddenItemsCount }) => {
				return hiddenItemsCount > 0 ? (
					<Button color='neutral' type='secondary' className={styles.item} onClick={() => setIsListOpen(true)}>
						+{hiddenItemsCount}
					</Button>
				) : (
					<></>
				);
			}}
			className={clsx(styles.invoiceList, isListOpen ? styles.invoiceListOpen : styles.invoiceListClose)}
		>
			{documentList.map((document, index) => (
				<Button color='neutral' type='secondary' key={`${document.id}${index}`} className={styles.item} onClick={() => onClickDocument(document)}>
					{t(
						document?.accountingEventType == AccountingEventType.INVOICE
							? 'invoice'
							: document?.accountingEventType == AccountingEventType.CREDIT
							? 'creditNote'
							: 'debitNote',
					)}{' '}
					{getDocumentIdNameByType(document)}
				</Button>
			))}
		</TruncatedList>
	);
};
