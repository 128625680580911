import { UserPermissions } from '@received/pricing-model';
import { NodeEnvTypes, RoutesType } from '../../types/generalTypes';
import { Customers, SingleCustomerPage, Subscribers } from '../../pages/_Customers';

export const customersRoutes: RoutesType[] = [
	{
		name: 'customers',
		path: '',
		showOnSidebar: true,
		iconType: 'users',
		licenses: [UserPermissions.BILLING],
		subElements: [
			{
				name: 'enterprises',
				path: '/customers',
				dataTestId: 'customers',
				showOnSidebar: true,
				element: <Customers />,
				licenses: [UserPermissions.BILLING],
			},
			{
				name: 'subscribers',
				path: '/subscribers',
				showOnSidebar: window._env_.REACT_APP_NODE_ENV != NodeEnvTypes.PRODUCTION,
				element: <Subscribers />,
				licenses: [UserPermissions.BILLING],
			},
		],
	},
	{
		name: 'singleCustomer',
		path: '/customers/single-customer/:id',
		showOnSidebar: false,
		element: <SingleCustomerPage />,
	},
];
