import { BillingAccountDetails } from '../../../types/customerTypes';

export const initialState: BillingAccountDetails = {
	bank: '',
	iban: '',
	branch: '',
	swift: '',
	account: '',
	routing: '',
	isDefault: false,
	isUsed: false,
	isDisabled: false,
};
