import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AccountingEventType, DocumentState } from '@received/pricing-model';
import {
	Button,
	FilterSideBar,
	FilterTags,
	Icon,
	SearchBar,
	SideBarFilters,
	DatePicker,
	InvoiceTable,
	SummaryBar,
	DateRangeFilters,
	ImportItemsProgress,
	Sidebars,
	MenuList,
	MenuOption,
} from '../../../../components';
import { httpService } from '../../../../services/httpService/httpService';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { DatesData, Sort, SortOrder } from '../../../../types/generalTypes';
import { Store } from '../../../../types/storeTypes';
import { InvoiceView, summaryType } from '../../../../types/invoiceTypes';
import {
	CreditNoteResponse,
	summaryBlocksTypes,
	creditNoteTableHeader,
	onDownloadCreditNotesCSV,
	getCreditsSummaryBlocksData,
} from './AllCreditNotes.utils';
import { setSidebarIsOpen } from '../../../../storeSlices/generalSlice';
import { useDebounce } from '../../../../hooks/generalHooks';
import { FooterTypeOptions } from '../../../../components/_uiComponents/DatePicker/Footer/Footer';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { ChangeStatusOption, cancelInvoices, downloadDocuments } from '../../../../components/InvoiceTable/InvoiceTable.utils';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../../components/Filters/DateRangeFilters.utils';
import { SidebarType } from '../../../../components/Sidebars/Sidebars.utils';
import {
	isDocumentTabsCanBeEdit,
	isTimePeriodSelectDisabled,
	getSummaryDates,
	getDefaultSortByState,
} from '../../Invoices/AllInvoices/AllInvoices.utils';
import { documentsViewOptionsList, reconcileDocuments, sendMultipleDocuments, submitDocuments } from '../../../../utils/DocumentsUtils';
import { getCreditNoteSummaryData } from '../../../../utils/CreditNoteUtils';
import { AllCreditNoteSummary } from '../../../../types/creditNoteTypes';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import styles from './AllCreditNotes.module.scss';

export const AllCreditNotes = () => {
	const { t } = useTranslation('translation');

	const [totalCount, setTotalCount] = useState(0);
	const [creditNoteList, setCreditNoteList] = useState<InvoiceView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState('20');
	const [openFilter, setOpenFilter] = useState(false);
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [sideBarFilters, setSideBarFilters] = useState<SideBarFilters>({ filterData: {}, filterTags: [] });
	const [searchText, setSearchText] = useState('');
	const [selectedCreditNoteList, setSelectedCreditNoteList] = useState<InvoiceView[]>([]);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [currentAction, setCurrentAction] = useState<ChangeStatusOption | ''>('');
	const [sort, setSort] = useState<Sort>({ orderBy: getDefaultSortByState(summaryType.WARNING), order: SortOrder.ASC });
	const [selectedSummary, setSelectedSummary] = useState<summaryType>(summaryType.WARNING);
	const [showDraft, setShowDraft] = useState(true);
	const [timePeriod, setTimePeriod] = useState<DatesData | undefined>(generalTimePeriodInitialState);
	const [creditNoteSummaries, setCreditNoteSummaries] = useState<AllCreditNoteSummary>();
	const [openMultipleSelectOptions, setOpenMultipleSelectOptions] = useState(false);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [toasterLabels, setToasterBarLabels] = useState({
		barTitle: undefined,
		cardTitle: undefined,
	});

	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const { appCurrency } = useSelector((store: Store) => store.general);
	const dispatch = useDispatch();
	const location: any = useLocation();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
		location?.state?.summaryType && setSelectedSummary(location?.state?.summaryType);
	}, [location?.state?.summaryType]);

	useEffect(() => {
		selectedSummary && getDocuments();
	}, [sort]);

	useEffect(() => {
		getData();
	}, [numberOfItems, pageNumber, sideBarFilters.filterData, sideBarFilters.filterTags, showDraft, timePeriod, selectedSummary]);

	const getData = async (search?: string, sideBarFiltersData?: SideBarFilters, selectedState?: summaryType) => {
		await getDocuments(search, sideBarFilters?.filterTags.length ? sideBarFilters : sideBarFiltersData, selectedState || selectedSummary);
		const time = await updateTimePeriod();
		await getSummary(time, sideBarFilters?.filterTags.length ? sideBarFilters : sideBarFiltersData, search);
	};

	const updateTimePeriod = async () => {
		const isTimePeriodDisabled = isTimePeriodSelectDisabled(sideBarFilters.filterData);
		setTimePeriod(isTimePeriodDisabled ? undefined : timePeriod || generalTimePeriodInitialState);
		return isTimePeriodDisabled ? undefined : timePeriod || generalTimePeriodInitialState;
	};

	const getSummary = async (time?: DatesData, sideBarFiltersData?: SideBarFilters, search?: string) => {
		const dates = getSummaryDates(
			sideBarFiltersData?.filterTags?.length ? sideBarFiltersData?.filterData : sideBarFilters?.filterData,
			time || timePeriod,
		);

		const summary = await getCreditNoteSummaryData(
			dispatch,
			dates?.from,
			dates?.to,
			sideBarFiltersData?.filterData?.customerIds || sideBarFilters?.filterData?.customerIds,
			search,
		);
		setCreditNoteSummaries(summary);
	};

	const getDocuments = async (search?: string, sideBarFiltersData?: SideBarFilters, selectedState?: summaryType) => {
		try {
			const res: CreditNoteResponse = (
				await httpService({
					showLoader: !search,
					dispatch,
					path: PathsConfig.getDocumentsView,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: pageNumber,
						accountingEventTypes: [AccountingEventType.CREDIT],
						states:
							summaryBlocksTypes(showDraft).find((block) => (selectedState ? block.label === selectedState : block.label === selectedSummary))
								?.value || [],
						orderBy: sort.orderBy || getDefaultSortByState(selectedState || selectedSummary),
						order: sort.order,
						searchText: search || searchText,
						fromIssueDate: timePeriod ? timePeriod?.from : undefined,
						toIssueDate: timePeriod ? timePeriod?.to : undefined,
						...(sideBarFiltersData?.filterTags?.length ? sideBarFiltersData?.filterData : sideBarFilters?.filterData),
					},
				})
			).data;

			setTotalCount(res?.count);
			setCreditNoteList(res?.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTES }));
		}
	};

	const onCreditNoteChecked = (isChecked: boolean, row: InvoiceView) => {
		if (isChecked) {
			setSelectedCreditNoteList([...selectedCreditNoteList, row]);
		} else {
			setSelectedCreditNoteList(selectedCreditNoteList.filter((invoice) => invoice.id !== row.id));
		}
	};

	const onPublish = (list: InvoiceView[], currentAction: ChangeStatusOption) => {
		setOpenDatePicker(true);
		setCurrentAction(currentAction);
		setSelectedCreditNoteList(list);
	};

	const onUpdateStatusFromDatePicker = async (date: Date) => {
		setOpenDatePicker(false);
		if (currentAction === ChangeStatusOption.MARK_AS_PAID) {
			await reconcileDocuments(
				date,
				selectedCreditNoteList.map((creditNote) => ({ id: creditNote.id, type: AccountingEventType.CREDIT })),
				dispatch,
			);
		} else {
			await submitDocuments(
				date,
				selectedCreditNoteList.map((creditNote) => ({ id: creditNote.id, type: AccountingEventType.CREDIT })),
				dispatch,
			);
		}
		setSelectedCreditNoteList([]);
		setCurrentAction('');
		getData();
	};

	const onReconcileCreditNote = async (date: Date) => {
		await reconcileDocuments(
			date,
			selectedCreditNoteList.map((creditNote) => ({ id: creditNote.id, type: AccountingEventType.CREDIT })),
			dispatch,
		);
		setSelectedCreditNoteList([]);
		setCurrentAction('');
		getData();
	};

	const onCloseDatePicker = (isOpen: boolean) => {
		setOpenDatePicker(isOpen);
		setCurrentAction('');
	};

	const filterChangeStatusOptions = () => {
		let optionList: MenuOption[] = [
			{
				title: 'Download Documents',
				iconType: 'download',
				onPress: () => {
					downloadDocuments(
						selectedCreditNoteList.map((invoice) => invoice.id),
						dispatch,
					);
				},
			},
		];

		if (isDocumentTabsCanBeEdit(selectedSummary)) {
			optionList = [
				{
					title: 'Send Documents',
					iconType: 'paper_airplane',
					color: 'primary',
					disabled: {
						isDisabled: selectedCreditNoteList.some(
							(invoice) => ![DocumentState.REVIEW_READY_TO_SEND, DocumentState.DRAFT, DocumentState.FINALIZED].includes(invoice.state),
						),
						reason: t('cantSendListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
					subList: [
						{
							title: 'Send now',
							onPress: () => {
								sendMultipleDocuments(
									t,
									selectedCreditNoteList.map((item) => item.id),
									dispatch,
								);
								setSelectedCreditNoteList([]);
							},
							iconType: 'paper_airplane',
						},
						{
							title: 'Mark as Sent',
							onPress: () => onPublish(selectedCreditNoteList, ChangeStatusOption.PUBLISH),
							iconType: 'letter',
						},
					],
				},
				{
					title: t('markAsPaid'),
					iconType: 'v_circle',
					color: 'success',
					subList: [
						{
							title: 'Pick a Date',
							onPress: () => {
								setOpenDatePicker(true);
								setCurrentAction(ChangeStatusOption.MARK_AS_PAID);
							},
							iconType: 'date',
						},
						{
							title: 'Paid Today',
							onPress: () => {
								onReconcileCreditNote(dayjs().toDate());
							},
							iconType: 'clock',
						},
					],
					disabled: {
						isDisabled: !!selectedCreditNoteList.find((invoice) =>
							[DocumentState.REVIEW_MISSING_DATA, DocumentState.COLLECTING_INFO].includes(invoice.state),
						),
						reason: t('cantMarkListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
				},

				...optionList,
			];

			optionList = [
				...optionList,

				{
					title: 'Cancel Documents',
					iconType: 'error_icon',
					color: 'destructive',
					onPress: () => selectedCreditNoteList.map((item) => cancelInvoices(item?.id, dispatch, getData)),
					disabled: {
						isDisabled: selectedCreditNoteList.some((invoice) => invoice.state === DocumentState.CANCELED),
						reason: t('cantCancelListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
				},
			];
		}

		return optionList;
	};

	const onSummaryBlockClick = (type: summaryType) => {
		setSelectedSummary(type);
		setSelectedCreditNoteList([]);
		setPageNumber(0);
		setSearchText('');
	};

	const onHideShowDrafts = (showDraft: boolean) => {
		setShowDraft(showDraft);
		setPageNumber(0);
	};

	const onSearch = useDebounce(getData, 750);

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={getData} barTitle={toasterLabels.barTitle} cardTitle={toasterLabels.cardTitle} />

				<DatePicker
					hideInput
					openDatePicker={openDatePicker}
					datePickerLabel={currentAction === ChangeStatusOption.MARK_AS_PAID ? t('choosePaymentDate') : t('choosePublishDate')}
					headerLabelStyle={styles.datePickerHeaderLabelStyle}
					footerType={FooterTypeOptions.SHOW_CONFIRM_BUTTONS}
					onConfirm={(date) => onUpdateStatusFromDatePicker(date)}
					updateIsOpen={onCloseDatePicker}
					defaultFromDate={
						selectedCreditNoteList.length
							? currentAction === ChangeStatusOption.MARK_AS_PAID
								? dayjs(selectedCreditNoteList[0].dueDate).toDate()
								: dayjs(selectedCreditNoteList[0].issueDate).toDate()
							: new Date()
					}
				/>
				<Sidebars openSideBarById={openSideBarById} onCloseSideBars={() => setOpenSideBarById(undefined)} onDataChange={getData} />

				<FilterSideBar filterData={sideBarFilters} isOpen={openFilter} closeSideBar={() => setOpenFilter(false)} updateFilters={setSideBarFilters} />

				<header className={styles.pageHeader} style={{ height: sideBarFilters?.filterTags.length ? '16rem' : '10rem' }}>
					<div className={styles.flexColumn}>
						<h1 style={{ marginTop: '1.4rem' }}>{t('creditNotes')}</h1>

						<FilterTags
							filterData={sideBarFilters}
							onRemoveTag={(newTags) => sideBarFilters && setSideBarFilters((prev) => ({ ...prev, ...newTags }))}
						/>
					</div>

					<div className={styles.rightButtonsContainer}>
						<SearchBar
							placeholder={t('search')}
							value={searchText}
							onChange={(val) => {
								setSearchText(val);
								onSearch(val, sideBarFilters, selectedSummary);
							}}
							className={styles.search}
						/>
						<DateRangeFilters
							data={fullFiltersSelectOptions}
							defaultSelectValue={isTimePeriodSelectDisabled(sideBarFilters.filterData) ? t('customDates') : timePeriod?.title}
							callback={setTimePeriod}
							disabled={isTimePeriodSelectDisabled(sideBarFilters.filterData)}
						/>

						<Button onClick={() => setOpenFilter(true)} type='outlined' color='neutral' className={styles.filterButton}>
							{t('filters')}
							<Icon imgType='filters' />
						</Button>

						<MenuList
							isMenuOpen={isOptionsMenuOpen}
							openMenu={() => setIsOptionsMenuOpen(false)}
							optionsList={documentsViewOptionsList({ t, onDownloadCSV: () => onDownloadCreditNotesCSV(t, creditNoteList) })}
						>
							<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}>
								<Icon imgType='hamburger' color='neutral700' />
							</Button>
						</MenuList>

						{!!selectedCreditNoteList.length && (
							<MenuList
								optionsList={filterChangeStatusOptions()}
								isMenuOpen={openMultipleSelectOptions}
								openMenu={() => setOpenMultipleSelectOptions(false)}
							>
								<Button onClick={() => setOpenMultipleSelectOptions(!openMultipleSelectOptions)} className={styles.mainButton}>
									{`${t('changeStatus')} ${
										!!selectedCreditNoteList.length &&
										`(${selectedCreditNoteList.length} ${t(selectedCreditNoteList.length > 1 ? 'creditNotes' : 'creditNote')})`
									}`}
									<Icon imgType='arrow_down' color='white' width={1.5} className={openMultipleSelectOptions ? styles.arrowUp : styles.arrowDown} />
								</Button>
							</MenuList>
						)}
					</div>
				</header>

				<SummaryBar
					summary={getCreditsSummaryBlocksData(t, creditNoteSummaries)}
					className={styles.summarySection}
					blockClassName={styles.blockClassName}
					selectedSummary={selectedSummary}
					onClick={onSummaryBlockClick}
				/>

				<InvoiceTable
					refreshData={getData}
					tableProps={{
						showSwitch: selectedSummary == summaryType.WARNING,
						sort: sort,
						numberOfItems: numberOfItems,
						showCheckBox: true,
						header: creditNoteTableHeader(getData),
						rows: creditNoteList,
						className: sideBarFilters?.filterTags.length ? styles.tableWithTagsContainer : styles.tableContainer,
						numberOfPages: Math.ceil(totalCount / +numberOfItems),
						totalNumberOfItems: totalCount,
						onUpdateNumberOfItems: setNumberOfItems,
						onUpdatePageNumber: setPageNumber,
						onRowPress: (data) =>
							setOpenSideBarById({
								sideBarType: SidebarType.CREDIT_NOTE,
								id: data.id,
							}),
						onFlipOrder: (accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }),
						onRowChecked: onCreditNoteChecked,
						onCheckAll: (isChecked) => (isChecked ? setSelectedCreditNoteList([...creditNoteList]) : setSelectedCreditNoteList([])),
						onToggleSwitch: onHideShowDrafts,
					}}
				/>
			</div>
		</div>
	);
};
