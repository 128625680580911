import React, { useEffect, useState } from 'react';
import { CellTypes } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icon, MenuList, MenuOption } from '../../../_uiComponents';
import { PricingModelTableClassNames } from '../../PricingModelTable';
import { PricingModelTableViewTypeOptions, isColumnHidden, requiredColumnsEnum } from '../../PricingModelTable.utils';
import { PricingModelTableColumns } from '../../../../types/pricingModelTypes';
import { diamond } from '../../../../constants/unicodes';
import styles from './HeaderCell.module.scss';

export interface TableHeaderProps {
	column: PricingModelTableColumns;
	columns: PricingModelTableColumns[];
	classNames?: PricingModelTableClassNames;
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	isHiddenInInvoice?: boolean;
	hoverOnFormula?: boolean;
	columnsToColor: { [id: string]: string };
	isEditable?: boolean;
	colIndex: number;
	updateName(name: string): void;
	onInsertCol(side: 'RIGHT' | 'LEFT'): void;
	onDelete(): void;
	openUsageSideBar(): void;
	onHideShowColumn(): void;
	onHideShowColumnInInvoice(): void;
	addManualUsageReport(): void;
	onMoveCol(side: 'RIGHT' | 'LEFT'): void;
}

export const HeaderCell = ({
	column,
	columns,
	classNames,
	pricingModelTableViewType,
	isHiddenInInvoice,
	hoverOnFormula,
	columnsToColor,
	isEditable,
	colIndex,
	openUsageSideBar,
	updateName,
	onInsertCol,
	onDelete,
	onHideShowColumn,
	onHideShowColumnInInvoice,
	addManualUsageReport,
	onMoveCol,
}: TableHeaderProps) => {
	const { t } = useTranslation('translation');
	const [hoverOnHeader, setHoverOnHeader] = useState(false);

	const isHidden = isColumnHidden(column, pricingModelTableViewType);
	const disableHeaderList = [
		PricingModelTableViewTypeOptions.CATALOG_PREVIEW_NOT_EDITABLE,
		PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE,
	];

	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [startRename, setStartRename] = useState(false);
	const [newName, setNewName] = useState(column.title);

	useEffect(() => {
		setNewName(column.title);
	}, [column.title, column.id]);

	const listOptions = () => {
		let list: MenuOption[] = [
			{ title: t('rename'), onPress: () => setStartRename(true), iconType: 'rename' },
			{
				title: t(column.isHiddenInInvoice ? 'showColumnInInvoice' : 'hideColumnInInvoice'),
				onPress: () => onHideShowColumnInInvoice(),
				iconType: 'hiddenInInvoice',
			},
		];

		if (colIndex != columns.length - 1) {
			list.push({
				title: t('moveRight'),
				onPress: () => onMoveCol('RIGHT'),
				iconType: 'arrow_right',
			});
		}

		if (colIndex != 0) {
			list.push({
				title: t('moveLeft'),
				onPress: () => onMoveCol('LEFT'),
				iconType: 'arrow_left',
				hasBottomBorder: !Object.keys(requiredColumnsEnum).includes(column.id),
			});
		}

		if (column.type === CellTypes.USAGE_AGGREGATOR_REFERENCE && pricingModelTableViewType === PricingModelTableViewTypeOptions.INVOICE_PREVIEW) {
			list.push({
				title: t('addManualUsageReport'),
				onPress: () => addManualUsageReport(),
				iconType: 'edit',
			});
		}

		if (pricingModelTableViewType === PricingModelTableViewTypeOptions.PRICING_MODEL) {
			list = list.concat([
				{
					title: t(column.isHidden ? 'showColumn' : 'hideColumn'),
					onPress: () => onHideShowColumn(),
					iconType: column.isHidden ? 'eye' : 'closed_eye',
				},
				{ title: t('Insert right column'), onPress: () => onInsertCol('RIGHT'), iconType: 'insertRight' },
				{
					title: t('Insert left column'),
					onPress: () => onInsertCol('LEFT'),
					iconType: 'insertLeft',
					hasBottomBorder: !Object.keys(requiredColumnsEnum).includes(column.id),
				},
			]);
		}
		if (column.type === CellTypes.USAGE_AGGREGATOR_REFERENCE && pricingModelTableViewType === PricingModelTableViewTypeOptions.PRICING_MODEL) {
			list.push({ title: t('connectUsageProduct'), onPress: () => openUsageSideBar(), iconType: 'usageIcon', color: 'calming' });
		}

		if (!Object.keys(requiredColumnsEnum).includes(column.id) && pricingModelTableViewType === PricingModelTableViewTypeOptions.PRICING_MODEL) {
			list = list.concat({ title: t('deleteColumn'), onPress: () => onDelete(), iconType: 'delete', color: 'destructive' });
		}

		return list;
	};

	const onRename = () => {
		columns.find((col) => {
			const newId = newName.toLocaleLowerCase().replace(/\s/g, '');
			return col.id === newId && newId != column.id;
		}) || newName === column.title
			? setNewName(column.title)
			: updateName(newName);
	};

	return (
		<div
			className={clsx(styles.headerCell, classNames?.headerCell, isHidden && styles.hideHeaderCell, isHiddenInInvoice && styles.text)}
			onClick={(event) => {
				event.stopPropagation();
				column?.onPress ? column.onPress(column) : setOpenMenuOptions(true);
			}}
			style={{
				cursor: column.onPress ? 'pointer' : 'default',
				pointerEvents: (pricingModelTableViewType && disableHeaderList.includes(pricingModelTableViewType)) || !isEditable ? 'none' : 'auto',
			}}
			onContextMenu={(event) => {
				event.stopPropagation();
				event.preventDefault();
				event.type === 'contextmenu' && setOpenMenuOptions(true);
			}}
			onDoubleClick={() => setStartRename(true)}
			data-testid={`header-column-title-${column.title}`}
		>
			{column.type == CellTypes.USAGE_AGGREGATOR_REFERENCE && <span className={styles.purpleDiamond}>{diamond} </span>}

			{isHidden ? (
				<div
					data-testid={`header-column-hidden-title-${column.title}`}
					onClick={(event) => {
						event.stopPropagation();
						onHideShowColumn();
					}}
					className={styles.hiddenColumn}
				>
					<Icon imgType='doubleHumbugger' color='neutral' height={2} />
				</div>
			) : startRename ? (
				<input
					data-testid={`header-column-input-${column.title}`}
					value={newName}
					onChange={(event) => setNewName(event.target.value)}
					className={styles.inputContainer}
					autoFocus
					onBlur={() => {
						setStartRename(false);
						onRename();
						setOpenMenuOptions(false);
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							setStartRename(false);
							onRename();
							setOpenMenuOptions(false);
						}
					}}
				></input>
			) : (
				<MenuList
					optionsList={listOptions()}
					isMenuOpen={openMenuOptions}
					openMenu={() => setOpenMenuOptions(false)}
					className={styles.menuContainer}
					offsetStyles={{ width: '100%' }}
				>
					<div className={styles.titleContainer} onMouseLeave={() => setHoverOnHeader(false)} onMouseEnter={() => setHoverOnHeader(true)}>
						<div className={styles.title} data-testid={`header-column-title-${column.title}`}>
							{hoverOnFormula || hoverOnHeader ? (
								<div className={styles.flexCenter}>
									#
									<input
										value={` ${column.id}`}
										className={styles.name}
										style={{ color: hoverOnFormula ? columnsToColor[column.id] : 'unset', textTransform: 'none' }}
										disabled
										readOnly
									/>
								</div>
							) : (
								<input value={column.title} className={styles.name} disabled readOnly />
							)}
						</div>
						<div className={styles.flexCenter} data-testid={`header-column-kebab-${column.title}`} onClick={() => setOpenMenuOptions(true)}>
							{isHiddenInInvoice && <Icon imgType='hiddenInInvoice' color='neutral400' height={1.5} className={styles.HideIcon} />}
							<Icon imgType='hamburger' color='neutral' height={1.5} className={styles.hamburger} />
						</div>
					</div>
				</MenuList>
			)}
		</div>
	);
};
