import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PricingModelTab, PricingType, Currency, AccountingEventType } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Button, Icon, InvoiceStatusTag, MenuList, Tabs, DatePickerFormatType, PDFPreviewModal, MenuOption } from '../..';
import { ErrorSuccessModalDescriptionOptions, successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { invoiceTabs } from './InvoicesSideBar.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { Contract } from '../../../types/contractTypes';
import { Invoice } from '../../../types/invoiceTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { invoiceMenuList, isInvoiceEditable, startGenerateInvoicePDFById } from '../../../utils/InvoiceUtils';
import { tabItem } from '../ContractSideBar/ContractSideBar.utils';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { EditDocumentNumberState, getDocumentNumberPrefix, getEmailsLabel } from '../../../utils/GeneralUtils';
import { SideBarHeader } from '../components';
import { Customer } from '../../../types/customerTypes';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { Store } from '../../../types/storeTypes';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { NavigateToDifferentSingleDocumentPage } from '../../../types/generalTypes';
import styles from './InvoicesSideBar.module.scss';

interface InvoicesSideBarSideBarProps {
	id: any;
	navigateToDifferentSingleDocumentPage?: NavigateToDifferentSingleDocumentPage;
	isOpen?: boolean;
	isSecondarySideBar?: boolean;
	refreshData?: boolean;
	containersClassName?: string;
	selectedRowId?: string;
	sidebarToBeNoticed: boolean;
	setSidebarToBeNoticed: () => void;
	closeSideBar(): void;
	onDataChange?: () => void;
	setCustomer?: (customer: Customer) => void;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	closeSecondarySidebar: () => void;
}

export const InvoicesSideBar = ({
	id,
	isOpen = true,
	isSecondarySideBar = false,
	containersClassName,
	refreshData,
	selectedRowId,
	sidebarToBeNoticed,
	navigateToDifferentSingleDocumentPage,
	setSidebarToBeNoticed,
	closeSideBar,
	closeSecondarySidebar,
	openOtherSidebar,
	onDataChange,
	setCustomer,
}: InvoicesSideBarSideBarProps) => {
	const { t } = useTranslation('translation');
	const [invoiceData, setInvoiceData] = useState<Invoice>({
		currency: Currency.USD,
		pricingModel: { tabs: [], sourcePricingModelIds: [] },
	});
	const [contractData, setContractData] = useState<Contract>();
	const [isEditInvoiceNumber, setIsEditInvoiceNumber] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [openPreviewSideBar, setOpenPreviewSideBar] = useState<boolean>(false);
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [editInvoiceNumberState, setEditDocumentNumberState] = useState<{ state: EditDocumentNumberState; errorMessage?: string }>();
	const [tabs, setTabs] = useState<tabItem[]>(invoiceTabs());
	const urlPaymentLink = invoiceData?.syncStatus?.find((status) => status)?.metadata?.paymentUrl;

	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			initSideBar(id);
			!refreshData && setActiveTab(0);
		}
	}, [id, isOpen, refreshData]);

	useEffect(() => {
		setOpenPreviewSideBar(false);
	}, [activeTab]);

	const initSideBar = (invoiceId: string) => {
		isOpen && invoiceId && getInvoiceData(invoiceId);
		setEditDocumentNumberState(undefined);
		setIsEditInvoiceNumber(false);
	};

	const getInvoiceData = async (id: string) => {
		let invoiceDataRes;
		try {
			invoiceDataRes = (
				await httpService({
					showLoader: false,
					dispatch,
					path: PathsConfig.getInvoiceData,
					urlParam: { id },
				})
			).data;

			setInvoiceData(invoiceDataRes);
			setCustomer?.(invoiceDataRes?.customer);

			const invoiceHasUsageProducts = invoiceDataRes?.pricingModel.tabs.some((tab: PricingModelTab) => tab.pricingType == PricingType.USAGE);
			setTabs(invoiceTabs(invoiceHasUsageProducts));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.INVOICE_DETAILS }));
		}

		try {
			if (invoiceDataRes?.contractId) {
				const contractData = (
					await httpService({
						showLoader: false,
						dispatch,
						path: PathsConfig.getContractData,
						urlParam: { id: invoiceDataRes?.contractId },
					})
				).data;
				setContractData(contractData);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_DATA }));
		}
	};

	const closeInvoicesSideBar = () => {
		setActiveTab(0);
		setOpenPreviewSideBar(false);
		setIsEditInvoiceNumber(false);
		return isSecondarySideBar ? closeSecondarySidebar?.() : closeSideBar();
	};

	const updateDocumentNumber = async (documentNumber: string) => {
		try {
			if (invoiceData?.id) {
				const invoiceDataRes: Invoice = (
					await httpService({
						dispatch,
						path: PathsConfig.assignInvoiceNumber,
						data: {
							id: invoiceData?.id,
							documentNumber: documentNumber || null, // NULL will reset documentNumber
						},
					})
				).data;
				setInvoiceData({ ...invoiceData, customNumber: invoiceDataRes.customNumber });
			}
			setEditDocumentNumberState(undefined);
			setIsEditInvoiceNumber(false);
			onDataChange && onDataChange();
		} catch (error: any) {
			if (
				[
					ErrorSuccessModalDescriptionOptions.INVALID_MANUAL_INVOICE_NUMBER,
					ErrorSuccessModalDescriptionOptions.INVOICE_NUMBER_TENANT_UQ,
					ErrorSuccessModalDescriptionOptions.INVOICE_NUMBER_ALREADY_IN_USE,
				].includes(error?.response?.data?.errorCode)
			) {
				setEditDocumentNumberState({ state: EditDocumentNumberState.ERROR, errorMessage: error?.response?.data?.errorCode });
			} else {
				dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_INVOICE_NUMBER }));
			}
		}
	};

	const onSplitSuccess = async () => {
		await getInvoiceData(id);
		onDataChange?.();
	};

	const getSplittedInvoicesByInvoiceId = async (search?: string) => {
		try {
			return (
				await httpService({
					showLoader: false,
					dispatch,
					path: PathsConfig.getSplittedInvoiceById,
					urlParam: { id },
					params: {
						searchText: search,
					},
				})
			).data;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_SPLIT_INVOICES }));
		}
	};

	const menuList = () => {
		const editDocumentNumber: MenuOption = {
			title: t('editInvoiceId'),
			onPress: () => setIsEditInvoiceNumber(true),
			iconType: 'rename',
		};
		const list = invoiceMenuList({
			item: invoiceData,
			dispatch,
			navigate,
			t,
			availableIntegration,
			editDocumentNumber: isInvoiceEditable(invoiceData.state) ? editDocumentNumber : undefined,
			onRefresh: onSplitSuccess,
			onPreview: () => setOpenPreviewSideBar(!openPreviewSideBar),
		});
		return list;
	};

	const onEdit = () => {
		if (id && navigateToDifferentSingleDocumentPage?.originalDocumentId) {
			navigateToDifferentSingleDocumentPage?.onClick?.(id);
		} else {
			navigate('../invoices/single-invoice', { state: { InvoiceId: invoiceData?.id } });
		}
	};

	return (
		<>
			{isOpen && <div onClick={closeInvoicesSideBar} className={clsx(isSecondarySideBar ? styles.secondaryModalOverlay : styles.modalOverlay)} />}

			<div
				data-testid='invoice-side-bar'
				className={clsx(
					!isOpen ? styles.sidebarHidden : styles.sidebar,
					isSecondarySideBar && styles.secondarySidebar,
					isOpen && containersClassName,
					sidebarToBeNoticed && styles.noticeMe,
				)}
				onAnimationEnd={setSidebarToBeNoticed}
				style={{ overflow: 'scroll' }}
			>
				<div className={styles.sidebarContent}>
					<div className={styles.header}>
						<SideBarHeader
							isEditable={isEditInvoiceNumber}
							sidebarType={t(invoiceData?.accountingEventType == AccountingEventType.INVOICE ? 'invoice' : 'debitNote')}
							customer={invoiceData?.customer}
							placeholder={t('documentNumber')}
							prefix={getDocumentNumberPrefix(invoiceData, isEditInvoiceNumber)}
							documentNumber={invoiceData?.documentNumber || invoiceData?.customNumber || invoiceData?.draftNumber}
							editInvoiceNumberState={editInvoiceNumberState}
							updateValue={updateDocumentNumber}
							onStopWithoutEdit={() => setIsEditInvoiceNumber(false)}
							syncIcon={invoiceData?.syncStatus?.length ? getSyncStatus(invoiceData)?.icon : undefined}
						/>

						<Button onClick={onEdit} className={styles.button}>
							<span className={styles.buttonText}> {t(isInvoiceEditable(invoiceData?.state) ? 'edit' : 'view')}</span>
						</Button>

						<MenuList optionsList={menuList()} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
							<Button
								dataTestId='invoice-side-bar-humbugger'
								type='outlined'
								color='neutral'
								onClick={() => setOpenMenuOptions(true)}
								className={styles.hamburgerButtonWrapper}
							>
								<Icon
									imgType='hamburger'
									height={2}
									color='neutral700'
									className={clsx(styles.hamburgerButton, openMenuOptions && styles.hamburgerRotate)}
								/>
							</Button>
						</MenuList>
					</div>

					<div className={styles.detailsRowContainer}>
						<div className={clsx(styles.detailsRow, styles.flex)}>
							<span className={styles.detailsText}>{t('customer')}</span>
							<span
								className={clsx(styles.blackText, styles.ellipsis, styles.link)}
								style={{ marginRight: '0.4rem' }}
								onClick={() => contractData?.customer && navigate(`/customers/single-customer/${contractData?.customer.id}`)}
							>
								{invoiceData?.customer?.name}
							</span>
							<span className={clsx(styles.greyText, styles.ellipsis)}>{` | ${getEmailsLabel(contractData?.customer?.email)}`}</span>
						</div>
						<div className={styles.detailsRow}>
							<span className={clsx(styles.detailsText, styles.detailsText)}>{t('issueDate')}</span>
							<span data-testid='invoice-side-bar-issue-date' className={styles.blackText}>
								{invoiceData?.issueDate && dayjs(invoiceData?.issueDate).format(DATE_FORMAT)}
							</span>
						</div>

						<div className={styles.detailsRow}>
							<span className={clsx(styles.detailsText, styles.detailsText)}>{t('contractID')}</span>
							<span
								data-testid='invoice-side-bar-contract-id'
								className={clsx(styles.blackText, styles.link)}
								onClick={() =>
									invoiceData?.contractId &&
									openOtherSidebar({ sidebarType: SidebarType.CONTRACT, triggeredBy: SidebarType.INVOICE, id: invoiceData?.contractId })
								}
							>
								{contractIdFormat(contractData?.poNumber, contractData?.contractNumber)}
							</span>
						</div>

						{invoiceData?.supplier && (
							<div className={clsx(styles.detailsRow, styles.flex)}>
								<span className={styles.detailsText}>{t('debitEntity')}</span>
								<span
									data-testid='invoice-side-bar-supplier-name'
									className={clsx(styles.blackText, styles.ellipsis)}
									style={{ marginRight: '0.4rem' }}
								>
									{invoiceData?.supplier?.name}
								</span>

								{invoiceData?.billingDetails?.billingAccountId && (
									<span data-testid='invoice-side-bar-bank' className={clsx(styles.blackText, styles.ellipsis)}>{` | ${
										invoiceData?.supplier?.billingDetails?.billingAccounts?.find((bank) => bank.id === invoiceData?.billingDetails?.billingAccountId)
											?.bank || ''
									}`}</span>
								)}
							</div>
						)}

						<div className={styles.detailsRow}>
							<span className={clsx(styles.detailsText, styles.detailsText)}>{t('dueDate')}</span>
							<span data-testid='invoice-side-bar-due-date' className={styles.blackText}>
								{invoiceData?.dueDate && dayjs(invoiceData?.dueDate).format(DATE_FORMAT)}
							</span>
						</div>
						<div className={styles.detailsRowStatus}>
							<span className={clsx(styles.detailsText, styles.detailsText)}>{t('Status')}</span>
							<div className={styles.statusContainer}>
								<InvoiceStatusTag tooltipPlacement='top' document={invoiceData} refreshData={() => initSideBar(id)} />
								<span data-testid='invoice-side-bar-issue-date' className={styles.blackText}>
									{invoiceData?.paidDate && dayjs(invoiceData?.paidDate).format(DATE_FORMAT)}
								</span>

								{urlPaymentLink && (
									<Button color='neutral' type='link'>
										<a href={urlPaymentLink} target='_blank' rel='noreferrer'>
											<Icon imgType='nav_link' width={1.5} color='neutral300' />
										</a>
									</Button>
								)}
							</div>
						</div>
					</div>

					<div className={styles.tabsSection}>
						<Tabs
							tabsArray={tabs.map((tab) => tab.title)}
							chosenTab={activeTab}
							updateChosenTab={(val: number) => setActiveTab(val)}
							classNames={styles}
						/>
						<div className={styles.tabsContent}>
							{tabs[activeTab].component({
								invoiceData,
								contractData,
								sidebarType: SidebarType.INVOICE,
								selectedRowId,
								onUsageRowPress: openOtherSidebar,
								openOtherSidebar,
								onDataChange,
								invoiceDetailsProps: {
									hasUsageReport: !!invoiceData?.usageReportsByProduct?.length,
									onPressUpdateUsage: (timePeriod?: DatePickerFormatType) =>
										openOtherSidebar({
											sidebarType: SidebarType.USAGE_REPORT,
											triggeredBy: SidebarType.INVOICE,
											id: invoiceData?.id,
											usagePeriod: {
												fromDate: timePeriod?.fromDate || null,
												toDate: timePeriod?.toDate || null,
											},
										}),
								},
								relatedInvoicesProps: {
									isSecondarySideBar,
									openOtherSidebar,
									getSplittedInvoices: getSplittedInvoicesByInvoiceId,
								},
							})}
						</div>
					</div>
				</div>

				<PDFPreviewModal
					isOpen={openPreviewSideBar}
					documentsList={[invoiceData]}
					onClose={() => setOpenPreviewSideBar(false)}
					onDownload={() => startGenerateInvoicePDFById(dispatch, invoiceData?.id)}
				/>
			</div>
		</>
	);
};
