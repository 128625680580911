import { DocumentProductSummary, ProductAnalyticsList, TimeUnit } from '../../../types/generalTypes';
import { getNumberingFormat, absoluteValueArray } from '../../../utils/NumberUtils';
import { StackBarDataset } from '../../_uiComponents';
import { monthLabel } from '../../../utils/GraphsUtils';
import { FilterTimeOptions } from '../../Filters/DateRangeFilters.utils';

//based on product name
export const stackBarDataHandler = (data: ProductAnalyticsList[], timeUnit: TimeUnit, filter?: FilterTimeOptions) => {
	let isEmptyData = true;
	const convertedData = data?.reduce(
		(
			acc: {
				labels: string[];
				datasets: StackBarDataset[];
				isEmpty: boolean;
			},
			{ date, amountsPerProduct },
		) => {
			const labels = acc.labels.concat(monthLabel(date, timeUnit, filter));
			if (amountsPerProduct.some((item) => item.totalProductAmount > 0)) {
				isEmptyData = false;
			}
			const updatedDataSets = appendMonthlyDataSet(acc.datasets, amountsPerProduct);
			return { labels, datasets: updatedDataSets, isEmpty: false };
		},
		{
			labels: [],
			datasets: [],
			isEmpty: false,
		},
	);
	const allValues: any = convertedData.datasets.map((item) => item.data || 0).flat();

	return { data: convertedData, isEmpty: isEmptyData, dataFormat: getNumberingFormat(Math.max(...absoluteValueArray(allValues))) };
};

const appendMonthlyDataSet = (datasets: StackBarDataset[], products: DocumentProductSummary[]) => {
	return products.reduce((acc, item) => {
		const productDataSetIndex = acc.findIndex((dataset) => dataset.label === item.productName);

		if (productDataSetIndex >= 0) {
			const updatedDataSets = [...acc];
			updatedDataSets[productDataSetIndex].data = updatedDataSets[productDataSetIndex].data.concat(item.totalProductAmount);
			return updatedDataSets;
		}

		return acc.concat({
			name: item.pricingModelTabName,
			id: item.productId,
			label: item.productName,
			data: [item.totalProductAmount],
			backgroundColor: item.color,
			borderColor: item.color,
		});
	}, datasets);
};
