import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks/generalHooks';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { Customer } from '../../../types/customerTypes';
import { Button, CheckBox, Icon, SearchBar } from '../../_uiComponents';
import styles from '../FilterSideBar.module.scss';

interface CustomerFilterProps {
	selectedList: Customer[];
	updateSelectedList(selected: Customer[]): void;
}

export const CustomerFilter = ({ selectedList, updateSelectedList }: CustomerFilterProps) => {
	const { t } = useTranslation('translation');
	const [customerList, setCustomerList] = useState<Customer[]>([]);
	const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
	const [searchText, setSearchText] = useState('');
	const [showSelected, setShowSelected] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		getCustomersList();
	}, []);

	useEffect(() => {
		setSelectedCustomers(selectedList);
	}, [selectedList]);

	const getCustomersList = async (search?: string) => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getCustomersList,
				params: {
					textFilter: search || searchText,
				},
			});
			setCustomerList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_LIST }));
		}
	};

	const onSearch = useDebounce(getCustomersList, 450);

	const onSearchCustomer = (text: string) => {
		setSearchText(text);
		onSearch(text);
	};

	const onClearCustomers = () => {
		updateSelectedList([]);
		setSelectedCustomers([]);
	};

	const onIncludeExcludeCustomer = (isChecked: boolean, customer: Customer) => {
		let newCustomers = [];
		if (isChecked) {
			newCustomers = [...selectedCustomers, customer];
		} else {
			newCustomers = selectedCustomers.filter((cus) => cus.id !== customer.id);
		}
		setSelectedCustomers(newCustomers);
		updateSelectedList(newCustomers);
	};

	const listToRender = showSelected ? selectedCustomers : customerList;

	return (
		<div className={styles.customerSection}>
			<div className={styles.sectionHeader}>
				<div>
					<div className={styles.sectionHeaderTitle}>
						<Icon imgType='customers' color='neutral700' className={styles.sectionIcon} />
						{t('byCustomers')}
					</div>
					<div className={styles.bottomCustomerText}>
						{`${selectedCustomers.length} ${t('selected')}`} -
						<Button type='link' onClick={() => setShowSelected(!showSelected)} className={styles.showSelectedButton}>
							{t(showSelected ? 'Show all' : 'Show selected')}
						</Button>
					</div>
				</div>

				<Button type='link' color='neutral' onClick={onClearCustomers}>
					{t('clear')}
				</Button>
			</div>

			<div className={styles.customerContainer}>
				<SearchBar placeholder={t('searchCustomer')} onChange={onSearchCustomer} className={styles.customerSearch} />

				{listToRender.map((customer, index) => {
					return (
						<div key={index} className={styles.customerItem}>
							<CheckBox
								isChecked={selectedCustomers.includes(customer)}
								setIsChecked={(isChecked) => onIncludeExcludeCustomer(isChecked, customer)}
							/>
							{customer?.iconData ? (
								<img alt='logo' src={customer?.iconData} className={styles.logoContainer} />
							) : (
								<div className={clsx(styles.logoContainer, styles.noImgLogo)}>{customer.name[0].toUpperCase()}</div>
							)}
							<div className={styles.customerName}>{customer.name}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};
