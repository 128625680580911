import { Dispatch } from '@reduxjs/toolkit';
import { PricingType, AccountingEventType, ContractState, ContractType } from '@received/pricing-model';
import { Contract, ContractView, PricingModelTab } from '../types/contractTypes';
import { MenuOption } from '../components';
import { subscriptionStateColor } from '../pages/PricingModels/PricingModelTabs/SubscriptionsTab/SubscriptionsTab.utils';
import { contractStateColor } from '../components/_customerTabs/ContractsTab/ContractsTab.utils';
import { cancelContract, cloneContract, deleteContract } from '../pages/Contracts/ContractComponent/Contract.utils';
import { partnersAgreementColor } from './PartnersUtils';

export const isContractHasProductsByPricingType = (pricingType: PricingType, contract?: Contract) => {
	return contract?.pricingModel?.tabs?.some((tab: PricingModelTab) => tab.pricingType === pricingType);
};

export const isContractHasProductsByDocumentType = (accountingEventType: AccountingEventType, contract?: Contract) => {
	return contract?.pricingModel?.tabs?.some((tab: PricingModelTab) => tab.accountingEventType === accountingEventType);
};

export const contractIdFormat = (poNumber: string | number | undefined, contractNumber: number | string | undefined) =>
	(poNumber || `ID-${contractNumber}` || '-').toString();

export const contractMenuOptions = (
	row: ContractView | Contract,
	t: any,
	dispatch: Dispatch,
	onEdit?: () => void,
	onRefresh?: () => void,
	onDelete?: () => void,
): MenuOption[] => {
	let options: MenuOption[] = [
		{
			title: t('edit'),
			onPress: () => onEdit?.(),
			iconType: 'edit',
		},
		{
			title: t('clone'),
			onPress: () => {
				row?.id && cloneContract(row?.id, dispatch, onRefresh);
			},
			iconType: 'duplicate',
		},
	];
	if (row?.state !== ContractState.CANCELED) {
		options = [
			...options,
			{
				title: t('cancel'),
				onPress: () => {
					row?.id && cancelContract(row?.id, dispatch, onRefresh);
				},
				iconType: 'error_icon',
				color: 'destructive',
			},
		];
	}
	options = [
		...options,
		{
			title: t('delete'),
			onPress: () => {
				row?.id && deleteContract(row?.id, dispatch, onRefresh, onDelete);
			},
			iconType: 'delete',
			color: 'destructive',
		},
	];
	return options;
};

export const contractTitleByType = (type?: ContractType) => {
	switch (type) {
		case ContractType.ENTERPRISE:
			return 'contract';
		case ContractType.RESELLER:
			return 'resellerAgreement';
		case ContractType.REFERRAL:
			return 'referralAgreement';
		case ContractType.SUBSCRIPTION:
			return 'subscription';

		default:
			return 'contract';
	}
};

export const statusByContractType = (contractData: Contract) => {
	switch (contractData.contractType) {
		case ContractType.ENTERPRISE:
		case ContractType.RESELLER:
			return partnersAgreementColor(contractData);
		case ContractType.SUBSCRIPTION:
		case ContractType.REFERRAL:
			return subscriptionStateColor(contractData);

		default:
			return contractStateColor(contractData);
	}
};
