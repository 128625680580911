import { UserPermissions } from '@received/pricing-model';
import { NodeEnvTypes, RoutesType } from '../types/generalTypes';
import { allProtectedRoutes } from './protectedRoutes/_allProtectedRoutes';

export const protectedRoutes = (licenses: UserPermissions[]): RoutesType[] => {
	if (window._env_.REACT_APP_NODE_ENV === NodeEnvTypes.DEV) {
		return allProtectedRoutes;
	}
	let routeLicense = licenses.filter((license) => [UserPermissions.BILLING, UserPermissions.PARTNERS].includes(license));
	if (!routeLicense.length) {
		routeLicense = [UserPermissions.BILLING];
	}

	const filteredSubLinks = allProtectedRoutes.reduce((prevRoute: RoutesType[], currentRoute) => {
		if (
			currentRoute?.licenses?.length &&
			!currentRoute?.subElements?.length &&
			!currentRoute?.licenses?.every((permission) => routeLicense.includes(permission))
		) {
			return [...prevRoute];
		}

		if (currentRoute?.subElements?.length) {
			const temp = currentRoute?.subElements.filter((item) =>
				item.licenses?.length ? item?.licenses.every((permission) => routeLicense.includes(permission)) : true,
			);
			if (temp.length) {
				return [...prevRoute, { ...currentRoute, subElements: temp }];
			}
			return [...prevRoute];
		}

		return [...prevRoute, currentRoute];
	}, []);

	return filteredSubLinks;
};
