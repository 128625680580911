import clsx from 'clsx';
import { Icon, IconImgType, TextWithTooltip } from '../../../components';
import { colors } from '../../../constants/colors';
import styles from './AnalyticCard.module.scss';

interface AnalyticCardStyles {
	container?: string;
	title?: string;
	quantity?: string;
	descriptionContainer?: string;
	icon?: string;
}
interface AnalyticCardProps {
	title: string;
	quantity: string | number;
	description?: string;
	subQuantity?: number;
	classNames?: AnalyticCardStyles;
	iconProps?: {
		imgType: IconImgType;
		width: number;
	};
}

export const AnalyticCard = ({
	title,
	quantity,
	description,
	subQuantity,
	classNames,
	iconProps = { imgType: 'arrow_back', width: 1 },
}: AnalyticCardProps) => {
	return (
		<div className={clsx(styles.container, classNames?.container)}>
			<span className={clsx(styles.title, classNames?.title)}>{title}</span>
			<span className={clsx(styles.quantity, classNames?.quantity)}>{quantity}</span>

			<div
				className={clsx(styles.descriptionContainer, classNames?.descriptionContainer)}
				style={{ color: subQuantity == 0 ? colors.neutral400 : subQuantity && subQuantity > 0 ? colors.success : colors.destructive }}
			>
				{!!subQuantity && subQuantity != 0 && (
					<Icon
						imgType={iconProps.imgType}
						width={iconProps.width}
						className={clsx(classNames?.icon, subQuantity > 0 ? styles.up : styles.down)}
						color={subQuantity > 0 ? 'success' : 'destructive'}
					/>
				)}
				{description && <TextWithTooltip className={styles.subText}>{description}</TextWithTooltip>}
			</div>
		</div>
	);
};
