import { DocumentState } from '@received/pricing-model';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../../hooks';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { CreditNoteView, CreditNoteViewsResponse } from '../../../../types/creditNoteTypes';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { EmptyStateCard, SearchBar } from '../../../_uiComponents';
import { SidebarToOpen, SidebarType } from '../../Sidebars.utils';
import { Contract } from '../../../../types/contractTypes';
import { CreditNoteListItem } from './CreditNoteListItem/CreditNoteListItem';
import { Invoice } from '../../../../types/invoiceTypes';
import { images } from '../../../../constants/images';
import styles from './CreditNotesSidebarTab.module.scss';

interface CreditNotesSidebarTabProps {
	invoiceData?: Invoice;
	contractData?: Contract;
	selectedRowId?: string;
	sidebarType?: SidebarType;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	onDataChange: () => void;
}

export const CreditNotesSidebarTab = ({
	invoiceData,
	sidebarType,
	contractData,
	selectedRowId,
	openOtherSidebar,
	onDataChange,
}: CreditNotesSidebarTabProps) => {
	const [creditNotesViews, setCreditNotesViews] = useState<CreditNoteView[]>([]);
	const [searchText, setSearchText] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [listCount, setListCount] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		setCreditNotes();
	}, [invoiceData, contractData]);

	const setCreditNotes = async (search?: string, page?: number) => {
		try {
			if (invoiceData || contractData) {
				const res: CreditNoteViewsResponse = await getCreditNotesByFilter(search, page);
				setCreditNotesViews(res.creditNotes || []);
				setIsEmpty(res.creditNotes.length === 0);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTE }));
		}
	};

	const getCreditNotesByFilter = async (search?: string, page?: number) => {
		const creditNotes: CreditNoteViewsResponse = (
			await httpService({
				dispatch,
				path: PathsConfig.getCreditNotesViews,
				params: {
					pageSize: 20,
					pageNumber: page || pageNumber,
					orderBy: 'issueDate',
					order: 'ASC',
					documentNumber: search,
					invoiceId: sidebarType == SidebarType.INVOICE ? invoiceData?.id : undefined,
					customerId: invoiceData?.customerId || contractData?.customerId,
					contractId: sidebarType == SidebarType.CONTRACT ? contractData?.id : undefined,
					excludedStates: [DocumentState.CONTRACT_DRAFT],
				},
			})
		).data;

		setListCount(creditNotes.totalCount);
		return creditNotes;
	};

	const onSearchCreditNote = (text: string) => {
		setSearchText(text);
		onSearch(text);
	};

	const onScroll = async (event: any) => {
		const bottom = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight + 15;

		if (bottom) {
			if ((invoiceData || contractData) && creditNotesViews?.length < listCount) {
				try {
					setPageNumber((prev) => prev + 1);
					const res: CreditNoteViewsResponse = await getCreditNotesByFilter(searchText, pageNumber + 1);

					setCreditNotesViews((prev) => [...prev, ...res.creditNotes]);
				} catch (error) {
					dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTE }));
				}
			}
		}
	};

	const onSearch = useDebounce(setCreditNotes, 650);

	return (
		<div className={styles.container}>
			<div className={styles.filtersContainer}>
				<SearchBar placeholder={t('searchById')} className={styles.searchBar} value={searchText} onChange={onSearchCreditNote} />
			</div>
			{!isEmpty ? (
				<>
					<div className={styles.header}>
						<div className={styles.headerItem}>{t('creditNo')}</div>
						<div className={styles.headerItem}>{t('issueDate')}</div>
						<div className={styles.headerItem}>{t('creditTotal')}</div>
						<div className={styles.headerItem}>{t('status')}</div>
					</div>
					<div className={styles.listContainer} onScroll={onScroll}>
						{creditNotesViews?.map((creditNoteView, index) => (
							<CreditNoteListItem
								key={index}
								isActive={selectedRowId == creditNoteView?.id}
								creditNoteView={creditNoteView}
								onRowPress={(id: string) => openOtherSidebar({ sidebarType: SidebarType.CREDIT_NOTE, triggeredBy: SidebarType.INVOICE, id })}
								onDataChange={() => {
									setCreditNotes();
									onDataChange();
								}}
							/>
						))}
					</div>
				</>
			) : (
				<EmptyStateCard src={images.invoiceTableEmpty} title={t('noDataSource')} subTitle={t('noDataFoundDesc')} containerStyle={styles.emptyState} />
			)}
		</div>
	);
};
