import {
	DocumentEditBlocker,
	CreditNoteReasonCode,
	CreditState,
	Currency,
	AccountingEventType,
	InvoiceCredit,
	UsageProduct,
	SyncStatusDto,
	NetTerms,
} from '@received/pricing-model';
import { Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { BillingDetails, PricingModel } from './contractTypes';
import { Customer } from './customerTypes';
import { UsageReportView } from './usageTypes';

export class CreditNote {
	id?: string;
	customerId?: string;
	customer?: Customer;
	invoiceIds?: string[];
	pricingModelId?: string;
	@Type(() => PricingModel)
	pricingModel: PricingModel;
	currency: Currency;
	state: CreditState;
	syncStatus?: SyncStatusDto[];
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	paidDate?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	createdAt?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	issueDate?: Date;
	draftNumber?: string;
	draftNumberPrefix?: string;
	reasonCode?: CreditNoteReasonCode;
	isDraft: boolean;
	documentNumber: string;
	total?: number;
	totalTax?: number;
	tax?: number;
	subTotal?: number;
	balance: number;
	creditsAmount?: number;
	invoiceCredits?: InvoiceCredit[];
	contractPricingModelTabIds?: string[];
	isCollective?: boolean;
	poNumber?: string;
	postingDate?: Date;
	accountingEventType?: AccountingEventType;
	totalInSeries?: number;
	numberInSeries?: number;
	editBlockers?: DocumentEditBlocker[];
	netTerms?: NetTerms;
	supplierId?: string;
	billingDetails?: BillingDetails;
	contractId?: string;
	usageReportsByProduct?: any[];
	contractNumber?: string;
	relatedUsageProducts?: UsageProduct[];
	usageReports?: UsageReportView[];
	hasUsageReports?: boolean;
	index?: number;
}

export interface CreditNoteView {
	id: string;
	documentNumber: string;
	state?: CreditState;
	reasonCode: CreditNoteReasonCode;
	creditsAmount: string;
	customerName: string;
	creditsBalance: number;
	usedCredit: number;
	invoiceIds: string[];
	createdOn?: Date;
	issueDate: Date;
	poNumber?: string;
	contractId?: string;
	draftNumber?: string;
	currency: Currency;
	contractCreditNoteNumber?: number;
	contractCreditNoteCount?: number;
	accountingEventType: AccountingEventType;
	customerEmail?: string;
	customerIconData?: string;
	contractDocumentNumber?: string;
	contractDocumentsCount?: string;
	invoiceCredits?: string;
	usageReports?: UsageReportView[];
	hasUsageReports?: boolean;
}

export interface CreditNoteViewsResponse {
	totalCount: number;
	creditNotes: CreditNoteView[];
}

export interface AllCreditNoteSummary {
	missingDataCount: number;
	missingDataSum: number;
	readyForSendingCount: number;
	readyForSendingSum: number;
	paidPaymentsSum: number;
	paidPaymentsCount: number;
	canceledCount: number;
	canceledSum: number;
	adjCreditsBalance: number;
	adjCreditsCount: number;
}
