import clsx from 'clsx';
import { AccountingEventType, InvoiceCredit, CryptoSettings } from '@received/pricing-model';
import { PricingSummary, CreditNotePricingSummary, PricingTerms, ReferralFeeSettings } from '..';
import { BillingDetails, PricingModelTab } from '../../types/contractTypes';
import { CreditNoteSettings } from '../CreditNoteSettings/CreditNoteSettings';
import styles from './TermsAndSettings.module.scss';

export interface UpdateCreditNoteSettings {
	note?: string;
	supplierId?: string;
	billingDetails?: BillingDetails;
}

export interface UpdatePricingSummary {
	tax: number;
	discount: number;
}
export interface UpdatePricingTerms {
	supplierId?: string;
	note?: string;
	billingDetails?: BillingDetails;
	cryptoSettings?: CryptoSettings;
}

interface TermsAndSettingsProps {
	pricingModelTab?: PricingModelTab;
	disabled?: boolean;
	customerId?: string;
	invoiceCredits?: InvoiceCredit[];
	creditsAmount?: number;
	updatePricingSummaryData: ({ tax, discount }: UpdatePricingSummary) => void;
	updatePricingTermsData: ({ note, supplierId, billingDetails, cryptoSettings }: UpdatePricingTerms, instantUpdate?: boolean) => void;
	updateCreditSettings: ({ note, supplierId, billingDetails }: UpdateCreditNoteSettings) => void;
}

export const TermsAndSettings = ({
	pricingModelTab,
	disabled,
	customerId,
	invoiceCredits,
	creditsAmount,
	updatePricingTermsData,
	updatePricingSummaryData,
	updateCreditSettings,
}: TermsAndSettingsProps) => {
	const settings = () => {
		switch (pricingModelTab?.accountingEventType) {
			case AccountingEventType.CREDIT: {
				return (
					<>
						<CreditNoteSettings
							pricingModelTab={pricingModelTab}
							className={clsx(styles.innerBorders, disabled && styles.notEditable)}
							updateCreditSettings={updateCreditSettings}
						/>
						{pricingModelTab && (
							<CreditNotePricingSummary
								pricingModelTab={pricingModelTab}
								updatePricingSummaryData={updatePricingSummaryData}
								className={clsx(styles.innerBorders, disabled && styles.notEditable)}
								invoiceCredits={invoiceCredits}
							/>
						)}
					</>
				);
			}
			case AccountingEventType.PAYOUT: {
				return (
					<>
						<ReferralFeeSettings
							pricingModelTab={pricingModelTab}
							className={clsx(styles.innerBorders, disabled && styles.notEditable)}
							updateCreditSettings={updateCreditSettings}
						/>
						{pricingModelTab && (
							<CreditNotePricingSummary
								pricingModelTab={pricingModelTab}
								updatePricingSummaryData={updatePricingSummaryData}
								className={clsx(styles.innerBorders, disabled && styles.notEditable)}
								invoiceCredits={invoiceCredits}
							/>
						)}
					</>
				);
			}
			default: {
				return (
					<>
						<PricingTerms
							disabled={disabled}
							className={clsx(styles.innerBorders, disabled && styles.notEditable)}
							pricingModelTab={pricingModelTab}
							customerId={customerId}
							updatePricingTermsData={updatePricingTermsData}
						/>
						{pricingModelTab && (
							<PricingSummary
								pricingModelTab={pricingModelTab}
								updatePricingSummaryData={updatePricingSummaryData}
								className={clsx(styles.innerBorders, disabled && styles.notEditable)}
								creditsAmount={creditsAmount}
							/>
						)}
					</>
				);
			}
		}
	};

	return (
		<>
			<div className={styles.container}>{settings()}</div>
		</>
	);
};
