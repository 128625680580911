import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ItemMatcher, ItemMatcherListHeader } from '../../../../../components';
import { IntegrationExportData, IntegrationMatchedObjects } from '../../../../../types/integrationTypes';
import styles from './ExportItems.module.scss';

interface ExportItemsProps {
	title: string;
	subTitle: string;
	isSyncing?: boolean;
	itemMatcherHeader: { rightLisHeader: ItemMatcherListHeader; leftListHeader: ItemMatcherListHeader };
	exportItems?: IntegrationExportData;
	onUpdateExportItems?(matchedObjects: IntegrationMatchedObjects[], syncStatusId: string): void;
	onRefresh?(): void;
	onDeleteRow(index: number): void;
}

export const ExportItems = ({
	title,
	subTitle,
	itemMatcherHeader,
	exportItems,
	isSyncing,
	onUpdateExportItems,
	onRefresh,
	onDeleteRow,
}: ExportItemsProps) => {
	const { t } = useTranslation('translation');

	return (
		<div>
			<div className={styles.header}>
				<div className={styles.fontSize24}>{title}</div>
				<div className={styles.subTitle}>{subTitle}</div>
			</div>

			{exportItems && (
				<ItemMatcher
					{...itemMatcherHeader}
					onRefresh={onRefresh}
					isSyncing={isSyncing}
					exportList={exportItems?.matchedObjects}
					rightList={exportItems.remoteObjects.map((item) => ({ label: item.name, value: item }))}
					leftList={exportItems.localObjects.map((item) => ({
						label: item.name,
						value: item,
						isDisabled: exportItems.matchedObjects.map((item) => item.domainObject?.originalObject?.id).includes(item.originalObject.id),
						customComponent: (i) => (
							<div className={styles.selectItem}>
								<div className={clsx(item?.isUsed ? styles.selectItemLabelWithTag : styles.selectItemLabel)}>{item.name}</div>
								{item?.isUsed && <div className={styles.tag}>{t('used')}</div>}
							</div>
						),
					}))}
					onUpdateExportItems={onUpdateExportItems}
					onDeleteRow={onDeleteRow}
				/>
			)}
		</div>
	);
};
