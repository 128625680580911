import dayjs from 'dayjs';
import { DATE_FORMAT } from '@received/pricing-model';
import { Icon } from '../../../../../components';
import { colors } from '../../../../../constants/colors';
import { billingCycleLabelsByEnum } from '../../../../../constants/generalConstants';
import { PricingModel } from '../../../../../types/contractTypes';
import styles from './PricingTable.module.scss';

export const pricingTableHeader = [
	{
		headerTitle: 'pricingName',
		CustomComponent: (item: PricingModel) => (
			<div className={styles.text}>
				<div className={styles.itemIconContainer}>
					<Icon imgType={item.isFavorite ? 'star' : 'subscription_template'} color={item.isFavorite ? 'black' : 'neutral'} height={2} />
				</div>
				<div className={styles.nameInTable}>{item.name}</div>
			</div>
		),
	},
	{
		headerTitle: 'product',
		CustomComponent: (item: PricingModel) => <div className={styles.itemTag}>{item.product?.name}</div>,
	},
	{
		headerTitle: 'pricingCycle',
		CustomComponent: (item: PricingModel) => (
			<div className={styles.itemTag} style={{ borderColor: colors.neutral200, color: colors.neutral700 }}>
				{billingCycleLabelsByEnum[item.tabs[0].billingCycle?.unit]}
			</div>
		),
	},
	{
		headerTitle: 'customers',
		CustomComponent: (item: PricingModel) => <div></div>,
	},
	{
		headerTitle: 'contracts',
		CustomComponent: (item: PricingModel) => <div></div>,
	},
	{
		headerTitle: 'cashflow',
		CustomComponent: (item: PricingModel) => <div></div>,
	},
	{
		headerTitle: 'lastPaid',
		CustomComponent: (item: PricingModel) => <div className={styles.text}>{dayjs(item?.updatedAt).format(DATE_FORMAT)}</div>,
	},
	{
		headerTitle: 'status',
		CustomComponent: (item: PricingModel) => (
			<div
				className={styles.itemTag}
				style={
					item.isArchived
						? { borderColor: colors.neutral200, color: colors.neutral700 }
						: { borderColor: colors.success50, backgroundColor: colors.success50, color: colors.success600 }
				}
			>
				{item.isArchived ? 'Archived' : 'Active'}
			</div>
		),
	},
];
