import React, { useEffect, useState, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ActionModalModal, CatalogModal, CategoriesTabs, CategoriesTabsItem, MenuOption } from '../../../../components';
import { ProductAndCategoriesItem } from '../../../../types/ProductTypes';
import { httpService } from '../../../../services/httpService/httpService';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PricingModel } from '../../../../types/contractTypes';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { PricingTable } from './PricingTable/PricingTable';
import {
	filterCatalogPricingModels,
	filterProductsWithCatalogPricingModels,
	onDuplicateCurrentModel,
	pricingFilter,
} from '../../PricingModels.utils';
import { EmptyPricingModel } from '../../../../components/ContractAndPricingModelContainer/EmptyPricingModel/EmptyPricingModel';
import { BLANK_PRICING_MODEL_ID } from '../../../../constants/templateConstants';
import { getPricingModel } from '../../../../utils/pricingModelUtils';
import { AddProductModal } from '../../AddProductModal/AddProductModal';
import { images } from '../../../../constants/images';
import styles from './MyProductsTab.module.scss';

interface MyProductsTabProps {
	isUsedAsComponent?: boolean;
	selectedFilter: pricingFilter | '';
	searchText: string;
	createdProductId: string;
	selectedProduct: CategoriesTabsItem;
	setSelectedProduct: React.Dispatch<SetStateAction<CategoriesTabsItem | undefined>>;
	navigateToNewModel?(data: any): void;
	onAddPricingModel?(): void;
}

export const MyProductsTab = ({
	selectedFilter,
	searchText,
	createdProductId,
	selectedProduct,
	setSelectedProduct,
	navigateToNewModel,
	onAddPricingModel,
}: MyProductsTabProps) => {
	const { t } = useTranslation('translation');
	const [selectedList, setSelectedList] = useState<PricingModel[]>([]);
	const [myProductsList, setMyProductsList] = useState<ProductAndCategoriesItem[]>([]);
	const [allProductsList, setAllProductsList] = useState<PricingModel[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedPricingModel, setSelectedPricingModel] = useState<PricingModel>();
	const [openCatalogModal, setOpenCatalogModal] = useState(false);
	const [selectedProductForAction, setSelectedProductForAction] = useState<CategoriesTabsItem>();
	const [openProductModal, setOpenProductModal] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		getMyProducts(createdProductId);
	}, [createdProductId]);

	const getMyProducts = async (productId?: string) => {
		try {
			const res: ProductAndCategoriesItem[] = (
				await httpService({
					dispatch,
					path: PathsConfig.myProductsList,
				})
			).data;

			let allModels: PricingModel[] = [];
			const templates = res.map((product) => {
				allModels = [...allModels, ...product.pricingModels];
				return {
					...product,
					count: product.pricingModels.length,
				};
			});

			const selected = templates.find((product) => product.id === productId);
			const list = productId ? selected?.pricingModels || allModels : allModels;

			setSelectedProduct(selected);
			setMyProductsList(templates);
			setAllProductsList(allModels);
			setSelectedList(list);
			if (list.length) {
				setSelectedPricingModel(list[0]);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_PRODUCTS }));
		}
	};

	const onSelectProduct = (selected: ProductAndCategoriesItem) => {
		const selectedList = selected ? selected.pricingModels : allProductsList;
		setSelectedList(selectedList);
		setSelectedPricingModel(selectedList[0]);
		setSelectedProduct(selected);
	};

	const createModelFromBlank = async () => {
		const blankPricingModel = await getPricingModel(BLANK_PRICING_MODEL_ID, dispatch);
		const modelData = { productId: selectedProduct?.id, productName: selectedProduct?.name, selectedPricingModel: blankPricingModel };
		navigateToNewModel
			? navigateToNewModel(modelData)
			: navigate('/pricing-models/new-pricing-model', {
					state: modelData,
			  });
	};

	const onUseThisPricing = (model: PricingModel) => {
		const modelData = {
			productId: selectedProduct?.id,
			productName: selectedProduct?.name,
			selectedPricingModel: { ...model, id: undefined, product: undefined },
		};

		navigateToNewModel
			? navigateToNewModel(modelData)
			: navigate('/pricing-models/new-pricing-model', {
					state: modelData,
			  });
	};

	const onDuplicateProduct = async (item: CategoriesTabsItem) => {
		await onDuplicateCurrentModel(item.id, dispatch);
		getMyProducts(item.id);
	};

	const onEditProduct = (item: CategoriesTabsItem) => {
		setSelectedProductForAction(item);
		setOpenProductModal(true);
	};

	const onDeleteProduct = async () => {
		try {
			setIsDeleteModalOpen(false);
			setSelectedProductForAction(undefined);
			await httpService({
				dispatch,
				path: PathsConfig.onDeleteProduct,
				urlParam: { id: selectedProductForAction?.id || '' },
			});

			await getMyProducts();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.DELETE_PRODUCT }));
		}
	};

	const menuOptions = (item: CategoriesTabsItem): MenuOption[] => [
		{ title: t('editProduct'), onPress: () => onEditProduct(item), iconType: 'edit' },
		{ title: t('duplicateProduct'), onPress: () => onDuplicateProduct(item), iconType: 'duplicate' },
		{
			title: t('deleteProduct'),
			onPress: () => {
				setSelectedProductForAction(item);
				setIsDeleteModalOpen(true);
			},
			iconType: 'delete',
			color: 'destructive',
		},
	];

	const onPressUseThisPricing = () => {
		const modelData = { productId: selectedPricingModel?.id, productName: selectedPricingModel?.name, pricingModelId: selectedPricingModel?.id };
		navigateToNewModel
			? navigateToNewModel(modelData)
			: navigate('/pricing-models/new-pricing-model', {
					state: modelData,
			  });
	};

	return (
		<div className={styles.container}>
			<div className={styles.categoriesContainer}>
				<CategoriesTabs
					title={t('products')}
					allTitle={t('allProducts')}
					tabsList={filterProductsWithCatalogPricingModels(selectedFilter, myProductsList)}
					menuOptions={menuOptions}
					allCount={filterCatalogPricingModels(selectedFilter, allProductsList, searchText).length}
					defaultSelectedTab={selectedProduct}
					onClickAdd={onAddPricingModel}
					onSelectTab={onSelectProduct}
				/>
			</div>

			<div className={styles.tableContainer}>
				{selectedList.length ? (
					<PricingTable
						list={filterCatalogPricingModels(selectedFilter, selectedList, searchText)}
						selectedModel={selectedPricingModel}
						selectPricing={setSelectedPricingModel}
						onRefreshTable={() => getMyProducts(selectedProduct?.id)}
						onClosePreview={() => setSelectedPricingModel(undefined)}
						onUseThisPricing={onPressUseThisPricing}
					/>
				) : (
					<EmptyPricingModel
						title='createPricing'
						description='addPricingDescription'
						subTitle='createFromCommon'
						pricingModelsNumber={3}
						startBlank={createModelFromBlank}
						openPricingModelCatalog={() => setOpenCatalogModal(true)}
						addModel={onUseThisPricing}
						className={styles.emptyPricingModel}
					/>
				)}
			</div>

			<CatalogModal
				startFromProducts
				openModal={openCatalogModal}
				setOpenModal={(isOpen) => {
					setOpenCatalogModal(isOpen);
				}}
				onUseThisPricing={onUseThisPricing}
			/>

			<ActionModalModal
				rightButton={{ title: 'delete', color: 'destructive' }}
				imagesOptions={images.deleteItem}
				isOpen={isDeleteModalOpen}
				deleteMessage={
					<>
						{t('deleteMessagePrefix').replace('__', 'product')}
						<span className={styles.name}> {selectedProduct?.name}</span> {t('deleteMessagePostfix').replace('__', 'product')}
					</>
				}
				onAccept={() => onDeleteProduct()}
				onClose={() => {
					setIsDeleteModalOpen(false);
					setSelectedProductForAction(undefined);
				}}
			/>
			<AddProductModal
				isOpen={openProductModal}
				editProductData={selectedProductForAction}
				onProductAdded={() => {
					setOpenProductModal(false);
					setSelectedProductForAction(undefined);
					getMyProducts();
				}}
				closeModal={() => {
					setOpenProductModal(false);
					setSelectedProductForAction(undefined);
				}}
			/>
		</div>
	);
};
