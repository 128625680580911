import { useState } from 'react';
import clsx from 'clsx';
import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { MenuOption, MenuList, Button, Icon } from '../../../../_uiComponents';
import { SHORT_YEAR_FORMAT } from '../../../../../constants/templateConstants';
import { numberWithCommas } from '../../../../../utils/NumberUtils';
import { UsageReportView } from '../../../../../types/usageTypes';
import { getDocumentIdNameByType } from '../../../../../utils/GeneralUtils';
import { isUsageItemCanBeDeleted } from './UsageListItem.utils';
import { downloadUsageLogs } from '../../../../../pages/Usage/Usage.utils';
import styles from './UsageListItem.module.scss';

interface UsageListItemProps {
	usageReportView: UsageReportView;
	isUsageReportActive: boolean;
	isContractSideBar?: boolean;
	index: number;
	onRowPress: (usageReport: UsageReportView) => void;
	onDelete: (usageReportId: string) => void;
	dispatch: Dispatch;
}

export const UsageListItem = ({
	isUsageReportActive,
	usageReportView,
	isContractSideBar,
	index,
	onRowPress,
	onDelete,
	dispatch,
}: UsageListItemProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const { t } = useTranslation('translation');

	const menuOptions = () => {
		let rowOptions: MenuOption[] = [];

		if (isUsageItemCanBeDeleted(usageReportView)) {
			rowOptions = [
				{
					title: t('downloadUsageReport'),
					onPress: () => downloadUsageLogs([usageReportView.usageReportId], dispatch),
					iconType: 'download',
				},
				{
					title: t('delete'),
					onPress: () => onDelete(usageReportView.usageReportId),
					iconType: 'delete',
					color: 'destructive',
				},
			];
		}

		return rowOptions;
	};

	return (
		<div
			className={clsx(styles.item, isUsageReportActive ? styles.activeItem : '')}
			onClick={() => onRowPress(usageReportView)}
			data-testid={`usage-report-row-${usageReportView?.documentId}`}
		>
			<div className={styles.periodCell} data-testid={`cell-document-period-${index}`}>
				<span className={styles.itemCellValue} data-testid={`document-period-date-${index}`}>{`${dayjs(usageReportView.startDate).format(
					SHORT_YEAR_FORMAT,
				)} - ${dayjs(usageReportView.endDate).format(SHORT_YEAR_FORMAT)}`}</span>

				{isContractSideBar ? (
					<span className={styles.itemCellSubValue} data-testid={`document-id-${index}`}>
						{getDocumentIdNameByType(usageReportView)} ({usageReportView?.contractDocumentNumber}/{usageReportView?.contractDocumentsCount})
					</span>
				) : (
					<span className={styles.itemCellSubValue}>{usageReportView?.subActivity}</span>
				)}
			</div>
			<div className={styles.usageProductCell} data-testid={`cell-product-name-${index}`}>
				<div className={styles.usageEllipsis}>{usageReportView.usageProductName}</div>
			</div>
			<div className={styles.quantityCell}>{`${numberWithCommas(usageReportView.quantity)} ${usageReportView.countedUnit}`}</div>

			{!!menuOptions().length && (
				<div className={styles.hamburgerContainer} data-testid={`cell-menu-options-${index}`}>
					<MenuList optionsList={menuOptions()} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
						<Button type='link' color='neutral' onClick={() => setOpenMenuOptions(!openMenuOptions)}>
							<Icon imgType='hamburger' className={clsx(styles.hamburgerButton, openMenuOptions && styles.hamburgerRotate)} color='neutral700' />
						</Button>
					</MenuList>
				</div>
			)}
		</div>
	);
};
