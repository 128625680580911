import { ContractType } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Button, MenuOption, QuantityCardProps } from '../../../components';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { Cell, LogoCell, PriceCell, StatusCol, TotalActiveCell } from '../../../components/_uiComponents/Table/Table.utils';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Customer, CustomerSummary, CustomerView } from '../../../types/customerTypes';
import { ErrorSuccessModalTypes } from '../../../types/generalTypes';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { formatEmailList } from '../../../utils/UserUtils';
import styles from './Customers.module.scss';

export const statusColor = (statusSeverity: string) => {
	return statusSeverity === 'HIGH' ? 'destructive' : statusSeverity === 'MEDIUM' ? 'warning' : 'neutral';
};

export const customerTableHeader = (t: any, callback: (id: string) => void, isSelfServed?: boolean) => [
	{
		headerTitle: 'customerName',
		accessor: 'name',
		subAccessor: 'email',
		isSortable: true,
		CustomComponent: (item: CustomerView) => {
			return isSelfServed ? (
				<Cell accessor={item.name} />
			) : (
				<LogoCell title={item.name} subTitle={formatEmailList(item.email)} logoData={item.iconData} cellSyncStatus={getSyncStatus(item)} />
			);
		},
	},
	{
		headerTitle: 'contracts',
		accessor: 'totalContracts',
		CustomComponent: (item: CustomerView) => {
			return isSelfServed ? (
				<Cell accessor={`${item?.activeContracts || '0'} ${t('active')}`} />
			) : (
				<TotalActiveCell total={item?.totalContracts} active={item?.activeContracts} numberOfImportedContracts={item.numberOfImportedContracts} />
			);
		},
	},
	{
		headerTitle: 'pendingPayments',
		accessor: 'pendingPaymentsAmount',
		isSortable: true,
		CustomComponent: (item: CustomerView) => <PriceCell mainNumber={item?.pendingPaymentsAmount} />,
	},
	{
		headerTitle: 'latePayments',
		accessor: 'delayedPaymentsAmount',
		isSortable: true,
		CustomComponent: (item: CustomerView) => <PriceCell mainNumber={item?.delayedPaymentsAmount} />,
	},
	{
		headerTitle: 'paid',
		accessor: 'totalPaymentsAmount',
		isSortable: true,
		CustomComponent: (item: CustomerView) => <PriceCell mainNumber={item?.totalPaymentsAmount} />,
	},
	{
		headerTitle: 'lastStatus',
		accessor: 'status',
		CustomComponent: (item: CustomerView) => {
			if (!item.totalContracts)
				return <Button className={styles.createContract} type='outlined' onClick={() => callback(item.id)}>{`${t('createContract')} +`}</Button>;

			if (!item.delayedPaymentsAmount && !item.pendingPaymentsAmount && !+item.missingDataCount && !+item?.readyForSendingCount)
				return <StatusCol color='neutral'>{t('greatJobAllDone')}</StatusCol>;
			return (
				<div className={styles.badges}>
					{(!!+item.readyForSendingCount || !!+item.missingDataCount) && (
						<StatusCol color='warning'>{`${+item?.readyForSendingCount + +item?.missingDataCount} ${t('review')}`}</StatusCol>
					)}
					{!!+item.pendingPaymentsCount && <StatusCol color='primary'>{`${item.pendingPaymentsCount} ${t('sent')}`}</StatusCol>}
					{!!+item.delayedPaymentsCount && <StatusCol color='destructive'>{`${item.delayedPaymentsCount} ${t('late')}`}</StatusCol>}
				</div>
			);
		},
	},
];

export const customerOptionList = (
	row: CustomerView,
	dispatch: Dispatch,
	onEditCustomer: (customer: Customer) => void,
	onPressDelete: (id: string, name: string) => void,
): MenuOption[] => {
	const canBeDeleted = !row.delayedPaymentsAmount && !row.pendingPaymentsAmount && !row.totalPaymentsAmount;
	return [
		{ title: 'Edit Customer', onPress: () => getCustomerById(row.id, dispatch, onEditCustomer), iconType: 'edit' },
		{
			title: 'Delete Customer',
			onPress: () =>
				canBeDeleted
					? onPressDelete(row.id, row.name)
					: dispatch(
							setOpenSuccessErrorModal({
								customTitle: 'cantDeleteCustomer',
								modalType: ErrorSuccessModalTypes.CANT_DELETE,
								successErrorMassage: 'cantDeleteCustomerDescription',
							}),
					  ),
			iconType: 'delete',
			color: 'destructive',
		},
	];
};

export const deleteCustomer = async (id: string, dispatch: Dispatch, onRefreshData: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteCustomer,
			urlParam: {
				id,
			},
		});

		onRefreshData();
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CUSTOMERS }));
	}
};

export const getCustomerById = async (id: string, dispatch: Dispatch, callback?: (customer: Customer) => void) => {
	try {
		const customer = (
			await httpService({
				dispatch,
				path: PathsConfig.getCustomerData,
				urlParam: { id },
			})
		).data;
		callback && callback(customer);
		return customer;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_BY_ID }));
	}
};

export const getCustomerSummaryData = async (
	dispatch: Dispatch,
	fromDate: Date | string,
	toDate: Date | string,
	contractType: ContractType[],
	customerIds?: string[],
	textFilter?: string,
) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getCustomersSummary,
			params: { fromDate, toDate, contractType, customerIds: customerIds || undefined, textFilter: textFilter || undefined },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};

export const quantityBarData = (t: any, statusBarData?: CustomerSummary): QuantityCardProps[] => {
	return [
		{
			title: t('activeCustomers'),
			description: statusBarData?.activeCustomersMoM ? `${Math.abs(Math.round(statusBarData?.activeCustomersMoM))}% MoM` : '',
			quantity: statusBarData?.activeCustomers || 0,
			subQuantity: statusBarData?.activeCustomersMoM,
			icon: 'customersEmpty',
			descriptionClassName: styles.cardText,
		},
		{
			title: t('activeContracts'),
			description: statusBarData?.activeContractsMom ? `${Math.abs(Math.round(statusBarData?.activeContractsMom))}% MoM` : '',
			quantity: statusBarData?.activeContracts || 0,
			subQuantity: statusBarData?.activeContractsMom,
			icon: 'book',
			descriptionClassName: styles.cardText,
		},
		{
			title: t('contractsRenewals'),
			description: statusBarData?.contractRenewals ? t('dueWithin60Days') : '',
			quantity: statusBarData?.contractRenewals || 0,
			icon: 'clock',
			descriptionClassName: styles.cardText,
		},
		{
			title: t('contractsReadyForReview'),
			quantity: statusBarData?.contractsForReview || 0,
			icon: 'refresh',
		},
	];
};
