import { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { convertCurrencyToSign } from '@received/pricing-model';
import { useDispatch } from 'react-redux';
import { MenuList, Button, Icon } from '../../../../_uiComponents';
import { getValueWithCurrencySign } from '../../../../../utils/NumberUtils';
import { PayoutView } from '../../../../../types/PayoutsTypes';
import { payoutsMenuOptions } from '../../../../../pages/_Billing/Payouts/Payouts.utils';
import { PayoutStatusTag } from '../../../../StatusTags';
import styles from './PayoutListItem.module.scss';

interface PayoutListItemProps {
	payout: PayoutView;
	isPayoutActive: boolean;
	index: number;
	onRowPress?: (payoutId: string) => void;
	onRefreshData?: () => void;
}

export const PayoutListItem = ({ isPayoutActive, payout, index, onRefreshData, onRowPress }: PayoutListItemProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	return (
		<div
			className={clsx(styles.item, isPayoutActive && styles.activeInvoice)}
			onClick={() => onRowPress?.(payout.id)}
			data-testid={`payout-row-${payout.id}`}
		>
			<div className={styles.cell} data-testid={`cell-document-type-${index}`}>
				{payout.payoutNumber}
				<span className={styles.itemCellSubValue}>{`${payout.payoutNumberInSeries}/${payout.payoutSeriesTotalCount}`}</span>
			</div>
			<div className={styles.cell} data-testid={`cell-issue-date-${index}`}>
				{dayjs(payout.issueDate).format('MMM DD, YYYY')}
			</div>
			<div className={styles.cell} data-testid={`cell-total-${index}`}>
				{getValueWithCurrencySign(payout.totalFee, convertCurrencyToSign(payout.currency))}
			</div>

			<div className={styles.cell} data-testid={`cell-status-${index}`}>
				<PayoutStatusTag document={payout} refreshData={onRefreshData} />
			</div>

			<div className={styles.hamburgerContainer} data-testid={`cell-menu-options-${index}`}>
				<MenuList
					optionsList={payoutsMenuOptions({
						t,
						payout,
						dispatch,
						navigate,
						onRefresh: () => {
							onRowPress?.(payout.id);
							onRefreshData?.();
						},
					})}
					isMenuOpen={openMenuOptions}
					openMenu={() => setOpenMenuOptions(false)}
				>
					<Button type='link' color='neutral' onClick={() => setOpenMenuOptions(!openMenuOptions)}>
						<Icon imgType='hamburger' className={styles.hamburgerButton} color='neutral700' />
					</Button>
				</MenuList>
			</div>
		</div>
	);
};
