import { useTranslation } from 'react-i18next';
import { Button, Icon } from '../_uiComponents';
import { images } from '../../constants/images';
import styles from './EmptyCatalogPreview.module.scss';

interface CatalogPreviewProps {
	addNewPricing?(): void;
}

export const EmptyCatalogPreview = ({ addNewPricing }: CatalogPreviewProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<div className={styles.emptyStateContainer}>
				<img alt='add-model-icon' src={images.addModel} className={styles.addModelIcon}></img>

				<div className={styles.emptyTitle}>{t('createPricing')}</div>
				<div className={styles.emptySubTitle}>{t('addPricingDescription')}</div>

				{addNewPricing && (
					<Button type='secondary' onClick={addNewPricing} className={styles.emptyStateButton}>
						{t('addNewPricing')}
						<Icon imgType='add' color='success' width={1.2} />
					</Button>
				)}
			</div>
		</div>
	);
};
