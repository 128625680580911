import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AccountingEventType, DocumentState } from '@received/pricing-model';
import { DateRangeFilters, InvoiceTable, ImportItemsProgress } from '../../../components';
import { EmptyStateCard, SummaryOverviewGraph } from '../../../components/_uiComponents';
import { lateInvoiceTableHeader, statusOverTimeDataHandler, invoiceUpcomingTableHeader, initialGraphState } from './Invoices.utils';
import { httpService, PathsConfig } from '../../../services';
import { DatesData, GraphState, Sort, SortOrder } from '../../../types/generalTypes';
import { InvoiceView, InvoiceSummary, InvoiceCashflow, summaryType } from '../../../types/invoiceTypes';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { InvoiceResponse } from './AllInvoices/AllInvoices.utils';
import { Sidebars } from '../../../components/Sidebars/Sidebars';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { StackBarGraph } from '../../../components/_uiComponents/Graphs/StackBarGraph/StackBarGraph';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../components/Filters/DateRangeFilters.utils';
import { Store } from '../../../types/storeTypes';
import { cashTimeUnitHandler } from '../../../utils/DateUtils';
import { getSummaryData, getSummaryBlocksData, getSortingOrderList } from '../../../utils/InvoiceUtils';
import { InvoicesForReviewCards, SummaryColorCards } from './components';
import { colors } from '../../../constants/colors';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import styles from './Invoices.module.scss';

export const Invoices = () => {
	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);

	const [invoiceUpcomingList, setInvoiceUpcomingList] = useState<InvoiceView[]>([]);
	const [invoiceLateList, setInvoiceLateList] = useState<InvoiceView[]>([]);
	const [readyForReviewInvoices, setReadyForReviewInvoices] = useState<InvoiceView[]>([]);
	const [upcomingDays, setUpcomingDays] = useState(30);
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [invoiceOverTime, setInvoiceOverTime] = useState<GraphState>(initialGraphState);
	const [invoicesSummary, setInvoiceSummary] = useState<InvoiceSummary>();
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [sortUpcoming, setSortUpcoming] = useState<Sort>({ orderBy: 'issueDate', order: SortOrder.ASC });
	const [sortLate, setSortLate] = useState<Sort>({ orderBy: undefined, order: SortOrder.ASC });

	const { t } = useTranslation('translation');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const numberOfItems = '10';

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		refreshData();
	}, [timePeriod]);

	useEffect(() => {
		getInvoicesUpcomingData();
	}, [sortUpcoming]);

	useEffect(() => {
		getLateInvoicesData();
	}, [sortLate]);

	useEffect(() => {
		getReadyForReviewInvoices();
	}, []);

	const refreshData = async () => {
		const results = await Promise.all([getInvoiceStatusOverTime(), getSummaryData(dispatch, timePeriod.from, timePeriod.to, undefined)]);
		setInvoiceSummary(results[1]);
	};

	const getLateInvoicesData = async () => {
		try {
			const lateInvoicesRes: InvoiceResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoicesViewData,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: 0,
						states: [DocumentState.LATE],
						orderBy: sortLate.orderBy || getSortingOrderList('dueDate'),
						order: sortLate.order,
					},
				})
			).data;

			!sortLate.orderBy && setSortLate((prev) => ({ ...prev, orderBy: getSortingOrderList('dueDate') }));
			setInvoiceLateList(lateInvoicesRes.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_INVOICE_VIEW }));
		}
	};

	const getReadyForReviewInvoices = async () => {
		try {
			const readyForReviewInvoices: InvoiceResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoicesViewData,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: 0,
						states: [DocumentState.REVIEW_READY_TO_SEND, DocumentState.REVIEW_MISSING_DATA, DocumentState.COLLECTING_INFO],
						orderBy: getSortingOrderList('totalDue'),
						order: SortOrder.DESC,
					},
				})
			).data;

			setReadyForReviewInvoices(readyForReviewInvoices.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_INVOICE_VIEW }));
		}
	};

	const getInvoicesUpcomingData = async () => {
		try {
			const upcomingInvoicesRes: InvoiceResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getDocumentsView,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: 0,
						states: [DocumentState.REVIEW_MISSING_DATA, DocumentState.REVIEW_READY_TO_SEND, DocumentState.DRAFT],
						orderBy: sortUpcoming.orderBy,
						order: sortUpcoming.order,
						fromIssueDate: timePeriod.from,
						toIssueDate: timePeriod.to,
					},
				})
			).data;

			setInvoiceUpcomingList(upcomingInvoicesRes.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_INVOICE_VIEW }));
		}
	};

	const getInvoiceStatusOverTime = async () => {
		try {
			const res: InvoiceCashflow[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoiceStatusOverTime,
					params: { fromDate: timePeriod.from, toDate: timePeriod.to, timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to) },
				})
			).data;

			const { dataForGraph, isEmpty, dataFormat } = statusOverTimeDataHandler(
				res,
				t,
				cashTimeUnitHandler(timePeriod.from, timePeriod.to),
				timePeriod.value,
			);
			setInvoiceOverTime({ data: dataForGraph, isEmpty, dataFormat });
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={refreshData} />
				<Sidebars openSideBarById={openSideBarById} onCloseSideBars={() => setOpenSideBarById(undefined)} onDataChange={refreshData} />

				<header className={styles.pageHeader}>
					<div>
						<h1>{t('overview')}</h1>
					</div>
					<div className={styles.buttonsContainer}>
						<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />
					</div>
				</header>

				<div className={styles.topAnalyticsContainer}>
					<SummaryColorCards
						summaryItems={getSummaryBlocksData(t, invoicesSummary)}
						className={styles.summaryColorCards}
						summaryBlockStyles={{ className: styles.summarySection, summaryIconClassName: styles.summarySectionIcon }}
						onClick={(summaryType: summaryType) => navigate('./all-invoices', { state: { summaryType } })}
					/>
					<InvoicesForReviewCards
						invoices={readyForReviewInvoices.slice(0, 6)}
						className={styles.InvoicesForReviewCards}
						onClick={(document) =>
							setOpenSideBarById({
								sideBarType: document?.accountingEventType == AccountingEventType.CREDIT ? SidebarType.CREDIT_NOTE : SidebarType.INVOICE,
								id: document.id,
							})
						}
					/>
				</div>

				<div className={styles.activityContainer}>
					<div className={styles.leftSide}>
						<div className={styles.leftTop}>
							<div className={styles.flexColumn}>
								<h2 className={styles.cardTitle}>{t('statusOverTime')}</h2>
								<span className={styles.cardSubTitle}>{`${timePeriod.title} ${t('byIssueDate')}`}</span>
							</div>

							<div className={styles.stackGraphContainer}>
								<StackBarGraph
									isCashflow
									dataFormat={invoiceOverTime.dataFormat}
									isEmpty={invoiceOverTime.isEmpty}
									chartData={{ labels: invoiceOverTime.data.labels, datasets: invoiceOverTime.data.datasets }}
								/>
							</div>
						</div>

						<InvoiceTable
							refreshData={refreshData}
							tableProps={{
								sort: sortUpcoming,
								hideTableFooter: true,
								title: t('next30Days'),
								titleClassName: styles.cardTitle,
								subTitle: `${t('until')} ${dayjs().add(upcomingDays, 'day').format(DATE_FORMAT)}`,
								header: invoiceUpcomingTableHeader(refreshData),
								rows: invoiceUpcomingList,
								className: styles.table,
								onRowPress: (data) =>
									setOpenSideBarById({
										sideBarType: data?.accountingEventType == AccountingEventType.CREDIT ? SidebarType.CREDIT_NOTE : SidebarType.INVOICE,
										id: data.id,
									}),
								onFlipOrder: (accessor) =>
									setSortUpcoming({
										orderBy: accessor,
										order: sortUpcoming.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
									}),
							}}
						/>
					</div>

					<div className={styles.rightSide}>
						<div className={styles.pieContainer}>
							<div className={styles.flexColumn}>
								<h2 className={styles.cardTitle}>{t('statusOverview')}</h2>
								<span className={styles.cardSubTitle}>{`${timePeriod.title} ${t('byIssueDate')}`}</span>
							</div>
							<div className={styles.pieGraphContainer}>
								<SummaryOverviewGraph
									emptyStateSubtitle={t('emptyStateSubtitle')}
									dataList={[
										{
											label: t('sent'),
											total: invoicesSummary?.pendingPaymentsSum || 0,
											count: invoicesSummary?.pendingPaymentsCount || 0,
											color: colors.primary,
										},
										{
											label: t('late'),
											total: invoicesSummary?.latePaymentsSum || 0,
											count: invoicesSummary?.latePaymentsCount || 0,
											color: colors.destructive,
										},
										{
											label: t('Paid'),
											total: invoicesSummary?.paidPaymentsSum || 0,
											count: (invoicesSummary?.paidPaymentsCount || 0) + (invoicesSummary?.paidCreditsCount || 0),
											color: colors.success,
										},
										{
											label: t('review'),
											total: (invoicesSummary?.missingDataSum || 0) + (invoicesSummary?.readyForSendingSum || 0),
											count:
												(invoicesSummary?.missingDataCount || 0) +
												(invoicesSummary?.readyForSendingCount || 0) +
												(invoicesSummary?.readyForSendingCreditsCount || 0) +
												(invoicesSummary?.missingDataCreditsCount || 0),
											color: colors.warning,
										},
									]}
								/>
							</div>
						</div>

						<InvoiceTable
							refreshData={refreshData}
							tableProps={{
								sort: sortLate,
								hideTableFooter: true,
								title: t('lateInvoices'),
								titleClassName: styles.cardTitle,
								subTitle: `${t('until')} ${dayjs().format(DATE_FORMAT)}`,
								header: lateInvoiceTableHeader,
								rows: invoiceLateList,
								className: styles.table,
								emptyState: (
									<EmptyStateCard showIcon imgType='v_circle' imgAlt='empty-state' title={t('nobodyLate')} containerStyle={styles.emptyStateCard} />
								),
								onRowPress: (data) =>
									setOpenSideBarById({
										sideBarType: data?.accountingEventType == AccountingEventType.CREDIT ? SidebarType.CREDIT_NOTE : SidebarType.INVOICE,
										id: data.invoiceId,
									}),
								onFlipOrder: (accessor) =>
									setSortLate({
										orderBy: accessor,
										order: sortLate.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
									}),
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
