import { ContractType, Currency, convertCurrencyToSign } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Cell, LogoCell, PriceCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { ResellerSummary, ResellerView } from '../../../types/partnerTypes';
import { getValueWithCurrencySign, numberWithCommas, priceNumberWithCurrencySignDisplay } from '../../../utils/NumberUtils';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import styles from './Resellers.module.scss';

dayjs.extend(relativeTime);

export const statusColor = (statusSeverity: string) => {
	return statusSeverity === 'HIGH' ? 'destructive' : statusSeverity === 'MEDIUM' ? 'warning' : 'neutral';
};

export const resellersTableHeader = (t: any, appCurrency: Currency) => [
	{
		headerTitle: 'partner',
		accessor: 'name',
		subAccessor: 'email',
		isSortable: true,
		CustomComponent: (item: ResellerView) => {
			const { t } = useTranslation('translation');
			return <LogoCell title={item.name} logoData={item.iconData} cellSyncStatus={getSyncStatus(item)} subTitle={t('reseller')} />;
		},
	},
	{
		headerTitle: 'TCV',
		accessor: 'totalContracts',
		CustomComponent: (item: ResellerView) => {
			return (
				<Cell
					accessor={getValueWithCurrencySign(item?.totalContractsValue || 0, convertCurrencyToSign(appCurrency))}
					subAccessor={item?.activeContracts ? `${numberWithCommas(item?.activeContracts)} ${t('activeagreements')}` : ''}
				/>
			);
		},
	},
	{
		headerTitle: 'totalPaid',
		accessor: 'totalPaymentsAmount',
		isSortable: true,
		CustomComponent: (item: ResellerView) => {
			return (
				<PriceCell
					mainNumber={item?.totalPaymentsAmount}
					subAccessor={item?.totalPaidAmountCount ? `${item?.totalPaidAmountCount} ${t('paidInv')}` : ''}
					mainNumberAccessor={item?.totalPaidPercentage ? `(${item?.totalPaidPercentage}%)` : ''}
				/>
			);
		},
	},
	{
		headerTitle: 'renewals',
		accessor: 'renewals',
		isSortable: true,
		CustomComponent: (item: ResellerView) => {
			return (
				<Cell
					accessor={item.daysForNextRenewal >= 0 ? `${item.daysForNextRenewal} ${t('daysLeft')}` : '-'}
					subAccessor={item?.renewalActivationStartDate ? `${t('activeFrom')} ${item?.renewalActivationStartDate}` : ''}
				/>
			);
		},
	},
	{
		headerTitle: 'latePayments',
		accessor: 'delayedPaymentsAmount',
		isSortable: true,
		CustomComponent: (item: ResellerView) => {
			return (
				<PriceCell
					mainNumber={item?.delayedPaymentsAmount}
					subAccessor={item?.delayedPaymentsCount ? `${item?.delayedPaymentsCount} ${t('lateInv')}` : ''}
				/>
			);
		},
	},

	{
		headerTitle: 'lastStatus',
		accessor: 'status',
		CustomComponent: (item: ResellerView) => {
			const status = () => {
				if (!!item.readyForSendingCount || !!item.missingDataCount) {
					return <StatusCol color='warning'>{`${item?.readyForSendingCount + item?.missingDataCount} ${t('review')}`}</StatusCol>;
				}

				if (item.pendingPaymentsCount) {
					return <StatusCol color='primary'>{`${item.pendingPaymentsCount} ${t('pending')}`}</StatusCol>;
				}

				if (item.delayedPaymentsCount) {
					return <StatusCol color='destructive'>{`${item.delayedPaymentsCount} ${t('late')}`}</StatusCol>;
				}
				return '-';
			};

			return <div className={styles.badges}>{status()}</div>;
		},
	},
];

export const summaryDataBar = (t: any, currency: Currency, data?: ResellerSummary) => {
	return [
		{
			title: t('activePartners'),
			quantity: numberWithCommas(data?.activePartners),
			subQuantity: data?.activePartnersMoM || 0,
			description: `${Math.abs(data?.activePartnersMoM || 0)}% MoM`,
		},
		{
			title: t('totalContractsValue'),
			quantity: priceNumberWithCurrencySignDisplay(data?.totalAgreementsValue || 0, convertCurrencyToSign(currency)),
			subQuantity: data?.totalAgreementsValueMom || 0,
			description: `${Math.abs(Math.round(data?.totalAgreementsValueMom || 0))}% MoM`,
		},
		{
			title: t('annualContractsValue'),
			quantity: priceNumberWithCurrencySignDisplay(data?.annualAgreementsValue || 0, convertCurrencyToSign(currency)),
			subQuantity: data?.annualAgreementsValueYoy || 0,
			description: `${Math.abs(Math.round(data?.annualAgreementsValueYoy || 0))}% YoY`,
		},
		{
			title: t('activeContracts'),
			quantity: numberWithCommas(data?.activeAgreements || 0),
			subQuantity: data?.activeAgreementsMom || 0,
			description: `${Math.abs(Math.round(data?.activeAgreementsMom || 0))}% MoM`,
		},
		{
			title: t('contractsRenewals'),
			quantity: numberWithCommas(data?.agreementsRenewals || 0),
			description: t('dueWithin60Days'),
		},
		{
			title: t('contractsForReview'),
			quantity: numberWithCommas(data?.agreementsForReview || 0),
		},
	];
};

export const getResellerSummaryData = async (
	dispatch: Dispatch,
	fromDate: Date | string,
	toDate: Date | string,
	contractType: ContractType[],
	customerIds?: string[],
	textFilter?: string,
) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getCustomersSummary,
			params: { fromDate, toDate, contractType, customerIds: customerIds || undefined, textFilter: textFilter || undefined },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};
