import { Dispatch } from '@reduxjs/toolkit';
import { IntegrationCategory, IntegrationDataSyncStatus, IntegrationPlatform, IntegrationProvider, SyncObjectType } from '@received/pricing-model';
import { PathsConfig, httpService } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { setAddResyncAccountingIntegration } from '../../../../storeSlices/IntegrationsSlice';
import { AccountingItemsExport } from '../../IntegrationLayout.utils';

export const resyncIntegrationData = async (
	dispatch: Dispatch,
	syncObjectTypes: SyncObjectType[],
	provider: IntegrationProvider,
	supplierId: string,
	integrationType: IntegrationPlatform,
	resyncAccountingIntegration: { [key: string | number]: IntegrationDataSyncStatus },
	accountingItemsExportStep: AccountingItemsExport,
) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.resyncIntegrationMatchingItems,
			data: {
				supplierId,
				category: IntegrationCategory.ACCOUNTING,
				platform: integrationType,
				// TODO add when supported
				// dataTypes: syncObjectTypes,
			},
		});

		dispatch(
			setAddResyncAccountingIntegration({
				[AccountingItemsExport.Accounts]: IntegrationDataSyncStatus.SYNCING,
				[AccountingItemsExport.Customers]: IntegrationDataSyncStatus.SYNCING,
				[AccountingItemsExport.Items]: IntegrationDataSyncStatus.SYNCING,
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
