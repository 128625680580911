import { ArAgingPage } from '../../pages/Dashboard/ArAgingPage/ArAgingPage';
import { Dashboard } from '../../pages/Dashboard/Dashboard';
import { SyncedStatusPage } from '../../pages/Dashboard/SyncedStatusPage/SyncedStatusPage';
import { Integrations } from '../../pages/Integrations/Integrations';
import { NewPricingModel } from '../../pages/PricingModels/NewPricingModel/NewPricingModel';
import { PricingModels } from '../../pages/PricingModels/PricingModels';
import { Reports } from '../../pages/Reports/Reports';
import { AllRevenueRecognitionActivities } from '../../pages/RevenueRecognition/AllRevenueRecognitionActivities/AllRevenueRecognitionActivities';
import { RevenueRecognition } from '../../pages/RevenueRecognition/RevenueRecognition';
import { Settings } from '../../pages/Settings/Settings';
import { ImportUsageLogs } from '../../pages/Usage/ImportUsageLogs/ImportUsageLogs';
import { Usage } from '../../pages/Usage/Usage';
import { RoutesType, NodeEnvTypes } from '../../types/generalTypes';
import { billingRoutes } from './billingRoutes';
import { contracts } from './contractsRoutes';
import { customersRoutes } from './customersRoutes';
import { partnersRoutes } from './partnersRoutes';

export const allProtectedRoutes: RoutesType[] = [
	{
		name: 'dashboard',
		path: '/',
		showOnSidebar: true,
		iconType: 'dashboard',
		element: <Dashboard />,
	},
	{
		name: 'upForRenewal',
		path: '/dashboard/ar-aging',
		showOnSidebar: false,
		element: <ArAgingPage />,
	},
	{
		name: 'upForRenewal',
		path: '/dashboard/synced-status',
		showOnSidebar: false,
		element: <SyncedStatusPage />,
	},
	...contracts,
	...customersRoutes,
	...partnersRoutes,
	...billingRoutes,
	{
		name: 'pricing',
		path: '/pricing-models',
		showOnSidebar: true,
		iconType: 'cube',
		element: <PricingModels />,
	},
	{
		name: 'newPricingModel',
		path: '/pricing-models/new-pricing-model',
		showOnSidebar: false,
		element: <NewPricingModel />,
	},
	{
		name: 'usage',
		path: '/usage',
		showOnSidebar: true,
		iconType: 'usage',
		element: <Usage />,
	},
	{
		name: 'reports',
		path: '/reports',
		showOnSidebar: true,
		iconType: 'pie_chart',
		element: <Reports />,
	},
	{
		name: 'revenueRecognitionShort',
		path: '/revenue-recognition',
		showOnSidebar: true,
		iconType: 'bag',
		element: <RevenueRecognition />,
	},
	{
		name: 'allRevenueRecognitionRecords',
		path: '/revenue-recognition/revenue-recognition-records',
		showOnSidebar: false,
		element: <AllRevenueRecognitionActivities />,
	},
	{
		name: 'settings',
		path: '/settings',
		showOnSidebar: false,
		element: <Settings />,
	},
	{
		name: 'integrations',
		path: '/integrations',
		showOnSidebar: false,
		element: <Integrations />,
	},
	{
		name: 'importUsageLogs',
		path: '/usage/import-usage-logs',
		showOnSidebar: false,
		element: <ImportUsageLogs />,
	},
];
