import { Currency, DATE_FORMAT } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { DatesData, ScheduledRevenueItem, SelectItem } from '../../../../types/generalTypes';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { VerticalTable, Select, ClickableCardsList, ClickableCardsItem } from '../../../_uiComponents';
import { VerticalTableColumn } from '../../../_uiComponents/VerticalTable/VerticalTable.utils';
import { tableHeaders } from './Schedule.utils';
import { httpService, PathsConfig } from '../../../../services';
import { cashTimeUnitHandler } from '../../../../utils/DateUtils';
import { DATE_FORMAT_FOR_SERVER } from '../../../../constants/templateConstants';
import { ProductContractRes } from '../../../../types/ProductTypes';
import { RevenueFeatureDisabled } from '..';
import { recognitionMethodLabels } from '../../../../constants/generalConstants';
import { ActivationDates } from '../../../_customerTabs/AnalyticsTab/AnalyticsTab.utils';
import { productsColorsList } from '../../../../utils/GeneralUtils';
import styles from './Schedule.module.scss';

interface ScheduledRevenuesResponse {
	count: number;
	entries: ScheduledRevenueItem[];
}

interface ScheduleProps {
	featureDisabled?: boolean;
	customerId?: string | null;
	isValid?: boolean;
	currency: Currency;
	activationDates?: ActivationDates;
	className?: string;
	yearsList?: SelectItem[];
	contractId?: string;
	filterByRevenueRecognitionMethod?: boolean;
	title?: string;
	excludeDraftContracts?: boolean;
}

export const Schedule = ({
	featureDisabled,
	yearsList,
	customerId,
	isValid = true,
	contractId,
	currency,
	activationDates,
	className,
	title,
	excludeDraftContracts = true,
	filterByRevenueRecognitionMethod,
}: ScheduleProps) => {
	const [timePeriod, setTimePeriod] = useState<DatesData>();
	const [products, setProducts] = useState<ClickableCardsItem[]>([]);
	const [scheduledRevenueData, setScheduledRevenueData] = useState<VerticalTableColumn[]>([]);
	const [years, setYears] = useState<SelectItem[]>([]);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		!featureDisabled && getProducts();
		setListOfYears(activationDates?.startActivationDate, activationDates?.endActivationDate);
	}, [featureDisabled, contractId, customerId]);

	useEffect(() => {
		timePeriod && products?.length && getScheduleData();
	}, [products, timePeriod, contractId, customerId]);

	const getProducts = async () => {
		try {
			const res: ProductContractRes[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractProductsList,
					params: {
						contractId,
						customerId,
						excludeDraftContracts,
					},
				})
			).data;

			const allProducts = res?.flatMap((prod, index) => ({
				id: prod.productId,
				isSelected: true,
				color: productsColorsList[index % productsColorsList?.length].textColor || '',
				productName: prod.productName,
				description: filterByRevenueRecognitionMethod ? recognitionMethodLabels[prod.revenueRecognitionMethod] : '',
			}));

			setProducts(allProducts);
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getScheduleData = async () => {
		try {
			const selectedProducts = products.filter((product) => product.isSelected);
			if (!selectedProducts.length) {
				return setScheduledRevenueData([]);
			}

			const res: ScheduledRevenuesResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getRevenueSchedule,
					params: {
						fromDate: timePeriod?.from,
						toDate: timePeriod?.to,
						productIds: selectedProducts.map((product) => product.id),
						customerIds: customerId ? [customerId] : undefined,
						contractId,
						timeUnit: timePeriod && cashTimeUnitHandler(timePeriod?.from, timePeriod?.to),
						excludeDraftContracts,
					},
				})
			).data;

			const data = res.entries.map((item: ScheduledRevenueItem) => ({ data: item?.amounts, label: dayjs(item?.date).format(DATE_FORMAT) }));
			setScheduledRevenueData(data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SCHEDULE_DATA }));
		}
	};

	const setListOfYears = (from?: string, to?: string) => {
		const yearFrom = yearsList ? dayjs().year() : dayjs(from).year();
		const yearTo = yearsList ? dayjs().year() : dayjs(to).year();
		const years = [];
		for (let i = yearFrom; i <= yearTo; i++) {
			years.push(i);
		}
		yearsList ? setYears(yearsList) : setYears(years.map((item: number) => ({ label: item, value: item })));

		setTimePeriod({
			from: dayjs(from).startOf('year').format(DATE_FORMAT_FOR_SERVER),
			to: dayjs().endOf('year').format(DATE_FORMAT_FOR_SERVER),
			title: years[0]?.toString() || dayjs().year().toString(),
		});
	};

	return (
		<>
			{isValid && (
				<div className={clsx(styles.box, className)}>
					{featureDisabled && <RevenueFeatureDisabled />}

					<div
						className={clsx(styles.content, featureDisabled && styles.needLicenseBox)}
						style={{ pointerEvents: featureDisabled ? 'none' : 'auto' }}
					>
						<div className={styles.topInfoContainer}>
							<div className={styles.topInfo}>
								<span className={styles.boldTitle}>{t(title || 'revenueRecognitionSchedule')}</span>

								<div className={styles.timeFilter}>
									<span className={styles.dates}>{`${dayjs(timePeriod?.from).format(DATE_FORMAT)} - ${dayjs(timePeriod?.to).format(
										DATE_FORMAT,
									)}`}</span>

									<Select
										fullBorder
										data={years}
										defaultValue={timePeriod?.title}
										width={10}
										className={styles.select}
										onChange={(value: string) => {
											setTimePeriod({
												from: dayjs()
													.year(+value)
													.startOf('year')
													.format(DATE_FORMAT_FOR_SERVER),
												to: dayjs()
													.year(+value)
													.endOf('year')
													.format(DATE_FORMAT_FOR_SERVER),
												title: value,
											});
										}}
									/>
								</div>
							</div>

							<div className={clsx(featureDisabled && styles.hidden)}>
								<ClickableCardsList cards={products} setCards={setProducts} />
							</div>
						</div>
						<div className={styles.bottomInfoContainer}>
							<VerticalTable headers={tableHeaders(currency)} columns={scheduledRevenueData} />
						</div>
					</div>
				</div>
			)}
		</>
	);
};
