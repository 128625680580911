import React, { CSSProperties, ReactNode, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { ErrorSuccessModalTypes } from '../../../types/generalTypes';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { images } from '../../../constants/images';
import styles from './FileUploader.module.scss';

export interface FileUploaderProps {
	value?: string;
	style?: CSSProperties;
	className?: string;
	tabIndex?: number;
	children?: ReactNode;
	fileTypes: string[]; //'PNG' | 'JPG' | 'JPEG' | 'PDF' | 'CSV'
	disabled?: boolean;
	showDescription?: boolean;
	uploadBase64File?: (base64String: string) => void; //return base64
	uploadFile?: (file: File) => void;
}

export const FileUploader = ({
	style,
	value = '',
	fileTypes,
	tabIndex,
	className,
	children,
	disabled,
	showDescription,
	uploadFile,
	uploadBase64File,
}: FileUploaderProps) => {
	const { t } = useTranslation('translation');

	const [fileNotSupported, setFileNotSupported] = useState(false);
	const [dragActive, setDragActive] = useState(false);

	const inputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch();

	const handleDrag = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setFileNotSupported(false);
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true);
		} else if (e.type === 'dragleave') {
			setDragActive(false);
		}
	};

	// triggers when file is dropped
	const handleDrop = function (e: any) {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			handleInputChange(e.dataTransfer.files[0]);
		} else {
			setFileNotSupported(true);
		}
	};

	const convertToBase64 = (file: File) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const onButtonClick = (e: any) => {
		setFileNotSupported(false);
		e.stopPropagation();
		inputRef?.current && !disabled && inputRef?.current?.click();
	};

	const handleInputChange = async (file: File) => {
		try {
			const type = file.type ? file.type.split('/')[1] : setFileNotSupported(true);

			if (type && fileTypes.includes(type.toUpperCase())) {
				if (uploadBase64File) {
					const base64File: string = (await convertToBase64(file)) as string;
					uploadBase64File(base64File);
				}
				uploadFile && uploadFile(file);
			} else {
				setFileNotSupported(true);
			}
		} catch (e) {
			dispatch(
				setOpenSuccessErrorModal({
					modalType: ErrorSuccessModalTypes.ERROR,
					successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_FILE,
				}),
			);
		}
	};

	return (
		<div
			tabIndex={tabIndex}
			id='drag-file-element'
			className={clsx(styles.generalContainer, dragActive && !disabled && styles.activeDrag, disabled && styles.disable, className)}
			onDragEnter={handleDrag}
			onDragLeave={handleDrag}
			onDragOver={handleDrag}
			onDrop={handleDrop}
			onClick={onButtonClick}
			onKeyDown={(event) => {
				if (event.key === 'Enter') {
					onButtonClick(event);
				}
			}}
		>
			<input
				tabIndex={tabIndex}
				autoFocus
				className={styles.inputContainer}
				ref={inputRef}
				type='file'
				id='input-file-upload'
				multiple={true}
				onChange={(e) => {
					e.stopPropagation();
					e.target.files && handleInputChange(e.target.files[0]);
				}}
				accept={`.${fileTypes.join(', .')}`}
				disabled={disabled}
			/>
			{children ? (
				children
			) : (
				<div className={styles.uploadImgContainer} style={style} data-testid='file-uploader'>
					<div className={styles.imgContainer}>
						<img alt='logo' src={value || images.uploadLogo} className={value ? styles.imgIcon : styles.noImgIcon} />
					</div>

					{/* TODO remove this section when new create entity developed */}
					{showDescription && (
						<div className={styles.fileWrapper}>
							<span className={styles.uploadText}>{t('clickToUpload')}</span>
							<span className={styles.imgDragAndDrop}>
								{' '}
								{t('dragAndDrop')} {fileTypes.join(', ')} ({t('maxImageSize')})
							</span>
						</div>
					)}
				</div>
			)}
			{fileNotSupported && <div className={styles.fileNotSupported}>{t('fileNotSupported')}</div>}
		</div>
	);
};
