import { useState } from 'react';
import {
	BillingCycleUnit,
	CellTypes,
	ContractState,
	CreditNoteReasonCode,
	currencyList,
	CurrencyListItem,
	CyclicDate,
} from '@received/pricing-model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import {
	billingCycleSelect,
	creditNoteReasonCodesLabelsSelect,
	DEFAULT_BILLING_CYCLE_VALUE,
	paymentTimeSelect,
} from '../../../../../constants/generalConstants';
import { CustomBillingCycle, DatePicker, DatePickerFormatType, Select, Switch, Tooltip } from '../../../../_uiComponents';
import { NetTermsSelect } from '../../../..';
import { FooterTypeOptions } from '../../../../_uiComponents/DatePicker/Footer/Footer';
import { isPaymentPeriodDisabled, TabSettingsProps } from '../../../PricingModelTabContent.utils';
import { IssueDatePicker } from '../../../../_uiComponents/DatePicker';
import { PaymentTimeOptions } from '../../../../../types/generalTypes';
import { PricingModelTableColumns } from '../../../../../types/pricingModelTypes';
import { updateBillingCycle } from '../../../PricingModelTabContentActions';
import { diamond } from '../../../../../constants/unicodes';
import { getCustomBillingCycleLabel } from '../../../../../pages/PricingModels/NewPricingModel/PricingModelSettings/PricingModelSettings.utils';
import { DATE_FORMAT } from '../../../../../constants/templateConstants';
import styles from '../ContractContent.module.scss';

export const CreditNoteTabSettings = ({
	pricingModelTab,
	contract,
	updateActivationDates,
	updatePricingModelData,
	updateCurrency,
}: TabSettingsProps) => {
	const { t } = useTranslation('translation');
	const [isCustomBillingCycleOpen, setIsCustomBillingCycleOpen] = useState(false);

	const isValidateBillingPeriod = !!contract?.editBlockers?.length && contract.state === ContractState.ACTIVE && !!pricingModelTab?.lastActiveDate;

	return (
		<div className={styles.settingsBarContainer}>
			<span className={styles.settingsBarTitle}>{t('billingSchedule')}</span>

			<div className={styles.settingsBarContent}>
				<div className={styles.leftContainer}>
					<Tooltip
						tooltipComponent={
							isValidateBillingPeriod && (
								<div className={styles.tooltipLabel}>{`${t('minCreditEndDate')} ${dayjs(pricingModelTab?.lastActiveDate).format(DATE_FORMAT)}`}</div>
							)
						}
						blackTooltip
						placement='right'
					>
						<DatePicker
							rangePicker
							footerType={
								isValidateBillingPeriod
									? undefined
									: pricingModelTab?.billingCycle?.unit !== BillingCycleUnit.ONE_TIME
									? FooterTypeOptions.ADD_YEARS
									: FooterTypeOptions.ADD_MONTHS
							}
							icon={'information'}
							title={t('creditPeriod')}
							headerContainerStyle={styles.activationDatePickerTitle}
							defaultFromDate={pricingModelTab?.billingStartDate}
							defaultToDate={pricingModelTab?.billingEndDate}
							updateOnClose={(date: DatePickerFormatType) => updateActivationDates(date.fromDate, date.toDate)}
							inputClassName={pricingModelTab?.billingEndDate ? styles.rangeDatePicker : styles.simpleDatePicker}
							disabledRangeUpdate={
								isValidateBillingPeriod ? { from: pricingModelTab?.billingStartDate, to: pricingModelTab?.lastActiveDate } : undefined
							}
							minMaxDates={isValidateBillingPeriod ? { minToDate: dayjs(pricingModelTab?.lastActiveDate).toDate() } : undefined}
						/>
					</Tooltip>
					<Select
						containerStyle={styles.billingDateSelect}
						title={t('cycle')}
						headerStyle={styles.billingPeriodSelectTitle}
						data={billingCycleSelect()}
						iconType={'information'}
						onChange={(billingCycleUnit: BillingCycleUnit) =>
							billingCycleUnit == BillingCycleUnit.CUSTOM
								? setIsCustomBillingCycleOpen(true)
								: updateBillingCycle({ unit: billingCycleUnit, cycle: DEFAULT_BILLING_CYCLE_VALUE }, pricingModelTab, updatePricingModelData, true)
						}
						defaultValue={getCustomBillingCycleLabel(t, pricingModelTab?.billingCycle)}
						width={15}
					/>
					<Select
						title={t('currency')}
						onChange={(value: any) => updateCurrency(value)}
						defaultValue={pricingModelTab?.currency}
						iconType={'information'}
						data={currencyList.map((item: CurrencyListItem) => ({
							label: `${item.flag} ${item.currency}`,
							value: item.currency,
						}))}
						className={styles.currencyInput}
						width={11.5}
					/>
				</div>

				<div className={styles.rightContainer}>
					<div className={styles.rightSideInputs}>
						<Select
							title={t('reasonCode')}
							iconType={'information'}
							placeholder={t('reasonCodePlaceholder')}
							headerStyle={styles.billingPeriodSelectTitle}
							data={creditNoteReasonCodesLabelsSelect}
							onChange={(reasonCode: CreditNoteReasonCode) =>
								updatePricingModelData({
									...pricingModelTab,
									reasonCode,
								})
							}
							defaultValue={pricingModelTab?.reasonCode}
							width={20}
							className={styles.margin}
						/>

						<IssueDatePicker
							title={t('issuedDay')}
							icon={'information'}
							datePickerLabel={t('chooseIssueDate')}
							customHeaderByBillingCycle={pricingModelTab?.billingCycle}
							defaultIssueDate={pricingModelTab?.issueDay}
							updateIssueDate={(date: CyclicDate) =>
								updatePricingModelData({
									...pricingModelTab,
									issueDay: date || undefined,
								})
							}
							className={clsx(styles.issueDatePicker, styles.whiteBackground)}
						/>
						<div className={styles.collectiveButton}>
							{t('collective')}
							<Switch
								smallSize
								defaultDisable={pricingModelTab.billingCycle?.unit === BillingCycleUnit.ONE_TIME}
								onChange={() =>
									updatePricingModelData(
										{
											...pricingModelTab,
											isCollective: !pricingModelTab?.isCollective || false,
										},
										true,
									)
								}
								defaultChecked={pricingModelTab?.isCollective}
							/>
						</div>
						<Tooltip
							disabled={
								!pricingModelTab?.pricingModelTable?.columns.find((i: PricingModelTableColumns) => i.type == CellTypes.USAGE_AGGREGATOR_REFERENCE)
							}
							tooltipComponent={
								<>
									<div className={styles.tooltipText}>{t('beforeAndDuringPeriods')}</div>
									<div className={styles.tooltipText}>{t('cannotBeSelected')}</div>
									<div className={styles.tooltipText}>
										{t('active')} <span className={styles.purpleDiamond}>{diamond}</span> {t('usageComponent')}
									</div>
								</>
							}
						>
							<Select
								disabled={isPaymentPeriodDisabled(pricingModelTab)}
								className={styles.periodSelect}
								headerStyle={styles.billingPeriodSelectTitle}
								data={paymentTimeSelect}
								onChange={(paymentTime: PaymentTimeOptions) =>
									updatePricingModelData({
										...pricingModelTab,
										paymentTime,
									})
								}
								defaultValue={pricingModelTab?.paymentTime}
								width={12.2}
							/>
						</Tooltip>
					</div>

					<NetTermsSelect
						title={t('paymentTerms')}
						onChange={(netTerms: any) =>
							updatePricingModelData(
								{
									...pricingModelTab,
									netTerms,
								},
								true,
							)
						}
						defaultValue={pricingModelTab?.netTerms}
						iconType={'information'}
						width={17}
						className={styles.netTermsSelect}
					/>
				</div>
			</div>

			<CustomBillingCycle
				isOpen={isCustomBillingCycleOpen}
				billingCycle={pricingModelTab?.billingCycle}
				onUpdateCustomBillingCycle={(billingCycle) => updateBillingCycle(billingCycle, pricingModelTab, updatePricingModelData, true)}
				onClose={() => setIsCustomBillingCycleOpen(false)}
			/>
		</div>
	);
};
