import { findCSVEntity, RowStatus } from '../../Integrations';
import { IndexInCustomerCSVFile } from '../../Integrations/UploadCSVMatcher/UploadCSVMatcher.utils';
import { CountrySelectItem } from '../../types/generalTypes';
import { Customer, CustomersDetails } from '../../types/customerTypes';
import { CSVRow } from './CSVDataMatcherModal';

export const getFormattedLabel = (value?: string, data?: CountrySelectItem[]) => {
	const trimmedInput = value?.replace(/\s/g, '').toLowerCase();
	const item = data?.find(
		(item) =>
			item.value.replace(/\s/g, '').toLowerCase() === trimmedInput ||
			item.prefix.replace(/\s/g, '').toLowerCase() === trimmedInput ||
			`${item.prefix.replace(/\s/g, '').toLowerCase()} - ${item?.value.replace(/\s/g, '').toLowerCase()}`.replace(/\s/g, '').toLowerCase() ===
				trimmedInput,
	);
	return item ? item.value : value;
};

export const handleSwitchCustomerRows = (importedRows: any[], suppliersList: Customer[]) => {
	const newRows = importedRows.map((row) => {
		const newRow = { ...row };

		Object.keys(newRow).forEach((key: string) => {
			newRow[key] = newRow[key]?.name ?? newRow[key];
			switch (+key) {
				case IndexInCustomerCSVFile.ENTITY: {
					const supplier = findCSVEntity(newRow[key], suppliersList);
					return (newRow[key] = suppliersList.length ? supplier : undefined);
				}
			}
		});

		return newRow;
	});

	return newRows;
};

export const setRowStatus = (row: CSVRow, existedCustomers?: CustomersDetails) => {
	if (row.isValid) {
		const customerName = existedCustomers?.customers?.find((customer) => customer.name?.trim() === row?.row[IndexInCustomerCSVFile.NAME]?.trim());
		if (customerName && row?.oldRow) {
			let unchangedCustomer = true;
			Object.keys(row.row).forEach((key: string, index: number) => {
				if (index >= 1 && row.row[key]?.toString() != row.oldRow[key]?.toString()) {
					unchangedCustomer = false;
				}
			});
			return unchangedCustomer ? RowStatus.UNCHANGED : RowStatus.UPDATE;
		}
		return RowStatus.NEW;
	}
	return RowStatus.ERROR;
};

export const setDuplicateDataForSimpleRows = async (rows: any[]) => {
	const allNamesInTable = rows.map((row) => row[IndexInCustomerCSVFile.NAME]);
	const allRemotesInTable = rows
		?.filter((row) => row[IndexInCustomerCSVFile.REMOTE_ID]?.length > 0)
		.map((row) => row[IndexInCustomerCSVFile.REMOTE_ID]);
	const duplicateNames: string[] = allNamesInTable.filter((name, index, list) => list.indexOf(name) !== index);
	const duplicateRemoteIds: string[] = allRemotesInTable.filter((remoteId, index, list) => list.indexOf(remoteId) !== index);
	const duplicates = { remoteIds: [...duplicateRemoteIds], names: [...duplicateNames], customers: [] };

	return duplicates;
};
