import { Currency, AccountingEventType, UsageAggregationFunction, UsageAggregationScope, DocumentState } from '@received/pricing-model';
import { IconColor } from '../components';
import { Invoice } from './invoiceTypes';
import { BillingDocumentType } from './generalTypes';

export interface UsageProduct {
	id: string;
	usageGroupId: string;
	productId?: string;
	subProductId?: string;
	name: string;
	isManual: boolean;
	isConnected?: boolean;
	usageGroup?: UsageGroup;
	documents?: BillingDocumentType[];
	subActivity?: string;
}

export interface UsageProductAggregator {
	name: string;
	usageGroupId: string;
	usageProduct: UsageProduct;
	aggregationFunction: UsageAggregationFunction;
	aggregationScope: UsageAggregationScope;
}

export interface UsageReportView {
	customerId: string;
	customerName: string;
	customerEmail: string;
	customerIconData: string;
	usageProductId: string;
	usageProductName: string;
	quantity: number;
	startDate: Date;
	endDate: Date;
	documentId: string;
	documentNumber: string;
	draftNumber: string;
	documentDate: Date;
	documentTotalPayment: number;
	contractDocumentNumber: number;
	contractDocumentsCount: string;
	contractDocumentsTotal: number;
	state: DocumentState;
	usageReportId: string;
	countedUnit: string;
	subActivity?: string;
	documentCurrency: Currency;
	accountingEventType?: AccountingEventType;
	updatedAt: Date;
}

export interface UsageSummaryView {
	connectedProducts: string[];
	totalUsageAmount: number;
	totalUsageQuantity: number;
	usageGroupName: string;
	usageGroupDescription: string;
	usageProductId: string;
	usageProductName: string;
	colors?: ColorSummaryBox;
}

export interface UsageProductsSummary {
	customerTotal: number;
	customerTotalMom: number;
	contractsTotal: number;
	contractsTotalMom: number;
	documentsTotal: number;
	documentsTotalMom: number;
}

export interface ColorSummaryBox {
	colorIcon: IconColor;
	backgroundColorIcon: string;
	textColor: string;
}

export interface UsageSummaryItem extends UsageSummaryView {
	colors?: ColorSummaryBox;
}

export interface UsageGroup {
	id: string;
	countedUnit: string;
	description: string;
}

export interface UsageSnippet {
	httpMethod: string;
	url: string;
	body: { [key: string]: string };
}

export enum UsageConnectionStatusTypes {
	MANUAL = 'MANUAL',
	API = 'API',
	NOT_CONNECTED = 'NOT_CONNECTED',
}

export interface ManualUsageReportItem {
	usageProduct: UsageProduct;
	invoices: Invoice[];
}

export interface UsageProductAnalyticsList {
	date: Date;
	products: UsageProductSummary[];
}

export interface UsageProductSummary {
	usageProductId: string;
	usageProductName: string;
	totalUsageQuantity: number;
	totalUsageAmount: number;
	colors?: ColorSummaryBox;
}

export interface SummaryByProductItem {
	usageLogs: number;
	customers: number;
	subActivities: number;
	invoices: number;
	connectedProducts: string[];
	usageProduct: { name: string; id: string };
	usageReportsView: UsageReportView[];
	usageReports: number;
}

export interface UsageLogsSummaries {
	csvWithErrorsUid: string;
	totalWarningCount: number;
	totalSummary: {
		usageLogs: number;
		invoices: number;
		products: number;
		customers: number;
		subActivities: number;
		usageReports: number;
	};
	summaryByUsageProduct: {
		[key: string]: SummaryByProductItem;
	};
	validationWarnings: ValidationWarnings;
	usageLogsRUid: string;
	usageReportRUid: string;
}

export interface ValidationWarnings {
	[errorKey: string]: { count: number; groupedRows: string[] };
}

export interface DocumentUsageReportList {
	columnMapping: { [key: string]: any };
	customerId: string;
	editBlockers: [];
	endDate: string;
	id: string;
	quantity: number;
	startDate: string;
	state: string;
	subActivity: string | null;
	usageLogsTable: { rows: any[]; columns: any[] };
	usageProductId: string;
}
