import { Dispatch } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { Cell, IconCell, LogoCell, PriceCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { ResellerView } from '../../../types/partnerTypes';
import { numberWithCommas } from '../../../utils/NumberUtils';
import { Customer, CustomerView } from '../../../types/customerTypes';
import { Button, MenuOption } from '../../../components';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { ErrorSuccessModalTypes } from '../../../types/generalTypes';
import { getCustomerById } from '../../_Customers';
import styles from './Referrals.module.scss';

export const referralsTableHeader = (tableSmallRowsView: boolean, createContract: () => void) => [
	{
		headerTitle: 'partnerName',
		accessor: 'name',
		subAccessor: 'email',
		isSortable: true,
		CustomComponent: (item: ResellerView) =>
			tableSmallRowsView ? <Cell accessor={item?.name} /> : <LogoCell title={item?.name} logoData={item?.iconData} />,
	},
	{
		headerTitle: 'refCustomers',
		accessor: 'totalCustomers',
		isSortable: true,
		CustomComponent: (item: any) => (
			<IconCell imgType='users' accessor={numberWithCommas(item?.totalCustomers || 0)} iconContainerClassName={styles.iconContainerClassName} />
		),
	},
	{
		headerTitle: 'contracts',
		accessor: 'Contracts',
		isSortable: true,
		CustomComponent: (item: any) => <Cell accessor={numberWithCommas(item?.totalContracts)} />,
	},
	{
		headerTitle: 'booking',
		accessor: 'booking',
		isSortable: true,
		CustomComponent: (item: any) => <PriceCell mainNumber={item?.booking} />,
	},
	{
		headerTitle: 'churnRate',
		accessor: 'churnRate',
		isSortable: true,
		CustomComponent: (item: any) => <Cell accessor={`${item?.churnRate || 0}%`} />,
	},
	{
		headerTitle: 'referralFee',
		accessor: 'referralFee',
		isSortable: true,
		CustomComponent: (item: any) => <PriceCell mainNumber={item?.booking} />,
	},
	{
		headerTitle: 'products',
		accessor: 'products',
		isSortable: true,
		CustomComponent: (item: any) => (
			<Cell accessor={item?.products?.length ? `${item?.products[0]}${item?.products.length > 1 ? ` + ${item?.products.length - 1}` : ''}` : '-'} />
		),
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		CustomComponent: (item: CustomerView) => {
			const { t } = useTranslation('translation');
			if (!item.totalContracts)
				return <Button className={styles.createAgreement} type='outlined' onClick={createContract}>{`${t('createAgreement')} +`}</Button>;

			if (!item.delayedPaymentsAmount && !item.pendingPaymentsAmount && !+item.missingDataCount && !+item?.readyForSendingCount)
				return <StatusCol color='neutral'>{t('greatJobAllDone')}</StatusCol>;
			return (
				<div className={styles.badges}>
					{(!!+item.readyForSendingCount || !!+item.missingDataCount) && (
						<StatusCol color='warning'>{`${+item?.readyForSendingCount + +item?.missingDataCount} ${t('review')}`}</StatusCol>
					)}
					{!!+item.pendingPaymentsCount && <StatusCol color='primary'>{`${item.pendingPaymentsCount} ${t('sent')}`}</StatusCol>}
					{!!+item.delayedPaymentsCount && <StatusCol color='destructive'>{`${item.delayedPaymentsCount} ${t('late')}`}</StatusCol>}
				</div>
			);
		},
	},
];

export const partnerOptionList = (
	row: CustomerView,
	dispatch: Dispatch,
	onEditCustomer: (customer: Customer) => void,
	onPressDelete: (id: string, name: string) => void,
): MenuOption[] => {
	const canBeDeleted = !row.delayedPaymentsAmount && !row.pendingPaymentsAmount && !row.totalPaymentsAmount;
	return [
		{ title: 'Edit Partner', onPress: () => getCustomerById(row.id, dispatch, onEditCustomer), iconType: 'edit' },
		{
			title: 'Delete Partner',
			onPress: () =>
				canBeDeleted
					? onPressDelete(row.id, row.name)
					: dispatch(
							setOpenSuccessErrorModal({
								customTitle: 'cantDeletePartner',
								modalType: ErrorSuccessModalTypes.CANT_DELETE,
								successErrorMassage: 'cantDeletePartnerDescription',
							}),
					  ),
			iconType: 'delete',
			color: 'destructive',
		},
	];
};
