import { Step } from '../../../components/_uiComponents/VerticalStepper/Step/Step';
import { ImportPricingModels } from '../IntegrationComponents';

export const salesforceStepper: Step[] = [
	// TODO enable when page is ready
	// {
	// 	title: 'Accounts',
	// 	subTitle: '',
	// 	state: 'IN_PROGRESS',
	// 	component: (props?: any) => <div>Accounts</div>,
	// },
	{
		title: 'Price books',
		subTitle: '',
		// TODO return to UNSET when Customers ready
		state: 'IN_PROGRESS',
		component: (props?: any) => <ImportPricingModels {...props} />,
	},
	// {
	// 	title: 'Opportunities',
	// 	subTitle: '',
	// 	state: 'UNSET',
	// 	component: (props?: any) => <div>Opportunities</div>,
	// },
];
