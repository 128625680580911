import dayjs from 'dayjs';
import { convertCurrencyToSign, DocumentState } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { CreditNoteStatusTag, SummaryBlockProps } from '../../../../components';
import { Cell, LogoCell, PriceCell } from '../../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../../constants/templateConstants';
import { DocumentView } from '../../../../types/generalTypes';
import { getSyncStatus } from '../../../../utils/IntegrationUtils';
import { getTotalDue } from '../../../../utils/InvoiceUtils';
import { InvoiceView, summaryType } from '../../../../types/invoiceTypes';
import { createCSVFile, getDocumentIdNameByType, normalizeDataToCSVRows } from '../../../../utils/GeneralUtils';
import { AllCreditNoteSummary, CreditNoteView } from '../../../../types/creditNoteTypes';
import { formatEmailList } from '../../../../utils/UserUtils';
import { getDocumentNumberInSeries } from '../../Invoices/Invoices.utils';
import { formatDraftIssueDays, formatSentIssueDays } from '../../Invoices/AllInvoices/AllInvoices.utils';
import { creditNoteStatus } from '../../../../utils/CreditNoteUtils';
import { numberWithCommas } from '../../../../utils/NumberUtils';

export interface CreditNoteResponse {
	data: InvoiceView[];
	count: number;
}

export const creditNoteTableHeader = (refreshData?: () => void) => [
	{
		headerTitle: 'relatedCustomer',
		accessor: 'customerName',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.CUSTOMER,
		CustomComponent: (item: InvoiceView) => (
			<LogoCell
				title={item.customerName}
				subTitle={formatEmailList(item.customerEmail)}
				logoData={item.customerIconData}
				cellSyncStatus={getSyncStatus(item)}
			/>
		),
	},
	{
		headerTitle: 'contractNo',
		accessor: 'contractNumber',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.CONTRACT_NO,
		CustomComponent: (item: InvoiceView) => <Cell accessor={item?.contractNumber || '-'} subAccessor={item.contractName} />,
	},
	{
		headerTitle: 'products',
		accessor: 'productNames',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.PRODUCTS,
		CustomComponent: (item: InvoiceView) => {
			const { t } = useTranslation('translation');
			const accessor = item?.productNames[0] || '';
			const subAccessor =
				+item?.productNames?.length > 1 ? `+${item?.productNames?.length - 1} ${t(item?.productNames?.length > 2 ? 'Products' : 'product')}` : '';
			return <Cell accessor={accessor} subAccessor={subAccessor} />;
		},
	},
	{
		headerTitle: 'documentNo',
		accessor: 'documentNumber',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.DOCUMENT_NO,
		CustomComponent: (item: DocumentView) => {
			return <Cell accessor={getDocumentIdNameByType(item)} subAccessor={getDocumentNumberInSeries(item)} />;
		},
	},
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.ISSUE_DATE,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.issueDate).format(DATE_FORMAT)} subAccessor={formatDraftIssueDays(item)} />,
	},
	{
		headerTitle: 'DueDate',
		accessor: 'dueDate',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.DUE_DATE,
		CustomComponent: (item: InvoiceView) => (
			<Cell accessor={item.dueDate ? dayjs(item.dueDate).format(DATE_FORMAT) : '-'} subAccessor={formatSentIssueDays(item)} />
		),
	},
	{
		headerTitle: 'totalDue',
		accessor: 'total',
		isSortable: true,
		CSVIndex: CreditNotesCSVTitles.TOTAL_DUE,
		CustomComponent: (item: InvoiceView) => <PriceCell currency={convertCurrencyToSign(item.currency)} mainNumber={getTotalDue(item)} />,
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		disableRowClick: true,
		CSVIndex: CreditNotesCSVTitles.STATUS,
		CustomComponent: (item: CreditNoteView) => <CreditNoteStatusTag document={item} refreshData={refreshData} />,
	},
];

export const summaryBlocksTypes = (showDraft?: boolean) => {
	return [
		{
			label: summaryType.WARNING,
			value: [
				DocumentState.REVIEW_MISSING_DATA,
				DocumentState.COLLECTING_INFO,
				DocumentState.REVIEW_READY_TO_SEND,
				DocumentState.PAYMENT_FAILED,
				DocumentState.PAYMENT_FAILED_COLLECTING_INFO,
				DocumentState.SENT_FAILED,
				DocumentState.PAYMENT_FAILED,
			].concat(showDraft ? [DocumentState.DRAFT] : []),
		},

		{
			label: summaryType.SUCCESS,
			value: [DocumentState.PAID],
		},
		{
			label: summaryType.ARCHIVE,
			value: [DocumentState.CANCELED],
		},
	];
};

export const getCreditsSummaryBlocksData = (t: any, creditNoteSummary?: AllCreditNoteSummary): SummaryBlockProps[] => {
	return [
		{
			type: summaryType.WARNING,
			title: 'Review',
			total: (creditNoteSummary?.missingDataSum || 0) + (creditNoteSummary?.readyForSendingSum || 0),
			subTitle: `${numberWithCommas(creditNoteSummary?.readyForSendingCount || 0)} ${t('ready')} • ${
				creditNoteSummary?.missingDataCount || 0 || '0'
			} ${t('missingInfo')}`.toLocaleLowerCase(),
			iconColor: 'warning',
		},
		{
			type: summaryType.SUCCESS,
			title: 'paid',
			total: creditNoteSummary?.paidPaymentsSum,
			subTitle: `${numberWithCommas(creditNoteSummary?.paidPaymentsCount) || '0'} ${t('creditNotes')}`.toLocaleLowerCase(),
			iconColor: 'success',
		},
		{
			type: summaryType.ARCHIVE,
			title: 'canceled',
			total: creditNoteSummary?.canceledSum,
			subTitle: `${numberWithCommas(creditNoteSummary?.canceledCount) || '0'} ${t('creditNotes')}`.toLocaleLowerCase(),
			iconColor: 'neutral',
		},
	];
};

export const onDownloadCreditNotesCSV = async (t: any, creditNotesList: InvoiceView[]) => {
	const titles: any = creditNoteTableHeader().reduce((acc, header) => ({ ...acc, [header.CSVIndex]: t(header.headerTitle) }), {});
	titles[CreditNotesCSVTitles.CURRENCY] = t('currency');

	const data = creditNotesList.map((creditNote) => ({
		[titles[CreditNotesCSVTitles.CUSTOMER]]: `"${creditNote.customerName}"`,
		[titles[CreditNotesCSVTitles.CONTRACT_NO]]: `"${creditNote?.contractNumber}"` || '-',
		[titles[CreditNotesCSVTitles.PRODUCTS]]: `"${creditNote?.productNames.join(', ')}"`,
		[titles[CreditNotesCSVTitles.DOCUMENT_NO]]: getDocumentIdNameByType(creditNote),
		[titles[CreditNotesCSVTitles.ISSUE_DATE]]: `"${dayjs(creditNote.issueDate).format(DATE_FORMAT)}"`,
		[titles[CreditNotesCSVTitles.DUE_DATE]]: creditNote.dueDate ? `"${dayjs(creditNote.dueDate).format(DATE_FORMAT)}"` : '-',
		[titles[CreditNotesCSVTitles.TOTAL_DUE]]: `"${numberWithCommas(getTotalDue(creditNote))}"`,
		[titles[CreditNotesCSVTitles.STATUS]]: t(creditNoteStatus(creditNote.state).text),
		[titles[CreditNotesCSVTitles.CURRENCY]]: creditNote.currency,
	}));

	const csvContent = normalizeDataToCSVRows(titles, data);
	createCSVFile(`data:text/csv;charset=utf-8,${csvContent}`, 'Credit Notes');
};

export enum CreditNotesCSVTitles {
	CUSTOMER,
	CONTRACT_NO,
	PRODUCTS,
	DOCUMENT_NO,
	ISSUE_DATE,
	DUE_DATE,
	TOTAL_DUE,
	STATUS,
	CURRENCY,
}
