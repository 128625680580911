import clsx from 'clsx';
import { numberWithCommas } from '../../utils/NumberUtils';
import { Icon, IconImgType } from '../_uiComponents/Icon';
import styles from './QuantityCard.module.scss';

export interface QuantityCardProps {
	title: string;
	quantity: number;
	subQuantity?: number;
	icon?: IconImgType;
	description?: string;
	descriptionClassName?: string;
}

export const QuantityCard = ({ title, quantity, subQuantity, description, icon, descriptionClassName }: QuantityCardProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.iconContainer}>{icon && <Icon imgType={icon} width={2} color='neutral600' />}</div>
			<div className={styles.flexColumn}>
				<span className={styles.title}>{title}</span>
				<div className={styles.descriptionContainer}>
					<span className={styles.mainText}>{numberWithCommas(quantity) || '0'}</span>
					<div>
						{!!subQuantity && subQuantity != 0 && (
							<Icon
								imgType='arrow_back'
								width={1}
								className={clsx(subQuantity > 0 ? styles.up : styles.down)}
								color={subQuantity > 0 ? 'success' : 'destructive'}
							/>
						)}
						<span
							className={clsx(
								styles.descText,
								subQuantity && subQuantity > 0 ? styles.success : subQuantity && subQuantity < 0 ? styles.destructive : styles.neutral,
								descriptionClassName,
							)}
						>
							{description}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
