import dayjs from 'dayjs';
import { Dispatch } from '@reduxjs/toolkit';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Cell, LogoCell, PaymentCardCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { images } from '../../../constants/images';
import { SubscribersView } from '../../../types/subscriptionTypes';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { Contract } from '../../../types/contractTypes';
import { MenuOption } from '../../../components';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { netTermsToLabel } from '../../../utils/GeneralUtils';
import { priceNumberWithCurrencySignDisplay } from '../../../utils/NumberUtils';
import { billingCycleLabelsByEnum } from '../../../constants/generalConstants';
import { subscriptionStateColor } from '../../../pages/PricingModels/PricingModelTabs/SubscriptionsTab/SubscriptionsTab.utils';
import styles from './SubscriptionsTab.module.scss';

export const subscriptionsTabTableHeader = (t: any) => [
	{
		headerTitle: 'subscription',
		accessor: 'subscriptionName',
		isSortable: true,
		CustomComponent: (item: SubscribersView) => (
			<LogoCell
				overrideStyles={{ avatarContainer: styles.avatarContainer }}
				title={item?.subscriptionName}
				logoData={images.subscriptions}
				subTitle={`${t('billing')}  ${
					item.billingCycles.length > 1
						? `${billingCycleLabelsByEnum[item.billingCycles[0]]} + ${item.billingCycles.length - 1}`
						: billingCycleLabelsByEnum[item.billingCycles[0]]
				}`}
			/>
		),
	},
	{
		headerTitle: 'products',
		accessor: 'products',
		isSortable: true,
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={item?.products?.length ? `${item?.products[0].name}${item?.products.length > 1 ? ` + ${item?.products.length - 1}` : ''}` : '-'}
				subAccessor={netTermsToLabel(item?.products[0].netTerms)}
			/>
		),
	},
	{
		headerTitle: 'paymentMethod',
		accessor: 'paymentMethod',
		CustomComponent: (item: SubscribersView) => <PaymentCardCell stripeCard={item?.paymentGatewayCustomer} />,
	},
	{
		headerTitle: 'nextInvoiceOn',
		accessor: 'nextInvoiceDate',
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={dayjs(item.nextInvoiceDate).format(DATE_FORMAT)}
				subAccessor={
					item?.nextInvoiceAmount &&
					`${t('for')} ${priceNumberWithCurrencySignDisplay(parseInt(item?.nextInvoiceAmount), convertCurrencyToSign(item?.currency))}`
				}
			/>
		),
	},
	{
		headerTitle: 'started',
		accessor: 'started',
		CustomComponent: (item: SubscribersView) => (
			<Cell accessor={dayjs(item.started).format(DATE_FORMAT)} subAccessor={`${item.startedInMonths}  ${t('monthAgo')}`} />
		),
	},
	{
		headerTitle: 'renewalOn',
		accessor: 'renewalOn',
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={dayjs(item.renewalOn).format(DATE_FORMAT)}
				subAccessor={item.renewalDaysLeft >= 0 ? `${item.renewalDaysLeft} ${t('daysLeft')}` : ''}
			/>
		),
	},
	{
		headerTitle: 'status',
		accessor: 'status',
		CustomComponent: (data: SubscribersView) => {
			const badgeColor = subscriptionStateColor(data);
			return <StatusCol color={badgeColor.color}>{badgeColor.text}</StatusCol>;
		},
	},
];

export const subscriptionsMenuOptions = (
	row: SubscribersView | Contract,
	t: any,
	dispatch: Dispatch,
	onRefresh?: () => void,
	onDelete?: () => void,
): MenuOption[] => [
	// TODO Add when ready
	// {
	// 	title: t('cloneContract'),
	// 	onPress: () => {},
	// 	iconType: 'duplicate',
	// },
	{
		title: t('deleteSubscription'),
		onPress: () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			deleteContract(row?.contractId || row?.id || '', dispatch, onRefresh, onDelete);
		},
		iconType: 'delete',
		color: 'destructive',
	},
];

export const deleteSubscription = async (subscriptionId: string, dispatch: Dispatch, callback?: () => void, onDelete?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteAssignedSubscription,
			urlParam: { id: subscriptionId },
		});
		onDelete?.();
		callback?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
