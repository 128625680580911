import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { IntegrationStatus } from '@received/pricing-model';
import { Button, Icon } from '../_uiComponents';
import { CheckBoxList, DateRange, FilterSideBarOptions, PriceRange } from './FilterSideBar.utils';
import { DATE_FORMAT, DATE_FORMAT_FOR_SERVER } from '../../constants/templateConstants';
import { CustomerFilter } from './CustomerFilter/CustomerFilter';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { IntegrationType } from '../../types/integrationTypes';
import { convertIntegrationTypeToStringService } from '../../utils/IntegrationUtils';
import { Customer } from '../../types/customerTypes';
import styles from './FilterSideBar.module.scss';

export interface FilterData {
	[key: string]: any;
}

export interface SideBarFilters {
	filterData: FilterData;
	filterTags: FilterTag[];
}

export interface FilterTag {
	title: string;
	keys: string[];
	id?: string;
	filterArray?: string;
}

interface FilterSideBarProps {
	filterData?: SideBarFilters;
	isOpen?: boolean;
	filterOption?: FilterSideBarOptions;
	closeSideBar(): void;
	updateFilters(filterData: SideBarFilters): void;
}

export const FilterSideBar = ({
	isOpen,
	filterData,
	filterOption = FilterSideBarOptions.ALL_INVOICES_PAGE,
	closeSideBar,
	updateFilters,
}: FilterSideBarProps) => {
	const { t } = useTranslation('translation');
	const [minPrice, setMinPrice] = useState('');
	const [maxPrice, setMaxPrice] = useState('');
	const [fromDate, setFromDate] = useState<Date | null>(null);
	const [toDate, setToDate] = useState<Date | null>(null);
	const [fromDueDate, setFromDueDate] = useState<Date | null>(null);
	const [toDueDate, setToDueDate] = useState<Date | null>(null);
	const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
	const [selectedIntegrations, setSelectedIntegrations] = useState<{ title: string; key: string }[]>([]);
	const [activeIntegrations, setActiveIntegrations] = useState<{ title: string; key: string }[]>([]);
	const [isValid, setIsValid] = useState(true);

	const dispatch = useDispatch();

	useEffect(() => {
		isOpen && getActiveIntegrations();
	}, [isOpen]);

	useEffect(() => {
		setMinPrice(filterData?.filterData?.minTotalAmount || '');
		setMaxPrice(filterData?.filterData?.maxTotalAmount || '');
		setFromDate(filterData?.filterData?.fromIssueDate || null);
		setToDate(filterData?.filterData?.toIssueDate || null);
		setFromDueDate(filterData?.filterData?.fromDueDate || null);
		setToDueDate(filterData?.filterData?.toDueDate || null);
		setSelectedIntegrations(filterData?.filterData?.selectedIntegrations || []);
		const filterSelectedCustomers = selectedCustomers.filter((customer) => filterData?.filterData?.customerIds?.includes(customer.id));
		setSelectedCustomers(filterSelectedCustomers);
	}, [filterData?.filterData, filterData?.filterData?.selectedIntegrations]);

	useEffect(() => {
		checkDates();
	}, [fromDate, toDate, fromDueDate, toDueDate]);

	const getActiveIntegrations = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getIntegrations,
				params: { connectionStatus: IntegrationStatus.LINKED },
			});
			setActiveIntegrations(
				res.data.map((item: IntegrationType) => ({ title: convertIntegrationTypeToStringService(item.platform), key: item.platform })),
			);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.FAILED_LOAD_INTEGRATION_LIST }));
		}
	};

	const onClearAll = () => {
		setMinPrice('');
		setMaxPrice('');
		setFromDate(null);
		setToDate(null);
		setToDueDate(null);
		setToDueDate(null);
		setSelectedCustomers([]);
		updateFilters({ filterData: {}, filterTags: [] });
		closeSideBar();
	};

	const onShowInvoices = () => {
		let filterData: any = { filterData: {}, filterTags: [] };
		if (minPrice) {
			filterData = {
				...filterData,
				filterData: { ...filterData.filterData, minTotalAmount: parseFloat(minPrice) },
				filterTags: [...filterData.filterTags, { title: `${t('minPrice')} ${t('Total')} ${minPrice}`, keys: ['minTotalAmount'] }],
			};
		}
		if (maxPrice) {
			filterData = {
				...filterData,
				filterData: { ...filterData.filterData, maxTotalAmount: parseFloat(maxPrice) },
				filterTags: [...filterData.filterTags, { title: `${t('maxPrice')} ${t('Total')} ${maxPrice}`, keys: ['maxTotalAmount'] }],
			};
		}

		if (fromDate && toDate) {
			filterData = {
				...filterData,
				filterData: {
					...filterData.filterData,
					fromIssueDate: dayjs(fromDate).format(DATE_FORMAT_FOR_SERVER),
					toIssueDate: dayjs(toDate).format(DATE_FORMAT_FOR_SERVER),
				},
				filterTags: [
					...filterData.filterTags,
					{
						title: `${t('issuedate')}  ${dayjs(fromDate).format(DATE_FORMAT)} -  ${dayjs(toDate).format(DATE_FORMAT)}`,
						keys: ['fromIssueDate', 'toIssueDate'],
					},
				],
			};
		}

		if (fromDueDate && toDueDate) {
			filterData = {
				...filterData,
				filterData: {
					...filterData.filterData,
					fromDueDate: dayjs(fromDueDate).format(DATE_FORMAT_FOR_SERVER),
					toDueDate: dayjs(toDueDate).format(DATE_FORMAT_FOR_SERVER),
				},
				filterTags: [
					...filterData.filterTags,
					{
						title: `${t('dueDate')} ${dayjs(fromDueDate).format(DATE_FORMAT)} - ${dayjs(toDueDate).format(DATE_FORMAT)}`,
						keys: ['fromDueDate', 'toDueDate'],
					},
				],
			};
		}
		if (selectedCustomers.length) {
			filterData = {
				...filterData,
				filterData: { ...filterData.filterData, customerIds: selectedCustomers.map((customer) => customer.id) },

				filterTags: [
					...filterData.filterTags,
					...selectedCustomers.map((customer) => ({
						title: customer.name,
						keys: customer.id,
						id: customer.id,
						filterArray: 'customerIds',
					})),
				],
			};
		}

		if (selectedIntegrations.length) {
			filterData = {
				...filterData,
				filterData: { ...filterData.filterData, integrationPlatforms: selectedIntegrations.map((i) => i.key) },
				filterTags: [
					...filterData.filterTags,
					...selectedIntegrations.map((option) => ({
						title: option.title,
						id: option.title,
						keys: option.key,
						filterArray: 'integrationPlatforms',
					})),
				],
			};
		}

		updateFilters(filterData);
		closeSideBar();
	};

	const onClose = () => {
		if (filterData?.filterTags.length) {
			return closeSideBar();
		}
		onClearAll();
	};

	const checkDates = () => {
		if ((fromDate || toDate) && (!fromDate || !toDate)) {
			setIsValid(false);
			return;
		}
		if ((fromDueDate || toDueDate) && (!fromDueDate || !toDueDate)) {
			setIsValid(false);
			return;
		}

		setIsValid(true);
	};

	return (
		<div>
			{isOpen && <div onClick={onClose} className={styles.modalOverlay} />}
			<div className={isOpen ? styles.sideBarContainer : styles.sidebarHidden}>
				<div className={styles.header}>
					{t('filters')}
					<Button type='link' color='neutral' onClick={onClose}>
						<Icon imgType='x_icon' color='neutral700' className={styles.xIcon} />
					</Button>
				</div>

				<div className={styles.filterContainer}>
					<PriceRange minPrice={minPrice} maxPrice={maxPrice} updateMinPrice={(val) => setMinPrice(val)} updateMaxPrice={(val) => setMaxPrice(val)} />
					<DateRange
						generalTitle={t('issuedate')}
						fromDateTitle={t('from')}
						toDateTitle={t('to')}
						fromDate={fromDate}
						toDate={toDate}
						updateFromDate={(date) => setFromDate(date)}
						updateToDate={(date) => setToDate(date)}
					/>
					<DateRange
						generalTitle={t('dueDate')}
						fromDateTitle={t('from')}
						toDateTitle={t('to')}
						fromDate={fromDueDate}
						toDate={toDueDate}
						updateFromDate={(date) => setFromDueDate(date)}
						updateToDate={(date) => setToDueDate(date)}
					/>
					{!!activeIntegrations.length && (
						<CheckBoxList
							optionsList={activeIntegrations}
							selectedList={selectedIntegrations}
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							updateSelected={(newList) => setSelectedIntegrations(newList)}
						/>
					)}
					{isOpen && filterOption === FilterSideBarOptions.ALL_INVOICES_PAGE && (
						<CustomerFilter selectedList={selectedCustomers} updateSelectedList={setSelectedCustomers} />
					)}
				</div>

				<div className={styles.footer}>
					<Button type='link' color='neutral' onClick={onClearAll} className={styles.clearButton}>
						{t('clearAll')}
					</Button>
					<Button onClick={onShowInvoices} className={styles.showButton} disabled={!isValid}>
						{`${t('save')}`}
					</Button>
				</div>
			</div>
		</div>
	);
};
