import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './RadioButton.module.scss';

export interface RadioButtonProps {
	isChecked: boolean;
	label?: string;
	classNames?: { containerClassName?: string; innerCircleClassName?: string };
	disabled?: boolean;
	onChange?: (val: any) => void;
}

export const RadioButton = ({ isChecked, label, classNames, disabled, onChange }: RadioButtonProps) => {
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);

	const onClick = () => {
		setChecked(!checked);
		onChange?.(checked);
	};
	return (
		<div className={clsx(styles.mainContainer, disabled && styles.disabled)}>
			<div className={clsx(styles.container, isChecked && styles.containerChecked, classNames?.containerClassName)} onClick={onClick}>
				{isChecked && <div className={clsx(styles.innerCircle, classNames?.innerCircleClassName)}></div>}
			</div>
			{label && <div className={styles.fontSize14}>{label}</div>}
		</div>
	);
};
