import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Store } from '../../../types/storeTypes';
import { Select, Switch } from '../../_uiComponents';
import { CSVRow } from '../..';
import { Customer, CustomersDetails } from '../../../types/customerTypes';
import { customerRowItem, isColumnShowOldValue } from './TableStructures';
import { IndexInCustomerCSVFile } from '../../../Integrations/UploadCSVMatcher/UploadCSVMatcher.utils';
import styles from './CSVDataMatcherTable.module.scss';

export interface CSVDataMatcherTableHeader {
	title: string;
	isSelect: boolean;
}

export interface CSVDataMatcherTableProps {
	header: string[];
	originalHeader: string[];
	rows: CSVRow[];
	className?: string;
	style?: CSSProperties;
	mandatoryColumns?: number[];
	invalidHeaderTypes: number[];
	disabledColumns: number[];
	suppliersList: Customer[];
	existedDataFromServer?: CustomersDetails;
	duplicateDataInTable?: CustomersDetails;
	updateHeader(newHeader: string[], headerIndex: number): void;
	updateRow(rowIndex: number, row: CSVRow): void;
	setDisabledAllRows(isChecked: boolean): void;
	onFocusOut(index: number): void;
}

export const CSVDataMatcherTable = ({
	disabledColumns,
	header,
	originalHeader,
	rows,
	className,
	style,
	invalidHeaderTypes,
	suppliersList,
	existedDataFromServer,
	duplicateDataInTable,
	updateHeader,
	updateRow,
	setDisabledAllRows,
	onFocusOut,
}: CSVDataMatcherTableProps) => {
	const geoLocationData = useSelector((store: Store) => store.general.geoLocationData);

	const onUpdateHeader = (index: number, selected: string) => {
		const newHeader = [...header];
		newHeader[index] = selected;
		updateHeader(newHeader, index);
	};

	const onUpdateValue = async (val: string | number, rowIndex: number, columnIndex: number) => {
		const newRows = [...rows];
		newRows[rowIndex] = { ...newRows[rowIndex] };
		newRows[rowIndex].row[columnIndex] = val;
		updateRow(rowIndex, newRows[rowIndex]);
	};

	const onDisableRow = (isChecked: boolean, rowIndex: number) => {
		const newRows = [...rows];
		newRows[rowIndex] = { ...newRows[rowIndex], isDisabled: !isChecked };
		updateRow(rowIndex, newRows[rowIndex]);
	};

	return (
		<div className={clsx(styles.mainContainer, className)} style={style}>
			<div className={styles.header}>
				<div className={styles.switchContainer}>
					<Switch smallSize onChange={setDisabledAllRows} className={styles.switch} />
				</div>
				{header.map((item, index) =>
					disabledColumns.includes(index) ? (
						<div
							key={index}
							className={clsx(
								styles.headerItem,
								styles.headerInput,
								styles.headerItemText,
								invalidHeaderTypes.includes(index) && styles.notValidRow,
								invalidHeaderTypes.includes(index) && styles.notValidInput,
							)}
						>
							{item}
						</div>
					) : (
						<Select
							key={index}
							style={{ width: '100%' }}
							defaultValue={item}
							data={originalHeader.map((item) => ({ label: item, value: item }))}
							onChange={(selected) => onUpdateHeader(index, selected)}
							inputClassName={clsx(
								styles.headerInput,
								invalidHeaderTypes.includes(index) && styles.notValidRow,
								invalidHeaderTypes.includes(index) && styles.notValidInput,
							)}
							className={clsx(styles.headerItem, invalidHeaderTypes.includes(index) && styles.notValidRow)}
						/>
					),
				)}
			</div>

			<div className={styles.rowsContainer}>
				{rows.map((row, rowIndex) => (
					<div
						className={clsx(styles.row, row.isDisabled ? styles.disabledRow : styles.selectedRow, !row.isValid && styles.notValidRow)}
						style={style}
						key={row.rowIndex}
					>
						<div className={styles.switchContainer}>
							<Switch smallSize defaultChecked={!row.isDisabled} onChange={(value) => onDisableRow(value, rowIndex)} className={styles.switch} />
						</div>

						{header.map((headerId, columnIndex) => {
							let oldValue = '';
							if (isColumnShowOldValue(columnIndex) && row?.oldRow?.[columnIndex]) {
								oldValue = row?.oldRow?.[columnIndex] !== row.row?.[columnIndex] ? row?.oldRow?.[columnIndex] : '';
							}
							if (columnIndex == IndexInCustomerCSVFile.ENTITY) {
								const isOldEntityExist =
									row?.oldRow?.[columnIndex]?.id !== row.row?.[columnIndex]?.id && row?.oldRow?.[columnIndex]?.name == row.row?.[columnIndex]?.name;
								oldValue = isOldEntityExist ? row.row?.[columnIndex]?.name : null;
							}
							return (
								<div className={styles.rowItem} key={`${row.rowIndex}${columnIndex}`}>
									{customerRowItem({
										columnIndex,
										rowIndex,
										row,
										geoLocationData,
										suppliersList,
										existedDataFromServer,
										duplicateDataInTable,
										onUpdateValue,
										onFocusOut,
									})}
									{oldValue && <span className={styles.oldValue}>{oldValue}</span>}
								</div>
							);
						})}
					</div>
				))}
			</div>
		</div>
	);
};
