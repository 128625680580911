import React from 'react';
import clsx from 'clsx';
import { svgImg } from '../../../assets/icons/icons';
import { colors } from '../../../constants/colors';
import { IconProps } from './IconOptions';
import styles from './Icon.module.scss';

export const Icon = ({ imgType, color, height, width, className, id }: IconProps) => {
	let rgbColor = 'rgb(156,163,175)';
	let c: any;
	if (color && /^#([A-Fa-f0-9]{3}){1,2}$/.test(colors[color])) {
		c = colors[color].substring(1).split('');
		if (colors[color].length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		rgbColor = 'rgb(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ')';
	}

	return (
		<img
			id={id}
			src={`data:image/svg+xml;utf8,${svgImg(imgType, rgbColor)}`}
			style={{ height: height || width ? `${height || width}rem` : undefined, width: width || height ? `${width || height}rem` : undefined }}
			className={clsx(styles.default, className)}
		/>
	);
};
