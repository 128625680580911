import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { MenuOption, Select } from '../../../components';
import { LogoCell, Cell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { UserRole, UserDto, UserStatus } from '../../../types/userTypes';
import { formatEmailList, formatUserName } from '../../../utils/UserUtils';
import styles from './UsersPermissions.module.scss';

export const userPermissionTypes = [UserRole.Member, UserRole.Admin];

export const tableHeader = (dispatch: Dispatch) => [
	{
		headerTitle: 'user',
		accessor: 'name',
		CustomComponent: (user: UserDto) => <LogoCell title={formatUserName(user)} subTitle={formatEmailList(user.email)} overrideStyles={styles} />,
	},
	{
		headerTitle: 'userType',
		accessor: 'role',
		CustomComponent: (user: UserDto) => (
			<Select
				defaultValue={user.role}
				data={userPermissionTypes}
				onChange={(role) => {
					setUserRole(user.id, role, dispatch);
				}}
				width={13}
			/>
		),
	},
	{
		headerTitle: 'invitedBy',
		accessor: 'invitedBy',
		CustomComponent: (user: UserDto) => (
			<Cell accessor={user.invitedBy ? `By ${user.invitedBy}` : '-'} subAccessor={'Joined ' + dayjs(user.joinedDate).format(DATE_FORMAT)} />
		),
	},
	{
		headerTitle: 'status',
		accessor: 'status',
		CustomComponent: (user: UserDto) => (
			<StatusCol className={styles.capitalLetter} color={user.status === UserStatus.Active ? 'success' : 'neutral'}>
				{user.status}
			</StatusCol>
		),
	},
];

export const setUserRole = async (userId: string, role: string, dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.authUserUpdate,
			data: {
				role,
				id: userId,
			},
		});
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const setUserEnabled = async (id: string, enable: boolean, getUsers: () => void, dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.authUserEnable,
			data: {
				enable: enable,
				id: id,
			},
		});

		getUsers();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const deleteUser = async (userId: string, getUsers: () => void, dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.authDeleteUser,
			urlParam: { userId },
		});

		getUsers();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const userRowOptions = (user: UserDto, getUsers: () => void, dispatch: Dispatch): MenuOption[] => {
	const menuOptions: MenuOption[] = [
		{
			title: 'Delete',
			onPress: () => deleteUser(user.id, getUsers, dispatch),
			iconType: 'delete',
		},
	];

	if (user.status !== UserStatus.Invited) {
		menuOptions.unshift({
			title: user.status === UserStatus.Active ? 'Disable' : 'Enable',
			onPress: () => setUserEnabled(user.id, user.status !== UserStatus.Active, getUsers, dispatch),
			iconType: 'edit',
		});
	}
	return menuOptions;
};
