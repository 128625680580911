import { useRef, useLayoutEffect, useState, CSSProperties } from 'react';
import { Tooltip, TooltipPlacement } from '../Tooltip/Tooltip';

interface TooltipProps {
	containerClassName?: string;
	className?: string;
	blackTooltip?: boolean;
	placement?: TooltipPlacement;
}

interface TextWithTooltipProps {
	maxWidth?: number;
	className?: string;
	style?: CSSProperties;
	tooltip?: TooltipProps;
	dataTestId?: string;
	children?: React.ReactNode;
}

export const TextWithTooltip = ({ className, maxWidth, dataTestId = 'text', children, tooltip, style }: TextWithTooltipProps) => {
	const [isOverflow, setIsOverflow] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	useLayoutEffect(() => {
		if (ref) {
			const hasOverflow = ref?.current ? ref?.current?.offsetWidth < ref?.current?.scrollWidth : false;
			setIsOverflow(hasOverflow);
		}
	}, [ref?.current, children]);

	return (
		<Tooltip {...tooltip} tooltipComponent={<span>{children}</span>} disabled={!isOverflow}>
			<div
				ref={ref}
				data-testid={dataTestId}
				style={{
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					maxWidth: maxWidth ? `${maxWidth}rem` : undefined,
					...style,
				}}
				className={className}
			>
				{children}
			</div>
		</Tooltip>
	);
};
