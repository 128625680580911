import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../../hooks';
import { onPressDeleteUsageReport } from '../../../../pages/Usage/Usage.utils';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { Contract } from '../../../../types/contractTypes';
import { Invoice } from '../../../../types/invoiceTypes';
import { UsageReportView } from '../../../../types/usageTypes';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { SearchBar } from '../../../_uiComponents';
import { EmptyState } from '..';
import { SidebarToOpen, SidebarType } from '../../Sidebars.utils';
import { UsageListItem } from './UsageListItem/UsageListItem';
import { CreditNote } from '../../../../types/creditNoteTypes';
import { Payout } from '../../../../types/PayoutsTypes';
import { UsageReportResponse } from '../../../../pages/Usage/UsagePageTabs/UsageReportsTab/UsageReportsTab.utils';
import styles from './UsageTab.module.scss';

export interface UsageTabProps {
	contractData?: Contract;
	invoiceData?: Invoice;
	payoutData?: Payout;
	creditNoteData?: CreditNote;
	sidebarType?: SidebarType;
	selectedRowId?: string;
	onUsageRowPress: (sidebar: SidebarToOpen) => void;
	onDataChange: () => void;
}

export const UsageTab = ({
	contractData,
	invoiceData,
	payoutData,
	creditNoteData,
	sidebarType,
	selectedRowId,
	onUsageRowPress,
	onDataChange,
}: UsageTabProps) => {
	const [usageReportList, setUsageReportList] = useState<UsageReportView[]>([]);
	const [searchText, setSearchText] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [listCount, setListCount] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		setUsageReports();
	}, [contractData, invoiceData, creditNoteData, payoutData]);

	const setUsageReports = async (search?: string, page?: number) => {
		try {
			if (contractData || creditNoteData || payoutData) {
				const res: UsageReportResponse = await getUsageReportsByFilter(search, page);
				setUsageReportList(res.data || []);
				setIsEmpty(res.data.length == 0);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_USAGE_REPORTS_DATA }));
		}
	};

	const getUsageReportsByFilter = async (search?: string, page?: number) => {
		const usageReports: UsageReportResponse = (
			await httpService({
				dispatch,
				path: PathsConfig.getUsageReportsViewData,
				params: {
					pageSize: 20,
					pageNumber: page || pageNumber,
					orderBy: 'startDate',
					order: 'ASC',
					searchText: search,
					contractId: sidebarType == SidebarType.CONTRACT ? contractData?.id : undefined,
					documentId: sidebarType == SidebarType.INVOICE ? invoiceData?.id : SidebarType.CREDIT_NOTE ? creditNoteData?.id : payoutData?.id,
				},
			})
		).data;

		setListCount(usageReports.count);
		return usageReports;
	};

	const onSearchUsageReport = (text: string) => {
		setSearchText(text);
		onSearch(text);
	};

	const onScroll = async (event: any) => {
		const bottom = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight + 15;

		if (bottom) {
			if ((contractData || invoiceData || creditNoteData || payoutData) && usageReportList.length < listCount) {
				try {
					setPageNumber((prev) => prev + 1);
					const res: UsageReportResponse = await getUsageReportsByFilter(searchText, pageNumber + 1);

					setUsageReportList((prev) => [...prev, ...res.data]);
				} catch (error) {
					dispatch(
						setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_USAGE_REPORTS_DATA }),
					);
				}
			}
		}
	};

	const onRow = (usageReport: UsageReportView) => {
		onUsageRowPress({
			sidebarType: SidebarType.USAGE_REPORT,
			triggeredBy: sidebarType,
			id: usageReport.usageReportId,
			usageReport,
			usagePeriod: {
				fromDate: usageReport.startDate || null,
				toDate: usageReport?.endDate || null,
			},
		});
	};

	const onSearch = useDebounce(setUsageReports, 650);

	return (
		<div className={styles.container}>
			<SearchBar placeholder={t('searchUsageReports')} className={styles.searchBar} value={searchText} onChange={onSearchUsageReport} />

			{!isEmpty ? (
				<>
					<div className={styles.header}>
						<div className={styles.headerItem}>{t('periodStartEnd')}</div>
						<div className={styles.headerItem}>{t('usageProduct')}</div>
						<div className={styles.headerItem}>{t('reportQty')}</div>
					</div>
					<div className={styles.listContainer} onScroll={onScroll}>
						{usageReportList.map((usageReportView: UsageReportView, index) => (
							<UsageListItem
								key={index}
								index={index}
								usageReportView={usageReportView}
								isUsageReportActive={selectedRowId == usageReportView.usageReportId}
								onRowPress={onRow}
								isContractSideBar={sidebarType == SidebarType.CONTRACT}
								onDelete={() => onPressDeleteUsageReport(dispatch, onDataChange, usageReportView)}
								dispatch={dispatch}
							/>
						))}
					</div>
				</>
			) : (
				<EmptyState title={t('noExistingUsageReports')} description={t('noExistingUsageReportsDescription')} buttonText={t('addReports')} />
			)}
		</div>
	);
};
