import { Dispatch } from '@reduxjs/toolkit';
import { ContractState } from '@received/pricing-model';
import { Cell, LogoCell, PriceCell, StatusCol } from '../../../../components/_uiComponents/Table/Table.utils';
import { images } from '../../../../constants/images';
import { PathsConfig, httpService } from '../../../../services';
import { SubscribersView, SubscriptionView } from '../../../../types/subscriptionTypes';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { Sort } from '../../../../types/generalTypes';
import { ButtonStatusColor, LineGraph } from '../../../../components';
import { Contract } from '../../../../types/contractTypes';
import { billingCycleLabelsByEnum } from '../../../../constants/generalConstants';
import { numberWithCommas } from '../../../../utils/NumberUtils';
import { colors } from '../../../../constants/colors';
import styles from './SubscriptionsTab.module.scss';

export const subscriptionsTableHeader = (t: any) => [
	{
		headerTitle: 'name',
		accessor: 'name',
		isSortable: true,
		CustomComponent: (item: SubscriptionView) => (
			<LogoCell overrideStyles={{ avatarContainer: styles.avatarContainer }} title={item?.name} logoData={images.subscriptions} />
		),
	},
	{
		headerTitle: 'products',
		accessor: 'product',
		CustomComponent: (data: SubscriptionView) => (
			<Cell
				accessor={
					data?.productNames?.length ? `${data?.productNames[0]}${data?.productNames.length > 1 ? ` + ${data?.productNames.length - 1}` : ''}` : '-'
				}
				subAccessor={`${t('billing')} ${
					data?.billingCycles.length > 2
						? `${billingCycleLabelsByEnum[data.billingCycles[0]]} + ${data.billingCycles.length - 2}`
						: data.billingCycles.map((key) => billingCycleLabelsByEnum[key]).join(' + ')
				}`}
			/>
		),
	},
	{
		headerTitle: 'subscribers',
		accessor: 'subscribers',
		CustomComponent: (data: SubscriptionView) => (
			<Cell
				accessor={numberWithCommas(data.churnSubscribers)}
				subAccessor={`${data?.subscribersMoM > 0 ? '+' : ''}${Math.round(data?.subscribersMoM)}% MoM`}
				subAccessorClassName={data?.subscribersMoM > 0 ? styles.greenText : undefined}
			/>
		),
	},
	{
		headerTitle: 'totalCashflow',
		accessor: 'totalCashflow',
		CustomComponent: (data: SubscriptionView) => (
			<PriceCell
				mainNumber={data.totalCashflow}
				subAccessor={`${data?.totalCashflowMom > 0 ? '+' : ''}${Math.round(data?.totalCashflowMom)}% MoM`}
				subAccessorClassName={data?.totalCashflowMom > 0 ? styles.greenText : undefined}
			/>
		),
	},
	{
		headerTitle: 'churnRate',
		accessor: 'churnRate',
		CustomComponent: (data: SubscriptionView) => <Cell accessor={`${data.churnRate}%`} subAccessor={`${data.subscribers} ${t('subscribers')}`} />,
	},
	{
		headerTitle: 'netVolume',
		accessor: 'netVolume',
		CustomComponent: (data: SubscriptionView) => <PriceCell mainNumber={data.netVolume} />,
	},
	{
		headerTitle: 'last30d',
		accessor: 'last30d',
		CustomComponent: (data: SubscriptionView) => (
			<LineGraph
				labels={Object.keys(data.last30D)}
				data={[{ data: Object.keys(data.last30D).map((key) => data.last30D[key]), backgroundColor: colors.success, borderColor: colors.success }]}
				containerClassName={styles.graphsCell}
			/>
		),
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		CustomComponent: (data: SubscriptionView) => {
			const badgeColor = subscriptionStateColor(data);
			return <StatusCol color={badgeColor.color}>{badgeColor.text}</StatusCol>;
		},
	},
];

export const subscriptionStateColor = (subscription: SubscriptionView | SubscribersView | Contract): { color: ButtonStatusColor; text: string } => {
	switch (subscription.state) {
		case ContractState.DRAFT:
			return { color: 'neutral', text: 'Draft' };
		case ContractState.ACTIVE:
			return { color: 'success', text: 'Active' };
		case ContractState.END_BILLING:
			return { color: 'neutral', text: 'End Billing' };
		case ContractState.CANCELED:
			return { color: 'neutral', text: 'Canceled' };
		default:
			return { color: 'neutral', text: '' };
	}
};

export const getSubscriptionList = async (dispatch: Dispatch, sort: Sort) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getSubscriptionView,
			params: {
				pageSize: 200,
				pageNumber: 0,
				orderBy: sort?.orderBy,
				order: sort?.order,
			},
		});

		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const deleteSubscription = async (id: string, dispatch: Dispatch, onRefresh: () => void) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.deleteSubscription,
			urlParam: { id },
		});

		onRefresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
