import dayjs from 'dayjs';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Cell, LogoCell, PaymentCardCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { SubscribersView } from '../../../types/subscriptionTypes';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { subscriptionStateColor } from '../../PricingModels/PricingModelTabs/SubscriptionsTab/SubscriptionsTab.utils';
import { netTermsToLabel } from '../../../utils/GeneralUtils';
import { billingCycleLabelsByEnum } from '../../../constants/generalConstants';
import { priceNumberWithCurrencySignDisplay } from '../../../utils/NumberUtils';
import { formatEmailList } from '../../../utils/UserUtils';

export const subscribersTableHeader = (tableSmallRowsView: boolean, t: any) => [
	{
		headerTitle: 'customer',
		accessor: 'name',
		isSortable: true,
		CustomComponent: (item: SubscribersView) =>
			tableSmallRowsView ? (
				<Cell accessor={item?.name} />
			) : (
				<LogoCell title={item.name} subTitle={formatEmailList(item.email)} logoData={item.iconData} />
			),
	},
	{
		headerTitle: 'subscription',
		accessor: 'subscriptionName',
		isSortable: true,
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={item?.subscriptionName}
				subAccessor={`${t('billing')}  ${
					item.billingCycles.length > 2
						? `${billingCycleLabelsByEnum[item.billingCycles[0]]} + ${item.billingCycles.length - 2}`
						: item.billingCycles.map((key) => billingCycleLabelsByEnum[key]).join(' + ')
				}`}
			/>
		),
	},
	{
		headerTitle: 'products',
		accessor: 'productNames',
		isSortable: true,
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={item?.products?.length ? `${item?.products[0].name}${item?.products.length > 1 ? ` + ${item?.products.length - 1}` : ''}` : '-'}
				subAccessor={netTermsToLabel(item?.products[0].netTerms)}
			/>
		),
	},
	{
		headerTitle: 'paymentMethod',
		accessor: 'paymentMethod',
		CustomComponent: (item: SubscribersView) => <PaymentCardCell stripeCard={item?.paymentGatewayCustomer} />,
	},
	{
		headerTitle: 'nextInvoiceOn',
		accessor: 'nextInvoiceDate',
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={dayjs(item.nextInvoiceDate).format(DATE_FORMAT)}
				subAccessor={
					item?.nextInvoiceAmount &&
					`${t('for')} ${priceNumberWithCurrencySignDisplay(parseInt(item?.nextInvoiceAmount), convertCurrencyToSign(item?.currency))}`
				}
			/>
		),
	},
	{
		headerTitle: 'started',
		accessor: 'started',
		CustomComponent: (item: SubscribersView) => (
			<Cell accessor={dayjs(item.started).format(DATE_FORMAT)} subAccessor={`${item.startedInMonths}  ${t('monthAgo')}`} />
		),
	},
	{
		headerTitle: 'renewalOn',
		accessor: 'renewalOn',
		CustomComponent: (item: SubscribersView) => (
			<Cell
				accessor={dayjs(item.renewalOn).format(DATE_FORMAT)}
				subAccessor={item.renewalDaysLeft >= 0 ? `${item.renewalDaysLeft} ${t('daysLeft')}` : ''}
			/>
		),
	},
	{
		headerTitle: 'status',
		accessor: 'status',
		CustomComponent: (data: SubscribersView) => {
			const badgeColor = subscriptionStateColor(data);
			return <StatusCol color={badgeColor.color}>{badgeColor.text}</StatusCol>;
		},
	},
];

export const pageOptions = (t: any) => [
	{ label: `20 ${t('items')}`, value: '20' },
	{ label: `50 ${t('items')}`, value: '50' },
	{ label: `100 ${t('items')}`, value: '100' },
	{ label: `200 ${t('items')}`, value: '200' },
];
