import { Tabs } from '../../../../../components';
import { CreditNoteContent } from '../../../../../components/PricingModelTabContent';
import { CreditNote } from '../../../../../types/creditNoteTypes';
import { PricingModelTab } from '../../../../../types/contractTypes';
import styles from './CreditNoteProducts.module.scss';

interface CreditNoteProductsProps {
	creditNote: CreditNote;
	isEditMode: boolean;
	selectedProductTab: number;
	setSelectedProductTab: (tab: number) => void;
	onUpdateManualReportFromTable: () => void;
	onUpdateCreditNotePricingModel: (data: PricingModelTab) => void;
}

export const CreditNoteProducts = ({
	creditNote,
	isEditMode,
	selectedProductTab,
	setSelectedProductTab,
	onUpdateCreditNotePricingModel,
	onUpdateManualReportFromTable,
}: CreditNoteProductsProps) => {
	return (
		<div>
			<Tabs
				id='pricing-model-tab-button'
				tabsArray={creditNote.pricingModel.tabs.map((tab) => ({ label: tab.name, labelTitle: tab.productName, value: tab.name }))}
				classNames={styles}
				chosenTab={selectedProductTab}
				updateChosenTab={setSelectedProductTab}
			/>

			<CreditNoteContent
				selectedCustomer={creditNote?.customer}
				pricingModelTab={creditNote.pricingModel.tabs[selectedProductTab]}
				updatePricingModelData={(data) => onUpdateCreditNotePricingModel(data)}
				onUpdateManualReportFromTable={onUpdateManualReportFromTable}
				className={styles.tabsContent}
				isEditable={isEditMode}
				invoiceCredits={creditNote?.invoiceCredits}
			/>
		</div>
	);
};
