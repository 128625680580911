import { IconColor, IconImgType } from '../../../../components';

enum TemplateId {
	USAGE = '1e4e35dd-9344-455e-b655-287d3eac748b',
	SUBSCRIPTION = '04028560-7897-4699-8738-dc7e2341b284',
	PROFESSIONAL_SERVICES = 'dfdb6078-de96-4a8a-b43f-2f24cd3ad3b6',
	LICENSE = '81d5b943-9156-4cc8-8589-9ed1bcf0b951',
}

export const getTemplateColorAndIconById = (templateId: string): { iconType: IconImgType; color: IconColor } => {
	switch (templateId) {
		case TemplateId.USAGE:
			return { iconType: 'usage_template', color: 'neutral' };
		case TemplateId.LICENSE:
			return { iconType: 'license_template', color: 'neutral' };
		case TemplateId.PROFESSIONAL_SERVICES:
			return { iconType: 'ps_template', color: 'neutral' };
		case TemplateId.SUBSCRIPTION:
			return { iconType: 'subscription_template', color: 'neutral' };
		default:
			return { iconType: 'subscription_template', color: 'neutral' };
	}
};
