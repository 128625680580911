import { Dispatch } from '@reduxjs/toolkit';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { setShowHideToastNotification, setShowImportedDataIndicator } from '../../storeSlices/generalSlice';
import { colors } from '../../constants/colors';
import { ToastNotificationComponentType } from '../NotificationCard/NotificationCard.utils';

export enum PaidPeriod {
	TODAY = 'TODAY',
	CUSTOM = 'CUSTOM',
}

export enum ChangeStatusOption {
	MARK_AS_PAID = 'MARK_AS_PAID',
	PUBLISH = 'PUBLISH',
	MARK_AS_PAID_FROM_ROW = 'MARK_AS_PAID_FROM_ROW',
	PUBLISH_FROM_ROW = 'PUBLISH_FROM_ROW',
}

export const sendInvoice = async (t: any, invoiceId: string, dispatch: Dispatch, onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.sendInvoice,
			urlParam: { id: invoiceId },
		});

		onRefresh?.();
		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				title: 'theDocumentHasBeenSent',
				description: 'theSendingProcessHasBeenCompleted',
				sideColor: colors.success,
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.SEND_INVOICE }));
	}
};

export const chargeMultipleInvoices = async (t: any, ids: string[], dispatch: Dispatch) => {
	try {
		httpService({
			dispatch,
			showLoader: false,
			path: PathsConfig.chargeMultipleInvoice,
			data: { ids },
		});

		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				title: 'chargingInvoices',
				description: 'theActionTakeAWhile',
			}),
		);
		return dispatch(
			setShowImportedDataIndicator({
				totalItems: ids.length,
				importedItems: 0,
				barTitle: t('charged'),
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.CHARGE_INVOICE }));
	}
};

export const cancelInvoices = async (id: string, dispatch: Dispatch, getInvoiceData?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.cancelInvoice,
			urlParam: { id },
		});

		dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.INVOICE_STATUS_CANCELED }));
		getInvoiceData && getInvoiceData();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.INVOICE_CANCELED }));
	}
};

export const downloadDocuments = async (ids: string[], dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.downloadDocumentsByIds,
			params: { ids },
		});

		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
				title: 'PreparingFilesForDownload',
				description: 'PreparingFilesForDownloadDescription',
				icon: 'clock',
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DOWNLOAD }));
	}
};
