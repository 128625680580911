import { createSlice } from '@reduxjs/toolkit';
import { modalTypeByStatusCodeHandler } from '../components/SuccessErrorModal/errorNumberHandler';
import { ErrorSuccessModalTypes } from '../types/generalTypes';
import { ErrorSuccessSliceState } from '../types/storeTypes';

const initialState: ErrorSuccessSliceState = {
	showSuccessErrorPopup: false,
	successErrorMassage: '',
	modalType: ErrorSuccessModalTypes.SUCCESS,
	responseId: '',
	responseErrorEnum: '',
	customTitle: '',
};

export const errorSuccessReducer = createSlice({
	name: 'errorSuccess',
	initialState,
	reducers: {
		setOpenSuccessErrorModal: (
			state: any,
			payload: {
				payload: {
					customTitle?: string;
					responseError?: any;
					successErrorMassage?: string;
					modalType?: ErrorSuccessModalTypes;
					responseId?: string;
				};
			},
		) => {
			const newResponseError = payload.payload?.responseError || null;
			const newModalType = payload.payload?.modalType || modalTypeByStatusCodeHandler(newResponseError);

			return {
				...state,
				showSuccessErrorPopup: true,
				successErrorMassage: payload.payload?.successErrorMassage ?? 'generalFail',
				modalType: newModalType,
				responseId: payload.payload.responseId
					? payload.payload.responseId
					: newResponseError
					? newResponseError?.config?.headers?.['X-Request-ID']
					: '',
				responseErrorEnum: newResponseError ? newResponseError?.response?.data?.errorCode : '',
				customTitle: payload.payload?.customTitle || '',
			};
		},
		setCloseSuccessErrorModal: (state: any) => {
			return {
				...state,
				showSuccessErrorPopup: false,
				successErrorMassage: '',
				modalType: ErrorSuccessModalTypes.ERROR,
			};
		},
	},
});

export const { setOpenSuccessErrorModal, setCloseSuccessErrorModal } = errorSuccessReducer.actions;

export default errorSuccessReducer.reducer;
