import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../types/storeTypes';
import { Icon, MenuList, MenuOption } from '../_uiComponents';
import { priceNumberWithCurrencySignDisplay } from '../../utils/NumberUtils';
import { ColorSummaryBox } from '../../types/usageTypes';
import styles from './UsageSummaryBlock.module.scss';

interface SummaryBlockProps {
	title: string;
	total?: number;
	subTitle?: string;
	icon?: string;
	style?: any;
	className?: string;
	connectedProducts?: string[];
	colors?: ColorSummaryBox;
	isSelected?: boolean;
	onEdit(): void;
	onClick(): void;
	onDeleteUsageProduct(): void;
}

export const UsageSummaryBlock = ({
	title,
	total,
	subTitle,
	connectedProducts,
	style,
	className,
	colors,
	isSelected,
	onEdit,
	onClick,
	onDeleteUsageProduct,
}: SummaryBlockProps) => {
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);

	const menuOptions: MenuOption[] = [
		{ title: t('editUsageProduct'), onPress: () => onEdit(), iconType: 'edit' },
		{ title: t('removeUsageProduct'), onPress: () => onDeleteUsageProduct(), iconType: 'delete', color: 'destructive' },
	];

	const connected = connectedProducts?.length
		? connectedProducts.map((name, index) => `${name}${index != connectedProducts.length - 1 ? ' •' : ''}`)
		: t('notUsed');

	return (
		<div
			className={clsx(styles.summaryBlockContainer, className, isSelected && styles.summaryBlockContainerActive)}
			style={style}
			onClick={(event) => {
				event.stopPropagation();
				onClick();
			}}
			onContextMenu={(event) => {
				event.preventDefault();
				setIsOptionsMenuOpen(true);
			}}
		>
			<div className={styles.textContainer}>
				<div className={styles.connectedProducts} style={{ color: colors?.textColor }}>
					{connected}
				</div>

				<span className={styles.title}>{title}</span>
				<span className={styles.total}>{priceNumberWithCurrencySignDisplay(total || 0, convertCurrencyToSign(appCurrency))}</span>
				<span className={styles.subTitle}>{subTitle}</span>
			</div>
			<MenuList optionsList={menuOptions} isMenuOpen={isOptionsMenuOpen} openMenu={() => setIsOptionsMenuOpen(false)}>
				<div
					className={styles.summaryIconContainer}
					style={{ backgroundColor: colors?.backgroundColorIcon, borderColor: colors?.textColor }}
					data-testid='usage-summary-block'
					onClick={(event) => {
						event.stopPropagation();
						setIsOptionsMenuOpen(true);
					}}
				>
					{!isOptionsMenuOpen && <Icon imgType='graph' color={colors?.colorIcon || 'primary'} height={2.4} className={styles.summaryIcon} />}
					<Icon
						imgType='hamburger'
						color={colors?.colorIcon || 'primary'}
						height={1.8}
						className={clsx(styles.kebabIconHidden, isOptionsMenuOpen && styles.kebabIconVisible)}
					/>
				</div>
			</MenuList>
		</div>
	);
};
