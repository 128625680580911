import { SelectItem } from '../../../../types/generalTypes';

export const handleKeyDown = (
	event: any,
	focusedItem: any,
	options: any,
	setFocusedItem: (val: any) => void,
	selectedValue?: SelectItem | string,
) => {
	if (typeof focusedItem != 'number' && (event.keyCode === 38 || event.keyCode === 40)) {
		//start from selected
		const selectedIndex = options.findIndex((item: any) => {
			const val = typeof item === 'string' ? item : item?.label;
			const selected = typeof selectedValue === 'string' ? selectedValue : selectedValue?.label;
			return val === selected;
		});
		return setFocusedItem(selectedIndex >= 0 ? selectedIndex : 0);
	}
	if (event.keyCode === 38) {
		//key up
		event.preventDefault();
		setFocusedItem((prev: any) => {
			const num = (prev || 0) - 1 >= 0 ? (prev || 0) - 1 : 0;
			return num;
		});
	} else if (event.keyCode === 40) {
		//key down
		event.preventDefault();

		setFocusedItem((prev: any) => {
			const num = (prev || 0) + 1 < options.length ? (prev || 0) + 1 : options.length - 1;
			return num;
		});
	}
};
