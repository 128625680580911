import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { EventType } from '@received/pricing-model';
import { DatePicker, Table, TableProps } from '../_uiComponents';
import { FooterTypeOptions } from '../_uiComponents/DatePicker/Footer/Footer';
import { ChangeStatusOption } from './InvoiceTable.utils';
import { Store } from '../../types/storeTypes';
import { setListenerResponseType } from '../../storeSlices/generalSlice';
import { InvoiceView } from '../../types/invoiceTypes';
import { invoiceMenuList } from '../../utils/InvoiceUtils';
import { reconcileDocuments, submitDocuments } from '../../utils/DocumentsUtils';
import styles from './InvoiceTable.module.scss';

export interface InvoiceTableProps {
	tableProps: TableProps;
	refreshData(): void;
}

export const InvoiceTable = ({ tableProps, refreshData }: InvoiceTableProps) => {
	const { t } = useTranslation('translation');
	const [selectedInvoiceList, setSelectedInvoiceList] = useState<InvoiceView[]>([]);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [currentAction, setCurrentAction] = useState<ChangeStatusOption | ''>('');

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const listenerResponseType = useSelector((store: Store) => store.general.listenerResponseType);
	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);

	useEffect(() => {
		onListenerResponse();
	}, [listenerResponseType]);

	const onListenerResponse = () => {
		if ((listenerResponseType && listenerResponseType == EventType.SEND_INVOICE_BY_EMAIL) || listenerResponseType == EventType.SEND_DOCUMENTS) {
			refreshData();
			dispatch(setListenerResponseType(undefined));
		}
	};

	const onCloseDatePicker = (isOpen: boolean) => {
		setOpenDatePicker(isOpen);
		setCurrentAction('');
		setSelectedInvoiceList([]);
	};

	const onUpdateStatusFromDatePicker = async (date: Date) => {
		setOpenDatePicker(false);
		if (currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW) {
			await reconcileDocuments(
				date,
				selectedInvoiceList.map((invoice) => ({ id: invoice.id, type: invoice.accountingEventType })),
				dispatch,
			);
		} else {
			await submitDocuments(
				date,
				selectedInvoiceList.map((invoice) => ({ id: invoice.id, type: invoice.accountingEventType })),
				dispatch,
			);
		}
		setSelectedInvoiceList([]);
		setCurrentAction('');
		refreshData();
	};

	return (
		<>
			<Table
				{...tableProps}
				header={tableProps.header}
				optionsList={(data) =>
					invoiceMenuList({
						item: data,
						dispatch,
						navigate,
						t,
						availableIntegration,
						onRefresh: refreshData,
					})
				}
			/>
			<DatePicker
				hideInput
				openDatePicker={openDatePicker}
				datePickerLabel={
					currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW || currentAction === ChangeStatusOption.MARK_AS_PAID
						? t('choosePaymentDate')
						: t('choosePublishDate')
				}
				headerLabelStyle={styles.datePickerHeaderLabelStyle}
				footerType={FooterTypeOptions.SHOW_CONFIRM_BUTTONS}
				onConfirm={onUpdateStatusFromDatePicker}
				updateIsOpen={onCloseDatePicker}
				defaultFromDate={
					selectedInvoiceList.length
						? currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW || currentAction === ChangeStatusOption.MARK_AS_PAID
							? dayjs(selectedInvoiceList[0].dueDate).toDate()
							: dayjs(selectedInvoiceList[0].issueDate).toDate()
						: new Date()
				}
			/>
		</>
	);
};
