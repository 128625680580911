import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { AccountingEventType, PricingType, convertCurrencyToSign } from '@received/pricing-model';
import { images } from '../../constants/images';
import { Customer } from '../../types/customerTypes';
import { getNetTermLabel } from '../../utils/GeneralUtils';
import { PricingModelTab } from '../../types/contractTypes';
import { Button, DatePickerFormatType, Icon } from '../_uiComponents';
import { SideBarProductDetails, getBankAccountLabel, getLabelForPrice, getPaymentLabel } from './PricingModelDetails.utils';
import { getValueWithCurrencySign } from '../../utils/NumberUtils';
import { DATE_FORMAT } from '../../constants/templateConstants';
import styles from './PricingModelDetails.module.scss';

export interface PricingModelDetailsProps {
	data: PricingModelTab; //invoice or payout or contract pricing model
	supplier?: Customer;
	disabled?: boolean;
	className?: string;
	hasUsageReport?: boolean;
	fromDate: Date | null | undefined;
	toDate: Date | null | undefined;
	onPressUpdateUsage?: (timePeriod?: DatePickerFormatType) => void;
}

export const PricingModelDetails = ({
	data,
	disabled,
	className,
	hasUsageReport,
	fromDate,
	toDate,
	supplier,
	onPressUpdateUsage,
}: PricingModelDetailsProps) => {
	const { t } = useTranslation('translation');
	const [isOpen, setIsOpen] = useState(false);
	const bankData = data?.billingDetails?.billingAccountId
		? supplier?.billingDetails?.billingAccounts?.find((bank) => bank.id === data?.billingDetails?.billingAccountId)
		: undefined;
	const isStripeConnected = !!data?.billingDetails?.paymentGatewayId;

	return (
		<div className={clsx(styles.mainContainer, isOpen && styles.mainContainerOpen, disabled && styles.disabled, className)}>
			<div data-testid='side-bar-product-details' className={styles.mainSection} onClick={() => setIsOpen(!isOpen)}>
				<div className={styles.imgSection}>
					<Icon imgType='box' color='neutral700' width={1.8} />
				</div>

				<div className={styles.dataRowContainer}>
					<div className={styles.dataRowLabel}>
						<span className={styles.productName} data-testid='side-bar-product-product-name'>
							{data.productName}
						</span>
					</div>
					<div className={styles.dataRowLabel}>
						<div className={styles.iconWrapper}>
							<span className={styles.mainData} data-testid='side-bar-product-name'>
								{data.name}
							</span>
							<Icon imgType='arrow_down' color='neutral400' width={1} className={isOpen ? styles.arrowUp : styles.arrowDown} />
						</div>

						<span className={styles.mainData} data-testid='side-bar-product-total'>
							{getValueWithCurrencySign(data.total, convertCurrencyToSign(data.currency))}
						</span>
					</div>
					<div className={styles.dataRowLabel}>
						<span className={styles.text}>
							{` ${dayjs(fromDate).format(DATE_FORMAT)}`}
							<span>
								{toDate &&
									` - ${dayjs(toDate).format(DATE_FORMAT)} • ${getNetTermLabel(data.netTerms)} ${isStripeConnected ? `• ${t('stripe')}` : ''}`}
							</span>
						</span>
					</div>
				</div>
			</div>

			<div className={isOpen ? styles.isOpen : styles.isClosed}>
				<SideBarProductDetails
					header={{ key: t('pricingItems') }}
					metaData={data.pricingModelTable.rows.map((row) => ({
						key: row.cells.item.cellValue,
						value: getLabelForPrice(row.cells.price.cellValue, row.cells.price.cellType, data.currency),
					}))}
				/>

				<SideBarProductDetails
					header={{
						key: `${t('pricingTerms')} ${supplier ? `• ${supplier?.name}` : ''}`,
						value: isStripeConnected && (
							<div className={styles.termsValue}>
								<img src={images.stripePayment} className={styles.stripeIcon} />
								{t('stripe')}
							</div>
						),
					}}
					additionalData={[
						{
							key: getNetTermLabel(data.netTerms),
							value: getPaymentLabel(t, isStripeConnected, !!bankData),
							style: styles.bold,
						},
						{
							key: '',
							value: bankData ? `${bankData?.bank} ${getBankAccountLabel(bankData?.account || '')}` : '',
							style: styles.uppercase,
						},
					]}
				/>

				{data?.pricingType == PricingType.USAGE && !hasUsageReport ? (
					<div className={styles.usageBox}>
						<div className={styles.usageCircle}>
							<Icon imgType='usageIcon' color='neutral700' className={styles.usageIcon} />
						</div>
						<span className={styles.usageDescription}>{t('usageMustBeUpdated')}</span>

						{onPressUpdateUsage && (
							<Button type='link' color='primary' onClick={() => onPressUpdateUsage()}>
								<span className={styles.usageUpdate}>{t('updateUsage')}</span>
							</Button>
						)}
					</div>
				) : (
					<SideBarProductDetails
						header={{ key: t('pricingSummary') }}
						additionalData={[
							{
								key: t('subtotal'),
								value: getValueWithCurrencySign(data.subTotal, convertCurrencyToSign(data.currency)),
								style: styles.bold,
							},
							{
								key: `${t('discount')} (${data.discount}%)`,
								value: `(${getValueWithCurrencySign(data.totalDiscount, convertCurrencyToSign(data.currency))})`,
								isHidden: data.accountingEventType == AccountingEventType.PAYOUT,
							},
							{
								key: `${t('tax')} (${data.tax}%)`,
								value: getValueWithCurrencySign(data.totalTax, convertCurrencyToSign(data.currency)),
							},
							{
								key: `${t('Total')}`,
								value: getValueWithCurrencySign(data.total, convertCurrencyToSign(data.currency)),
							},
						]}
					/>
				)}
			</div>
		</div>
	);
};
