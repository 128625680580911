import { Currency } from '@received/pricing-model';
import { accountsReceivableAgingHeader } from '../Dashboard.utils';
import { createCSVFile, normalizeDataToCSVRows } from '../../../utils/GeneralUtils';
import { numberWithCommas } from '../../../utils/NumberUtils';
import { ARAgingRecord } from '../../../types/generalTypes';

export interface ARAgingResponse {
	data: ARAgingRecord[];
	count: number;
	total: ARAgingRecord;
}

export const onDownloadARAgingCSV = async (t: any, arAgingRecords: ARAgingRecord[], currency: Currency) => {
	const titles: any = accountsReceivableAgingHeader().reduce((acc, header) => ({ ...acc, [header.CSVIndex]: t(header.headerTitle) }), {});
	titles[ARAgingCSVTitles.CURRENCY] = t('currency');

	const data = arAgingRecords.map((record) => ({
		[titles[ARAgingCSVTitles.CUSTOMER]]: `"${record.customerName}"`,
		[titles[ARAgingCSVTitles.CURRENT]]: `"${numberWithCommas(record?.current)}"` || '-',
		[titles[ARAgingCSVTitles.DAYS_1_30]]: `"${numberWithCommas(record['1-30 days'])}"`,
		[titles[ARAgingCSVTitles.DAYS_31_60]]: `"${numberWithCommas(record['31-60 days'])}"`,
		[titles[ARAgingCSVTitles.DAYS_61_90]]: `"${numberWithCommas(record['61-90 days'])}"`,
		[titles[ARAgingCSVTitles.DAYS_OVER_90]]: `"${numberWithCommas(record['over 90 days'])}"`,
		[titles[ARAgingCSVTitles.TOTAL]]: `"${numberWithCommas(record['total'])}"`,
		[titles[ARAgingCSVTitles.CURRENCY]]: currency,
	}));

	const csvContent = normalizeDataToCSVRows(titles, data);
	createCSVFile(`data:text/csv;charset=utf-8,${csvContent}`, 'AR-Aging-Report');
};

export enum ARAgingCSVTitles {
	CUSTOMER,
	CURRENT,
	DAYS_1_30,
	DAYS_31_60,
	DAYS_61_90,
	DAYS_OVER_90,
	TOTAL,
	CURRENCY,
}
