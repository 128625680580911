import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CellProps, billingCycleShortLabelsByEnum, handleCellKeyDown } from '../../TableRow.utils';
import { tableInputNumberWithCommas } from '../../../../../utils/NumberUtils';
import { Tooltip } from '../../../..';
import { requiredColumnsEnum } from '../../../PricingModelTable.utils';
import styles from './FormulaCell.module.scss';

export const FormulaCell = ({
	rowData,
	column,
	selectedCell,
	columnsToColor,
	rowIndex,
	columnIndex,
	pricingModelTab,
	onArrowNavigation,
	setHoverOnFormula,
	onCellSelected,
	updateTableData,
	classNames,
}: CellProps) => {
	const { t } = useTranslation('translation');

	const [isEditMode, setIsEditMode] = useState(false);
	const [value, setValue] = useState<string | number>('');
	const [formula, setFormula] = useState<string>('');

	useEffect(() => {
		const nowEdited = selectedCell?.cell.cellId === rowData.cells[column.id].cellId;
		setIsEditMode(nowEdited);
	}, [selectedCell, rowData]);

	useEffect(() => {
		setValue(rowData.cells[column.id].cellValue);
		setFormula(rowData.cells[column.id]?.formula || '');
	}, [rowData.cells[column.id].formula, rowData.cells[column.id].cellValue]);

	const onFormulaChange = (formulaVal: string) => {
		setFormula(formulaVal);
		const newRowData = { ...rowData };
		newRowData.cells[column.id].formula = formulaVal;
		updateTableData(newRowData);
	};

	const convertFormulaToColors = () => {
		let newFormula = formula;
		Object.keys(columnsToColor).forEach((key) => {
			newFormula = newFormula?.replaceAll(key, `<span style="color: ${columnsToColor[key]}">${key}</span>`);
		});
		return newFormula ? <span dangerouslySetInnerHTML={{ __html: newFormula }}></span> : '';
	};

	return (
		<>
			{!isEditMode ? (
				<Tooltip
					tooltipComponent={convertFormulaToColors()}
					placement='top'
					onMouseEnter={() => setHoverOnFormula(true)}
					onMouseLeave={() => setHoverOnFormula(false)}
					containerClassName={styles.tooltip}
					className={styles.tooltipPlacement}
				>
					<div
						className={clsx(styles.cell, classNames?.cell, isEditMode && styles.editMode)}
						onMouseEnter={() => setHoverOnFormula(true)}
						onMouseLeave={() => setHoverOnFormula(false)}
					>
						<div data-testid={`${rowIndex}-row-${column.title}-column-cell-value`}>
							{isNaN(+value) ? value : tableInputNumberWithCommas(Number(value))}
							{column.id === requiredColumnsEnum.amount && pricingModelTab?.billingCycle && (
								<span className={styles.billingCycle}>
									{` / ${pricingModelTab?.billingCycle?.cycle > 1 ? pricingModelTab?.billingCycle?.cycle : ''} `}
									{billingCycleShortLabelsByEnum[pricingModelTab?.billingCycle?.unit]}
								</span>
							)}
						</div>

						{!(column.id === requiredColumnsEnum.amount && pricingModelTab?.billingCycle) && <div className={styles.formula}>{t('formula')}</div>}
					</div>
				</Tooltip>
			) : (
				<Tooltip
					tooltipComponent={t('amountNotEditable')}
					placement='bottom'
					containerClassName={styles.tooltip}
					className={styles.tooltipPlacement}
					disabled={column.id != requiredColumnsEnum.amount}
				>
					<input
						data-testid={`${rowIndex}-row-${column.title}-column-cell-input`}
						autoFocus
						value={formula}
						onChange={(event) => {
							if (column.id != requiredColumnsEnum.amount) {
								onFormulaChange(event.target.value);
							}
							setHoverOnFormula(true);
						}}
						className={clsx(styles.cell, styles.cell, classNames?.cell, isEditMode && styles.editMode)}
						onKeyDown={(event) => isEditMode && handleCellKeyDown(event, rowIndex, columnIndex, onArrowNavigation, onCellSelected, column)}
						onMouseLeave={() => setHoverOnFormula(false)}
						onMouseEnter={() => setHoverOnFormula(true)}
						readOnly={column.id === requiredColumnsEnum.amount}
					></input>
				</Tooltip>
			)}
		</>
	);
};
