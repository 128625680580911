import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContractState, ContractType } from '@received/pricing-model';
import { SummaryBar, Table } from '../../../components';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { contractsTabTableHeader, getContractSummaryBlocksData, getContractSummaryData, noDraftContractsStates } from './ContractsTab.utils';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { ContractSummary, ContractTabDataRes, ContractView } from '../../../types/contractTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Store } from '../../../types/storeTypes';
import { contractMenuOptions } from '../../../utils/ContractUtils';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { createContract } from '../../../pages/Contracts/ContractComponent/Contract.utils';
import styles from './ContractsTab.module.scss';

const defaultNumberOfItems = '20';

interface ContractsTabProps extends CustomerTabProps {
	contractType: ContractType;
}

export const ContractsTab = ({ isActive, customerData, sideBarFilters, refreshTabs, contractType, setOpenSideBarById }: ContractsTabProps) => {
	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);

	const [contractList, setContractList] = useState<ContractView[]>([]);
	const [summary, setSummary] = useState<ContractSummary>();
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [states, setStates] = useState<ContractState[]>();
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: 'state', order: SortOrder.DESC });

	const dispatch = useDispatch();
	const location: any = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		isActive && getData();
	}, [
		customerData,
		customerData?.id,
		numberOfItems,
		pageNumber,
		states,
		sort.orderBy,
		sort.order,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
		isActive,
		refreshTabs,
	]);

	useEffect(() => {
		location?.state?.filterByState && setSort((prev) => ({ ...prev, orderBy: 'state' }));
	}, []);

	const getData = async () => {
		if (customerData?.id) {
			await getContractTabData();
			const summary = await getContractSummaryData(dispatch, customerData?.id);
			setSummary(summary);
		}
	};

	const getContractTabData = async (page?: number, items?: string) => {
		try {
			const res: ContractTabDataRes = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractsView,
					params: {
						customerId: customerData?.id,
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						order: sort.orderBy ? sort.order : undefined,
						states,
						contractType: ContractType.ENTERPRISE,
						...sideBarFilters?.filterData,
					},
				})
			).data;

			setTotalCount(res?.count);
			setContractList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_TAB }));
		}
	};

	const onPressNewContract = async () => {
		const contractId = await createContract(dispatch, contractType, customerData?.id);
		contractId && navigate('../contract', { state: { contractId, customer: customerData } });
	};

	return (
		<div className={styles.container}>
			<SummaryBar summary={getContractSummaryBlocksData(t, summary)} blockClassName={styles.summaryBlock} />

			<Table
				sort={sort}
				showSwitch
				defaultSwitchChecked
				onToggleSwitch={(showDraft: boolean) => setStates(showDraft ? undefined : noDraftContractsStates)}
				title={t('contracts')}
				header={contractsTabTableHeader}
				rows={contractList}
				optionsList={(row) => contractMenuOptions(row, t, dispatch, () => navigate('../contract', { state: { contractId: row?.id } }), getData)}
				className={styles.tableContainer}
				numberOfPages={Math.ceil(totalCount / +numberOfItems)}
				totalNumberOfItems={totalCount}
				numberOfItems={numberOfItems}
				onUpdateNumberOfItems={setNumberOfItems}
				onUpdatePageNumber={setPageNumber}
				onRowPress={(data) => setOpenSideBarById?.({ sideBarType: SidebarType.CONTRACT, id: data.id })}
				onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				emptyStateProps={{
					title: t('noContracts'),
					description: t('noContractsDescription'),
					buttonText: `${t('createContract')} +`,
					onClick: () => onPressNewContract(),
				}}
			/>
		</div>
	);
};
