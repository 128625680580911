import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PricingModel } from '../../../../../types/contractTypes';
import { Button, PricingTableCardView } from '../../../../../components';
import { pricingTableHeader } from './PricingTable.utils';
import { PricingTableRow } from './PricingTableRow/PricingTableRow';
import { PricingModelTableViewTypeOptions } from '../../../../../components/PricingModelTable/PricingModelTable.utils';
import styles from './PricingTable.module.scss';

interface PricingTableProps {
	list?: PricingModel[];
	selectedModel?: PricingModel;
	customHeader?: React.ReactNode;
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	selectButtonText?: string;
	disableUseThisPricing?: boolean;
	showSwitch?: { isVisible?: boolean; defaultChecked?: boolean; onChange?: (isChecked: boolean) => void };
	selectedRows?: boolean[][];
	onRefreshTable?(): void;
	onUseThisPricing?(): void;
	selectPricing(pricingModel: PricingModel): void;
	onClosePreview?(): void;
	onUpdateSelectedRows?: (rowIndex: number, isSelected: boolean) => void;
}

export const PricingTable = ({
	list,
	selectedModel,
	customHeader,
	pricingModelTableViewType,
	selectButtonText,
	showSwitch,
	selectedRows,
	disableUseThisPricing,
	selectPricing,
	onRefreshTable,
	onUseThisPricing,
	onUpdateSelectedRows,
	onClosePreview,
}: PricingTableProps) => {
	const { t } = useTranslation('translation');
	const [selectedPricing, setSelectedPricing] = useState<PricingModel>();

	useEffect(() => {
		setSelectedPricing(selectedModel);
	}, [selectedModel]);

	const onRowClick = (model: PricingModel) => {
		setSelectedPricing(model);
		selectPricing(model);
	};

	return (
		<div className={styles.container}>
			<div className={selectedModel ? styles.smallView : styles.fullView}>
				{customHeader}
				<div className={clsx(styles.header, selectedModel && styles.itemsSizesClose)}>
					{pricingTableHeader.map((header) => (
						<div key={header.headerTitle} className={clsx(styles.rowItems, selectedModel && styles.rowItemsSmallView)}>
							{t(header.headerTitle)}
						</div>
					))}
					<div></div>
				</div>

				<div className={styles.listContainer}>
					{list?.map((item) => (
						<PricingTableRow
							selectedModel={selectedModel}
							key={item.id}
							pricingModel={item}
							onRowClick={() => onRowClick(item)}
							onRefreshTable={onRefreshTable}
						/>
					))}
				</div>
			</div>

			<div className={selectedPricing ? styles.previewContainerOpen : styles.previewContainerClose}>
				<PricingTableCardView
					showSwitch={showSwitch}
					selectedRows={selectedRows}
					pricingModel={selectedPricing}
					imgType={selectedPricing?.isFavorite ? 'star' : 'subscription_template'}
					iconColor={selectedPricing?.isFavorite ? 'black' : 'neutral'}
					tagText={selectedPricing?.product?.name}
					pricingModelTableViewType={pricingModelTableViewType}
					onUpdateSelectedRows={onUpdateSelectedRows}
				/>

				<div className={styles.buttonsContainer}>
					{/* TODO Return when api support missing fields */}
					{/* <Button type='outlined' color='neutral' onClick={onClosePreview}>
						{t('close')}
					</Button> */}
					<Button onClick={onUseThisPricing} dataTestId='edit-pricing' disabled={disableUseThisPricing}>
						{t(selectButtonText || 'editPricing')}
					</Button>
				</div>
			</div>
		</div>
	);
};
