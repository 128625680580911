import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { CheckBox, Icon } from '../..';
import { Sort, SortOrder } from '../../../../types/generalTypes';
import styles from './TableHeader.module.scss';

export interface TableHeaderHeaderProps {
	headerTitle: string;
	isSortable?: boolean;
	accessor: string;
}

export interface TableHeaderProps {
	header: TableHeaderHeaderProps[];
	className?: string;
	cellClassName?: string;
	showCheckBox?: boolean;
	isChecked?: boolean;
	sort?: Sort;
	checkAll?: (check: boolean) => void;
	onFlipOrder?: (accessor: string) => void;
}

export const TableHeader = ({ header, isChecked, className, showCheckBox, cellClassName, sort, checkAll, onFlipOrder }: TableHeaderProps) => {
	const { t } = useTranslation('translation');
	const [isAllChecked, setIsAllChecked] = useState(false);

	useEffect(() => {
		setIsAllChecked(!!isChecked);
	}, [isChecked]);

	return (
		<div className={styles.container}>
			{showCheckBox && (
				<div className={styles.checkBoxCol}>
					<CheckBox isChecked={isAllChecked} setIsChecked={(check) => checkAll && checkAll(check)} />
				</div>
			)}
			<div className={clsx(styles.tableHeader, className)}>
				{header.map((item, index) => (
					<div
						key={index}
						className={clsx(styles.headerSection, item.isSortable && styles.sortable, cellClassName)}
						onClick={() => item.isSortable && onFlipOrder && onFlipOrder(item.accessor)}
					>
						<span>{item.headerTitle && t(item.headerTitle)}</span>
						{item.isSortable && sort ? (
							<div
								className={clsx(
									styles.arrows,
									(item.accessor === sort?.orderBy || sort?.orderBy?.includes(item.accessor)) &&
										(sort.order === SortOrder.ASC ? styles.arrowUp : styles.arrowDown),
								)}
							>
								<Icon imgType='arrow_back' color='neutral700' className={styles.userArrowIcon} height={1} width={1.1} />
							</div>
						) : (
							<div />
						)}
					</div>
				))}
			</div>
			<div style={{ width: '3rem' }} />
		</div>
	);
};
