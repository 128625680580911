import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AccountingEventType, DocumentState } from '@received/pricing-model';
import { InvoiceTable, SummaryBar } from '../../../components';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { InvoiceSummary, InvoiceView } from '../../../types/invoiceTypes';
import { invoicesTabTableHeader } from './InvoicesTab.utils';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { getSortingOrderList, getSummaryBlocksData, getSummaryData } from '../../../utils/InvoiceUtils';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { InvoiceResponse } from '../../../pages/_Billing/Invoices/AllInvoices/AllInvoices.utils';
import styles from '../InvoicesTab/InvoicesTab.module.scss';

const defaultNumberOfItems = '20';

export const InvoicesTab = ({ customerData, isActive, sideBarFilters, refreshTabs, timePeriod, setOpenSideBarById }: CustomerTabProps) => {
	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);

	const [invoicesList, setInvoicesList] = useState<InvoiceView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: ['issueDate', 'draftNumber'], order: SortOrder.ASC });
	const [invoicesSummary, setInvoiceSummary] = useState<InvoiceSummary>();

	const dispatch = useDispatch();

	useEffect(() => {
		isActive && refreshData();
	}, [
		customerData,
		numberOfItems,
		pageNumber,
		sort,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
		isActive,
		refreshTabs,
		timePeriod,
	]);

	const getInvoiceData = async () => {
		try {
			const res: InvoiceResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoicesViewData,
					params: {
						customerIds: [customerData?.id],
						pageSize: parseInt(numberOfItems),
						pageNumber: pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						excludedStates: [DocumentState.CONTRACT_DRAFT],
						fromIssueDate: timePeriod.from,
						toIssueDate: timePeriod.to,
						...sideBarFilters?.filterData,
					},
				})
			).data;
			setTotalCount(res?.count);
			setInvoicesList(res?.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_INVOICE_DATA }));
		}
	};

	const refreshData = async () => {
		await getInvoiceData();
		const summary = await getSummaryData(dispatch, timePeriod.from, timePeriod.to, customerData?.id ? [customerData?.id] : undefined, [
			AccountingEventType.INVOICE,
			AccountingEventType.DEBIT,
		]);
		setInvoiceSummary(summary);
	};

	return (
		<div className={styles.mainContainer}>
			<SummaryBar summary={getSummaryBlocksData(t, invoicesSummary)} blockClassName={styles.blockClassName} />

			<InvoiceTable
				refreshData={refreshData}
				tableProps={{
					sort: sort,
					numberOfItems: numberOfItems,
					header: invoicesTabTableHeader(refreshData),
					rows: invoicesList,
					className: styles.tableContainer,
					numberOfPages: Math.ceil(totalCount / +numberOfItems),
					totalNumberOfItems: totalCount,
					onUpdateNumberOfItems: setNumberOfItems,
					onUpdatePageNumber: setPageNumber,
					onRowPress: (data) => setOpenSideBarById?.({ sideBarType: SidebarType.INVOICE, id: data.invoiceId }),
					onFlipOrder: (accessor) =>
						setSort({ orderBy: getSortingOrderList(accessor), order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }),
				}}
			/>
		</div>
	);
};
