import React, { useEffect, useState } from 'react';
import { Input, InputProps } from '../..';

export const ControlledInput = <T extends HTMLInputElement>({ value, onChange, ...props }: InputProps<T>) => {
	const [text, setText] = useState<any>();

	useEffect(() => {
		setText(value);
	}, [value]);

	const onUpdate = (val: any, event: any) => {
		setText(val);
		onChange && onChange(val, event);
	};

	return <Input {...props} value={text} onChange={onUpdate} />;
};
