import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Icon, MenuList, MenuOption } from '../_uiComponents';
import { BillingDetailsFormType } from './BillingDetails.utils';
import { BillingDetailsForm } from './BillingDetailsForm/BillingDetailsForm';
import styles from './BillingDetails.module.scss';

interface BillingDetailsProps {
	currentBank?: BillingDetailsFormType;
	isOpen: boolean;
	index: number;
	onClick: (isOpen: boolean) => void;
	setCurrentBankData: (data: BillingDetailsFormType) => void;
	onRemove: (index: number) => void;
	onDefault: (index: number) => void;
}

export const BillingDetails = ({ currentBank, isOpen, index, onClick, setCurrentBankData, onRemove, onDefault }: BillingDetailsProps) => {
	const { t } = useTranslation('translation');
	const [openTab, setOpenTab] = useState(false);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);

	const menuOptions: MenuOption[] = [
		{
			title: t('defaultEntity'),
			onPress: () => onDefault(index),
			iconType: 'defaultBadge',
		},
		{ title: t('deleteEntity'), onPress: () => onRemove(index), iconType: 'delete', color: 'destructive' },
	];

	useEffect(() => {
		setOpenTab(isOpen);
	}, [isOpen]);

	const onHeaderClick = () => {
		setOpenTab(!openTab);
		onClick(!openTab);
	};

	return (
		<div className={styles.container}>
			<div className={clsx(styles.flexRowSpaceBetween, styles.detailsContainer, isOpen && styles.openedDetailsContainer)} onClick={onHeaderClick}>
				<div className={clsx(styles.flex)}>
					<div className={styles.square}>
						<Icon imgType='companyFlag' color='neutral300' width={2.8} />
					</div>

					<div className={styles.details}>
						<div className={styles.bankNameContainer}>
							<span className={styles.bankName}>{currentBank?.bank ? currentBank?.bank : t('bankTitlePlaceholder')}</span>
							{currentBank?.isDefault && (
								<span className={styles.default}>
									<span className={styles.delimiter}>{'\xa0'}• </span> {t('default')}
								</span>
							)}

							<Icon imgType='arrow_down' color='black' width={1.1} className={clsx(styles.arrowIcon, isOpen ? styles.arrowUp : styles.arrowDown)} />
						</div>

						<span className={styles.account}>{`${t('account')} ${currentBank?.account ?? '0000000-0000000'} • ${t('branch')} ${
							currentBank?.branch ?? '0000'
						}`}</span>
						<span className={styles.account}>{`${t('iban')} ${currentBank?.iban} • ${t('swift')} ${currentBank?.swift}`}</span>
					</div>
				</div>

				{!currentBank?.isDefault && (
					<MenuList optionsList={menuOptions} isMenuOpen={isOptionsMenuOpen} openMenu={() => setIsOptionsMenuOpen(false)} className={styles.menuList}>
						<Button type='link' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)} className={styles.hamburgerButton}>
							<Icon imgType='hamburger' color='neutral700' className={styles.icon} />
						</Button>
					</MenuList>
				)}
			</div>

			<BillingDetailsForm isOpen={isOpen} data={currentBank} setCurrentBankData={setCurrentBankData} />
		</div>
	);
};
