/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
	CellTypes,
	PricingModelTableRow,
	PricingModelTable,
	tableCalculation,
	Consumer,
	ItemDto,
	PricingModelTableColumn,
	BillingCycleStruct,
	PricingCycleStruct,
	BillingCycleUnit,
} from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { DragAndDropSelectItem, SelectedCellWithIndex } from '..';
import { UpdateUsageProduct, newCellValueSelector, requiredColumnsEnum } from './PricingModelTable.utils';
import { EMPTY_ITEM_ID } from '../../constants/templateConstants';
import { calculateValueByCycle, checkExistingOrCreateItem } from './TableRow/TableRow.utils';
import { PricingModelTab } from '../../types/contractTypes';

// on any changes to this function please update addColumn in shared module or change to use function from shared module to avoid code duplicate
export const onInsertCol = (side: 'RIGHT' | 'LEFT', colIndex: number, pricingModelTable: PricingModelTable) => {
	const checkName = pricingModelTable.columns.filter((col) => col.id.toLocaleLowerCase().replace(/\s/g, '').includes('newcolumn'));
	let defaultCol = { id: 'newcolumn', title: 'New column', type: CellTypes.COUNT, isHidden: false, isHiddenInInvoice: false, isReadOnly: false };

	if (checkName.length) {
		let index = 1;
		checkName.forEach((col) => {
			const newIndex = Number(col.id.toLocaleLowerCase().replace('newcolumn', ''));

			if (newIndex >= index) {
				index = newIndex + 1;
			}
		});
		defaultCol = { ...defaultCol, id: `newcolumn${index}`, title: `New column ${index}` };
	}

	const newColumns = pricingModelTable.columns.map((col) => ({ ...col }));
	newColumns.splice(side === 'LEFT' ? colIndex : colIndex + 1, 0, defaultCol);
	const newTable = {
		columns: newColumns,
		rows: pricingModelTable.rows.map((row) => {
			const newRow = {
				...row,
				cells: {
					...row.cells,
					[defaultCol.id]: {
						cellId: uuidv4(),
						cellType: defaultCol.type,
						cellValue: 0,
						isEditable: true,
						isHidden: false,
						isHiddenInInvoice: false,
						isReadOnly: false,
						refObjectId: undefined,
						refObject: undefined,
					},
				},
			};

			return newRow;
		}),
	};

	return newTable;
};

export const onUpdateHeaderName = (name: string, prevId: string, index: number, pricingModelTable: PricingModelTable) => {
	const requiredColumns = Object.keys(requiredColumnsEnum);
	const id = requiredColumns.includes(pricingModelTable.columns[index].id)
		? pricingModelTable.columns[index].id
		: name.toLocaleLowerCase().replace(/\s/g, '');

	const newTable = {
		columns: [...pricingModelTable.columns],
		rows: pricingModelTable.rows.map((row) => {
			const newRowCells = {
				...row.cells,
				[requiredColumns.includes(pricingModelTable.columns[index].id)
					? pricingModelTable.columns[index].id
					: name.toLocaleLowerCase().replace(/\s/g, '')]: row.cells[prevId],
			};
			!requiredColumns.includes(pricingModelTable.columns[index].id) && delete newRowCells[prevId];

			if (!requiredColumns.includes(pricingModelTable.columns[index].id)) {
				Object.keys(newRowCells).forEach((cell) => {
					if (newRowCells[cell]?.formula) {
						const regex = new RegExp(`\\b${prevId.toLocaleLowerCase()}\\b`, 'g');
						newRowCells[cell].formula = newRowCells[cell].formula?.replaceAll(regex, id);
					}
				});
			}

			const newRow = { ...row, cells: newRowCells };
			return newRow;
		}),
	};

	newTable.columns[index] = {
		...pricingModelTable?.columns[index],
		id,
		title: name,
	};

	return newTable;
};

export const onAddRowStructure = (pricingModelTable: PricingModelTable) => {
	let newRow: PricingModelTableRow = {
		cells: {},
		isHiddenInInvoice: true,
		isHidden: false,
		isSelectedForContract: false,
		itemPrice: { item: { name: '', id: EMPTY_ITEM_ID }, itemId: EMPTY_ITEM_ID, price: 0 },
	};

	if (pricingModelTable.rows.length) {
		const newCells = { ...pricingModelTable.rows[pricingModelTable.rows.length - 1].cells };
		pricingModelTable.columns.forEach((column) => {
			newCells[column.id] = {
				...newCells[column.id],
				cellId: uuidv4(),
				cellValue: column.type === CellTypes.USAGE_AGGREGATOR_REFERENCE ? newCells[column.id].cellValue : newCellValueSelector(column.type),
				cellType: column.type,
				refObjectId: column.type === CellTypes.USAGE_AGGREGATOR_REFERENCE ? newCells[column.id].refObjectId : undefined,
				refObject: column.type === CellTypes.USAGE_AGGREGATOR_REFERENCE ? newCells[column.id].refObject : undefined,
			};
		});

		newRow = { ...pricingModelTable.rows[pricingModelTable.rows.length - 1], isHiddenInInvoice: true, cells: newCells };
	} else {
		const newCells = { ...newRow.cells };
		pricingModelTable.columns.forEach((column) => {
			newCells[column.id] = {
				cellId: uuidv4(),
				cellValue: newCellValueSelector(column.type),
				cellType: column.type,
				refObjectId: undefined,
				refObject: undefined,
				formula: column.type === CellTypes.FORMULA ? '' : undefined,
			};
		});

		newRow = {
			...newRow,
			cells: newCells,
		};
	}
	return { ...pricingModelTable, rows: [...pricingModelTable.rows, newRow], columns: [...pricingModelTable.columns] };
};

export const onUpdateRowForColumnType = (cellType: CellTypes, pricingModelTable: PricingModelTable, selectedCell: SelectedCellWithIndex) => {
	const newRows = pricingModelTable.rows.map((row) => {
		const newRow = { ...row, cells: { ...row.cells } };
		newRow.cells[selectedCell.columnId].cellValue = newCellValueSelector(cellType);
		newRow.cells[selectedCell.columnId].cellType = cellType;
		if (cellType === CellTypes.FORMULA) {
			newRow.cells[selectedCell.columnId].formula = newRow.cells[selectedCell.columnId]?.formula || '';
		} else {
			newRow.cells[selectedCell.columnId].formula = undefined;
		}
		return newRow;
	});

	return newRows;
};

export const onAddRow = async (
	pricingModelTab: PricingModelTab | undefined,
	pricingModelTable: PricingModelTable,
	setSelectedCell: React.Dispatch<React.SetStateAction<SelectedCellWithIndex | undefined>>,
	itemsList: ItemDto[],
	dispatch: Dispatch,
	updateTableData: ((data: PricingModelTable) => void) | undefined,
	item?: DragAndDropSelectItem,
) => {
	setSelectedCell(undefined);
	const newTable = onAddRowStructure(pricingModelTable);
	if (item) {
		const rowIndex = newTable.rows.length ? newTable.rows.length - 1 : 0;
		newTable.rows[rowIndex].itemPriceId = item.item.itemPriceId;

		const newItem = await checkExistingOrCreateItem(itemsList, item.item.name, dispatch);
		newTable.rows[rowIndex].itemPrice = {
			id: item.item.itemPriceId,
			itemId: newItem.id,
			item: newItem,
			price: +item.item.unitPrice,
			currency: pricingModelTab?.currency,
		};

		newTable.rows[rowIndex].cells[requiredColumnsEnum.item].cellValue = item.item.name;
		newTable.rows[rowIndex].cells[requiredColumnsEnum.price].cellValue = item.item.unitPrice;
	}
	const { table } = tableCalculation(newTable, Consumer.RECEIVED_UI);
	updateTableData?.(table);
};

export const onUpdateUsageProduct = (
	{ usageAggregator, column, columnIndex, rowIndex = -1 }: UpdateUsageProduct,
	pricingModelTable: PricingModelTable,
	updateTableData: ((data: PricingModelTable) => void) | undefined,
	setUsageEditorData: React.Dispatch<React.SetStateAction<any>>,
) => {
	const newRowData = { ...pricingModelTable };
	newRowData.columns = [...pricingModelTable.columns];
	if (rowIndex >= 0) {
		const newRow = { ...newRowData.rows[rowIndex], cells: { ...newRowData.rows[rowIndex].cells } };
		newRow.cells[column.id].refObjectId = usageAggregator?.id;
		newRow.cells[column.id].refObject = usageAggregator;
		newRowData.rows[rowIndex] = newRow;
	} else {
		newRowData.rows = pricingModelTable.rows.map((row) => {
			const newRow = { ...row, cells: { ...row.cells } };
			newRow.cells[column.id].refObjectId = usageAggregator?.id;
			newRow.cells[column.id].refObject = usageAggregator;

			return newRow;
		});
	}

	setUsageEditorData(undefined);
	updateTableData?.(newRowData);
};

export const onDeleteCol = (
	colIndex: number,
	columns: PricingModelTableColumn[],
	pricingModelTable: PricingModelTable,
	updateTableData: ((data: PricingModelTable) => void) | undefined,
) => {
	if (pricingModelTable) {
		const newTable = {
			columns: pricingModelTable.columns.filter((col) => col.id !== columns[colIndex].id),
			rows: pricingModelTable.rows.map((row) => {
				delete row.cells[columns[colIndex].id];

				return row;
			}),
		};
		updateTableData?.(newTable);
	}
};

export const pricingAndBillingUpdateTableCalculation = ({
	pricingModelTab,
	newBillingCycle,
	newPricingCycle,
	isFromContract,
}: {
	pricingModelTab: PricingModelTab;
	newBillingCycle?: BillingCycleStruct;
	newPricingCycle?: PricingCycleStruct;
	isFromContract?: boolean;
}): PricingModelTable => {
	const newRows = pricingModelTab?.pricingModelTable.rows.map((row) => {
		const newRow = { ...row, cells: { ...row.cells } };
		Object.keys(row.cells).forEach((colId) => {
			if (colId === pricingModelTab?.pricingCycleActivation.toLocaleLowerCase()) {
				let prevPricingCycleValue = null;
				if (
					isFromContract
						? newBillingCycle?.unit != BillingCycleUnit.ONE_TIME &&
						  // @ts-ignore
						  pricingModelTab.billingCycle?.unit != pricingModelTab.pricingCycle?.unit
						: // @ts-ignore
						  pricingModelTab.billingCycle?.unit != pricingModelTab.pricingCycle?.unit
				) {
					// When switching between cycles need the prev calculated
					// value that is seen and updated in the table but not saved
					// because we save in cellValue the billing that used to formula calculation
					prevPricingCycleValue = calculateValueByCycle(row.cells[colId].cellValue, pricingModelTab, true);
				}
				row.cells[colId].cellValue = calculateValueByCycle(
					prevPricingCycleValue || row.cells[colId].cellValue,
					{
						...pricingModelTab,
						billingCycle: newBillingCycle || pricingModelTab.billingCycle,
						// @ts-ignore
						pricingCycle: newPricingCycle || pricingModelTab?.pricingCycle,
					},
					false,
				);
			}
		});
		return newRow;
	});

	const newTable = { ...pricingModelTab?.pricingModelTable, rows: newRows };
	const { table } = tableCalculation(newTable, Consumer.RECEIVED_UI);

	return table;
};

export const onMoveColumn = (side: 'RIGHT' | 'LEFT', colIndex: number, pricingModelTable: PricingModelTable) => {
	const table = { columns: [...pricingModelTable.columns], rows: [...pricingModelTable.rows] };

	const currentCol = table.columns[colIndex];
	const replaceWithColIndex = side === 'RIGHT' ? colIndex + 1 : colIndex - 1;
	const replaceWithCol = table.columns[replaceWithColIndex];

	table.columns[replaceWithColIndex] = { ...currentCol };
	table.columns[colIndex] = { ...replaceWithCol };

	return table;
};
