import { BillingCycleStruct, PricingCycleStruct, PricingCycleUnit } from '@received/pricing-model';

export const getCustomBillingCycleLabel = (t: any, cycle?: PricingCycleStruct | BillingCycleStruct) => {
	if (cycle?.cycle === 1) {
		return cycle?.unit;
	}
	switch (cycle?.unit) {
		case PricingCycleUnit.MONTHLY:
			return `${t('every')} ${cycle?.cycle} ${t('months')}`;
		case PricingCycleUnit.QUARTERLY:
			return `${t('every')} ${cycle?.cycle} ${t('quarters')}`;
		case PricingCycleUnit.YEARLY:
			return `${t('every')} ${cycle?.cycle} ${t('years')}`;
	}
};
