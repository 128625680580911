import React, { useEffect, useState } from 'react';
import { ContractState, ContractType, DocumentState } from '@received/pricing-model';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { plainToInstance } from 'class-transformer';
import { Button, Icon, MenuList, MenuOption, Tabs } from '../..';
import { contractsTabs, tabItem } from './ContractSideBar.utils';
import { Contract } from '../../../types/contractTypes';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { getBanks, SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { getSuppliers } from '../../../utils/CustomerUtils';
import { SideBarHeader } from '../components';
import { onSplitInvoice } from '../InvoicesSideBar/InvoicesSideBar.utils';
import { BillingAccountDetails, Customer } from '../../../types/customerTypes';
import { contractMenuOptions, contractTitleByType } from '../../../utils/ContractUtils';
import { getSortingOrderList } from '../../../utils/InvoiceUtils';
import { subscriptionsMenuOptions } from '../../_customerTabs/SubscriptionsTab/SubscriptionsTab.utils';
import { AgreementData, ContractData } from './components';
import styles from './ContractSideBar.module.scss';

interface SingleCustomerSideBarProps {
	id?: string;
	isOpen?: boolean;
	isSecondarySideBar?: boolean;
	selectedRowId?: string;
	refreshData?: boolean;
	sidebarToBeNoticed: boolean;
	setSidebarToBeNoticed: () => void;
	closeSideBar(): void;
	onDataChange?: () => void;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	closeSecondarySidebar: () => void;
}

export const ContractSideBar = ({
	id,
	isOpen = true,
	isSecondarySideBar,
	selectedRowId,
	sidebarToBeNoticed,
	refreshData,
	setSidebarToBeNoticed,
	closeSideBar,
	openOtherSidebar,
	onDataChange,
	closeSecondarySidebar,
}: SingleCustomerSideBarProps) => {
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [contractData, setContractData] = useState<Contract>();
	const [activeTab, setActiveTab] = useState(0);
	const [tabs, setTabs] = useState<tabItem[]>(contractsTabs());
	const [suppliers, setSuppliers] = useState<Customer[]>();
	const [banks, setBanks] = useState<BillingAccountDetails[]>();
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [isEditPoNumber, setIsEditPoNumber] = useState(false);

	const canEditPo = !contractData?.editBlockers?.length;
	const isPartner = !!contractData?.contractType && [ContractType.REFERRAL, ContractType.RESELLER].includes(contractData?.contractType);

	useEffect(() => {
		if (isOpen) {
			id && getData(id);
			setBanksData();
			setSuppliersData();
			setActiveTab(0);
		}
	}, [id, isOpen, refreshData]);

	const setBanksData = async () => {
		const banks = await getBanks(dispatch);
		setBanks(banks);
	};

	const setSuppliersData = async () => {
		const suppliers = await getSuppliers(dispatch);
		setSuppliers(suppliers);
	};

	const getData = async (contractId: string) => {
		try {
			const contractData = (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.getContractData,
					urlParam: { id: contractId },
				})
			).data;
			setContractData(contractData);

			setTabs(contractsTabs(contractData));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_DATA }));
		}
	};

	const updateContractPoNumber = async (poNumber: string) => {
		try {
			const newContractData = { ...contractData, poNumber };

			const res = await httpService({
				dispatch,
				path: PathsConfig.updateContract,
				data: newContractData,
				showLoader: false,
			});
			await publishContract(res.data);
			setIsEditPoNumber(false);
			onDataChange?.();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_DATA }));
		}
	};

	const publishContract = async (contractData: Contract) => {
		try {
			const transformedContractData = plainToInstance(Contract, contractData);
			const updatedContractData = (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.publishContract,
					data: transformedContractData,
				})
			).data;
			setContractData(updatedContractData);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onEditInvoice = (invoiceId?: string) => {
		invoiceId && navigate('../invoices/single-invoice', { state: { InvoiceId: invoiceId } });
	};

	const onSplit = async (invoiceId?: string) => {
		await onSplitInvoice(dispatch, invoiceId, onDataChange);
	};

	const getSplittedInvoicesByContract = async (searchText: string, pageNumber: number, splittedOnly?: boolean) => {
		if (!contractData?.id) return;
		try {
			return (
				await httpService({
					dispatch,
					path: PathsConfig.getInvoicesViewData,
					params: {
						contractId: contractData?.id,
						pageSize: 50,
						pageNumber,
						orderBy: getSortingOrderList('issueDate'),
						order: 'ASC',
						searchText,
						excludedStates: [DocumentState.CONTRACT_DRAFT],
						splittedOnly,
					},
				})
			).data;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_SPLIT_INVOICES }));
		}
	};

	const onClose = () => {
		setActiveTab(0);
		setIsEditPoNumber(false);
		return isSecondarySideBar ? closeSecondarySidebar?.() : closeSideBar();
	};

	const menuByContractType = () => {
		switch (contractData?.contractType) {
			case ContractType.ENTERPRISE:
			case ContractType.REFERRAL:
			case ContractType.RESELLER:
				return enterpriseMenuList();
			case ContractType.SUBSCRIPTION:
				return subscriptionsMenuOptions(contractData, t, dispatch, onDataChange);
			default:
				return [];
		}
	};

	const enterpriseMenuList = () => {
		let list: MenuOption[] = contractData
			? contractMenuOptions(
					contractData,
					t,
					dispatch,
					() => navigate('../contract', { state: { contractId: contractData?.id } }),
					onDataChange,
					closeSideBar,
			  )
			: [];
		if (canEditPo && contractData?.state != ContractState.CANCELED) {
			list = [
				{
					title: t('editPoNumber'),
					onPress: () => setIsEditPoNumber(true),
					iconType: 'rename',
				},
				...list,
			];
		}
		return list;
	};

	const navigateByContractType = () => {
		switch (contractData?.contractType) {
			case ContractType.SUBSCRIPTION:
				return openOtherSidebar({ sidebarType: SidebarType.ASSIGN_SUBSCRIPTION, id: contractData?.id });
			case ContractType.ENTERPRISE:
				return navigate('/contract', { state: { contractId: contractData?.id } });
			case ContractType.RESELLER:
				return navigate('../reseller-agreement', { state: { contractId: contractData?.id } });
			case ContractType.REFERRAL:
				return navigate('../referral-agreement', { state: { contractId: contractData?.id } });
		}
	};

	const getContractDataByType = () => {
		switch (contractData?.contractType) {
			case ContractType.SUBSCRIPTION:
			case ContractType.ENTERPRISE:
				return <ContractData contractData={contractData} suppliers={suppliers} banks={banks} />;
			case ContractType.REFERRAL:
			case ContractType.RESELLER:
				return <AgreementData agreementData={contractData} suppliers={suppliers} banks={banks} />;
		}
	};
	return (
		<>
			{isOpen && <div onClick={onClose} className={clsx(isSecondarySideBar ? styles.secondaryModalOverlay : styles.modalOverlay)} />}
			<div
				className={clsx(
					!isOpen ? styles.sidebarHidden : styles.sidebar,
					isSecondarySideBar && styles.secondarySidebar,
					sidebarToBeNoticed && styles.noticeMe,
				)}
				onAnimationEnd={setSidebarToBeNoticed}
				style={{ overflow: 'scroll' }}
			>
				<div data-testid='contract-side-bar' className={styles.sidebarContent}>
					<div className={styles.topSection}>
						<div className={styles.titleContainer}>
							<SideBarHeader
								sidebarType={t(contractTitleByType(contractData?.contractType))}
								customer={contractData?.customer}
								prefix={contractData?.poNumber ? '' : 'ID'}
								documentNumber={contractData?.poNumber || contractData?.contractNumber?.toString()}
								isEditable={isEditPoNumber && canEditPo}
								hideWarning
								customerTitle={contractData?.name}
								placeholder='0000'
								syncIcon={contractData?.syncStatus?.length ? getSyncStatus(contractData)?.icon : undefined}
								updateValue={updateContractPoNumber}
								onStopWithoutEdit={() => setIsEditPoNumber(false)}
								inputType='text'
							/>

							<Button
								type='primary'
								onClick={navigateByContractType}
								disabled={contractData?.state == ContractState.DRAFT && !!contractData?.editBlockers?.length}
								className={styles.button}
							>
								{t('edit')}
								<Icon imgType={'edit'} color='white' className={styles.buttonIcon} />
							</Button>

							{menuByContractType().length && (
								<MenuList optionsList={menuByContractType()} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
									<Button
										dataTestId='contract-side-bar-humbugger'
										type='outlined'
										color='neutral'
										onClick={() => setOpenMenuOptions(true)}
										className={styles.hamburgerButton}
									>
										<Icon
											imgType='hamburger'
											height={2}
											color='neutral700'
											className={clsx(styles.hamburgerButton, openMenuOptions && styles.hamburgerRotate)}
										/>
									</Button>
								</MenuList>
							)}
						</div>
						{getContractDataByType()}
					</div>

					<div className={styles.tabsSection}>
						<Tabs
							tabsArray={tabs.map((tab) => tab.title)}
							chosenTab={activeTab}
							updateChosenTab={(val: number) => setActiveTab(val)}
							classNames={styles}
						/>
						<div className={styles.tabsContent}>
							{tabs[activeTab].component({
								banks,
								contractData,
								selectedRowId,
								sidebarType: SidebarType.CONTRACT,
								isSecondarySideBar,
								title: isPartner ? t('programs') : t('products'),
								suppliers: suppliers,
								openOtherSidebar,
								onEditInvoice,
								onSplit,
								getSplittedInvoices: getSplittedInvoicesByContract,
								onUsageRowPress: openOtherSidebar,
								onDataChange,
								onSideBarRefresh: () => {
									id && getData(id);
									onDataChange?.();
								},
							})}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
