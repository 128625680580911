import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../components';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Store } from '../../../types/storeTypes';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { payoutsTableHeader } from './PayoutsTab.utils';
import { PayoutView, PayoutsResponse } from '../../../types/PayoutsTypes';
import styles from './PayoutsTab.module.scss';
import { SidebarType } from '../../Sidebars/Sidebars.utils';

const defaultNumberOfItems = '20';

export const PayoutsTab = ({ isActive, customerData, sideBarFilters, refreshTabs, timePeriod, setOpenSideBarById }: CustomerTabProps) => {
	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);

	const [payoutsList, setPayoutsList] = useState<PayoutView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: 'state', order: SortOrder.DESC });

	const dispatch = useDispatch();
	const location: any = useLocation();

	useEffect(() => {
		isActive && customerData?.id && getPayoutsData();
	}, [
		customerData,
		customerData?.id,
		numberOfItems,
		pageNumber,
		sort.orderBy,
		sort.order,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
		isActive,
		refreshTabs,
		timePeriod,
	]);

	useEffect(() => {
		location?.state?.filterByState && setSort((prev) => ({ ...prev, orderBy: 'state' }));
	}, []);

	const getPayoutsData = async (page?: number, items?: string) => {
		try {
			const res: PayoutsResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getPayoutsViewData,
					params: {
						customerId: customerData?.id,
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						fromIssueDate: timePeriod.from,
						toIssueDate: timePeriod.to,
						...sideBarFilters?.filterData,
					},
				})
			).data;

			setTotalCount(res?.count);
			setPayoutsList(res.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.container}>
			<Table
				sort={sort}
				header={payoutsTableHeader(getPayoutsData)}
				rows={payoutsList}
				className={styles.tableContainer}
				numberOfPages={Math.ceil(totalCount / +numberOfItems)}
				totalNumberOfItems={totalCount}
				numberOfItems={numberOfItems}
				onUpdateNumberOfItems={setNumberOfItems}
				onUpdatePageNumber={setPageNumber}
				onRowPress={(data) => setOpenSideBarById?.({ sideBarType: SidebarType.PAYOUT, id: data.id })}
				onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				emptyStateProps={{
					title: t('noPayoutsFound'),
					description: t('noAgreementsFoundDescription'),
				}}
			/>
		</div>
	);
};
