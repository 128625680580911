import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Customer, DATE_FORMAT } from '@received/pricing-model';
import { useDispatch } from 'react-redux';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { Payout } from '../../../types/PayoutsTypes';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { getSupplierById } from '../../../utils/CustomerUtils';
import { PDFPreviewModal } from '../../PDFPreviewModal/PDFPreviewModal';
import { SideBarHeader } from '../components';
import { getDocumentNumberPrefix, getEmailsLabel } from '../../../utils/GeneralUtils';
import { Button, DatePickerFormatType, Icon, MenuList, Tabs } from '../../_uiComponents';
import { initialPayout, payoutsTabs } from './PayoutSideBar.utils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { startGeneratePayoutPDFById } from '../../../utils/PayoutUtils';
import { payoutsMenuOptions } from '../../../pages/_Billing/Payouts/Payouts.utils';
import { NavigateToDifferentSingleDocumentPage } from '../../../types/generalTypes';
import { PayoutStatusTag } from '../../StatusTags';
import styles from './PayoutSideBar.module.scss';

interface PayoutSideBarProps {
	id?: string;
	isOpen?: boolean;
	isSecondarySideBar?: boolean;
	navigateToDifferentSingleDocumentPage?: NavigateToDifferentSingleDocumentPage;
	refreshData?: boolean;
	selectedRowId?: string;
	sidebarToBeNoticed: boolean;
	containersClassName?: string;
	setSidebarToBeNoticed: () => void;
	closeSideBar(): void;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	onDataChange: () => void;
	closeSecondarySidebar: () => void;
}

export const PayoutSideBar = ({
	id,
	isOpen,
	isSecondarySideBar,
	selectedRowId,
	sidebarToBeNoticed,
	containersClassName,
	navigateToDifferentSingleDocumentPage,
	refreshData,
	setSidebarToBeNoticed,
	closeSideBar,
	openOtherSidebar,
	onDataChange,
	closeSecondarySidebar,
}: PayoutSideBarProps) => {
	const { t } = useTranslation('translation');
	const [payout, setPayout] = useState<Payout>(initialPayout);
	const [activeTab, setActiveTab] = useState(0);
	const [openPreviewSideBar, setOpenPreviewSideBar] = useState(false);
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [supplier, setSupplier] = useState<Customer>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			getPayoutData(id);
		}
	}, [id, isOpen, refreshData]);

	useEffect(() => {
		setOpenPreviewSideBar(false);
	}, [activeTab]);

	const getPayoutData = async (id?: string) => {
		if (!id) return;

		try {
			const data: Payout = (
				await httpService({
					showLoader: false,
					dispatch,
					path: PathsConfig.getPayoutById,
					urlParam: { id },
				})
			).data;

			data?.supplierId && (await setSupplierData(data?.supplierId));

			setPayout(data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_PAYOUT_DETAILS }));
		}
	};

	const setSupplierData = async (supplierId?: string) => {
		const supplier = await getSupplierById(dispatch, supplierId);
		setSupplier(supplier);
	};

	const onClose = () => {
		setActiveTab(0);
		setOpenPreviewSideBar(false);
		return isSecondarySideBar ? closeSecondarySidebar?.() : closeSideBar();
	};

	const onEdit = () => {
		if (id && navigateToDifferentSingleDocumentPage?.originalDocumentId) {
			navigateToDifferentSingleDocumentPage?.onClick?.(id);
		} else {
			navigate('../payouts/single-payout', { state: { payoutId: payout?.id } });
		}
	};

	return (
		<>
			{isOpen && <div onClick={onClose} className={clsx(isSecondarySideBar ? styles.secondaryModalOverlay : styles.modalOverlay)} />}
			<div
				data-testid='payout-side-bar'
				className={clsx(
					!isOpen ? styles.sidebarHidden : isSecondarySideBar ? styles.secondarySidebar : styles.sidebar,
					isOpen && containersClassName,
					sidebarToBeNoticed && styles.noticeMe,
				)}
				onAnimationEnd={setSidebarToBeNoticed}
				style={{ overflow: 'scroll' }}
			>
				<div className={styles.sidebarContent}>
					<div className={styles.topContainer}>
						<div className={styles.header}>
							<SideBarHeader
								sidebarType={t('payout')}
								customer={payout?.customer}
								prefix={getDocumentNumberPrefix(payout)}
								documentNumber={payout?.documentNumber || payout?.draftNumber}
							/>

							<Button onClick={() => navigate('../payouts/single-payout', { state: { payoutId: payout?.id } })} className={styles.button}>
								{t(!payout?.editBlockers?.length ? 'edit' : 'view')}
							</Button>

							<MenuList
								optionsList={payoutsMenuOptions({
									t,
									payout,
									dispatch,
									onEdit,
									navigate,
									onRefresh: () => onDataChange(),
									onPreview: () => setOpenPreviewSideBar(true),
								})}
								isMenuOpen={openMenuOptions}
								openMenu={() => setOpenMenuOptions(false)}
							>
								<Button
									data-testid='payout-side-bar-hamburger'
									type='link'
									color='neutral'
									onClick={() => setOpenMenuOptions(true)}
									className={styles.hamburgerButtonWrapper}
								>
									<Icon
										imgType='hamburger'
										height={2}
										color='neutral700'
										className={clsx(styles.hamburgerButton, openMenuOptions && styles.hamburgerRotate)}
									/>
								</Button>
							</MenuList>
						</div>

						<div className={styles.detailsRowContainer}>
							<div className={clsx(styles.detailsRow, styles.flex)}>
								<span className={styles.detailsText}>{t('partner')}</span>
								<span
									data-testid='payout-side-bar-partner-name'
									className={clsx(styles.blackText, styles.ellipsis, styles.link)}
									style={{ marginRight: '0.4rem' }}
									onClick={() => payout?.customer && navigate(`/partners/single-referral/${payout?.customer.id}`)}
								>
									{payout?.customer?.name}{' '}
								</span>
								<span className={clsx(styles.greyText, styles.ellipsis)}>{` | ${getEmailsLabel(payout?.customer?.email)}`}</span>
							</div>

							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('issued')}</span>
								<span data-testid='payout-side-bar-issue-date' className={styles.detailValue}>
									{payout?.issueDate && dayjs(payout?.issueDate).format(DATE_FORMAT)}
								</span>
							</div>

							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('dueDate')}</span>
								<span data-testid='payout-side-bar-payout-due-date' className={styles.detailValue}>
									{payout?.dueDate && dayjs(payout?.dueDate).format(DATE_FORMAT)}
								</span>
							</div>

							<div className={clsx(styles.detailsRow, styles.flex)}>
								<span className={styles.detailsText}>{t('entity')}</span>
								<span
									data-testid='payout-side-bar-supplier-name'
									className={clsx(styles.blackText, styles.ellipsis)}
									style={{ marginRight: '0.4rem' }}
								>
									{supplier?.name || '-'}
								</span>

								{payout?.billingDetails?.billingAccountId && (
									<span data-testid='invoice-side-bar-bank' className={clsx(styles.blackText, styles.ellipsis)}>{` | ${
										payout?.supplier?.billingDetails?.billingAccounts?.find((bank) => bank.id === payout?.billingDetails?.billingAccountId)?.bank ||
										''
									}`}</span>
								)}
							</div>

							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('createdAt')}</span>
								<span data-testid='payout-side-bar-created-at' className={styles.detailValue}>
									{payout?.createdAt && dayjs(payout?.createdAt).format(DATE_FORMAT)}
								</span>
							</div>

							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('agreementno')}</span>
								<span
									data-testid='payout-side-bar-agreement-id'
									className={clsx(styles.detailValue, payout?.contractId && styles.link)}
									onClick={() =>
										payout?.contractId &&
										openOtherSidebar({ sidebarType: SidebarType.CONTRACT, triggeredBy: SidebarType.PAYOUT, id: payout?.contractId })
									}
								>
									{contractIdFormat(payout?.poNumber, payout?.contractNumber)}
								</span>
							</div>

							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('status')}</span>
								<PayoutStatusTag document={payout} refreshData={getPayoutData} />
							</div>
						</div>
					</div>
					<div className={styles.tabsSection}>
						<Tabs
							tabsArray={payoutsTabs(payout).map((tab) => tab.title)}
							chosenTab={activeTab}
							updateChosenTab={(val: number) => setActiveTab(val)}
							classNames={styles}
						/>
						<div className={styles.tabsContent}>
							{payoutsTabs(payout)[activeTab].component({
								payoutData: payout,
								selectedRowId,
								hasUsageReport: !!payout?.usageReportsByProduct?.length,
								openPreviewSideBar: () => setOpenPreviewSideBar(!openPreviewSideBar),
								openOtherSidebar,
								onDataChange,
								onUsageRowPress: openOtherSidebar,
								onPressUpdateUsage: (timePeriod?: DatePickerFormatType) =>
									openOtherSidebar({
										sidebarType: SidebarType.USAGE_REPORT,
										triggeredBy: SidebarType.PAYOUT,
										id: payout?.id,
										usagePeriod: {
											fromDate: timePeriod?.fromDate || null,
											toDate: timePeriod?.toDate || null,
										},
									}),
							})}
						</div>
					</div>
				</div>

				<PDFPreviewModal
					isOpen={openPreviewSideBar}
					documentsList={[payout]}
					onClose={() => setOpenPreviewSideBar(false)}
					onDownload={() => startGeneratePayoutPDFById(dispatch, payout?.id)}
				/>
			</div>
		</>
	);
};
