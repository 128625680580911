import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IntegrationProvider, IntegrationStatus } from '@received/pricing-model';
import { images } from '../../../../constants/images';
import { IntegrationType } from '../../../../types/integrationTypes';
import { convertIntegrationTypeToDescription, convertIntegrationTypeToStringService } from '../../../../utils/IntegrationUtils';
import { Button, ConnectModal, StripeConnectModal } from '../../..';
import { PermissionsMapping } from '../../../../services/PermissionsMapping/PermissionsMapping';
import styles from './IntegrationBySupplierCard.module.scss';

interface IntegrationBySupplierCardProps extends IntegrationType {
	image: string;
	openModal?: boolean;
	onConnect(supplierId: string, integrationAccountId?: string, existing?: boolean): void;
	onDisconnect?(id: string, integrationAccountId?: string): Promise<void>;
	onClickEntity?(supplierId: string, connectionStatus: IntegrationStatus): void;
	onCancel?(supplierId: string, integrationAccountId: string): void;
	onMissingDetails?(integrationAccountId: string): void;
}

export const IntegrationBySupplierCard = ({
	image,
	platform,
	iconSrc,
	connectedCount,
	totalCount,
	provider,
	openModal,
	onConnect,
	onDisconnect,
	onClickEntity,
	onCancel,
	onMissingDetails,
}: IntegrationBySupplierCardProps) => {
	const { t } = useTranslation('translation');
	const navigate = useNavigate();
	const [openConnectModal, setOpenConnectModal] = useState(false);

	useEffect(() => {
		setOpenConnectModal(!!openModal);
	}, [openModal]);

	const getConnectModalByProvider = () => {
		switch (provider) {
			case IntegrationProvider.STRIPE:
				return (
					<StripeConnectModal
						isOpen={openConnectModal}
						platform={platform}
						image={image}
						onConnect={onConnect}
						onCancel={onCancel}
						onMissingDetails={onMissingDetails}
						closeModal={() => {
							navigate('.', { replace: true });
							setOpenConnectModal(false);
						}}
					/>
				);
			default:
				return (
					<ConnectModal
						isOpen={openConnectModal}
						platform={platform}
						image={image}
						onConnect={onConnect}
						onDisconnect={onDisconnect}
						onClickEntity={onClickEntity}
						onCancel={onCancel}
						onMissingDetails={onMissingDetails}
						closeModal={() => {
							navigate('.', { replace: true });
							setOpenConnectModal(false);
						}}
					/>
				);
		}
	};

	return (
		<>
			{getConnectModalByProvider()}
			<div className={styles.card}>
				<div className={styles.topContainer}>
					<div className={styles.iconContainer}>
						<img alt='cardIcon' src={images[iconSrc]} className={styles.cardIcon}></img>
					</div>
					{!!connectedCount && +connectedCount > 0 && (
						<span className={styles.connectedTag}> {`${t('connected')} ${connectedCount}/${totalCount}`}</span>
					)}
				</div>
				<h2>{t(convertIntegrationTypeToStringService(platform))}</h2>
				<span className={styles.cardDescription}>{t(convertIntegrationTypeToDescription(platform))}</span>
				<PermissionsMapping
					actionType={platform}
					disabledFeatureChildren={
						<Button disabled type='outlined' color='neutral' className={styles.cardButton}>
							{t('comingSoon')}
						</Button>
					}
				>
					<Button type='outlined' color='neutral' onClick={() => setOpenConnectModal(true)} className={styles.cardButton}>
						{t('configuration')}
					</Button>
				</PermissionsMapping>
			</div>
		</>
	);
};
