import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { summaryType } from '../../types/invoiceTypes';
import { SummaryBlock, SummaryBlockProps } from '../SummaryBlock/SummaryBlock';
import styles from './SummaryBar.module.scss';

export interface SummaryBarProps {
	summary?: SummaryBlockProps[];
	small?: boolean;
	className?: string;
	blockClassName?: string;
	selectedSummary?: summaryType;
	hideDiviner?: boolean;
	onClick?(type?: summaryType): void;
}

export const SummaryBar = ({ summary, small, className, blockClassName, selectedSummary, hideDiviner, onClick }: SummaryBarProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.summarySection, className)}>
			{summary?.map((itemSummary: SummaryBlockProps, index: number) => (
				<div key={index} className={clsx(styles.box)}>
					<SummaryBlock
						key={index}
						{...itemSummary}
						title={t(itemSummary.title)}
						small={small}
						summaryBlockStyles={{ className: blockClassName }}
						isSelected={itemSummary.type === selectedSummary}
						onClick={() => onClick && onClick(itemSummary?.type)}
						isPressable={!!onClick}
					/>
					{summary.length != index + 1 && !hideDiviner && <div className={styles.diviner} />}
				</div>
			))}
		</div>
	);
};
