import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DocumentState } from '@received/pricing-model';
import { StackBarGraph, ClickableCardsList, Table } from '../../../components';
import { GraphState, ProductAnalyticsList, Sort, SortOrder } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { initialGraphState } from '../../../pages/Dashboard/Dashboard.utils';
import { LatestActivitiesTableHeader, PartnerOverviewSummary, partnerOverViewSummaryInitialState } from './PartnerOverviewTab.utils';
import { GraphsDataResponse, OverviewProduct } from '../../../pages/Contracts/ContractComponent/ContractOverview/ContractOverview.utils';
import { ProductContractRes } from '../../../types/ProductTypes';
import { cashTimeUnitHandler } from '../../../utils/DateUtils';
import { stackBarDataHandler } from '../../_featureComponents/RevenueRecognition/RevenueRecognition.utils';
import { PartnerOverviewTabSummary } from './components';
import { PayoutView, PayoutsResponse } from '../../../types/PayoutsTypes';
import { productsColorsList } from '../../../utils/GeneralUtils';
import styles from './PartnerOverviewTab.module.scss';

export const PartnerOverviewTab = ({ isActive, customerData, timePeriod, sideBarFilters, refreshTabs, setOpenSideBarById }: CustomerTabProps) => {
	const [summary, setSummary] = useState<PartnerOverviewSummary>(partnerOverViewSummaryInitialState);
	const [sort, setSort] = useState<Sort>({ orderBy: 'updatedAt', order: SortOrder.DESC });

	//GRAPHS
	const [productsBreakdown, setProductsBreakdown] = useState<GraphState>(initialGraphState);

	//PRODUCTS
	const [breakdownProducts, setBreakdownProducts] = useState<OverviewProduct[]>([]);

	//TABLE
	const [payoutsList, setPayoutsList] = useState<PayoutView[]>([]);

	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);
	const dispatch = useDispatch();

	useEffect(() => {
		customerData?.id && getProducts();
	}, [customerData]);

	useEffect(() => {
		customerData?.id && isActive && breakdownProducts && getSummary();
	}, [refreshTabs, customerData?.id, timePeriod, sideBarFilters.filterData, sideBarFilters.filterTags, toastNotification.showToastNotification]);

	useEffect(() => {
		getPayouts();
	}, [sort]);

	useEffect(() => {
		handleProductsBreakdownData();
	}, [breakdownProducts]);

	useEffect(() => {
		customerData?.id && onRefresh();
	}, [timePeriod]);

	const getProducts = async () => {
		try {
			const res: ProductContractRes[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractProductsList,
					params: {
						customerId: customerData?.id,
					},
				})
			).data;

			const allProducts = res?.flatMap((prod, index) => ({
				id: prod.productId,
				isSelected: true,
				color: productsColorsList[index % productsColorsList?.length].textColor || '',
				productName: prod.productName,
			}));

			setBreakdownProducts(allProducts);
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSummary = async () => {
		try {
			const summary: PartnerOverviewSummary = (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.getSinglePartnerSummary,
					params: {
						partnerId: customerData?.id,
						...sideBarFilters?.filterData,
					},
				})
			).data;
			setSummary(summary);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getPayouts = async () => {
		try {
			const res: PayoutsResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getPayoutsViewData,
					params: {
						orderBy: sort.orderBy,
						order: sort.order,
						excludedStates: [DocumentState.CONTRACT_DRAFT],
						customerId: customerData?.id,
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
					},
				})
			).data;

			setPayoutsList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_PAYOUTS_LIST }));
		}
	};

	const getProductsBreakdownData = async () => {
		try {
			const selectedProducts = breakdownProducts.filter((product) => product.isSelected);
			if (!selectedProducts.length) {
				return { entries: [] };
			}

			return (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.getBreakDownProductsData,
					params: {
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						customerId: customerData?.id,
						productIds: selectedProducts.map((product) => product.id),
						timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to),
					},
				})
			).data;
		} catch (error) {
			setProductsBreakdown(initialGraphState);
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const handleProductsBreakdownData = async () => {
		const productsBreakdownData: GraphsDataResponse = await getProductsBreakdownData();
		// BARS COLORS BY INDEX
		const breakdown = productsBreakdownData?.entries?.map((month: ProductAnalyticsList) => {
			return {
				...month,
				amountsPerProduct: month?.amountsPerProduct?.map((product) => {
					return {
						...product,
						color: breakdownProducts?.find((breakdownProduct) => breakdownProduct.id == product.productId)?.color,
					};
				}),
			};
		});
		const { data, isEmpty, dataFormat } = stackBarDataHandler(breakdown, cashTimeUnitHandler(timePeriod.from, timePeriod.to), timePeriod.value);
		setProductsBreakdown({
			data,
			isEmpty,
			dataFormat,
		});
	};

	const onRefresh = async () => {
		await Promise.all([getPayouts(), handleProductsBreakdownData(), getSummary()]);
	};

	return (
		<div className={styles.overviewContainer}>
			<div className={styles.topContainer}>
				<div className={styles.statistic}>
					<PartnerOverviewTabSummary summary={summary} />
				</div>

				<div className={styles.graphContainer}>
					<div className={styles.graphHeader}>
						<span className={styles.title}>{t('productsBreakdown')}</span>
						<div className={styles.productList}>
							<ClickableCardsList cards={breakdownProducts} setCards={setBreakdownProducts} />
						</div>
					</div>
					<div className={styles.graph}>
						<StackBarGraph
							isCashflow
							isStacked
							isEmpty={productsBreakdown.isEmpty}
							dataFormat={productsBreakdown.dataFormat}
							chartData={productsBreakdown.data}
						/>
					</div>
				</div>
			</div>

			<div className={styles.bottomContainer}>
				<Table
					sort={sort}
					header={LatestActivitiesTableHeader(onRefresh)}
					rows={payoutsList}
					className={styles.tableContainer}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyStateProps={{
						title: t('noActivitiesFound'),
					}}
					title={t('latestActivity')}
				/>
			</div>
		</div>
	);
};
