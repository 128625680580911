import clsx from 'clsx';
import React, { CSSProperties } from 'react';
import styles from './Button.module.scss';

export type ButtonStatusColor = 'primary' | 'neutral' | 'success' | 'warning' | 'calming' | 'destructive';
export type ButtonTypes = 'primary' | 'secondary' | 'outlined' | 'link';
export interface ButtonProps {
	children: React.ReactNode;
	type?: ButtonTypes;
	color?: ButtonStatusColor;
	disabled?: boolean;
	className?: string;
	style?: CSSProperties;
	id?: string;
	tabIndex?: number;
	onFocus?: any;
	dataTestId?: string;
	onClick?: (value?: any) => void;
	onKeyDown?: (event: any) => void;
}

export const Button = ({
	tabIndex,
	children,
	type = 'primary',
	disabled,
	color = 'success',
	className,
	style,
	id,
	onFocus,
	dataTestId,
	onClick,
	onKeyDown,
}: ButtonProps) => {
	return (
		<div
			id={id}
			tabIndex={tabIndex}
			onClick={(e) => {
				onClick && onClick(e);
				e.stopPropagation();
			}}
			onKeyDown={(event) => {
				event.key === 'Enter' && onClick && onClick();
				onKeyDown && onKeyDown(event);
			}}
			className={clsx(styles.generalButton, styles[type], styles[`${color}${type}Background`], disabled && styles.disabled, className)}
			data-testid={dataTestId || 'main-button'}
			style={style}
			onFocus={onFocus}
		>
			{children}
		</div>
	);
};
