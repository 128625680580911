import dayjs from 'dayjs';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { Cell, LogoCell, PriceCell } from '../../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT, SHORT_YEAR_FORMAT } from '../../../../constants/templateConstants';
import { UsageReportView } from '../../../../types/usageTypes';
import { numberWithCommas } from '../../../../utils/NumberUtils';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { PathsConfig, httpService } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { formatEmailList } from '../../../../utils/UserUtils';
import { getStatusTagByDocumentType } from '../../../../utils/DocumentsUtils';

export interface UsageReportResponse {
	data: UsageReportView[];
	count: number;
}

export const usageReportsTableHeader = [
	{
		headerTitle: 'customer',
		accessor: 'customerName',
		isSortable: true,
		CustomComponent: (item: UsageReportView) => (
			<LogoCell title={item.customerName} subTitle={formatEmailList(item.customerEmail)} logoData={item.customerIconData} />
		),
	},
	{
		headerTitle: 'updatedAt',
		accessor: 'updatedAt',
		isSortable: true,
		CustomComponent: (item: UsageReportView) => <Cell accessor={dayjs(item.updatedAt).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'documentID',
		accessor: 'documentNumber',
		isSortable: true,
		CustomComponent: (item: UsageReportView) => <Cell accessor={getDocumentIdNameByType(item)} />,
	},
	{
		headerTitle: 'usageProduct',
		accessor: 'usageProductName',
		isSortable: true,
	},
	{
		headerTitle: 'periodStartEnd',
		accessor: 'startDate',
		isSortable: true,
		CustomComponent: (item: UsageReportView) => (
			<Cell
				accessor={`${dayjs(item.startDate).format(SHORT_YEAR_FORMAT)} - ${dayjs(item.endDate).format(SHORT_YEAR_FORMAT)}`}
				subAccessor={item.subActivity}
			/>
		),
	},
	{
		headerTitle: 'usageQuantity',
		accessor: 'quantity',
		isSortable: true,
		CustomComponent: (item: UsageReportView) => <Cell accessor={`${numberWithCommas(item.quantity)} ${item.countedUnit}`} />,
	},
	{
		headerTitle: 'total',
		accessor: 'documentTotalPayment',
		isSortable: true,
		CustomComponent: (item: UsageReportView) => (
			<PriceCell currency={convertCurrencyToSign(item?.documentCurrency)} mainNumber={item?.documentTotalPayment} />
		),
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		CustomComponent: (document: UsageReportView) => getStatusTagByDocumentType({ document, disabled: true }),
	},
];

export const usageReportActionsOptions = (list: UsageReportView[], t: any, dispatch: Dispatch, onRefresh?: () => void) => {
	let optionList: { title: string; onClick(): void }[] = [];

	optionList = [
		...optionList,
		{
			title: t('deleteUsageReports'),
			onClick: () =>
				onDeleteUsageReports(
					dispatch,
					list.map((item) => item.usageReportId),
					onRefresh,
				),
		},
	];
	return optionList;
};

const onDeleteUsageReports = async (dispatch: Dispatch, ids: string[], onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.onDeleteMultipleUsageReports,
			data: { ids },
		});
		onRefresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
