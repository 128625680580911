import { Dispatch } from '@reduxjs/toolkit';
import { AgreementsTab, MenuOption, PartnerOverviewTab, PayoutsTab } from '../../../components';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { ErrorSuccessModalTypes } from '../../../types/generalTypes';
import { Customer } from '../../../types/customerTypes';
import { getCustomerById } from '../../_Customers';

export enum SinglePartnerPageTabIndex {
	overview,
	referralAgreements,
	dataStream,
	payouts,
}

export const singlePartnerPageTabs = [
	{ title: 'overview', component: (prop?: any) => <PartnerOverviewTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.overview },
	{ title: 'agreements', component: (prop?: any) => <AgreementsTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.referralAgreements },
	// { title: 'dataStream', component: (prop?: any) => <div></div>, tabIndex: SinglePartnerPageTabIndex.dataStream },
	{ title: 'payouts', component: (prop?: any) => <PayoutsTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.payouts },
];

export const singlePartnerOptionList = (
	dispatch: Dispatch,
	onEditCustomer: (customer: Customer) => void,
	onPressDelete: () => void,
	customer?: Customer,
): MenuOption[] => {
	const canBeDeleted = customer?.activeContractsCount == 0;
	return [
		{ title: 'Edit Partner', onPress: () => customer?.id && getCustomerById(customer?.id, dispatch, onEditCustomer), iconType: 'edit' },
		{
			title: 'Delete Partner',
			onPress: () =>
				canBeDeleted
					? onPressDelete()
					: dispatch(
							setOpenSuccessErrorModal({
								customTitle: 'cantDeletePartner',
								modalType: ErrorSuccessModalTypes.CANT_DELETE,
								successErrorMassage: 'cantDeletePartnerDescription',
							}),
					  ),
			iconType: 'delete',
			color: 'destructive',
		},
	];
};

export const isTabHasTimeFilters = (tabIndex: number) => {
	switch (tabIndex) {
		case SinglePartnerPageTabIndex.overview:
		case SinglePartnerPageTabIndex.payouts:
			return true;
		default:
			return false;
	}
};
