import { Dispatch } from '@reduxjs/toolkit';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { Customer } from '../types/customerTypes';

export const getExistsCustomerList = async (dispatch: Dispatch, names: string[], remoteIds: string[]) => {
	try {
		return (
			await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getExistsCustomerList,
				params: { names, remoteIds },
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_LIST }));
	}
};

export const getSuppliers = async (dispatch: Dispatch, showLoader = false) => {
	try {
		return (
			await httpService({
				showLoader,
				dispatch,
				path: PathsConfig.getSuppliers,
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_COMPANIES }));
	}
};

export const getSupplierById = async (dispatch: Dispatch, id?: string) => {
	if (!id) return;
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getSuppliersById,
				urlParam: { id },
				showLoader: false,
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_COMPANIES }));
	}
};

export interface shortCustomerData {
	id: string;
	name: string;
}

export const deleteDataEmptyState = {
	id: '',
	name: '',
};

export const getBillingAccountId = (suppliers?: Customer[]) => {
	const defaultSupplier = suppliers?.find((supplier) => supplier.isDefault);
	return defaultSupplier?.billingDetails?.billingAccounts?.find((bank) => bank.isDefault)?.id || null;
};

export const getSupplierIdByCustomer = (customer?: Customer, suppliers?: Customer[]) => {
	return customer?.supplierId || suppliers?.find((supplier) => supplier.isDefault)?.id;
};
