import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { CustomHeader } from './CustomHeader/CustomHeader';
import { Button, Header, Icon, Input, Modal } from '..';
import { DATE_FORMAT, YEARLY_DATE_FORMAT } from '../../../constants/templateConstants';
import { IconImgType } from '../Icon/IconOptions';
import { Footer, FooterTypeOptions } from './Footer/Footer';
import { isDateValid } from '../../../utils/DateUtils';
import styles from './DatePicker.module.scss';
import './DatePicker.css';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export type DateType = Date | null;

export interface DatePickerFormatType {
	fromDate: DateType;
	toDate: DateType;
}

export interface DatePickerProps {
	title?: string;
	icon?: IconImgType | null;
	headerContainerStyle?: string;
	defaultFromDate?: DateType;
	defaultToDate?: DateType;
	rangePicker?: boolean;
	headerLabelStyle?: string;
	className?: string;
	datePickerLabel?: string;
	disabled?: boolean;
	footerType?: FooterTypeOptions;
	openDatePicker?: boolean;
	hideInput?: boolean;
	hideIcon?: boolean;
	showError?: boolean;
	inputClassName?: string;
	showReset?: boolean;
	minMaxDates?: { minFromDate?: Date | null; maxFromDate?: Date | null; minToDate?: Date | null; maxToDate?: Date | null };
	disabledRangeUpdate?: { from?: Date | null | undefined; to?: Date | null | undefined };
	fullBorder?: boolean;
	onConfirm?: (date: Date) => void;
	updateDate?: (date: DatePickerFormatType) => void;
	updateOnClose?: (date: DatePickerFormatType) => void;
	updateIsOpen?: (isOpen: boolean) => void;
}

export const DatePicker = ({
	className,
	icon,
	defaultFromDate = null,
	defaultToDate = null,
	rangePicker,
	headerLabelStyle,
	title,
	datePickerLabel,
	headerContainerStyle,
	footerType,
	disabled,
	openDatePicker,
	hideInput,
	hideIcon,
	inputClassName,
	showError,
	showReset,
	minMaxDates,
	disabledRangeUpdate,
	fullBorder,
	onConfirm,
	updateDate,
	updateOnClose,
	updateIsOpen,
}: DatePickerProps) => {
	const { t } = useTranslation('translation');

	const [fromMonth, setFromMonth] = useState(new Date());
	const [toMonth, setToMonth] = useState(dayjs(new Date()).add(1, 'month').toDate());
	const [fromDate, setFromDate] = useState<DateType>(null);
	const [toDate, setToDate] = useState<DateType>(null);
	const [inputText, setInputText] = useState<string | undefined>('');
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		updateDefaultDate(defaultFromDate, defaultToDate);
	}, [defaultFromDate, defaultToDate]);

	useEffect(() => {
		setIsOpen(!!openDatePicker);
	}, [openDatePicker]);

	const updateDefaultDate = (fromDate: DateType, toDate: DateType) => {
		if ((!isDateValid(fromDate) && fromDate != null) || (!isDateValid(toDate) && toDate != null)) {
			return setInputText(`${dayjs(fromDate).format(YEARLY_DATE_FORMAT)}${toDate ? ` - ${dayjs(fromDate).format(YEARLY_DATE_FORMAT)}` : ''}`);
		}

		const from = fromDate || toDate ? dayjs(fromDate || toDate).toDate() : null;

		const to = toDate ? dayjs(toDate).toDate() : null;
		setFromDate(from);
		setToDate(to);

		from && setFromMonth(from);
		if (to) {
			const isSameMonth = from?.getMonth() === to.getMonth() && from.getFullYear() === to.getFullYear();
			isSameMonth ? setToMonth(dayjs(to).add(1, 'month').toDate()) : setToMonth(to);
		} else {
			const isNextMonthBefore = dayjs(toMonth).isSameOrBefore(from) || toMonth?.getMonth() === from?.getMonth();
			if (isNextMonthBefore) {
				setToMonth(dayjs(from).add(1, 'month').toDate());
			}
		}

		setInputText(from ? `${dayjs(from).format(DATE_FORMAT)}${rangePicker && to ? ` - ${dayjs(to).format(DATE_FORMAT)}` : ''}` : '');
	};

	// update start date and check validation
	const updateFromDate = (fromDate: DateType) => {
		setFromDate(fromDate);
		if (!fromDate) {
			setInputText('');
		} else {
			setInputText(dayjs(fromDate).format(DATE_FORMAT));
		}
		updateDate && updateDate({ fromDate, toDate: null });
	};

	const onReset = () => {
		setFromDate(null);
		setToDate(null);
		setInputText('');
		setFromMonth(new Date());
		setToMonth(dayjs(new Date()).add(1, 'month').toDate());
		updateDate && updateDate({ fromDate: null, toDate: null });
	};

	const onClose = () => {
		setIsOpen(false);
		updateIsOpen && updateIsOpen(false);
		updateOnClose?.({ fromDate, toDate });
	};

	const onPressToday = () => {
		updateFromDate(new Date());
		setFromMonth(new Date());
		setInputText(dayjs(new Date()).format(DATE_FORMAT));
		setToMonth(dayjs(new Date()).add(1, 'month').toDate());
		setToDate(null);
	};

	const confirmFromFooter = () => {
		onConfirm && fromDate && onConfirm(fromDate);
		onReset();
		onClose();
	};

	const updateToDate = (to: Date, from: Date) => {
		setToMonth(to);
		setToDate(to);
		setInputText(`${dayjs(from).format(DATE_FORMAT)} - ${dayjs(to).format(DATE_FORMAT)}`);
		updateDate && updateDate({ fromDate: from, toDate: to });
	};

	const addDateWithRange = (footerType: FooterTypeOptions | undefined, numberToAdd: number) => {
		const from = fromDate || new Date();
		if (!fromDate) {
			setFromMonth(from);
			setFromDate(from);
		}
		switch (footerType) {
			case FooterTypeOptions.ADD_YEARS: {
				const newDate = dayjs(from).add(numberToAdd, 'year').subtract(1, 'day').toDate();
				updateToDate(newDate, from);
				return;
			}
			case FooterTypeOptions.ADD_MONTHS: {
				const newDate = dayjs(from).add(numberToAdd, 'month').subtract(1, 'day').toDate();
				updateToDate(newDate, from);
				return;
			}
		}
	};

	const onSetFromMonth = (newMonth: Date) => {
		const isNextMonthBefore = dayjs(toMonth).isSameOrBefore(newMonth);

		if (isNextMonthBefore) {
			setToMonth(dayjs(newMonth).add(1, 'month').toDate());
		}
		setFromMonth(newMonth);
	};

	const onSetToMonth = (newMonth: Date) => {
		const isPrevMonthBefore = dayjs(newMonth).isSameOrBefore(fromMonth);
		if (isPrevMonthBefore) {
			setFromMonth(dayjs(newMonth).subtract(1, 'month').toDate());
		}

		setToMonth(newMonth);
	};

	//  can accept to types:  [DateType, DateType] | DateType
	const onChangeDate = (dates: any) => {
		if (rangePicker) {
			let currentEnd = toDate;
			let currentStart = fromDate;
			if (disabledRangeUpdate) {
				currentEnd = dates;
			} else {
				const [start, end] = dates;
				currentEnd = end;
				currentStart = start;

				if (fromDate && toDate && dayjs(start).isAfter(toDate)) {
					currentEnd = start;
					currentStart = fromDate;
				}
			}
			setFromDate(currentStart);

			setToDate(currentEnd);
			setInputText(
				currentStart
					? `${dayjs(currentStart).format(DATE_FORMAT)}${rangePicker && currentEnd ? ` - ${dayjs(currentEnd).format(DATE_FORMAT)}` : ''}`
					: '',
			);
			updateDate && updateDate({ fromDate: currentStart, toDate: currentEnd });
		} else {
			updateFromDate(dates);
		}
	};

	return (
		<div className={clsx(disabled && styles.notEditable)}>
			{!hideInput && (
				<>
					{title && <Header title={title} containerStyle={headerContainerStyle} iconType={icon} />}
					<Input
						fullBorder={fullBorder}
						placeholder={'mm/dd/yyyy'}
						value={inputText}
						onFocus={() => setIsOpen(true)}
						className={className}
						inputClassName={inputClassName}
						showError={showError}
						dataTestId={title}
						rightChildren={!hideIcon && <Icon imgType={'date'} color={'neutral300'} width={1.5} className={styles.rightInputIcon} />}
					/>
				</>
			)}

			<Modal isOpen={isOpen} closeModal={onClose} overlayClassName={styles.modal} dataTestId='date-picker'>
				<div className={styles.header}>
					<span data-testid='date-picker-header' className={clsx(styles.title, headerLabelStyle)}>
						{datePickerLabel || t('chooseDates')}
					</span>

					{!minMaxDates && (
						<div className={styles.flexCenter}>
							<Button type='link' className={styles.resetTodayButtons} onClick={onPressToday}>
								{t('today')}
							</Button>
							{(rangePicker || showReset) && (
								<div className={styles.flexCenter}>
									<span className={styles.dot}>•</span>
									<Button color='destructive' type='link' className={styles.resetTodayButtons} onClick={onReset}>
										{t('reset')}
									</Button>
								</div>
							)}
						</div>
					)}
				</div>

				<div className={styles.flexColumn}>
					<div className={clsx(styles.dayPickerContainer, rangePicker && !disabledRangeUpdate ? styles.doublePicker : styles.singlePicker)}>
						{!disabledRangeUpdate && (
							<ReactDatePicker
								selectsRange={rangePicker}
								renderCustomHeader={(props) => <CustomHeader {...props} monthDate={fromMonth} setCurrentMonth={onSetFromMonth} />}
								onChange={onChangeDate}
								startDate={fromDate}
								data-testid='date-picker-component'
								endDate={toDate}
								shouldCloseOnSelect={false}
								openToDate={fromMonth}
								minDate={minMaxDates?.minFromDate}
								maxDate={minMaxDates?.maxFromDate}
								dayClassName={(date) => {
									return clsx(
										styles.day,
										dayjs(date).format(DATE_FORMAT) == dayjs().format(DATE_FORMAT) && styles.currentDay,
										dayjs(date).format(DATE_FORMAT) == dayjs(fromDate).format(DATE_FORMAT) &&
											date.getMonth() === fromMonth.getMonth() &&
											styles.selectedDay,
										dayjs(date).format(DATE_FORMAT) == dayjs(toDate).format(DATE_FORMAT) &&
											date.getMonth() === fromMonth.getMonth() &&
											styles.selectedDay,
										dayjs(date).isBetween(fromDate, toDate, 'day', '()') && date.getMonth() === fromMonth.getMonth() && styles.selectedRange,
										dayjs(date).format(DATE_FORMAT) == dayjs(fromDate).format(DATE_FORMAT) &&
											dayjs(toDate).format(DATE_FORMAT) !== dayjs(fromDate).format(DATE_FORMAT) &&
											toDate &&
											styles.selectedFrom,
										dayjs(date).format(DATE_FORMAT) == dayjs(toDate).format(DATE_FORMAT) &&
											dayjs(toDate).format(DATE_FORMAT) !== dayjs(fromDate).format(DATE_FORMAT) &&
											fromDate &&
											styles.selectedTo,
									);
								}}
								fixedHeight
								calendarContainer={({ children }) => <CalendarContainer className={styles.calendarContainer}>{children}</CalendarContainer>}
								inline
							/>
						)}

						{rangePicker && (
							<ReactDatePicker
								selectsRange={rangePicker && !disabledRangeUpdate}
								renderCustomHeader={(props) => <CustomHeader {...props} isRange monthDate={toMonth} setCurrentMonth={onSetToMonth} />}
								onChange={onChangeDate}
								startDate={disabledRangeUpdate ? undefined : fromDate}
								endDate={toDate}
								data-testid='date-picker-component'
								openToDate={toMonth}
								shouldCloseOnSelect={false}
								minDate={minMaxDates?.minToDate}
								maxDate={minMaxDates?.maxToDate}
								dayClassName={(date) => {
									return disabledRangeUpdate
										? clsx(
												styles.day,
												dayjs(date).format(DATE_FORMAT) == dayjs().format(DATE_FORMAT) && styles.current,
												dayjs(date).format(DATE_FORMAT) == dayjs(toDate).format(DATE_FORMAT) &&
													date.getMonth() === toMonth.getMonth() &&
													styles.selectedDay,
												dayjs(date).format(DATE_FORMAT) == dayjs(fromDate).format(DATE_FORMAT) &&
													date.getMonth() === toMonth.getMonth() &&
													styles.selectedDay,

												dayjs(date).isBefore(disabledRangeUpdate?.to) && styles.disabledDate,
										  )
										: clsx(
												styles.day,
												dayjs(date).format(DATE_FORMAT) == dayjs().format(DATE_FORMAT) && styles.current,
												dayjs(date).format(DATE_FORMAT) == dayjs(toDate).format(DATE_FORMAT) &&
													date.getMonth() === toMonth.getMonth() &&
													styles.selectedDay,
												dayjs(date).format(DATE_FORMAT) == dayjs(fromDate).format(DATE_FORMAT) &&
													date.getMonth() === toMonth.getMonth() &&
													styles.selectedDay,
												dayjs(date).isBetween(fromDate, toDate, 'day', '()') && date.getMonth() === toMonth.getMonth() && styles.selectedRange,
												dayjs(date).format(DATE_FORMAT) == dayjs(fromDate).format(DATE_FORMAT) &&
													toDate &&
													dayjs(toDate).format(DATE_FORMAT) !== dayjs(fromDate).format(DATE_FORMAT) &&
													styles.selectedFrom,
												dayjs(date).format(DATE_FORMAT) == dayjs(toDate).format(DATE_FORMAT) &&
													fromDate &&
													dayjs(toDate).format(DATE_FORMAT) !== dayjs(fromDate).format(DATE_FORMAT) &&
													styles.selectedTo,
										  );
								}}
								fixedHeight
								calendarContainer={({ children }) => (
									<CalendarContainer data-testid='date-picker-container-component' className={styles.calendarContainer}>
										{children}
									</CalendarContainer>
								)}
								inline
							/>
						)}
					</div>

					<Footer
						fromDate={fromDate}
						footerType={footerType}
						confirmFromFooter={confirmFromFooter}
						onConfirm={onClose}
						onCancel={() => {
							onReset();
							onClose();
						}}
						onAdd={(numberToAdd) => addDateWithRange(footerType, numberToAdd)}
					/>
				</div>
			</Modal>
		</div>
	);
};
