import { PricingModelTableRow } from '@received/pricing-model';
import { ProductAndCategoriesItem } from '../../types/ProductTypes';
import { PricingModel } from '../../types/contractTypes';

export const getFilteredList = (prodList: ProductAndCategoriesItem[], searchText: string): ProductAndCategoriesItem[] => {
	const parsedSearch = searchText.toLocaleLowerCase().trim();
	return prodList.reduce((acc: ProductAndCategoriesItem[], product) => {
		const { name, pricingModels = [] } = product;
		const isInProduct = name.toLocaleLowerCase().includes(parsedSearch);
		if (isInProduct) {
			return [...acc, product];
		}
		const filteredModels = pricingModels.filter(({ name = '' }) => name.toLocaleLowerCase().includes(parsedSearch));
		if (filteredModels.length) {
			return [
				...acc,
				{
					...product,
					pricingModels: filteredModels,
				},
			];
		}
		return acc;
	}, []);
};

export const initialProduct: ProductAndCategoriesItem = {
	id: '',
	name: 'Untitled Product',
	pricingModels: [],
};

export const onClickSelectOrEdit = (
	selectedPricingModel: PricingModel | undefined,
	listOfSelectedRows: boolean[][],
	onClose: () => void,
	onUseThisPricing?: (pricingModel: PricingModel) => void,
) => {
	if (selectedPricingModel?.tabs.length) {
		const newModel = { ...selectedPricingModel, tabs: [...selectedPricingModel.tabs] };

		newModel.tabs = newModel?.tabs?.map((model, tabIndex) => {
			const newTabs = { ...model };
			newTabs.name = selectedPricingModel?.name || model.name;
			newTabs.productName = selectedPricingModel?.product?.name || (selectedPricingModel?.categories && selectedPricingModel?.categories[0]?.name);

			const newTableRows: PricingModelTableRow[] = [];
			listOfSelectedRows[tabIndex].forEach((isSelected, index) => {
				if (isSelected) {
					const copyRow = { ...model.pricingModelTable.rows[index] };
					newTableRows.push(copyRow);
				}
			});

			newTabs.pricingModelTable.rows = [...newTableRows];
			return newTabs;
		});

		newModel?.id && onUseThisPricing?.(newModel);
		onClose();
	}
};
