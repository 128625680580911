export const hexToRGBAColor = (color?: string, opacity?: string) => {
	let rgbColor = 'rgb(156,163,175)';
	let c: any;

	if (color && /^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
		c = color.substring(1).split('');
		if (color.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		rgbColor = `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${opacity || 1})`;
	}

	return rgbColor;
};
