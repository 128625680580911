import clsx from 'clsx';
import { useState } from 'react';
import { Icon } from '..';
import styles from './Collapsible.module.scss';

interface CollapsibleProps {
	header: string | React.ReactNode;
	children?: React.ReactNode;
	className?: string;
	headerClassName?: string;
	heightWhenOpen?: number | string;
}

export const Collapsible = ({ header, children, headerClassName, heightWhenOpen, className }: CollapsibleProps) => {
	const [isCollapsed, setIsCollapsed] = useState(false);

	return (
		<div className={clsx(className, isCollapsed && styles.showBorder)}>
			<div className={clsx(styles.header, headerClassName)} onClick={() => setIsCollapsed(!isCollapsed)}>
				{header}
				<Icon imgType='arrow_up' color='neutral' width={1.3} className={isCollapsed ? styles.arrowExpanded : styles.arrowCollapsed} />
			</div>
			<div className={clsx(isCollapsed ? styles.collapsibleContent : styles.expanded)} style={{ height: isCollapsed ? 0 : heightWhenOpen }}>
				{children}
			</div>
		</div>
	);
};
