import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PricingModel } from '../../../../types/contractTypes';
import { Button, Icon, Select, TextArea } from '../../../../components';
import { Sequence } from '../../../../types/dunningTypes';
import { PathsConfig, httpService } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import styles from './DocumentSettings.module.scss';

interface DocumentSettingsProps {
	pricingModel: PricingModel;
	className?: string;
	isEditMode?: boolean;
	updateSettings: (pricingModel: PricingModel, instantUpdate?: boolean) => void;
}

export const DocumentSettings = ({ pricingModel, className, isEditMode, updateSettings }: DocumentSettingsProps) => {
	const [comment, setComment] = useState('');
	const [sequencesList, setSequencesList] = useState<Sequence[]>([]);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		getSequences();
	}, []);

	useEffect(() => {
		setComment(pricingModel?.comment || '');
	}, [pricingModel?.comment]);

	const getSequences = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getSequences,
				})
			).data;
			setSequencesList(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={clsx(styles.container, !isEditMode ? styles.notAllowedCursor : styles.editable, className)}>
			<div className={clsx(styles.box, !isEditMode ? styles.locked : styles.editable)}>
				<div className={styles.wrapper}>{t('documentsSettings')}</div>
				<div className={styles.wrapper}>
					<span className={styles.label}>{t('documentNote')}</span>
					<TextArea
						value={comment}
						onChange={setComment}
						onBlur={(e) =>
							updateSettings({
								...pricingModel,
								comment,
							})
						}
						onKeyDown={(e) =>
							e.key === 'Enter' &&
							updateSettings({
								...pricingModel,
								comment,
							})
						}
						className={styles.multiline}
						placeholder={t('thisCommentWillNotBeOnPdf')}
					/>
				</div>

				<div className={styles.wrapper}>{t('manageCollections')}</div>
				<div className={styles.wrapper}>
					<span className={styles.label}>{t('dunning')}</span>
					<div className={styles.dunningInputs}>
						<Select
							data={sequencesList.map((sequence: Sequence) => ({ label: sequence.name, value: sequence?.id || '' }))}
							onChange={(val) =>
								updateSettings(
									{
										...pricingModel,
										dunningSequenceId: val?.length ? val : null,
										isDunningActive: !!val,
									},
									true,
								)
							}
							width={''}
							defaultValue={sequencesList.find((sequence: Sequence) => sequence?.id === pricingModel?.dunningSequenceId)?.id}
							containerStyle={styles.selectSequence}
							className={styles.selectSequenceClassName}
							emptyState={{ subTitle: '' }}
							canRemoveSelectedValue
							placeholder={t('selectASequence')}
						/>

						<Button
							type='outlined'
							color='neutral'
							onClick={() =>
								updateSettings({
									...pricingModel,
									isDunningActive: !pricingModel?.isDunningActive,
								})
							}
							disabled={!sequencesList.find((sequence: Sequence) => sequence?.id === pricingModel?.dunningSequenceId)?.id}
							className={styles.playButton}
						>
							<Icon imgType={pricingModel?.isDunningActive ? 'pause_in_circle' : 'play_in_circle'} width={2} color='neutral700' />
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};
