export type ImagesOptionsType =
	| 'addCustomer'
	| 'modelNotFound'
	| 'addModel'
	| 'createCustomer'
	| 'addGroupType'
	| 'addProduct'
	| 'productCard'
	| 'greenPlanes'
	| 'codat'
	| 'modelsNotFound'
	| 'somethingWentWrong'
	| 'emptyGraph'
	| 'deleteItem'
	| 'createContract'
	| 'emptyData'
	| 'addModelBlue'
	| 'addContractBlue'
	| 'emptyGraphs'
	| 'wip'
	| 'addUsageReport'
	| 'success'
	| 'authorization'
	| 'noInternet'
	| 'noLateCustomers'
	| 'quickBooks'
	| 'whiteRefresh'
	| 'welcome'
	| 'salesforce'
	| 'emptyBox'
	| 'netsuite'
	| 'login'
	| 'received'
	| 'addFormat'
	| 'analytics'
	| 'papers'
	| 'xero'
	| 'stripePayment'
	| 'emptyIntegration'
	| 'exitViewMode'
	| 'usdc'
	| 'usdt'
	| 'United States'
	| 'Israel'
	| 'United Kingdom'
	| 'China'
	| 'Japan'
	| 'Germany'
	| 'eu'
	| 'emptyIntegrations'
	| 'saveChanges'
	| 'alert'
	| 'uploadLogo';

export interface ImagesOptions {
	addCustomer: string;
	modelNotFound: string;
	addModel: string;
	createCustomer: string;
	addGroupType: string;
	addProduct: string;
	productCard: string;
	greenPlanes: string;
	modelsNotFound: string;
	codat: string;
	salesforce: string;
	somethingWentWrong: string;
	emptyGraph: string;
	deleteItem: string;
	createContract: string;
	emptyData: string;
	addModelBlue: string;
	addContractBlue: string;
	emptyGraphs: string;
	wip: string;
	addUsageReport: string;
	success: string;
	authorization: string;
	noInternet: string;
	noLateCustomers: string;
	activeContract: string;
	cantDelete: string;
	exportCSV: string;
	quickBooks: string;
	blankPricingModelCard: string;
	outOfSync: string;
	connected: string;
	whiteRefresh: string;
	welcome: string;
	emptyBox: string;
	netsuite: string;
	login: string;
	usageProductCard: string;
	defaultProductCard: string;
	received: string;
	invoiceTableEmpty: string;
	addFormat: string;
	analytics: string;
	papers: string;
	xero: string;
	stripePayment: string;
	emptyIntegration: string;
	exitViewMode: string;
	usdc: string;
	usdt: string;
	syncError: string;
	'United States': string;
	Israel: string;
	'United Kingdom': string;
	China: string;
	Japan: string;
	eu: string;
	Germany: string;
	emptyIntegrations: string;
	saveChanges: string;
	hubspot: string;
	syncing: string;
	subscriptions: string;
	alert: string;
	uploadLogo: string;
}

export const images: ImagesOptions = {
	addCustomer: require('../assets/icons/addCustomer.png'),
	modelNotFound: require('../assets/icons/modelsNotFound.png'),
	addModel: require('../assets/icons/addModel.png'),
	createCustomer: require('../assets/icons/createCustomer.png'),
	addGroupType: require('../assets/icons/addGroupType.png'),
	addProduct: require('../assets/icons/addProduct.png'),
	productCard: require('../assets/icons/productCard.png'),
	greenPlanes: require('../assets/icons/greenPlanes.png'),
	modelsNotFound: require('../assets/icons/modelsNotFound.png'),
	codat: require('../assets/icons/codat.png'),
	salesforce: require('../assets/icons/salesforce.png'),
	somethingWentWrong: require('../assets/icons/somethingWentWrong.png'),
	emptyGraph: require('../assets/icons/emptyGraph.png'),
	deleteItem: require('../assets/icons/deleteItem.png'),
	createContract: require('../assets/icons/createContract.png'),
	emptyData: require('../assets/icons/emptyData.png'),
	addModelBlue: require('../assets/icons/addModelBlue.png'),
	addContractBlue: require('../assets/icons/addContractBlue.png'),
	emptyGraphs: require('../assets/icons/emptyGraphs.png'),
	wip: require('../assets/icons/wip.png'),
	addUsageReport: require('../assets/icons/addUsageReport.png'),
	success: require('../assets/icons/success.png'),
	authorization: require('../assets/icons/authorization.png'),
	noInternet: require('../assets/icons/noInternet.png'),
	noLateCustomers: require('../assets/icons/noLateCustomers.png'),
	activeContract: require('../assets/icons/activeContract.png'),
	cantDelete: require('../assets/icons/cantDelete.png'),
	exportCSV: require('../assets/icons/exportCSV.png'),
	quickBooks: require('../assets/icons/qb.png'),
	blankPricingModelCard: require('../assets/icons/blankPricingModelCard.png'),
	outOfSync: require('../assets/icons/outOfSync.png'),
	connected: require('../assets/icons/connected.jpg'),
	whiteRefresh: require('../assets/icons/whiteRefresh.png'),
	welcome: require('../assets/icons/welcome.png'),
	emptyBox: require('../assets/icons/emptyBox.png'),
	netsuite: require('../assets/icons/netsuite.png'),
	login: require('../assets/icons/login.png'),
	usageProductCard: require('../assets/icons/usageProductCard.png'),
	defaultProductCard: require('../assets/icons/defaultProductCard.png'),
	received: require('../assets/icons/received.png'),
	invoiceTableEmpty: require('../assets/icons/invoiceTableEmpty.png'),
	addFormat: require('../assets/icons/addFormat.png'),
	papers: require('../assets/icons/papers.png'),
	analytics: require('../assets/icons/analytics.png'),
	xero: require('../assets/icons/xero.png'),
	stripePayment: require('../assets/icons/stripePayment.png'),
	emptyIntegration: require('../assets/icons/emptyIntegration.png'),
	exitViewMode: require('../assets/icons/exitViewMode.png'),
	usdc: require('../assets/icons/usdcToken.png'),
	usdt: require('../assets/icons/usdtToken.png'),
	syncError: require('../assets/icons/syncError.png'),
	'United States': require('../assets/icons/United States.png'),
	Israel: require('../assets/icons/Israel.png'),
	'United Kingdom': require('../assets/icons/United Kingdom.png'),
	China: require('../assets/icons/China.png'),
	Japan: require('../assets/icons/Japan.png'),
	eu: require('../assets/icons/eu.png'),
	Germany: require('../assets/icons/Germany.png'),
	emptyIntegrations: require('../assets/icons/emptyIntegrations.png'),
	saveChanges: require('../assets/icons/saveChanges.png'),
	hubspot: require('../assets/icons/hubspot.png'),
	syncing: require('../assets/icons/syncing.png'),
	subscriptions: require('../assets/icons/subscriptions.png'),
	alert: require('../assets/icons/alert.png'),
	uploadLogo: require('../assets/icons/uploadLogo.png'),
};
