import { useEffect, useState } from 'react';
import { Font, usePDF } from '@react-pdf/renderer';
import { Document, Page } from 'react-pdf';
import { useDispatch } from 'react-redux';
import { PaginationBar } from '../../..';
import { getSupplierById } from '../../../../utils/CustomerUtils';
import { images } from '../../../../constants/images';
import { ErrorBoundary } from '../../../../services/ErrorBoundary/ErrorBoundary';
import { getDocumentByType } from './PDFPreview.utils';
import { BillingAccountDetails, Customer } from '../../../../types/customerTypes';
import TWKLausanne from '../../../../fonts/TWKLausanne-450.woff';
import TWKLausanne650 from '../../../../fonts/TWKLausanne-650.woff';
import TWKLausanne850 from '../../../../fonts/TWKLausanne-850.woff';
import { BillingDocumentType } from '../../../../types/generalTypes';
import styles from './PDFPreview.module.scss';

Font.register({
	family: 'TWKLausanne',
	fonts: [
		{
			src: TWKLausanne,
		},
		{
			src: TWKLausanne650,
			fontWeight: 600,
		},
		{
			src: TWKLausanne850,
			fontWeight: 700,
		},
	],
});

Font.registerHyphenationCallback((word) => {
	if (word.length > 13) {
		return word.match(/[\s\S]{1,13}/g) || [];
	}
	return [word];
});

interface PDFPreviewProps {
	document: BillingDocumentType;
	displayRefreshMode?: boolean;
	isOpen?: boolean;
	onRefresh?: () => void;
}

export const PDFPreview = ({ document, displayRefreshMode, isOpen, onRefresh }: PDFPreviewProps) => {
	const dispatch = useDispatch();

	const [bankDetails, setBankDetails] = useState<BillingAccountDetails>();
	const [documentData, setDocumentData] = useState<BillingDocumentType>();
	const [supplier, setSupplier] = useState<Customer>();
	const [{ pageNumber, numPages }, setPageNumbers] = useState<{ pageNumber: number; numPages: number }>({ pageNumber: 1, numPages: 1 });
	const [pageScale, setPageScale] = useState(1);

	const [instance, updateInstance] = usePDF({
		document: getDocumentByType(document, supplier, bankDetails),
	});

	useEffect(() => {
		setDocumentData(document);
	}, [document]);

	useEffect(() => {
		setSupplierData();
	}, [documentData?.billingDetails?.billingAccountId, documentData?.supplierId]);

	useEffect(() => {
		updateDocument();
	}, [documentData, supplier, bankDetails, document?.usageReportsByProduct]);

	const updateDocument = () => {
		try {
			documentData?.pricingModel?.tabs.length && updateInstance();
		} catch (err) {
			initializePageNumbers();
		}
	};

	const setSupplierData = async () => {
		const supplier = await getSupplierById(dispatch, documentData?.supplierId);
		setSupplier(supplier);
		setBankDetails(
			supplier?.billingDetails?.billingAccounts?.find(
				(account: BillingAccountDetails) => account.id === documentData?.billingDetails?.billingAccountId,
			) ?? undefined,
		);
	};

	const onPressRefresh = async () => {
		if (displayRefreshMode) {
			onRefresh?.();
			updateInstance();
			await setSupplierData();
		}
	};

	const goToPreviousPage = () => {
		if (pageNumber !== 1) {
			setPageNumbers((prev) => ({
				...prev,
				pageNumber: prev.pageNumber - 1,
			}));
		}
	};

	const goToNextPage = () => {
		if (pageNumber !== numPages) {
			setPageNumbers((prev) => ({
				...prev,
				pageNumber: prev.pageNumber + 1,
			}));
		}
	};

	const initializePageNumbers = () => {
		setPageNumbers({
			pageNumber: 1,
			numPages: 1,
		});
	};

	const handleZoomIn = () => {
		if (pageScale < 3) {
			setPageScale(pageScale + 0.2);
		}
	};

	const handleZoomOut = () => {
		if (pageScale > 0.3) {
			setPageScale(pageScale - 0.2);
		}
	};

	return (
		<div className={styles.container} onClick={onPressRefresh}>
			<ErrorBoundary
				allowedRetries
				customUIComponent={
					<div className={styles.errorContainer}>
						<img alt='refresh-document-preview' src={images.whiteRefresh} />
					</div>
				}
			>
				<Document
					file={instance?.blob}
					onLoadSuccess={({ numPages }) => {
						setPageNumbers((prev) => ({
							...prev,
							numPages,
						}));
					}}
					onLoadProgress={initializePageNumbers}
					className={styles.pdf}
				>
					{displayRefreshMode && (
						<div className={styles.refreshView}>
							<img alt='refresh-document-preview' src={images.whiteRefresh} className={styles.refreshImage} />
						</div>
					)}

					<Page
						scale={pageScale}
						onLoad={initializePageNumbers}
						onReset={initializePageNumbers}
						pageNumber={pageNumber}
						className={styles.pagePdf}
						renderTextLayer={false}
						renderAnnotationLayer={false}
					/>
				</Document>
			</ErrorBoundary>

			{isOpen && (
				<PaginationBar
					pageNumber={pageNumber}
					numPages={numPages}
					pageScale={pageScale}
					goToPreviousPage={goToPreviousPage}
					goToNextPage={goToNextPage}
					zoomIn={handleZoomIn}
					zoomOut={handleZoomOut}
				/>
			)}
		</div>
	);
};
