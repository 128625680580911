/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Invoice, InvoiceView } from '../types/invoiceTypes';
import { UsageReportView } from '../types/usageTypes';

export const getDebitIdName = (debit: Invoice | UsageReportView) => {
	if ((debit as Invoice | InvoiceView)?.documentNumber) {
		return `${(debit as Invoice | InvoiceView)?.documentNumber}`;
	} else if ((debit as UsageReportView)?.documentNumber) {
		return `${(debit as UsageReportView)?.documentNumber}`;
		// @ts-ignore
	} else if (debit?.customNumber) {
		// @ts-ignore
		return debit?.customNumber;
	} else if (debit?.draftNumber) {
		return `DRAFT-${debit?.draftNumber}`;
	} else {
		return '';
	}
};
