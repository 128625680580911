import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Store } from '../../../types/storeTypes';
import { Button, ImportItemsProgress, RevenueFeatureDisabled, Table } from '../../../components';
import { AccountPermissionsActionsList, DatesData, Sort, SortOrder } from '../../../types/generalTypes';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { PermissionsMapping } from '../../../services/PermissionsMapping/PermissionsMapping';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { initialMainTimePeriod, revRecTabTableHeader } from './../RevenueRecognition.utils';
import { RevRecView } from '../../../types/revRecTypes';
import styles from './AllRevenueRecognitionActivities.module.scss';

const defaultNumberOfItems = '20';

export const AllRevenueRecognitionActivities = () => {
	const { t } = useTranslation('translation');

	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const { appCurrency } = useSelector((store: Store) => store.general);

	const [revRecList, setRevRecList] = useState<RevRecView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: 'postingDate', order: SortOrder.ASC });
	const [timePeriod, setTimePeriod] = useState<DatesData>(initialMainTimePeriod);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getRevRecData();
	}, [sort, timePeriod, pageNumber, numberOfItems]);

	const getRevRecData = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getRevenueTableView,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
					},
				})
			).data;

			setTotalCount(res?.count);
			setRevRecList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={getRevRecData} />
				<header className={styles.header}>
					<div className={styles.goBackContainer}>
						<Button type='link' color='neutral' onClick={() => navigate('../revenue-recognition')} className={styles.goBack}>
							{t('Customers')} / {t('allRecords')}
						</Button>
					</div>

					<h1>{t('records')}</h1>
				</header>

				<PermissionsMapping actionType={AccountPermissionsActionsList.VIEW_REV_REC} disabledFeatureChildren={<RevenueFeatureDisabled />}>
					<Table
						sort={sort}
						header={revRecTabTableHeader(appCurrency)}
						rows={revRecList}
						numberOfPages={Math.ceil(totalCount / +numberOfItems)}
						totalNumberOfItems={totalCount}
						onUpdateNumberOfItems={setNumberOfItems}
						numberOfItems={numberOfItems}
						onUpdatePageNumber={setPageNumber}
						className={styles.tableContainer}
						onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					/>
				</PermissionsMapping>
			</div>
		</div>
	);
};
