import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import styles from './Avatars.module.scss';

export interface AvatarsProps {
	imgArray: { img?: string; name: string }[];
	size?: 'Small' | 'Medium' | 'Large';
	style?: CSSProperties;
	className?: string;
}

export const Avatars = ({ imgArray, size = 'Large', style, className }: AvatarsProps) => {
	return (
		<div className={styles.groupContainer} style={style}>
			{imgArray &&
				imgArray?.map(({ img, name }, index) =>
					img ? (
						<img
							key={index}
							src={img}
							className={clsx(styles.avatarContainer, styles[`avatar${size}Container`], className)}
							style={{ left: size === 'Small' ? index * 20 : index * 30 }}
							data-testid='avatar-img'
						/>
					) : (
						<div key={`${index}_${name}`} className={clsx(styles.avatarContainer, styles[`avatar${size}Container`], styles.noAvatarContainer)}>
							{name && name[0].toUpperCase()}
						</div>
					),
				)}
		</div>
	);
};
