import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DEFAULT_CURRENCY, ProductPerformanceType, convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../types/storeTypes';
import {
	ClickableCard,
	ClickableCardsItem,
	ClickableCardsList,
	DateRangeFilters,
	ImportItemsProgress,
	RevenueRecognitionComponent,
	Schedule,
	Select,
	StackBarDataset,
	StackBarGraph,
} from '../../components';
import { fullFiltersSelectOptions } from '../../components/Filters/DateRangeFilters.utils';
import { AccountPermissionsActionsList, DatesData, GraphState, ProductAnalyticsList } from '../../types/generalTypes';
import {
	ReportsMetrics,
	arrDataMomentumDataHandler,
	initialMainTimePeriod,
	initialSummaryData,
	productPerformancesTypeSelect,
	rightMetrics,
} from './Reports.utils';
import { setSidebarIsOpen } from '../../storeSlices/generalSlice';
import { PermissionsMapping } from '../../services/PermissionsMapping/PermissionsMapping';
import { AnalyticCard } from './AnalyticCard/AnalyticCard';
import { priceNumberWithCurrencySignDisplay } from '../../utils/NumberUtils';
import { initialGraphState } from '../Dashboard/Dashboard.utils';
import { PathsConfig, httpService } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { colors } from '../../constants/colors';
import { cashTimeUnitHandler } from '../../utils/DateUtils';
import { stackBarDataHandler } from '../../components/_featureComponents/RevenueRecognition/RevenueRecognition.utils';
import { ProductContractRes } from '../../types/ProductTypes';
import { filterYearsList } from '../../constants/generalConstants';
import { productsColorsList } from '../../utils/GeneralUtils';
import styles from './Reports.module.scss';

export const Reports = () => {
	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const { appCurrency } = useSelector((store: Store) => store.general);

	const [mainTimePeriod, setTimePeriod] = useState<DatesData>(initialMainTimePeriod);
	const [arrMomentumMetrics, serArrMomentumMetrics] = useState<GraphState>(initialGraphState);
	const [summaryCards, setSummaryCards] = useState<ReportsMetrics>(initialSummaryData);
	const [productPerformanceMetrics, setProductPerformanceMetrics] = useState<GraphState>(initialGraphState);
	const [productPerformanceProducts, setProductPerformanceProducts] = useState<ClickableCardsItem[]>([]);
	const [productPerformanceType, setProductPerformanceType] = useState<ProductPerformanceType>(ProductPerformanceType.CASH);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getProducts();
	}, []);

	useEffect(() => {
		getSummaryData();
		getArrMomentumData();
	}, [mainTimePeriod]);

	useEffect(() => {
		getProductPerformanceData();
	}, [productPerformanceType, mainTimePeriod, productPerformanceProducts]);

	const getProducts = async () => {
		try {
			const res: ProductContractRes[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractProductsList,
				})
			).data;

			const allProducts = res?.flatMap((prod, index) => ({
				id: prod.productId,
				isSelected: true,
				color: productsColorsList[index % productsColorsList?.length].textColor || '',
				productName: prod.productName,
			}));

			setProductPerformanceProducts(allProducts);
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSummaryData = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getReportsSummary,
				})
			).data;

			setSummaryCards(res);
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	const getArrMomentumData = async () => {
		try {
			const res = (
				await httpService({
					dispatch,

					path: PathsConfig.getArrMomentumMetrics,
					params: { fromDate: mainTimePeriod.from, toDate: mainTimePeriod.to },
				})
			).data;

			const { dataForGraph, isEmpty, dataFormat } = arrDataMomentumDataHandler(
				res,
				cashTimeUnitHandler(mainTimePeriod.from, mainTimePeriod.to),
				mainTimePeriod.value,
			);
			serArrMomentumMetrics({ data: dataForGraph, isEmpty, dataFormat });
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getProductPerformanceData = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getProductPerformanceMetrics,
					params: { fromDate: mainTimePeriod.from, toDate: mainTimePeriod.to, type: productPerformanceType },
				})
			).data;

			const productsWithColors = res?.entries.map((month: ProductAnalyticsList) => {
				return {
					...month,
					amountsPerProduct: month?.amountsPerProduct
						.filter((prod) => productPerformanceProducts?.find((leanTab) => leanTab.id == prod.productId)?.isSelected)
						?.map((product) => {
							return {
								...product,
								color: productPerformanceProducts?.find((leanTab) => leanTab.id == product.productId)?.color,
							};
						}),
				};
			});

			const { data, isEmpty, dataFormat } = stackBarDataHandler(
				productsWithColors,
				cashTimeUnitHandler(mainTimePeriod.from, mainTimePeriod.to),
				mainTimePeriod.value,
			);

			setProductPerformanceMetrics({
				data,
				isEmpty,
				dataFormat,
			});
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const refreshData = async () => {
		getSummaryData();
		getArrMomentumData();
		getProductPerformanceData();
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={refreshData} />

				<header className={styles.pageHeader}>
					<h1>{t('reports')}</h1>
					<DateRangeFilters
						selectClassName={styles.reportSelectClassName}
						inputClassName={styles.reportSelectInputClassName}
						mediumInput
						data={fullFiltersSelectOptions}
						defaultSelectValue={mainTimePeriod.title}
						callback={setTimePeriod}
						width={17}
					/>
				</header>

				<div className={styles.analyticsRowContainer}>
					<div className={styles.analyticsLeftCards}>
						<AnalyticCard
							title={t('todaysArr')}
							quantity={priceNumberWithCurrencySignDisplay(summaryCards.todayArr, convertCurrencyToSign(appCurrency))}
							subQuantity={summaryCards.todayArrYoy}
							description={summaryCards.todayArrYoy ? `${Math.abs(summaryCards.todayArrYoy).toFixed()}% YoY` : ''}
							classNames={{ container: styles.leftCard }}
						/>
						<div className={styles.divider} />
						<AnalyticCard
							title={t('quarterlyArrGrowth')}
							quantity={priceNumberWithCurrencySignDisplay(summaryCards.quarterlyArrGrowth, convertCurrencyToSign(appCurrency))}
							subQuantity={summaryCards.quarterlyArrGrowthQoq}
							description={summaryCards.quarterlyArrGrowthQoq ? `${Math.abs(summaryCards.quarterlyArrGrowthQoq).toFixed()}% QoQ` : ''}
							classNames={{ container: styles.leftCard }}
						/>
					</div>

					<div className={styles.analyticsRightCards}>
						{rightMetrics(t, summaryCards, appCurrency).map((card, index) => (
							<AnalyticCard
								key={index}
								title={card.title}
								quantity={card.quantity}
								subQuantity={card.subQuantity}
								description={card.description}
								classNames={{ container: card.styles }}
							/>
						))}
					</div>
				</div>

				<div className={styles.rowContainer}>
					<div className={clsx(styles.left, styles.box)}>
						<div className={styles.headerCol}>
							<div className={styles.title}>{t('arrMomentum')}</div>
							<ClickableCard title={t('ARR')} isSelected color={colors.calming} />
						</div>

						<div className={styles.graphContainer}>
							<StackBarGraph
								isCashflow
								isEmpty={arrMomentumMetrics.isEmpty}
								emptyTitle={t('noDataSource')}
								chartData={{ labels: arrMomentumMetrics.data.labels, datasets: arrMomentumMetrics.data.datasets as StackBarDataset[] }}
								dataFormat={arrMomentumMetrics.dataFormat}
							/>
						</div>
					</div>

					<div className={styles.right}>
						<PermissionsMapping
							actionType={AccountPermissionsActionsList.VIEW_REV_REC}
							disabledFeatureChildren={<RevenueRecognitionComponent timeFilter={mainTimePeriod} featureDisabled classNames={styles} />}
						>
							<RevenueRecognitionComponent timeFilter={mainTimePeriod} classNames={styles} />
						</PermissionsMapping>
					</div>
				</div>

				<div className={styles.productPerfRowContainer}>
					{/* <div className={clsx(styles.left, styles.box)}>
						<div className={styles.headerRow}>
							<div className={styles.title}>{t('totalCustomers')}</div>
						</div>
						<div className={styles.graphContainer}>
							<StackBarGraph
								isCashflow
								isEmpty={customerMetrics.isEmpty}
								emptyTitle={t('noDataSource')}
								chartData={{ labels: customerMetrics.data.labels, datasets: customerMetrics.data.datasets as StackBarDataset[] }}
								dataFormat={customerMetrics.dataFormat}
							/>
						</div>
					</div> */}
					<div className={styles.productPerformance}>
						<div className={styles.headerRow}>
							<div className={styles.filterRow}>
								<div className={styles.title}>{t('productPerformance')}</div>
								<Select
									fullBorder
									data={productPerformancesTypeSelect}
									onChange={(val: ProductPerformanceType) => setProductPerformanceType(val)}
									defaultValue={productPerformanceType}
									width={8}
									className={styles.smallSelect}
								/>
							</div>

							<ClickableCardsList cards={productPerformanceProducts} setCards={setProductPerformanceProducts} />
						</div>

						<div className={styles.graphContainer}>
							<StackBarGraph
								isCashflow
								isEmpty={productPerformanceMetrics?.isEmpty}
								emptyTitle={t('noDataSource')}
								chartData={{ labels: productPerformanceMetrics.data.labels, datasets: productPerformanceMetrics.data.datasets as StackBarDataset[] }}
								dataFormat={productPerformanceMetrics.dataFormat}
							/>
						</div>
					</div>
				</div>
				<Schedule title='schedule' currency={DEFAULT_CURRENCY} yearsList={filterYearsList} />
			</div>
		</div>
	);
};
