import { RevenueRecognitionState } from '@received/pricing-model';
import { RevRecView } from '../types/revRecTypes';
import { ButtonStatusColor } from '../components';

export const revRecStatusColor = (revRec: RevRecView): { color: ButtonStatusColor; text: string } => {
	switch (revRec.status) {
		case RevenueRecognitionState.DRAFT:
			return { color: 'neutral', text: 'Draft' };
		case RevenueRecognitionState.READY:
			return { color: 'primary', text: 'Ready' };
		case RevenueRecognitionState.POSTED:
			return { color: 'success', text: 'Posted' };
		default:
			return { color: 'neutral', text: 'Draft' };
	}
};
