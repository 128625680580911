import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { CellProps, handleCellKeyDown } from '../../TableRow.utils';
import { tableInputNumberWithCommas } from '../../../../../utils/NumberUtils';
import styles from './PercentageCell.module.scss';

export const PercentageCell = ({
	rowData,
	column,
	selectedCell,
	classNames,
	rowIndex,
	columnIndex,
	onArrowNavigation,
	onCellSelected,
	updateTableData,
}: CellProps) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [value, setValue] = useState<string | number>('');

	useEffect(() => {
		setIsEditMode(selectedCell?.cell.cellId === rowData.cells[column.id].cellId);
	}, [selectedCell, rowData]);

	useEffect(() => {
		setValue(rowData.cells[column.id].cellValue);
	}, [rowData]);

	const updateValue = (val: string | number) => {
		setValue(val);

		const newRowData = { ...rowData };
		newRowData.cells[column.id].cellValue = val;
		updateTableData(newRowData);
	};

	return (
		<div className={clsx(styles.cell, classNames?.cell, isEditMode && styles.editMode)}>
			<div className={styles.inputContainer}>
				%
				{!isEditMode ? (
					<span data-testid={`${rowIndex}-row-${column.title}-column-cell-value`}>
						{value && typeof Number(value) === 'number' ? tableInputNumberWithCommas(Number(value)) : value}
					</span>
				) : (
					<input
						data-testid={`${rowIndex}-row-${column.title}-column-cell-input`}
						autoFocus
						value={value}
						type='number'
						onChange={(event) => updateValue(event.target.value)}
						className={clsx(styles.input, isEditMode && styles.isEditable, isEditMode && classNames?.isEditable)}
						onKeyDown={(event) => isEditMode && handleCellKeyDown(event, rowIndex, columnIndex, onArrowNavigation, onCellSelected)}
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						onWheel={(e) => e.target?.blur()}
					></input>
				)}
			</div>
		</div>
	);
};
