import { SyncStatus, convertCurrencyToSign, ContractState, ContractType } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { ButtonStatusColor, SummaryBlockProps } from '../../../components';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { Cell, ContractCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Contract, ContractSummary, ContractView } from '../../../types/contractTypes';
import { summaryType } from '../../../types/invoiceTypes';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { getValueWithCurrencySign, numberWithCommas } from '../../../utils/NumberUtils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { AgreementView } from '../../../types/AgreementTypes';
import styles from './ContractsTab.module.scss';

export const contractsTabTableHeader = [
	{
		headerTitle: 'contract',
		accessor: 'contract',
		CustomComponent: (item: ContractView) => (
			<ContractCell contractNumber={contractIdFormat(item?.poNumber, item?.contractNumber)} subAccessor={item?.name} />
		),
	},
	{
		headerTitle: 'products',
		accessor: 'productName',
		CustomComponent: (item: ContractView) => {
			const { t } = useTranslation('translation');
			const accessor = item?.productName || '';
			const subAccessor = +item?.productCount > 1 ? `+${item?.productCount - 1} ${t(item?.productCount > 2 ? 'Products' : 'product')}` : '';
			return <Cell accessor={accessor} subAccessor={subAccessor} />;
		},
	},
	{
		headerTitle: 'paid',
		accessor: 'totalPayments',
		CustomComponent: (item: ContractView) => {
			return <Cell accessor={item.totalPayments ? `${getValueWithCurrencySign(item.totalPayments, convertCurrencyToSign(item.currency))}` : '-'} />;
		},
	},
	{
		headerTitle: 'nextInvoice',
		accessor: 'nextInvoiceAmount',
		CustomComponent: (item: ContractView) => (
			<Cell
				accessor={item.nextInvoiceAmount ? `${getValueWithCurrencySign(item.nextInvoiceAmount, convertCurrencyToSign(item.currency))}` : '-'}
				subAccessor={item.nextInvoiceDate ? dayjs(item.nextInvoiceDate).format(DATE_FORMAT) : ''}
			/>
		),
	},
	{
		headerTitle: 'lastEdit',
		accessor: 'lastEdit',
		CustomComponent: (item: ContractView) => <Cell accessor={dayjs(item?.lastEdit).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		CustomComponent: (item: ContractView) => {
			const badgeColor = contractStateColor(item);
			return (
				<StatusCol color={badgeColor.color} className={clsx(item?.state == ContractState.END_BILLING && styles.contractEndBillingBadge)}>
					{badgeColor.text}
				</StatusCol>
			);
		},
	},
];

export const contractStateColor = (contract: Contract | ContractView | AgreementView): { color: ButtonStatusColor; text: string } => {
	const syncStatus = getSyncStatus(contract);
	if (syncStatus && syncStatus.status === SyncStatus.OUT_OF_SYNC) {
		return { color: 'warning', text: 'Out of sync' };
	}

	switch (contract.state) {
		case ContractState.DRAFT:
			return { color: 'neutral', text: 'Draft' };
		case ContractState.ACTIVE:
			return { color: 'success', text: 'Active' };
		case ContractState.END_BILLING:
			return { color: 'neutral', text: 'End Billing' };
		case ContractState.CANCELED:
			return { color: 'neutral', text: 'Canceled' };
		default:
			return { color: 'neutral', text: '' };
	}
};

export const getContractSummaryData = async (dispatch: Dispatch, customerId: string, contractType?: ContractType) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getContractSummary,
			params: { customerIds: [customerId], contractType },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};

export const getContractSummaryBlocksData = (t: any, contractSummary?: ContractSummary): SummaryBlockProps[] => {
	return [
		{
			type: summaryType.SUCCESS,
			title: 'activeContracts',
			total: contractSummary?.activeContractAmount,
			subTitle: `${numberWithCommas(contractSummary?.activeContractCount) || '0'} ${t(
				contractSummary?.activeContractCount == 1 ? 'contract' : 'contracts',
			)}`.toLocaleLowerCase(),
			iconColor: 'success',
			iconHeight: 2.4,
			icon: 'upTrend',
		},
		{
			type: summaryType.GREY,
			title: 'endContractsBilling',
			total: contractSummary?.endContractBillingAmount,
			subTitle: `${numberWithCommas(contractSummary?.endContractBillingCount) || '0'} ${t(
				contractSummary?.endContractBillingCount == 1 ? 'contract' : 'contracts',
			)}`.toLocaleLowerCase(),
			iconHeight: 2.4,
			iconColor: 'neutral700',
			icon: 'archive',
		},
	];
};

export const noDraftContractsStates = [ContractState.ACTIVE, ContractState.CANCELED, ContractState.END_BILLING];
