import { ErrorCode } from '@received/pricing-model';

export const convertWarningKeyToString = (warningKey: string) => {
	switch (warningKey) {
		case ErrorCode.CUSTOMER_NOT_FOUND:
			return 'customerNotFound';
		case ErrorCode.SUPPLIER_NOT_FOUND:
			return 'entityNotFound';
		case ErrorCode.CURRENCY_NOT_FOUND:
			return 'currencyNotFound';
		case ErrorCode.USAGE_LOG_DATE_INVALID:
			return 'invalidDate';
		case ErrorCode.USAGE_LOG_ENTITY_DIFFERENT_IN_DOCUMENT:
			return 'documentHaveDifferentEntity';
		case ErrorCode.USAGE_LOG_CURRENCY_DIFFERENT_IN_DOCUMENT:
			return 'documentHaveDifferentCurrency';
		case ErrorCode.USAGE_LOG_CONTRACT_PO_DIFFERENT_IN_DOCUMENT:
			return 'documentHaveDifferentContractPo';
		case ErrorCode.USAGE_LOG_DOCUMENT_NOT_FOUND:
			return 'documentNotFoundForCurrentLog';
		case ErrorCode.USAGE_LOG_QUANTITY_INVALID:
			return 'invalidQuantity';
		case ErrorCode.USAGE_PRODUCT_NOT_FOUND:
			return 'usageProductNotFound';
		default:
			return warningKey;
	}
};
