import { AccountingEventType, DocumentState } from '@received/pricing-model';
import { UsageReportView } from '../../../../../types/usageTypes';

export const isUsageItemCanBeDeleted = (usageReportView: UsageReportView) => {
	switch (usageReportView.accountingEventType) {
		case AccountingEventType.INVOICE:
		case AccountingEventType.DEBIT:
			return Object.values(DocumentState).indexOf(usageReportView.state) < Object.values(DocumentState).indexOf(DocumentState.SENT);
		case AccountingEventType.CREDIT:
			return Object.values(DocumentState).indexOf(usageReportView.state) < Object.values(DocumentState).indexOf(DocumentState.PAID);
	}
};
