import { convertCurrencyToSign, Currency, DATE_FORMAT, PricingType, sum } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClickableCardsList, DateRangeFilters, DoughnutGraph, StackBarDataset, StackBarGraph } from '..';
import { fullFiltersSelectOptions } from '../Filters/DateRangeFilters.utils';
import { DatesData, DoughnutGraphState, GraphState, ProductAnalyticsList } from '../../types/generalTypes';
import { getUniqueListValues, initialDoughnutGraphState, productsColorsList, timePeriodNext12MonthsInitialState } from '../../utils/GeneralUtils';
import { cashTimeUnitHandler } from '../../utils/DateUtils';
import { colors } from '../../constants/colors';
import {
	AmountsSummary,
	CashInflowData,
	initialGraphState,
	OverviewProduct,
} from '../../pages/Contracts/ContractComponent/ContractOverview/ContractOverview.utils';
import { httpService, PathsConfig } from '../../services';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { stackBarDataHandler } from '../_featureComponents/RevenueRecognition/RevenueRecognition.utils';
import { getValueWithCurrencySign } from '../../utils/NumberUtils';
import { PricingModelTab } from '../../types/contractTypes';
import { ProductContractRes } from '../../types/ProductTypes';
import styles from './CashInflow.module.scss';

interface CashInflowProps {
	currency: Currency;
	contractId?: string;
	tabs: PricingModelTab[];
	customerId?: string | null;
	className?: string;
	excludeDraftContracts?: boolean;
}

export const CashInflow = ({ currency, tabs, customerId, contractId, className, excludeDraftContracts = true }: CashInflowProps) => {
	const [timePeriod, setTimePeriod] = useState<DatesData>(timePeriodNext12MonthsInitialState);
	const [cashflowStackBarGraph, setCashflowStackBarGraph] = useState<GraphState>(initialGraphState);
	const [cashflowDoughnutGraph, setCashflowDoughnutGraph] = useState<DoughnutGraphState>(initialDoughnutGraphState);
	const [selectedProductsSummary, setSelectedProductsSummary] = useState<AmountsSummary>();
	const [products, setProducts] = useState<OverviewProduct[]>([]);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		!!tabs.length && getProducts();
	}, [tabs.length]);

	useEffect(() => {
		products?.length && handleCashInflowData();
	}, [products, timePeriod]);

	const getProducts = async () => {
		try {
			const res: ProductContractRes[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractProductsList,
					params: {
						contractId,
						customerId,
						excludeDraftContracts,
					},
				})
			).data;

			const allProducts = getUniqueListValues(res, 'productId')?.flatMap((prod, index) => ({
				id: prod.productId,
				isSelected: true,
				color: productsColorsList[index % productsColorsList?.length].textColor || '',
				productName: prod.productName,
			}));

			setProducts(allProducts);
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getCashflowData = async () => {
		try {
			const selectedProducts = products?.filter((product) => product.isSelected);
			if (!selectedProducts.length) {
				return { entries: [] };
			}
			return (
				await httpService({
					dispatch,
					path: PathsConfig.getCashInflow,
					params: {
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						productIds: products?.filter((product) => product.isSelected).map((product) => product.id),
						customerIds: customerId ? [customerId] : undefined,
						contractId,
						timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to),
					},
				})
			).data;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CASH_INFLOWS }));
		}
	};

	const handleCashInflowData = async () => {
		const cashflow: CashInflowData = await getCashflowData();

		// BARS COLORS BY INDEX
		const cashInflows = cashflow?.entries?.map((month: ProductAnalyticsList) => {
			return {
				...month,
				amountsPerProduct: month?.amountsPerProduct?.map((product) => {
					return {
						...product,
						productName: product.productName,
						color: products?.find((cashflowProduct) => cashflowProduct.id == product.productId)?.color,
					};
				}),
			};
		});

		const { data, isEmpty, dataFormat } = stackBarDataHandler(cashInflows, cashTimeUnitHandler(timePeriod.from, timePeriod.to), timePeriod.value);
		setCashflowStackBarGraph({ data, isEmpty, dataFormat });

		// CHART COLORS BY INDEX
		const doughnutGraphData = data?.datasets?.map((cashflowItem: StackBarDataset) => {
			return {
				backgroundColor: products?.find((cashflowProduct: OverviewProduct) => cashflowProduct.id == cashflowItem.id)?.color || colors.primary,
				hoverBorderColor: products?.find((cashflowProduct: OverviewProduct) => cashflowProduct.id == cashflowItem.id)?.color || colors.primary,
				value: sum(cashflowItem?.data.map((value) => value || 0)),
				label: cashflowItem?.label || '',
			};
		});
		setCashflowDoughnutGraph({
			data: doughnutGraphData,
			isEmpty,
			dataFormat,
		});

		const singleSelectedProduct =
			products?.map((product) => product.isSelected)?.length == 1 ? products?.find((product) => product.isSelected) : undefined;

		setSelectedProductsSummary({
			subTotal: cashflow?.summary?.subTotal,
			totalDiscount: cashflow?.summary?.totalDiscount,
			totalTax: cashflow?.summary?.totalTax,
			totalDue: cashflow?.summary?.totalDue,
			discountPercent: singleSelectedProduct ? singleSelectedProduct?.discount : undefined,
			taxPercent: singleSelectedProduct ? singleSelectedProduct?.tax : undefined,
		});
	};

	const isUsageProductSelected = () => {
		return products?.filter((product) => product.isSelected).every((product) => product?.pricingType === PricingType.USAGE);
	};

	return (
		<div className={clsx(styles.box, styles.fullBox, className)}>
			<div className={styles.topInfoContainer}>
				<div className={styles.topInfo}>
					<span className={styles.boldTitle}>{t('cashInflows')}</span>

					<div className={styles.timeFilter}>
						<span className={styles.dates}>
							{dayjs(timePeriod.from).format(DATE_FORMAT)} - {dayjs(timePeriod.to).format(DATE_FORMAT)}
						</span>
						<DateRangeFilters
							data={fullFiltersSelectOptions}
							defaultSelectValue={timePeriodNext12MonthsInitialState.title}
							width={18}
							callback={setTimePeriod}
							selectClassName={clsx(styles.select, styles.editable)}
						/>
					</div>
				</div>

				<ClickableCardsList cards={products} setCards={setProducts} />
			</div>
			<div className={styles.bottomInfoContainer}>
				<div className={clsx(styles.leftBottomInfo, cashflowStackBarGraph.isEmpty && styles.emptyState)}>
					{!cashflowStackBarGraph.isEmpty && (
						<div className={styles.productDataContainer}>
							<div className={styles.minWidthBox}>
								<span className={styles.title}>{t('subtotal')}</span>
								<span className={styles.text}>{getValueWithCurrencySign(selectedProductsSummary?.subTotal, convertCurrencyToSign(currency), 0)}</span>
							</div>

							<div className={styles.minWidthBox}>
								<span className={styles.title}>
									{t('discount')}
									{selectedProductsSummary?.discountPercent ? `(${selectedProductsSummary?.discountPercent}%)` : ''}
								</span>
								<span className={styles.text}>
									({getValueWithCurrencySign(selectedProductsSummary?.totalDiscount, convertCurrencyToSign(currency), 0)})
								</span>
							</div>

							<div className={styles.minWidthBox}>
								<span className={styles.title}>
									{t('tax')} {selectedProductsSummary?.taxPercent ? `(${selectedProductsSummary?.taxPercent}%)` : ''}
								</span>
								<span className={styles.text}>{getValueWithCurrencySign(selectedProductsSummary?.totalTax, convertCurrencyToSign(currency), 0)}</span>
							</div>

							<div className={clsx(styles.minWidthBox, styles.wideBox)}>
								<span className={styles.title}>{t('totalDue')}</span>
								<span className={styles.text}>{getValueWithCurrencySign(selectedProductsSummary?.totalDue, convertCurrencyToSign(currency), 0)}</span>
							</div>
						</div>
					)}
					<div>
						<StackBarGraph
							isCashflow
							isStacked
							isEmpty={cashflowStackBarGraph.isEmpty}
							emptyTitle={isUsageProductSelected() ? t('noUsageActivities') : t('noDataSource')}
							chartData={{ labels: cashflowStackBarGraph.data.labels, datasets: cashflowStackBarGraph.data.datasets as StackBarDataset[] }}
							dataFormat={cashflowStackBarGraph.dataFormat}
							customStyle={{ height: '220px' }}
							currency={currency}
						/>
					</div>
				</div>
				<div className={styles.rightBottomInfo}>
					<DoughnutGraph
						data={cashflowDoughnutGraph.data}
						isEmpty={cashflowDoughnutGraph.isEmpty}
						dataFormat={cashflowDoughnutGraph.dataFormat}
						emptyTitle={isUsageProductSelected() ? t('noUsageActivities') : t('noDataSource')}
						customStyle={{ height: '255px' }}
						currency={currency}
					/>
				</div>
			</div>
		</div>
	);
};
