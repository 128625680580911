import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ActionModalModal, Button, EmptyStateCard, Table } from '../../../../components';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { images } from '../../../../constants/images';
import { DATE_FORMAT_FOR_SERVER } from '../../../../constants/templateConstants';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { httpService } from '../../../../services/httpService/httpService';
import { UsageReportView, UsageSummaryView } from '../../../../types/usageTypes';
import { UsageProductsRow } from '../../UsageProductsRow/UsageProductsRow';
import { UsageReportResponse, usageReportsTableHeader } from './UsageReportsTab.utils';
import { onDeleteUsageProduct } from '../../../../components/UsageProductModal/UsageProductModal.utils';
import { onPressDeleteUsageReport, usageOptionList } from '../../Usage.utils';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { DatesData, Sort, SortOrder } from '../../../../types/generalTypes';
import { productsColorsList } from '../../../../utils/GeneralUtils';
import { SidebarType } from '../../../../components/Sidebars/Sidebars.utils';
import styles from './UsageReportsTab.module.scss';

interface UsageReportsTabProps {
	isActive: boolean;
	refreshTab: boolean;
	openSideBarById?: string;
	timePeriod: DatesData;
	selectedUsageProductsIds: string[];
	selectedUsageProduct: string;
	selectedUsageReport: UsageReportView;
	selectedUsageList: UsageReportView[];
	setIsAddUsageProductOpen: Dispatch<SetStateAction<boolean>>;
	setSelectedUsageProductsIds: Dispatch<SetStateAction<string[]>>;
	setSelectedUsageProduct: Dispatch<SetStateAction<string>>;
	setSelectedUsageReport: Dispatch<SetStateAction<UsageReportView | undefined>>;
	setOpenSideBarById: Dispatch<SetStateAction<{ sideBarType: SidebarType; id: string }>>;
	setSelectedUsageList: Dispatch<SetStateAction<UsageReportView[]>>;
}

export const UsageReportsTab = ({
	isActive,
	timePeriod,
	selectedUsageReport,
	selectedUsageProductsIds,
	selectedUsageList,
	refreshTab,
	setSelectedUsageList,
	setIsAddUsageProductOpen,
	setSelectedUsageProductsIds,
	setSelectedUsageProduct,
	setSelectedUsageReport,
	setOpenSideBarById,
}: UsageReportsTabProps) => {
	const { t } = useTranslation('translation');

	const [usageReportsList, setUsageReportsList] = useState<UsageReportView[]>([]);
	const [numberOfItems, setNumberOfItems] = useState('20');
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [usageSummaryList, setUsageSummaryList] = useState<UsageSummaryView[]>([]);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [sort, setSort] = useState<Sort>({ orderBy: 'startDate', order: SortOrder.DESC });

	const dispatch = useDispatch();

	useEffect(() => {
		isActive && onRefresh();
	}, [selectedUsageProductsIds, timePeriod, isActive, refreshTab]);

	useEffect(() => {
		getUsageReportsData();
	}, [numberOfItems, pageNumber, sort]);

	const getUsageReportsData = async () => {
		try {
			const res: UsageReportResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getUsageReportsViewData,
					params: {
						pageSize: parseInt(numberOfItems),
						pageNumber: pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						usageProductIds: selectedUsageProductsIds || undefined,
						fromDate: dayjs(timePeriod.from).format(DATE_FORMAT_FOR_SERVER),
						toDate: dayjs(timePeriod.to).format(DATE_FORMAT_FOR_SERVER),
					},
				})
			).data;

			setTotalCount(res?.count || 0);
			setUsageReportsList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_USAGE_REPORTS_DATA }));
		}
	};

	const getUsageData = async () => {
		try {
			const res: UsageSummaryView[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getUsageProductSummaryList,
					params: {
						fromDate: dayjs(timePeriod.from).format(DATE_FORMAT_FOR_SERVER),
						toDate: dayjs(timePeriod.to).format(DATE_FORMAT_FOR_SERVER),
					},
				})
			).data;
			const usages = res?.map((usageSummary: UsageSummaryView, index) => {
				return {
					...usageSummary,
					colors: productsColorsList[index % productsColorsList.length],
				};
			});

			setUsageSummaryList(usages);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
		}
	};

	const onOpenReport = (rowData: UsageReportView) => {
		setSelectedUsageReport(rowData);
		setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: rowData?.usageReportId });
	};

	const onDeleteReport = (rowData: UsageReportView) => {
		setIsDeleteModalOpen(true);
		setSelectedUsageReport(rowData);
	};

	const onCloseDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedUsageReport(undefined);
	};

	const onRefresh = () => {
		getUsageData();
		setSelectedUsageList([]);
	};

	const onSelectUsageProduct = (id: string) => {
		if (selectedUsageProductsIds.includes(id)) {
			setSelectedUsageProductsIds(selectedUsageProductsIds.filter((usageProductId) => usageProductId !== id));
		} else {
			setSelectedUsageProductsIds([...selectedUsageProductsIds, id]);
		}
	};

	const onEditUsageProduct = (id: string) => {
		setSelectedUsageProduct(id);
		setIsAddUsageProductOpen(true);
	};

	const onDeleteUsageProductById = async (id: string) => {
		await onDeleteUsageProduct(id, dispatch);
		await Promise.all([getUsageReportsData(), onRefresh()]);
	};

	const onUsageChecked = (isChecked: boolean, row: UsageReportView) => {
		if (isChecked) {
			setSelectedUsageList([...selectedUsageList, row]);
		} else {
			setSelectedUsageList(selectedUsageList.filter((report) => report.usageReportId !== row.usageReportId));
		}
	};

	const pageItemsOptions = [
		{ label: `20 ${t('items')}`, value: '20' },
		{ label: `50 ${t('items')}`, value: '50' },
	];

	return (
		<div className={styles.pageContainer}>
			<div className={styles.pageHeader}>
				<UsageProductsRow
					productList={usageSummaryList}
					onClickOnUsage={onSelectUsageProduct}
					onDeleteUsageProduct={onDeleteUsageProductById}
					onEditOnUsage={onEditUsageProduct}
					selectedUsageProductIds={selectedUsageProductsIds}
				/>
			</div>

			<Table
				sort={sort}
				showCheckBox
				pageItemsOptions={pageItemsOptions}
				numberOfItems={numberOfItems}
				header={usageReportsTableHeader}
				rows={usageReportsList}
				className={styles.tableContainer}
				numberOfPages={Math.ceil(totalCount / +numberOfItems)}
				totalNumberOfItems={totalCount}
				onUpdateNumberOfItems={setNumberOfItems}
				onUpdatePageNumber={setPageNumber}
				optionsList={(row) => usageOptionList(row, onDeleteReport, dispatch)}
				tableRowClassName={styles.tableRowClassName}
				onRowPress={onOpenReport}
				onRowChecked={onUsageChecked}
				onCheckAll={(isChecked) => (isChecked ? setSelectedUsageList([...usageReportsList]) : setSelectedUsageList([]))}
				onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				emptyState={
					<Button
						color='neutral'
						type='link'
						className={styles.emptyStateCard}
						onClick={() => setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: '' })}
					>
						<EmptyStateCard
							containerStyle={styles.emptyState}
							src={images.addUsageReport}
							emptyIconClassName={styles.emptyIcon}
							imgAlt='empty-state'
							title={t('noUsageReports')}
							subTitle={t('addUsageReports')}
						/>
					</Button>
				}
			/>

			<ActionModalModal
				rightButton={{ title: 'delete', color: 'destructive' }}
				imagesOptions={images.deleteItem}
				isOpen={isDeleteModalOpen}
				deleteMessage={`${t('deleteMessagePrefix').replace('__', 'usage report')} ${t('deleteMessagePostfix').replace('__', 'report')}`}
				onAccept={() => {
					selectedUsageReport && onPressDeleteUsageReport(dispatch, onRefresh, selectedUsageReport);
					onCloseDeleteModal();
				}}
				onClose={onCloseDeleteModal}
			/>
		</div>
	);
};
