import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Icon, Modal, Tabs } from '..';
import { tabs } from './CreateSupplier.utils';
import { PathsConfig, httpService } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { DEFAULT_COUNTRY } from '../../constants/generalConstants';
import { Customer } from '../../types/customerTypes';
import styles from './CreateSupplier.module.scss';

interface CreateSupplierProps {
	isOpen: boolean;
	companiesListLength: number;
	defaultEntity?: Customer;
	overlayClassName?: string;
	selectedTab?: number;
	selectedBankId?: string | null;
	closeModal(): void;
	onCreateUpdateSupplier?(data: Customer): void;
}
export const CreateSupplier = ({
	isOpen,
	defaultEntity,
	overlayClassName,
	selectedTab,
	selectedBankId,
	companiesListLength,
	closeModal,
	onCreateUpdateSupplier,
}: CreateSupplierProps) => {
	const { t } = useTranslation('translation');
	const [activeTab, setActiveTab] = useState(0);
	const [supplier, setSupplier] = useState<Customer>();

	const dispatch = useDispatch();

	useEffect(() => {
		defaultEntity?.id && setSupplier(defaultEntity);
	}, [defaultEntity?.id]);

	useEffect(() => {
		setActiveTab(selectedTab || 0);
	}, [selectedTab]);

	const postCompany = async () => {
		defaultEntity ? updateSupplier() : createSupplier();
	};

	const createSupplier = async () => {
		try {
			let tempSupplier = { ...supplier };
			if (companiesListLength === 0) {
				tempSupplier = { ...tempSupplier, isDefault: true };
			}

			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.addNewSupplier,
					data: {
						...tempSupplier,
						countryState: tempSupplier?.country !== DEFAULT_COUNTRY ? null : tempSupplier?.countryState,
					},
				})
			).data;

			onCreateUpdateSupplier?.(res);
			onClose();
		} catch (error) {
			dispatch(
				setOpenSuccessErrorModal({
					responseError: error,
					successErrorMassage: successErrorMassageOptions.ERROR.CREATE_COMPANY,
				}),
			);
		}
	};

	const updateSupplier = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.updateSupplier,
				urlParam: { id: supplier?.id ?? '' },
				data: {
					...supplier,
					countryState: supplier?.country !== DEFAULT_COUNTRY ? null : supplier?.countryState,
				},
			});

			onCreateUpdateSupplier?.(res.data);
			onClose();
		} catch (error) {
			dispatch(
				setOpenSuccessErrorModal({
					responseError: error,
					successErrorMassage: successErrorMassageOptions.ERROR.UPDATE_COMPANY,
				}),
			);
		}
	};

	const onClose = () => {
		setActiveTab(0);

		setSupplier(undefined);
		closeModal();
	};

	return (
		<Modal isOpen={isOpen} closeModal={onClose} overlayClassName={overlayClassName}>
			<div className={styles.container}>
				<div className={styles.titleContainer}>
					<div className={styles.details}>
						<span className={styles.title}>{t('entityDetails')}</span>
						<Tabs
							tabsArray={tabs.map((tab) => tab.title)}
							classNames={styles}
							style={{ border: 'none' }}
							chosenTab={activeTab}
							updateChosenTab={setActiveTab}
						/>
					</div>

					<Button tabIndex={30} type='link' color='neutral' onClick={onClose} className={styles.button}>
						<Icon imgType='x_icon' color='neutral700' className={styles.x_icon} />
					</Button>
				</div>
				{tabs[activeTab].component({ selectedBankId, supplier, postCompany, setSupplier, setActiveTab })}
			</div>
		</Modal>
	);
};
