import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon } from '../_uiComponents';
import { setShowHideToastNotification } from '../../storeSlices/generalSlice';
import { Store } from '../../types/storeTypes';
import { toastNotification, ToastNotificationComponentType } from './NotificationCard.utils';
import { colors } from '../../constants/colors';
import styles from './NotificationCard.module.scss';

export const NotificationCard = () => {
	const [seconds, setSeconds] = useState(10);
	const { showToastNotification, toastNotificationComponent, title, description, additionalData, icon, sideColor } = useSelector(
		(store: Store) => store.general.toastNotification,
	);

	const dispatch = useDispatch();

	useEffect(() => {
		const interval = setInterval(() => {
			if (showToastNotification) {
				if (seconds > 0 && toastNotificationComponent === ToastNotificationComponentType.RefreshInTenSec) {
					setSeconds((prev) => prev - 1);
				} else {
					clearInterval(interval);
				}
			}
		}, 1000);

		if (seconds === 0 && toastNotificationComponent === ToastNotificationComponentType.RefreshInTenSec && showToastNotification) {
			additionalData?.onRefresh && additionalData.onRefresh();
			dispatch(
				setShowHideToastNotification({
					showToastNotification: false,
					toastNotificationComponent: null,
					additionalData: undefined,
					title: '',
					description: '',
				}),
			);
		}

		return () => clearInterval(interval);
	}, [seconds, toastNotificationComponent, showToastNotification]);

	return showToastNotification
		? ReactDOM.createPortal(
				<div className={styles.container}>
					<div className={styles.blueContainer}>
						<Icon imgType={icon || 'information'} color='white' height={2} className={styles.informationIcon} />
						<div className={styles.blueSide} style={{ height: `${seconds * 10}%`, backgroundColor: sideColor || colors.primary }}></div>
					</div>
					<div className={styles.textContainer}>{toastNotification(toastNotificationComponent, seconds, title, description, additionalData)}</div>

					<Button
						onClick={() =>
							dispatch(
								setShowHideToastNotification({
									showToastNotification: false,
									toastNotificationComponent: null,
									additionalData: undefined,
									title: '',
									description: '',
								}),
							)
						}
						type='link'
						color='neutral'
						className={styles.xIcon}
					>
						<Icon imgType='x_icon' />
					</Button>
				</div>,
				document.body,
		  )
		: null;
};
