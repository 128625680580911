import { ContractState } from '@received/pricing-model';
import { ButtonStatusColor } from '../components';

export const partnersAgreementColor = (contract: any): { color: ButtonStatusColor; text: string } => {
	switch (contract.state) {
		case ContractState.DRAFT:
			return { color: 'neutral', text: 'Draft' };
		case ContractState.ACTIVE:
			return { color: 'success', text: 'Active' };
		case ContractState.END_BILLING:
			return { color: 'neutral', text: 'End Billing' };
		case ContractState.CANCELED:
			return { color: 'neutral', text: 'Canceled' };
		default:
			return { color: 'neutral', text: '' };
	}
};
