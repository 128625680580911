import { useTranslation } from 'react-i18next';
import { images } from '../../../constants/images';
import { Button, Modal } from '../../_uiComponents';
import styles from './CustomerSuccessModal.module.scss';

export interface CustomerSuccessProps {
	isOpen: boolean;
	lastCreatedCustomer: { name: string; id: string };
	closeModal(): void;
	onAddCustomer(): void;
	onOverviewCustomer(): void;
}

export const CustomerSuccessModal = ({ isOpen, lastCreatedCustomer, closeModal, onAddCustomer, onOverviewCustomer }: CustomerSuccessProps) => {
	const { t } = useTranslation('translation');

	return (
		<Modal isOpen={isOpen} closeModal={closeModal}>
			<div className={styles.modalContainer}>
				<img alt='customer-success' src={images.createCustomer} className={styles.icon}></img>
				<h1 className={styles.title}>{t('greatJob')}</h1>
				<span className={styles.text}>{`${t('youJustAdded')} ${lastCreatedCustomer.name}. ${t('youCanCreate')}`}</span>

				<div className={styles.buttonContainer}>
					<Button onClick={onAddCustomer} type='secondary' className={styles.button}>
						{t('addAnotherCustomer')}
					</Button>
					<Button onClick={onOverviewCustomer} className={styles.button}>
						{t('customerOverview')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
