import React, { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { BasePlacement } from '@popperjs/core';
import { ButtonStatusColor } from '..';
import { IconImgType } from '../Icon/IconOptions';
import { MenuItem } from './MenuItem/MenuItem';
import styles from './MenuList.module.scss';

export interface DisabledMenuOptionProps {
	isDisabled?: boolean;
	reason?: React.ReactNode;
	tooltipStyle?: string;
}

export interface MenuOption {
	title: string;
	iconType?: IconImgType;
	iconWidth?: number;
	color?: ButtonStatusColor;
	hasBottomBorder?: boolean;
	subList?: MenuOption[];
	disabled?: DisabledMenuOptionProps;
	onPress?: (props?: any) => void;
}

export interface MenuListProps {
	optionsList?: MenuOption[];
	isMenuOpen: boolean;
	children: React.ReactNode;
	customTop?: number;
	customLeft?: number;
	className?: string;
	placement?: BasePlacement;
	offsetClassName?: string;
	offsetStyles?: React.CSSProperties;
	openMenu(isOpen: boolean): void;
}

export const MenuList = ({
	customTop,
	placement,
	customLeft,
	optionsList = [],
	children,
	isMenuOpen,
	className,
	offsetClassName,
	offsetStyles,
	openMenu,
}: MenuListProps) => {
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const [currentOpenIndex, setCurrentOpenIndex] = useState<number>();

	useEffect(() => {
		window.addEventListener('resize', () => {});
	});

	const { styles: style, attributes } = usePopper(referenceElement, popperElement, {
		placement: placement || 'top',
		modifiers: [
			{
				name: 'offset',
				enabled: true,
				options: {
					offset: [customTop || -10, customLeft || 10],
				},
			},
		],
	});

	return (
		<div className={clsx(styles.container, className)}>
			<div style={{ ...style.offset, ...offsetStyles }} className={offsetClassName} ref={(ref) => setReferenceElement(ref)}>
				{children}
			</div>
			{isMenuOpen && optionsList.length
				? ReactDOM.createPortal(
						<>
							<div
								ref={(ref) => setPopperElement(ref)}
								{...attributes.popper}
								data-testid='menu-list-portal'
								className={isMenuOpen ? styles.menuList : styles.menuListHidden}
								style={style.popper}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								{optionsList.map((item, index) => (
									<MenuItem
										index={index}
										key={index}
										currentOpenIndex={currentOpenIndex}
										optionItem={item}
										openMenu={(open) => openMenu(open)}
										updateSelectedIndex={setCurrentOpenIndex}
									/>
								))}
							</div>
							{isMenuOpen && (
								<div
									className={styles.selectOverlay}
									onClick={(e) => {
										e.stopPropagation();
										openMenu(false);
										setCurrentOpenIndex(undefined);
									}}
								></div>
							)}
						</>,
						document.body,
				  )
				: null}
		</div>
	);
};
