import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	AccountingEventType,
	RevenueRecognitionPeriod,
	BillingCycleUnit,
	BillingCycleStruct,
	ContractState,
	ContractType,
} from '@received/pricing-model';
import { CustomBillingCycle, PricingModelTableComponent, TermsAndSettings } from '../../..';
import { AccountPermissionsActionsList } from '../../../../types/generalTypes';
import { initialCyclicDay } from './../../PricingModelTabContent.utils';
import { PricingModelTableViewTypeOptions } from '../../../PricingModelTable/PricingModelTable.utils';
import { PermissionsMapping } from '../../../../services/PermissionsMapping/PermissionsMapping';
import { Contract, PricingModelTab, RevenueSettings } from '../../../../types/contractTypes';
import { Customer } from '../../../../types/customerTypes';
import { CreditNoteTabSettings } from './CreditNoteTabSettings/CreditNoteTabSettings';
import { InvoiceTabSettings } from './InvoiceTabSettings/InvoiceTabSettings';
import { RevRecSettings } from './RevRecSettings/RevRecSettings';
import {
	getBillingStartDate,
	onUpdateCurrency,
	updatePaymentPeriod,
	updatePricingModelTable,
	updateRevRecPaymentPeriod,
} from '../../PricingModelTabContentActions';
import { ReferralTabSettings } from './ReferralTabSettings/ReferralTabSettings';
import styles from './ContractContent.module.scss';

export interface ContractContentProps {
	contract?: Contract;
	selectedCustomer?: Customer;
	pricingModelTab: PricingModelTab;
	isEditable?: boolean;
	className?: string;
	contractType?: ContractType;
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void;
	onUpdateManualReportFromTable?: () => void;
}

export const ContractContent = ({
	contract,
	selectedCustomer,
	pricingModelTab,
	className,
	isEditable,
	contractType,
	updatePricingModelData,
}: ContractContentProps) => {
	const [isCustomBillingCycleOpen, setIsCustomBillingCycleOpen] = useState(false);

	const notEditableTab =
		(!!contract?.editBlockers?.length &&
			contract.state === ContractState.ACTIVE &&
			!!pricingModelTab?.lastActiveDate &&
			pricingModelTab.billingCycle.unit === BillingCycleUnit.ONE_TIME) ||
		!isEditable;

	useEffect(() => {
		updatePaymentPeriod(pricingModelTab, updatePricingModelData);
	}, [pricingModelTab?.issueDay, pricingModelTab?.billingStartDate, pricingModelTab?.billingEndDate, pricingModelTab?.billingCycle]);

	useEffect(() => {
		updateRevRecPaymentPeriod(pricingModelTab, updatePricingModelData);
	}, [
		pricingModelTab?.revenueSettings?.postingDay,
		pricingModelTab?.revenueSettings?.revenueCycle,
		pricingModelTab?.revenueSettings?.revenueRecognitionPeriod,
	]);

	const updateActivationDates = (startDate: Date | null, endDate: Date | null) => {
		const tempModel = { ...pricingModelTab };
		tempModel.billingStartDate = startDate;
		tempModel.billingEndDate = endDate;

		tempModel.issueDay = getBillingStartDate(tempModel?.billingCycle, startDate, endDate);

		updatePricingModelData(tempModel, true);
	};

	const updateRevRecActivationDates = (revenueSettings: RevenueSettings) => {
		const revenueCycle = pricingModelTab?.revenueSettings?.revenueCycle;
		let postingDay;

		if (revenueCycle?.unit === BillingCycleUnit.QUARTERLY) {
			postingDay = initialCyclicDay;
		} else {
			switch (revenueSettings?.revenueRecognitionPeriod) {
				case RevenueRecognitionPeriod.CONTRACT_ACTIVATION: {
					postingDay = getBillingStartDate(revenueCycle as BillingCycleStruct, contract?.activationStartDate);
					break;
				}
				case RevenueRecognitionPeriod.CUSTOM: {
					postingDay = getBillingStartDate(revenueCycle as BillingCycleStruct, revenueSettings?.recognitionPeriodStart);
					break;
				}
			}
		}

		updatePricingModelData?.(
			{
				...pricingModelTab,
				revenueSettings: { ...pricingModelTab.revenueSettings, ...revenueSettings, postingDay },
			},
			true,
		);
	};

	const getSettingsByAccountingEventType = (accountingEventType?: AccountingEventType) => {
		switch (accountingEventType) {
			case AccountingEventType.DEBIT:
			case AccountingEventType.INVOICE:
				return (
					<InvoiceTabSettings
						contract={contract}
						pricingModelTab={pricingModelTab}
						updateActivationDates={updateActivationDates}
						updatePricingModelData={updatePricingModelData}
						updateCurrency={(currency) => onUpdateCurrency(currency, pricingModelTab, updatePricingModelData)}
					/>
				);
			case AccountingEventType.PAYOUT:
				return (
					<ReferralTabSettings
						contract={contract}
						pricingModelTab={pricingModelTab}
						updateActivationDates={updateActivationDates}
						updatePricingModelData={updatePricingModelData}
						updateCurrency={(currency) => onUpdateCurrency(currency, pricingModelTab, updatePricingModelData)}
					/>
				);
			case AccountingEventType.CREDIT:
				return (
					<CreditNoteTabSettings
						contract={contract}
						pricingModelTab={pricingModelTab}
						updateActivationDates={updateActivationDates}
						updatePricingModelData={updatePricingModelData}
						updateCurrency={(currency) => onUpdateCurrency(currency, pricingModelTab, updatePricingModelData)}
					/>
				);
			default:
				break;
		}
	};

	const onUpdateCustomBillingCycle = (billingCycle: BillingCycleStruct) => {
		pricingModelTab &&
			updatePricingModelData?.({
				...pricingModelTab,
				pricingCycle: null,
				billingCycle: { unit: billingCycle?.unit, cycle: billingCycle?.cycle },
			});
	};

	return (
		<div className={clsx(notEditableTab && styles.notAllowedCursor)}>
			<div className={clsx(styles.container, notEditableTab && styles.notEditableContract, className)}>
				{getSettingsByAccountingEventType(pricingModelTab?.accountingEventType)}

				{contractType != ContractType.REFERRAL && (
					<PermissionsMapping
						actionType={AccountPermissionsActionsList.VIEW_REV_REC}
						disabledFeatureChildren={<RevRecSettings disabledFeature pricingModelTab={pricingModelTab} className={styles.sectionContainer} />}
					>
						<RevRecSettings
							pricingModelTab={pricingModelTab}
							updatePricingModelData={(data, instantUpdate?: boolean) => updatePricingModelData(data, instantUpdate)}
							updateActivationDates={updateRevRecActivationDates}
							className={styles.sectionContainer}
						/>
					</PermissionsMapping>
				)}

				<PricingModelTableComponent
					pricingModelTableViewType={PricingModelTableViewTypeOptions.CONTRACT}
					pricingModelTable={pricingModelTab?.pricingModelTable}
					pricingModelTab={pricingModelTab}
					isEditable={!notEditableTab}
					selectedCustomer={selectedCustomer}
					updateTableData={(table) => updatePricingModelTable(table, pricingModelTab, updatePricingModelData)}
					updatePricingModelData={(data, instantUpdate?: boolean) => updatePricingModelData(data, instantUpdate)}
					classNames={{ tableContainer: styles.tableSectionContainer, settings: styles.tableHeaderContainer }}
				/>

				<TermsAndSettings
					pricingModelTab={pricingModelTab}
					customerId={selectedCustomer?.id}
					updatePricingTermsData={({ note, supplierId, billingDetails, cryptoSettings }, instantUpdate?: boolean) => {
						updatePricingModelData(
							{
								...pricingModelTab,
								billingDetails: { ...pricingModelTab.billingDetails, ...billingDetails },
								cryptoSettings: { ...pricingModelTab.cryptoSettings, ...cryptoSettings },
								supplierId,
								note,
							},
							instantUpdate,
						);
					}}
					updatePricingSummaryData={({ tax, discount }) => updatePricingModelData({ ...pricingModelTab, tax, discount })}
					updateCreditSettings={({ note, supplierId, billingDetails }) => {
						updatePricingModelData({
							...pricingModelTab,
							note,
							supplierId,
							billingDetails: { ...pricingModelTab.billingDetails, ...billingDetails },
						});
					}}
				/>

				<CustomBillingCycle
					isOpen={isCustomBillingCycleOpen}
					billingCycle={pricingModelTab?.billingCycle}
					onUpdateCustomBillingCycle={onUpdateCustomBillingCycle}
					onClose={() => setIsCustomBillingCycleOpen(false)}
				/>
			</div>
		</div>
	);
};
