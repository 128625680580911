import dayjs from 'dayjs';
import { AccountingEventType, CryptoSettings, Currency, DocumentEditBlocker, DocumentState, NetTerms, UsageProduct } from '@received/pricing-model';
import { Transform, Type } from 'class-transformer';
import { BillingDetails, PricingModel } from './contractTypes';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { UsageReportView } from './usageTypes';
import { Customer } from './customerTypes';

export class Payout {
	id: string;
	customerId?: string;
	customer?: Customer;
	pricingModelId?: string;
	@Type(() => PricingModel)
	pricingModel: PricingModel;
	currency: Currency;
	state?: DocumentState;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	paidDate?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	dueDate?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	createdAt?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	issueDate?: Date;
	draftNumber?: string;
	draftNumberPrefix?: string;
	isDraft: boolean;
	documentNumber: string;
	total?: number;
	totalTax?: number;
	tax?: number;
	subTotal?: number;
	contractPricingModelTabIds?: string[];
	isCollective?: boolean;
	poNumber?: string;
	postingDate?: Date;
	accountingEventType?: AccountingEventType;
	totalInSeries?: number;
	numberInSeries?: number;
	editBlockers?: DocumentEditBlocker[];
	netTerms?: NetTerms;
	supplierId?: string;
	supplier?: Customer;
	billingDetails?: BillingDetails;
	contractId?: string;
	usageReportsByProduct?: any[];
	contractNumber?: string;
	relatedUsageProducts?: UsageProduct[];
	usageReports?: UsageReportView[];
	hasUsageReports?: boolean;
	index?: number;
	cryptoSettings?: CryptoSettings;
}

export interface PayoutView {
	id: string;
	partnerId: string;
	partnerName: string;
	partnerEmail: string;
	partnerIconData: string;
	agreementId: string;
	agreementName: string;
	agreementNumber: number;
	poNumber: string;
	issueDate: Date;
	netTerms: NetTerms;
	payoutDueDate: Date;
	payoutNumber: string;
	payoutNumberInSeries: number;
	payoutSeriesTotalCount: number;
	totalFee: number;
	currency: Currency;
	state: DocumentState;
	accountingEventType: AccountingEventType;
	hasUsageReports: boolean;
	editBlockers?: DocumentEditBlocker[];
	updatedAt: Date;
}

export interface PayoutsSummary {
	pendingSum: number;
	missingInfoCount: number;
	readyCount: number;
	lateSum: number;
	lateCount: number;
	paidSum: number;
	paidCount: number;
}

export interface PayoutsResponse {
	entries: PayoutView[];
	count: number;
}
