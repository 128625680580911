import { IntegrationCategory, IntegrationPlatform, IntegrationProvider, IntegrationStatus } from '@received/pricing-model';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, getButtonByStatus, getTagByStatus } from '../../..';
import { PermissionsMapping } from '../../../../services/PermissionsMapping/PermissionsMapping';
import styles from './IntegrationCard.module.scss';

export interface IntegrationCardProps {
	supplierId?: string;
	title: string;
	disabled?: boolean;
	description?: string;
	imgType: string;
	platform: IntegrationPlatform;
	connectionStatus: IntegrationStatus;
	haveConfigurationOptions: boolean;
	category: IntegrationCategory;
	provider: IntegrationProvider;
	navigateToMatchingPage?: boolean;
	onConnect: () => void;
	onDisconnect?: () => void;
	onCancel?: () => void;
}

export const IntegrationCard = ({
	title,
	connectionStatus,
	platform,
	haveConfigurationOptions,
	description,
	disabled,
	imgType,
	category,
	provider,
	supplierId,
	navigateToMatchingPage,
	onConnect,
	onDisconnect,
	onCancel,
}: IntegrationCardProps) => {
	const { t } = useTranslation('translation');
	const [buttonStatus, setButtonStatus] = useState<IntegrationStatus>(IntegrationStatus.UNLINKED);

	const navigate = useNavigate();

	useEffect(() => {
		setButtonStatus(connectionStatus);
	}, [connectionStatus]);

	useEffect(() => {
		navigateToMatchingPage && navigate('../integrations', { state: { integrationType: platform, category, provider, supplierId, connectionStatus } });
	}, [navigateToMatchingPage]);

	return (
		<div className={clsx(styles.card, disabled && styles.cardDisabled)}>
			<div className={styles.topContainer}>
				<div className={styles.iconContainer}>
					<img alt='cardIcon' src={imgType} className={styles.cardIcon}></img>
				</div>
				{getTagByStatus(t, buttonStatus)}
			</div>

			<h2>{t(title)}</h2>

			<span className={styles.cardDescription}>{description}</span>
			<PermissionsMapping
				actionType={platform}
				disabledFeatureChildren={
					<Button disabled type='outlined' color='neutral' className={styles.cardButton}>
						{t('comingSoon')}
					</Button>
				}
			>
				{getButtonByStatus(
					t,
					buttonStatus,
					haveConfigurationOptions,
					onConnect,
					() => navigate('../integrations', { state: { integrationType: platform, category, provider, supplierId, connectionStatus: status } }),
					onDisconnect,
					disabled,
					onCancel,
				)}
			</PermissionsMapping>
		</div>
	);
};
