import React, { CSSProperties, useEffect, useState } from 'react';
import clsx from 'clsx';
import styles from './Switch.module.scss';

export interface SwitchProps {
	defaultChecked?: boolean;
	defaultDisable?: boolean;
	smallSize?: boolean;
	className?: string;
	switchColor?: string;
	style?: CSSProperties;
	dataTestId?: string;
	onChange?: (value: boolean) => void;
}

export const Switch = ({ defaultChecked = true, smallSize, className, switchColor, defaultDisable, style, dataTestId, onChange }: SwitchProps) => {
	const [isChecked, setIsChecked] = useState(defaultChecked);
	const [isDisable, setIsDisable] = useState(defaultDisable);

	useEffect(() => {
		setIsChecked(defaultChecked);
	}, [defaultChecked]);

	useEffect(() => {
		setIsDisable(defaultDisable);
	}, [defaultDisable]);

	const onValueChange = () => {
		setIsChecked(!isChecked);
		onChange && onChange(!isChecked);
	};

	return (
		<div
			className={clsx(
				styles.container,
				smallSize && styles.smallContainer,
				isChecked ? styles.switchOn : styles.switchOff,
				isDisable && styles.disabled,
				className,
			)}
			data-testid={dataTestId || `switch-${isChecked ? 'on' : 'off'}`}
			style={{ backgroundColor: isChecked && switchColor ? switchColor : undefined, ...style }}
			onClick={onValueChange}
		>
			<div className={clsx(styles.movingSwitch, isChecked ? styles.movingSwitchRight : styles.movingSwitchLeft)}></div>
		</div>
	);
};
