import { useState } from 'react';
import clsx from 'clsx';
import { Button, Icon } from '..';
import styles from './ActionSelect.module.scss';

export interface ActionSelectProps {
	title?: string;
	optionList: { title: string; onClick(): void }[];
	disabled?: boolean;
}

export const ActionSelect = ({ optionList, title, disabled }: ActionSelectProps) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div>
			<Button onClick={() => setIsOpen(!isOpen)} className={styles.mainButton} disabled={disabled}>
				{title}
				<Icon imgType='arrow_down' color='white' width={1.5} className={isOpen ? styles.arrowUp : styles.arrowDown} />
			</Button>

			<div
				className={clsx(styles.selectDropdownContainer, isOpen ? styles.selectDropdownContainerOpen : styles.selectDropdownClose)}
				style={{ height: isOpen ? `${optionList.length * 4.5}rem` : 0 }}
			>
				{optionList?.map(({ title, onClick }, index) => (
					<Button
						key={index}
						color='neutral'
						type='link'
						className={styles.selectItem}
						onClick={() => {
							onClick();
							setIsOpen(false);
						}}
					>
						{title}
					</Button>
				))}
			</div>
			{isOpen && <div className={styles.selectOverlay} onClick={() => setIsOpen(false)}></div>}
		</div>
	);
};
