import React, { CSSProperties, useMemo } from 'react';
import { convertCurrencyToSign, InvoiceCredit, sum } from '@received/pricing-model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getValueWithCurrencySign } from '../../utils/NumberUtils';
import { PricingModelTab } from '../../types/contractTypes';
import { InputIcon, UpdatePricingSummary } from '..';
import styles from './CreditNotePricingSummary.module.scss';

interface CreditNotePricingSummaryProps {
	className?: string;
	style?: CSSProperties;
	pricingModelTab: PricingModelTab;
	invoiceCredits?: InvoiceCredit[];
	updatePricingSummaryData: ({ tax, discount }: UpdatePricingSummary) => void;
}

export const CreditNotePricingSummary = ({
	className,
	style,
	pricingModelTab,
	invoiceCredits,
	updatePricingSummaryData,
}: CreditNotePricingSummaryProps) => {
	const { pricingModelTableTotal, tax, discount } = pricingModelTab;
	const { t } = useTranslation('translation');

	const discountValue = useMemo(() => (pricingModelTableTotal * (discount || 0)) / 100, [discount, pricingModelTableTotal]);
	const taxValue = useMemo(() => ((pricingModelTableTotal - discountValue) * tax) / 100, [discountValue, pricingModelTableTotal, tax]);
	const totalValue = useMemo(() => pricingModelTableTotal - discountValue + taxValue, [discountValue, pricingModelTableTotal, taxValue]);
	const usedCredit = sum(invoiceCredits?.map((invoiceCredit) => invoiceCredit?.usedCredit));

	return (
		<div className={clsx(styles.container, className)} style={style}>
			<div className={clsx(styles.title)}>{t('summary')}</div>

			<div className={clsx(styles.subTitle, styles.row)}>
				<div>{t('subtotal')}</div>
				<div className={styles.sumWrapper}>{getValueWithCurrencySign(pricingModelTableTotal, convertCurrencyToSign(pricingModelTab.currency))}</div>
			</div>

			<div className={clsx(styles.subTitle, styles.row)}>
				<div>{t('tax')}</div>

				<div className={styles.sumWrapper}>
					<span className={clsx(taxValue == 0 && styles.fadedText)}>
						{getValueWithCurrencySign(taxValue, convertCurrencyToSign(pricingModelTab.currency))}
					</span>
					<InputIcon value={tax} prefixSign={'%'} type='number' onChange={(val) => updatePricingSummaryData({ tax: Number(val), discount })} />
				</div>
			</div>

			<div className={styles.flexColumn}>
				<div className={styles.row} style={{ margin: 0 }}>
					<div className={styles.total}>{t('Total')}</div>
					<div className={styles.totalSumWrapper}>{getValueWithCurrencySign(totalValue, convertCurrencyToSign(pricingModelTab.currency))}</div>
				</div>
				{!!invoiceCredits?.length && <div className={styles.note}>{t('creditFutureUse')}</div>}
			</div>
			{!!invoiceCredits?.length && (
				<>
					{!!usedCredit && (
						<div className={styles.creditRow}>
							<div className={styles.boldSubTitle}>{t('previousCreditsPaid')}</div>
							<div className={styles.totalSumWrapper}>{`(${getValueWithCurrencySign(
								usedCredit,
								convertCurrencyToSign(pricingModelTab.currency),
							)})`}</div>
						</div>
					)}
					<div className={styles.creditRow}>
						<div className={styles.boldSubTitle}>{t('creditBalance')}</div>
						<div className={styles.totalSumWrapper}>
							{getValueWithCurrencySign(totalValue - usedCredit, convertCurrencyToSign(pricingModelTab.currency))}
						</div>
					</div>
				</>
			)}
		</div>
	);
};
