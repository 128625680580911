import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Currency, CurrencyListItem, SettingsCategory, SettingsName, currencyList } from '@received/pricing-model';
import { EmptyStateCard, Icon, Select } from '../../components';
import { Store } from '../../types/storeTypes';
import { AccountTabs, AuthorizedPage, Sections, settingsNestedPages } from './Settings.utils';
import { images } from '../../constants/images';
import { setAppCurrency, setSidebarIsOpen } from '../../storeSlices/generalSlice';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig, httpService } from '../../services';
import { PermissionsMapping } from '../../services/PermissionsMapping/PermissionsMapping';
import styles from './Settings.module.scss';

export const Settings = () => {
	const { t } = useTranslation('translation');
	const [activeTab, setActiveTab] = useState({ activeSection: Sections.ACCOUNT, activeButton: AccountTabs.PERSONAL_INFO });
	const [filteredSettings, setFilteredSettings] = useState<AuthorizedPage[]>([]);

	const userRole = useSelector((store: Store) => store.user.role);
	const { isSidebarOpen, appCurrency } = useSelector((store: Store) => store.general);
	const dispatch = useDispatch();
	const location: any = useLocation();

	useEffect(() => {
		location?.state?.activeSection && setActiveTab({ activeSection: location?.state?.activeSection, activeButton: location?.state?.activeButton });
	}, []);

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, [isSidebarOpen]);

	useEffect(() => {
		setFilteredSettings(settingsNestedPages.filter((item) => !item.requiredRole || item.requiredRole === userRole));
	}, [userRole]);

	const onUpdateCurrency = async (value: Currency) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.updateSettingsDataByAttributeName,
				data: { category: SettingsCategory.COMMON, settingName: SettingsName.DEFAULT_CURRENCY, value: value },
			});

			dispatch(setAppCurrency(value));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_UPDATE_CURRENCY }));
		}
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<header className={styles.pageHeader}>
					<div>
						<h1>{t('settings')}</h1>
						<span className={styles.description}>{t('settingsDescription')}</span>
					</div>
					<Select
						fullBorder
						className={styles.entitySelect}
						defaultValue={appCurrency}
						onChange={(value: any) => onUpdateCurrency(value)}
						width={14}
						headerStyle={styles.selectHeaderStyle}
						data={currencyList.map((item: CurrencyListItem) => ({
							label: `${item.flag} ${item.currency}`,
							value: item.currency,
						}))}
						required
					/>
				</header>

				<div className={styles.settingsContainer}>
					<div className={styles.settingsList}>
						{filteredSettings.length ? (
							filteredSettings.map((item, index) => (
								<div key={index} className={styles.listContainer}>
									<div className={styles.listHeader}>
										<span className={styles.listTitle}>{t(item.header)}</span>
										<span className={styles.listDescription}>{t(item.description)}</span>
									</div>

									{item.items.map((item, itemIndex) => {
										const active = activeTab.activeSection === index && activeTab.activeButton === itemIndex;
										return item.actionType ? (
											<PermissionsMapping key={itemIndex} actionType={item.actionType}>
												<div
													onClick={() => setActiveTab({ activeSection: index, activeButton: itemIndex })}
													className={clsx(styles.settingsButton, active && styles.settingsButtonSelected)}
												>
													{item?.iconImgType && <Icon color={active ? 'primary' : 'neutral700'} imgType={item.iconImgType} height={1.8}></Icon>}
													<div>{t(item.label)}</div>
												</div>
											</PermissionsMapping>
										) : (
											<div
												key={itemIndex}
												onClick={() => setActiveTab({ activeSection: index, activeButton: itemIndex })}
												className={clsx(styles.settingsButton, active && styles.settingsButtonSelected)}
											>
												{item?.iconImgType && <Icon color='neutral700' imgType={item.iconImgType} height={1.8}></Icon>}
												<div>{t(item.label)}</div>
											</div>
										);
									})}
								</div>
							))
						) : (
							<div className={styles.emptyContainer}>
								<EmptyStateCard src={images.wip} imgAlt='wip' title={t('wip')} />
							</div>
						)}
					</div>

					<div className={styles.settingsCard}>
						{filteredSettings.length ? (
							filteredSettings?.[activeTab.activeSection]?.items?.[activeTab.activeButton]?.component({
								chosenPlatform: location?.state?.chosenPlatform,
							})
						) : (
							<div className={styles.emptyContainer}>
								<EmptyStateCard src={images.wip} imgAlt='wip' title={t('wip')} />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
