import React from 'react';
import Lottie from 'lottie-react';
import creatingLottie from '../../assets/lottie/creatingGlobal.json';
import styles from './CreateIndicator.module.scss';

interface CreateIndicatorProps {
	isShown: boolean;
	lottie?: any;
}

export const CreateIndicator = ({ isShown, lottie }: CreateIndicatorProps) => {
	return isShown ? (
		<div className={styles.container}>
			<Lottie animationData={lottie || creatingLottie} loop={true} className={styles.lottie} />
		</div>
	) : null;
};
