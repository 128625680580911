import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CreateProduct, Modal } from '../../../components';
import styles from './AddProductModal.module.scss';

export interface AddProductModalProps {
	isOpen: boolean;
	editProductData?: { name: string; id: string };
	onProductAdded(productId: string, productName: string): void;
	closeModal(): void;
}

export const AddProductModal = ({ isOpen, editProductData, onProductAdded, closeModal }: AddProductModalProps) => {
	const { t } = useTranslation('translation');

	const onProductAdd = (productId: string, productName: string) => {
		onProductAdded(productId, productName);
		closeModal();
	};

	return (
		<Modal isOpen={isOpen} closeModal={closeModal} className={styles.modalContainer}>
			<CreateProduct editProductData={editProductData} onProductAdded={onProductAdd} />
			<Button type='link' color='neutral' onClick={closeModal} className={styles.footerButtons}>
				{t('Cancel')}
			</Button>
		</Modal>
	);
};
