import React from 'react';
import styles from './Main.module.scss';

type MainProps = {
	children: React.ReactNode;
};

export const Main = ({ children }: MainProps) => {
	return <div className={styles.mainContainer}>{children}</div>;
};
