import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../../_uiComponents';
import { UsageConnectionStatusTypes } from '../../../../types/usageTypes';
import styles from './UsageConnectionStatus.module.scss';

interface UsageConnectionStatusProps {
	status?: UsageConnectionStatusTypes;
	onClick?: () => void;
}

export const UsageConnectionStatus = ({ status, onClick }: UsageConnectionStatusProps) => {
	const { t } = useTranslation('translation');
	const statusTitle = () => {
		switch (status) {
			case UsageConnectionStatusTypes.MANUAL:
				return 'Manual usage product';
			case UsageConnectionStatusTypes.API:
				return 'Connected API product';
			case UsageConnectionStatusTypes.NOT_CONNECTED:
				return 'Connect usage product';
			default:
				return 'Choose usage product';
		}
	};

	return (
		<div className={styles.container}>
			<div className={clsx(styles.dot, status && styles[status])}></div>
			<div className={clsx(styles.dashedBorder, status && styles[status])}></div>
			<div className={clsx(styles.statusContainer, status && styles[status])}>
				{t(statusTitle())}
				{status && <Icon imgType={status === 'NOT_CONNECTED' ? 'error_icon' : 'v_circle'} color='white' className={styles.icon} />}
			</div>
			<div className={clsx(styles.dashedBorder, status && styles[status])}></div>
			<div className={clsx(styles.dot, status && styles[status])}></div>
		</div>
	);
};
