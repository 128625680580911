import dayjs from 'dayjs';
import { convertCurrencyToSign, AccountingEventType } from '@received/pricing-model';
import { Cell, PriceCell } from '../../_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { InvoiceView } from '../../../types/invoiceTypes';
import { getCreditUsed, getTotalDue } from '../../../utils/InvoiceUtils';
import { getDocumentIdNameByType } from '../../../utils/GeneralUtils';
import { CreditNoteView } from '../../../types/creditNoteTypes';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { formatSentIssueDays } from '../../../pages/_Billing/Invoices/AllInvoices/AllInvoices.utils';
import { getInvoiceNumberInSeries } from '../../../pages/_Billing/Invoices/Invoices.utils';
import { SidebarType } from '../../Sidebars/Sidebars.utils';
import { BillingDocumentType, BillingDocumentViewType } from '../../../types/generalTypes';
import { getStatusTagByDocumentType } from '../../../utils/DocumentsUtils';

export const customerLastActivityTableHeader = (refreshData: () => void) => [
	{
		headerTitle: 'contractNo',
		accessor: 'contractNumber',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<Cell accessor={contractIdFormat(item?.poNumber, item?.contractNumber)} subAccessor={item.contractName} />
		),
	},
	{
		headerTitle: 'updatedAt',
		accessor: 'updatedAt',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.updatedAt).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'documentNo',
		accessor: 'documentNumber',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => {
			return <Cell accessor={getDocumentIdNameByType(item)} subAccessor={getInvoiceNumberInSeries(item)} />;
		},
	},
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.issueDate).format(DATE_FORMAT)} subAccessor={formatSentIssueDays(item)} />,
	},
	{
		headerTitle: 'totalDue',
		accessor: 'total',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<PriceCell currency={convertCurrencyToSign(item.currency)} mainNumber={getTotalDue(item)} subNumber={getCreditUsed(item)} />
		),
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		disableRowClick: true,
		isSortable: true,
		CustomComponent: (document: InvoiceView | CreditNoteView) => getStatusTagByDocumentType({ document, refreshData }),
	},
];

export const getSidebarTypeByDocumentType = (document?: BillingDocumentType | BillingDocumentViewType): SidebarType => {
	switch (document?.accountingEventType) {
		case AccountingEventType.INVOICE:
		case AccountingEventType.DEBIT:
			return SidebarType.INVOICE;
		case AccountingEventType.CREDIT:
			return SidebarType.CREDIT_NOTE;
		case AccountingEventType.PAYOUT:
			return SidebarType.PAYOUT;
		default:
			return SidebarType.INVOICE;
	}
};
