import { PaymentTerms } from '@received/pricing-model';
import { NodeEnvTypes } from '../../types/generalTypes';

export const DUE_UPON_RECEIPT_LABEL = 'Due upon receipt';
export const DUE_UPON_USAGE_LABEL = 'Due upon usage';

export const defaultNetTerms = PaymentTerms.DUE_UPON_RECEIPT;

export const netTermsSelect = (isUsageProduct?: boolean) => [
	{
		label: DUE_UPON_RECEIPT_LABEL,
		value: PaymentTerms.DUE_UPON_RECEIPT,
	},
	{
		label: DUE_UPON_USAGE_LABEL,
		value: PaymentTerms.DUE_UPON_USAGE,
		isDisabled: !isUsageProduct || window._env_.REACT_APP_NODE_ENV === NodeEnvTypes.PRODUCTION,
	},
	{
		label: 'Net 30',
		value: 30,
	},
	{
		label: 'Net 45',
		value: 45,
	},
	{
		label: 'Net 60',
		value: 60,
	},
	{
		label: 'Net 90',
		value: 90,
	},
];
