import React, { ChangeEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Input } from '..';
import styles from './InputIcon.module.scss';

export interface InputIconProps {
	value: string | number;
	className?: string;
	prefixSign: string;
	type?: string;
	inputContainerClassName?: string;
	onChange?: (val: string, event: ChangeEvent<HTMLInputElement>) => void;
	onSwitchPrefix?: () => void;
}

export const InputIcon = ({ value, className, prefixSign, type, inputContainerClassName, onChange, onSwitchPrefix }: InputIconProps) => {
	const [currentValue, setCurrentValue] = useState<string | number>('');

	useEffect(() => {
		setCurrentValue(value);
	}, [value]);

	const onUpdateValue = (val: string, event: ChangeEvent<HTMLInputElement>) => {
		setCurrentValue(val);
		onChange && onChange(val, event);
	};

	return (
		<div className={clsx(styles.container, className)}>
			<Button type='secondary' color='neutral' onClick={onSwitchPrefix} className={styles.prefixSign} disabled={!onSwitchPrefix}>
				{prefixSign}
			</Button>

			<Input
				fullBorder
				type={type}
				value={currentValue}
				onChange={onUpdateValue}
				containerClass={clsx(styles.inputContainer, inputContainerClassName)}
				className={clsx(styles.inputContainer, inputContainerClassName)}
				inputClassName={clsx(styles.inputContainer, inputContainerClassName)}
			></Input>
		</div>
	);
};
