import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, NewEntity, Entity, CreateSupplier, ActionModalModal } from '../../../components';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../services';
import { getSuppliers, deleteDataEmptyState, shortCustomerData } from '../../../utils/CustomerUtils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Customer } from '../../../types/customerTypes';
import { images } from '../../../constants/images';
import styles from './CompanyInfo.module.scss';

export const CompanyInfo = () => {
	const { t } = useTranslation('translation');
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState<Customer>();
	const [companies, setCompanies] = useState<Customer[]>([]);
	const [customerDeleteData, setCustomerDeleteData] = useState<shortCustomerData>(deleteDataEmptyState);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		getSupplierCompanies();
	}, []);

	const getSupplierCompanies = async () => {
		const suppliers = await getSuppliers(dispatch, true);
		setCompanies(suppliers);
	};

	const deleteCompany = async (id: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.deleteSupplier,
				urlParam: { id },
			});
			await getSupplierCompanies();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.DELETE_COMPANY }));
		}
	};

	const setCompanyAsDefault = async (id: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.updateSupplier,
				urlParam: { id },
				data: {
					isDefault: true,
				},
			});
			await getSupplierCompanies();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.UPDATE_COMPANY }));
		}
	};

	const getCompanyById = async (id: string) => {
		try {
			const entity = (
				await httpService({
					dispatch,
					path: PathsConfig.getSupplierData,
					urlParam: { id },
				})
			).data;

			setSelectedCompany(entity);
			setShowCreateModal(true);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_COMPANY_BY_ID }));
		}
	};

	const onPressDelete = (id: string, name: string) => {
		setIsDeleteModalOpen(true);
		setCustomerDeleteData({ id, name });
	};

	const onDeleteCompany = (id: string) => {
		deleteCompany(id);
		setIsDeleteModalOpen(false);
	};

	const onCloseDeleteModal = () => {
		setCustomerDeleteData(deleteDataEmptyState);
		setIsDeleteModalOpen(false);
	};

	return (
		<>
			<div className={styles.pageHeader}>
				<h2>{t('companyInfo')}</h2>
				<Button type='secondary' onClick={() => setShowCreateModal(true)} className={styles.newEntityButton}>
					{t('newEntity')} +
				</Button>
			</div>

			<div className={styles.entitiesContainer}>
				{companies?.map((company) => (
					<Entity key={company?.id} entity={company} onClick={getCompanyById} onDefault={setCompanyAsDefault} onRemove={onPressDelete} />
				))}

				<NewEntity onClick={() => setShowCreateModal(true)} />
			</div>

			<CreateSupplier
				isOpen={showCreateModal}
				companiesListLength={companies.length}
				closeModal={() => {
					setShowCreateModal(false);
					setSelectedCompany(undefined);
				}}
				defaultEntity={selectedCompany}
				onCreateUpdateSupplier={getSupplierCompanies}
			/>
			<ActionModalModal
				rightButton={{ title: 'delete', color: 'destructive' }}
				imagesOptions={images.deleteItem}
				isOpen={isDeleteModalOpen}
				deleteMessage={
					<>
						{t('deleteMessagePrefix').replace('__', 'entity')}
						<span className={styles.name}> {customerDeleteData.name}</span> {t('deleteMessagePostfix').replace('__', 'entity')}
					</>
				}
				onAccept={() => onDeleteCompany(customerDeleteData.id)}
				onClose={onCloseDeleteModal}
			/>
		</>
	);
};
