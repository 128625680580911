import { DEFAULT_CURRENCY, AccountingEventType, DocumentState } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ButtonStatusColor, ButtonTypes, MenuOption } from '../components';
import { creditNoteReasonCodesLabelsSelect } from '../constants/generalConstants';
import { httpService, PathsConfig } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { CreditNote, CreditNoteView } from '../types/creditNoteTypes';
import { UsageReportView } from '../types/usageTypes';
import { setShowHideToastNotification } from '../storeSlices/generalSlice';
import { ToastNotificationComponentType } from '../components/NotificationCard/NotificationCard.utils';
import { deleteCreditNote } from '../components/_customerTabs/CreditsNotesTab/CreditsNotesTab.utils';
import { downloadDocumentUsageLogs } from '../pages/Usage/Usage.utils';
import { DocumentMenuListProps } from './DocumentsUtils';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';

dayjs.extend(isToday);
dayjs.extend(relativeTime);

export const initialCreditNoteState: CreditNote = {
	pricingModel: { tabs: [] },
	state: DocumentState.OPEN,
	reasonCode: creditNoteReasonCodesLabelsSelect[0].value,
	currency: DEFAULT_CURRENCY,
	isDraft: true,
	balance: 0,
	accountingEventType: AccountingEventType.CREDIT,
	documentNumber: '',
	editBlockers: [],
};

export const getCreditNoteDateDescription = (creditNoteCreationDate?: Date) => {
	if (dayjs(creditNoteCreationDate).isToday()) return 'Today';
	else return dayjs(creditNoteCreationDate).fromNow();
};

export const isCreditNoteEditable = (creditNote?: CreditNote) => !creditNote?.editBlockers?.length;

export const startGenerateCreditNotePDFById = async (dispatch: Dispatch, id?: string) => {
	if (!id) return;

	try {
		await httpService({
			dispatch,
			path: PathsConfig.generateCreditIdForDownload,
			urlParam: { id },
		});

		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
				title: 'PreparingFileForDownload',
				description: 'PreparingFileForDownloadDescription',
				icon: 'clock',
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const onDuplicateCredit = async (dispatch: Dispatch, creditId?: string, onSuccess?: () => void) => {
	if (!creditId) return;
	try {
		await httpService({
			dispatch,
			path: PathsConfig.duplicateCreditIdById,
			urlParam: { id: creditId },
		});
		onSuccess && onSuccess();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const creditNoteStatus = (state?: DocumentState, isShort?: boolean): { color?: ButtonStatusColor; type?: ButtonTypes; text: string } => {
	switch (state) {
		case DocumentState.OPEN:
			return { color: 'primary', type: 'secondary', text: 'adjustment' };
		case DocumentState.DRAFT:
			return { color: 'neutral', type: 'secondary', text: 'draft' };
		case DocumentState.REVIEW_READY_TO_SEND:
			return { color: 'primary', type: 'outlined', text: isShort ? 'ready' : 'readyToSend' };
		case DocumentState.REVIEW_MISSING_DATA:
			return { color: 'warning', type: 'secondary', text: 'missingInfo' };
		case DocumentState.PAID:
			return { color: 'success', type: 'secondary', text: 'paid' };
		case DocumentState.CANCELED:
			return { color: 'neutral', type: 'outlined', text: 'canceled' };

		default:
			return { color: undefined, type: undefined, text: '' };
	}
};

export const getCreditNoteIdName = (creditNote: CreditNote | CreditNoteView | UsageReportView) => {
	if ((creditNote as CreditNote | CreditNoteView).documentNumber) {
		return (creditNote as CreditNote | CreditNoteView).documentNumber;
	} else if ((creditNote as UsageReportView).documentNumber) {
		return (creditNote as UsageReportView).documentNumber;
	} else if (creditNote.draftNumber) {
		return `DRAFT-${creditNote?.draftNumber}`;
	} else {
		return '';
	}
};

export const isCreditEditableByState = (creditState?: DocumentState) =>
	creditState == DocumentState.DRAFT ||
	creditState == DocumentState.REVIEW_MISSING_DATA ||
	creditState == DocumentState.REVIEW_READY_TO_SEND ||
	creditState == DocumentState.OPEN;

export const getSortingOrderList = (accessor: string) => {
	switch (accessor) {
		case 'issueDate':
			return ['issueDate', 'draftNumber'];

		default:
			return [accessor, 'issueDate'];
	}
};

export const creditNoteMenuList = ({ item, dispatch, navigate, t, editDocumentNumber, onRefresh, onPreview }: DocumentMenuListProps) => {
	let listOptions: MenuOption[] = [];

	if (isCreditEditableByState(item?.state) && isCreditNoteEditable(item as CreditNote)) {
		listOptions = [
			...listOptions,
			{
				title: t('edit'),
				onPress: () => navigate('../single-credit-note', { state: { creditNoteId: item?.id } }),
				iconType: 'edit',
			},
		];
	}

	if (onPreview && item.state !== DocumentState.CANCELED) {
		listOptions = [
			...listOptions,
			{
				title: t('preview'),
				onPress: () => onPreview(),
				iconType: 'eye',
			},
		];
	}

	listOptions = [
		...listOptions,
		{
			title: t('Duplicate'),
			onPress: () => onDuplicateCredit(dispatch, item?.id, onRefresh),
			iconType: 'duplicate',
		},
		{
			title: t('downloadDocument'),
			onPress: () => startGenerateCreditNotePDFById(dispatch, item?.id),
			iconType: 'download',
		},
	];

	if (editDocumentNumber) {
		listOptions = [...listOptions, editDocumentNumber];
	}

	if (item?.hasUsageReports) {
		listOptions = [
			...listOptions,
			{
				title: t('downloadUsageReports'),
				onPress: () => item?.hasUsageReports && downloadDocumentUsageLogs(item.id ? [item.id] : [], dispatch),
				iconType: 'download',
			},
		];
	}

	if (item.state !== DocumentState.CANCELED) {
		if (item?.accountingEventType == AccountingEventType.CREDIT && onRefresh && !item?.invoiceCredits?.length && item.state == DocumentState.OPEN) {
			listOptions = [
				...listOptions,
				{
					title: t('delete'),
					onPress: () => deleteCreditNote(dispatch, onRefresh, item?.id),
					iconType: 'delete',
					color: 'destructive',
				},
			];
		}
	}
	return listOptions;
};

export const getCreditNoteSummaryData = async (
	dispatch: Dispatch,
	fromDate: Date | string,
	toDate: Date | string,
	customerIds?: string[],
	textFilter?: string,
) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getCreditNotesSummary,
			params: { fromDate, toDate, customerIds: customerIds || [], textFilter: textFilter || undefined },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};
