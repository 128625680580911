import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../../types/storeTypes';
import { Button, Icon, ImportItemsProgress, Tabs, Select, SearchBar, CategoriesTabsItem, MenuList, CreateSubscription } from '../../components';
import { AddProductModal } from './AddProductModal/AddProductModal';
import { setSidebarIsOpen } from '../../storeSlices/generalSlice';
import { pricingSelectList, pricingTabsIndex, pricingTabsList } from './PricingModels.utils';
import { SubscriptionView } from '../../types/subscriptionTypes';
import { NodeEnvTypes, Sort, SortOrder } from '../../types/generalTypes';
import { getSubscriptionList } from './PricingModelTabs/SubscriptionsTab/SubscriptionsTab.utils';
import styles from './PricingModels.module.scss';

interface PricingModelsProps {
	isUsedAsComponent?: boolean;
	localLocation?: any;
	navigateToNewModel?: (data: any) => void;
}

export const PricingModels = ({ isUsedAsComponent, localLocation, navigateToNewModel }: PricingModelsProps) => {
	const { t } = useTranslation('translation');
	const [openProductModal, setOpenProductModal] = useState(false);
	const [selectedFilter, setSelectedFilter] = useState('');
	const [searchText, setSearchText] = useState('');
	const [selectedTab, setSelectedTab] = useState(0);
	const [createdProductId, setCreatedProductId] = useState('');
	const [selectedProduct, setSelectedProduct] = useState<CategoriesTabsItem>();
	const [openNewButtonMenu, setOpenNewButtonMenu] = useState(false);
	const [openCreateSubscription, setOpenCreateSubscription] = useState(false);
	const [selectedSubscriptionId, setSelectedSubscriptionId] = useState<string>();
	const [subscriptions, setSubscriptions] = useState<SubscriptionView[]>([]);
	const [subscriptionSort, setSubscriptionSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.ASC });

	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location: any = useLocation();

	const currentLocation = localLocation || location.state;

	useEffect(() => {
		!isOpen && !isUsedAsComponent && dispatch(setSidebarIsOpen(true));
		if (location?.state?.opedCreateProduct) {
			setOpenProductModal(true);
			location.state.opedCreateProduct = null;
		}
	}, []);

	useEffect(() => {
		if (currentLocation?.product?.id) {
			setCreatedProductId(currentLocation?.product?.id || '');
			setSelectedTab(pricingTabsIndex.products);
			currentLocation.product = null;
		}
	}, []);

	useEffect(() => {
		if (selectedTab === pricingTabsIndex.subscriptionPlans) {
			updateSubscriptionList();
		}
	}, [selectedTab]);

	const updateSubscriptionList = async () => {
		const list = await getSubscriptionList(dispatch, subscriptionSort);
		setSubscriptions(list?.subscriptions);
	};

	const onSelectTab = (tabNumber: number) => {
		setSelectedTab(tabNumber);
		setSelectedFilter('');
		setSelectedProduct(undefined);
		setCreatedProductId('');
	};

	const onUseThisPricing = () => {
		const modelData = { productId: selectedProduct?.id || '', productName: selectedProduct?.name || '' };
		navigateToNewModel
			? navigateToNewModel(modelData)
			: navigate('/pricing-models/new-pricing-model', {
					state: modelData,
			  });
	};

	const menuOptions = () => {
		let list = [
			{
				title: t('pricing'),
				onPress: () => onUseThisPricing(),
			},
		];

		if (window._env_.REACT_APP_NODE_ENV != NodeEnvTypes.PRODUCTION) {
			list = [
				...list,
				{
					title: t('subscription'),
					onPress: () => setOpenCreateSubscription(true),
				},
			];
		}
		return list;
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.mainContainer}>
				<ImportItemsProgress />
				<header className={styles.pageHeader}>
					<h1>{t('pricing')}</h1>

					<div className={styles.headerButtons}>
						<SearchBar onChange={setSearchText} className={styles.searchBar} placeholder={`${t('search')}...`} />
						<Select
							fullBorder
							width={25}
							data={pricingSelectList(t)}
							disabled={selectedTab != pricingTabsIndex.products}
							defaultValue={selectedFilter}
							onChange={(selected) => setSelectedFilter(selected)}
						/>
						<MenuList optionsList={menuOptions()} openMenu={() => setOpenNewButtonMenu(false)} isMenuOpen={openNewButtonMenu}>
							<Button dataTestId='new-product-button' onClick={() => setOpenNewButtonMenu(true)} className={styles.newProduct}>
								{t('new')}
								<Icon imgType='add' color='white' width={1} className={styles.addIcon} />
							</Button>
						</MenuList>
					</div>
				</header>

				<Tabs
					id='pricing-model-tab-button'
					tabsArray={pricingTabsList().map((tab) => ({ label: t(tab.label), value: tab.label }))}
					updateChosenTab={onSelectTab}
					chosenTab={selectedTab}
					classNames={{ tabsContainer: styles.tabsContainer, tabComponent: styles.tabComponent, activeLabel: styles.activeTab }}
				/>

				{pricingTabsList()[selectedTab].component({
					navigateToNewModel,
					selectedFilter,
					searchText,
					createdProductId,
					selectedProduct,
					openCreateSubscription,
					selectedSubscriptionId,
					subscriptions,
					subscriptionSort,
					setSubscriptionSort,
					updateSubscriptionList,
					setSubscriptions,
					setSelectedSubscriptionId,
					setOpenCreateSubscription,
					setSelectedProduct,
					onAddPricingModel: () => setOpenProductModal(true),
				})}
			</div>

			<AddProductModal
				isOpen={openProductModal}
				onProductAdded={(id) => {
					setSelectedTab(pricingTabsIndex.products);
					setCreatedProductId(id);
				}}
				closeModal={() => setOpenProductModal(false)}
			/>

			<CreateSubscription
				selectedSubscriptionId={selectedSubscriptionId}
				isOpen={openCreateSubscription}
				onCreateSubscription={() => {
					updateSubscriptionList();
					setSelectedTab(pricingTabsIndex.subscriptionPlans);
				}}
				closeSideBar={() => {
					setOpenCreateSubscription(false);
					setSelectedSubscriptionId(undefined);
				}}
			/>
		</div>
	);
};
