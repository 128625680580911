import React from 'react';
import { Button, Icon } from '../../_uiComponents';
import { FilterTag, SideBarFilters } from '../FilterSideBar';
import styles from './FilterTags.module.scss';

interface FilterTagsProps {
	filterData: SideBarFilters;
	onRemoveTag(tags: SideBarFilters): void;
}

export const FilterTags = ({ filterData, onRemoveTag }: FilterTagsProps) => {
	const onRemovePress = (removeTag: FilterTag) => {
		const newTags = filterData.filterTags.filter((tag) => tag.keys !== removeTag.keys);
		let newFilters = { ...filterData.filterData };
		if (removeTag.filterArray) {
			newFilters = { ...newFilters, [removeTag.filterArray]: newFilters[removeTag.filterArray].filter((item: string) => item !== removeTag.id) };
		} else {
			removeTag.keys.forEach((key: string) => delete newFilters[key]);
		}
		onRemoveTag({ filterTags: newTags, filterData: newFilters });
	};
	return (
		<div className={styles.tagsRow}>
			{filterData.filterTags.map((tag, index) => (
				<div key={index} className={styles.filterTag}>
					<div>{tag.title}</div>
					<Button onClick={() => onRemovePress(tag)} color='neutral' type='link' className={styles.xButton}>
						<Icon imgType='x_icon' color='neutral700' className={styles.xButton} />
					</Button>
				</div>
			))}
		</div>
	);
};
