import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { BillingCycleUnit, CellTypes, CyclicDate, EventsGenerationMethod, RevenueRecognitionPeriod } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import {
	DEFAULT_BILLING_CYCLE_VALUE,
	billingCycleSelect,
	paymentTimeSelect,
	recognitionMethodSelect,
	recognitionPeriodSelect,
} from '../../../../../constants/generalConstants';
import { PricingModelTableColumns } from '../../../../../types/pricingModelTypes';
import { Button, DatePicker, Icon, Input, IssueDatePicker, Select, Tooltip } from '../../../../_uiComponents';
import { PaymentTimeOptions } from '../../../../../types/generalTypes';
import { onClickUpgradeRevRecFeature } from '../../../../../utils/GeneralUtils';
import { PricingModelTab, RevenueSettings } from '../../../../../types/contractTypes';
import { isRevRecPaymentPeriodDisabled } from '../../../../PricingModelTabContent/PricingModelTabContent.utils';
import { diamond } from '../../../../../constants/unicodes';
import { DATE_FORMAT } from '../../../../../constants/templateConstants';
import { FooterTypeOptions } from '../../../../_uiComponents/DatePicker/Footer/Footer';
import styles from './RevRecSettings.module.scss';

interface RevRecSettingsProps {
	pricingModelTab?: PricingModelTab;
	disabledFeature?: boolean;
	className?: string;
	updatePricingModelData?: (data: PricingModelTab, instantUpdate?: boolean) => void;
	updateActivationDates?: (revenueSettings: RevenueSettings) => void;
}

export const RevRecSettings = ({
	pricingModelTab,
	disabledFeature,
	className,
	updatePricingModelData,
	updateActivationDates,
}: RevRecSettingsProps) => {
	const { t } = useTranslation('translation');
	const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
	const [customSelectedValue, setCustomSelectedValue] = useState<string>();

	useEffect(() => {
		// customSelectedValue shown in period select only for RevenueRecognitionPeriod.CUSTOM
		if (
			pricingModelTab?.revenueSettings?.revenueRecognitionPeriod === RevenueRecognitionPeriod.CUSTOM &&
			!isDatePickerOpen &&
			pricingModelTab?.revenueSettings?.recognitionPeriodStart &&
			pricingModelTab?.revenueSettings?.recognitionPeriodEnd
		) {
			setCustomSelectedValue(
				`${dayjs(pricingModelTab?.revenueSettings?.recognitionPeriodStart).format(DATE_FORMAT)} - ${dayjs(
					pricingModelTab?.revenueSettings?.recognitionPeriodEnd,
				).format(DATE_FORMAT)}`,
			);
		} else {
			setCustomSelectedValue(undefined);
		}
	}, [
		isDatePickerOpen,
		pricingModelTab?.index,
		pricingModelTab?.revenueSettings?.revenueRecognitionPeriod,
		pricingModelTab?.revenueSettings?.recognitionPeriodStart,
		pricingModelTab?.revenueSettings?.recognitionPeriodEnd,
	]);

	const onSelectPeriod = (value: RevenueRecognitionPeriod) => {
		if (value == RevenueRecognitionPeriod.CUSTOM) {
			setCustomSelectedValue(t('customDates'));
			return setIsDatePickerOpen(true);
		}

		setCustomSelectedValue(undefined);
		updateActivationDates?.({
			...pricingModelTab?.revenueSettings,
			revenueRecognitionPeriod: value,
			recognitionPeriodStart: null,
			recognitionPeriodEnd: null,
		});
	};

	const onCloseCustomWithoutDates = () => {
		if (!pricingModelTab?.revenueSettings?.recognitionPeriodStart || !pricingModelTab?.revenueSettings.recognitionPeriodEnd) {
			setCustomSelectedValue(undefined);
			updateActivationDates?.({
				...pricingModelTab?.revenueSettings,
				recognitionPeriodStart: null,
				recognitionPeriodEnd: null,
			});
		}
	};

	return (
		<div className={clsx(styles.settingsBarContainer, className)}>
			<div className={styles.header}>
				<span className={styles.settingsBarTitle}>{t('revenueRecognition')}</span>
				{disabledFeature && (
					<Button className={styles.needLicenseButton} color='calming' type='secondary' onClick={onClickUpgradeRevRecFeature}>
						<Icon imgType='spark' color='calming' width={2} />
						{t('upgrade')}
					</Button>
				)}
			</div>
			<div className={clsx(styles.settingsBarContent, disabledFeature && styles.disabledFeature)}>
				<div className={styles.leftContainer}>
					<Select
						title={t('revenueMethod')}
						data={recognitionMethodSelect}
						iconType={'information'}
						onChange={(revenueRecognitionMethod: EventsGenerationMethod) =>
							pricingModelTab &&
							updatePricingModelData?.({
								...pricingModelTab,
								revenueSettings: { ...pricingModelTab.revenueSettings, revenueRecognitionMethod, recognitionAmount: undefined },
							})
						}
						defaultValue={pricingModelTab?.revenueSettings?.revenueRecognitionMethod}
						width={24}
						disabled={disabledFeature}
						placeholder={t('selectMethod')}
					/>

					{pricingModelTab?.revenueSettings?.revenueRecognitionMethod !== EventsGenerationMethod.IMMEDIATE && (
						<>
							{pricingModelTab?.revenueSettings?.revenueRecognitionMethod === EventsGenerationMethod.FIXED_STRAIGHT_LINE && (
								<Input
									title={t('recognitionAmount')}
									value={pricingModelTab?.revenueSettings?.recognitionAmount}
									onChange={(val) => {
										pricingModelTab &&
											updatePricingModelData?.({
												...pricingModelTab,
												revenueSettings: { ...pricingModelTab.revenueSettings, recognitionAmount: +val || undefined },
											});
									}}
									type='number'
									className={styles.inputs}
									showError={!pricingModelTab?.revenueSettings?.recognitionAmount}
								/>
							)}

							<Select
								title={t('cycle')}
								headerStyle={styles.billingPeriodSelectTitle}
								data={billingCycleSelect(false, true)}
								iconType={'information'}
								onChange={(revenueCycleUnit: BillingCycleUnit) =>
									pricingModelTab &&
									updatePricingModelData?.({
										...pricingModelTab,
										revenueSettings: {
											...pricingModelTab.revenueSettings,
											revenueCycle: { unit: revenueCycleUnit, cycle: DEFAULT_BILLING_CYCLE_VALUE },
										},
									})
								}
								defaultValue={pricingModelTab?.revenueSettings?.revenueCycle?.unit}
								width={15}
								disabled={disabledFeature}
								placeholder={t('selectCycle')}
							/>

							<Select
								title={t('period')}
								onChange={(revenueRecognitionPeriod: RevenueRecognitionPeriod) => onSelectPeriod(revenueRecognitionPeriod)}
								defaultValue={customSelectedValue || pricingModelTab?.revenueSettings?.revenueRecognitionPeriod}
								iconType={'information'}
								data={recognitionPeriodSelect}
								className={styles.periodInput}
								width={pricingModelTab?.revenueSettings?.revenueRecognitionPeriod === RevenueRecognitionPeriod.CUSTOM ? 22 : 18}
								disabled={disabledFeature}
								placeholder={t('selectPeriod')}
							/>
						</>
					)}
				</div>

				{pricingModelTab?.revenueSettings?.revenueRecognitionMethod !== EventsGenerationMethod.IMMEDIATE && (
					<>
						<div className={styles.rightSideInputs}>
							<IssueDatePicker
								title={t('postingDay')}
								icon={'information'}
								customHeaderByBillingCycle={pricingModelTab?.revenueSettings?.revenueCycle}
								defaultIssueDate={pricingModelTab?.revenueSettings?.postingDay}
								updateIssueDate={(postingDay: CyclicDate) =>
									pricingModelTab &&
									updatePricingModelData?.({
										...pricingModelTab,
										revenueSettings: { ...pricingModelTab.revenueSettings, postingDay },
									})
								}
								className={styles.issueDatePicker}
								disabled={disabledFeature}
							/>
							<Tooltip
								disabled={
									!pricingModelTab?.pricingModelTable?.columns.find((i: PricingModelTableColumns) => i.type == CellTypes.USAGE_AGGREGATOR_REFERENCE)
								}
								tooltipComponent={
									<>
										<div className={styles.tooltipText}>{t('beforeAndDuringPeriods')}</div>
										<div className={styles.tooltipText}>{t('cannotBeSelected')}</div>
										<div className={styles.tooltipText}>
											{t('active')} <span className={styles.purpleDiamond}>{diamond}</span> {t('usageComponent')}
										</div>
									</>
								}
							>
								<Select
									className={styles.periodSelect}
									headerStyle={styles.billingPeriodSelectTitle}
									data={paymentTimeSelect}
									onChange={(recognitionTime: PaymentTimeOptions) =>
										pricingModelTab &&
										updatePricingModelData?.({
											...pricingModelTab,
											revenueSettings: { ...pricingModelTab.revenueSettings, recognitionTime },
										})
									}
									defaultValue={pricingModelTab?.revenueSettings?.recognitionTime}
									width={14}
									disabled={pricingModelTab && isRevRecPaymentPeriodDisabled(pricingModelTab)}
									placeholder={t('selectPeriod')}
								/>
							</Tooltip>
						</div>
					</>
				)}
			</div>

			<DatePicker
				hideInput
				rangePicker
				defaultFromDate={pricingModelTab?.revenueSettings?.recognitionPeriodStart}
				defaultToDate={pricingModelTab?.revenueSettings?.recognitionPeriodEnd}
				openDatePicker={isDatePickerOpen}
				updateIsOpen={(isOpen) => {
					setIsDatePickerOpen(isOpen);
					!isOpen && onCloseCustomWithoutDates();
				}}
				updateDate={(date) => {
					date.fromDate &&
						date.toDate &&
						updateActivationDates?.({
							...pricingModelTab?.revenueSettings,
							revenueRecognitionPeriod: RevenueRecognitionPeriod.CUSTOM,
							recognitionPeriodStart: date.fromDate,
							recognitionPeriodEnd: date.toDate,
						});
				}}
				footerType={FooterTypeOptions.ADD_YEARS}
			/>
		</div>
	);
};
