import { useMemo } from 'react';

export const SEPARATOR = '...';

const range = (start: number, end: number) => {
	const length = end - start + 1;
	return Array.from({ length }, (_, i) => i + start);
};

export const usePagination = ({
	totalPageCount,
	siblingCount = 1,
	currentPage,
}: {
	currentPage: number;
	totalPageCount: number;
	siblingCount?: number;
}) => {
	const paginationRange = useMemo<Array<number | string>>(() => {
		if (10 >= totalPageCount) {
			return range(1, totalPageCount);
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);

		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [...leftRange, SEPARATOR, totalPageCount];
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
			return [firstPageIndex, SEPARATOR, ...rightRange];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [firstPageIndex, SEPARATOR, ...middleRange, SEPARATOR, lastPageIndex];
		}
		return [];
	}, [totalPageCount, siblingCount, currentPage]);

	return paginationRange;
};
