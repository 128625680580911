import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../..';
import styles from './Footer.module.scss';

interface YearsFooterProps {
	onAddYear(numberOfYears: number): void;
	onConfirm(): void;
}

interface MonthsFooterProps {
	onAddMonth(numberOfMonths: number): void;
	onConfirm(): void;
}

interface ConfirmButtonsFooterProps {
	fromDate: Date | null;
	onConfirm(): void;
	onCancel(): void;
}

export const YearsFooter = ({ onAddYear, onConfirm }: YearsFooterProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<div className={styles.datePickerFooter}>
				{[1, 2, 3].map((num) => (
					<Button key={num} type='outlined' onClick={() => onAddYear(num)} className={styles.plusYearButton}>
						+{num} {num > 1 ? t('years') : t('year')}
					</Button>
				))}
			</div>

			<Button onClick={onConfirm}>{t('done')}</Button>
		</div>
	);
};

export const MonthsFooter = ({ onAddMonth, onConfirm }: MonthsFooterProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<div className={styles.datePickerFooter}>
				{[3, 6, 12].map((num) => (
					<Button key={num} type='outlined' onClick={() => onAddMonth(num)} className={styles.plusYearButton}>
						+{num} {num > 1 ? t('months') : t('month')}
					</Button>
				))}
			</div>

			<Button onClick={onConfirm}>{t('done')}</Button>
		</div>
	);
};

export const ConfirmButtonsFooter = ({ fromDate, onConfirm, onCancel }: ConfirmButtonsFooterProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.confirmButtonsFooter}>
			<Button type='outlined' color='neutral' onClick={onCancel} className={styles.confirmButtons}>
				{t('cancel')}
			</Button>
			<Button onClick={onConfirm} disabled={!fromDate} className={styles.confirmButtons}>
				{t('confirm')}
			</Button>
		</div>
	);
};
