import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { DATE_FORMAT, DateUtils, PricingType } from '@received/pricing-model';
import { plainToInstance } from 'class-transformer';
import { useLocation, useNavigate } from 'react-router-dom';
import { Payout } from '../../../../types/PayoutsTypes';
import { initialPayout } from '../../../../components/Sidebars/PayoutSideBar/PayoutSideBar.utils';
import { setSidebarIsOpen } from '../../../../storeSlices/generalSlice';
import { PathsConfig, httpService } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { getSupplierById, getSuppliers } from '../../../../utils/CustomerUtils';
import { Customer } from '../../../../types/customerTypes';
import {
	DisabledActions,
	SideBarData,
	UnSavedChanges,
	UnSavedModalActions,
	initialUnSavedChangesState,
	isTabEditable,
	setCollectiveTabsData,
} from '../../Invoices/SingleInvoicePage/SingleInvoicePage.utils';
import { PricingModel, PricingModelTab } from '../../../../types/contractTypes';
import { startGeneratePayoutPDFById } from '../../../../utils/PayoutUtils';
import { onUpdateUsageManualReportFromTable } from '../../../../utils/pricingModelUtils';
import {
	ActionModal,
	Button,
	CustomerDetails,
	DatePicker,
	DatePickerFormatType,
	GroupButton,
	Icon,
	DocumentDetail,
	MenuList,
	MenuOption,
	NetTermsSelect,
	PDFPreviewModal,
	Sidebars,
	Tooltip,
	PayoutStatusTag,
} from '../../../../components';
import { useDebounce } from '../../../../hooks';
import { images } from '../../../../constants/images';
import { contractIdFormat } from '../../../../utils/ContractUtils';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { payoutTabs } from './SinglePayoutPage.utils';
import { sendMultipleDocuments } from '../../../../utils/DocumentsUtils';
import styles from './SinglePayoutPage.module.scss';

export const SinglePayoutPage = () => {
	const [payout, setPayout] = useState<Payout>(initialPayout);
	const [openPayoutPreview, setOpenPayoutPreview] = useState(false);
	const [viewOnlyMode, setViewOnlyMode] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedProductTab, setSelectedProductTab] = useState(0);
	const [activeTab, setActiveTab] = useState(0);
	const [supplierEntities, setSupplierEntities] = useState<Customer[]>([]);
	const [newPayoutToNavigate, setNewPayoutToNavigate] = useState<string>();
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [openSideBarById, setOpenSideBarById] = useState<SideBarData>();
	const [closeAllSideBars, setCloseAllSideBars] = useState(false);

	const [disableActions, setDisabledActions] = useState<DisabledActions>({
		disabledSend: false,
		disabledSaveAndClose: false,
		tooltipLabel: '',
	});
	const [unSavedChanges, setUnSavedChanges] = useState<UnSavedChanges>(initialUnSavedChangesState);

	const { t } = useTranslation('translation');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location: any = useLocation();

	useEffect(() => {
		location.state?.payoutId ? getPayout(location.state.payoutId) : navigate(-1);
		dispatch(setSidebarIsOpen(false));
	}, [location.state?.payoutId]);

	useEffect(() => {
		setAllSuppliers();
	}, []);

	const selectedUsagePeriod = {
		fromDate: payout?.pricingModel?.tabs[selectedProductTab]?.billingEndDate || null,
		toDate: payout?.pricingModel?.tabs[selectedProductTab]?.billingEndDate || null,
	};

	const menuOptions: MenuOption[] = [
		{
			title: t('saveAndDownload'),
			onPress: () => saveAndDownload(),
			disabled: { isDisabled: disableActions.disabledSaveAndClose },
			iconType: 'download',
		},
	];

	const getPayout = async (id: string) => {
		try {
			const data: Payout = (
				await httpService({
					dispatch,
					path: PathsConfig.getPayoutById,
					urlParam: { id },
				})
			).data;

			setPayout(data);
			setViewOnlyMode(!!data?.editBlockers?.length);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_PAYOUT_DETAILS }));
		}
	};

	const setAllSuppliers = async () => {
		const suppliers = await getSuppliers(dispatch);
		setSupplierEntities(suppliers);
	};

	const savePayout = async () => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.updatePayout,
				data: plainToInstance(Payout, payout),
				urlParam: { id: payout?.id },
			});
			setUnSavedChanges({ ...unSavedChanges, documentChanged: false });
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.PAYOUT_UPDATE }));
		}
	};

	const setSupplier = async (newPricingModelTab: PricingModelTab, suppliers: Customer[]) => {
		let chosenSupplier;
		chosenSupplier = suppliers?.find((supplier) => supplier.id === newPricingModelTab.supplierId);
		if (!chosenSupplier) {
			chosenSupplier = await getSupplierById(dispatch, newPricingModelTab?.supplierId);
		}

		return chosenSupplier;
	};

	const saveClose = async () => {
		if (unSavedChanges.documentChanged) {
			await savePayout();
		}
		navigate(-1);
	};

	const saveAndSend = async () => {
		await savePayout();
		if (payout.id) {
			await sendMultipleDocuments(t, [payout.id], dispatch);
		}
		navigate(-1);
	};

	const onSend = async () => {
		if (unSavedChanges.documentChanged) {
			setUnSavedChanges({
				...unSavedChanges,
				isSaveChangesModalOpen: true,
				action: UnSavedModalActions.SAVE_AND_SEND,
				mainButtonAction: saveAndSend,
				secondaryButtonAction: () => setUnSavedChanges({ ...unSavedChanges, isSaveChangesModalOpen: false }),
			});
		} else {
			await saveAndSend();
		}
	};

	const saveAndDownload = async () => {
		if (!payout?.editBlockers?.length) {
			await savePayout();
		}
		payout.id && (await startGeneratePayoutPDFById(dispatch, payout.id));
	};

	const setPayoutProductByIndex = async (newPricingModelTab: PricingModelTab, tabIndex: number, suppliers: Customer[]) => {
		if (!viewOnlyMode) {
			setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
			let chosenSupplier: Customer | undefined = undefined;
			if (payout?.supplierId != newPricingModelTab?.supplierId) {
				chosenSupplier = await setSupplier(newPricingModelTab, suppliers);
			}
			// pricing model id should be reused from prev and not created in container
			setPayout((prev) => {
				let newPayout = { ...prev };
				newPayout.pricingModel.tabs[tabIndex] = newPricingModelTab;
				newPricingModelTab.subTotal = Math.max(newPricingModelTab.pricingModelTableTotal, newPricingModelTab.minimumFee || 0);
				newPricingModelTab.total = Math.max(newPricingModelTab.subTotal, newPricingModelTab.minimumFee || 0);
				const collectiveTabsData = setCollectiveTabsData(newPayout, newPricingModelTab) as Payout;

				const dueDate =
					collectiveTabsData?.customer?.country && prev.issueDate
						? DateUtils.getDueDate(
								prev.issueDate,
								isNaN(+newPricingModelTab.netTerms) ? newPricingModelTab.netTerms : +newPricingModelTab.netTerms,
								collectiveTabsData?.customer?.country,
						  )
						: payout.dueDate;

				newPayout = { ...collectiveTabsData, issueDate: prev.issueDate, dueDate, supplier: chosenSupplier };

				return newPayout;
			});
		}
	};

	const onUpdateManualReportFromTable = async () => {
		setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
		if (payout?.id) {
			const resInvoiceData: Payout = (
				await httpService({
					dispatch,
					path: PathsConfig.getPayoutById,
					urlParam: { id: payout?.id },
				})
			).data;

			const newPayout = onUpdateUsageManualReportFromTable(resInvoiceData, payout) as Payout;
			setPayout((prev) => ({ ...prev, ...newPayout }));
		}
	};

	const onUpdateIssueDate = async (date: DatePickerFormatType) => {
		setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
		const netTerms = payout.pricingModel.tabs[selectedProductTab].netTerms;

		const dueDate =
			payout?.customer?.country && date.fromDate
				? DateUtils.getDueDate(date.fromDate, isNaN(+netTerms) ? netTerms : +netTerms, payout?.customer?.country)
				: payout.dueDate;
		setPayout((prev) => ({ ...prev, issueDate: date.fromDate || undefined, dueDate }));
	};

	const handleNavigationToDifferentPayoutFromSideBar = (newPayoutId?: string) => {
		setCloseAllSideBars(!closeAllSideBars);
		setUnSavedChanges({ documentChanged: false, isSaveChangesModalOpen: false });
		navigate('../payouts/single-payout', {
			state: {
				PayoutId: newPayoutId || newPayoutToNavigate,
			},
		});
	};

	const onBackArrowClick = () => {
		if (unSavedChanges.documentChanged) {
			setUnSavedChanges({
				...unSavedChanges,
				isSaveChangesModalOpen: true,
				action: UnSavedModalActions.GO_BACK,
				mainButtonAction: saveClose,
				secondaryButtonAction: () => navigate(-1),
			});
		} else {
			setUnSavedChanges(initialUnSavedChangesState);
			navigate(-1);
		}
	};

	const setPayoutPricingModel = (newPricingModel: PricingModel) => {
		if (isTabEditable(activeTab, viewOnlyMode)) {
			setPayout((prev) => ({ ...prev, pricingModel: { ...prev.pricingModel, ...newPricingModel } }));
			return setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
		}
		setUnSavedChanges({ ...unSavedChanges, documentChanged: false });
	};

	const onUpdatePayoutPricingModel = useDebounce(setPayoutProductByIndex, 350);

	return (
		<div className={styles.globalPageContainer}>
			<div className={clsx(styles.content, viewOnlyMode && styles.notAllowedCursor)}>
				<div className={clsx(styles.payoutContainer, viewOnlyMode && styles.locked)}>
					<div className={styles.headerContainer}>
						<div className={styles.header}>
							<div className={styles.flex}>
								<Button
									type='outlined'
									color='neutral'
									onClick={() => onBackArrowClick()}
									className={clsx(styles.goBack, viewOnlyMode && styles.editable)}
								>
									<Icon imgType='arrow_back' color='neutral700' width={1.8} />
								</Button>

								<div className={styles.topContainer}>
									<CustomerDetails
										name={payout?.customer?.name}
										country={payout?.customer?.country}
										currency={payout?.customer?.currency}
										ein={payout?.customer?.ein}
										email={payout?.customer?.email}
										hideLogo
									/>
								</div>
							</div>

							<div className={clsx(styles.actions, styles.editable)}>
								{payout?.pricingModel && (
									<Button type='outlined' color='neutral' onClick={() => setOpenPayoutPreview(!openPayoutPreview)} className={styles.eyeButton}>
										<Icon imgType={openPayoutPreview ? 'closed_eye' : 'eye'} color='neutral' height={2} />
									</Button>
								)}

								<div className={styles.actions}>
									<Tooltip tooltipComponent={disableActions.disabledSaveAndClose && t(disableActions.tooltipLabel)} placement='left' blackTooltip>
										<Button
											onClick={saveClose}
											type='outlined'
											color='neutral'
											className={styles.actionButton}
											disabled={disableActions.disabledSaveAndClose}
										>
											<span className={styles.buttonText}>{t(unSavedChanges.documentChanged ? 'saveClose' : 'close')}</span>
										</Button>
									</Tooltip>

									<Button onClick={onSend} color='success' className={styles.publishButton} disabled={disableActions.disabledSend}>
										{t('send')}
									</Button>

									<MenuList optionsList={menuOptions} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
										<Button type='outlined' color='neutral' onClick={() => setOpenMenuOptions(true)} className={styles.hamburgerButton}>
											<Icon imgType='hamburger' height={2} color='neutral700' />
										</Button>
									</MenuList>
								</div>
							</div>
						</div>

						<div className={styles.metadata}>
							<DocumentDetail
								title={t('payoutNumber')}
								body={getDocumentIdNameByType(payout)}
								footer={<PayoutStatusTag document={payout} disabled />}
							/>

							<DocumentDetail
								title={t('issueDate')}
								body={`${dayjs(payout.issueDate).format(DATE_FORMAT)}`}
								footer={!!payout?.isCollective && t('collective')}
								displayArrowIcon
								onClick={() => setOpenDatePicker(true)}
								flipArrow={openDatePicker}
							/>

							<DatePicker
								hideInput
								openDatePicker={openDatePicker}
								defaultFromDate={payout.issueDate}
								updateDate={onUpdateIssueDate}
								updateIsOpen={() => setOpenDatePicker(false)}
							/>

							<DocumentDetail
								title={t('dueDate')}
								body={
									<NetTermsSelect
										isUsageProduct={payout?.pricingModel?.tabs[selectedProductTab]?.pricingType == PricingType.USAGE}
										width={''}
										defaultValue={payout?.netTerms}
										onChange={(value) => {
											setUnSavedChanges({ ...unSavedChanges, documentChanged: true });
											setPayoutProductByIndex(
												{
													...payout?.pricingModel?.tabs[selectedProductTab],
													netTerms: value,
												},
												selectedProductTab,
												supplierEntities,
											);
										}}
										className={styles.select}
									/>
								}
								footer={dayjs(payout?.dueDate).format(DATE_FORMAT)}
							/>

							<DocumentDetail
								title={t('payoutPeriod')}
								body={`${dayjs(payout?.pricingModel?.tabs[selectedProductTab]?.billingStartDate).format(DATE_FORMAT)} - ${dayjs(
									payout?.pricingModel?.tabs[selectedProductTab]?.billingEndDate,
								).format(DATE_FORMAT)}`}
								footer={contractIdFormat(payout?.poNumber, payout?.contractNumber)}
							/>
						</div>
					</div>

					<GroupButton
						buttonArray={payoutTabs().map((tab) => ({ children: tab.title }))}
						buttonClassName={styles.tabComponent}
						className={styles.mainTabsContainer}
						selectedIndex={activeTab}
						setSelectedIndex={setActiveTab}
					/>

					<div className={styles.bottomContainer}>
						{payoutTabs()[activeTab].component({
							payout,
							pricingModel: payout?.pricingModel,
							isEditMode: isTabEditable(activeTab, viewOnlyMode),
							selectedProductTab,
							className: styles.settingClassName,
							setSelectedProductTab,
							onUpdateCreditNotePricingModel: (data: PricingModelTab) => onUpdatePayoutPricingModel(data, selectedProductTab),
							onUpdateManualReportFromTable,
							updateSettings: setPayoutPricingModel,
						})}
					</div>
					{payout?.pricingModel.tabs && (
						<PDFPreviewModal
							isOpen={openPayoutPreview}
							documentsList={[payout]}
							onClose={() => setOpenPayoutPreview(false)}
							onDownload={() => saveAndDownload()}
						/>
					)}
				</div>
			</div>

			<Sidebars
				selectedCustomer={payout?.customer}
				selectedUsagePeriod={selectedUsagePeriod}
				disableDocumentSideBar
				openSideBarById={openSideBarById}
				onCloseSideBars={() => setOpenSideBarById(undefined)}
				closeAllSideBars={closeAllSideBars}
				navigateToDifferentSingleDocumentPage={{
					onClick: (id) => {
						setNewPayoutToNavigate(id);
						if (unSavedChanges.documentChanged) {
							setUnSavedChanges({
								...unSavedChanges,
								isSaveChangesModalOpen: true,
								action: UnSavedModalActions.NAVIGATE_TO_DIFFERENT_DOCUMENT,
								mainButtonAction: async () => {
									await savePayout();
									handleNavigationToDifferentPayoutFromSideBar(id);
								},
								secondaryButtonAction: () => handleNavigationToDifferentPayoutFromSideBar(id),
							});
						} else {
							handleNavigationToDifferentPayoutFromSideBar(id);
						}
					},
					originalDocumentId: payout.id,
				}}
				onUpdateManualReport={onUpdateManualReportFromTable}
			/>

			<ActionModal
				isOpen={unSavedChanges.isSaveChangesModalOpen}
				title={t('unsavedChanges')}
				description={
					<>
						<div>{t('youMadeSomeChanges')}</div>
						<div>{t(unSavedChanges.action == UnSavedModalActions.SAVE_AND_SEND ? 'wouldYouLikeToSaveAndSend' : 'wouldYouLikeToSaveOrDiscard')}</div>
					</>
				}
				mainButton={{
					title: t('save'),
					onClick: async () => {
						unSavedChanges?.mainButtonAction?.();
					},
				}}
				secondaryButton={{
					title: t(unSavedChanges.action == UnSavedModalActions.SAVE_AND_SEND ? 'cancel' : 'discardChanges'),
					type: 'outlined',
					color: 'destructive',
					className: styles.actionModalSecondaryButton,
					onClick: () => {
						unSavedChanges?.secondaryButtonAction?.();
					},
				}}
				imgSrc={images.saveChanges}
				onClose={() => {
					setNewPayoutToNavigate(undefined);
					setUnSavedChanges({ ...unSavedChanges, isSaveChangesModalOpen: false });
				}}
			/>
		</div>
	);
};
