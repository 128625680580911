import clsx from 'clsx';
import { FileUploader, Icon, CountriesSelect, ControlledInput, CSVRow, Select, Badge, ButtonStatusColor } from '../../..';
import { RowStatus, validateCustomerInput } from '../../../../Integrations';
import { IndexInCustomerCSVFile } from '../../../../Integrations/UploadCSVMatcher/UploadCSVMatcher.utils';
import { GeoLocationOptionsData, SelectItem } from '../../../../types/generalTypes';
import { countryIsUSA } from '../../../../utils/GeneralUtils';
import { Customer, CustomersDetails } from '../../../../types/customerTypes';
import styles from '../CSVDataMatcherTable.module.scss';

export interface customerRowItemProps {
	columnIndex: number;
	rowIndex: number;
	row: CSVRow;
	geoLocationData: GeoLocationOptionsData;
	suppliersList: Customer[];
	duplicateDataInTable?: CustomersDetails;
	existedDataFromServer?: CustomersDetails;
	onUpdateValue(val: string | number, rowIndex: number, columnIndex: number): void;
	onFocusOut(index: number): void;
}

export const customerRowItem = ({
	columnIndex,
	rowIndex,
	row,
	geoLocationData,
	suppliersList,
	existedDataFromServer,
	duplicateDataInTable,
	onUpdateValue,
	onFocusOut,
}: customerRowItemProps) => {
	switch (columnIndex) {
		case IndexInCustomerCSVFile.ICON_DATA: {
			// logo
			return (
				<div className={styles.fileContainer}>
					<FileUploader
						value={row.row[columnIndex]}
						fileTypes={['PNG', 'JPG', 'JPEG']}
						uploadBase64File={(file) => onUpdateValue(file, rowIndex, columnIndex)}
						tabIndex={0}
					>
						{row.row[columnIndex] ? (
							<img alt='logo' src={row.row[columnIndex]} className={styles.imgIcon} />
						) : (
							<div className={styles.noImgIcon}>
								<Icon imgType='upload' color='neutral' width={2} />
							</div>
						)}
					</FileUploader>
				</div>
			);
		}

		case IndexInCustomerCSVFile.NAME:
		case IndexInCustomerCSVFile.REMOTE_ID: {
			return (
				<ControlledInput
					placeholder='-'
					value={row.row[columnIndex] || ''}
					className={clsx(styles.rowItemInput, row.isDisabled ? styles.disabledInput : styles.selectedRow, !row.isValid && styles.notValidRow)}
					inputClassName={clsx(
						!row.isValid && styles.notValidRow,
						!validateCustomerInput(
							row.row,
							row.row[columnIndex],
							columnIndex,
							suppliersList,
							geoLocationData,
							existedDataFromServer,
							duplicateDataInTable,
						) && styles.notValidInput,
					)}
					onChange={(event) => onUpdateValue(event, rowIndex, columnIndex)}
					onBlur={() => {
						onFocusOut(rowIndex);
					}}
					onKeyDown={(event) => {
						event.key === 'Enter' && onFocusOut(rowIndex);
					}}
				/>
			);
		}
		// case IndexInCustomerCSVFile.LEGAL_NAME: {
		// 	// legal name
		// }
		case IndexInCustomerCSVFile.ENTITY: {
			// supplier
			return suppliersList.length ? (
				<Select
					defaultValue={row.row[columnIndex]?.name ? { label: row.row[columnIndex].name, value: row.row[columnIndex]?.id } : undefined}
					isSearchable
					data={suppliersList.map((entity) => ({ label: entity.name, value: entity.id })) as SelectItem[]}
					onChange={(val, selected) =>
						onUpdateValue(
							val ? { name: typeof selected === 'string' ? selected : selected?.label, id: val } : row.row[columnIndex],
							rowIndex,
							columnIndex,
						)
					}
					inputClassName={clsx(
						styles.rowItemInput,
						row.isDisabled ? styles.disabledInput : styles.selectedRow,
						!row.isValid && styles.notValidRow,
						!validateCustomerInput(
							row.row,
							row.row[columnIndex],
							columnIndex,
							suppliersList,
							geoLocationData,
							existedDataFromServer,
							duplicateDataInTable,
						) && styles.notValidInput,
					)}
					className={clsx(styles.selectContainer, row.isDisabled ? styles.disabledInput : styles.selectedRow, !row.isValid && styles.notValidRow)}
					style={{ width: '100%' }}
					width={''}
				/>
			) : (
				<ControlledInput
					placeholder='-'
					value={'N/A'}
					disabled
					className={clsx(styles.rowItemInput, row.isDisabled ? styles.disabledInput : styles.selectedRow)}
					onChange={(event) => onUpdateValue(event, rowIndex, columnIndex)}
				/>
			);
		}
		// case IndexInCustomerCSVFile.EIN: {
		// 	// ein
		// }
		// case IndexInCustomerCSVFile.EMAIL: {
		// 	// email
		// }
		case IndexInCustomerCSVFile.COUNTRY: {
			return (
				// country

				<CountriesSelect
					hideArrowIcon
					defaultValue={row.row[columnIndex]}
					onChange={(value: string) => onUpdateValue(value, rowIndex, columnIndex)}
					placeholder='-'
					data={geoLocationData.countries}
					width={''}
					className={clsx(styles.selectContainer, row.isDisabled ? styles.disabledInput : styles.selectedRow, !row.isValid && styles.notValidRow)}
					inputClassName={clsx(
						styles.rowItemInput,
						row.isDisabled ? styles.disabledInput : styles.selectedRow,
						!row.isValid && styles.notValidRow,
						!validateCustomerInput(
							row.row,
							row.row[columnIndex],
							columnIndex,
							suppliersList,
							geoLocationData,
							existedDataFromServer,
							duplicateDataInTable,
						) && styles.notValidInput,
					)}
				/>
			);
		}
		case IndexInCustomerCSVFile.COUNTRY_STATE: {
			// state
			return (
				<CountriesSelect
					hideArrowIcon
					width={''}
					defaultValue={row.row[columnIndex]}
					onChange={(value: string) => onUpdateValue(value, rowIndex, columnIndex)}
					placeholder='-'
					data={countryIsUSA(row.row[columnIndex - 1]) ? geoLocationData.usaStates : []}
					className={clsx(styles.selectContainer, row.isDisabled ? styles.disabledInput : styles.selectedRow, !row.isValid && styles.notValidRow)}
					inputClassName={clsx(
						styles.rowItemInput,
						row.isDisabled ? styles.disabledInput : styles.selectedRow,
						!row.isValid && styles.notValidRow,
						!validateCustomerInput(
							row.row,
							row.row[columnIndex],
							columnIndex,
							suppliersList,
							geoLocationData,
							existedDataFromServer,
							duplicateDataInTable,
						) && styles.notValidInput,
					)}
				/>
			);
		}

		case IndexInCustomerCSVFile.ROW_STATUS: {
			const value = row.row[columnIndex] as RowStatus;
			return (
				<Badge type='outlined' color={getBadgeColorByStatus(value)} className={styles.badge} dataTestId={`customer-table-badge-${columnIndex}`}>
					{value}
				</Badge>
			);
		}
		// case IndexInCustomerCSVFile.CITY: {
		// 	// city
		// }
		// case IndexInCustomerCSVFile.BILLING_ADDRESS: {
		// 	// billing address
		// }
		// case IndexInCustomerCSVFile.TAX: {
		// 	// tax
		// }
		default:
			return (
				<ControlledInput
					placeholder='-'
					value={row.row[columnIndex] || ''}
					className={clsx(styles.rowItemInput, row.isDisabled ? styles.disabledInput : styles.selectedRow, !row.isValid && styles.notValidRow)}
					inputClassName={clsx(
						!row.isValid && styles.notValidRow,
						!validateCustomerInput(
							row.row,
							row.row[columnIndex],
							columnIndex,
							suppliersList,
							geoLocationData,
							existedDataFromServer,
							duplicateDataInTable,
						) && styles.notValidInput,
					)}
					onChange={(event) => onUpdateValue(event, rowIndex, columnIndex)}
				/>
			);
	}
};

const getBadgeColorByStatus = (status: RowStatus): ButtonStatusColor => {
	switch (status) {
		case RowStatus.NEW:
			return 'primary';
		case RowStatus.ERROR:
			return 'destructive';
		case RowStatus.UPDATE:
			return 'success';
		case RowStatus.UNCHANGED:
			return 'neutral';
		default:
			return 'primary';
	}
};

export const isColumnShowOldValue = (columnIndex: number) => {
	return columnIndex > IndexInCustomerCSVFile.NAME && columnIndex !== IndexInCustomerCSVFile.ENTITY;
};
