import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { AccountingEventType } from '@received/pricing-model';
import { Button, CustomerSelect, DatePicker, DatePickerFormatType, Icon, Tooltip } from '../..';
import { UsageReportInput } from './UsageReportInput/UsageReportInput';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { UsageProduct, UsageReportView } from '../../../types/usageTypes';
import { DATE_FORMAT_FOR_SERVER } from '../../../constants/templateConstants';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { DocumentList } from './DocumentList/DocumentList';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { getDocumentIdNameByType, getUniqueListValues } from '../../../utils/GeneralUtils';
import { Customer } from '../../../types/customerTypes';
import { BillingDocumentType } from '../../../types/generalTypes';
import styles from './UsageReportSideBar.module.scss';

export interface UsageReportSideBarProps {
	isOpen: boolean;
	selectedCustomer?: Customer;
	selectedUsagePeriod?: DatePickerFormatType;
	selectedUsageReport?: UsageReportView;
	isSecondarySideBar?: boolean;
	disableDocumentSideBar?: boolean;
	sidebarToBeNoticed: boolean;
	setSidebarToBeNoticed: () => void;
	closeSideBar(): void;
	onUpdateManualReport?: () => void;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	closeSecondarySidebar: () => void;
}

export const UsageReportSideBar = ({
	isOpen,
	selectedCustomer,
	selectedUsagePeriod,
	selectedUsageReport,
	isSecondarySideBar,
	sidebarToBeNoticed,
	setSidebarToBeNoticed,
	onUpdateManualReport,
	closeSideBar,
	openOtherSidebar,
	closeSecondarySidebar,
}: UsageReportSideBarProps) => {
	const { t } = useTranslation('translation');
	const [chosenCustomer, setChosenCustomer] = useState<Customer>();
	const [usagePeriod, setUsagePeriod] = useState<DatePickerFormatType>();
	const [ButtonStatus, setButtonStatus] = useState<{ isLoading: boolean; isDisabled: boolean }>({ isLoading: false, isDisabled: true });
	const [documentList, setDocumentList] = useState<Array<BillingDocumentType>>([]);
	const [usageProductsList, setUsageProductsList] = useState<UsageProduct[]>([]);
	const [updatedUsageProductsCount, setUpdatedUsageProductsCount] = useState<{ [key: string]: number }>({});
	const [showSubActivity, setShowSubActivity] = useState(false);
	const [updatedSubActivity, setUpdatedSubActivity] = useState<{ [key: string]: string }>({});

	const dispatch = useDispatch();

	useEffect(() => {
		if (isOpen) {
			selectedUsageReport ? getSelectedCustomer() : setChosenCustomer(selectedCustomer);
		}
	}, [selectedCustomer, selectedUsageReport, isOpen]);

	useEffect(() => {
		selectedUsagePeriod && isOpen && setUsagePeriod(selectedUsagePeriod);
	}, [isOpen, selectedUsagePeriod]);

	useEffect(() => {
		isOpen && getUsageServicesList();
	}, [chosenCustomer, usagePeriod?.fromDate?.toString(), usagePeriod?.toDate?.toString(), isOpen]);

	useEffect(() => {
		setButtonStatus({ ...ButtonStatus, isDisabled: !Object.keys(updatedUsageProductsCount).length });
	}, [updatedUsageProductsCount]);

	const getUsageServicesList = async () => {
		try {
			const from = usagePeriod?.fromDate ? dayjs(usagePeriod?.fromDate).format(DATE_FORMAT_FOR_SERVER) : undefined;
			const to = usagePeriod?.toDate ? usagePeriod?.toDate : usagePeriod?.fromDate;

			const res = await httpService({
				// to show loader only in first load
				showLoader: !documentList.length,
				dispatch,
				path: PathsConfig.getUsageReportDocuments,
				params: {
					validInvoicesOnly: !selectedUsageReport,
					fromDate: dayjs(from).format(DATE_FORMAT_FOR_SERVER),
					toDate: to ? dayjs(to).format(DATE_FORMAT_FOR_SERVER) : undefined,
					customerId: chosenCustomer?.id,
				},
			});

			const documents = res.data.map((document: BillingDocumentType) => {
				const usageProductsData = document?.relatedUsageProducts?.map((usageP) => {
					const documentList = res?.data.filter((doc: BillingDocumentType) => {
						const products = doc?.relatedUsageProducts?.map((item) => item.id);
						return products?.includes(usageP.id);
					});
					return { ...usageP, documents: documentList };
				});
				return { ...document, relatedUsageProducts: usageProductsData };
			});

			if (res?.data && res?.data?.length) {
				setUpdatedUsageProductsCount(selectedUsageReport ? { [selectedUsageReport.usageProductId]: selectedUsageReport.quantity } : {});
				setUpdatedSubActivity(selectedUsageReport ? { [selectedUsageReport.usageProductId]: selectedUsageReport?.subActivity || '' } : {});
				setShowSubActivity(!!selectedUsageReport?.subActivity);

				setDocumentList(documents);
				setUsageProductsList(documents?.flatMap((doc: any) => doc?.relatedUsageProducts));
			} else {
				setUpdatedUsageProductsCount({});
				setUpdatedSubActivity({});
				setDocumentList([]);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSelectedCustomer = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getCustomerData,
				urlParam: { id: selectedUsageReport?.customerId || '' },
			});

			setChosenCustomer(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const sendManualUsage = async () => {
		try {
			setButtonStatus({ isLoading: true, isDisabled: true });
			const reportList = getUniqueListValues(usageProductsList, 'id');
			const reportsPayload = reportList
				?.map((item: any) => ({
					customerId: chosenCustomer?.id || '',
					date: dayjs(usagePeriod?.fromDate).format(DATE_FORMAT_FOR_SERVER),
					quantity: +updatedUsageProductsCount[item.id] || 0,
					subActivity: updatedSubActivity[item.id] || '',
					usageProductId: item.id,
				})) //filter is for remove empty counts from the array
				.filter((items) => Object.keys(updatedUsageProductsCount).includes(items.usageProductId));

			await httpService({
				dispatch,
				path: PathsConfig.sendManualUsageReport,
				data: reportsPayload,
			});

			await onUpdateManualReport?.();
			setButtonStatus({ isLoading: false, isDisabled: false });
			onClose();
		} catch (error) {
			setButtonStatus({ isLoading: false, isDisabled: false });
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onUpdateCount = (count: string, usageProductId: string) => {
		if (count != '') {
			setUpdatedUsageProductsCount((prev) => ({ ...prev, [usageProductId]: +count }));
		} else {
			const newCountData = { ...updatedUsageProductsCount };
			delete newCountData[usageProductId];
			setUpdatedUsageProductsCount(newCountData);
		}
	};

	const updateSubActivity = (val: string, usageProductId: string) => {
		if (val) {
			setUpdatedSubActivity((prev) => ({ ...prev, [usageProductId]: val }));
		} else {
			const newSubActivityData = { ...updatedSubActivity };
			delete newSubActivityData[usageProductId];
			setUpdatedSubActivity(newSubActivityData);
		}
	};

	const onClose = () => {
		setDocumentList([]);
		setUsageProductsList([]);
		setChosenCustomer(undefined);
		setUsagePeriod(undefined);
		setUpdatedUsageProductsCount({});
		setUpdatedSubActivity({});
		setButtonStatus({ isLoading: false, isDisabled: false });
		return isSecondarySideBar ? closeSecondarySidebar?.() : closeSideBar();
	};

	return (
		<>
			{isOpen && <div onClick={onClose} className={clsx(isSecondarySideBar ? styles.secondaryModalOverlay : styles.modalOverlay)} />}

			<div
				className={clsx(
					!isOpen ? styles.sidebarHidden : styles.sidebar,
					isSecondarySideBar && styles.secondarySidebar,
					sidebarToBeNoticed && styles.noticeMe,
				)}
				style={{ overflow: 'scroll' }}
				onAnimationEnd={setSidebarToBeNoticed}
			>
				<div data-testid={'usage-report-side-bar'} className={styles.sidebarContent}>
					<div className={styles.header}>
						<div>
							<div className={styles.title}>{t('usageReport')}</div>
						</div>
						<Button type='link' color='neutral' onClick={onClose}>
							<Icon imgType='x_icon' color='neutral700' className={styles.xIcon} />
						</Button>
					</div>

					<div className={styles.contentContainer} style={{ pointerEvents: selectedUsageReport ? 'none' : 'auto' }}>
						<div className={styles.section}>
							<div className={styles.sectionTitle}>
								<span className={styles.step}>{t('step')} 1</span>
								{t('chooseCustomer')}
							</div>
							<CustomerSelect
								defaultCustomer={chosenCustomer}
								small
								hideEmailTooltip
								onCustomerSelected={(customer) => setChosenCustomer(customer)}
								hideOptionButton={!!selectedCustomer}
							/>
						</div>

						<div className={styles.section}>
							<div className={styles.sectionTitle}>
								<span className={styles.step}>{t('step')} 2</span>
								{t('searchUsagePeriod')}
							</div>
							<div className={styles.usageSubTitle}>{t(selectedUsageReport ? 'usagePeriod' : 'UsageDate')}</div>

							<DatePicker
								rangePicker={!!selectedUsageReport}
								defaultFromDate={usagePeriod?.toDate}
								updateDate={(date: DatePickerFormatType) => setUsagePeriod(date)}
							/>

							<DocumentList
								isSideBarOpen={isOpen}
								documentList={documentList}
								onClickDocument={(selectedDocument) =>
									openOtherSidebar({
										sidebarType: selectedDocument?.accountingEventType == AccountingEventType.CREDIT ? SidebarType.CREDIT_NOTE : SidebarType.INVOICE,
										triggeredBy: SidebarType.USAGE_REPORT,
										id: selectedDocument.id,
									})
								}
							/>
						</div>

						{usageProductsList?.length ? (
							<div className={styles.sectionLast}>
								<div className={styles.sectionTitle}>
									<span className={styles.step}>{t('step')} 3</span>
									{t('fillUsageQuantity')} {documentList?.length == 1 && getDocumentIdNameByType(documentList[0])}
								</div>

								<div className={styles.usageSubTitleContainer}>
									<div className={styles.usageSubTitle}>{t('usageProducts')}</div>

									<Button
										type='link'
										className={styles.subActivityButton}
										onClick={() => {
											setShowSubActivity(!showSubActivity);
											setUpdatedSubActivity({});
										}}
									>
										{t(showSubActivity ? 'removeSubActivity' : 'addSubActivity')}
										{showSubActivity && <Icon color='primary' imgType='error_icon' />}
									</Button>
								</div>

								{getUniqueListValues(usageProductsList, 'id')
									.filter((item: any) => (selectedUsageReport ? item.id === selectedUsageReport.usageProductId : item))
									.map((usageP: any, index: number) => (
										<UsageReportInput
											index={index}
											key={usageP.id}
											subActivity={updatedSubActivity[usageP.id]}
											onUpdateSubActivity={(val) => updateSubActivity(val, usageP.id)}
											usageProduct={usageP}
											documents={documentList}
											showSubActivity={showSubActivity}
											disableInput={!!selectedUsageReport}
											defaultCount={updatedUsageProductsCount[usageP.id] ? updatedUsageProductsCount[usageP.id].toString() : ''}
											onUpdateCount={(count) => onUpdateCount(count, usageP.id)}
										/>
									))}
							</div>
						) : chosenCustomer && usagePeriod?.fromDate ? (
							<div className={styles.emptyState}>
								<div className={styles.emptyCircle}>
									<Icon imgType='page' height={3} />
								</div>
								<h2>{t('missingUsageProduct')}</h2>
								<div className={styles.text}>{t('SeemsYouDontHaveDocumentsTryChangeDate')}</div>
							</div>
						) : (
							<></>
						)}
					</div>
					{!selectedUsageReport && (
						<div className={styles.footer}>
							<Tooltip
								tooltipComponent={
									(!chosenCustomer || !usagePeriod || ButtonStatus.isDisabled) && (
										<>
											{!chosenCustomer && <div>• {t('missingCustomer')}</div>}
											{!usagePeriod?.fromDate && <div>• {t('missingUsagePeriod')}</div>}
											{ButtonStatus.isDisabled && <div>• {t('didntFillQuantity')}</div>}
										</>
									)
								}
								containerClassName={styles.footerButtons}
							>
								<Button
									onClick={sendManualUsage}
									className={styles.footerButtons}
									disabled={!chosenCustomer || !usagePeriod || ButtonStatus.isDisabled || ButtonStatus.isLoading}
								>
									{ButtonStatus.isLoading ? <Icon imgType='refresh' color='white' className={styles.refreshIconAnimation} /> : t('manualReport')}
								</Button>
							</Tooltip>
						</div>
					)}
				</div>
			</div>
		</>
	);
};
