import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { Store } from '../../types/storeTypes';
import { Button, Icon, Modal } from '../_uiComponents';
import { modalDataByType } from './SuccessErrorModal.utils';
import styles from './SuccessErrorModal.module.scss';

export const SuccessErrorModal = () => {
	const { t } = useTranslation('translation');
	const errorSuccessData = useSelector((store: Store) => store.errorSuccess);
	const dispatch = useDispatch();

	const onClose = () => dispatch(setCloseSuccessErrorModal());

	return (
		<Modal
			isOpen={errorSuccessData?.showSuccessErrorPopup}
			closeModal={onClose}
			className={styles.modalContainer}
			overlayClassName={styles.modalOverlay}
		>
			<div className={styles.container}>
				<Button onClick={onClose} type='link' color='neutral' className={styles.xIcon}>
					<Icon imgType='x_icon' />
				</Button>

				<div className={styles.flexColumnCenter}>
					<img alt='error-success-modal-img' src={modalDataByType(errorSuccessData).iconType} className={styles.icon}></img>

					<h1 className={styles.title}>{t(modalDataByType(errorSuccessData).title)}</h1>
					<span className={styles.text}>{t(modalDataByType(errorSuccessData)?.description)}</span>
				</div>

				<div className={styles.buttonContainer}>
					{modalDataByType(errorSuccessData).leftButtonTitle && (
						<Button
							onClick={() => {
								onClose();
								modalDataByType(errorSuccessData).onLeftButtonPress();
							}}
							type={modalDataByType(errorSuccessData).leftButtonType || 'outlined'}
							color={modalDataByType(errorSuccessData).leftButtonColor || 'neutral'}
							className={styles.button}
						>
							{t(modalDataByType(errorSuccessData)?.leftButtonTitle)}
						</Button>
					)}
					{modalDataByType(errorSuccessData).rightButtonTitle && (
						<Button
							onClick={() => {
								onClose();
								modalDataByType(errorSuccessData).onRightButtonPress();
							}}
							type='primary'
							color={modalDataByType(errorSuccessData).rightButtonColor}
							className={styles.button}
						>
							{t(modalDataByType(errorSuccessData)?.rightButtonTitle)}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	);
};
