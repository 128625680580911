import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Button, Icon, MenuList, MenuOption, Tooltip } from '../..';
import styles from './MenuItem.module.scss';

export interface MenuItemProps {
	optionItem: MenuOption;
	currentOpenIndex?: number;
	index: number;
	updateSelectedIndex(index: number): void;
	openMenu(isOpen: boolean): void;
}

export const MenuItem = ({ optionItem, index, currentOpenIndex, openMenu, updateSelectedIndex }: MenuItemProps) => {
	const [openSubMenu, setOpenSubMenu] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', () => {});
		if (index !== currentOpenIndex) {
			setOpenSubMenu(false);
		}
	}, [currentOpenIndex]);

	const handlePress = (e: any) => {
		e.stopPropagation();

		if (optionItem?.onPress) {
			setOpenSubMenu(false);
			optionItem.onPress();
			openMenu(false);
		} else if (optionItem.subList) {
			setOpenSubMenu(!openSubMenu);
			updateSelectedIndex(index);
		}
	};

	return (
		<Tooltip
			tooltipComponent={optionItem?.disabled?.reason}
			blackTooltip
			placement='left'
			disabled={!optionItem?.disabled?.isDisabled}
			className={clsx(styles.tooltipContainer, optionItem?.disabled?.tooltipStyle)}
		>
			<MenuList
				placement='right'
				customLeft={15}
				customTop={15}
				optionsList={optionItem.subList}
				isMenuOpen={openSubMenu}
				openMenu={() => {
					setOpenSubMenu(false);
					openMenu(false);
				}}
			>
				<Button
					type='link'
					disabled={optionItem?.disabled?.isDisabled}
					color={optionItem?.color || 'neutral'}
					className={clsx(styles.selectItem, optionItem?.hasBottomBorder && styles.bottomBorder)}
					onClick={handlePress}
					dataTestId={`menu-item-${optionItem.title}`}
				>
					<div className={styles.titleContainer}>
						{optionItem?.iconType && (
							<div className={styles.iconWrapper}>
								<Icon imgType={optionItem?.iconType} color={optionItem.color || 'neutral'} width={optionItem?.iconWidth} />
							</div>
						)}
						<div>{optionItem.title}</div>
					</div>
					<div className={styles.arrowIconWrapper}>{optionItem.subList && <Icon imgType='arrow_right' color='neutral300' height={1} />}</div>
				</Button>
			</MenuList>
		</Tooltip>
	);
};
