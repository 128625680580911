import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { Customer } from '../../types/customerTypes';
import { Button, CloseButton, Modal } from '../_uiComponents';
import { DEFAULT_COUNTRY, DEFAULT_PHONE_PREFIX } from '../../constants/generalConstants';
import { CustomerForm, customerValidation, initialState, tabIndexes } from './CreateCustomer.utils';
import { Store } from '../../types/storeTypes';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { Configurations, CustomerFormDetails, IntegrationIDsDetails } from './CustomerFields';
import styles from './CreateCustomer.module.scss';

export interface CreateCustomerProps {
	isOpen: boolean;
	customer?: Customer;
	isPartner?: boolean;
	closeModal(): void;
	refreshData?: () => void;
	onCustomerAdded?: (customerName: string, id: string) => void;
}

export const CreateCustomer = ({ isOpen, customer, isPartner, closeModal, refreshData, onCustomerAdded }: CreateCustomerProps) => {
	const { t } = useTranslation('translation');
	const [customerDetails, setCustomerDetails] = useState<CustomerForm>(initialState);
	const [isShippingAsBillingAddress, setIsShippingAsBillingAddress] = useState(true);

	const containerRef = useRef<HTMLDivElement>(null);
	const { countries } = useSelector((store: Store) => store.general.geoLocationData);
	const dispatch = useDispatch();

	useEffect(() => {
		isOpen &&
			setCustomerDetails((prev) => ({
				...prev,
				...customer,
				isPartner: customer?.isPartner ?? isPartner ?? initialState.isPartner,
			}));
	}, [customer, isOpen]);

	useEffect(() => {
		setCountryCode();
	}, [customerDetails.country]);

	const setCountryCode = () => {
		const prefix = countries?.find((i) => i.label === customerDetails.country)?.phonePrefix ?? DEFAULT_PHONE_PREFIX;
		updatePhone('countryCode', prefix);
	};

	const validateData = async () => {
		const { isValid, data } = customerValidation(customerDetails);

		if (isValid) {
			await postCustomer();
		} else {
			setCustomerDetails(data);
			containerRef?.current?.children[data?.errorSection || 0]?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	const postCustomer = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: customer ? PathsConfig.updateCustomer : PathsConfig.addNewCustomer,
				urlParam: {
					id: customerDetails?.id || '',
				},
				data: {
					...customerDetails,
					email: customerDetails?.email || null,
					phone: { phone: customerDetails?.phone?.phone || undefined, countryCode: customerDetails?.phone?.countryCode || undefined },
					countryState: customerDetails.country !== DEFAULT_COUNTRY ? null : customerDetails.countryState,
					shippingAddress: isShippingAsBillingAddress ? customerDetails.billingAddress : customerDetails.shippingAddress,
					remoteId: customerDetails?.remoteId ? customerDetails?.remoteId : null,
				},
			});

			refreshData?.();
			onCustomerAdded?.(customerDetails.name, res.data.id);
			onClose();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const updateForm = (field: string, data?: string) => {
		setCustomerDetails((prev) => ({ ...prev, [field]: data, [`${field}Error`]: false }));
	};

	const updatePhone = (field: string, data?: string) => {
		const phone = { ...customerDetails.phone, [field]: data ?? '', [`${field}Error`]: false };
		setCustomerDetails((prev) => ({ ...prev, phone }));
	};

	const onReset = () => {
		setCustomerDetails({
			...initialState,
			phone: undefined,
			isPartner: customer?.isPartner ?? isPartner ?? initialState.isPartner,
		});
	};

	const onClose = () => {
		onReset();
		closeModal();
	};

	return (
		<>
			<Modal isOpen={isOpen} closeModal={onClose} className={styles.modal}>
				<div className={styles.formContainer}>
					<div className={styles.header}>
						<span className={styles.mainTitle}>
							{isPartner ? (customer ? t('editPartner') : t('createPartner')) : customer ? t('editCustomer') : t('createCustomer')}
						</span>
						<CloseButton type='link' onClick={onClose} />
					</div>

					<div className={styles.body} ref={containerRef}>
						<CustomerFormDetails
							isShippingAsBillingAddress={isShippingAsBillingAddress}
							customerDetails={customerDetails}
							isPartner={isPartner}
							updateForm={updateForm}
							setIsShippingAsBillingAddress={setIsShippingAsBillingAddress}
							setCustomerDetails={setCustomerDetails}
						/>
						<IntegrationIDsDetails customerDetails={customerDetails} updateForm={updateForm} />
						<Configurations customerDetails={customerDetails} isOpen={isOpen} setCustomerDetails={setCustomerDetails} updateForm={updateForm} />
					</div>

					<div className={styles.footer}>
						<Button tabIndex={tabIndexes.RESET_BUTTON} type='link' color='neutral' onClick={onReset} className={styles.lightText}>
							{t('resetAll')}
						</Button>
						<Button tabIndex={tabIndexes.CONFIRM_BUTTON} onClick={validateData} className={styles.addButton}>
							{t('save')}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};
