import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IntegrationCategory, IntegrationDataSyncStatus, IntegrationPlatform, IntegrationProvider, IntegrationStatus } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { IntegrationSideBar } from './IntegrationSideBar/IntegrationSideBar';
import { exportItemsStepper, salesforceStepper } from './integrationSteppers';
import { Step } from '../../components/_uiComponents/VerticalStepper/Step/Step';
import { IntegrationItemsExport, SalesforceImport } from './IntegrationComponents';
import { AccountingItemsExport, convertTypeToIcon, onAccountingExport, onSaveSalesforceImport } from './IntegrationLayout.utils';
import { convertIntegrationTypeToStringService, getLabelByStatus } from '../../utils/IntegrationUtils';
import { Store } from '../../types/storeTypes';
import { IntegrationType } from '../../types/integrationTypes';
import styles from './IntegrationLayout.module.scss';

interface IntegrationLayoutProps {
	integrationType: IntegrationPlatform;
	category?: IntegrationCategory;
	supplierId: string;
	provider: IntegrationProvider;
	connectionStatus: IntegrationStatus;
	connection?: IntegrationType;
}

export const IntegrationLayout = ({ integrationType, supplierId, category, provider, connectionStatus, connection }: IntegrationLayoutProps) => {
	const { t } = useTranslation('translation');
	const [stepper, setStepper] = useState<Step[]>([]);
	const [activeIndex, setActiveIndex] = useState(0);
	const [stepsData, setStepsData] = useState<{ [index: number]: any }>({});
	const [accountingDeletedItems, setAccountingDeletedItems] = useState<string[]>([]);
	const [errors, setErrors] = useState<{ [index: number]: string[] }>({});
	const [status, setStatus] = useState('');

	const resyncAccountingIntegration = useSelector((store: Store) => store.integrations.resyncAccountingIntegration);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		getStatus();
		setStepper(integrationStepper());
	}, [category, resyncAccountingIntegration]);

	const getStatus = () => {
		//TO-DO need the create API that get status of entity
		const isConnected =
			resyncAccountingIntegration?.[AccountingItemsExport.Items] == IntegrationDataSyncStatus.SYNCED &&
			resyncAccountingIntegration?.[AccountingItemsExport.Accounts] == IntegrationDataSyncStatus.SYNCED &&
			resyncAccountingIntegration?.[AccountingItemsExport.Customers] == IntegrationDataSyncStatus.SYNCED;

		const isSyncing =
			resyncAccountingIntegration?.[AccountingItemsExport.Items] == IntegrationDataSyncStatus.SYNCING ||
			resyncAccountingIntegration?.[AccountingItemsExport.Accounts] == IntegrationDataSyncStatus.SYNCING ||
			resyncAccountingIntegration?.[AccountingItemsExport.Customers] == IntegrationDataSyncStatus.SYNCING;

		setStatus(getLabelByStatus(t, isConnected ? IntegrationStatus.LINKED : isSyncing ? IntegrationStatus.SYNCING : connectionStatus));
	};

	const sideBarHeader = () => {
		switch (category) {
			case IntegrationCategory.CRM:
				return {
					icon: convertTypeToIcon(integrationType),
					title: convertIntegrationTypeToStringService(integrationType),
					subTitle: t('importIntegration'),
				};
			case IntegrationCategory.ACCOUNTING:
				return {
					icon: convertTypeToIcon(integrationType),
					title: convertIntegrationTypeToStringService(integrationType),
					subTitle: t('exportIntegration'),
				};
		}
	};

	const integrationStepper = () => {
		switch (category) {
			case IntegrationCategory.CRM: {
				switch (integrationType) {
					case IntegrationPlatform.SALESFORCE:
						return salesforceStepper;
					default:
						return [];
				}
			}
			case IntegrationCategory.ACCOUNTING:
				return exportItemsStepper({ integrationType, t, syncingSteps: resyncAccountingIntegration });
			default:
				return [];
		}
	};

	const integrationComponent = (props: any) => {
		switch (category) {
			case IntegrationCategory.CRM:
				return <SalesforceImport {...props} />;
			case IntegrationCategory.ACCOUNTING:
				return <IntegrationItemsExport {...props} setAccountingDeletedItems={setAccountingDeletedItems} setErrors={setErrors} />;
			default:
				return [];
		}
	};

	const onSave = async () => {
		switch (category) {
			case IntegrationCategory.CRM:
				return await onSaveSalesforceImport(supplierId, stepsData, dispatch, navigate);
			case IntegrationCategory.ACCOUNTING:
				return await onAccountingExport(supplierId, stepsData, integrationType, dispatch, navigate, provider, accountingDeletedItems);
		}
	};

	return (
		<div className={styles.container}>
			<IntegrationSideBar
				header={sideBarHeader()}
				activeIndex={activeIndex}
				stepperList={stepper}
				onUpdateStep={setStepper}
				updateActiveIndex={setActiveIndex}
				onSave={onSave}
				errors={errors}
				connectionStatus={status}
				connection={connection}
			/>

			{integrationComponent({
				activeIndex,
				supplierId,
				stepsData,
				integrationType,
				provider,
				syncingSteps: resyncAccountingIntegration,
				setStepsData,
			})}
		</div>
	);
};
