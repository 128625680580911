import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { customerCsvDataHandler, CUSTOMER_CSV_TEMPLATE, CUSTOMER_CSV_TEMPLATE_NAME, importCustomerColumns } from '.';
import { CSVDataMatcherModal, UploadCSVModal } from '../../components';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { setShowCreateIndicator } from '../../storeSlices/generalSlice';
import { Store } from '../../types/storeTypes';
import { handelCustomerForServer, IndexInCustomerCSVFile } from './UploadCSVMatcher.utils';
import { getSuppliers } from '../../utils/CustomerUtils';
import { Customer, CustomersDetails } from '../../types/customerTypes';

interface UploadCSVMatcherProps {
	csvFile?: File;
	isUploadCSVModalOpen: boolean;
	onRefreshData?: () => void;
	onCloseUploadCsv(): void;
}

export const UploadCSVMatcher = ({ csvFile, isUploadCSVModalOpen, onCloseUploadCsv, onRefreshData }: UploadCSVMatcherProps) => {
	const [formattedCSVData, setFormattedCSVData] = useState<{
		header: string[];
		rows: any[];
		originalRows: any[];
		originalHeader: string[];
		existedCustomers: CustomersDetails;
		rowsWithOldValues: any[];
	}>();
	const [openMultipleImportModal, setOpenMultipleImportModal] = useState(false);
	const [suppliersList, setSuppliersList] = useState<Customer[]>([]);

	const geoLocationData = useSelector((store: Store) => store.general.geoLocationData);
	const dispatch = useDispatch();

	useEffect(() => {
		csvFile && onExportFile(csvFile);
	}, [csvFile]);

	useEffect(() => {
		getSuppliersData();
	}, [csvFile]);

	const getSuppliersData = async () => {
		const suppliers = await getSuppliers(dispatch);
		setSuppliersList(suppliers);
	};

	const onExportFile = (csv: File) => {
		dispatch(setShowCreateIndicator(true));
		const fileReader = new FileReader();
		fileReader.onload = async (event) => {
			const text = event?.target?.result as string;

			const data = await customerCsvDataHandler(dispatch, text, suppliersList, geoLocationData);
			setFormattedCSVData(data);
			setOpenMultipleImportModal(true);
		};

		fileReader.readAsText(csv);
		fileReader.onerror = () => dispatch(setShowCreateIndicator(false));
	};

	const onImportData = async (rows: any[], existedDataFromServer?: CustomersDetails) => {
		try {
			const importList = {
				importList: handelCustomerForServer(rows, existedDataFromServer || formattedCSVData?.existedCustomers),
				url: PathsConfig.createMultipleCustomers,
			};
			if (importList) {
				await httpService({
					dispatch,
					path: importList.url,
					data: importList.importList,
				});
				onRefreshData?.();
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.CREATE_CUSTOMER }));
		}
	};

	return (
		<>
			<UploadCSVModal
				texts={{
					title: 'importCustomers',
					subTitle: 'uploadCSVToImportCustomer',
					template: CUSTOMER_CSV_TEMPLATE,
					templateName: CUSTOMER_CSV_TEMPLATE_NAME,
				}}
				isOpen={isUploadCSVModalOpen}
				closeModal={onCloseUploadCsv}
				onExportFromCSV={onExportFile}
			/>
			{formattedCSVData && (
				<CSVDataMatcherModal
					texts={{
						title: 'importCustomers',
						subTitle: 'uploadCSVToImportCustomer',
						template: CUSTOMER_CSV_TEMPLATE,
						templateName: CUSTOMER_CSV_TEMPLATE_NAME,
					}}
					suppliersList={suppliersList}
					isOpen={openMultipleImportModal}
					importedData={formattedCSVData}
					tableColumns={{
						columns: importCustomerColumns,
						requiredColumns: [
							IndexInCustomerCSVFile.NAME,
							IndexInCustomerCSVFile.LEGAL_NAME,
							IndexInCustomerCSVFile.EMAIL,
							IndexInCustomerCSVFile.COUNTRY,
						],
						disabledColumns: [IndexInCustomerCSVFile.ROW_STATUS, IndexInCustomerCSVFile.ICON_DATA],
					}}
					closeModal={() => {
						setOpenMultipleImportModal(false);
						setFormattedCSVData(undefined);
					}}
					onImportData={onImportData}
				/>
			)}
		</>
	);
};
