import clsx from 'clsx';
import FlipMove from 'react-flip-move';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, CloseButton, Icon, Input, Switch } from '../../../../components';
import { Sequence, Step, StepType } from '../../../../types/dunningTypes';
import { PathsConfig, httpService } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { SequenceItem } from './components/SequenceItem/SequenceItem';
import { duplicateSequence } from '../Dunning.utils';
import styles from './SequenceSideBar.module.scss';

interface SequenceSideBarProps {
	id?: string;
	isFirstSequence: boolean;
	isOpen: boolean;
	onClose: () => void;
}

const emptySequence = { name: '', steps: [], isDefault: false, customers: 0 };

export const SequenceSideBar = ({ id, isOpen, isFirstSequence, onClose }: SequenceSideBarProps) => {
	const [sequence, setSequence] = useState<Sequence>(emptySequence);
	const [showError, setShowError] = useState(false);
	const [startAnimation, setStartAnimation] = useState(false);
	const [stepsWithError, setStepsWithError] = useState<Step[]>([]);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		if (isOpen && id) {
			getSequenceById();
		}
	}, [isOpen, id]);

	useEffect(() => {
		setSequence({ ...sequence, isDefault: isFirstSequence });
	}, [isOpen, isFirstSequence]);

	useEffect(() => {
		sortByTypeAndDays();
	}, [startAnimation]);

	const getSequenceById = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getSequenceById,
					urlParam: { id: id || '' },
				})
			).data;
			setSequence(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const validateInput = () => {
		const allStepWithZeroDays = sequence.steps.reduce((acc: Step[], step, i) => (step.days == undefined ? [...acc, step] : acc), []);
		setStepsWithError(allStepWithZeroDays);
		setShowError(!sequence.name.length);
		return sequence.name.length && allStepWithZeroDays.length == 0;
	};

	const addSequence = async () => {
		try {
			await httpService({
				dispatch,
				path: id ? PathsConfig.updateSequence : PathsConfig.addNewSequence,
				data: {
					...sequence,
					id,
				},
			});
			onCloseSideBar();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onDeleteSequenceStep = (stepIndex: number) => {
		let stepsList = [...sequence.steps];
		stepsList = stepsList.filter((step, index) => index != stepIndex);
		setSequence({ ...sequence, steps: stepsList });
	};

	const onCloseSideBar = () => {
		setSequence(emptySequence);
		setShowError(false);
		setStartAnimation(false);
		setStepsWithError([]);
		onClose();
	};

	const onEditStepByIndex = (step: Step, index: number) => {
		setStartAnimation(true);
		const steps = [...sequence.steps];
		steps[index] = step;
		const stepsWithErrors = [...stepsWithError];
		setStepsWithError(stepsWithErrors.filter((_step) => step !== _step));
		setSequence({ ...sequence, steps });
	};

	const sortByTypeAndDays = () => {
		const descendingDays = sequence.steps.filter((step) => step.timeIndicator == StepType.AFTER).sort((a, b) => (a?.days || 0) - (b?.days || 0));
		const ascendingDays = sequence.steps.filter((step) => step.timeIndicator == StepType.BEFORE).sort((a, b) => (b?.days || 0) - (a?.days || 0));
		setSequence({ ...sequence, steps: [...ascendingDays, ...descendingDays] });
		setStartAnimation(false);
	};

	return (
		<>
			{isOpen && <div onClick={onCloseSideBar} className={styles.modalOverlay} />}
			<div data-testid='sequence-side-bar' className={!isOpen ? styles.sidebarHidden : styles.sidebar}>
				<div className={styles.header}>
					<h2>{t('emailSequence')}</h2>
					<CloseButton onClick={onCloseSideBar} type='link' data-testid='sequence-sidebar-close-button' />
				</div>
				<div className={styles.body}>
					<Input
						autoFocus
						data-testid='sequence-name-input'
						title={t('sequenceName')}
						placeholder={t('typeSequenceName')}
						value={sequence?.name}
						onChange={(name: string) => {
							setShowError(false);
							setSequence({ ...sequence, name });
						}}
						containerClass={styles.nameInputContainer}
						showError={showError}
						customTitle={
							<div className={styles.defaultContainer}>
								<span className={styles.defaultLabel}>{t('applyOnNewContracts')}</span>
								<Switch
									smallSize
									onChange={(val) => setSequence({ ...sequence, isDefault: val })}
									defaultChecked={sequence.isDefault}
									className={styles.defaultBadge}
									data-testid='sequence-sidebar-default-toggle'
								/>
							</div>
						}
					/>

					{!!sequence.steps.length && (
						<FlipMove
							enterAnimation='none'
							leaveAnimation='fade'
							appearAnimation='fade'
							duration={250}
							className={styles.itemList}
							staggerDurationBy={20}
						>
							{sequence.steps.map((step, index) => (
								<div key={`${step.days}_${step.id || step?.name}`} className={styles.itemContainer}>
									<SequenceItem
										index={index + 1}
										data={step}
										showError={!!stepsWithError?.includes(step)}
										onDelete={() => onDeleteSequenceStep(index)}
										onEdit={(step: Step) => onEditStepByIndex(step, index)}
									/>

									<Icon imgType='long_arrow' className={styles.arrow} color='primary' width={1.7} />
								</div>
							))}
						</FlipMove>
					)}

					<Button
						data-testid='sequence-sidebar-add-button'
						color='neutral'
						type='outlined'
						onClick={() =>
							setSequence({ ...sequence, steps: [...sequence.steps, { days: undefined, timeIndicator: StepType.AFTER, name: uuidv4() }] })
						}
						className={styles.addButton}
					>
						<Icon imgType='add' color='neutral600' width={1.2} />
						<span className={styles.addButtonLabel}>{t('newSequenceItem')}</span>
					</Button>
				</div>
				<div className={styles.footer}>
					<Button
						type='outlined'
						color='neutral'
						className={clsx(styles.duplicate, !id && styles.hidden)}
						data-testid='sequence-sidebar-duplicate-button'
						onClick={() => duplicateSequence(dispatch, sequence, () => onCloseSideBar())}
					>
						<Icon imgType='duplicate' color='primary' width={1.8} />
						{t('Duplicate')}
					</Button>

					<div className={styles.actions}>
						<Button type='outlined' color='neutral' className={styles.cancel} onClick={onClose} data-testid='sequence-sidebar-cancel-button'>
							{t('cancel')}
						</Button>
						<Button className={styles.save} data-testid='sequence-sidebar-save-button' onClick={() => validateInput() && addSequence()}>
							{t('SaveChanges')}
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};
