import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { BillingCycleStruct, BillingCycleUnit, PricingCycleStruct } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Select } from '../..';
import { CustomCycleType, billingCycleIntervals } from './CustomBillingCycle.utils';
import { DEFAULT_BILLING_CYCLE_VALUE } from '../../../constants/generalConstants';
import styles from './CustomBillingCycle.module.scss';

interface CustomBillingCycleProps {
	billingCycle?: BillingCycleStruct | PricingCycleStruct | null;
	isOpen: boolean;
	customCycleType?: CustomCycleType;
	onUpdateCustomBillingCycle?: (billingCycle: BillingCycleStruct) => void;
	onClose: () => void;
}

export const CustomBillingCycle = ({
	billingCycle,
	isOpen,
	customCycleType = CustomCycleType.BILLING,
	onClose,
	onUpdateCustomBillingCycle,
}: CustomBillingCycleProps) => {
	const [cycle, setCycle] = useState<number>(DEFAULT_BILLING_CYCLE_VALUE);
	const [unit, setUnit] = useState<BillingCycleUnit>(BillingCycleUnit.MONTHLY);

	const { t } = useTranslation('translation');

	useEffect(() => {
		if (isOpen) {
			setCycle(billingCycle?.cycle || DEFAULT_BILLING_CYCLE_VALUE);
			setUnit((billingCycleIntervals.find((interval) => interval?.value === billingCycle?.unit) || billingCycleIntervals[0]).value);
		}
	}, [isOpen]);

	const onDone = () => {
		onUpdateCustomBillingCycle?.({ cycle, unit });
		close();
	};

	const close = () => {
		setCycle(DEFAULT_BILLING_CYCLE_VALUE);
		setUnit(BillingCycleUnit.MONTHLY);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} closeModal={close} className={styles.container} overlayClassName={styles.modal}>
			<div className={styles.header}>
				<span className={styles.title}>{t(customCycleType === CustomCycleType.BILLING ? 'customBillingCycle' : 'customPricingCycle')}</span>
				<span className={styles.mainTitle}>{t(customCycleType === CustomCycleType.BILLING ? 'billingInterval' : 'pricingInterval')}</span>
			</div>

			<div className={styles.body}>
				<span className={styles.desc}>{t(customCycleType === CustomCycleType.BILLING ? 'documentWillBeReadyEvery' : 'pricePer')}</span>
				<div className={styles.inputsWrapper}>
					<Input onChange={(val, e: any) => setCycle(e.target.valueAsNumber)} type='number' className={styles.input} value={cycle} />
					<Select
						data={billingCycleIntervals}
						defaultValue={unit}
						onChange={(billingCycleUnit: BillingCycleUnit) => setUnit(billingCycleUnit)}
						className={styles.select}
						width={10}
					/>
				</div>
			</div>

			<Button className={styles.button} onClick={onDone}>
				{t('done')}
			</Button>
		</Modal>
	);
};
