import React from 'react';
import clsx from 'clsx';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import styles from './DnDDropDownItem.module.scss';

export interface DnDDropDownItemProps {
	name: string;
	price: string | number;
	isSelected: boolean;
	onDrag?: (event: any) => void;
	onAddToRow: () => void;
}

export const DnDDropDownItem = ({ name, price, isSelected, onAddToRow, onDrag }: DnDDropDownItemProps) => {
	return (
		<div className={styles.container} draggable onDrag={onDrag} onDragStart={onDrag}>
			<div className={styles.flexCenter}>
				{isSelected && <div className={styles.dot}></div>}
				<div className={clsx(styles.fontSize14, styles.name)}>{name}</div>
			</div>
			<div className={styles.flexCenter}>
				<div className={styles.fontSize14neutral}>{price}</div>
				<Button type='link' className={styles.addButton} onClick={onAddToRow}>
					<Icon imgType='round_add' color='neutral' height={2.5} />
				</Button>
			</div>
		</div>
	);
};
