import { AccountingEventType, DocumentState } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CreditNote, CreditNoteView } from '../../../types/creditNoteTypes';
import { creditNoteStatus } from '../../../utils/CreditNoteUtils';
import { PaidPeriod } from '../../InvoiceTable/InvoiceTable.utils';
import { Button, DatePicker, Icon, MenuList } from '../../_uiComponents';
import { FooterTypeOptions } from '../../_uiComponents/DatePicker/Footer/Footer';
import { changeCreditNoteStatus } from './CreditNoteStatusTag.utils';
import { submitDocuments } from '../../../utils/DocumentsUtils';
import { StatusTagProps } from '../StatusTags.utils';
import styles from './CreditNoteStatusTag.module.scss';

interface CreditNoteStatusTagProps extends StatusTagProps {
	document: CreditNote | CreditNoteView;
}

export const CreditNoteStatusTag = ({ document, isShort, className, disabled, refreshData }: CreditNoteStatusTagProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const creditStateData = creditNoteStatus(document?.state, isShort);

	const onMarkAsPaidFromTable = async (period: PaidPeriod) => {
		switch (period) {
			case PaidPeriod.TODAY:
				document?.id && (await submitDocuments(new Date(), [{ id: document.id, type: AccountingEventType.CREDIT }], dispatch));
				refreshData?.();
				break;
			case PaidPeriod.CUSTOM:
				setOpenDatePicker(true);
				break;
		}
	};

	const onUpdateStatusFromDatePicker = async (date: Date) => {
		if (document?.id) {
			setOpenDatePicker(false);
			await submitDocuments(date, [{ id: document.id, type: AccountingEventType.CREDIT }], dispatch);
			refreshData?.();
		}
	};

	const creditNoteList = document && !disabled ? changeCreditNoteStatus(t, document, onMarkAsPaidFromTable, dispatch, refreshData) : [];

	return (
		<>
			{document?.state !== DocumentState.CONTRACT_DRAFT && (
				<MenuList optionsList={creditNoteList} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
					<Button
						dataTestId='credit-status-button'
						onClick={(e) => {
							e.stopPropagation();
							setOpenMenuOptions(true);
						}}
						type={creditStateData.type}
						color={creditStateData.color}
						className={clsx(styles.button, isShort && styles.isShortContainer, className)}
						style={{ pointerEvents: document && creditNoteList.length ? 'auto' : 'none' }}
					>
						{t(creditStateData.text)}
						<div className={styles.iconContainer}>
							{document && creditNoteList.length != 0 && <Icon imgType='arrow_down' color={creditStateData.color} width={1} />}
						</div>
					</Button>
				</MenuList>
			)}

			<DatePicker
				hideInput
				openDatePicker={openDatePicker}
				footerType={FooterTypeOptions.SHOW_CONFIRM_BUTTONS}
				datePickerLabel={t('choosePaymentDate')}
				onConfirm={onUpdateStatusFromDatePicker}
				updateIsOpen={setOpenDatePicker}
				defaultFromDate={document ? dayjs(document.issueDate).toDate() : dayjs().toDate()}
			/>
		</>
	);
};
