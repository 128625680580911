import { Dispatch } from '@reduxjs/toolkit';
import { DocumentState, PaymentOptions } from '@received/pricing-model';
import { ButtonStatusColor, ButtonTypes, IconColor, MenuOption } from '../..';
import { Invoice, InvoiceView } from '../../../types/invoiceTypes';
import { cancelInvoices, ChangeStatusOption, chargeMultipleInvoices, PaidPeriod, sendInvoice } from '../../InvoiceTable/InvoiceTable.utils';
import { DocumentView } from '../../../types/generalTypes';
import styles from './InvoiceStatusTag.module.scss';

export const invoiceStatus = (
	status?: DocumentState | 'SYNCED',
	isShort?: boolean,
): { color?: ButtonStatusColor; type?: ButtonTypes; text: string; iconColor?: IconColor } => {
	switch (status) {
		case 'SYNCED':
			return { color: 'primary', type: 'secondary', text: 'synced' };
		case DocumentState.DRAFT:
			return { color: 'neutral', type: 'secondary', text: 'draft' };
		case DocumentState.FINALIZED:
		case DocumentState.REVIEW_READY_TO_SEND:
			return { color: 'primary', type: 'outlined', text: isShort ? 'ready' : 'readyToSend' };
		case DocumentState.REVIEW_MISSING_DATA:
			return { color: 'warning', type: 'secondary', text: 'missingInfo' };
		case DocumentState.SENT:
			return { color: 'primary', type: 'secondary', text: 'sent' };
		case DocumentState.SENT_FAILED:
			return { color: 'destructive', type: 'primary', text: 'sentFailed', iconColor: 'white' };
		case DocumentState.PAYMENT_FAILED:
		case DocumentState.PAYMENT_FAILED_COLLECTING_INFO:
			return { color: 'destructive', type: 'primary', text: 'paymentFailed', iconColor: 'white' };
		case DocumentState.LATE:
			return { color: 'destructive', type: 'secondary', text: 'late' };
		case DocumentState.PAID:
			return { color: 'success', type: 'secondary', text: 'paid' };
		case DocumentState.CANCELED:
			return { color: 'neutral', type: 'outlined', text: 'canceled' };
		case DocumentState.CONTRACT_DRAFT:
			return { color: 'neutral', type: 'link', text: '' };
		case DocumentState.COLLECTING_INFO:
			return { color: 'calming', type: 'secondary', text: 'collectingData' };

		default:
			return { color: undefined, type: undefined, text: '' };
	}
};

export const changeInvoiceStatus = (
	t: any,
	item: InvoiceView | Invoice,
	dispatch: Dispatch,
	onMarkAsPaid: (period: PaidPeriod) => void,
	onPublish: (setCurrentAction: ChangeStatusOption) => void,
	getInvoiceData?: () => void,
): MenuOption[] => {
	let listOptions: MenuOption[] = [];

	if (item.state === DocumentState.CANCELED) {
		return listOptions;
	}

	if (item.state === DocumentState.REVIEW_READY_TO_SEND || item.state === DocumentState.DRAFT || item.state === DocumentState.FINALIZED) {
		const sendOption: MenuOption = {
			title: t('sendDocument'),
			iconType: 'paper_airplane',
			color: 'primary',
			subList: [
				{
					title: t('sendNow'),
					onPress: () => item.id && sendInvoice(t, item.id, dispatch, getInvoiceData),
					iconType: 'paper_airplane',
				},
				{
					title: t('markAsSent'),
					onPress: () => onPublish(ChangeStatusOption.PUBLISH_FROM_ROW),
					iconType: 'letter',
				},
			],
		};
		listOptions = [...listOptions, sendOption];
	} else if (
		item.state === DocumentState.SENT ||
		item.state === DocumentState.SENT_FAILED ||
		item.state === DocumentState.LATE ||
		item.state === DocumentState.PAID ||
		item.state === DocumentState.PAYMENT_FAILED
	) {
		listOptions = [
			...listOptions,
			{
				title: t('resendDocument'),
				iconType: 'refresh',
				color: 'neutral',
				onPress: () => item.id && sendInvoice(t, item.id, dispatch),
			},
		];
	}

	if (
		item.state === DocumentState.DRAFT ||
		item.state === DocumentState.REVIEW_READY_TO_SEND ||
		item.state === DocumentState.COLLECTING_INFO ||
		item.state === DocumentState.PAYMENT_FAILED_COLLECTING_INFO ||
		item.state === DocumentState.FINALIZED ||
		item.state === DocumentState.SENT ||
		item.state === DocumentState.SENT_FAILED ||
		item.state === DocumentState.LATE ||
		item.state === DocumentState.PAYMENT_FAILED
	) {
		const markAsPaidOption: MenuOption = {
			title: t('markAsPaid'),
			iconType: 'v_circle',
			color: 'success',
			subList: [
				{
					title: t('pickADate'),
					onPress: () => onMarkAsPaid(PaidPeriod.CUSTOM),
					iconType: 'date',
				},
				{
					title: t('paidToday'),
					onPress: () => onMarkAsPaid(PaidPeriod.TODAY),
					iconType: 'clock',
				},
			],
		};
		listOptions = [...listOptions, markAsPaidOption];
	}

	if (
		item.state === DocumentState.REVIEW_READY_TO_SEND ||
		item.state === DocumentState.DRAFT ||
		item.state === DocumentState.LATE ||
		item.state === DocumentState.SENT_FAILED ||
		item.state === DocumentState.SENT
	) {
		listOptions = [
			...listOptions,
			{
				title: t('charge'),
				iconType: 'solo_payment_link',
				color: 'neutral',
				disabled: isInvoiceCanBeCharged(t, item),
				onPress: () => item.id && chargeMultipleInvoices(t, [item.id], dispatch),
			},
		];
	} else if (item.state === DocumentState.PAYMENT_FAILED || item.state === DocumentState.PAYMENT_FAILED_COLLECTING_INFO) {
		listOptions = [
			...listOptions,
			{
				title: t('recharge'),
				iconType: 'refresh',
				color: 'neutral',
				onPress: () => item.id && chargeMultipleInvoices(t, [item.id], dispatch),
			},
		];
	}

	listOptions = [
		...listOptions,
		{
			title: t('cancelDocument'),
			onPress: () => item.id && cancelInvoices(item?.id, dispatch, getInvoiceData),
			iconType: 'error_icon',
			color: 'destructive',
		},
	];

	return listOptions;
};

export const getSyncStatusError = (invoice?: Invoice | InvoiceView) => {
	if (invoice?.syncStatus?.length) {
		const errorMetadata = invoice.syncStatus.find((status) => status?.metadata?.paymentError)?.metadata;
		if (errorMetadata) {
			return errorMetadata?.paymentError;
		}
	}
	return undefined;
};

const isInvoiceCanBeCharged = (t: any, invoice: Invoice | InvoiceView | DocumentView): { isDisabled?: boolean; reason?: React.ReactNode } => {
	const autoChargeWithoutCard =
		invoice?.billingDetails?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE) && !invoice?.billingDetails?.paymentGatewayCustomerId;
	const noAutoCharge = !invoice?.billingDetails?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE);

	if (noAutoCharge) {
		return {
			isDisabled: true,
			reason: (
				<div className={styles.reason}>
					<div>{t('cantBeChargedWhen')}</div>
					<div>{t('autoChargeIsOff')}</div>
				</div>
			),
		};
	}

	if (autoChargeWithoutCard) {
		return {
			isDisabled: true,
			reason: (
				<div className={styles.reason}>
					<div>{t('cantBeChargedWhen')}</div>
					<div>{t('noCardSelected')}</div>
				</div>
			),
		};
	}

	return {
		isDisabled: false,
	};
};
