import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { CellProps } from '../../TableRow.utils';
import { getUsageCellAggregationLabel, PricingModelTableViewTypeOptions } from '../../../PricingModelTable.utils';
import { tableInputNumberWithCommas } from '../../../../../utils/NumberUtils';
import { Button, Icon, MenuList, MenuOption } from '../../../..';
import { diamond } from '../../../../../constants/unicodes';
import styles from './UsageCell.module.scss';

export const UsageCell = ({
	rowData,
	column,
	selectedCell,
	classNames,
	pricingModelTableViewType,
	rowIndex,
	columnIndex,
	addManualUsageReport,
	openUsageSideBar,
	onArrowNavigation,
	onCellSelected,
}: CellProps) => {
	const { t } = useTranslation('translation');
	const [isEditMode, setIsEditMode] = useState(false);
	const [value, setValue] = useState<string | number>('');
	const [openActionMenu, setOpenActionMenu] = useState(false);

	useEffect(() => {
		setIsEditMode(selectedCell?.cell?.cellId === rowData.cells[column.id]?.cellId);
	}, [selectedCell, rowData]);

	useEffect(() => {
		setValue(rowData.cells[column.id].cellValue);
	}, [rowData]);

	const usageActionOptions = () => {
		let list: MenuOption[] = [];

		if (pricingModelTableViewType === PricingModelTableViewTypeOptions.INVOICE_PREVIEW) {
			list = [
				...list,
				{
					title: t('addManualUsageReport'),
					onPress: () => addManualUsageReport(),
					iconType: 'edit',
				},
			];
		}
		if (pricingModelTableViewType === PricingModelTableViewTypeOptions.PRICING_MODEL) {
			list.push({
				title: t('connectUsageProduct'),
				onPress: () => openUsageSideBar(column, columnIndex, rowIndex),
				iconType: 'usageIcon',
				color: 'calming',
			});
		}
		return list;
	};

	return (
		<div
			id='usageCell'
			onContextMenu={(event) => {
				event.preventDefault();
				event.type === 'contextmenu' && setOpenActionMenu(true);
			}}
			className={styles.usageContainer}
		>
			<div className={clsx(styles.dataContainer, isEditMode && styles.editMode)}>
				{pricingModelTableViewType == PricingModelTableViewTypeOptions.INVOICE_PREVIEW && (
					<div className={clsx(styles.numberText, classNames?.cell)}>
						{typeof Number(value) == 'number' ? tableInputNumberWithCommas(Number(value)) : value}
					</div>
				)}
				<div className={styles.usageType}>
					<div className={styles.usageTypeLabel}>
						<div className={styles.purpleDiamond}>
							{diamond} {'\xa0'}
						</div>
						<input
							style={{ background: 'none', pointerEvents: 'none' }}
							className={clsx(styles.cell, classNames?.cell)}
							value={rowData.cells[column.id]?.refObject?.name || t('Usage')}
							readOnly
						/>
					</div>

					{pricingModelTableViewType != PricingModelTableViewTypeOptions.INVOICE_PREVIEW && (
						<div className={styles.aggregationInputContainer}>
							<Icon imgType='green_circle' width={1} color='success' className={styles.greenIcon} />

							<input
								className={styles.aggregationLabel}
								disabled
								readOnly
								value={getUsageCellAggregationLabel(
									rowData.cells[column.id]?.refObject?.aggregationFunction,
									rowData.cells[column.id]?.refObject?.aggregationScope,
								)}
							/>
						</div>
					)}
				</div>
			</div>

			{pricingModelTableViewType != PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE && !!usageActionOptions()?.length && (
				<MenuList optionsList={usageActionOptions()} isMenuOpen={openActionMenu} openMenu={(isOpen) => setOpenActionMenu(isOpen)}>
					<Button type='link' color={isEditMode ? 'calming' : 'neutral'} onClick={() => setOpenActionMenu(true)} className={styles.hamburgerButton}>
						<Icon imgType='hamburger' color={isEditMode ? 'calming' : 'neutral'} className={styles.hamburger} />
					</Button>
				</MenuList>
			)}
		</div>
	);
};
