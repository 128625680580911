import { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { convertCurrencyToSign } from '@received/pricing-model';
import { useDispatch, useSelector } from 'react-redux';
import { MenuList, Button, Icon } from '../../../../_uiComponents';
import { getValueWithCurrencySign } from '../../../../../utils/NumberUtils';
import { InvoiceStatusTag } from '../../../..';
import { InvoiceView } from '../../../../../types/invoiceTypes';
import { getInvoiceNumberInSeries } from '../../../../../pages/_Billing/Invoices/Invoices.utils';
import { getDocumentIdNameByType } from '../../../../../utils/GeneralUtils';
import { Store } from '../../../../../types/storeTypes';
import { invoiceMenuList } from '../../../../../utils/InvoiceUtils';
import styles from './InvoiceListItem.module.scss';

interface InvoiceListItemProps {
	invoice: InvoiceView;
	isInvoiceActive: boolean;
	index: number;
	onRowPress?: (invoiceId: string) => void;
	onRefreshData?: () => void;
}

export const InvoiceListItem = ({ isInvoiceActive, invoice, index, onRefreshData, onRowPress }: InvoiceListItemProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);

	return (
		<div
			className={clsx(styles.invoiceItem, isInvoiceActive && styles.activeInvoice)}
			onClick={() => onRowPress?.(invoice.id)}
			data-testid={`invoice-row-${invoice.id}`}
		>
			<div className={styles.invoiceCell} data-testid={`cell-document-type-${index}`}>
				{getDocumentIdNameByType(invoice)}
				<span className={styles.itemCellSubValue}>{getInvoiceNumberInSeries(invoice)}</span>
			</div>
			<div className={styles.invoiceCell} data-testid={`cell-issue-date-${index}`}>
				{dayjs(invoice.issueDate).format('MMM DD, YYYY')}
			</div>
			<div className={styles.invoiceCell} data-testid={`cell-total-${index}`}>
				{getValueWithCurrencySign(invoice.total, convertCurrencyToSign(invoice.currency))}
			</div>

			<div className={styles.invoiceCell} data-testid={`cell-status-${index}`}>
				<InvoiceStatusTag document={invoice} refreshData={onRefreshData} />
			</div>

			<div className={styles.hamburgerContainer} data-testid={`cell-menu-options-${index}`}>
				<MenuList
					optionsList={invoiceMenuList({
						item: invoice,
						dispatch,
						navigate,
						t,
						availableIntegration,
						onRefresh: () => {
							onRowPress?.(invoice.id);
							onRefreshData?.();
						},
					})}
					isMenuOpen={openMenuOptions}
					openMenu={() => setOpenMenuOptions(false)}
				>
					<Button type='link' color='neutral' onClick={() => setOpenMenuOptions(!openMenuOptions)}>
						<Icon imgType='hamburger' className={styles.hamburgerButton} color='neutral700' />
					</Button>
				</MenuList>
			</div>
		</div>
	);
};
