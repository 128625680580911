import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Cell, PricingModelTable, PricingModelTableRow, CellTypes, tableCalculation, Consumer, ItemPriceDto, ItemDto } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { Button, DatePickerFormatType, DragAndDropSelectItem, DragAndDropSelectOptions, Icon } from '../_uiComponents';
import { ConnectUsageProductModal } from './ConnectUsageProductModal/ConnectUsageProductModal';
import { TableHeader } from './TableHeader/TableHeader';
import { TableRow } from './TableRow/TableRow';
import { convertIdToColors, PricingModelTableViewTypeOptions } from './PricingModelTable.utils';
import {
	onAddRow,
	onDeleteCol,
	onInsertCol,
	onMoveColumn,
	onUpdateHeaderName,
	onUpdateRowForColumnType,
	onUpdateUsageProduct,
} from './PricingModelTableActions';
import { PricingModelTableColumns } from '../../types/pricingModelTypes';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { httpService, PathsConfig } from '../../services';
import { Sidebars } from '../Sidebars/Sidebars';
import { PricingModelTab } from '../../types/contractTypes';
import { Customer } from '../../types/customerTypes';
import { PricingTableCellConfiguration } from './PricingTableCellConfiguration/PricingTableCellConfiguration';
import { AdditionalSettings } from './AdditionalSettings/AdditionalSettings';
import { SidebarType } from '../Sidebars/Sidebars.utils';
import styles from './PricingModelTable.module.scss';

export interface SelectedCell {
	cell: Cell;
	columnId: string;
}

export interface SelectedCellWithIndex extends SelectedCell {
	rowIndex: number;
}

export interface PricingModelTableClassNames {
	tableContainer?: string;
	headerContainer?: string;
	headerCell?: string;
	notEditable?: string;
	isEditable?: string;
	rowContainer?: string;
	row?: string;
	col?: string;
	cellContainer?: string;
	cell?: string;
	currentlyEdit?: string;
	settings?: string;
	table?: string;
}

interface PricingModelTableProps {
	pricingModelTable?: PricingModelTable;
	isEditable?: boolean;
	classNames?: PricingModelTableClassNames;
	selectedRows?: boolean[];
	selectedCustomer?: Customer;
	selectedUsagePeriod?: DatePickerFormatType;
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	selectedRowItem?: DragAndDropSelectItem;
	pricingModelTab?: PricingModelTab;
	headerChildren?: React.ReactNode;
	updateTableData?: (data: PricingModelTable) => void;
	onUpdateSelectedRows?: (rowIndex: number, isSelected: boolean) => void;
	onUpdateManualReportFromTable?: () => void;
	updatePricingModelData?: (data: PricingModelTab, instantUpdate?: boolean) => void;
	openUsageSideBar?(): void;
}

export const PricingModelTableComponent = ({
	pricingModelTable = { columns: [], rows: [] },
	isEditable,
	classNames,
	selectedRows,
	selectedCustomer,
	selectedUsagePeriod,
	pricingModelTableViewType,
	selectedRowItem,
	pricingModelTab,
	headerChildren,
	updateTableData,
	onUpdateSelectedRows,
	onUpdateManualReportFromTable,
	updatePricingModelData,
	openUsageSideBar,
}: PricingModelTableProps) => {
	const { t } = useTranslation('translation');
	const { columns = [], rows = [] } = pricingModelTable;

	const tableRef = useRef<HTMLInputElement>(null);

	const [selectedCell, setSelectedCell] = useState<SelectedCellWithIndex | undefined>();
	const [usageEditorData, setUsageEditorData] = useState<{ column: PricingModelTableColumns; columnIndex: number; rowIndex?: number }>();
	const [openConnectUsageModal, setOpenConnectUsageModal] = useState(false);
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [cellValueInput, setCellValueInput] = useState<string | number>('');
	const [hoverOnFormula, setHoverOnFormula] = useState(false);
	const [columnsToColor, setColumnsToColor] = useState({});
	const [itemPriceList, setItemPriceList] = useState<ItemPriceDto[]>([]);
	const [itemsList, setItemsList] = useState<ItemDto[]>([]);

	const dispatch = useDispatch();

	useEffect(() => {
		setCellValueInput(selectedCell?.cell?.formula || selectedCell?.cell?.cellValue || '');
	}, [selectedCell, selectedCell?.cell?.formula, selectedCell?.cell?.cellValue]);

	useEffect(() => {
		selectedCell &&
			setSelectedCell((prev) => {
				if (!prev) return;
				if (prev.rowIndex < rows.length && rows[prev.rowIndex].cells[prev.columnId]) {
					return { ...prev, cell: rows[prev.rowIndex].cells[prev.columnId] };
				} else {
					return { ...prev };
				}
			});
		const columnsColor = convertIdToColors(pricingModelTable.columns);
		setColumnsToColor(columnsColor);
	}, [pricingModelTable]);

	useEffect(() => {
		if (selectedRowItem && selectedRowItem.addOption === DragAndDropSelectOptions.ADD_BUTTON) {
			onAddRow(pricingModelTab, pricingModelTable, setSelectedCell, itemsList, dispatch, updateTableData, selectedRowItem);
		}
	}, [selectedRowItem]);

	useEffect(() => {
		document.addEventListener('click', stopEditing, true);
		return () => {
			document.removeEventListener('click', stopEditing, true);
		};
	}, [tableRef, selectedCell]);

	useEffect(() => {
		getItemPrice();
		getItems();
	}, []);

	const stopEditing = (event: { target: any }) => {
		// if all array elements not found in control or click was outside every marked element
		if (
			[tableRef].every(
				(ref) =>
					!ref.current ||
					event.target.id === 'pricing-model-tab-button' ||
					(ref.current &&
						!ref.current.contains(event.target) &&
						!event.target.id.includes('select') &&
						!event.target.id.includes('pricing-model-table-input') &&
						!(selectedCell?.cell.cellType === CellTypes.USAGE_AGGREGATOR_REFERENCE)),
			)
		) {
			setSelectedCell(undefined);
		}
	};

	const getItemPrice = async () => {
		try {
			const res = await httpService({
				dispatch,
				showLoader: false,
				path: PathsConfig.getItemPriceItems,
			});
			setItemPriceList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getItems = async () => {
		try {
			const res = await httpService({
				dispatch,
				showLoader: false,
				path: PathsConfig.getItems,
			});
			setItemsList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onRemoveRow = (index: number) => {
		setSelectedCell(undefined);
		const newRows = [...rows];
		newRows.splice(index, 1);
		const newTable = { ...pricingModelTable, rows: newRows, columns: [...columns] };
		const { table } = tableCalculation(newTable, Consumer.RECEIVED_UI);
		updateTableData && updateTableData(table);
	};

	const onUpdateTable = (rowData: PricingModelTableRow, index: number) => {
		const newRows = [...rows];
		newRows[index] = { ...rowData };
		const { table } = tableCalculation({ ...pricingModelTable, rows: newRows }, Consumer.RECEIVED_UI);

		updateTableData && updateTableData(table);
	};

	const onUpdateColumnType = (cellType: CellTypes) => {
		if (selectedCell) {
			const newColumns = columns.map((col) => ({ ...col, type: col.id === selectedCell.columnId ? cellType : col.type }));
			const newRows = onUpdateRowForColumnType(cellType, pricingModelTable, selectedCell);
			updateTableData && updateTableData({ rows: newRows, columns: newColumns });
		}
	};

	const onUpdateCellInput = (cellValue: string) => {
		if (selectedCell) {
			setCellValueInput(cellValue);
			const newRowData = { ...rows[selectedCell.rowIndex] };
			switch (newRowData.cells[selectedCell.columnId].cellType) {
				case CellTypes.FORMULA:
					newRowData.cells[selectedCell.columnId].formula = cellValue;
					break;
				default:
					newRowData.cells[selectedCell.columnId].cellValue = cellValue;
			}

			onUpdateTable(newRowData, selectedCell.rowIndex);
		}
	};

	const onArrowNavigation = ({ rowIndex, columnIndex }: { rowIndex: number; columnIndex: number }) => {
		const columIndexes = pricingModelTable?.columns.map((col) => col.id) || [];
		if (
			pricingModelTable?.rows &&
			rowIndex >= 0 &&
			rowIndex < pricingModelTable?.rows?.length &&
			columnIndex >= 0 &&
			columnIndex < columIndexes.length
		) {
			const newEditable: Cell = pricingModelTable?.rows[rowIndex].cells[columIndexes[columnIndex]];
			setSelectedCell({ cell: { ...newEditable }, rowIndex, columnId: columIndexes[columnIndex] });
		}
	};

	const onRenameColumn = (name: string, prevId: string, index: number) => {
		setSelectedCell(undefined);

		if (pricingModelTable) {
			const newTable = onUpdateHeaderName(name, prevId, index, pricingModelTable);
			updateTableData && updateTableData(newTable);
		}
	};

	const onStartInsertCol = (side: 'RIGHT' | 'LEFT', colIndex: number) => {
		if (pricingModelTable) {
			const newTable = onInsertCol(side, colIndex, pricingModelTable);
			updateTableData && updateTableData(newTable);
		}
	};

	const onOpenUsageSideBar = (column: PricingModelTableColumns, columnIndex: number, rowIndex?: number) => {
		setUsageEditorData(() => ({ column, columnIndex, rowIndex }));
		setOpenConnectUsageModal(true);
	};

	const onHideShowColumn = (column: PricingModelTableColumns, columnIndex: number) => {
		const newColumn = { ...column, isHidden: !column.isHidden };
		const newColumns = [...columns];
		newColumns[columnIndex] = newColumn;
		updateTableData && updateTableData({ columns: newColumns, rows: [...pricingModelTable.rows] });
	};

	const onHideShowColumnInInvoice = (column: PricingModelTableColumns, columnIndex: number) => {
		const newColumn = { ...column, isHiddenInInvoice: !column.isHiddenInInvoice };
		const newColumns = [...columns];
		newColumns[columnIndex] = newColumn;
		updateTableData && updateTableData({ columns: newColumns, rows: [...pricingModelTable.rows] });
	};

	const onHideShowRow = (row: PricingModelTableRow, rowIndex: number) => {
		const newRow = { ...row, isHidden: !row.isHidden };
		const newRows = [...rows];
		newRows[rowIndex] = newRow;

		updateTableData && updateTableData({ columns: [...pricingModelTable.columns], rows: newRows });
	};

	const onHideShowRowInInvoice = (row: PricingModelTableRow, rowIndex: number) => {
		const newRow = { ...row, isHiddenInInvoice: !row.isHiddenInInvoice };
		const newRows = [...rows];
		newRows[rowIndex] = newRow;
		updateTableData && updateTableData({ columns: [...pricingModelTable.columns], rows: newRows });
	};

	const onMoveCol = (side: 'RIGHT' | 'LEFT', colIndex: number) => {
		if (pricingModelTable) {
			const newTable = onMoveColumn(side, colIndex, pricingModelTable);
			updateTableData && updateTableData(newTable);
		}
	};

	return (
		<>
			<div className={clsx(styles.tableContainer, classNames?.tableContainer)} data-testid='pricing-model-table'>
				{pricingModelTableViewType === PricingModelTableViewTypeOptions.PRICING_MODEL && (
					<PricingTableCellConfiguration
						selectedCell={selectedCell}
						cellValueInput={cellValueInput}
						onUpdateCellInput={onUpdateCellInput}
						onUpdateColumnType={onUpdateColumnType}
						setSelectedCell={setSelectedCell}
						classNames={classNames}
					/>
				)}
				{(pricingModelTableViewType === PricingModelTableViewTypeOptions.CONTRACT ||
					pricingModelTableViewType == PricingModelTableViewTypeOptions.INVOICE_PREVIEW) && (
					<AdditionalSettings
						classNames={classNames}
						pricingModelTab={pricingModelTab}
						updatePricingModelData={updatePricingModelData}
						headerChildren={headerChildren}
					/>
				)}

				<div className={clsx(styles.table, classNames?.table)}>
					<TableHeader
						columns={columns}
						hoverOnFormula={hoverOnFormula}
						classNames={classNames}
						pricingModelTab={pricingModelTab}
						pricingModelTableViewType={pricingModelTableViewType}
						columnsToColor={columnsToColor}
						isEditable={isEditable}
						openUsageSideBar={onOpenUsageSideBar}
						onHideShowColumn={onHideShowColumn}
						onHideShowColumnInInvoice={onHideShowColumnInInvoice}
						addManualUsageReport={() =>
							openUsageSideBar ? openUsageSideBar() : setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: '' })
						}
						addColum={() => onStartInsertCol('RIGHT', columns.length - 1)}
						updateHeaderName={onRenameColumn}
						onInsertCol={onStartInsertCol}
						onDelete={(i) => onDeleteCol(i, columns, pricingModelTable, updateTableData)}
						onMoveCol={onMoveCol}
					/>

					<div ref={tableRef}>
						{rows.map((row: PricingModelTableRow, index: number) => (
							<TableRow
								key={index}
								rowData={row}
								columns={columns}
								rowIndex={index}
								selectedCell={selectedCell}
								selectedRows={selectedRows}
								columnsToColor={columnsToColor}
								isEditable={isEditable}
								classNames={classNames}
								productItems={itemPriceList}
								itemsList={itemsList}
								hoverOnFormula={hoverOnFormula}
								pricingModelTableViewType={pricingModelTableViewType}
								selectedRowItem={selectedRowItem}
								pricingModelTab={pricingModelTab}
								refreshData={() => getItems()}
								onCellSelected={(cell) => setSelectedCell(cell ? { ...cell, rowIndex: index } : undefined)}
								onRemoveRow={() => onRemoveRow(index)}
								updateTableData={(data) => onUpdateTable(data, index)}
								onArrowNavigation={onArrowNavigation}
								onUpdateSelectedRows={onUpdateSelectedRows}
								onHideShowRow={() => onHideShowRow(row, index)}
								onHideShowRowInInvoice={() => onHideShowRowInInvoice(row, index)}
								addManualUsageReport={() =>
									openUsageSideBar ? openUsageSideBar() : setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: '' })
								}
								setHoverOnFormula={setHoverOnFormula}
								openUsageSideBar={onOpenUsageSideBar}
							/>
						))}
					</div>
				</div>

				{isEditable && (
					<div className={styles.addNewRow}>
						<Button
							type='outlined'
							color='neutral'
							onClick={() => onAddRow(pricingModelTab, pricingModelTable, setSelectedCell, itemsList, dispatch, updateTableData)}
							className={styles.addNewRowButton}
						>
							<div>{t('new')}</div>
							<Icon imgType='round_add' color='neutral700' width={1.7} className={styles.addIcon}></Icon>
						</Button>
					</div>
				)}
			</div>

			{openSideBarById && (
				<Sidebars
					selectedCustomer={selectedCustomer}
					selectedUsagePeriod={selectedUsagePeriod}
					disableDocumentSideBar
					openSideBarById={openSideBarById}
					onCloseSideBars={() => setOpenSideBarById(undefined)}
					onUpdateManualReport={onUpdateManualReportFromTable}
				/>
			)}

			{!!usageEditorData && (
				<ConnectUsageProductModal
					isOpen={openConnectUsageModal}
					usageEditorData={usageEditorData}
					updateCellWithUsage={(data) => onUpdateUsageProduct(data, pricingModelTable, updateTableData, setUsageEditorData)}
					closeModal={() => setOpenConnectUsageModal(false)}
				/>
			)}
		</>
	);
};
