import { DocumentEditBlocker } from '@received/pricing-model';

export const creditNoteBlockersLabels = (t: any, blockers?: DocumentEditBlocker[]) => {
	const messages: string[] = [];
	blockers?.map((blocker) => {
		switch (blocker) {
			case DocumentEditBlocker.IMMUTABLE_STATE:
				return messages.push(t('creditnotepaid'));
			case DocumentEditBlocker.USED_BY_IMMUTABLE:
				return messages.push(t('usedByInvoices'));
		}
	});
	return messages.length > 1 ? messages.join(' & ') : messages;
};
