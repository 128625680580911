import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { convertCurrencyToSign } from '@received/pricing-model';
import { InvoiceStatusTag } from '../../../components';
import { Cell, ContractCell, PriceCell } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { InvoiceView } from '../../../types/invoiceTypes';
import { getSyncStatus } from '../../../utils/IntegrationUtils';
import { getCreditUsed, getTotalDue } from '../../../utils/InvoiceUtils';
import { getDocumentIdNameByType } from '../../../utils/GeneralUtils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { formatDraftIssueDays, formatSentIssueDays } from '../../../pages/_Billing/Invoices/AllInvoices/AllInvoices.utils';
import { getInvoiceNumberInSeries } from '../../../pages/_Billing/Invoices/Invoices.utils';

export const invoicesTabTableHeader = (refreshData: () => void) => [
	{
		headerTitle: 'contractNo',
		accessor: 'contractNumber',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<ContractCell
				contractNumber={contractIdFormat(item?.poNumber, item?.contractNumber)}
				state={item.state}
				cellSyncStatus={getSyncStatus(item)}
				subAccessor={item?.contractName}
			/>
		),
	},
	{
		headerTitle: 'invoiceNo',
		accessor: 'draftNumber',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => {
			return <Cell accessor={getDocumentIdNameByType(item)} subAccessor={getInvoiceNumberInSeries(item)} />;
		},
	},
	{
		headerTitle: 'products',
		accessor: 'productName',
		isSortable: true,
		CustomComponent: (item: any) => {
			const { t } = useTranslation('translation');
			const accessor = item?.productName || '';
			const subAccessor = +item?.productsCount > 1 ? `+${item?.productsCount - 1} ${t(item?.productsCount > 2 ? 'Products' : 'product')}` : '';
			return <Cell accessor={accessor} subAccessor={subAccessor} />;
		},
	},
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.issueDate).format(DATE_FORMAT)} subAccessor={formatDraftIssueDays(item)} />,
	},
	{
		headerTitle: 'dueDate',
		accessor: 'dueDate',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.dueDate).format(DATE_FORMAT)} subAccessor={formatSentIssueDays(item)} />,
	},
	{
		headerTitle: 'totalDue',
		accessor: 'total',
		isSortable: true,
		CustomComponent: (item: InvoiceView) => (
			<PriceCell currency={convertCurrencyToSign(item?.currency)} mainNumber={getTotalDue(item)} subNumber={getCreditUsed(item)} />
		),
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		disableRowClick: true,
		CustomComponent: (item: InvoiceView) => <InvoiceStatusTag document={item} refreshData={refreshData} />,
	},
];
