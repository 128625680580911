import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntegrationStatus } from '@received/pricing-model';
import clsx from 'clsx';
import { ActionModalModal, Button, Icon, MenuList, MenuOption } from '../../../../..';
import { IntegrationType } from '../../../../../../types/integrationTypes';
import { images } from '../../../../../../constants/images';
import styles from './ListItem.module.scss';

export interface ListItemProps {
	item: IntegrationType;
	menuOptions?: MenuOption[];
	connectLabel?: string;
	onDisconnect(): void;
	onClick?(supplierId?: string): void;
	onCancel(supplierId?: string): void;
	onMissingDetails(): void;
	onRemove(): void;
	onReconnect(): void;
}

export const ListItem = ({
	item,
	menuOptions,
	connectLabel,
	onDisconnect,
	onReconnect,
	onClick,
	onCancel,
	onMissingDetails,
	onRemove,
}: ListItemProps) => {
	const { t } = useTranslation('translation');
	const [openOptionsMenu, setOpenOptionsMenu] = useState(false);
	const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false);

	const getTagByStatus = () => {
		switch (item.connectionStatus) {
			case IntegrationStatus.LINKED:
				return <span className={styles.connectedTag}> {t('connected')}</span>;
			case IntegrationStatus.PENDING_AUTH:
				return <span className={styles.connectingTag}> {t('connecting')}</span>;
			case IntegrationStatus.MISSING_DETAILS:
				return <span className={styles.connectingTag}> {t('missingDetails')}</span>;
			case IntegrationStatus.SYNCING:
				return <span className={styles.connectedTag}> {t('syncing')}</span>;
			case IntegrationStatus.DISCONNECTED:
				return <span className={styles.disconnectedTag}> {t('disconnected')}</span>;
		}
	};

	const getActionByStatus = () => {
		switch (item.connectionStatus) {
			case IntegrationStatus.SYNCING:
			case IntegrationStatus.LINKED:
				return { title: 'disconnect', onClick: () => setOpenAreYouSureModal(true) };
			case IntegrationStatus.PENDING_AUTH:
				return { title: 'cancel', onClick: () => onCancel() };
			case IntegrationStatus.MISSING_DETAILS:
				return { title: 'completeDetails', onClick: () => onMissingDetails() };
			case IntegrationStatus.DISCONNECTED:
				return { title: 'reconnect', onClick: () => onReconnect() };
			default:
				null;
		}
	};

	const onDeleteItem = () => {
		switch (item.connectionStatus) {
			case IntegrationStatus.SYNCING:
			case IntegrationStatus.LINKED:
				return setOpenAreYouSureModal(true);
			case IntegrationStatus.PENDING_AUTH:
			case IntegrationStatus.MISSING_DETAILS:
				return onCancel();
			default:
				return onRemove();
		}
	};

	const clickableStates = [IntegrationStatus.LINKED, IntegrationStatus.SYNCING];

	return (
		<div
			className={clsx(styles.itemContainer, clickableStates.includes(item.connectionStatus) && styles.clickableItemContainer)}
			onClick={() => onClick?.(item.supplier?.id)}
		>
			<div className={styles.flexCenter}>
				<div className={styles.logoContainer}>
					{item?.supplier?.iconData ? (
						<img alt='logo' src={item?.supplier?.iconData} className={styles.logo} />
					) : (
						<Icon imgType='companyFlag' color='neutral300' width={2.8} />
					)}
				</div>
				<div className={styles.description}>
					<h2 className={clsx(styles.fontSize14, styles.fontWeight650)}>{item?.supplier?.name}</h2>
					{item?.supplier?.ein && (
						<div className={clsx(styles.neutralFontSize12, styles.fontWeight650)}>
							{t('EIN')} {item?.supplier?.ein}
						</div>
					)}
					{menuOptions ? (
						<MenuList
							optionsList={menuOptions}
							isMenuOpen={openOptionsMenu}
							openMenu={() => setOpenOptionsMenu(false)}
							placement='bottom'
							className={styles.menuOptions}
						>
							<Button
								type='outlined'
								color='neutral'
								dataTestId={`connect-integration-account-button`}
								onClick={(e) => {
									e.stopPropagation();
									setOpenOptionsMenu(true);
								}}
								className={clsx(styles.connectIntegrationAccount, openOptionsMenu && styles.borderSuccess)}
							>
								<span>{connectLabel}</span>
								<Icon imgType='arrow_up' color='neutral' width={1.3} className={openOptionsMenu ? styles.arrow : styles.arrowClosed} />
							</Button>
						</MenuList>
					) : (
						<div className={styles.descriptionLabel}>{item?.metadata?.accountName}</div>
					)}
				</div>
			</div>

			<div className={styles.actions}>
				<div className={styles.tag}>
					<div className={clsx(styles.connected)}>{getTagByStatus()}</div>

					{!!getActionByStatus()?.title && (
						<div
							className={styles[getActionByStatus()?.title || '']}
							onClick={(e) => {
								e.stopPropagation();
								getActionByStatus()?.onClick();
							}}
						>
							{t(getActionByStatus()?.title || '')}
						</div>
					)}
				</div>
				<Button onClick={() => onDeleteItem()} type='outlined' color='neutral' className={styles.delete}>
					<Icon imgType='delete' width={1.2} color='neutral700' />
				</Button>
				<Button
					onClick={(e) => {
						e.stopPropagation();
						onClick?.(item.supplier?.id);
					}}
					disabled={!clickableStates.includes(item.connectionStatus)}
					type='outlined'
					color='neutral'
					className={styles.delete}
				>
					<Icon imgType='settings' width={1.2} color='neutral700' />
				</Button>
			</div>

			<ActionModalModal
				rightButton={{ title: 'disconnect', color: 'destructive' }}
				imagesOptions={images.alert}
				isOpen={openAreYouSureModal}
				deleteMessage={`${t('disconnect')} ${item?.supplier?.name}`}
				onAccept={() => {
					onDisconnect?.();
					setOpenAreYouSureModal(false);
				}}
				onClose={() => setOpenAreYouSureModal(false)}
			/>
		</div>
	);
};
