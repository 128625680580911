import React, { CSSProperties, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Cell } from '../Table.utils';
import { Button, CheckBox, Icon, MenuList, MenuOption } from '../..';
import styles from './TableRow.module.scss';

type Accessor = {
	headerTitle?: string;
	accessor: string;
	subAccessor?: string;
	totalTitle?: string;
	disableRowClick?: boolean;
};

type CustomComponent = {
	headerTitle?: string;
	totalTitle?: string;
	disableRowClick?: boolean;
	isSortable?: boolean;
	CustomComponent: (item: any, index: number) => React.ReactNode;
};

export type TableRowHeaderProps = (Accessor | CustomComponent) & { style?: CSSProperties };

interface TableRowProps {
	header: TableRowHeaderProps[];
	rowData: any;
	className?: string;
	cellClassName?: string;
	activeRow?: boolean;
	showCheckBox?: boolean;
	isAllChecked?: boolean;
	containerStyle?: string;
	totalData?: any;
	rowIndex?: number;
	optionsList?: (row: any) => MenuOption[];
	onRowPress?: () => void;
	checkRow?: (check: boolean) => void;
}

const isCustomComponent = (item: CustomComponent | Accessor): item is CustomComponent => {
	return !!(item as CustomComponent).CustomComponent;
};

export const TableRow = ({
	rowIndex,
	rowData,
	header,
	isAllChecked,
	showCheckBox,
	containerStyle,
	onRowPress,
	checkRow,
	className,
	activeRow,
	totalData,
	cellClassName,
	optionsList,
}: TableRowProps) => {
	const { t } = useTranslation('translation');

	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	useEffect(() => {
		setIsChecked(!!isAllChecked);
	}, [isAllChecked]);

	return (
		<div data-testid={`table-row-index-${rowIndex}`} className={clsx(styles.container, activeRow && styles.containerWithPress, containerStyle)}>
			{showCheckBox && (
				<div className={styles.checkBoxCol}>
					<CheckBox
						isChecked={isChecked}
						setIsChecked={(check) => {
							checkRow && checkRow(check);
							setIsChecked(check);
						}}
					/>
				</div>
			)}

			<div
				className={clsx(styles.rowContainer, className)}
				data-testid={`table-clickable-row-values:${rowData ? Object.values(rowData).join(', ') : ''}`}
			>
				{header.map((item, index) => (
					<div
						data-testid={`header:${item.headerTitle}-cell`}
						key={index}
						onClick={(e) => {
							!item.disableRowClick && onRowPress && onRowPress();
						}}
						className={clsx(styles.col, cellClassName)}
						style={item?.style}
					>
						{item?.totalTitle && totalData ? (
							<Cell accessor={t(item?.totalTitle)}></Cell>
						) : isCustomComponent(item) ? (
							item.CustomComponent(rowData, index)
						) : (
							<Cell accessor={rowData[item.accessor]} subAccessor={item?.subAccessor && rowData[item?.subAccessor]}></Cell>
						)}
					</div>
				))}
			</div>

			<div className={styles.hamburgerContainer}>
				{optionsList && optionsList(rowData).length ? (
					<MenuList optionsList={optionsList(rowData)} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
						<Button
							dataTestId={`table-row-hamburger-values:${rowData ? Object.values(rowData).join(', ') : ''}`}
							type='link'
							color='neutral'
							onClick={() => setOpenMenuOptions(!openMenuOptions)}
							className={styles.hamburgerButton}
						>
							<Icon imgType='hamburger' className={styles.hamburger} />
						</Button>
					</MenuList>
				) : (
					<div />
				)}
			</div>
		</div>
	);
};
