import React from 'react';
import styles from './Layout.module.scss';

interface LayoutProps {
	children: React.ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
	return <div className={styles.layoutContainer}>{children}</div>;
};
