export const DATE_FORMAT = 'MMM D, YYYY';
export const SHORT_YEAR_FORMAT = 'MMM DD, ‘YY';
export const DATE_FORMAT_SLASH = 'MM/DD/YYYY';
export const YEARLY_DATE_FORMAT = 'MMM D';
export const MONTHLY_DATE_FORMAT = 'D';
export const DATE_FORMAT_FOR_SERVER = 'YYYY-MM-DD';
export const FILE_DATE = 'MMM DD, YYYY hh:mm';
export const TIMESTAMP = 'MMM D, YYYY • HH:mm';

export const BLANK_PRICING_MODEL_ID = 'f9a194bc-61e6-452a-a26d-fb9cc8d8cabc';
export const BLANK_REFERRAL_PRICING_MODEL_ID = '13714ea4-271b-4fe0-b72a-1a3c9f343df1';
export const CREDIT_BLANK_PRICING_MODEL_ID = '878ba8a6-883f-49f5-8620-b3835683629a';
export const EMPTY_ITEM_ID = 'b82a8b38-c84b-4cb3-8894-2942ead0dd10';
