import { DocumentState, PricingType } from '@received/pricing-model';
import { PricingModelTab } from '../../../../types/contractTypes';
import { Invoice } from '../../../../types/invoiceTypes';
import { canAddMoreUsageStates } from '../../../../utils/InvoiceUtils';
import { InvoiceProducts } from './InvoiceProducts/InvoiceProducts';
import { SidebarType } from '../../../../components/Sidebars/Sidebars.utils';
import { DocumentSettings } from '../../../Contracts/ContractComponent/DocumentSettings/DocumentSettings';
import { Payout } from '../../../../types/PayoutsTypes';

export enum SingleInvoiceTabsName {
	PRODUCTS = 'Products',
	SETTINGS = 'Settings',
}

export const invoiceTabs = () => {
	return [
		{
			title: SingleInvoiceTabsName.PRODUCTS,
			component: (prop?: any) => <InvoiceProducts {...prop} />,
		},
		{
			title: SingleInvoiceTabsName.SETTINGS,
			component: (prop?: any) => <DocumentSettings {...prop} />,
		},
	];
};

export interface DisabledActions {
	disabledSend: boolean;
	disabledSaveAndClose: boolean;
	tooltipLabel: string;
}

export interface SideBarData {
	sideBarType: SidebarType;
	id: string;
}

export const setCollectiveTabsData = (newInvoice: Invoice | Payout, newPricingModelTab: PricingModelTab) => {
	if (newInvoice?.netTerms != newPricingModelTab?.netTerms) {
		newInvoice.netTerms = newPricingModelTab?.netTerms;
		newInvoice.pricingModel.tabs.forEach((tab) => {
			tab.netTerms = newPricingModelTab?.netTerms;
		});
	}
	if (newInvoice?.issueDate != newPricingModelTab?.issueDate) {
		newInvoice.issueDate = newPricingModelTab?.issueDate;
		newInvoice.pricingModel.tabs.forEach((tab) => {
			tab.issueDate = newPricingModelTab?.issueDate;
		});
	}
	if (newInvoice?.supplierId != newPricingModelTab?.supplierId) {
		newInvoice.supplierId = newPricingModelTab?.supplierId;
		newInvoice.pricingModel.tabs.forEach((tab) => {
			tab.supplierId = newPricingModelTab?.supplierId;
		});
	}

	if (newInvoice?.billingDetails) {
		newInvoice.billingDetails = newPricingModelTab?.billingDetails;
		newInvoice.pricingModel.tabs.forEach((tab) => {
			if (tab.billingDetails) {
				tab.billingDetails = newPricingModelTab?.billingDetails;
			}
		});
	}
	if (newInvoice?.cryptoSettings) {
		newInvoice.cryptoSettings = newPricingModelTab?.cryptoSettings;
		newInvoice.pricingModel.tabs.forEach((tab) => {
			if (tab.cryptoSettings) {
				tab.cryptoSettings = newPricingModelTab?.cryptoSettings;
			}
		});
	}
	if (newInvoice?.currency != newPricingModelTab?.currency) {
		newInvoice.currency = newPricingModelTab?.currency;
		newInvoice.pricingModel.tabs.forEach((tab) => {
			tab.currency = newPricingModelTab?.currency;
		});
	}
	return newInvoice;
};

export const isDueDateEditable = (invoice?: Invoice) => {
	return invoice?.state && Object.values(DocumentState).indexOf(invoice?.state) < Object.values(DocumentState).indexOf(DocumentState.COLLECTING_INFO);
};

export const isAddReportButtonDisplayed = (invoiceState?: DocumentState, pricingModelTabType?: PricingType): boolean => {
	if (pricingModelTabType === PricingType.USAGE) {
		return canAddMoreUsageStates(invoiceState);
	}
	return false;
};

export enum UnSavedModalActions {
	GO_BACK = 'GO_BACK',
	SAVE_AND_SEND = 'SAVE_AND_SEND',
	NAVIGATE_TO_DIFFERENT_DOCUMENT = 'NAVIGATE_TO_DIFFERENT_DOCUMENT',
}

export interface UnSavedChanges {
	documentChanged: boolean;
	isSaveChangesModalOpen: boolean;
	action?: UnSavedModalActions;
	mainButtonAction?: () => void;
	secondaryButtonAction?: () => void;
}

export const initialUnSavedChangesState = {
	documentChanged: false,
	isSaveChangesModalOpen: false,
	mainButtonAction: undefined,
	secondaryButtonAction: undefined,
};

export const isTabEditable = (activeTab: number, viewOnlyMode: boolean) =>
	activeTab == Object.values(SingleInvoiceTabsName).indexOf(SingleInvoiceTabsName.PRODUCTS) ? !viewOnlyMode : true;
