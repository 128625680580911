import { useEffect, useState } from 'react';
import { NestedItem } from './NestedItem/NestedItem';

export interface NestedListHeader {
	mainName: string;
	id: string;
	title: string;
	[key: string]: string;
}
export interface NestedListProps {
	data: NestedItem[];
	selectedItem?: string;
	defaultHeaderOpen?: string;
	className?: string;
}

export const NestedList = ({ data, selectedItem, defaultHeaderOpen, className }: NestedListProps) => {
	const [selectedItemId, setSelectedItemId] = useState('');
	const [selectedHeader, setSelectedHeader] = useState('');

	useEffect(() => {
		selectedItem && setSelectedItemId(selectedItem);
	}, [selectedItem]);

	useEffect(() => {
		defaultHeaderOpen && setSelectedHeader(defaultHeaderOpen);
	}, [defaultHeaderOpen]);

	return (
		<div data-testid='nested-list' className={className}>
			{data.map((listData, index) => (
				<NestedItem
					key={index}
					index={index}
					selectedItem={selectedItemId}
					selectedHeader={selectedHeader}
					handleHeaderClick={setSelectedHeader}
					{...listData}
				/>
			))}
		</div>
	);
};
