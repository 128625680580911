import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, CountriesSelect, FileUploader, Input } from '../..';
import { EntityForm, entityValidation, initialState, tabIndexes } from './EntityInfoTab.utils';
import { DEFAULT_PHONE_PREFIX } from '../../../constants/generalConstants';
import { Store } from '../../../types/storeTypes';
import { countryIsUSA } from '../../../utils/GeneralUtils';
import { Customer } from '../../../types/customerTypes';
import styles from './EntityInfoTab.module.scss';

interface EntityInfoTabProps {
	supplier?: Customer;
	setSupplier: (data: Customer) => void;
	setActiveTab: (tab: number) => void;
}

export const EntityInfoTab = ({ supplier, setSupplier, setActiveTab }: EntityInfoTabProps) => {
	const [entityData, setEntityData] = useState<EntityForm>(initialState);
	const [hideState, setHideState] = useState(false);

	const { t } = useTranslation('translation');
	const { countries, usaStates } = useSelector((store: Store) => store.general?.geoLocationData);

	useEffect(() => {
		setEntityData({
			...entityData,
			...supplier,
		});
	}, [supplier]);

	useEffect(() => {
		setHideState(!countryIsUSA(entityData.country));
		setCountryCode();
	}, [entityData.country]);

	const setCountryCode = () => {
		const prefix = countries?.find((i) => i.label === entityData.country)?.phonePrefix ?? DEFAULT_PHONE_PREFIX;
		updatePhone('countryCode', prefix);
	};

	const validateData = async () => {
		const { isValid, data } = entityValidation(entityData);
		setSupplier({ ...supplier, ...data });
		if (isValid) {
			setActiveTab(1);
		} else {
			setEntityData(data);
		}
	};

	const updateForm = (field: string, value: string) => {
		setEntityData((prev) => {
			setSupplier({ ...prev, [field]: value, [`${field}Error`]: false });
			return { ...prev, [field]: value, [`${field}Error`]: false };
		});
	};

	const updatePhone = (field: string, value: string) => {
		const phone = { ...entityData.phone, ...supplier?.phone, [field]: value ?? '' };
		setEntityData((prev) => {
			return { ...prev, phone, [`${field}Error`]: false };
		});
	};

	return (
		<>
			<div className={styles.content}>
				<div className={styles.firstRow}>
					<FileUploader
						showDescription
						value={entityData.iconData}
						fileTypes={['PNG', 'JPG', 'JPEG']}
						uploadBase64File={(file) => updateForm('iconData', file)}
						className={styles.fileRow}
					/>
					<Input
						autoFocus
						tabIndex={tabIndexes.LEGAL_NAME}
						value={entityData.legalName}
						showError={entityData.legalNameError}
						onChange={(value) => {
							updateForm('legalName', value);
						}}
						title={t('legalName')}
						placeholder={t('companyRegistrationName')}
						titleStyle={styles.input}
						containerClass={styles.fileRow}
						required
						onBlur={(e) => {
							updateForm('legalName', e.target.value.trim());
						}}
					/>
				</div>

				<div className={styles.detailsForm}>
					<div className={styles.leftContainer}>
						<Input
							tabIndex={tabIndexes.NAME}
							value={entityData.name}
							showError={entityData.nameError}
							onChange={(value) => {
								updateForm('name', value);
							}}
							title={t('entityName')}
							placeholder={t('companyName')}
							titleStyle={styles.input}
							containerClass={styles.nameInput}
							required
							onBlur={(e) => {
								updateForm('name', e.target.value.trim());
							}}
						/>

						<CountriesSelect
							tabIndex={tabIndexes.COUNTRY}
							title={t('country')}
							onChange={(value) => updateForm('country', value)}
							defaultValue={entityData?.country}
							data={countries}
							width={28.5}
						/>

						<Input
							tabIndex={tabIndexes.CITY}
							type='text'
							value={entityData?.city}
							titleStyle={styles.input}
							onChange={(value) => updateForm('city', value)}
							showError={entityData.cityError}
							title={t('city')}
							placeholder={t('cityPlaceholder')}
						/>

						<Input
							tabIndex={tabIndexes.EMAIL}
							value={entityData?.email}
							onChange={(value) => updateForm('email', value)}
							title={t('email')}
							containerClass={styles.someMargin}
							showError={entityData.emailError}
							type='email'
							titleStyle={styles.input}
							placeholder={t('emailPlaceholder')}
						/>
						<span className={styles.neutralFontSize12}>{t('emailComment')}</span>
					</div>

					<div className={styles.rightContainer}>
						<Input
							tabIndex={tabIndexes.EIN}
							value={entityData?.ein}
							titleStyle={styles.input}
							onChange={(value) => updateForm('ein', value)}
							showError={entityData.einError}
							title={t('EINumber')}
							placeholder={t('einPlaceHolder')}
						/>
						<div className={styles.flex}>
							{!hideState && (
								<CountriesSelect
									tabIndex={tabIndexes.STATE}
									title={t('state')}
									onChange={(value) => updateForm('countryState', value)}
									placeholder={t('selectState')}
									width={16.3}
									data={usaStates}
									defaultValue={entityData.countryState}
									showOnlyPrefix
								/>
							)}
							<Input
								tabIndex={tabIndexes.ZIP_CODE}
								value={entityData?.zipCode}
								onChange={(value) => updateForm('zipCode', value)}
								title={t('zipCode')}
								showError={entityData.postalCodeError}
								titleStyle={!hideState ? styles.zipCodeTitle : undefined}
								placeholder={'000000'}
								className={!hideState ? styles.zipCode : styles.zipCodeWithoutState}
							/>
						</div>

						<Input
							tabIndex={tabIndexes.FULL_ADDRESS}
							value={entityData.shippingAddress}
							onChange={(value) => updateForm('shippingAddress', value)}
							title={t('fullAddress')}
							showError={entityData.shippingAddressError}
							titleStyle={styles.input}
							placeholder={t('addressPlaceholder')}
						/>

						<div>
							<span className={styles.phoneTitle}>{t('phoneNumber')}</span>
							<div className={styles.flex}>
								<Input
									tabIndex={tabIndexes.PHONE_PREFIX}
									maxLength={4}
									value={`+${entityData?.phone?.countryCode}`}
									showError={entityData.countryCodeError}
									onChange={(value) => updatePhone('countryCode', value.replace(/\D+/g, ''))}
									className={styles.phonePrefix}
									titleStyle={styles.input}
								/>
								<Input
									tabIndex={tabIndexes.PHONE_NUMBER}
									maxLength={20}
									value={entityData?.phone?.phone}
									showError={entityData.phoneError}
									onChange={(value) => updatePhone('phone', value.replace(/\D+/g, ''))}
									containerClass={styles.fullWidth}
									placeholder={'000 0000 0000'}
									titleStyle={styles.input}
								/>
							</div>
						</div>
						<span className={styles.neutralFontSize12}>{t('phoneComment')}</span>
					</div>
				</div>
			</div>
			<div className={styles.buttonContainer}>
				<Button tabIndex={tabIndexes.NEXT_BUTTON} onClick={validateData} className={styles.button}>
					{t('next')}
				</Button>
			</div>
		</>
	);
};
