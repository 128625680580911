import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, DatePickerFormatType, PricingModelTableComponent, PricingSummary, PricingTerms } from '../../..';
import { PricingModelTableViewTypeOptions } from '../../../PricingModelTable/PricingModelTable.utils';
import { Invoice } from '../../../../types/invoiceTypes';
import { Customer } from '../../../../types/customerTypes';
import { PricingModelTab } from '../../../../types/contractTypes';
import { updatePaymentPeriod, updatePricingModelTable } from '../../PricingModelTabContentActions';
import { isAddReportButtonDisplayed } from '../../../../pages/_Billing/Invoices/SingleInvoicePage/SingleInvoicePage.utils';
import styles from './InvoiceContent.module.scss';

export interface InvoiceContentProps {
	invoice?: Invoice;
	selectedCustomer?: Customer;
	pricingModelTab: PricingModelTab;
	isEditable?: boolean;
	className?: string;
	creditsAmount?: number;
	disableActions: boolean;
	selectedUsagePeriod?: DatePickerFormatType | undefined;
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void;
	setOpenUsageSideBar(): void;
}

export const InvoiceContent = ({
	invoice,
	selectedCustomer,
	pricingModelTab,
	className,
	isEditable,
	creditsAmount,
	disableActions,
	selectedUsagePeriod,
	updatePricingModelData,
	setOpenUsageSideBar,
}: InvoiceContentProps) => {
	const { t } = useTranslation('translation');

	useEffect(() => {
		updatePaymentPeriod(pricingModelTab, updatePricingModelData);
	}, [pricingModelTab?.issueDay, pricingModelTab?.billingStartDate, pricingModelTab?.billingEndDate, pricingModelTab?.billingCycle]);

	return (
		<div className={clsx(styles.container, className)}>
			<PricingModelTableComponent
				headerChildren={
					<Button
						onClick={setOpenUsageSideBar}
						type='outlined'
						color='neutral'
						className={clsx(
							styles.editable,
							isAddReportButtonDisplayed(invoice?.state, pricingModelTab?.pricingType) ? styles.actionButton : styles.hidden,
						)}
						disabled={disableActions}
					>
						<span className={styles.buttonText}>{t('addUsage')}</span>
					</Button>
				}
				openUsageSideBar={setOpenUsageSideBar}
				pricingModelTableViewType={PricingModelTableViewTypeOptions.INVOICE_PREVIEW}
				pricingModelTable={pricingModelTab?.pricingModelTable}
				pricingModelTab={pricingModelTab}
				isEditable={isEditable}
				selectedCustomer={selectedCustomer}
				selectedUsagePeriod={selectedUsagePeriod}
				updateTableData={(table) => updatePricingModelTable(table, pricingModelTab, updatePricingModelData)}
				updatePricingModelData={updatePricingModelData}
				classNames={{ tableContainer: styles.tableContainer }}
			/>

			<div className={styles.termsAndPricingContainer}>
				<PricingTerms
					className={styles.innerBorders}
					pricingModelTab={pricingModelTab}
					customerId={selectedCustomer?.id}
					updatePricingTermsData={({ note, supplierId, billingDetails, cryptoSettings }, instantUpdate?: boolean) => {
						updatePricingModelData(
							{
								...pricingModelTab,
								billingDetails: { ...pricingModelTab.billingDetails, ...billingDetails },
								cryptoSettings: { ...pricingModelTab.cryptoSettings, ...cryptoSettings },
								supplierId,
								note,
							},
							instantUpdate,
						);
					}}
				/>
				{pricingModelTab && (
					<PricingSummary
						pricingModelTab={pricingModelTab}
						updatePricingSummaryData={({ tax, discount }) => updatePricingModelData({ ...pricingModelTab, tax, discount })}
						className={styles.innerBorders}
						creditsAmount={creditsAmount}
					/>
				)}
			</div>
		</div>
	);
};
