import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon, Input, Tooltip } from '../../..';
import { UsageProduct } from '../../../../types/usageTypes';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { BillingDocumentType } from '../../../../types/generalTypes';
import styles from './UsageReportInput.module.scss';

interface UsageReportInputProps {
	usageProduct: UsageProduct;
	defaultCount?: string;
	index?: number;
	documents: Array<BillingDocumentType>;
	showSubActivity: boolean;
	subActivity?: string;
	disableInput?: boolean;
	onUpdateCount(count: string): void;
	onUpdateSubActivity(val: string): void;
}

export const UsageReportInput = ({
	usageProduct,
	defaultCount,
	showSubActivity,
	subActivity,
	disableInput,
	index,
	onUpdateCount,
	onUpdateSubActivity,
}: UsageReportInputProps) => {
	const { t } = useTranslation('translation');
	const [usedCount, setUsedCount] = useState('');
	const [usageSubActivity, setUsageSubActivity] = useState('');

	useEffect(() => {
		setUsedCount(defaultCount || '');
	}, [defaultCount]);

	useEffect(() => {
		setUsageSubActivity(subActivity || '');
	}, [subActivity]);

	const updateCount = (count: string) => {
		onUpdateCount(count);
		setUsedCount(count);
	};

	const updateSubActivity = (val: string) => {
		setUsageSubActivity(val);
		onUpdateSubActivity(val);
	};

	return (
		<div className={clsx(styles.container, showSubActivity ? styles.containerOpen : styles.containerClose)}>
			<div className={styles.sectionContainer}>
				<div className={styles.leftSection}>
					<div className={styles.iconContainer}>
						<Icon imgType='graph' color='success' className={styles.summaryIcon} />
					</div>

					<div>
						<div className={styles.titleContainer}>
							<div className={styles.title}>{usageProduct.name}</div>
						</div>
						<Tooltip
							tooltipComponent={
								<div className={styles.tooltipLabel}>
									{usageProduct?.documents?.map((document) => getDocumentIdNameByType(document)).join('\r\n')}
								</div>
							}
							blackTooltip
						>
							<div className={styles.invoiceList}>{usageProduct?.documents?.map((document) => getDocumentIdNameByType(document)).join(', ')}</div>
						</Tooltip>
					</div>
				</div>

				<div className={styles.rightSection}>
					<div className={styles.flex}>
						<input
							autoFocus={index == 0}
							type='number'
							data-testid={`usage-product-input-${usageProduct?.name}-${index}`}
							value={usedCount}
							placeholder='0.00'
							onChange={(event) => updateCount(event.target.value)}
							maxLength={10}
							className={clsx(styles.input, disableInput && styles.disabled)}
							disabled={disableInput}
						/>
						<div className={styles.titleInput}>{usageProduct.usageGroup?.countedUnit}</div>
					</div>
					<div className={styles.subTitle}>{t('Total')}</div>
				</div>
			</div>

			{showSubActivity && (
				<Input
					title={t('subActivity')}
					placeholder={t('typeNameSubActivity')}
					value={usageSubActivity}
					onChange={updateSubActivity}
					containerClass={styles.subActivityInput}
				/>
			)}
		</div>
	);
};
