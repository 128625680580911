import dayjs from 'dayjs';
import { convertCurrencyToSign, DATE_FORMAT } from '@received/pricing-model';
import { Cell, PriceCell } from '../../../components/_uiComponents/Table/Table.utils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { GraphsDataResponse } from '../../../pages/Contracts/ContractComponent/ContractOverview/ContractOverview.utils';
import { formatPaymentDueDate, PayoutCSVTitles } from '../../../pages/_Billing/Payouts/Payouts.utils';
import { PayoutView } from '../../../types/PayoutsTypes';
import { netTermsToLabel } from '../../../utils/GeneralUtils';
import { PayoutStatusTag } from '../../StatusTags';

export interface PartnerOverviewSummary {
	bookingsSum: number;
	bookingYoy: number;
	partnerFees: number;
	partnerFeesYoy: number;
	grossIncome: number;
	customers: number;
	activeContracts: number;
	churnRate: number;
	unpaidFees: number;
}

export const LatestActivitiesTableHeader = (refreshData: () => void) => [
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CSVIndex: PayoutCSVTitles.ISSUE_DATE,
		CustomComponent: (item: PayoutView) => <Cell accessor={dayjs(item.issueDate).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'payoutNo',
		accessor: 'payoutNumber',
		isSortable: true,
		CSVIndex: PayoutCSVTitles.PAYOUT_NO,
		CustomComponent: (item: PayoutView) => (
			<Cell
				accessor={item?.payoutNumber}
				subAccessor={item?.payoutNumberInSeries ? `${item.payoutNumberInSeries}/${item.payoutSeriesTotalCount}` : ''}
			/>
		),
	},
	{
		headerTitle: 'agreementNo',
		accessor: 'agreementNumber',
		isSortable: true,
		CSVIndex: PayoutCSVTitles.AGREEMENT_NO,
		CustomComponent: (item: PayoutView) => (
			<Cell accessor={contractIdFormat(item?.poNumber, item?.agreementNumber)} subAccessor={item.agreementName} />
		),
	},
	{
		headerTitle: 'DueDate',
		accessor: 'payoutDueDate',
		isSortable: true,
		CSVIndex: PayoutCSVTitles.DUE_DATE,
		CustomComponent: (item: PayoutView) => <Cell accessor={formatPaymentDueDate(item)} subAccessor={`${netTermsToLabel(item?.netTerms)} `} />,
	},
	{
		headerTitle: 'totalFee',
		accessor: 'totalFee',
		isSortable: true,
		CSVIndex: PayoutCSVTitles.TOTAL_FEE,
		CustomComponent: (item: PayoutView) => <PriceCell mainNumber={item?.totalFee} currency={convertCurrencyToSign(item?.currency)} />,
	},

	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		disableRowClick: true,
		CSVIndex: PayoutCSVTitles.STATUS,
		CustomComponent: (item: PayoutView) => <PayoutStatusTag document={item} isShort refreshData={refreshData} />,
	},
];

export const partnerOverViewSummaryInitialState: PartnerOverviewSummary = {
	bookingsSum: 0,
	bookingYoy: 0,
	partnerFees: 0,
	partnerFeesYoy: 0,
	grossIncome: 0,
	customers: 0,
	activeContracts: 0,
	churnRate: 0,
	unpaidFees: 0,
};
