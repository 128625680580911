import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { truncateString } from '../../../utils/GeneralUtils';
import { Button, Icon, Input, InputProps } from '../../_uiComponents';
import styles from './RemoteIdInput.module.scss';

export const RemoteIdInput = <T extends HTMLInputElement>({ value, tabIndex, ...props }: InputProps<T>) => {
	const { t } = useTranslation('translation');

	const [editMode, setEditMode] = useState(false);

	return (
		<div className={styles.container}>
			{editMode ? (
				<Input
					{...props}
					onBlur={() => setEditMode(false)}
					onKeyDown={(event) => {
						event.key === 'Enter' && setEditMode(false);
					}}
					value={value}
					autoFocus
					placeholder={t('addRemoteId')}
					className={styles.integrationIdBox}
					inputClassName={styles.integrationIdInput}
					rightChildren={<Icon imgType='edit' width={1.2} color='neutral600' />}
					leftChildren={<Icon imgType='key' width={2} color='neutral600' />}
				/>
			) : (
				<div className={styles.integrationIdBox} onClick={() => setEditMode(true)} tabIndex={tabIndex}>
					<div className={styles.leftIntegrationContentBox}>
						<Icon imgType='key' width={2} color='neutral600' />
						<span className={styles.value}>{value ? truncateString(value?.toString() || '') : t('addRemoteId')}</span>
					</div>
					<Button type='link' color='neutral' className={styles.editIconWrapper}>
						<Icon imgType='edit' width={1.2} color='neutral600' />
					</Button>
					{!!value && (
						<Button type='link' color='neutral' className={styles.copyButton} onClick={() => navigator.clipboard.writeText(value?.toString() || '')}>
							<Icon imgType='copy' width={2} className={styles.copy} color='neutral400' />
						</Button>
					)}
				</div>
			)}
		</div>
	);
};
