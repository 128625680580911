import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { convertCurrencyToSign } from '@received/pricing-model';
import { UsageSummaryItem } from '../../../../../../types/usageTypes';
import { Button, Icon, MenuList, MenuOption, TextWithTooltip } from '../../../../../../components';
import { Store } from '../../../../../../types/storeTypes';
import { numberWithCommas, priceNumberWithCurrencySignDisplay } from '../../../../../../utils/NumberUtils';
import styles from './UsageProductCard.module.scss';

interface UsageProductCardProps {
	usageProduct: UsageSummaryItem;
	isSelected: boolean;
	onClick(): void;
	onEdit(): void;
	onDelete(): void;
}

export const UsageProductCard = ({ usageProduct, isSelected, onClick, onEdit, onDelete }: UsageProductCardProps) => {
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);

	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);
	const menuOptions: MenuOption[] = [
		{ title: t('edit'), onPress: () => onEdit(), iconType: 'edit', hasBottomBorder: true },
		{ title: t('remove'), onPress: () => onDelete(), iconType: 'delete', color: 'destructive' },
	];

	return (
		<div
			className={clsx(styles.container, isSelected && styles.isActive)}
			onClick={(event) => {
				event.stopPropagation();
				onClick();
			}}
			data-testid='usage-product-menu'
			onContextMenu={(event) => {
				event.preventDefault();
				setIsOptionsMenuOpen(true);
			}}
		>
			<div className={styles.iconWrapper} style={{ backgroundColor: usageProduct?.colors?.backgroundColorIcon }}>
				<Icon imgType='usage_template' width={2} color={usageProduct?.colors?.colorIcon || 'success'} />
			</div>
			<div className={styles.dataWrapper}>
				<div className={styles.labels}>
					<TextWithTooltip
						maxWidth={22}
						className={styles.neutralLabel}
						style={{ color: usageProduct?.colors?.textColor }}
						tooltip={{ className: styles.tooltip }}
					>
						{usageProduct.connectedProducts?.length ? (
							usageProduct.connectedProducts.map((name, index) => (
								<Fragment key={index}>{`${name}${index != usageProduct.connectedProducts.length - 1 ? ' •' : ''}`}</Fragment>
							))
						) : (
							<span data-testid='pricing-product'> {t('notUsed')}</span>
						)}
					</TextWithTooltip>
					<TextWithTooltip
						maxWidth={22}
						className={styles.neutralLabel}
						style={{ color: usageProduct?.colors?.textColor }}
						tooltip={{ className: styles.tooltip }}
					>
						<span data-testid='usage-product-name' className={styles.mainLabel}>
							{usageProduct.usageProductName}
						</span>
					</TextWithTooltip>
					<span data-testid='usage-product-description' className={styles.neutralSubLabel}>
						{usageProduct.usageGroupDescription}
					</span>
				</div>
				<div className={styles.divider} />

				<div className={styles.amounts}>
					<MenuList optionsList={menuOptions} isMenuOpen={isOptionsMenuOpen} openMenu={() => setIsOptionsMenuOpen(false)} className={styles.menu}>
						<Button
							dataTestId='usage-product-menu'
							type='outlined'
							color='neutral'
							onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}
							className={styles.menuButtons}
						>
							<Icon imgType='hamburger' color='neutral' height={1.1} />
						</Button>
					</MenuList>
					<span className={styles.mainLabel} data-testid={'usage-product-amount'}>
						{priceNumberWithCurrencySignDisplay(usageProduct.totalUsageAmount || 0, convertCurrencyToSign(appCurrency))}
					</span>
					<span className={styles.neutralSubLabel} data-testid='usage-product-quantity'>{`${numberWithCommas(usageProduct.totalUsageQuantity)} ${
						usageProduct.usageGroupName
					}`}</span>
				</div>
			</div>
		</div>
	);
};
