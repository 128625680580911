import React from 'react';
import { Dispatch } from '@reduxjs/toolkit';
import { httpService, PathsConfig } from '../../../services';
import { PricingModel } from '../../../types/contractTypes';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { images } from '../../../constants/images';
import { setShowLoader } from '../../../storeSlices/generalSlice';
import styles from './CatalogCard.module.scss';

export const onDeletePricingModel = async (dispatch: Dispatch, pricingModel?: PricingModel, onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.onDeletePricingModelTemplate,
			urlParam: { id: pricingModel?.id || '' },
		});

		onRefresh && onRefresh();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.DELETE_PRICING_MODEL }));
	}
};

export const onUpdateFavorite = async (dispatch: Dispatch, pricingModel?: PricingModel, onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.updatePricingModel,
			data: { ...pricingModel, isFavorite: !pricingModel?.isFavorite },
		});

		onRefresh && onRefresh();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const onDuplicatePricingModel = async (dispatch: Dispatch, pricingModel?: PricingModel, onRefresh?: () => void) => {
	try {
		const newModel = (
			await httpService({
				dispatch,
				path: PathsConfig.clonePricingModel,
				urlParam: { id: pricingModel?.id || '' },
				data: { ...pricingModel, name: `copy of ${pricingModel?.name}` },
			})
		).data;

		await httpService({
			dispatch,
			path: PathsConfig.connectModelToProduct,
			data: { pricingModelId: newModel.id, productId: pricingModel?.product?.id },
		});

		onRefresh && onRefresh();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export enum CatalogCardType {
	BLANK = 'blank',
	USAGE = 'usage',
	SUBSCRIPTION = 'subscription',
	LICENSE = 'license',
	PROFESSIONAL = 'professional',
	DEFAULT = 'default',
}
export const getStyleByCardType = (type?: CatalogCardType) => {
	switch (type) {
		case CatalogCardType.BLANK:
			return {
				src: images.blankPricingModelCard,
				topContainerStyle: styles.topBlank,
			};

		case CatalogCardType.DEFAULT:
		case CatalogCardType.USAGE:
		case CatalogCardType.SUBSCRIPTION:
		case CatalogCardType.LICENSE:
		case CatalogCardType.PROFESSIONAL:
			return {
				src: images.productCard,
				topContainerStyle: styles.topRegular,
			};

		default:
			return {
				src: images.productCard,
				topContainerStyle: styles.topRegular,
			};
	}
};
