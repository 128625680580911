import { Currency, DATE_FORMAT, DEFAULT_CURRENCY, sum } from '@received/pricing-model';
import dayjs from 'dayjs';
import { PricingModelTab } from '../../../../types/contractTypes';
import { BillingDocumentType } from '../../../../types/generalTypes';

export interface ContractTimelineDocumentsGroup {
	datesRange: string;
	total: number;
	currency: Currency;
	productsCount: number;
	timelineItems: TimelineDocument[];
}

export interface TimelineDocument {
	document: BillingDocumentType;
	hidden: boolean;
	index: number;
	date: string;
	parentIndex?: number;
}

const divideIntoPeriods = (startDate: Date, endDate: Date) => {
	const result = [];
	const currentStartDate = new Date(startDate);

	while (currentStartDate <= endDate) {
		const currentEndDate = new Date(currentStartDate);
		currentEndDate.setMonth(currentEndDate.getMonth() + 11);

		if (currentEndDate > endDate) {
			currentEndDate.setDate(endDate.getDate());
			currentEndDate.setMonth(endDate.getMonth());
			currentEndDate.setFullYear(endDate.getFullYear());
		}

		result.push({
			startDate: new Date(currentStartDate),
			endDate: new Date(currentEndDate),
		});

		currentStartDate.setMonth(currentStartDate.getMonth() + 12);
	}
	return result;
};

export const formatData = (documentsList: Array<BillingDocumentType>, activationStartDate: Date, activationEndDate: Date) => {
	const groupedDocuments: any = [];

	const periodsDates = divideIntoPeriods(activationStartDate, activationEndDate);
	documentsList = documentsList.map((doc, index) => ({
		...doc,
		index,
	}));

	periodsDates.forEach((period) => {
		const docsWithinPeriod = documentsList.filter((document) => dayjs(document.issueDate).isBetween(period.startDate, period.endDate, 'month', '[]'));

		docsWithinPeriod.forEach((document: BillingDocumentType, documentIndex) => {
			const groupKey = `${dayjs(period.startDate).format(DATE_FORMAT)} - ${dayjs(period.endDate).format(DATE_FORMAT)}`;

			if (!groupedDocuments[groupKey]) {
				groupedDocuments[groupKey] = [];
			}

			const documentItem: TimelineDocument = groupedDocuments[groupKey].find(
				(group: TimelineDocument) => group.date === dayjs(document.issueDate).format(DATE_FORMAT),
			);
			if (!documentItem) {
				groupedDocuments[groupKey].push({
					index: documentIndex,
					hidden: false,
					date: dayjs(document.issueDate).format(DATE_FORMAT),
					document,
				});
			} else {
				groupedDocuments[groupKey].push({
					index: documentIndex,
					hidden: false,
					date: dayjs(document.issueDate).format(DATE_FORMAT),
					document,
					parentIndex: groupedDocuments[groupKey].findIndex(
						(group: TimelineDocument) => group.date === dayjs(document.issueDate).format(DATE_FORMAT),
					),
				});
			}
		});
	});

	const result = Object.entries(groupedDocuments as TimelineDocument).map(([datesRange, timelineItems]) => ({
		datesRange,
		timelineItems,
		currency: timelineItems[0].currency || DEFAULT_CURRENCY,
		total: sum(timelineItems.map((timelineItem: TimelineDocument) => timelineItem.document.total)),
		productsCount: new Set(
			timelineItems.flatMap((item: TimelineDocument) => item.document.pricingModel.tabs)?.map((tab: PricingModelTab) => tab.originPricingModelTabId),
		).size,
	}));
	return result;
};
