import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, EmptyStateCard, MenuOption, Table } from '../../../../components';
import { deleteSubscription, subscriptionsTableHeader } from './SubscriptionsTab.utils';
import { SubscriptionView } from '../../../../types/subscriptionTypes';
import { Sort, SortOrder } from '../../../../types/generalTypes';
import styles from './SubscriptionsTab.module.scss';

interface SubscriptionsTabProps {
	searchText: string;
	openCreateSubscription: boolean;
	selectedSubscriptionId: string;
	subscriptions: SubscriptionView[];
	subscriptionSort: Sort;
	setSubscriptionSort: React.Dispatch<React.SetStateAction<Sort>>;
	setSubscriptions: React.Dispatch<React.SetStateAction<SubscriptionView[]>>;
	setSelectedSubscriptionId: React.Dispatch<React.SetStateAction<string>>;
	setOpenCreateSubscription: React.Dispatch<React.SetStateAction<boolean>>;
	updateSubscriptionList(): void;
}

export const SubscriptionsTab = ({
	searchText,
	openCreateSubscription,
	selectedSubscriptionId,
	subscriptions,
	subscriptionSort,
	setSubscriptionSort,
	setSubscriptions,
	updateSubscriptionList,
	setSelectedSubscriptionId,
	setOpenCreateSubscription,
}: SubscriptionsTabProps) => {
	const { t } = useTranslation('translation');

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const openEditSubscription = (subscriptionId: string) => {
		setSelectedSubscriptionId(subscriptionId);
		setOpenCreateSubscription(true);
	};

	const filterSearch = (subscriptionView: SubscriptionView[]) => {
		const filtered = subscriptionView?.filter((item) => (item.name?.toLowerCase().includes(searchText.toLowerCase()) ? item : false));
		return filtered;
	};

	const subscriptionOptionList = (data: SubscriptionView): MenuOption[] => {
		return [
			{ title: t('editSubscription'), onPress: () => openEditSubscription(data.id), iconType: 'edit' },
			{
				title: t('deleteSubscription'),
				onPress: () => deleteSubscription(data.id, dispatch, updateSubscriptionList),
				iconType: 'delete',
				color: 'destructive',
			},
		];
	};

	return (
		<div className={styles.container}>
			<Table
				sort={subscriptionSort}
				header={subscriptionsTableHeader(t)}
				rows={filterSearch(subscriptions)}
				className={styles.tableContainer}
				hideTableFooter
				tableRowClassName={styles.tableRowClassName}
				onRowPress={(data) => openEditSubscription(data.id)}
				optionsList={(row: SubscriptionView) => subscriptionOptionList(row)}
				onFlipOrder={(accessor) =>
					setSubscriptionSort({ orderBy: accessor, order: subscriptionSort?.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })
				}
				emptyState={
					!searchText ? (
						<EmptyStateCard showIcon imgType='refresh' title={t('noExistingSubscriptions')} subTitle={t('noSubscriptionsCreated')}>
							<Button className={styles.createButton} onClick={() => setOpenCreateSubscription(true)}>
								<span>{t('createSubscription')}</span>
							</Button>
						</EmptyStateCard>
					) : undefined
				}
			/>
		</div>
	);
};
