import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SyncStatus, SyncStatusDto } from '@received/pricing-model';
import { Icon, Tooltip } from '../_uiComponents';
import { getSyncStatus } from '../../utils/IntegrationUtils';
import styles from './CustomerDetails.module.scss';

export interface CustomerDetailsProps {
	logo?: string;
	name?: string;
	country?: string;
	currency?: string;
	ein?: string; //Employer Identification Number (EIN)
	email?: string;
	small?: boolean;
	style?: CSSProperties;
	nameStyle?: string;
	syncStatus?: SyncStatusDto[];
	hideTooltip?: boolean;
	hideLogo?: boolean;
	showArrow?: boolean;
	arrowUp?: boolean;
	className?: string;
	imgClassName?: string;
	hideDetails?: boolean;
	tagText?: string;
}

export const CustomerDetails = ({
	name,
	country = '',
	currency = '',
	ein = '',
	email = '',
	logo = '',
	small,
	style,
	nameStyle,
	syncStatus,
	hideTooltip,
	hideLogo,
	showArrow,
	arrowUp,
	className,
	imgClassName,
	hideDetails,
	tagText,
}: CustomerDetailsProps) => {
	const emails = email?.split(',');
	const syncStatusData = getSyncStatus({ syncStatus });

	return (
		<div className={clsx(styles.customersContainer, className)} data-testid='single-customer-details' style={style}>
			{!hideLogo && (
				<div style={{ flex: 0.1 }}>
					{logo ? (
						<img alt='logo' src={logo} className={clsx(styles.defaultIconStyle, styles.imageIcon, imgClassName, small && styles.imageIconSmall)} />
					) : (
						<div
							className={clsx(
								styles.defaultIconStyle,
								small ? styles.logoContainerSmall : styles.logoContainer,
								imgClassName,
								styles.noAvatarContainer,
							)}
						>
							{name ? name[0].toUpperCase() : ''}
						</div>
					)}
					{syncStatusData?.status === SyncStatus.SYNCED && <img alt='connection-status' src={syncStatusData.icon} className={styles.syncedIcon} />}
				</div>
			)}

			<div style={{ flex: 2 }}>
				{tagText && <div className={styles.partnerTag}>{tagText}</div>}
				<div className={styles.flex}>
					<div className={clsx(nameStyle, small ? styles.nameSmall : styles.name)}>{name}</div>
					{showArrow && (
						<Icon imgType='arrow_down' color={'neutral700'} className={clsx(styles.arrow, arrowUp ? styles.arrowUp : styles.arrowDown)} />
					)}
				</div>

				{!hideDetails && (
					<span className={small ? styles.descriptionSmall : styles.description}>
						{country ? `${country} • ` : ''}
						{currency ? `${currency} • ` : ''}
						{ein ? `${ein} • ` : ''}
					</span>
				)}
				<Tooltip
					tooltipComponent={!hideTooltip && emails.length > 1 && emails.slice(1).join(', ')}
					placement='right'
					containerClassName={styles.emails}
				>
					{emails[0]}
					{emails.length > 1 && ` +${emails.length - 1}`}
				</Tooltip>
			</div>
		</div>
	);
};
