/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { DATE_FORMAT_FOR_SERVER } from '../../../constants/templateConstants';
import { UserDto } from '../../../types/userTypes';
import { Store } from '../../../types/storeTypes';
import { setUser } from '../../../storeSlices/userSlice';
import { Button, DatePicker, Icon, Input } from '../../../components';
import { personalInfoErrors, personalInfoValidation } from './PersonalInfo.utils';
import styles from './PersonalInfo.module.scss';

export const PersonalInfo = () => {
	const { t } = useTranslation('translation');
	const [userInfo, setUserInfo] = useState<UserDto>();
	const [isEditName, setIsEditName] = useState(false);
	const [userInfoErrors, setUserInfoErrors] = useState(personalInfoErrors);

	const currentUser = useSelector((store: Store) => store.user);
	const dispatch = useDispatch();

	useEffect(() => {
		setUserInfo(currentUser);
	}, [currentUser]);

	const updateUser = async (user?: UserDto) => {
		const currentUser = user ? { ...user } : { ...userInfo };
		try {
			const newUser = {
				id: currentUser?.id,
				name: currentUser?.name,
				surName: currentUser?.surName,
				email: currentUser?.email,
				phone:
					currentUser?.phone?.phone && currentUser?.phone?.countryCode
						? { countryCode: currentUser?.phone?.countryCode, phone: currentUser?.phone?.phone }
						: undefined,
				birthday: currentUser?.birthday ? dayjs(currentUser?.birthday).format(DATE_FORMAT_FOR_SERVER) : undefined,
				workAnniversary: currentUser?.workAnniversary ? dayjs(currentUser?.workAnniversary).format(DATE_FORMAT_FOR_SERVER) : undefined,
				iconData: currentUser?.icon || undefined,
				jobTitle: currentUser?.jobTitle,
			};
			await httpService({
				dispatch,
				path: PathsConfig.updateUserPersonalInfo,
				data: { ...newUser },
			});

			// @ts-ignore
			dispatch(setUser(newUser));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onUpdateData = () => {
		const { isValid, errors } = personalInfoValidation(userInfo);
		setUserInfoErrors(errors);
		if (isValid) {
			setIsEditName(false);
			updateUser();
		}
	};

	const onEditForm = (key: string, val: string) => {
		// @ts-ignore
		setUserInfo((prev) => ({ ...prev, [key]: val }));
		setUserInfoErrors(personalInfoErrors);
	};

	const onEditPhone = (key: string, val: string) => {
		const phone = { ...userInfo?.phone, [key]: val };
		setUserInfoErrors(personalInfoErrors);
		// @ts-ignore
		setUserInfo((prev) => ({ ...prev, phone }));
	};

	const onUpdateDate = (key: string, val: Date | null) => {
		const newUser = { ...userInfo, [key]: val };
		setUserInfoErrors(personalInfoErrors);

		// @ts-ignore
		setUserInfo((prev) => ({ ...prev, newUser }));
		// @ts-ignore
		updateUser(newUser);
	};

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.logoContainer}>
					{userInfo?.icon ? (
						<img alt='logo' src={userInfo?.icon} className={styles.logo} />
					) : (
						<>
							{currentUser?.name ? `${currentUser?.name[0].toUpperCase()}${currentUser?.surName ? currentUser?.surName[0].toUpperCase() : ''}` : ''}
						</>
					)}
				</div>

				<div>
					<div className={styles.role}>{userInfo?.role}</div>
					<div
						className={styles.name}
						onClick={() => setIsEditName(true)}
						onKeyDown={(event) => {
							event.key === 'Enter' && onUpdateData();
						}}
					>
						{isEditName ? (
							<div className={styles.editName}>
								<input
									id='firsName'
									onChange={(e) => onEditForm('name', e.target.value)}
									placeholder={t('firstName')}
									autoFocus
									tabIndex={1}
									value={userInfo?.name}
									className={clsx(styles.nameInput, userInfoErrors.nameError && styles.error)}
								/>
								<input
									id='lastName'
									onChange={(e) => onEditForm('surName', e.target.value)}
									placeholder={t('lastName')}
									tabIndex={2}
									value={userInfo?.surName}
									className={clsx(styles.nameInput, userInfoErrors.surNameError && styles.error)}
								/>

								<Button type='link' onClick={() => onUpdateData()}>
									<Icon imgType='v_icon' height={2} className={styles.icon} />
								</Button>
							</div>
						) : (
							<div>
								{userInfo?.name} {userInfo?.surName}
								<Icon imgType='edit' height={2} className={styles.icon} />
							</div>
						)}
					</div>
				</div>
			</div>

			<div className={styles.dataRows}>
				<div className={styles.fontWeight650}>{t('jobTitle')}</div>
				<Input value={userInfo?.jobTitle} onChange={(val) => onEditForm('jobTitle', val)} onBlur={() => onUpdateData()} className={styles.inputs} />
			</div>

			<div className={styles.dataRows}>
				<div className={styles.fontWeight650}>{t('email')}</div>
				<Input
					disabled
					value={userInfo?.email}
					showError={userInfoErrors.emailError}
					onChange={(val) => onEditForm('email', val)}
					onBlur={() => onUpdateData()}
					className={styles.inputs}
				/>
			</div>
			{/* TODO return when server ready */}
			{/* <div className={styles.dataRows}>
				<div className={styles.fontWeight650}>{t('phoneNumberUpperCase')}</div>

				<div className={clsx(styles.phoneRow, styles.inputs)}>
					<Input
						value={userInfo?.phone?.countryCode}
						className={styles.countryCode}
						showError={userInfoErrors.countryCodeError}
						onChange={(val) => onEditPhone('countryCode', val.replace(/\D+/g, ''))}
						onBlur={() => onUpdateData()}
					/>
					<Input
						value={userInfo?.phone?.phone}
						className={styles.phone}
						showError={userInfoErrors.phoneError}
						onChange={(val) => onEditPhone('phone', val.replace(/\D+/g, ''))}
						onBlur={() => onUpdateData()}
					/>
				</div>
			</div> */}

			<div className={styles.dataRows}>
				<div className={styles.fontWeight650}>{t('birthday')}</div>
				<DatePicker
					showReset
					defaultFromDate={userInfo?.birthday}
					updateOnClose={(date) => onUpdateDate('birthday', date.fromDate)}
					className={styles.inputs}
					hideIcon
				/>
			</div>

			<div className={styles.dataRows}>
				<div>
					<div className={styles.fontWeight650}>{t('workAnniversary')}</div>
					<div className={clsx(styles.fontWeight250, styles.fontSize14neutral)}>{t('workAnniversaryDescription')}</div>
				</div>
				<DatePicker
					showReset
					defaultFromDate={userInfo?.workAnniversary}
					updateOnClose={(date) => onUpdateDate('workAnniversary', date.fromDate)}
					className={styles.inputs}
					hideIcon
				/>
			</div>
		</div>
	);
};
