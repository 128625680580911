import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { IntegrationLayout } from '../../Integrations';
import { setSidebarIsOpen } from '../../storeSlices/generalSlice';
import styles from './Integrations.module.scss';

export const Integrations = () => {
	const dispatch = useDispatch();
	const location: any = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		location.state?.integrationType ? dispatch(setSidebarIsOpen(false)) : navigate(-1);
	}, []);

	return (
		<div className={styles.globalPageContainer}>
			<IntegrationLayout
				supplierId={location.state?.supplierId}
				category={location.state?.category}
				integrationType={location.state?.integrationType}
				provider={location.state?.provider}
				connectionStatus={location.state?.connectionStatus}
				connection={location.state?.connection}
			/>
		</div>
	);
};
