import React, { LegacyRef, useEffect, useRef, useState } from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { BillingCycleStruct, BillingCycleUnit } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Button, Icon } from '../..';
import { monthList, years } from '../DatePicker.utils';
import styles from './CustomHeader.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface CustomHeaderProps extends ReactDatePickerCustomHeaderProps {
	customHeaderByBillingCycle?: BillingCycleStruct;
	isRange?: boolean;
	setCurrentMonth(date: Date): void;
}

export const CustomHeader = ({
	monthDate,
	date,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	customHeaderByBillingCycle,
	isRange,
	changeYear,
	decreaseMonth,
	increaseMonth,
	changeMonth,
	setCurrentMonth,
}: CustomHeaderProps) => {
	const [isPickerOpen, setIsPickerOpen] = useState(false);
	const currentSelectedYearRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		currentSelectedYearRef?.current?.scrollIntoView();
	}, [isPickerOpen]);

	useEffect(() => {
		changeYear(monthDate.getFullYear());
		changeMonth(monthDate.getMonth());
	}, [monthDate]);

	const onOpenDatePicker = () => {
		setIsPickerOpen(!isPickerOpen);
	};

	return (
		<div className={styles.container}>
			<div className={styles.rowContainer}>
				<div className={styles.buttonContainer} onClick={onOpenDatePicker} data-testid={`date-picker-custom-header-${isRange ? 'end' : 'start'}`}>
					<div className={styles.monthName}>{dayjs(monthDate).format('MMMM')}</div>

					<div className={styles.yearSelectButton}>
						{customHeaderByBillingCycle?.unit != BillingCycleUnit.YEARLY && date.getFullYear()}
						<Icon imgType={isPickerOpen ? 'arrow_up' : 'arrow_down'} color={isPickerOpen ? 'primary' : 'neutral900'} className={styles.downIcon} />
					</div>
					<div className={isPickerOpen ? styles.yearSelectOpen : styles.yearSelectClose}>
						{customHeaderByBillingCycle?.unit != BillingCycleUnit.YEARLY && (
							<div className={styles.optionList}>
								{years.map((option, index) => {
									const isSelected = date.getFullYear() === option;
									const refProps: { ref?: LegacyRef<HTMLDivElement> } = {};
									if (isSelected) {
										refProps.ref = currentSelectedYearRef;
									}
									return (
										<div
											key={index}
											className={clsx(styles.optionItem, isSelected && styles.selectedOption)}
											onClick={() => {
												const month = dayjs(`1 ${dayjs(monthDate).format('MMMM')} ${option}`, 'D MMMM YYYY').toDate();
												setCurrentMonth(month);
												setIsPickerOpen(false);
											}}
											{...refProps}
										>
											{option}
										</div>
									);
								})}
							</div>
						)}
						<div className={styles.optionList}>
							{monthList.map((option, index) => (
								<div
									key={index}
									className={clsx(styles.optionItem, dayjs(monthDate).format('MMMM') === option && styles.selectedOption)}
									onClick={() => {
										const month = dayjs(`1 ${option} ${date.getFullYear()}`, 'D MMMM YYYY').toDate();
										setCurrentMonth(month);
									}}
								>
									{option}
								</div>
							))}
						</div>
					</div>
				</div>

				<div className={styles.buttonContainer} style={{ justifyContent: 'flex-end' }}>
					<Button
						type='link'
						color='neutral'
						onClick={() => {
							setCurrentMonth(dayjs(monthDate).subtract(1, 'month').toDate());
							decreaseMonth();
						}}
						disabled={prevMonthButtonDisabled}
						dataTestId='previous-arrow-button'
					>
						<Icon imgType='arrow_left' className={styles.arrowIcons} />
					</Button>

					<Button
						type='link'
						color='neutral'
						onClick={() => {
							setCurrentMonth(dayjs(monthDate).add(1, 'month').toDate());
							increaseMonth();
						}}
						dataTestId='next-arrow-button'
						disabled={nextMonthButtonDisabled}
					>
						<Icon imgType='arrow_right' className={styles.arrowIcons} />
					</Button>
				</div>
			</div>
		</div>
	);
};
