import { Dispatch } from '@reduxjs/toolkit';
import { GeoLocationOptionsData } from '../../../types/generalTypes';
import { countryIsUSA, CSVToArray } from '../../../utils/GeneralUtils';
import { getFormattedLabel } from '../../../components/CSVDataMatcherModal/CSVDataMatcherModal.utils';
import { isValidEmail } from '../../../utils/validationUtils';
import { IndexInCustomerCSVFile } from '../UploadCSVMatcher.utils';
import { Customer, CustomersDetails } from '../../../types/customerTypes';
import { getExistsCustomerList } from '../../../utils/CustomerUtils';

export enum RowStatus {
	NEW = 'New',
	UNCHANGED = 'Unchanged',
	UPDATE = 'Update',
	ERROR = 'Error',
}

export enum customerColumnsOptions {
	LOGO = 'Customer logo',
	NAME = 'Customer name',
	LEGAL_NAME = 'Legal name',
	ENTITY = 'Receiving Entity',
	EIN = 'EIN',
	EMAIL = 'Email',
	COUNTRY = 'Country',
	STATE = 'State',
	CITY = 'City',
	BILLING_ADDRESS = 'Billing Address',
	TAX = 'Tax',
	REMOTE_ID = 'Remote ID',
}

export const importCustomerColumns = [
	'state',
	customerColumnsOptions.LOGO,
	customerColumnsOptions.NAME,
	customerColumnsOptions.LEGAL_NAME,
	customerColumnsOptions.ENTITY,
	customerColumnsOptions.EIN,
	customerColumnsOptions.EMAIL,
	customerColumnsOptions.COUNTRY,
	customerColumnsOptions.STATE,
	customerColumnsOptions.CITY,
	customerColumnsOptions.BILLING_ADDRESS,
	customerColumnsOptions.TAX,
	customerColumnsOptions.REMOTE_ID,
];

export const customerCsvDataHandler = async (
	dispatch: Dispatch,
	csvString: string,
	suppliersList: Customer[],
	geoLocationData: GeoLocationOptionsData,
) => {
	let header: string[] = [];
	let originalHeader: string[] = [];
	let rows = [];
	const rowsWithOldValues: any[] = [];
	const originalRows: any[] = [];

	const csvData = CSVToArray(csvString);
	let existedCustomers: CustomersDetails = { customers: [], names: [], remoteIds: [] };

	if (csvData.length) {
		originalHeader = csvData[0];
		header = customerHeaderAdapter(originalHeader);

		const customerNameIndex = originalHeader.findIndex(
			(name) =>
				name.toLocaleLowerCase().includes('name') || name.toLocaleLowerCase().includes('customer') || name.toLocaleLowerCase().includes('account'),
		);

		const customerRemoteIdIndex = originalHeader.findIndex(
			(name) =>
				name.toLocaleLowerCase().includes('remote') || name.toLocaleLowerCase().includes('id') || name.toLocaleLowerCase().includes('integration'),
		);

		const names = Array.from(new Set(csvData.slice(1).map((row) => row[customerNameIndex])));
		const remoteIds = Array.from(new Set(csvData.slice(1).map((row) => row[customerRemoteIdIndex]))); //last value in each row
		existedCustomers = await getExistsCustomerList(dispatch, names, remoteIds);

		rows = csvData.slice(1).map((row: any, rowIndex: number) => {
			const originalRow: any = {};
			let rowWithOldValues = null;
			const newRow: any = { [IndexInCustomerCSVFile.ROW_STATUS]: RowStatus.NEW, [IndexInCustomerCSVFile.ICON_DATA]: '' };

			row.forEach((item: string, index: number) => (originalRow[originalHeader[index]] = item));

			const customer = existedCustomers.customers.find(
				(customer) => customer.name.toLocaleLowerCase() == originalRow?.[header[IndexInCustomerCSVFile.NAME]]?.toLocaleLowerCase(),
			);

			if (customer) {
				rowWithOldValues = {
					[IndexInCustomerCSVFile.ROW_STATUS]: RowStatus.UPDATE,
					[IndexInCustomerCSVFile.ICON_DATA]: customer.iconData || '',
					[IndexInCustomerCSVFile.NAME]: customer.name,
					[IndexInCustomerCSVFile.LEGAL_NAME]: customer.legalName,
					[IndexInCustomerCSVFile.ENTITY]: customer?.supplier ? { id: customer?.supplier?.id, name: customer?.supplier?.name } : null,
					[IndexInCustomerCSVFile.EIN]: customer.ein,
					[IndexInCustomerCSVFile.EMAIL]: customer.email,
					[IndexInCustomerCSVFile.COUNTRY]: customer.country,
					[IndexInCustomerCSVFile.COUNTRY_STATE]: customer.countryState,
					[IndexInCustomerCSVFile.CITY]: customer.city,
					[IndexInCustomerCSVFile.BILLING_ADDRESS]: customer.billingAddress,
					[IndexInCustomerCSVFile.TAX]: customer.tax?.toString(),
					[IndexInCustomerCSVFile.REMOTE_ID]: customer.remoteId || '',
				};

				rowsWithOldValues.push(rowWithOldValues);
			}

			header.forEach((item, index) => {
				switch (index) {
					case IndexInCustomerCSVFile.ENTITY: {
						const supplier = findCSVEntity(originalRow[item], suppliersList);
						return (newRow[index] = suppliersList.length ? supplier : undefined);
					}
					case IndexInCustomerCSVFile.COUNTRY:
						return (newRow[index] = getFormattedLabel(originalRow[item], geoLocationData.countries));
					case IndexInCustomerCSVFile.COUNTRY_STATE: {
						return (newRow[index] = getFormattedLabel(originalRow[item], geoLocationData.usaStates));
					}
					case IndexInCustomerCSVFile.ICON_DATA: {
						return (newRow[index] = customer?.iconData);
					}

					default:
						return (newRow[index] = originalRow[item] || '');
				}
			});

			originalRows.push(originalRow);
			newRow[IndexInCustomerCSVFile.ROW_STATUS] = getRowStatus(newRow, rowWithOldValues);

			return newRow;
		});
	}
	return { header, rows, originalRows, rowsWithOldValues, originalHeader, existedCustomers };
};

export const getRowStatus = (row: any, oldValuesRow?: any) => {
	if (oldValuesRow) {
		let unchangedCustomer = true;
		row[IndexInCustomerCSVFile.NAME] = row[IndexInCustomerCSVFile.NAME].trim();
		oldValuesRow[IndexInCustomerCSVFile.NAME] = oldValuesRow[IndexInCustomerCSVFile.NAME].trim();
		for (let index = 2; index < Object.keys(row).length; index++) {
			if (row[index]?.toString() !== oldValuesRow[index]?.toString()) {
				unchangedCustomer = false;
			}
		}

		return unchangedCustomer ? RowStatus.UNCHANGED : RowStatus.UPDATE;
	}
	return RowStatus.NEW;
};

export const findCSVEntity = (item: string, entityList: Customer[]) => {
	const customer = entityList.find((customer) =>
		item ? customer.name.toLowerCase() == item?.toLowerCase().replace('\n', '').trim() || customer.id === item : customer.isDefault,
	);

	return { name: customer?.name || item, id: customer?.id || '' };
};

const customerHeaderAdapter = (originalHeader: string[]) => {
	const header: string[] = [...importCustomerColumns];

	const customerName = originalHeader.find(
		(name) =>
			name.toLocaleLowerCase().includes('name') || name.toLocaleLowerCase().includes('customer') || name.toLocaleLowerCase().includes('account'),
	);
	if (customerName) {
		header[IndexInCustomerCSVFile.NAME] = customerName;
	}
	originalHeader.forEach((headerItem) => {
		if (headerItem.toLocaleLowerCase().includes('legal') || headerItem.toLocaleLowerCase().includes('official')) {
			header[IndexInCustomerCSVFile.LEGAL_NAME] = headerItem;
		}

		if (headerItem.toLocaleLowerCase().includes('entity') || headerItem.toLocaleLowerCase().includes('supplier')) {
			header[IndexInCustomerCSVFile.ENTITY] = headerItem;
		}
		if (headerItem.toLocaleLowerCase().includes('ein')) {
			header[IndexInCustomerCSVFile.EIN] = headerItem;
		}
		if (
			headerItem.toLocaleLowerCase().includes('email') ||
			headerItem.toLocaleLowerCase().includes('mail') ||
			headerItem.toLocaleLowerCase().includes('contact')
		) {
			header[IndexInCustomerCSVFile.EMAIL] = headerItem;
		}
		if (headerItem.toLocaleLowerCase().includes('country')) {
			header[IndexInCustomerCSVFile.COUNTRY] = headerItem;
		}
		if (headerItem.toLocaleLowerCase().includes('state')) {
			header[IndexInCustomerCSVFile.COUNTRY_STATE] = headerItem;
		}
		if (headerItem.toLocaleLowerCase().includes('city')) {
			header[IndexInCustomerCSVFile.CITY] = headerItem;
		}
		if (headerItem.toLocaleLowerCase().includes('address')) {
			header[IndexInCustomerCSVFile.BILLING_ADDRESS] = headerItem;
		}
		if (headerItem.toLocaleLowerCase().includes('tax') || headerItem.toLocaleLowerCase().includes('vat')) {
			header[IndexInCustomerCSVFile.TAX] = headerItem;
		}
		if (
			headerItem.toLocaleLowerCase().includes('remote') ||
			headerItem.toLocaleLowerCase().includes('id') ||
			headerItem.toLocaleLowerCase().includes('integration')
		) {
			header[IndexInCustomerCSVFile.REMOTE_ID] = headerItem;
		}
	});

	return header;
};

export const validateCustomerInput = (
	row: any,
	input: any,
	columnIndex: number,
	suppliersList: Customer[],
	geoLocationData: GeoLocationOptionsData = { countries: [], usaStates: [] },
	existedDataFromServer?: CustomersDetails,
	duplicateDataInTable?: CustomersDetails,
) => {
	let isValid = true;
	switch (columnIndex) {
		case IndexInCustomerCSVFile.ICON_DATA: {
			// logo
			return isValid;
		}
		case IndexInCustomerCSVFile.NAME: {
			// name
			let duplicateNamesExist = false;

			if (input) {
				if (duplicateDataInTable?.names?.length) {
					duplicateNamesExist = duplicateDataInTable?.names.some((name) => name.trim() === input.trim());
				}

				isValid = !duplicateNamesExist;
			} else {
				isValid = false;
			}
			return isValid;
		}
		case IndexInCustomerCSVFile.LEGAL_NAME: {
			// legal name
			isValid = !!input;
			return isValid;
		}
		case IndexInCustomerCSVFile.ENTITY: {
			// entity
			if (suppliersList.length) {
				const customer = suppliersList.find((custom) => custom?.name.toLowerCase() === input?.name?.toLowerCase() || custom.id === input?.id);

				isValid = !!customer;
			}
			return isValid;
		}
		case IndexInCustomerCSVFile.EIN: {
			// ein
			return isValid;
		}
		case IndexInCustomerCSVFile.EMAIL: {
			// email
			if (input) {
				input
					.replace(/\s/g, '')
					.split(',')
					.forEach((email: string) => {
						if (email && !isValidEmail(email)) {
							isValid = false;
						}
					});
			} else {
				isValid = false;
			}
			return isValid;
		}
		case IndexInCustomerCSVFile.COUNTRY: {
			//country
			return !!geoLocationData.countries.find((country) => input?.includes(country.value));
		}
		case IndexInCustomerCSVFile.COUNTRY_STATE: {
			// state
			if (input) {
				isValid = !!geoLocationData.usaStates.find((state) => input?.includes(state.value)) && countryIsUSA(row[columnIndex - 1]);
			}
			return isValid;
		}
		case IndexInCustomerCSVFile.CITY: {
			// city
			return isValid;
		}
		case IndexInCustomerCSVFile.BILLING_ADDRESS: {
			// billing address
			return isValid;
		}
		case IndexInCustomerCSVFile.TAX: {
			// tax
			const currentInput = input?.replace('%', '');
			isValid = currentInput ? !isNaN(currentInput) && Number(currentInput) >= 0 && Number(currentInput) <= 100 : true;
			return isValid;
		}
		case IndexInCustomerCSVFile.REMOTE_ID: {
			// Integration ID
			let duplicateRemoteExist = false,
				uniqueRemoteIdsExist = false;

			if (input) {
				if (duplicateDataInTable?.remoteIds?.length) {
					duplicateRemoteExist = duplicateDataInTable?.remoteIds.some((remoteId) => remoteId === input);
				}
				if (existedDataFromServer?.remoteIds?.length) {
					uniqueRemoteIdsExist = existedDataFromServer?.remoteIds.some((remoteId) => remoteId === input);

					const customerByRemoteId = existedDataFromServer?.customers.find((customer) => customer.remoteId === input);
					if (customerByRemoteId) {
						uniqueRemoteIdsExist = row[IndexInCustomerCSVFile.NAME] !== customerByRemoteId.name;
					}
				}
				isValid = !duplicateRemoteExist && !uniqueRemoteIdsExist;
			} else {
				isValid = true;
			}
			return isValid;
		}
	}
	return isValid;
};
