/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AccountingEventType, NetTerms, PaymentTerms, WorkDays, Countries } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { FilterTimeOptions, filtersSelectLabels } from '../components/Filters/DateRangeFilters.utils';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { initialStackBarGraphNumberFormat } from '../components/_uiComponents/Graphs/StackBarGraph/StackBarGraph.utils';
import { DEFAULT_COUNTRY, DEFAULT_COUNTRY_PREFIX, REVENUE_RECOGNITION_LINK } from '../constants/generalConstants';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { setGeoLocationOptionsData } from '../storeSlices/generalSlice';
import { CreditNote, CreditNoteView } from '../types/creditNoteTypes';
import { BillingDocumentType, BillingDocumentViewType, DoughnutGraphState } from '../types/generalTypes';
import { Invoice } from '../types/invoiceTypes';
import { ColorSummaryBox, UsageReportView } from '../types/usageTypes';
import { getCreditNoteIdName } from './CreditNoteUtils';
import { getDebitIdName } from './DebitUtils';
import { getInvoiceIdName } from './InvoiceUtils';
import { isValidEmail } from './validationUtils';
import { DUE_UPON_RECEIPT_LABEL, DUE_UPON_USAGE_LABEL, netTermsSelect } from '../components/NetTermsSelect/NetTermsSelect.utils';
import { StripeCard } from '../types/integrationTypes';
import { CreditCardBrand } from '../components/_uiComponents/Select/StripeCardSelect/StripeCardSelect.utils';
import { IconImgType } from '../components';
import { colors } from '../constants/colors';

export enum EditDocumentNumberState {
	WARNING = 'WARNING',
	ERROR = 'ERROR',
}

export const getGeoLocationOptionsData = async (dispatch: Dispatch) => {
	try {
		const workDaysObj = Object.keys(WorkDays);
		const countriesWithPrefix = Countries.filter((item: any) => workDaysObj.includes(item.label));
		const constCountries = workDaysObj.map((item) => ({ label: item, value: item, prefix: item.slice(0, 2) }));

		dispatch(setGeoLocationOptionsData({ countries: countriesWithPrefix.length ? countriesWithPrefix : constCountries }));
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FETCH_COUNTRIES }));
	}
};

export const createCSVFile = (csvContent: string, documentName: string) => {
	const encodedUri = encodeURI(csvContent);
	const link = document.createElement('a');
	link.setAttribute('href', encodedUri);
	link.setAttribute('download', `${documentName}.csv`);
	document.body.appendChild(link);
	link.click();
};

export const CSVToArray = (csvString: string, csvSeparator?: string) => {
	csvSeparator = csvSeparator || ',';

	const objPattern = new RegExp(
		// Delimiters.
		'(\\' +
			csvSeparator +
			'|\\r?\\n|\\r|^)' +
			// Quoted fields.
			'(?:"([^"]*(?:""[^"]*)*)"|' +
			// Standard fields.
			'([^"\\' +
			csvSeparator +
			'\\r\\n]*))',
		'gi',
	);
	const arrData: string[][] = [[]];

	let arrMatches = null;

	while ((arrMatches = objPattern.exec(csvString))) {
		const strMatchedDelimiter = arrMatches[1];

		if (strMatchedDelimiter.length && strMatchedDelimiter !== csvSeparator) {
			arrData.push([]);
		}
		let strMatchedValue;

		if (arrMatches[2]) {
			strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
		} else {
			strMatchedValue = arrMatches[3];
		}
		// @ts-ignore
		arrData[arrData.length - 1].push(strMatchedValue);
	}
	if (arrData.length) {
		arrData[0] = arrData[0].map((item: string) => item.trim());
	}

	return arrData;
};

export const getNetTermLabel = (netTerm: NetTerms) => {
	if (netTerm > 0) {
		return `${'Net'} ${netTerm}`;
	}
	return netTermsSelect().find((element) => element.value === netTerm)?.label || '';
};

export const countryIsUSA = (input?: string) => {
	const country = input?.replace(/\s/g, '').toLowerCase();

	return (
		country == DEFAULT_COUNTRY.replace(/\s/g, '').toLowerCase() ||
		country == `${DEFAULT_COUNTRY_PREFIX} - ${DEFAULT_COUNTRY}`.replace(/\s/g, '').toLowerCase() ||
		country == DEFAULT_COUNTRY_PREFIX.replace(/\s/g, '').toLowerCase()
	);
};

export const splitArray = (arr: any[], size: number) => {
	if (size === 0) {
		return [];
	}

	return arr.reduce((split, element, index) => {
		index % size === 0 ? split.push([element]) : split[Math.floor(index / size)].push(element);
		return split;
	}, []);
};

export const downloadFile = (file: any, name?: string) => {
	const a = document.createElement('a');
	a.href = URL.createObjectURL(file.data);

	const fileName = name || file.headers['content-disposition'].replaceAll('"', '').split('filename=')[1];
	a.setAttribute('download', fileName);
	document.body.appendChild(a);

	a.click();
};

export const normalizeDataToCSVRows = (titles: string[], data: any) => {
	const headers = Object.values(titles);
	const rows = data.map((item: any) => headers.map((header) => item[header]));
	const headersRow = headers.join(',');
	const csvRows = [headersRow, ...rows.map((row: any) => row.join(','))];

	return csvRows.join('\n');
};

export const getDocumentIdNameByType = (document?: BillingDocumentType | BillingDocumentViewType) => {
	switch (document?.accountingEventType) {
		case AccountingEventType.INVOICE:
			return getInvoiceIdName(document as Invoice | UsageReportView);
		case AccountingEventType.CREDIT:
		case AccountingEventType.PAYOUT:
			return getCreditNoteIdName(document as CreditNote | CreditNoteView | UsageReportView);
		case AccountingEventType.DEBIT:
			return getDebitIdName(document as Invoice | UsageReportView);

		default:
			return '';
	}
};

export const getEmailsLabel = (emailList?: string) => {
	const emails = emailList?.split(',') || [];
	return emails.length > 1 ? ` ${emails[0]} +${emails.length - 1}` : emails[0];
};

export const getDocumentNumberPrefix = (document?: BillingDocumentType, isEditInvoiceNumber?: boolean) => {
	if (document?.documentNumber) {
		return '';
	}
	if (document?.draftNumber) {
		// @ts-ignore
		return isEditInvoiceNumber ? '' : document?.customNumber ? '' : 'DRAFT';
	}
	return '';
};

export const initialDoughnutBarGraphData = [
	{
		value: 0,
		label: '',
		backgroundColor: '',
		hoverBorderColor: '',
	},
];

export const initialDoughnutGraphState: DoughnutGraphState = {
	isEmpty: false,
	data: initialDoughnutBarGraphData,
	dataFormat: initialStackBarGraphNumberFormat,
};

export const onClickUpgradeRevRecFeature = () => window.open(REVENUE_RECOGNITION_LINK);

export const timePeriodNext12MonthsInitialState = {
	from: dayjs().startOf('month').format(DATE_FORMAT_FOR_SERVER),
	to: dayjs().add(11, 'month').endOf('month').format(DATE_FORMAT_FOR_SERVER),
	title: filtersSelectLabels.UPCOMING_12_MONTHS,
	value: FilterTimeOptions.UPCOMING_12_MONTHS,
};

export const getUniqueListValues = (list: any[], uniqueBy: string) => {
	return Array.from(new Map(list.map((item) => [item[uniqueBy], item])).values());
};

export const splitEmailsWithCommasString = (emailString: string) => {
	const validEmails: string[] = [];
	const invalidEmails: string[] = [];
	const emails = emailString.replaceAll(/\s/g, '').split(',');
	emails.forEach((email: string) => {
		if (isValidEmail(email)) {
			validEmails.push(email);
		} else {
			invalidEmails.push(email);
		}
	});
	return { validEmails, invalidEmails };
};

export const truncateString = (str: string, endCharCount = 4, dotCount = 4) => {
	const maxStartCharCount = 5;
	if (str.length <= 8) {
		return str; // No truncation needed
	}

	const startCharCount = Math.min(maxStartCharCount, str.length);
	const firstPortion = str.slice(0, startCharCount);
	const endPortion = str.slice(-endCharCount);
	const dots = '.'.repeat(dotCount);

	return `${firstPortion}${dots}${endPortion}`;
};

export const netTermsToLabel = (net = PaymentTerms.DUE_UPON_RECEIPT) => {
	switch (net) {
		case PaymentTerms.DUE_UPON_RECEIPT:
			return DUE_UPON_RECEIPT_LABEL;
		case PaymentTerms.DUE_UPON_USAGE:
			return DUE_UPON_USAGE_LABEL;
		default:
			return `Net ${net}`;
	}
};

export const creditCardLabel = (item?: StripeCard, displayCustomerNameOnly?: boolean) => {
	return displayCustomerNameOnly ? item?.name : `${item?.name} ${item?.hasDefaultCard ? `| •••• ${item.lastDigits}` : ''}`;
};

export const getCreditCardBrandLogo = (brand?: CreditCardBrand): IconImgType => {
	switch (brand?.toLocaleUpperCase()) {
		case CreditCardBrand.AMEX:
		case CreditCardBrand.AMERICAN_EXPRESS:
			return 'amex';
		case CreditCardBrand.DINERS:
			return 'diners';
		case CreditCardBrand.DISCOVER:
			return 'discover';
		case CreditCardBrand.UNIONPAY:
			return 'unionpay';
		case CreditCardBrand.MASTERCARD:
			return 'mastercard';
		case CreditCardBrand.VISA:
			return 'visa';
		default:
			return 'payment_link';
	}
};

export const productsColorsList: ColorSummaryBox[] = [
	{ colorIcon: 'primary', backgroundColorIcon: colors.primary100, textColor: colors.primary },
	{ colorIcon: 'success', backgroundColorIcon: colors.success100, textColor: colors.success },
	{ colorIcon: 'calming', backgroundColorIcon: colors.calming100, textColor: colors.calming },
	{ colorIcon: 'destructive400', backgroundColorIcon: colors.destructive100, textColor: colors.destructive400 },
	{ colorIcon: 'orange', backgroundColorIcon: colors.orange100, textColor: colors.orange },
	{ colorIcon: 'blueSky', backgroundColorIcon: colors.blueSky100, textColor: colors.blueSky },
	{ colorIcon: 'eveningStar', backgroundColorIcon: colors.eveningStar100, textColor: colors.eveningStar },
	{ colorIcon: 'pinkDelight', backgroundColorIcon: colors.pinkDelight100, textColor: colors.pinkDelight },
	{ colorIcon: 'oceanInBowl', backgroundColorIcon: colors.oceanInBowl100, textColor: colors.oceanInBowl },
];
