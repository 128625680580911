import clsx from 'clsx';
import { Button, ButtonStatusColor, ButtonTypes, Icon, Modal } from '..';
import styles from './ActionModal.module.scss';

interface ModalButton {
	title: string;
	type?: ButtonTypes;
	color?: ButtonStatusColor;
	className?: string;
	onClick: () => void;
}

interface ActionModalProps {
	isOpen: boolean;
	title: string;
	description: React.ReactNode;
	imgSrc: string;
	mainButton?: ModalButton;
	secondaryButton?: ModalButton;
	className?: string;
	titleClassName?: string;
	onClose: () => void;
}

export const ActionModal = ({
	isOpen,
	title,
	imgSrc,
	description,
	mainButton,
	secondaryButton,
	className,
	titleClassName,
	onClose,
}: ActionModalProps) => {
	return (
		<Modal isOpen={isOpen} closeModal={onClose} className={clsx(styles.container, className)}>
			<div className={styles.closeButton}>
				<Button type='link' color='neutral' onClick={onClose}>
					<Icon imgType='x_icon' color='neutral700' className={styles.x_icon} />
				</Button>
			</div>

			<div className={styles.modalBody}>
				<div>
					<img src={imgSrc} className={styles.image} />
					<div className={styles.texts}>
						<h1 className={clsx(styles.title, titleClassName)}>{title}</h1>

						<div className={styles.messageContainer}>
							<div className={styles.message}>{description}</div>
						</div>
					</div>
				</div>

				<div className={styles.buttonsContainer}>
					{secondaryButton && (
						<Button
							type={secondaryButton?.type}
							color={secondaryButton?.color}
							onClick={secondaryButton.onClick || onClose}
							className={clsx(styles.button, secondaryButton?.className)}
						>
							{secondaryButton?.title}
						</Button>
					)}
					{mainButton && (
						<Button
							type={mainButton?.type}
							color={mainButton?.color}
							onClick={mainButton?.onClick}
							className={clsx(styles.button, mainButton?.className)}
						>
							{mainButton?.title}
						</Button>
					)}
				</div>
			</div>
		</Modal>
	);
};
