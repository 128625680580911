import clsx from 'clsx';
import { CSSProperties } from 'react';
import { ButtonStatusColor, ButtonTypes } from '../Button/Button';
import buttonStyles from '../Button/Button.module.scss';
import badgeStyles from './Badge.module.scss';

interface BadgeProps {
	color?: ButtonStatusColor;
	type?: ButtonTypes;
	children?: React.ReactNode;
	className?: string;
	style?: CSSProperties;
	dataTestId?: string;
}

export const Badge = ({ color = 'primary', type = 'primary', style, className, dataTestId = 'main-badge', children }: BadgeProps) => {
	return (
		<div
			className={clsx(buttonStyles.generalButton, buttonStyles[`${color}${type}Background`], badgeStyles.generalBadge, className)}
			data-testid={dataTestId}
			style={style}
		>
			{children}
		</div>
	);
};
