import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { PricingModel } from '../../types/contractTypes';
import { Icon } from '..';
import styles from './PricingList.module.scss';

interface PricingListProps {
	list?: PricingModel[];
	selectedModel?: PricingModel;
	customHeader?: React.ReactNode;
	className?: string;
	listContainerClassName?: string;
	selectPricing(pricingModel: PricingModel): void;
}

export const PricingList = ({ list, selectedModel, customHeader, className, listContainerClassName, selectPricing }: PricingListProps) => {
	const { t } = useTranslation('translation');
	const [selectedPricing, setSelectedPricing] = useState<PricingModel>();

	useEffect(() => {
		setSelectedPricing(selectedModel);
	}, [selectedModel]);

	const onSelectPricing = (pricing: PricingModel) => {
		setSelectedPricing(pricing);
		selectPricing(pricing);
	};

	return (
		<div className={clsx(styles.container, className)}>
			{customHeader}
			<div className={styles.header}>
				<div className={styles.pricingNameTitle}>{t('pricingName')}</div>
				<div>{t('category')}</div>
			</div>

			<div className={clsx(styles.listContainer, listContainerClassName)}>
				{list?.map((item) => (
					<div
						key={item.id}
						className={clsx(styles.listItem, selectedPricing?.id == item.id && styles.listItemSelected)}
						onClick={() => onSelectPricing(item)}
						data-testid={`table-item-${item.name}`}
					>
						<div className={styles.itemTitle}>
							<div className={styles.itemIconContainer}>
								<Icon imgType={item.iconType || 'subscription_template'} height={2} />
							</div>
							<div>{item.name}</div>
						</div>

						<div className={styles.itemTag}>{item.categoryName}</div>
					</div>
				))}
			</div>
		</div>
	);
};
