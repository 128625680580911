import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from '../../Button/Button';
import { NestedListItem } from '../NestedArrayItem/NestedListItem';
import { NestedListHeader } from '../..';
import styles from './NestedItem.module.scss';

export interface NestedArrayItem {
	name: string;
	id: string;
	displayOptionMenu?: boolean;
	onDuplicateProduct?: (id: string) => void;
	onRenameProduct?: (val: string) => void;
	onDeleteProduct?: (id: string) => void;
	[key: string]: any;
}

export interface NestedItem {
	header: NestedListHeader;
	listArray: NestedArrayItem[];
	style?: CSSProperties;
	className?: string;
	canAddNewItems?: boolean;
	displayOptionMenu?: boolean;
	totalCount: number;
	onSelectElement(selectedItem: string, selectedHeaderId: string): void;
	onHeaderClick(header: NestedListHeader): void;
	onClickAddItem?(): void;
	onDuplicate?: (itemId: string) => void;
	onRemove?: (itemId: string) => void;
	onRename?: (name: string) => void;
}

export interface NestedItemProps extends NestedItem {
	index: number;
	selectedItem: string;
	selectedHeader: string;
	handleHeaderClick(selectedHeader: string): void;
}

export const NestedItem = ({
	index,
	header,
	listArray = [],
	selectedHeader,
	selectedItem,
	style,
	className,
	canAddNewItems,
	totalCount,
	onHeaderClick,
	onSelectElement,
	handleHeaderClick,
	onClickAddItem,
	...props
}: NestedItemProps) => {
	const { t } = useTranslation('translation');

	const onAddNewItem = (e: Event) => {
		e.stopPropagation();
		onClickAddItem && onClickAddItem();
	};

	const onHandleHeaderClick = () => {
		onHeaderClick(header);
		handleHeaderClick(header.id);
	};

	return (
		<div className={className} data-testid='nested-list' style={style}>
			<div className={clsx(styles.header)}>
				<div className={styles.headerWrapper} data-testid='nested-list-header'>
					{header.title}
				</div>
				{canAddNewItems && (
					<Button dataTestId='add-new-product' color='neutral' type='outlined' className={styles.addNewItems} onClick={onAddNewItem}>
						<div className={styles.addNewItemsText}>{t('New')} +</div>
					</Button>
				)}
			</div>

			<NestedListItem
				{...props}
				dataTestId={`nested-list-header-${header.mainName}`}
				item={{ name: header.mainName, id: header.id }}
				selectedItem={selectedItem == header.id}
				onSelect={onHandleHeaderClick}
				count={totalCount}
			/>
			{listArray.map((item, index) => (
				<NestedListItem
					{...props}
					key={index}
					item={item}
					selectedItem={selectedItem == item.id}
					onSelect={(itemId: string) => onSelectElement(itemId, header.id)}
					count={item?.pricingModels.length}
				/>
			))}
		</div>
	);
};
