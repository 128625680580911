import { DocumentState } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { CreditNote, CreditNoteView } from '../../../types/creditNoteTypes';
import { PaidPeriod } from '../../InvoiceTable/InvoiceTable.utils';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { MenuOption } from '../../_uiComponents/MenuList/MenuList';

export const changeCreditNoteStatus = (
	t: any,
	item: CreditNoteView | CreditNote,
	onMark: (period: PaidPeriod) => void,
	dispatch: Dispatch,
	refreshData?: () => void,
): MenuOption[] => {
	let listOptions: MenuOption[] = [];

	if (item.state === DocumentState.REVIEW_READY_TO_SEND || item.state === DocumentState.DRAFT) {
		listOptions = [
			{
				title: t('sendDocument'),
				iconType: 'paper_airplane',
				color: 'primary',
				subList: [
					{
						title: t('sendNow'),
						onPress: () => item.id && sendCreditNote(item.id, dispatch, refreshData),
						iconType: 'paper_airplane',
					},
					{
						title: t('markAsSent'),
						onPress: () => onMark(PaidPeriod.CUSTOM),
						iconType: 'letter',
					},
				],
			},
			{
				title: t('markAsPaid'),
				iconType: 'v_circle',
				color: 'success',
				subList: [
					{
						title: t('pickADate'),
						onPress: () => onMark(PaidPeriod.CUSTOM),
						iconType: 'date',
					},
					{
						title: t('paidToday'),
						onPress: () => onMark(PaidPeriod.TODAY),
						iconType: 'clock',
					},
				],
			},
		];
	} else if (item.state === DocumentState.PAID || item.state === DocumentState.SENT_FAILED) {
		listOptions = [
			{
				title: t('resendDocument'),
				iconType: 'refresh',
				color: 'neutral',
				onPress: () => item.id && sendCreditNote(item.id, dispatch),
			},
			...listOptions,
		];
	}
	listOptions = [
		...listOptions,
		{
			title: t('cancelDocument'),
			onPress: () => item.id && cancelCreditNote(item?.id, dispatch, refreshData),
			iconType: 'error_icon',
			color: 'destructive',
		},
	];
	return listOptions;
};

const sendCreditNote = async (creditNoteId: string, dispatch: Dispatch, onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.sendCreditNote,
			urlParam: { id: creditNoteId },
		});

		onRefresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.SEND_CREDIT }));
	}
};

export const cancelCreditNote = async (id: string, dispatch: Dispatch, refresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.cancelCreditNote,
			urlParam: { id },
		});

		dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.CREDIT_STATUS_CANCELED }));
		refresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
