import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { Icon } from '../Icon';
import { Input, InputProps } from '../Input/Input';
import styles from './MultipleTagsInput.module.scss';

export interface MultipleTagsInputProps extends InputProps<HTMLInputElement> {
	value: string;
	regex: RegExp;
	customTitle?: ReactNode;
	width: number;
	maxEmailCount?: number;
	tagsContainerClassName?: string;
	validateInput: (value: string) => boolean;
	onChange: (validValue: string) => void;
}

export const MultipleTagsInput = ({
	width,
	customTitle,
	value,
	regex,
	showError,
	maxEmailCount = 10,
	tagsContainerClassName,
	validateInput,
	onChange,
	...props
}: MultipleTagsInputProps) => {
	const { t } = useTranslation('translation');
	const [tagsList, setTagsList] = useState<string[]>([]);
	const [input, setInput] = useState('');
	const [error, setError] = useState(false);

	useEffect(() => {
		value && setTagsList(value.split(','));
	}, [value]);

	const handleKeyDown = (event: any) => {
		if (['Enter', ','].includes(event.key)) {
			handleBlur(event);
		}
	};

	const handleBlur = (event: any) => {
		event.preventDefault();
		if (event.target.value) {
			const trimmedInput = event.target.value.trim();

			if (validate(trimmedInput)) {
				setTagsList([...tagsList, trimmedInput]);
				onChange([...tagsList, trimmedInput].join(','));
				setInput('');
			}
		}
	};

	const validate = (value: string) => {
		const error = tagsList.includes(value) || !validateInput(value) || tagsList.length >= maxEmailCount;
		setError(error);
		return !error;
	};

	const handlePaste = (event: any) => {
		event.preventDefault();
		handleChange('');
		const validItems: string[] = [];
		const invalidItems: string[] = [];

		const paste = event.clipboardData.getData('text');
		const splicedItems = paste.replaceAll(/\s/g, '').split(',');
		splicedItems.forEach((item: string) => {
			if (regex.test(item)) {
				validItems.push(item);
			} else {
				invalidItems.push(item);
			}
		});
		if (invalidItems.length) {
			setError(true);
			handleChange(invalidItems.join(','));
		}

		if (validItems.length) {
			const uniques = Array.from(new Set(validItems));
			const toBeAdded = uniques.filter((match: string) => !tagsList.includes(match));
			setTagsList([...tagsList, ...toBeAdded]);
			onChange([...tagsList, ...toBeAdded].join(','));
		}
	};

	const onDeleteClick = (value: string) => {
		deleteTag(value);
		if (input == value) setInput('');
	};

	const deleteTag = (value: string) => {
		const temp = [...tagsList];
		temp.splice(tagsList.indexOf(value), 1);
		setTagsList(temp);
		onChange(temp.join(','));
	};

	const handleChange = (value: string) => {
		setInput(value);
		setError(false);
	};

	const onClickTag = (value: string) => {
		handleChange(value);
		deleteTag(value);
	};

	const resetAll = () => {
		setTagsList([]);
		setInput('');
		setError(false);
	};

	return (
		<div className={styles.container} style={{ width: `${width}rem`, maxWidth: `${width}rem` }}>
			<Input
				{...props}
				value={input}
				onChange={handleChange}
				onBlur={handleBlur}
				onKeyDown={handleKeyDown}
				onPaste={handlePaste}
				showError={error || showError}
				onFocus={(e) => e.target.select()}
				customTitle={
					<Button type='link' color='destructive' className={tagsList.length ? styles.removeAllEmails : styles.hide} onClick={resetAll}>
						{t('removeAllEmails')}
					</Button>
				}
			/>
			{!!tagsList.length && (
				<div className={clsx(styles.tagsContainer, tagsContainerClassName)}>
					{tagsList.map((tag, index) => (
						<Button key={`${tag}_${index}`} color='neutral' type='secondary' className={styles.tag} onClick={() => onClickTag(tag)}>
							<div className={styles.tagText}>{tag}</div>
							<Button onClick={() => onDeleteClick(tag)} color='neutral' type='link' className={styles.deleteButton}>
								<Icon imgType='x_icon' color='neutral700' className={styles.deleteIcon} />
							</Button>
						</Button>
					))}
				</div>
			)}
		</div>
	);
};
