import React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Button, Icon } from '../..';
import { FILE_DATE } from '../../../../constants/templateConstants';
import styles from './FileItem.module.scss';

export interface FileItemProps {
	file: File;
	iconHeight?: number;
	iconContainer?: string;
	onDelete(): void;
}

export const FileItem = ({ file, iconHeight, iconContainer, onDelete }: FileItemProps) => {
	return (
		<div className={styles.container}>
			<div className={styles.flexCenter}>
				<div className={clsx(styles.iconContainer, iconContainer)}>
					<Icon imgType='file' color='primary' height={iconHeight} />
				</div>

				<div className={styles.text}>
					<div className={styles.fileName}>{file.name}</div>
					<div className={styles.fontSize14neutral}>
						{dayjs(file.lastModified).format(FILE_DATE)} • {file.size / 1000}KB
					</div>
				</div>
			</div>

			<Button tabIndex={16} type='secondary' color='destructive' onClick={onDelete} className={styles.delete}>
				<Icon imgType='delete' color='destructive' />
			</Button>
		</div>
	);
};
