import { Dispatch } from '@reduxjs/toolkit';
import { PathsConfig, httpService } from '..';

export let keepAliveIds: Array<NodeJS.Timeout | undefined> = [];

export const startKeepAlive = (dispatch: Dispatch) => {
	const id = setInterval(async () => {
		await httpService({
			dispatch,
			path: PathsConfig.getAuthStatus,
			unResetKeepAlive: true,
			showLoader: false,
		});
	}, 5 * 60 * 1_000);

	keepAliveIds.push(id);
};

export const stopKeepAliveTime = () => {
	keepAliveIds.forEach((id) => clearInterval(id));
	keepAliveIds = [];
};
