import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../hooks/generalHooks';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { Icon } from '../_uiComponents';
import { EntityDetails } from '..';
import { Customer } from '../../types/customerTypes';
import styles from './EntitySelect.module.scss';

export interface EntitySelectProps {
	entity?: Customer;
	small?: boolean;
	isOptionsOpen?: boolean;
	selectedEntityStyle?: string;
	className?: string;
	selectContainer?: string;
	detailsClassName?: string;
	onAddEntity?: () => void;
	onEntitySelected?(entity?: Customer): void;
}

export const EntitySelect = ({ entity, small, className, selectContainer, detailsClassName, onAddEntity, onEntitySelected }: EntitySelectProps) => {
	const [entitiesList, setEntitiesList] = useState<Customer[]>([]);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedEntity, setSelectedEntity] = useState<Customer>();

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		setSelectedEntity(entity);
	}, [entity]);

	const getEntitiesList = async (searchText?: string) => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getSuppliers,
				params: {
					filter: searchText,
				},
			});
			setEntitiesList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_LIST }));
		}
	};

	const onSearch = useDebounce(getEntitiesList, 450);

	const onSelectEntity = (entity?: Customer) => {
		setSelectedEntity(entity);
		onEntitySelected?.(entity);
		setIsOpen(false);
	};

	const addEntity = () => {
		setIsOpen(false);
		onAddEntity?.();
	};

	const openSelect = () => {
		getEntitiesList();
		setIsOpen(true);
	};

	return (
		<div className={clsx(styles.dataSelectContainer, selectContainer)} onClickCapture={selectedEntity && openSelect}>
			<div className={clsx(styles.details, detailsClassName)}>
				{selectedEntity ? (
					<>
						<EntityDetails entity={entity} small={small} className={className} />
						<Icon imgType='arrow_down' color={'neutral700'} className={clsx(styles.arrow, isOpen ? styles.arrowUp : styles.arrowDown)} />
					</>
				) : (
					<>
						<div onClick={openSelect} className={small ? styles.searchIconSmall : styles.searchIcon} data-testid='customer-details-search'>
							<Icon imgType='search' color='primary' />
						</div>
						<input
							data-testid='entity-select-input'
							placeholder={t('selectEntity')}
							className={small ? styles.inputContainerSmall : styles.inputContainer}
							onFocus={openSelect}
							onChange={(event) => onSearch(event.target.value)}
						/>
					</>
				)}
			</div>

			<div className={isOpen ? styles.selectListContainer : styles.selectListContainerHidden}>
				<div className={styles.selectList}>
					{entitiesList.map((entity, index) => (
						<div data-testid='entity-select-item' key={index} className={styles.selectItem} onClick={() => onSelectEntity(entity)}>
							<EntityDetails entity={entity} small={small} className={className} />
						</div>
					))}
				</div>
				{onAddEntity && (
					<div className={styles.selectAddButton} onClick={addEntity} data-testid='entity-select-add-item'>
						{t('addNewEntity')} +
					</div>
				)}
			</div>
			{isOpen && <div className={styles.selectOverlay} onClick={() => setIsOpen(false)}></div>}
		</div>
	);
};
