import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ItemPriceDto } from '@received/pricing-model';
import { CellProps, handleCellKeyDown } from '../../TableRow.utils';
import { ProductSelect } from './ProductSelect/ProductSelect';
import { requiredColumnsEnum } from '../../../PricingModelTable.utils';
import styles from './ProductCell.module.scss';

export const ProductCell = ({
	rowData,
	column,
	selectedCell,
	classNames,
	productItems,
	rowIndex,
	columnIndex,
	itemsList,
	pricingModelTab,
	onArrowNavigation,
	onCellSelected,
	updateTableData,
	refreshData,
}: CellProps) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [value, setValue] = useState<string | number>('');

	useEffect(() => {
		setIsEditMode(selectedCell?.cell.cellId === rowData.cells[column.id].cellId);
	}, [selectedCell, rowData]);

	useEffect(() => {
		setValue(rowData.cells[column.id].cellValue);
	}, [rowData]);

	const updateValue = (val: string | number) => {
		setValue(val);
		const newRowData = { ...rowData };
		newRowData.cells[column.id].cellValue = val;

		updateTableData(newRowData);
	};

	const onSelectItem = (item: ItemPriceDto) => {
		setValue(item.item.name);

		const newRowData = { ...rowData };
		newRowData.cells[column?.id].cellValue = item.item.name;
		newRowData.cells[requiredColumnsEnum.price].cellValue = item.price;
		newRowData.itemPrice = { ...item, id: undefined, currency: pricingModelTab?.currency };
		newRowData.itemPriceId = undefined;

		updateTableData(newRowData);
	};

	return (
		<>
			{!isEditMode ? (
				<input
					data-testid={`${rowIndex}-row-${column.title}-column-cell-value`}
					style={{ background: 'none', pointerEvents: 'none' }}
					className={clsx(styles.cell, classNames?.cell)}
					value={value}
					readOnly
				></input>
			) : (
				<ProductSelect
					value={value}
					data={productItems}
					rowData={rowData}
					updateValue={(value) => updateValue(value)}
					className={clsx(styles.cell, classNames?.cell, isEditMode && styles.isEditable, isEditMode && classNames?.isEditable)}
					onKeyDown={(event) => isEditMode && handleCellKeyDown(event, rowIndex, columnIndex, onArrowNavigation, onCellSelected, column)}
					onSelectItem={onSelectItem}
					itemsList={itemsList}
					refreshData={refreshData}
					rowIndex={rowIndex}
				/>
			)}
		</>
	);
};
