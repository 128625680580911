export const initialState = {
	arr: 0,
	billedCurrentMonth: { sentTotalAmount: 0, readyToSendTotalAmount: 0 },
	billedLastMonth: { sentTotalAmount: 0, readyToSendTotalAmount: 0 },
	mrr: 0,
	readyToSend7Days: '0',
	sentLastMonth: '0',
	readyToSendCredit7Days: '0',
	sentCreditLastMonth: '0',
	top3Products: [],
	upForRenewal: { total: '0', contracts: '0', customers: '0' },
};
