import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { CalendarContainer } from 'react-datepicker';
import { BillingCycleStruct, BillingCycleUnit, CyclicDate } from '@received/pricing-model';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isBetween from 'dayjs/plugin/isBetween';
import { MONTHLY_DATE_FORMAT, YEARLY_DATE_FORMAT, DATE_FORMAT } from '../../../../constants/templateConstants';
import { getDatePostfix } from '../../../../utils/DateUtils';
import { Button } from '../../Button/Button';
import { Header } from '../../Header/Header';
import { Icon, IconImgType } from '../../Icon';
import { Input } from '../../Input/Input';
import { Modal } from '../../Modal/Modal';
import { CustomHeader } from '../CustomHeader/CustomHeader';
import { mapCyclicDateToDate, mapDateToCyclicDate } from '../../../PricingModelTabContent/PricingModelTabContent.utils';
import { WeeklyComponent } from './WeeklyComponent/WeeklyComponent';
import { DateType } from '../DatePicker';
import styles from '../DatePicker.module.scss';
import '../DatePicker.css';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);

export interface IssueDatePickerProps {
	title?: string;
	icon?: IconImgType | null;
	customHeaderByBillingCycle?: BillingCycleStruct;
	defaultIssueDate?: CyclicDate;
	disabled?: boolean;
	openDatePicker?: boolean;
	inputClassName?: string;
	headerContainerStyle?: string;
	headerLabelStyle?: string;
	className?: string;
	datePickerLabel?: string;
	updateIssueDate?: (date: CyclicDate) => void;
	updateOnClose?: (date: CyclicDate) => void;
	updateIsOpen?: (isOpen: boolean) => void;
}

export const IssueDatePicker = ({
	className,
	icon,
	customHeaderByBillingCycle,
	defaultIssueDate,
	headerLabelStyle,
	title,
	headerContainerStyle,
	disabled,
	openDatePicker,
	inputClassName,
	datePickerLabel,
	updateIssueDate,
	updateIsOpen,
	updateOnClose,
}: IssueDatePickerProps) => {
	const { t } = useTranslation('translation');

	const [fromMonth, setFromMonth] = useState(new Date());
	const [issueDate, setIssueDate] = useState<DateType>(null);
	const [inputText, setInputText] = useState<string | undefined>('');
	const [isOpen, setIsOpen] = useState(false);
	const [monthInQuarter, setMonthInQuarter] = useState<number>();
	const [selectedDay, setSelectedDay] = useState('');

	useEffect(() => {
		updateDefaultIssueDate(defaultIssueDate);
	}, [defaultIssueDate, customHeaderByBillingCycle, monthInQuarter]);

	useEffect(() => {
		setIsOpen(!!openDatePicker);
	}, [openDatePicker]);

	const updateDefaultIssueDate = (issueDate?: CyclicDate) => {
		if (issueDate) {
			setMonthInQuarter(issueDate?.monthInQuarter || 1);
			// TODO add day of week when weekly billing syce ready
			// setSelectedDay(issueDate.selectedDay || 'monday')
			const date = mapCyclicDateToDate(issueDate, customHeaderByBillingCycle);
			setFromMonth(date);
			setIssueDate(date);
			setLabel(date, monthInQuarter);
		} else {
			setMonthInQuarter(undefined);
			setFromMonth(new Date());
			setIssueDate(null);
			setInputText('');
		}
	};

	const onChangeDate = (fromDate: DateType, monthInQuarter?: number) => {
		setIssueDate(fromDate);
		if (!fromDate) {
			setInputText('');
		} else {
			setLabel(fromDate, monthInQuarter);
		}
		const date = mapDateToCyclicDate(fromDate, customHeaderByBillingCycle, monthInQuarter);
		updateIssueDate?.(date);
	};

	const setLabel = (date: DateType, monthInQuarter?: number) => {
		switch (customHeaderByBillingCycle?.unit) {
			case BillingCycleUnit.ONE_TIME: {
				setInputText(date ? `${dayjs(date).format(DATE_FORMAT)}` : '');
				break;
			}
			case BillingCycleUnit.WEEKLY: {
				setInputText(date ? t('monday') : '');
				break;
			}
			case BillingCycleUnit.MONTHLY: {
				setInputText(`${dayjs(date).format(MONTHLY_DATE_FORMAT)}${getDatePostfix(Number(dayjs(date).format(MONTHLY_DATE_FORMAT)))}`);
				break;
			}
			case BillingCycleUnit.QUARTERLY: {
				setInputText(
					`${dayjs(date).format(MONTHLY_DATE_FORMAT)}${getDatePostfix(Number(dayjs(date).format(MONTHLY_DATE_FORMAT)))} of Q(M${monthInQuarter}) `,
				);
				break;
			}
			case BillingCycleUnit.YEARLY: {
				setInputText(`${dayjs(date).format(YEARLY_DATE_FORMAT)}`);
				break;
			}

			default: {
				setInputText(date ? `${dayjs(date).format(DATE_FORMAT)}` : '');
			}
		}
	};

	const onClose = () => {
		setIsOpen(false);
		updateIsOpen && updateIsOpen(false);
		const date = mapDateToCyclicDate(issueDate, customHeaderByBillingCycle, monthInQuarter);
		updateOnClose?.(date);
	};

	const getHeaderTitle = () => {
		switch (customHeaderByBillingCycle?.unit) {
			case BillingCycleUnit.QUARTERLY: {
				return <span className={clsx(styles.fontSize24, headerLabelStyle)}>{t('quarterlyCycle')}</span>;
			}
			default:
				return <span className={clsx(styles.title, headerLabelStyle)}>{datePickerLabel || t('chooseIssueDate')}</span>;
		}
	};

	const updateIssueWeeklyDate = (day: string) => {
		setInputText(t(day));
		// TODO add day of week when weekly billing syce ready
		// updateIssueDate()
	};

	return (
		<div className={clsx(disabled && styles.notEditable)}>
			<>
				<Header title={title} containerStyle={headerContainerStyle} iconType={icon} />
				<Input
					placeholder='mm/dd/yyyy'
					value={inputText}
					onFocus={() => setIsOpen(true)}
					className={className}
					inputClassName={inputClassName}
					rightChildren={<Icon imgType='date' color='neutral300' width={1.5} className={styles.rightInputIcon} />}
				/>
			</>

			<Modal isOpen={isOpen} closeModal={onClose} overlayClassName={styles.modal}>
				<div className={styles.header}>{getHeaderTitle()}</div>

				<div className={styles.flexColumn}>
					{customHeaderByBillingCycle?.unit === BillingCycleUnit.WEEKLY ? (
						<WeeklyComponent defaultDay={selectedDay} updateIssueDate={updateIssueWeeklyDate} />
					) : (
						<div
							className={clsx(
								styles.dayPickerContainer,
								customHeaderByBillingCycle?.unit === BillingCycleUnit.MONTHLY && styles.dayPickerMonthlyContainer,
								styles.singlePicker,
							)}
						>
							<ReactDatePicker
								data-testid='date-picker-component'
								renderCustomHeader={(props) => {
									switch (customHeaderByBillingCycle?.unit) {
										case BillingCycleUnit.MONTHLY:
											return null;
										case BillingCycleUnit.QUARTERLY:
											return (
												<div className={styles.quarterMonthsContainer}>
													<div className={styles.months}>
														{[1, 2, 3].map((month, index) => (
															<Button
																key={index}
																type='secondary'
																color='neutral'
																className={clsx(styles.quarterButton, monthInQuarter === month && styles.activeQuarter)}
																onClick={() => onChangeDate(issueDate, month)}
															>
																<span>{`${month}${getDatePostfix(month)}`}</span>
															</Button>
														))}
													</div>
												</div>
											);
										default:
											return (
												<CustomHeader
													{...props}
													monthDate={fromMonth}
													customHeaderByBillingCycle={customHeaderByBillingCycle}
													setCurrentMonth={setFromMonth}
												/>
											);
									}
								}}
								onChange={(date) => onChangeDate(date, monthInQuarter)}
								startDate={issueDate}
								openToDate={fromMonth}
								shouldCloseOnSelect={false}
								dayClassName={(date) => {
									return clsx(
										styles.day,
										dayjs(date).format(DATE_FORMAT) == dayjs().format(DATE_FORMAT) && styles.currentDay,
										dayjs(date).format(DATE_FORMAT) == dayjs(issueDate).format(DATE_FORMAT) &&
											date.getMonth() === issueDate?.getMonth() &&
											styles.selectedDay,
										dayjs(date).format(DATE_FORMAT) == dayjs(issueDate).format(DATE_FORMAT) &&
											date.getMonth() === issueDate?.getMonth() &&
											styles.selectedDay,
									);
								}}
								fixedHeight
								calendarContainer={({ children }) => (
									<CalendarContainer data-testid='date-picker-container-component' className={styles.calendarContainer}>
										{children}
									</CalendarContainer>
								)}
								inline
							/>
						</div>
					)}
					<Button onClick={onClose} className={styles.doneButton}>
						{t('done')}
					</Button>
				</div>
			</Modal>
		</div>
	);
};
