import dayjs from 'dayjs';
import { BillingCycleStruct, BillingCycleUnit, Currency, PricingCycleActivation, PricingModelTable } from '@received/pricing-model';
import { PaymentTimeOptions } from '../../types/generalTypes';
import { calculateSubTotal, initialCyclicDay, mapCyclicDateToDate, mapDateToCyclicDate } from '.';
import { PricingModelTab } from '../../types/contractTypes';
import { pricingAndBillingUpdateTableCalculation } from '../PricingModelTable/PricingModelTableActions';

export const updatePaymentPeriod = (
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
) => {
	const issueDay = mapCyclicDateToDate(pricingModelTab?.issueDay);
	switch (pricingModelTab?.billingCycle?.unit) {
		case BillingCycleUnit.ONE_TIME:
			if (dayjs(issueDay).isAfter(pricingModelTab?.billingEndDate || pricingModelTab?.billingStartDate, 'days')) {
				return onUpdatePaymentPeriod(PaymentTimeOptions.AFTER_USE, pricingModelTab, updatePricingModelData);
			} else if (dayjs(issueDay).isBefore(pricingModelTab?.billingStartDate, 'days'))
				return onUpdatePaymentPeriod(PaymentTimeOptions.BEFORE_USE, pricingModelTab, updatePricingModelData);
			else return onUpdatePaymentPeriod(PaymentTimeOptions.DURING_USE, pricingModelTab, updatePricingModelData);
	}
};

export const updateRevRecPaymentPeriod = (
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
) => {
	const postingDay = mapCyclicDateToDate(pricingModelTab?.revenueSettings?.postingDay);
	switch (pricingModelTab?.revenueSettings?.revenueCycle?.unit) {
		case BillingCycleUnit.ONE_TIME:
			if (dayjs(postingDay).isAfter(pricingModelTab?.billingEndDate ?? pricingModelTab?.billingStartDate, 'days')) {
				return onUpdateRevRecPaymentPeriod(PaymentTimeOptions.AFTER_USE, pricingModelTab, updatePricingModelData);
			} else if (dayjs(postingDay).isBefore(pricingModelTab?.billingStartDate, 'days'))
				return onUpdateRevRecPaymentPeriod(PaymentTimeOptions.BEFORE_USE, pricingModelTab, updatePricingModelData);
			else return onUpdateRevRecPaymentPeriod(PaymentTimeOptions.DURING_USE, pricingModelTab, updatePricingModelData);
	}
};

export const onUpdateRevRecPaymentPeriod = (
	paymentTime: PaymentTimeOptions,
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
) => {
	const tempModel = { ...pricingModelTab, revenueSettings: { ...pricingModelTab.revenueSettings } };
	tempModel.revenueSettings.recognitionTime = paymentTime;
	updatePricingModelData(tempModel);
};

export const updateBillingCycle = (
	billingCycle: BillingCycleStruct,
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
	isFromContract?: boolean,
) => {
	const tempModel = { ...pricingModelTab };
	// pricingAndBillingUpdateTableCalculation must be before the update of billingCycle
	const newTable = pricingAndBillingUpdateTableCalculation({ pricingModelTab: tempModel, newBillingCycle: billingCycle, isFromContract });
	tempModel.pricingModelTable = newTable;
	tempModel.pricingModelTableTotal = calculateSubTotal(newTable.rows);

	tempModel.billingCycle = billingCycle;

	if (billingCycle?.unit === BillingCycleUnit.QUARTERLY) {
		tempModel.issueDay = initialCyclicDay;
	} else if (billingCycle?.unit === BillingCycleUnit.ONE_TIME) {
		tempModel.pricingCycle = null;
		tempModel.pricingCycleActivation = PricingCycleActivation.NONE;
		tempModel.autoRenewal = false;
		tempModel.issueDay = getBillingStartDate(tempModel?.billingCycle, tempModel.billingStartDate, tempModel.billingEndDate);
	} else {
		tempModel.issueDay = getBillingStartDate(tempModel?.billingCycle, tempModel.billingStartDate, tempModel.billingEndDate);
	}

	updatePricingModelData(tempModel);
};

export const getBillingStartDate = (billingCycle: BillingCycleStruct, billingStart?: Date | null, billingEnd?: Date | null) => {
	let billingStartDate = dayjs(billingStart || new Date()).toDate();
	// Avoid partial invoice at the end of the contract by setting last invoice billing start day
	if (billingEnd) {
		switch (billingCycle?.unit) {
			case BillingCycleUnit.QUARTERLY:
			case BillingCycleUnit.ONE_TIME: {
				break;
			}
			case BillingCycleUnit.MONTHLY: {
				billingStartDate = dayjs(billingEnd).add(1, 'day').subtract(1, 'month').toDate();
				break;
			}
			case BillingCycleUnit.YEARLY: {
				billingStartDate = dayjs(billingEnd).add(1, 'day').subtract(1, 'year').toDate();
				break;
			}
		}
	}
	const billingDay = billingStart && dayjs(billingStartDate).isBefore(billingStart) ? billingStart : billingStartDate;
	return mapDateToCyclicDate(billingDay);
};

export const updatePricingModelTable = (
	newTable: PricingModelTable,
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
) => {
	const updatedModel = {
		...pricingModelTab,
		pricingModelTable: { ...newTable },
		pricingModelTableTotal: calculateSubTotal(newTable.rows),
	};
	updatePricingModelData(updatedModel);
};

export const onUpdatePaymentPeriod = (
	paymentTime: PaymentTimeOptions,
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
) => {
	const tempModel = { ...pricingModelTab };
	tempModel.paymentTime = paymentTime;

	updatePricingModelData(tempModel);
};

export const onUpdateCurrency = (
	currency: Currency,
	pricingModelTab: PricingModelTab,
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void,
) => {
	const newTab = {
		...pricingModelTab,
		pricingModelTable: {
			...pricingModelTab.pricingModelTable,
			rows: pricingModelTab.pricingModelTable.rows.map((row) => {
				let newRow = { ...row };
				if (row?.itemPrice) {
					newRow = { ...newRow, itemPrice: { ...row?.itemPrice, currency: currency } };
				}
				return newRow;
			}),
		},
	};
	updatePricingModelData({ ...newTab, currency });
};
