import { colors } from '../../../../constants/colors';
import { priceNumberGraphsDisplay, sumNumbersArray } from '../../../../utils/NumberUtils';

export const percentageCalculator = (num: number, total: number) => {
	return num ? `${((num * 100) / total).toFixed()}%` : '0%';
};

export const textCenter = (currency: string) => [
	{
		id: 'textCenter',
		afterDraw(chart: any, args: any, pluginOption: any) {
			const {
				ctx,
				data,
				_active,
				chartArea: { width, height },
			} = chart;

			ctx.save();

			if (_active.length) {
				const dataIndexValue = _active[0].index;
				const title = data.labels[dataIndexValue];
				const dataPoint = data.datasets[0].data[dataIndexValue];
				const sum = sumNumbersArray(chart.data.datasets[0]?.data);
				const total = `${percentageCalculator(dataPoint, sum)}`;
				const percentage =
					dataPoint < 0
						? `
		(${currency}${priceNumberGraphsDisplay(Math.abs(dataPoint))})`
						: `${currency}${priceNumberGraphsDisplay(dataPoint)}`;

				ctx.font = '85% TWKLausanne250';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillStyle = data.datasets[0].backgroundColor[dataIndexValue];
				ctx.fillText(title, width / 2, height / 2 - 25);

				ctx.font = 'bold 150% TWKLausanne250';
				ctx.fillStyle = colors.black;
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillText(total, width / 2, height / 2);

				ctx.font = '80% TWKLausanne450';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillStyle = colors.black;
				ctx.zIndex = -1;
				ctx.fillText(percentage, width / 2, height / 2 + 25);
			} else {
				let title, total, percentage, maxValueIndex;

				if (chart.data?.datasets[0]?.data) {
					const sum = sumNumbersArray(chart.data?.datasets[0]?.data);
					const maxValue = Math.max(...(chart.data.datasets[0]?.data ?? []));
					maxValueIndex = chart.data?.datasets[0]?.data.findIndex((i: number) => i == maxValue);
					title = data.labels[maxValueIndex];
					total = `${percentageCalculator(maxValue, sum)}`;
					percentage =
						maxValue < 0
							? `
					(${currency}${priceNumberGraphsDisplay(Math.abs(maxValue))})`
							: `${currency}${priceNumberGraphsDisplay(maxValue)}`;
				}
				ctx.font = '85% TWKLausanne450';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillStyle = chart.data?.datasets[0]?.backgroundColor[maxValueIndex];
				ctx.fillText(title?.length > 17 ? `${title?.substring(0, 15)}...` : title, width / 2, height / 2 - 25);

				ctx.font = 'bold 150% TWKLausanne250';
				ctx.fillStyle = colors.black;
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillText(total, width / 2, height / 2);

				ctx.font = '80% TWKLausanne450';
				ctx.textAlign = 'center';
				ctx.textBaseline = 'middle';
				ctx.fillStyle = colors.black;
				ctx.fillText(percentage, width / 2, height / 2 + 25);
			}
		},
	},
];
