import React from 'react';
import { salesforceStepper } from '../../integrationSteppers';
import styles from './SalesforceImport.module.scss';

interface SalesforceImportProps {
	activeIndex: number;
	supplierId: string;
	stepsData: { [index: number]: any };
	setStepsData: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

export const SalesforceImport = ({ activeIndex, supplierId, stepsData, setStepsData }: SalesforceImportProps) => {
	return <div className={styles.container}>{salesforceStepper[activeIndex]?.component({})}</div>;
};
