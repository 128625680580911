import { Dispatch } from '@reduxjs/toolkit';
import { DocumentState } from '@received/pricing-model';
import { PathsConfig, httpService } from '../services';
import { setOpenSuccessErrorModal } from '../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../components/SuccessErrorModal/SuccessErrorModal.utils';
import { setShowHideToastNotification } from '../storeSlices/generalSlice';
import { ToastNotificationComponentType } from '../components/NotificationCard/NotificationCard.utils';
import { ButtonStatusColor, ButtonTypes } from '../components';

export const duplicatePayout = async (payoutId: string, dispatch: Dispatch, onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.duplicatePayout,
			urlParam: { id: payoutId },
		});
		onRefresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DUPLICATE_PAYOUT }));
	}
};

export const startGeneratePayoutPDFById = async (dispatch: Dispatch, payoutId: string) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.generatePayoutPDF,
			urlParam: { id: payoutId },
		});

		dispatch(
			setShowHideToastNotification({
				showToastNotification: true,
				toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
				title: 'PreparingFileForDownload',
				description: 'PreparingFileForDownloadDescription',
				icon: 'clock',
			}),
		);
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const payoutStatus = (state?: DocumentState, isShort?: boolean): { color?: ButtonStatusColor; type?: ButtonTypes; text: string } => {
	switch (state) {
		case DocumentState.DRAFT:
			return { color: 'neutral', type: 'secondary', text: 'draft' };
		case DocumentState.REVIEW_READY_TO_SEND:
			return { color: 'primary', type: 'outlined', text: isShort ? 'ready' : 'readyToSend' };
		case DocumentState.REVIEW_MISSING_DATA:
			return { color: 'warning', type: 'secondary', text: 'missingInfo' };
		case DocumentState.PAID:
			return { color: 'success', type: 'secondary', text: 'paid' };
		case DocumentState.CANCELED:
			return { color: 'neutral', type: 'outlined', text: 'canceled' };
		case DocumentState.CONTRACT_DRAFT:
			return { color: 'neutral', type: 'link', text: '' };
		case DocumentState.LATE:
			return { color: 'destructive', type: 'secondary', text: 'late' };

		default:
			return { color: undefined, type: undefined, text: '' };
	}
};
