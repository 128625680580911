import { useTranslation } from 'react-i18next';
import { AccountingEventType } from '@received/pricing-model';
import { PricingModelDetails } from '../../..';
import { contractsTabsProps } from '../ContractSideBar.utils';
import { setFooterDataForContractSidebar } from './SideBarOverview.utils';
import { CreditNotePricingModelDetails } from '../../CreditNoteSidebar/CreditNotePricingModelDetails/CreditNotePricingModelDetails';
import { SideBarFooter } from '../../components';
import styles from './SideBarOverview.module.scss';

export const SideBarOverview = ({ contractData, suppliers, title }: contractsTabsProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<div className={styles.dataContainer}>
				<div className={styles.headerOverviewContainer}>
					<span className={styles.subTitle}>{title}</span>
					<span className={styles.productCount}>{contractData?.pricingModel?.tabs?.length}</span>
				</div>

				<div className={styles.cards}>
					{contractData?.pricingModel?.tabs.map((tab, index) =>
						tab?.accountingEventType != AccountingEventType.CREDIT ? (
							<PricingModelDetails
								key={index}
								data={tab}
								supplier={suppliers?.find((supplier) => supplier.id == tab.supplierId)}
								hasUsageReport={tab.hasUsageReports}
								fromDate={tab.billingStartDate}
								toDate={tab.billingEndDate}
							/>
						) : (
							<CreditNotePricingModelDetails
								key={index}
								tab={tab}
								contractId={contractData?.id}
								fromDate={tab.billingStartDate}
								toDate={tab.billingEndDate}
								hasUsageReport={tab.hasUsageReports}
							/>
						),
					)}
				</div>
			</div>
			<SideBarFooter items={setFooterDataForContractSidebar(contractData)} />
		</div>
	);
};
