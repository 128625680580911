import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setShowHideToastNotification } from '../../storeSlices/generalSlice';
import styles from './NotificationCard.module.scss';

export enum ToastNotificationComponentType {
	ImportedData = 'ImportedData',
	RefreshInTenSec = 'RefreshInTenSec',
	GeneralNotification = 'GeneralNotification',
}

export const toastNotification = (
	type: ToastNotificationComponentType | null,
	seconds: number,
	title: string,
	description?: string,
	additionalData?: any,
) => {
	switch (type) {
		case ToastNotificationComponentType.ImportedData:
			return <ImportedData {...additionalData} title={title} />;
		case ToastNotificationComponentType.RefreshInTenSec:
			return <RefreshAlert {...additionalData} seconds={seconds} title={title} />;
		default:
			return <GeneralNotification title={title} description={description} {...additionalData} />;
	}
};

export const ImportedData = ({
	count = 0,
	title,
	platform,
	importedDataSingleType,
	importedDataMultipleType,
}: {
	count: number;
	title: string;
	importedDataSingleType: string;
	importedDataMultipleType: string;
	platform: string;
}) => {
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	return (
		<>
			<div className={styles.fontSize14}>{t(title)}</div>
			<div className={styles.text}>
				{t('youGotNewImportedData')
					.replace('__', count.toString())
					.replace('importedDataType', count === 1 ? importedDataSingleType : importedDataMultipleType)
					.replace('platform', platform)}
				{/* TODO return the link when filters on customer are ready */}
				{/* <span
					className={styles.link}
					onClick={() =>
						dispatch(setShowHideToastNotification({ showToastNotification: false, toastNotificationComponent: null, additionalData: undefined }))
					}
				>
					{t('clickForReview')}
				</span> */}
			</div>
		</>
	);
};

export const RefreshAlert = ({ seconds, title, onRefresh }: { seconds: number; title: string; onRefresh?: () => void }) => {
	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	return (
		<>
			<div className={styles.fontSize14}>{t(title)}</div>
			<div className={styles.fontSize14neutral}>
				<span>{t('refreshWillBeIn').replace('__', seconds.toString())} </span>
				<span
					className={styles.link}
					onClick={() => {
						onRefresh && onRefresh();
						dispatch(setShowHideToastNotification({ showToastNotification: false, toastNotificationComponent: null, additionalData: undefined }));
					}}
				>
					{t('refreshNow')}
				</span>
			</div>
		</>
	);
};

export const GeneralNotification = ({ title, description }: { title: string; description: string }) => {
	const { t } = useTranslation('translation');
	return (
		<div className={styles.appearAnimation}>
			<div className={styles.fontSize14}>{t(title)}</div>
			<div className={styles.neutralFontSize12}>
				<span>{t(description)}</span>
			</div>
		</div>
	);
};
