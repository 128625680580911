import { useEffect, useState } from 'react';
import { ContractState, ContractType, DEFAULT_CURRENCY, convertCurrencyToSign } from '@received/pricing-model';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AgreementResponse, AgreementView, AgreementsSummary } from '../../../types/AgreementTypes';
import { Store } from '../../../types/storeTypes';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { PathsConfig, httpService } from '../../../services';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { agreementMenuOptions, agreementsTableHeader, defaultAgreementStates, getAgreementSummaryData } from './Agreements.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Button, CreateCustomer, EmptyStateCard, Icon, ImportItemsProgress, SearchBar, Sidebars, Table } from '../../../components';
import { useDebounce } from '../../../hooks';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import { defaultNumberOfItems } from '../../../constants/generalConstants';
import styles from './Agreements.module.scss';

export const Agreements = () => {
	const [showCreateCustomer, setShowCreateCustomer] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [agreementsList, setAgreementsList] = useState<AgreementView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [searchText, setSearchText] = useState('');
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [statusBarData, setStatusBarData] = useState<AgreementsSummary>();
	const [sort, setSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.ASC });
	const [states, setStates] = useState<ContractState[] | undefined>(defaultAgreementStates);

	const currency = convertCurrencyToSign(agreementsList?.[0]?.currency || DEFAULT_CURRENCY);

	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getAgreementsData();
		//TODO - uncomment when server ready
		// setSummaryBar();
	}, [numberOfItems, pageNumber, toastNotification.showToastNotification, sort, searchText, states]);

	const getAgreementsData = async (page?: number, items?: string) => {
		try {
			const res: AgreementResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getAgreementsViewData,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						textFilter: searchText || undefined,
						order: sort.order,
						states,
					},
				})
			).data;

			setTotalCount(res?.count);
			setAgreementsList(res.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const setSummaryBar = async () => {
		const summaryData = await getAgreementSummaryData(dispatch, searchText);
		setStatusBarData(summaryData);
	};

	const onRefreshData = () => {
		getAgreementsData();
		//TODO - uncomment when server ready
		//setSummaryBar();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
	};

	const newReferralAgreement = async () => {
		const agreementId = await createContract(dispatch, ContractType.REFERRAL);
		agreementId && navigate('../referral-agreement', { state: { contractId: agreementId } });
	};

	const onSearch = useDebounce(setSearchText, 650);

	return (
		<div className={styles.globalPageContainer}>
			<Sidebars openSideBarById={openSideBarById} onCloseSideBars={() => setOpenSideBarById(undefined)} onDataChange={onRefreshData} />

			<div className={styles.container}>
				<ImportItemsProgress onRefresh={onRefreshData} />

				<div className={styles.header}>
					<header className={styles.customHeader}>
						<h1 data-testid='agreements-title'>{t('partnerAgreements')}</h1>

						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.search} />

							<Button color='neutral' type='outlined' onClick={() => setShowCreateCustomer(true)} className={styles.customerButtons}>
								{t('newPartner')}
								<Icon imgType='add' width={1.2} className={styles.addIcon} />
							</Button>

							<Button color='success' onClick={newReferralAgreement} className={styles.newAgreement}>
								{t('newAgreement')}
								<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
							</Button>
						</div>
					</header>
				</div>

				{/* TODO - uncomment when specify 
				<div className={styles.cardsContainer}>
					{quantityBarData(t, statusBarData).map((card, index: number) => (
						<div key={index} className={styles.cardWrapper} data-testid={`agreements-summary-card=${index}`}>
							<div className={styles.card}>
								<span className={styles.cardTitle}>{card.title}</span>
								<span className={styles.cardQuantity}>{getValueWithCurrencySign(card.quantity, currency)}</span>
								<span className={styles.cardDescription}>{card.description}</span>
							</div>
							<div className={styles.divider} />
						</div>
					))}
				</div> */}

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={agreementsTableHeader}
					rows={agreementsList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onRowPress={(data) =>
						setOpenSideBarById({
							sideBarType: SidebarType.CONTRACT,
							id: data.id,
						})
					}
					optionsList={(row) =>
						agreementMenuOptions(
							row,
							t,
							dispatch,
							() =>
								setOpenSideBarById({
									sideBarType: SidebarType.CONTRACT,
									id: row.id,
								}),
							onRefreshData,
						)
					}
					showSwitch
					onToggleSwitch={(showDraft: boolean) => setStates(showDraft ? undefined : defaultAgreementStates)}
					defaultSwitchChecked={false}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyState={
						!searchText ? (
							<EmptyStateCard
								showIcon
								imgType='users'
								title={t('noAgreementsFound')}
								subTitle={t('noAgreementsFoundDescription')}
								bodyContainerClassName={styles.emptyStateWrapper}
							>
								<Button color='success' className={styles.emptyButton} onClick={() => newReferralAgreement()}>
									{t('createAgreement')}
									<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
								</Button>
							</EmptyStateCard>
						) : undefined
					}
				/>
			</div>

			<CreateCustomer
				isOpen={showCreateCustomer}
				closeModal={() => setShowCreateCustomer(false)}
				onCustomerAdded={() => setShowCreateCustomer(false)}
				refreshData={onRefreshData}
				isPartner
			/>
		</div>
	);
};
