import React from 'react';
import clsx from 'clsx';
import { Button, EmptyStateCard, Icon, IconImgType, Select } from '..';
import { SelectItem } from '../../types/generalTypes';
import styles from './AnalyticsList.module.scss';

export interface AnalyticsListData {
	amount?: number | string;
	amountSubTitle?: string;
	name: string;
	id: string;
	iconData: string;
	subText?: string;
	fullIcon?: boolean;
	customerId?: string;
}

export interface AnalyticsListProps {
	title: string;
	total?: number;
	data?: AnalyticsListData[];
	subTitle: string;
	className?: string;
	emptyStateTitle?: string;
	emptyStateSubTitle?: string;
	emptyStateIcon?: IconImgType;
	titles?: string[];
	emptyStateCard?: React.ReactNode;
	customFilters?: React.ReactNode;
	filterData?: { defaultValue?: SelectItem | string; data: Array<SelectItem | string> };
	onRowClick?: (row: AnalyticsListData) => void;
	onNavigate?: () => void;
	onSelectFilterItem?(val: string, selected?: SelectItem | string): void;
}

export const AnalyticsList = ({
	title,
	total = 0,
	data,
	subTitle,
	emptyStateTitle,
	emptyStateSubTitle,
	emptyStateIcon,
	titles,
	className,
	emptyStateCard,
	filterData,
	customFilters,
	onRowClick,
	onNavigate,
	onSelectFilterItem,
}: AnalyticsListProps) => {
	return (
		<div className={clsx(styles.container, className)}>
			<div className={clsx(styles.header, titles && styles.headerWithTitles)}>
				<div className={styles.headerItems}>
					<div>
						<div className={styles.fontSize20}>{title}</div>
						<div className={styles.subTitle}>
							{total} {subTitle}
						</div>
					</div>

					<div className={styles.filters}>
						{customFilters}

						{filterData && onSelectFilterItem && (
							<Select
								fullBorder
								mediumInput
								isSearchable
								className={styles.select}
								onChange={onSelectFilterItem}
								width={13.5}
								data={filterData?.data}
								defaultValue={filterData?.defaultValue}
							/>
						)}

						{onNavigate && (
							<Button onClick={onNavigate} type='outlined' color='neutral' className={styles.navigateButton}>
								<Icon imgType='navigate' color='neutral700' height={1.8} />
							</Button>
						)}
					</div>
				</div>

				<div className={styles.titles}>
					{titles?.map((title) => (
						<div key={title}>{title}</div>
					))}
				</div>
			</div>

			<div className={styles.listContainer}>
				{data?.length ? (
					data?.map((row, index) => (
						<div
							key={index}
							className={styles.customersContainer}
							onClick={() => onRowClick?.(row)}
							style={{ pointerEvents: onRowClick ? 'auto' : 'none' }}
						>
							<div className={styles.flex}>
								<div className={clsx(styles.iconContainer, row?.fullIcon && styles.fullIcon)}>
									{row?.iconData ? (
										<img alt='logo' src={row.iconData} className={clsx(styles.iconStyle, row.fullIcon && styles.fullIcon)} />
									) : (
										<div className={clsx(styles.noAvatarContainer, row.fullIcon && styles.fullNoIcon)}>
											{row.name ? row.name[0].toUpperCase() : ''}
										</div>
									)}
								</div>

								<div className={styles.nameContainer}>
									<div className={styles.fontSize14}>{row.name}</div>
									<span className={styles.neutralFontSize12}>{row.subText}</span>
								</div>
							</div>

							<div className={styles.amountContainer}>
								<div className={styles.fontSize14}>{row.amount}</div>
								<span className={styles.neutralFontSize12}>{row.amountSubTitle}</span>
							</div>
						</div>
					))
				) : emptyStateCard ? (
					emptyStateCard
				) : (
					<EmptyStateCard
						showIcon
						imgType={emptyStateIcon}
						imgAlt='empty-state'
						title={emptyStateTitle}
						subTitle={emptyStateSubTitle}
						containerStyle={styles.emptyStateCard}
					/>
				)}
			</div>
		</div>
	);
};
