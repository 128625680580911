import clsx from 'clsx';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setShowHideToastNotification, setShowImportedDataIndicator } from '../../storeSlices/generalSlice';
import { Store } from '../../types/storeTypes';
import { ToastNotificationComponentType } from '../NotificationCard/NotificationCard.utils';
import { colors } from '../../constants/colors';
import styles from './ImportItemsProgress.module.scss';

interface ImportItemsProgressProps {
	barTitle?: string;
	cardTitle?: string;
	onRefresh?(): void;
}

export const ImportItemsProgress = ({ barTitle, cardTitle, onRefresh }: ImportItemsProgressProps) => {
	const { t } = useTranslation('translation');
	const importData = useSelector((store: Store) => store.general.importedDataIndicator);
	const dispatch = useDispatch();

	useEffect(() => {
		if (importData?.totalItems && importData?.totalItems === importData?.importedItems) {
			setTimeout(() => {
				dispatch(setShowImportedDataIndicator(null));
			}, 2500);

			dispatch(
				setShowHideToastNotification({
					showToastNotification: true,
					toastNotificationComponent: ToastNotificationComponentType.RefreshInTenSec,
					title: cardTitle || importData.cardTitle || 'finishToLoadItems',
					additionalData: { onRefresh },
					sideColor: colors.success,
				}),
			);
		}
	}, [importData?.totalItems, importData?.importedItems]);

	return (
		importData && (
			<div className={styles.container}>
				<span className={styles.fontSize14neutral}>
					{importData?.importedItems}/{importData?.totalItems} {barTitle || importData?.barTitle || t('imported')}
				</span>

				<div className={styles.indicatorContainer}>
					<div
						className={clsx(styles.indicator, importData?.totalItems === importData?.importedItems && styles.indicatorDone)}
						style={{ width: `${(importData?.importedItems * 100) / importData?.totalItems}%` }}
					></div>
				</div>
			</div>
		)
	);
};
