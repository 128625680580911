import { AccountingEventType } from '@received/pricing-model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getDocumentIdNameByType } from '../../../../utils/GeneralUtils';
import { CustomerDetails } from '../../../CustomerDetails/CustomerDetails';
import { Button, Icon } from '../../../_uiComponents';
import { contractIdFormat } from '../../../../utils/ContractUtils';
import { BillingDocumentType } from '../../../../types/generalTypes';
import styles from './TopBar.module.scss';

interface TopBarProps {
	document?: BillingDocumentType;
	onClose: () => void;
	onDownload?: () => void;
}

export const TopBar = ({ document, onClose, onDownload }: TopBarProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.topToolbar}>
			<div className={styles.leftContainer}>
				<CustomerDetails logo={document?.customer?.iconData} name={document?.customer?.name} imgClassName={styles.name} nameStyle={styles.title} />
				<div>
					{!!document?.contractId && (
						<span className={styles.greyTitle}>
							{t('contract')} {contractIdFormat(document?.poNumber, document?.contractNumber)}
							{' • '}
						</span>
					)}
					<span className={styles.greyTitle}>
						{t(
							document?.accountingEventType == AccountingEventType.INVOICE
								? 'invoice'
								: document?.accountingEventType == AccountingEventType.CREDIT
								? 'creditNote'
								: 'debitNote',
						)}{' '}
						{getDocumentIdNameByType(document)}
					</span>
				</div>
			</div>
			<div className={styles.leftContainer}>
				{onDownload && (
					<Button color='neutral' type='link' className={styles.button} onClick={onDownload}>
						<Icon imgType='downloadArrow' color='white' width={2} />
					</Button>
				)}
				<Button type='outlined' color='neutral' onClick={onClose} className={clsx(styles.close, styles.button)}>
					<Icon imgType='x_icon' color='white' width={1.2} />
				</Button>
			</div>
		</div>
	);
};
