import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DnDDropDown, DragAndDropSelectItem, SearchBar } from '../../../../../components';
import { NewPricingModel } from '../../../../../pages/PricingModels/NewPricingModel/NewPricingModel';
import { PricingModels } from '../../../../../pages/PricingModels/PricingModels';
import { httpService, PathsConfig } from '../../../../../services';
import { setOpenSuccessErrorModal } from '../../../../../storeSlices/errorSuccessSlice';
import { PriceBookData } from '../../../../../types/integrationTypes';
import styles from './ImportPricingModels.module.scss';

export const ImportPricingModels = () => {
	const { t } = useTranslation('translation');
	const [pricingModelData, setPricingModelData] = useState<PriceBookData[]>([]);

	const [showProductPage, setShowProductPage] = useState(true);
	const [openedSelect, setOpenedSelect] = useState();
	const [searchText, setSearchText] = useState('');

	const [localLocationData, setLocalLocation] = useState();
	const [itemForTable, setItemForTable] = useState<DragAndDropSelectItem>();

	const dispatch = useDispatch();

	useEffect(() => {
		getData();
	}, []);

	const getData = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getSalesforcePriceBooks,
			});
			setPricingModelData(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onPressNavigateToNewModel = (data: any) => {
		setShowProductPage(false);
		setLocalLocation(data);
	};

	const onGoBackToProducts = (data: any) => {
		setShowProductPage(true);
		setLocalLocation(data);
	};

	const onSearch = (data: PriceBookData[]) => {
		const newList = data.map((item) => {
			const newList = item.priceBookEntries.filter((item) => item.name.toLowerCase().includes(searchText.toLowerCase()));
			return { ...item, priceBookEntries: newList };
		});

		return newList;
	};

	return (
		<div className={styles.container}>
			<div className={styles.leftSection}>
				<div className={styles.header}>
					<div className={styles.fontSize24}>{t('importPriceBooksBuildPricingModels')}</div>
				</div>
				<SearchBar onChange={setSearchText} className={styles.searchBar} placeholder={`${t('search')}...`} />

				<div className={styles.listContainer}>
					{onSearch(pricingModelData).map((item, index) => (
						<DnDDropDown
							key={`${index}${item.name}`}
							id={`${index}${item.name}`}
							currentOpen={openedSelect}
							title={item.name}
							dataList={item.priceBookEntries}
							subTitle={`${item.priceBookEntries.length} ${t(item.priceBookEntries.length === 1 ? 'Product' : 'Products')}`}
							setOpenedSelect={setOpenedSelect}
							onAddItem={setItemForTable}
						/>
					))}
				</div>
			</div>

			<div className={styles.rightSection}>
				{showProductPage ? (
					<PricingModels isUsedAsComponent localLocation={localLocationData} navigateToNewModel={onPressNavigateToNewModel} />
				) : (
					<NewPricingModel
						isUsedAsComponent
						localLocation={localLocationData}
						selectedRowItem={itemForTable}
						navigateToProducts={onGoBackToProducts}
					/>
				)}
			</div>
		</div>
	);
};
