export const colors = {
	black: '#000000',
	white: '#FFFFFF',
	sideBar: '#4c6896',
	// neutral
	neutral: '#6B7280',
	neutral50: '#f9fafb',
	neutral100: '#f3f4f6',
	neutral200: '#e5e7eb',
	neutral300: '#D1D5DB',
	neutral400: '#9ca3af',
	neutral600: '#4b5563',
	neutral700: '#374151',
	neutral900: '#111827',
	// primary
	primary: '#168FFF',
	primary50: '#f8fbff',
	primary100: '#e7f3ff',
	primary200: '#c6e3ff',
	primary300: '#93cbff',
	primary400: '#60b2ff',
	primary600: '#0e7fe7',
	primary700: '#056dcd',
	primary800: '#045bab',
	primary900: '#024a8b',
	// success
	success: '#22C55E',
	success50: '#f0fdf4',
	success100: '#DCFCE7',
	success200: '#bbf7d0',
	success400: '#4ade80',
	success300: '#86efac',
	success600: '#16a34a',
	success900: '#14532d',
	// warning
	warning: '#F59E0B',
	warning200: '#fde68a',
	warning400: '#fbbf24',
	warning600: '#d97706',
	warning700: '#b45309',
	// destructive
	destructive: '#EF4444',
	destructive100: '#fee2e2',
	destructive200: '#fecaca',
	destructive400: '#f87171',
	destructive600: '#dc2626',
	// calming
	calming: '#7D44EF',
	calming100: '#EBE2FE',
	calming400: ' #9e71f8',
	calming600: '#6326dc',
	//orange
	orange: '#FFA25F',
	orange100: '#ffe3cf',
	//blueSky
	blueSky: '#5FC5FF',
	blueSky100: '#EFF9FF',
	//yellow
	eveningStar: '#FFD25F',
	eveningStar100: '#FFF6DF',
	//Pink Delight
	pinkDelight: '#FF89E5',
	pinkDelight100: '#FFE7F9',
	//oceanInBowl
	oceanInBowl: '#58E1B0',
	oceanInBowl100: '#DDF9EF',
};
