import React, { useEffect, useState } from 'react';
import { BillingCycleUnit, ContractType, PaymentTerms, PricingType } from '@received/pricing-model';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, DragAndDropSelectItem, Icon, Tabs } from '../_uiComponents';
import { EmptyPricingModel } from './EmptyPricingModel/EmptyPricingModel';
import { Contract, PricingModel, PricingModelTab } from '../../types/contractTypes';
import { PricingModelTabContentOptions } from '../PricingModelTabContent/PricingModelTabContentType';
import { BLANK_PRICING_MODEL_ID, BLANK_REFERRAL_PRICING_MODEL_ID } from '../../constants/templateConstants';
import { CatalogModal } from '..';
import { getBillingAccountId, getSupplierIdByCustomer, getSuppliers } from '../../utils/CustomerUtils';
import { PaymentTimeOptions } from '../../types/generalTypes';
import { calculateSubTotal, initialCyclicDay } from '../PricingModelTabContent/PricingModelTabContent.utils';
import { Customer } from '../../types/customerTypes';
import { getPricingModel } from '../../utils/pricingModelUtils';
import { ContractContent, NewPricingModelContent } from '../PricingModelTabContent';
import { getBillingStartDate } from '../PricingModelTabContent/PricingModelTabContentActions';
import styles from './ContractAndPricingModelContainer.module.scss';

interface ContractAndPricingModelContainerProps {
	contract?: Contract;
	selectedCustomer?: Customer;
	pricingModel?: PricingModel;
	isEditMode?: boolean;
	selectedRowItem?: DragAndDropSelectItem;
	className?: string;
	pricingModelClassName?: string;
	tabContentType: PricingModelTabContentOptions;
	defaultSelectedTab?: number;
	contractType?: ContractType;
	setPricingModel: (value: PricingModel, instantUpdate?: boolean) => void;
	setProductsSelectedTab?: React.Dispatch<React.SetStateAction<number>>;
}

export const ContractAndPricingModelContainer = ({
	contract,
	pricingModel = { tabs: [], sourcePricingModelIds: [] },
	selectedCustomer,
	isEditMode,
	selectedRowItem,
	className,
	pricingModelClassName,
	tabContentType,
	defaultSelectedTab,
	contractType,
	setPricingModel,
	setProductsSelectedTab,
}: ContractAndPricingModelContainerProps) => {
	const [openModal, setOpenModal] = useState(false);
	const [selectedTab, setSelectedTab] = useState<number>(0);
	const [selectedCommonModel, setSelectedCommonModel] = useState<PricingModel>();
	const [supplierEntities, setSupplierEntities] = useState<Customer[]>([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setSelectedTab(defaultSelectedTab || 0);
	}, [defaultSelectedTab]);

	useEffect(() => {
		getSupplierEntities();
	}, [selectedCustomer]);

	const getSupplierEntities = async () => {
		const companies = await getSuppliers(dispatch);
		setSupplierEntities(companies);
	};

	const addTabs = (tabs: PricingModelTab[]): PricingModelTab[] => {
		const newTabs = [...tabs];

		newTabs.forEach((tab) => {
			const billingStartDate = dayjs().toDate();
			const billingEndDate = tab.billingCycle?.unit == BillingCycleUnit.ONE_TIME ? dayjs().toDate() : null;
			tab.billingStartDate = billingStartDate;
			tab.billingEndDate = billingEndDate;
			tab.issueDay =
				tab?.billingCycle?.unit === BillingCycleUnit.QUARTERLY
					? initialCyclicDay
					: getBillingStartDate(tab?.billingCycle, billingStartDate, billingEndDate);
			tab.netTerms = selectedCustomer?.netTerms ? Number(selectedCustomer?.netTerms) : PaymentTerms.DUE_UPON_RECEIPT;
			tab.supplierId = getSupplierIdByCustomer(selectedCustomer, supplierEntities);
			tab.paymentTime = tab.pricingType == PricingType.USAGE ? PaymentTimeOptions.AFTER_USE : PaymentTimeOptions.BEFORE_USE;
			if (tab?.billingDetails) {
				if (selectedCustomer?.billingIntegration) {
					tab.billingDetails = { ...selectedCustomer?.billingIntegration };
				} else {
					tab.billingDetails.billingAccountId = getBillingAccountId(supplierEntities);
				}
			}
			tab.pricingModelTableTotal = calculateSubTotal(tab.pricingModelTable.rows);
			tab.issueDate = dayjs().toDate();
			tab.tax = selectedCustomer?.tax ? +selectedCustomer?.tax : 0;
			if (selectedCustomer?.netTerms) {
				tab.netTerms = selectedCustomer.netTerms;
			}
			if (tab.revenueSettings) {
				tab.revenueSettings.postingDay = initialCyclicDay;
			}
			tab.cryptoSettings = selectedCustomer?.cryptoSettings;
			tab.templatePricingModelTabId = tab.id;
			delete tab?.cryptoSettings?.id;
			// delete id so api will generate a new object instead of updating an existing one
			delete tab.id;
		});

		return newTabs;
	};

	const handleAddModel = (model: PricingModel) => {
		const newModel = { ...pricingModel };
		model.id && newModel.sourcePricingModelIds?.push(model.id);
		newModel.tabs = [...newModel.tabs, ...addTabs(model.tabs)];
		newModel.tabs.forEach((tab, index) => {
			tab.index = index;
		});
		setSelectedTab(newModel.tabs.length - 1);
		setProductsSelectedTab?.(newModel.tabs.length - 1);
		setPricingModel(newModel, true);
	};

	const updatePricingModelTab = (pricingModelTab: PricingModelTab, instantUpdate?: boolean) => {
		const newModel = { ...pricingModel };
		newModel.tabs.splice(selectedTab, 1, pricingModelTab);
		setPricingModel(newModel, instantUpdate);
	};

	const onStartBlank = async () => {
		const emptyPricingModel = await getPricingModel(
			contractType === ContractType.REFERRAL ? BLANK_REFERRAL_PRICING_MODEL_ID : BLANK_PRICING_MODEL_ID,
			dispatch,
		);
		handleAddModel(emptyPricingModel);
	};

	const onUpdateTabName = (name: string, index: number) => {
		const newTabs = [...pricingModel.tabs];
		newTabs[index] = { ...pricingModel.tabs[index], productName: name };
		setPricingModel({ ...pricingModel, tabs: newTabs });
	};

	const onTabClose = (index: number) => {
		if (selectedTab != 0) {
			setSelectedTab(selectedTab - 1);
			setProductsSelectedTab?.(selectedTab - 1);
		}

		// remove tab
		let tempModel = { ...pricingModel };
		const newTabs = [...tempModel.tabs.map((tab) => ({ ...tab }))];
		newTabs.splice(index, 1);

		// remove model id
		if (tempModel?.sourcePricingModelIds) {
			const newTemplateIds = [...tempModel.sourcePricingModelIds];
			newTemplateIds.splice(index, 1);
			tempModel = { ...tempModel, sourcePricingModelIds: newTemplateIds };
		}
		setPricingModel({ ...tempModel, tabs: newTabs }, true);
	};

	return (
		<div className={clsx(styles.container, !pricingModel?.tabs?.length && styles.pricingModel, className)} data-testid='pricing-model-container'>
			{pricingModel.tabs?.length ? (
				<>
					{tabContentType === PricingModelTabContentOptions.CONTRACT && (
						<div className={styles.tabContainer}>
							<Tabs
								id='pricing-model-tab-button'
								tabsArray={pricingModel.tabs.map((tab) => ({ label: tab.name, labelTitle: tab.productName, value: tab.name }))}
								style={{ border: 'none' }}
								updateChosenTab={(num) => {
									setSelectedTab(num);
									setProductsSelectedTab?.(num);
								}}
								chosenTab={selectedTab}
								onNameUpdate={onUpdateTabName}
								onTabClose={onTabClose}
								classNames={styles}
								disabledActions={!isEditMode}
							/>
							{isEditMode && (
								<Button
									id='pricing-model-tab-button'
									type='link'
									color='neutral'
									onClick={() => setOpenModal(true)}
									className={styles.addModelButton}
								>
									<Icon imgType='add' color='neutral' width={1.2} />
								</Button>
							)}
						</div>
					)}

					{tabContentType === PricingModelTabContentOptions.CONTRACT ? (
						<ContractContent
							contractType={contractType}
							contract={contract}
							selectedCustomer={selectedCustomer}
							pricingModelTab={pricingModel.tabs[selectedTab]}
							isEditable={isEditMode}
							className={pricingModelClassName}
							updatePricingModelData={updatePricingModelTab}
						/>
					) : (
						<NewPricingModelContent
							pricingModelTab={pricingModel.tabs[selectedTab]}
							selectedRowItem={selectedRowItem}
							className={pricingModelClassName}
							updatePricingModelData={updatePricingModelTab}
						/>
					)}
				</>
			) : (
				<EmptyPricingModel
					contractType={contractType}
					title={tabContentType != PricingModelTabContentOptions.CONTRACT ? 'createPricing' : undefined}
					description={tabContentType != PricingModelTabContentOptions.CONTRACT ? 'addPricingDescription' : undefined}
					subTitle={tabContentType != PricingModelTabContentOptions.CONTRACT ? 'createFromCommon' : undefined}
					startBlank={onStartBlank}
					openPricingModelCatalog={() => setOpenModal(true)}
					addModel={(model) => {
						setOpenModal(true);
						setSelectedCommonModel(model);
					}}
					className={clsx(styles.emptyPricingModel, tabContentType === PricingModelTabContentOptions.CONTRACT && styles.emptyPricingModelContract)}
				/>
			)}
			<CatalogModal
				startFromProducts
				contractType={contractType}
				editMode={tabContentType === PricingModelTabContentOptions.CONTRACT}
				selectedModel={selectedCommonModel}
				openModal={openModal}
				setOpenModal={(isOpen) => {
					setOpenModal(isOpen);
					setSelectedCommonModel(undefined);
				}}
				onUseThisPricing={(model) => {
					setSelectedCommonModel(undefined);
					handleAddModel(model);
				}}
				addNewPricingEmptyProduct={
					tabContentType === PricingModelTabContentOptions.CONTRACT
						? (selectedCatalog) => {
								navigate('../pricing-models', {
									replace: true,
									state: {
										product: { id: selectedCatalog?.id, name: selectedCatalog?.name },
									},
								});
								setOpenModal(false);
						  }
						: undefined
				}
			/>
		</div>
	);
};
