import { CSSProperties, useEffect, useState } from 'react';
import { Currency, DATE_FORMAT } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ClickableCardsItem, ClickableCardsList, DateRangeFilters, RevenueFeatureDisabled, StackBarDataset, StackBarGraph } from '../../..';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { DatesData, ProductAnalyticsList } from '../../../../types/generalTypes';
import { cashTimeUnitHandler } from '../../../../utils/DateUtils';
import { productsColorsList, timePeriodNext12MonthsInitialState } from '../../../../utils/GeneralUtils';
import { fullFiltersSelectOptions } from '../../../Filters/DateRangeFilters.utils';
import { stackBarDataHandler } from '../RevenueRecognition.utils';
import { ProductContractRes } from '../../../../types/ProductTypes';
import { recognitionMethodLabels } from '../../../../constants/generalConstants';
import {
	GraphsDataResponse,
	RevRecGraphState,
	initialGraphState,
} from '../../../../pages/Contracts/ContractComponent/ContractOverview/ContractOverview.utils';
import styles from './RevenueRecognitionComponent.module.scss';

interface RevenueRecognitionClassNamesProps {
	revRecContainer?: string;
	revRecHeader?: string;
	needLicense?: string;
	stackBarGraph?: string;
}

interface RevenueRecognitionComponentProps {
	featureDisabled?: boolean;
	isValid?: boolean;
	customerId?: string | null;
	timeFilter?: DatesData;
	classNames?: RevenueRecognitionClassNamesProps;
	customStyle?: CSSProperties;
	contractId?: string;
	filterByRevenueRecognitionMethod?: boolean;
	hideHeader?: boolean;
	currency?: Currency;
	excludeDraftContracts?: boolean;
}

export const RevenueRecognitionComponent = ({
	featureDisabled,
	timeFilter,
	customerId,
	isValid = true,
	classNames,
	customStyle,
	contractId,
	hideHeader,
	currency,
	filterByRevenueRecognitionMethod,
	excludeDraftContracts,
}: RevenueRecognitionComponentProps) => {
	const [timePeriod, setTimePeriod] = useState<DatesData>(timePeriodNext12MonthsInitialState);
	const [products, setProducts] = useState<ClickableCardsItem[]>([]);
	const [revenueStackBarGraph, setRevenueStackBarGraph] = useState<RevRecGraphState>(initialGraphState);
	const [deferredStackBarGraph, setDeferredStackBarGraph] = useState<RevRecGraphState>(initialGraphState);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		!featureDisabled && getProducts();
	}, [contractId, customerId, featureDisabled]);

	useEffect(() => {
		!featureDisabled && setTimePeriod(timeFilter || timePeriodNext12MonthsInitialState);
	}, [timeFilter]);

	useEffect(() => {
		if (!featureDisabled) {
			handleRevenueData();
			handleDeferredData();
		}
	}, [products, timePeriod, featureDisabled]);

	const getProducts = async () => {
		try {
			const res: ProductContractRes[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getContractProductsList,
					params: {
						contractId,
						customerId,
						excludeDraftContracts,
					},
				})
			).data;
			const allProducts = res?.flatMap((prod, index) => ({
				id: prod.productId,
				isSelected: true,
				productName: prod.productName,
				color: productsColorsList[index % productsColorsList?.length].textColor || '',
				description: filterByRevenueRecognitionMethod ? recognitionMethodLabels[prod.revenueRecognitionMethod] : '',
			}));
			setProducts(allProducts);
		} catch (error: any) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getRevenueData = async () => {
		try {
			const selectedProducts = products?.filter((product) => product.isSelected);
			if (!selectedProducts.length) {
				return { entries: [] };
			}

			return (
				await httpService({
					dispatch,
					path: PathsConfig.getRevenueGraphsView,
					params: {
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						customerIds: customerId ? [customerId] : undefined,
						contractId,
						productIds: selectedProducts.map((product) => product.id),
						timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to),
						excludeDraftContracts,
					},
				})
			).data;
		} catch (error) {
			setRevenueStackBarGraph(initialGraphState);
			dispatch(
				setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_REVENUES_RECOGNITIONS }),
			);
		}
	};

	const getDeferredData = async () => {
		try {
			const selectedProducts = products?.filter((product) => product.isSelected);
			if (!selectedProducts.length) {
				return { entries: [] };
			}
			return (
				await httpService({
					dispatch,
					path: PathsConfig.getDeferredView,
					params: {
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						customerIds: customerId ? [customerId] : undefined,
						contractId,
						productIds: selectedProducts.map((product) => product.id),
						timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to),
						excludeDraftContracts,
					},
				})
			).data;
		} catch (error) {
			setDeferredStackBarGraph(initialGraphState);
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_DEFERRED }));
		}
	};

	const handleRevenueData = async () => {
		const revenueData: GraphsDataResponse = await getRevenueData();
		// BARS COLORS BY INDEX
		const revenues = revenueData?.entries?.map((month: ProductAnalyticsList) => {
			return {
				...month,
				amountsPerProduct: month?.amountsPerProduct?.map((product) => {
					return {
						...product,
						color: products?.find((revRecProduct) => revRecProduct.id == product.productId)?.color,
					};
				}),
			};
		});
		const { data, isEmpty, dataFormat } = stackBarDataHandler(revenues, cashTimeUnitHandler(timePeriod.from, timePeriod.to), timePeriod.value);

		setRevenueStackBarGraph({
			data,
			isEmpty,
			dataFormat,
		});
	};

	const handleDeferredData = async () => {
		const deferredData: GraphsDataResponse = await getDeferredData();
		// BARS COLORS BY INDEX
		const deferred = deferredData?.entries?.map((month: ProductAnalyticsList) => {
			return {
				...month,
				amountsPerProduct: month?.amountsPerProduct?.map((product) => {
					return {
						...product,
						color: products?.find((revRecProduct) => revRecProduct.id == product.productId)?.color,
					};
				}),
			};
		});

		const { data, isEmpty, dataFormat } = stackBarDataHandler(deferred, cashTimeUnitHandler(timePeriod.from, timePeriod.to), timePeriod.value);

		setDeferredStackBarGraph({
			data,
			isEmpty,
			dataFormat,
		});
	};

	return (
		<>
			{isValid && (
				<div className={clsx(styles.box, classNames?.revRecContainer)}>
					{featureDisabled && <RevenueFeatureDisabled className={classNames?.needLicense} />}

					<div className={clsx(featureDisabled && styles.needLicenseBox)} style={{ pointerEvents: featureDisabled ? 'none' : 'auto' }}>
						<div className={styles.topInfoContainer}>
							{!hideHeader && (
								<div className={styles.topInfo}>
									<span className={clsx(styles.boldTitle, classNames?.revRecHeader)}>{t('revenueRecognition')}</span>

									{!timeFilter && (
										<div className={styles.timeFilter}>
											<span className={styles.dates}>{`${dayjs(timePeriod.from).format(DATE_FORMAT)} - ${dayjs(timePeriod.to).format(
												DATE_FORMAT,
											)}`}</span>

											<DateRangeFilters
												data={fullFiltersSelectOptions}
												defaultSelectValue={timePeriodNext12MonthsInitialState.title}
												width={18}
												callback={setTimePeriod}
												selectClassName={styles.select}
											/>
										</div>
									)}
								</div>
							)}

							<div className={clsx(featureDisabled && styles.hidden)}>
								<ClickableCardsList cards={products} setCards={setProducts} />
							</div>
						</div>

						<div className={styles.bottomInfoContainer}>
							<div className={styles.middleLeft}>
								<div className={styles.flexColumn}>
									<span className={styles.title}>{t('recognizedRevenue')}</span>
									{/* TO-DO WHEN WE ADD TOTAL TO API
									<span className={clsx(featureDisabled ? styles.linearGradient : styles.text, styles.decimalNumber)}>
										{getValueWithCurrencySign(revenueStackBarGraph?.total, convertCurrencyToSign(currency))}
									</span> */}
								</div>
								<div>
									<StackBarGraph
										isCashflow
										isStacked
										isEmpty={revenueStackBarGraph.isEmpty}
										emptyTitle={t('noDataSource')}
										chartData={{ labels: revenueStackBarGraph.data.labels, datasets: revenueStackBarGraph.data.datasets as StackBarDataset[] }}
										dataFormat={revenueStackBarGraph.dataFormat}
										customStyle={customStyle}
										className={classNames?.stackBarGraph}
										currency={currency}
									/>
								</div>
							</div>

							<div className={styles.middleRight}>
								<div className={styles.flexColumn}>
									<span className={styles.title}>{t('deferredRevenue')}</span>
									{/* TO-DO WHEN WE ADD TOTAL TO API 
									<span className={clsx(featureDisabled ? styles.linearGradient : styles.text, styles.decimalNumber)}>
										{getValueWithCurrencySign(deferredStackBarGraph?.total, convertCurrencyToSign(currency))}
									</span> */}
								</div>
								<div>
									<StackBarGraph
										isCashflow
										isStacked
										isEmpty={deferredStackBarGraph.isEmpty}
										emptyTitle={t('noDataSource')}
										chartData={{ labels: deferredStackBarGraph.data.labels, datasets: deferredStackBarGraph.data.datasets as StackBarDataset[] }}
										dataFormat={deferredStackBarGraph.dataFormat}
										customStyle={customStyle}
										className={classNames?.stackBarGraph}
										currency={currency}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
