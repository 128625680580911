import React, { useEffect } from 'react';
import clsx from 'clsx';
import { InvoiceCredit } from '@received/pricing-model';
import { CreditNotePricingSummary, CreditNoteSettings, PricingModelTableComponent } from '../../..';
import { PricingModelTableViewTypeOptions } from '../../../PricingModelTable/PricingModelTable.utils';
import { updatePaymentPeriod, updatePricingModelTable } from '../../PricingModelTabContentActions';
import { Customer } from '../../../../types/customerTypes';
import { PricingModelTab } from '../../../../types/contractTypes';
import styles from './CreditNoteContent.module.scss';

export interface CreditNoteContentProps {
	selectedCustomer?: Customer;
	pricingModelTab: PricingModelTab;
	isEditable?: boolean;
	className?: string;
	invoiceCredits?: InvoiceCredit[];
	creditsAmount?: number;
	updatePricingModelData: (data: PricingModelTab, instantUpdate?: boolean) => void;
	onUpdateManualReportFromTable?: () => void;
}

export const CreditNoteContent = ({
	selectedCustomer,
	pricingModelTab,
	className,
	isEditable,
	invoiceCredits,
	updatePricingModelData,
	onUpdateManualReportFromTable,
}: CreditNoteContentProps) => {
	useEffect(() => {
		updatePaymentPeriod(pricingModelTab, updatePricingModelData);
	}, [pricingModelTab?.issueDay, pricingModelTab?.billingStartDate, pricingModelTab?.billingEndDate, pricingModelTab?.billingCycle]);

	return (
		<div className={clsx(styles.container, className)}>
			<PricingModelTableComponent
				pricingModelTableViewType={PricingModelTableViewTypeOptions.INVOICE_PREVIEW}
				pricingModelTable={pricingModelTab?.pricingModelTable}
				pricingModelTab={pricingModelTab}
				isEditable={isEditable}
				selectedCustomer={selectedCustomer}
				onUpdateManualReportFromTable={onUpdateManualReportFromTable}
				selectedUsagePeriod={{
					fromDate: pricingModelTab?.billingEndDate || null,
					toDate: pricingModelTab?.billingEndDate || null,
				}}
				updateTableData={(table) => updatePricingModelTable(table, pricingModelTab, updatePricingModelData)}
				updatePricingModelData={(data, instantUpdate?: boolean) => updatePricingModelData(data, instantUpdate)}
				classNames={{ tableContainer: styles.tableContainer }}
			/>

			<div className={styles.termsAndPricingContainer}>
				<CreditNoteSettings
					pricingModelTab={pricingModelTab}
					className={styles.innerBorders}
					updateCreditSettings={({ note, supplierId, billingDetails }) => {
						updatePricingModelData({
							...pricingModelTab,
							note,
							supplierId,
							billingDetails: { ...pricingModelTab.billingDetails, ...billingDetails },
						});
					}}
				/>

				{pricingModelTab && (
					<CreditNotePricingSummary
						pricingModelTab={pricingModelTab}
						updatePricingSummaryData={({ tax, discount }) => updatePricingModelData({ ...pricingModelTab, tax, discount })}
						className={styles.innerBorders}
						invoiceCredits={invoiceCredits}
					/>
				)}
			</div>
		</div>
	);
};
