import { Dispatch } from '@reduxjs/toolkit';
import { DocumentState } from '@received/pricing-model';
import { PayoutView, Payout } from '../../../types/PayoutsTypes';
import { ChangeStatusOption, PaidPeriod } from '../../InvoiceTable/InvoiceTable.utils';
import { MenuOption } from '../../_uiComponents';
import { sendMultipleDocuments } from '../../../utils/DocumentsUtils';

export const changePayoutStatus = (
	item: PayoutView | Payout,
	markDocumentAsPaid: (period: PaidPeriod) => void,
	dispatch: Dispatch,
	t: any,
	setCurrentActionForDocument: (setCurrentAction: ChangeStatusOption) => void,
): MenuOption[] => {
	let listOptions: MenuOption[] = [];

	if (item.state === DocumentState.REVIEW_READY_TO_SEND || item.state === DocumentState.DRAFT) {
		listOptions = [
			{
				title: t('sendDocument'),
				iconType: 'paper_airplane',
				color: 'primary',
				subList: [
					{
						title: t('sendNow'),
						onPress: () => item.id && sendMultipleDocuments(t, [item.id], dispatch),
						iconType: 'paper_airplane',
					},
					{
						title: t('markAsSent'),
						onPress: () => setCurrentActionForDocument(ChangeStatusOption.PUBLISH_FROM_ROW),
						iconType: 'letter',
					},
				],
			},
			{
				title: t('markAsPaid'),
				iconType: 'v_circle',
				color: 'success',
				subList: [
					{
						title: t('pickADate'),
						onPress: () => markDocumentAsPaid(PaidPeriod.CUSTOM),
						iconType: 'date',
					},
					{
						title: t('paidToday'),
						onPress: () => markDocumentAsPaid(PaidPeriod.TODAY),
						iconType: 'clock',
					},
				],
			},
		];
	} else if (item.state === DocumentState.PAID || item.state === DocumentState.SENT_FAILED) {
		listOptions = [
			{
				title: t('resendDocument'),
				iconType: 'refresh',
				color: 'neutral',
				onPress: () => item.id && sendMultipleDocuments(t, [item.id], dispatch),
			},
			...listOptions,
		];
	}

	return listOptions;
};
