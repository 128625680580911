import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BillingDetails, Button } from '../..';
import { BillingDetailsFormType, billingValidation } from '../../BillingDetails/BillingDetails.utils';
import { initialState } from './BillingDetailsTab.utils';
import { BillingAccountDetails, Customer } from '../../../types/customerTypes';
import styles from './BillingDetailsTab.module.scss';

interface BillingDetailsTabProps {
	supplier: Customer;
	selectedBankId?: string;
	postCompany: (data: Customer, updateCompany?: boolean) => void;
	setSupplier: (data: Customer) => void;
	setActiveTab: (tab: number) => void;
}

export const BillingDetailsTab = ({ supplier, selectedBankId, postCompany, setSupplier, setActiveTab }: BillingDetailsTabProps) => {
	const { t } = useTranslation('translation');

	const [currentBankIndex, serCurrentBankIndex] = useState(-1); //all banks should be closed at first
	const [currentBank, setCurrentBank] = useState<BillingDetailsFormType>();
	const [buttonsDisabled, setButtonsDisabled] = useState(false);
	const [listOfBanks, setListOfBanks] = useState<BillingAccountDetails[]>(supplier?.billingDetails?.billingAccounts || []);

	useEffect(() => {
		initialSetup();
	}, []);

	const initialSetup = () => {
		if (!supplier?.billingDetails || supplier?.billingDetails?.billingAccounts?.length == 0) {
			serCurrentBankIndex(0);
			const initialBank = { ...initialState, isDefault: true };
			setCurrentBank(initialBank);
			setListOfBanks([initialBank]);
			setButtonsDisabled(true);
		} else if (supplier?.billingDetails?.billingAccounts && selectedBankId) {
			const index = supplier?.billingDetails?.billingAccounts?.findIndex((b) => b.id === selectedBankId);
			serCurrentBankIndex(index);
			setCurrentBank(listOfBanks[index]);
		}
	};

	const setCurrentBankData = (formData: BillingAccountDetails) => {
		const { isValid } = billingValidation(formData);
		setCurrentBank(formData);
		setButtonsDisabled(!isValid);

		if (currentBank) {
			const index = listOfBanks.indexOf(currentBank);

			//if item exist in list - update it
			if (currentBankIndex === index) {
				listOfBanks.splice(currentBankIndex, 1, formData);
			} else {
				//if item not exist in list, add it
				setListOfBanks([...listOfBanks, currentBank]);
			}
			setSupplier({ ...supplier, billingDetails: { billingAccounts: [...listOfBanks] } });
		}
	};

	const onClickSelectedBank = (index: number, isOpen: boolean) => {
		if (!isOpen) {
			if (currentBank) {
				const { isValid, data } = billingValidation(currentBank);
				if (isValid) {
					setCurrentBank(undefined);
					return serCurrentBankIndex(-1);
				}
				setCurrentBankData(data);
			}
		} else {
			serCurrentBankIndex(index);
			setCurrentBank(listOfBanks[index]);
		}
	};

	const onPostCompany = () => {
		if (currentBank) {
			const { isValid, data } = billingValidation(currentBank);
			if (isValid) {
				postCompany(supplier);
			} else {
				setCurrentBank(data);
				const index = listOfBanks.indexOf(currentBank);
				if (currentBankIndex == index) {
					listOfBanks.splice(currentBankIndex, 1, data);
					setSupplier({ ...supplier, billingDetails: { billingAccounts: [...listOfBanks] } });
				}
			}
		} else {
			postCompany(supplier);
		}
		setActiveTab(0);
	};

	const onAddNewBank = () => {
		serCurrentBankIndex(listOfBanks.length > 0 ? listOfBanks.length : 0);
		setCurrentBank(initialState);
		setButtonsDisabled(true);
		setListOfBanks([...listOfBanks, initialState]);
		setSupplier({ ...supplier, billingDetails: { billingAccounts: [...listOfBanks] } });
	};

	const setBankAsDefault = (index: number) => {
		//get the current default bank
		const currentDefaultBankIndex = listOfBanks.findIndex((i) => i.isDefault);
		if (currentDefaultBankIndex !== -1) listOfBanks.splice(currentDefaultBankIndex, 1, { ...listOfBanks[currentDefaultBankIndex], isDefault: false });

		// update with new default bank
		listOfBanks.splice(index, 1, { ...listOfBanks[index], isDefault: true });
		setSupplier({ ...supplier, billingDetails: { billingAccounts: [...listOfBanks] } });
	};

	const removeBank = (index: number) => {
		listOfBanks.splice(index, 1);
		setSupplier({ ...supplier, billingDetails: { billingAccounts: [...listOfBanks] } });
	};

	return (
		<>
			<div className={styles.content}>
				{listOfBanks?.map((item: BillingDetailsFormType, index: number) => (
					<BillingDetails
						key={`${item.id}_${index}`}
						currentBank={item}
						isOpen={currentBankIndex == index}
						onClick={(isOpen) => onClickSelectedBank(index, isOpen)}
						index={index}
						setCurrentBankData={setCurrentBankData}
						onDefault={setBankAsDefault}
						onRemove={removeBank}
					/>
				))}
			</div>

			<div className={styles.buttonContainer}>
				<Button tabIndex={8} onClick={onAddNewBank} className={styles.button} type='secondary' disabled={buttonsDisabled}>
					{`${t('addNewAccount')} +`}
				</Button>
				<Button tabIndex={9} onClick={onPostCompany} className={styles.button}>
					{t('done')}
				</Button>
			</div>
		</>
	);
};
