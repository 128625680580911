import { CellTypes } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Select, Input } from '../../_uiComponents';
import { PricingModelTableClassNames, SelectedCellWithIndex } from '../PricingModelTable';
import { cellTypesList, requiredColumnsEnum } from '../PricingModelTable.utils';
import { PricingModelTab } from '../../../types/contractTypes';
import styles from './PricingTableCellConfiguration.module.scss';

interface PricingTableCellConfigurationProps {
	selectedCell: SelectedCellWithIndex | undefined;
	cellValueInput: string | number;
	pricingModelTab?: PricingModelTab;
	classNames?: PricingModelTableClassNames;
	onUpdateColumnType: (cellType: CellTypes) => void;
	onUpdateCellInput: (cellValue: string) => void;
	setSelectedCell: (selectedCell: SelectedCellWithIndex | undefined) => void;
}

export const PricingTableCellConfiguration = ({
	selectedCell,
	cellValueInput,
	pricingModelTab,
	classNames,
	onUpdateCellInput,
	onUpdateColumnType,
	setSelectedCell,
}: PricingTableCellConfigurationProps) => {
	const { t } = useTranslation('translation');

	const disabledHeaders = [CellTypes.ITEM_REFERENCE, CellTypes.NUMBER];

	return (
		<div className={clsx(styles.pricingModelSetting, classNames?.settings)}>
			<span className={styles.settingsTitle}>{t('pricingModel')}</span>

			<div className={styles.cellProperty}>
				<Select
					placeholder={`#  ${t('number')}`}
					disabled={!selectedCell || disabledHeaders.includes(selectedCell.cell.cellType) || selectedCell.columnId === requiredColumnsEnum.amount}
					defaultValue={selectedCell ? selectedCell.cell?.cellType : ''}
					data={cellTypesList}
					className={styles.columnPropertySelect}
					width={17}
					onChange={onUpdateColumnType}
				/>

				<Input
					id='pricing-model-table-input'
					type={selectedCell?.cell?.cellType}
					placeholder={t('chooseCell')}
					className={styles.input}
					inputClassName={styles.inputClassName}
					value={cellValueInput}
					disabled={
						!selectedCell ||
						pricingModelTab?.pricingCycle?.cycle != pricingModelTab?.billingCycle?.cycle ||
						selectedCell.columnId === requiredColumnsEnum.amount
					}
					onChange={onUpdateCellInput}
					onKeyDown={(event) => event.key === 'Enter' && setSelectedCell(undefined)}
					containerStyle={{ width: 'calc(100% - 17rem)' }}
				/>
			</div>
		</div>
	);
};
