import { Currency, PricingModelTab, PricingType } from '@received/pricing-model';
import { Payout } from '../../../types/PayoutsTypes';
import { UsageTab, UsageTabProps } from '../components';
import { SidebarToOpen } from '../Sidebars.utils';
import { DatePickerFormatType } from '../../_uiComponents';
import { PayoutOverviewTab } from './components';

export const initialPayout: Payout = {
	currency: Currency.USD,
	pricingModel: { tabs: [], sourcePricingModelIds: [] },
	id: '',
	isDraft: false,
	documentNumber: '',
};

export interface PayoutTabsProps {
	payoutData: Payout;
	selectedRowId?: string;
	hasUsageReport: boolean;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	onPressUpdateUsage?: (timePeriod?: DatePickerFormatType) => void;
}

export const payoutsTabs = (payout?: Payout) => {
	let tabs = [{ title: 'Overview', component: (prop?: any) => <PayoutOverviewTab {...prop} /> }];

	if (payout?.pricingModel?.tabs?.some((tab: PricingModelTab) => tab.pricingType === PricingType.USAGE)) {
		tabs = [...tabs, { title: 'Usage', component: (prop: UsageTabProps) => <UsageTab {...prop} /> }];
	}
	return tabs;
};
