import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Icon, Input } from '../../components';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { setIsAuthenticated } from '../../storeSlices/userSlice';
import { loginValidation } from './Login.utils';
import { images } from '../../constants/images';
import styles from './Login.module.scss';

const initialDetails = {
	email: '',
	emailError: false,
	password: '',
	passwordError: false,
};

export const Login = () => {
	const { t } = useTranslation('translation');

	const [searchParams, setSearchParams] = useSearchParams();

	const [loginDetails, setLoginDetails] = useState(initialDetails);
	const [loginErrorMessage, setLoginErrorMessage] = useState<string>();
	const [showPassword, setShowPassword] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	// invite user flow will reach here and validate email on the server before login
	useEffect(() => {
		const userName = searchParams.get('verificationKey');
		userName && verifyEmailFromInvitation(userName);
	}, []);

	const validateData = async () => {
		setLoginErrorMessage(undefined);
		const { isValid, data } = loginValidation(loginDetails);
		if (isValid) {
			await login();
		} else {
			setLoginErrorMessage(t('incorrectCredentials'));
			setLoginDetails(data);
		}
	};

	const login = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.authLogin,
				data: {
					email: loginDetails.email,
					password: loginDetails.password,
				},
			});
			dispatch(setIsAuthenticated(true));
			setLoginErrorMessage(undefined);
			navigate('/');
		} catch (e: any) {
			if (e.response?.data?.message) {
				setLoginErrorMessage(e.response?.data?.message);
			} else {
				dispatch(setOpenSuccessErrorModal({ responseError: e }));
			}
		}
	};

	const verifyEmailFromInvitation = async (userName: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.authVerifyUser,
				urlParam: { userName },
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const updateForm = (field: string, data: string) => {
		setLoginDetails((prev) => ({ ...prev, [field]: data, [`${field}Error`]: false }));
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container} onKeyDown={(event) => event.key === 'Enter' && validateData()}>
				<div className={styles.leftContainer}>
					<div className={styles.header}>
						<Icon imgType='logo' height={2.5} width={12.3} color='black' />
					</div>
					<div className={styles.content}>
						<div className={styles.loginContainer}>
							<div className={styles.loginTitleContainer}>
								<h1 className={styles.title}>{t('auth.welcomeBack')}</h1>
								<span className={styles.description}>{t('auth.welcomeBackDetails')}</span>
							</div>
							<div className={styles.loginFields}>
								<Input
									value={loginDetails.email}
									onChange={(value) => updateForm('email', value)}
									title={t('email')}
									showError={loginDetails.emailError}
									type='email'
									placeholder={t('enterYourEmail')}
								/>
								<Input
									value={loginDetails.password}
									onChange={(value) => updateForm('password', value)}
									title={t('password')}
									showError={loginDetails.passwordError}
									type={showPassword ? undefined : 'password'}
									placeholder='••••••••••'
									rightChildren={
										<Button type='link' color='neutral' onClick={() => setShowPassword(!showPassword)} className={styles.eyeButton}>
											<Icon imgType={showPassword ? 'closed_eye' : 'eye'} />
										</Button>
									}
								/>
								<Button className={styles.signInButton} onClick={validateData}>
									{t('auth.signIn')}
								</Button>
							</div>
							<div className={styles.errorBox} style={{ visibility: loginErrorMessage ? 'visible' : 'hidden' }}>
								<Icon imgType='warning_sign' width={2} color='destructive' />
								<span>{loginErrorMessage}</span>

								<Button type='link' color='neutral' onClick={() => setLoginErrorMessage(undefined)}>
									<Icon imgType='x_icon' color='destructive' className={styles.x_icon} />
								</Button>
							</div>
						</div>
					</div>
				</div>
				<div className={styles.rightContainer}>
					<img alt='login' src={images.login} className={styles.image} />
				</div>
			</div>
		</div>
	);
};
