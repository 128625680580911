import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { UsageLogsMappingType } from '@received/pricing-model';
import { Button, Icon, IconImgType, SearchBar, TextArea } from '../../../../../components';
import { SelectItem } from '../../../../../types/generalTypes';
import { TabsSelectItem } from './TabsSelect';
import { colors } from '../../../../../constants/colors';
import { REGEX_FORMULA_PARAM } from '../LeftContainer.utils';
import styles from './TabsSelect.module.scss';

export interface TabsSelectListProps {
	selectedValue?: { selectedTabType: any; selectedItem: SelectItem | string };
	selectedTab: number;
	data?: TabsSelectItem[];
	defaultValue?: { value: string; tabType: any; label?: string };
	csvHeaders: string[];
	onSearch(): SelectItem[] | string[];
	setSearchText: Dispatch<SetStateAction<string>>;
	onSelectValue(val: SelectItem | string): void;
}

export const tabsSelectComponentType = (key: UsageLogsMappingType, props: TabsSelectListProps) => {
	switch (key) {
		case UsageLogsMappingType.RECEIVED:
		case UsageLogsMappingType.FILE:
			return <TabsSelectList {...props} />;
		case UsageLogsMappingType.FORMULA:
			return <FormulaInput {...props} />;
	}
};

export const TabsSelectList = ({ selectedTab, data = [], selectedValue, onSearch, setSearchText, onSelectValue }: TabsSelectListProps) => {
	const { t } = useTranslation('translation');

	return (
		<>
			{data[selectedTab]?.isSearchable && <SearchBar autoFocus onChange={(val) => setSearchText(val)} className={styles.searchBar} />}
			{data.length && data[selectedTab]?.optionsList?.length ? (
				onSearch().map((item, index) => {
					const val = typeof item === 'string' ? item : item?.label || item.value;
					const selectedItem =
						typeof selectedValue?.selectedItem === 'string'
							? selectedValue.selectedItem
							: selectedValue?.selectedItem?.label || selectedValue?.selectedItem?.value;
					const isSelected = data[selectedTab].type === selectedValue?.selectedTabType && val === selectedItem;

					return (
						<div
							className={clsx(styles.selectItem, isSelected && styles.selectedItem)}
							key={index}
							onClick={(e) => {
								e.stopPropagation;
								onSelectValue(item);
							}}
						>
							<div className={styles.flexCenter}>
								{data[selectedTab]?.imgType && (
									<Icon
										imgType={data[selectedTab].imgType as IconImgType}
										height={2}
										color={isSelected ? 'primary' : 'neutral'}
										className={styles.itemIcon}
									/>
								)}
								<div>{val}</div>
							</div>

							{isSelected && <Icon height={1.8} imgType='v_icon' color='primary' />}
						</div>
					);
				})
			) : (
				<div className={styles.noItemsFound}>{data[selectedTab].emptyText || t('noItemsFound')}</div>
			)}
		</>
	);
};

export const FormulaInput = ({ defaultValue, csvHeaders, onSelectValue }: TabsSelectListProps) => {
	const { t } = useTranslation('translation');

	const [formula, setFormula] = useState('');
	const [isEdit, setIsEdit] = useState(false);

	useEffect(() => {
		if (defaultValue?.tabType === UsageLogsMappingType.FORMULA) {
			setFormula(defaultValue?.value);
		}
	}, [defaultValue]);

	const colorText = () => {
		let newFormula = formula;
		const splicedKeys = formula.split(new RegExp(REGEX_FORMULA_PARAM));

		splicedKeys.forEach((key) => {
			if (key.match(REGEX_FORMULA_PARAM)) {
				const currentHeaderName = key.replaceAll(/[\[\@\]]/g, '');
				newFormula = newFormula?.replaceAll(
					key,
					`<span style="color: ${csvHeaders.includes(currentHeaderName) ? colors.primary : colors.destructive}">${key}</span>`,
				);
			}
		});

		return newFormula;
	};

	return (
		<div className={styles.customFormulaContainer}>
			<div className={styles.flexRowSpaceBetween}>
				<div className={styles.fontSize14}>{t('customFormula')}</div>
				<Button
					type='link'
					className={styles.saveButton}
					onClick={() => {
						onSelectValue({ value: formula, label: t('customFormula') });
						setIsEdit(false);
					}}
				>
					{t('save')}
				</Button>
			</div>
			{isEdit ? (
				<TextArea
					autoFocus
					value={formula}
					className={styles.customFormulaInput}
					onBlur={(e) => {
						e.stopPropagation();
						setIsEdit(false);
					}}
					onChange={(val) => setFormula(val)}
				/>
			) : (
				<div
					className={styles.customFormulaInput}
					onClick={() => setIsEdit(true)}
					dangerouslySetInnerHTML={{
						__html: colorText(),
					}}
				></div>
			)}
		</div>
	);
};
