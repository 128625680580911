import { Dispatch } from '@reduxjs/toolkit';
import { UsageLogsFormatMapping } from '@received/pricing-model/dist/usage/interfaces/usage-report.interface';
import { UsageLogsColumnsOptions, UsageLogsMappingType } from '@received/pricing-model';
import { PathsConfig, httpService } from '../../../services';

export interface UsageLogsFormat {
	id?: string;
	name: string;
	mapping: { [key: string]: UsageLogsFormatMapping };
}

export interface ImportedColumnsListFormat {
	reportItems: UsageLogsFormatMapping[];
	logItems: UsageLogsFormatMapping[];
}
export interface ColumnsListFormat {
	reportItems: string[];
	logItems: string[];
}

export enum UploadLogsSteps {
	UPLOAD_CSV_AND_MAPPER = 'UPLOAD_CSV_AND_MAPPER',
	REFRESH_CSV_AND_MAPPER = 'REFRESH_CSV_AND_MAPPER',
	IMPORT_REPORTS_AFTER_REVIEW = 'IMPORT_REPORTS_AFTER_REVIEW',
}

export const initialLocalColumns = {
	reportItems: [
		UsageLogsColumnsOptions.CUSTOMER,
		UsageLogsColumnsOptions.USAGE_PRODUCT,
		UsageLogsColumnsOptions.ENTITY,
		UsageLogsColumnsOptions.CURRENCY,
		UsageLogsColumnsOptions.PO_NUMBER,
	],
	logItems: [UsageLogsColumnsOptions.DATE, UsageLogsColumnsOptions.QUANTITY, UsageLogsColumnsOptions.SUB_ACTIVITY],
};

export const defaultColumn = {
	type: UsageLogsMappingType.FILE,
	value: '',
	name: '',
	keyTitle: '',
};

export const initialImportedColumns = {
	reportItems: initialLocalColumns.reportItems.map((col, index) => ({ ...defaultColumn, keyTitle: col, index: index })),
	logItems: initialLocalColumns.logItems.map((col, index) => ({
		...defaultColumn,
		keyTitle: col,
		index: initialLocalColumns.reportItems.length + index,
	})),
};

export const initialFormat = {
	name: 'Default Format',
	mapping: {
		[UsageLogsColumnsOptions.CUSTOMER]: { ...initialImportedColumns.reportItems[0] },
		[UsageLogsColumnsOptions.USAGE_PRODUCT]: { ...initialImportedColumns.reportItems[1] },
		[UsageLogsColumnsOptions.DATE]: { ...initialImportedColumns.logItems[0] },
		[UsageLogsColumnsOptions.QUANTITY]: { ...initialImportedColumns.logItems[1] },
		[UsageLogsColumnsOptions.SUB_ACTIVITY]: { ...initialImportedColumns.logItems[2] },
	},
};

export const convertLogFormatToArrays = (format: UsageLogsFormat) => {
	const imported = Object.values(format.mapping);
	const local = Object.keys(format.mapping).map((title, index) => ({ title, index: imported[index].index }));

	const customerIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.CUSTOMER);
	const productIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.USAGE_PRODUCT);
	const entityIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.ENTITY);
	const currencyIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.CURRENCY);
	const poNumberIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.PO_NUMBER);

	const dateIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.DATE);
	const quantityIndex = local.findIndex((item) => item.title === UsageLogsColumnsOptions.QUANTITY);

	const indexToFilter = [entityIndex, productIndex, customerIndex, currencyIndex, dateIndex, quantityIndex, poNumberIndex];

	const importedArrays = {
		reportItems: [
			customerIndex >= 0 ? imported[customerIndex] : { ...defaultColumn, index: 0, keyTitle: UsageLogsColumnsOptions.CUSTOMER },
			productIndex >= 0 ? imported[productIndex] : { ...defaultColumn, index: 1, keyTitle: UsageLogsColumnsOptions.USAGE_PRODUCT },
			entityIndex >= 0 ? imported[entityIndex] : { ...defaultColumn, index: 2, keyTitle: UsageLogsColumnsOptions.ENTITY },
			currencyIndex >= 0 ? imported[currencyIndex] : { ...defaultColumn, index: 3, keyTitle: UsageLogsColumnsOptions.CURRENCY },
			poNumberIndex >= 0 ? imported[poNumberIndex] : { ...defaultColumn, index: 4, keyTitle: UsageLogsColumnsOptions.PO_NUMBER },
		],
		logItems: [
			dateIndex >= 0 ? imported[dateIndex] : { ...defaultColumn, index: 5, keyTitle: UsageLogsColumnsOptions.DATE },
			quantityIndex >= 0 ? imported[quantityIndex] : { ...defaultColumn, index: 6, keyTitle: UsageLogsColumnsOptions.QUANTITY },

			...imported.filter((_, index) => !indexToFilter.includes(index)).sort((a, b) => a?.index - b?.index),
		],
	};

	const localArrays = {
		...initialLocalColumns,
		logItems: [
			UsageLogsColumnsOptions.DATE,
			UsageLogsColumnsOptions.QUANTITY,
			...local
				.filter((_, index) => !indexToFilter.includes(index))
				.sort((a, b) => a?.index - b?.index)
				.map((item) => item.title),
		],
	};

	return { importedArrays, localArrays };
};

export const onAddNewFormat = async (logsFormat: UsageLogsFormat, dispatch: Dispatch, showLoader: boolean) => {
	return (
		await httpService({
			dispatch,
			showLoader,
			path: PathsConfig.postUsageLogObjectMapper,
			data: logsFormat,
		})
	).data;
};
