import React, { CSSProperties, InputHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './TextArea.module.scss';

export interface TextAreaProps<T> extends Omit<InputHTMLAttributes<T>, 'onChange'> {
	title?: string;
	tabIndex?: number;
	customTitle?: ReactNode;
	required?: boolean;
	containerStyle?: CSSProperties;
	showError?: boolean;
	className?: string;
	titleStyle?: string;
	containerClass?: string;
	disabled?: boolean;
	rows?: number;
	onChange?: (val: string, event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const TextArea = <T extends HTMLTextAreaElement>({
	title,
	tabIndex,
	customTitle,
	containerStyle,
	containerClass,
	showError,
	className,
	disabled,
	required = false,
	onChange,
	titleStyle,
	rows,
	...props
}: TextAreaProps<T>) => {
	return (
		<div style={containerStyle} className={containerClass}>
			<div className={styles.header}>
				{title && <span className={clsx(disabled && styles.disabled, styles.formTitle, titleStyle)}>{required ? `${title}*` : title}</span>}
				{customTitle}
			</div>
			<textarea
				{...props}
				tabIndex={tabIndex}
				className={clsx(disabled && styles.disabled, styles.inputContainer, showError && styles.errorContainer, className)}
				onChange={(event) => onChange && onChange(event.target.value, event)}
				data-testid='text-area'
				onFocus={(e) => e.target.select()}
			/>
		</div>
	);
};
