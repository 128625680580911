import dayjs from 'dayjs';
import { DocumentState } from '@received/pricing-model';
import { StackBarGraphData } from '../components';
import { TimeUnit } from '../types/generalTypes';
import { getNumberingFormat, absoluteValueArray } from './NumberUtils';
import { InvoiceCashflow } from '../types/invoiceTypes';
import { colors } from '../constants/colors';
import { FilterTimeOptions } from '../components/Filters/DateRangeFilters.utils';

export const collectionStatusDataHandler = (invoiceData: InvoiceCashflow[], t: any, timeUnit: TimeUnit, filter?: FilterTimeOptions) => {
	let isEmpty = true;

	const destructiveLine: Array<number | null> = [];
	const successLine: Array<number | null> = [];
	const primaryLine: Array<number | null> = [];
	const allSums: number[] = [];

	const labels = invoiceData?.map(({ date, statesCashflow }, index) => {
		statesCashflow.forEach((item) => {
			if (
				item.totalSum > 0 &&
				item.state != DocumentState.DRAFT &&
				item.state != DocumentState.REVIEW_MISSING_DATA &&
				item.state != DocumentState.COLLECTING_INFO &&
				item.state != DocumentState.REVIEW_READY_TO_SEND
			) {
				isEmpty = false;
				allSums.push(item.totalSum);
			}

			switch (item.state) {
				case DocumentState.SENT:
					return primaryLine.push(item.totalSum);

				case DocumentState.LATE:
					return destructiveLine.push(item.totalSum);

				case DocumentState.PAID:
					return successLine.push(item.totalSum);
			}
		});

		if (primaryLine.length != index + 1) {
			primaryLine.push(null);
		}
		if (destructiveLine.length != index + 1) {
			destructiveLine.push(null);
		}
		if (successLine.length != index + 1) {
			successLine.push(null);
		}

		return monthLabel(date, timeUnit, filter);
	});

	const dataForGraph: StackBarGraphData = {
		labels,
		datasets: [
			{ data: destructiveLine, backgroundColor: colors.destructive, borderColor: colors.destructive, label: t('late') },
			{ data: successLine, backgroundColor: colors.success, borderColor: colors.success, label: t('paid') },
			{ data: primaryLine, backgroundColor: colors.primary, borderColor: colors.primary, label: t('sent') },
		],
	};
	return { dataForGraph, isEmpty, dataFormat: getNumberingFormat(Math.max(...absoluteValueArray(allSums))) };
};

export const monthLabel = (date: Date, timeUnit: TimeUnit, filter?: FilterTimeOptions) => {
	if (timeUnit === TimeUnit.MONTH) {
		let monthFormat = 'MMM';
		if (dayjs(date).get('month') == 0 && filter != FilterTimeOptions.CURRENT_YEAR) {
			monthFormat = "MMM 'YY";
		}
		return `${dayjs(date).format(monthFormat)},${date}`;
	}
	return `${dayjs(date).format('MMM DD')},${date}`;
};
