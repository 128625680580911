import { IntegrationStatus } from '@received/pricing-model';
import { Button, Icon } from '../../_uiComponents';
import styles from './NetsuiteCard/NetsuiteCard.module.scss';

export const getButtonByStatus = (
	t: any,
	status: IntegrationStatus,
	haveConfigurationOptions: boolean,
	onConnect: () => void,
	onConfiguration: () => void,
	onDisconnect?: () => void,
	disabled?: boolean,
	onCancel?: () => void,
) => {
	switch (status) {
		case IntegrationStatus.SYNCING:
		case IntegrationStatus.LINKED:
			return haveConfigurationOptions ? (
				<div className={styles.buttons}>
					<Button type='outlined' color='neutral' onClick={onDisconnect} className={styles.disconnect}>
						<Icon imgType='logout' height={2} color='destructive' />
					</Button>
					<Button
						type='outlined'
						color='neutral'
						onClick={onConfiguration}
						className={styles.configuration}
						disabled={status == IntegrationStatus.SYNCING}
					>
						{t('configuration')}
					</Button>
				</div>
			) : (
				<Button type='outlined' color='neutral' onClick={onDisconnect} className={styles.cardButton}>
					<span className={styles.disconnectButton}>{t('disconnect')}</span>
				</Button>
			);
		case IntegrationStatus.PENDING_AUTH:
			return (
				<Button type='outlined' color='neutral' className={styles.cardButton} onClick={onCancel}>
					<span className={styles.disconnectButton}>{t('cancel')}</span>
				</Button>
			);
		case IntegrationStatus.MISSING_DETAILS:
		case IntegrationStatus.UNLINKED:
			return (
				<Button type='outlined' color='neutral' onClick={onConnect} className={styles.cardButton} disabled={disabled}>
					{t('connect')}
					<Icon imgType='navigate' color='neutral700' />
				</Button>
			);
		case IntegrationStatus.DISCONNECTED:
			return (
				<Button type='outlined' color='neutral' onClick={onConnect} className={styles.cardButton} disabled={disabled}>
					{t('reconnect')}
				</Button>
			);
	}
};

export const getTagByStatus = (t: any, status: IntegrationStatus) => {
	switch (status) {
		case IntegrationStatus.LINKED:
			return <span className={styles.connectedTag}> {t('connected')}</span>;
		case IntegrationStatus.PENDING_AUTH:
			return <span className={styles.connectingTag}> {t('connecting')}</span>;
		case IntegrationStatus.MISSING_DETAILS:
			return <span className={styles.connectingTag}> {t('missingDetails')}</span>;
		case IntegrationStatus.SYNCING:
			return <span className={styles.connectedTag}> {t('syncing')}</span>;
		case IntegrationStatus.DISCONNECTED:
			return <span className={styles.reconnectTag}> {t('disconnected')}</span>;
	}
};
