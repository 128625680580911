/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Fragment } from 'react';
import { CurrencyListItem, UsageLogsColumnsOptions, UsageLogsMappingType, currencyList } from '@received/pricing-model';
import { UsageLogsFormatMapping } from '@received/pricing-model/dist/usage/interfaces/usage-report.interface';
import { Button, Icon, IconImgType } from '../../../../components';
import { ColumnsListFormat, ImportedColumnsListFormat, initialImportedColumns, initialLocalColumns } from '../ImportUsageLogs.utils';
import { SelectItem } from '../../../../types/generalTypes';
import styles from './LeftContainer.module.scss';

export const REGEX_FORMULA_PARAM = /(\[@.*?\])/g;

export const ColumnsSeparator = ({
	localColumns,
	onPressUpDown,
}: {
	localColumns?: ColumnsListFormat;
	onPressUpDown(index: number, replaceIndex: number): void;
}) => (
	<div className={styles.columnsSeparator}>
		<div className={styles.dot}></div>
		<div className={styles.line} style={{ height: '5.2rem' }}></div>
		{/* Upper columns section */}
		{localColumns?.reportItems.map((item, index) => (
			<Fragment key={`${index}${item}`}>
				<div key={index} className={styles.circle} style={{ pointerEvents: 'none' }}>
					<Icon imgType='long_arrow' height={1} color='neutral' className={styles.arrowRight} />
				</div>
				<div className={styles.line} style={{ height: '2.2rem' }}></div>
			</Fragment>
		))}

		{/* File columns section */}
		<div className={styles.line} style={{ height: '2.5rem' }}></div>
		{localColumns?.logItems.map((item, index) => {
			const isClickable = index > 1 && localColumns?.logItems.length > initialLocalColumns.logItems.length;
			return (
				<Fragment key={`${index}${item}`}>
					<div key={index} className={styles.circle} style={{ pointerEvents: isClickable ? 'auto' : 'none' }}>
						<Icon imgType='long_arrow' height={1} color={isClickable ? 'neutral900' : 'neutral'} className={styles.arrowRight} />

						<div className={styles.arrowsContainer}>
							{index + 1 != localColumns?.logItems.length && (
								<Button className={styles.arrowButton} type='link' color='neutral' onClick={() => onPressUpDown(index, index + 1)}>
									<Icon imgType='long_arrow' height={1.4} color='neutral' className={styles.arrowDown} />
								</Button>
							)}
							{index != 2 && (
								<Button className={styles.arrowButton} type='link' color='neutral' onClick={() => onPressUpDown(index, index - 1)}>
									<Icon imgType='long_arrow' height={1.4} color='neutral' className={styles.arrowUp} />
								</Button>
							)}
						</div>
					</div>
					<div className={styles.line} style={{ height: '2.2rem' }}></div>
				</Fragment>
			);
		})}
		<div className={styles.line} style={{ height: '2.8rem' }}></div>
		<div className={styles.dot}></div>
	</div>
);

export const convertLocColToIcon = (key: string): IconImgType | undefined => {
	switch (key) {
		case UsageLogsColumnsOptions.ENTITY:
			return 'bank_transfer';
		case UsageLogsColumnsOptions.USAGE_PRODUCT:
			return 'cube';
		case UsageLogsColumnsOptions.CUSTOMER:
			return 'customersEmpty';
		case UsageLogsColumnsOptions.DATE:
			return 'date';
		case UsageLogsColumnsOptions.QUANTITY:
			return 'cube';
		case UsageLogsColumnsOptions.CURRENCY:
			return 'cash';
		default:
			return undefined;
	}
};

export const isSelectRequired = (key: string) => {
	switch (key) {
		case UsageLogsColumnsOptions.USAGE_PRODUCT:
		case UsageLogsColumnsOptions.CUSTOMER:
		case UsageLogsColumnsOptions.DATE:
		case UsageLogsColumnsOptions.QUANTITY:
			return true;

		default:
			return false;
	}
};

export const selectTabsByColumnTypes = (
	key: string,
	t: any,
	csvHeaders: string[],
	usageProducts: SelectItem[],
	customers: SelectItem[],
	entitiesList: SelectItem[],
) => {
	const advanced = {
		title: t('advanced'),
		type: UsageLogsMappingType.FORMULA,
	};
	const selectTabs = [
		{
			title: t('fromFile'),
			type: UsageLogsMappingType.FILE,
			optionsList: csvHeaders,
			emptyText: t('csvHeaderNotFound'),
			imgType: 'csvRows' as IconImgType,
			isSearchable: true,
		},
	];
	switch (key) {
		case UsageLogsColumnsOptions.USAGE_PRODUCT:
			return [
				...selectTabs,
				{
					title: t('fromProducts'),
					type: UsageLogsMappingType.RECEIVED,
					optionsList: usageProducts,
					emptyText: t('usageProductsNotFound'),
					imgType: 'cube' as IconImgType,
					isSearchable: true,
				},
				advanced,
			];
		case UsageLogsColumnsOptions.CUSTOMER:
			return [
				...selectTabs,
				{
					title: t('fromCustomers'),
					type: UsageLogsMappingType.RECEIVED,
					optionsList: customers,
					emptyText: t('customersNotFound'),
					imgType: 'customersEmpty' as IconImgType,
					isSearchable: true,
				},
				advanced,
			];

		case UsageLogsColumnsOptions.ENTITY:
			return [
				...selectTabs,
				{
					title: t('fromEntities'),
					type: UsageLogsMappingType.RECEIVED,
					optionsList: entitiesList,
					emptyText: t('entitiesNotFound'),
					imgType: 'bank_transfer' as IconImgType,
					isSearchable: true,
				},
				advanced,
			];

		case UsageLogsColumnsOptions.CURRENCY:
			return [
				...selectTabs,
				{
					title: t('fromCurrencies'),
					type: UsageLogsMappingType.RECEIVED,
					optionsList: currencyList.map((item: CurrencyListItem) => ({
						label: `${item.flag} ${item.currency}`,
						value: item.currency,
					})),
					emptyText: t('currenciesNotFound'),
					isSearchable: true,
				},
				advanced,
			];

		default:
			return [...selectTabs, advanced];
	}
};

export const usageLogsMapping = (local: ColumnsListFormat, imported: ImportedColumnsListFormat) => {
	const newMapping: { [key: string]: UsageLogsFormatMapping } = {};
	imported.reportItems.forEach((item, index) => {
		if (item.value) {
			newMapping[local.reportItems[index]] = { ...item, index };
		}
	});
	imported.logItems.forEach((item, index) => {
		if ((item.value && local.logItems[index]) || item.keyTitle) {
			newMapping[local.logItems[index]] = { ...item, index: imported.reportItems.length + index };
		}
	});

	return newMapping;
};

export const importLogsValidation = (t: any, local: ColumnsListFormat, imported: ImportedColumnsListFormat, csvHeaders: string[]) => {
	const errors: string[] = [];

	if (csvHeaders.length) {
		imported.reportItems.forEach((item, index) => {
			if (!item.value && isSelectRequired(local.reportItems[index])) {
				errors.push(`${t('missing')} ${local.reportItems[index]}`);
			} else if (item.value && !csvHeaders.includes(item.value) && item.type === UsageLogsMappingType.FILE) {
				errors.push(`${item.value} ${t('headerNotFoundInCsv')}`);
			} else if (item.value && item.type === UsageLogsMappingType.FORMULA) {
				if (checkIsMatchingHeader(item.value, csvHeaders)) {
					errors.push(`${item.keyTitle || item.name}: ${t('cantFindColumnOnFormulaIn')}`);
				}
			}
		});

		imported.logItems.forEach((item, index) => {
			if (item.value && !csvHeaders.includes(item.value) && item.type === UsageLogsMappingType.FILE) {
				errors.push(`${item.value} ${t('headerNotFoundInCsv')}`);
			} else if (item.value && item.type === UsageLogsMappingType.FORMULA) {
				if (checkIsMatchingHeader(item.value, csvHeaders)) {
					errors.push(`${item.keyTitle || item.name}: ${t('cantFindColumnOnFormulaIn')}`);
				}
			} else {
				if (index < initialImportedColumns.logItems.length - 1) {
					if (!item.value && !item.keyTitle) {
						errors.push(`${local.logItems[index]} ${t('missing').toLowerCase()} ${t('fileColumn').toLowerCase()} ${t('and')} ${t('title')}`);
					} else {
						if (!item.value) {
							errors.push(`${local.logItems[index]} ${t('missing').toLowerCase()} ${t('fileColumn')}`);
						}
						if (!item.keyTitle) {
							errors.push(`${t('fileColumn')} ${item.value} ${t('missing').toLowerCase()} ${t('title')}`);
						}
					}
				} else {
					if (!item.value && local.logItems[index]) {
						errors.push(`${local.logItems[index]} ${t('missing').toLowerCase()} ${t('fileColumn')}`);
					}
					if (item.value && !local.logItems[index]) {
						errors.push(`${item.value} ${t('missing').toLowerCase()} ${t('title')}`);
					}
				}
			}
		});
	} else {
		errors.push(t('missingCsv'));
	}

	return errors;
};

const checkIsMatchingHeader = (value: string, csvHeaders: string[]) => {
	const splicedKeys = value.split(new RegExp(REGEX_FORMULA_PARAM));

	return splicedKeys.some((key) => {
		if (key.match(REGEX_FORMULA_PARAM)) {
			const currentHeaderName = key.replaceAll(/[\[\@\]]/g, '');
			return !csvHeaders.includes(currentHeaderName);
		}
		return false;
	});
};

export const onUpdateLocalValues = (currentValue: string, newValue: string, newLocalColumns: ColumnsListFormat) => {
	const isConstKey =
		/* @ts-ignore */
		initialLocalColumns.logItems.includes(currentValue) ||
		/* @ts-ignore */
		initialLocalColumns.reportItems.includes(currentValue);
	if (isConstKey || currentValue === newValue) {
		return currentValue;
	} else if (newLocalColumns.logItems.includes(newValue) || newLocalColumns.reportItems.includes(newValue)) {
		return `${newValue}new`;
	} else {
		return newValue;
	}
};
