import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContractType } from '@received/pricing-model';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, CreateCustomer, Table, DateRangeFilters, ImportItemsProgress, SearchBar, EmptyStateCard, Icon } from '../../../components';
import { PathsConfig, httpService } from '../../../services';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { Store } from '../../../types/storeTypes';
import { getResellerSummaryData, resellersTableHeader, summaryDataBar } from './Resellers.utils';
import { CustomerSummary, CustomerView } from '../../../types/customerTypes';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../components/Filters/DateRangeFilters.utils';
import { DatesData, Sort, SortOrder } from '../../../types/generalTypes';
import { useDebounce } from '../../../hooks';
import { AnalyticCard } from '../../Reports/AnalyticCard/AnalyticCard';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import styles from './Resellers.module.scss';

interface CustomerResponse {
	customerViewList: CustomerView[];
	totalCount: number;
}

const defaultNumberOfItems = '20';

export const Resellers = () => {
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);
	const { appCurrency } = useSelector((store: Store) => store.general);

	const [showCreateCustomer, setShowCreateCustomer] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [customersList, setCustomersList] = useState<CustomerView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [statusBarData, setStatusBarData] = useState<CustomerSummary>();
	const [sort, setSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.ASC });
	const [searchText, setSearchText] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getResellerPartnersData();
		setSummaryBar();
	}, [numberOfItems, pageNumber, timePeriod, toastNotification.showToastNotification, sort]);

	useEffect(() => {
		searchCustomers();
	}, [searchText]);

	const searchCustomers = () => {
		getResellerPartnersData();
		setSummaryBar();
		setPageNumber(0);
	};

	const getResellerPartnersData = async (page?: number, items?: string) => {
		try {
			const res: CustomerResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getCustomersViewData,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						isPartner: true,
						fromDate: timePeriod?.from,
						toDate: timePeriod?.to,
						orderBy: sort.orderBy,
						textFilter: searchText || undefined,
						order: sort.order,
						contractTypes: [ContractType.RESELLER],
					},
				})
			).data;

			setTotalCount(res.totalCount);
			setCustomersList(res.customerViewList);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const setSummaryBar = async () => {
		//TODO - UNCOMMENT WHEN SERVER READY
		//const summaryData = await getResellerSummaryData(dispatch, timePeriod.from, timePeriod.to, [ContractType.RESELLER], undefined, searchText);
		//setStatusBarData(summaryData);
	};

	const onRefreshData = () => {
		getResellerPartnersData();
		setSummaryBar();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
	};

	const onSearch = useDebounce(setSearchText, 650);

	const newReferralAgreement = async () => {
		const contractId = await createContract(dispatch, ContractType.RESELLER);
		contractId && navigate('../reseller-agreement', { state: { contractId } });
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.customersContainer}>
				<ImportItemsProgress onRefresh={() => onRefreshData()} />
				<div className={styles.header}>
					<header className={styles.customersHeader}>
						<h1 data-testid='reseller-partners-title'>{t('resellerPartners')}</h1>
						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.customerSearch} />
							<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />

							<Button color='neutral' type='outlined' onClick={() => setShowCreateCustomer(true)} className={styles.customerButtons}>
								<Icon imgType='add' width={1} className={styles.addIcon} />
								{t('newPartner')}
							</Button>

							<Button color='success' onClick={newReferralAgreement} className={styles.customerButtons}>
								{t('resellerAgreement')}
								<Icon imgType='add' color='white' width={1} />
							</Button>
						</div>
					</header>

					{/* TODO - UNCOMMENT WHEN SERVER READY
					<div className={styles.cardsContainer}>
						{summaryDataBar(t, appCurrency, statusBarData).map((card, index) => (
							<AnalyticCard subQuantity={card?.subQuantity} key={index} {...card} classNames={{ container: styles.box }} />
						))}
					</div> */}
				</div>

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={resellersTableHeader(t, appCurrency)}
					rows={customersList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					tableRowClassName={styles.tableRowClassName}
					cellClassName={styles.cellHeader}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onRowPress={(data) => navigate(`../partners/single-reseller/${data.id}`)}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyState={
						!searchText ? (
							<EmptyStateCard
								showIcon
								imgType='users'
								title={t('noPartnersFound')}
								subTitle={t('noPartnersResellerFoundDescription')}
								bodyContainerClassName={styles.emptyStateContainer}
							>
								<Button color='success' className={styles.emptyButton} onClick={newReferralAgreement}>
									{t('createResellerAgreement')}
									<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
								</Button>
							</EmptyStateCard>
						) : undefined
					}
				/>

				<CreateCustomer
					isOpen={showCreateCustomer}
					closeModal={() => setShowCreateCustomer(false)}
					onCustomerAdded={() => setShowCreateCustomer(false)}
					refreshData={() => onRefreshData()}
					isPartner
				/>
			</div>
		</div>
	);
};
