import { Dispatch } from '@reduxjs/toolkit';
import { UsageReportView } from '../../types/usageTypes';
import { getSuppliers } from '../../utils/CustomerUtils';
import { DatePickerFormatType } from '../_uiComponents';
import { Customer } from '../../types/customerTypes';

export enum SidebarType {
	CONTRACT = 'contract',
	INVOICE = 'invoice',
	USAGE_REPORT = 'usage report',
	CREDIT_NOTE = 'credit note',
	ASSIGN_SUBSCRIPTION = 'Assign Subscription',
	PAYOUT = 'payout',
}

export interface SidebarToOpen {
	sidebarType?: SidebarType;
	triggeredBy?: SidebarType;
	id: string | undefined; // id is required for contract, invoice, credit note
	customer?: Customer;
	usagePeriod?: DatePickerFormatType;
	usageReport?: UsageReportView;
}

export const getBanks = async (dispatch: Dispatch) => {
	const suppliers = await getSuppliers(dispatch);
	const banks = suppliers?.map((supplier: Customer) => supplier.billingDetails?.billingAccounts)?.flat();
	return banks;
};
