import { Transform } from 'class-transformer';
import dayjs from 'dayjs';
import { BillingCycleUnit, ContractState, Currency, ContractEditBlocker, NetTerms } from '@received/pricing-model';
import { BillingDetails, PricingModel } from './contractTypes';
import { Customer } from './customerTypes';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { StripeCard } from './integrationTypes';

export class Subscription {
	id?: string;
	name: string;
	customerId?: string | null;
	pricingModel: PricingModel;
	currency?: Currency;
	discount: number;
	tax: number;
	netTerms: NetTerms;
	supplierId?: string;
	billingDetails?: BillingDetails;
	autoCharge: boolean;
	showNoteInInvoice: boolean;
	note?: string;
	supplier?: Customer;
	paymentGatewayCustomer?: StripeCard;
}

export interface SubscriptionView {
	id: string;
	customerId: string;
	name: string;
	productNames?: string[];
	totalCashflow: number;
	churnRate: number;
	netVolume: number;
	state: ContractState;
	subscribers: number;
	billingCycles: BillingCycleUnit[];
	churnSubscribers: number;
	subscribersMoM: number;
	totalCashflowMom: number;
	last30D: { [key: string]: number };
}

export interface SubscribersView {
	customerId: string;
	contractId?: string;
	name: string;
	iconData?: string;
	email: string;
	productNames: string[];
	products: [{ name: string; netTerms: NetTerms }];
	subscriptionName: string;
	syncStatus?: string | null;
	started?: Date;
	state: ContractState;
	renewalOn?: Date;
	nextInvoiceDate?: Date;
	billingCycles: BillingCycleUnit[];
	paymentGatewayCustomer?: StripeCard;
	nextInvoiceAmount?: string;
	currency: Currency;
	renewalDaysLeft: number;
	startedInMonths: number;
}

export class AssignedSubscription {
	id?: string;
	subscriptionId?: string;
	customerId: string;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	billingStartDate: Date | null;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	billingEndDate: Date | null;
	paymentGatewayCustomerId?: string;
	editBlockers?: ContractEditBlocker[];
	paymentGatewayCustomer?: StripeCard;
}
