/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	BillingCycleStruct,
	CreditNoteReasonCode,
	AccountingEventType,
	currencyList,
	CurrencyListItem,
	Currency,
	convertCurrencyToSign,
	PricingCycleActivation,
	PricingCycleUnit,
	PricingType,
	CellTypes,
	BillingCycleUnit,
	PricingCycleStruct,
} from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import {
	accountingEventTypeSelect,
	DEFAULT_BILLING_CYCLE_VALUE,
	billingCycleSelect,
	creditNoteReasonCodesLabelsSelect,
	pricingCycleSelect,
} from '../../../../constants/generalConstants';
import { Select, Input, Header, RadioButton, Switch, Tooltip, CustomBillingCycle } from '../../../../components/_uiComponents';
import { PricingModelTab } from '../../../../types/contractTypes';
import { requiredColumnsEnum } from '../../../../components/PricingModelTable/PricingModelTable.utils';
import { pricingAndBillingUpdateTableCalculation } from '../../../../components/PricingModelTable/PricingModelTableActions';
import { getCustomBillingCycleLabel } from './PricingModelSettings.utils';
import { CustomCycleType } from '../../../../components/_uiComponents/CustomBillingCycle/CustomBillingCycle.utils';
import styles from './PricingModelSettings.module.scss';

interface PricingModelSettingsProps {
	pricingModelTab?: PricingModelTab;
	onUpdateBillingCycle?: (billingCycle: BillingCycleStruct) => void;
	updateCurrency?: (currency: Currency) => void;
	updatePricingModelData?: (data: PricingModelTab) => void;
}

export const PricingModelSettings = ({
	pricingModelTab,
	onUpdateBillingCycle,
	updateCurrency,
	updatePricingModelData,
}: PricingModelSettingsProps) => {
	const { t } = useTranslation('translation');
	const [showBillingSelect, setShowBillingSelect] = useState(false);
	const [isCustomBillingCycleOpen, setIsCustomBillingCycleOpen] = useState(false);
	const [customCycleType, setCustomCycleType] = useState<CustomCycleType>();

	useEffect(() => {
		setShowBillingSelect(!isSameAsBillingChecked());
	}, []);

	useEffect(() => {
		if (
			// All the cases that pricing cycle counted same as billing cycle
			pricingModelTab?.pricingType != PricingType.USAGE &&
			(pricingModelTab?.billingCycle === pricingModelTab?.pricingCycle || pricingModelTab?.pricingCycle === null)
		) {
			pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, pricingCycleActivation: PricingCycleActivation.PRICE });
		}
	}, [pricingModelTab?.billingCycle, pricingModelTab?.pricingCycle]);

	const onUpdateAccountingEventType = (accountingEventType: AccountingEventType) => {
		if (pricingModelTab) {
			const tempModel = { ...pricingModelTab };
			tempModel.accountingEventType = accountingEventType;
			if (!tempModel.reasonCode) {
				tempModel.reasonCode = creditNoteReasonCodesLabelsSelect[0].value;
			}
			updatePricingModelData?.(tempModel);
		}
	};

	const onUpdatePricingCycle = (pricingCycle: PricingCycleStruct) => {
		if (pricingModelTab?.pricingType === PricingType.USAGE) {
			setShowBillingSelect(false);
		}

		switch (pricingCycle?.unit) {
			case PricingCycleUnit.PER_UNIT: {
				pricingModelTab &&
					updatePricingModelData?.({
						...pricingModelTab,
						pricingCycle,
						billingCycle:
							pricingModelTab.billingCycle?.unit === BillingCycleUnit.ONE_TIME
								? { unit: BillingCycleUnit.MONTHLY, cycle: DEFAULT_BILLING_CYCLE_VALUE }
								: pricingModelTab.billingCycle,
						pricingCycleActivation: PricingCycleActivation.NONE,
					});
				return setShowBillingSelect(true);
			}
			case PricingCycleUnit.ONE_TIME: {
				pricingModelTab &&
					updatePricingModelData?.({
						...pricingModelTab,
						pricingCycle: null,
						billingCycle: { unit: BillingCycleUnit.ONE_TIME, cycle: DEFAULT_BILLING_CYCLE_VALUE },
						pricingCycleActivation: PricingCycleActivation.NONE,
					});
				return setShowBillingSelect(false);
			}
			case PricingCycleUnit.CUSTOM: {
				setCustomCycleType(CustomCycleType.PRICING);
				return setIsCustomBillingCycleOpen(true);
			}
		}

		if (pricingModelTab?.pricingCycle === null) {
			// @ts-ignore  BillingCycle and PricingCycle enums
			onUpdateBillingCycle?.(pricingCycle);
		}
		// @ts-ignore  BillingCycle and PricingCycle enums
		else if (pricingModelTab) {
			const newTable = pricingAndBillingUpdateTableCalculation({ pricingModelTab, newPricingCycle: pricingCycle });
			pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, pricingCycle, pricingModelTable: newTable });
		}
	};

	const onChangeBillingCycle = (unit: BillingCycleUnit) => {
		if (unit == BillingCycleUnit.CUSTOM) {
			setCustomCycleType(CustomCycleType.BILLING);
			return setIsCustomBillingCycleOpen(true);
		}

		onUpdateBillingCycle?.({ unit, cycle: DEFAULT_BILLING_CYCLE_VALUE });
	};
	const onClickSameAsBilling = (val: boolean) => {
		// @ts-ignore  BillingCycle and PricingCycle enums
		pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, pricingCycle: val ? null : pricingModelTab.billingCycle });
		setShowBillingSelect(!val);
	};

	const onUpdateCustomBillingCycle = (cycle: BillingCycleStruct | PricingCycleStruct) => {
		// @ts-ignore  BillingCycle and PricingCycle struct
		customCycleType == CustomCycleType.PRICING ? onUpdatePricingCycle(cycle) : onUpdateBillingCycle?.(cycle);
	};

	const isQuantityClickable =
		pricingModelTab?.pricingCycle?.unit === PricingCycleUnit.PER_UNIT ||
		pricingModelTab?.pricingType === PricingType.USAGE ||
		// @ts-ignore  BillingCycle and PricingCycle enums
		pricingModelTab?.pricingCycle === pricingModelTab?.billingCycle ||
		(!!pricingModelTab?.pricingModelTable.rows.length &&
			pricingModelTab?.pricingModelTable.rows[0].cells[requiredColumnsEnum.quantity].cellType != CellTypes.COUNT);

	const sameAsBillingSwitchDisabledCycles =
		pricingModelTab?.pricingCycle?.unit === PricingCycleUnit.PER_UNIT ||
		pricingModelTab?.billingCycle?.unit === BillingCycleUnit.ONE_TIME ||
		pricingModelTab?.pricingType === PricingType.USAGE;

	const isSameAsBillingChecked = () => {
		if (pricingModelTab?.pricingType === PricingType.USAGE) {
			return pricingModelTab?.pricingCycle?.unit != PricingCycleUnit.PER_UNIT;
		}
		if (pricingModelTab?.pricingCycle?.unit === PricingCycleUnit.PER_UNIT) return false;
		if (pricingModelTab?.billingCycle?.unit === BillingCycleUnit.ONE_TIME) return true;

		return pricingModelTab?.pricingCycle === null;
	};

	const tooltipOptions = () => {
		if (pricingModelTab?.billingCycle?.unit === BillingCycleUnit.ONE_TIME) {
			return <div className={styles.tooltipLabel}>{t('differentBillingCycleNotAvailable')}</div>;
		}
		if (pricingModelTab?.pricingCycle?.unit === PricingCycleUnit.PER_UNIT) {
			return <div className={styles.tooltipLabel}>{t('optionUnavailableForPerUnit')}</div>;
		}
	};

	return (
		<div className={styles.settingsContainer}>
			<span className={styles.settingsTitle}>{t('billingSettings')}</span>

			<div className={styles.settingsContent}>
				<Select
					className={styles.select}
					title={t('documentType')}
					data={accountingEventTypeSelect()}
					defaultValue={pricingModelTab?.accountingEventType || accountingEventTypeSelect()[0].value}
					style={{ width: '100%' }}
					onChange={(accountingEventType: AccountingEventType) => {
						pricingModelTab && onUpdateAccountingEventType(accountingEventType);
					}}
				/>

				<Select
					className={styles.select}
					defaultValue={getCustomBillingCycleLabel(t, pricingModelTab?.pricingCycle || pricingModelTab?.billingCycle)}
					title={t('pricingCycle')}
					data={pricingCycleSelect()}
					style={{ width: '100%' }}
					headerLeftChildren={
						<div className={styles.sameAsBillingContainer}>
							<span className={styles.sameAsBilling}>{t('sameAsBilling')}</span>
							<Tooltip tooltipComponent={tooltipOptions()} blackTooltip placement='left'>
								<Switch
									smallSize
									defaultDisable={sameAsBillingSwitchDisabledCycles}
									onChange={onClickSameAsBilling}
									defaultChecked={isSameAsBillingChecked()}
								/>
							</Tooltip>
						</div>
					}
					onChange={(pricingCycleUnit: PricingCycleUnit) => onUpdatePricingCycle({ unit: pricingCycleUnit, cycle: DEFAULT_BILLING_CYCLE_VALUE })}
				/>

				<div className={showBillingSelect ? styles.showSelect : styles.hideSelect}>
					<Select
						className={styles.select}
						title={t('billingCycle')}
						data={billingCycleSelect(true)}
						defaultValue={
							pricingModelTab?.billingCycle ? getCustomBillingCycleLabel(t, pricingModelTab?.billingCycle) : billingCycleSelect()[0].value
						}
						style={{ width: '100%' }}
						onChange={(billingCycleUnit: BillingCycleUnit) => onChangeBillingCycle(billingCycleUnit)}
					/>
					<Header title={t('activateCycleLogicOn')} />
					<div className={styles.radioButtonContainer}>
						<RadioButton
							isChecked={pricingModelTab?.pricingCycleActivation === PricingCycleActivation.PRICE}
							label={t('price')}
							disabled={pricingModelTab?.pricingCycle?.unit === PricingCycleUnit.PER_UNIT || pricingModelTab?.pricingType === PricingType.USAGE}
							onChange={() =>
								pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, pricingCycleActivation: PricingCycleActivation.PRICE })
							}
						/>
						<RadioButton
							isChecked={pricingModelTab?.pricingCycleActivation === PricingCycleActivation.QUANTITY}
							label={t('quantity')}
							onChange={() =>
								pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, pricingCycleActivation: PricingCycleActivation.QUANTITY })
							}
							disabled={isQuantityClickable}
						/>
						<RadioButton
							isChecked={pricingModelTab?.pricingCycleActivation === PricingCycleActivation.NONE}
							label={t('none')}
							onChange={() =>
								pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, pricingCycleActivation: PricingCycleActivation.NONE })
							}
						/>
					</div>
				</div>

				<Select
					title={t('currency')}
					onChange={(value: any) => updateCurrency?.(value)}
					defaultValue={pricingModelTab?.currency}
					className={styles.select}
					style={{ width: '100%' }}
					data={currencyList.map((item: CurrencyListItem) => ({
						label: `${item.flag} ${item.currency}`,
						value: item.currency,
					}))}
				/>

				<Select
					title={t('reasonCode')}
					placeholder={t('reasonCodePlaceholder')}
					data={creditNoteReasonCodesLabelsSelect}
					className={styles.select}
					style={{ width: '100%' }}
					defaultValue={pricingModelTab?.reasonCode || creditNoteReasonCodesLabelsSelect[0].value}
					width={pricingModelTab?.accountingEventType == AccountingEventType.CREDIT ? 20 : 0}
					containerStyle={clsx(pricingModelTab?.accountingEventType != AccountingEventType.CREDIT && styles.hidden)}
					onChange={(reasonCode: CreditNoteReasonCode) => pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, reasonCode })}
				/>

				<Input
					title={t('minimumFee')}
					type='number'
					placeholder='0.00'
					defaultValue={pricingModelTab?.minimumFee}
					data-testid='minimum-fee-input'
					className={styles.select}
					inputClassName={pricingModelTab?.minimumFee && pricingModelTab?.minimumFee > 0 ? '' : styles.zero}
					containerClass={clsx(
						pricingModelTab?.accountingEventType &&
							[AccountingEventType.CREDIT, AccountingEventType.PAYOUT].includes(pricingModelTab?.accountingEventType) &&
							styles.hidden,
					)}
					leftChildren={
						<span className={pricingModelTab?.minimumFee && pricingModelTab?.minimumFee > 0 ? styles.blackText : styles.zero}>
							{pricingModelTab?.currency && convertCurrencyToSign(pricingModelTab?.currency)}
						</span>
					}
					onChange={(minimumFee) => pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, minimumFee: Number(minimumFee) })}
				/>
			</div>

			<CustomBillingCycle
				customCycleType={customCycleType}
				isOpen={isCustomBillingCycleOpen}
				billingCycle={customCycleType == CustomCycleType.PRICING ? pricingModelTab?.pricingCycle : pricingModelTab?.billingCycle}
				onUpdateCustomBillingCycle={onUpdateCustomBillingCycle}
				onClose={() => setIsCustomBillingCycleOpen(false)}
			/>
		</div>
	);
};
