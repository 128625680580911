import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { EventType, IntegrationPlatform, IntegrationStatus } from '@received/pricing-model';
import { Button, CloseButton, CreateSupplier, EmptyStateCard, Icon, MenuList, MenuOption, Modal } from '../../../..';
import { images } from '../../../../../constants/images';
import { PathsConfig, httpService } from '../../../../../services';
import { setOpenSuccessErrorModal } from '../../../../../storeSlices/errorSuccessSlice';
import { getSuppliers } from '../../../../../utils/CustomerUtils';
import { convertIntegrationTypeToDescription, convertIntegrationTypeToStringService } from '../../../../../utils/IntegrationUtils';
import { ListItem } from './ListItem/ListItem';
import { IntegrationType } from '../../../../../types/integrationTypes';
import { Store } from '../../../../../types/storeTypes';
import { setListenerResponseType } from '../../../../../storeSlices/generalSlice';
import { Customer } from '../../../../../types/customerTypes';
import { onRemoveIntegration } from '../../../../../services/integrationService/integrationService';
import styles from './ConnectModal.module.scss';

export interface ConnectModalProps {
	isOpen: boolean;
	platform: IntegrationPlatform;
	image: string;
	closeModal(): void;
	onConnect(supplierId: string): void;
	onDisconnect?(id: string): Promise<void>;
	onClickEntity?(supplierId: string, connectionStatus: IntegrationStatus): void;
	onCancel?(supplierId: string, integrationAccountId: string): void;
	onMissingDetails?(integrationAccountId: string): void;
}

export const ConnectModal = ({
	isOpen,
	platform,
	image,
	closeModal,
	onConnect,
	onDisconnect,
	onClickEntity,
	onCancel,
	onMissingDetails,
}: ConnectModalProps) => {
	const [platformList, setPlatformList] = useState<IntegrationType[]>([]);
	const [companies, setCompanies] = useState<MenuOption[]>([]);
	const [openEntityMenu, setOpenEntityMenu] = useState(false);
	const [showCreateEntityModal, setShowCreateEntityModal] = useState(false);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const listenerResponseType = useSelector((store: Store) => store.general.listenerResponseType);

	useEffect(() => {
		if (isOpen || listenerResponseType == EventType.CHANGED_INTEGRATION_ACCOUNT) {
			getIntegrationsList();
			listenerResponseType == EventType.CHANGED_INTEGRATION_ACCOUNT && dispatch(setListenerResponseType(undefined));
		}
	}, [isOpen, listenerResponseType]);

	useEffect(() => {
		isOpen && getSupplierCompanies();
	}, [platformList]);

	const getIntegrationsList = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getIntegrations,
					params: { platform },
				})
			).data;
			setPlatformList(res);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSupplierCompanies = async () => {
		const connectedSuppliers = platformList
			.filter((entity) => entity.connectionStatus != IntegrationStatus.UNLINKED)
			.map((entity) => entity.supplier.id);

		const suppliers: Customer[] = await getSuppliers(dispatch);
		const supplierMenu = suppliers
			?.filter((supplier) => !connectedSuppliers.includes(supplier.id))
			?.map((supplier, index) => ({
				title: supplier.name,
				onPress: () => supplier.id && onConnectSupplierToPlatform(supplier.id),
				hasBottomBorder: suppliers.length - 1 === index,
			}));

		setCompanies([...supplierMenu, { title: t('newEntity'), onPress: () => setShowCreateEntityModal(true), iconType: 'add', color: 'success' }]);
	};

	const onConnectSupplierToPlatform = (supplierId: string) => {
		onConnect(supplierId);
		closeModal();
	};

	const addCompany = async (entityData: Customer) => {
		entityData?.id && onConnectSupplierToPlatform(entityData?.id);
		await getSupplierCompanies();
	};

	return (
		<>
			<Modal isOpen={isOpen} closeModal={closeModal} className={styles.modalContainer}>
				<div className={styles.header}>
					<div className={styles.flexCenter}>
						<div className={styles.iconContainer}>
							<img alt='cardIcon' src={image} className={styles.cardIcon}></img>
						</div>
						<div>
							<h2 className={styles.fontWeight650}>{t(convertIntegrationTypeToStringService(platform))}</h2>
							<div className={styles.cardDescription}>{t(convertIntegrationTypeToDescription(platform))}</div>
						</div>
					</div>

					<CloseButton onClick={closeModal} />
				</div>

				<div className={styles.content}>
					{platformList.filter((entity) => entity.connectionStatus != IntegrationStatus.UNLINKED).length ? (
						<>
							<div className={styles.contentHeader}>
								<div className={styles.fontWeight650}>{t('connectedEntities')}</div>
								<MenuList optionsList={companies} placement='right' isMenuOpen={openEntityMenu} openMenu={() => setOpenEntityMenu(false)}>
									<Button type='link' onClick={() => setOpenEntityMenu(true)}>
										{t('addEntity')} <Icon imgType='add' color='primary' />
									</Button>
								</MenuList>
							</div>

							{platformList
								.filter((entity) => entity.connectionStatus != IntegrationStatus.UNLINKED)
								.map((item) => (
									<ListItem
										key={item.id}
										item={item}
										onDisconnect={() => item?.supplier?.id && onDisconnect?.(item?.supplier?.id)}
										onCancel={() => {
											item?.supplier?.id && item?.id && onCancel?.(item?.supplier?.id, item?.id);
										}}
										onMissingDetails={() => {
											item?.supplier?.id && item?.id && onMissingDetails?.(item?.id);
										}}
										onClick={() => {
											item?.supplier?.id && onClickEntity?.(item?.supplier?.id, item?.connectionStatus);
										}}
										onReconnect={() => item?.supplier?.id && onConnectSupplierToPlatform(item?.supplier?.id)}
										onRemove={() => item?.id && onRemoveIntegration(dispatch, item.provider, item?.id)}
									/>
								))}
						</>
					) : (
						<div className={styles.emptyContainer}>
							<EmptyStateCard
								src={images.emptyIntegration}
								emptyIconClassName={styles.imgContainerStyle}
								title={t('connectEntitiesAccount').replace('__', platform?.replaceAll('_', ' ').toLowerCase())}
								subTitle={t('connectSubtitle')}
							>
								<MenuList optionsList={companies} isMenuOpen={openEntityMenu} openMenu={() => setOpenEntityMenu(false)}>
									<Button onClick={() => setOpenEntityMenu(true)} className={styles.emptyIntegrationButton}>
										{t('addEntity')} <Icon imgType='add' color='white' />
									</Button>
								</MenuList>
							</EmptyStateCard>
						</div>
					)}
				</div>

				{!!platformList.length && (
					<div className={styles.footer}>
						<Button onClick={closeModal} className={styles.footerButton}>
							{t('done')}
						</Button>
					</div>
				)}
			</Modal>
			<CreateSupplier
				companiesListLength={companies?.length}
				isOpen={showCreateEntityModal}
				closeModal={() => setShowCreateEntityModal(false)}
				onCreateUpdateSupplier={addCompany}
				overlayClassName={styles.newEntityModel}
			/>
		</>
	);
};
