export const tabIndexes = {
	BANK_NAME: 1,
	BANK_CODE: 2,
	ACCOUNT_NUMBER: 3,
	ACCOUNT_NAME: 4,
	BRANCH: 5,
	ADDRESS: 6,
	ROUTING: 7,
	SWIFT: 8,
	IBAN: 9,
};
