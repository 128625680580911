import { useEffect, useState } from 'react';
import { CountrySelectItem, SelectItem } from '../../../../types/generalTypes';
import { Select, SelectProps } from '../Select/Select';

export interface CountriesSelectProps extends SelectProps {
	data: CountrySelectItem[];
	showOnlyPrefix?: boolean;
}

export const CountriesSelect = ({ showOnlyPrefix, defaultValue, data, onChange, ...props }: CountriesSelectProps) => {
	const [label, setLabel] = useState(defaultValue);

	useEffect(() => {
		defaultValue && getDefaultLabel();
	}, [defaultValue]);

	const formatData = (options: CountrySelectItem[]) => {
		return options.map((item) => ({ value: item.value, label: `${item.prefix} - ${item.label}` }));
	};

	const filterOptions = (input: string) => {
		const res = data.filter(
			(item) =>
				item.label.toString().toLowerCase().startsWith(input.toLowerCase()) ||
				item.prefix.toString().toLowerCase().startsWith(input.toLowerCase()) ||
				`${item.prefix} - ${item.label}`.toLowerCase().startsWith(input.toLowerCase()),
		);
		return formatData(res);
	};

	const onChangeValue = (value: string, item: SelectItem) => {
		setLabel(value ? (showOnlyPrefix ? item.label.toString().split(' - ')[0] : label) : '');
		onChange(value, item);
	};

	const getDefaultLabel = () => {
		const item = data.find((item) => item.value === defaultValue);
		if (item) {
			setLabel(showOnlyPrefix ? item.prefix : `${item.prefix} - ${item.label}`);
		}
	};

	return <Select {...props} data={formatData(data)} onChange={onChangeValue} isSearchable onCustomSearch={filterOptions} defaultValue={label} />;
};
