import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { images } from '../../constants/images';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { Button, Input, Modal } from '../_uiComponents';
import styles from './CreateGroupModal.module.scss';

export interface CreateGroupModalProps {
	isOpen: boolean;
	returnCreatedId?: (id: string) => void;
	closeModal(): void;
}

export const CreateGroupModal = ({ isOpen, returnCreatedId, closeModal }: CreateGroupModalProps) => {
	const { t } = useTranslation('translation');
	const [countedUnit, setCountedUnit] = useState<string>();
	const [typeDescription, setTypeDescription] = useState<string>();

	const dispatch = useDispatch();

	const onAddGroup = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.createUsageType,
				data: {
					countedUnit,
					description: typeDescription,
				},
			});
			returnCreatedId && returnCreatedId(res.data.id);
			closeModal();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<Modal isOpen={isOpen} closeModal={closeModal} className={styles.modalContainer} overlayClassName={styles.modalOverlay}>
			<img alt='add-model-icon' src={images.addGroupType} className={styles.icon}></img>
			<div className={styles.title}>{t('Create New UsageType')}</div>

			<div className={styles.contentContainer}>
				<Input title={t('Usage Type Unit')} maxLength={20} value={countedUnit} onChange={(value) => setCountedUnit(value)} className={styles.input} />
				<Input
					title={t('Usage Type Description')}
					maxLength={50}
					value={typeDescription}
					onChange={(value) => setTypeDescription(value)}
					className={styles.input}
				/>
			</div>

			<Button onClick={onAddGroup} className={styles.footerButtons} disabled={!typeDescription || !countedUnit}>
				{t('Add Type')}
			</Button>
			<Button type='link' color='neutral' onClick={closeModal} className={styles.footerButtons}>
				{t('Cancel')}
			</Button>
		</Modal>
	);
};
