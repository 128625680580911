import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import clsx from 'clsx';
import {
	Chart as ChartJS,
	ChartData,
	LineController,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	CategoryScale,
	ChartArea,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../../../types/storeTypes';
import { colors } from '../../../../constants/colors';
import { numberWithCommas, priceNumberGraphsDisplay } from '../../../../utils/NumberUtils';
import { hexToRGBAColor } from '../../../../utils/ColorUtils';
import { EmptyStateCard, Icon } from '../..';
import { images } from '../../../../constants/images';
import styles from './LineGraph.module.scss';

ChartJS.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Filler, Legend);

export interface LineDataset {
	data: Array<number | null>;
	backgroundColor?: string;
	borderColor?: string;
	borderDash?: number[];
}

export interface LineGraphDataset {
	labels: string[];
	data: LineDataset[];
	isEmpty?: boolean;
	emptyTitle?: string;
	emptySubTitle?: string;
	isCashflow?: boolean;
	isGradient?: boolean;
	containerClassName?: string;
}

export const LineGraph = ({ labels, data, isEmpty, emptyTitle, emptySubTitle, isCashflow, isGradient, containerClassName }: LineGraphDataset) => {
	const { appCurrency } = useSelector((store: Store) => store.general);

	const chartRef = useRef<ChartJS>(null);
	const [chartData, setChartData] = useState<ChartData<any>>({
		datasets: [],
	});

	useEffect(() => {
		const chart = chartRef?.current;

		if (!chart) return;

		const chartData = {
			labels: labels,
			datasets: data.map((dataset) => ({
				fill: true,
				borderColor: dataset.borderColor,
				borderDash: dataset.borderDash,
				data: dataset.data,
				backgroundColor: isGradient ? createGradient(chart.ctx, chart.chartArea, dataset?.backgroundColor) : 'transparent',
				cubicInterpolationMode: 'monotone',
				tension: 0.4,
			})),
		};

		setChartData(chartData);
	}, [labels, data]);

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		radius: 0,
		borderWidth: 1,
		showTooltips: true,
		elements: {
			line: {
				tension: 0.5,
			},
		},
		animations: {
			tension: {
				duration: 1000,
				easing: 'linear',
				from: 0.5,
				to: 0,
				loop: false,
				delay: 800,
			},
		},
		hover: {
			mode: 'index',
			intersect: false,
		},
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				titleFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				bodyFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				backgroundColor: colors.neutral900,
				padding: 15,
				boxWidth: 1,
				boxHeight: 1,
				mode: 'index',
				intersect: false,
				callbacks: {
					label: (context: any) => {
						let label = '';
						if (context.parsed.y) {
							label = ` ${isCashflow ? convertCurrencyToSign(appCurrency) : ''}${numberWithCommas(context.parsed.y)}`;
						}
						return label;
					},
				},
			},
		},
		scales: {
			x: {
				grid: {
					display: false,
					borderColor: colors.white,
				},
				ticks: {
					display: false,
					color: colors.white,
					fontSize: 12,
				},
			},
			y: {
				display: false,
				min: 0,
				grid: {
					display: false,
				},
				ticks: {
					display: false,
					fontSize: 12,
					callback: (value: number) => `${isCashflow ? convertCurrencyToSign(appCurrency) : ''}${priceNumberGraphsDisplay(value)}`,
				},
			},
		},
	};

	function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea, backgroundColor?: string) {
		const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

		gradient.addColorStop(1, hexToRGBAColor(backgroundColor || colors.neutral200, '1'));
		gradient.addColorStop(0.1, 'rgba(255,255,255,0)');
		gradient.addColorStop(0, 'transparent');
		return gradient;
	}

	return (
		<div className={clsx(styles.container, containerClassName)}>
			{isEmpty ? (
				<div className={styles.emptyContainer}>
					<Icon imgType='emptyGraph' className={styles.icon} color='neutral400' />
					<EmptyStateCard
						src={images.emptyGraph}
						imgAlt={'empty-graph'}
						title={emptyTitle}
						subTitle={emptySubTitle}
						containerStyle={styles.textContainer}
					/>
				</div>
			) : (
				<Chart type='line' options={options} ref={chartRef} data={chartData} className={styles.container} style={{ height: '100%', width: '100%' }} />
			)}
		</div>
	);
};
