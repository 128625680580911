import { UserDto } from '../../../types/userTypes';
import { isValidEmail } from '../../../utils/validationUtils';

interface PersonalInfoErrors {
	nameError: boolean;
	surNameError: boolean;
	emailError: boolean;
	phoneError: boolean;
	birthdayError: boolean;
	workAnniversaryError: boolean;
}

export const personalInfoErrors = {
	nameError: false,
	surNameError: false,
	emailError: false,
	phoneError: false,
	countryCodeError: false,
	birthdayError: false,
	workAnniversaryError: false,
};

export const personalInfoValidation = (userData?: UserDto) => {
	const userErrors = { ...personalInfoErrors };
	let isValid = true;

	if (!userData?.name) {
		userErrors.nameError = true;
		isValid = false;
	}
	if (!userData?.surName) {
		userErrors.surNameError = true;
		isValid = false;
	}

	if (userData?.email ? !isValidEmail(userData?.email) : true) {
		userErrors.emailError = true;
		isValid = false;
	}

	if (userData?.phone?.phone || userData?.phone?.countryCode) {
		if (!userData?.phone?.phone || isNaN(Number(userData?.phone?.phone)) || userData?.phone?.phone.length < 7) {
			userErrors.phoneError = true;
			isValid = false;
		}
		if (!userData?.phone?.countryCode || isNaN(Number(userData?.phone?.countryCode))) {
			userErrors.countryCodeError = true;
			isValid = false;
		}
	}
	return { isValid, errors: userErrors };
};
