import React, { CSSProperties, useState } from 'react';
import clsx from 'clsx';
import { Button, ChosenTabItem, Icon, TabsClassNames } from '../..';
import styles from './TabItem.module.scss';

export interface TabItemProps {
	id?: string;
	index: number;
	tab: ChosenTabItem | string;
	activeTab: number;
	tabStyle?: CSSProperties;
	style?: CSSProperties;
	classNames?: TabsClassNames;
	disabledActions?: boolean;
	onTabPress(): void;
	onNameUpdate?: (name: string, index: number) => void;
	onTabClose?: (index: number) => void;
}

export const TabItem = ({ index, tab, activeTab, tabStyle, classNames, id, disabledActions, onNameUpdate, onTabPress, onTabClose }: TabItemProps) => {
	const [startEdit, setStartEdit] = useState(false);
	const [newName, setNewName] = useState(typeof tab === 'object' ? tab.labelTitle || tab.label : tab);

	const onUpdateName = () => {
		if ((typeof tab === 'object' ? tab.labelTitle || tab.label : tab) !== newName) {
			onNameUpdate && onNameUpdate(newName, index);
		}
	};

	return (
		<div
			id={id}
			onClick={onTabPress}
			className={clsx(
				styles.tabComponent,
				classNames?.tabComponent,
				activeTab === index && styles.activeTab,
				activeTab === index && classNames?.activeTab,
			)}
			style={tabStyle}
			data-testid={`tabs-button-${typeof tab === 'object' ? tab.label : tab}`}
			onDoubleClick={() => !disabledActions && onNameUpdate && setStartEdit(true)}
		>
			{startEdit ? (
				<input
					autoFocus
					value={newName}
					className={styles.inputContainer}
					onChange={(event) => setNewName(event.target.value)}
					maxLength={30}
					disabled={disabledActions}
					onBlur={() => {
						setStartEdit(false);
						onUpdateName();
					}}
					onKeyDown={(event) => {
						if (event.key === 'Enter') {
							setStartEdit(false);
							onUpdateName();
						}
					}}
				/>
			) : (
				<>
					{typeof tab === 'object' && tab.iconImgType && (
						<Icon imgType={tab.iconImgType} color={activeTab === index ? 'primary' : 'neutral700'} className={styles.tab_icon} />
					)}
					{typeof tab === 'object' ? (
						<div className={clsx(styles.tabLabels, classNames?.tabLabels, activeTab === index && classNames?.tabActiveLabels)}>
							<span className={clsx(styles.tabTitleLabel, classNames?.tabTitleLabel, activeTab === index && classNames?.activeTabLabel)}>
								{tab?.labelTitle}
							</span>
							<span className={clsx(styles.tabLabel, classNames?.label, activeTab === index && classNames?.activeLabel)}>{tab.label}</span>
						</div>
					) : (
						tab
					)}
				</>
			)}
			{!disabledActions && onTabClose && (
				<Button type='link' onClick={() => onTabClose(index)} className={styles.closeButton}>
					<Icon imgType='x_icon' color='neutral' className={styles.x_icon} />
				</Button>
			)}
		</div>
	);
};
