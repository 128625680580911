import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ContractType } from '@received/pricing-model';
import {
	Button,
	CustomerDetails,
	Tabs,
	Sidebars,
	SideBarFilters,
	FilterSideBar,
	Icon,
	FilterTags,
	ImportItemsProgress,
	CreateCustomer,
	MenuList,
	ActionModalModal,
	DateRangeFilters,
} from '../../../components';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { SinglePartnerPageTabIndex, isTabHasTimeFilters, singlePartnerPageTabs } from './SingleResellerPage.utils';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../components/Filters/DateRangeFilters.utils';
import { DatesData } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { FilterSideBarOptions } from '../../../components/FilterSideBar/FilterSideBar.utils';
import { Customer } from '../../../types/customerTypes';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { images } from '../../../constants/images';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import { singlePartnerOptionList } from '../SingleReferralPage/SingleReferralPage.utils';
import styles from './SingleResellerPage.module.scss';

export const SingleResellerPage = () => {
	const { t } = useTranslation('translation');
	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);

	const [customerData, setCustomerData] = useState<Customer>();
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [refreshTabs, setRefreshTabs] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [openFilter, setOpenFilter] = useState(false);
	const [sideBarFilters, setSideBarFilters] = useState<SideBarFilters>({ filterData: {}, filterTags: [] });
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [isContractOptionsOpen, setIsContractOptionsOpen] = useState(false);
	const [isCreateCustomerOpen, setIsCreateCustomerOpen] = useState(false);

	const customerTabs = singlePartnerPageTabs().map((tab) => t(tab.title));

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location: any = useLocation();
	const { id } = useParams();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
		id ? getCustomer(id) : navigate('../referrals');
	}, []);

	const getCustomer = async (id: string) => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getCustomerData,
				urlParam: { id },
			});
			setCustomerData(res.data);

			if (location.state?.activeTab) {
				const tabIndex = singlePartnerPageTabs().findIndex((tab) => tab?.tabIndex === location.state?.activeTab);
				setActiveTab(tabIndex >= 0 ? tabIndex : 0);
			}
			location.state?.contractId && handleShowContract();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.FAILED_LOAD_CUSTOMERS }));
			!customerData && navigate('../resellers');
		}
	};

	const handleShowContract = () => {
		setOpenSideBarById({ sideBarType: SidebarType.CONTRACT, id: location.state?.contractId });
		//reset location state
		navigate('.', { replace: true });
	};

	const agreementsOptions = [
		{
			title: t('resellerAgreements'),
			onPress: async () => {
				const contractId = await createContract(dispatch, ContractType.RESELLER, customerData?.id);
				contractId && navigate('../reseller-agreement', { state: { contractId, customer: customerData } });
			},
		},
		{
			title: t('referralAgreements'),
			onPress: async () => {
				const agreementId = await createContract(dispatch, ContractType.REFERRAL, customerData?.id);
				agreementId && navigate('../referral-agreement', { state: { contractId: agreementId, customer: customerData } });
			},
		},
	];

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.customersContainer}>
				<ImportItemsProgress
					onRefresh={() => {
						setRefreshTabs(true);
						setTimeout(() => setRefreshTabs(false));
					}}
				/>
				<Sidebars
					openSideBarById={openSideBarById}
					onCloseSideBars={() => setOpenSideBarById(undefined)}
					onDataChange={() => {
						customerData?.id && getCustomer(customerData?.id);
					}}
					selectedCustomer={customerData}
				/>

				<FilterSideBar
					filterData={sideBarFilters}
					isOpen={openFilter}
					filterOption={FilterSideBarOptions.SINGLE_CUSTOMER_PAGE}
					closeSideBar={() => setOpenFilter(false)}
					updateFilters={setSideBarFilters}
				/>

				<CreateCustomer
					isOpen={isCreateCustomerOpen}
					closeModal={() => setIsCreateCustomerOpen(false)}
					onCustomerAdded={async () => {
						setIsCreateCustomerOpen(false);
						customerData?.id && (await getCustomer(customerData?.id));
					}}
					customer={customerData}
				/>

				<ActionModalModal
					rightButton={{ title: 'delete', color: 'destructive' }}
					isOpen={isDeleteModalOpen}
					imagesOptions={images.deleteItem}
					deleteMessage={
						<>
							{t('deleteMessagePrefix').replace('__', 'partner')}
							<span className={styles.name}> {customerData?.name}</span> {t('deleteMessagePostfix').replace('__', 'partner')}
						</>
					}
					onAccept={() => {}}
					onClose={() => setIsDeleteModalOpen(false)}
				/>

				<div className={styles.customersHeaderContainer} style={{ height: sideBarFilters?.filterTags.length ? '18.5rem' : '14rem' }}>
					<div className={styles.goBackContainer}>
						<Button type='link' color='neutral' onClick={() => navigate('../resellers')} className={styles.goBack}>
							{t('resellers')}
						</Button>

						<div className={styles.ellipsis}>{`/ ${customerData?.name}`} </div>
					</div>

					<header className={styles.customersHeader}>
						<CustomerDetails {...customerData} tagText={t('reseller')} nameStyle={styles.ellipsis} />

						<div className={styles.buttonsContainer}>
							{isTabHasTimeFilters(singlePartnerPageTabs()[activeTab]?.tabIndex) && (
								<>
									<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />
									<Button onClick={() => setOpenFilter(true)} type='outlined' color='neutral' className={styles.filterButton}>
										{t('filters')}
										<Icon imgType='filters' color='neutral' height={2} />
									</Button>
								</>
							)}

							<MenuList optionsList={agreementsOptions} isMenuOpen={isContractOptionsOpen} openMenu={() => setIsContractOptionsOpen(false)}>
								<Button color='success' onClick={() => setIsContractOptionsOpen(true)} className={styles.customerButtons}>
									{t('new')} +
								</Button>
							</MenuList>

							<MenuList
								isMenuOpen={isOptionsMenuOpen}
								openMenu={() => setIsOptionsMenuOpen(false)}
								optionsList={singlePartnerOptionList(
									dispatch,
									() => setIsCreateCustomerOpen(true),
									() => setIsDeleteModalOpen(true),
									customerData,
								)}
							>
								<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)} className={styles.hamburgerButton}>
									<Icon imgType='hamburger' color='neutral700' />
								</Button>
							</MenuList>
						</div>
					</header>

					<FilterTags
						filterData={sideBarFilters}
						onRemoveTag={(newTags) => sideBarFilters && setSideBarFilters((prev) => ({ ...prev, ...newTags }))}
					/>
				</div>

				<Tabs
					chosenTab={activeTab}
					tabsArray={customerTabs}
					updateChosenTab={(val: number) => setActiveTab(val)}
					style={{ padding: '0 2.4rem' }}
					classNames={styles}
				/>
				<div
					className={styles.tabsContent}
					style={{
						height: [SinglePartnerPageTabIndex.analytics].includes(singlePartnerPageTabs()[activeTab].tabIndex)
							? 'auto'
							: sideBarFilters?.filterTags.length
							? 'calc(100% - 23.5rem)'
							: 'calc(100% - 19.5rem)',
					}}
				>
					{singlePartnerPageTabs()[activeTab].component({
						customerData,
						timePeriod,
						sideBarFilters,
						refreshTabs,
						setOpenSideBarById,
						isActive: activeTab == singlePartnerPageTabs().findIndex((tab, index: number) => index == activeTab),
					})}
				</div>
			</div>
		</div>
	);
};
