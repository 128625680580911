import { Dispatch } from '@reduxjs/toolkit';
import { DEFAULT_CURRENCY, DocumentState, convertCurrencyToSign } from '@received/pricing-model';
import { Contract } from '../../../types/contractTypes';
import { Invoice } from '../../../types/invoiceTypes';
import { InvoiceDetails } from './InvoiceDetails/InvoiceDetails';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { DatePickerFormatType } from '../../_uiComponents';
import { UsageReportView } from '../../../types/usageTypes';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { CreditNotesSidebarTab, InvoicesSideBarTab, UsageTab } from '../components';
import { FooterItem } from '../components/SideBarFooter/SideBarFooter.utils';
import styles from './InvoicesSideBar.module.scss';

export interface InvoiceDetailsProps {
	hasUsageReport: boolean;
	onPressUpdateUsage?: (timePeriod?: DatePickerFormatType) => void;
}

export interface RelatedInvoicesProps {
	getSplittedInvoices: (invoiceId?: string) => any;
}

export interface InvoiceTabsProps {
	selectedRowId?: string;
	invoiceData?: Invoice;
	contractData?: Contract;
	invoiceDetailsProps: InvoiceDetailsProps;
	relatedInvoicesProps: RelatedInvoicesProps;
	sidebarType?: SidebarType;
	onUsageRowPress: (usageReport: UsageReportView) => void;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
}

export const invoiceTabs = (isInvoiceHasUsageProducts?: boolean) => {
	let tabs = [
		{ title: 'Details', component: (prop?: any) => <InvoiceDetails {...prop} /> },
		{ title: 'Related Invoices', component: (prop?: any) => <RelatedInvoices {...prop} /> },
	];
	if (isInvoiceHasUsageProducts) {
		tabs = [...tabs, { title: 'Usage', component: (prop?: any) => <UsageTab {...prop} /> }];
	}
	return [...tabs, { title: 'Credit Notes', component: (prop?: any) => <CreditNotesSidebarTab {...prop} /> }];
};

export const RelatedInvoices = ({ relatedInvoicesProps, invoiceData, contractData, selectedRowId, openOtherSidebar }: InvoiceTabsProps) => {
	const { getSplittedInvoices } = relatedInvoicesProps;
	return (
		<InvoicesSideBarTab
			selectedRowId={selectedRowId}
			invoiceData={invoiceData}
			contractData={contractData}
			onRowPress={(id?: string) => openOtherSidebar({ sidebarType: SidebarType.INVOICE, triggeredBy: SidebarType.INVOICE, id })}
			getSplittedInvoices={getSplittedInvoices}
			className={styles.invoiceTabContainer}
		/>
	);
};

export const onSplitInvoice = async (dispatch: Dispatch, invoiceId?: string, onSuccess?: () => void) => {
	if (!invoiceId) return;
	try {
		await httpService({
			dispatch,
			path: PathsConfig.splitInvoiceById,
			urlParam: { id: invoiceId },
		});
		onSuccess && onSuccess();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_SPLIT_INVOICE }));
	}
};

export const onDuplicateInvoice = async (dispatch: Dispatch, invoiceId?: string, onSuccess?: () => void) => {
	if (!invoiceId) return;
	try {
		await httpService({
			dispatch,
			path: PathsConfig.duplicateInvoiceById,
			urlParam: { id: invoiceId },
		});
		onSuccess && onSuccess();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const setFooterData = (invoice?: Invoice): FooterItem[] => {
	const currency = convertCurrencyToSign(invoice?.pricingModel?.tabs[0]?.currency || DEFAULT_CURRENCY);
	return [
		{
			title: 'subtotal',
			value: getValueWithCurrencySign(invoice?.subTotal, currency),
		},
		{
			title: 'totalDiscount',
			value: `(${getValueWithCurrencySign(invoice?.totalDiscount, currency)})`,
		},
		{
			title: 'totalTAX',
			value: getValueWithCurrencySign(invoice?.totalTax, currency),
		},
		{
			title: 'creditUsed',
			value: `(${getValueWithCurrencySign(invoice?.creditsAmount, currency)})`,
			isHidden: invoice?.state && Object.values(DocumentState).indexOf(invoice?.state) < Object.values(DocumentState).indexOf(DocumentState.SENT),
		},
		{
			title: 'totalDue',
			value: getValueWithCurrencySign(invoice?.total && invoice?.total - (invoice?.creditsAmount || 0), currency),
			isBlack: true,
		},
	];
};
