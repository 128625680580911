import { Dispatch } from '@reduxjs/toolkit';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { PricingModel } from '../../types/contractTypes';
import { AllTemplatesTab, MyProductsTab, SubscriptionsTab } from './PricingModelTabs';
import { ProductAndCategoriesItem } from '../../types/ProductTypes';
import { NodeEnvTypes } from '../../types/generalTypes';

export enum pricingTabsIndex {
	products,
	subscriptionPlans,
	allTemplates,
}

export enum pricingFilter {
	isFavorite = 'isFavorite',
	isArchived = 'isArchived',
}

export const pricingTabsList = () => {
	let list = [{ label: 'products', component: (prop?: any) => <MyProductsTab {...prop} /> }];
	if (window._env_.REACT_APP_NODE_ENV != NodeEnvTypes.PRODUCTION) {
		list = [...list, { label: 'subscriptionPlans', component: (prop?: any) => <SubscriptionsTab {...prop} /> }];
	}
	list = [...list, { label: 'allTemplates', component: (prop?: any) => <AllTemplatesTab {...prop} /> }];
	return list;
};

export const pricingSelectList = (t: any) => [
	{
		label: t('showAll'),
		value: '',
	},
	{
		label: t('favorites'),
		value: pricingFilter.isFavorite,
	},
	{
		label: t('archived'),
		value: pricingFilter.isArchived,
	},
];

export const filterCatalogPricingModels = (filter: pricingFilter | '', catalogPricingModels: PricingModel[], searchText = '') => {
	const filtered = catalogPricingModels.filter((item) =>
		item.name?.toLowerCase().includes(searchText.toLowerCase()) ? (filter ? item[filter] : item) : false,
	);
	return filtered;
};

export const filterProductsWithCatalogPricingModels = (filter: pricingFilter | '', products: ProductAndCategoriesItem[]) => {
	return products.map((product) => {
		const filtered = product.pricingModels.filter((item) => (filter ? item[filter] : item));
		return { ...product, count: filtered.length, pricingModels: filtered };
	});
};

export const onDuplicateCurrentModel = async (id: string, dispatch: Dispatch) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.duplicateProduct,
			urlParam: { id },
		});

		dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.PRODUCT_DUPLICATED }));
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DUPLICATE_PRODUCT }));
	}
};
