import { PricingType } from '@received/pricing-model';
import {
	initialStackBarGraphNumberFormat,
	initialStackBarGraphState,
} from '../../../../components/_uiComponents/Graphs/StackBarGraph/StackBarGraph.utils';
import { GraphState, ProductAnalyticsList } from '../../../../types/generalTypes';
import { RevenueSettings } from '../../../../types/contractTypes';

export interface RevRecGraphState extends GraphState {
	total?: number;
}

export interface OverviewProduct {
	isSelected?: boolean;
	color?: string;
	id?: string;
	name?: string;
	productName?: string;
	revenueSettings?: RevenueSettings;
	discount?: number;
	tax?: number;
	subTotal?: number;
	pricingType?: PricingType;
}

export interface GraphsDataResponse {
	count?: number;
	entries: ProductAnalyticsList[];
}
export interface ProductsSummaryCalculations {
	totalDiscount: number;
	discount?: number;
	totalTAX: number;
	tax?: number;
	totalModels: number;
	isEstimatedTotal?: boolean;
	subTotalAmount: number;
}

export interface AmountsSummary {
	subTotal?: number;
	totalDiscount?: number;
	totalTax?: number;
	totalDue?: number;
	discountPercent?: number;
	taxPercent?: number;
}

export interface CashInflowData extends GraphsDataResponse {
	summary: AmountsSummary;
}

export const initialGraphState: RevRecGraphState = {
	isEmpty: false,
	data: initialStackBarGraphState,
	dataFormat: initialStackBarGraphNumberFormat,
	total: 0,
};

export const getProductsCalculations = (pricingModelTabsList?: OverviewProduct[]) => {
	let totalDiscount = 0;
	let totalTAX = 0;
	let totalModels = 0;
	let subTotalAmount = 0;

	pricingModelTabsList?.forEach((tab) => {
		const subTotal = tab?.subTotal || 0;
		const discount = tab?.discount || 0;
		const tax = tab?.tax || 0;

		const discountValue = (subTotal * discount) / 100;
		const taxValue = ((subTotal - discountValue) * tax) / 100;
		const totalValue = subTotal - discountValue + taxValue;

		totalDiscount = totalDiscount + discountValue;
		totalTAX = totalTAX + taxValue;
		totalModels = totalModels + totalValue;
		subTotalAmount = subTotalAmount + subTotal;
	});

	return {
		totalDiscount,
		totalTAX,
		totalModels,
		subTotalAmount,
		tax: pricingModelTabsList?.length == 1 ? pricingModelTabsList[0].tax : undefined,
		discount: pricingModelTabsList?.length == 1 ? pricingModelTabsList[0].discount : undefined,
	};
};
