import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RevenueFeatureDisabled, Table } from '../../../components';
import { AccountPermissionsActionsList, Sort, SortOrder } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { revRecTabTableHeader } from './RevRecTab.utils';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { httpService } from '../../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { PermissionsMapping } from '../../../services/PermissionsMapping/PermissionsMapping';
import { RevRecView } from '../../../types/revRecTypes';
import { CustomerTabProps } from '../CustomerTabs.utils';
import styles from '../RevRecTab/RevRecTab.module.scss';

const defaultNumberOfItems = '20';

export const RevRecTab = ({ customerData, isActive, sideBarFilters, refreshTabs, timePeriod }: CustomerTabProps) => {
	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);

	const [revRecList, setRevRecList] = useState<RevRecView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: 'postingDate', order: SortOrder.ASC });

	const { appCurrency } = useSelector((store: Store) => store.general);
	const dispatch = useDispatch();

	useEffect(() => {
		isActive && getRevRecData();
	}, [
		customerData,
		numberOfItems,
		pageNumber,
		sort,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
		isActive,
		refreshTabs,
		timePeriod,
	]);

	const getRevRecData = async () => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getRevenueTableView,
					params: {
						customerIds: [customerData?.id],
						pageSize: parseInt(numberOfItems),
						pageNumber: pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						fromDate: timePeriod.from,
						toDate: timePeriod.to,
						...sideBarFilters?.filterData,
					},
				})
			).data;
			setTotalCount(res?.count);
			setRevRecList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.mainContainer}>
			<PermissionsMapping
				actionType={AccountPermissionsActionsList.VIEW_REV_REC}
				disabledFeatureChildren={<RevenueFeatureDisabled className={styles.disabledState} />}
			>
				<Table
					sort={sort}
					title={t('relatedRecords')}
					header={revRecTabTableHeader(appCurrency)}
					rows={revRecList}
					className={styles.tableContainer}
					numberOfItems={numberOfItems}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				/>
			</PermissionsMapping>
		</div>
	);
};
