import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { syncedStatusPageListHeader } from './SyncedStatusPage.utils';
import { DatesData, Sort, SortOrder } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../components/Filters/DateRangeFilters.utils';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Button, DateRangeFilters, ImportItemsProgress, Table } from '../../../components';
import styles from './SyncedStatusPage.module.scss';

const defaultNumberOfItems = '20';

export const SyncedStatusPage = () => {
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);

	const [totalCount, setTotalCount] = useState(0);
	const [syncedStatusList, setSyncedStatusList] = useState<any[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [sort, setSort] = useState<Sort>({ orderBy: undefined, order: SortOrder.ASC });

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location: any = useLocation();

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getSyncedStatusList();
	}, [numberOfItems, pageNumber, timePeriod, toastNotification.showToastNotification, sort]);

	const getSyncedStatusList = async () => {
		try {
			// TODO return when api is ready
			// const res = (
			// 	await httpService({
			// 		dispatch,
			// 		path: PathsConfig.getSyncedStatusList,
			// 		params: {
			// 			pageSize: parseInt(items || numberOfItems),
			// 			pageNumber: page || pageNumber,
			// 			fromDate: timePeriod?.from,
			// 			toDate: timePeriod?.to,
			// 			orderBy: sort.orderBy,
			// 		},
			// 	})
			// ).data;
			// setSyncedStatusList(res)
			// setTotalCount()
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container}>
				<ImportItemsProgress onRefresh={() => getSyncedStatusList()} />
				<div className={styles.goBackContainer}>
					<Button type='link' color='neutral' onClick={() => navigate('../')} className={styles.goBack}>
						{t('dashboard')}
					</Button>

					<div className={styles.ellipsis}> / {t('syncedStatus')}</div>
				</div>
				<header className={styles.header}>
					<h1 data-testid='upForRenewal-title'>{t('syncedStatus')}</h1>
					<div className={styles.buttonsContainer}>
						<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />
					</div>
				</header>

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={syncedStatusPageListHeader}
					rows={syncedStatusList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				/>
			</div>
		</div>
	);
};
