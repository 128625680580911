import { convertCurrencyToSign, DATE_FORMAT, DEFAULT_CURRENCY } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { images } from '../../../../constants/images';
import { Contract, PricingModelTab } from '../../../../types/contractTypes';
import { AccountPermissionsActionsList } from '../../../../types/generalTypes';
import { getValueWithCurrencySign } from '../../../../utils/NumberUtils';
import { ProductsSummaryCalculations, getProductsCalculations } from './ContractOverview.utils';
import { PermissionsMapping } from '../../../../services/PermissionsMapping/PermissionsMapping';
import { RevenueRecognitionComponent, Schedule } from '../../../../components/_featureComponents';
import { CashInflow } from '../../../../components';
import { getUniqueListValues } from '../../../../utils/GeneralUtils';
import styles from './ContractOverview.module.scss';

interface ContractOverviewProps {
	contract: Contract;
	isValid?: boolean;
	isPartner: boolean;
}

export const ContractOverview = ({ contract, isValid, isPartner }: ContractOverviewProps) => {
	const [contractCalculations, setContractCalculations] = useState<ProductsSummaryCalculations>();
	const [products, setProducts] = useState<PricingModelTab[]>([]);

	const { t } = useTranslation('translation');
	const currency = contract?.pricingModel?.tabs?.[0]?.currency || DEFAULT_CURRENCY;

	useEffect(() => {
		isValid && setData();
	}, []);

	const setData = async () => {
		const contractCalculations = getProductsCalculations(contract?.pricingModel?.tabs);
		setContractCalculations(contractCalculations);
		const uniqueProducts = getUniqueListValues(
			contract?.pricingModel?.tabs?.map((tab) => tab),
			'name',
		);
		setProducts(uniqueProducts);
	};

	return (
		<div className={styles.container}>
			{/* EMPTY STATE */}
			{!isValid ? (
				<div className={clsx(styles.fullBox, styles.emptyState)}>
					<div className={styles.emptyStateBody}>
						<img alt='overview-contract-empty-state' src={images.papers} className={styles.noDataImage} />
						<div className={styles.flexColumnCenter}>
							<span className={styles.noDataTitle}>{t('completeFields')}</span>
							<span className={styles.noDataDesc}>{t('addInformation')}</span>
						</div>
					</div>
				</div>
			) : (
				<>
					{/* CONTRACT DETAILS  */}
					<div className={styles.contractDetails}>
						<div className={clsx(styles.box, styles.leftBox)}>
							<div className={styles.boxContent}>
								<span className={styles.title}>{t(isPartner ? 'agreementActivation' : 'contractActivation')}</span>
								<span className={styles.text}>
									{dayjs(contract?.activationStartDate).format(DATE_FORMAT)}
									{contract?.activationEndDate ? ` - ${dayjs(contract?.activationEndDate).format(DATE_FORMAT)}` : ''}
								</span>
							</div>

							<div className={styles.boxContent}>
								<span className={styles.title}>{t('products')}</span>
								<span className={styles.text}>{contract?.pricingModel?.tabs?.length || 0}</span>
							</div>
						</div>

						<div className={clsx(styles.box, styles.rightBox)}>
							<div className={styles.boxContent}>
								<span className={styles.title}>{t('Subtotal')}</span>
								<span className={styles.text}>
									{getValueWithCurrencySign(contractCalculations?.subTotalAmount || 0, convertCurrencyToSign(currency))}
								</span>
							</div>

							<div className={styles.boxContent}>
								<span className={styles.title}>{t('discount')}</span>
								<span className={styles.text}>
									({getValueWithCurrencySign(contractCalculations?.totalDiscount, convertCurrencyToSign(currency))})
								</span>
							</div>

							<div className={styles.boxContent}>
								<span className={styles.title}>{t('tax')}</span>
								<span className={styles.text}>{getValueWithCurrencySign(contractCalculations?.totalTAX, convertCurrencyToSign(currency))}</span>
							</div>

							<div className={clsx(styles.boxContent, styles.wideBox)}>
								<span className={clsx(styles.title, styles.bold)}>{t('Total')}</span>
								<span className={styles.text}>{getValueWithCurrencySign(contractCalculations?.totalModels, convertCurrencyToSign(currency))}</span>
							</div>
						</div>
					</div>

					<CashInflow currency={currency} tabs={products} customerId={contract?.customerId} contractId={contract?.id} excludeDraftContracts={false} />
				</>
			)}
			{/* REV REC */}
			<PermissionsMapping
				actionType={AccountPermissionsActionsList.VIEW_REV_REC}
				disabledFeatureChildren={
					<RevenueRecognitionComponent
						customerId={contract?.customerId}
						contractId={contract.id}
						isValid={isValid}
						featureDisabled
						classNames={{ revRecContainer: styles.revRecContainer }}
						excludeDraftContracts={false}
					/>
				}
			>
				<RevenueRecognitionComponent
					filterByRevenueRecognitionMethod
					contractId={contract.id}
					customerId={contract?.customerId}
					isValid={isValid}
					classNames={{ revRecContainer: styles.revRecContainer }}
					currency={currency}
					excludeDraftContracts={false}
				/>
			</PermissionsMapping>

			<PermissionsMapping
				actionType={AccountPermissionsActionsList.VIEW_REV_REC}
				disabledFeatureChildren={
					<Schedule
						currency={currency}
						customerId={contract?.customerId}
						contractId={contract.id}
						isValid={isValid}
						featureDisabled
						activationDates={{
							startActivationDate: dayjs(contract?.activationStartDate).toString(),
							endActivationDate: dayjs(contract?.activationEndDate).toString(),
						}}
						excludeDraftContracts={false}
					/>
				}
			>
				<Schedule
					filterByRevenueRecognitionMethod
					currency={currency}
					customerId={contract?.customerId}
					contractId={contract.id}
					isValid={isValid}
					activationDates={{
						startActivationDate: dayjs(contract?.activationStartDate).toString(),
						endActivationDate: dayjs(contract?.activationEndDate).toString(),
					}}
					excludeDraftContracts={false}
				/>
			</PermissionsMapping>
		</div>
	);
};
