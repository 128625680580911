import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../hooks/generalHooks';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { CustomerDetails } from '../CustomerDetails/CustomerDetails';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { Button, Icon, MenuList, MenuOption, SearchBar } from '../_uiComponents';
import { Customer } from '../../types/customerTypes';
import styles from './CustomerSelect.module.scss';

export interface CustomerSelectProps {
	defaultCustomer?: Customer;
	moveHumbuggerButton?: boolean;
	small?: boolean;
	hideOptionButton?: boolean;
	hideEmailTooltip?: boolean;
	isOptionsOpen?: boolean;
	selectedCustomerStyle?: string;
	className?: string;
	showArrow?: boolean;
	selectContainer?: string;
	detailsClassName?: string;
	isPartner?: boolean;
	hideDetails?: boolean;
	onAddCustomer?: () => void;
	onCustomerSelected?(data?: Customer): void;
}

export const CustomerSelect = ({
	defaultCustomer,
	small,
	hideOptionButton,
	moveHumbuggerButton,
	selectedCustomerStyle,
	hideEmailTooltip,
	showArrow,
	className,
	selectContainer,
	detailsClassName,
	isPartner,
	hideDetails,
	onAddCustomer,
	onCustomerSelected,
}: CustomerSelectProps) => {
	const [customerList, setCustomerList] = useState<Customer[]>([]);
	const [isCustomerSelectOpen, setIsCustomerSelectOpen] = useState(false);
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>(defaultCustomer);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	const menuOptions: MenuOption[] = [{ title: 'Remove customer', onPress: () => onSelectCustomer(), iconType: 'delete', color: 'destructive' }];

	useEffect(() => {
		setSelectedCustomer(defaultCustomer);
	}, [defaultCustomer]);

	const getCustomersList = async (searchText?: string) => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getCustomersList,
				params: {
					textFilter: searchText,
					isPartner,
				},
			});
			setCustomerList(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_LIST }));
		}
	};

	const onSearch = useDebounce(getCustomersList, 450);

	const onSelectCustomer = (customer?: Customer) => {
		setSelectedCustomer(customer);
		onCustomerSelected && onCustomerSelected(customer);
		setIsCustomerSelectOpen(false);
	};

	const addCustomer = () => {
		setIsCustomerSelectOpen(false);
		getCustomersList();
		onAddCustomer && onAddCustomer();
	};

	const openSelect = () => {
		getCustomersList();
		setIsCustomerSelectOpen(true);
	};

	const onOpenList = () => {
		setOpenMenuOptions(!openMenuOptions);
		if (!openMenuOptions) {
			getCustomersList();
		}
	};

	return (
		<div className={clsx(styles.dataSelectContainer, small && defaultCustomer && styles.selectedSmallContainer, selectContainer)}>
			<div className={clsx(styles.customerDetails, detailsClassName)} onClick={selectedCustomer && openSelect}>
				{selectedCustomer ? (
					<>
						<CustomerDetails
							name={selectedCustomer?.name || ''}
							country={selectedCustomer?.country || ''}
							currency={selectedCustomer?.currency || ''}
							ein={selectedCustomer?.ein || ''}
							email={selectedCustomer?.email || ''}
							logo={selectedCustomer?.iconData || ''}
							small={small}
							hideTooltip={hideEmailTooltip}
							nameStyle={selectedCustomerStyle ?? styles.ellipsisSelected}
							style={{ width: '95%' }}
							className={className}
							showArrow={showArrow}
							arrowUp={isCustomerSelectOpen}
							hideDetails={hideDetails}
						/>
						{!hideOptionButton && (
							<MenuList optionsList={menuOptions} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
								<Button
									type='link'
									color='neutral'
									style={{ marginRight: moveHumbuggerButton ? '6rem' : 0 }}
									onClick={onOpenList}
									className={styles.hamburgerButton}
								>
									<Icon imgType='hamburger' className={styles.hamburger} />
								</Button>
							</MenuList>
						)}
					</>
				) : small ? (
					<SearchBar placeholder={t(isPartner ? 'searchAndAddPartner' : 'searchAndAddCustomer')} onChange={onSearch} onFocus={openSelect} />
				) : (
					<>
						<div className={styles.searchIcon} data-testid='customer-details-search'>
							<Icon imgType='search' color='primary' className={styles.hamburger} />
						</div>

						<input
							placeholder={t('search')}
							className={styles.inputContainer}
							onFocus={openSelect}
							onChange={(event) => onSearch(event.target.value)}
						/>
					</>
				)}
			</div>

			<div className={isCustomerSelectOpen ? styles.selectListContainer : styles.selectListContainerHidden}>
				<div className={styles.selectList}>
					{customerList.map((item, index) => (
						<div data-testid='customer-details-item' key={index} className={styles.selectItem} onClick={() => onSelectCustomer(item)}>
							<CustomerDetails
								name={item.name}
								country={item?.country}
								currency={item.currency}
								ein={item.ein}
								email={item.email}
								logo={item.iconData}
								small
								nameStyle={selectedCustomerStyle ?? styles.ellipsis}
								hideDetails={hideDetails}
							/>
						</div>
					))}
				</div>
				{onAddCustomer && (
					<div className={styles.selectAddButton} onClick={addCustomer}>
						{t(isPartner ? 'addNewPartner' : 'addCustomerButton')} +
					</div>
				)}
			</div>
			{isCustomerSelectOpen && <div className={styles.selectOverlay} onClick={() => setIsCustomerSelectOpen(false)}></div>}
		</div>
	);
};
