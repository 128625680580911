import dayjs from 'dayjs';
import { DATE_FORMAT_FOR_SERVER } from '../../../constants/templateConstants';
import { Contract } from '../../../types/contractTypes';

export interface ActivationDates {
	startActivationDate?: string;
	endActivationDate?: string;
}

export const getActivationDatesFromAllContracts = (contractsList: Contract[]) => {
	const sortedStartDates = contractsList.map((contract) => dayjs(contract.activationStartDate)).sort((a: any, b: any) => a - b);
	const sortedEndDates = contractsList.map((contract) => dayjs(contract.activationEndDate)).sort((a: any, b: any) => a - b);
	const startActivationDate = sortedStartDates[0];
	const endActivationDate = sortedEndDates[contractsList.length - 1];

	return {
		startActivationDate: dayjs(startActivationDate).format(DATE_FORMAT_FOR_SERVER),
		endActivationDate: dayjs(endActivationDate).format(DATE_FORMAT_FOR_SERVER),
	};
};
