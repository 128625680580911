import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_CURRENCY } from '@received/pricing-model';
import { DEFAULT_COUNTRIES_LIST, DEFAULT_STATES_LIST } from '../constants/generalConstants';
import { GeneralState } from '../types/storeTypes';

const initialState: GeneralState = {
	isSidebarOpen: true,
	showDashboardIntegrationBox: false,
	showCodatLoginPopup: false,
	geoLocationData: { countries: DEFAULT_COUNTRIES_LIST, usaStates: DEFAULT_STATES_LIST },
	showLoaderIds: [],
	showCreateIndicator: false,
	listenerResponseType: undefined,
	toastNotification: {
		showToastNotification: false,
		toastNotificationComponent: null,
		title: '',
		description: '',
	},
	updateSupplierDocumentPreview: true,
	importedDataIndicator: null,
	appCurrency: DEFAULT_CURRENCY,
};

export const generalReducer = createSlice({
	name: 'general',
	initialState,
	reducers: {
		setSidebarIsOpen: (state: GeneralState, payload) => {
			return { ...state, isSidebarOpen: payload.payload };
		},
		setOpenCodatLoginPopup: (state: GeneralState, payload) => {
			return {
				...state,
				showCodatLoginPopup: payload.payload,
			};
		},
		setGeoLocationOptionsData: (state: GeneralState, payload) => {
			return {
				...state,
				geoLocationData: { ...state.geoLocationData, ...payload.payload },
			};
		},
		setShowLoader: (state: GeneralState, payload) => {
			state.showLoaderIds.push(payload.payload);
		},
		setHideLoader: (state: GeneralState, payload) => {
			state.showLoaderIds = payload.payload ? state.showLoaderIds.filter((id) => payload.payload != id) : [];
		},
		setShowCreateIndicator: (state: GeneralState, payload) => {
			return { ...state, showCreateIndicator: payload.payload };
		},
		setListenerResponseType: (state: GeneralState, payload) => {
			return { ...state, listenerResponseType: payload.payload };
		},
		setShowHideToastNotification: (state: GeneralState, payload) => {
			return { ...state, toastNotification: { ...payload.payload } };
		},
		setUpdateSupplierDocumentPreview: (state: GeneralState, payload) => {
			return { ...state, updateSupplierDocumentPreview: payload.payload };
		},
		setShowDashboardIntegrationBox: (state: GeneralState, payload) => {
			return { ...state, showDashboardIntegrationBox: payload.payload };
		},
		setShowImportedDataIndicator: (
			state: GeneralState,
			payload: { payload: { totalItems: number; importedItems: number; barTitle?: string; cardTitle?: string } | null },
		) => {
			return { ...state, importedDataIndicator: payload.payload };
		},
		setAppCurrency: (state: GeneralState, payload) => {
			return { ...state, appCurrency: payload.payload };
		},
	},
});

export const {
	setSidebarIsOpen,
	setOpenCodatLoginPopup,
	setGeoLocationOptionsData,
	setShowLoader,
	setListenerResponseType,
	setShowHideToastNotification,
	setUpdateSupplierDocumentPreview,
	setShowDashboardIntegrationBox,
	setShowImportedDataIndicator,
	setShowCreateIndicator,
	setAppCurrency,
	setHideLoader,
} = generalReducer.actions;

export default generalReducer.reducer;
