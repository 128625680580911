import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import dayjs from 'dayjs';
import {
	Chart as ChartJS,
	ChartData,
	LineController,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	CategoryScale,
	ArcElement,
	DoughnutController,
	BarController,
	BarElement,
} from 'chart.js';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Currency, convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../../../types/storeTypes';
import { colors } from '../../../../constants/colors';
import { NumberFormat } from '../../../../types/generalTypes';
import { numberWithCommas } from '../../../../utils/NumberUtils';
import { EmptyStateCard } from '../..';
import { images } from '../../../../constants/images';
import { hexToRGBAColor } from '../../../../utils/ColorUtils';
import styles from './StackBarGraph.module.scss';

ChartJS.register(
	LineController,
	BarController,
	BarElement,
	ArcElement,
	DoughnutController,
	LineElement,
	PointElement,
	CategoryScale,
	Title,
	Tooltip,
	Filler,
	Legend,
);

export interface StackBarDataset {
	id?: string;
	name?: string;
	label?: string;
	data: Array<number | null>;
	backgroundColor?: string | string[];
	borderColor?: string | string[];
	borderRadius?: number;
	borderSkipped?: string | boolean;
}

export interface StackBarGraphData {
	labels: string[];
	datasets: StackBarDataset[];
}

export interface StackBarGraphProps {
	chartData: StackBarGraphData;
	isHorizontal?: boolean;
	isStacked?: boolean;
	isEmpty?: boolean;
	emptyTitle?: string;
	emptySubTitle?: string;
	isCashflow?: boolean;
	displayXLine?: boolean;
	showYGrid?: boolean;
	dataFormat: NumberFormat;
	customStyle?: CSSProperties;
	className?: string;
	currency?: Currency;
}

export const StackBarGraph = ({
	chartData,
	isHorizontal,
	isStacked,
	isEmpty,
	emptyTitle,
	emptySubTitle,
	isCashflow,
	displayXLine,
	showYGrid,
	dataFormat,
	customStyle,
	className,
	currency,
}: StackBarGraphProps) => {
	const { appCurrency } = useSelector((store: Store) => store.general);

	const chartRef = useRef<ChartJS>(null);
	const [data, setData] = useState<ChartData<any>>({
		datasets: [],
	});

	const { t } = useTranslation('translation');

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}

		chartData && setData({ labels: chartData?.labels, datasets: chartData?.datasets });
	}, [chartData?.datasets, chartData?.labels]);

	const options = {
		indexAxis: isHorizontal ? ('y' as const) : ('x' as const),
		responsive: true,
		barPercentage: 0.5,
		skipNull: false,
		categoryPercentage: 0.8,
		maxBarThickness: 9,
		borderSkipped: false,
		maintainAspectRatio: false,
		borderWidth: 1,
		hoverBorderWidth: 5,
		groupPadding: 0.1,
		pointPadding: 5,
		devicePixelRatio: 5,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				titleFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				bodyFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				backgroundColor: colors.neutral900,
				titleColor: colors.neutral400,
				padding: 15,
				boxWidth: 2,
				boxHeight: 2,
				position: 'nearest',
				mode: 'index',
				intersect: false,
				footerFont: 'TWKLausanne450',
				cornerRadius: 7,
				callbacks: {
					title: (context: any) => {
						const date = context[0].label.split(',')[1];
						return dayjs(date).format('MMMM YYYY');
					},
					label: (context: any) => {
						let label = '';
						if (context.parsed.y) {
							const numericValue = `${numberWithCommas(isCashflow ? Math.abs(context.parsed.y) : context.parsed.y, 0)}`;
							let numericLabel = `${isCashflow ? convertCurrencyToSign(currency || appCurrency) : ''}${numericValue}`;
							numericLabel = `${context.parsed.y < 0 && isCashflow ? `(${numericLabel})` : numericLabel}`;
							const checkForLongLabel = context.dataset.label.length > 20 ? `${context.dataset.label.substring(0, 20)}...` : context.dataset.label;
							label = ` ${checkForLongLabel ? `${checkForLongLabel} • ` : ''} ${numericLabel}`;
						}
						return label;
					},
				},
			},
		},
		scales: {
			x: {
				stacked: !!isStacked,
				borderColor: colors.white,
				grid: {
					lineWidth: 1.5,
					borderColor: colors.white,
					color: (context: any) => {
						const ctx = context.chart.ctx;
						const gradient = ctx.createLinearGradient(0, 200, 0, 0);
						gradient.addColorStop(0, hexToRGBAColor(colors.neutral200, '1'));
						gradient.addColorStop(0.4, hexToRGBAColor(colors.neutral200, '0.4'));
						gradient.addColorStop(1, hexToRGBAColor(colors.white, '0'));

						return gradient;
					},
					borderWidth: displayXLine ? 2 : 0,
					tickColor: colors.white,
				},
				ticks: {
					color: colors.neutral,
					fontSize: 12,
					font: {
						family: 'TWKLausanne250',
						size: window.innerWidth < 1200 ? 11 : 12,
					},
					callback: (value: number) => {
						const fullLabel = (data?.labels && (data?.labels[value] as string)) || '';
						const label = fullLabel.split(',')[0];
						return label;
					},
				},
			},
			y: {
				stacked: !!isStacked,
				borderColor: colors.white,
				grid: {
					borderColor: colors.white,
					lineWidth: 1,
					display: !!showYGrid,
				},
				ticks: {
					crossAlign: 'far',
					display: true,
					autoSkip: true,
					maxTicksLimit: 7,
					color: colors.neutral,
					fontSize: 12,
					font: {
						family: 'TWKLausanne250',
						size: 12,
					},
					callback: (value: number) => {
						const numericValue =
							value != 0 ? `${numberWithCommas((isCashflow ? Math.abs(value) : value) / dataFormat.divider)}${dataFormat.postfix}` : 0;
						const label = `${isCashflow ? convertCurrencyToSign(currency || appCurrency) : ''}${numericValue}`;
						return `${value < 0 && isCashflow ? `(${label})` : label}`;
					},
				},
				position: 'right',
			},
		},
	};

	return isEmpty ? (
		<div className={styles.emptyContainer}>
			<EmptyStateCard
				showIcon
				src={images.emptyGraph}
				imgAlt={'empty-graph'}
				title={emptyTitle || t('noDataSource')}
				subTitle={emptySubTitle || t('emptyStateSubtitle')}
			/>
		</div>
	) : (
		<Chart type='bar' options={{ ...options }} ref={chartRef} data={data} style={{ height: '100%', ...customStyle }} className={className} />
	);
};
