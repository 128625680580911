import dayjs from 'dayjs';
import { Dispatch } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { DATE_FORMAT, DocumentState, convertCurrencyToSign } from '@received/pricing-model';
import { Cell, LogoCell, PriceCell } from '../../../components/_uiComponents/Table/Table.utils';
import { numberWithCommas } from '../../../utils/NumberUtils';
import { colors } from '../../../constants/colors';
import { formatEmailList } from '../../../utils/UserUtils';
import { Payout, PayoutView, PayoutsSummary } from '../../../types/PayoutsTypes';
import { createCSVFile, netTermsToLabel, normalizeDataToCSVRows } from '../../../utils/GeneralUtils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { MenuOption, PayoutStatusTag } from '../../../components';
import { duplicatePayout, payoutStatus, startGeneratePayoutPDFById } from '../../../utils/PayoutUtils';
import { downloadDocumentUsageLogs } from '../../Usage/Usage.utils';

export const payoutsTableHeader = (refreshData?: () => void) => {
	return [
		{
			headerTitle: 'partner',
			accessor: 'partnerName',
			isSortable: true,
			CSVIndex: PayoutCSVTitles.PARTNER,
			CustomComponent: (item: PayoutView) => (
				<LogoCell title={item.partnerName} logoData={item.partnerIconData} subTitle={formatEmailList(item.partnerEmail)} />
			),
		},
		{
			headerTitle: 'issueDate',
			accessor: 'issueDate',
			isSortable: true,
			CSVIndex: PayoutCSVTitles.ISSUE_DATE,
			CustomComponent: (item: PayoutView) => <Cell accessor={`${dayjs(item.issueDate).format(DATE_FORMAT)}`} />,
		},
		{
			headerTitle: 'payoutNo',
			accessor: 'payoutNumber',
			isSortable: true,
			CSVIndex: PayoutCSVTitles.PAYOUT_NO,
			CustomComponent: (item: PayoutView) => (
				<Cell
					accessor={item?.payoutNumber}
					subAccessor={item?.payoutNumberInSeries ? `${item.payoutNumberInSeries}/${item.payoutSeriesTotalCount}` : ''}
				/>
			),
		},
		{
			headerTitle: 'agreementNo',
			accessor: 'agreementNumber',
			isSortable: true,
			CSVIndex: PayoutCSVTitles.AGREEMENT_NO,
			CustomComponent: (item: PayoutView) => (
				<Cell accessor={contractIdFormat(item?.poNumber, item?.agreementNumber)} subAccessor={item.agreementName} />
			),
		},
		{
			headerTitle: 'DueDate',
			accessor: 'payoutDueDate',
			isSortable: true,
			CSVIndex: PayoutCSVTitles.DUE_DATE,
			CustomComponent: (item: PayoutView) => <Cell accessor={formatPaymentDueDate(item)} subAccessor={`${netTermsToLabel(item?.netTerms)} `} />,
		},
		{
			headerTitle: 'totalFee',
			accessor: 'totalFee',
			isSortable: true,
			CSVIndex: PayoutCSVTitles.TOTAL_FEE,
			CustomComponent: (item: PayoutView) => <PriceCell mainNumber={item?.totalFee} currency={convertCurrencyToSign(item?.currency)} />,
		},

		{
			headerTitle: 'status',
			accessor: 'state',
			isSortable: true,
			disableRowClick: true,
			CSVIndex: PayoutCSVTitles.STATUS,
			CustomComponent: (item: PayoutView) => <PayoutStatusTag document={item} isShort refreshData={refreshData} />,
		},
	];
};

export const quantityBarData = (t: any, statusBarData?: PayoutsSummary) => {
	return [
		{
			title: t('pending'),
			description: `${numberWithCommas(statusBarData?.missingInfoCount) || 0} ${t('missingInfo')} | ${statusBarData?.readyCount || 0} ${t('ready')}`,
			quantity: statusBarData?.pendingSum || 0,
			color: colors.warning,
		},
		{
			title: t('late'),
			description: `${numberWithCommas(statusBarData?.lateCount) || 0} ${t('late')}`,
			quantity: statusBarData?.lateSum || 0,
			color: colors.destructive,
		},
		{
			title: t('paid'),
			description: `${numberWithCommas(statusBarData?.paidCount) || 0} ${t('sent')} `,
			quantity: statusBarData?.paidSum || 0,
			color: colors.success,
		},
	];
};

export const getPayoutSummaryData = async (dispatch: Dispatch, textFilter?: string) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getPayoutsSummary,
			params: { textFilter: textFilter || undefined },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};

interface PayoutsMenuOptionsProps {
	t: any;
	payout: Payout | PayoutView;
	dispatch: Dispatch;
	navigate: NavigateFunction;
	onEdit?: () => void;
	onRefresh?: () => void;
	onPreview?: () => void;
}

export const payoutsMenuOptions = ({ t, payout, dispatch, navigate, onEdit, onRefresh, onPreview }: PayoutsMenuOptionsProps) => {
	let listOptions: MenuOption[] = [];

	if (!payout?.editBlockers?.length) {
		listOptions = [
			...listOptions,
			{
				title: t('edit'),
				onPress: () => {
					onEdit?.() || navigate('../payouts/single-payout', { state: { payoutId: payout?.id } });
				},
				iconType: 'edit',
			},
		];
	}
	listOptions = [
		...listOptions,
		{
			title: t('Duplicate'),
			onPress: () => duplicatePayout(payout.id, dispatch, onRefresh),
			iconType: 'duplicate',
		},
		{
			title: t('downloadDocument'),
			onPress: () => startGeneratePayoutPDFById(dispatch, payout.id),
			iconType: 'download',
		},
	];

	if (payout?.hasUsageReports) {
		listOptions = [
			...listOptions,
			{
				title: t('downloadUsageReports'),
				onPress: () => payout?.hasUsageReports && downloadDocumentUsageLogs(payout.id ? [payout.id] : [], dispatch),
				iconType: 'download',
			},
		];
	}

	if (onPreview && payout.state !== DocumentState.CANCELED) {
		listOptions = [
			...listOptions,
			{
				title: t('preview'),
				onPress: onPreview,
				iconType: 'eye',
			},
		];
	}

	return listOptions;
};

export const formatPaymentDueDate = (payout: PayoutView): string => {
	switch (payout.state) {
		case DocumentState.LATE: {
			return `${dayjs().diff(payout.payoutDueDate, 'day')} days late`;
		}
		default: {
			const diff = dayjs().diff(payout.payoutDueDate, 'day');
			return diff < 0 ? `${Math.abs(diff)} days left` : `${diff} days ago`;
		}
	}
};

export const onDownloadPayoutsCSV = async (t: any, payoutsList: PayoutView[]) => {
	const titles: any = payoutsTableHeader().reduce((acc, header) => ({ ...acc, [header.CSVIndex]: t(header.headerTitle) }), {});
	titles[PayoutCSVTitles.CURRENCY] = t('currency');

	const data = payoutsList.map((payout) => ({
		[titles[PayoutCSVTitles.PARTNER]]: `"${payout.partnerName}"`,
		[titles[PayoutCSVTitles.ISSUE_DATE]]: `"${dayjs(payout.issueDate).format(DATE_FORMAT)}"` || '-',
		[titles[PayoutCSVTitles.PAYOUT_NO]]: payout?.payoutNumber,
		[titles[PayoutCSVTitles.AGREEMENT_NO]]: payout.agreementNumber,
		[titles[PayoutCSVTitles.DUE_DATE]]: `"${dayjs(payout.payoutDueDate).format(DATE_FORMAT)}"`,
		[titles[PayoutCSVTitles.TOTAL_FEE]]: `"${numberWithCommas(payout.totalFee)}"`,
		[titles[PayoutCSVTitles.STATUS]]: t(payoutStatus(payout.state).text),
		[titles[PayoutCSVTitles.CURRENCY]]: payout?.currency,
	}));

	const csvContent = normalizeDataToCSVRows(titles, data);
	createCSVFile(`data:text/csv;charset=utf-8,${csvContent}`, 'Payouts');
};

export enum PayoutCSVTitles {
	PARTNER,
	ISSUE_DATE,
	PAYOUT_NO,
	AGREEMENT_NO,
	DUE_DATE,
	TOTAL_FEE,
	STATUS,
	CURRENCY,
}
