import clsx from 'clsx';
import dayjs from 'dayjs';
import { AccountingEventType, DocumentState } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Payout, PayoutView } from '../../../types/PayoutsTypes';
import { Button, DatePicker, Icon, MenuList } from '../../_uiComponents';
import { ChangeStatusOption, PaidPeriod } from '../../InvoiceTable/InvoiceTable.utils';
import { FooterTypeOptions } from '../../_uiComponents/DatePicker/Footer/Footer';
import { payoutStatus } from '../../../utils/PayoutUtils';
import { changePayoutStatus } from './PayoutStatusTag.utils';
import { reconcileDocuments, submitDocuments } from '../../../utils/DocumentsUtils';
import styles from './PayoutStatusTag.module.scss';
import { StatusTagProps } from '../StatusTags.utils';

interface PayoutStatusTagProps extends StatusTagProps {
	document: PayoutView | Payout;
}

export const PayoutStatusTag = ({ document, isShort, disabled, className, refreshData }: PayoutStatusTagProps) => {
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [currentAction, setCurrentAction] = useState<ChangeStatusOption>();

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const payoutStatusData = payoutStatus(document?.state, isShort);

	const markDocumentAsPaid = async (period: PaidPeriod) => {
		setCurrentAction(ChangeStatusOption.MARK_AS_PAID_FROM_ROW);
		switch (period) {
			case PaidPeriod.TODAY:
				document?.id && (await reconcileDocuments(new Date(), [{ id: document.id, type: AccountingEventType.PAYOUT }], dispatch));
				refreshData?.();
				break;
			case PaidPeriod.CUSTOM:
				setOpenDatePicker(true);
				break;
		}
	};

	const onUpdateStatusFromDatePicker = async (date: Date) => {
		if (document?.id) {
			setOpenDatePicker(false);
			currentAction === ChangeStatusOption.MARK_AS_PAID_FROM_ROW
				? await reconcileDocuments(date, [{ id: document.id, type: AccountingEventType.PAYOUT }], dispatch)
				: await submitDocuments(date, [{ id: document.id, type: AccountingEventType.PAYOUT }], dispatch);
			setCurrentAction(undefined);
			refreshData?.();
		}
	};

	const onCloseDatePicker = (isOpen: boolean) => {
		setOpenDatePicker(isOpen);
		setCurrentAction(undefined);
	};

	const setCurrentActionForDocument = (currentAction: ChangeStatusOption) => {
		setOpenDatePicker(true);
		setCurrentAction(currentAction);
	};

	const payoutList = document && !disabled ? changePayoutStatus(document, markDocumentAsPaid, dispatch, t, setCurrentActionForDocument) : [];
	return (
		<>
			{document?.state !== DocumentState.CONTRACT_DRAFT && (
				<MenuList optionsList={payoutList} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
					<Button
						dataTestId='payout-status-button'
						onClick={(e) => {
							e.stopPropagation();
							setOpenMenuOptions(true);
						}}
						type={payoutStatusData.type}
						color={payoutStatusData.color}
						className={clsx(styles.button, isShort && styles.isShortContainer, className)}
						style={{ pointerEvents: document && payoutList.length ? 'auto' : 'none' }}
					>
						{t(payoutStatusData.text)}
						<div className={styles.iconContainer}>
							{document && payoutList.length != 0 && <Icon imgType='arrow_down' color={payoutStatusData.color} width={1} />}
						</div>
					</Button>
				</MenuList>
			)}

			<DatePicker
				hideInput
				openDatePicker={openDatePicker}
				footerType={FooterTypeOptions.SHOW_CONFIRM_BUTTONS}
				datePickerLabel={t('chooseDate')}
				onConfirm={onUpdateStatusFromDatePicker}
				updateIsOpen={onCloseDatePicker}
				defaultFromDate={document ? dayjs(document.issueDate).toDate() : dayjs().toDate()}
			/>
		</>
	);
};
