import { useEffect, useState } from 'react';
import { CollapsedDocumentsGroup, DocumentItem } from './components';
import { ContractTimelineDocumentsGroup, formatData } from './ContractTimeline.utils';
import { Collapsible } from '../../..';
import { BillingDocumentType } from '../../../../types/generalTypes';
import styles from './ContractTimeline.module.scss';

interface ContractTimelineProps {
	documentsList: Array<BillingDocumentType>;
	activationStartDate?: Date | null;
	activationEndDate?: Date | null;
	activeDocumentIndex: number;
	setActiveDocumentIndex: (documentIndex: number) => void;
}

export const ContractTimeline = ({
	documentsList,
	activationStartDate,
	activationEndDate,
	activeDocumentIndex,
	setActiveDocumentIndex,
}: ContractTimelineProps) => {
	const [documentGroups, setDocumentGroups] = useState<ContractTimelineDocumentsGroup[]>([]);

	useEffect(() => {
		const docs: ContractTimelineDocumentsGroup[] = formatData(
			documentsList,
			activationStartDate ? new Date(activationStartDate) : new Date(),
			activationEndDate ? new Date(activationEndDate) : new Date(),
		);
		setDocumentGroups(docs);
	}, []);

	const getHiddenDocsCount = (timelineItemIndex: number, groupIndex: number) => {
		const hiddenItemsByParentIndex = documentGroups[groupIndex].timelineItems.filter((timeLineItem) => timeLineItem.parentIndex == timelineItemIndex);
		return hiddenItemsByParentIndex.length;
	};

	const onClickMoreDocs = (timelineItemIndex: number, groupIndex: number, displayMoreDocs: boolean) => {
		const itemsByParentIndex = documentGroups[groupIndex].timelineItems
			.filter((timeLineItem) => timeLineItem.parentIndex == timelineItemIndex)
			.map((doc) => ({ ...doc, hidden: !displayMoreDocs }));
		const newList = [...documentGroups];

		newList[groupIndex].timelineItems.splice(timelineItemIndex + 1, itemsByParentIndex.length, ...itemsByParentIndex);
		setDocumentGroups(newList);

		// if a duplicated issue date document is selected & user close the group, the parent document will be selected
		if (!displayMoreDocs && itemsByParentIndex.map((doc) => doc.index).includes(activeDocumentIndex)) {
			setActiveDocumentIndex(timelineItemIndex);
		}
	};

	return (
		<div className={styles.container}>
			{documentGroups.map((documentGroup: ContractTimelineDocumentsGroup, groupIndex) => (
				<div key={groupIndex} className={styles.flex}>
					<Collapsible
						className={styles.collapsible}
						header={
							<CollapsedDocumentsGroup
								docsCount={documentGroup.timelineItems.length || 0}
								productsCount={documentGroup.productsCount}
								total={documentGroup.total}
								title={documentGroup.datesRange}
								currency={documentGroup.currency}
							/>
						}
					>
						<div className={styles.axisContainer}>
							<div className={styles.timeline} />

							<div className={styles.expandedGroup}>
								{documentGroup.timelineItems.map((timeLineItem, timeLineItemIndex) => (
									<DocumentItem
										key={timeLineItemIndex}
										timeLineItem={timeLineItem}
										documentIsOnTheSameDate={typeof timeLineItem?.parentIndex == 'number'}
										isActive={timeLineItem?.document?.index == activeDocumentIndex}
										moreDocsCount={getHiddenDocsCount(timeLineItemIndex, groupIndex)}
										onClick={() => typeof timeLineItem?.document?.index == 'number' && setActiveDocumentIndex(timeLineItem?.document?.index)}
										onClickMoreDocs={(displayMoreDocs: boolean) => onClickMoreDocs(timeLineItemIndex, groupIndex, displayMoreDocs)}
									/>
								))}
							</div>
						</div>
					</Collapsible>
				</div>
			))}
		</div>
	);
};
