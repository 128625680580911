import clsx from 'clsx';
import dayjs from 'dayjs';
import { Dispatch } from '@reduxjs/toolkit';
import { ContractState, DATE_FORMAT, convertCurrencyToSign } from '@received/pricing-model';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { AgreementsSummary, AgreementView } from '../../../types/AgreementTypes';
import { Cell, ContractCell, LogoCell, PriceCell, StatusCol } from '../../../components/_uiComponents/Table/Table.utils';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { contractStateColor } from '../../../components/_customerTabs/ContractsTab/ContractsTab.utils';
import { numberWithCommas } from '../../../utils/NumberUtils';
import { MenuOption } from '../../../components';
import { cancelAgreement, cloneAgreement, deleteAgreement } from '../../../utils/AgreementUtils';
import styles from './Agreements.module.scss';

export const agreementsTableHeader = [
	{
		headerTitle: 'partner',
		accessor: 'partnerName',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <LogoCell title={item.partnerName} logoData={item.partnerIconData} />,
	},
	{
		headerTitle: 'agreementNo',
		accessor: 'agreementNumber',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <Cell accessor={contractIdFormat(item?.poNumber, item?.agreementNumber)} />,
	},
	{
		headerTitle: 'createAt',
		accessor: 'createAt',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <Cell accessor={`${dayjs(item.createdAt).format(DATE_FORMAT)}`} />,
	},
	{
		headerTitle: 'programs',
		accessor: 'programsCount',
		isSortable: true,
		CustomComponent: (item: AgreementView) => (
			<div className={styles.programContainer}>
				<span className={styles.accessorRow}>{item?.programName}</span>
				{item.programsCount > 1 && <span className={styles.programsCount}>{`+${item.programsCount}`}</span>}
			</div>
		),
	},
	{
		headerTitle: 'refCustomers',
		accessor: 'refCustomersCount',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <Cell accessor={numberWithCommas(item.refCustomersCount)} />,
	},
	{
		headerTitle: 'totalFee',
		accessor: 'totalFees',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <PriceCell mainNumber={item?.totalFees} currency={convertCurrencyToSign(item?.currency)} />,
	},
	{
		headerTitle: 'nextFee',
		accessor: 'nextFee',
		isSortable: true,
		CustomComponent: (item: AgreementView) => <PriceCell mainNumber={item?.nextFee} currency={convertCurrencyToSign(item?.currency)} />,
	},

	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		CustomComponent: (item: AgreementView) => {
			const badgeColor = contractStateColor(item);
			return (
				<StatusCol color={badgeColor.color} className={clsx(item?.state == ContractState.END_BILLING && styles.contractEndBillingBadge)}>
					{badgeColor.text}
				</StatusCol>
			);
		},
	},
];

export const quantityBarData = (t: any, statusBarData?: AgreementsSummary) => {
	return [
		{
			title: t('review'),
			description: `${numberWithCommas(statusBarData?.missingInfoCount) || 0} ${t('missingInfo')} | ${statusBarData?.readyCount || 0} ${t('ready')}`,
			quantity: statusBarData?.reviewSum || 0,
		},
		{
			title: t('sent'),
			description: `${numberWithCommas(statusBarData?.sentCount) || 0} ${t('sent')} `,
			quantity: statusBarData?.sentSum || 0,
		},
		{
			title: t('late'),
			description: `${numberWithCommas(statusBarData?.lateCount) || 0} ${t('late')}`,
			quantity: statusBarData?.lateSum || 0,
		},
	];
};

export const getAgreementSummaryData = async (dispatch: Dispatch, textFilter?: string) => {
	try {
		const res = await httpService({
			dispatch,
			path: PathsConfig.getAgreementsSummary,
			params: { textFilter: textFilter || undefined },
		});
		return res.data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_SUMMARY }));
	}
};

export const agreementMenuOptions = (row: AgreementView, t: any, dispatch: Dispatch, onEdit: () => void, onRefresh: () => void): MenuOption[] => {
	let options: MenuOption[] = [
		{
			title: t('edit'),
			onPress: () => onEdit(),
			iconType: 'edit',
		},
		{
			title: t('cloneAgreement'),
			onPress: () => cloneAgreement(row.id, dispatch, onRefresh),
			iconType: 'duplicate',
		},
	];
	if (row?.state !== ContractState.CANCELED) {
		options = [
			...options,
			{
				title: t('cancelAgreement'),
				onPress: () => cancelAgreement(row.id, dispatch, onRefresh),
				iconType: 'error_icon',
				color: 'destructive',
			},
		];
	}
	options = [
		...options,
		{
			title: t('deleteAgreement'),
			onPress: () => deleteAgreement(row.id, dispatch, onRefresh),
			iconType: 'delete',
			color: 'destructive',
		},
	];
	return options;
};

export const defaultAgreementStates = [ContractState.ACTIVE, ContractState.CANCELED];
