import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Icon } from '../_uiComponents';
import { Subscription } from '../../types/subscriptionTypes';
import { netTermsToLabel } from '../../utils/GeneralUtils';
import { images } from '../../constants/images';
import styles from './SubscriptionDetails.module.scss';

export interface SubscriptionDetailsProps {
	subscription?: Subscription;
	small?: boolean;
	style?: CSSProperties;
	nameStyle?: string;
	hideTooltip?: boolean;
	hideLogo?: boolean;
	showArrow?: boolean;
	arrowUp?: boolean;
	className?: string;
	imgClassName?: string;
}

export const SubscriptionDetails = ({
	subscription,
	small,
	style,
	nameStyle,
	hideLogo,
	showArrow,
	arrowUp,
	className,
	imgClassName,
}: SubscriptionDetailsProps) => {
	const { t } = useTranslation('translation');

	const isStripeConnected = !!subscription?.billingDetails?.paymentGatewayId;

	return (
		<div className={clsx(styles.customersContainer, className)} data-testid='single-customer-details' style={style}>
			{!hideLogo && (
				<div className={clsx(styles.defaultIconStyle, imgClassName)}>
					<Icon imgType='subscription' color='neutral400' height={2} />
				</div>
			)}

			<div>
				<div className={styles.flex}>
					<span className={clsx(small ? styles.nameSmall : styles.name, nameStyle)}>{subscription?.name}</span>
					{showArrow && <Icon imgType='arrow_down' color='neutral700' className={clsx(styles.arrow, arrowUp ? styles.arrowUp : styles.arrowDown)} />}
				</div>

				<span className={small ? styles.descriptionSmall : styles.description}>
					{subscription?.pricingModel?.tabs.length
						? `${subscription?.pricingModel?.tabs[0].name}${
								subscription?.pricingModel?.tabs.length > 1 ? ` + ${subscription?.pricingModel?.tabs.length - 1}` : ''
						  }`
						: ''}
					<span className={styles.dot}> • </span>
					{netTermsToLabel(subscription?.netTerms)}
				</span>

				<div>
					{isStripeConnected && (
						<div className={styles.stripeTag}>
							<img src={images.stripePayment} className={styles.stripeIcon} />
							<span className={styles.stripeText}>{t('stripeAccount')}</span>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
