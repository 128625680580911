import clsx from 'clsx';
import { SelectItem } from '../../../types/generalTypes';
import { IntegrationMatchedObjects } from '../../../types/integrationTypes';
import { Button, Icon, Select } from '../../_uiComponents';
import styles from './ExportRow.module.scss';

export interface ItemMatcherListHeader {
	title: string;
	subTitle: string;
	icon: string;
}

export interface ExportRowProps {
	data: IntegrationMatchedObjects;
	rightList: SelectItem[];
	leftList: SelectItem[];
	onUpdateRow(newRow: IntegrationMatchedObjects): void;
	onDeleteRow(): void;
}

export const ExportRow = ({ data, rightList, leftList, onUpdateRow, onDeleteRow }: ExportRowProps) => {
	const onUpdate = (val: any, isDomainObject?: boolean) => {
		if (isDomainObject) {
			return onUpdateRow({ ...data, domainObject: val || undefined });
		}
		return onUpdateRow({ ...data, remoteObject: val || undefined });
	};

	return (
		<div className={styles.container}>
			<Select
				defaultValue={data?.domainObject?.name}
				isSearchable
				width={''}
				data={leftList}
				className={styles.item}
				optionsStyle={{ minWidth: '40rem', maxWidth: '40rem' }}
				onChange={(value) => onUpdate(value, true)}
			/>
			{data?.status ? (
				<div className={styles.syncContainer}>
					<div className={clsx(styles.dot, data?.status && styles[data?.status])}></div>
					<div className={clsx(styles.line, data?.status && styles[data?.status])}></div>
					<div className={clsx(styles.dot, data?.status && styles[data?.status])}></div>
					<Button type='link' color='neutral' className={styles.deleteButton} onClick={onDeleteRow}>
						<Icon imgType='delete' color='white' width={2} />
					</Button>
				</div>
			) : (
				<div className={styles.syncContainer}>
					<Button type='link' color='neutral' className={styles.deleteButton} onClick={onDeleteRow}>
						<Icon imgType='delete' color='white' width={2} />
					</Button>
					<Icon imgType='match_for_export' color='neutral400' width={5.5} height={2} />
				</div>
			)}

			<Select
				defaultValue={data?.remoteObject?.name}
				width={''}
				isSearchable
				data={rightList}
				className={styles.rightItem}
				optionsStyle={{ minWidth: '40rem', maxWidth: '40rem' }}
				onChange={(value) => onUpdate(value)}
			/>
		</div>
	);
};
