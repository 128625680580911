import { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import clsx from 'clsx';
import {
	CryptoTokens,
	cryptoTokens,
	getBankAccountString,
	IntegrationPlatform,
	IntegrationProvider,
	IntegrationStatus,
	PaymentOptions,
	PaymentPlatform,
	PaymentTerms,
} from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, Input, Select, Switch, TextArea, Tooltip, CheckedTag, CheckedTagsList, IconImgType, StripeCardSelect } from '../_uiComponents';
import { PricingModelTab } from '../../types/contractTypes';
import { Store } from '../../types/storeTypes';
import { UserRole } from '../../types/userTypes';
import { getSuppliers } from '../../utils/CustomerUtils';
import { CreateSupplier, StripeConnectModal } from '..';
import { UpdatePricingTerms } from '../TermsAndSettings/TermsAndSettings';
import { images } from '../../constants/images';
import { PaymentMethodOptions } from '../../types/generalTypes';
import { onCancelIntegration, onConnectEntity, onMissingDetailsEntity } from '../../services/integrationService/integrationService';
import {
	getImageSrcByName,
	getTooltipBySettings,
	isPaymentMethodDisabled,
	isPaymentMethodSelected,
	paymentOptions as constPaymentOptions,
} from './PricingTerms.utils';
import { BillingAccountDetails, Customer } from '../../types/customerTypes';
import { IntegrationType, StripeCard } from '../../types/integrationTypes';
import {
	autoChargeSettings,
	bankTransferSettings,
	bankTransferWithStripeSettings,
	dueUponUsageSettings,
	PricingTermsSettings,
	stripeSettings,
} from './settings';
import styles from './PricingTerms.module.scss';

export class CryptoWallet {
	cryptoWalletAddress?: string;
	isCryptoWalletEnabled: boolean;
	tokens: CheckedTag[];
}

interface PricingTermsProps {
	className?: string;
	style?: CSSProperties;
	pricingModelTab?: PricingModelTab;
	disabled?: boolean;
	customerId?: string;
	updatePricingTermsData: ({ note, supplierId, billingDetails, cryptoSettings }: UpdatePricingTerms, instantUpdate?: boolean) => void;
}

export const PricingTerms = ({ disabled, pricingModelTab, className, style, updatePricingTermsData }: PricingTermsProps) => {
	const [banks, setBanks] = useState<BillingAccountDetails[]>([]);
	const [isCreateEntityOpened, setIsCreateEntityOpened] = useState(false);
	const [isNewPaymentAccountOpened, setIsNewPaymentAccountOpened] = useState(false);
	const [supplierEntities, setSupplierEntities] = useState<Customer[]>([]);
	const [onlinePaymentsGateways, setOnlinePaymentsGateways] = useState<IntegrationType[]>([]);
	const [note, setNote] = useState('');
	const [paymentMethods, setPaymentMethods] = useState<PaymentMethodOptions[]>([]);
	const [cryptoWallet, setCryptoWallet] = useState<CryptoWallet>({ isCryptoWalletEnabled: false, cryptoWalletAddress: '', tokens: [] });
	const [settings, setSettings] = useState<PricingTermsSettings>();

	const userRole = useSelector((store: Store) => store.user.role);
	const dispatch = useDispatch();
	const { t } = useTranslation('translation');

	// INITIAL SETTINGS
	useEffect(() => {
		initSettings();
		setNote(pricingModelTab?.note || '');
	}, [
		pricingModelTab?.index,
		pricingModelTab?.supplierId,
		supplierEntities,
		pricingModelTab?.billingDetails?.paymentOptions,
		pricingModelTab?.netTerms,
	]);

	// SUPPLIERS SETTINGS
	useEffect(() => {
		setSuppliers();
	}, []);

	// CRYPTO SETTINGS
	useEffect(() => {
		setCryptoWallet({
			isCryptoWalletEnabled: pricingModelTab?.cryptoSettings?.isEnabled || false,
			cryptoWalletAddress: pricingModelTab?.cryptoSettings?.address,
			tokens: cryptoTokens.map((token) => ({
				id: token.id,
				isChecked: pricingModelTab?.cryptoSettings?.tokens?.includes(token.id) || false,
				label: token.title,
				iconSrc: getImageSrcByName(token.id),
			})),
		});
	}, [pricingModelTab?.index, pricingModelTab?.cryptoSettings?.isEnabled]);

	// STRIPE ACCOUNT SETTINGS
	useEffect(() => {
		setPaymentGateways();
	}, [supplierEntities, pricingModelTab?.supplierId, pricingModelTab?.billingDetails?.paymentOptions]);

	// AUTO CHARGE SETTINGS
	useEffect(() => {
		settings?.isAutoChargeToggleOn && onUpdateAutoCharge();
	}, [settings?.isAutoChargeToggleOn]);

	const initSettings = () => {
		let methods: PaymentMethodOptions[] = [];
		let settings: PricingTermsSettings | undefined;

		if (pricingModelTab?.netTerms == PaymentTerms.DUE_UPON_USAGE) {
			settings = dueUponUsageSettings;
			methods = [PaymentMethodOptions.AUTO_CHARGE];
		} else {
			if (pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.BANK_TRANSFER)) {
				settings = bankTransferSettings;
				methods = [...methods, PaymentMethodOptions.BANK_TRANSFER];
				const bank = updateBank();
				!pricingModelTab?.billingDetails?.billingAccountId && onChangeBank(bank?.id);
			}
			if (pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.PAYMENT_LINK)) {
				settings = stripeSettings;
				if (pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.BANK_TRANSFER)) {
					settings = bankTransferWithStripeSettings;
				}
				methods = [...methods, PaymentMethodOptions.PAYMENT_LINK];
			}
			if (pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE)) {
				settings = autoChargeSettings;
				methods = [PaymentMethodOptions.AUTO_CHARGE, PaymentMethodOptions.PAYMENT_LINK];
			}
		}

		setSettings(settings);
		setPaymentMethods(methods);
	};

	////STRIPE ACCOUNTS + CARDS SETTINGS
	const setPaymentGateways = () => {
		if (
			pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.PAYMENT_LINK) ||
			pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE)
		) {
			// get the linked stripe accounts from the chosen supplier
			const linkedPaymentGateways = supplierEntities
				.find((company) => company.id === pricingModelTab?.supplierId)
				?.billingDetails?.paymentGateways?.filter((paymentGateway) => paymentGateway.connectionStatus == IntegrationStatus.LINKED);

			setOnlinePaymentsGateways(linkedPaymentGateways || []);
		}
	};

	const onChangeOnlinePayment = async (paymentGatewayId: string) => {
		updatePricingTermsData({
			...pricingModelTab,
			billingDetails: {
				...pricingModelTab?.billingDetails,
				paymentGatewayId: paymentGatewayId,
				paymentGatewayCustomerId: null,
				paymentGatewayCustomer: null,
			},
		});
	};

	const onChangeStripeCard = async (paymentGatewayCustomerId: string, paymentGatewayCustomer: StripeCard | undefined) => {
		updatePricingTermsData(
			{
				...pricingModelTab,
				billingDetails: {
					...pricingModelTab?.billingDetails,
					paymentGatewayCustomer: paymentGatewayCustomer ? paymentGatewayCustomer : null,
					paymentGatewayCustomerId: paymentGatewayCustomerId?.length ? paymentGatewayCustomerId : null,
				},
			},
			true,
		);
	};

	const onChangePaymentMethod = (paymentMethod: PaymentMethodOptions) => {
		let data;
		let paymentOptions: PaymentOptions[] = [];
		if (paymentMethods.includes(paymentMethod)) {
			data = paymentMethods.filter((item) => item != paymentMethod);
		} else {
			data = [...paymentMethods, paymentMethod];
		}

		if (data.includes(PaymentMethodOptions.BANK_TRANSFER)) {
			paymentOptions = [PaymentOptions.BANK_TRANSFER];
		}

		if (data.includes(PaymentMethodOptions.PAYMENT_LINK)) {
			paymentOptions.push(PaymentOptions.PAYMENT_LINK);
		}

		setPaymentMethods(data);
		updatePricingTermsData(
			{
				...pricingModelTab,
				billingDetails: {
					...pricingModelTab?.billingDetails,
					paymentGatewayId: data.includes(PaymentMethodOptions.PAYMENT_LINK) ? pricingModelTab?.billingDetails?.paymentGatewayId : null,
					paymentGatewayCustomerId: settings?.isAutoChargeToggleOn ? pricingModelTab?.billingDetails?.paymentGatewayCustomerId : null,
					paymentGatewayCustomer: settings?.isAutoChargeToggleOn ? pricingModelTab?.billingDetails?.paymentGatewayCustomer : null,
					paymentOptions,
				},
			},
			true,
		);
	};

	const onClickAutoCharge = async () => {
		updatePricingTermsData({
			...pricingModelTab,
			billingDetails: {
				...pricingModelTab?.billingDetails,
				paymentOptions: settings?.isAutoChargeToggleOn ? [PaymentOptions.PAYMENT_LINK] : [PaymentOptions.AUTO_CHARGE],
				billingAccountId: null,
			},
			cryptoSettings: {
				...pricingModelTab?.cryptoSettings,
				isEnabled: settings?.isAutoChargeToggleOn ? pricingModelTab?.cryptoSettings?.isEnabled : false,
			},
		});
	};

	const onUpdateAutoCharge = async () => {
		updatePricingTermsData({
			...pricingModelTab,
			billingDetails: {
				...pricingModelTab?.billingDetails,
				paymentOptions: [PaymentOptions.AUTO_CHARGE],
				billingAccountId: null,
			},
			cryptoSettings: {
				...pricingModelTab?.cryptoSettings,
				isEnabled: settings?.isAutoChargeToggleOn ? pricingModelTab?.cryptoSettings?.isEnabled : false,
			},
		});
	};

	////SUPPLIERS + BANKS SETTINGS
	const addEntity = async (supplier: Customer) => {
		setBanks(supplier?.billingDetails?.billingAccounts || []);
		const billingAccountId = supplier?.billingDetails?.billingAccounts?.find((bank: BillingAccountDetails) => bank.isDefault)?.id;
		await setSuppliers();
		pricingModelTab &&
			updatePricingTermsData(
				{
					...pricingModelTab,
					billingDetails: {
						...pricingModelTab.billingDetails,
						billingAccountId: settings?.isBankMethodSelected ? billingAccountId : null,
						paymentGatewayId: null,
					},
					supplierId: supplier?.id,
				},
				true,
			);
	};

	const onChangeEntity = async (supplierId: string) => {
		const billingAccountId = supplierEntities
			?.find((company) => company?.id === supplierId)
			?.billingDetails?.billingAccounts?.find((bank) => bank.isDefault)?.id;

		updatePricingTermsData(
			{
				...pricingModelTab,
				billingDetails: {
					...pricingModelTab?.billingDetails,
					billingAccountId: settings?.isBankMethodSelected ? billingAccountId : null,
					paymentGatewayId: null,
					paymentGatewayCustomerId: null,
					paymentGatewayCustomer: null,
				},
				supplierId,
			},
			true,
		);
	};

	const onChangeBank = async (billingAccountId?: string) => {
		updatePricingTermsData({
			...pricingModelTab,
			billingDetails: {
				...pricingModelTab?.billingDetails,
				billingAccountId,
			},
		});
	};

	const updateBank = () => {
		const banks = supplierEntities?.find((company) => company.id === pricingModelTab?.supplierId)?.billingDetails?.billingAccounts || [];
		setBanks(banks);
		return banks[0];
	};

	const setSuppliers = async () => {
		const companies = await getSuppliers(dispatch);
		setSupplierEntities(companies);
	};

	//CRYPTO SETTINGS
	const onUpdateCryptoTokens = (tags: CheckedTag[]) => {
		updatePricingTermsData({
			...pricingModelTab,
			cryptoSettings: {
				...pricingModelTab?.cryptoSettings,
				tokens: tags.filter((token) => token.isChecked).map((token) => token.id || '') as CryptoTokens[],
			},
		});
	};

	const updateCryptoWalletAddress = (address: string) => {
		updatePricingTermsData({
			...pricingModelTab,
			cryptoSettings: {
				...pricingModelTab?.cryptoSettings,
				address,
			},
		});
	};

	const updateCryptoWalletEnabled = (isCryptoWalletEnabled: boolean) => {
		setCryptoWallet({ ...cryptoWallet, isCryptoWalletEnabled });
		updatePricingTermsData(
			{
				...pricingModelTab,
				cryptoSettings: {
					...pricingModelTab?.cryptoSettings,
					isEnabled: isCryptoWalletEnabled,
				},
			},
			true,
		);
	};

	const onPaste = async (event: any) => {
		event.preventDefault();
		const paste = await navigator.clipboard.readText();
		setCryptoWallet({ ...cryptoWallet, cryptoWalletAddress: paste });
		updateCryptoWalletAddress(paste);
	};

	const onUpdateNote = (val: string) => {
		setNote(val);
		updatePricingTermsData({
			...pricingModelTab,
			note: val,
		});
	};

	return (
		<div className={clsx(styles.container, className)} style={style}>
			<div className={styles.title}>{t('settings')}</div>

			<div className={styles.content}>
				<div className={styles.rowItem}>
					<Select
						fullBorder
						disabled={disabled}
						placeholder={t('receivingEntityPlaceholder')}
						containerStyle={styles.fullWidthContainer}
						title={t('debitEntity')}
						onChange={onChangeEntity}
						defaultValue={pricingModelTab?.supplierId}
						width={''}
						data={
							supplierEntities?.map((item: Customer) => ({
								label: `${item?.name} ${item?.ein ? `(EIN ${item?.ein})` : ''}`,
								value: item.id,
							})) || []
						}
						headerStyle={styles.selectHeaderStyle}
						addButton={{ title: t('addNewEntity'), onPress: () => setIsCreateEntityOpened(true), isDisabled: userRole !== UserRole.Admin }}
					/>
				</div>

				{pricingModelTab?.supplierId && (
					<>
						<div className={styles.rowItem}>
							<div className={styles.subTitle}>{t('paymentMethod')}</div>
							<div className={styles.flex}>
								{constPaymentOptions.map((item: { id: PaymentMethodOptions; title: string }) => {
									return (
										<Tooltip
											key={item.id}
											tooltipComponent={getTooltipBySettings(t, item.id, settings)}
											blackTooltip
											disabled={!isPaymentMethodDisabled(item.id, settings)}
											containerClassName={styles.tooltipContainer}
										>
											<Button
												type='outlined'
												color='neutral'
												disabled={isPaymentMethodDisabled(item.id, settings)}
												className={clsx(styles.iconContainer, isPaymentMethodSelected(item.id, settings) && styles.selected)}
												onClick={() => {
													onChangePaymentMethod(item.id);
												}}
											>
												<Icon
													imgType={item.id as IconImgType}
													width={2.9}
													color={isPaymentMethodSelected(item.id, settings) ? 'success900' : 'neutral700'}
												/>
												{item.title}
											</Button>
										</Tooltip>
									);
								})}
								<Tooltip disabled={!settings?.isCryptoSettingsDisabled} tooltipComponent={getTooltipBySettings(t, '', settings)} blackTooltip>
									<Button
										type='outlined'
										color='neutral'
										className={clsx(styles.iconContainer, cryptoWallet.isCryptoWalletEnabled && styles.selected)}
										onClick={() => updateCryptoWalletEnabled(!cryptoWallet.isCryptoWalletEnabled)}
										disabled={settings?.isCryptoSettingsDisabled}
									>
										<Icon imgType='crypto_wallet' width={2.9} color={cryptoWallet.isCryptoWalletEnabled ? 'success900' : 'neutral700'} />
										{t('crypto')}
									</Button>
								</Tooltip>
							</div>
						</div>

						<Select
							fullBorder
							data={
								banks?.map((item: BillingAccountDetails) => ({
									label: getBankAccountString(item),
									value: item.id,
								})) || []
							}
							onChange={onChangeBank}
							defaultValue={pricingModelTab?.billingDetails?.billingAccountId || ''}
							width={''}
							optionsStyle={{ maxWidth: '15rem' }}
							className={styles.paymentMethodSelect}
							containerStyle={clsx(styles.selectContainer, !settings?.isBanksSelectDisplayed && styles.hidden)}
							title={t('bankTransfer')}
						/>

						{settings?.isStripeSelectDisplayed && (
							<div className={styles.selectsContainer}>
								<Select
									fullBorder
									data={
										onlinePaymentsGateways?.map((item) => ({
											label: item?.metadata?.accountName || item?.metadata?.accountId || '',
											value: item?.id,
											customComponent: () => (
												<div className={styles.stripeAccountItem}>
													<Icon imgType='stripe' color='calming' width={2} />
													{item?.metadata?.accountName || item?.metadata?.accountId || ''}
												</div>
											),
										})) || []
									}
									onChange={onChangeOnlinePayment}
									defaultValue={pricingModelTab?.billingDetails?.paymentGatewayId || undefined}
									width={''}
									className={styles.paymentMethodSelect}
									containerStyle={styles.selectContainer}
									title={t('stripeIntegration')}
									placeholder={t('addOnlinePaymentAccount')}
									addButton={{
										title: t('connectOnlinePaymentAccount'),
										onPress: () => setIsNewPaymentAccountOpened(true),
									}}
									inputIcon={{ imgType: 'stripe', color: 'calming' }}
								/>
								<Tooltip
									blackTooltip
									disabled={!settings.isAutoChargeToggleDisabled}
									tooltipComponent={
										<div className={styles.tooltipLabel}>
											<div>{t('whenUsingPayUponUsage')}</div>
											<div>{t('theAutoChargeRequired')}</div>
										</div>
									}
									className={styles.tooltip}
								>
									<div className={styles.autoCharge}>
										<span className={styles.autoChargeLabel}>{t('autoCharge')}</span>
										<Switch
											onChange={() => onClickAutoCharge()}
											defaultChecked={settings.isAutoChargeToggleOn}
											defaultDisable={settings.isAutoChargeToggleDisabled}
											className={clsx(styles.autoChargeToggle, settings.isAutoChargeToggleDisabled && styles.autoChargeToggleDisabled)}
										/>
									</div>
								</Tooltip>
							</div>
						)}

						<StripeCardSelect
							onChange={(selectedId, selectedItem) => onChangeStripeCard(selectedId, selectedItem)}
							// PaymentGatewayCustomer and paymentGatewayId are needed to show the select which stripe account selected
							defaultCustomer={pricingModelTab?.billingDetails?.paymentGatewayCustomer || undefined}
							paymentGatewayId={pricingModelTab?.billingDetails?.paymentGatewayId || undefined}
							supplierId={pricingModelTab?.supplierId}
							// suppliers needed in case supplier was changed
							iconType='information'
							showCheckoutComment
							suppliers={supplierEntities}
							className={styles.paymentMethodSelect}
							containerStyle={clsx(styles.selectContainer, settings?.isStripeCardsSelectDisplayed ? styles.appearAnimation : styles.hidden)}
							tooltipComponent={
								<div className={styles.tooltipLabel}>
									<div>{t('ifNoCardIsSelected')}</div>
									<div>{t('automaticPaymentLinkWillBe')}</div>
									<div>{t('sentToTheCustomer')}</div>
								</div>
							}
							displayCustomerNameOnly={!pricingModelTab?.billingDetails?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE)}
						/>

						<div className={clsx(styles.cryptoWalletInputContainer, cryptoWallet.isCryptoWalletEnabled ? styles.appearAnimation : styles.hidden)}>
							<Input
								value={cryptoWallet?.cryptoWalletAddress || ''}
								onChange={(val: string, event: ChangeEvent<HTMLInputElement>) => {
									setCryptoWallet({ ...cryptoWallet, cryptoWalletAddress: event.target.value });
									updateCryptoWalletAddress(event.target.value);
								}}
								title={t('receiptCryptoWallet')}
								placeholder={t('pasteHereYourWalletAddress')}
								containerClass={styles.fullWidthInput}
								rightChildren={
									<Tooltip tooltipComponent={t('paste')}>
										<Button color='neutral' type='link' className={styles.pasteButton} onClick={onPaste}>
											<Icon imgType='paste' width={2} color='neutral700'></Icon>
										</Button>
									</Tooltip>
								}
							/>
							<div className={styles.flex}>
								<CheckedTagsList data={cryptoWallet.tokens} onUpdateTags={onUpdateCryptoTokens} />
							</div>
						</div>
					</>
				)}

				<TextArea
					type='textarea'
					value={note}
					onChange={onUpdateNote}
					title={`${t('note')} (${t('optional')})`}
					className={styles.multiline}
					titleStyle={styles.noteTitle}
					placeholder={t('noteComment')}
				/>
			</div>

			<CreateSupplier
				companiesListLength={supplierEntities?.length || 0}
				isOpen={isCreateEntityOpened}
				closeModal={() => setIsCreateEntityOpened(false)}
				onCreateUpdateSupplier={addEntity}
				selectedBankId={pricingModelTab?.billingDetails?.billingAccountId || null}
			/>

			<StripeConnectModal
				isOpen={isNewPaymentAccountOpened}
				platform={PaymentPlatform.STRIPE}
				image={images.stripePayment}
				onConnect={(id, integrationAccountId, existing) =>
					onConnectEntity(dispatch, IntegrationProvider.STRIPE, id, IntegrationPlatform.STRIPE, integrationAccountId, existing)
				}
				onCancel={(supplierId: string, integrationAccountId?: string) =>
					onCancelIntegration(dispatch, IntegrationProvider.STRIPE, supplierId, IntegrationPlatform.STRIPE, integrationAccountId)
				}
				onMissingDetails={(integrationAccountId: string) => onMissingDetailsEntity(dispatch, IntegrationProvider.STRIPE, integrationAccountId)}
				closeModal={() => setIsNewPaymentAccountOpened(false)}
				onRefresh={setSuppliers}
			/>
		</div>
	);
};
