import { EventsGenerationMethod } from '@received/pricing-model';
import { IconProps } from '../components/_uiComponents/Icon/IconOptions';
import { PricingModel } from './contractTypes';

export interface ProductAndCategoriesItem {
	id: string;
	name: string;
	pricingModels: PricingModel[];
	iconType?: IconProps['imgType'];
}

export interface ProductContractRes {
	productId: string;
	productName: string;
	revenueRecognitionMethod: EventsGenerationMethod;
	type: string;
}

export enum ProductsHeaderId {
	CATEGORIES = 'Categories',
	PRODUCTS = 'Products',
}

export interface Product {
	id?: string;
	name: string;
}
