import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, GroupButton, Icon, Input, Modal, Select, Tooltip } from '..';
import { PathsConfig } from '../../services/httpService/configPaths';
import { httpService } from '../../services/httpService/httpService';
import { UsageGroup, UsageSnippet } from '../../types/usageTypes';
import { CreateGroupModal } from '../CreateGroupModal/CreateGroupModal';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { onDeleteUsageProduct } from './UsageProductModal.utils';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import styles from './UsageProductModal.module.scss';

export interface UsageProductModalProps {
	isOpen: boolean;
	selectedUsageProductId?: string;
	closeModal(id?: string): void;
}

export const UsageProductModal = ({ isOpen, selectedUsageProductId, closeModal }: UsageProductModalProps) => {
	const { t } = useTranslation('translation');
	const [usageProductId, setUsageProductId] = useState('');
	const [usageName, setUsageName] = useState<string>();
	const [selectedUsageType, setSelectedUsageType] = useState<string>();
	const [selectedConnection, setSelectedConnection] = useState(0); // API=0 ; MANUAL=1
	const [snippet, setSnippet] = useState<UsageSnippet>();
	const [openCreateType, setOpenCreateType] = useState(false);
	const [isConnected, setIsConnected] = useState(false);
	const [connectionUrl, setConnectionUrl] = useState('');
	const [usageTypes, setUsageTypes] = useState<UsageGroup[]>();
	const [isCopied, setIsCopied] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isOpen) {
			getUsageTypesList();
			selectedUsageProductId ? setExistingUsageProduct() : onStartEditNewUsageProduct();
		}
	}, [isOpen, selectedUsageProductId]);

	useEffect(() => {
		usageProductId && getConnectionUrl();
	}, [usageProductId]);

	const getConnectionUrl = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getUsageConnectionUrl,
				urlParam: { id: usageProductId },
			});

			setConnectionUrl(`${res.data?.httpMethod} ${res.data?.url}`);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onStartEditNewUsageProduct = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.createUsageProduct,
			});

			setUsageProductId(res.data.id);
			getSnippet(res.data.id);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getUsageTypesList = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getUsageGroupList,
			});
			setUsageTypes(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_USAGE_LIST }));
		}
	};

	const setExistingUsageProduct = async () => {
		try {
			if (selectedUsageProductId) {
				const res = await httpService({
					dispatch,
					path: PathsConfig.getUsageProduct,
					urlParam: { id: selectedUsageProductId },
				});
				const existingUsageProduct = res.data;

				setUsageName(existingUsageProduct?.name);
				setSelectedUsageType(existingUsageProduct?.usageGroupId);
				getSnippet(existingUsageProduct?.id);
				setSelectedConnection(existingUsageProduct.isManual ? 1 : 0);
				setIsConnected(!!res.data?.isConnected);
				setUsageProductId(selectedUsageProductId);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getSnippet = async (usageProductId: string) => {
		try {
			if (usageProductId) {
				const res = await httpService({
					dispatch,
					path: PathsConfig.getUsageSnippet,
					urlParam: { usageProductId },
				});
				setSnippet(res.data);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onSaveProduct = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.publishUsageProduct,
				data: {
					id: usageProductId,
					usageGroupId: selectedUsageType,
					isManual: !!selectedConnection,
					name: usageName,
				},
			});
			onClose(res.data.id);

			dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.USAGE_CREATED }));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onUpdateUsageProduct = async () => {
		try {
			const selectedType = usageTypes?.find((type) => type.id === selectedUsageType);
			await httpService({
				dispatch,
				path: PathsConfig.updateUsageProduct,
				data: {
					id: selectedUsageProductId,
					usageGroupId: selectedUsageType,
					usageGroup: selectedType,
					isManual: !!selectedConnection,
					name: usageName,
				},
			});
			onClose(selectedUsageProductId);

			dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.USAGE_UPDATED }));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onCopySnippet = () => {
		let snippetBody = '';
		snippet && Object.keys(snippet?.body).forEach((key) => (snippetBody = `${snippetBody} ${key}: ${snippet?.body[key]},`));
		const stringToCopy = `${snippet?.httpMethod} ${snippet?.url}\n {${snippetBody}}`;
		navigator.clipboard.writeText(stringToCopy);
		copied();
	};

	const onAddOrClose = () => {
		selectedUsageProductId ? onUpdateUsageProduct() : onSaveProduct();
	};

	const onClose = (id?: string) => {
		setUsageName('');
		setSelectedUsageType('');
		setSnippet(undefined);
		setSelectedConnection(0);
		setUsageProductId('');
		getSnippet('');
		closeModal(id);
	};

	const onCancel = () => {
		//on cancel without saving the model
		!selectedUsageProductId && onDeleteUsageProduct(usageProductId, dispatch);
		onClose();
	};

	const copied = () => {
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1500);
	};

	return (
		<Modal isOpen={isOpen} closeModal={onCancel} className={styles.modalContainer} overlayClassName={styles.modalOverlay}>
			<div className={styles.header}>
				<div>
					<div className={styles.title}>{t('usageProduct')}</div>
					<div className={styles.subTitle}>{t('Create usage products and sync')}</div>
				</div>
				<Button type='link' color='neutral' onClick={onCancel}>
					<Icon imgType='x_icon' color='neutral700' className={styles.xIcon} />
				</Button>
			</div>

			<div className={styles.contentContainer}>
				<div className={styles.inputs}>
					<Input
						title={t('Usage Name')}
						autoFocus
						maxLength={128}
						value={usageName}
						onChange={(value) => setUsageName(value)}
						className={styles.nameInput}
						rightChildren={<Icon imgType='edit' color='neutral300' width={1.5} className={styles.rightInputIcon} />}
					/>
					<Select
						title={t('Usage Type')}
						defaultValue={selectedUsageType}
						data={usageTypes?.map((item) => ({ label: `${item.countedUnit} - ${item.description}`, value: item.id })) || []}
						onChange={(usageTypeId) => setSelectedUsageType(usageTypeId)}
						width={22.4}
						addButton={{ title: t('addUsageType'), onPress: () => setOpenCreateType(true) }}
					/>
				</div>

				<GroupButton
					buttonArray={[{ children: t('Connect API') }, { children: t('Manual usage') }]}
					selectedIndex={selectedConnection}
					setSelectedIndex={(index) => setSelectedConnection(index)}
					buttonClassName={styles.groupButton}
				/>

				{selectedConnection === 0 && (
					<>
						<div className={styles.snippetHeader}>
							<div className={styles.contentTitle}>{t('Copy and send IT')}</div>
							{isConnected ? (
								<div className={styles.connected}>
									{t('connected')} <Icon imgType='v_circle' color='success' />
								</div>
							) : (
								<div className={styles.copyHoverContainer}>
									<Tooltip
										tooltipComponent={
											<>
												<div>{t('copyConnectionUrl')}</div>
												<div>{t('URLBelowToYourITteam')}</div>
											</>
										}
										placement='left'
									>
										<Button
											type='link'
											color='warning'
											onClick={() => {
												navigator.clipboard.writeText(connectionUrl);
												copied();
											}}
											className={styles.notConnectedButton}
										>
											{t('notConnected')}
											<Icon imgType='error_icon' color='warning' />
										</Button>
									</Tooltip>
								</div>
							)}
						</div>

						<div className={styles.snippetContainer} onClick={onCopySnippet}>
							{snippet && (
								<>
									<div className={styles.copyButton}>
										<Icon imgType='clipboard' color='neutral700' height={1.5} />
									</div>

									<div style={{ width: '97%' }}>
										{snippet?.httpMethod} {snippet?.url}
									</div>
									<div>
										{'{'}
										{Object.keys(snippet?.body).map((key) => (
											<div className={styles.snippetBody} key={key}>
												{key}: {snippet?.body[key]}
											</div>
										))}
										{'}'}
									</div>
								</>
							)}
						</div>
					</>
				)}
				{isCopied && <div className={styles.copied}>{t('copied')}!</div>}
			</div>

			<div className={styles.footer}>
				<Button onClick={onAddOrClose} className={styles.footerButtons} disabled={!usageName || !selectedUsageType}>
					{t(selectedUsageProductId ? 'update' : 'Add Product')}
				</Button>
			</div>

			<CreateGroupModal
				isOpen={openCreateType}
				closeModal={() => {
					setOpenCreateType(false);
					getUsageTypesList();
				}}
				returnCreatedId={(usageTypeId) => setSelectedUsageType(usageTypeId)}
			/>
		</Modal>
	);
};
