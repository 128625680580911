import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { VerticalTableColumn, VerticalTableHeader } from './VerticalTable.utils';
import { EmptyStateCard } from '../EmptyStateCard/EmptyStateCard';
import { images } from '../../../constants/images';
import styles from './VerticalTable.module.scss';

interface VerticalTableProps {
	headers: VerticalTableHeader;
	columns: VerticalTableColumn[];
	emptyTitle?: string;
	emptySubTitle?: string;
	isEmpty?: boolean;
}

export const VerticalTable = ({ headers, columns, emptyTitle, emptySubTitle, isEmpty }: VerticalTableProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.container, isEmpty && styles.emptyContainer)}>
			{isEmpty ? (
				<EmptyStateCard
					src={images.emptyGraphs}
					imgAlt={'empty-graph'}
					title={emptyTitle || t('noDataSource')}
					subTitle={emptySubTitle || t('emptyStateSubtitle')}
				/>
			) : (
				<>
					<div className={styles.horizontalHeaders}>
						<>
							<div className={styles.verticalColumn}>
								<span className={styles.verticalColumnText}>{t(headers.title)}</span>
							</div>

							{headers.data.map((header) => (
								<div key={header.headerTitle} className={clsx(styles.horizontalHeader, styles.cell)}>
									<span className={styles.header}>{t(header.headerTitle)}</span>
								</div>
							))}
						</>
					</div>

					<div className={styles.columns}>
						{columns.length ? (
							columns.map((column) => (
								<div key={column.label}>
									<div className={styles.verticalColumn}>
										<span className={styles.verticalColumnText}>{t(column.label)}</span>
									</div>

									{headers.data.map((header: any, index: number) => (
										<div key={index} className={clsx(styles.cell, styles.data)}>
											{header?.CustomComponent ? header?.CustomComponent(column.data) : column.data[header.accessor]}
										</div>
									))}
								</div>
							))
						) : (
							<EmptyStateCard
								showIcon
								containerStyle={styles.tableEmpty}
								title={t('noDataSource')}
								subTitle={t('emptyStateSubtitle')}
							></EmptyStateCard>
						)}
					</div>
				</>
			)}
		</div>
	);
};
