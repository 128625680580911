import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { AccountingEventType, DocumentState, IntegrationCategory, IntegrationStatus, PaymentOptions } from '@received/pricing-model';
import {
	Button,
	FilterSideBar,
	FilterTags,
	Icon,
	SearchBar,
	SideBarFilters,
	DatePicker,
	InvoiceTable,
	SummaryBar,
	DateRangeFilters,
	ImportItemsProgress,
	Sidebars,
	MenuList,
	MenuOption,
	IconImgType,
} from '../../../../components';
import { httpService } from '../../../../services/httpService/httpService';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { DatesData, Sort, SortOrder } from '../../../../types/generalTypes';
import { getSummaryData, syncInvoiceWithAccountingIntegration } from '../../../../utils/InvoiceUtils';
import { Store } from '../../../../types/storeTypes';
import { InvoiceSummary, InvoiceView, summaryType } from '../../../../types/invoiceTypes';
import {
	InvoiceResponse,
	invoicesTableHeader,
	summaryBlocksTypes,
	isDocumentTabsCanBeEdit,
	getDefaultSortByState,
	getInvoicesSummaryBlocksData,
	isTimePeriodSelectDisabled,
	getSummaryDates,
	onDownloadInvoicesCSV,
} from './AllInvoices.utils';
import { setSidebarIsOpen } from '../../../../storeSlices/generalSlice';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { useDebounce } from '../../../../hooks/generalHooks';
import { FooterTypeOptions } from '../../../../components/_uiComponents/DatePicker/Footer/Footer';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import {
	ChangeStatusOption,
	cancelInvoices,
	chargeMultipleInvoices,
	downloadDocuments,
} from '../../../../components/InvoiceTable/InvoiceTable.utils';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../../components/Filters/DateRangeFilters.utils';
import { convertIntegrationTypeToStringService } from '../../../../utils/IntegrationUtils';
import { SidebarType } from '../../../../components/Sidebars/Sidebars.utils';
import { documentsViewOptionsList, reconcileDocuments, sendMultipleDocuments, submitDocuments } from '../../../../utils/DocumentsUtils';
import styles from './AllInvoices.module.scss';

export const AllInvoices = () => {
	const { t } = useTranslation('translation');

	const [totalCount, setTotalCount] = useState(0);
	const [invoicesList, setInvoicesList] = useState<InvoiceView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState('20');
	const [openFilter, setOpenFilter] = useState(false);
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();
	const [sideBarFilters, setSideBarFilters] = useState<SideBarFilters>({ filterData: {}, filterTags: [] });
	const [searchText, setSearchText] = useState('');
	const [selectedInvoiceList, setSelectedInvoiceList] = useState<InvoiceView[]>([]);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [currentAction, setCurrentAction] = useState<ChangeStatusOption | ''>('');
	const [sort, setSort] = useState<Sort>({ orderBy: getDefaultSortByState(summaryType.WARNING), order: SortOrder.ASC });
	const [selectedSummary, setSelectedSummary] = useState<summaryType>(summaryType.WARNING);
	const [showDraft, setShowDraft] = useState(true);
	const [timePeriod, setTimePeriod] = useState<DatesData | undefined>(generalTimePeriodInitialState);
	const [invoicesSummaries, setInvoiceSummaries] = useState<InvoiceSummary>();
	const [openMultipleSelectOptions, setOpenMultipleSelectOptions] = useState(false);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);

	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);
	const { appCurrency } = useSelector((store: Store) => store.general);

	const dispatch = useDispatch();
	const location: any = useLocation();

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		location?.state?.summaryType && setSelectedSummary(location?.state?.summaryType);
	}, [location?.state?.summaryType]);

	useEffect(() => {
		selectedSummary && getData();
	}, [numberOfItems, pageNumber, sideBarFilters.filterData, sideBarFilters.filterTags, showDraft, timePeriod, selectedSummary]);

	useEffect(() => {
		getDocuments();
	}, [sort]);

	const getData = async (search?: string, sideBarFiltersData?: SideBarFilters, selectedState?: summaryType) => {
		await getDocuments(search, sideBarFilters?.filterTags.length ? sideBarFilters : sideBarFiltersData, selectedState || selectedSummary);
		const time = await updateTimePeriod();
		await getSummary(time, sideBarFilters?.filterTags.length ? sideBarFilters : sideBarFiltersData, search);
	};

	const updateTimePeriod = async () => {
		const isTimePeriodDisabled = isTimePeriodSelectDisabled(sideBarFilters.filterData);
		setTimePeriod(isTimePeriodDisabled ? undefined : timePeriod || generalTimePeriodInitialState);
		return isTimePeriodDisabled ? undefined : timePeriod || generalTimePeriodInitialState;
	};

	const getSummary = async (time?: DatesData, sideBarFiltersData?: SideBarFilters, search?: string) => {
		const dates = getSummaryDates(
			sideBarFiltersData?.filterTags?.length ? sideBarFiltersData?.filterData : sideBarFilters?.filterData,
			time || timePeriod,
		);

		const summary = await getSummaryData(
			dispatch,
			dates?.from,
			dates?.to,
			sideBarFiltersData?.filterData.customerIds || sideBarFilters?.filterData.customerIds,
			[AccountingEventType.INVOICE, AccountingEventType.DEBIT],
			search || searchText,
		);
		setInvoiceSummaries(summary);
	};

	const getDocuments = async (search?: string, sideBarFiltersData?: SideBarFilters, selectedState?: summaryType) => {
		try {
			const res: InvoiceResponse = (
				await httpService({
					showLoader: !search,
					dispatch,
					path: PathsConfig.getDocumentsView,
					params: {
						accountingEventTypes: [AccountingEventType.INVOICE, AccountingEventType.DEBIT],
						pageSize: parseInt(numberOfItems),
						pageNumber: pageNumber,
						states:
							summaryBlocksTypes(showDraft).find((block) => (selectedState ? block.label === selectedState : block.label === selectedSummary))
								?.value || [],
						orderBy: sort.orderBy || getDefaultSortByState(selectedState || selectedSummary),
						order: sort.order,
						searchText: search || searchText,
						fromIssueDate: timePeriod ? timePeriod?.from : undefined,
						toIssueDate: timePeriod ? timePeriod?.to : undefined,
						...(sideBarFiltersData?.filterTags?.length ? sideBarFiltersData?.filterData : sideBarFilters?.filterData),
					},
				})
			).data;

			setTotalCount(res?.count);
			setInvoicesList(res?.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_INVOICE_DATA }));
		}
	};

	const onInvoiceChecked = (isChecked: boolean, row: InvoiceView) => {
		if (isChecked) {
			setSelectedInvoiceList([...selectedInvoiceList, row]);
		} else {
			setSelectedInvoiceList(selectedInvoiceList.filter((invoice) => invoice.id !== row.id));
		}
	};

	const onPublish = (list: InvoiceView[], currentAction: ChangeStatusOption) => {
		setOpenDatePicker(true);
		setCurrentAction(currentAction);
		setSelectedInvoiceList(list);
	};

	const onUpdateStatusFromDatePicker = async (date: Date) => {
		setOpenDatePicker(false);
		if (currentAction === ChangeStatusOption.MARK_AS_PAID) {
			await reconcileDocuments(
				date,
				selectedInvoiceList.map((invoice) => ({ id: invoice.id, type: invoice.accountingEventType })),
				dispatch,
			);
		} else {
			await submitDocuments(
				date,
				selectedInvoiceList.map((invoice) => ({ id: invoice.id, type: invoice.accountingEventType })),
				dispatch,
			);
		}
		setSelectedInvoiceList([]);
		setCurrentAction('');
		getData();
	};

	const onReconcileInvoices = async (date: Date) => {
		await reconcileDocuments(
			date,
			selectedInvoiceList.map((invoice) => ({ id: invoice.id, type: invoice.accountingEventType })),
			dispatch,
		);
		setSelectedInvoiceList([]);
		setCurrentAction('');
		getData();
	};

	const onCloseDatePicker = (isOpen: boolean) => {
		setOpenDatePicker(isOpen);
		setCurrentAction('');
	};

	const filterChangeStatusOptions = () => {
		let optionList: MenuOption[] = [
			{
				title: 'Download Documents',
				iconType: 'download',
				onPress: () => {
					downloadDocuments(
						selectedInvoiceList.map((invoice) => invoice.id),
						dispatch,
					);
				},
			},
		];

		if (isDocumentTabsCanBeEdit(selectedSummary)) {
			const linkedAccountingIntegrations = availableIntegration.filter((item) => {
				const connectedCount = item?.connectedCount ? +item?.connectedCount : 0;
				return (item.connectionStatus === IntegrationStatus.LINKED || !!connectedCount) && item.category === IntegrationCategory.ACCOUNTING;
			});

			optionList = [
				{
					title: 'Send Documents',
					iconType: 'paper_airplane',
					color: 'primary',
					disabled: {
						isDisabled: selectedInvoiceList.some(
							(invoice) => ![DocumentState.REVIEW_READY_TO_SEND, DocumentState.DRAFT, DocumentState.FINALIZED].includes(invoice.state),
						),
						reason: t('cantSendListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
					subList: [
						{
							title: 'Send now',
							onPress: () => {
								sendMultipleDocuments(
									t,
									selectedInvoiceList.map((item) => item.id),
									dispatch,
								);
								setSelectedInvoiceList([]);
							},
							iconType: 'paper_airplane',
						},
						{
							title: 'Mark as Sent',
							onPress: () => onPublish(selectedInvoiceList, ChangeStatusOption.PUBLISH),
							iconType: 'letter',
						},
					],
				},
				{
					title: t('markAsPaid'),
					iconType: 'v_circle',
					color: 'success',
					subList: [
						{
							title: 'Pick a Date',
							onPress: () => {
								setOpenDatePicker(true);
								setCurrentAction(ChangeStatusOption.MARK_AS_PAID);
							},
							iconType: 'date',
						},
						{
							title: 'Paid Today',
							onPress: () => {
								onReconcileInvoices(dayjs().toDate());
							},
							iconType: 'clock',
						},
					],
					disabled: {
						isDisabled: !!selectedInvoiceList.find((invoice) =>
							[DocumentState.REVIEW_MISSING_DATA, DocumentState.COLLECTING_INFO].includes(invoice.state),
						),
						reason: t('cantMarkListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
				},
				{
					title: selectedInvoiceList?.every((doc) => doc.state === DocumentState.PAYMENT_FAILED) ? t('recharge') : t('charge'),
					iconType: 'solo_payment_link',
					color: 'neutral',
					onPress: () => {
						chargeMultipleInvoices(
							t,
							selectedInvoiceList.map((invoice) => invoice.id),
							dispatch,
						);
						setSelectedInvoiceList([]);
					},
					disabled: {
						isDisabled: selectedInvoiceList.some(
							(invoice) =>
								!(invoice?.billingDetails?.paymentOptions?.includes(PaymentOptions.AUTO_CHARGE) && invoice?.billingDetails?.paymentGatewayCustomerId),
						),
						reason: t('cantChargeListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
				},
				...optionList,
			];

			if (linkedAccountingIntegrations.length) {
				optionList = [
					...optionList,
					{
						title: t('syncWith'),
						iconType: 'refresh',
						color: 'primary',
						subList: linkedAccountingIntegrations.map((integration) => ({
							title: t(convertIntegrationTypeToStringService(integration.platform)),
							onPress: () =>
								syncInvoiceWithAccountingIntegration(
									dispatch,
									selectedInvoiceList.map((item) => item.id),
									t,
									getData,
								),
							iconType: integration?.iconSrc as IconImgType,
						})),
						disabled: {
							isDisabled: !!selectedInvoiceList.find(
								(invoice) => invoice.syncBlockers?.length || invoice?.accountingEventType === AccountingEventType.CREDIT,
							),
							reason: t('cantSyncListWithThisStatus'),
							tooltipStyle: styles.changeStatusTooltipStyle,
						},
					},
				];
			}

			optionList = [
				...optionList,

				{
					title: 'Cancel Documents',
					iconType: 'error_icon',
					color: 'destructive',
					onPress: () => selectedInvoiceList.map((item) => cancelInvoices(item?.id, dispatch, getData)),
					disabled: {
						isDisabled: selectedInvoiceList.some((invoice) => invoice.state === DocumentState.CANCELED),
						reason: t('cantCancelListWithThisStatus'),
						tooltipStyle: styles.changeStatusTooltipStyle,
					},
				},
			];
		}

		return optionList;
	};

	const onSummaryBlockClick = (type: summaryType) => {
		setSelectedSummary(type);
		setSelectedInvoiceList([]);
		setPageNumber(0);
		setSearchText('');
	};

	const onHideShowDrafts = (showDraft: boolean) => {
		setShowDraft(showDraft);
		setPageNumber(0);
	};

	const onSearch = useDebounce(getData, 750);

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={getData} />

				<DatePicker
					hideInput
					openDatePicker={openDatePicker}
					datePickerLabel={currentAction === ChangeStatusOption.MARK_AS_PAID ? t('choosePaymentDate') : t('choosePublishDate')}
					headerLabelStyle={styles.datePickerHeaderLabelStyle}
					footerType={FooterTypeOptions.SHOW_CONFIRM_BUTTONS}
					onConfirm={(date) => onUpdateStatusFromDatePicker(date)}
					updateIsOpen={onCloseDatePicker}
					defaultFromDate={
						selectedInvoiceList.length
							? currentAction === ChangeStatusOption.MARK_AS_PAID
								? dayjs(selectedInvoiceList[0].dueDate).toDate()
								: dayjs(selectedInvoiceList[0].issueDate).toDate()
							: new Date()
					}
				/>
				<Sidebars openSideBarById={openSideBarById} onCloseSideBars={() => setOpenSideBarById(undefined)} onDataChange={getData} />

				<FilterSideBar filterData={sideBarFilters} isOpen={openFilter} closeSideBar={() => setOpenFilter(false)} updateFilters={setSideBarFilters} />

				<header className={styles.pageHeader} style={{ height: sideBarFilters?.filterTags.length ? '16rem' : '10rem' }}>
					<div className={styles.flexColumn}>
						<h1 style={{ marginTop: '1.4rem' }}>{t('invoicesPage.title')}</h1>

						<FilterTags
							filterData={sideBarFilters}
							onRemoveTag={(newTags) => sideBarFilters && setSideBarFilters((prev) => ({ ...prev, ...newTags }))}
						/>
					</div>

					<div className={styles.rightButtonsContainer}>
						<SearchBar
							placeholder={t('search')}
							value={searchText}
							onChange={(val) => {
								setSearchText(val);
								onSearch(val, sideBarFilters, selectedSummary);
							}}
							className={styles.search}
						/>
						<DateRangeFilters
							data={fullFiltersSelectOptions}
							defaultSelectValue={isTimePeriodSelectDisabled(sideBarFilters.filterData) ? t('customDates') : timePeriod?.title}
							callback={setTimePeriod}
							disabled={isTimePeriodSelectDisabled(sideBarFilters.filterData)}
						/>

						<Button onClick={() => setOpenFilter(true)} type='outlined' color='neutral' className={styles.filterButton}>
							{t('filters')}
							<Icon imgType='filters' />
						</Button>

						<MenuList
							isMenuOpen={isOptionsMenuOpen}
							openMenu={() => setIsOptionsMenuOpen(false)}
							optionsList={documentsViewOptionsList({ t, onDownloadCSV: () => onDownloadInvoicesCSV(t, invoicesList) })}
						>
							<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)}>
								<Icon imgType='hamburger' color='neutral700' />
							</Button>
						</MenuList>

						{!!selectedInvoiceList.length && (
							<MenuList
								optionsList={filterChangeStatusOptions()}
								isMenuOpen={openMultipleSelectOptions}
								openMenu={() => setOpenMultipleSelectOptions(false)}
							>
								<Button onClick={() => setOpenMultipleSelectOptions(!openMultipleSelectOptions)} className={styles.mainButton}>
									{`${t('changeStatus')} ${
										!!selectedInvoiceList.length && `(${selectedInvoiceList.length} ${t(selectedInvoiceList.length > 1 ? 'Invoices' : 'Invoice')})`
									}`}
									<Icon imgType='arrow_down' color='white' width={1.5} className={openMultipleSelectOptions ? styles.arrowUp : styles.arrowDown} />
								</Button>
							</MenuList>
						)}
					</div>
				</header>

				<SummaryBar
					summary={getInvoicesSummaryBlocksData(t, invoicesSummaries)}
					className={styles.summarySection}
					blockClassName={styles.blockClassName}
					selectedSummary={selectedSummary}
					onClick={onSummaryBlockClick}
				/>

				<InvoiceTable
					refreshData={getData}
					tableProps={{
						showSwitch: selectedSummary == summaryType.WARNING,
						sort: sort,
						numberOfItems: numberOfItems,
						showCheckBox: true,
						header: invoicesTableHeader(getData),
						rows: invoicesList,
						className: sideBarFilters?.filterTags.length ? styles.tableWithTagsContainer : styles.tableContainer,
						numberOfPages: Math.ceil(totalCount / +numberOfItems),
						totalNumberOfItems: totalCount,
						onUpdateNumberOfItems: setNumberOfItems,
						onUpdatePageNumber: setPageNumber,
						onRowPress: (data) =>
							setOpenSideBarById({
								sideBarType: SidebarType.INVOICE,
								id: data.id,
							}),
						onFlipOrder: (accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC }),
						onRowChecked: onInvoiceChecked,
						onCheckAll: (isChecked) => (isChecked ? setSelectedInvoiceList([...invoicesList]) : setSelectedInvoiceList([])),
						onToggleSwitch: onHideShowDrafts,
					}}
				/>
			</div>
		</div>
	);
};
