import { convertCurrencyToSign } from '@received/pricing-model';
import dayjs from 'dayjs';
import { Cell, PriceCell } from '../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { getDocumentIdNameByType, netTermsToLabel } from '../../../utils/GeneralUtils';
import { PayoutView } from '../../../types/PayoutsTypes';
import { formatPaymentDueDate } from '../../../pages/_Billing/Payouts/Payouts.utils';
import { PayoutStatusTag } from '../../StatusTags';

export const payoutsTableHeader = (refreshData: () => void) => {
	return [
		{
			headerTitle: 'payoutNo',
			accessor: 'payoutNumber',
			isSortable: true,
			CustomComponent: (item: PayoutView) => (
				<Cell
					accessor={getDocumentIdNameByType(item)}
					subAccessor={item?.payoutNumberInSeries ? `${item.payoutNumberInSeries}/${item.payoutSeriesTotalCount}` : ''}
				/>
			),
		},
		{
			headerTitle: 'refAgreement',
			accessor: 'agreementNumber',
			isSortable: true,
			CustomComponent: (item: PayoutView) => (
				<Cell accessor={contractIdFormat(item?.poNumber, item?.agreementNumber)} subAccessor={item.agreementName} />
			),
		},
		{
			headerTitle: 'payoutDate',
			accessor: 'payoutDate',
			isSortable: true,
			CustomComponent: (item: PayoutView) => <Cell accessor={`${dayjs(item.issueDate).format(DATE_FORMAT)}`} />,
		},

		{
			headerTitle: 'DueDate',
			accessor: 'payoutDueDate',
			isSortable: true,
			CustomComponent: (item: PayoutView) => <Cell accessor={formatPaymentDueDate(item)} subAccessor={`${netTermsToLabel(item?.netTerms)} `} />,
		},
		{
			headerTitle: 'totalFee',
			accessor: 'totalFee',
			isSortable: true,
			CustomComponent: (item: PayoutView) => <PriceCell mainNumber={item?.totalFee} currency={convertCurrencyToSign(item?.currency)} />,
		},

		{
			headerTitle: 'status',
			accessor: 'state',
			isSortable: true,
			disableRowClick: true,
			CustomComponent: (item: PayoutView) => <PayoutStatusTag document={item} refreshData={refreshData} />,
		},
	];
};
