import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ChartData,
	LineController,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	CategoryScale,
	ArcElement,
	DoughnutController,
} from 'chart.js';
import { colors } from '../../../../constants/colors';
import { priceNumberGraphsDisplay } from '../../../../utils/NumberUtils';
import styles from './HalfDoughnutGraph.module.scss';

ChartJS.register(LineController, ArcElement, DoughnutController, LineElement, PointElement, CategoryScale, Title, Tooltip, Filler, Legend);

interface HalfDoughnutGraphProps {
	labels: string[];
	data: { count: number; color: string }[];
	totalSum?: number;
}

export const HalfDoughnutGraph = ({ labels, data, totalSum }: HalfDoughnutGraphProps) => {
	const chartRef = useRef<ChartJS>(null);
	const [chartData, setChartData] = useState<ChartData<any>>({
		datasets: [],
	});

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}

		const chartData = {
			labels: labels,
			datasets: [
				{
					data: data.map(({ count }) => count),
					backgroundColor: data.map(({ color }) => color),
				},
			],
		};

		setChartData(chartData);
	}, [labels, data]);

	const options = {
		responsive: true,
		cutout: '93%',
		rotation: -90,
		circumference: 180,
		aspectRatio: 2,
		maintainAspectRatio: true,
		borderRadius: 10,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				titleFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				bodyFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				displayColors: false,
				backgroundColor: colors.neutral900,
				padding: 10,
			},
		},
	};

	return (
		<div className={styles.container}>
			<div className={styles.totalText}>
				<span>{priceNumberGraphsDisplay(totalSum || 0)}</span>
			</div>
			<Chart type='doughnut' options={options} ref={chartRef} data={chartData} />
		</div>
	);
};
