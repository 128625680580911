import { ContractSyncStatusDto, IntegrationPlatform, IntegrationStatus, SyncDirection, SyncStatus, SyncStatusDto } from '@received/pricing-model';
import { images } from '../constants/images';

export type TitleOptions = 'NetSuite' | 'QuickBooks online' | 'QuickBooks sandbox' | 'Xero' | 'Salesforce' | 'Stripe' | 'HubSpot' | '';

export interface SyncStatusMetadata {
	status: SyncStatus;
	platformsNames: string[];
	icon: string;
	errors?: { platformName: string; errors: string[] }[];
}

export const getSyncStatus = (item: { syncStatus?: SyncStatusDto[] }): undefined | SyncStatusMetadata => {
	if (item?.syncStatus?.length) {
		if (item.syncStatus.some((status) => status.status === SyncStatus.SYNCING)) {
			return {
				icon: images.syncing,
				status: SyncStatus.SYNCING,
				platformsNames: item.syncStatus
					.filter((status) => status.status === SyncStatus.SYNCING)
					.map((platform) => convertIntegrationTypeToStringService(platform.platformName)),
			};
		}
		if (item.syncStatus.some((status) => status.status === SyncStatus.SYNC_FAILED)) {
			return {
				icon: images.syncError,
				status: SyncStatus.SYNC_FAILED,
				platformsNames: [],
				errors: item.syncStatus
					.filter((status) => status.status === SyncStatus.SYNC_FAILED)
					.map((platform) => ({
						platformName: convertIntegrationTypeToStringService(platform.platformName),
						errors: platform?.metadata?.errors,
					})),
			};
		}
		if (item.syncStatus.some((status) => status.status === SyncStatus.OUT_OF_SYNC)) {
			return {
				icon: images.outOfSync,
				status: SyncStatus.OUT_OF_SYNC,
				platformsNames: item.syncStatus
					.filter((status) => status.status === SyncStatus.OUT_OF_SYNC)
					.map((platform) => convertIntegrationTypeToStringService(platform.platformName)),
				errors: item.syncStatus
					.filter((status) => status.status === SyncStatus.OUT_OF_SYNC)
					.map((platform) => ({
						platformName: convertIntegrationTypeToStringService(platform.platformName),
						errors: platform?.metadata?.errors,
					})),
			};
		}
		if (item.syncStatus.some((status) => status.status === SyncStatus.SYNCED)) {
			return {
				icon: images.connected,
				status: SyncStatus.SYNCED,
				platformsNames: item.syncStatus
					.filter((status) => status.status === SyncStatus.SYNCED)
					.map((platform) => convertIntegrationTypeToStringService(platform.platformName)),
			};
		}
	}
	return undefined;
};

export const getImportedFrom = (item?: { syncStatus?: ContractSyncStatusDto[] }): undefined | ContractSyncStatusDto => {
	if (item?.syncStatus && item?.syncStatus.length) {
		return item.syncStatus.find((status) => status.direction === SyncDirection.IMPORT);
	}
};

export const convertIntegrationTypeToDescription = (type: IntegrationPlatform) => {
	switch (type) {
		case IntegrationPlatform.NET_SUITE:
			return 'integrationsNetsuiteDescription';
		case IntegrationPlatform.QUICK_BOOKS_ONLINE:
			return 'integrationsQuickbooksDescription';
		case IntegrationPlatform.QUICK_BOOKS_ONLINE_SANDBOX:
			return 'integrationsQuickbooksDescription';
		case IntegrationPlatform.XERO:
			return 'integrationXeroDescription';
		case IntegrationPlatform.SALESFORCE:
			return 'integrationSalesforceDescription';
		case IntegrationPlatform.STRIPE:
			return 'integrationsConnectStripe';
		case IntegrationPlatform.HUBSPOT:
			return 'integrationsConnectHubSpot';
		default:
			return '';
	}
};

export const convertIntegrationTypeToStringService = (type: IntegrationPlatform): TitleOptions => {
	switch (type) {
		case IntegrationPlatform.NET_SUITE:
			return 'NetSuite';
		case IntegrationPlatform.QUICK_BOOKS_ONLINE:
			return 'QuickBooks online';
		case IntegrationPlatform.QUICK_BOOKS_ONLINE_SANDBOX:
			return 'QuickBooks sandbox';
		case IntegrationPlatform.XERO:
			return 'Xero';
		case IntegrationPlatform.SALESFORCE:
			return 'Salesforce';
		case IntegrationPlatform.STRIPE:
			return 'Stripe';
		case IntegrationPlatform.HUBSPOT:
			return 'HubSpot';
		default:
			return '';
	}
};

export const getLabelByStatus = (t: any, status: IntegrationStatus) => {
	switch (status) {
		case IntegrationStatus.LINKED:
			return t('connected');
		case IntegrationStatus.PENDING_AUTH:
			return t('connecting');
		case IntegrationStatus.MISSING_DETAILS:
			return t('missingDetails');
		case IntegrationStatus.SYNCING:
			return t('syncing');
	}
};
