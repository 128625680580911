import { convertCurrencyToSign } from '@received/pricing-model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, EmptyStateCard } from '../../../../../components';
import { InvoiceView } from '../../../../../types/invoiceTypes';
import { Store } from '../../../../../types/storeTypes';
import { priceNumberWithCurrencySignDisplay } from '../../../../../utils/NumberUtils';
import styles from './InvoicesForReviewCards.module.scss';

interface InvoicesForReviewCardsProps {
	invoices: InvoiceView[];
	className?: string;
	onClick: (invoice: InvoiceView) => void;
}

export const InvoicesForReviewCards = ({ invoices, className, onClick }: InvoicesForReviewCardsProps) => {
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	return (
		<div className={clsx(styles.container, className)}>
			{invoices.length > 0 ? (
				<div className={styles.content}>
					<span className={styles.title}>{t('topInvoicesForReview')}</span>
					<div className={styles.body}>
						{invoices.map((invoice, index) => (
							<Button key={index} className={styles.invoiceContainer} type='link' color='neutral' onClick={() => onClick(invoice)}>
								<div className={styles.numberContainer}>0{index + 1}</div>
								<div className={styles.text}>
									<span className={styles.customerName}>{invoice.customerName}</span>
									<span className={styles.invoiceTotal}>
										{priceNumberWithCurrencySignDisplay(invoice.total || 0, convertCurrencyToSign(appCurrency))}
									</span>
								</div>
							</Button>
						))}
					</div>
				</div>
			) : (
				<EmptyStateCard
					showIcon
					imgType='v_circle'
					imgAlt='empty-state'
					title={t('allInvoicesHaveBeenSent')}
					subTitle={t('allInvoicesAreReviewedOnTime')}
					titleStyle={styles.emptyStateTitle}
					subTitleStyle={styles.emptyStateSubTitle}
				/>
			)}
		</div>
	);
};
