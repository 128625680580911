import { BillingAccountDetails } from '../../types/customerTypes';

export interface BillingDetailsFormType extends BillingAccountDetails {
	bankError?: boolean;
	branchError?: boolean;
	ibanError?: boolean;
	swiftError?: boolean;
	accountError?: boolean;
	routingError?: boolean;
	addressError?: boolean;
}

export const initialState: BillingDetailsFormType = {
	bank: '',
	bankError: false,
	branch: '',
	branchError: false,
	iban: '',
	ibanError: false,
	swift: '',
	swiftError: false,
	account: '',
	accountError: false,
	routing: '',
	address: '',
	accountName: '',
	bankCode: '',
	routingError: false,
	isDefault: false,
	addressError: false,
};

export const billingValidation = (data: BillingDetailsFormType) => {
	const formData = { ...data };
	let isValid = true;

	if (!formData.bank) {
		formData.bankError = true;
		isValid = false;
	}

	if (!formData.account) {
		formData.accountError = true;
		isValid = false;
	}

	return { isValid, data: formData };
};
