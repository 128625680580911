import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellTypes, CyclicDate, PricingType } from '@received/pricing-model';
import { PricingModelTab } from '../../../../types/contractTypes';
import { AdvancedSettings, Button, Icon, IssueDatePicker, PricingModelTableComponent, Select, Tooltip } from '../../..';
import { colors } from '../../../../constants/colors';
import { PricingModelTableViewTypeOptions } from '../../../PricingModelTable/PricingModelTable.utils';
import { isPaymentPeriodDisabled } from '../../../PricingModelTabContent';
import { paymentTimeSelect } from '../../../../constants/generalConstants';
import { diamond } from '../../../../constants/unicodes';
import { PricingModelTableColumns } from '../../../../types/pricingModelTypes';
import { PaymentTimeOptions } from '../../../../types/generalTypes';
import styles from './../ProductDetails.module.scss';

export interface PricingModelTableCardProps {
	tab: PricingModelTab;
	onRemoveProduct?(): void;
	updateTab?(tab: PricingModelTab): void;
}

export const PricingModelTableCard = ({ tab, onRemoveProduct, updateTab }: PricingModelTableCardProps) => {
	const { t } = useTranslation('translation');

	const isUsage = tab?.pricingType === PricingType.USAGE;

	return (
		<div key={tab.id} className={styles.tableCard}>
			<div className={styles.tableCardHeader}>
				<div className={styles.itemCardIconContainer} style={{ backgroundColor: isUsage ? colors.calming100 : colors.primary100 }}>
					<Icon imgType={isUsage ? 'usage_template' : 'subscription_template'} color={isUsage ? 'calming' : 'primary'} height={2} />
				</div>
				<div style={{ width: 'calc(100% - 2.4rem)' }}>
					<div
						className={styles.itemTag}
						style={{
							paddingBottom: '0.8rem',
							color: isUsage ? colors.calming : colors.primary,
							borderColor: isUsage ? colors.calming : colors.primary,
						}}
					>
						{tab?.productName}
					</div>
					<div className={styles.productName}>{tab.name}</div>
				</div>

				<div className={styles.rightButtons}>
					<AdvancedSettings
						title={t('advancedSettings')}
						settingsChildren={
							<div className={styles.settingsContainer}>
								<IssueDatePicker
									title={t('issueDay')}
									icon={'information'}
									customHeaderByBillingCycle={tab?.billingCycle}
									defaultIssueDate={tab?.issueDay}
									updateIssueDate={(date: CyclicDate) => {
										updateTab?.({ ...tab, issueDay: date || undefined });
									}}
									className={styles.issueDatePicker}
								/>

								<Tooltip
									disabled={!tab?.pricingModelTable?.columns.find((i: PricingModelTableColumns) => i.type == CellTypes.USAGE_AGGREGATOR_REFERENCE)}
									tooltipComponent={
										<>
											<div className={styles.tooltipText}>{t('beforeAndDuringPeriods')}</div>
											<div className={styles.tooltipText}>{t('cannotBeSelected')}</div>
											<div className={styles.tooltipText}>
												{t('active')} <span className={styles.purpleDiamond}>{diamond}</span> {t('usageComponent')}
											</div>
										</>
									}
								>
									<Select
										disabled={isPaymentPeriodDisabled(tab)}
										className={styles.periodSelect}
										headerStyle={styles.billingPeriodSelectTitle}
										data={paymentTimeSelect}
										onChange={(paymentTime: PaymentTimeOptions) => {
											updateTab?.({ ...tab, paymentTime });
										}}
										defaultValue={tab?.paymentTime}
										width={12.2}
									/>
								</Tooltip>
							</div>
						}
					/>

					{onRemoveProduct && (
						<Button type='outlined' color='neutral' dataTestId={`delete-${tab.name}`} onClick={onRemoveProduct}>
							<Icon imgType='delete' color='destructive' />
						</Button>
					)}
				</div>
			</div>

			<div className={styles.tableScroll}>
				<PricingModelTableComponent
					pricingModelTab={tab}
					classNames={{ tableContainer: styles.table }}
					pricingModelTable={tab.pricingModelTable}
					pricingModelTableViewType={PricingModelTableViewTypeOptions.CATALOG_PREVIEW_NOT_EDITABLE}
				/>
			</div>
		</div>
	);
};
