/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, CreateSupplier, EntitySelect, Icon, Input, Tooltip } from '../../../components';
import {
	getInvoiceSettings,
	DocumentStartingNumberSettings,
	DocumentSettingsInitialState,
	getCreditNoteSettings,
	validateSettings,
	updateInvoiceSettings,
	updateCreditNoteStartingNumber,
	updateCcEmailList,
} from './DocumentSettings.utils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { getSuppliers } from '../../../utils/CustomerUtils';
import { EntityForm } from '../../../components/CreateSupplier/EntityInfoTab/EntityInfoTab.utils';
import { Customer } from '../../../types/customerTypes';
import { splitEmailsWithCommasString } from '../../../utils/GeneralUtils';
import styles from './DocumentSettings.module.scss';

export const DocumentSettings = () => {
	const [invoiceStartingNumber, setInvoiceStartingNumber] = useState<DocumentStartingNumberSettings>(DocumentSettingsInitialState);
	const [creditNoteStartingNumber, setCreditNoteStartingNumber] = useState<DocumentStartingNumberSettings>(DocumentSettingsInitialState);
	const [disableUpdateButton, setDisableUpdateButton] = useState(true);
	const [isInputsChanged, setIsInputsChanged] = useState(false);
	const [entity, setEntity] = useState<Customer>();
	const [newEntity, setNewEntity] = useState<Customer>();
	const [allEntities, setAllEntities] = useState<Customer[]>([]);
	const [isCreateEntityOpen, setIsCreateEntityOpen] = useState(false);
	const [ccEmail, setCcEmail] = useState('');
	const [ccEmailList, setCcEmailList] = useState<string[]>([]);
	const [isSettingChange, setIsSettingChange] = useState(false);
	const [showError, setShowError] = useState({ ccDocument: false });

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();

	useEffect(() => {
		setDefaultEntity();
	}, []);

	useEffect(() => {
		setInvoiceSettings();
		setCreditNoteSettings();
	}, [entity]);

	useEffect(() => {
		const isValid = validateSettings(invoiceStartingNumber, creditNoteStartingNumber);
		setDisableUpdateButton(isValid);
	}, [invoiceStartingNumber, creditNoteStartingNumber]);

	const setDefaultEntity = async () => {
		const entities = await getSuppliers(dispatch);
		setAllEntities(entities);
		entities.length && onSelectEntity(entities[0]);
	};

	const onSelectEntity = async (entity?: Customer) => {
		setEntity(entity);
		if (entity?.id) {
			setCcEmailList(entity?.settings?.documentsCC || []);
		}
	};

	const setInvoiceSettings = async () => {
		if (entity?.id) {
			const res = await getInvoiceSettings(dispatch, entity?.id);
			if (res) {
				setInvoiceStartingNumber({
					editable: res.editable,
					number: res.number,
					documentPrefix: res.documentPrefix,
					entityPrefix: res.entityPrefix,
				});
			}
		}
	};

	const setCreditNoteSettings = async () => {
		if (entity?.id) {
			const res = await getCreditNoteSettings(dispatch, entity?.id);
			res &&
				setCreditNoteStartingNumber({
					editable: res.editable,
					number: res.number,
					documentPrefix: res.documentPrefix,
					entityPrefix: res.entityPrefix,
				});
		}
	};

	const updateData = async () => {
		if (isInputsChanged) {
			if (invoiceStartingNumber.editable && entity?.id) {
				await updateInvoiceSettings(dispatch, invoiceStartingNumber, entity?.id);
			}
			if (creditNoteStartingNumber.editable && entity?.id) {
				await updateCreditNoteStartingNumber(dispatch, creditNoteStartingNumber, entity?.id);
			}
			setIsInputsChanged(false);
		}
		if (isSettingChange) {
			if (entity?.id) {
				// TODO split customer and supplier settings
				// @ts-ignore
				await updateCcEmailList(dispatch, { ...entity, settings: { ...entity?.settings, documentsCC: ccEmailList } });
			}
			setIsSettingChange(false);
		}

		dispatch(setOpenSuccessErrorModal({ successErrorMassage: successErrorMassageOptions.SUCCESS.SETTING_UPDATED_SUCCESSFULLY }));
	};

	const addEntity = async (entityData: EntityForm) => {
		const entities = await getSuppliers(dispatch);
		setAllEntities(entities);
		onSelectEntity(entityData);
	};

	const onAddCcEmail = () => {
		setShowError((prev) => ({ ...prev, ccDocument: false }));
		const { validEmails, invalidEmails } = splitEmailsWithCommasString(ccEmail);
		const newList = validEmails ? [...ccEmailList, ...validEmails].slice(0, 2) : ccEmailList;

		setIsSettingChange(true);
		setCcEmailList(newList);
		setCcEmail(newList.length < 2 ? invalidEmails.join(',') : '');
		if (invalidEmails.length && newList.length < 2) {
			setShowError((prev) => ({ ...prev, ccDocument: true }));
		}
	};

	const onRemoveEmail = (index: number) => {
		setIsSettingChange(true);
		const newList = [...ccEmailList];
		newList.splice(index, 1);
		setCcEmailList(newList);
	};

	const onUpdateCCInput = (val: string) => {
		setCcEmail(val);
		setShowError((prev) => ({ ...prev, ccDocument: false }));
	};

	return (
		<>
			<CreateSupplier
				isOpen={isCreateEntityOpen}
				overlayClassName={styles.newEntityModel}
				defaultEntity={newEntity}
				companiesListLength={allEntities?.length}
				closeModal={() => {
					setNewEntity(undefined);
					setIsCreateEntityOpen(false);
				}}
				onCreateUpdateSupplier={addEntity}
			/>

			<div className={styles.header}>
				<h2>{t('documentsSettings')}</h2>
				<Tooltip tooltipComponent={(isSettingChange ? false : disableUpdateButton) && t('settingNotEditable')} placement='left'>
					<Button onClick={updateData} className={styles.update} disabled={isSettingChange ? false : disableUpdateButton || !isInputsChanged}>
						{t('update')}
					</Button>
				</Tooltip>
			</div>

			<div className={styles.content}>
				<div className={styles.entityContainer}>
					<EntitySelect
						entity={entity}
						onAddEntity={() => setIsCreateEntityOpen(true)}
						onEntitySelected={(entity) => onSelectEntity(entity)}
						detailsClassName={styles.detailsClassName}
						selectContainer={styles.selectContainer}
					/>
				</div>

				<div className={styles.section}>
					<div className={styles.descriptionContent}>
						<span className={styles.title}>{t('invoicesNumbering')}</span>
						<span className={styles.desc}>{t('invoicesNumberingDesc')}</span>
					</div>
					<div className={styles.inputs}>
						<span className={clsx(styles.inputTitle, !creditNoteStartingNumber?.editable && styles.disabled)}>{t('invoiceNumberStart')}*</span>
						<div className={styles.inputContentInRow}>
							<Input
								disabled
								placeholder='INV'
								value={invoiceStartingNumber.documentPrefix}
								className={styles.documentPrefix}
								containerClass={clsx(styles.flexCenter, styles.separator)}
								showError={
									invoiceStartingNumber.documentPrefix
										? invoiceStartingNumber?.documentPrefix?.length < 2 || invoiceStartingNumber?.documentPrefix?.length > 4
										: !!entity
								}
								onFocus={(e) => e.target.select()}
								onChange={(documentPrefix: string) => {
									setInvoiceStartingNumber({ ...invoiceStartingNumber, documentPrefix });
									setIsInputsChanged(true);
								}}
							/>

							<Input
								disabled={!invoiceStartingNumber.editable}
								placeholder='AA'
								value={invoiceStartingNumber.entityPrefix}
								className={styles.entityPrefix}
								showError={
									invoiceStartingNumber.entityPrefix
										? invoiceStartingNumber?.entityPrefix?.length < 2 || invoiceStartingNumber?.entityPrefix?.length > 4
										: !!entity
								}
								onChange={(entityPrefix: string) => {
									setInvoiceStartingNumber({ ...invoiceStartingNumber, entityPrefix });
									setIsInputsChanged(true);
								}}
								onFocus={(e) => e.target.select()}
							/>
							<Input
								disabled={!invoiceStartingNumber.editable}
								value={invoiceStartingNumber.number}
								placeholder='10001'
								type='number'
								containerClass={styles.documentNumberInput}
								onFocus={(e) => e.target.select()}
								onChange={(number: string) => {
									setInvoiceStartingNumber({ ...invoiceStartingNumber, number });
									setIsInputsChanged(true);
								}}
							/>
						</div>
						<span className={styles.inputDesc}>{t('invoiceStartNumberInputDesc')}</span>
					</div>
				</div>

				<div className={styles.section}>
					<div className={styles.descriptionContent}>
						<span className={styles.title}>{t('creditNoteNumbering')}</span>
						<span className={styles.desc}>{t('creditNoteNumberingDesc')}</span>
					</div>
					<div className={styles.inputs}>
						<span className={clsx(styles.inputTitle, !creditNoteStartingNumber?.editable && styles.disabled)}>{t('creditNoteNumberStart')}*</span>
						<div className={styles.inputContentInRow}>
							<Input
								disabled
								placeholder='CN'
								value={creditNoteStartingNumber.documentPrefix}
								className={styles.documentPrefix}
								containerClass={clsx(styles.flexCenter, styles.separator)}
								showError={
									creditNoteStartingNumber.documentPrefix
										? creditNoteStartingNumber?.documentPrefix?.length < 2 || creditNoteStartingNumber?.documentPrefix?.length > 4
										: !!entity
								}
								onChange={(documentPrefix: string) => {
									setCreditNoteStartingNumber({ ...creditNoteStartingNumber, documentPrefix });
									setIsInputsChanged(true);
								}}
								onFocus={(e) => e.target.select()}
							/>
							<Input
								disabled={!creditNoteStartingNumber.editable}
								placeholder='AA'
								value={creditNoteStartingNumber.entityPrefix}
								className={styles.entityPrefix}
								showError={
									creditNoteStartingNumber.entityPrefix
										? creditNoteStartingNumber?.entityPrefix?.length < 2 || creditNoteStartingNumber?.entityPrefix?.length > 4
										: !!entity
								}
								onChange={(entityPrefix: string) => {
									setCreditNoteStartingNumber({ ...creditNoteStartingNumber, entityPrefix });
									setIsInputsChanged(true);
								}}
								onFocus={(e) => e.target.select()}
							/>
							<Input
								disabled={!creditNoteStartingNumber.editable}
								value={creditNoteStartingNumber.number}
								placeholder='10001'
								type='number'
								containerClass={styles.documentNumberInput}
								onFocus={(e) => e.target.select()}
								onChange={(number: string) => {
									setCreditNoteStartingNumber({ ...creditNoteStartingNumber, number });
									setIsInputsChanged(true);
								}}
							/>
						</div>
						<span className={styles.inputDesc}>{t('creditNoteStartNumberInputDesc')}</span>
					</div>
				</div>

				<div className={styles.documentCCSection}>
					<div className={styles.descriptionContent}>
						<span className={styles.title}>{t('documentCC')}</span>
						<span className={styles.desc}>{t('documentCCDescription')}</span>
					</div>

					<div className={styles.documentCCInput}>
						<Input
							placeholder={t('addMax2email')}
							value={ccEmail}
							className={ccEmailList.length ? styles.inputBorder : ''}
							onChange={(val: string) => ccEmailList.length < 2 && onUpdateCCInput(val)}
							disabled={ccEmailList.length > 1 || !entity}
							showError={showError.ccDocument}
							onKeyDown={(e: any) => {
								if (e.key === 'Enter') {
									if (!(!splitEmailsWithCommasString(ccEmail).validEmails?.length || ccEmailList.length > 1)) {
										setShowError((prev) => ({ ...prev, ccDocument: false }));
										e.target.blur();
										onAddCcEmail();
									}
								}
							}}
							rightChildren={
								<Button
									onClick={onAddCcEmail}
									disabled={!splitEmailsWithCommasString(ccEmail).validEmails.length || ccEmailList.length > 1}
									className={styles.addButton}
								>
									<Icon imgType='add' color='white' />
								</Button>
							}
						/>

						{ccEmailList.map((item, index) => (
							<div key={index} className={clsx(styles.emailRowItem, index === ccEmailList.length - 1 && styles.lastEmailRowItem)}>
								<div className={styles.userEmailContainer}>
									<div className={styles.userIconContainer}>
										<Icon imgType='user' color='neutral400' height={1.8} />
									</div>
									<div className={styles.fontSize14}>{item}</div>
								</div>
								<Button type='link' onClick={() => onRemoveEmail(index)} className={styles.emailRowItemButton}>
									<Icon imgType='delete' color='neutral400' height={1.8} />
								</Button>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};
