import { useTranslation } from 'react-i18next';
import { Button, Collapsible, Icon } from '../..';
import { CustomerForm, tabIndexes } from '../CreateCustomer.utils';
import { truncateString } from '../../../utils/GeneralUtils';
import { RemoteIdInput } from '../RemoteIdInput/RemoteIdInput';
import { convertTypeToIcon } from '../../../Integrations/OutsourceIntegrations/IntegrationLayout.utils';
import styles from '../CreateCustomer.module.scss';

export interface IntegrationIDsProps {
	customerDetails: CustomerForm;
	updateForm(field: string, data?: string): void;
}

export const IntegrationIDsDetails = ({ customerDetails, updateForm }: IntegrationIDsProps) => {
	const { t } = useTranslation('translation');
	return (
		<Collapsible
			headerClassName={styles.collapsibleHeader}
			className={styles.collapsible}
			heightWhenOpen={'5.4rem'}
			header={
				<div className={styles.headerContainer}>
					<div className={styles.iconWrapper}>
						<Icon imgType='key' className={styles.headerIcon} />
					</div>
					<span className={styles.title}>{t('integrationsIds')}</span>
				</div>
			}
		>
			<div className={styles.wrapper}>
				<div className={styles.integrationIdsContent}>
					<RemoteIdInput tabIndex={tabIndexes.REMOTE_ID} value={customerDetails?.remoteId} onChange={(value, e) => updateForm('remoteId', value)} />
					{customerDetails?.integrationsRemoteIds?.map((remoteId, index) => (
						<Button
							key={index}
							type='link'
							color='neutral'
							className={styles.integrationIdBox}
							onClick={() => navigator.clipboard.writeText(remoteId.id || '')}
						>
							<div className={styles.leftIntegrationContentBox}>
								<img className={styles.integrationIcon} src={convertTypeToIcon(remoteId.platform)} color='neutral600' />
								<span>{truncateString(remoteId?.id || '')}</span>
							</div>
							<Button key={index} type='link' color='neutral' className={styles.copyButton}>
								<Icon imgType='copy' width={2} className={styles.copy} color='neutral400' />
							</Button>
						</Button>
					))}
				</div>
			</div>
		</Collapsible>
	);
};
