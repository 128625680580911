import { BasePlacement } from '@popperjs/core';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { Button, CheckBox, Icon } from '../..';
import styles from './CheckedTagsList.module.scss';

export interface CheckedTag {
	id?: string;
	isChecked: boolean;
	label: string;
	iconSrc: string;
}

interface CheckedTagsListProps {
	data: CheckedTag[];
	placement?: BasePlacement;
	customTop?: number;
	customLeft?: number;
	showError?: boolean;
	onUpdateTags: (tags: CheckedTag[]) => void;
}

export const CheckedTagsList = ({ data, showError, placement, customTop, customLeft, onUpdateTags }: CheckedTagsListProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [items, setItems] = useState<CheckedTag[]>([]);

	useEffect(() => {
		setItems(data);
	}, [data]);

	const { styles: style, attributes } = usePopper(referenceElement, popperElement, {
		placement: placement || 'top',
		modifiers: [
			{
				name: 'offset',
				enabled: true,
				options: {
					offset: [customTop || -10, customLeft || 10],
				},
			},
		],
	});

	const onUpdateList = (currentOption: CheckedTag, index: number) => {
		const newList = [...items];
		newList.splice(index, 1, { ...currentOption, isChecked: !currentOption.isChecked });
		onUpdateTags(newList);
		setItems(newList);
	};

	return (
		<div className={styles.container}>
			{items.map(
				({ iconSrc, label, isChecked }) =>
					!!isChecked && (
						<div key={label} className={styles.tag}>
							<img src={iconSrc} className={styles.icon} />
							<span className={styles.label}>{label}</span>
						</div>
					),
			)}

			<div ref={(ref) => setReferenceElement(ref)}>
				<Button
					color='neutral'
					type='secondary'
					className={clsx(styles.addButton, showError && styles.errorContainer)}
					onClick={() => setIsOpen(true)}
				>
					<Icon imgType='add' width={0.8} color='neutral700' />
				</Button>
			</div>

			{isOpen && data?.length
				? ReactDOM.createPortal(
						<>
							<div
								ref={(ref) => setPopperElement(ref)}
								{...attributes.popper}
								data-testid='checkbox-menu-list-portal'
								className={isOpen ? styles.menuList : styles.menuListHidden}
								style={style.popper}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								{items.map((item, index) => (
									<div key={index} onClick={() => onUpdateList(item, index)} className={styles.tag}>
										<CheckBox className={styles.addressCheckbox} isChecked={item.isChecked} setIsChecked={() => {}} />
										<img src={item.iconSrc} className={styles.icon} />
										<span className={styles.label}>{item.label}</span>
									</div>
								))}
							</div>
							{isOpen && (
								<div
									className={styles.selectOverlay}
									onClick={(e) => {
										e.stopPropagation();
										setIsOpen(false);
									}}
								></div>
							)}
						</>,
						document.body,
				  )
				: null}
		</div>
	);
};
