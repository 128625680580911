import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { ItemDto, ItemPriceDto, PricingModelTableRow, convertCurrencyToSign, DEFAULT_CURRENCY } from '@received/pricing-model';
import { checkExistingOrCreateItem } from '../../../TableRow.utils';
import { requiredColumnsEnum } from '../../../../PricingModelTable.utils';
import { getValueWithCurrencySign } from '../../../../../../utils/NumberUtils';
import styles from './ProductSelect.module.scss';

interface ProductSelectProps {
	value: string | number;
	data: ItemPriceDto[];
	className?: string;
	rowData: PricingModelTableRow;
	itemsList: ItemDto[];
	rowIndex: number;
	onKeyDown(event: any): void;
	updateValue: (val: string) => void;
	onSelectItem(item: ItemPriceDto): void;
	refreshData(): void;
}

export const ProductSelect = ({
	value,
	data,
	className,
	rowData,
	rowIndex,
	itemsList,
	onKeyDown,
	updateValue,
	onSelectItem,
	refreshData,
}: ProductSelectProps) => {
	const [referenceElement, setReferenceElement] = useState<any>(null);
	const [popperElement, setPopperElement] = useState<any>(null);
	const [text, setText] = useState<string>('');
	const [openSelect, setOpenSelect] = useState(false);
	const [filteredData, setFilteredData] = useState(data);
	const [focusedItem, setFocusedItem] = useState<number>();

	const dispatch = useDispatch();

	useEffect(() => {
		setText(value.toString());
	}, [value]);

	useEffect(() => {
		setFilteredData(data);
	}, [data]);

	useEffect(() => {
		if (openSelect) {
			const selectedValueIndex = data.findIndex((item) => item.item.name === text);
			const focusedElement = popperElement?.children?.[focusedItem || (selectedValueIndex >= 0 ? selectedValueIndex : 0)];
			focusedElement && focusedElement.scrollIntoView({ behavior: 'smooth' });
		}
	}, [focusedItem, openSelect]);

	useEffect(() => {
		document.addEventListener('click', outOfFocus, true);
		return () => {
			document.removeEventListener('click', outOfFocus, true);
		};
	}, [referenceElement, popperElement, text, itemsList]);

	const outOfFocus = async (event: { target: any }) => {
		// detect out of focus instead blur on input in case click on item
		if (event.target.id != `product-select-input${rowIndex}`) {
			await checkExistingItem();
		}
	};

	const { styles: popperStyle, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom-start',
		modifiers: [
			{
				name: 'flip',
				options: {
					flipVariations: true,
				},
			},
		],
	});

	const onChange = (val: string) => {
		const newOptions = data?.filter((item) => item.item.name.toLowerCase().includes(val.toString().toLowerCase()));
		setFilteredData(newOptions);
		setText(val);
		updateValue(val);
	};

	const onSelectValue = (item: ItemPriceDto) => {
		setText(item.item.name);
		onSelectItem({ ...item, itemId: item.item.id || '' });
		setOpenSelect(false);
	};

	const checkExistingItem = async () => {
		try {
			const item = await checkExistingOrCreateItem(itemsList, text, dispatch);
			rowData?.itemPrice &&
				onSelectItem({
					...rowData.itemPrice,
					itemId: item.id,
					item: item,
					price: +rowData.cells[requiredColumnsEnum.price].cellValue,
				});

			refreshData();
		} catch (error) {}
	};

	const onArrowNavigation = async (event: any) => {
		if (event.key === 'Enter' && focusedItem) {
			onSelectValue(data[focusedItem]);
			await checkExistingItem();
		}

		if (typeof focusedItem != 'number' && (event.keyCode === 38 || event.keyCode === 40)) {
			//start from selected
			const selectedIndex = data.findIndex((item) => item.item.name === text);
			return setFocusedItem(selectedIndex >= 0 ? selectedIndex : 0);
		}
		if (event.keyCode === 38) {
			//key up
			event.preventDefault();
			setFocusedItem((prev) => {
				const num = (prev || 0) - 1 >= 0 ? (prev || 0) - 1 : 0;
				return num;
			});
		} else if (event.keyCode === 40) {
			//key down
			event.preventDefault();

			setFocusedItem((prev) => {
				const num = (prev || 0) + 1 < filteredData.length ? (prev || 0) + 1 : filteredData.length - 1;
				return num;
			});
		}
	};

	return (
		<>
			<input
				id={`product-select-input${rowIndex}`}
				autoComplete='off'
				ref={(ref) => setReferenceElement(ref)}
				className={className}
				autoFocus
				onFocus={() => setOpenSelect(true)}
				value={text}
				onChange={(e) => onChange(e.target.value)}
				onKeyDown={(event) => {
					onKeyDown(event);
					onArrowNavigation(event);
				}}
				data-testid={`${rowIndex}-row-product-cell-input`}
			></input>

			{ReactDOM.createPortal(
				<div
					id='product-select'
					ref={(ref) => setPopperElement(ref)}
					{...attributes.popper}
					className={clsx(openSelect ? styles.listContainerOpen : styles.listContainerClose)}
					style={{
						...popperStyle.popper,
					}}
				>
					{filteredData.map((item, index) => {
						return (
							<div
								id={`product-select-input${rowIndex}`}
								className={clsx(styles.item, index === focusedItem && styles.focusedItem)}
								key={`${index}${item.item.name}${item.price}`}
								onClick={() => onSelectValue(item)}
							>
								<div id={`product-select-input${rowIndex}`}>{item.item.name}</div>
								<div className={styles.price} id={`product-select-input${rowIndex}`}>
									{getValueWithCurrencySign(item.price, convertCurrencyToSign(item?.currency || DEFAULT_CURRENCY))}
								</div>
							</div>
						);
					})}
				</div>,
				document.body,
			)}
		</>
	);
};
