import React from 'react';
import { images } from '../../constants/images';
import { loggerService } from '../loggerService/loggerService';
import styles from './ErrorBoundary.module.scss';

export class ErrorBoundary extends React.Component<
	{ children: React.ReactNode; customErrorHandler?: () => void; customUIComponent?: React.ReactNode; allowedRetries?: boolean },
	{ hasError: boolean; startRetries: boolean; retriesNumber: number }
> {
	constructor(props: any) {
		super(props);
		this.state = { hasError: false, startRetries: false, retriesNumber: 0 };
	}

	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// Log the error to an error reporting service
		if (this.props.allowedRetries && this.state.retriesNumber < 4) {
			this.setState((prev) => ({
				hasError: false,
				startRetries: true,
				retriesNumber: prev.retriesNumber + 1,
			}));
		}
		this.props.customErrorHandler?.();
		loggerService(`ErrorBoundary- error: ${JSON.stringify(error)}, ${JSON.stringify(errorInfo)}`);
		setTimeout(
			() =>
				this.setState({
					startRetries: false,
				}),
			20,
		);
	}

	render() {
		if (this.state.startRetries) {
			null;
		} else if (this.state.hasError) {
			// Render any custom fallback UI
			return this.props.customUIComponent ? (
				this.props.customUIComponent
			) : (
				<div className={styles.container}>
					<img alt='customer-success' src={images.somethingWentWrong} className={styles.icon}></img>

					<h1>Something went wrong.</h1>
					<h2 className={styles.subText}>Please refresh the page</h2>
				</div>
			);
		}

		return this.props.children;
	}
}
