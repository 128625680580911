import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EventType, IntegrationPlatform, IntegrationProvider } from '@received/pricing-model';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../types/storeTypes';
import { setListenerResponseType } from '../../../storeSlices/generalSlice';
import {
	getAvailableIntegrations,
	onCancelIntegration,
	onConnectEntity,
	onConnectStripe,
	onDisconnectEntity,
	onMissingDetailsEntity,
} from '../../../services/integrationService/integrationService';
import { MargeIntegrationsCard, IntegrationBySupplierCard } from '../../../components';
import { images } from '../../../constants/images';
import { IntegrationType } from '../../../types/integrationTypes';
import styles from './Integrations.module.scss';

export interface IntegrationsProps {
	chosenPlatform?: IntegrationPlatform;
}

export const Integrations = ({ chosenPlatform }: IntegrationsProps) => {
	const listenerResponseType = useSelector((store: Store) => store.general.listenerResponseType);
	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);

	const { t } = useTranslation('translation');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		loadConnections();
	}, [listenerResponseType]);

	const loadConnections = async () => {
		if (listenerResponseType && listenerResponseType == EventType.CHANGED_INTEGRATION_ACCOUNT) dispatch(setListenerResponseType(undefined));
		await getAvailableIntegrations(dispatch);
	};

	const getIntegrationCard = (integration: IntegrationType, key: number) => {
		switch (integration.provider) {
			case IntegrationProvider.MERGE:
				return <MargeIntegrationsCard {...integration} key={key} platformIsChosen={chosenPlatform == integration.platform} />;

			case IntegrationProvider.STRIPE:
				return (
					<IntegrationBySupplierCard
						{...integration}
						key={key}
						openModal={chosenPlatform == integration.platform}
						image={images[integration.iconSrc]}
						onConnect={(id, integrationAccountId, existing) => onConnectStripe(dispatch, integration.provider, integrationAccountId, existing)}
						onClickEntity={(id) => onConnectEntity(dispatch, integration.provider, id, integration.platform)}
						onCancel={(supplierId: string, integrationAccountId?: string) =>
							onCancelIntegration(dispatch, integration.provider, supplierId, integration.platform, integrationAccountId)
						}
						onMissingDetails={(integrationAccountId: string) => onMissingDetailsEntity(dispatch, integration.provider, integrationAccountId)}
					/>
				);
			case IntegrationProvider.CODAT:
				return (
					<IntegrationBySupplierCard
						{...integration}
						key={key}
						openModal={chosenPlatform == integration.platform}
						image={images[integration.iconSrc]}
						onConnect={(id) => onConnectEntity(dispatch, integration.provider, id, integration.platform)}
						onDisconnect={(id) => onDisconnectEntity(dispatch, integration.provider, id, integration.platform)}
						onClickEntity={(supplierId) =>
							navigate('../integrations', {
								state: {
									integrationType: integration?.platform,
									supplierId,
									category: integration.category,
									provider: integration.provider,
									connectionStatus: integration.connectionStatus,
								},
							})
						}
						onCancel={(supplierId: string, integrationAccountId?: string) =>
							onCancelIntegration(dispatch, integration.provider, supplierId, integration.platform, integrationAccountId)
						}
					/>
				);
		}
	};

	return (
		<div className={styles.pageContainer}>
			<div className={styles.pageHeader}>
				<h2>{t('integrations')}</h2>
			</div>
			<div className={styles.horizontalDivider} />
			<div className={styles.pageContent}>{availableIntegration?.map((integration, index) => getIntegrationCard(integration, index))}</div>
		</div>
	);
};
