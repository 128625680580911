import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { IntegrationPlatform } from '@received/pricing-model';
import { useMergeLink } from '@mergeapi/react-merge-link';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { images } from '../../../../constants/images';
import { IntegrationType } from '../../../../types/integrationTypes';
import { convertIntegrationTypeToDescription, convertIntegrationTypeToStringService } from '../../../../utils/IntegrationUtils';
import { ActionModalModal, Button, IntegrationBySupplierCard, IntegrationCard, NetsuiteCard } from '../../..';
import styles from './MargeIntegrationsCard.module.scss';

interface MargeIntegrationsCardProps extends IntegrationType {
	showOnlyIcon?: boolean;
	platformIsChosen?: boolean;
}

export const MargeIntegrationsCard = ({
	showOnlyIcon,
	platform,
	iconSrc,
	category,
	provider,
	connectionStatus,
	haveConfigurationOptions,
	supplier,
	platformIsChosen,
	...integration
}: MargeIntegrationsCardProps) => {
	const [linkToken, setLinkToken] = useState<string>();
	const [publicToken, setPublicToken] = useState<string>();
	const [supplierId, setSupplierId] = useState<string>();
	const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation('translation');

	const { open, isReady } = useMergeLink({
		linkToken: linkToken,
		onSuccess: (token) => setPublicToken(token),
		onExit: () => reset(),
	});

	useEffect(() => {
		if (isReady && linkToken) {
			open();
		} else {
			reset();
		}
	}, [linkToken, isReady]);

	useEffect(() => {
		publicToken && swapPublicToken(publicToken, supplierId);
	}, [publicToken, supplierId]);

	const reset = () => {
		setLinkToken(undefined);
		setPublicToken(undefined);
		setSupplierId(undefined);
	};

	const getMergeLinkToken = async (supplierId?: string) => {
		try {
			const res = (
				await httpService({
					dispatch,
					path: PathsConfig.getMergeLinkToken,
					params: { platform, supplierId, category },
				})
			).data;

			setLinkToken(res.linkToken);
			setTimeout(() => {
				setLinkToken(res.linkToken);
				setSupplierId(supplierId);
			}, 1000);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_LINK_TOKEN }));
		}
	};

	const swapPublicToken = async (publicToken: string, supplierId?: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.swapMergePublicToken,
				urlParam: { publicToken },
				data: { platform, supplierId, category, restoreSyncedData: true },
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_SWAP_PUBLIC_TOKEN }));
		}
	};

	const onDisconnect = async (supplierId?: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.disconnectMergeAccount,
				data: { platform, supplierId, category },
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onCancel = async (supplierId?: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.cancelMergeAccount,
				data: { supplierId, platform, category },
			});
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getComponentByPlatform = () => {
		switch (platform) {
			case IntegrationPlatform.HUBSPOT:
			case IntegrationPlatform.SALESFORCE:
				return showOnlyIcon ? (
					<Button type='outlined' color='neutral' className={styles.cardButton} onClick={() => getMergeLinkToken()}>
						<img alt='cardIcon' src={images[iconSrc]} className={styles.cardIcon}></img>
					</Button>
				) : (
					<IntegrationCard
						{...integration}
						category={category}
						supplierId={supplier?.id}
						provider={provider}
						navigateToMatchingPage={platformIsChosen}
						title={convertIntegrationTypeToStringService(platform)}
						imgType={images[iconSrc]}
						connectionStatus={connectionStatus}
						platform={platform}
						haveConfigurationOptions={haveConfigurationOptions}
						description={t(convertIntegrationTypeToDescription(platform))}
						onConnect={() => getMergeLinkToken()}
						onDisconnect={() => setOpenAreYouSureModal(true)}
					/>
				);
			case IntegrationPlatform.XERO:
			case IntegrationPlatform.QUICK_BOOKS_ONLINE:
			case IntegrationPlatform.QUICK_BOOKS_ONLINE_SANDBOX:
				return (
					<IntegrationBySupplierCard
						{...integration}
						iconSrc={iconSrc}
						haveConfigurationOptions={haveConfigurationOptions}
						connectionStatus={connectionStatus}
						provider={provider}
						category={category}
						supplier={supplier}
						image={images[iconSrc]}
						platform={platform}
						openModal={platformIsChosen}
						onConnect={(supplierId) => getMergeLinkToken(supplierId)}
						onDisconnect={(supplierId) => onDisconnect(supplierId)}
						onCancel={onCancel}
						onClickEntity={(supplierId, connectionStatus) =>
							navigate('../integrations', { state: { integrationType: platform, supplierId, category, provider, connectionStatus } })
						}
					/>
				);
			case IntegrationPlatform.NET_SUITE:
				return showOnlyIcon ? (
					<Button type='outlined' color='neutral' className={styles.cardButton} onClick={() => getMergeLinkToken()}>
						<img alt='cardIcon' src={images[iconSrc]} className={styles.cardIcon} />
					</Button>
				) : (
					<NetsuiteCard
						{...integration}
						category={category}
						provider={provider}
						navigateToMatchingPage={platformIsChosen}
						title={convertIntegrationTypeToStringService(platform)}
						imgType={images[iconSrc]}
						connectionStatus={connectionStatus}
						platform={platform}
						haveConfigurationOptions={haveConfigurationOptions}
						description={t(convertIntegrationTypeToDescription(platform))}
						onConnect={() => getMergeLinkToken()}
						onDisconnect={() => setOpenAreYouSureModal(true)}
					/>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			{getComponentByPlatform()}
			<ActionModalModal
				rightButton={{ title: 'disconnect', color: 'destructive' }}
				imagesOptions={images.alert}
				isOpen={openAreYouSureModal}
				onAccept={() => {
					onDisconnect?.();
					setOpenAreYouSureModal(false);
				}}
				onClose={() => setOpenAreYouSureModal(false)}
			/>
		</>
	);
};
