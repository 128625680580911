import { Dispatch } from '@reduxjs/toolkit';
import { Cell, IconCell } from '../../../components/_uiComponents/Table/Table.utils';
import { Sequence } from '../../../types/dunningTypes';
import { MenuOption } from '../../../components';
import { PathsConfig, httpService } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import styles from './Dunning.module.scss';

export const tableHeader = [
	{
		headerTitle: 'sequenceName',
		isSortable: false,
		accessor: 'name',
		CustomComponent: (sequenceView: Sequence) => (
			<IconCell
				accessor={sequenceView.name}
				imgType={sequenceView.isDefault ? 'play_in_circle' : 'email'}
				overrideStyles={{ accessorRow: styles.accessorRow }}
			/>
		),
	},
	{
		headerTitle: 'steps',
		isSortable: false,
		accessor: 'steps',
		CustomComponent: (sequenceView: Sequence) => <Cell accessor={sequenceView.steps.length} className={styles.sequenceStepsCol} />,
	},
	{
		headerTitle: 'customers',
		isSortable: false,
		accessor: 'customers',
		CustomComponent: (sequenceView: Sequence) => <Cell accessor={sequenceView.customers} className={styles.sequenceCustomersCol} />,
	},
];

export const sequenceRowOptions = (dispatch: Dispatch, t: any, sequence: Sequence, getSequences: () => void): MenuOption[] => {
	let menuOptions: MenuOption[] = [];

	if (!sequence.isDefault) {
		menuOptions = [
			{
				title: t('applyOnNewContracts'),
				onPress: async () => {
					await updateSequence(dispatch, sequence);
					getSequences();
				},
				iconType: 'unfilled_bookmark',
			},

			...menuOptions,
		];
	}

	menuOptions = [
		...menuOptions,
		{
			title: t('Duplicate'),
			onPress: async () => {
				await duplicateSequence(dispatch, sequence);
				getSequences();
			},
			iconType: 'duplicate',
		},
		{
			title: t('remove'),
			onPress: async () => {
				await deleteSequence(dispatch, sequence?.id || '');
				getSequences();
			},
			iconType: 'delete',
			color: 'destructive',
		},
	];
	return menuOptions;
};

export const updateSequence = async (dispatch: Dispatch, sequence: Sequence) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.updateSequence,
			data: {
				...sequence,
				isDefault: true,
			},
		});
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const duplicateSequence = async (dispatch: Dispatch, sequence: Sequence, onRefresh?: () => void) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.duplicateSequence,
			urlParam: { id: sequence.id || '' },
		});
		onRefresh?.();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const deleteSequence = async (dispatch: Dispatch, id: string) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteSequence,
			urlParam: { id },
		});
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: error?.response?.data?.message }));
	}
};
