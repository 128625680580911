import { createSlice } from '@reduxjs/toolkit';
import { IntegrationsState } from '../types/storeTypes';

const initialState: IntegrationsState = {
	syncedIntegrationData: [],
	availableIntegration: [],
	resyncAccountingIntegration: {},
	refreshAfterResync: false,
};

export const integrationsReducer = createSlice({
	name: 'integrations',
	initialState,
	reducers: {
		setSyncedIntegrationData: (state: IntegrationsState, payload) => {
			return { ...state, syncedIntegrationData: payload.payload };
		},
		setAvailableIntegration: (state: IntegrationsState, payload) => {
			return { ...state, availableIntegration: payload.payload };
		},
		setAddResyncAccountingIntegration: (state: IntegrationsState, payload) => {
			state.resyncAccountingIntegration = { ...state.resyncAccountingIntegration, ...payload.payload };
		},
		setRefreshAfterResync: (state: IntegrationsState, payload) => {
			return { ...state, refreshAfterResync: payload.payload };
		},
	},
});

export const { setSyncedIntegrationData, setAvailableIntegration, setAddResyncAccountingIntegration, setRefreshAfterResync } =
	integrationsReducer.actions;

export default integrationsReducer.reducer;
