import React, { useEffect, useRef, useState } from 'react';
import { IntegrationPlatform, IntegrationProvider, IntegrationStatus, PaymentPlatform, convertCurrencyToSign } from '@received/pricing-model';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { PathsConfig, httpService } from '../../services';
import { Button, CreateSupplier, Icon, Input, InputIcon, Select, StripeConnectModal, Switch, TextArea, Tooltip } from '..';
import { Subscription } from '../../types/subscriptionTypes';
import { defaultSubscription, subscriptionValidation } from './CreateSubscription.utils';
import { ProductDetails } from './ProductDetails/ProductDetails';
import { numberInRange } from '../../utils/NumberUtils';
import { Store } from '../../types/storeTypes';
import { Customer } from '../../types/customerTypes';
import { getSuppliers } from '../../utils/CustomerUtils';
import { UserRole } from '../../types/userTypes';
import { IntegrationType } from '../../types/integrationTypes';
import { images } from '../../constants/images';
import { onCancelIntegration, onConnectEntity, onMissingDetailsEntity } from '../../services/integrationService/integrationService';
import styles from './CreateSubscription.module.scss';

export interface CreateSubscriptionProps {
	isOpen: boolean;
	selectedSubscriptionId?: string;
	containerClassName?: string;
	onCreateSubscription(subscription: Subscription): void;
	closeSideBar(): void;
}

export const CreateSubscription = ({
	isOpen,
	selectedSubscriptionId,
	containerClassName,
	onCreateSubscription,
	closeSideBar,
}: CreateSubscriptionProps) => {
	const { t } = useTranslation('translation');
	const [subscription, setSubscription] = useState<Subscription>(defaultSubscription);
	const [discountInPercentage, setDiscountInPercentage] = useState(true);
	const [supplierEntities, setSupplierEntities] = useState<Customer[]>([]);
	const [isCreateEntityOpened, setIsCreateEntityOpened] = useState(false);
	const [onlinePaymentsGateways, setOnlinePaymentsGateways] = useState<IntegrationType[]>([]);
	const [isNewPaymentAccountOpened, setIsNewPaymentAccountOpened] = useState(false);

	const containerRef = useRef<HTMLDivElement>(null);

	const { appCurrency } = useSelector((store: Store) => store.general);
	const userRole = useSelector((store: Store) => store.user.role);

	const dispatch = useDispatch();

	useEffect(() => {
		selectedSubscriptionId && getSelectedSubscription(selectedSubscriptionId);
		isOpen && setSuppliers();
	}, [isOpen, selectedSubscriptionId]);

	useEffect(() => {
		isOpen && setPaymentGateways();
	}, [isOpen, supplierEntities, subscription?.supplierId]);

	useEffect(() => {
		isOpen &&
			containerRef?.current?.scroll({
				top: 0,
				behavior: 'smooth',
			});
	}, [isOpen]);

	const getSelectedSubscription = async (id: string) => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getSubscription,
				urlParam: { id },
			});

			setSubscription(res.data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const createSubscription = async () => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.createSubscription,
				data: subscription,
			});

			onCreateSubscription(res.data);
			onClose();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const updateSubscription = async () => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.updateSubscription,
				data: subscription,
			});
			onCreateSubscription(res.data);
			onClose();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const setSuppliers = async () => {
		const companies: Customer[] = await getSuppliers(dispatch);
		if (!subscription.supplierId) {
			setSubscription((prev) => ({ ...prev, supplierId: companies.find((item) => item.isDefault)?.id || '' }));
		}
		setSupplierEntities(companies);
	};

	const addEntity = async (supplier: Customer) => {
		await setSuppliers();
		setSubscription((prev) => ({ ...prev, supplierId: supplier?.id }));
	};

	////STRIPE ACCOUNTS + CARDS SETTINGS
	const setPaymentGateways = () => {
		const linkedPaymentGateways = supplierEntities
			.find((company) => company.id === subscription?.supplierId)
			?.billingDetails?.paymentGateways?.filter((paymentGateway) => paymentGateway.connectionStatus == IntegrationStatus.LINKED);

		setOnlinePaymentsGateways(linkedPaymentGateways || []);
	};

	const onClose = () => {
		setSubscription(defaultSubscription);
		closeSideBar();
	};

	return (
		<>
			{isOpen && <div onClick={onClose} className={styles.modalOverlay} />}

			<div className={clsx(!isOpen ? styles.sidebarHidden : styles.sidebar, containerClassName)}>
				<div className={styles.header}>
					<div>
						<div className={styles.title}>{t('createSubscription')}</div>
					</div>
					<Button type='link' color='neutral' onClick={closeSideBar}>
						<Icon imgType='x_icon' color='neutral700' height={1} />
					</Button>
				</div>

				<div className={styles.dataContainer} ref={containerRef}>
					<Input
						autoFocus
						value={subscription?.name}
						title={t('subscriptionName')}
						placeholder={t('chooseNameForSubscription')}
						onChange={(value) => setSubscription((prev) => ({ ...prev, name: value }))}
						rightChildren={<Icon imgType='edit' />}
						leftChildren={<Icon imgType='subscription' color='neutral700' height={1.8} className={styles.editNameIcon} />}
					/>

					<div className={styles.subTitle}>{t('productDetails')}</div>
					<ProductDetails subscription={subscription} setSubscription={setSubscription} />

					<div className={styles.subTitle}>{t('subscriptionSettings')}</div>
					<div className={styles.discountTaxContainer}>
						<div className={styles.discountContainer}>
							<div className={styles.discountTitle}>
								<div className={styles.fontSize14neutral}>{t('discount')}</div>
							</div>
							<InputIcon
								value={subscription?.discount}
								prefixSign={discountInPercentage ? '%' : convertCurrencyToSign(subscription?.currency || appCurrency)}
								type='number'
								onChange={(val) => setSubscription((prev) => ({ ...prev, discount: numberInRange(+val, 0, 100) }))}
								// TODO return when server ready
								// onSwitchPrefix={onUpdateDiscountPrefix}
								inputContainerClassName={styles.discountInput}
							/>
						</div>

						<div className={styles.discountContainer}>
							<div className={styles.discountTitle}>
								<div className={styles.fontSize14neutral}>{t('tax')}</div>
							</div>
							<InputIcon
								value={subscription?.tax}
								prefixSign='%'
								type='number'
								onChange={(val) => setSubscription((prev) => ({ ...prev, tax: Number(val) }))}
								// TODO return when server ready
								// onSwitchPrefix={onUpdateDiscountPrefix}
								inputContainerClassName={styles.discountInput}
							/>
						</div>
					</div>

					<Select
						placeholder={t('receivingEntityPlaceholder')}
						title={t('debitEntity')}
						onChange={(id) => setSubscription((prev) => ({ ...prev, supplierId: id }))}
						defaultValue={subscription?.supplierId}
						width={''}
						data={
							supplierEntities?.map((item: Customer) => ({
								label: `${item?.name} ${item?.ein ? `(EIN ${item?.ein})` : ''}`,
								value: item.id,
							})) || []
						}
						addButton={{ title: t('addNewEntity'), onPress: () => setIsCreateEntityOpened(true), isDisabled: userRole !== UserRole.Admin }}
						className={styles.row}
						headerLeftChildren={
							<div className={styles.switchContainer}>
								{t('autoCharge')}
								<Switch
									smallSize
									onChange={(val) => setSubscription((prev) => ({ ...prev, autoCharge: val }))}
									defaultChecked={subscription.autoCharge}
								/>
							</div>
						}
					/>

					<Select
						data={
							onlinePaymentsGateways?.map((item) => ({
								label: item?.metadata?.accountName || item?.metadata?.accountId || '',
								value: item?.id,
								customComponent: () => (
									<div className={styles.stripeAccountItem}>
										<Icon imgType='stripe' color='calming' width={2} />
										{item?.metadata?.accountName || item?.metadata?.accountId || ''}
									</div>
								),
							})) || []
						}
						onChange={(id) =>
							setSubscription((prev) => ({
								...prev,
								billingDetails: {
									...prev?.billingDetails,
									paymentGatewayId: id,
									paymentGatewayCustomerId: null,
									paymentGatewayCustomer: null,
								},
							}))
						}
						defaultValue={subscription?.billingDetails?.paymentGatewayId || undefined}
						width={''}
						className={styles.paymentMethodSelect}
						containerStyle={styles.selectContainer}
						title={t('stripeIntegration')}
						placeholder={t('addOnlinePaymentAccount')}
						addButton={{
							title: t('connectOnlinePaymentAccount'),
							onPress: () => setIsNewPaymentAccountOpened(true),
						}}
						inputIcon={{ imgType: 'stripe', color: 'calming' }}
					/>

					<TextArea
						type='textarea'
						value={subscription?.note}
						onChange={(val) => setSubscription((prev) => ({ ...prev, note: val }))}
						title={`${t('note')} (${t('optional')})`}
						className={styles.multiline}
						placeholder={t('noteComment')}
						customTitle={
							<div className={styles.switchContainer}>
								{t('showInInvoice')}
								<Switch
									smallSize
									onChange={(val) => setSubscription((prev) => ({ ...prev, showNoteInInvoice: val }))}
									defaultChecked={subscription.showNoteInInvoice}
								/>
							</div>
						}
					/>
				</div>

				<div className={styles.footer}>
					<Button onClick={() => setSubscription(defaultSubscription)} className={styles.footerButtons} type='link' color='neutral'>
						{t('resetAll')}
					</Button>
					<Tooltip
						disabled={!subscriptionValidation(subscription).length}
						tooltipComponent={
							<>
								{subscriptionValidation(subscription).map((err) => (
									<div key={err}>{t(err)}</div>
								))}
							</>
						}
					>
						<Button
							onClick={selectedSubscriptionId ? updateSubscription : createSubscription}
							className={styles.footerButtons}
							disabled={!!subscriptionValidation(subscription).length}
						>
							{t(selectedSubscriptionId ? 'updateSubscription' : 'createSubscription')}
						</Button>
					</Tooltip>
				</div>
			</div>

			<CreateSupplier
				companiesListLength={supplierEntities?.length || 0}
				isOpen={isCreateEntityOpened}
				closeModal={() => setIsCreateEntityOpened(false)}
				onCreateUpdateSupplier={addEntity}
			/>

			<StripeConnectModal
				isOpen={isNewPaymentAccountOpened}
				platform={PaymentPlatform.STRIPE}
				image={images.stripePayment}
				onConnect={(id, integrationAccountId, existing) =>
					onConnectEntity(dispatch, IntegrationProvider.STRIPE, id, IntegrationPlatform.STRIPE, integrationAccountId, existing)
				}
				onCancel={(supplierId: string, integrationAccountId?: string) =>
					onCancelIntegration(dispatch, IntegrationProvider.STRIPE, supplierId, IntegrationPlatform.STRIPE, integrationAccountId)
				}
				onMissingDetails={(integrationAccountId: string) => onMissingDetailsEntity(dispatch, IntegrationProvider.STRIPE, integrationAccountId)}
				closeModal={() => setIsNewPaymentAccountOpened(false)}
				onRefresh={setSuppliers}
			/>
		</>
	);
};
