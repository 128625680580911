import { CryptoTokens } from '@received/pricing-model';
import { images } from '../../constants/images';
import { PaymentMethodOptions } from '../../types/generalTypes';
import { PricingTermsSettings } from './settings';
import styles from './PricingTerms.module.scss';

export const getImageSrcByName = (name: string) => {
	switch (name) {
		case CryptoTokens.USDC:
			return images.usdc;
		case CryptoTokens.USDT:
			return images.usdt;
		default:
			return '';
	}
};

export const paymentOptions = [
	{ id: PaymentMethodOptions.BANK_TRANSFER, title: 'Bank Transfer' },
	{ id: PaymentMethodOptions.PAYMENT_LINK, title: 'Stripe' },
];

export const getTooltipBySettings = (t: any, item: any, settings?: PricingTermsSettings) => {
	if (settings?.isAutoChargeToggleDisabled) {
		return (
			<div className={styles.tooltipLabel}>
				<div>{t('whenUsingPayUponUsage')}</div>
				<div>{t('theAutoChargeRequired')}</div>
			</div>
		);
	} else {
		if (item !== PaymentMethodOptions.PAYMENT_LINK) {
			return (
				<div className={styles.tooltipLabel}>
					<div>{t('toSelectDifferentPayment')}</div>
					<div>{t('methodsPleaseDisable')}</div>
					<div>{t('autoDashCharge')}</div>
				</div>
			);
		}
	}
};

export const isPaymentMethodDisabled = (itemId: string, settings?: PricingTermsSettings) => {
	return (
		(itemId == PaymentMethodOptions.BANK_TRANSFER && settings?.isBankMethodDisabled) ||
		(itemId == PaymentMethodOptions.PAYMENT_LINK && settings?.isStripeMethodDisabled)
	);
};

export const isPaymentMethodSelected = (itemId: string, settings?: PricingTermsSettings) => {
	return (
		(itemId == PaymentMethodOptions.BANK_TRANSFER && settings?.isBankMethodSelected) ||
		(itemId == PaymentMethodOptions.PAYMENT_LINK && settings?.isStripeMethodSelected)
	);
};
