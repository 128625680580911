import React, { CSSProperties, useEffect, useState } from 'react';
import clsx from 'clsx';
import { IconImgType } from '../Icon/IconOptions';
import { TabItem } from './TabItem/TabItem';
import { AccountPermissions } from '../../../types/generalTypes';
import styles from './Tabs.module.scss';

export interface ChosenTabItem {
	label: string;
	labelTitle?: string;
	value: any;
	iconImgType?: IconImgType;
	actionType?: AccountPermissions;
}

export interface TabsClassNames {
	tabsContainer?: string;
	tabComponent?: string;
	activeTab?: string;
	tabTitleLabel?: string;
	label?: string;
	activeLabel?: string;
	activeTabLabel?: string;
	tabLabels?: string;
	tabActiveLabels?: string;
}

export interface TabsProps {
	tabsArray: Array<ChosenTabItem> | string[];
	chosenTab?: number;
	tabStyle?: CSSProperties;
	style?: CSSProperties;
	classNames?: TabsClassNames;
	id?: string;
	disabledActions?: boolean;
	onNameUpdate?: (name: string, index: number) => void;
	onTabClose?: (index: number) => void;
	updateChosenTab?(tabNumber: number, chosenItem: string | ChosenTabItem): void;
}

export const Tabs = ({
	tabsArray,
	disabledActions,
	onNameUpdate,
	onTabClose,
	updateChosenTab,
	chosenTab = 0,
	style,
	tabStyle,
	classNames,
	id,
}: TabsProps) => {
	const [activeTab, setActiveTab] = useState(0);

	const onTabPress = (index: number, item: string | ChosenTabItem) => {
		updateChosenTab && updateChosenTab(index, item);
		setActiveTab(index);
	};

	useEffect(() => {
		setActiveTab(chosenTab);
	}, [chosenTab]);

	return (
		<div id={id} className={clsx(styles.tabsContainer, classNames?.tabsContainer)} data-testid='tabs-container' style={style}>
			{tabsArray.map((item, index) => (
				<TabItem
					id={id}
					tab={item}
					index={index}
					key={index}
					activeTab={activeTab}
					tabStyle={tabStyle}
					classNames={classNames}
					onNameUpdate={onNameUpdate}
					onTabClose={onTabClose}
					onTabPress={() => onTabPress(index, item)}
					disabledActions={disabledActions}
				/>
			))}
		</div>
	);
};
