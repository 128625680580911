import React, { SetStateAction, useEffect, useState } from 'react';
import { DOLLAR } from '@received/pricing-model';
import { PriceBookEntries } from '../../../types/integrationTypes';
import { numberWithCommas } from '../../../utils/NumberUtils';
import { Icon } from '../Icon/Icon';
import { DnDDropDownItem } from './DnDDropDownItem/DnDDropDownItem';
import styles from './DnDDropDown.module.scss';

export enum DragAndDropSelectOptions {
	DRAG_AND_DROP = 'DRAG_AND_DROP',
	ADD_BUTTON = 'ADD_BUTTON',
}

export interface DragAndDropSelectItem {
	item: PriceBookEntries;
	addOption: DragAndDropSelectOptions;
}

export interface DnDDropDownProps {
	title: string;
	subTitle: string;
	dataList: PriceBookEntries[];
	currentOpen?: string;
	id: string;
	setOpenedSelect: SetStateAction<any>;
	onAddItem: (item: DragAndDropSelectItem) => void;
}

export const DnDDropDown = ({ title, id, subTitle, dataList, currentOpen, setOpenedSelect, onAddItem }: DnDDropDownProps) => {
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (currentOpen) {
			id != currentOpen && setIsOpen(false);
		}
	}, [currentOpen, id]);

	const openSelect = () => {
		setIsOpen(!isOpen);
		setOpenedSelect(isOpen ? undefined : id);
	};

	return (
		<div className={isOpen ? styles.containerOpen : styles.containerClose} style={{ height: isOpen ? `${11 + 5.8 * dataList.length}rem` : '7.8rem' }}>
			<div className={styles.header} onClick={openSelect}>
				<div className={styles.flexCenter}>
					<div className={styles.iconContainer}>
						<Icon imgType='book' color='neutral' height={2} />
					</div>

					<div>
						<div className={styles.title}>{title}</div>
						<div className={styles.fontSize14neutral}>{subTitle}</div>
					</div>
				</div>

				<div className={styles.arrowContainer}>
					<Icon imgType='arrow_up' color='neutral' height={1.8} className={isOpen ? styles.arrowUp : styles.arrowDown} />
				</div>
			</div>

			<div className={styles.list}>
				{dataList.map((item) => (
					<DnDDropDownItem
						key={item.itemPriceId}
						name={item.name}
						isSelected={item.isUsedInPricingModel}
						price={`${DOLLAR}${numberWithCommas(+item.unitPrice)}`}
						onDrag={() => onAddItem({ item, addOption: DragAndDropSelectOptions.DRAG_AND_DROP })}
						onAddToRow={() => onAddItem({ item, addOption: DragAndDropSelectOptions.ADD_BUTTON })}
					/>
				))}
			</div>
		</div>
	);
};
