import { convertCurrencyToSign, DEFAULT_CURRENCY } from '@received/pricing-model';
import { Contract } from '../../../../types/contractTypes';
import { getValueWithCurrencySign } from '../../../../utils/NumberUtils';
import { FooterItem } from '../../components/SideBarFooter/SideBarFooter.utils';

const calculateTotals = (contract?: Contract) => {
	let totalTAX = 0;
	let totalDiscount = 0;
	let totalModels = 0;
	let subTotal = 0;
	let isEstimatedTotal = false;

	contract?.pricingModel?.tabs.forEach((item) => {
		if (item.isEstimatedTotal) isEstimatedTotal = true;
		totalDiscount = totalDiscount + (item.totalDiscount || 0);
		totalTAX = totalTAX + (item?.totalTax || 0);
		totalModels = totalModels + (item?.total || 0);
		subTotal = subTotal + (item?.subTotal || 0);
	});

	return { totalTAX, totalDiscount, totalModels, isEstimatedTotal, subTotal };
};

export const setFooterDataForContractSidebar = (contract?: Contract): FooterItem[] => {
	const currency = convertCurrencyToSign(contract?.pricingModel?.tabs[0]?.currency || DEFAULT_CURRENCY);
	const footerValues = calculateTotals(contract);
	return [
		{
			title: 'subtotal',
			value: getValueWithCurrencySign(footerValues.subTotal, currency),
		},
		{
			title: 'discount',
			value: `(${getValueWithCurrencySign(footerValues?.totalDiscount, currency)})`,
		},
		{
			title: 'Tax',
			value: getValueWithCurrencySign(footerValues?.totalTAX, currency),
		},
		{
			title: 'Total',
			value: getValueWithCurrencySign(footerValues?.totalModels, currency),
		},
	];
};
