import React, { useEffect, useMemo } from 'react';

// debounce hook, you can use it for any purpose and with any required delay
// eslint-disable-next-line @typescript-eslint/ban-types
export function useDebounce(func: Function, time: number, timerRef?: React.MutableRefObject<NodeJS.Timeout | null>) {
	let timeoutId: ReturnType<typeof setTimeout> | undefined;
	return useMemo(
		() =>
			(...args: any) => {
				if (timeoutId) clearTimeout(timeoutId);
				timeoutId = setTimeout(() => func(...args), time);
				timerRef && (timerRef.current = timeoutId);
			},
		[],
	);
}

/**
 * Hook that handles clicks outside of the passed ref
 */
export function useOutsideCallback(ref: React.MutableRefObject<HTMLElement | null>, onClick?: () => void, dependencies: any[] = []) {
	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutside(event: any) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClick && onClick();
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref, ...dependencies]);
}
