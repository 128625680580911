import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SyncStatus, convertCurrencyToSign, ContractState, DocumentState } from '@received/pricing-model';
import { Store } from '../../../types/storeTypes';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import { Button, ButtonStatusColor, ButtonTypes, GlowingDot, Icon, IconImgType, TextWithTooltip, Tooltip } from '..';
import { colors } from '../../../constants/colors';
import { SyncStatusMetadata } from '../../../utils/IntegrationUtils';
import { getCreditCardBrandLogo } from '../../../utils/GeneralUtils';
import { StripeCard } from '../../../types/integrationTypes';
import styles from './TableRow/TableRow.module.scss';

interface PriceCellProps {
	mainNumber: number;
	mainNumberAccessor?: string;
	subNumber?: number | string;
	subAccessor?: string;
	currency?: string;
	subAccessorClassName?: string;
}

interface CellProps {
	accessor: string | number;
	subAccessor?: string | number;
	className?: string;
	subAccessorClassName?: string;
}

interface PaymentCardCellProps {
	stripeCard?: StripeCard;
	className?: string;
	subAccessorClassName?: string;
}

interface LogoCellProps {
	title: string;
	subTitle?: string;
	logoData?: string; //base64 string
	overrideStyles?: Record<string, string>;
	cellSyncStatus?: SyncStatusMetadata;
}

interface StatusColProps {
	children?: React.ReactNode;
	color?: ButtonStatusColor;
	text?: string;
	missingInfo?: boolean;
	className?: string;
	type?: ButtonTypes;
}

interface TotalActiveCellProps {
	total?: number;
	active: number;
	numberOfImportedContracts?: number;
}

interface ContractCellProps {
	contractNumber: string;
	state?: ContractState | DocumentState;
	subAccessor?: string;
	cellSyncStatus?: SyncStatusMetadata;
}

interface CreditNoteCellProps {
	documentNumber: string;
	className?: string;
	subAccessor?: string;
}

interface IconCellProps {
	imgType: IconImgType;
	accessor: string | number;
	iconContainerClassName?: string;
	overrideStyles?: Record<string, string>;
}

export const Cell = ({ accessor, subAccessor, className, subAccessorClassName }: CellProps) => {
	return (
		<>
			<span className={clsx(styles.accessorRow, className)}>{accessor}</span>
			{subAccessor && <span className={clsx(styles.subAccessorRow, subAccessorClassName)}>{subAccessor}</span>}
		</>
	);
};

export const PaymentCardCell = ({ stripeCard, className }: PaymentCardCellProps) => {
	return stripeCard ? (
		<div className={clsx(styles.paymentCardCell, className)}>
			<Icon width={3} imgType={getCreditCardBrandLogo(stripeCard?.cardBrand)} />
			•••• {stripeCard?.lastDigits}
		</div>
	) : (
		<div>-</div>
	);
};

export const PriceCell = ({ mainNumber, currency, subNumber, subAccessor, subAccessorClassName, mainNumberAccessor }: PriceCellProps) => {
	const { appCurrency } = useSelector((store: Store) => store.general);
	const currencyToShow = currency || convertCurrencyToSign(appCurrency);

	return (
		<>
			<span className={styles.accessorRow}>
				{getValueWithCurrencySign(mainNumber, currencyToShow)}
				<span className={styles.mainAccessorRow}>{mainNumberAccessor}</span>
			</span>
			{!!subNumber && (
				<span className={styles.subAccessorRow}>{!isNaN(+subNumber) ? getValueWithCurrencySign(+subNumber, currencyToShow) : subNumber}</span>
			)}
			{subAccessor && <span className={clsx(styles.subAccessorRow, subAccessorClassName)}>{subAccessor}</span>}
		</>
	);
};

export const LogoCell = ({ title, logoData, overrideStyles, cellSyncStatus, subTitle }: LogoCellProps) => {
	const tooltipLabel = getSyncStatusTooltipLabel(cellSyncStatus);

	return (
		<div className={clsx(styles.avatarCol, overrideStyles?.avatarCol)}>
			<div className={clsx(styles.contractIcon, overrideStyles?.icon)}>
				{logoData ? (
					<img alt='logo' src={logoData} className={clsx(styles.avatarContainer, overrideStyles?.avatarContainer)} />
				) : (
					<div className={clsx(styles.avatarContainer, overrideStyles?.avatarContainer, styles.noAvatarContainer, overrideStyles?.noAvatarContainer)}>
						{title && title[0].toUpperCase()}
					</div>
				)}

				<Tooltip
					tooltipComponent={tooltipLabel}
					blackTooltip
					disabled={!tooltipLabel}
					className={clsx(styles.logoCellTooltip, styles.tooltipContainer)}
					placement='right'
				>
					{cellSyncStatus && <img alt='connection-status' src={cellSyncStatus?.icon} className={styles.logoConnectionStatus} />}
				</Tooltip>
			</div>
			<div className={clsx(styles.avatarColText, overrideStyles?.avatarColText)}>
				<TextWithTooltip tooltip={{ placement: 'right' }} className={overrideStyles?.accessorRow}>
					{title || '-'}
				</TextWithTooltip>
				<div className={clsx(styles.subAccessorRow, overrideStyles?.subAccessorRow)}>{subTitle}</div>
			</div>
		</div>
	);
};

export const StatusCol = ({ children, color, className, type = 'secondary' }: StatusColProps) => {
	return (
		<Button dataTestId='general-status-button' type={type} color={color} className={clsx(styles.statusCol, className)}>
			{children}
		</Button>
	);
};

export const TotalActiveCell = ({ total, active, numberOfImportedContracts = 0 }: TotalActiveCellProps) => {
	const { t } = useTranslation('translation');

	return (
		<>
			<span className={styles.accessorRow}>{`${active || '0'} ${t('active')}`}</span>
			<div className={styles.contractSubAccessorRow}>
				{numberOfImportedContracts > 0 && <GlowingDot />}
				<span className={styles.subAccessorRow}>{`${total || '0'} ${t('total')}`}</span>
				{numberOfImportedContracts > 0 && (
					<span className={styles.subAccessorRow} style={{ color: colors.primary }}>
						+ {numberOfImportedContracts} {t('imported')}
					</span>
				)}
			</div>
		</>
	);
};

export const ContractCell = ({ contractNumber, cellSyncStatus, subAccessor }: ContractCellProps) => {
	const tooltipLabel = getSyncStatusTooltipLabel(cellSyncStatus);

	return (
		<div className={styles.avatarCol}>
			<div className={styles.contractIcon}>
				<Icon imgType='file' height={2} color='neutral700' />
				<Tooltip
					tooltipComponent={<div className={styles.tooltipLabel}>{tooltipLabel}</div>}
					blackTooltip
					disabled={!tooltipLabel}
					className={styles.tooltipContainer}
					placement='right'
				>
					{cellSyncStatus && <img alt='connection-status' src={cellSyncStatus?.icon} className={styles.contractConnectionStatus} />}
				</Tooltip>
			</div>

			<div className={styles.avatarColText}>
				<span className={styles.accessorRow}>{contractNumber}</span>
				<span className={styles.subAccessorRow}>{subAccessor}</span>
			</div>
		</div>
	);
};

const getSyncStatusTooltipLabel = (cellSyncStatus?: SyncStatusMetadata) => {
	switch (cellSyncStatus?.status) {
		case SyncStatus.SYNCED:
			return `Synced to ${cellSyncStatus?.platformsNames?.[0]} ${
				cellSyncStatus?.platformsNames?.length >= 2 ? ` +${cellSyncStatus?.platformsNames?.length - 1}` : ''
			}`;
		case SyncStatus.SYNC_FAILED:
			return `${cellSyncStatus?.errors?.map((error) => `${error?.errors ? `${error?.platformName}: ${error?.errors?.join(',\n')}` : ''}`)}`;
	}
};

export const CreditNoteCell = ({ documentNumber, className, subAccessor }: CreditNoteCellProps) => {
	return (
		<div className={clsx(styles.avatarCol, className)}>
			<div className={styles.contractIcon}>
				<Icon imgType='creditNote' height={2} />
			</div>
			<div className={clsx(styles.avatarColText, styles.flexColumn)}>
				<span className={styles.documentNumber}>{documentNumber}</span>
				{subAccessor && <span className={styles.subAccessorRow}>{subAccessor}</span>}
			</div>
		</div>
	);
};

export const IconCell = ({ imgType, accessor, iconContainerClassName, overrideStyles }: IconCellProps) => {
	return (
		<div className={styles.sequenceCol}>
			<div className={clsx(styles.sequenceIcon, iconContainerClassName)}>
				<Icon imgType={imgType} height={2} color='neutral700' />
			</div>
			<div className={styles.avatarColText}>
				<div className={clsx(styles.documentNumber, overrideStyles?.accessorRow)}>{accessor}</div>
			</div>
		</div>
	);
};
