import React, { useEffect } from 'react';
import clsx from 'clsx';
import { AccountingEventType } from '@received/pricing-model';
import { DragAndDropSelectItem, PricingModelTableComponent } from '../../..';
import { AccountPermissionsActionsList } from '../../../../types/generalTypes';
import { PricingModelTableViewTypeOptions } from '../../../PricingModelTable/PricingModelTable.utils';
import { PermissionsMapping } from '../../../../services/PermissionsMapping/PermissionsMapping';
import { PricingModelTab } from '../../../../types/contractTypes';
import {
	onUpdateCurrency,
	updateBillingCycle,
	updatePaymentPeriod,
	updatePricingModelTable,
	updateRevRecPaymentPeriod,
} from '../../PricingModelTabContentActions';
import { PricingModelSettings } from '../../../../pages/PricingModels/NewPricingModel/PricingModelSettings/PricingModelSettings';
import { PricingModelRevRecSettings } from '../../../../pages/PricingModels/NewPricingModel/PricingModelRevRecSettings/PricingModelRevRecSettings';
import styles from './NewPricingModelContent.module.scss';

interface NewPricingModelContentProps {
	pricingModelTab: PricingModelTab;
	className?: string;
	selectedRowItem?: DragAndDropSelectItem;
	updatePricingModelData(data: PricingModelTab, instantUpdate?: boolean): void;
}

export const NewPricingModelContent = ({ pricingModelTab, className, selectedRowItem, updatePricingModelData }: NewPricingModelContentProps) => {
	useEffect(() => {
		updatePaymentPeriod(pricingModelTab, updatePricingModelData);
	}, [pricingModelTab?.issueDay, pricingModelTab?.billingStartDate, pricingModelTab?.billingEndDate, pricingModelTab?.billingCycle]);

	useEffect(() => {
		updateRevRecPaymentPeriod(pricingModelTab, updatePricingModelData);
	}, [
		pricingModelTab?.revenueSettings?.postingDay,
		pricingModelTab?.revenueSettings?.revenueCycle,
		pricingModelTab?.revenueSettings?.revenueRecognitionPeriod,
	]);

	return (
		<div className={clsx(styles.container, className)}>
			<PricingModelTableComponent
				isEditable
				selectedRowItem={selectedRowItem}
				pricingModelTableViewType={PricingModelTableViewTypeOptions.PRICING_MODEL}
				pricingModelTable={pricingModelTab?.pricingModelTable}
				pricingModelTab={pricingModelTab}
				updateTableData={(table) => updatePricingModelTable(table, pricingModelTab, updatePricingModelData)}
				classNames={{ tableContainer: styles.tableContainer }}
			/>

			<div className={styles.pricingSettings}>
				<PricingModelSettings
					pricingModelTab={pricingModelTab}
					onUpdateBillingCycle={(cycle) => updateBillingCycle(cycle, pricingModelTab, updatePricingModelData)}
					updatePricingModelData={updatePricingModelData}
					updateCurrency={(currency) => onUpdateCurrency(currency, pricingModelTab, updatePricingModelData)}
				/>

				{pricingModelTab.accountingEventType != AccountingEventType.PAYOUT && (
					<PermissionsMapping
						actionType={AccountPermissionsActionsList.VIEW_REV_REC}
						disabledFeatureChildren={<PricingModelRevRecSettings disabledFeature pricingModelTab={pricingModelTab} />}
					>
						<PricingModelRevRecSettings pricingModelTab={pricingModelTab} updatePricingModelData={updatePricingModelData} />
					</PermissionsMapping>
				)}
			</div>
		</div>
	);
};
