import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from '..';
import styles from './Pagination.module.scss';
import { SEPARATOR, usePagination } from './usePagination';

export interface PaginationProps {
	numberOfPages?: number;
	currentPageNumber?: number;
	onUpdatePageNumber?: (num: number) => void;
}

export const Pagination = ({ numberOfPages = 1, currentPageNumber, onUpdatePageNumber }: PaginationProps) => {
	const { t } = useTranslation('translation');
	const [currentPage, setCurrentPage] = useState(currentPageNumber || 1);

	const onUpdatePage = (num: number) => {
		onUpdatePageNumber && onUpdatePageNumber(num - 1);
		setCurrentPage(num);
	};

	const paginationRange = usePagination({ currentPage, totalPageCount: numberOfPages, siblingCount: 2 });
	return (
		<div className={styles.paginationContainer}>
			<Button
				type='link'
				color='neutral'
				onClick={() => onUpdatePage(currentPage - 1)}
				className={styles.prevNextButton}
				disabled={currentPage === 1}
				dataTestId='pagination-previous'
			>
				<Icon imgType='arrow_left' className={styles.arrow} />
				{t('previous')}
			</Button>

			{paginationRange.map((pageNumber) => {
				if (pageNumber === SEPARATOR) {
					return <Icon key={pageNumber} imgType='hamburger' className={styles.dotsIcon} />;
				}
				const isActive = pageNumber === currentPage && pageNumber;
				return (
					<Button
						type={isActive ? 'secondary' : 'link'}
						color={isActive ? 'success' : 'neutral'}
						key={pageNumber}
						onClick={() => onUpdatePage(pageNumber as number)}
						style={{ height: 36, width: 36 }}
						className={clsx(currentPage === pageNumber && styles.disableNext)}
						dataTestId={`pagination-page-${pageNumber}`}
					>
						{pageNumber}
					</Button>
				);
			})}

			<Button
				type='link'
				color='neutral'
				onClick={() => onUpdatePage(currentPage + 1)}
				className={styles.prevNextButton}
				disabled={currentPage === numberOfPages || numberOfPages === 0}
				dataTestId='pagination-next'
			>
				{t('next')}
				<Icon imgType='arrow_right' className={styles.arrow} />
			</Button>
		</div>
	);
};
