import { Dispatch } from '@reduxjs/toolkit';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';

export const onDeleteUsageProduct = async (usageProductId: string, dispatch: Dispatch) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteUsageProduct,
			urlParam: { id: usageProductId },
		});
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
