import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AccountingEventType, ContractType } from '@received/pricing-model';
import { Button, Modal, NestedList, SearchBar, Select } from '../_uiComponents';
import { PricingModel } from '../../types/contractTypes';
import { EmptyCatalogPreview, PricingList, PricingTableCardView } from '..';
import { ProductAndCategoriesItem, ProductsHeaderId } from '../../types/ProductTypes';
import { successErrorMassageOptions } from '../SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { initialProduct, onClickSelectOrEdit } from './CatalogModal.utils';
import { filterCatalogPricingModels, onDuplicateCurrentModel, pricingFilter, pricingSelectList } from '../../pages/PricingModels/PricingModels.utils';
import { PricingTable } from '../../pages/PricingModels/PricingModelTabs/MyProductsTab/PricingTable/PricingTable';
import { getTemplateColorAndIconById } from '../../pages/PricingModels/PricingModelTabs/AllTemplatesTab/AllTemplatesTab.utils';
import { PricingModelTableViewTypeOptions } from '../PricingModelTable/PricingModelTable.utils';
import styles from './CatalogModal.module.scss';

interface CatalogModalProps {
	openModal: boolean;
	startFromProducts?: boolean;
	selectedModel?: PricingModel;
	editMode?: boolean;
	contractType?: ContractType;
	setOpenModal: (value: boolean) => void;
	onUseThisPricing?: (model: PricingModel) => void;
	onProductsChange?: (productId?: string) => void;
	addNewPricingEmptyProduct?(selectedProduct: ProductAndCategoriesItem): void;
}

export const CatalogModal = ({
	openModal,
	startFromProducts,
	selectedModel,
	editMode,
	contractType,
	setOpenModal,
	onUseThisPricing,
	onProductsChange,
	addNewPricingEmptyProduct,
}: CatalogModalProps) => {
	const { t } = useTranslation('translation');
	const [myProductsList, setMyProductsList] = useState<ProductAndCategoriesItem[]>([]);
	const [pricingTemplateList, setPricingTemplateList] = useState<ProductAndCategoriesItem[]>([]);
	const [selectedProduct, setSelectedProduct] = useState<ProductAndCategoriesItem>();
	const [selectedPricingModel, setSelectedPricingModel] = useState<PricingModel>();
	const [searchText, setSearchText] = useState('');
	const [selectedFilter, setSelectedFilter] = useState<pricingFilter | ''>(pricingFilter.isFavorite);
	const [listOfSelectedRows, setListOfSelectedRows] = useState<boolean[][]>([]);
	const [disableUseButton, setDisableUseButton] = useState(editMode);
	const [isMainTableSwitchOn, setIsMainTableSwitchOn] = useState<boolean>(false);

	const disableUseThisPricing = !onUseThisPricing || !selectedPricingModel || disableUseButton;

	const dispatch = useDispatch();

	useEffect(() => {
		if (openModal) {
			contractType !== ContractType.REFERRAL && setCategories();
			setMyProducts();
		}
	}, [openModal]);

	useEffect(() => {
		let isAllOn = true;
		const selectedModelRows = selectedPricingModel?.tabs?.map((model) =>
			model.pricingModelTable.rows.map((row) => {
				if (!row.isSelectedForContract) {
					isAllOn = false;
				}
				return editMode ? row.isSelectedForContract : true;
			}),
		);
		setIsMainTableSwitchOn(isAllOn);
		selectedPricingModel?.id && setListOfSelectedRows(selectedModelRows || []);
	}, [selectedPricingModel?.tabs]);

	useEffect(() => {
		if (listOfSelectedRows?.length && editMode) {
			const checker = listOfSelectedRows[0].every((v) => !v);
			setDisableUseButton(checker);
		}
	}, [listOfSelectedRows]);

	const setMyProducts = async () => {
		try {
			let productList: ProductAndCategoriesItem[] = (
				await httpService({
					dispatch,
					path: PathsConfig.myProductsList,
				})
			).data;

			if (contractType === ContractType.REFERRAL) {
				productList = productList.map((product) => ({
					...product,
					pricingModels: product.pricingModels.filter((model) => model.tabs[0].accountingEventType === AccountingEventType.PAYOUT),
				}));
			} else {
				productList = productList.map((product) => ({
					...product,
					pricingModels: product.pricingModels.filter((model) => model.tabs[0].accountingEventType != AccountingEventType.PAYOUT),
				}));
			}

			setMyProductsList(productList);

			if (selectedModel) {
				setSelectedPricingModel(selectedModel);
				const selectedProduct = productList.find((product) => {
					return !!product.pricingModels.filter((model) => model.id === selectedModel.id).length;
				});
				setSelectedProduct(selectedProduct);
			} else if (startFromProducts) {
				onSelectMyProducts(productList);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_PRODUCTS }));
		}
	};

	const setCategories = async () => {
		try {
			const templates: ProductAndCategoriesItem[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getCategoriesList,
					params: { includePricingModels: 'true' },
				})
			).data;

			const models = templates.map((product) => {
				const models = product.pricingModels.map((model) => ({
					...model,
					iconType: getTemplateColorAndIconById(product.id).iconType,
					categoryName: product.name,
				}));

				return {
					...product,
					iconType: getTemplateColorAndIconById(product.id).iconType,
					count: product.pricingModels.length,
					pricingModels: [...models],
				};
			});

			setPricingTemplateList(models);
			if (!startFromProducts && !selectedModel) {
				onSelectTemplates(models);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CATEGORIES }));
		}
	};

	const onSelectProduct = (productId: string) => {
		const selectedProductById = myProductsList.find((item) => item.id === productId);
		if (selectedProductById) {
			setSelectedProduct(selectedProductById);
		}
		setSelectedPricingModel(selectedProductById?.pricingModels.length ? selectedProductById.pricingModels[0] : undefined);
	};

	const onSelectMyProducts = (products: ProductAndCategoriesItem[]) => {
		const models = products?.map((product) => product?.pricingModels || [])?.flat();
		const myProductsAsProduct = {
			id: ProductsHeaderId.PRODUCTS,
			name: t('allProducts'),
			pricingModels: models,
		};
		setSelectedPricingModel(models.length ? models[0] : undefined);
		setSelectedProduct(myProductsAsProduct);
	};

	const onSelectCategory = (categoryId: string) => {
		const selectedCategory = pricingTemplateList.find((item) => item.id === categoryId);
		if (selectedCategory) {
			setSelectedProduct(selectedCategory);
		}
		setSelectedPricingModel(selectedCategory?.pricingModels.length ? selectedCategory?.pricingModels[0] : undefined);
	};

	const onSelectTemplates = (templates: ProductAndCategoriesItem[]) => {
		const models = templates?.map((product) => product?.pricingModels || [])?.flat();

		const templatesAsProduct = {
			id: ProductsHeaderId.CATEGORIES,
			name: t('allTemplates'),
			pricingModels: models,
		};
		setSelectedProduct(templatesAsProduct);
		setSelectedPricingModel(models.length ? models[0] : undefined);
	};

	const onClickAddNewProduct = async () => {
		const newProduct = await onAddProduct(initialProduct.name);
		myProductsList.splice(0, 0, newProduct);
		setSelectedProduct(newProduct);
		onProductsChange && onProductsChange();
	};

	const onAddProduct = async (name: string) => {
		try {
			return (
				await httpService({
					dispatch,
					path: PathsConfig.createNewProduct,
					data: { name },
					showLoader: false,
				})
			).data;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const isProduct = (productSelected?: ProductAndCategoriesItem) => {
		return productSelected && !(pricingTemplateList?.includes(productSelected) || productSelected.id === ProductsHeaderId.CATEGORIES);
	};

	const onRenameProduct = async (name: string) => {
		if (selectedProduct) {
			name = !name.length ? t('untitledProduct') : name;
			await onUpdateProduct(selectedProduct.id, name);
			const productList: ProductAndCategoriesItem[] = (
				await httpService({
					dispatch,
					path: PathsConfig.myProductsList,
				})
			).data;
			setMyProductsList(productList);
			const newProduct = productList.find((listItem) => selectedProduct.id == listItem.id);

			setSelectedProduct(newProduct);
			newProduct && onProductsChange && (await onProductsChange(newProduct.id));
		}
	};

	const onDuplicateProduct = async (id: string) => {
		const newProduct: ProductAndCategoriesItem = await onDuplicateCurrentModel(id, dispatch);
		setSelectedProduct(newProduct);
		await setMyProducts();
		newProduct && onProductsChange && (await onProductsChange(newProduct.id));
	};

	const onDeleteProduct = async (id: string) => {
		try {
			await httpService({
				dispatch,
				path: PathsConfig.onDeleteProduct,
				urlParam: { id },
			});
			await setMyProducts();
			onProductsChange && onProductsChange();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.DELETE_PRODUCT }));
		}
	};

	const onUpdateProduct = async (id: string, name: string) => {
		try {
			return (
				await httpService({
					dispatch,
					path: PathsConfig.editProduct,
					data: { name, id },
					showLoader: false,
				})
			).data;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onClose = () => {
		setSelectedProduct(undefined);
		setSelectedPricingModel(undefined);
		setOpenModal(false);
	};

	const onUpdateSelectedRows = (rowIndex: number, isSelected: boolean) => {
		const newList = [...listOfSelectedRows];
		newList[0] = [...listOfSelectedRows[0]];
		newList[0][rowIndex] = isSelected;

		setListOfSelectedRows(newList);
	};

	const onAllUpdateSelectedRows = (isSelected: boolean) => {
		if (selectedPricingModel) {
			const selectedModelRows = selectedPricingModel?.tabs?.map((model) => model.pricingModelTable.rows.map(() => isSelected));
			selectedPricingModel?.id && setListOfSelectedRows(selectedModelRows);
		}
	};

	return (
		<Modal isOpen={openModal} closeModal={onClose}>
			<div data-testid='products-catalog-modal' className={styles.mainContainer}>
				<div className={styles.leftContainer}>
					<div className={styles.titleContainer}>
						<div className={styles.title}>{t('allProducts')}</div>
					</div>

					<div className={styles.pricingModelContainer}>
						<div className={styles.listContainer}>
							<SearchBar onChange={setSearchText} className={styles.searchBar} placeholder={`${t('search')}...`} />

							<NestedList
								selectedItem={selectedProduct?.id}
								className={styles.templateList}
								data={[
									{
										header: { title: t('products'), mainName: t('allProducts'), id: ProductsHeaderId.PRODUCTS },
										listArray: myProductsList.map((item) => ({
											...item,
											displayOptionMenu: true,
											onDuplicateProduct,
											onRenameProduct,
											onDeleteProduct,
										})),
										totalCount: myProductsList.flatMap((product) => product.pricingModels.map((model) => model)).length,
										canAddNewItems: true,
										onHeaderClick: () => onSelectMyProducts(myProductsList),
										onClickAddItem: onClickAddNewProduct,
										onSelectElement: onSelectProduct,
									},
								]}
							/>
							{contractType !== ContractType.REFERRAL && (
								<NestedList
									selectedItem={selectedProduct?.id}
									className={styles.templateList}
									data={[
										{
											header: { title: t('templates'), mainName: t('allTemplates'), id: ProductsHeaderId.CATEGORIES },
											listArray: pricingTemplateList,
											totalCount: pricingTemplateList.flatMap((product) => product.pricingModels.map((model) => model)).length,
											onHeaderClick: () => onSelectTemplates(pricingTemplateList),
											onSelectElement: onSelectCategory,
										},
									]}
								/>
							)}
						</div>
					</div>
				</div>

				<div className={styles.rightContainer}>
					{isProduct(selectedProduct) ? (
						selectedPricingModel ? (
							<PricingTable
								customHeader={
									<div className={styles.pricingTableHeader}>
										<div>{selectedProduct?.name}</div>
										<Select
											fullBorder
											width={12}
											data={pricingSelectList(t)}
											disabled={!isProduct(selectedProduct)}
											defaultValue={selectedFilter}
											onChange={(selected: pricingFilter) => setSelectedFilter(selected)}
										/>
									</div>
								}
								selectedRows={listOfSelectedRows}
								list={filterCatalogPricingModels(selectedFilter, selectedProduct?.pricingModels || [], searchText)}
								selectedModel={selectedPricingModel}
								selectPricing={(model) => setSelectedPricingModel(model)}
								selectButtonText='useThisPricing'
								pricingModelTableViewType={
									editMode ? PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE : PricingModelTableViewTypeOptions.CATALOG_PREVIEW_NOT_EDITABLE
								}
								onUseThisPricing={() => onClickSelectOrEdit(selectedPricingModel, listOfSelectedRows, onClose, onUseThisPricing)}
								onUpdateSelectedRows={onUpdateSelectedRows}
								disableUseThisPricing={disableUseThisPricing}
								onRefreshTable={() => setMyProducts()}
								showSwitch={{
									isVisible: editMode,
									onChange: (isSelected) => onAllUpdateSelectedRows(isSelected),
									defaultChecked: isMainTableSwitchOn,
								}}
							/>
						) : (
							<div>
								<div className={styles.pricingTableHeader}>
									<div>{selectedProduct?.name}</div>
								</div>
								<EmptyCatalogPreview
									addNewPricing={addNewPricingEmptyProduct && selectedProduct ? () => addNewPricingEmptyProduct?.(selectedProduct) : undefined}
								/>
							</div>
						)
					) : (
						<div className={styles.templateListContainer}>
							<PricingList
								customHeader={
									<div className={styles.pricingTableHeader}>
										<div>{selectedProduct?.name}</div>
									</div>
								}
								list={filterCatalogPricingModels('', selectedProduct?.pricingModels || [], searchText)}
								selectedModel={selectedPricingModel}
								selectPricing={(model) => setSelectedPricingModel(model)}
								listContainerClassName={styles.pricingList}
								className={styles.pricingListContainer}
							/>
							<div className={styles.tableContainer}>
								<PricingTableCardView
									showSwitch={{
										isVisible: editMode,
										onChange: (isSelected) => onAllUpdateSelectedRows(isSelected),
										defaultChecked: isMainTableSwitchOn,
									}}
									pricingModel={selectedPricingModel}
									selectedRows={listOfSelectedRows}
									imgType={selectedPricingModel?.iconType || 'subscription_template'}
									tagText={selectedPricingModel?.categoryName}
									pricingModelTableViewType={
										editMode
											? PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE
											: PricingModelTableViewTypeOptions.CATALOG_PREVIEW_NOT_EDITABLE
									}
									onUpdateSelectedRows={onUpdateSelectedRows}
								/>

								<div className={styles.buttonsContainer}>
									<Button
										dataTestId='edit-pricing'
										onClick={() => onClickSelectOrEdit(selectedPricingModel, listOfSelectedRows, onClose, onUseThisPricing)}
										disabled={disableUseThisPricing}
									>
										{t('useThisPricing')}
									</Button>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};
