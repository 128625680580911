import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ContractState, ContractType } from '@received/pricing-model';
import { Table } from '../../../components';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Store } from '../../../types/storeTypes';
import { contractMenuOptions } from '../../../utils/ContractUtils';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { createContract } from '../../../pages/Contracts/ContractComponent/Contract.utils';
import { AgreementResponse, AgreementView } from '../../../types/AgreementTypes';
import { agreementsTableHeader } from './AgreementsTab.utils';
import styles from './AgreementsTab.module.scss';

const defaultNumberOfItems = '20';

export const AgreementsTab = ({ isActive, customerData, sideBarFilters, refreshTabs, setOpenSideBarById }: CustomerTabProps) => {
	const { t } = useTranslation('translation');
	const { toastNotification } = useSelector((store: Store) => store.general);

	const [agreementsList, setAgreementsList] = useState<AgreementView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [states, setStates] = useState<ContractState[]>([]);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: 'state', order: SortOrder.DESC });

	const dispatch = useDispatch();
	const location: any = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		isActive && getData();
	}, [
		customerData,
		customerData?.id,
		numberOfItems,
		pageNumber,
		states,
		sort.orderBy,
		sort.order,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
		isActive,
		refreshTabs,
	]);

	useEffect(() => {
		location?.state?.filterByState && setSort((prev) => ({ ...prev, orderBy: 'state' }));
	}, []);

	const getData = async () => {
		if (customerData?.id) {
			await getAgreementsTabData();
		}
	};

	const getAgreementsTabData = async (page?: number, items?: string) => {
		try {
			const res: AgreementResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getAgreementsViewData,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						...sideBarFilters?.filterData,
					},
				})
			).data;

			setTotalCount(res?.count);
			setAgreementsList(res?.entries);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onHideShowDrafts = (showDraft: boolean) => {
		showDraft ? setStates([]) : setStates([ContractState.ACTIVE]);
	};

	const onPressNewAgreement = async () => {
		const agreementId = await createContract(dispatch, ContractType.REFERRAL, customerData?.id);
		agreementId && navigate('../referral-agreement', { state: { contractId: agreementId, customer: customerData } });
	};

	return (
		<div className={styles.container}>
			<Table
				sort={sort}
				showSwitch
				onToggleSwitch={onHideShowDrafts}
				header={agreementsTableHeader}
				rows={agreementsList}
				optionsList={(row) =>
					contractMenuOptions(row, t, dispatch, () => navigate('../referral-agreement', { state: { contractId: row?.id } }), getData)
				}
				className={styles.tableContainer}
				numberOfPages={Math.ceil(totalCount / +numberOfItems)}
				totalNumberOfItems={totalCount}
				numberOfItems={numberOfItems}
				onUpdateNumberOfItems={setNumberOfItems}
				onUpdatePageNumber={setPageNumber}
				onRowPress={(data) => setOpenSideBarById?.({ sideBarType: SidebarType.CONTRACT, id: data.id })}
				onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
				emptyStateProps={{
					title: t('noAgreementsFound'),
					description: t('noContractsDescription'),
					buttonText: `${t('createAgreement')} +`,
					onClick: () => onPressNewAgreement(),
				}}
			/>
		</div>
	);
};
