import dayjs from 'dayjs';
import { convertCurrencyToSign, DocumentState } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { FilterData, InvoiceStatusTag, SummaryBlockProps } from '../../../../components';
import { Cell, LogoCell, PriceCell } from '../../../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT } from '../../../../constants/templateConstants';
import { DatesData, DocumentView } from '../../../../types/generalTypes';
import { getSyncStatus } from '../../../../utils/IntegrationUtils';
import { getCreditUsed, getSummaryBlocksData, getTotalDue } from '../../../../utils/InvoiceUtils';
import { getDocumentNumberInSeries } from '../Invoices.utils';
import { InvoiceSummary, InvoiceView, summaryType } from '../../../../types/invoiceTypes';
import { createCSVFile, getDocumentIdNameByType, normalizeDataToCSVRows } from '../../../../utils/GeneralUtils';
import { formatEmailList } from '../../../../utils/UserUtils';
import { invoiceStatus } from '../../../../components/StatusTags/InvoiceStatusTag/InvoiceStatusTag.utils';
import { numberWithCommas } from '../../../../utils/NumberUtils';

export interface InvoiceResponse {
	data: InvoiceView[];
	count: number;
}

export const isDocumentTabsCanBeEdit = (type?: summaryType) => type != summaryType.SUCCESS && type != summaryType.ARCHIVE;

export const formatDaysToSend = (invoice: InvoiceView): string => {
	if (invoice.state > DocumentState.REVIEW_READY_TO_SEND) {
		const diff = dayjs().diff(invoice.dueDate, 'day');
		return diff < 0 ? `${Math.abs(diff)} days left` : `${diff} days ago`;
	}
	return '';
};

export const formatDraftIssueDays = (invoice: InvoiceView): string => {
	if (invoice.state <= DocumentState.REVIEW_READY_TO_SEND) {
		const diff = dayjs().diff(invoice.issueDate, 'day');
		return diff < 0 ? `in ${Math.abs(diff)} days` : `${diff} days ago`;
	}
	return '';
};

export const formatSentIssueDays = (invoice: InvoiceView): string => {
	switch (invoice.state) {
		case DocumentState.SENT: {
			const diff = dayjs().diff(invoice.dueDate, 'day');
			return diff < 0 ? `${Math.abs(diff)} days left` : `${diff} days ago`;
		}
		case DocumentState.LATE: {
			return `${dayjs().diff(invoice.dueDate, 'day')} days late`;
		}
		default:
			return '';
	}
};

export const invoicesTableHeader = (refreshData?: () => void) => [
	{
		headerTitle: 'relatedCustomer',
		accessor: 'customerName',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.CUSTOMER,
		CustomComponent: (item: InvoiceView) => (
			<LogoCell
				title={item.customerName}
				subTitle={formatEmailList(item.customerEmail)}
				logoData={item.customerIconData}
				cellSyncStatus={getSyncStatus(item)}
			/>
		),
	},
	{
		headerTitle: 'contractNo',
		accessor: 'contractNumber',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.CONTRACT_NO,
		CustomComponent: (item: InvoiceView) => <Cell accessor={item?.contractNumber || '-'} subAccessor={item.contractName} />,
	},
	{
		headerTitle: 'products',
		accessor: 'productNames',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.PRODUCTS,
		CustomComponent: (item: InvoiceView) => {
			const { t } = useTranslation('translation');
			const accessor = item?.productNames?.[0] || '';
			const subAccessor =
				+item?.productNames?.length > 1 ? `+${item?.productNames?.length - 1} ${t(item?.productNames?.length > 2 ? 'Products' : 'product')}` : '';
			return <Cell accessor={accessor} subAccessor={subAccessor} />;
		},
	},
	{
		headerTitle: 'documentNo',
		accessor: 'documentNumber',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.DOCUMENT_NO,
		CustomComponent: (item: DocumentView) => <Cell accessor={getDocumentIdNameByType(item)} subAccessor={getDocumentNumberInSeries(item)} />,
	},
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.ISSUE_DATE,
		CustomComponent: (item: InvoiceView) => <Cell accessor={dayjs(item.issueDate).format(DATE_FORMAT)} subAccessor={formatDraftIssueDays(item)} />,
	},
	{
		headerTitle: 'DueDate',
		accessor: 'dueDate',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.DUE_DATE,
		CustomComponent: (item: InvoiceView) => (
			<Cell accessor={item.dueDate ? dayjs(item.dueDate).format(DATE_FORMAT) : '-'} subAccessor={formatSentIssueDays(item)} />
		),
	},
	{
		headerTitle: 'totalDue',
		accessor: 'total',
		isSortable: true,
		CSVIndex: InvoiceCSVTitles.TOTAL_DUE,
		CustomComponent: (item: InvoiceView) => (
			<PriceCell currency={convertCurrencyToSign(item.currency)} mainNumber={getTotalDue(item)} subNumber={getCreditUsed(item)} />
		),
	},
	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		disableRowClick: true,
		CSVIndex: InvoiceCSVTitles.STATUS,
		CustomComponent: (item: InvoiceView) => <InvoiceStatusTag document={item} refreshData={refreshData} />,
	},
];

export const summaryBlocksTypes = (showDraft?: boolean) => {
	return [
		{
			label: summaryType.WARNING,
			value: [
				DocumentState.REVIEW_MISSING_DATA,
				DocumentState.COLLECTING_INFO,
				DocumentState.REVIEW_READY_TO_SEND,
				DocumentState.PAYMENT_FAILED,
				DocumentState.PAYMENT_FAILED_COLLECTING_INFO,
			].concat(showDraft ? [DocumentState.DRAFT] : []),
		},
		{
			label: summaryType.PRIMARY,
			value: [DocumentState.SENT, DocumentState.SENT_FAILED],
		},
		{
			label: summaryType.DESTRUCT,
			value: [DocumentState.LATE],
		},
		{
			label: summaryType.SUCCESS,
			value: [DocumentState.PAID],
		},
		{
			label: summaryType.ARCHIVE,
			value: [DocumentState.CANCELED],
		},
	];
};

export const getDefaultSortByState = (type?: summaryType): string => (type === summaryType.WARNING ? 'issueDate' : 'dueDate');

export const getInvoicesSummaryBlocksData = (t: any, invoiceSummary?: InvoiceSummary): SummaryBlockProps[] => {
	return [
		...getSummaryBlocksData(t, invoiceSummary),
		{
			type: summaryType.ARCHIVE,
			title: 'canceled',
			total: invoiceSummary?.canceledSum,
			subTitle: `${numberWithCommas(invoiceSummary?.canceledCount) || '0'} ${t('invoices')}`.toLocaleLowerCase(),
			iconColor: 'neutral',
		},
	];
};

export const isTimePeriodSelectDisabled = (filterData: FilterData): boolean =>
	!!filterData?.fromDueDate || !!filterData?.fromIssueDate || !!filterData?.toDueDate || !!filterData?.toIssueDate;

export const getSummaryDates = (filterData: FilterData, timePeriod: DatesData | undefined) => {
	if (timePeriod) {
		return { from: timePeriod.from, to: timePeriod.to };
	}
	if (filterData?.fromIssueDate || filterData?.toIssueDate) {
		return { from: filterData?.fromIssueDate, to: filterData?.toIssueDate };
	}
	if (filterData?.fromDueDate || filterData?.toDueDate) {
		return { from: filterData?.fromDueDate, to: filterData?.toDueDate };
	}
};

export const onDownloadInvoicesCSV = async (t: any, invoicesList: InvoiceView[]) => {
	const titles: any = invoicesTableHeader().reduce((acc, header) => ({ ...acc, [header.CSVIndex]: t(header.headerTitle) }), {});
	titles[InvoiceCSVTitles.CURRENCY] = t('currency');

	const data = invoicesList.map((invoice) => ({
		[titles[InvoiceCSVTitles.CUSTOMER]]: `"${invoice.customerName}"`,
		[titles[InvoiceCSVTitles.CONTRACT_NO]]: `"${invoice?.contractNumber}"` || '-',
		[titles[InvoiceCSVTitles.PRODUCTS]]: `"${invoice?.productNames.join(', ')}"`,
		[titles[InvoiceCSVTitles.DOCUMENT_NO]]: getDocumentIdNameByType(invoice),
		[titles[InvoiceCSVTitles.ISSUE_DATE]]: `"${dayjs(invoice.issueDate).format(DATE_FORMAT)}"`,
		[titles[InvoiceCSVTitles.DUE_DATE]]: invoice.dueDate ? `"${dayjs(invoice.dueDate).format(DATE_FORMAT)}"` : '-',
		[titles[InvoiceCSVTitles.TOTAL_DUE]]: `"${numberWithCommas(getTotalDue(invoice))}"`,
		[titles[InvoiceCSVTitles.STATUS]]: t(invoiceStatus(invoice.state).text),
		[titles[InvoiceCSVTitles.CURRENCY]]: invoice.currency,
	}));

	const csvContent = normalizeDataToCSVRows(titles, data);
	createCSVFile(`data:text/csv;charset=utf-8,${csvContent}`, 'Invoices');
};

export enum InvoiceCSVTitles {
	CUSTOMER,
	CONTRACT_NO,
	PRODUCTS,
	DOCUMENT_NO,
	ISSUE_DATE,
	DUE_DATE,
	TOTAL_DUE,
	STATUS,
	CURRENCY,
}
