/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	ColumnsListFormat,
	ImportedColumnsListFormat,
	UploadLogsSteps,
	UsageLogsFormat,
	convertLogFormatToArrays,
	defaultColumn,
	initialFormat,
	initialImportedColumns,
	initialLocalColumns,
	onAddNewFormat,
} from '../ImportUsageLogs.utils';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { PathsConfig, httpService } from '../../../../services';
import { ActionModal, Button, CloseButton, ControlledInput, Icon, Input, MenuList, MenuOption, Select, Tooltip } from '../../../../components';
import {
	ColumnsSeparator,
	convertLocColToIcon,
	importLogsValidation,
	isSelectRequired,
	onUpdateLocalValues,
	selectTabsByColumnTypes,
	usageLogsMapping,
} from './LeftContainer.utils';
import { TabsSelect, TabsSelectItem } from './TabsSelect/TabsSelect';
import { AddFormatModal } from './AddFormatModal/AddFormatModal';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { UsageProduct } from '../../../../types/usageTypes';
import { SelectItem } from '../../../../types/generalTypes';
import { getSuppliers } from '../../../../utils/CustomerUtils';
import { Customer } from '../../../../types/customerTypes';
import { images } from '../../../../constants/images';
import styles from './LeftContainer.module.scss';

interface LeftContainerProps {
	csvHeaders: string[];
	logsFormat: UsageLogsFormat;
	uploadSteps: UploadLogsSteps;
	reportsCount?: number;
	disableSubmit: boolean;
	errorFromServer: string;
	onUpdateLogsFormat(format: UsageLogsFormat): void;
	onImportUsageReports(logsFormat: UsageLogsFormat): void;
	setUploadSteps: Dispatch<SetStateAction<UploadLogsSteps>>;
	setHaveErrors(errors: boolean): void;
}

export const LeftContainer = ({
	csvHeaders,
	logsFormat,
	uploadSteps,
	reportsCount = 0,
	disableSubmit,
	errorFromServer,
	setUploadSteps,
	onImportUsageReports,
	onUpdateLogsFormat,
	setHaveErrors,
}: LeftContainerProps) => {
	const { t } = useTranslation('translation');

	const selectInputRef = useRef<HTMLInputElement>(null);

	const [isImportDisabled, setIsImportDisabled] = useState(true);
	const [logsFormatList, setLogsFormatList] = useState<UsageLogsFormat[]>([]);
	const [importedColumns, setImportedColumns] = useState<ImportedColumnsListFormat>({
		reportItems: [...initialImportedColumns.reportItems.map((item) => ({ ...item }))],
		logItems: [...initialImportedColumns.logItems.map((item) => ({ ...item }))],
	});
	const [localColumns, setLocalColumns] = useState<ColumnsListFormat>({
		reportItems: [...initialLocalColumns.reportItems],
		logItems: [...initialLocalColumns.logItems],
	});
	const [openAddFormat, setOpenAddFormat] = useState(false);
	const [customerList, setCustomerList] = useState<SelectItem[]>([]);
	const [entitiesList, setEntitiesList] = useState<SelectItem[]>([]);
	const [usageProducts, setUsageProducts] = useState<SelectItem[]>([]);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [isRenameFormat, setIsRenameFormat] = useState(false);
	const [isFormatEdited, setIsFormatEdited] = useState(false);
	const [{ showSaveFormatModal, formatToSwitch, isSaveAndClose }, setShowSaveFormatModal] = useState<{
		showSaveFormatModal: boolean;
		formatToSwitch: UsageLogsFormat | null;
		isSaveAndClose: boolean;
	}>({
		showSaveFormatModal: false,
		formatToSwitch: null,
		isSaveAndClose: false,
	});

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		selectInitialFormat();
		getCustomerList();
		getEntitiesList();
		getUsageProductList();
	}, []);

	useEffect(() => {
		const localErrors = importLogsValidation(t, localColumns, importedColumns, csvHeaders);
		const errors = localErrors.length ? localErrors : errorFromServer ? [errorFromServer] : [];

		const disableByExistingRequests = reportsCount === 0;
		setValidationErrors(errors);
		setHaveErrors(!!errors.length);
		setIsImportDisabled(
			disableSubmit || uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW
				? disableByExistingRequests || !!errors.length
				: !!errors.length || !csvHeaders.length,
		);
	}, [importedColumns, csvHeaders, reportsCount, uploadSteps, localColumns, disableSubmit, errorFromServer]);

	const getUsageFormatsList = async () => {
		try {
			const list = (
				await httpService({
					dispatch,
					path: PathsConfig.getUsageLogObjectMapperList,
				})
			).data;
			const sortedList = list.sort((a: UsageLogsFormat, b: UsageLogsFormat) => a.name.localeCompare(b.name));
			setLogsFormatList(sortedList);

			return sortedList;
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getCustomerList = async () => {
		try {
			const res = await httpService({
				showLoader: false,
				dispatch,
				path: PathsConfig.getCustomersList,
			});
			setCustomerList(res.data.map((item: Customer) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.GET_CUSTOMER_LIST }));
		}
	};

	const getEntitiesList = async () => {
		const entities = await getSuppliers(dispatch);
		setEntitiesList(entities.map((item: Customer) => ({ label: item.name, value: item.id })));
	};

	const getUsageProductList = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getUsageProductList,
				params: { isDraft: false },
			});

			setUsageProducts(res.data.map((item: UsageProduct) => ({ label: item.name, value: item.id })));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_USAGE_LIST }));
		}
	};

	const selectInitialFormat = async () => {
		try {
			setIsFormatEdited(false);
			const list = await getUsageFormatsList();

			setLogsFormatList(list);

			if (list.length) {
				const format = list.find((format: UsageLogsFormat) => format.name === initialFormat.name) || list[0];
				onUpdateLogsFormat(format);

				const { importedArrays, localArrays } = convertLogFormatToArrays(format);
				setImportedColumns(importedArrays);
				setLocalColumns(localArrays);
			} else {
				const newFormat = await onAddNewFormat(logsFormat, dispatch, true);
				onUpdateLogsFormat(newFormat);

				const { importedArrays, localArrays } = convertLogFormatToArrays(newFormat);
				setImportedColumns(importedArrays);
				setLocalColumns(localArrays);
				const list = await getUsageFormatsList();

				setLogsFormatList(list);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const selectFormat = async (format: UsageLogsFormat, isEdit: boolean) => {
		if (isEdit) {
			setShowSaveFormatModal((prev) => ({ ...prev, formatToSwitch: format, showSaveFormatModal: true }));
		} else {
			setShowSaveFormatModal((prev) => ({ ...prev, formatToSwitch: null, showSaveFormatModal: false }));
			const { importedArrays, localArrays } = convertLogFormatToArrays(format);
			onUpdateLogsFormat(format);
			setImportedColumns(importedArrays);
			setLocalColumns(localArrays);
		}
	};

	const onMatchReportItems = (index: number, tab?: TabsSelectItem, val?: string, name?: string) => {
		setIsFormatEdited(true);
		const newImportedColumns = { ...importedColumns };
		if (val) {
			newImportedColumns.reportItems[index] = {
				value: val,
				type: tab?.type,
				name: name,
				keyTitle: localColumns.reportItems[index],
				index,
			};
		} else {
			newImportedColumns.reportItems[index] = { ...defaultColumn, index };
		}
		const newMapping = usageLogsMapping(localColumns, newImportedColumns);

		uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW && setUploadSteps(UploadLogsSteps.REFRESH_CSV_AND_MAPPER);
		onUpdateLogsFormat({ ...logsFormat, mapping: newMapping });

		setImportedColumns({ ...newImportedColumns });
	};

	const onMatchLogItems = (index: number, tab?: TabsSelectItem, val?: string, name?: string) => {
		setIsFormatEdited(true);
		const newImportedColumns = { ...importedColumns };
		const newLocalColumns = { ...localColumns };

		const keyTitle = newImportedColumns.logItems[index]?.keyTitle || name || val || '';
		if (val) {
			newImportedColumns.logItems[index] = {
				value: val,
				type: tab?.type,
				name: name,
				keyTitle: keyTitle,
				index: index + newImportedColumns.reportItems.length,
			};
		} else {
			newImportedColumns.logItems[index] = { ...defaultColumn, index: index + newImportedColumns.reportItems.length };
		}
		newLocalColumns.logItems[index] = onUpdateLocalValues(newLocalColumns.logItems[index], keyTitle, newLocalColumns);

		const newMapping = usageLogsMapping(newLocalColumns, newImportedColumns);
		onUpdateLogsFormat({ ...logsFormat, mapping: newMapping });

		uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW && setUploadSteps(UploadLogsSteps.REFRESH_CSV_AND_MAPPER);
		setImportedColumns({ ...newImportedColumns });
	};

	const onAddItem = () => {
		setIsFormatEdited(true);

		setLocalColumns((prev) => ({ ...prev, logItems: [...prev.logItems, ''] }));
		setImportedColumns((prev) => ({
			...prev,
			logItems: [...prev.logItems, { ...defaultColumn, index: prev.reportItems.length + prev.logItems.length }],
		}));
	};

	const onDeleteItem = (index: number) => {
		setIsFormatEdited(true);

		const newLocal = { ...localColumns };
		newLocal.logItems.splice(index, 1);
		const newImported = { ...importedColumns };
		newImported.logItems.splice(index, 1);

		const newMapping = usageLogsMapping(newLocal, newImported);
		onUpdateLogsFormat({ ...logsFormat, mapping: newMapping });
		setLocalColumns({ ...newLocal });
		setImportedColumns({ ...newImported });
		uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW && setUploadSteps(UploadLogsSteps.REFRESH_CSV_AND_MAPPER);
	};

	const onUpdateInput = (index: number, val: string) => {
		setIsFormatEdited(true);

		const newLocal = { ...localColumns, logItems: [...localColumns.logItems] };
		const newImported = { ...importedColumns, logItems: [...importedColumns.logItems] };

		newLocal.logItems[index] = onUpdateLocalValues(newLocal.logItems[index], val, newLocal);
		newImported.logItems[index] = { ...importedColumns.logItems[index] };
		newImported.logItems[index].keyTitle = val;
		setLocalColumns({ ...localColumns });
		setImportedColumns({ ...newImported });

		const newMapping = usageLogsMapping(newLocal, newImported);
		onUpdateLogsFormat({ ...logsFormat, mapping: newMapping });
		uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW && setUploadSteps(UploadLogsSteps.REFRESH_CSV_AND_MAPPER);
	};

	const onDeleteFormat = async (format: UsageLogsFormat) => {
		try {
			if (format.id) {
				await httpService({
					dispatch,
					path: PathsConfig.deleteUsageLogObjectMapper,
					urlParam: { id: format.id },
				});

				if (format?.id === logsFormat?.id) {
					selectInitialFormat();
				} else {
					getUsageFormatsList();
				}
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onPressUpDown = (index: number, replaceIndex: number) => {
		setIsFormatEdited(true);

		const currentColumn = { ...importedColumns.logItems[index] };
		const replacedColumn = { ...importedColumns.logItems[replaceIndex] };
		const newImportedColumns = { ...importedColumns };
		newImportedColumns.logItems[index] = replacedColumn;
		newImportedColumns.logItems[replaceIndex] = currentColumn;

		const currentLocalColumn = localColumns.logItems[index];
		const replacedLocalColumn = localColumns.logItems[replaceIndex];
		const newLocalColumns = { ...localColumns };
		newLocalColumns.logItems[index] = replacedLocalColumn;
		newLocalColumns.logItems[replaceIndex] = currentLocalColumn;

		const newMapping = usageLogsMapping(newLocalColumns, newImportedColumns);
		onUpdateLogsFormat({ ...logsFormat, mapping: newMapping });

		uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW && setUploadSteps(UploadLogsSteps.REFRESH_CSV_AND_MAPPER);
		setImportedColumns({ ...newImportedColumns });
	};

	const onReset = async () => {
		setIsFormatEdited(true);
		const newFormat = { ...logsFormat, mapping: initialFormat.mapping };
		selectFormat(newFormat, false);
	};

	const onSaveFormat = async () => {
		await onAddNewFormat(logsFormat, dispatch, false);
		const list = await getUsageFormatsList();
		setLogsFormatList(list);
		setIsFormatEdited(false);
	};

	const onDuplicateFormat = async () => {
		const newFormat = { ...logsFormat, id: undefined, name: `${t('copyOf')} ${logsFormat.name}` };
		const newFormatWithId = await onAddNewFormat(newFormat, dispatch, false);
		selectFormat(newFormatWithId, false);
		const list = await getUsageFormatsList();
		setLogsFormatList(list);
		setIsFormatEdited(false);
	};

	const menuOptions = () => {
		let list: MenuOption[] = [
			{ title: t('addFormat'), onPress: () => setOpenAddFormat(true), iconType: 'add' },
			{ title: t('rename'), onPress: () => setIsRenameFormat(true), iconType: 'edit' },
			{ title: t('Duplicate'), onPress: () => onDuplicateFormat(), iconType: 'duplicate', hasBottomBorder: logsFormatList.length != 1 },
		];
		if (logsFormatList.length != 1) {
			list = [...list, { title: t('deleteFormat'), onPress: () => onDeleteFormat(logsFormat), iconType: 'delete', color: 'destructive' }];
		}
		return list;
	};

	return (
		<div className={styles.leftContainer}>
			<header className={styles.header}>
				<div className={styles.leftContainerHeader}>
					<div className={styles.headerIcon}>
						<Icon imgType='import' color='neutral700' height={3.2} />
					</div>
					<div>
						<div className={styles.fontSize24}>{t('importUsageLogData')}</div>
						<div className={styles.subTitle}>{t('importUsageLogAndMatchColumns')}</div>
					</div>
				</div>

				<CloseButton
					onClick={() =>
						isFormatEdited ? setShowSaveFormatModal((prev) => ({ ...prev, showSaveFormatModal: true, isSaveAndClose: true })) : navigate(-1)
					}
				/>
			</header>

			<div className={styles.formatContainer}>
				<div className={styles.formatContainerSubTitles}>
					<div>{t('chooseFormat')}</div>
					<div className={styles.actionsButtons}>
						<Button type='link' onClick={() => onSaveFormat()} disabled={!isFormatEdited}>
							{t('save')}
						</Button>
						•
						<MenuList
							optionsList={menuOptions()}
							isMenuOpen={isOptionsMenuOpen}
							openMenu={() => setIsOptionsMenuOpen(false)}
							className={styles.menuList}
						>
							<Button type='link' onClick={() => setIsOptionsMenuOpen(true)} className={styles.moreButton}>
								{t('more')}
								<Icon imgType={isOptionsMenuOpen ? 'arrow_up' : 'arrow_down'} width={1.2} color='neutral900' />
							</Button>
						</MenuList>
					</div>
				</div>
				<Select
					inputRef={selectInputRef}
					defaultValue={{ label: logsFormat?.name, value: logsFormat?.id }}
					data={logsFormatList.map((item) => ({
						label: item.name,
						value: item?.id,
						customComponent: () => (
							<div className={styles.selectItem}>
								<div>{item.name}</div>
							</div>
						),
					}))}
					onChange={(value) => {
						selectFormat(logsFormatList.find((item) => item.id === value) || initialFormat, isFormatEdited);
						setUploadSteps(
							csvHeaders.length && uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW
								? UploadLogsSteps.REFRESH_CSV_AND_MAPPER
								: UploadLogsSteps.UPLOAD_CSV_AND_MAPPER,
						);
					}}
					style={{ width: '100%' }}
					addButton={{ title: t('newFormat'), onPress: () => setOpenAddFormat(true) }}
				/>

				<div className={styles.formatContainerSubTitles}>
					<div>{t('matchColumns')}</div>
					<Button type='link' onClick={onReset}>
						{t('resetColumns')}
						<Icon imgType='refresh' color='success' />
					</Button>
				</div>

				<div className={styles.columnsContainer}>
					<div className={styles.columns}>
						<div className={styles.columnsTitle}>{t('yourTable')}</div>
						{importedColumns?.reportItems.map((item, index) => (
							<TabsSelect
								key={`0${index}`}
								required={isSelectRequired(localColumns.reportItems[index])}
								defaultValue={{ value: item?.value, tabType: item?.type, label: item?.name }}
								csvHeaders={csvHeaders}
								inputProps={{
									disabled: true,
									containerClass: styles.selectInputContainer,
									inputClassName: styles.selectInput,
									placeholder: `${t('selectColumn')}${isSelectRequired(localColumns.reportItems[index]) ? ' *' : ''}`,
									rightChildren: <Icon imgType='arrow_down' color='neutral' width={1.5} className={styles.rightInputIcon} />,
								}}
								title={`${t('select')} ${localColumns?.reportItems[index]}`}
								data={selectTabsByColumnTypes(localColumns.reportItems[index], t, csvHeaders, usageProducts, customerList, entitiesList)}
								onChange={(tab, value, name) => onMatchReportItems(index, tab, value, name)}
							/>
						))}

						<div className={styles.columnsTitle}>{t('fileColumns')}</div>
						{importedColumns?.logItems.map((item, index) => (
							<TabsSelect
								key={`1${index}`}
								required={isSelectRequired(localColumns.logItems[index])}
								defaultValue={{ value: item?.value, tabType: item?.type, label: item?.name }}
								csvHeaders={csvHeaders}
								inputProps={{
									disabled: true,
									containerClass: styles.selectInputContainer,
									inputClassName: styles.selectInput,
									placeholder: `${t('selectColumn')}${isSelectRequired(localColumns.logItems[index]) ? ' *' : ''}`,
									rightChildren: <Icon imgType='arrow_down' color='neutral' width={1.5} className={styles.rightInputIcon} />,
								}}
								title={`${t('select')} ${localColumns?.logItems[index]}`}
								data={selectTabsByColumnTypes(localColumns.logItems[index], t, csvHeaders, usageProducts, customerList, entitiesList)}
								onChange={(tab, value, name) => onMatchLogItems(index, tab, value, name)}
							/>
						))}
						<Button onClick={onAddItem} type='link' className={styles.addItemButton}>
							<Icon imgType='add' color='success' height={1} />
							{t('addItem')}
						</Button>
					</div>

					<ColumnsSeparator localColumns={localColumns} onPressUpDown={onPressUpDown} />

					<div className={styles.columns}>
						<div className={styles.columnsTitle}>{t('willBecome')}</div>
						{localColumns?.reportItems.map((item, index) => (
							<Input
								key={`${index}${item}`}
								value={item}
								containerClass={styles.selectInputContainer}
								inputClassName={styles.input}
								disabled
								// @ts-ignore
								leftChildren={
									convertLocColToIcon(item) && (
										// @ts-ignore
										<Icon imgType={convertLocColToIcon(item)} color='neutral400' width={1.5} className={styles.leftInputIcon} />
									)
								}
							/>
						))}

						<div className={styles.columnsTitle}>{t('reportColumns')}</div>
						{localColumns?.logItems.map((item, index) => (
							<div key={`${index}${item}`} className={styles.inputContainer}>
								<ControlledInput
									value={importedColumns?.logItems[index]?.keyTitle}
									placeholder={item}
									containerClass={styles.selectInputContainer}
									onBlur={(e) => onUpdateInput(index, e.target.value)}
									onKeyDown={(e) => {
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										// @ts-ignore
										e.key === 'Enter' && onUpdateInput(index, e.target.value);
									}}
									// @ts-ignore
									leftChildren={
										convertLocColToIcon(item) && (
											// @ts-ignore
											<Icon imgType={convertLocColToIcon(item)} color='neutral400' width={1.5} className={styles.leftInputIcon} />
										)
									}
								/>
								{index >= initialImportedColumns.logItems.length - 1 && (
									<Button onClick={() => onDeleteItem(index)} type='link' className={styles.deleteItemButton}>
										<Icon imgType='delete' color='destructive' height={2} />
									</Button>
								)}
							</div>
						))}
					</div>
				</div>
			</div>

			<div className={styles.buttonsContainer}>
				<Tooltip
					tooltipComponent={
						validationErrors.length ? (
							<>
								{validationErrors.map((er, index) => (
									<div key={index} className={styles.errorText}>
										{er}
									</div>
								))}
							</>
						) : null
					}
				>
					<Button onClick={() => onImportUsageReports(logsFormat)} disabled={isImportDisabled} className={styles.buttons}>
						{disableSubmit ? (
							<Icon imgType='refresh' color='white' className={styles.refreshIconAnimation} />
						) : (
							<>
								{uploadSteps === UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW
									? t('importUsageReports').replace('__', reportsCount.toString())
									: t('showUsageReports')}
							</>
						)}
					</Button>
				</Tooltip>

				<Button
					onClick={() =>
						isFormatEdited ? setShowSaveFormatModal((prev) => ({ ...prev, showSaveFormatModal: true, isSaveAndClose: true })) : navigate(-1)
					}
					color='destructive'
					type='outlined'
					className={styles.buttons}
				>
					{t('discardImportData')}
				</Button>
			</div>

			<AddFormatModal
				isOpen={openAddFormat || isRenameFormat}
				isEdit={isRenameFormat}
				logsFormat={logsFormat}
				logsFormatList={logsFormatList}
				onFormatAdded={(format) => {
					getUsageFormatsList();
					selectFormat(format, false);
					setIsFormatEdited(false);
				}}
				closeModal={() => {
					setOpenAddFormat(false);
					setIsRenameFormat(false);
				}}
			/>

			<ActionModal
				isOpen={showSaveFormatModal}
				title={t('unsavedChanges')}
				description={
					<>
						<div>{t('youMadeSomeChanges')}</div>
						<div>{t('wouldYouLikeToSaveOrDiscard')}</div>
					</>
				}
				mainButton={{
					title: t('save'),
					onClick: async () => {
						await onSaveFormat();
						if (isSaveAndClose) {
							setShowSaveFormatModal((prev) => ({ ...prev, formatToSwitch: null, showSaveFormatModal: false, isSaveAndClose: false }));
							navigate(-1);
						} else {
							formatToSwitch && selectFormat(formatToSwitch, false);
						}
					},
				}}
				secondaryButton={{
					title: t('discardChanges'),
					type: 'outlined',
					color: 'neutral',
					className: styles.actionModalSecondaryButton,
					onClick: async () => {
						if (isSaveAndClose) {
							setShowSaveFormatModal((prev) => ({ ...prev, formatToSwitch: null, showSaveFormatModal: false, isSaveAndClose: false }));
							navigate(-1);
						} else {
							setIsFormatEdited(false);
							formatToSwitch && selectFormat(formatToSwitch, false);
						}
					},
				}}
				imgSrc={images.saveChanges}
				onClose={() => setShowSaveFormatModal((prev) => ({ ...prev, formatToSwitch: null, showSaveFormatModal: false, isSaveAndClose: false }))}
			/>
		</div>
	);
};
