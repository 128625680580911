import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, MenuList, MenuOption } from '..';
import { BillingAccountDetails, Customer } from '../../types/customerTypes';
import styles from './Entity.module.scss';

interface EntityProps {
	entity: Customer;
	onClick: (id: string) => void;
	onRemove: (id: string, name: string) => void;
	onDefault: (id: string) => void;
}

export const Entity = ({ entity, onClick, onRemove, onDefault }: EntityProps) => {
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const location = [entity.city, entity.countryState].reduce((a, b) => (a && b ? `${a}, ${b}` : a ? a : b ? b : ''));
	const { t } = useTranslation('translation');

	const menuOptions = () => {
		let defaultList: MenuOption[] = [
			{
				title: t('editInfo'),
				onPress: () => entity.id && onClick(entity.id),
				iconType: 'edit',
			},
		];

		if (!entity.isDefault) {
			defaultList = [
				...defaultList,
				{
					title: t('defaultEntity'),
					onPress: () => entity.id && onDefault(entity.id),
					iconType: 'defaultBadge',
				},
				{ title: t('deleteEntity'), onPress: () => entity.id && onRemove(entity.id, entity.name), iconType: 'delete', color: 'destructive' },
			];
		}
		return defaultList;
	};

	return (
		<Button className={styles.container} type='link' onClick={() => entity?.id && onClick(entity?.id)}>
			<div className={styles.topSection}>
				{entity.iconData ? (
					<img alt='logo' src={entity.iconData} className={styles.logo} />
				) : (
					<div className={styles.square}>
						<Icon imgType='companyFlag' color='neutral300' width={2.8} className={styles.icon} />
					</div>
				)}

				<MenuList optionsList={menuOptions()} isMenuOpen={isOptionsMenuOpen} openMenu={() => setIsOptionsMenuOpen(false)}>
					<Button type='link' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)} className={styles.hamburgerButton}>
						<Icon imgType='hamburger' color='neutral700' className={styles.icon} />
					</Button>
				</MenuList>
			</div>

			<div className={styles.bottomSection}>
				<div className={styles.details}>
					<div className={styles.flex}>
						{entity.isDefault && <div className={styles.badge} />}
						<div className={styles.title}>{entity.name}</div>
					</div>

					<div className={styles.subTitle}>
						<div className={styles.subTitle}>{entity.ein}</div>
					</div>
					<div className={styles.location}>{entity.shippingAddress}</div>
					<span className={styles.location}>{location}</span>
					<span className={entity.country && location ? styles.country : styles.hidden}>{entity.country}</span>
				</div>

				<div className={styles.flex}>
					{entity?.billingDetails?.billingAccounts &&
						(entity?.billingDetails?.billingAccounts?.length <= 2 ? (
							entity?.billingDetails.billingAccounts.map((bank: BillingAccountDetails) => (
								<div key={bank.id} className={bank.bank && styles.tag}>
									{bank.bank}
								</div>
							))
						) : (
							<>
								<div className={styles.tag}>{entity?.billingDetails.billingAccounts[0].bank}</div>
								<div className={styles.tag}>+ {entity?.billingDetails.billingAccounts.length - 1}</div>
							</>
						))}
				</div>
			</div>
		</Button>
	);
};
