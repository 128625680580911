import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { DEFAULT_CURRENCY, UsageLogsColumnsOptions } from '@received/pricing-model';
import { Icon, IconImgType } from '../../../../components';
import { SummaryByProductItem, ValidationWarnings } from '../../../../types/usageTypes';
import { DATE_FORMAT_SLASH } from '../../../../constants/templateConstants';
import styles from './RightContainer.module.scss';

const startDateExample = dayjs().format(DATE_FORMAT_SLASH);
const nameExample = 'Acme Inc';
const usageProductExample = 'Units';
const entityExample = 'Acme Inc USA';
const currencyExample = DEFAULT_CURRENCY;
const poExample = '';
const subActivityExample = '';
const quantityExample = '60';

export const importUsageLogsTemplateColumns = [
	UsageLogsColumnsOptions.DATE,
	UsageLogsColumnsOptions.CUSTOMER,
	UsageLogsColumnsOptions.USAGE_PRODUCT,
	UsageLogsColumnsOptions.ENTITY,
	UsageLogsColumnsOptions.CURRENCY,
	UsageLogsColumnsOptions.PO_NUMBER,
	UsageLogsColumnsOptions.SUB_ACTIVITY,
	UsageLogsColumnsOptions.QUANTITY,
];

export const USAGE_CSV_TEMPLATE_NAME = 'template';

export const USAGE_LOGS_CSV_TEMPLATE = `data:text/csv;charset=utf-8,${importUsageLogsTemplateColumns.join(
	',',
)}\n${startDateExample},${nameExample},${usageProductExample},${entityExample},${currencyExample},${poExample},${subActivityExample},${quantityExample},`;

export const AnalyticItem = ({ icon, number, subTitle }: { icon: IconImgType; number?: number; subTitle: string }) => {
	return (
		<div className={styles.analyticItem}>
			<Icon imgType={icon} color='neutral700' height={4} className={styles.analyticItemIcon} />

			<div>
				<div className={styles.fontSize24}>{number || 0}</div>
				<div>{subTitle}</div>
			</div>
		</div>
	);
};

export const UsageLogSummaryItem = ({ item, isSelected, onClick }: { item: SummaryByProductItem; isSelected?: boolean; onClick(): void }) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.usageLogSummaryItem, isSelected && styles.usageLogSummaryItemSelected)} onClick={onClick}>
			<div className={styles.header}>
				<div>
					<div className={styles.productTitle}>{item?.connectedProducts.length ? item.connectedProducts[0] : ''}</div>
					<div className={styles.fontSize20}>{item?.usageProduct?.name}</div>
				</div>

				<Icon imgType='arrow_down' color='neutral700' className={isSelected ? styles.arrowUp : styles.arrowDown} />
			</div>

			<div className={styles.tagsContainer}>
				<Tag icon='document' text={t(item?.usageReportsView?.length === 1 ? 'report' : 'reports')} number={item?.usageReportsView?.length} />
				<Tag icon='graph' text={t(item?.usageLogs === 1 ? 'log' : 'logs')} number={item?.usageLogs} />
				<Tag icon='customersEmpty' text={t(item?.customers === 1 ? 'customer' : 'Customers')} number={item?.customers} />
				<Tag icon='avatar' text={t(item?.subActivities === 1 ? 'subActivity' : 'subActivities')} number={item?.subActivities} />
				<Tag icon='unfilled_bookmark' text={t(item?.invoices === 1 ? 'document' : 'documents')} number={item?.invoices} />
			</div>
		</div>
	);
};

const Tag = ({ icon, number = 0, text }: { icon: IconImgType; number?: number; text: string }) => {
	return (
		<div className={styles.tag}>
			<Icon imgType={icon} color='neutral700' />

			<div className={styles.fontSize14}>
				{number} {text}
			</div>
		</div>
	);
};

export const numberOfWarnings = (warnings?: ValidationWarnings) =>
	warnings ? Object.keys(warnings).reduce((item, KeyB) => item + warnings[KeyB].count, 0) : 0;
