import react, { useEffect } from 'react';
import { usePDF } from '@react-pdf/renderer';
import { Document, Page } from 'react-pdf';
import { UsageLogsPDF, UsageProduct } from '@received/pricing-model';
import { ErrorBoundary } from '../../../../../services/ErrorBoundary/ErrorBoundary';
import { images } from '../../../../../constants/images';
import styles from './LogsPdfPreview.module.scss';

interface PDFPreviewProps {
	usageProduct: UsageProduct;
}

export const LogsPdfPreview = ({ usageProduct }: PDFPreviewProps) => {
	const [instance, setInstance] = usePDF({
		document: <UsageLogsPDF usageProduct={usageProduct} isSample />,
	});

	useEffect(() => {
		setInstance();
	}, [usageProduct, usageProduct.usageReports]);

	return (
		<div className={styles.container}>
			<ErrorBoundary
				allowedRetries
				customUIComponent={
					<div className={styles.errorContainer}>
						<img alt='refresh-document-preview' src={images.whiteRefresh} />
					</div>
				}
			>
				<Document file={instance?.blob} className={styles.pdf}>
					<Page scale={2} pageNumber={1} className={styles.pagePdf} renderTextLayer={false} renderAnnotationLayer={false} />
				</Document>
			</ErrorBoundary>
		</div>
	);
};
