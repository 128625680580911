import React, { ChangeEvent, CSSProperties, InputHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import { Header } from '../Header/Header';
import { IconImgType } from '../Icon';
import styles from './Input.module.scss';

export interface InputProps<T> extends Omit<InputHTMLAttributes<T>, 'onChange'> {
	title?: string;
	required?: boolean;
	containerStyle?: CSSProperties;
	showError?: boolean;
	className?: string;
	inputClassName?: string;
	titleStyle?: string;
	containerClass?: string;
	disabled?: boolean;
	customTitle?: ReactNode;
	inputContainerStyle?: CSSProperties;
	rightChildren?: ReactNode;
	leftChildren?: ReactNode;
	iconType?: IconImgType | null;
	dataTestId?: string;
	fullBorder?: boolean;
	onChange?: (val: string, event: ChangeEvent<HTMLInputElement>) => void;
}

export const Input = <T extends HTMLInputElement>({
	title,
	value,
	containerStyle,
	containerClass,
	showError,
	className,
	disabled,
	required = false,
	titleStyle,
	customTitle,
	inputClassName,
	inputContainerStyle,
	rightChildren,
	leftChildren,
	iconType,
	dataTestId,
	fullBorder,
	onChange,
	...props
}: InputProps<T>) => {
	return (
		<div
			data-testid={`input-button${title ? `-${title}` : ''}${dataTestId ? `-${dataTestId}` : ''}`}
			style={{ ...containerStyle }}
			className={clsx(disabled && styles.disabled, containerClass)}
		>
			{(title || customTitle) && (
				<div className={styles.header}>
					{title && <Header title={required ? `${title}*` : title} labelStyle={clsx(styles.formTitle, titleStyle)} iconType={iconType} />}
					{customTitle}
				</div>
			)}

			<div
				className={clsx(styles.inputContainer, showError && styles.errorContainer, fullBorder && styles.fullBorder, className)}
				style={inputContainerStyle}
			>
				{leftChildren}
				<input
					{...props}
					value={value}
					className={clsx(styles.input, inputClassName)}
					onChange={(event) => onChange && onChange(event.target.value, event)}
					data-testid={dataTestId || `input${title ? `-${title}` : ''}`}
					autoComplete='off'
					onWheel={(e: any) => e.target?.blur()}
				/>
				{rightChildren}
			</div>
		</div>
	);
};
