import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { InputIconType } from '..';
import { StripeCard } from '../../../../types/integrationTypes';
import { Icon, IconImgType } from '../../Icon';
import { creditCardLabel, getCreditCardBrandLogo } from '../../../../utils/GeneralUtils';
import styles from './StripeCardSelect.module.scss';

export enum CreditCardBrand {
	AMEX = 'AMEX',
	AMERICAN_EXPRESS = 'AMERICAN EXPRESS',
	DINERS = 'DINERS',
	DISCOVER = 'DISCOVER',
	EFTPOS_AU = 'EFTPOS_AU',
	JCB = 'JCB',
	MASTERCARD = 'MASTERCARD',
	UNIONPAY = 'UNIONPAY',
	VISA = 'VISA',
	UNKNOWN = 'UNKNOWN',
}
interface CreditCardSelectItemProps {
	item?: StripeCard;
	displayCustomerNameOnly?: boolean;
	className?: string;
}

export const INITIAL_INPUT_ICON: InputIconType = { imgType: 'profile', color: 'neutral700', width: 2 };

export const CreditCardSelectItem = ({ item, displayCustomerNameOnly }: CreditCardSelectItemProps) => {
	const logo = getCreditCardBrandLogo(item?.cardBrand);

	return (
		<div className={styles.stripeCardItem}>
			<div className={styles.flex}>
				{!displayCustomerNameOnly && (
					<div className={styles.iconContainer}>
						<Icon imgType={logo} className={styles.stripeIcon} />
					</div>
				)}
				{creditCardLabel(item, displayCustomerNameOnly)}
			</div>
			<Errors item={item} displayCustomerNameOnly={displayCustomerNameOnly} />
		</div>
	);
};

export const Errors = ({ item, displayCustomerNameOnly, className }: CreditCardSelectItemProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(displayCustomerNameOnly || !item ? styles.hidden : styles.stripeCardErrors, className)}>
			{getStripeCreditCardErrors(item).map((error) => (
				<div key={error} className={clsx(error ? styles.stripeCardError : styles.hidden)}>
					{t(error)}
				</div>
			))}
		</div>
	);
};

export const getInputLogo = (displayCustomerNameOnly?: boolean, cardBrand?: CreditCardBrand): InputIconType => {
	if (!cardBrand || displayCustomerNameOnly) {
		return INITIAL_INPUT_ICON;
	}
	return { imgType: getCreditCardBrandLogo(cardBrand), width: 2.5 };
};

export const getStripeCreditCardErrors = (card?: StripeCard) => {
	let errors: string[] = [];
	if (!card?.errors?.cvcCheckPassed) {
		errors = ['CVCMissing'];
	}
	if (card?.errors?.expired) {
		errors = [...errors, 'expired'];
	}
	if (!card?.hasDefaultCard) {
		errors = ['noDefaultCard'];
	}
	return errors;
};
