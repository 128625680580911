import { useTranslation } from 'react-i18next';
import { PayoutTabsProps } from '../../PayoutSideBar.utils';
import { SideBarFooter } from '../../../components';
import { setFooterData } from './PayoutOverviewTab.utils';
import { PricingModelDetails } from '../../../../PricingModelDetails/PricingModelDetails';
import styles from './PayoutOverviewTab.module.scss';

export const PayoutOverviewTab = ({ payoutData, hasUsageReport, onPressUpdateUsage }: PayoutTabsProps) => {
	const { t } = useTranslation('translation');

	const tabsCount = payoutData?.pricingModel?.tabs?.length || 0;

	return (
		<div className={styles.tabsSection}>
			<div className={styles.container}>
				<div className={styles.headerOverviewContainer}>
					<div className={styles.flexColumn}>
						<span className={styles.title}>{`${tabsCount} ${t('related')} ${t(tabsCount == 1 ? 'product' : 'Products')}`}</span>
						<span className={styles.grayText}>{`${tabsCount} ${t(tabsCount == 1 ? 'pricingModel' : 'pricingModels')}`}</span>
					</div>
				</div>

				<div className={styles.cards}>
					{payoutData?.pricingModel?.tabs?.map((tab, index) => (
						<PricingModelDetails
							key={index}
							data={tab}
							supplier={payoutData?.supplier}
							hasUsageReport={hasUsageReport}
							fromDate={tab.billingStartDate}
							toDate={tab.billingEndDate}
							onPressUpdateUsage={() =>
								onPressUpdateUsage?.({
									fromDate: tab?.billingStartDate || null,
									toDate: tab?.billingEndDate || null,
								})
							}
						/>
					))}
				</div>
			</div>

			<SideBarFooter items={setFooterData(payoutData)} className={styles.footer} />
		</div>
	);
};
