import { Dispatch, SetStateAction, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Select, Input, Button, Icon, Tooltip, MenuList } from '../../../../components/_uiComponents';
import { PricingModel } from '../../../../types/contractTypes';
import { ProductAndCategoriesItem } from '../../../../types/ProductTypes';
import { menuOptions } from '../NewPricingModel.utils';
import styles from '../NewPricingModel.module.scss';

interface PricingModelHeadersProps {
	pricingModel: PricingModel;
	product: { id: string; name: string };
	productsList: ProductAndCategoriesItem[];
	currentLocation: any;
	isUsedAsComponent?: boolean;
	setProduct: Dispatch<SetStateAction<{ id: string; name: string }>>;
	setOpenProductModal: Dispatch<SetStateAction<boolean>>;
	getPricingModel(id?: string): void;
	onGoBack(): void;
	onDoneEditingPricingModel(): void;
	setPricingModel: Dispatch<SetStateAction<PricingModel>>;
}

export const PricingModelHeader = ({
	pricingModel,
	product,
	productsList,
	currentLocation,
	isUsedAsComponent,
	setProduct,
	setOpenProductModal,
	getPricingModel,
	onGoBack,
	onDoneEditingPricingModel,
	setPricingModel,
}: PricingModelHeadersProps) => {
	const { t } = useTranslation('translation');
	const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false);
	const [editInputName, setEditInputName] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<header className={styles.pageHeader}>
			<div>
				<Button type='link' color='neutral' onClick={onGoBack} className={styles.goBack}>
					<Icon imgType='arrow_back' />
					<span>{t('allProducts')}</span>
				</Button>

				<div className={styles.titleContainer}>
					<div className={styles.iconContainer}>
						<Icon imgType='subscription_template' height={2} />
					</div>
					<div>
						{pricingModel.product?.name ? (
							<span className={styles.productName}>{pricingModel.product?.name}</span>
						) : (
							<Select
								placeholder={t('selectProduct')}
								autoFocus={!product?.id}
								width={13}
								inputClassName={styles.productNameInput}
								className={styles.selectTag}
								defaultValue={{ label: product?.name, value: product?.id }}
								data={productsList.map((item) => ({ label: item.name, value: item.id }))}
								onChange={(_, item) => typeof item != 'string' && setProduct({ id: item?.value, name: (item?.label as string) || '' })}
								addButton={{ title: t('addProduct'), onPress: () => setOpenProductModal(true) }}
							/>
						)}

						{editInputName ? (
							<Input
								dataTestId='pricing-title-input'
								autoFocus
								onChange={(value) => setPricingModel((prev) => ({ ...prev, name: value }))}
								className={styles.editModelNameContainer}
								inputClassName={styles.modelName}
								value={pricingModel.name}
								onBlur={() => setEditInputName(false)}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										setEditInputName(false);
									}
								}}
							/>
						) : (
							<div data-testid='pricing-title-edit' className={styles.modelNameContainer} onClick={() => setEditInputName(true)}>
								<div className={styles.modelName}>{pricingModel.name}</div>
								<Icon imgType='edit' height={2} />
							</div>
						)}
					</div>
				</div>
			</div>

			<div className={styles.buttonsContainer}>
				<Tooltip placement='left' tooltipComponent={!product.id && <div>{t('missingProduct')}</div>}>
					<Button
						type={isUsedAsComponent ? 'secondary' : 'primary'}
						className={styles.publishButton}
						onClick={onDoneEditingPricingModel}
						disabled={!pricingModel.tabs.length || !product.id}
					>
						{currentLocation?.pricingModelId ? t('updatePricing') : t('publish')}
					</Button>
				</Tooltip>

				<MenuList
					isMenuOpen={isOptionsMenuOpen}
					openMenu={() => setIsOptionsMenuOpen(false)}
					optionsList={menuOptions(
						t,
						dispatch,
						pricingModel,
						() => pricingModel?.id && getPricingModel(pricingModel?.id),
						() => navigate('../pricing-models'),
					)}
				>
					<Button type='outlined' color='neutral' onClick={() => setIsOptionsMenuOpen(!isOptionsMenuOpen)} className={styles.hamburgerButton}>
						<Icon imgType='hamburger' color='neutral700' />
					</Button>
				</MenuList>
			</div>
		</header>
	);
};
