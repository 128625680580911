import { useTranslation } from 'react-i18next';
import { Button, ButtonStatusColor, Icon, Modal } from '../_uiComponents';
import styles from './ActionModalModal.module.scss';

interface ActionModalModalProps {
	isOpen: boolean;
	deleteMessage?: React.ReactNode;
	imagesOptions: string;
	title?: string;
	rightButton: { title: string; color: ButtonStatusColor };
	onAccept: () => void;
	onClose: () => void;
}

export const ActionModalModal = ({ isOpen, deleteMessage, imagesOptions, title, rightButton, onAccept, onClose }: ActionModalModalProps) => {
	const { t } = useTranslation('translation');

	return (
		<Modal isOpen={isOpen} closeModal={onClose} className={styles.container}>
			<div className={styles.closeButton}>
				<Button type='link' color='neutral' onClick={onClose}>
					<Icon imgType='x_icon' color='neutral700' className={styles.x_icon} />
				</Button>
			</div>

			<div className={styles.body}>
				<img src={imagesOptions} className={styles.image} />
				<h1 className={styles.title}>{t(title || 'areYouSure')}</h1>

				<div className={styles.messageContainer}>
					<span className={styles.message}>{deleteMessage}</span>
				</div>

				<div className={styles.buttonsContainer}>
					<Button type='outlined' color='neutral' onClick={onClose} className={styles.button}>
						{t('cancel')}
					</Button>
					<Button color={rightButton.color} onClick={onAccept} className={styles.button}>
						{t(rightButton.title)}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
