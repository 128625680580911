import { useTranslation } from 'react-i18next';
import { CellProps } from '../../TableRow.utils';
import { tableInputNumberWithCommas } from '../../../../../utils/NumberUtils';
import { emptyDiamond } from '../../../../../constants/unicodes';
import styles from './UsageQuantityCell.module.scss';

export const UsageQuantityCell = ({ rowData, column }: CellProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.container}>
			<div className={styles.numberText}>
				<div className={styles.valueContainer}>
					<span className={styles.diamond}>{emptyDiamond} </span>
					{typeof Number(rowData.cells[column.id].cellValue) == 'number'
						? tableInputNumberWithCommas(Number(rowData.cells[column.id].cellValue))
						: rowData.cells[column.id].cellValue}
				</div>

				<span className={styles.label}>{t('dueUponUsage')}</span>
			</div>
		</div>
	);
};
