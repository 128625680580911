import { DEFAULT_CURRENCY, convertCurrencyToSign, PricingType } from '@received/pricing-model';
import { PricingModelTab } from '../../../types/contractTypes';
import { CreditNote } from '../../../types/creditNoteTypes';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import { DatePickerFormatType } from '../../_uiComponents';
import { InvoicesSideBarTab, UsageTab, UsageTabProps } from '../components';
import { FooterItem } from '../components/SideBarFooter/SideBarFooter.utils';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { CreditNoteOverview } from './CreditNoteOverview/CreditNoteOverview';
import styles from './CreditNoteSidebar.module.scss';

export interface CreditNotesTabsProps {
	creditNoteData: CreditNote;
	selectedRowId?: string;
	hasUsageReport: boolean;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	onPressUpdateUsage?: (timePeriod?: DatePickerFormatType) => void;
}

export const creditNotesTabs = (creditNote?: CreditNote) => {
	let tabs = [{ title: 'Overview', component: (prop?: any) => <CreditNoteOverview {...prop} /> }];
	if (!creditNote?.contractId) {
		tabs = [...tabs, { title: 'Invoices', component: (prop?: any) => <CreditNoteSideBarInvoices {...prop} /> }];
	}
	if (creditNote?.pricingModel?.tabs?.some((tab: PricingModelTab) => tab.pricingType === PricingType.USAGE)) {
		tabs = [...tabs, { title: 'Usage', component: (prop: UsageTabProps) => <UsageTab {...prop} /> }];
	}
	return tabs;
};

export const CreditNoteSideBarInvoices = ({ creditNoteData, selectedRowId, openOtherSidebar }: CreditNotesTabsProps) => {
	return (
		<InvoicesSideBarTab
			creditNoteId={creditNoteData?.id}
			selectedRowId={selectedRowId}
			onRowPress={(id?: string) => openOtherSidebar({ sidebarType: SidebarType.INVOICE, triggeredBy: SidebarType.CREDIT_NOTE, id })}
			className={styles.invoiceTabContainer}
		/>
	);
};

export const setFooterData = (creditNote: CreditNote): FooterItem[] => {
	const currency = convertCurrencyToSign(creditNote?.pricingModel?.tabs[0]?.currency || DEFAULT_CURRENCY);
	const list: FooterItem[] = [
		{
			title: 'subtotal',
			value: getValueWithCurrencySign(creditNote?.subTotal, currency),
		},
		{
			title: 'tax',
			value: getValueWithCurrencySign(creditNote?.totalTax, currency),
		},
		{
			title: 'totalCredits',
			value: getValueWithCurrencySign(creditNote?.total, currency),
		},
	];
	if (!creditNote?.contractId) {
		list.push(
			{
				title: 'paidCredits',
				value: `(${getValueWithCurrencySign(creditNote?.total ? creditNote?.total - (creditNote?.balance || 0) : 0, currency)})`,
				isHidden: !creditNote?.invoiceCredits?.length || !creditNote?.balance,
			},
			{
				title: 'creditBalance',
				value: getValueWithCurrencySign(creditNote?.balance, currency),
				isBlack: true,
				isHidden: !creditNote?.invoiceCredits?.length || !creditNote?.balance,
			},
		);
	}
	return list;
};
