import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IntegrationStatus } from '@received/pricing-model';
import { images } from '../../../constants/images';
import { Button, Icon } from '../../../components';
import { Store } from '../../../types/storeTypes';
import { PermissionsMapping } from '../../../services/PermissionsMapping/PermissionsMapping';
import { convertIntegrationTypeToStringService } from '../../../utils/IntegrationUtils';
import { CompanyTabs, Sections } from '../../Settings/Settings.utils';
import styles from './EmptyIntegrations.module.scss';

interface EmptyIntegrationsProps {
	className?: string;
}
export const EmptyIntegrations = ({ className }: EmptyIntegrationsProps) => {
	const { t } = useTranslation('translation');
	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);

	const linkedIntegration = availableIntegration.filter((item) => {
		const connectedCount = item?.connectedCount ? +item?.connectedCount : 0;
		return item.connectionStatus === IntegrationStatus.LINKED || !!connectedCount;
	});

	const navigate = useNavigate();

	return (
		<div className={clsx(styles.container, className)}>
			<div className={styles.header}>
				<div className={styles.fontSize20}>{t('integratedYourTools')}</div>

				{!!linkedIntegration.length && (
					<Button
						onClick={() => navigate('/settings', { state: { activeSection: Sections.COMPANY, activeButton: CompanyTabs.INTEGRATIONS } })}
						type='outlined'
						color='neutral'
						className={styles.addIntegrationButton}
					>
						<Icon imgType='add' color='neutral700' />
					</Button>
				)}
			</div>

			<div className={styles.itemsContainer}>
				{linkedIntegration.length ? (
					linkedIntegration.map((item, index) => (
						<PermissionsMapping key={index} actionType={item.platform}>
							<Button
								type='link'
								color='neutral'
								className={styles.iconContainer}
								onClick={() =>
									navigate('/settings', {
										state: { activeSection: Sections.COMPANY, activeButton: CompanyTabs.INTEGRATIONS, chosenPlatform: item.platform },
									})
								}
							>
								<div className={styles.cardIconHeight}>
									<img alt='cardIcon' src={images[item.iconSrc]} className={styles.cardIcon} />
								</div>
								<div className={styles.fontSize12}>{t(convertIntegrationTypeToStringService(item.platform))}</div>
								<div className={styles.connected}>{t('connected')}</div>
							</Button>
						</PermissionsMapping>
					))
				) : (
					<div className={styles.emptyItemsContainer}>
						<img src={images.emptyIntegrations} className={styles.image} />
						<div className={styles.fontWeight650}>{t('noConnectionsYet')}</div>

						<Button
							className={styles.button}
							onClick={() => navigate('/settings', { state: { activeSection: Sections.COMPANY, activeButton: CompanyTabs.INTEGRATIONS } })}
						>
							{t('addFirstConnection')}
							<Icon imgType='add' color='white' height={1.2} />
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
