import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { UsageProduct } from '@received/pricing-model';
import { setHideLoader, setShowLoader, setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { PathsConfig, httpService } from '../../../services';
import { UploadLogsSteps, UsageLogsFormat, initialFormat, onAddNewFormat } from './ImportUsageLogs.utils';
import { LeftContainer } from './LeftContainer/LeftContainer';
import { RightContainer } from './RightContainer/RightContainer';
import { CSVToArray } from '../../../utils/GeneralUtils';
import { UsageLogsSummaries, UsageReportView, ValidationWarnings } from '../../../types/usageTypes';
import { LogsTableModal } from './LogsTableModal/LogsTableModal';
import { useDebounce } from '../../../hooks';
import styles from './ImportUsageLogs.module.scss';

export const ImportUsageLogs = () => {
	const [file, setFile] = useState<{ file: File; uploadId: string }>();
	const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
	const [analytics, setAnalytics] = useState<UsageLogsSummaries>();
	const [logsFormat, setLogsFormat] = useState<UsageLogsFormat>({ ...initialFormat });
	const [uploadSteps, setUploadSteps] = useState(UploadLogsSteps.UPLOAD_CSV_AND_MAPPER);
	const [showReportsTableModal, setShowReportsTableModal] = useState(false);
	const [selectedReportView, setSelectedReportView] = useState<UsageReportView[]>();
	const [selectedReportIndex, setSelectedReportIndex] = useState<number>();
	const [haveErrors, setHaveErrors] = useState(false);
	const [pdfPreview, setPdfPreview] = useState<{ usageProduct: UsageProduct; validationWarnings: ValidationWarnings } | 'LOADING'>();
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [errorFromServer, setErrorFromServer] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const updateReportTimer = useRef<NodeJS.Timeout>(null);

	useEffect(() => {
		dispatch(setSidebarIsOpen(false));
	}, []);

	const onPressSubmit = (logsFormat: UsageLogsFormat) => {
		switch (uploadSteps) {
			case UploadLogsSteps.REFRESH_CSV_AND_MAPPER:
			case UploadLogsSteps.UPLOAD_CSV_AND_MAPPER: {
				return onUploadMatcher(logsFormat);
			}
			case UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW:
				return onImportUsageReports();
		}
	};

	const getPdfPreview = async (logsFormat: UsageLogsFormat, uploadFileId?: string) => {
		try {
			setPdfPreview('LOADING');
			const newFormat = await onAddNewFormat(logsFormat, dispatch, false);
			setLogsFormat((prev) => ({ ...prev, ...newFormat }));

			const res = await (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.getUsageLogPdfPreview,
					data: { objectMapperId: newFormat.id, csvDataId: uploadFileId || file?.uploadId },
				})
			).data;

			setPdfPreview(res);
			setErrorFromServer('');
		} catch (error: any) {
			if (error?.response?.data?.errorText) {
				setErrorFromServer(error?.response?.data?.errorText);
			}
			setPdfPreview(undefined);
		}
	};

	const onUploadMatcher = async (logsFormat: UsageLogsFormat) => {
		const loaderId = uuidV4();

		try {
			dispatch(setShowLoader(loaderId));
			setDisableSubmit(true);
			const newFormat = await onAddNewFormat(logsFormat, dispatch, false);

			const summaries = await (
				await httpService({
					dispatch,
					showLoader: false,
					path: PathsConfig.uploadUsageLogsFileAndMatcher,
					data: { objectMapperId: newFormat.id, csvDataId: file?.uploadId },
				})
			).data;

			setUploadSteps(UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW);
			setAnalytics(summaries);
			setPdfPreview(undefined);

			setTimeout(() => {
				dispatch(setHideLoader(loaderId));
				setDisableSubmit(false);
			}, 1000);
		} catch (error) {
			dispatch(setHideLoader(loaderId));
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
			setUploadSteps(UploadLogsSteps.REFRESH_CSV_AND_MAPPER);
			setDisableSubmit(false);
		}
	};

	const onImportUsageReports = async () => {
		try {
			httpService({
				dispatch,
				path: PathsConfig.saveUsageLogs,
				data: { usageLogsRUid: analytics?.usageLogsRUid, usageReportRUid: analytics?.usageReportRUid },
			});

			navigate('/usage');
		} catch (error) {
			setDisableSubmit(false);
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const onUploadFile = (file?: { file: File; uploadId: string }) => {
		setFile(file);
		setAnalytics(undefined);
		setPdfPreview(undefined);
		if (file) {
			const fileReader = new FileReader();

			fileReader.onload = async (event) => {
				const text = event?.target?.result as string;
				const csvData = await CSVToArray(text);
				setCsvHeaders(csvData.length ? csvData[0] : []);
			};

			fileReader.readAsText(file?.file);

			onPreviewFromServer(logsFormat, file.uploadId);
		} else {
			setCsvHeaders([]);
			setUploadSteps(UploadLogsSteps.UPLOAD_CSV_AND_MAPPER);
		}
	};

	const onOpenModalView = (usageReportView: UsageReportView[], reportIndex: number) => {
		setSelectedReportView(usageReportView);
		setSelectedReportIndex(reportIndex);
		setShowReportsTableModal(true);
	};

	const onUpdateLogsFormat = (format: UsageLogsFormat) => {
		setLogsFormat((prev) => ({ ...prev, ...format }));
		if (uploadSteps === UploadLogsSteps.UPLOAD_CSV_AND_MAPPER && file) {
			onPreviewFromServer(format, file?.uploadId);
		}
	};

	const onPreviewFromServer = useDebounce(getPdfPreview, 2000, updateReportTimer);

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.container}>
				<LeftContainer
					disableSubmit={disableSubmit}
					csvHeaders={csvHeaders}
					uploadSteps={uploadSteps}
					logsFormat={logsFormat}
					reportsCount={analytics?.totalSummary.usageReports}
					errorFromServer={errorFromServer}
					onUpdateLogsFormat={onUpdateLogsFormat}
					onImportUsageReports={onPressSubmit}
					setUploadSteps={setUploadSteps}
					setHaveErrors={(er) => setHaveErrors(er)}
				/>

				<RightContainer
					pdfPreview={pdfPreview}
					uploadSteps={uploadSteps}
					analytics={analytics}
					totalSummary={analytics?.totalSummary}
					file={file?.file}
					selectedReportIndex={selectedReportIndex}
					haveErrors={haveErrors}
					onUploadFile={onUploadFile}
					onOpenModalView={onOpenModalView}
					refreshSummaries={() => {
						setUploadSteps(UploadLogsSteps.IMPORT_REPORTS_AFTER_REVIEW);
						onUploadMatcher(logsFormat);
					}}
				/>

				<LogsTableModal
					isOpen={showReportsTableModal}
					usageReportView={selectedReportView}
					onClose={() => {
						setSelectedReportIndex(undefined);
						setShowReportsTableModal(false);
						setSelectedReportView(undefined);
					}}
				/>
			</div>
		</div>
	);
};
