import dayjs from 'dayjs';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { TimeUnit } from '../types/generalTypes';

export const getDatePostfix = (day: number) => {
	if (day > 3 && day < 21) return 'th';
	switch (day % 10) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};

export const cashTimeUnitHandler = (fromDate: Date | string, toDate: Date | string) => {
	const dayDif = dayjs(fromDate).diff(toDate, 'day');
	return Math.abs(dayDif) > 31 ? TimeUnit.MONTH : TimeUnit.DAY;
};

export const dateFormattingForServer = (date?: Date | null | string) => (isDateValid(date) ? dayjs(date).format(DATE_FORMAT_FOR_SERVER) : undefined);

export const isDateValid = (date?: Date | null | string) => dayjs(date).format(DATE_FORMAT_FOR_SERVER) != 'Invalid Date';
