import { Login } from '../pages/Login/Login';
import { RoutesType } from '../types/generalTypes';

export const publicRoutes: RoutesType[] = [
	{
		name: 'login',
		path: '*',
		showOnSidebar: false,
		element: <Login />,
	},
];
