/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatch } from '@reduxjs/toolkit';
import {
	PricingModelTableRow,
	CellTypes,
	ItemPriceDto,
	ItemDto,
	convertCurrencyToSign,
	DEFAULT_CURRENCY,
	PricingType,
	BillingCycleUnit,
	PricingCycleUnit,
} from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import { PricingModelTableColumns } from '../../../types/pricingModelTypes';
import { PricingModelTableClassNames, SelectedCell, SelectedCellWithIndex } from '../PricingModelTable';
import { PricingModelTableViewTypeOptions, requiredColumnsEnum } from '../PricingModelTable.utils';
import { FormulaCell, NumberCell, PercentageCell, ProductCell, UsageCell, UsageQuantityCell } from './Cells';
import { TextCell } from './Cells/TextCell/TextCell';
import { PathsConfig, httpService } from '../../../services';
import { EMPTY_ITEM_ID } from '../../../constants/templateConstants';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { PricingModelTab } from '../../../types/contractTypes';
import { tableInputNumberWithCommas } from '../../../utils/NumberUtils';
import { DEFAULT_BILLING_CYCLE_VALUE } from '../../../constants/generalConstants';
import styles from './TableRow.module.scss';

export interface CellProps {
	rowIndex: number;
	rowData: PricingModelTableRow;
	column: PricingModelTableColumns;
	selectedCell?: SelectedCellWithIndex;
	columnIndex: number;
	classNames?: PricingModelTableClassNames;
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	hoverOnFormula: boolean;
	columnsToColor: { [id: string]: string };
	productItems: ItemPriceDto[];
	itemsList: ItemDto[];
	pricingModelTab?: PricingModelTab;
	prefixSign?: string;
	openUsageSideBar(column: PricingModelTableColumns, columnIndex: number, rowIndex: number): void;
	setHoverOnFormula(isHover: boolean): void;
	onCellSelected(selectedCell: SelectedCell | undefined): void;
	updateTableData(rowData: PricingModelTableRow): void;
	addManualUsageReport(): void;
	onArrowNavigation(nextCell: { rowIndex: number; columnIndex: number }): void;
	refreshData(): void;
}

export const cellOptionsSelector = (props: CellProps) => {
	if (Object.keys(props.rowData.cells).length === 0) return;
	const cellType = props.rowData.cells[props.column.id].cellType;
	switch (cellType) {
		case CellTypes.ITEM_REFERENCE:
			return <ProductCell {...props} />;
		case CellTypes.NUMBER: //Price cell
			return <NumberCell prefixSign={convertCurrencyToSign(props.pricingModelTab?.currency || DEFAULT_CURRENCY)} {...props} />;
		case CellTypes.COUNT:
			return <NumberCell {...props} />;
		case CellTypes.FORMULA:
			return <FormulaCell prefixSign={convertCurrencyToSign(props.pricingModelTab?.currency || DEFAULT_CURRENCY)} {...props} />;
		case CellTypes.PERCENTAGE:
			return <PercentageCell {...props} />;
		case CellTypes.USAGE_AGGREGATOR_REFERENCE:
			return <UsageCell {...props} />;
		case CellTypes.USAGE_QTY_AGGREGATOR:
			return <UsageQuantityCell {...props} />;
		case CellTypes.TEXT:
			return <TextCell {...props} />;
	}
};

export const billingCycleShortLabelsByEnum = {
	[BillingCycleUnit.ONE_TIME]: 'one time',
	[BillingCycleUnit.WEEKLY]: 'Wkly.',
	[BillingCycleUnit.MONTHLY]: 'mo.',
	[BillingCycleUnit.QUARTERLY]: 'qtr.',
	[BillingCycleUnit.YEARLY]: 'yr.',
	[BillingCycleUnit.CUSTOM]: '',
};

export const customPeriodByEnum = {
	[PricingCycleUnit.PER_UNIT]: 'Per Unit',
	[BillingCycleUnit.ONE_TIME]: 'one time',
	[BillingCycleUnit.WEEKLY]: 'weeks',
	[BillingCycleUnit.MONTHLY]: 'months',
	[BillingCycleUnit.QUARTERLY]: 'quarters',
	[BillingCycleUnit.YEARLY]: 'years',
	[BillingCycleUnit.CUSTOM]: '',
};

export const periodByEnum = {
	[PricingCycleUnit.PER_UNIT]: 'Per Unit',
	[BillingCycleUnit.ONE_TIME]: 'one time',
	[BillingCycleUnit.WEEKLY]: 'week',
	[BillingCycleUnit.MONTHLY]: 'month',
	[BillingCycleUnit.QUARTERLY]: 'quarter',
	[BillingCycleUnit.YEARLY]: 'year',
	[BillingCycleUnit.CUSTOM]: '',
};

export const BillingCycleLabel = ({
	column,
	pricingModelTab,
	value,
	prefixSign,
}: {
	column: PricingModelTableColumns;
	pricingModelTab?: PricingModelTab;
	value: number | string;
	prefixSign?: string;
}) => {
	// @ts-ignore
	const val = `${prefixSign || ''} ${value && typeof Number(value) ? tableInputNumberWithCommas(+value) : value}`;

	if (column.id === pricingModelTab?.pricingCycleActivation?.toLocaleLowerCase()) {
		const cycleNumber = ` / ${pricingModelTab?.billingCycle?.cycle > 1 ? pricingModelTab?.billingCycle?.cycle : ''} `;
		return (
			<div
				data-testid={`billing-cycle-${column.title}-value-${cycleNumber}-cycle${billingCycleShortLabelsByEnum[pricingModelTab?.billingCycle?.unit]}`}
			>
				{val}
				{pricingModelTab?.pricingType != PricingType.USAGE && pricingModelTab.billingCycle?.unit != BillingCycleUnit.ONE_TIME && (
					<span className={styles.billingCycle}>
						{cycleNumber}
						{billingCycleShortLabelsByEnum[pricingModelTab?.billingCycle?.unit]}
					</span>
				)}
			</div>
		);
	}
	return <span>{val}</span>;
};

export const PricingCycleLabel = ({
	column,
	pricingModelTab,
	value,
	prefixSign,
}: {
	column: PricingModelTableColumns;
	pricingModelTab?: PricingModelTab;
	value: number | string;
	prefixSign?: string;
}) => {
	const { t } = useTranslation('translation');
	if (
		column.id === pricingModelTab?.pricingCycleActivation?.toLocaleLowerCase()
			? pricingModelTab?.pricingCycle
				? // @ts-ignore pricingCycle and billingCycle enums
				  pricingModelTab?.pricingCycle?.unit != pricingModelTab?.billingCycle?.unit
					? true
					: pricingModelTab?.pricingCycle?.cycle !== pricingModelTab?.billingCycle?.cycle
				: false
			: false
	) {
		const isCustom = (pricingModelTab?.pricingCycle?.cycle || pricingModelTab?.billingCycle?.cycle || DEFAULT_BILLING_CYCLE_VALUE) > 1;
		const cycleNumber = ` / ${
			isCustom
				? pricingModelTab?.pricingCycle?.cycle || pricingModelTab?.billingCycle?.cycle
				: `${pricingModelTab?.billingCycle?.unit != BillingCycleUnit.ONE_TIME && t('per')}`
		}`;

		const cycleUnit = pricingModelTab?.pricingCycle?.unit || pricingModelTab?.billingCycle?.unit || BillingCycleUnit.CUSTOM;
		return (
			<div
				className={styles.pricingCycle}
				data-testid={`pricing-cycle${column.title}-value-${cycleNumber}-cycle${isCustom ? customPeriodByEnum[cycleUnit] : periodByEnum[cycleUnit]}`}
			>
				{prefixSign || ''} {value && typeof Number(value) ? tableInputNumberWithCommas(+value) : value} {cycleNumber}{' '}
				{isCustom ? customPeriodByEnum[cycleUnit] : periodByEnum[cycleUnit]}
			</div>
		);
	}
	return null;
};

export const handleCellKeyDown = (
	event: any,
	rowIndex: number,
	columnIndex: number,
	onArrowNavigation: (nextCell: { rowIndex: number; columnIndex: number }) => void,
	onStartEditCell: (selectedCell: SelectedCell | undefined) => void,
	column?: PricingModelTableColumns,
) => {
	// TODO return when arrow navigation fixed
	switch (event.keyCode) {
		case 37: {
			//key left
			if (event.currentTarget.selectionStart === 0 || column?.id === requiredColumnsEnum.amount) {
				event.preventDefault();
				return onArrowNavigation({ rowIndex, columnIndex: columnIndex - 1 });
			}
			break;
		}
		case 38: {
			//key up
			event.preventDefault();
			onArrowNavigation({ rowIndex: rowIndex - 1, columnIndex });
			break;
		}
		case 39: {
			//key right
			if (event.currentTarget.value.length === event.currentTarget.selectionEnd || column?.id === requiredColumnsEnum.amount) {
				event.preventDefault();
				return onArrowNavigation({ rowIndex, columnIndex: columnIndex + 1 });
			}
			break;
		}
		case 40: {
			//key down
			if (column?.id != requiredColumnsEnum.item) {
				event.preventDefault();
				onArrowNavigation({ rowIndex: rowIndex + 1, columnIndex });
			}

			break;
		}
		case 13: {
			//key enter
			event.preventDefault();
			onStartEditCell(undefined);
			break;
		}
		case 9: {
			//key tab
			event.preventDefault();
			onArrowNavigation({ rowIndex, columnIndex: columnIndex + 1 });

			break;
		}
	}
};

export const canHideInInvoice = (row: PricingModelTableRow, pricingModelTableViewType?: PricingModelTableViewTypeOptions) => {
	return (
		(pricingModelTableViewType == PricingModelTableViewTypeOptions.INVOICE_PREVIEW ||
			pricingModelTableViewType == PricingModelTableViewTypeOptions.PRICING_MODEL ||
			pricingModelTableViewType == PricingModelTableViewTypeOptions.CONTRACT) &&
		+row.cells['amount'].cellValue == 0
	);
};

export const checkExistingOrCreateItem = async (itemsList: ItemDto[], text: string, dispatch: Dispatch) => {
	try {
		if (text) {
			if (!itemsList.some((item) => item.name === text)) {
				const res = await httpService({
					dispatch,
					path: PathsConfig.createItem,
					data: { name: text },
				});
				return res.data;
			}
		} else {
			return { name: '', id: EMPTY_ITEM_ID };
		}
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const calculateValueByCycle = (value: number | string, pricingModelTab: PricingModelTab | undefined, isCalculatePricing?: boolean) => {
	if (!(value && typeof Number(value))) {
		return value;
	}
	const weeksInMonth = 4;
	const weeksInYear = 52;
	const weeksInQuarter = 13;
	const quarterInYear = 4;
	const monthInQuarter = 3;
	const monthInYear = 12;

	const pricingCycle = pricingModelTab?.pricingCycle;
	const billingCycle = pricingModelTab?.billingCycle;
	const billingCycleValue = billingCycle?.cycle || DEFAULT_BILLING_CYCLE_VALUE;
	const pricingCycleValue = pricingCycle?.cycle || DEFAULT_BILLING_CYCLE_VALUE;

	const cycleUnit = { ...PricingCycleUnit, ...BillingCycleUnit };

	const valueCycle = isCalculatePricing ? billingCycleValue : pricingCycleValue;
	const requiredCycle = isCalculatePricing ? pricingCycleValue : billingCycleValue;

	const oneToOneCalculation = (+value / valueCycle) * requiredCycle;

	const calculationMapping = {
		[cycleUnit.PER_UNIT]: {
			[cycleUnit.PER_UNIT]: value,
			[cycleUnit.ONE_TIME]: value,
			[cycleUnit.WEEKLY]: value,
			[cycleUnit.MONTHLY]: value,
			[cycleUnit.QUARTERLY]: value,
			[cycleUnit.YEARLY]: value,
		},
		[cycleUnit.ONE_TIME]: {
			[cycleUnit.PER_UNIT]: value,
			[cycleUnit.ONE_TIME]: value,
			[cycleUnit.WEEKLY]: value,
			[cycleUnit.MONTHLY]: value,
			[cycleUnit.QUARTERLY]: value,
			[cycleUnit.YEARLY]: value,
		},
		[cycleUnit.WEEKLY]: {
			[cycleUnit.WEEKLY]: oneToOneCalculation,
			[cycleUnit.MONTHLY]: (+value / valueCycle / weeksInMonth) * requiredCycle,
			[cycleUnit.QUARTERLY]: (+value / valueCycle / weeksInQuarter) * requiredCycle,
			[cycleUnit.YEARLY]: (+value / valueCycle / weeksInYear) * requiredCycle,
		},
		[cycleUnit.MONTHLY]: {
			[cycleUnit.WEEKLY]: (+value / valueCycle) * weeksInMonth * requiredCycle,
			[cycleUnit.MONTHLY]: oneToOneCalculation,
			[cycleUnit.QUARTERLY]: (+value / valueCycle / monthInQuarter) * requiredCycle,
			[cycleUnit.YEARLY]: (+value / valueCycle / monthInYear) * requiredCycle,
		},
		[cycleUnit.QUARTERLY]: {
			[cycleUnit.WEEKLY]: (+value / valueCycle) * weeksInQuarter * requiredCycle,
			[cycleUnit.MONTHLY]: (+value / valueCycle) * monthInQuarter * requiredCycle,
			[cycleUnit.QUARTERLY]: oneToOneCalculation,
			[cycleUnit.YEARLY]: (+value / valueCycle / quarterInYear) * requiredCycle,
		},
		[cycleUnit.YEARLY]: {
			[cycleUnit.WEEKLY]: (+value / valueCycle) * weeksInYear * requiredCycle,
			[cycleUnit.MONTHLY]: (+value / valueCycle) * monthInYear * requiredCycle,
			[cycleUnit.QUARTERLY]: (+value / valueCycle) * quarterInYear * requiredCycle,
			[cycleUnit.YEARLY]: oneToOneCalculation,
		},
	};

	return (
		(isCalculatePricing
			? // @ts-ignore
			  pricingCycle && calculationMapping[pricingCycle?.unit][billingCycle?.unit]
			: // @ts-ignore
			  billingCycle && calculationMapping[billingCycle?.unit][pricingCycle?.unit || billingCycle?.unit]) || value
	);
};
