import React, { ReactNode } from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import styles from './Modal.module.scss';

export interface ModalProps {
	isOpen: boolean;
	className?: string;
	children?: ReactNode;
	shouldCloseOnClickOutside?: boolean;
	overlayClassName?: string;
	dataTestId?: string;
	closeModal?: () => void;
}

export const Modal = ({ isOpen, children, className, overlayClassName, dataTestId, closeModal, shouldCloseOnClickOutside = true }: ModalProps) => {
	const handleClick = (e: any) => {
		e.stopPropagation();
		if (shouldCloseOnClickOutside && closeModal) {
			closeModal();
		}
	};

	return isOpen
		? ReactDOM.createPortal(
				<>
					<div
						tabIndex={50}
						onClick={handleClick}
						onKeyDown={(e) => e.key === 'Enter' && closeModal?.()}
						className={clsx(styles.modalOverlay, overlayClassName)}
					/>
					<div className={clsx(styles.modalContainer, className)} data-testid={`${dataTestId}-modal`}>
						{children}
					</div>
				</>,
				document.body,
		  )
		: null;
};
