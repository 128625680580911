import { AnalyticsTab, CreditsNotesTab, InvoicesTab, PartnerOverviewTab, ResellerAgreementsTab, RevRecTab } from '../../../components';
import { NodeEnvTypes } from '../../../types/generalTypes';

export enum SinglePartnerPageTabIndex {
	overview,
	ResellerAgreements,
	invoices,
	credits,
	analytics,
	revenueRecognition,
}

export const singlePartnerPageTabs = () => {
	const tabs = [
		{ title: 'overview', component: (prop?: any) => <PartnerOverviewTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.overview },
		{ title: 'agreements', component: (prop?: any) => <ResellerAgreementsTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.ResellerAgreements },

		{ title: 'invoices', component: (prop?: any) => <InvoicesTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.invoices },
		{ title: 'credits', component: (prop?: any) => <CreditsNotesTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.credits },
		{ title: 'analytics', component: (prop?: any) => <AnalyticsTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.analytics },
		{ title: 'revenueRecognition', component: (prop?: any) => <RevRecTab {...prop} />, tabIndex: SinglePartnerPageTabIndex.revenueRecognition },
	];

	return tabs;
};

export const isTabHasTimeFilters = (tabIndex: number) => {
	switch (tabIndex) {
		case SinglePartnerPageTabIndex.overview:
		case SinglePartnerPageTabIndex.invoices:
		case SinglePartnerPageTabIndex.credits:
			return true;
		default:
			return false;
	}
};
