import { useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import { Icon, MenuList, MenuOption } from '..';
import { PricingModel } from '../../../types/contractTypes';
import { CatalogCardType, getStyleByCardType, onDeletePricingModel, onDuplicatePricingModel, onUpdateFavorite } from './CatalogCard.utils';
import { TIMESTAMP } from '../../../constants/templateConstants';
import styles from './CatalogCard.module.scss';

export interface CatalogCardProps {
	pricingModel?: PricingModel;
	className?: string;
	disableEdit?: boolean;
	favoriteButtonHidden?: boolean;
	lastEditTimeHidden?: boolean;
	type: CatalogCardType;
	onPress?: () => void;
	onRefresh?: () => void;
	onEditPricingModel?: (state: any) => void;
}

export const CatalogCard = ({
	lastEditTimeHidden,
	pricingModel,
	disableEdit,
	className,
	favoriteButtonHidden,
	type,
	onPress,
	onRefresh,
	onEditPricingModel,
}: CatalogCardProps) => {
	const { t } = useTranslation('translation');
	const [openOptionsMenu, setOpenOptionsMenu] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const menuOptions = (): MenuOption[] => {
		let options: MenuOption[] = [
			{
				title: pricingModel?.isFavorite ? t('unfavoriteTemplate') : t('favoriteTemplate'),
				onPress: () => onUpdateFavorite(dispatch, pricingModel, onRefresh),
				iconType: 'emptyStar',
			},
			{
				title: t('Duplicate'),
				onPress: () => onDuplicatePricingModel(dispatch, pricingModel, onRefresh),
				iconType: 'duplicate',
				hasBottomBorder: true,
			},
		];
		if (pricingModel?.product) {
			options = [
				...options,
				{
					title: t('editPricingModel'),
					onPress: () => {
						const state = { pricingModelId: pricingModel?.id, productId: pricingModel?.product?.id, productName: pricingModel?.product?.name };
						onEditPricingModel
							? onEditPricingModel(state)
							: navigate('/pricing-models/new-pricing-model', {
									state,
							  });
					},
					iconType: 'edit',
				},
				{
					title: t('removeTemplate'),
					onPress: () => onDeletePricingModel(dispatch, pricingModel, onRefresh),
					iconType: 'delete',
					color: 'destructive',
				},
			];
		}
		return options;
	};

	return (
		<div
			data-testid={`catalog-card-${pricingModel?.name}`}
			className={clsx(styles.container, className, !lastEditTimeHidden ? styles.higher : '')}
			onClick={(e) => {
				onPress && onPress();
				e.stopPropagation();
			}}
		>
			<div className={clsx(styles.topContainer, getStyleByCardType(type)?.topContainerStyle)}>
				<Button
					type='link'
					onClick={() => !disableEdit && onUpdateFavorite(dispatch, pricingModel, onRefresh)}
					className={clsx(styles.favoriteButton, favoriteButtonHidden ? styles.hide : '')}
					style={{ pointerEvents: disableEdit ? 'none' : 'auto' }}
				>
					<Icon imgType={pricingModel?.isFavorite ? 'star' : 'emptyStar'} color='black' height={pricingModel?.isFavorite ? 2.2 : 1.9} />
				</Button>

				<img src={getStyleByCardType(type)?.src} className={styles.icon} />

				{!disableEdit && pricingModel ? (
					<MenuList optionsList={menuOptions()} isMenuOpen={openOptionsMenu} openMenu={() => setOpenOptionsMenu(false)}>
						<Button
							type='link'
							dataTestId={`catalog-card-hamburger-${pricingModel?.name}`}
							onClick={() => setOpenOptionsMenu(true)}
							className={styles.hamburgerButton}
						>
							<Icon imgType='hamburger' className={styles.hamburgerIcon} />
						</Button>
					</MenuList>
				) : (
					<div className={styles.hamburgerButton} />
				)}
			</div>

			<div className={styles.dataContainer}>
				<div>
					<div className={styles.productTitle}>
						{pricingModel?.product?.name || (pricingModel?.categories && pricingModel?.categories[0]?.name) || ''}
					</div>
					<div className={styles.title}>{pricingModel?.name || ''}</div>
				</div>

				<div className={lastEditTimeHidden ? styles.hide : ''}>
					<div className={styles.lastChange}>{t('lastChange')}</div>
					<div className={styles.lastChange}>{dayjs(pricingModel?.updatedAt).format(TIMESTAMP)}</div>
				</div>
			</div>
		</div>
	);
};
