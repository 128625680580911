import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DocumentState } from '@received/pricing-model';
import { SearchBar, Select } from '../../..';
import { successErrorMassageOptions } from '../../../SuccessErrorModal/SuccessErrorModal.utils';
import { useDebounce } from '../../../../hooks/generalHooks';
import { PathsConfig } from '../../../../services/httpService/configPaths';
import { httpService } from '../../../../services/httpService/httpService';
import { Contract } from '../../../../types/contractTypes';
import { Invoice, invoicesFilterOptions, InvoiceView } from '../../../../types/invoiceTypes';
import { InvoiceResponse } from '../../../../pages/_Billing/Invoices/AllInvoices/AllInvoices.utils';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { invoiceFilterSelect } from '../../../../constants/generalConstants';
import { EmptyState } from '..';
import { InvoiceListItem } from './InvoiceListItem/InvoiceListItem';
import { getSortingOrderList } from '../../../../utils/InvoiceUtils';
import styles from './InvoicesSideBarTab.module.scss';

interface InvoicesSideBarTabProps {
	contractData?: Contract;
	creditNoteId?: string;
	invoiceData?: Invoice;
	selectedRowId?: string;
	className?: string;
	onRowPress?: (invoiceId?: string) => void;
	getSplittedInvoices?: (search: string, page: number, splittedOnly?: boolean) => any;
	onSideBarRefresh?: () => void;
}

export const InvoicesSideBarTab = ({
	contractData,
	invoiceData,
	creditNoteId,
	selectedRowId,
	className,
	onRowPress,
	getSplittedInvoices,
	onSideBarRefresh,
}: InvoicesSideBarTabProps) => {
	const { t } = useTranslation('translation');
	const [invoicesList, setInvoicesList] = useState<InvoiceView[]>([]);
	const [searchText, setSearchText] = useState('');
	const [pageNumber, setPageNumber] = useState(0);
	const [listCount, setListCount] = useState(0);
	const [isEmpty, setIsEmpty] = useState(false);
	const [selectFilter, setSelectFilter] = useState<invoicesFilterOptions>(invoiceFilterSelect[0].value);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		setInvoiceData();
	}, [contractData, invoiceData, creditNoteId]);

	const setInvoiceData = async (filter: invoicesFilterOptions = selectFilter, search?: string, page?: number) => {
		try {
			if (contractData || creditNoteId) {
				const res: InvoiceResponse = await getInvoicesByFilter(filter, search, page);
				setInvoicesList(res.data || []);
				setIsEmpty(res.data.length === 0);
			}
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_INVOICE_DATA }));
		}
	};

	const getInvoicesByFilter = async (filter: invoicesFilterOptions, search?: string, page?: number) => {
		let invoices: InvoiceResponse;

		switch (filter) {
			case invoicesFilterOptions.CONTRACT:
				invoices = (
					await httpService({
						showLoader: false,
						dispatch,
						path: PathsConfig.getInvoicesViewData,
						params: {
							contractId: contractData?.id,
							creditNoteId,
							pageSize: 20,
							pageNumber: page || pageNumber,
							orderBy: getSortingOrderList('issueDate'),
							order: 'ASC',
							searchText: search || '',
							excludedStates: [DocumentState.CONTRACT_DRAFT],
						},
					})
				).data;
				break;
			case invoicesFilterOptions.SPLITTED_INVOICES:
				invoices = getSplittedInvoices && (await getSplittedInvoices(search || '', page || pageNumber, true));
				break;
			default:
				invoices = (
					await httpService({
						dispatch,
						path: PathsConfig.getInvoicesViewData,
						showLoader: false,
						params: {
							creditNoteId,
							pageSize: 20,
							pageNumber: page || pageNumber,
							orderBy: getSortingOrderList('issueDate'),
							order: 'ASC',
							searchText: search || '',
							excludedStates: [DocumentState.CONTRACT_DRAFT],
						},
					})
				).data;
				break;
		}

		setListCount(invoices.count);
		return invoices;
	};

	const onSearchInvoice = (text: string) => {
		setSearchText(text);
		onSearch(selectFilter, text);
	};

	const onScrolledToBottom = async (event: any) => {
		const bottom = event.target.scrollHeight <= event.target.scrollTop + event.target.clientHeight + 15;

		if (bottom) {
			if ((contractData || creditNoteId) && invoicesList.length < listCount) {
				try {
					setPageNumber((prev) => prev + 1);
					const res: InvoiceResponse = await getInvoicesByFilter(selectFilter, searchText, pageNumber + 1);
					setInvoicesList((prev) => [...prev, ...res.data]);
				} catch (error) {
					dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.ALL_INVOICE_DATA }));
				}
			}
		}
	};

	const onChange = async (selectedFilter: invoicesFilterOptions) => {
		setSelectFilter(selectedFilter);
		selectedFilter != selectFilter && setSearchText('');
		setInvoiceData(selectedFilter, '');
		setPageNumber(0);
	};

	const onSearch = useDebounce(setInvoiceData, 650);

	return (
		<div className={clsx(styles.container, className)}>
			<div className={styles.filtersContainer}>
				<SearchBar
					placeholder={t('search')}
					className={creditNoteId ? styles.searchBarFullWidth : styles.searchBar}
					value={searchText}
					onChange={onSearchInvoice}
				/>
				{!creditNoteId && (
					<Select data={invoiceFilterSelect} onChange={onChange} className={styles.select} width={17.2} defaultValue={selectFilter} />
				)}
			</div>
			{!isEmpty ? (
				<>
					<div className={styles.header}>
						<div className={styles.headerItem}>{t('documentNo')}</div>
						<div className={styles.headerItem}>{t('issueDate')}</div>
						<div className={styles.headerItem}>{t('Total')}</div>
						<div className={styles.headerItem}>{t('status')}</div>
						<div style={{ width: '2rem' }}></div>
					</div>
					<div className={styles.listContainer} onScroll={onScrolledToBottom}>
						{invoicesList.map((invoiceView, index) => (
							<InvoiceListItem
								key={index}
								index={index}
								invoice={invoiceView}
								isInvoiceActive={selectedRowId === invoiceView.id}
								onRowPress={onRowPress}
								onRefreshData={async () => {
									onSideBarRefresh ? await onSideBarRefresh() : await setInvoiceData();
								}}
							/>
						))}
					</div>
				</>
			) : (
				<EmptyState
					title={t('noExistingInvoices')}
					description={t('noExistingInvoicesDescription')}
					buttonText={t('editContract')}
					onEdit={() => contractData?.id && navigate('/contract', { state: { contractId: contractData?.id } })}
				/>
			)}
		</div>
	);
};
