import { useTranslation } from 'react-i18next';
import { SideBarFooter } from '../../components';
import { CreditNotePricingModelDetails } from '../CreditNotePricingModelDetails/CreditNotePricingModelDetails';
import { CreditNotesTabsProps, setFooterData } from '../CreditNoteSidebar.utils';
import styles from './CreditNoteOverview.module.scss';

export const CreditNoteOverview = ({ creditNoteData, hasUsageReport, onPressUpdateUsage }: CreditNotesTabsProps) => {
	const { t } = useTranslation('translation');

	const tabsCount = creditNoteData?.pricingModel?.tabs?.length || 0;

	return (
		<div className={styles.tabsSection}>
			<div className={styles.container}>
				<div className={styles.headerOverviewContainer}>
					<div className={styles.flexColumn}>
						<span className={styles.title}>{`${tabsCount} ${t('related')} ${t(tabsCount == 1 ? 'product' : 'Products')}`}</span>
						<span className={styles.grayText}>{`${tabsCount} ${t(tabsCount == 1 ? 'pricingModel' : 'pricingModels')}`}</span>
					</div>
				</div>
				<div className={styles.cards}>
					{creditNoteData?.pricingModel?.tabs?.map((tab) => (
						<CreditNotePricingModelDetails
							key={tab.id}
							tab={tab}
							balance={creditNoteData?.balance}
							contractId={creditNoteData?.contractId}
							hasUsageReport={hasUsageReport}
							onPressUpdateUsage={() =>
								onPressUpdateUsage?.({
									fromDate: tab.billingStartDate || null,
									toDate: tab.billingEndDate || null,
								})
							}
							fromDate={tab.billingStartDate}
							toDate={tab.billingEndDate}
						/>
					))}
				</div>
			</div>

			<SideBarFooter items={setFooterData(creditNoteData)} className={styles.footer} />
		</div>
	);
};
