import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Button, EmptyStateCard } from '../../../../components';
import { images } from '../../../../constants/images';
import styles from './ContractEmptyState.module.scss';

interface ContractEmptyStateProps {
	className?: string;
	onClick: () => void;
}
export const ContractEmptyState = ({ className, onClick }: ContractEmptyStateProps) => {
	const { t } = useTranslation('translation');
	return (
		<div className={clsx(styles.flexCenter, className)}>
			<div className={styles.container}>
				<EmptyStateCard
					src={images.createContract}
					imgAlt={'add-contract-icon'}
					title={t('createContract')}
					titleStyle={styles.header}
					subTitle={t('contractEmptySateDesc')}
				/>
				<Button className={styles.button} onClick={onClick}>
					{t('newContract')}
				</Button>
			</div>
		</div>
	);
};
