import { convertCurrencyToSign, creditNoteReasonCodesLabels, DATE_FORMAT, DocumentState } from '@received/pricing-model';
import { Dispatch } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CreditNoteStatusTag, SummaryBlockProps } from '../../../components';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { Cell, CreditNoteCell } from '../../../components/_uiComponents/Table/Table.utils';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { CreditNoteView, AllCreditNoteSummary } from '../../../types/creditNoteTypes';
import { summaryType } from '../../../types/invoiceTypes';
import { getCreditNoteIdName } from '../../../utils/CreditNoteUtils';
import { getValueWithCurrencySign, numberWithCommas } from '../../../utils/NumberUtils';
import styles from './CreditsNotesTab.module.scss';

export const defaultNumberOfItems = '20';

export const creditNotesTabTableHeader = (refreshData: () => void) => [
	{
		headerTitle: 'creditId',
		accessor: 'draftNumber',
		isSortable: true,
		CustomComponent: (item: CreditNoteView) => {
			const { t } = useTranslation('translation');
			return (
				<CreditNoteCell
					documentNumber={getCreditNoteIdName(item)}
					subAccessor={item?.poNumber ? item?.poNumber : !item?.contractId ? t('adjustedCredit') : ''}
				/>
			);
		},
	},
	{
		headerTitle: 'issueDate',
		accessor: 'issueDate',
		isSortable: true,
		CustomComponent: (item: CreditNoteView) => <Cell accessor={dayjs(item?.issueDate).format(DATE_FORMAT)} />,
	},
	{
		headerTitle: 'reasonCode',
		accessor: 'reasonCode',
		isSortable: true,
		CustomComponent: (item: CreditNoteView) => <Cell className={styles.ellipsis} accessor={creditNoteReasonCodesLabels[item.reasonCode] || '-'} />,
	},

	{
		headerTitle: 'totalCredits',
		accessor: 'total',
		isSortable: true,
		CustomComponent: (item: CreditNoteView) => (
			<Cell
				accessor={getValueWithCurrencySign(+item?.creditsAmount, convertCurrencyToSign(item?.currency), 0)}
				subAccessor={
					(item.state == DocumentState.OPEN || item.state == DocumentState.PAID) && item?.invoiceIds.length != 0
						? `${getValueWithCurrencySign(+item?.creditsAmount - item.creditsBalance, convertCurrencyToSign(item?.currency), 0)} paid`
						: ''
				}
			/>
		),
	},
	{
		headerTitle: 'appliedInvoices',
		accessor: '',
		CustomComponent: (item: CreditNoteView) => (
			<Cell accessor={item.invoiceIds.length > 0 ? `${item.invoiceIds.length} ${item.invoiceIds.length > 1 ? 'invoices' : 'invoice'}` : '-'} />
		),
	},

	{
		headerTitle: 'status',
		accessor: 'state',
		isSortable: true,
		disableRowClick: true,
		CustomComponent: (item: CreditNoteView) => <CreditNoteStatusTag refreshData={refreshData} className={styles.statusTag} document={item} />,
	},
];

export const deleteCreditNote = async (dispatch: Dispatch, getCreditNotes: () => void, id?: string) => {
	if (!id) return;
	try {
		await httpService({
			dispatch,
			path: PathsConfig.deleteCreditNote,
			urlParam: { id },
		});
		getCreditNotes();
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_DELETE_CREDIT_NOTE }));
	}
};

export const getCreditNoteSummaryBlocksData = (t: any, creditNoteSummary?: AllCreditNoteSummary): SummaryBlockProps[] => {
	return [
		{
			type: summaryType.PRIMARY,
			title: 'adjustedCreditsBalance',
			total: creditNoteSummary?.adjCreditsBalance,
			subTitle: `${numberWithCommas(creditNoteSummary?.adjCreditsCount, 0) || '0'} ${t(
				creditNoteSummary?.adjCreditsCount == 1 ? 'adjustedCreditNote' : 'adjustedCreditNotes',
			)}`.toLocaleLowerCase(),
			iconColor: 'primary',
			icon: 'pencil',
			iconHeight: 2.4,
		},
		{
			type: summaryType.WARNING,
			title: 'reviewCredits',
			total: (creditNoteSummary?.missingDataSum || 0) + (creditNoteSummary?.readyForSendingSum || 0),
			subTitle: `${numberWithCommas(creditNoteSummary?.readyForSendingCount || 0)} ${t('ready')} • ${
				creditNoteSummary?.missingDataCount || 0 || '0'
			} ${t('missingInfo')}`.toLocaleLowerCase(),
			iconColor: 'warning',
			iconHeight: 2.4,
		},
		{
			type: summaryType.SUCCESS,
			title: 'paidCredits',
			total: creditNoteSummary?.paidPaymentsSum,
			subTitle: `${numberWithCommas(creditNoteSummary?.paidPaymentsCount, 0) || '0'} ${t(
				creditNoteSummary?.paidPaymentsCount == 1 ? 'paidCreditNote' : 'paidCreditNotes',
			)}`.toLocaleLowerCase(),
			iconColor: 'success',
			iconHeight: 2.4,
		},
	];
};
