import { DEFAULT_CURRENCY, convertCurrencyToSign } from '@received/pricing-model';
import { Payout } from '../../../../../types/PayoutsTypes';
import { FooterItem } from '../../../components/SideBarFooter/SideBarFooter.utils';
import { getValueWithCurrencySign } from '../../../../../utils/NumberUtils';

export const setFooterData = (payout?: Payout): FooterItem[] => {
	const currency = convertCurrencyToSign(payout?.pricingModel?.tabs[0]?.currency || DEFAULT_CURRENCY);
	return [
		{
			title: 'subtotal',
			value: getValueWithCurrencySign(payout?.subTotal, currency),
		},
		{
			title: 'tax',
			value: getValueWithCurrencySign(payout?.totalTax, currency),
		},
		{
			title: 'total',
			value: getValueWithCurrencySign(payout?.total, currency),
			isBlack: true,
		},
	];
};
