import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ContractType } from '@received/pricing-model';
import {
	Button,
	CreateCustomer,
	Table,
	DateRangeFilters,
	MenuList,
	Icon,
	MenuOption,
	ImportItemsProgress,
	QuantityCard,
	QuantityCardProps,
	SearchBar,
	ActionModalModal,
} from '../../../components';
import { CustomerEmptyState } from './../CustomerEmptyState/CustomerEmptyState';
import { CustomerSuccessModal } from '../../../components/CreateCustomer/CustomerSuccessModal/CustomerSuccessModal';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { PathsConfig, httpService } from '../../../services';
import { setSidebarIsOpen } from '../../../storeSlices/generalSlice';
import { Store } from '../../../types/storeTypes';
import { customerOptionList, customerTableHeader, deleteCustomer, getCustomerById, getCustomerSummaryData, quantityBarData } from './Customers.utils';
import { Customer, CustomerSummary, CustomerView } from '../../../types/customerTypes';
import { deleteDataEmptyState, shortCustomerData } from '../../../utils/CustomerUtils';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../../components/Filters/DateRangeFilters.utils';
import { DatesData, Sort, SortOrder } from '../../../types/generalTypes';
import { UploadCSVMatcher } from '../../../Integrations';
import { useDebounce } from '../../../hooks';
import { images } from '../../../constants/images';
import { createContract } from '../../Contracts/ContractComponent/Contract.utils';
import styles from './Customers.module.scss';

interface CustomerResponse {
	customerViewList: CustomerView[];
	totalCount: number;
}

const defaultNumberOfItems = '20';

export const Customers = () => {
	const { t } = useTranslation('translation');
	const { isSidebarOpen, toastNotification } = useSelector((store: Store) => store.general);

	const [isSelfServed, setIsSelfServed] = useState(false);
	const [showCreateCustomer, setShowCreateCustomer] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [customersList, setCustomersList] = useState<CustomerView[]>([]);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [showCustomerCreatedModal, setShowCustomerCreatedModal] = useState(false);
	const [lastCreatedCustomer, setLastCreatedCustomer] = useState({ name: '', id: '' });
	const [customerDeleteData, setCustomerDeleteData] = useState<shortCustomerData>(deleteDataEmptyState);
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [currentCustomer, setCurrentCustomer] = useState<Customer>();
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);
	const [isCustomerOptionsMenuOpen, setIsCustomerOptionsMenuOpen] = useState(false);
	const [openUploadCSVModal, setOpenUploadCSVModal] = useState(false);
	const [csvFile, setCsvFile] = useState<File>();
	const [statusBarData, setStatusBarData] = useState<CustomerSummary>();
	const [sort, setSort] = useState<Sort>({ orderBy: 'name', order: SortOrder.ASC });
	const [searchText, setSearchText] = useState('');

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location: any = useLocation();

	useEffect(() => {
		setIsSelfServed(+numberOfItems >= 50);
	}, [numberOfItems]);

	useEffect(() => {
		!isSidebarOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	useEffect(() => {
		getCustomersData();
		setSummaryBar();
	}, [numberOfItems, pageNumber, timePeriod, toastNotification.showToastNotification, sort]);

	useEffect(() => {
		searchCustomers();
	}, [searchText]);

	useEffect(() => {
		location?.state?.opedCreateCustomer && setShowCreateCustomer(true);
		location?.state?.orderBy && setSort((prev) => ({ ...prev, orderBy: location?.state?.orderBy, order: location?.state?.order || sort.order }));
	}, []);

	const searchCustomers = () => {
		getCustomersData();
		setSummaryBar();
		setPageNumber(0);
	};

	const getCustomersData = async (page?: number, items?: string) => {
		try {
			const res: CustomerResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getCustomersViewData,
					params: {
						pageSize: parseInt(items || numberOfItems),
						pageNumber: page || pageNumber,
						fromDate: timePeriod?.from,
						toDate: timePeriod?.to,
						orderBy: sort.orderBy,
						textFilter: searchText || undefined,
						order: sort.order,
					},
				})
			).data;

			setTotalCount(res?.totalCount);
			setCustomersList(res.customerViewList);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CUSTOMERS }));
		}
	};

	const setSummaryBar = async () => {
		const summaryData = await getCustomerSummaryData(
			dispatch,
			timePeriod.from,
			timePeriod.to,
			[ContractType.ENTERPRISE, ContractType.SUBSCRIPTION],
			undefined,
			searchText,
		);
		setStatusBarData(summaryData);
	};

	const createCustomerMenuOptions: MenuOption[] = [
		{
			title: t('addManually'),
			onPress: () => onCreateNewCustomer(),
			iconType: 'addCustomer',
		},
		{
			title: t('importCustomers'),
			onPress: () => setOpenUploadCSVModal(true),
			iconType: 'addDocument',
		},
	];

	const onEditCustomer = (customer: Customer) => {
		setCurrentCustomer(customer);
		setShowCreateCustomer(true);
	};

	const onRefreshData = () => {
		getCustomersData();
		setSummaryBar();
		setNumberOfItems(defaultNumberOfItems);
		setPageNumber(0);
	};

	const onPressNewContract = async () => {
		const contractId = await createContract(dispatch, ContractType.ENTERPRISE);
		contractId && navigate('../contract', { state: { contractId } });
	};

	const onCustomerAdded = (name: string, id: string) => {
		setShowCreateCustomer(false);
		setLastCreatedCustomer({ name, id });
		setShowCustomerCreatedModal(currentCustomer == undefined);
	};

	const onCreateNewCustomer = () => {
		setCurrentCustomer(undefined);
		setShowCreateCustomer(true);
	};

	const onDeleteCustomer = (id: string) => {
		deleteCustomer(id, dispatch, onRefreshData);
		setIsDeleteModalOpen(false);
	};

	const onPressDelete = (id: string, name: string) => {
		setIsDeleteModalOpen(true);
		setCustomerDeleteData({ id, name });
	};

	const onCloseDeleteModal = () => {
		setCustomerDeleteData(deleteDataEmptyState);
		setIsDeleteModalOpen(false);
	};

	const onPressNewContractInRow = async (id: string) => {
		const customer = await getCustomerById(id, dispatch);
		const contractId = await createContract(dispatch, ContractType.ENTERPRISE, customer?.id);
		contractId && navigate('../contract', { state: { contractId } });
	};

	const onSearch = useDebounce(setSearchText, 650);

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.customersContainer}>
				<ImportItemsProgress onRefresh={() => onRefreshData()} />
				<div className={styles.header}>
					<header className={styles.customersHeader}>
						<h1 data-testid='customersPage-title'>{t('enterprises')}</h1>
						<div className={styles.buttonsContainer}>
							<SearchBar placeholder={t('search')} onChange={onSearch} className={styles.customerSearch} />
							<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />

							<MenuList
								optionsList={createCustomerMenuOptions}
								isMenuOpen={isCustomerOptionsMenuOpen}
								openMenu={() => setIsCustomerOptionsMenuOpen(false)}
							>
								<Button type='secondary' onClick={onCreateNewCustomer} className={styles.customerButtons}>
									{t('newCustomer')}
									<div
										onClick={(e) => {
											e.stopPropagation();
											setIsCustomerOptionsMenuOpen(!isCustomerOptionsMenuOpen);
										}}
										className={styles.customerOptionButton}
									>
										<Icon
											imgType='arrow_down'
											color='success'
											className={isCustomerOptionsMenuOpen ? styles.customerOptionArrowUp : styles.customerOptionArrowDown}
										/>
									</div>
								</Button>
							</MenuList>

							<Button onClick={onPressNewContract} className={styles.customerButtons}>
								{t('createContract')}
								<Icon imgType='add' color='white' width={1.2} className={styles.addIcon} />
							</Button>
						</div>
					</header>

					<div className={styles.cardsContainer}>
						{quantityBarData(t, statusBarData).map((card: QuantityCardProps, index: number) => (
							<QuantityCard key={index} {...card} />
						))}
					</div>
				</div>

				<Table
					sort={sort}
					numberOfItems={numberOfItems}
					header={customerTableHeader(t, onPressNewContractInRow, isSelfServed)}
					rows={customersList}
					className={styles.tableContainer}
					numberOfPages={Math.ceil(totalCount / +numberOfItems)}
					totalNumberOfItems={totalCount}
					tableRowClassName={isSelfServed ? styles.extendedTableRowClassName : styles.tableRowClassName}
					cellClassName={clsx(isSelfServed && styles.extendedTableCellClassName)}
					onUpdateNumberOfItems={setNumberOfItems}
					onUpdatePageNumber={setPageNumber}
					onRowPress={(data) => navigate(`./single-customer/${data.id}`)}
					optionsList={(row) => customerOptionList(row, dispatch, onEditCustomer, onPressDelete)}
					onFlipOrder={(accessor) => setSort({ orderBy: accessor, order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })}
					emptyState={
						!searchText ? (
							<CustomerEmptyState
								onExportFromCSV={setCsvFile}
								onAddManual={() => setShowCreateCustomer(true)}
								className={styles.emptyStateContainer}
							/>
						) : undefined
					}
				/>

				<CreateCustomer
					isOpen={showCreateCustomer}
					closeModal={() => setShowCreateCustomer(false)}
					onCustomerAdded={onCustomerAdded}
					refreshData={() => onRefreshData()}
					customer={currentCustomer}
				/>
				<CustomerSuccessModal
					isOpen={showCustomerCreatedModal}
					lastCreatedCustomer={lastCreatedCustomer}
					closeModal={() => setShowCustomerCreatedModal(false)}
					onAddCustomer={() => {
						setShowCustomerCreatedModal(false);
						setShowCreateCustomer(true);
					}}
					onOverviewCustomer={() => {
						setShowCustomerCreatedModal(false);
						navigate(`./single-customer/${lastCreatedCustomer.id}`);
					}}
				/>
				<ActionModalModal
					rightButton={{ title: 'delete', color: 'destructive' }}
					imagesOptions={images.deleteItem}
					isOpen={isDeleteModalOpen}
					deleteMessage={
						<>
							{t('deleteMessagePrefix').replace('__', 'customer')}
							<span className={styles.name}> {customerDeleteData.name}</span> {t('deleteMessagePostfix').replace('__', 'customer')}
						</>
					}
					onAccept={() => onDeleteCustomer(customerDeleteData.id)}
					onClose={onCloseDeleteModal}
				/>
				<UploadCSVMatcher
					csvFile={csvFile}
					isUploadCSVModalOpen={openUploadCSVModal}
					onCloseUploadCsv={() => setOpenUploadCSVModal(false)}
					onRefreshData={onRefreshData}
				/>
			</div>
		</div>
	);
};
