import { Dispatch } from '@reduxjs/toolkit';
import { SettingsCategory } from '@received/pricing-model';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { ErrorSuccessModalTypes } from '../../../types/generalTypes';
import { Customer } from '../../../types/customerTypes';

export interface DocumentStartingNumberSettings {
	editable: boolean;
	number: string;
	documentPrefix?: string;
	entityPrefix?: string;
}

export const DocumentSettingsInitialState = {
	editable: true,
	number: '',
	documentPrefix: '',
	entityPrefix: '',
};

export const validateSettings = (invoiceSettings: DocumentStartingNumberSettings, creditNoteSettings: DocumentStartingNumberSettings) => {
	return (
		(!invoiceSettings.editable && !creditNoteSettings.editable) ||
		(creditNoteSettings.documentPrefix ? creditNoteSettings?.documentPrefix?.length > 4 || creditNoteSettings?.documentPrefix?.length < 2 : true) ||
		(invoiceSettings.documentPrefix ? invoiceSettings?.documentPrefix?.length > 4 || invoiceSettings?.documentPrefix?.length < 2 : true) ||
		(creditNoteSettings.entityPrefix ? creditNoteSettings?.entityPrefix?.length > 4 || creditNoteSettings?.entityPrefix?.length < 2 : true) ||
		(invoiceSettings.entityPrefix ? invoiceSettings?.entityPrefix?.length > 4 || invoiceSettings?.entityPrefix?.length < 2 : true)
	);
};

export const getInvoiceSettings = async (dispatch: Dispatch, supplierId: string) => {
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getStartingNumberSettingsByDocumentType,
				urlParam: { documentType: SettingsCategory.INVOICES, supplierId },
				showLoader: false,
			})
		).data;
	} catch (error) {
		dispatch(
			setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_INVOICE_STARTING_NUMBER }),
		);
	}
};

export const getCreditNoteSettings = async (dispatch: Dispatch, supplierId: string) => {
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getStartingNumberSettingsByDocumentType,
				urlParam: { documentType: 'credit-notes', supplierId },
				showLoader: false,
			})
		).data;
	} catch (error) {
		dispatch(
			setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_INVOICE_STARTING_NUMBER }),
		);
	}
};

export const updateInvoiceSettings = async (dispatch: Dispatch, invoiceSettings: DocumentStartingNumberSettings, supplierId: string) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.updateStartingNumberByDocumentType,
			urlParam: { documentType: SettingsCategory.INVOICES },
			data: {
				supplierId,
				documentPrefix: invoiceSettings.documentPrefix,
				entityPrefix: invoiceSettings.entityPrefix,
				number: +invoiceSettings.number,
			},
		});
	} catch (error) {
		dispatch(
			setOpenSuccessErrorModal({
				responseError: error,
				successErrorMassage: successErrorMassageOptions.ERROR.FAILED_UPDATE_INVOICE_STARTING_NUMBER,
			}),
		);
	}
};

export const updateCreditNoteStartingNumber = async (dispatch: Dispatch, creditNoteSettings: DocumentStartingNumberSettings, supplierId: string) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.updateStartingNumberByDocumentType,
			urlParam: { documentType: 'credit-notes' },
			data: {
				supplierId,
				documentPrefix: creditNoteSettings.documentPrefix,
				entityPrefix: creditNoteSettings.entityPrefix,
				number: +creditNoteSettings.number,
			},
		});
	} catch (error) {
		dispatch(
			setOpenSuccessErrorModal({
				responseError: error,
				successErrorMassage: successErrorMassageOptions.ERROR.FAILED_UPDATE_CREDIT_NOTE_STARTING_NUMBER,
			}),
		);
	}
};

export const updateCcEmailList = async (dispatch: Dispatch, supplier: Customer) => {
	try {
		await httpService({
			dispatch,
			path: PathsConfig.updateSupplier,
			urlParam: { id: supplier?.id ?? '' },
			data: {
				...supplier,
			},
		});
	} catch (error) {
		dispatch(
			setOpenSuccessErrorModal({
				responseError: error,
				successErrorMassage: successErrorMassageOptions.ERROR.UPDATE_COMPANY,
			}),
		);
	}
};
