import { creditNoteReasonCodesLabels } from '@received/pricing-model';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, DatePickerFormatType, Icon, MenuList, Tabs, Tooltip } from '../../_uiComponents';
import { DATE_FORMAT } from '../../../constants/templateConstants';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { CreditNote } from '../../../types/creditNoteTypes';
import { creditNoteMenuList, initialCreditNoteState, isCreditNoteEditable, startGenerateCreditNotePDFById } from '../../../utils/CreditNoteUtils';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { creditNotesTabs } from './CreditNoteSidebar.utils';
import { SidebarToOpen, SidebarType } from '../Sidebars.utils';
import { CreditNoteStatusTag, PDFPreviewModal } from '../..';
import { getDocumentNumberPrefix, getEmailsLabel } from '../../../utils/GeneralUtils';
import { getSupplierById } from '../../../utils/CustomerUtils';
import { SideBarHeader } from '../components';
import { Customer } from '../../../types/customerTypes';
import { contractIdFormat } from '../../../utils/ContractUtils';
import { Store } from '../../../types/storeTypes';
import { creditNoteBlockersLabels } from './CreditNoteOverview/CreditNoteOverview.utils';
import styles from './CreditNoteSidebar.module.scss';

interface CreditNoteSidebarProps {
	id?: string;
	isOpen?: boolean;
	isSecondarySideBar?: boolean;
	refreshData?: boolean;
	selectedRowId?: string;
	sidebarToBeNoticed: boolean;
	containersClassName?: string;
	setSidebarToBeNoticed: () => void;
	closeSideBar(): void;
	openOtherSidebar: (sidebar: SidebarToOpen) => void;
	onDataChange: () => void;
	closeSecondarySidebar: () => void;
}

export const CreditNoteSidebar = ({
	id,
	isOpen,
	isSecondarySideBar,
	selectedRowId,
	sidebarToBeNoticed,
	containersClassName,
	refreshData,
	setSidebarToBeNoticed,
	closeSideBar,
	openOtherSidebar,
	onDataChange,
	closeSecondarySidebar,
}: CreditNoteSidebarProps) => {
	const { t } = useTranslation('translation');
	const [creditNote, setCreditNote] = useState<CreditNote>(initialCreditNoteState);
	const [activeTab, setActiveTab] = useState(0);
	const [openPreviewSideBar, setOpenPreviewSideBar] = useState(false);
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [supplier, setSupplier] = useState<Customer>();

	const availableIntegration = useSelector((store: Store) => store.integrations.availableIntegration);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (isOpen) {
			setCreditNoteData(id);
		}
	}, [id, isOpen, refreshData]);

	useEffect(() => {
		setOpenPreviewSideBar(false);
	}, [activeTab]);

	const setCreditNoteData = async (id?: string) => {
		if (!id) return;

		try {
			const data: CreditNote = (
				await httpService({
					showLoader: false,
					dispatch,
					path: PathsConfig.getCreditNoteData,
					urlParam: { id },
				})
			).data;

			data?.supplierId && (await setSupplierData(data?.supplierId));

			setCreditNote(data);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTE }));
		}
	};

	const setSupplierData = async (supplierId?: string) => {
		const supplier = await getSupplierById(dispatch, supplierId);
		setSupplier(supplier);
	};

	const onClose = () => {
		setActiveTab(0);
		setOpenPreviewSideBar(false);
		return isSecondarySideBar ? closeSecondarySidebar?.() : closeSideBar();
	};

	return (
		<>
			{isOpen && <div onClick={onClose} className={clsx(isSecondarySideBar ? styles.secondaryModalOverlay : styles.modalOverlay)} />}

			<div
				data-testid='credit-note-side-bar'
				className={clsx(
					!isOpen ? styles.sidebarHidden : isSecondarySideBar ? styles.secondarySidebar : styles.sidebar,
					isOpen && containersClassName,
					sidebarToBeNoticed && styles.noticeMe,
				)}
				onAnimationEnd={setSidebarToBeNoticed}
				style={{ overflow: 'scroll' }}
			>
				<div className={styles.sidebarContent}>
					<div className={styles.topContainer}>
						<div className={styles.header}>
							<SideBarHeader
								sidebarType={t('creditNote')}
								customer={creditNote?.customer}
								prefix={getDocumentNumberPrefix(creditNote)}
								documentNumber={creditNote?.documentNumber || creditNote?.draftNumber}
							/>

							<Tooltip
								placement='left'
								tooltipComponent={<div className={styles.capitalize}>{creditNoteBlockersLabels(t, creditNote?.editBlockers)}</div>}
								blackTooltip
								disabled={isCreditNoteEditable(creditNote)}
							>
								<Button onClick={() => navigate('../single-credit-note', { state: { creditNoteId: creditNote?.id } })} className={styles.button}>
									{t(isCreditNoteEditable(creditNote) ? 'edit' : 'view')}
								</Button>
							</Tooltip>

							<MenuList
								optionsList={creditNoteMenuList({
									item: creditNote,
									dispatch,
									navigate,
									t,
									onRefresh: () => setCreditNoteData(id),
									onPreview: () => setOpenPreviewSideBar(!openPreviewSideBar),
								})}
								isMenuOpen={openMenuOptions}
								openMenu={() => setOpenMenuOptions(false)}
							>
								<Button
									data-testid='credit-note-side-bar-hamburger'
									type='link'
									color='neutral'
									onClick={() => setOpenMenuOptions(true)}
									className={styles.hamburgerButtonWrapper}
								>
									<Icon
										imgType='hamburger'
										height={2}
										color='neutral700'
										className={clsx(styles.hamburgerButton, openMenuOptions && styles.hamburgerRotate)}
									/>
								</Button>
							</MenuList>
						</div>

						<div className={styles.detailsRowContainer}>
							<div className={clsx(styles.detailsRow, styles.flex)}>
								<span className={styles.detailsText}>{t('customer')}</span>
								<span
									data-testid='credit-note-side-bar-customer-name'
									className={clsx(styles.blackText, styles.ellipsis, styles.link)}
									style={{ marginRight: '0.4rem' }}
									onClick={() => creditNote?.customer && navigate(`/customers/single-customer/${creditNote?.customer.id}`)}
								>
									{creditNote?.customer?.name}{' '}
								</span>
								<span className={clsx(styles.greyText, styles.ellipsis)}>{` | ${getEmailsLabel(creditNote?.customer?.email)}`}</span>
							</div>
							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('issuedate')}</span>
								<span data-testid='credit-note-side-bar-issue-date' className={styles.detailValue}>
									{creditNote?.issueDate && dayjs(creditNote?.issueDate).format(DATE_FORMAT)}
								</span>
							</div>
							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('contractID')}</span>
								<span
									data-testid='credit-note-side-bar-contract-id'
									className={clsx(styles.detailValue, creditNote?.contractId && styles.link)}
									onClick={() =>
										creditNote?.contractId &&
										openOtherSidebar({ sidebarType: SidebarType.CONTRACT, triggeredBy: SidebarType.CREDIT_NOTE, id: creditNote?.contractId })
									}
								>
									{contractIdFormat(creditNote?.poNumber, creditNote?.contractNumber)}
								</span>
							</div>

							<div className={clsx(styles.detailsRow, styles.flex)}>
								<span className={styles.detailsText}>{t('creditEntity')}</span>
								<span
									data-testid='credit-note-side-bar-supplier-name'
									className={clsx(styles.blackText, styles.ellipsis)}
									style={{ marginRight: '0.4rem' }}
								>
									{supplier?.name || '-'}
								</span>
							</div>

							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('reasoncode')}</span>
								<span data-testid='credit-note-side-bar-reason-code' className={styles.detailValue}>
									{creditNote.reasonCode ? creditNoteReasonCodesLabels[creditNote.reasonCode] : '-'}
								</span>
							</div>
							<div className={styles.detailsRow}>
								<span className={styles.detailsText}>{t('status')}</span>

								<CreditNoteStatusTag
									className={styles.badge}
									document={creditNote}
									refreshData={() => {
										setCreditNoteData(id);
										onDataChange();
									}}
								/>
							</div>
						</div>
					</div>
					<div className={styles.tabsSection}>
						<Tabs
							tabsArray={creditNotesTabs(creditNote).map((tab) => tab.title)}
							chosenTab={activeTab}
							updateChosenTab={(val: number) => setActiveTab(val)}
							classNames={styles}
						/>
						<div className={styles.tabsContent}>
							{creditNotesTabs(creditNote)[activeTab].component({
								creditNoteData: creditNote,
								selectedRowId,
								sidebarType: SidebarType.CREDIT_NOTE,
								hasUsageReport: !!creditNote?.usageReportsByProduct?.length,
								openPreviewSideBar: () => setOpenPreviewSideBar(!openPreviewSideBar),
								openOtherSidebar,
								onDataChange,
								onUsageRowPress: openOtherSidebar,
								onPressUpdateUsage: (timePeriod?: DatePickerFormatType) =>
									openOtherSidebar({
										sidebarType: SidebarType.USAGE_REPORT,
										triggeredBy: SidebarType.CREDIT_NOTE,
										id: creditNote?.id,
										usagePeriod: {
											fromDate: timePeriod?.fromDate || null,
											toDate: timePeriod?.toDate || null,
										},
									}),
							})}
						</div>
					</div>
				</div>

				<PDFPreviewModal
					isOpen={openPreviewSideBar}
					documentsList={[creditNote]}
					onClose={() => setOpenPreviewSideBar(false)}
					onDownload={() => startGenerateCreditNotePDFById(dispatch, creditNote?.id)}
				/>
			</div>
		</>
	);
};
