import React from 'react';
import { useTranslation } from 'react-i18next';
import { PricingModelTableComponent, Icon, IconImgType, IconColor, Switch } from '..';
import { PricingModelTableViewTypeOptions } from '../PricingModelTable/PricingModelTable.utils';
import { PricingModel } from '../../types/contractTypes';
import styles from './PricingTableCardView.module.scss';

interface PricingTableCardViewProps {
	pricingModel?: PricingModel;
	imgType?: IconImgType;
	iconColor?: IconColor;
	tagText?: string;
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	showSwitch?: { isVisible?: boolean; defaultChecked?: boolean; onChange?: (isChecked: boolean) => void };
	selectedRows?: boolean[][];
	onUpdateSelectedRows?: (rowIndex: number, isSelected: boolean) => void;
}

export const PricingTableCardView = ({
	pricingModel,
	imgType,
	iconColor,
	tagText,
	pricingModelTableViewType,
	showSwitch,
	selectedRows,
	onUpdateSelectedRows,
}: PricingTableCardViewProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={styles.tableCard}>
			<div className={styles.tableCardHeader}>
				<div className={styles.cardName}>
					<div className={styles.itemCardIconContainer}>
						<Icon imgType={imgType || 'subscription_template'} color={iconColor || 'neutral'} height={2} />
					</div>
					<div>
						<div className={styles.itemTag}>{tagText}</div>
						<div>{pricingModel?.name}</div>
					</div>
				</div>
				{showSwitch?.isVisible && (
					<div className={styles.switchContainer}>
						{t('selectAllRows')}
						<Switch
							dataTestId='main-table-switch'
							defaultChecked={showSwitch?.defaultChecked}
							onChange={showSwitch?.onChange}
							className={styles.switch}
						/>
					</div>
				)}
			</div>

			{pricingModel && (
				<PricingModelTableComponent
					selectedRows={selectedRows?.[0]}
					onUpdateSelectedRows={onUpdateSelectedRows}
					pricingModelTableViewType={pricingModelTableViewType || PricingModelTableViewTypeOptions.CATALOG_PREVIEW_NOT_EDITABLE}
					pricingModelTable={pricingModel?.tabs[0].pricingModelTable}
					classNames={{ tableContainer: styles.tableContainer, table: styles.table }}
				/>
			)}
		</div>
	);
};
