import react, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { UsageProduct } from '@received/pricing-model';
import { UploadLogsSteps } from '../ImportUsageLogs.utils';
import { Button, EmptyStateCard, FileUploader, Icon } from '../../../../components';
import { images } from '../../../../constants/images';
import { createCSVFile } from '../../../../utils/GeneralUtils';
import { FileItem } from '../../../../components/_uiComponents/FileUploader';
import { AnalyticItem, USAGE_CSV_TEMPLATE_NAME, USAGE_LOGS_CSV_TEMPLATE, UsageLogSummaryItem, numberOfWarnings } from './RightContainer.utils';
import { PathsConfig, httpService } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { UsageLogsSummaries, UsageReportView, ValidationWarnings } from '../../../../types/usageTypes';
import { WarningModal } from './WarningModal/WarningModal';
import { LogsPdfPreview } from './LogsPdfPreview/LogsPdfPreview';
import styles from './RightContainer.module.scss';

interface RightContainerProps {
	totalSummary?: UsageLogsSummaries['totalSummary'];
	file?: File;
	analytics?: UsageLogsSummaries;
	uploadSteps: UploadLogsSteps;
	selectedReportIndex?: number;
	haveErrors: boolean;
	pdfPreview?: { usageProduct: UsageProduct; validationWarnings?: ValidationWarnings } | 'LOADING';
	onUploadFile(file?: { file: File; uploadId: string }): void;
	refreshSummaries(): void;
	onOpenModalView(usageReportView: UsageReportView[], reportIndex: number): void;
}

export const RightContainer = ({
	totalSummary,
	file,
	analytics,
	uploadSteps,
	selectedReportIndex,
	haveErrors,
	pdfPreview,
	refreshSummaries,
	onUploadFile,
	onOpenModalView,
}: RightContainerProps) => {
	const { t } = useTranslation('translation');
	const [openWarnings, setOpenWarnings] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		setOpenWarnings(false);
	}, [analytics, uploadSteps]);

	const uploadFile = async (file: File) => {
		try {
			let base64String = '';
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async () => {
				base64String = reader.result as string;

				const res = await httpService({
					dispatch,
					path: PathsConfig.uploadUsageLogsFile,
					data: { csvData: base64String, fileName: file.name },
				});

				onUploadFile({ file, uploadId: res.data });
			};
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<div className={styles.rightContainer}>
			{uploadSteps === UploadLogsSteps.REFRESH_CSV_AND_MAPPER && (
				<div className={styles.refresh} onClick={() => refreshSummaries()} style={{ pointerEvents: haveErrors ? 'none' : 'auto' }}>
					<img alt='refresh-document-preview' src={images.whiteRefresh} className={styles.refreshImage} />
				</div>
			)}
			<div className={styles.dataContainer}>
				<header className={styles.rightContainerHeader}>
					{t('review&Summaries')}
					<div className={styles.fileBadgeContainer}>
						{analytics && (
							<div className={styles.fileBadge}>
								{file?.name}
								<Button type='link' color='neutral' onClick={() => onUploadFile(undefined)}>
									<Icon imgType='delete' color='white' />
								</Button>
							</div>
						)}

						{analytics && !!numberOfWarnings(analytics?.validationWarnings) && (
							<Button color='warning' onClick={() => setOpenWarnings(!openWarnings)} className={styles.warningBadge}>
								{`${numberOfWarnings(analytics?.validationWarnings)} ${t(
									numberOfWarnings(analytics?.validationWarnings) === 1 ? 'warning' : 'warnings',
								)}`}
								<Icon imgType='arrow_down' color='white' width={1} className={openWarnings ? styles.arrowDown : styles.arrowUp} />
							</Button>
						)}
					</div>
				</header>

				<div className={styles.analyticsContainer}>
					<div className={styles.analyticsBlock}>
						<AnalyticItem icon='document' number={totalSummary?.usageReports} subTitle={t('reports')} />
						<AnalyticItem icon='cube' number={totalSummary?.products} subTitle={t('products')} />
					</div>
					<div className={styles.analyticsBlock}>
						<AnalyticItem icon='graph' number={totalSummary?.usageLogs} subTitle={t('usageLogs')} />
						<AnalyticItem icon='customersEmpty' number={totalSummary?.customers} subTitle={t('Customers')} />
					</div>
					<div className={styles.analyticsBlock}>
						<AnalyticItem icon='unfilled_bookmark' number={totalSummary?.invoices} subTitle={t('documents')} />
						<AnalyticItem icon='avatar' number={totalSummary?.subActivities} subTitle={t('subActivities')} />
					</div>
				</div>

				<div className={styles.fileContainer}>
					{analytics ? (
						<>
							{Object.keys(analytics.summaryByUsageProduct).length ? (
								Object.keys(analytics.summaryByUsageProduct).map((key, index) => (
									<UsageLogSummaryItem
										key={index}
										item={analytics.summaryByUsageProduct[key]}
										isSelected={selectedReportIndex === index}
										onClick={() => onOpenModalView(analytics?.summaryByUsageProduct[key]?.usageReportsView, index)}
									/>
								))
							) : (
								<EmptyStateCard
									containerStyle={styles.emptyState}
									src={images.emptyData}
									imgAlt='empty-state'
									title={t('noReportsFound')}
									subTitle={t('checkColumnsOrData')}
								/>
							)}
						</>
					) : (
						<>
							{file ? (
								<>
									<div className={styles.subTitle}>{t('yourFile')}</div>
									<FileItem file={file} onDelete={() => onUploadFile(undefined)} iconHeight={3.2} iconContainer={styles.iconContainer} />

									<div className={styles.pdfPreviewTitle}>{t('previewReport')}</div>
									<div className={styles.pdfPreview}>
										{pdfPreview != 'LOADING' && pdfPreview?.usageProduct ? (
											<LogsPdfPreview usageProduct={pdfPreview?.usageProduct} />
										) : pdfPreview === 'LOADING' ? (
											<div className={styles.refreshContainer}>
												<Icon imgType='refresh' height={5} className={styles.refreshIconAnimation} />
											</div>
										) : (
											<EmptyStateCard
												containerStyle={styles.emptyState}
												src={images.emptyData}
												imgAlt='empty-state'
												title={t('cantCreateReportPreview')}
												subTitle={t('checkColumnsOrData')}
											/>
										)}
									</div>
								</>
							) : (
								<FileUploader className={styles.fileUploader} fileTypes={['CSV']} uploadFile={(file) => uploadFile(file)}>
									<EmptyStateCard src={images.exportCSV} imgAlt='add-customer-icon'>
										<span>
											<span>{t('download')} </span>
											<span
												className={styles.download}
												onClick={(e) => {
													e.stopPropagation();
													createCSVFile(USAGE_LOGS_CSV_TEMPLATE, USAGE_CSV_TEMPLATE_NAME);
												}}
											>
												{t('template')}
											</span>
											<span> {t('toUploadAndImport')}</span>
										</span>

										<div className={styles.neutralFontSize12}> {t('dragAndDropHere')}</div>

										<div className={styles.importButton}>{t('importACsv')}</div>
									</EmptyStateCard>
								</FileUploader>
							)}
						</>
					)}
				</div>

				{analytics?.validationWarnings && uploadSteps != UploadLogsSteps.REFRESH_CSV_AND_MAPPER && (
					<WarningModal
						totalWarningCount={analytics?.totalWarningCount}
						fileName={file?.name}
						warnings={analytics?.validationWarnings}
						csvWithErrorsUid={analytics?.csvWithErrorsUid}
						isOpen={openWarnings}
						onClose={() => setOpenWarnings(false)}
					/>
				)}
			</div>
		</div>
	);
};
