import { Dispatch } from '@reduxjs/toolkit';
import { ContractType } from '@received/pricing-model';
import { AnalyticsTab, ContractsTab, CreditsNotesTab, InvoicesTab, MenuOption, OverviewTab, RevRecTab, SubscriptionsTab } from '../../../components';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { ErrorSuccessModalTypes, NodeEnvTypes } from '../../../types/generalTypes';
import { Customer } from '../../../types/customerTypes';
import { getCustomerById } from '..';

export enum SingleCustomerPageTabIndex {
	overview,
	contracts,
	subscriptions,
	invoices,
	credits,
	analytics,
	revenueRecognition,
}

export const singleCustomerPageTabs = () => {
	let tabs = [
		{ title: 'overview', component: (prop?: any) => <OverviewTab {...prop} />, tabIndex: SingleCustomerPageTabIndex.overview },
		{
			title: 'contracts',
			component: (prop?: any) => <ContractsTab contractType={ContractType.ENTERPRISE} {...prop} />,
			tabIndex: SingleCustomerPageTabIndex.contracts,
		},
	];
	if (window._env_.REACT_APP_NODE_ENV != NodeEnvTypes.PRODUCTION) {
		tabs = [
			...tabs,
			{ title: 'subscriptions', component: (prop?: any) => <SubscriptionsTab {...prop} />, tabIndex: SingleCustomerPageTabIndex.subscriptions },
		];
	}

	tabs = [
		...tabs,
		{ title: 'invoices', component: (prop?: any) => <InvoicesTab {...prop} />, tabIndex: SingleCustomerPageTabIndex.invoices },
		{ title: 'credits', component: (prop?: any) => <CreditsNotesTab {...prop} />, tabIndex: SingleCustomerPageTabIndex.credits },
		{ title: 'analytics', component: (prop?: any) => <AnalyticsTab {...prop} />, tabIndex: SingleCustomerPageTabIndex.analytics },
		{ title: 'revenueRecognition', component: (prop?: any) => <RevRecTab {...prop} />, tabIndex: SingleCustomerPageTabIndex.revenueRecognition },
	];

	return tabs;
};

export const customerOptionList = (
	dispatch: Dispatch,
	onEditCustomer: (customer: Customer) => void,
	onPressDelete: () => void,
	customer?: Customer,
): MenuOption[] => {
	const canBeDeleted = customer?.activeContractsCount == 0;
	return [
		{ title: 'Edit Customer', onPress: () => customer?.id && getCustomerById(customer?.id, dispatch, onEditCustomer), iconType: 'edit' },
		{
			title: 'Delete Customer',
			onPress: () =>
				canBeDeleted
					? onPressDelete()
					: dispatch(
							setOpenSuccessErrorModal({
								customTitle: 'cantDeleteCustomer',
								modalType: ErrorSuccessModalTypes.CANT_DELETE,
								successErrorMassage: 'cantDeleteCustomerDescription',
							}),
					  ),
			iconType: 'delete',
			color: 'destructive',
		},
	];
};

export const isTabHasTimeFilters = (tabIndex: number) => {
	switch (tabIndex) {
		case SingleCustomerPageTabIndex.overview:
		case SingleCustomerPageTabIndex.invoices:
		case SingleCustomerPageTabIndex.credits:
			return true;
		default:
			return false;
	}
};
