import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountingEventType, ContractType } from '@received/pricing-model';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { Button, CatalogCard } from '../../_uiComponents';
import { PricingModel } from '../../../types/contractTypes';
import { httpService } from '../../../services/httpService/httpService';
import { PathsConfig } from '../../../services/httpService/configPaths';
import { successErrorMassageOptions } from '../../SuccessErrorModal/SuccessErrorModal.utils';
import { images } from '../../../constants/images';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { CatalogCardType } from '../../_uiComponents/CatalogCard/CatalogCard.utils';
import styles from './EmptyPricingModel.module.scss';

interface EmptyPricingModelProps {
	title?: string;
	description?: string;
	subTitle?: string;
	className?: string;
	pricingModelsNumber?: number;
	contractType?: ContractType;
	startBlank(): void;
	openPricingModelCatalog(): void;
	addModel: (pricingModel: PricingModel) => void;
}
export const EmptyPricingModel = ({
	title,
	description,
	subTitle,
	className,
	pricingModelsNumber = 4,
	contractType,
	startBlank,
	openPricingModelCatalog,
	addModel,
}: EmptyPricingModelProps) => {
	const { t } = useTranslation('translation');
	const [commonUsedPricingModels, setCommonUsedPricingModels] = useState<PricingModel[]>();

	const dispatch = useDispatch();

	useEffect(() => {
		getCommonUsedPricingModels();
	}, []);

	const getCommonUsedPricingModels = async () => {
		try {
			const res: PricingModel[] = (
				await httpService({
					dispatch,
					path: PathsConfig.getCommonTemplatesList,
				})
			).data;

			let list = res.splice(0, pricingModelsNumber);

			if (contractType === ContractType.REFERRAL) {
				list = list.filter((model) => model.tabs[0].accountingEventType === AccountingEventType.PAYOUT);
			} else {
				list = list.filter((model) => model.tabs[0].accountingEventType != AccountingEventType.PAYOUT);
			}
			setCommonUsedPricingModels(list.splice(0, pricingModelsNumber));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CATEGORIES }));
		}
	};

	return (
		<div className={clsx(styles.emptyPricingModels, className)} data-testid='empty-pricing-model'>
			<img alt='add-model-icon' src={images.addModel} className={styles.icon}></img>

			<div className={styles.title}>{t(title || 'addProductTitle')}</div>
			<div className={styles.subTitle}>{t(description || 'pickATemplate')}</div>

			<div className={styles.buttonContainer}>
				<Button className={styles.templateButton} style={{ marginRight: '1.4rem' }} type='outlined' color='neutral' onClick={startBlank}>
					{t('startBlank')}
				</Button>

				<Button className={styles.templateButton} onClick={openPricingModelCatalog}>
					{t('Start from templates')}
				</Button>
			</div>

			{!!commonUsedPricingModels?.length && (
				<>
					<div className={styles.commonPlans}>{t(subTitle || 'commonPlans')}:</div>
					<div className={styles.catalogContainer}>
						{commonUsedPricingModels.map((pricingModel, index) => {
							return (
								<CatalogCard
									pricingModel={pricingModel}
									disableEdit
									key={index}
									className={styles.catalogCard}
									onPress={() => pricingModel?.id && addModel(pricingModel)}
									type={CatalogCardType.DEFAULT}
								/>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
};
