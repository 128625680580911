import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { UsageLogsFormat, initialFormat, onAddNewFormat } from '../../ImportUsageLogs.utils';
import { Button, Input, Modal, Tooltip } from '../../../../../components';
import { images } from '../../../../../constants/images';
import { setOpenSuccessErrorModal } from '../../../../../storeSlices/errorSuccessSlice';
import styles from './AddFormatModal.module.scss';

export interface AddFormatModalProps {
	isOpen: boolean;
	logsFormatList: UsageLogsFormat[];
	isEdit?: boolean;
	logsFormat?: UsageLogsFormat;
	onFormatAdded(logsFormat: UsageLogsFormat): void;
	closeModal(): void;
}

export const AddFormatModal = ({ isOpen, logsFormatList, isEdit, logsFormat, onFormatAdded, closeModal }: AddFormatModalProps) => {
	const { t } = useTranslation('translation');
	const [formatName, setFormatNameName] = useState('');
	const [existingFormatName, setExistingFormatName] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		if (isEdit && logsFormat) {
			setFormatNameName(logsFormat?.name);
		}
	}, [isEdit, logsFormat]);

	useEffect(() => {
		const isExisting = logsFormatList.find((format) => format.name.toLowerCase() === formatName.toLowerCase());
		setExistingFormatName(!!isExisting);
	}, [formatName, logsFormatList]);

	const onAddFormat = async () => {
		try {
			const newFormat = isEdit && logsFormat ? { ...logsFormat, name: formatName } : { name: formatName, mapping: initialFormat.mapping };

			const format = await onAddNewFormat(newFormat, dispatch, true);

			onFormatAdded(format);
			setFormatNameName('');
			closeModal();
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	return (
		<Modal isOpen={isOpen} closeModal={closeModal} className={styles.modalContainer}>
			<img alt='add-model-icon' src={images.addFormat} className={styles.icon}></img>
			<div className={styles.title}>{t(isEdit ? 'editFormat' : 'addFormat')}</div>

			<Input
				autoFocus
				placeholder={t('formatName')}
				value={formatName}
				onChange={(value) => setFormatNameName(value)}
				inputClassName={styles.input}
				className={clsx(styles.inputContainerClassName, styles.input)}
				onKeyDown={(event) => {
					if (event.key === 'Enter') {
						!(!formatName || existingFormatName) && onAddFormat();
					}
				}}
			/>

			<Tooltip tooltipComponent={existingFormatName ? <div>{t('existingFormatName')}</div> : null} containerClassName={styles.footerButtons}>
				<Button onClick={onAddFormat} className={styles.footerButtons} disabled={!formatName || existingFormatName}>
					{t(isEdit ? 'update' : 'create')}
				</Button>
			</Tooltip>

			<Button
				type='link'
				color='neutral'
				onClick={() => {
					setFormatNameName('');
					closeModal();
				}}
				className={styles.footerButtons}
			>
				{t('Cancel')}
			</Button>
		</Modal>
	);
};
