import { CellTypes, UsageAggregationFunction, UsageAggregationScope, UsageAggregator } from '@received/pricing-model';
import { colors } from '../../constants/colors';
import { PricingModelTableColumns } from '../../types/pricingModelTypes';

export enum requiredColumnsEnum {
	amount = 'amount',
	quantity = 'quantity',
	price = 'price',
	item = 'item',
}

export enum PricingModelTableViewTypeOptions {
	PRICING_MODEL = 'PRICING_MODEL',
	CONTRACT = 'CONTRACT',
	INVOICE_PREVIEW = 'INVOICE_PREVIEW',
	CATALOG_PREVIEW_EDITABLE = 'CATALOG_PREVIEW_EDITABLE',
	CATALOG_PREVIEW_NOT_EDITABLE = 'CATALOG_PREVIEW_NOT_EDITABLE',
}

export const newCellValueSelector = (type: CellTypes) => {
	switch (type) {
		case CellTypes.ITEM_REFERENCE:
			return '';
		case CellTypes.TEXT:
			return '';
		case CellTypes.NUMBER:
			return '0';
		case CellTypes.FORMULA:
			return '0';
		default:
			return '0';
	}
};

export interface UpdateUsageProduct {
	usageAggregator: UsageAggregator;
	column: PricingModelTableColumns;
	columnIndex: number;
	rowIndex?: number;
}

export const isColumnHidden = (column: PricingModelTableColumns, pricingModelTableViewType: PricingModelTableViewTypeOptions | undefined) => {
	const isHidden =
		pricingModelTableViewType &&
		[
			PricingModelTableViewTypeOptions.PRICING_MODEL,
			PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE,
			PricingModelTableViewTypeOptions.CATALOG_PREVIEW_NOT_EDITABLE,
		].includes(pricingModelTableViewType) &&
		column.isHidden;
	return isHidden;
};

export const cellTypesList = [
	{ label: 'Item', value: CellTypes.ITEM_REFERENCE, isDisabled: true },
	{ label: 'Price', value: CellTypes.NUMBER, isDisabled: true },
	{ label: CellTypes.TEXT, value: CellTypes.TEXT },
	{ label: CellTypes.FORMULA, value: CellTypes.FORMULA },
	{ label: CellTypes.COUNT, value: CellTypes.COUNT },
	{ label: 'Usage', value: CellTypes.USAGE_AGGREGATOR_REFERENCE },
	{ label: CellTypes.PERCENTAGE, value: CellTypes.PERCENTAGE },
];

export const pricingModelTableColorsList = [
	colors.success400,
	colors.primary400,
	colors.calming400,
	colors.warning400,
	colors.destructive400,
	colors.success600,
	colors.primary600,
	colors.calming600,
	colors.warning600,
	colors.destructive600,
];

export const convertIdToColors = (columns: PricingModelTableColumns[]) => {
	const idToColor: { [id: string]: string } = {};
	columns.forEach((col, index) => {
		idToColor[col.id] = pricingModelTableColorsList[index % pricingModelTableColorsList.length];
	});
	return idToColor;
};

export const getUsageCellAggregationLabel = (method?: UsageAggregationFunction, scope?: UsageAggregationScope) => {
	return method && scope && `${method} Per ${scope.replaceAll('_', ' ')}`.toUpperCase();
};
