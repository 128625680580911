import { DatePickerFormatType, Tabs } from '../../../../../components';
import { Invoice } from '../../../../../types/invoiceTypes';
import { InvoiceContent } from '../../../../../components/PricingModelTabContent';
import { Customer } from '../../../../../types/customerTypes';
import { PricingModelTab } from '../../../../../types/contractTypes';
import { DisabledActions, SideBarData } from '../SingleInvoicePage.utils';
import { SidebarType } from '../../../../../components/Sidebars/Sidebars.utils';
import styles from './InvoiceProducts.module.scss';

interface InvoiceProductsProps {
	invoice: Invoice;
	customerSelected: Customer;
	isEditMode: boolean;
	selectedProductTab: number;
	disableActions: DisabledActions;
	selectedUsagePeriod?: DatePickerFormatType | undefined;
	setSelectedProductTab: (tab: number) => void;
	setOpenSideBarById: (sideBarData?: SideBarData) => void;
	onUpdateInvoicePricingModel: (data: PricingModelTab, instantUpdate?: boolean) => void;
}

export const InvoiceProducts = ({
	invoice,
	customerSelected,
	isEditMode,
	selectedProductTab,
	disableActions,
	selectedUsagePeriod,
	onUpdateInvoicePricingModel,
	setOpenSideBarById,
	setSelectedProductTab,
}: InvoiceProductsProps) => {
	return (
		<div>
			<Tabs
				id='pricing-model-tab-button'
				tabsArray={invoice?.pricingModel.tabs.map((tab) => ({ label: tab.name, labelTitle: tab.productName, value: tab.name }))}
				classNames={styles}
				updateChosenTab={setSelectedProductTab}
				chosenTab={selectedProductTab}
			/>
			<InvoiceContent
				invoice={invoice}
				selectedCustomer={customerSelected}
				pricingModelTab={invoice?.pricingModel.tabs[selectedProductTab]}
				updatePricingModelData={(data) => onUpdateInvoicePricingModel(data)}
				className={styles.tabsContent}
				isEditable={isEditMode}
				creditsAmount={invoice?.creditsAmount}
				disableActions={disableActions.disabledSaveAndClose}
				setOpenUsageSideBar={() => setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: '' })}
				selectedUsagePeriod={selectedUsagePeriod}
			/>
		</div>
	);
};
