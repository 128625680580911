import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { PricingModelTableRow, ItemPriceDto, ItemDto, DEFAULT_CURRENCY } from '@received/pricing-model';
import { useTranslation } from 'react-i18next';
import {
	Button,
	DragAndDropSelectItem,
	DragAndDropSelectOptions,
	Icon,
	MenuList,
	MenuOption,
	PricingModelTableClassNames,
	SelectedCell,
	SelectedCellWithIndex,
	Switch,
} from '../..';
import { canHideInInvoice, cellOptionsSelector, checkExistingOrCreateItem } from './TableRow.utils';
import { PricingModelTableViewTypeOptions, isColumnHidden, requiredColumnsEnum } from '../PricingModelTable.utils';
import { PricingModelTableColumns } from '../../../types/pricingModelTypes';
import { PricingModelTab } from '../../../types/contractTypes';
import styles from './TableRow.module.scss';

interface TableRowProps {
	rowData: PricingModelTableRow;
	columns: PricingModelTableColumns[];
	rowIndex: number;
	selectedCell?: SelectedCellWithIndex;
	isEditable?: boolean;
	classNames?: PricingModelTableClassNames;
	selectedRows?: boolean[];
	pricingModelTableViewType?: PricingModelTableViewTypeOptions;
	hoverOnFormula: boolean;
	columnsToColor: { [id: string]: string };
	selectedRowItem?: DragAndDropSelectItem;
	productItems: ItemPriceDto[];
	itemsList: ItemDto[];
	pricingModelTab?: PricingModelTab;
	setHoverOnFormula(isHover: boolean): void;
	onCellSelected(selectedCell: SelectedCell): void;
	onRemoveRow(): void;
	updateTableData(rowData: PricingModelTableRow): void;
	onArrowNavigation(nextCell: { rowIndex: number; columnIndex: number }): void;
	onUpdateSelectedRows?: (rowIndex: number, isSelected: boolean) => void;
	addManualUsageReport(): void;
	onHideShowRow(): void;
	onHideShowRowInInvoice(): void;
	refreshData(): void;
	openUsageSideBar(column: PricingModelTableColumns, columnIndex: number, rowIndex?: number): void;
}

export const TableRow = ({
	columns,
	rowData,
	rowIndex,
	selectedCell,
	isEditable,
	selectedRows,
	classNames,
	pricingModelTableViewType,
	selectedRowItem,
	itemsList,
	pricingModelTab,
	onRemoveRow,
	onCellSelected,
	onUpdateSelectedRows,
	onHideShowRow,
	onHideShowRowInInvoice,
	updateTableData,
	openUsageSideBar,
	...props
}: TableRowProps) => {
	const { t } = useTranslation('translation');
	const [openMenuOptions, setOpenMenuOptions] = useState(false);
	const [dragOver, setDragOver] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const target = document.getElementById(`${rowIndex}droptarget`);
		target?.addEventListener('dragover', (event) => {
			// prevent default to allow drop
			event.preventDefault();
		});

		target?.addEventListener('drop', (event) => {
			// prevent default action (open as link for some elements)
			event.preventDefault();
		});

		return () => {
			target?.removeEventListener('dragover', () => {});
			target?.removeEventListener('drop', () => {});
		};
	}, []);

	const menuOptions = () => {
		let rowOptions: MenuOption[] = [
			{ title: t('hideRow'), onPress: () => onHideShowRow(), iconType: 'closed_eye' },
			{ title: t('removeRow'), onPress: () => onRemoveRow(), iconType: 'error_icon', color: 'destructive' },
		];

		if (canHideInInvoice(rowData, pricingModelTableViewType)) {
			rowOptions = [
				{
					title: rowData.isHiddenInInvoice ? t('showRowInInvoice') : t('hideRowInInvoice'),
					onPress: () => onHideShowRowInInvoice(),
					iconType: 'hiddenInInvoice',
				},
				...rowOptions,
			];
		}
		return rowOptions;
	};

	const onDropSelectedItem = async () => {
		if (selectedRowItem && selectedRowItem.addOption === DragAndDropSelectOptions.DRAG_AND_DROP) {
			const newRowData = { ...rowData };
			newRowData.itemPriceId = selectedRowItem.item.itemPriceId;
			newRowData.cells = { ...newRowData.cells };

			const item = await checkExistingOrCreateItem(itemsList, selectedRowItem.item.name, dispatch);
			newRowData.itemPrice = {
				id: selectedRowItem.item.itemPriceId,
				itemId: item?.id,
				item,
				price: +selectedRowItem.item.unitPrice,
				currency: pricingModelTab?.currency || DEFAULT_CURRENCY,
			};

			newRowData.cells[requiredColumnsEnum.item].cellValue = selectedRowItem.item.name;
			newRowData.cells[requiredColumnsEnum.price].cellValue = selectedRowItem.item.unitPrice;
			updateTableData(newRowData);
		}

		setTimeout(() => setDragOver(false), 300);
	};

	return (
		<div
			id={`${rowIndex}droptarget`}
			onDrop={(event) => {
				setDragOver(true);
				event.stopPropagation();
				event.preventDefault();
				onDropSelectedItem();
			}}
			onDropCapture={(event) => {
				setDragOver(true);
				event.stopPropagation();
				event.preventDefault();
				onDropSelectedItem();
			}}
			className={
				rowData.isHidden
					? styles.hiddenRowContainer
					: clsx(
							styles.rowContainer,
							classNames?.rowContainer,
							!isEditable && pricingModelTableViewType != PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE && styles.notEditable,
							!isEditable && pricingModelTableViewType != PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE && classNames?.notEditable,
							dragOver && styles.selectedDagAndDropHover,
					  )
			}
		>
			{rowData.isHidden ? (
				<Button
					type='link'
					color='neutral'
					className={clsx(styles.flexCenter, styles.hiddenButton)}
					onClick={(event) => {
						event.stopPropagation();
						onHideShowRow();
					}}
				>
					<Icon imgType='doubleHumbugger' color='neutral400' height={1.8} className={styles.doubleHumbugger} />
				</Button>
			) : (
				<>
					{pricingModelTableViewType === PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE && (
						<Switch
							dataTestId={`table-row-switch-${rowIndex}`}
							defaultChecked={selectedRows && selectedRows[rowIndex]}
							onChange={(isChecked) => onUpdateSelectedRows && onUpdateSelectedRows(rowIndex, isChecked)}
							className={styles.switch}
						/>
					)}
					<div
						className={clsx(
							styles.row,
							selectedRows && !selectedRows[rowIndex] && styles.disableRow,
							classNames?.row,
							rowData.isHiddenInInvoice ? styles.hiddenInInvoice : '',
						)}
						style={{ pointerEvents: pricingModelTableViewType === PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE ? 'none' : 'auto' }}
					>
						{columns.map((column, index) => (
							<div
								key={column.id}
								data-testid={`${rowIndex}-row-${column.title}-column-cell`}
								onClick={() => onCellSelected({ cell: { ...rowData.cells[column.id] }, columnId: column.id })}
								className={clsx(styles.col, classNames?.col, isColumnHidden(column, pricingModelTableViewType) && styles.hideHeaderCell)}
								style={{ pointerEvents: !isEditable ? 'none' : 'auto' }}
							>
								{isColumnHidden(column, pricingModelTableViewType) ? (
									<div className={styles.hiddenColumn}>
										<div className={styles.hiddenCell}>|</div>
									</div>
								) : (
									cellOptionsSelector({
										column,
										rowIndex,
										columnIndex: index,
										rowData,
										selectedCell,
										pricingModelTableViewType,
										classNames,
										itemsList,
										pricingModelTab,
										updateTableData,
										onCellSelected,
										openUsageSideBar,
										...props,
									})
								)}
								<div className={styles.scrollableBorder}></div>
							</div>
						))}
					</div>

					<div
						className={clsx(
							styles.hamburgerContainer,
							selectedRows && !selectedRows[rowIndex] && styles.disableRow,
							rowData.isHiddenInInvoice ? styles.flexColumnCenter : styles.alignItemsCenter,
						)}
					>
						<Button
							type='link'
							color='neutral'
							className={rowData.isHiddenInInvoice ? (!isEditable ? styles.noPointer : '') : styles.iconHidden}
							onClick={(event) => {
								event.stopPropagation();
								onHideShowRowInInvoice();
							}}
						>
							<Icon imgType='hiddenInInvoice' color='neutral400' className={rowData.isHiddenInInvoice ? styles.icon : styles.iconHidden} />
						</Button>

						{pricingModelTableViewType != PricingModelTableViewTypeOptions.CATALOG_PREVIEW_EDITABLE && (
							<MenuList optionsList={menuOptions()} isMenuOpen={openMenuOptions} openMenu={() => setOpenMenuOptions(false)}>
								<Button type='link' color='neutral' onClick={() => setOpenMenuOptions(!openMenuOptions)}>
									<Icon imgType='hamburger' color='neutral700' className={styles.hamburger} />
								</Button>
							</MenuList>
						)}
					</div>
				</>
			)}
		</div>
	);
};
