import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AccountingEventType, convertCurrencyToSign } from '@received/pricing-model';
import { AdvancedSettings, Badge, Input, PricingModelTableClassNames } from '../..';
import { PricingModelTab } from '../../../types/contractTypes';
import { getValueWithCurrencySign } from '../../../utils/NumberUtils';
import styles from './AdditionalSettings.module.scss';

interface AdditionalSettingsProps {
	pricingModelTab?: PricingModelTab;
	headerChildren?: React.ReactNode;
	classNames?: PricingModelTableClassNames;
	updatePricingModelData?: (data: PricingModelTab) => void;
}

export const AdditionalSettings = ({ pricingModelTab, headerChildren, classNames, updatePricingModelData }: AdditionalSettingsProps) => {
	const { t } = useTranslation('translation');
	const [openSettings, setOpenSettings] = useState(false);

	return (
		<div className={clsx(styles.header, classNames?.settings)}>
			<div>
				<span>{t('pricing')}</span>
				{!!pricingModelTab?.minimumFee && (
					<Badge color='neutral' type='secondary' className={styles.badge} dataTestId='minimum-fee-badge'>
						<span data-testid='minimum-fee-badge-text'>
							{pricingModelTab.currency &&
								`${t('minimumFee')}: ${getValueWithCurrencySign(pricingModelTab.minimumFee, convertCurrencyToSign(pricingModelTab.currency))}`}
						</span>
					</Badge>
				)}
			</div>

			<div className={styles.flex}>
				{headerChildren}
				{pricingModelTab?.accountingEventType !== AccountingEventType.CREDIT && (
					<AdvancedSettings
						title={t('advancedSettings')}
						openSettings={openSettings}
						setOpenSettings={setOpenSettings}
						settingsChildren={
							<Input
								autoFocus
								title={t('minimumFee')}
								iconType='information'
								placeholder='0.00'
								type='number'
								defaultValue={pricingModelTab?.minimumFee && pricingModelTab?.minimumFee > 0 ? pricingModelTab?.minimumFee : undefined}
								data-testid='minimum-fee-input'
								leftChildren={
									<span className={pricingModelTab?.minimumFee && pricingModelTab?.minimumFee > 0 ? styles.blackText : styles.greyText}>
										{pricingModelTab?.currency && convertCurrencyToSign(pricingModelTab.currency)}
									</span>
								}
								onChange={(minimumFee) => pricingModelTab && updatePricingModelData?.({ ...pricingModelTab, minimumFee: Number(minimumFee) })}
								onKeyDown={(event) => {
									if (event.key === 'Enter') {
										setOpenSettings(false);
									}
								}}
							/>
						}
					/>
				)}
			</div>
		</div>
	);
};
