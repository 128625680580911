import { CreditNoteReasonCode, DATE_FORMAT } from '@received/pricing-model';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	Badge,
	Button,
	CustomerDetails,
	DocumentDetail,
	Icon,
	Select,
	DatePicker,
	DatePickerFormatType,
	PDFPreviewModal,
	GroupButton,
} from '../../../../components';
import { successErrorMassageOptions } from '../../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { creditNoteReasonCodesLabelsSelect } from '../../../../constants/generalConstants';
import { CREDIT_BLANK_PRICING_MODEL_ID } from '../../../../constants/templateConstants';
import { useDebounce } from '../../../../hooks';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { setSidebarIsOpen } from '../../../../storeSlices/generalSlice';
import { PricingModel, PricingModelTab } from '../../../../types/contractTypes';
import { CreditNote } from '../../../../types/creditNoteTypes';
import { Store } from '../../../../types/storeTypes';
import {
	getCreditNoteDateDescription,
	creditNoteStatus,
	initialCreditNoteState,
	isCreditNoteEditable,
	getCreditNoteIdName,
	startGenerateCreditNotePDFById,
} from '../../../../utils/CreditNoteUtils';
import {
	createCreditNote,
	creditNoteTabs,
	getCreditNoteCurrentNumber,
	isTabEditable,
	setCollectiveDataInTabs,
	updateCreditNote,
} from './SingleCreditNote.utils';
import { getPricingModel, onUpdateUsageManualReportFromTable } from '../../../../utils/pricingModelUtils';
import { contractIdFormat } from '../../../../utils/ContractUtils';
import styles from './SingleCreditNote.module.scss';

export const SingleCreditNote = () => {
	const [creditNote, setCreditNote] = useState<CreditNote>(initialCreditNoteState);
	const [openCreditNotePreview, setOpenCreditNotePreview] = useState(false);
	const [viewOnlyMode, setViewOnlyMode] = useState(false);
	const [openDatePicker, setOpenDatePicker] = useState(false);
	const [selectedProductTab, setSelectedProductTab] = useState(0);
	const [activeTab, setActiveTab] = useState(0);

	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);
	const { t } = useTranslation('translation');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location: any = useLocation();
	const { appCurrency } = useSelector((store: Store) => store.general);

	useEffect(() => {
		location?.state?.creditNoteId ? getCreditNote(location?.state?.creditNoteId) : setInitialCreditNote();
		isOpen && dispatch(setSidebarIsOpen(false));
	}, []);

	const getCreditNote = async (id: string) => {
		try {
			const data: CreditNote = (
				await httpService({
					dispatch,
					path: PathsConfig.getCreditNoteData,
					urlParam: { id },
				})
			).data;

			setCreditNote(data);
			setViewOnlyMode(!isCreditNoteEditable(data));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTE }));
		}
	};

	const setInitialCreditNote = async () => {
		const blankPricingModel = await getPricingModel(CREDIT_BLANK_PRICING_MODEL_ID, dispatch);
		const creditNoteCurrentNumber = await getCreditNoteCurrentNumber(dispatch);

		const newCreditNote = { ...creditNote };

		newCreditNote.pricingModel = { ...creditNote, ...blankPricingModel };
		newCreditNote.customer = location.state.customer;
		newCreditNote.customerId = location.state.customer.id;
		newCreditNote.documentNumber = creditNoteCurrentNumber.value;
		newCreditNote.supplierId = location.state.customer.supplierId;
		newCreditNote.pricingModel.tabs.forEach((tab) => {
			tab.supplierId = location.state.customer?.supplierId;
		});
		newCreditNote.pricingModel.tabs.forEach((tab) => {
			tab.currency = appCurrency;
		});
		newCreditNote.currency = appCurrency;
		setCreditNote(newCreditNote);
		setViewOnlyMode(false);
	};

	const onPublishCreditNote = async () => {
		const newCreditNote = await createCreditNote(dispatch, creditNote);
		newCreditNote && (await publishCreditNote(newCreditNote.id));
	};

	const onUpdateCreditNote = async () => {
		await updateCreditNote(dispatch, creditNote);
		navigate(-1);
	};

	const publishCreditNote = async (creditNoteId?: string) => {
		if (!creditNoteId) return;
		try {
			await httpService({
				dispatch,
				path: PathsConfig.publishCreditNote,
				urlParam: { id: creditNoteId },
			});
			navigate(-1);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_POST_CREDIT_NOTE }));
		}
	};

	const setCreditNoteProductByIndex = (newPricingModelTab: PricingModelTab, tabIndex: number) => {
		// pricing model id should be reused from prev and not created in container
		if (!viewOnlyMode) {
			setCreditNote((prev) => {
				let newCreditNote = { ...prev };
				newCreditNote.pricingModel.tabs[tabIndex] = newPricingModelTab;
				newCreditNote.balance = newPricingModelTab.pricingModelTableTotal;
				newCreditNote.subTotal = newPricingModelTab.pricingModelTableTotal;
				newCreditNote.tax = newPricingModelTab.tax;
				newCreditNote.totalTax = (newPricingModelTab.pricingModelTableTotal * newPricingModelTab.tax) / 100;
				newCreditNote.total = (newPricingModelTab.pricingModelTableTotal * newPricingModelTab.tax) / 100 + newPricingModelTab.pricingModelTableTotal;
				newCreditNote.currency = newPricingModelTab.currency;

				newCreditNote = { ...newCreditNote, ...setCollectiveDataInTabs(newCreditNote, newPricingModelTab) };
				return newCreditNote;
			});
		}
	};

	const setCreditNotePricingModel = (newPricingModel: PricingModel) => {
		if (isTabEditable(activeTab, viewOnlyMode)) {
			setCreditNote((prev) => ({ ...prev, pricingModel: { ...prev.pricingModel, ...newPricingModel } }));
		}
	};

	const onUpdateManualReportFromTable = async () => {
		if (creditNote?.id) {
			const creditNoteData: CreditNote = (
				await httpService({
					dispatch,
					path: PathsConfig.getCreditNoteData,
					urlParam: { id: creditNote?.id },
				})
			).data;

			const newCreditNote = onUpdateUsageManualReportFromTable(creditNoteData, creditNote) as CreditNote;
			setCreditNote((prev) => ({ ...prev, ...newCreditNote }));
		}
	};

	const onUpdateCreditNotePricingModel = useDebounce(setCreditNoteProductByIndex, 350);

	return (
		<div className={styles.globalPageContainer}>
			<div className={clsx(styles.content, viewOnlyMode && styles.notAllowedCursor)}>
				<div className={clsx(styles.creditNoteContainer, viewOnlyMode && styles.locked)}>
					<div className={styles.headerContainer}>
						<div className={styles.topContainer}>
							<div className={styles.flex}>
								<Button type='outlined' color='neutral' onClick={() => navigate(-1)} className={clsx(styles.goBack, viewOnlyMode && styles.editable)}>
									<Icon imgType='arrow_back' color='neutral700' width={1.8} />
								</Button>

								<div className={styles.customerDetails}>
									<CustomerDetails
										name={creditNote?.customer?.name}
										country={creditNote?.customer?.country}
										currency={creditNote?.customer?.currency}
										ein={creditNote?.customer?.ein}
										email={creditNote?.customer?.email}
										hideLogo
									/>
								</div>
							</div>
							<div className={clsx(styles.actions, styles.editable)}>
								{creditNote?.pricingModel && (
									<Button
										type='outlined'
										color='neutral'
										onClick={() => setOpenCreditNotePreview(!openCreditNotePreview)}
										className={clsx(styles.eyeButton, viewOnlyMode && styles.editable)}
									>
										<Icon imgType={openCreditNotePreview ? 'closed_eye' : 'eye'} color='neutral' height={2} />
									</Button>
								)}

								<Button
									onClick={creditNote?.id ? onUpdateCreditNote : onPublishCreditNote}
									color='success'
									type='outlined'
									className={styles.primaryButton}
								>
									{creditNote?.id ? t('updateCreditNote') : t('publishCreditNote')}
								</Button>
							</div>
						</div>

						<div className={styles.metadata}>
							<DocumentDetail
								title={t('creditNoteNo')}
								body={getCreditNoteIdName(creditNote) || t('draft')}
								className={styles.documentNumber}
								footer={
									!!creditNote?.state && (
										<Badge
											color={creditNoteStatus(creditNote?.state).color}
											type={creditNoteStatus(creditNote?.state).type}
											className={styles.badge}
											dataTestId='credit-note-badge'
										>
											<span>{t(creditNoteStatus(creditNote?.state).text)}</span>
										</Badge>
									)
								}
							/>

							<DocumentDetail
								title={t('issueDate')}
								body={dayjs(creditNote.issueDate).format(DATE_FORMAT)}
								footer={getCreditNoteDateDescription(creditNote.issueDate)}
								displayArrowIcon={!!creditNote?.contractId}
								onClick={() => !!creditNote?.contractId && setOpenDatePicker(true)}
								flipArrow={openDatePicker}
							/>

							<DatePicker
								hideInput
								openDatePicker={openDatePicker}
								defaultFromDate={creditNote.issueDate}
								updateDate={(date: DatePickerFormatType) => setCreditNote((prev) => ({ ...prev, issueDate: date.fromDate || undefined }))}
								updateIsOpen={() => setOpenDatePicker(false)}
							/>

							<DocumentDetail
								title={t('reasonCode')}
								body={
									<Select
										width={22.5}
										defaultValue={creditNote?.reasonCode || creditNoteReasonCodesLabelsSelect[0].value}
										data={creditNoteReasonCodesLabelsSelect}
										onChange={(value: CreditNoteReasonCode) => setCreditNote((prev) => ({ ...prev, reasonCode: value || undefined }))}
										className={styles.select}
									/>
								}
								footer={!creditNote?.contractId && t('adjustment')}
							/>

							{creditNote?.contractId && (
								<DocumentDetail
									title={t('creditPeriod')}
									body={`${dayjs(creditNote?.pricingModel?.tabs[selectedProductTab]?.billingStartDate).format(DATE_FORMAT)} - ${dayjs(
										creditNote?.pricingModel?.tabs[selectedProductTab]?.billingEndDate,
									).format(DATE_FORMAT)}`}
									footer={contractIdFormat(creditNote?.poNumber, creditNote?.contractNumber)}
								/>
							)}
						</div>
					</div>
					<GroupButton
						buttonArray={creditNoteTabs().map((tab) => ({ children: tab.title }))}
						buttonClassName={styles.tabComponent}
						className={styles.mainTabsContainer}
						selectedIndex={activeTab}
						setSelectedIndex={setActiveTab}
					/>

					<div className={styles.bottomContainer}>
						{creditNoteTabs()[activeTab].component({
							creditNote,
							pricingModel: creditNote?.pricingModel,
							isEditMode: isTabEditable(activeTab, viewOnlyMode),
							selectedProductTab,
							className: styles.settingClassName,
							setSelectedProductTab,
							onUpdateCreditNotePricingModel: (data: PricingModelTab) => onUpdateCreditNotePricingModel(data, selectedProductTab),
							onUpdateManualReportFromTable,
							updateSettings: setCreditNotePricingModel,
						})}
					</div>
				</div>
				{creditNote.pricingModel?.tabs && (
					<PDFPreviewModal
						isOpen={openCreditNotePreview}
						documentsList={[creditNote]}
						onClose={() => setOpenCreditNotePreview(false)}
						onDownload={() => creditNote?.id && startGenerateCreditNotePDFById(dispatch, creditNote?.id)}
					/>
				)}
			</div>
		</div>
	);
};
