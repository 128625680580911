import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { Button, Icon } from '..';
import styles from './AdvancedSettings.module.scss';

interface AdvancedSettingsProps {
	settingsChildren: React.ReactNode;
	title?: string;
	openSettings?: boolean;
	setOpenSettings?: Dispatch<SetStateAction<boolean>>;
}

export const AdvancedSettings = ({ title, settingsChildren, openSettings, setOpenSettings }: AdvancedSettingsProps) => {
	const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
	const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		setIsOpen(!!openSettings);
	}, [openSettings]);

	const { styles: style, attributes } = usePopper(referenceElement, popperElement, {
		placement: 'bottom',
		modifiers: [
			{
				name: 'offset',
				enabled: true,
				options: {
					offset: [-130, 10],
				},
			},
		],
	});

	return (
		<>
			<div style={{ ...style.offset }} ref={(ref) => setReferenceElement(ref)}>
				<Button
					type='outlined'
					color='neutral'
					className={styles.advancedSettings}
					onClick={() => {
						setIsOpen(true);
						setOpenSettings?.(true);
					}}
				>
					<Icon imgType='settings' color='neutral900' width={1.5} />
				</Button>
			</div>
			{isOpen
				? ReactDOM.createPortal(
						<>
							<div
								onClick={() => {
									setIsOpen(false);
									setOpenSettings?.(false);
								}}
								className={styles.modalOverlay}
							/>
							<div
								className={styles.container}
								ref={(ref) => setPopperElement(ref)}
								{...attributes.popper}
								data-testid='menu-list-portal'
								style={style.popper}
								onClick={(e) => {
									e.stopPropagation();
								}}
							>
								<span className={styles.title}>{title}</span>
								{settingsChildren}
							</div>
						</>,
						document.body,
				  )
				: null}
		</>
	);
};
