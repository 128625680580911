import { CSSProperties } from 'react';
import clsx from 'clsx';
import { Icon, IconImgType } from '..';
import styles from './EmptyStateCard.module.scss';

export interface EmptyStateCardProps {
	src?: string;
	imgAlt?: string;
	imgType?: IconImgType;
	title?: string;
	subTitle?: string;
	titleStyle?: string;
	subTitleStyle?: string;
	containerStyle?: string;
	imgContainerStyle?: string;
	bodyContainerClassName?: string;
	emptyIconClassName?: string;
	children?: React.ReactNode;
	showIcon?: boolean;
	emptyIconContainer?: string;
}

export const EmptyStateCard = ({
	src,
	imgAlt,
	title,
	subTitle,
	titleStyle,
	subTitleStyle,
	containerStyle,
	bodyContainerClassName,
	children,
	imgContainerStyle,
	imgType,
	emptyIconClassName,
	showIcon,
	emptyIconContainer,
}: EmptyStateCardProps) => {
	return (
		<div className={clsx(styles.container, containerStyle)}>
			<div className={imgContainerStyle}>
				{showIcon ? (
					<div className={clsx(styles.emptyIconContainer, emptyIconContainer)}>
						<Icon color='neutral400' imgType={imgType || 'searchCircle'} height={3} />
					</div>
				) : (
					<img alt={imgAlt} src={src} className={clsx(styles.emptyIcon, emptyIconClassName)} />
				)}
			</div>
			<div className={clsx(styles.flexColumnCenter, bodyContainerClassName)}>
				<span className={clsx(styles.title, titleStyle)} style={{ display: 'block' }}>
					{title || ''}
				</span>
				<span className={clsx(styles.subTitle, subTitleStyle)}>{subTitle || ''}</span>
				{children}
			</div>
		</div>
	);
};
