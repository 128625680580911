import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { ActionSelect, Button, Icon, MenuList, MenuOption, Tabs } from '../../components/_uiComponents';
import { UsagePageTabIndex, isUsageReportEditable, usagePageTabs } from './Usage.utils';
import { Store } from '../../types/storeTypes';
import { DateRangeFilters, ImportItemsProgress, Sidebars, UsageProductModal } from '../../components';
import { fullFiltersSelectOptions, generalTimePeriodInitialState } from '../../components/Filters/DateRangeFilters.utils';
import { DatesData } from '../../types/generalTypes';
import { SidebarType } from '../../components/Sidebars/Sidebars.utils';
import { setSidebarIsOpen } from '../../storeSlices/generalSlice';
import { UsageReportView } from '../../types/usageTypes';
import { usageReportActionsOptions } from './UsagePageTabs/UsageReportsTab/UsageReportsTab.utils';
import styles from './Usage.module.scss';

export const Usage = () => {
	//FILTERS
	const [activeTab, setActiveTab] = useState(0);
	const [timePeriod, setTimePeriod] = useState<DatesData>(generalTimePeriodInitialState);

	//MODALS & SIDEBARS
	const [isAddUsageProductOpen, setIsAddUsageProductOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [openSideBarById, setOpenSideBarById] = useState<{ sideBarType: SidebarType; id: string }>();

	//USAGE PRODUCTS
	const [selectedUsageProduct, setSelectedUsageProduct] = useState<string>();
	const [selectedUsageProductsIds, setSelectedUsageProductsIds] = useState<string[]>([]);

	//USAGE REPORTS
	const [selectedUsageReport, setSelectedUsageReport] = useState<UsageReportView>();
	const [selectedUsageList, setSelectedUsageList] = useState<UsageReportView[]>([]);
	const [refreshTab, setReportsTab] = useState(false);

	const { t } = useTranslation('translation');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const isOpen = useSelector((store: Store) => store.general.isSidebarOpen);

	useEffect(() => {
		!isOpen && dispatch(setSidebarIsOpen(true));
	}, []);

	const addUsageMenuOptions: MenuOption[] = [
		{
			title: t('manualReport'),
			onPress: () => setOpenSideBarById({ sideBarType: SidebarType.USAGE_REPORT, id: '' }),
			iconType: 'add',
			iconWidth: 1,
		},
		{
			title: t('importUsageLogs'),
			onPress: () => navigate('/usage/import-usage-logs'),
			iconType: 'addDocument',
		},
		{
			title: t('createUsageProduct'),
			onPress: () => setIsAddUsageProductOpen(true),
			iconType: 'graph',
		},
	];

	return (
		<div className={styles.globalPageContainer}>
			<div className={styles.pageContainer}>
				<ImportItemsProgress onRefresh={() => setReportsTab(!refreshTab)} />

				<header className={styles.pageHeader}>
					<span className={styles.pageTitle}>{t('usage')}</span>

					<div className={styles.buttonsContainer}>
						<DateRangeFilters data={fullFiltersSelectOptions} defaultSelectValue={timePeriod.title} callback={setTimePeriod} />
						<Button
							type='outlined'
							color='neutral'
							onClick={() => setActiveTab(UsagePageTabIndex.reports)}
							className={clsx(activeTab !== UsagePageTabIndex.products && styles.hidden)}
						>
							{t('allActivities')}
							<Icon imgType='navigate' color='neutral700' />
						</Button>
						<MenuList optionsList={addUsageMenuOptions} isMenuOpen={isMenuOpen} openMenu={() => setIsMenuOpen(false)}>
							<Button onClick={() => setIsMenuOpen(!isMenuOpen)} className={styles.menuButtons}>
								<Icon imgType='add' color='white' height={1.1} />
							</Button>
						</MenuList>

						{!!selectedUsageList.length && (
							<ActionSelect
								title={`${t('selectAction')} ${`(${selectedUsageList.length} ${t(selectedUsageList.length > 1 ? 'usageReports' : 'usageReport')})`}`}
								optionList={usageReportActionsOptions(selectedUsageList, t, dispatch, () => {
									setReportsTab(!refreshTab);
									setSelectedUsageList([]);
								})}
								disabled={!!selectedUsageList.find((item) => !isUsageReportEditable(item))}
							/>
						)}
					</div>
				</header>
				<Tabs
					chosenTab={activeTab}
					tabsArray={usagePageTabs.map((tab) => t(tab.title) as string)}
					updateChosenTab={(val: number) => {
						setActiveTab(val);
						setSelectedUsageList([]);
					}}
					style={{ padding: '0 2.4rem' }}
					classNames={styles}
				/>
				<div className={styles.tabsContent}>
					{usagePageTabs[activeTab].component({
						refreshTab,

						//FILTERS
						timePeriod,
						isActive: activeTab == usagePageTabs.findIndex((tab, index: number) => index == activeTab),
						setActiveTab,

						//SELECTED PRODUCTS
						selectedUsageProduct,
						selectedUsageProductsIds,
						setSelectedUsageProductsIds,
						setSelectedUsageProduct,

						//USAGE REPORTS
						selectedUsageReport,
						setSelectedUsageReport,
						selectedUsageList,
						setSelectedUsageList,

						//SIDE BAR & MODALS
						openSideBarById,
						isAddUsageProductOpen,
						setOpenSideBarById,
						setIsAddUsageProductOpen,
					})}
				</div>
				<UsageProductModal
					isOpen={isAddUsageProductOpen}
					selectedUsageProductId={selectedUsageProduct}
					closeModal={() => {
						setIsAddUsageProductOpen(false);
						setSelectedUsageProductsIds([]);
						setSelectedUsageProduct(undefined);
					}}
				/>

				<Sidebars
					selectedUsageReport={selectedUsageReport}
					openSideBarById={openSideBarById}
					selectedUsagePeriod={{
						fromDate: selectedUsageReport?.endDate || null,
						toDate: selectedUsageReport?.endDate || null,
					}}
					onCloseSideBars={() => {
						setOpenSideBarById(undefined);
						setSelectedUsageReport(undefined);
					}}
				/>
			</div>
		</div>
	);
};
