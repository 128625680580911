import { ChosenTabItem } from '../../components';
import { UserRole } from '../../types/userTypes';
import { CompanyInfo } from './CompanyInfo/CompanyInfo';
import { Integrations } from './Integrations/Integrations';
import { UsersPermissions } from './UsersPermissions/UsersPermissions';
import { AccountPermissions, AccountPermissionsActionsList } from '../../types/generalTypes';
import { DocumentSettings } from './DocumentSettings/DocumentSettings';
import { PersonalInfo } from './PersonalInfo/PersonalInfo';
import { Dunning } from './Dunning/Dunning';

export enum Sections {
	ACCOUNT,
	COMPANY,
	BILLING,
}

export enum AccountTabs {
	PERSONAL_INFO,
}

export enum CompanyTabs {
	COMPANY_INFO,
	USERS_PERMISSIONS,
	INTEGRATIONS,
}

export enum BillingTabs {
	DOCUMENT_SETTINGS,
	DUNNING,
}

type ChosenTabItemComponent = ChosenTabItem & { component: (prop?: any) => React.ReactNode };

export const settingsPageAccountTabs: ChosenTabItemComponent[] = [
	{ label: 'Personal Info', iconImgType: 'user', value: '', component: (prop?: any) => <PersonalInfo /> },
	// { label: 'Password', iconImgType: 'customersEmpty', value: '', component: (prop?: any) => <div>Password</div> },
	// { label: 'Language & Region', iconImgType: 'customersEmpty', value: '', component: (prop?: any) => <div>Language & Region</div> },
];

export const settingsPageCompanyTabs: ChosenTabItemComponent[] = [
	{ label: 'Company Info', value: '', iconImgType: 'office_building', component: (prop?: any) => <CompanyInfo {...prop} /> },
	{ label: 'Users Permissions', value: '', iconImgType: 'users', component: (prop?: any) => <UsersPermissions {...prop} /> },
	{ label: 'Integrations', value: '', iconImgType: 'integrations', component: (prop?: any) => <Integrations {...prop} /> },
];

export const settingsPageBillingTabs: ChosenTabItemComponent[] = [
	{
		label: 'Documents',
		value: '',
		iconImgType: 'writingBoard',
		actionType: AccountPermissionsActionsList.DOCUMENT_SETTINGS,
		component: (prop?: any) => <DocumentSettings {...prop} />,
	},
	{
		label: 'Dunning',
		value: '',
		iconImgType: 'v_circle',
		actionType: AccountPermissionsActionsList.DUNNING,
		component: () => <Dunning />,
	},
];

export interface AuthorizedPage {
	header: string;
	description: string;
	requiredRole?: UserRole;
	items: ChosenTabItemComponent[];
	actionType?: AccountPermissions;
}

export const settingsNestedPages: AuthorizedPage[] = [
	{
		header: 'settingsAccount',
		description: 'settingsAccountDescription',
		items: settingsPageAccountTabs,
	},
	{
		header: 'settingsCompany',
		description: 'settingsCompanyDescription',
		requiredRole: UserRole.Admin,
		items: settingsPageCompanyTabs,
	},
	{
		header: 'settingsBilling',
		description: '',
		items: settingsPageBillingTabs,
	},
];
