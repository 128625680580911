import { Dispatch } from '@reduxjs/toolkit';
import { EventType, IntegrationDataSyncStatus, SyncObjectType } from '@received/pricing-model';
import { ToastNotificationComponentType } from '../../components/NotificationCard/NotificationCard.utils';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { setListenerResponseType, setShowHideToastNotification, setShowImportedDataIndicator } from '../../storeSlices/generalSlice';
import { AsyncMessageData, AsyncMessageStatus } from '../../types/generalTypes';
import { colors } from '../../constants/colors';
import { setAddResyncAccountingIntegration, setRefreshAfterResync } from '../../storeSlices/IntegrationsSlice';
import { AccountingItemsExport } from '../../Integrations/OutsourceIntegrations/IntegrationLayout.utils';
import { downloadDocumentPDFById } from '../../utils/DocumentsUtils';

export const handleListenerChannelMessages = (t: any, event: any, dispatch: Dispatch) => {
	const eventMessage: AsyncMessageData = JSON.parse(event.data);

	switch (eventMessage.type) {
		case EventType.INVOICE_CHARGE_COMPLETED:
			return dispatch(
				setShowImportedDataIndicator({
					totalItems: eventMessage.data?.totalRequested || 0,
					importedItems: eventMessage.data?.succeeded?.length + eventMessage.data?.failed?.length || 0,
					barTitle: t('charged'),
					cardTitle: t('chargingInvoicesHasBeenCompleted'),
				}),
			);

		case EventType.CHARGE_INVOICES:
			return dispatch(
				setShowImportedDataIndicator({
					totalItems: eventMessage.data?.totalRequested || 0,
					importedItems: eventMessage.data?.succeeded?.length + eventMessage.data?.failed?.length || 0,
					barTitle: t('charged'),
					cardTitle: t('chargingInvoicesHasBeenCompleted'),
				}),
			);

		case EventType.CHANGED_INTEGRATION_ACCOUNT:
			return dispatch(setListenerResponseType(EventType.CHANGED_INTEGRATION_ACCOUNT));

		case EventType.SEND_INVOICE_BY_EMAIL:
			if (eventMessage.status == AsyncMessageStatus.SUCCESS) {
				dispatch(setListenerResponseType(EventType.SEND_INVOICE_BY_EMAIL));
				return dispatch(
					setShowImportedDataIndicator({
						totalItems: 1,
						importedItems: 1,
						barTitle: t('sent'),
						cardTitle: t('theSendingProcessHasBeenCompleted'),
					}),
				);
			}
			return dispatch(
				setOpenSuccessErrorModal({
					responseError: new Error(),
					responseId: event?.originRequestId || '',
					successErrorMassage: successErrorMassageOptions.ERROR.SEND_DOCUMENT,
				}),
			);

		case EventType.NEW_CONTRACT_IMPORTED:
			return dispatch(
				setShowHideToastNotification({
					showToastNotification: true,
					toastNotificationComponent: ToastNotificationComponentType.ImportedData,
					title: 'newContractsImported',
					additionalData: {
						count: eventMessage.data.newContractCount,
						importedDataSingleType: 'contract',
						importedDataMultipleType: 'contracts',
						platform: 'Salesforce',
					},
				}),
			);

		case EventType.NEW_USAGE_REPORT_IMPORTED: {
			return dispatch(
				setShowImportedDataIndicator({
					totalItems: eventMessage.data?.newUsageReportTotal || 0,
					importedItems: eventMessage.data?.newUsageReportCount || 0,
				}),
			);
		}

		case EventType.SEND_DOCUMENTS: {
			return dispatch(
				setShowImportedDataIndicator({
					totalItems: eventMessage.data?.totalRequested || 0,
					importedItems: eventMessage.data?.succeeded?.length + eventMessage.data?.failed?.length || 0,
					barTitle: t('sent'),
					cardTitle: t('theSendingProcessHasBeenCompleted'),
				}),
			);
		}

		case EventType.PDF_GENERATION_COMPLETED: {
			return downloadDocumentPDFById(dispatch, eventMessage.data?.generationId, eventMessage.data?.countCompleted);
		}

		case EventType.PUSH_INVOICE_TO_ACCOUNTING: {
			return dispatch(
				setShowHideToastNotification({
					showToastNotification: true,
					toastNotificationComponent: ToastNotificationComponentType.GeneralNotification,
					title: 'finishSyncing',
					description: 'pleaseRefreshThePage',
					sideColor: colors.success,
				}),
			);
		}

		case EventType.INTEGRATION_DATA_SYNCED: {
			dispatch(
				setAddResyncAccountingIntegration({
					[AccountingItemsExport.Accounts]: IntegrationDataSyncStatus.SYNCED,
					[AccountingItemsExport.Customers]: IntegrationDataSyncStatus.SYNCED,
					[AccountingItemsExport.Items]: IntegrationDataSyncStatus.SYNCED,
				}),
			);
			return dispatch(setRefreshAfterResync(true));
		}
		case EventType.INTEGRATION_DATA_TYPE_SYNCED: {
			let key;
			switch (eventMessage.data) {
				case SyncObjectType.CUSTOMER: {
					key = AccountingItemsExport.Customers;
					break;
				}
				case SyncObjectType.BILLING_ACCOUNT: {
					key = AccountingItemsExport.Accounts;
					break;
				}
				case SyncObjectType.ITEM: {
					key = AccountingItemsExport.Items;
				}
			}
			if (key) {
				dispatch(
					setAddResyncAccountingIntegration({
						[key]: IntegrationDataSyncStatus.SYNCED,
					}),
				);
				return dispatch(setRefreshAfterResync(true));
			}
		}
	}
};
