import { Currency, PaymentTerms } from '@received/pricing-model';
import { Subscription } from '../../types/subscriptionTypes';

export const defaultSubscription = {
	name: '',
	netTerms: PaymentTerms.DUE_UPON_RECEIPT,
	currency: Currency.USD,
	discount: 0,
	tax: 0,
	pricingModel: { tabs: [], sourcePricingModelIds: [] },
	autoCharge: true,
	showNoteInInvoice: true,
	note: '',
};

export const subscriptionValidation = (subscription: Subscription) => {
	const errors: string[] = [];
	if (!subscription.name) {
		errors.push('missingName');
	}
	if (!subscription.pricingModel.tabs.length) {
		errors.push('missingProduct');
	}
	if (!subscription.supplierId) {
		errors.push('missingDebitEntity');
	}
	if (!subscription.billingDetails?.paymentGatewayId) {
		errors.push('missingStripeAccount');
	}
	return errors;
};
