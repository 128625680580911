import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, CloseButton, Icon, Modal, Pagination, Select } from '../_uiComponents';
import { handleSwitchCustomerRows, setDuplicateDataForSimpleRows, setRowStatus } from './CSVDataMatcherModal.utils';
import { CSVDataMatcherTable } from './CSVDataMatcherTable/CSVDataMatcherTable';
import { Store } from '../../types/storeTypes';
import { RowStatus, validateCustomerInput } from '../../Integrations';
import { splitArray } from '../../utils/GeneralUtils';
import { setShowCreateIndicator } from '../../storeSlices/generalSlice';
import { Customer, CustomersDetails } from '../../types/customerTypes';
import { IndexInCustomerCSVFile } from '../../Integrations/UploadCSVMatcher/UploadCSVMatcher.utils';
import { getExistsCustomerList } from '../../utils/CustomerUtils';
import styles from './CSVDataMatcherModal.module.scss';

export interface CSVDataMatcherModalProps {
	isOpen: boolean;
	suppliersList: Customer[];
	importedData: {
		header: string[];
		rows: any[];
		originalHeader: string[];
		originalRows: any[];
		rowsWithOldValues: any[];
		existedCustomers: CustomersDetails;
	};
	title?: string;
	tableColumns: { columns: string[]; requiredColumns: number[]; disabledColumns: number[] };
	texts: { title: string; subTitle: string; template: string; templateName: string };
	closeModal(): void;
	onImportData(rows: any[], existedDataFromServer?: CustomersDetails): void;
}

export interface CSVRow {
	rowIndex: number;
	isValid: boolean;
	isDisabled: boolean;
	row: any;
	oldRow: any;
}

const DEFAULT_PAGE_SIZE = '20';

export const CSVDataMatcherModal = ({
	isOpen,
	importedData,
	tableColumns,
	suppliersList,
	texts,
	onImportData,
	closeModal,
}: CSVDataMatcherModalProps) => {
	const { t } = useTranslation('translation');
	const geoLocationData = useSelector((store: Store) => store.general.geoLocationData);

	const mandatoryColumns = tableColumns.requiredColumns;

	const [headerOptions, setHeaderOptions] = useState<string[]>([]);

	const [importedRows, setImportedRows] = useState<CSVRow[]>([]);
	const [splicedRows, setSplicedRows] = useState<CSVRow[][]>([]);

	const [invalidRows, setInvalidRows] = useState<CSVRow[]>([]);
	const [disableButton, setDisableButton] = useState(true);

	const [showInvalid, setShowInvalid] = useState(false);
	const [hideDisabled, setHideDisabled] = useState(false);

	const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
	const [currentPage, setCurrentPage] = useState(0);
	const [invalidHeaderTypes, setInvalidHeaderTypes] = useState<number[]>([]);

	const [existedDataFromServer, setExistedDataFromServer] = useState<CustomersDetails>();
	const [duplicateDataInTable, setDuplicateDataInTable] = useState<CustomersDetails>();

	const dispatch = useDispatch();

	useEffect(() => {
		isOpen && dispatch(setShowCreateIndicator(false));
	}, [isOpen]);

	useEffect(() => {
		if (isOpen) {
			setExistedDataFromServer(importedData?.existedCustomers);
		}
	}, [importedData?.existedCustomers]);

	useEffect(() => {
		if (isOpen && existedDataFromServer) {
			setHeaderOptions(importedData.header);
			checkValidation(importedData.rows, importedData.header);
		}
	}, [isOpen, importedData.header, importedData.rows, existedDataFromServer?.customers]);

	useEffect(() => {
		const haveInvalid = !!invalidRows.filter((row) => !row.isDisabled).length;
		const haveRows = !importedRows.filter((row) => !row.isDisabled).length;

		setDisableButton(haveRows || haveInvalid);
	}, [importedRows]);

	const onFocusOut = async (index: number) => {
		const existedData: CustomersDetails = await getExistsCustomerList(
			dispatch,
			importedRows.map((row) => row.row[IndexInCustomerCSVFile.NAME]),
			importedRows.map((row) => row.row[IndexInCustomerCSVFile.REMOTE_ID]),
		);

		const newSplicedRows = [...splicedRows];
		const customer = existedData.customers.find(
			(customer) => customer.name.toLocaleLowerCase() == newSplicedRows[currentPage][index].row[IndexInCustomerCSVFile.NAME].toLocaleLowerCase(),
		);

		if (customer) {
			newSplicedRows[currentPage][index].oldRow = {
				[IndexInCustomerCSVFile.ROW_STATUS]: RowStatus.UPDATE,
				[IndexInCustomerCSVFile.ICON_DATA]: customer.iconData || '',
				[IndexInCustomerCSVFile.NAME]: customer.name,
				[IndexInCustomerCSVFile.LEGAL_NAME]: customer.legalName,
				[IndexInCustomerCSVFile.ENTITY]: customer?.supplierId ? customer?.supplier?.name : '',
				[IndexInCustomerCSVFile.EIN]: customer.ein,
				[IndexInCustomerCSVFile.EMAIL]: customer.email,
				[IndexInCustomerCSVFile.COUNTRY]: customer.country,
				[IndexInCustomerCSVFile.COUNTRY_STATE]: customer.countryState,
				[IndexInCustomerCSVFile.CITY]: customer.city,
				[IndexInCustomerCSVFile.BILLING_ADDRESS]: customer.billingAddress,
				[IndexInCustomerCSVFile.TAX]: customer.tax?.toString(),
				[IndexInCustomerCSVFile.REMOTE_ID]: customer.remoteId || '',
			};
		}

		newSplicedRows[currentPage][index].row[IndexInCustomerCSVFile.ROW_STATUS] = setRowStatus(newSplicedRows[currentPage][index], existedData);

		setSplicedRows(newSplicedRows);
		setExistedDataFromServer(existedData);
		setDuplicateDataForCsvRows();
	};

	const setDuplicateDataForCsvRows = async () => {
		const allNamesInTable = importedRows.map((row) => row.row[IndexInCustomerCSVFile.NAME]);
		const allRemotesInTable = importedRows
			.filter((row) => !row.row[IndexInCustomerCSVFile.REMOTE_ID]?.length)
			.map((row) => row.row[IndexInCustomerCSVFile.REMOTE_ID]);
		const duplicateNames: string[] = allNamesInTable.filter((name, index, list) => list.indexOf(name) !== index);
		const duplicateRemoteIds: string[] = allRemotesInTable.filter((remoteId, index, list) => list.indexOf(remoteId) !== index);
		const duplicates = { remoteIds: [...duplicateRemoteIds], names: [...duplicateNames], customers: [] };

		setDuplicateDataInTable(duplicates);

		return duplicates;
	};

	const checkValidation = async (rows: any[], currentHeader: string[]) => {
		const newInvalidRows: CSVRow[] = [];
		const newInvalidHeaders: number[] = [];
		const duplicateDataInTable: CustomersDetails = await setDuplicateDataForSimpleRows(rows);

		const newRows = rows.map((row, rowIndex) => {
			let validRow = true;
			currentHeader.forEach((header, columnIndex) => {
				if (!validateCustomerInput(row, row[columnIndex], columnIndex, suppliersList, geoLocationData, existedDataFromServer, duplicateDataInTable)) {
					validRow = false;
					row[IndexInCustomerCSVFile.ROW_STATUS] = RowStatus.ERROR;
					newInvalidHeaders.push(columnIndex);
				}
			});
			const oldRowIndex = importedData.rowsWithOldValues.findIndex(
				(oldRow) => oldRow[IndexInCustomerCSVFile.NAME] == row[IndexInCustomerCSVFile.NAME],
			);

			!validRow &&
				newInvalidRows.push({
					isValid: validRow,
					rowIndex,
					isDisabled: false,
					row,
					oldRow:
						splicedRows.length && splicedRows[currentPage][rowIndex]?.oldRow
							? splicedRows[currentPage][rowIndex]?.oldRow
							: oldRowIndex != -1
							? importedData.rowsWithOldValues?.[oldRowIndex]
							: null,
				});
			return {
				isValid: validRow,
				rowIndex,
				isDisabled: false,
				row,
				oldRow:
					splicedRows.length && splicedRows[currentPage][rowIndex]?.oldRow
						? splicedRows[currentPage][rowIndex]?.oldRow
						: oldRowIndex != -1
						? importedData.rowsWithOldValues?.[oldRowIndex]
						: null,
			};
		});

		setImportedRows(newRows);
		setInvalidHeaderTypes(newInvalidHeaders);
		setSplicedRows(splitArray(newRows, Number(pageSize)));
		setInvalidRows(newInvalidRows);
	};

	const onClose = () => {
		closeModal();
		setImportedRows([]);
		setHeaderOptions([]);
		setInvalidRows([]);
		setInvalidHeaderTypes([]);
		setCurrentPage(0);
	};

	const onImport = () => {
		onImportData(
			importedRows.filter((rows) => (rows.isDisabled ? false : rows.isValid)).map((row) => row.row),
			existedDataFromServer,
		);
		onClose();
	};

	const updateHeader = async (newHeader: string[], headerIndex: number) => {
		let updatedRows = importedRows.map((row, rowIndex) => {
			const newData = { ...row.row };
			newData[headerIndex] = importedData.originalRows[rowIndex][newHeader[headerIndex]];
			return { ...newData };
		});

		setHeaderOptions(newHeader);

		updatedRows = handleSwitchCustomerRows(
			updatedRows.map((row) => row),
			suppliersList,
		);

		checkValidation(updatedRows, newHeader);
	};

	const onUpdatePageSize = (num: string) => {
		setSplicedRows(splitArray(splicedRows.flat(), Number(num)));
		setPageSize(num);
		setCurrentPage(0);
	};

	const onShowHideErrors = () => {
		setShowInvalid(!showInvalid);
		const newInvalidRows: CSVRow[] = [];
		const newInvalidHeaders: number[] = [];
		setCurrentPage(0);

		if (showInvalid) {
			let newImported = importedRows.map((row) => {
				let validRow = true;
				headerOptions.forEach((header, columnIndex) => {
					if (
						!validateCustomerInput(
							row.row,
							row.row[columnIndex],
							columnIndex,
							suppliersList,
							geoLocationData,
							existedDataFromServer,
							duplicateDataInTable,
						)
					) {
						validRow = false;
						!row.isDisabled && newInvalidHeaders.push(columnIndex);
					}
				});

				!validRow && newInvalidRows.push({ ...row, isValid: validRow });
				return { ...row, isValid: validRow };
			});

			setImportedRows(newImported);

			if (hideDisabled) {
				newImported = newImported.filter((row) => !row.isDisabled);
			}

			setSplicedRows(splitArray(newImported, Number(pageSize)));
			setInvalidHeaderTypes(newInvalidHeaders);
			setInvalidRows(newInvalidRows);
		} else {
			let newInvalid = [...invalidRows];
			if (hideDisabled) {
				newInvalid = invalidRows.filter((row) => !row.isDisabled);
			}
			setSplicedRows(splitArray(newInvalid, Number(pageSize)));
		}
	};

	const onHideDisabled = () => {
		setHideDisabled(!hideDisabled);
		let newSplicedRows = [...splicedRows.flat()];
		if (!hideDisabled) {
			newSplicedRows = newSplicedRows.filter((row) => !row.isDisabled);
		} else {
			newSplicedRows = showInvalid ? [...invalidRows] : [...importedRows];
		}

		setSplicedRows(splitArray(newSplicedRows, Number(pageSize)));
	};

	const onUpdateRows = async (rowIndex: number, newRow: CSVRow) => {
		const newInvalidRows: CSVRow[] = [];
		const newInvalidHeaders: number[] = [];
		let newImported = [...importedRows];
		const newSplicedRows = [...splicedRows];

		newSplicedRows[currentPage][rowIndex] = { ...newRow };
		const duplicates: CustomersDetails = await setDuplicateDataForCsvRows();
		let validNewRow = true;
		headerOptions.forEach((header, columnIndex) => {
			if (
				!validateCustomerInput(newRow.row, newRow.row[columnIndex], columnIndex, suppliersList, geoLocationData, existedDataFromServer, duplicates)
			) {
				validNewRow = false;
			}
		});

		newRow.row[IndexInCustomerCSVFile.ROW_STATUS] = setRowStatus({ ...newRow, isValid: validNewRow }, existedDataFromServer);
		newSplicedRows[currentPage][rowIndex] = { ...newRow, isValid: validNewRow };

		newImported[newRow.rowIndex] = { ...newRow };
		newImported = newImported.map((row) => {
			let validRow = true;
			headerOptions.forEach((header, columnIndex) => {
				if (!validateCustomerInput(row.row, row.row[columnIndex], columnIndex, suppliersList, geoLocationData, existedDataFromServer, duplicates)) {
					validRow = false;
					!row.isDisabled && newInvalidHeaders.push(columnIndex);
				}
			});

			row.row[IndexInCustomerCSVFile.ROW_STATUS] = setRowStatus({ ...row, isValid: validRow }, existedDataFromServer);

			!validRow && newInvalidRows.push({ ...row, isValid: validRow });
			return { ...row, isValid: validRow };
		});

		setSplicedRows(newSplicedRows);
		setImportedRows(newImported);
		setInvalidHeaderTypes(newInvalidHeaders);
		setInvalidRows(newInvalidRows);
	};

	const onUpdateDisabledAll = (isChecked: boolean) => {
		const newSplicedRows = splicedRows.flat().map((row) => ({ ...row, isDisabled: !isChecked }));
		const newImported = importedRows.map((row) => {
			if (newSplicedRows.find((current) => current.rowIndex === row.rowIndex)) {
				return { ...row, isDisabled: !isChecked };
			}
			return { ...row };
		});

		const newErrorsRows = invalidRows.map((row) => ({ ...row, isDisabled: !isChecked }));

		setSplicedRows(splitArray(newSplicedRows, Number(pageSize)));
		setImportedRows(newImported);
		setInvalidRows(newErrorsRows);
	};

	const pageOptions = [
		{ label: `10 ${t('items')}`, value: '10' },
		{ label: `20 ${t('items')}`, value: DEFAULT_PAGE_SIZE },
		{ label: `30 ${t('items')}`, value: '30' },
		{ label: `40 ${t('items')}`, value: '40' },
		{ label: `50 ${t('items')}`, value: '50' },
	];

	return (
		<Modal isOpen={isOpen} closeModal={onClose} className={styles.modalContainer}>
			<div className={styles.header}>
				<div>
					<h2 className={styles.title}>{t(texts.title)}</h2>
					<span className={styles.text}>{`${t('matchResultsWithYourData')}`}</span>
				</div>

				<div className={styles.headerButtons}>
					{(!!invalidRows.length || showInvalid) && (
						<Button type='outlined' color='neutral' className={styles.hideButton} onClick={onShowHideErrors}>
							{t(showInvalid ? 'showAll' : 'showOnlyRowsWithErrors')}
							<Icon imgType={showInvalid ? 'eye' : 'closed_eye'} />
						</Button>
					)}
					{importedRows.find((row) => row.isDisabled) && (
						<Button type='outlined' color='neutral' className={styles.hideButton} onClick={onHideDisabled}>
							{t(hideDisabled ? 'showDisabledRows' : 'hideDisabledRows')}
							<Icon imgType={hideDisabled ? 'eye' : 'closed_eye'} />
						</Button>
					)}
					<Button onClick={onImport} className={styles.importButton} disabled={disableButton}>
						{t('import')}
					</Button>
					<CloseButton onClick={onClose} />
				</div>
			</div>

			<div className={styles.fileScrollingContainer}>
				<div className={styles.customerOptionsColumns}>
					<div className={styles.emptyBox} />
					{tableColumns.columns.map((item, index) => (
						<div key={index} className={styles.customerOptionsColumnsItems}>
							{item}
							{mandatoryColumns.includes(index) && ' *'}
						</div>
					))}
				</div>

				<div className={styles.matchYourColumns}>{t('matchYourColumns')}:</div>
				<CSVDataMatcherTable
					disabledColumns={tableColumns.disabledColumns}
					invalidHeaderTypes={invalidHeaderTypes}
					mandatoryColumns={mandatoryColumns}
					header={headerOptions}
					originalHeader={importedData.originalHeader}
					rows={splicedRows[currentPage] || []}
					suppliersList={suppliersList}
					existedDataFromServer={existedDataFromServer}
					duplicateDataInTable={duplicateDataInTable}
					updateHeader={updateHeader}
					updateRow={onUpdateRows}
					setDisabledAllRows={onUpdateDisabledAll}
					onFocusOut={onFocusOut}
				/>
			</div>

			<div className={styles.footer}>
				<Select defaultValue={pageSize} data={pageOptions} onChange={(val) => onUpdatePageSize(val)} />

				<Pagination numberOfPages={splicedRows?.length} onUpdatePageNumber={setCurrentPage} currentPageNumber={currentPage} />

				<div className={styles.rowsMissingData}>
					{invalidRows.filter((row) => !row.isDisabled).length}/{importedRows?.length}{' '}
					{t(invalidRows.filter((row) => !row.isDisabled).length === 1 ? 'rowWithError' : 'rowsWithError')}
				</div>
			</div>
		</Modal>
	);
};
