import { UserPermissions } from '@received/pricing-model';
import { RoutesType } from '../../types/generalTypes';
import { Payouts } from '../../pages/_Billing/Payouts/Payouts';
import { Invoices } from '../../pages/_Billing/Invoices/Invoices';
import { AllInvoices } from '../../pages/_Billing/Invoices/AllInvoices/AllInvoices';
import { SingleInvoicePage } from '../../pages/_Billing/Invoices/SingleInvoicePage/SingleInvoicePage';
import { SingleCreditNote } from '../../pages/_Billing/CreditNotes/SingleCreditNote/SingleCreditNote';
import { AllCreditNotes } from '../../pages/_Billing/CreditNotes/AllCreditNotes/AllCreditNotes';
import { SinglePayoutPage } from '../../pages/_Billing/Payouts/SinglePayoutPage/SinglePayoutPage';

export const billingRoutes: RoutesType[] = [
	{
		name: 'billing',
		path: '',
		showOnSidebar: true,
		iconType: 'billing',
		subElements: [
			{
				name: 'overview',
				path: '/invoices',
				showOnSidebar: true,
				element: <Invoices />,
				licenses: [UserPermissions.BILLING],
			},
			{
				name: 'invoices',
				path: '/all-invoices',
				showOnSidebar: true,
				element: <AllInvoices />,
				licenses: [UserPermissions.BILLING],
			},
			{
				name: 'credits',
				path: '/credits',
				showOnSidebar: true,
				element: <AllCreditNotes />,
				licenses: [UserPermissions.BILLING],
			},
			{
				name: 'payouts',
				path: '/payouts',
				showOnSidebar: true,
				element: <Payouts />,
				licenses: [UserPermissions.PARTNERS],
			},
		],
	},
	{
		name: 'singleInvoice',
		path: '/invoices/single-invoice',
		showOnSidebar: false,
		element: <SingleInvoicePage />,
	},
	{
		name: 'singlePayout',
		path: '/payouts/single-payout',
		showOnSidebar: false,
		element: <SinglePayoutPage />,
	},
	{
		name: 'singleCreditNote',
		path: '/single-credit-note',
		showOnSidebar: false,
		element: <SingleCreditNote />,
	},
];
