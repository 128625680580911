import { useState } from 'react';
import { ContractType } from '@received/pricing-model';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FlippingAddButton } from '../_uiComponents';
import { images } from '../../constants/images';
import { createContract } from '../../pages/Contracts/ContractComponent/Contract.utils';
import styles from './CallToActionMenu.module.scss';

export const CallToActionMenu = () => {
	const { t } = useTranslation('translation');
	const [isClicked, setIsClicked] = useState(false);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const onPressNewContract = async () => {
		const contractId = await createContract(dispatch, ContractType.ENTERPRISE);
		contractId && navigate('../contract', { state: { contractId } });
	};

	return (
		<div className={styles.container}>
			<FlippingAddButton isPressed={isClicked} onClick={() => setIsClicked(!isClicked)} />
			{isClicked && (
				<>
					<div className={styles.buttonsContainer}>
						<div
							className={styles.button}
							onClick={() => {
								setIsClicked(false);
								navigate('../pricing-models', { state: { opedCreateProduct: true } });
							}}
						>
							<img src={images.addModelBlue} className={styles.icon}></img>
							<div className={styles.textContainer}>
								<div className={styles.fontSize14}>{t('newProduct')}</div>
								<div className={styles.neutralFontSize12}>{t('BuildProductModel')}</div>
							</div>
						</div>

						<div
							className={styles.button}
							onClick={() => {
								setIsClicked(false);
								onPressNewContract();
							}}
						>
							<img src={images.addContractBlue} className={styles.icon}></img>
							<div className={styles.textContainer}>
								<div className={styles.fontSize14}>{t('newContract')}</div>
								<div className={styles.neutralFontSize12}>{t('createContractWithCustomers')}</div>
							</div>
						</div>

						<div
							className={styles.button}
							onClick={() => {
								setIsClicked(false);
								navigate('../customers', { state: { opedCreateCustomer: true } });
							}}
						>
							<img src={images.addCustomer} className={styles.icon}></img>

							<div className={styles.textContainer}>
								<div className={styles.fontSize14}>{t('newCustomer')}</div>
								<div className={styles.neutralFontSize12}>{t('addYourselfOrImportCustomer')}</div>
							</div>
						</div>
					</div>

					<div className={styles.overlay} onClick={() => setIsClicked(!isClicked)}></div>
				</>
			)}
		</div>
	);
};
