import React, { CSSProperties, useMemo, useState } from 'react';
import { AccountingEventType, convertCurrencyToSign } from '@received/pricing-model';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { getValueWithCurrencySign, numberInRange } from '../../utils/NumberUtils';
import { PricingModelTab } from '../../types/contractTypes';
import { InputIcon, UpdatePricingSummary } from '..';
import styles from './PricingSummary.module.scss';

interface PricingSummaryProps {
	className?: string;
	style?: CSSProperties;
	pricingModelTab: PricingModelTab;
	creditsAmount?: number;
	updatePricingSummaryData: ({ tax, discount }: UpdatePricingSummary) => void;
}

export const PricingSummary = ({ className, style, pricingModelTab, creditsAmount, updatePricingSummaryData }: PricingSummaryProps) => {
	const { pricingModelTableTotal, tax, discount, minimumFee } = pricingModelTab;
	const total = Math.max(pricingModelTableTotal, minimumFee || 0);
	const { t } = useTranslation('translation');
	const [discountInPercentage, setDiscountInPercentage] = useState(true);

	const discountValue = useMemo(() => (total * (discount || 0)) / 100, [discount, total]);
	const taxValue = useMemo(() => ((total - discountValue) * tax) / 100, [discountValue, total, tax]);
	const totalValue = useMemo(() => total - discountValue + taxValue, [discountValue, total, taxValue]);
	const totalDue = totalValue - (creditsAmount || 0);

	return (
		<div className={clsx(styles.container, className)} style={style}>
			<div className={clsx(styles.title)}>{t('summary')}</div>

			<div className={clsx(styles.subTitle, styles.row)}>
				<div>{t('subtotal')}</div>
				<div className={styles.sumWrapper}>{getValueWithCurrencySign(total, convertCurrencyToSign(pricingModelTab.currency))}</div>
			</div>

			{pricingModelTab?.accountingEventType != AccountingEventType.CREDIT && (
				<div className={clsx(styles.subTitle, styles.row)}>
					<div>{t('discount')}</div>

					<div className={styles.sumWrapper}>
						<span className={clsx(discountValue == 0 && styles.fadedText)}>
							({getValueWithCurrencySign(discountValue, convertCurrencyToSign(pricingModelTab.currency))})
						</span>

						<InputIcon
							value={discount}
							prefixSign={discountInPercentage ? '%' : convertCurrencyToSign(pricingModelTab.currency)}
							type='number'
							onChange={(val) => updatePricingSummaryData({ tax, discount: numberInRange(+val, 0, 100) })}
							// TODO return when server ready
							// onSwitchPrefix={onUpdateDiscountPrefix}
						/>
					</div>
				</div>
			)}

			<div className={clsx(styles.subTitle, styles.row)}>
				<div>{t('tax')}</div>

				<div className={styles.sumWrapper}>
					<span className={clsx(taxValue == 0 && styles.fadedText)}>
						{getValueWithCurrencySign(taxValue, convertCurrencyToSign(pricingModelTab.currency))}
					</span>

					<InputIcon value={tax} prefixSign={'%'} type='number' onChange={(val) => updatePricingSummaryData({ tax: Number(val), discount })} />
				</div>
			</div>

			{!!creditsAmount && (
				<div className={styles.flexColumn}>
					<div className={clsx(styles.subTitle, styles.row)}>
						<div>{t('Total')}</div>
						<div className={styles.sumWrapper}>{getValueWithCurrencySign(totalValue, convertCurrencyToSign(pricingModelTab.currency))}</div>
					</div>

					<div className={clsx(styles.subTitle, styles.row)}>
						<div>{t('paidCredits')}</div>
						<div className={styles.sumWrapper}>({getValueWithCurrencySign(creditsAmount, convertCurrencyToSign(pricingModelTab.currency))})</div>
					</div>
				</div>
			)}

			<div className={styles.flexColumn}>
				<div className={styles.row} style={{ margin: 0 }}>
					<div className={styles.total}>{t('totalDue')}</div>
					<div className={styles.totalSumWrapper}>
						{getValueWithCurrencySign(totalDue >= 0 ? totalDue : 0, convertCurrencyToSign(pricingModelTab.currency))}
					</div>
				</div>
				{!!creditsAmount && <span className={styles.note}>*{t('creditAppliedOnInvoice')}</span>}
			</div>
		</div>
	);
};
