import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './WeeklyComponent.module.scss';

export interface WeeklyComponentProps {
	defaultDay?: string;
	updateIssueDate: (day: string) => void;
}

export const WeeklyComponent = ({ defaultDay = '', updateIssueDate }: WeeklyComponentProps) => {
	const { t } = useTranslation('translation');
	const [selectedDay, setSelectedDay] = useState('');
	const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

	useEffect(() => {
		setSelectedDay(defaultDay);
	}, [defaultDay]);

	return (
		<div className={styles.container}>
			<div>
				<div className={styles.title}>{t('weeklyCycle')}</div>
			</div>
			<div className={styles.daysContainer}>
				{days.map((day) => (
					<div
						key={day}
						onClick={() => {
							setSelectedDay(day);
							updateIssueDate(day);
						}}
						className={clsx(styles.dayItem, selectedDay === day && styles.dayItemSelected)}
					>
						{t(day)}
					</div>
				))}
			</div>
		</div>
	);
};
