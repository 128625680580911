import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
	Chart as ChartJS,
	ChartData,
	LineController,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	CategoryScale,
	ArcElement,
	DoughnutController,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { convertCurrencyToSign } from '@received/pricing-model';
import { Store } from '../../../../types/storeTypes';
import { colors } from '../../../../constants/colors';
import { numberWithCommas, priceNumberGraphsDisplay } from '../../../../utils/NumberUtils';
import { EmptyStateCard } from '../..';
import { textCenter } from './SummaryOverviewGraph.utils';
import styles from './SummaryOverviewGraph.module.scss';

ChartJS.register(LineController, ArcElement, DoughnutController, LineElement, PointElement, CategoryScale, Title, Tooltip, Filler, Legend);

interface SummaryOverviewGraphData {
	label: string;
	total: number;
	count: number | string;
	color: string;
}

interface SummaryOverviewGraphProps {
	dataList: SummaryOverviewGraphData[];
	title?: string;
	selectedYear?: number;
	showTooltip?: boolean;
	containerClassName?: string;
	graphContainerClassName?: string;
	itemsContainerClassName?: string;
	emptyStateSubtitle?: string;
	showOnlyPercentage?: boolean;
}

export const SummaryOverviewGraph = ({
	dataList,
	showTooltip,
	containerClassName,
	graphContainerClassName,
	itemsContainerClassName,
	emptyStateSubtitle,
	showOnlyPercentage,
}: SummaryOverviewGraphProps) => {
	const { t } = useTranslation('translation');
	const { appCurrency } = useSelector((store: Store) => store.general);

	const isEmpty = !dataList.find((item) => item.total != 0);
	const total = dataList.reduce((acc, o) => acc + o.total, 0);

	const chartRef = useRef<ChartJS>(null);
	const [chartData, setChartData] = useState<ChartData<any>>({
		datasets: [],
	});

	useEffect(() => {
		const chart = chartRef.current;

		if (!chart) {
			return;
		}
		const data: number[] = [];
		const backgroundColor: string[] = [];
		const hoverBorderColor: string[] = [];
		const labels = dataList.map((item) => {
			data.push(item.total);
			backgroundColor.push(item.color);
			hoverBorderColor.push(item.color);

			return item.label;
		});
		const chartData = {
			labels,
			datasets: [
				{
					data,
					backgroundColor,
					hoverBorderColor,
				},
			],
		};

		setChartData(chartData);
	}, [dataList]);

	const options = {
		responsive: true,
		cutout: '89%',
		borderRadius: 10,
		maintainAspectRatio: false,
		animation: {
			duration: 2000,
		},

		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				titleFont: {
					family: 'TWKLausanne350',
					size: 12,
				},
				bodyFont: {
					family: 'TWKLausanne250',
					size: 12,
				},
				enabled: !!showTooltip,
				displayColors: false,
				backgroundColor: colors.neutral900,
				intersect: false,
				width: 30,
				padding: 10,
				callbacks: {
					label: (context: any) => {
						let label = '';
						if (context.parsed) {
							let numericLabel = `${convertCurrencyToSign(appCurrency)}${numberWithCommas(Math.abs(context.parsed))}`;
							numericLabel = `${context.parsed < 0 ? `(${numericLabel})` : numericLabel}`;
							label = ` ${context.label ? `${context.label} • ` : ''} ${numericLabel}`;
						}
						return label;
					},
				},
			},
		},
	};

	return (
		<div className={clsx(styles.container, containerClassName)}>
			{isEmpty ? (
				<div className={styles.emptyContainer}>
					<EmptyStateCard showIcon imgType='chart_pie' title={t('noDataSource')} subTitle={emptyStateSubtitle} />
				</div>
			) : (
				<>
					<div className={clsx(styles.summaryContainer, itemsContainerClassName)}>
						{dataList.map((item, index) => (
							<div key={index} className={styles.row}>
								<div className={styles.titleContainer}>
									<div className={styles.dot} style={{ backgroundColor: item.color }}></div>
									<div className={styles.title}>{item.label}</div>
								</div>

								<div className={styles.subTitle}>
									{!showOnlyPercentage && <span className={styles.purestValue}>{item.count}</span>}
									<span className={styles.purest}>
										{showOnlyPercentage ? `${item.count}%` : `• ${convertCurrencyToSign(appCurrency)}${priceNumberGraphsDisplay(item.total)}`}
									</span>
								</div>
							</div>
						))}
					</div>

					<div className={clsx(styles.graphContainer, graphContainerClassName)}>
						<Chart plugins={textCenter(convertCurrencyToSign(appCurrency))} type='doughnut' options={options} ref={chartRef} data={chartData} />
					</div>
				</>
			)}
		</div>
	);
};
