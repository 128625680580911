import {
	Currency,
	InvoiceCredit,
	PricingModelTab,
	SyncStatusDto,
	UsageProduct,
	AccountingEventType,
	CryptoSettings,
	NetTerms,
	DocumentState,
	DocumentEditBlocker,
} from '@received/pricing-model';
import { Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';
import { DATE_FORMAT_FOR_SERVER } from '../constants/templateConstants';
import { BillingDetails, PricingModel } from './contractTypes';
import { DocumentUsageReportList, UsageReportView } from './usageTypes';
import { Customer } from './customerTypes';

export enum summaryType {
	WARNING = 'WARNING',
	SUCCESS = 'SUCCESS',
	DESTRUCT = 'DESTRUCT',
	PRIMARY = 'PRIMARY',
	GRAPH = 'GRAPH',
	ARCHIVE = 'ARCHIVE',
	GREY = 'GREY',
}

export interface InvoiceSummary {
	pendingPaymentsSum: number;
	pendingPaymentsCount: number;
	latePaymentsSum: number;
	latePaymentsCount: number;
	paidPaymentsSum: number;
	paidPaymentsCount: number;
	missingDataCount: number;
	missingDataSum: number;
	readyForSendingCount: number;
	readyForSendingSum: number;
	readyForSendingCreditsCount: number;
	canceledCount: number;
	canceledSum: number;
	paidCreditsCount: number;
	missingDataCreditsCount: number;
	pendingPaymentsFromLastWeekCount: number;
	latePaymentsLongerThan90DaysCount: number;
}

export interface InvoiceMonthSummary {
	date: Date;
	summary: InvoiceSummary;
}

export interface InvoiceView {
	contractNumber: number;
	poNumber: string;
	customerId: string;
	customerName: string;
	customerEmail: string;
	customerIconData: string; //base64
	currency: Currency;
	id: string;
	documentNumber?: string;
	draftNumber: string;
	issueDate: string;
	contractInvoiceNumber: number;
	contractInvoicesCount: number;
	total: number;
	tax: number;
	createDate: Date;
	dueDate: Date;
	state: DocumentState;
	subTotal: number;
	totalTax: number;
	netTerms: NetTerms;
	productNames: string[];
	updatedAt: Date;
	totalInSplitInvoice?: number;
	numberInSplitInvoice?: number;
	syncStatus?: SyncStatusDto[];
	usageReports?: UsageReportView[];
	invoiceCredits?: InvoiceCredit[];
	accountingEventType: AccountingEventType;
	billingDetails?: BillingDetails;
	syncBlockers?: string[];
	contractName?: string;
	hasUsageReports?: boolean;
}

export enum invoicesFilterOptions {
	CONTRACT = 'CONTRACT',
	SPLITTED_INVOICES = 'SPLITTED_INVOICES',
}

export class Invoice {
	id?: string;
	documentNumber?: string;
	customNumber?: string;
	draftNumber?: string;
	draftNumberPrefix?: string;
	customerId?: string;
	customer?: Customer;
	contractId?: string;
	poNumber?: string;
	contractNumber?: string;
	tax?: number;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	issueDate?: Date | null;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	dueDate?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	paidDate?: Date;
	createdAt?: Date;
	currency: Currency;
	state?: DocumentState;
	total?: number;
	totalTax?: number;
	subTotal?: number;
	totalDiscount?: number;
	contractPricingModelTabIds?: string[];
	contractPricingModelTabs?: PricingModelTab[];
	invoicePricingModelId?: string;
	@Type(() => PricingModel)
	pricingModel: PricingModel;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	billingPeriodEndDate?: Date;
	@Transform(({ value }) => value && dayjs(value).format(DATE_FORMAT_FOR_SERVER))
	billingPeriodStartDate?: Date;
	totalInSeries?: number;
	numberInSeries?: number;
	totalInSplitInvoice?: number;
	numberInSplitInvoice?: number;
	netTerms?: NetTerms;
	supplierId?: string;
	billingDetails?: BillingDetails;
	usageReportsByProduct?: any[];
	usageReports?: DocumentUsageReportList[];
	relatedUsageProducts?: UsageProduct[];
	isCollective?: boolean;
	invoiceCredits?: InvoiceCredit[];
	creditsAmount?: number;
	accountingEventType?: AccountingEventType;
	supplier?: Customer;
	cryptoSettings?: CryptoSettings;
	syncStatus?: SyncStatusDto[];
	index?: number;
	syncBlockers?: string[];
	hasUsageReports?: boolean;
	editBlockers?: DocumentEditBlocker[];
}

export interface InvoiceCashflow {
	date: Date;
	statesCashflow: InvoiceCashflowItem[];
}

export interface InvoiceCashflowItem {
	state: DocumentState;
	invoiceCount: number;
	totalSum: number;
}
