import { Method } from 'axios';

type Config = {
	method: Method;
	url: string;
};

export const PATH_TO_CONFIG: Record<PathsConfig, Config> = {
	getPaymentsSummary: {
		method: 'get',
		url: '/invoices/summary/view',
	},
	getContractsInvoiceList: {
		method: 'get',
		url: '/contracts/invoices/list',
	},
	createContractsDocuments: {
		method: 'post',
		url: '/contracts/invoices/regenerate/<id>',
	},
	getInvoiceData: {
		method: 'get',
		url: '/invoices/preview/<id>',
	},
	getInvoicesViewData: {
		method: 'get',
		url: '/invoices/view/pagination',
	},
	updateInvoice: {
		method: 'post',
		url: '/invoices/update',
	},
	sendInvoice: {
		method: 'post',
		url: '/invoices/send/<id>',
	},
	assignInvoiceNumber: {
		method: 'post',
		url: '/invoices/number/assign',
	},
	getCustomersViewData: {
		method: 'get',
		url: '/customers/view/list',
	},
	getCustomerData: {
		method: 'get',
		url: '/customers/<id>',
	},
	updateCustomer: {
		method: 'post',
		url: '/customers/update/<id>',
	},
	getSupplierData: {
		method: 'get',
		url: '/suppliers/<id>',
	},
	getCustomersList: {
		method: 'get',
		url: '/customers/list/filter/',
	},
	getExistsCustomerList: {
		method: 'get',
		url: '/customers/existing',
	},
	getAllPricingModels: {
		method: 'get',
		url: '/pricing-model',
	},
	getPricingModelData: {
		method: 'get',
		url: '/pricing-model/<id>',
	},
	getPricingModelTemplates: {
		method: 'get',
		url: 'pricing-model/list/templates',
	},
	addNewCustomer: {
		method: 'post',
		url: '/customers',
	},
	addNewSupplier: {
		method: 'post',
		url: '/suppliers',
	},
	updateSupplier: {
		method: 'post',
		url: '/suppliers/update/<id>',
	},
	createPricingModel: {
		method: 'post',
		url: '/pricing-model',
	},
	clonePricingModel: {
		method: 'post',
		url: 'pricing-model/clone/template/<id>',
	},
	updatePricingModel: {
		method: 'post',
		url: '/pricing-model/update',
	},
	publishPricingModel: {
		method: 'post',
		url: '/pricing-model/publish',
	},
	getContractData: {
		method: 'get',
		url: '/contracts/<id>',
	},
	getContractsView: {
		method: 'get',
		url: '/contracts/view/pagination',
	},
	createContract: {
		method: 'post',
		url: '/contracts',
	},
	updateContract: {
		method: 'post',
		url: '/contracts/update/',
	},
	publishContract: {
		method: 'post',
		url: '/contracts/publish',
	},
	deleteContract: {
		method: 'delete',
		url: '/contracts/<id>',
	},
	deleteAgreement: {
		method: 'delete',
		url: '/agreements/referral/<id>',
	},
	cancelAgreement: {
		method: 'post',
		url: '/agreements/referral/cancel/<id>',
	},
	cancelContract: {
		method: 'post',
		url: '/contracts/cancel/<id>',
	},
	cloneContract: {
		method: 'post',
		url: '/contracts/clone/<id>',
	},
	cloneAgreement: {
		method: 'post',
		url: '/agreements/referral/clone/<id>',
	},
	getAuthStatus: {
		method: 'get',
		url: '/auth',
	},
	authLogin: {
		method: 'post',
		url: '/auth/login',
	},
	authLogout: {
		method: 'post',
		url: '/auth/logout',
	},
	authUserList: {
		method: 'get',
		url: '/auth/user/list',
	},
	authCurrentUser: {
		method: 'get',
		url: '/auth/user/current',
	},
	authUserEnable: {
		method: 'post',
		url: '/auth/user/enable',
	},
	authDeleteUser: {
		method: 'delete',
		url: '/auth/user/<userId>',
	},
	authUserUpdate: {
		method: 'post',
		url: '/auth/user/update',
	},
	authInviteUser: {
		method: 'post',
		url: '/auth/user/invite',
	},
	authVerifyUser: {
		method: 'post',
		url: '/auth/user/verify/<userName>',
	},
	getCategoriesList: {
		method: 'get',
		url: '/pricing-model/categories/list',
	},
	getUsageProductList: {
		method: 'get',
		url: 'usage/product/list/filter',
	},
	getUsageReportsViewData: {
		method: 'get',
		url: '/usage/report/view/pagination',
	},
	getUsageGroupList: {
		method: 'get',
		url: '/usage/group/list',
	},
	createUsageProduct: {
		method: 'post',
		url: '/usage/product/create',
	},
	publishUsageProduct: {
		method: 'post',
		url: '/usage/product/publish',
	},
	getUsageSnippet: {
		method: 'get',
		url: '/usage/report-form/<usageProductId>',
	},
	createUsageType: {
		method: 'post',
		url: '/usage/group',
	},
	sendManualUsageReport: {
		method: 'post',
		url: '/usage/report',
	},
	getUsageReportProducts: {
		method: 'get',
		url: '/usage/report/manual-report/product/list',
	},
	getUsageReportDocuments: {
		method: 'get',
		url: '/usage/report/manual-report/document/list',
	},
	myProductsList: {
		method: 'get',
		url: '/product/list/filter',
	},
	createNewProduct: {
		method: 'post',
		url: '/product',
	},
	connectModelToProduct: {
		method: 'post',
		url: '/product/pricing/add',
	},
	reconcileDocuments: {
		method: 'post',
		url: '/documents/reconcile',
	},
	getUsageProductSummaryList: {
		method: 'get',
		url: '/usage/product/summary/view',
	},
	getInvoiceFlowData: {
		method: 'get',
		url: '/invoices/summary/monthly/view',
	},
	getCountries: {
		method: 'get',
		url: '/',
	},
	deleteUsageProduct: {
		method: 'delete',
		url: '/usage/product/<id>',
	},
	getCommonTemplatesList: {
		method: 'get',
		url: '/product/list/common',
	},
	getSuppliers: {
		method: 'get',
		url: '/suppliers',
	},
	getSuppliersById: {
		method: 'get',
		url: '/suppliers/<id>',
	},
	deleteSupplier: {
		method: 'delete',
		url: '/suppliers/<id>',
	},
	deleteCustomer: {
		method: 'delete',
		url: '/customers/<id>',
	},
	getCollectionStatus: {
		method: 'get',
		url: 'invoices/cashflow/report',
	},
	getInvoiceStatusOverTime: {
		method: 'get',
		url: 'invoices/summary/overtime/view',
	},
	splitInvoiceById: {
		method: 'post',
		url: '/invoices/split/<id>',
	},
	getSplittedInvoiceById: {
		method: 'get',
		url: '/invoices/splitted/<id>',
	},
	getInvoiceForecast: {
		method: 'get',
		url: '/invoices/cashflow/forecast',
	},
	getReceivableAging: {
		method: 'get',
		url: '/invoices/ar-aging/report',
	},
	updateUsageProduct: {
		method: 'post',
		url: '/usage/product/update',
	},
	getUsageProduct: {
		method: 'get',
		url: '/usage/product/<id>',
	},
	onDeleteProduct: {
		method: 'delete',
		url: '/product/<id>',
	},
	getUsageProductAnalyticsList: {
		method: 'get',
		url: '/usage/product/analytics/view',
	},
	onDeletePricingModelTemplate: {
		method: 'delete',
		url: '/pricing-model/template/<id>',
	},
	getUsageConnectionUrl: {
		method: 'get',
		url: '/usage/product/verification-address/<id>',
	},
	onDeleteUsageReport: {
		method: 'delete',
		url: '/usage/report/<id>',
	},
	onUpdateUsageReport: {
		method: 'post',
		url: '/usage/report/update/<usageProductId>',
	},
	editProduct: {
		method: 'post',
		url: '/product/update',
	},
	cancelInvoice: {
		method: 'post',
		url: '/invoices/cancel/<id>',
	},
	chargeInvoice: {
		method: 'post',
		url: '/invoices/charge/<id>',
	},
	downloadDocumentById: {
		method: 'get',
		url: '/documents/pdf/download/<generationId>',
	},
	downloadDocumentsByIds: {
		method: 'get',
		url: '/documents/pdf/generate',
	},
	duplicateProduct: {
		method: 'post',
		url: '/product/duplicate/<id>',
	},
	connectIntegrationWithEntity: {
		method: 'post',
		url: '/integrations/<integrationProvider>/connect',
	},
	disconnectIntegrationFromEntity: {
		method: 'post',
		url: '/integrations/<integrationProvider>/disconnect',
	},
	continueIntegrationWithEntity: {
		method: 'post',
		url: '/integrations/<integrationProvider>/connect/continue',
	},
	createIntegrationWithEntity: {
		method: 'post',
		url: '/integrations/<integrationProvider>/connect/create',
	},
	cancelIntegration: {
		method: 'post',
		url: '/integrations/<integrationProvider>/cancel',
	},
	removeIntegration: {
		method: 'post',
		url: '/integrations/<integrationProvider>/remove',
	},
	getCustomersByIntegration: {
		method: 'get',
		url: '/integrations/<integrationProvider>/customers',
	},
	createMultipleCustomers: {
		method: 'post',
		url: '/customers/multiple',
	},
	getMergeLinkToken: {
		method: 'get',
		url: '/integrations/merge/link-token',
	},
	swapMergePublicToken: {
		method: 'post',
		url: '/integrations/merge/swap-public-token/<publicToken>',
	},
	disconnectMergeAccount: {
		method: 'post',
		url: '/integrations/merge/disconnect',
	},
	cancelMergeAccount: {
		method: 'post',
		url: '/integrations/merge/cancel',
	},
	getSyncedContracts: {
		method: 'get',
		url: '/customers/synced/contracts',
	},
	getSettingsDataByAttributeName: {
		method: 'get',
		url: '/settings/<settingsSection>/<settingName>',
	},
	getSettingsStringByAttributeName: {
		method: 'get',
		url: '/settings/<settingsSection>/<settingName>/full-string',
	},
	updateSettingsDataByAttributeName: {
		method: 'post',
		url: '/settings',
	},
	getIntegrationsCards: {
		method: 'get',
		url: '/integrations/list/cards',
	},
	getIntegrations: {
		method: 'get',
		url: '/integrations/list',
	},
	getUsageReportInvoiceList: {
		method: 'post',
		url: '/usage/report/manual-report/multiple/documents',
	},
	createUsageAggregator: {
		method: 'post',
		url: '/usage/aggregator/create',
	},
	updateExportIntegrationItems: {
		method: 'post',
		url: '/integrations/<category>/match',
	},
	getIntegrationMatchingItems: {
		method: 'get',
		url: '/integrations/<category>/matching',
	},
	resyncIntegrationMatchingItems: {
		method: 'post',
		url: '/integrations/merge/resync',
	},
	getSalesforcePriceBooks: {
		method: 'get',
		url: '/integrations/merge/crm/sf/pricebook',
	},
	getItemPriceItems: {
		method: 'get',
		url: '/item-price/list/filter',
	},
	createCreditNote: {
		method: 'post',
		url: '/credit',
	},
	updateCreditNote: {
		method: 'patch',
		url: '/credit/<id>',
	},
	updateLockedCreditNote: {
		method: 'patch',
		url: '/credit/locked/<id>',
	},
	deleteCreditNote: {
		method: 'delete',
		url: '/credit/<id>',
	},
	getCreditNoteById: {
		method: 'get',
		url: '/credit/<id>',
	},
	getCreditNoteData: {
		method: 'get',
		url: '/credit/preview/<id>',
	},
	getCreditNotesViews: {
		method: 'get',
		url: '/credit/view/pagination',
	},
	publishCreditNote: {
		method: 'post',
		url: '/credit/publish/<id>',
	},
	getCreditNotesSummary: {
		method: 'get',
		url: '/credit/summary/view',
	},
	getContractSummary: {
		method: 'get',
		url: '/contracts/summary/view',
	},
	createItemPrice: {
		method: 'post',
		url: '/item-price',
	},
	downloadCreditNoteById: {
		method: 'get',
		url: '/credit/pdf/download/<id>',
	},
	downloadCreditNoteByDto: {
		method: 'post',
		url: '/credit/pdf/generate',
	},
	doneImportConfiguration: {
		method: 'post',
		url: '/integrations/merge/crm/sf/import/<state>',
	},
	getItems: {
		method: 'get',
		url: '/items/list/filter',
	},
	createItem: {
		method: 'post',
		url: '/items',
	},
	getUsageLogObjectMapperList: {
		method: 'get',
		url: '/object-mapper',
	},
	postUsageLogObjectMapper: {
		method: 'post',
		url: '/object-mapper',
	},
	deleteUsageLogObjectMapper: {
		method: 'delete',
		url: '/object-mapper/<id>',
	},
	uploadUsageLogsFile: {
		method: 'post',
		url: '/usage/report/bulk/log/upload',
	},
	uploadUsageLogsFileAndMatcher: {
		method: 'post',
		url: '/usage/report/bulk/log/process',
	},
	saveUsageLogs: {
		method: 'post',
		url: '/usage/report/bulk/log/persist',
	},
	getContractDocument: {
		method: 'get',
		url: 'contracts/documents/list',
	},
	getCsvWithErrors: {
		method: 'get',
		url: '/usage/report/bulk/log/errors/<id>',
	},
	getUsageLogPdfPreview: {
		method: 'post',
		url: '/usage/report/bulk/log/preview',
	},
	sendCreditNote: {
		method: 'post',
		url: '/credit/send/<id>',
	},
	getDocumentsView: {
		method: 'get',
		url: '/documents/view/pagination',
	},
	downloadUsageLogs: {
		method: 'get',
		url: '/usage/report/logs/csv',
	},
	downloadDocumentUsageLogs: {
		method: 'get',
		url: 'usage/report/document/logs/csv',
	},
	generateInvoiceIdForDownload: {
		method: 'get',
		url: '/invoices/pdf/generate/<id>',
	},
	generateCreditIdForDownload: {
		method: 'get',
		url: '/credit/pdf/generate/<id>',
	},
	getRevenueGraphsView: {
		method: 'get',
		url: '/revenue/summary',
	},
	getDeferredView: {
		method: 'get',
		url: '/revenue/deferred/view',
	},
	getRevenueSchedule: {
		method: 'get',
		url: '/revenue/schedule/view',
	},
	getRevenueTableView: {
		method: 'get',
		url: '/revenue/view/pagination',
	},
	getCashInflow: {
		method: 'get',
		url: 'invoices/cash/inflow',
	},
	getContractsList: {
		method: 'get',
		url: 'contracts/list/filter',
	},
	getContractsAmounts: {
		method: 'get',
		url: 'contracts/amounts/summary',
	},
	getStartingNumberSettingsByDocumentType: {
		method: 'get',
		url: '/numbering/<documentType>/starting/<supplierId>',
	},
	updateStartingNumberByDocumentType: {
		method: 'post',
		url: '/numbering/<documentType>/starting/update',
	},
	getExpectedNumberByDocumentType: {
		method: 'get',
		url: '/numbering/<documentType>/expected/<supplierId>',
	},
	updateUserPersonalInfo: {
		method: 'post',
		url: '/auth/user/update/me',
	},
	getAnalyticsSummary: {
		method: 'get',
		url: '/analytics/summary',
	},
	getUpForRenewal: {
		method: 'get',
		url: '/analytics/up4renewal',
	},
	getCustomerLocation: {
		method: 'get',
		url: '/analytics/customer/location',
	},
	getCustomersSummary: {
		method: 'get',
		url: '/analytics/customers/overview',
	},
	sendErrorReportId: {
		method: 'post',
		url: '/utils/exception',
	},
	duplicateInvoiceById: {
		method: 'post',
		url: '/invoices/duplicate/<id>',
	},
	duplicateCreditIdById: {
		method: 'post',
		url: '/credit/duplicate/<id>',
	},
	onDeleteMultipleUsageReports: {
		method: 'delete',
		url: 'usage/report/bulk/delete',
	},
	cancelCreditNote: {
		method: 'post',
		url: '/credit/cancel/<id>',
	},
	deleteAccountingIntegrationItems: {
		method: 'delete',
		url: '/integrations/accounting/match',
	},
	syncInvoiceWithAccountingIntegration: {
		method: 'post',
		url: '/integrations/accounting/invoices/sync',
	},
	createSubscription: {
		method: 'post',
		url: '/subscriptions',
	},
	updateSubscription: {
		method: 'post',
		url: '/subscriptions/update',
	},
	getSubscriptionList: {
		method: 'get',
		url: '/subscriptions/list/filter',
	},
	deleteSubscription: {
		method: 'delete',
		url: '/subscriptions/<id>',
	},
	getSubscription: {
		method: 'get',
		url: '/subscriptions/<id>',
	},
	getSubscriptionView: {
		method: 'get',
		url: '/subscriptions/view/pagination',
	},
	getSubscribersViewData: {
		method: 'get',
		url: '/customers/subscribers/view/pagination',
	},
	getSubscriptionsViewData: {
		method: 'get',
		url: '/contracts/subscriptions/view/pagination',
	},
	assignSubscription: {
		method: 'post',
		url: '/subscriptions/assign',
	},
	getSelectedSubscription: {
		method: 'get',
		url: '/subscriptions/assign/<id>',
	},
	sendMultipleDocuments: {
		method: 'post',
		url: '/documents/send',
	},
	updateAssignedSubscription: {
		method: 'post',
		url: '/subscriptions/assign/update',
	},
	deleteAssignedSubscription: {
		method: 'delete',
		url: '/subscriptions/assign/<id>',
	},
	chargeMultipleInvoice: {
		method: 'post',
		url: '/invoices/charge',
	},
	getReportsSummary: {
		method: 'get',
		url: '/analytics/reports',
	},
	getArrMomentumMetrics: {
		method: 'get',
		url: '/analytics/arr/momentum',
	},
	getTotalCustomersMetrics: {
		method: 'get',
		url: '/analytics/customer/total',
	},
	getProductPerformanceMetrics: {
		method: 'get',
		url: '/analytics/product/performance',
	},
	getContractProductsList: {
		method: 'get',
		url: '/product/used/list',
	},
	editActiveContract: {
		method: 'post',
		url: '/contracts/edit',
	},
	getSequences: {
		method: 'get',
		url: '/documents/dunning',
	},
	addNewSequence: {
		method: 'post',
		url: '/documents/dunning',
	},
	duplicateSequence: {
		method: 'post',
		url: '/documents/dunning/clone/<id>',
	},
	updateSequence: {
		method: 'post',
		url: '/documents/dunning/update',
	},
	deleteSequence: {
		method: 'delete',
		url: '/documents/dunning/<id>',
	},
	getSequenceById: {
		method: 'get',
		url: '/documents/dunning/<id>',
	},
	createEntityConnection: {
		method: 'post',
		url: '/integrations/<integrationProvider>/connect-entity',
	},
	removeEntityConnection: {
		method: 'post',
		url: '/integrations/<integrationProvider>/remove-entity',
	},
	updateLockedInvoice: {
		method: 'post',
		url: '/invoices/update/locked',
	},
	getPayoutsViewData: {
		method: 'get',
		url: 'payouts/view/pagination',
	},
	getAgreementsViewData: {
		method: 'get',
		url: '/agreements/referral/view/pagination',
	},
	getAgreementsSummary: {
		method: 'get',
		url: '/analytics/agreements/overview',
	},
	getPayoutsSummary: {
		method: 'get',
		url: '/analytics/payouts/overview',
	},
	updateIntegrationConnection: {
		method: 'post',
		url: '/integrations',
	},
	deleteIntegrationConnection: {
		method: 'delete',
		url: '/integrations/<id>',
	},
	duplicatePayout: {
		method: 'post',
		url: '/payouts/duplicate/<id>',
	},
	generatePayoutPDF: {
		method: 'get',
		url: '/payouts/pdf/generate/<id>',
	},
	getPayoutById: {
		method: 'get',
		url: '/payouts/preview/<id>',
	},
	updatePayout: {
		method: 'patch',
		url: '/payouts/<id>',
	},
	submitDocument: {
		method: 'post',
		url: 'documents/submit',
	},
	getPartnerLatestActivities: {
		method: 'get',
		url: '',
	},
	getSinglePartnerSummary: {
		method: 'get',
		url: 'partners/referral/summary',
	},
	getBreakDownProductsData: {
		method: 'get',
		url: 'payouts/product/summary',
	},
	getPartnerReferralView: {
		method: 'get',
		url: 'partners/referral/view',
	},
};

export enum PathsConfig {
	getPartnerReferralView = 'getPartnerReferralView',
	getBreakDownProductsData = 'getBreakDownProductsData',
	getSinglePartnerSummary = 'getSinglePartnerSummary',
	getPartnerLatestActivities = 'getPartnerLatestActivities',
	submitDocument = 'submitDocument',
	updatePayout = 'updatePayout',
	duplicatePayout = 'duplicatePayout',
	generatePayoutPDF = 'generatePayoutPDF',
	getPayoutById = 'getPayoutById',
	cancelAgreement = 'cancelAgreement',
	deleteAgreement = 'deleteAgreement',
	getAgreementsSummary = 'getAgreementsSummary',
	getPayoutsSummary = 'getPayoutsSummary',
	getAgreementsViewData = 'getAgreementsViewData',
	deleteIntegrationConnection = 'deleteIntegrationConnection',
	updateIntegrationConnection = 'updateIntegrationConnection',
	removeEntityConnection = 'removeEntityConnection',
	createEntityConnection = 'createEntityConnection',
	updateLockedInvoice = 'updateLockedInvoice',
	updateLockedCreditNote = 'updateLockedCreditNote',
	getPayoutsViewData = 'getPayoutsViewData',
	downloadDocumentsByIds = 'downloadDocumentsByIds',
	deleteSequence = 'deleteSequence',
	duplicateSequence = 'duplicateSequence',
	addNewSequence = 'addNewSequence',
	updateSequence = 'updateSequence',
	getSequenceById = 'getSequenceById',
	getSequences = 'getSequences',
	getProductPerformanceMetrics = 'getProductPerformanceMetrics',
	getTotalCustomersMetrics = 'getTotalCustomersMetrics',
	getArrMomentumMetrics = 'getArrMomentumMetrics',
	getReportsSummary = 'getReportsSummary',
	chargeInvoice = 'chargeInvoice',
	removeIntegration = 'removeIntegration',
	createIntegrationWithEntity = 'createIntegrationWithEntity',
	clonePricingModel = 'clonePricingModel',
	getCustomersSummary = 'getCustomersSummary',
	continueIntegrationWithEntity = 'continueIntegrationWithEntity',
	getCustomersByIntegration = 'getCustomersByIntegration',
	disconnectIntegrationFromEntity = 'disconnectIntegrationFromEntity',
	updateStartingNumberByDocumentType = 'updateStartingNumberByDocumentType',
	getExpectedNumberByDocumentType = 'getExpectedNumberByDocumentType',
	getStartingNumberSettingsByDocumentType = 'getStartingNumberSettingsByDocumentType',
	getContractsAmounts = 'getContractsAmounts',
	getContractsList = 'getContractsList',
	getRevenueSchedule = 'getRevenueSchedule',
	getCashInflow = 'getCashInflow',
	getRevenueGraphsView = 'getRevenueGraphsView',
	getDeferredView = 'getDeferredView',
	getDocumentsView = 'getDocumentsView',
	getContractSummary = 'getContractSummary',
	getContractDocument = 'getContractDocument',
	getCreditNoteData = 'getCreditNoteData',
	downloadCreditNoteById = 'downloadCreditNoteById',
	downloadCreditNoteByDto = 'downloadCreditNoteByDto',
	getCreditNotesSummary = 'getCreditNotesSummary',
	publishCreditNote = 'publishCreditNote',
	createCreditNote = 'createCreditNote',
	updateCreditNote = 'updateCreditNote',
	deleteCreditNote = 'deleteCreditNote',
	getCreditNoteById = 'getCreditNoteById',
	getCreditNotesViews = 'getCreditNotesViews',
	createUsageAggregator = 'createUsageAggregator',
	getSettingsDataByAttributeName = 'getSettingsDataByAttributeName',
	getSettingsStringByAttributeName = 'getSettingsStringByAttributeName',
	updateSettingsDataByAttributeName = 'updateSettingsDataByAttributeName',
	createContractsDocuments = 'createContractsDocuments',
	cancelIntegration = 'cancelIntegration',
	getSplittedInvoiceById = 'getSplittedInvoiceById',
	splitInvoiceById = 'splitInvoiceById',
	downloadDocumentById = 'downloadDocumentById',
	updateCustomer = 'updateCustomer',
	deleteCustomer = 'deleteCustomer',
	updateSupplier = 'updateSupplier',
	getSuppliers = 'getSuppliers',
	getSuppliersById = 'getSuppliersById',
	getPaymentsSummary = 'getPaymentsSummary',
	getInvoiceData = 'getInvoiceData',
	getInvoicesViewData = 'getInvoicesViewData',
	updateInvoice = 'updateInvoice',
	sendInvoice = 'sendInvoice',
	assignInvoiceNumber = 'assignInvoiceNumber',
	getCustomersViewData = 'getCustomersViewData',
	getCustomerData = 'getCustomerData',
	getSupplierData = 'getSupplierData',
	getCustomersList = 'getCustomersList',
	getAllPricingModels = 'getAllPricingModels',
	getPricingModelTemplates = 'getPricingModelTemplates',
	getPricingModelData = 'getPricingModelData',
	addNewCustomer = 'addNewCustomer',
	addNewSupplier = 'addNewSupplier',
	createPricingModel = 'createPricingModel',
	updatePricingModel = 'updatePricingModel',
	publishPricingModel = 'publishPricingModel',
	createContract = 'createContract',
	updateContract = 'updateContract',
	publishContract = 'publishContract',
	deleteContract = 'deleteContract',
	cancelContract = 'cancelContract',
	cloneContract = 'cloneContract',
	cloneAgreement = 'cloneAgreement',
	getContractData = 'getContractData',
	getContractsView = 'getContractsView',
	getContractsInvoiceList = 'getContractsInvoiceList',
	authLogin = 'authLogin',
	authLogout = 'authLogout',
	authUserList = 'authUserList',
	authCurrentUser = 'authCurrentUser',
	authUserEnable = 'authUserEnable',
	authDeleteUser = 'authDeleteUser',
	authUserUpdate = 'authUserUpdate',
	authInviteUser = 'authInviteUser',
	authVerifyUser = 'authVerifyUser',
	getAuthStatus = 'getAuthStatus',
	getCategoriesList = 'getCategoriesList',
	getUsageProductList = 'getUsageProductList',
	getUsageGroupList = 'getUsageGroupList',
	createUsageProduct = 'createUsageProduct',
	getUsageReportsViewData = 'getUsageReportsViewData',
	getUsageSnippet = 'getUsageSnippet',
	publishUsageProduct = 'publishUsageProduct',
	createUsageType = 'createUsageType',
	sendManualUsageReport = 'sendManualUsageReport',
	getUsageReportProducts = 'getUsageReportProducts',
	myProductsList = 'myProductsList',
	createNewProduct = 'createNewProduct',
	connectModelToProduct = 'connectModelToProduct',
	reconcileDocuments = 'reconcileDocuments',
	getUsageProductSummaryList = 'getUsageProductSummaryList',
	getInvoiceFlowData = 'getInvoiceFlowData',
	getCountries = 'getCountries',
	deleteUsageProduct = 'deleteUsageProduct',
	getCommonTemplatesList = 'getCommonTemplatesList',
	deleteSupplier = 'deleteSupplier',
	getInvoiceStatusOverTime = 'getInvoiceStatusOverTime',
	getCollectionStatus = 'getCollectionStatus',
	getInvoiceForecast = 'getInvoiceForecast',
	getReceivableAging = 'getReceivableAging',
	updateUsageProduct = 'updateUsageProduct',
	getUsageProduct = 'getUsageProduct',
	onDeleteProduct = 'onDeleteProduct',
	getUsageProductAnalyticsList = 'getUsageProductAnalyticsList',
	onDeletePricingModelTemplate = 'onDeletePricingModelTemplate',
	getUsageConnectionUrl = 'getUsageConnectionUrl',
	onDeleteUsageReport = 'onDeleteUsageReport',
	onUpdateUsageReport = 'onUpdateUsageReport',
	editProduct = 'editProduct',
	cancelInvoice = 'cancelInvoice',
	duplicateProduct = 'duplicateProduct',
	createMultipleCustomers = 'createMultipleCustomers',
	getMergeLinkToken = 'getMergeLinkToken',
	swapMergePublicToken = 'swapMergePublicToken',
	cancelMergeAccount = 'cancelMergeAccount',
	getSyncedContracts = 'getSyncedContracts',
	getIntegrations = 'getIntegrations',
	disconnectMergeAccount = 'disconnectMergeAccount',
	getUsageReportInvoiceList = 'getUsageReportInvoiceList',
	getUsageReportDocuments = 'getUsageReportDocuments',
	updateExportIntegrationItems = 'updateExportIntegrationItems',
	getIntegrationMatchingItems = 'getIntegrationMatchingItems',
	getSalesforcePriceBooks = 'getSalesforcePriceBooks',
	getItemPriceItems = 'getItemPriceItems',
	createItemPrice = 'createItemPrice',
	doneImportConfiguration = 'doneImportConfiguration',
	getItems = 'getItems',
	createItem = 'createItem',
	getUsageLogObjectMapperList = 'getUsageLogObjectMapperList',
	postUsageLogObjectMapper = 'postUsageLogObjectMapper',
	uploadUsageLogsFileAndMatcher = 'uploadUsageLogsFileAndMatcher',
	saveUsageLogs = 'saveUsageLogs',
	uploadUsageLogsFile = 'uploadUsageLogsFile',
	getCsvWithErrors = 'getCsvWithErrors',
	getUsageLogPdfPreview = 'getUsageLogPdfPreview',
	deleteUsageLogObjectMapper = 'deleteUsageLogObjectMapper',
	sendCreditNote = 'sendCreditNote',
	downloadUsageLogs = 'downloadUsageLogs',
	downloadDocumentUsageLogs = 'downloadDocumentUsageLogs',
	generateInvoiceIdForDownload = 'generateInvoiceIdForDownload',
	generateCreditIdForDownload = 'generateCreditIdForDownload',
	connectIntegrationWithEntity = 'connectIntegrationWithEntity',
	getIntegrationsCards = 'getIntegrationsCards',
	updateUserPersonalInfo = 'updateUserPersonalInfo',
	getAnalyticsSummary = 'getAnalyticsSummary',
	getUpForRenewal = 'getUpForRenewal',
	getCustomerLocation = 'getCustomerLocation',
	sendErrorReportId = 'sendErrorReportId',
	duplicateInvoiceById = 'duplicateInvoiceById',
	onDeleteMultipleUsageReports = 'onDeleteMultipleUsageReports',
	duplicateCreditIdById = 'duplicateCreditIdById',
	cancelCreditNote = 'cancelCreditNote',
	getExistsCustomerList = 'getExistsCustomerList',
	deleteAccountingIntegrationItems = 'deleteAccountingIntegrationItems',
	syncInvoiceWithAccountingIntegration = 'syncInvoiceWithAccountingIntegration',
	createSubscription = 'createSubscription',
	updateSubscription = 'updateSubscription',
	getSubscriptionList = 'getSubscriptionList',
	deleteSubscription = 'deleteSubscription',
	getSubscription = 'getSubscription',
	getSubscriptionView = 'getSubscriptionView',
	getSubscribersViewData = 'getSubscribersViewData',
	getSubscriptionsViewData = 'getSubscriptionsViewData',
	assignSubscription = 'assignSubscription',
	getSelectedSubscription = 'getSelectedSubscription',
	sendMultipleDocuments = 'sendMultipleDocuments',
	updateAssignedSubscription = 'updateAssignedSubscription',
	deleteAssignedSubscription = 'deleteAssignedSubscription',
	chargeMultipleInvoice = 'chargeMultipleInvoice',
	getContractProductsList = 'getContractProductsList',
	resyncIntegrationMatchingItems = 'resyncIntegrationMatchingItems',
	editActiveContract = 'editActiveContract',
	getRevenueTableView = 'getRevenueTableView',
}
