import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../types/storeTypes';
import { UsageReportView } from '../../types/usageTypes';
import { DatePickerFormatType } from '../_uiComponents';
import { ContractSideBar } from './ContractSideBar/ContractSideBar';
import { CreditNoteSidebar } from './CreditNoteSidebar/CreditNoteSidebar';
import { UsageReportSideBar } from './UsageReportSideBar/UsageReportSideBar';
import { SidebarToOpen, SidebarType } from './Sidebars.utils';
import { InvoicesSideBar } from './InvoicesSideBar/InvoicesSideBar';
import { Customer } from '../../types/customerTypes';
import { AssignSubscription } from '..';
import { PayoutSideBar } from './PayoutSideBar/PayoutSideBar';
import { NavigateToDifferentSingleDocumentPage } from '../../types/generalTypes';
import styles from './Sidebars.module.scss';

export interface SidebarsProps {
	openSideBarById?: { sideBarType: SidebarType; id: string };
	containersClassName?: string;
	selectedCustomer?: Customer;
	selectedUsagePeriod?: DatePickerFormatType;
	selectedUsageReport?: UsageReportView;
	isSecondarySideBar?: boolean;
	disableDocumentSideBar?: boolean;
	closeAllSideBars?: boolean;
	navigateToDifferentSingleDocumentPage?: NavigateToDifferentSingleDocumentPage;
	onUpdateManualReport?: () => void;
	onCloseSideBars?: () => void;
	onDataChange?: () => void;
}

export const Sidebars = ({
	containersClassName,
	disableDocumentSideBar,
	selectedCustomer,
	selectedUsageReport,
	selectedUsagePeriod,
	openSideBarById,
	navigateToDifferentSingleDocumentPage,
	closeAllSideBars,
	onCloseSideBars,
	onDataChange,
	onUpdateManualReport,
}: SidebarsProps) => {
	const [activeSideBars, setActiveSideBars] = useState<SidebarToOpen[]>([]);
	const [refreshData, setRefreshData] = useState(false);
	const [customer, setCustomer] = useState<Customer>();
	const [sidebarToBeNoticed, setSidebarToBeNoticed] = useState<SidebarType>();

	const importData = useSelector((store: Store) => store.general.importedDataIndicator);

	useEffect(() => {
		// refresh sidebars when csv loading detected
		if (importData?.totalItems && activeSideBars.length && importData?.totalItems === importData?.importedItems) {
			setTimeout(() => {
				setRefreshData(true);
				onDataChange && onDataChange();
				setTimeout(() => {
					setRefreshData(false);
				}, 100);
			}, 10000);
		}
	}, [importData?.totalItems, importData?.importedItems]);

	useEffect(() => {
		if (openSideBarById) {
			manageActiveSidebars({
				sidebarType: openSideBarById?.sideBarType,
				id: openSideBarById?.id,
				usageReport: selectedUsageReport,
				usagePeriod: selectedUsagePeriod,
			});
		}

		selectedCustomer && setCustomer(selectedCustomer);
	}, [openSideBarById?.id, openSideBarById?.sideBarType, selectedCustomer, selectedUsagePeriod, selectedUsageReport]);

	useEffect(() => closeSideBars(), [closeAllSideBars]);

	const manageActiveSidebars = (incoming: SidebarToOpen) => {
		if (incoming.sidebarType === SidebarType.ASSIGN_SUBSCRIPTION) {
			closeSideBars();
			return setTimeout(() => setActiveSideBars([incoming]), 500);
		}
		if (!isActiveSideBarListFull()) {
			//  open side bars count < 2
			// incoming side bar type closed
			if (!isSidebarOpenByType(incoming?.sidebarType)) {
				const newList = [...activeSideBars];
				newList.push(incoming);
				setActiveSideBars(newList);
			} // incoming side bar type is opened
			else if (incoming?.triggeredBy && isSidebarOpenByType(incoming?.sidebarType)) {
				//  incoming side bar value is opened
				if (incoming?.triggeredBy && !isSidebarOpen(incoming)) {
					const newList = [...activeSideBars];
					newList.pop();
					newList[0] = incoming;
					setActiveSideBars(newList);
				}
			}
		}

		//  open side bars count = 2
		else {
			// incoming side bar type is closed
			if (!isSidebarOpenByType(incoming?.sidebarType)) {
				if (incoming?.triggeredBy && isSidebarOpenAsPrimary(incoming?.triggeredBy)) {
					const newList = [...activeSideBars];
					newList.pop();
					newList.push(incoming);
					setActiveSideBars(newList);
				} else if (incoming?.triggeredBy && isSidebarOpenAsSecondary(incoming?.triggeredBy)) {
					if (incoming?.triggeredBy != incoming.sidebarType) {
						const newList = [...activeSideBars];
						newList.pop();
						setActiveSideBars(newList);

						setTimeout(() => {
							setActiveSideBars([activeSideBars[0]]);

							setTimeout(() => {
								setActiveSideBars([activeSideBars[1]]);

								setTimeout(() => setActiveSideBars([activeSideBars[1], incoming]), 400);
							}, 300);
						}, 50);
					} else if (incoming?.triggeredBy == incoming.sidebarType) {
						const newList = [...activeSideBars];
						newList[1] = incoming;
						setActiveSideBars(newList);
					}
				}
			}
			// incoming side bar type is open
			else {
				// incoming side bar value is closed
				if (!isSidebarOpen(incoming)) {
					if (isSidebarOpenAsPrimary(incoming?.sidebarType)) {
						const newList = [...activeSideBars];
						newList.pop();
						newList[0] = incoming;

						setActiveSideBars(newList);
					} else if (isSidebarOpenAsSecondary(incoming?.sidebarType)) {
						const newList = [...activeSideBars];
						newList[1] = incoming;
						setActiveSideBars(newList);
					}
				}
				// incoming side bar value is open
				else {
					setSidebarToBeNoticed(incoming?.sidebarType);
				}
			}
		}
	};

	const isActiveSideBarListFull = () => {
		return activeSideBars?.length === 2;
	};

	const isActiveSideBarListIsEmpty = () => {
		return activeSideBars?.length === 0;
	};

	const isSidebarOpenAsPrimary = (sidebarType?: SidebarType) => {
		return !isActiveSideBarListIsEmpty() && activeSideBars[0].sidebarType === sidebarType;
	};

	const isSidebarOpenAsSecondary = (sidebarType?: SidebarType) => {
		return isActiveSideBarListFull() && activeSideBars[1].sidebarType === sidebarType;
	};

	const isSidebarOpenByType = (sidebarType?: SidebarType) => {
		const activeSideBarsTypes = activeSideBars.map((sideBar) => sideBar?.sidebarType);
		return activeSideBarsTypes?.includes(sidebarType);
	};

	const isSidebarOpen = (sidebar: SidebarToOpen) => {
		const activeSideBarsTypes = activeSideBars.map((sideBar) => sideBar.sidebarType);
		const activeSideBarsIds = activeSideBars.map((sideBar) => sideBar.id);
		const res = activeSideBarsTypes?.includes(sidebar.sidebarType) && activeSideBarsIds?.includes(sidebar?.id);
		return res;
	};

	const getSidebarDataByType = (sidebarType: SidebarType) => {
		return activeSideBars.find((sideBar) => sideBar.sidebarType === sidebarType);
	};

	const getSelectedRowId = () => {
		return isActiveSideBarListFull() ? activeSideBars[1]?.id : activeSideBars[0]?.id;
	};

	const closeSecondarySidebar = () => {
		if (isActiveSideBarListFull()) {
			const newList = [...activeSideBars];
			newList.pop();
			setActiveSideBars(newList);
			setSidebarToBeNoticed(undefined);
		}
	};

	const closeSideBars = () => {
		setCustomer(undefined);
		if (isActiveSideBarListIsEmpty()) return;
		if (isActiveSideBarListFull()) {
			setTimeout(() => {
				setActiveSideBars([activeSideBars[0]]);
				setTimeout(() => {
					setActiveSideBars([]);
				}, 250);
			}, 50);
		} else {
			setActiveSideBars([]);
		}

		setSidebarToBeNoticed(undefined);
		onCloseSideBars?.();
	};

	const onRefreshData = () => {
		setRefreshData(!refreshData);
		onDataChange?.();
		onUpdateManualReport?.();
	};

	return (
		<>
			<InvoicesSideBar
				id={getSidebarDataByType(SidebarType.INVOICE)?.id}
				navigateToDifferentSingleDocumentPage={navigateToDifferentSingleDocumentPage}
				isOpen={isSidebarOpenByType(SidebarType.INVOICE)}
				isSecondarySideBar={isSidebarOpenAsSecondary(SidebarType.INVOICE)}
				selectedRowId={getSelectedRowId()}
				sidebarToBeNoticed={sidebarToBeNoticed == SidebarType.INVOICE}
				setSidebarToBeNoticed={() => setSidebarToBeNoticed(undefined)}
				refreshData={refreshData}
				containersClassName={isSidebarOpenAsPrimary(SidebarType.USAGE_REPORT) ? styles.sidebar : containersClassName}
				onDataChange={onRefreshData}
				openOtherSidebar={manageActiveSidebars}
				setCustomer={setCustomer}
				closeSideBar={closeSideBars}
				closeSecondarySidebar={closeSecondarySidebar}
			/>

			<ContractSideBar
				id={getSidebarDataByType(SidebarType.CONTRACT)?.id}
				isOpen={isSidebarOpenByType(SidebarType.CONTRACT)}
				selectedRowId={getSelectedRowId()}
				refreshData={refreshData}
				sidebarToBeNoticed={sidebarToBeNoticed == SidebarType.CONTRACT}
				isSecondarySideBar={isSidebarOpenAsSecondary(SidebarType.CONTRACT)}
				setSidebarToBeNoticed={() => setSidebarToBeNoticed(undefined)}
				openOtherSidebar={manageActiveSidebars}
				onDataChange={onRefreshData}
				closeSideBar={closeSideBars}
				closeSecondarySidebar={closeSecondarySidebar}
			/>

			<UsageReportSideBar
				isOpen={isSidebarOpenByType(SidebarType.USAGE_REPORT)}
				isSecondarySideBar={isSidebarOpenAsSecondary(SidebarType.USAGE_REPORT)}
				selectedCustomer={customer}
				sidebarToBeNoticed={sidebarToBeNoticed == SidebarType.USAGE_REPORT}
				setSidebarToBeNoticed={() => setSidebarToBeNoticed(undefined)}
				disableDocumentSideBar={disableDocumentSideBar}
				selectedUsagePeriod={getSidebarDataByType(SidebarType.USAGE_REPORT)?.usagePeriod}
				selectedUsageReport={getSidebarDataByType(SidebarType.USAGE_REPORT)?.usageReport}
				openOtherSidebar={manageActiveSidebars}
				closeSideBar={closeSideBars}
				onUpdateManualReport={onRefreshData}
				closeSecondarySidebar={closeSecondarySidebar}
			/>

			<CreditNoteSidebar
				id={getSidebarDataByType(SidebarType.CREDIT_NOTE)?.id}
				isOpen={isSidebarOpenByType(SidebarType.CREDIT_NOTE)}
				isSecondarySideBar={isSidebarOpenAsSecondary(SidebarType.CREDIT_NOTE)}
				refreshData={refreshData}
				sidebarToBeNoticed={sidebarToBeNoticed == SidebarType.CREDIT_NOTE}
				setSidebarToBeNoticed={() => setSidebarToBeNoticed(undefined)}
				selectedRowId={getSelectedRowId()}
				openOtherSidebar={manageActiveSidebars}
				closeSideBar={closeSideBars}
				onDataChange={onRefreshData}
				containersClassName={isSidebarOpenAsPrimary(SidebarType.USAGE_REPORT) ? styles.sidebar : containersClassName}
				closeSecondarySidebar={closeSecondarySidebar}
			/>

			<PayoutSideBar
				id={getSidebarDataByType(SidebarType.PAYOUT)?.id}
				isOpen={isSidebarOpenByType(SidebarType.PAYOUT)}
				navigateToDifferentSingleDocumentPage={navigateToDifferentSingleDocumentPage}
				isSecondarySideBar={isSidebarOpenAsSecondary(SidebarType.PAYOUT)}
				refreshData={refreshData}
				sidebarToBeNoticed={sidebarToBeNoticed == SidebarType.PAYOUT}
				setSidebarToBeNoticed={() => setSidebarToBeNoticed(undefined)}
				selectedRowId={getSelectedRowId()}
				openOtherSidebar={manageActiveSidebars}
				closeSideBar={closeSideBars}
				onDataChange={onRefreshData}
				containersClassName={isSidebarOpenAsPrimary(SidebarType.USAGE_REPORT) ? styles.sidebar : containersClassName}
				closeSecondarySidebar={closeSecondarySidebar}
			/>

			<AssignSubscription
				selectedCustomer={selectedCustomer}
				selectedSubscriptionId={getSidebarDataByType(SidebarType.ASSIGN_SUBSCRIPTION)?.id}
				isOpen={isSidebarOpenByType(SidebarType.ASSIGN_SUBSCRIPTION)}
				closeSideBar={closeSideBars}
				onAssignSubscription={onRefreshData}
			/>
		</>
	);
};
