import clsx from 'clsx';
import React from 'react';
import styles from './Tooltip.module.scss';

export type TooltipPlacement = 'top' | 'bottom' | 'right' | 'left';
export interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
	tooltipComponent: React.ReactNode;
	children: React.ReactNode;
	containerClassName?: string;
	className?: string;
	disabled?: boolean;
	blackTooltip?: boolean;
	placement?: TooltipPlacement;
	dataTestId?: string;
	onClick?: () => void;
}

export const Tooltip = ({
	tooltipComponent,
	children,
	placement = 'top',
	containerClassName,
	disabled,
	blackTooltip,
	className,
	dataTestId,
	onClick,
	...props
}: TooltipProps) => {
	return (
		<div className={clsx(styles.container, containerClassName)} data-testid={dataTestId} onClick={onClick}>
			{!disabled && !!tooltipComponent && (
				<div
					{...props}
					data-testid='tooltip'
					className={clsx(styles.tooltipContainer, styles[placement], blackTooltip && styles.blackTooltipContainer, className)}
				>
					{tooltipComponent}
				</div>
			)}
			{children}
		</div>
	);
};
