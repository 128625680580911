import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Badge, Button, Tooltip, VerticalStepper } from '../../../components';
import { Step } from '../../../components/_uiComponents/VerticalStepper/Step/Step';
import { TitleOptions } from '../../../utils/IntegrationUtils';
import { CompanyTabs, Sections } from '../../../pages/Settings/Settings.utils';
import { IntegrationType } from '../../../types/integrationTypes';
import styles from './IntegrationSideBar.module.scss';

interface IntegrationSideBarProps {
	header?: { icon: string; title: TitleOptions; subTitle?: string };
	stepperList: Step[];
	activeIndex: number;
	errors: { [index: number]: string[] };
	connectionStatus: string;
	connection?: IntegrationType;
	onUpdateStep(newList: Step[]): void;
	updateActiveIndex(index: number): void;
	onSave(): void;
}

export const IntegrationSideBar = ({
	header,
	stepperList,
	activeIndex,
	errors,
	connectionStatus,
	connection,
	updateActiveIndex,
	onUpdateStep,
	onSave,
}: IntegrationSideBarProps) => {
	const { t } = useTranslation('translation');
	const navigate = useNavigate();

	const onPressNextOrSave = () => {
		if (activeIndex + 1 < stepperList?.length) {
			const newList = [...(stepperList || [])];
			newList[activeIndex].state = 'IN_PROGRESS';

			newList[activeIndex + 1].state = 'IN_PROGRESS';
			onUpdateStep(newList);
			updateActiveIndex(activeIndex + 1);
		} else {
			onSave();
		}
	};

	const errorStrings = Object.keys(errors).flatMap((err) => errors[+err].map((e) => e));

	return (
		<div className={styles.sidebar}>
			<div className={styles.header}>
				<div className={styles.iconContainer}>
					<div className={styles.iconWrapper}>
						<img alt='cardIcon' src={header?.icon} className={styles.cardIcon} />
					</div>

					<Badge type='secondary' color='success' className={styles.badge} dataTestId='integration-status-badge'>
						{connectionStatus}
					</Badge>
				</div>

				<div>
					<div className={styles.title}>{header?.title}</div>
					<div className={styles.title}>{header?.subTitle}</div>
				</div>

				<div className={styles.descriptionContent}>
					<div className={styles.description}>{connection?.supplier?.name} </div>
					<div className={clsx(styles.description, styles.neutralText)}>{connection?.metadata?.subsidiary?.name}</div>
				</div>
			</div>

			<div>
				{stepperList && (
					<VerticalStepper stepsList={stepperList} updateSteps={onUpdateStep} updateActiveIndex={updateActiveIndex} className={styles.stepper} />
				)}
			</div>

			<div className={styles.buttonContainer}>
				<Tooltip tooltipComponent={errorStrings.length ? errorStrings.map((err) => <div key={err}>{err}</div>) : null}>
					<Button
						className={styles.publishButton}
						onClick={onPressNextOrSave}
						disabled={!!errorStrings.length && stepperList?.length === activeIndex + 1}
					>
						{t(stepperList?.length === activeIndex + 1 ? 'done' : 'next')}
					</Button>
				</Tooltip>

				<Button
					className={styles.button}
					type='link'
					color='neutral'
					onClick={() => navigate('/settings', { state: { activeSection: Sections.COMPANY, activeButton: CompanyTabs.INTEGRATIONS } })}
				>
					{t('close')}
				</Button>
			</div>
		</div>
	);
};
