import { Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { v4 as uuidV4 } from 'uuid';
import { setHideLoader, setShowLoader } from '../../storeSlices/generalSlice';
import { setIsAuthenticated } from '../../storeSlices/userSlice';
import { PathsConfig, PATH_TO_CONFIG } from './configPaths';
import { keepAliveIds, startKeepAlive, stopKeepAliveTime } from '../keepAliveService/keepAliveService';

interface httpServiceProps {
	path: PathsConfig;
	data?: any;
	params?: any;
	urlParam?: Record<string, string | number>;
	showLoader?: boolean;
	responseType?: 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream';
	unResetKeepAlive?: boolean;
	dispatch: Dispatch;
}

const instance = axios.create({
	baseURL: window._env_?.REACT_APP_API_ENDPOINT || '',
	timeout: parseInt(window._env_?.REACT_APP_API_TIMEOUT || '3000'),
	headers: { 'X-Custom-Header': 'foobar' },
	withCredentials: true,
});

export const setupInterceptor = (dispatch: Dispatch) => {
	instance.interceptors.request.use(
		(req) => {
			return req;
		},
		(err) => {
			dispatch(setHideLoader(null));
			return Promise.reject(err);
		},
	);

	instance.interceptors.response.use(
		(res) => {
			return Promise.resolve(res);
		},
		(err) => {
			dispatch(setHideLoader(null));

			if (err.response?.status === 401 || err.response?.status === 403) {
				dispatch(setIsAuthenticated(false));
			}
			return Promise.reject(err);
		},
	);
};

export const getUrlWithParams = (url: string, params: Record<string, string | number> = {}) => {
	return Object.entries(params).reduce((urlWithParams, [key, value]) => {
		return urlWithParams.replace(`<${key}>`, String(value));
	}, url);
};

export const httpService = async ({
	path,
	data,
	params,
	urlParam,
	dispatch,
	responseType,
	showLoader = true,
	unResetKeepAlive,
}: httpServiceProps) => {
	if (!unResetKeepAlive) {
		stopKeepAliveTime();
		if (keepAliveIds.length === 0) {
			startKeepAlive(dispatch);
		}
	}

	const loaderId = uuidV4();
	showLoader && dispatch(setShowLoader(loaderId));

	const { method, url } = PATH_TO_CONFIG[path];
	const urlWithParams = getUrlWithParams(url, urlParam);

	const LoaderTimeout = isNaN(+window._env_?.REACT_APP_API_TIMEOUT) ? 120000 : +window._env_?.REACT_APP_API_TIMEOUT;
	setTimeout(() => {
		dispatch(setHideLoader(loaderId));
	}, LoaderTimeout);

	const res = await instance({ method, url: urlWithParams, data, params, headers: { 'X-Request-ID': uuidV4() }, responseType });
	showLoader && dispatch(setHideLoader(loaderId));
	return res;
};
