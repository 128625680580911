/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { Button, Icon, IconImgType, Input, InputProps, Tabs } from '../../../../../components';
import { SelectItem } from '../../../../../types/generalTypes';
import { tabsSelectComponentType } from './TabsSelect.utils';
import { loggerService } from '../../../../../services/loggerService/loggerService';
import styles from './TabsSelect.module.scss';

export interface TabsSelectItem {
	title: string;
	isSearchable?: boolean;
	type: any;
	imgType?: IconImgType;
	emptyText?: string;
	optionsList?: SelectItem[] | string[];
}
export interface TabsSelectProps {
	inputProps: InputProps<any>;
	title: string;
	required?: boolean;
	data?: TabsSelectItem[];
	defaultValue?: { value: string; tabType: any; label?: string };
	showError?: boolean;
	csvHeaders: string[];
	onChange(tab?: TabsSelectItem, selectedValue?: string, selectLabel?: string): void;
}

export const TabsSelect = ({ inputProps, data = [], title, required, defaultValue, showError, csvHeaders, onChange }: TabsSelectProps) => {
	const [referenceElement, setReferenceElement] = useState<any>(null);
	const [popperElement, setPopperElement] = useState<any>(null);
	const [isOpen, setIsOpen] = useState(false);
	const [selectedTab, setSelectedTab] = useState(0);
	const [selectedValue, setSelectedValue] = useState<{ selectedTabType: any; selectedItem: SelectItem | string }>();
	const [selectLabel, setSelectLabel] = useState<string>('');
	const [searchText, setSearchText] = useState('');
	const {
		styles: popperStyle,
		attributes,
		update,
	} = usePopper(referenceElement, popperElement, {
		placement: 'auto',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'flip',
				options: {
					allowedAutoPlacements: ['top', 'bottom'],
					flipVariations: true,
					fallbackPlacements: ['top'],
				},
			},
		],
	});

	useEffect(() => {
		const tabIndex = data.findIndex((tab) => tab.type === defaultValue?.tabType);
		setSelectLabel(defaultValue?.label || defaultValue?.value ? `${defaultValue?.label || defaultValue?.value}${required ? ' *' : ''}` : '');
		if (tabIndex >= 0) {
			// @ts-ignore
			const selectItem = data[tabIndex].optionsList?.find((item: SelectItem | string) =>
				typeof item == 'string' ? item === defaultValue?.value : item.value === defaultValue?.value,
			);
			setSelectedValue({ selectedTabType: data[tabIndex].type, selectedItem: selectItem });
			setSelectedTab(tabIndex);
		}
		// Here for check reload bug, remove if RCVD-2931 closed
		loggerService(`useEffect render usage logs- defaultValue:${defaultValue?.value}`);
	}, [defaultValue?.value, isOpen]);

	const tabsList = data?.map(({ title }) => ({
		label: title,
		value: title,
	}));

	const onSelectValue = (val: SelectItem | string) => {
		setSelectedValue(() => ({ selectedTabType: data[selectedTab].type, selectedItem: val }));
		onChange(data[selectedTab], typeof val === 'string' ? val : val.value, typeof val === 'string' ? undefined : (val.label as string));

		const label = `${typeof val === 'string' ? val : val.label}${required && val ? ' *' : ''}`;
		setSelectLabel(label);
		onClose();
	};

	const onReset = () => {
		setSelectedValue(undefined);
		setSelectLabel('');
		setSelectedTab(0);
		onChange();
		onClose();
	};

	const onSearch = (): SelectItem[] | string[] => {
		// @ts-ignore
		return data[selectedTab].optionsList.filter((item, index) => {
			const val = typeof item === 'string' ? item : item?.label || item.value;
			return val.toLowerCase().includes(searchText.toLowerCase());
		});
	};

	const onClose = () => {
		setSearchText('');
		setIsOpen(false);
	};

	return (
		<>
			<div
				style={popperStyle.offset}
				ref={(ref) => setReferenceElement(ref)}
				onClick={() => {
					update?.();
					setIsOpen(true);
				}}
			>
				<Input {...inputProps} defaultValue={selectLabel} value={selectLabel} showError={showError} />
			</div>

			{isOpen &&
				ReactDOM.createPortal(
					<>
						<div
							ref={(ref) => setPopperElement(ref)}
							{...attributes.popper}
							className={clsx(styles.selectDropdownContainer)}
							style={{
								...popperStyle.popper,
							}}
						>
							<div className={styles.selectHeader}>
								<div className={styles.title}>{title}</div>

								<Button type='link' onClick={onReset}>
									<Icon imgType='delete' color='destructive' />
								</Button>
							</div>

							<Tabs
								chosenTab={selectedTab}
								tabsArray={tabsList}
								updateChosenTab={(index) => setSelectedTab(index)}
								classNames={{ tabsContainer: styles.tabs }}
							/>
							<div className={styles.selectContainer}>
								{tabsSelectComponentType(data[selectedTab].type, {
									selectedTab,
									data,
									selectedValue,
									defaultValue,
									csvHeaders,
									onSearch,
									setSearchText,
									onSelectValue,
								})}
							</div>
						</div>
					</>,
					document.body,
				)}
			{isOpen && <div className={styles.selectOverlay} onClick={onClose}></div>}
		</>
	);
};
