import { ContractState, DEFAULT_CURRENCY } from '@received/pricing-model';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../services';
import { PermissionsMapping } from '../../../services/PermissionsMapping/PermissionsMapping';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { Contract, PricingModelTab } from '../../../types/contractTypes';
import { AccountPermissionsActionsList } from '../../../types/generalTypes';
import { Store } from '../../../types/storeTypes';
import { ActivationDates, getActivationDatesFromAllContracts } from './AnalyticsTab.utils';
import { getUniqueListValues } from '../../../utils/GeneralUtils';
import { CashInflow, RevenueRecognitionComponent, Schedule } from '../..';
import { CustomerTabProps } from '../CustomerTabs.utils';
import styles from './AnalyticsTab.module.scss';

export const AnalyticsTab = ({ isActive, customerData, timePeriod, sideBarFilters, refreshTabs }: CustomerTabProps) => {
	const [activationDates, setActivationDates] = useState<ActivationDates>();
	const [products, setProducts] = useState<PricingModelTab[]>([]);

	const { toastNotification } = useSelector((store: Store) => store.general);
	const dispatch = useDispatch();

	const currency = DEFAULT_CURRENCY;

	useEffect(() => {
		isActive && customerData && getContracts();
	}, [
		customerData,
		refreshTabs,
		customerData?.id,
		timePeriod,
		sideBarFilters.filterData,
		sideBarFilters.filterTags,
		toastNotification.showToastNotification,
	]);

	const getContracts = async () => {
		try {
			const res = (
				await httpService({
					showLoader: false,
					dispatch,
					path: PathsConfig.getContractsList,
					params: {
						customerId: customerData?.id,
						state: ContractState.ACTIVE,
					},
				})
			).data;
			const activationDates = getActivationDatesFromAllContracts(res);
			setActivationDates(activationDates);
			const uniqueProducts = getUniqueListValues(
				res.flatMap((contract: Contract) => contract?.pricingModel?.tabs?.map((tab) => tab)),
				'name',
			);
			setProducts(uniqueProducts);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CONTRACT_TAB }));
		}
	};

	return (
		<div className={styles.container}>
			<CashInflow currency={currency} tabs={products} customerId={customerData?.id} className={styles.boxBorder} />

			<PermissionsMapping
				actionType={AccountPermissionsActionsList.VIEW_REV_REC}
				disabledFeatureChildren={
					<RevenueRecognitionComponent
						customerId={customerData?.id}
						featureDisabled
						customStyle={{ height: '220px' }}
						classNames={{ revRecContainer: styles.boxBorder }}
					/>
				}
			>
				<RevenueRecognitionComponent
					filterByRevenueRecognitionMethod
					customerId={customerData?.id}
					customStyle={{ height: '220px' }}
					classNames={{ revRecContainer: styles.boxBorder }}
				/>
			</PermissionsMapping>

			<PermissionsMapping
				actionType={AccountPermissionsActionsList.VIEW_REV_REC}
				disabledFeatureChildren={
					<Schedule
						customerId={customerData?.id}
						featureDisabled
						currency={currency}
						activationDates={{ startActivationDate: activationDates?.startActivationDate, endActivationDate: activationDates?.endActivationDate }}
						className={styles.boxBorder}
					/>
				}
			>
				<Schedule
					filterByRevenueRecognitionMethod
					customerId={customerData?.id}
					currency={currency}
					activationDates={{ startActivationDate: activationDates?.startActivationDate, endActivationDate: activationDates?.endActivationDate }}
					className={styles.boxBorder}
				/>
			</PermissionsMapping>
		</div>
	);
};
