import { UserPermissions } from '@received/pricing-model';
import { EnterpriseContract, ResellerAgreement, ReferralAgreement } from '../../pages/Contracts';
import { NodeEnvTypes, RoutesType } from '../../types/generalTypes';
import { Subscriptions } from '../../pages/_ContractsViews/Subscriptions/Subscriptions';
import { Agreements } from '../../pages/_ContractsViews/Agreements/Agreements';
import { EnterprisesContracts } from '../../pages/_ContractsViews/EnterprisesContracts/EnterprisesContracts';

export const contracts: RoutesType[] = [
	{
		name: 'contracts',
		path: '',
		showOnSidebar: true,
		iconType: 'license_template',
		subElements: [
			{
				name: 'enterprises',
				dataTestId: 'enterprises-contracts',
				path: '/enterprises-contracts',
				showOnSidebar: true,
				element: <EnterprisesContracts />,
				licenses: [UserPermissions.BILLING],
			},
			{
				name: 'subscriptions',
				path: '/subscriptions-contracts',
				showOnSidebar: window._env_.REACT_APP_NODE_ENV != NodeEnvTypes.PRODUCTION,
				element: <Subscriptions />,
				licenses: [UserPermissions.BILLING],
			},
			{
				name: 'partnerAgreements',
				path: '/agreements',
				showOnSidebar: true,
				element: <Agreements />,
				licenses: [UserPermissions.PARTNERS],
			},
		],
	},
	{
		name: 'contract',
		path: '/contract',
		showOnSidebar: false,
		element: <EnterpriseContract />,
	},
	{
		name: 'referralAgreement',
		path: '/referral-agreement',
		showOnSidebar: false,
		element: <ReferralAgreement />,
	},
	{
		name: 'resellerAgreement',
		path: '/reseller-agreement',
		showOnSidebar: false,
		element: <ResellerAgreement />,
	},
];
