import clsx from 'clsx';
import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, EmptyStateCard, MenuOption } from '..';
import { TableFooter } from './TableFooter/TableFooter';
import { TableHeader, TableHeaderHeaderProps } from './TableHeader/TableHeader';
import { TableRow, TableRowHeaderProps } from './TableRow/TableRow';
import { SelectItem, Sort } from '../../../types/generalTypes';
import styles from './Table.module.scss';

// One of accessor or CustomComponent has to be supplied, Customer component takes precedence
export type TableHeaderProps = TableRowHeaderProps & TableHeaderHeaderProps;

export type TableEmptyStateProps = {
	title?: string;
	description?: string;
	buttonText?: string;
	className?: string;
	onClick?: () => void;
};

export interface TableProps {
	title?: string;
	titleClassName?: string;
	subTitle?: string;
	header: TableHeaderProps[];
	rows: any[];
	className?: string;
	cellClassName?: string;
	numberOfPages?: number;
	totalNumberOfItems?: number;
	currentPageNumber?: number;
	filters?: React.ReactNode;
	numberOfItems?: string;
	hideTableFooter?: boolean;
	tableRowClassName?: string;
	showCheckBox?: boolean;
	style?: CSSProperties;
	totalData?: any;
	showSwitch?: boolean;
	disableSwitch?: boolean;
	defaultSwitchChecked?: boolean;
	emptyStateProps?: TableEmptyStateProps;
	emptyState?: React.ReactNode;
	pageItemsOptions?: SelectItem[];
	sort?: Sort;
	optionsList?: (row: any) => MenuOption[];
	onUpdateNumberOfItems?: (num: string) => void;
	onUpdatePageNumber?: (num: number) => void;
	onRowPress?: (row: any, index: number) => void;
	onRowChecked?: (isChecked: boolean, row: any) => void;
	onCheckAll?: (isChecked: boolean) => void;
	onToggleSwitch?: (isChecked: boolean) => void;
	onFlipOrder?: (accessor: string) => void;
}

export const Table = ({
	title,
	subTitle,
	header,
	rows,
	filters,
	className,
	numberOfPages,
	totalNumberOfItems,
	currentPageNumber,
	numberOfItems,
	hideTableFooter,
	tableRowClassName,
	showCheckBox,
	style,
	totalData,
	showSwitch,
	disableSwitch,
	defaultSwitchChecked,
	emptyState,
	emptyStateProps,
	cellClassName,
	titleClassName,
	pageItemsOptions,
	sort,
	onFlipOrder,
	onCheckAll,
	onRowChecked,
	optionsList,
	onUpdateNumberOfItems,
	onUpdatePageNumber,
	onRowPress,
	onToggleSwitch,
}: TableProps) => {
	const { t } = useTranslation('translation');

	const [isAllChecked, setIsAllChecked] = useState(false);

	const footerHeight = hideTableFooter ? 0 : 7.5;
	const titleHeight = title ? 7 : 0;
	const subTitleHeight = subTitle ? 1.8 : 0;
	const totalDataHeight = totalData ? 5.8 : 0;
	const filtersHeight = filters ? 2.2 : 0;

	useEffect(() => {
		setIsAllChecked(false);
	}, [rows]);

	const onRowCheck = (isChecked: boolean, row: any) => {
		onRowChecked && onRowChecked(isChecked, row);
	};

	return (
		<div className={clsx(styles.mainContainer, className)} style={style}>
			{title && (
				<div className={styles.tableHeader}>
					<div>
						<span className={clsx(styles.tableTitle, titleClassName)}>{title}</span>
						<span className={styles.tableSubTitle}>{subTitle}</span>
					</div>
					<div className={styles.tableFilters}>{filters}</div>
				</div>
			)}

			{rows?.length ? (
				<div style={{ height: `calc(100% - ${footerHeight + titleHeight + subTitleHeight + totalDataHeight + filtersHeight}rem)` }}>
					<TableHeader
						sort={sort}
						isChecked={isAllChecked}
						showCheckBox={showCheckBox}
						header={header}
						className={tableRowClassName}
						cellClassName={cellClassName}
						onFlipOrder={onFlipOrder}
						checkAll={(check) => {
							setIsAllChecked(check);
							onCheckAll && onCheckAll(check);
						}}
					/>
					<div className={clsx(styles.rowsContainer)} style={{ cursor: onRowPress ? 'pointer' : 'default' }}>
						{rows.map((row, index) => (
							<TableRow
								rowIndex={index}
								key={JSON.stringify(row)}
								showCheckBox={showCheckBox}
								rowData={row}
								header={header}
								onRowPress={() => onRowPress && onRowPress(row, index)}
								className={tableRowClassName}
								cellClassName={cellClassName}
								activeRow={!!onRowPress}
								optionsList={optionsList}
								isAllChecked={isAllChecked}
								checkRow={(isChecked) => onRowCheck(isChecked, row)}
							/>
						))}
					</div>
				</div>
			) : (
				<div
					style={{ height: `calc(100% - ${footerHeight + titleHeight + subTitleHeight + totalDataHeight + filtersHeight}rem)` }}
					className={styles.flexCenter}
				>
					{emptyState || (
						<EmptyStateCard
							showIcon
							containerStyle={clsx(styles.invoiceTableEmpty, !rows?.length && styles.fadeIn)}
							title={emptyStateProps?.title || t('noDataSource')}
							subTitle={emptyStateProps?.description || t('emptyStateSubtitle')}
						>
							{emptyStateProps?.onClick ? (
								<Button className={styles.button} onClick={emptyStateProps.onClick}>
									<span>{emptyStateProps?.buttonText}</span>
								</Button>
							) : null}
						</EmptyStateCard>
					)}
				</div>
			)}

			{!!totalData && <TableRow totalData={totalData} rowData={totalData} header={header} containerStyle={styles.totalRow} />}

			{!hideTableFooter && (
				<TableFooter
					pageItemsOptions={pageItemsOptions}
					showSwitch={showSwitch}
					defaultSwitchChecked={defaultSwitchChecked}
					disableSwitch={disableSwitch}
					numberOfPages={numberOfPages}
					totalNumberOfItems={totalNumberOfItems}
					currentPageNumber={currentPageNumber}
					rowsNumber={rows.length}
					numberOfItems={numberOfItems}
					onUpdateNumberOfItems={onUpdateNumberOfItems}
					onUpdatePageNumber={onUpdatePageNumber}
					onToggleSwitch={onToggleSwitch}
				/>
			)}
		</div>
	);
};
