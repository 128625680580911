import { createSlice } from '@reduxjs/toolkit';
import { UserState } from '../types/storeTypes';
import { UserStateUpdate } from '../types/userTypes';

const initialState: Partial<UserState> = {
	isAuthenticated: false,
	role: undefined,
	name: '',
	surName: '',
	email: '',
	permissions: [],
	phone: {
		countryCode: '',
		phone: '',
	},
	birthday: undefined,
	workAnniversary: undefined,
	icon: undefined,
};

export const userReducer = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setIsAuthenticated: (state: any, payload) => {
			return { ...state, isAuthenticated: payload.payload };
		},
		setUser: (state: any, payload: { payload: UserStateUpdate }) => {
			return { ...state, ...payload.payload };
		},
	},
});

export const { setIsAuthenticated, setUser } = userReducer.actions;

export default userReducer.reducer;
