import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IntegrationCategory, IntegrationDataSyncStatus, IntegrationPlatform, IntegrationProvider, SyncObjectType } from '@received/pricing-model';
import { httpService, PathsConfig } from '../../../../services';
import { setOpenSuccessErrorModal } from '../../../../storeSlices/errorSuccessSlice';
import { exportItemsStepper } from '../../integrationSteppers';
import { IntegrationExportData, IntegrationMatchedObjects } from '../../../../types/integrationTypes';
import { AccountingItemsExport } from '../../IntegrationLayout.utils';
import { setAddResyncAccountingIntegration, setRefreshAfterResync } from '../../../../storeSlices/IntegrationsSlice';
import { Store } from '../../../../types/storeTypes';
import { resyncIntegrationData } from './IntegrationItemsExport.utils';
import styles from './IntegrationItemsExport.module.scss';

interface IntegrationItemsExportProps {
	activeIndex: number;
	supplierId: string;
	syncingSteps: { [key: string | number]: IntegrationDataSyncStatus };
	stepsData: { [index: number]: any };
	integrationType: IntegrationPlatform;
	provider: IntegrationProvider;
	setStepsData: React.Dispatch<React.SetStateAction<{ [index: number]: any }>>;
	setAccountingDeletedItems: React.Dispatch<React.SetStateAction<string[]>>;
	setErrors: React.Dispatch<
		React.SetStateAction<{
			[index: number]: string[];
		}>
	>;
}

const defaultItems = { localObjects: [], matchedObjects: [], remoteObjects: [] };

export const IntegrationItemsExport = ({
	activeIndex,
	supplierId,
	integrationType,
	provider,
	syncingSteps,
	setAccountingDeletedItems,
	setStepsData,
	setErrors,
}: IntegrationItemsExportProps) => {
	const { t } = useTranslation('translation');
	const [matchingCustomers, setMatchingCustomers] = useState<IntegrationExportData>(defaultItems);
	const [exportItems, setExportItems] = useState<IntegrationExportData>(defaultItems);
	const [matchingAccounts, setMatchingAccounts] = useState<IntegrationExportData>(defaultItems);

	const { resyncAccountingIntegration, refreshAfterResync } = useSelector((store: Store) => store.integrations);
	const dispatch = useDispatch();

	useEffect(() => {
		getItemsData();
		getMatchingAccountsData();
		getMatchingCustomersData();
	}, [supplierId, integrationType, refreshAfterResync]);

	useEffect(() => {
		const exportItemsEmptyCustomers = matchingCustomers.matchedObjects.find((row) => !row?.domainObject || !row?.remoteObject);
		const exportItemsEmptyItems = exportItems.matchedObjects.find((row) => !row?.domainObject || !row?.remoteObject);
		const matchingAccountsEmptyItems = matchingAccounts.matchedObjects.find((row) => !row?.domainObject || !row?.remoteObject);

		setErrors((prev) => ({
			...prev,
			[AccountingItemsExport.Customers]: exportItemsEmptyCustomers ? [t('emptyFieldsOnCustomers')] : [],
			[AccountingItemsExport.Items]: exportItemsEmptyItems ? [t('emptyFieldsOnItems')] : [],
			[AccountingItemsExport.Accounts]: matchingAccountsEmptyItems ? [t('emptyFieldsOnAccounts')] : [],
		}));
	}, [exportItems, matchingAccounts, matchingCustomers]);

	const getItemsData = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getIntegrationMatchingItems,
				showLoader: !exportItems.remoteObjects.length,
				urlParam: { provider, category: IntegrationCategory.ACCOUNTING },
				params: {
					supplierId,
					platform: integrationType,
					dataType: SyncObjectType.ITEM,
				},
			});

			setExportItems(filterLists(res.data, !refreshAfterResync, exportItems.matchedObjects));
			setStepsData((prev) => ({ ...prev, [AccountingItemsExport.Items]: refreshAfterResync ? exportItems.matchedObjects : res.data.matchedObjects }));
			dispatch(setAddResyncAccountingIntegration({ ...resyncAccountingIntegration, [AccountingItemsExport.Items]: res.data?.dataSyncStatus }));
			dispatch(setRefreshAfterResync(false));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getMatchingAccountsData = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getIntegrationMatchingItems,
				showLoader: !matchingAccounts.remoteObjects.length,
				urlParam: { provider, category: IntegrationCategory.ACCOUNTING },
				params: {
					supplierId,
					platform: integrationType,
					dataType: SyncObjectType.BILLING_ACCOUNT,
				},
			});

			setMatchingAccounts(filterLists(res.data, !refreshAfterResync, matchingAccounts.matchedObjects));
			setStepsData((prev) => ({
				...prev,
				[AccountingItemsExport.Accounts]: refreshAfterResync ? matchingAccounts.matchedObjects : res.data.matchedObjects,
			}));
			dispatch(setAddResyncAccountingIntegration({ ...resyncAccountingIntegration, [AccountingItemsExport.Accounts]: res.data?.dataSyncStatus }));
			dispatch(setRefreshAfterResync(false));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const getMatchingCustomersData = async () => {
		try {
			const res = await httpService({
				dispatch,
				path: PathsConfig.getIntegrationMatchingItems,
				showLoader: !matchingCustomers.remoteObjects.length,
				urlParam: { provider, category: IntegrationCategory.ACCOUNTING },
				params: {
					supplierId,
					platform: integrationType,
					dataType: SyncObjectType.CUSTOMER,
				},
			});

			setMatchingCustomers(filterLists(res.data, !refreshAfterResync, matchingCustomers.matchedObjects));
			setStepsData((prev) => ({
				...prev,
				[AccountingItemsExport.Customers]: refreshAfterResync ? matchingCustomers.matchedObjects : res.data.matchedObjects,
			}));
			dispatch(setAddResyncAccountingIntegration({ ...resyncAccountingIntegration, [AccountingItemsExport.Customers]: res.data?.dataSyncStatus }));
			dispatch(setRefreshAfterResync(false));
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error }));
		}
	};

	const filterLists = (integrationExportData: IntegrationExportData, initialLoad?: boolean, oldMatchedData?: IntegrationMatchedObjects[]) => {
		return {
			localObjects: [...integrationExportData.localObjects].sort((a, b) => a.name.localeCompare(b.name)).sort((a) => (a.isUsed ? -1 : 1)),
			matchedObjects: initialLoad ? [...integrationExportData.matchedObjects] : oldMatchedData || [],
			remoteObjects: [...integrationExportData.remoteObjects].sort((a, b) => a.name.localeCompare(b.name)),
		};
	};

	const onUpdateExportCustomers = (newList: IntegrationMatchedObjects[], syncStatusId: string) => {
		setAccountingDeletedItems((prev) => [...prev, syncStatusId || ''].filter((val) => val));
		setMatchingCustomers((prev) => ({ ...prev, matchedObjects: newList }));
		setStepsData((prev) => ({ ...prev, [activeIndex]: newList }));
	};

	const onUpdateExportItems = (newList: IntegrationMatchedObjects[], syncStatusId: string) => {
		setAccountingDeletedItems((prev) => [...prev, syncStatusId || ''].filter((val) => val));
		setExportItems((prev) => ({ ...prev, matchedObjects: newList }));
		setStepsData((prev) => ({ ...prev, [activeIndex]: newList }));
	};

	const onUpdateMatchingAccounts = (newList: IntegrationMatchedObjects[], syncStatusId: string) => {
		setAccountingDeletedItems((prev) => [...prev, syncStatusId || ''].filter((val) => val));
		setMatchingAccounts((prev) => ({ ...prev, matchedObjects: newList }));
		setStepsData((prev) => ({ ...prev, [activeIndex]: newList }));
	};

	const onDeleteRow = (index: number, step: AccountingItemsExport) => {
		switch (step) {
			case AccountingItemsExport.Customers: {
				setAccountingDeletedItems((prev) => [...prev, matchingCustomers.matchedObjects[index]?.syncStatusId || ''].filter((val) => val));
				setStepsData((prev) => {
					const newMatchedObjects = [...prev[AccountingItemsExport.Customers]];
					newMatchedObjects.splice(index, 1);
					return { ...prev, [AccountingItemsExport.Customers]: newMatchedObjects };
				});

				setMatchingCustomers((prev) => {
					const newMatchedObjects = [...prev.matchedObjects];
					newMatchedObjects.splice(index, 1);
					return { ...prev, matchedObjects: newMatchedObjects };
				});
				break;
			}
			case AccountingItemsExport.Items: {
				setAccountingDeletedItems((prev) => [...prev, exportItems.matchedObjects[index]?.syncStatusId || ''].filter((val) => val));
				setStepsData((prev) => {
					const newMatchedObjects = [...prev[AccountingItemsExport.Items]];
					newMatchedObjects.splice(index, 1);
					return { ...prev, [AccountingItemsExport.Items]: newMatchedObjects };
				});

				setExportItems((prev) => {
					const newMatchedObjects = [...prev.matchedObjects];
					newMatchedObjects.splice(index, 1);
					return { ...prev, matchedObjects: newMatchedObjects };
				});
				break;
			}
			case AccountingItemsExport.Accounts: {
				setAccountingDeletedItems((prev) => [...prev, matchingAccounts.matchedObjects[index]?.syncStatusId || ''].filter((val) => val));
				setStepsData((prev) => {
					const newMatchedObjects = [...prev[AccountingItemsExport.Accounts]];
					newMatchedObjects.splice(index, 1);
					return { ...prev, [AccountingItemsExport.Accounts]: newMatchedObjects };
				});
				setMatchingAccounts((prev) => {
					const newMatchedObjects = [...prev.matchedObjects];
					newMatchedObjects.splice(index, 1);
					return { ...prev, matchedObjects: newMatchedObjects };
				});
				break;
			}
		}
	};

	return (
		<div className={styles.container}>
			{exportItemsStepper({
				integrationType,
				t,
				syncingSteps,
				exportItems,
				matchingAccounts,
				matchingCustomers,
				onUpdateExportItems,
				onResync: (accountingItemsExportStep, syncObjectType) =>
					resyncIntegrationData(
						dispatch,
						[syncObjectType],
						provider,
						supplierId,
						integrationType,
						resyncAccountingIntegration,
						accountingItemsExportStep,
					),
				onUpdateMatchingAccounts,
				onDeleteRow,
				onUpdateExportCustomers,
			})[activeIndex]?.component()}
		</div>
	);
};
