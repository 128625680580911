import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../types/storeTypes';
import { PricingModelTab } from '../../types/contractTypes';
import { Select, TextArea } from '../_uiComponents';
import { UserRole } from '../../types/userTypes';
import { getSuppliers } from '../../utils/CustomerUtils';
import { UpdateCreditNoteSettings } from '../TermsAndSettings/TermsAndSettings';
import { CreateSupplier } from '..';
import { Customer } from '../../types/customerTypes';
import styles from './ReferralFeeSettings.module.scss';

interface ReferralFeeSettingsProps {
	pricingModelTab: PricingModelTab;
	className?: string;
	updateCreditSettings: ({ note, supplierId, billingDetails }: UpdateCreditNoteSettings) => void;
}

export const ReferralFeeSettings = ({ pricingModelTab, className, updateCreditSettings }: ReferralFeeSettingsProps) => {
	const [note, setNote] = useState('');
	const [suppliers, setSuppliers] = useState<Customer[]>([]);
	const [isNewSupplierModalOpened, setIsNewSupplierModalOpened] = useState(false);

	const { t } = useTranslation('translation');
	const userRole = useSelector((store: Store) => store.user.role);
	const dispatch = useDispatch();

	useEffect(() => {
		setSuppliersData();
	}, []);

	useEffect(() => {
		setNote(pricingModelTab?.note || '');
	}, [pricingModelTab]);

	const setSuppliersData = async () => {
		const suppliers = await getSuppliers(dispatch);
		setSuppliers(suppliers);
	};

	const addSupplier = async (newSupplier: Customer) => {
		await setSuppliersData();
		updateCreditSettings({
			...pricingModelTab,
			supplierId: newSupplier?.id,
		});
	};

	const onChangeSupplier = (supplierId: string) => {
		if (supplierId != pricingModelTab?.supplierId) {
			const billingAccountId =
				suppliers.find((supplier) => supplier?.id === supplierId)?.billingDetails?.billingAccounts?.find((bank) => bank.isDefault)?.id || null;

			pricingModelTab &&
				updateCreditSettings({
					...pricingModelTab,
					billingDetails: {
						...pricingModelTab.billingDetails,
						billingAccountId: billingAccountId || null,
					},
					supplierId,
				});
		}
	};

	const onUpdateNote = (note: string) => {
		setNote(note);
		updateCreditSettings({
			...pricingModelTab,
			note,
		});
	};

	return (
		<div className={clsx(styles.container, className)}>
			<div className={styles.title}>{t('settings')}</div>
			<div className={styles.content}>
				<div className={styles.supplierContainer}>
					<Select
						placeholder={t('receivingEntityPlaceholder')}
						className={styles.supplierSelect}
						containerStyle={styles.supplierContainerSelect}
						title={t('referralFeeEntity')}
						onChange={onChangeSupplier}
						defaultValue={pricingModelTab?.supplierId}
						data={suppliers.map((item: Customer) => ({ label: item.name, value: item.id }))}
						headerStyle={styles.selectHeaderStyle}
						width={''}
						addButton={{ title: t('addNewEntity'), onPress: () => setIsNewSupplierModalOpened(true), isDisabled: userRole !== UserRole.Admin }}
					/>
				</div>

				<TextArea
					type='textarea'
					value={note}
					onChange={onUpdateNote}
					title={`${t('note')} (${t('optional')})`}
					className={styles.multiline}
					titleStyle={styles.noteTitle}
					placeholder={t('noteComment')}
				/>

				<CreateSupplier
					companiesListLength={suppliers.length}
					isOpen={isNewSupplierModalOpened}
					closeModal={() => setIsNewSupplierModalOpened(false)}
					onCreateUpdateSupplier={addSupplier}
				/>
			</div>
		</div>
	);
};
