import { useSelector } from 'react-redux';
import { AccountPermissions } from '../../types/generalTypes';
import { Store } from '../../types/storeTypes';
import { permissionCheck } from './PermissionsMapping.utils';

interface PermissionsMappingProps {
	children: React.ReactNode;
	disabledFeatureChildren?: React.ReactNode;
	actionType?: AccountPermissions;
}

export function PermissionsMapping({ children, actionType, disabledFeatureChildren }: PermissionsMappingProps): JSX.Element {
	const userPermissions = useSelector((store: Store) => store.user.permissions);

	return <>{permissionCheck(actionType, userPermissions) ? children : disabledFeatureChildren || null}</>;
}
