import clsx from 'clsx';
import { colors } from '../../../constants/colors';
import styles from './ClickableCard.module.scss';

export interface ClickableCardProps {
	title?: string;
	description?: string;
	color?: string;
	isSelected?: boolean;
	isEditable?: boolean;
	className?: string;
	onClick?(): void;
}

export const ClickableCard = ({ title, description, color, isSelected, isEditable, className, onClick }: ClickableCardProps) => {
	return (
		<div
			className={clsx(styles.productInfo, !isSelected && styles.notSelectedProduct, isEditable && styles.editable, className)}
			style={{ borderLeft: `3px solid ${isSelected ? color : colors.neutral300}` }}
			onClick={onClick}
		>
			<span className={styles.productName}>{title}</span>
			<span className={styles.productDescription}>{description} </span>
		</div>
	);
};
