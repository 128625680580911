import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AccountingEventType, DocumentState } from '@received/pricing-model';
import { successErrorMassageOptions } from '../../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { httpService, PathsConfig } from '../../../services';
import { setOpenSuccessErrorModal } from '../../../storeSlices/errorSuccessSlice';
import { AllCreditNoteSummary, CreditNoteView, CreditNoteViewsResponse } from '../../../types/creditNoteTypes';
import { creditNotesTabTableHeader, defaultNumberOfItems, getCreditNoteSummaryBlocksData } from './CreditsNotesTab.utils';
import { SidebarType } from '../../../components/Sidebars/Sidebars.utils';
import { Sort, SortOrder } from '../../../types/generalTypes';
import { creditNoteMenuList, getCreditNoteSummaryData, getSortingOrderList } from '../../../utils/CreditNoteUtils';
import { CustomerTabProps } from '../CustomerTabs.utils';
import { SummaryBar, Table } from '../..';
import styles from './CreditsNotesTab.module.scss';

export const CreditsNotesTab = ({ isActive, customerData, timePeriod, refreshTabs, setOpenSideBarById }: CustomerTabProps) => {
	const { t } = useTranslation('translation');
	const [creditNotesList, setCreditNotesList] = useState<CreditNoteView[]>([]);
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const [numberOfItems, setNumberOfItems] = useState(defaultNumberOfItems);
	const [sort, setSort] = useState<Sort>({ orderBy: ['issueDate', 'draftNumber'], order: SortOrder.ASC });
	const [summary, setSummary] = useState<AllCreditNoteSummary>();

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		isActive && getData();
	}, [isActive, customerData, timePeriod, numberOfItems, pageNumber, sort, refreshTabs]);

	const getData = async () => {
		await getCreditNotesViews();
		const summary = await getCreditNoteSummaryData(dispatch, timePeriod.from, timePeriod.to, [customerData?.id || '']);
		setSummary(summary);
	};

	const getCreditNotesViews = async () => {
		try {
			const res: CreditNoteViewsResponse = (
				await httpService({
					dispatch,
					path: PathsConfig.getCreditNotesViews,
					params: {
						customerId: customerData?.id,
						pageSize: numberOfItems,
						pageNumber,
						orderBy: sort.orderBy,
						order: sort.order,
						fromIssueDate: timePeriod.from,
						toIssueDate: timePeriod.to,
						excludedStates: [DocumentState.CONTRACT_DRAFT],
					},
				})
			).data;
			setCreditNotesList(res.creditNotes);
			setTotalCount(res.totalCount);
		} catch (error) {
			dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_GET_CREDIT_NOTES }));
		}
	};

	return (
		<div className={styles.container}>
			<SummaryBar summary={getCreditNoteSummaryBlocksData(t, summary)} blockClassName={styles.summaryBlock} />
			<Table
				header={creditNotesTabTableHeader(getData)}
				sort={sort}
				rows={creditNotesList}
				className={styles.tableContainer}
				tableRowClassName={styles.tableRow}
				optionsList={(row) =>
					creditNoteMenuList({
						item: { ...row, accountingEventType: AccountingEventType.CREDIT },
						dispatch,
						navigate,
						t,
						onRefresh: getData,
					})
				}
				numberOfPages={Math.ceil(totalCount / +numberOfItems)}
				totalNumberOfItems={totalCount}
				numberOfItems={numberOfItems}
				onUpdateNumberOfItems={setNumberOfItems}
				onUpdatePageNumber={setPageNumber}
				onRowPress={(data) => setOpenSideBarById?.({ sideBarType: SidebarType.CREDIT_NOTE, id: data.id })}
				emptyStateProps={{
					title: t('noOpenCredits'),
					description: t('noOpenCreditNotesDescription'),
					buttonText: `${t('newCredit')} +`,
					onClick: () => navigate('../single-credit-note', { state: { customer: customerData } }),
				}}
				onFlipOrder={(accessor) =>
					setSort({ orderBy: getSortingOrderList(accessor), order: sort.order === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC })
				}
			/>
		</div>
	);
};
