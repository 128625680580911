import dayjs from 'dayjs';
import { Dispatch } from '@reduxjs/toolkit';
import clsx from 'clsx';
import { convertCurrencyToSign, Currency } from '@received/pricing-model';
import {
	filtersSelectLabels,
	FilterTimeOptions,
	generalTimePeriodInitialState,
	getTimePeriodByFilterTimeOptions,
} from '../../components/Filters/DateRangeFilters.utils';
import { initialStackBarGraphNumberFormat, initialStackBarGraphState } from '../../components/_uiComponents/Graphs/StackBarGraph/StackBarGraph.utils';
import { Cell, LogoCell } from '../../components/_uiComponents/Table/Table.utils';
import { DATE_FORMAT_FOR_SERVER } from '../../constants/templateConstants';
import { ARAgingRecord, CustomerLocation, DatesData, GraphState } from '../../types/generalTypes';
import { priceNumberDisplay, priceNumberWithCurrencySignDisplay } from '../../utils/NumberUtils';
import { httpService, PathsConfig } from '../../services';
import { setOpenSuccessErrorModal } from '../../storeSlices/errorSuccessSlice';
import { successErrorMassageOptions } from '../../components/SuccessErrorModal/SuccessErrorModal.utils';
import { CustomerView } from '../../types/customerTypes';
import { cashTimeUnitHandler } from '../../utils/DateUtils';
import { images, ImagesOptionsType } from '../../constants/images';
import { ARAgingCSVTitles } from './ArAgingPage/ArAging.utils';
import styles from './Dashboard.module.scss';

export const initialGraphState: GraphState = {
	isEmpty: false,
	data: initialStackBarGraphState,
	dataFormat: initialStackBarGraphNumberFormat,
};

export const accountsReceivableAgingHeader = (currency?: string) => [
	{
		headerTitle: 'customerName',
		totalTitle: 'Total',
		accessor: 'customerName',
		CSVIndex: ARAgingCSVTitles.CUSTOMER,
		CustomComponent: (item: ARAgingRecord) => <LogoCell title={item?.customerName || ''} logoData={item?.customerIcon} />,
	},
	{
		headerTitle: 'current',
		accessor: 'current',
		CSVIndex: ARAgingCSVTitles.CURRENT,
		CustomComponent: (item: ARAgingRecord) => (
			<Cell
				className={clsx(item?.current || styles.zero)}
				accessor={item?.current ? `${priceNumberWithCurrencySignDisplay(item?.current, currency)}` : `${currency}0`}
			/>
		),
	},
	{
		headerTitle: '1-30d',
		accessor: '1-30 days',
		CSVIndex: ARAgingCSVTitles.DAYS_1_30,
		CustomComponent: (item: ARAgingRecord) => (
			<Cell
				className={clsx(item?.['1-30 days'] || styles.zero)}
				accessor={item['1-30 days'] ? `${priceNumberWithCurrencySignDisplay(item['1-30 days'], currency)}` : `${currency}0`}
			/>
		),
	},
	{
		headerTitle: '31-60d',
		accessor: '31-60 days',
		CSVIndex: ARAgingCSVTitles.DAYS_31_60,
		CustomComponent: (item: ARAgingRecord) => (
			<Cell
				className={clsx(item?.['31-60 days'] || styles.zero)}
				accessor={item['31-60 days'] ? `${priceNumberWithCurrencySignDisplay(item['31-60 days'], currency)}` : `${currency}0`}
			/>
		),
	},
	{
		headerTitle: '61-90d',
		accessor: '61-90 days',
		CSVIndex: ARAgingCSVTitles.DAYS_61_90,
		CustomComponent: (item: ARAgingRecord) => (
			<Cell
				className={clsx(item?.['61-90 days'] || styles.zero)}
				accessor={item['61-90 days'] ? `${priceNumberWithCurrencySignDisplay(item['61-90 days'], currency)}` : `${currency}0`}
			/>
		),
	},
	{
		headerTitle: 'over90d',
		accessor: 'over 90 days',
		CSVIndex: ARAgingCSVTitles.DAYS_OVER_90,
		CustomComponent: (item: ARAgingRecord) => (
			<Cell
				className={clsx(item?.['over 90 days'] || styles.zero)}
				accessor={item['over 90 days'] ? `${priceNumberWithCurrencySignDisplay(item['over 90 days'], currency)}` : `${currency}0`}
			/>
		),
	},
	{
		headerTitle: 'Total',
		accessor: 'total',
		CSVIndex: ARAgingCSVTitles.TOTAL,
		CustomComponent: (item: ARAgingRecord) => (
			<Cell
				className={clsx(item?.total || styles.zero)}
				accessor={item.total ? `${priceNumberWithCurrencySignDisplay(item.total, currency)}` : `${currency}0`}
			/>
		),
	},
];

export const forecastFiltersSelectOptions = [
	{
		label: `${filtersSelectLabels.CURRENT_YEAR} (${dayjs().year()})`,
		value: FilterTimeOptions.CURRENT_YEAR,
	},
];

export const forecastTimePeriodInitialState = getTimePeriodByFilterTimeOptions(FilterTimeOptions.CURRENT_YEAR) || generalTimePeriodInitialState;

export const collectionStatusTimePeriodInitialState =
	getTimePeriodByFilterTimeOptions(FilterTimeOptions.LAST_12_MONTHS) || generalTimePeriodInitialState;

export const upForRenewalPeriodItems = [
	{
		label: 'Next 30 days',
		value: dayjs().add(30, 'day').format(DATE_FORMAT_FOR_SERVER),
	},
	{
		label: 'Next 60 days',
		value: dayjs().add(60, 'day').format(DATE_FORMAT_FOR_SERVER),
	},
	{
		label: 'Next 90 days',
		value: dayjs().add(90, 'day').format(DATE_FORMAT_FOR_SERVER),
	},
];

export const defaultUpForRenewalPeriod = upForRenewalPeriodItems[2];

export const getTopLateCustomers = async (dispatch: Dispatch, timePeriod: DatesData, t: any, appCurrency: Currency) => {
	try {
		const data = (
			await httpService({
				dispatch,
				path: PathsConfig.getCustomersViewData,
				params: {
					pageSize: 50,
					pageNumber: 0,
					orderBy: 'delayedPaymentsAmount',
					order: 'DESC',
					fromDate: timePeriod?.from,
					toDate: timePeriod?.to,
				},
			})
		).data;
		const topLate: CustomerView[] = data.customerViewList.filter((item: CustomerView) => item.delayedPaymentsAmount != 0);
		return {
			total: topLate.length,
			data: topLate.map((item) => ({
				count: item.delayedPaymentsAmount,
				name: item.name,
				id: item.id,
				iconData: item.iconData,
				subText: `${t('customerSince')} ${dayjs(item.createdAt).format('MMM YYYY')}`,
				amount: `${
					item.delayedPaymentsAmount < 0
						? `(${priceNumberDisplay(Math.abs(item.delayedPaymentsAmount), 0, convertCurrencyToSign(appCurrency))})`
						: `${priceNumberDisplay(item.delayedPaymentsAmount, 0, convertCurrencyToSign(appCurrency))}`
				}`,
			})),
		};
	} catch (error: any) {
		dispatch(setOpenSuccessErrorModal({ responseError: error, successErrorMassage: successErrorMassageOptions.ERROR.FAILED_LOAD_CUSTOMERS }));
	}
};

export const getCashflowData = async (timePeriod: DatesData, dispatch: Dispatch) => {
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getCollectionStatus,
				params: { fromDate: timePeriod.from, toDate: timePeriod.to, timeUnit: cashTimeUnitHandler(timePeriod.from, timePeriod.to) },
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const getForecastData = async (forecastPeriod: DatesData, dispatch: Dispatch) => {
	try {
		return (
			await httpService({
				dispatch,
				path: PathsConfig.getInvoiceForecast,
				params: {
					fromDate: forecastPeriod.from,
					toDate: forecastPeriod.to,
					timeUnit: cashTimeUnitHandler(forecastPeriod.from, forecastPeriod.to),
				},
			})
		).data;
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};

export const getCustomerLocation = async (dispatch: Dispatch) => {
	try {
		const res: CustomerLocation[] = (
			await httpService({
				dispatch,
				path: PathsConfig.getCustomerLocation,
			})
		).data;

		let total = 0;
		const list = res.map((item) => {
			total = total + +item.count;

			return {
				amount: item.count,
				name: item.country,
				id: '',
				iconData: Object.keys(images).includes(item.country) ? images[item.country as ImagesOptionsType] : '',
				amountSubTitle: `${item.percentage} %`,
				fullIcon: true,
			};
		});

		return { data: list, total };
	} catch (error) {
		dispatch(setOpenSuccessErrorModal({ responseError: error }));
	}
};
