import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { CellProps, handleCellKeyDown } from '../../TableRow.utils';
import styles from './TextCell.module.scss';

export const TextCell = ({
	rowData,
	column,
	selectedCell,
	classNames,
	rowIndex,
	columnIndex,
	onArrowNavigation,
	onCellSelected,
	updateTableData,
}: CellProps) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const [value, setValue] = useState<string | number>('');

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		setIsEditMode(selectedCell?.cell.cellId === rowData.cells[column.id].cellId);
	}, [selectedCell, rowData]);

	useEffect(() => {
		setValue(rowData.cells[column.id].cellValue);
	}, [rowData]);

	useEffect(() => {
		isEditMode && inputRef?.current?.focus();
	}, [isEditMode]);

	const updateValue = (val: string | number) => {
		setValue(val);
		const newRowData = { ...rowData };
		newRowData.cells[column.id].cellValue = val;

		updateTableData(newRowData);
	};

	return (
		<input
			data-testid={`${rowIndex}-row-${column.title}-column-cell-input`}
			autoFocus={isEditMode}
			ref={inputRef}
			value={value}
			onChange={(event) => updateValue(event.target.value)}
			className={clsx(styles.cell, classNames?.cell, isEditMode && styles.isEditable, isEditMode && classNames?.isEditable)}
			onKeyDown={(event) => isEditMode && handleCellKeyDown(event, rowIndex, columnIndex, onArrowNavigation, onCellSelected)}
			style={!isEditMode ? { background: 'none', pointerEvents: 'none' } : undefined}
			readOnly={!isEditMode}
		></input>
	);
};
