import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditDocumentNumberState } from '../../../../utils/GeneralUtils';
import { Badge, Button, Icon, Input, TextWithTooltip } from '../../../_uiComponents';
import { Customer } from '../../../../types/customerTypes';
import styles from './SideBarHeader.module.scss';

export interface SideBarHeaderProps {
	sidebarType: string;
	customer?: Customer;
	prefix: string;
	documentNumber: string | undefined;
	isEditable?: boolean;
	syncIcon?: string;
	editInvoiceNumberState?: { state: EditDocumentNumberState; errorMessage?: string };
	hideWarning?: boolean;
	placeholder?: string;
	inputType?: string;
	customerTitle?: string;
	updateValue?: (value: string) => void;
	onStopWithoutEdit?(): void;
}

export const SideBarHeader = ({
	sidebarType,
	customer,
	prefix,
	syncIcon,
	documentNumber,
	isEditable,
	editInvoiceNumberState,
	hideWarning,
	placeholder,
	inputType,
	customerTitle,
	updateValue,
	onStopWithoutEdit,
}: SideBarHeaderProps) => {
	const [editableValue, setEditableValue] = useState('');
	const { t } = useTranslation('translation');

	useEffect(() => {
		setEditableValue(documentNumber || '');
	}, [documentNumber]);

	return (
		<div className={styles.container}>
			<div className={styles.avatarContainer}>
				{customer?.iconData ? (
					<img alt='logo' src={customer?.iconData} className={styles.avatarContainerWithIcon} />
				) : (
					<div className={styles.avatarLetter}>{customer?.name ? customer?.name[0].toUpperCase() : ''}</div>
				)}
				{syncIcon && <img alt='connection-status' src={syncIcon} className={styles.contractConnectionStatus} />}
			</div>

			<div className={styles.body}>
				<Badge color='neutral' type='outlined' className={styles.badge} dataTestId={`${sidebarType}-sidebar-header-badge`}>
					<span data-testid='side-bar-type' className={styles.badgeText}>
						{sidebarType}
					</span>
				</Badge>
				<div className={styles.content}>
					<div className={styles.titleContainer}>
						{isEditable ? (
							<div className={styles.inputContainer}>
								<Input
									data-testid='side-bar-input-prefix'
									autoFocus
									placeholder={placeholder}
									value={editableValue}
									onChange={(val, e) => setEditableValue(e.target.value)}
									onKeyDown={(event) => {
										if (event.key === 'Enter') {
											event.currentTarget.value != documentNumber && updateValue?.(event.currentTarget.value);
										}
									}}
									inputClassName={styles.documentId}
									className={clsx(
										styles.inputContainerClassName,
										editInvoiceNumberState?.state == EditDocumentNumberState.ERROR && styles.errorBorder,
									)}
									type={inputType}
									rightChildren={
										<Button
											dataTestId='side-bar-edit-prefix-button'
											type='link'
											onClick={() => {
												setEditableValue(documentNumber || '');
												onStopWithoutEdit?.();
											}}
										>
											<Icon imgType='x_icon' color='neutral700' height={1} />
										</Button>
									}
								/>

								<Button dataTestId='side-bar-edit-prefix-button' onClick={() => updateValue?.(editableValue)} className={styles.saveButton}>
									<Icon imgType='v_icon' color='white' height={1.8} />
								</Button>
							</div>
						) : (
							<TextWithTooltip
								dataTestId={`${sidebarType}-side-bar-prefix`}
								className={styles.title}
								maxWidth={25}
								tooltip={{ blackTooltip: true }}
							>{`${prefix ? `${prefix}-${documentNumber}` : documentNumber}`}</TextWithTooltip>
						)}
					</div>

					{editInvoiceNumberState?.state == EditDocumentNumberState.ERROR ? (
						<span className={clsx(styles.warningText, styles.errorText)}>{t(editInvoiceNumberState?.errorMessage || 'editDocumentNoWarning')}</span>
					) : !hideWarning && isEditable ? (
						<span className={styles.warningText}>{t('editDocumentNoWarning')}</span>
					) : (
						<span className={styles.customTitle}>{customerTitle}</span>
					)}
				</div>
			</div>
		</div>
	);
};
