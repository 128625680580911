import { UserPermissions } from '@received/pricing-model';

// must match Role enum on the server
export enum UserRole {
	UsageApi = 'usage_api',
	Member = 'Member',
	Admin = 'Admin',
	System = 'System',
}

export enum UserStatus {
	Active = 'active',
	Inactive = 'inactive',
	Invited = 'invited',
}

export interface UserDto {
	id: string;
	cognitoId: string;
	cognitoName: string;
	name: string;
	surName: string;
	email: string;
	role: UserRole;
	invitedBy: string;
	joinedDate: Date;
	status: UserStatus;
	permissions: UserPermissions[];
	jobTitle?: string;
	phone?: {
		countryCode?: string;
		phone?: string;
	};
	birthday?: Date; // YYYY-MM-DD format
	workAnniversary?: Date; // YYYY-MM-DD format
	icon?: string;
}

export interface UserStateUpdate extends Partial<UserDto> {
	isAuthenticated?: boolean;
}
