import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { FooterItem } from './SideBarFooter.utils';
import styles from './SideBarFooter.module.scss';

interface SideBarFooterProps {
	items: FooterItem[];
	className?: string;
}

export const SideBarFooter = ({ items, className }: SideBarFooterProps) => {
	const { t } = useTranslation('translation');

	return (
		<div className={clsx(styles.footer, className)}>
			<span className={styles.title}>{t('summary')}</span>
			<div className={styles.summaryFooter}>
				{items?.map(
					({ value, title, isBlack, isHidden }: FooterItem, index: number) =>
						!isHidden && (
							<div key={index} className={styles.summaryItem}>
								<div className={clsx(styles.summaryValues, isBlack && styles.blackText)}>{value}</div>
								<span className={styles.grayText}>{t(title)}</span>
							</div>
						),
				)}
			</div>
		</div>
	);
};
