import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT } from '@received/pricing-model';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Contract } from '../../../../../types/contractTypes';
import { getEmailsLabel } from '../../../../../utils/GeneralUtils';
import { getImportedFrom } from '../../../../../utils/IntegrationUtils';
import { arrowRight } from '../../../../../constants/unicodes';
import { getUniqueBanksCount, getUniqueSuppliersCount, isBankExist, isSupplierExist } from '../../ContractSideBar.utils';
import { BillingAccountDetails, Customer } from '../../../../../types/customerTypes';
import { statusByContractType } from '../../../../../utils/ContractUtils';
import { StatusCol } from '../../../../_uiComponents/Table/Table.utils';
import styles from './ContractData.module.scss';

interface ContractDataProps {
	contractData?: Contract;
	suppliers?: Customer[];
	banks?: BillingAccountDetails[];
}

export const ContractData = ({ contractData, suppliers, banks }: ContractDataProps) => {
	const { t } = useTranslation('translation');

	const importStatus = getImportedFrom(contractData);
	const contractSupplier = isSupplierExist(contractData);
	const navigate = useNavigate();

	return (
		<div className={styles.container}>
			<div className={styles.flex}>
				<span className={styles.detailsText}>{t('customer')}</span>
				<span
					data-testid='contract-side-bar-customer-name'
					className={clsx(styles.blackText, styles.ellipsis, styles.customerLink)}
					style={{ marginRight: '0.4rem' }}
					onClick={() => contractData?.customer && navigate(`/customers/single-customer/${contractData?.customer.id}`)}
				>
					{contractData?.customer?.name}
				</span>
				<span className={clsx(styles.greyText, styles.ellipsis)}>{` | ${getEmailsLabel(contractData?.customer?.email)}`}</span>
			</div>

			<div>
				<span className={styles.detailsText}>{t('activation')}</span>
				<span data-testid='contract-side-bar-activation-date' className={styles.blackText}>
					{`${dayjs(contractData?.activationStartDate).format(DATE_FORMAT)} ${
						contractData?.activationEndDate ? `${arrowRight} ` + dayjs(contractData?.activationEndDate).format(DATE_FORMAT) : ''
					}`}
				</span>
			</div>

			<div>
				<span className={styles.detailsText}>{t('createAt')}</span>
				<span data-testid='contract-side-bar-create-at' className={styles.blackText}>
					{contractData?.updatedAt && dayjs(contractData?.createdAt).format(DATE_FORMAT)}
					{importStatus && (
						<span>
							{' '}
							{t('importedFrom')}{' '}
							<a href={importStatus.metadata?.link} target='_blank' rel='noreferrer' onClick={() => {}} className={styles.link}>
								{importStatus.platformName}
							</a>
						</span>
					)}
				</span>
			</div>

			{contractSupplier && (
				<div className={clsx(styles.detailsRow, styles.flex)}>
					<span className={styles.detailsText}>{t('debitEntity')}</span>
					<span data-testid='contract-side-bar-supplier' className={clsx(styles.blackText, styles.ellipsis)} style={{ marginRight: '0.4rem' }}>
						{`${suppliers?.find((supplier: Customer) => contractSupplier == supplier.id)?.name} ${
							getUniqueSuppliersCount(contractData) - 1 > 0 ? `+${getUniqueSuppliersCount(contractData) - 1}` : ''
						}`}
					</span>
					{isBankExist(contractData) && (
						<span data-testid='contract-side-bar-bank' className={clsx(styles.blackText, styles.ellipsis)}>{` | ${
							banks?.find((bank) => bank.id == isBankExist(contractData))?.bank
						} ${getUniqueBanksCount(contractData) - 1 > 0 ? `+${getUniqueBanksCount(contractData) - 1}` : ''}`}</span>
					)}
				</div>
			)}

			<div className={styles.detailsRowStatus}>
				<span className={styles.detailsText}>{t('status')}</span>
				{contractData && <StatusCol color={statusByContractType(contractData).color}>{statusByContractType(contractData).text}</StatusCol>}
			</div>
		</div>
	);
};
