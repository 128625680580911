import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Customer } from '../../types/customerTypes';
import styles from './EntityDetails.module.scss';

export interface EntityDetailsProps {
	entity?: Customer;
	className?: string;
	small?: boolean;
	style?: CSSProperties;
}

export const EntityDetails = ({ entity, className, small, style }: EntityDetailsProps) => {
	const { t } = useTranslation('translation');
	const location = [entity?.city, entity?.shippingAddress, entity?.countryState, entity?.country].reduce((a, b) =>
		a && b ? `${a}, ${b}` : a ? a : b ? b : '',
	);
	return (
		<div className={clsx(styles.container, className)} data-testid='single-entity-details' style={style}>
			<div className={styles.logo}>
				<div className={styles.logoContent}>
					{entity?.iconData ? (
						<img alt='logo' src={entity?.iconData} className={clsx(styles.defaultIconStyle, styles.imageIcon, small && styles.imageIconSmall)} />
					) : (
						<div className={clsx(styles.defaultIconStyle, small ? styles.logoContainerSmall : styles.logoContainer, styles.noAvatarContainer)}>
							{entity?.name ? entity?.name[0].toUpperCase() : ''}
						</div>
					)}
				</div>
			</div>

			<div style={{ flex: 2 }}>
				<div className={styles.flexColumn}>
					<span className={clsx(small ? styles.nameSmall : styles.name)}>{entity?.name}</span>
					<span className={clsx(small ? styles.descriptionSmall : styles.description)}>{entity?.ein ? `${t('ein')} ${entity?.ein}` : ''}</span>
					<span className={clsx(small ? styles.descriptionSmall : styles.description)}>{location}</span>
				</div>
			</div>
		</div>
	);
};
