import React from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../types/storeTypes';
import styles from './Loader.module.scss';

export const Loader = () => {
	const { isSidebarOpen, showLoaderIds } = useSelector((store: Store) => store.general);

	return showLoaderIds.length ? (
		<div data-testid='loader' className={styles.container}>
			<div className={styles.loaderIcon} style={{ marginRight: isSidebarOpen ? '24.8rem' : 0 }}></div>
		</div>
	) : null;
};
