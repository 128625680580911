import { CSSProperties, RefObject, useEffect, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { Header, Icon, IconImgType, Input } from '..';
import { SelectItem } from '../../types/generalTypes';
import { DUE_UPON_RECEIPT_LABEL, defaultNetTerms, netTermsSelect } from './NetTermsSelect.utils';
import styles from './NetTermsSelect.module.scss';

export interface NetTermsSelectProps {
	title?: string;
	tabIndex?: number;
	className?: string;
	width?: number | string;
	placeholder?: string;
	isUsageProduct?: boolean;
	defaultValue?: number | string;
	containerStyle?: string;
	required?: boolean;
	style?: CSSProperties;
	iconType?: IconImgType | null;
	disabled?: boolean;
	headerStyle?: string;
	inputClassName?: string;
	hideIcon?: boolean;
	showError?: boolean;
	inputRef?: RefObject<HTMLInputElement>;
	mediumInput?: boolean;
	inputIcon?: string;
	headerLabelStyle?: string;
	tooltipComponent?: React.ReactNode;
	fullBorder?: boolean;
	onChange(val: any): void;
}

export const NetTermsSelect = ({
	title,
	iconType,
	placeholder,
	defaultValue,
	width = 15,
	style,
	disabled,
	className,
	headerStyle,
	required,
	containerStyle,
	tabIndex,
	inputClassName,
	hideIcon,
	showError,
	headerLabelStyle,
	inputRef,
	mediumInput,
	tooltipComponent,
	inputIcon,
	isUsageProduct,
	fullBorder,
	onChange,
}: NetTermsSelectProps) => {
	const { t } = useTranslation('translation');

	const searchRef = useRef<HTMLInputElement>(null);
	const containerRef = useRef<HTMLInputElement>(null);
	const [referenceElement, setReferenceElement] = useState<any>(null);
	const [popperElement, setPopperElement] = useState<any>(null);
	const [selectedValue, setSelectedValue] = useState<SelectItem>();
	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState<SelectItem[]>([]);
	const [focusedItem, setFocusedItem] = useState<number>();
	const [customNet, setCustomNet] = useState<number | string>('');

	const {
		styles: popperStyle,
		attributes,
		update,
	} = usePopper(referenceElement, popperElement, {
		placement: 'auto',
		strategy: 'fixed',
		modifiers: [
			{
				name: 'flip',
				options: {
					allowedAutoPlacements: ['top', 'bottom'],
					flipVariations: true,
					fallbackPlacements: ['top'],
				},
			},
		],
	});

	useEffect(() => {
		if (isOpen) {
			const selectedValueIndex = options.findIndex((item) => {
				return item?.value === selectedValue?.value;
			});

			const focusedElement = popperElement?.children?.[focusedItem || (selectedValueIndex >= 0 ? selectedValueIndex : 0)];
			focusedElement && focusedElement.scrollIntoView({ behavior: 'smooth' });
		}
	}, [focusedItem, isOpen, options]);

	useEffect(() => {
		const list = netTermsSelect(isUsageProduct);
		setOptions(list);

		const defaultValIndex = list.findIndex((item) => {
			return defaultValue?.toString() ? item?.value === defaultValue : item?.value === defaultNetTerms;
		});

		if (defaultValIndex >= 0) {
			setSelectedValue(list[defaultValIndex]);
			setCustomNet('');
		} else if (defaultValue?.toString()) {
			setSelectedValue({ label: `Net ${defaultValue}`, value: defaultValue });
			setCustomNet(defaultValue);
		}
	}, [defaultValue, isUsageProduct, isOpen]);

	const onValueChange = (item: SelectItem) => {
		setSelectedValue(item);
		onChange(item?.value);
		setCustomNet('');
		setIsOpen(false);
		setFocusedItem(undefined);
	};

	const onInputChange = (val: string) => {
		setCustomNet(val);
		setSelectedValue({ label: val ? `Net ${val}` : DUE_UPON_RECEIPT_LABEL, value: val });
	};

	const handleKeyDown = (event: any) => {
		if (event.keyCode === 38) {
			//key up
			event.preventDefault();
			setFocusedItem((prev) => {
				const num = (prev || 0) - 1 >= 0 ? (prev || 0) - 1 : 0;
				return num;
			});
		} else if (event.keyCode === 40) {
			//key down
			event.preventDefault();

			setFocusedItem((prev) => {
				const num = (prev || 0) + 1 < options.length ? (prev || 0) + 1 : options.length - 1;
				return num;
			});
		}
	};

	const onClose = () => {
		setIsOpen(false);
		setFocusedItem(undefined);
		setCustomNet('');
		if (customNet) {
			onChange(`${customNet}`);
		} else if (!selectedValue?.value) {
			onChange(defaultNetTerms);
		}
	};

	return (
		<div
			ref={containerRef}
			data-testid='net-terms-select-component'
			style={{ width: `${width}rem`, ...style }}
			className={clsx(containerStyle, disabled && styles.disabled)}
			id='select'
		>
			{title && (
				<Header
					title={required ? `${title}*` : title}
					containerStyle={headerStyle}
					labelStyle={headerLabelStyle}
					id='net-terms-select-header'
					iconType={iconType}
					tooltipComponent={tooltipComponent}
				/>
			)}

			<div
				style={popperStyle.offset}
				ref={(ref) => setReferenceElement(ref)}
				className={clsx(
					styles.input,
					fullBorder && styles.fullBorder,
					mediumInput && styles.inputMediumInputContainer,
					showError && styles.errorContainer,
					className,
				)}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						setIsOpen(true);
						typeof focusedItem === 'number' && onValueChange(options[focusedItem]);
					}
				}}
				onClick={() => setIsOpen(true)}
				id='net-terms-select-component-input'
				data-testid={`select-input-button${title ? `-${title}` : ''}`}
			>
				{inputIcon && <img src={inputIcon} className={styles.inputIcon} />}
				<input
					onClick={() => {
						update && update();
						setIsOpen(true);
					}}
					onFocus={() => {
						update && update();
						setIsOpen(true);
					}}
					id='net-terms-select-input'
					data-testid={`net-terms-select-input${title ? `-${title}` : ''}`}
					tabIndex={tabIndex}
					placeholder={placeholder}
					className={clsx(styles.inputText, inputClassName)}
					value={selectedValue?.label}
					ref={inputRef || searchRef}
					onKeyDown={(e) => isOpen && handleKeyDown(e)}
					autoComplete='off'
				/>

				{!hideIcon && (
					<Icon
						id='net-terms-select-icon'
						imgType='arrow_down'
						color='neutral700'
						width={1.5}
						className={isOpen ? styles.arrowUp : styles.arrowDown}
					/>
				)}
			</div>

			{ReactDOM.createPortal(
				<>
					<div
						ref={(ref) => setPopperElement(ref)}
						{...attributes.popper}
						className={clsx(styles.selectDropdownContainer, isOpen ? styles.selectDropdownContainerOpen : styles.selectDropdownClose)}
						style={{
							minWidth: containerRef?.current?.offsetWidth,
							...popperStyle.popper,
						}}
					>
						{options?.map((item, index) => (
							<div
								className={clsx(
									styles.selectItem,
									item?.label === selectedValue?.label && styles.selected,
									index === focusedItem && styles.focusedItem,
									item?.isDisabled && styles.disabled,
								)}
								id={`net-terms-select-list-item-${index}`}
								key={index}
								onClick={() => onValueChange(item)}
							>
								{typeof item === 'string' ? item : item.customComponent ? item.customComponent(item) : item.label}
							</div>
						))}

						<Input
							id='select-list-item-custom-input'
							data-testid='select-list-item-custom-input'
							tabIndex={tabIndex}
							placeholder={t('customNet')}
							className={clsx(styles.selectInputContainer, customNet === selectedValue?.value && styles.selected)}
							inputClassName={clsx(customNet === selectedValue?.value && styles.selected)}
							onChange={(val) => onInputChange(val.replace(/\D+/g, ''))}
							value={customNet}
							onBlur={() => {}}
							rightChildren={<Icon imgType='edit' />}
							autoComplete='off'
							leftChildren={<div className={styles.net}>{t('net')} </div>}
						/>
					</div>
					{isOpen && <div className={styles.selectOverlay} onClick={onClose}></div>}
				</>,
				document.body,
			)}
		</div>
	);
};
