import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { SelectItem } from '../../types/generalTypes';
import { IntegrationMatchedObjects } from '../../types/integrationTypes';
import { Button, Icon, Tooltip } from '../_uiComponents';
import { ExportRow } from './ExportRow/ExportRow';
import { ActionModalModal } from '..';
import { images } from '../../constants/images';
import styles from './ItemMatcher.module.scss';

export interface ItemMatcherListHeader {
	title: string;
	subTitle: string;
	icon: string;
}

export interface ItemMatcherProps {
	rightLisHeader: ItemMatcherListHeader;
	leftListHeader: ItemMatcherListHeader;
	exportList: IntegrationMatchedObjects[];
	rightList: SelectItem[];
	leftList: SelectItem[];
	isSyncing?: boolean;
	onUpdateExportItems?(matchedObjects: IntegrationMatchedObjects[], syncStatusId: string): void;
	onRefresh?(): void;
	onDeleteRow(index: number): void;
}

export const ItemMatcher = ({
	rightLisHeader,
	leftListHeader,
	exportList = [],
	rightList,
	leftList,
	isSyncing,
	onUpdateExportItems,
	onRefresh,
	onDeleteRow,
}: ItemMatcherProps) => {
	const { t } = useTranslation('translation');
	const [openAreYouSureModal, setOpenAreYouSureModal] = useState(false);

	const onAddRow = () => {
		onUpdateExportItems?.([...exportList, { domainObject: undefined, remoteObject: undefined }], '');
	};

	const onUpdateRow = (newRow: IntegrationMatchedObjects, index: number) => {
		const newList = [...exportList];
		newList[index] = { ...newRow, status: undefined, syncStatusId: undefined };
		onUpdateExportItems?.(newList, newList[index]?.syncStatusId || '');
	};

	return (
		<Tooltip tooltipComponent={isSyncing && t('actionsInvalidWhenSyncing')} blackTooltip>
			<div className={clsx(styles.container, isSyncing && styles.locked)}>
				<div className={styles.listHeader}>
					<div className={styles.headerItem}>
						<div className={styles.iconContainer}>
							<img alt='cardIcon' src={rightLisHeader.icon} className={styles.cardIcon}></img>
						</div>
						<div>
							<div className={styles.title}>{rightLisHeader.title}</div>
							<div className={styles.fontSize18}>{rightLisHeader.subTitle}</div>
						</div>
					</div>

					<div className={styles.headerItem}>
						<div className={styles.headerItemTitles}>
							<div className={styles.iconContainer}>
								<img alt='cardIcon' src={leftListHeader.icon} className={styles.cardIcon}></img>
							</div>
							<div className={styles.rightSide}>
								<div className={styles.titleWrapper}>
									<div className={styles.title}>{leftListHeader.title}</div>
									<Button
										color='neutral'
										type='outlined'
										onClick={() => setOpenAreYouSureModal(true)}
										className={clsx(styles.button, isSyncing ? styles.buttonSyncing : styles.refreshButton)}
									>
										{t(isSyncing ? 'syncing' : 'resync')}
										<Icon imgType='refresh' height={1.2} color='neutral700' className={isSyncing ? styles.refreshIconAnimation : ''} />
									</Button>
								</div>

								<div className={styles.fontSize18}>{leftListHeader.subTitle}</div>
							</div>
						</div>
					</div>
				</div>

				{exportList.map((item, index) => (
					<ExportRow
						key={index}
						data={item}
						leftList={leftList}
						rightList={rightList}
						onUpdateRow={(newRow) => onUpdateRow(newRow, index)}
						onDeleteRow={() => onDeleteRow(index)}
					/>
				))}

				<Button type='outlined' color='neutral' className={styles.addButton} onClick={onAddRow}>
					{t('add')}
					<Icon imgType='add' height={1} color='neutral' />
				</Button>
			</div>

			<ActionModalModal
				rightButton={{ title: 'resync', color: 'primary' }}
				imagesOptions={images.alert}
				isOpen={openAreYouSureModal}
				deleteMessage={`${t('resync')} ${leftListHeader.title}`}
				onAccept={() => {
					onRefresh?.();
					setOpenAreYouSureModal(false);
				}}
				onClose={() => setOpenAreYouSureModal(false)}
			/>
		</Tooltip>
	);
};
